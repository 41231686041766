import { Fragment, useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { AudioOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { Modal, Input, Table, Button, Space, Tooltip, Select, Card, Tag, Popover } from 'antd';
import { getUsermanagementList, getAdminDetails, updatestatus, activeStatus, disableStatusChange, LegalAdmindisableStatusChange, haveCompliance, getById, resendEmail, getUsermanagementDetails } from './../../Store/Action/Master/UserManagement'
import {
    InfoCircleOutlined, LikeOutlined, DislikeOutlined, UpCircleOutlined, UpOutlined,
    StopOutlined, SearchOutlined, PlayCircleOutlined, CloseCircleOutlined, RetweetOutlined, PlusCircleOutlined, CloseOutlined, FullscreenOutlined, MailOutlined, EyeOutlined, FilterOutlined, EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import AddUserManagement from './AddUserManagement';
import TextArea from 'antd/lib/input/TextArea';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from '../Scrollbar';
import ReplaceUser from './ReplaceUser';
import { REMARKS_MODAL_RESET } from '../../Store/types';
import { Toaster } from '../../Libs/Toaster';
import { CountrySplit, HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()

const uniq_country_data = CountrySplit()

const _ = require("lodash");
const { Option } = Select;
const mapStateToProps = (state) => ({
    usermanagementList: state.UserManagement.userManagement,
    filterrecall: state.recallfilters.recallfilters
})
const Usermanagement = ({
    getUsermanagementList,
    resendEmail,
    getUsermanagementDetails,
    GetRemarksData,
    updatestatus,
    getById,
    activeStatus,
    getAdminDetails,
    addChange,
    setAddChange,
    userfullscreen1,
    disableStatusChange,
    LegalAdmindisableStatusChange,
    haveCompliance,
    filterrecall: { recallremarks },
    usermanagementList: { userManagementList, UserLegalDetails, adminDetails, userManagementDetails, GetUserDetails }
}) => {
    const historynew = useHistory();
    console.log(userManagementList, 'userManagementList');
    const location = useLocation();
    const is_modal_true = useSelector((state) => state.recallfilters.is_modal_true)
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    console.log(UserLegalDetails, 'UserLegalDetails');
    const serviceProviderRef1 = useRef(null);
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const entityFormvalidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [userCategoryId, setUserCategoryId] = useState()
    const [particularLeId, setParticularLeId] = useState([])
    const [prevLe, setPrevLe] = useState([])
    console.log(prevLe, 'prevLe');
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        le_id: '',
        user_id: ''
    })
    console.log(UserLegalDetails, 'UserLegalDetails');
    console.log(adminDetails, 'adminDetails');
    const [scroll, setScroll] = useState(false)
    const [fullscreen, setfullscreen] = useState(false);
    const [usermanagementDetails, setuserMAnagementDeails] = useState([])
    console.log(particularLeId, 'particularLeId');
    const [EntitySubmit, setEntityFormSubmit] = useState(false);
    const [remarks, setRemarks] = useState('')
    const [filterarray, setFilterArray] = useState([])
    const [tempusermagementListData, setTempmanagementListData] = useState([])
    console.log(tempusermagementListData, 'tempusermagementListData');
    const [usermagementListData, setUsermanagementListData] = useState([])
    console.log(usermagementListData, 'usermagementListData');
    console.log(filterarray, 'filterarray');
    const selectedEntity = localStorage.getItem('SelectedEntity')
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    // const entityid = localStorage.getItem('SelectedEntityid');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [disableType, setDisableType] = useState("")
    const [remarksModal, setRemarksModal] = useState(false);
    const currentdate = new Date();
    const [date, setDate] = useState(moment(currentdate).format('DD-MMM-YYYY'))
    const [show, setShow] = useState(false)
    const [filterModal, setFilterModal] = useState(false)
    console.log(filterModal, "filterModal");
    const [showList, setShowList] = useState(false)
    const [replace, setReplace] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );

    const [passvisible, Setpassvisible] = useState(false);
    const [usermangementData, setUserMagementData] = useState([])
    const [EntityValue, setEntityValue] = useState()
    const [main, setmain] = useState({
        country: '',
        countryid: '',
        entity: '',
        business: ''
    })
    console.log(main, 'mainmainmain');
    const [categoryValue, setCategoryValue] = useState({
        compfie: [],
        vendor: []
    })
    console.log(categoryValue, 'categoryValue');
    const [existingUser, setExistingUser] = useState(false)
    const [existingUserData, setExistingUserData] = useState([])
    const [type, setType] = useState("");
    const [AddType, setAddType] = useState("list")
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [legalEntity, setLegalEntity] = useState()
    const [bgArray, setBgArray] = useState([])
    const [finallegalEntity, setFinallegalentity] = useState()

    const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
    const entity_info = sessionValue.entity_info
    const [countryData, setCountryData] = useState([])
    const country_info = sessionValue.country_info
    const loginResponse = sessionValue.login_response
    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        user_id: '',
        product_id: '',
        le_id: ''
    })
    const [modalData, setModalData] = useState({
        password: '',
        reason: '',
        user_id: ''

    })
    const [active, setActive] = useState({
        u_id: "",
        emp_name: "",
    })
    const [modalVisible, setModaVisible] = useState(false)
    const [business, setBusiness] = useState(false)
    const [legal, setLegal] = useState(false)
    const [Entity, setEntity] = useState({})
    const [filteredArray, setFilteredArray] = useState([])
    console.log(filteredArray, 'filteredArray');
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [page, setPage] = useState(1);
    const [remarksvalue, Setremarksvalue] = useState();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    console.log(entity_info.length, 'entity_info');
    const RemarksColumn = [
        {
            title: '#',
            dataIndex: false,
            width: '18px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            },

        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '100px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                setRemarks(text)
                setRemarksModal(false)
                dispatch({
                    type: REMARKS_MODAL_RESET,
                    payload: false
                })
            }}>{record.r_description}</a>,

        },
    ];

    const [baseData, setBaseData] = useState([]);
    // const contents = (items) => {
    //     return <table style={{ width: "80px", height: '100px' }} class="table table-striped" >
    //         <tbody>
    //             <tc>
    //                 <th>Concurrer</th>
    //                 <td><b>{items.concurrence_person}</b></td>
    //             </tc>
    //             <tc>
    //                 <th>Approver</th>
    //                 <td><b>{items.approval_person}</b></td>
    //             </tc>
    //         </tbody>
    //     </table>
    // };
    useEffect(() => {
        if (recallremarks && is_modal_true) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
            if (temp.length == 0) {
                Toaster.error('No Remarks Available')
                setAddFormSubmit(false)
                Setpassvisible(false);
                setModaVisible(false)
                setAddFormSubmit1(false)
                Setpassword({
                    ...passwordata,
                    passvalue: ""

                })
                dispatch({
                    type: REMARKS_MODAL_RESET,
                    payload: false
                })
                setModalData({
                    ...modalData,
                    password: ""
                })

                setRemarks("")
            } else {
                setRemarksModal(true);
            }
        }

    }, [recallremarks])
    useEffect(() => {
        if (categoryValue.compfie && categoryValue.compfie.length == 0) {
            setCategoryValue({
                vendor: []
            })
        }
    }, [categoryValue.compfie])
    useEffect(() => {
        if (entity_info && entity_info.length > 0) {
            const unique = _.uniqBy(entity_info, function (e) {

                return e.c_id;

            });
            setCountryData(unique)

        }
    }, [])
    console.log(countryData, 'countryData');

    const modalCancel = () => {
        setFilterModal(false)
    }
    const passwordcancel = () => {
        setAddFormSubmit(false)
        Setpassvisible(false);
        setModaVisible(false)
        setAddFormSubmit1(false)
        Setpassword({
            ...passwordata,
            passvalue: ""

        })
        dispatch({
            type: REMARKS_MODAL_RESET,
            payload: false
        })
        setModalData({
            ...modalData,
            password: ""
        })

        setRemarks("")

    }
    /*auto focus */
    const setFocus = (ref) => {
        console.log(ref, 'currentref')
        setTimeout(() => {
            ref.current.focus();
        });
    };
    /*auto focus */

    // const scrollup = () => {
    //     window.scrollTo(0, 0)
    // }

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    // }, []);

    // const handleScroll = () => {
    //     if (window.scrollY >= 213) {
    //         setScroll(true)
    //     }
    //     else {
    //         setScroll(false)
    //     }
    // }


    const showModal = () => {
        setIsModalVisible(true);
    };
    const businessmodel = () => {
        setBusiness(true)
    }
    const legalmodel = () => {
        setLegal(true)
    }
    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
        entityFormvalidator.current.showMessages()
    }, [])
    const OnSubmited = () => {
        let datas = []
        let activeData = []
        setAddFormSubmit(true)
        if (userCategoryId && userCategoryId.u_cat_id == 3) {
            let data = _.filter(usermangementData, { u_cat_id: Number(userCategoryId.u_cat_id) })

            if (data && data.length > 0) {
                datas = _.filter(data, { le_id: userCategoryId.le_id })
                if (datas && datas.length > 0) {
                    activeData = _.filter(datas, { is_disable: 0 })
                }
                console.log(activeData, 'activeData');
            }
        }
        if (userCategoryId.u_cat_id != 3) {
            if (formValidator.current.allValid()) {
                setModaVisible(false)
                if (disableType == 'disable') {
                    haveCompliance({
                        payload:
                            [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "HaveCompliances",
                                        {
                                            "le_ids": modalData.le_id,
                                            "user_id": modalData.user_id,
                                            "password": modalData.password,
                                            "product_id": "",

                                        }

                                    ]
                                }
                            ],
                        paramid: paramid,
                        entityid: entityid,
                        type: disableType,
                        user_id: modalData.user_id,
                        remarks: remarks,
                        authtoken: authtoken,
                        password: modalData.password,
                        setModaVisible: setModaVisible
                    })
                }
                else {
                    const payload1 = [

                        authtoken,

                        {

                            "session_token": authtoken,

                            "request": [

                                "BlockUser",

                                {

                                    "user_id": modalData.user_id,

                                    "is_blocked": false,

                                    "active_status": true,

                                    "remarks": remarks,

                                    "password": modalData.password



                                }


                            ]


                        }

                    ]
                    disableStatusChange({
                        payload: payload1,
                        paramid: paramid,
                        setModaVisible: setModaVisible
                    })
                }
                setRemarks("")
                Setpassword({
                    ...passwordata,
                    passvalue: ""

                })
                setModalData({
                    ...modalData,
                    password: ""
                })
                setAddFormSubmit(false)

            }
        }
        else {
            if (formValidator.current.allValid()) {
                setModaVisible(false)
                if (datas && datas.length == 1) {
                    if (disableType == 'disable') {
                        haveCompliance({
                            payload:
                                [
                                    authtoken,
                                    {
                                        "session_token": authtoken,
                                        "request": [
                                            "HaveCompliances",
                                            // "SaveUnitClosureData",
                                            // {
                                            //     "password": modalData.password,
                                            //     "closed_remarks": remarks,
                                            //     "unit_id": Number(modalData.unit_id),
                                            //     "grp_mode": modalData.reason=='closed'?"close":"reactive",
                                            //     "legal_entity_id": entityid
                                            // }
                                            {
                                                "le_ids": modalData.le_id,
                                                "user_id": modalData.user_id,
                                                "password": modalData.password,
                                                "product_id": ""
                                            }

                                        ]
                                    }
                                ],
                            paramid: paramid,
                            entityid: entityid,
                            type: disableType,
                            user_id: modalData.user_id,
                            remarks: remarks,
                            authtoken: authtoken,
                            password: modalData.password,
                            setModaVisible: setModaVisible
                        })
                    }
                    else {
                        const payload1 = [

                            authtoken,

                            {

                                "session_token": authtoken,

                                "request": [

                                    "BlockUser",

                                    {

                                        "user_id": modalData.user_id,

                                        "is_blocked": false,

                                        "active_status": true,

                                        "remarks": remarks,

                                        "password": modalData.password



                                    }


                                ]


                            }

                        ]
                        disableStatusChange({
                            payload: payload1,
                            paramid: paramid,
                            setModaVisible: setModaVisible
                        })
                    }
                }
                else if (activeData && activeData.length == 1) {
                    if (disableType == 'disable') {
                        haveCompliance({
                            payload:
                                [
                                    authtoken,
                                    {
                                        "session_token": authtoken,
                                        "request": [
                                            "HaveCompliances",
                                            // "SaveUnitClosureData",
                                            // {
                                            //     "password": modalData.password,
                                            //     "closed_remarks": remarks,
                                            //     "unit_id": Number(modalData.unit_id),
                                            //     "grp_mode": modalData.reason=='closed'?"close":"reactive",
                                            //     "legal_entity_id": entityid
                                            // }
                                            {
                                                "le_ids": modalData.le_id,
                                                "user_id": modalData.user_id,
                                                "password": modalData.password,
                                                "product_id": ""
                                            }

                                        ]
                                    }
                                ],
                            paramid: paramid,
                            entityid: entityid,
                            type: disableType,
                            user_id: modalData.user_id,
                            remarks: remarks,
                            authtoken: authtoken,
                            password: modalData.password,
                            setModaVisible: setModaVisible
                        })
                    }
                    else {
                        const payload1 = [

                            authtoken,

                            {

                                "session_token": authtoken,

                                "request": [

                                    "BlockUser",

                                    {

                                        "user_id": modalData.user_id,

                                        "is_blocked": disableType == 'disable' ? true : false,

                                        "active_status": true,

                                        "remarks": remarks,

                                        "password": modalData.password



                                    }


                                ]


                            }

                        ]
                        LegalAdmindisableStatusChange({
                            payload: payload1,
                            paramid: paramid,
                            setModaVisible: setModaVisible,
                            type: disableType
                        })
                    }
                }
                else {
                    const payload1 = [

                        authtoken,

                        {

                            "session_token": authtoken,

                            "request": [

                                "BlockUser",

                                {

                                    "user_id": modalData.user_id,

                                    "is_blocked": disableType == 'disable' ? true : false,

                                    "active_status": true,

                                    "remarks": remarks,

                                    "password": modalData.password



                                }


                            ]


                        }

                    ]
                    LegalAdmindisableStatusChange({
                        payload: payload1,
                        paramid: paramid,
                        setModaVisible: setModaVisible,
                        type: disableType
                    })
                }

                setRemarks("")
                Setpassword({
                    ...passwordata,
                    passvalue: ""

                })
                setModalData({
                    ...modalData,
                    password: ""
                })
                setAddFormSubmit(false)

            }
        }

    }
    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        // setRemarksModal(true);
        dispatch({
            type: REMARKS_MODAL_RESET,
            payload: true
        })
    };
    const handleOk = () => {

        setBusiness(false)
        setLegal(false)
        setRemarksModal(false)
        Setpassvisible(false)
    };

    const handleRemarksOk = () => {
        setRemarksModal(false)
    }


    const submitpassword = () => {
        setAddFormSubmit1(true)
        if (formValidator1.current.allValid()) {

            if (type === "active") {
                console.log('test in')
                const pay = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "HaveCompliances",
                            {
                                "le_ids": passwordata.le_id,
                                "user_id": passwordata.user_id,
                                "password": passwordata.passvalue,
                                "product_id": ""
                            }
                        ]
                    }
                ]

                const payloads = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "ChangeClientUserStatus",
                            {
                                "u_id": active.u_id,
                                "active_status": false,
                                "emp_name": active.emp_name,
                                "password": passwordata.passvalue
                            }
                        ]


                    }
                ]

                updatestatus({
                    payload: pay,
                    paramid: paramid,
                    types: type,
                    payloads: payloads,
                    Setpassvisible: Setpassvisible
                })

                Setpassvisible(false);
                Setpassword({
                    ...passwordata,
                    passvalue: ""
                })
            }
            else {

                const payloads = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "ChangeClientUserStatus",
                            {
                                "u_id": active.u_id,
                                "active_status": type === "active" ? false : true,
                                "emp_name": active.emp_name,
                                "password": passwordata.passvalue
                            }
                        ]


                    }
                ]

                activeStatus({
                    types: type,
                    payload: payloads,
                    paramid: paramid,
                    Setpassvisible: Setpassvisible
                })
                Setpassvisible(false);
                Setpassword({
                    ...passwordata,
                    passvalue: ""
                })

            }
            setAddFormSubmit1(false)
        }
    }

    const handleCancel = () => {
        setAddFormSubmit(false)
        setIsModalVisible(false);
        Setpassvisible(false)
        setBusiness(false)
        setLegal(false)
        setModaVisible(false)

    };
    const handleCancel1 = () => {
        setRemarksModal(false)
    }
    useEffect(() => {
        let finalArray = [];
        let countrylistdata = [];
        let countrylisttempdata = [];
        // for (let countrylist in country_info) {

        let entityArray = []
        let tempArray = []
        let sumValue = 0;
        let country_id
        let country_name
        for (let entitylist in entity_info) {
            country_id = entity_info[entitylist].c_id
            country_name = entity_info[entitylist].c_name
            let entitylist_cid = entity_info[entitylist].c_id
            if (entitylist_cid === country_id) {
                sumValue = entity_info[entitylist].u_count + sumValue
                entityArray = {
                    'bg_id': entity_info[entitylist].bg_id,
                    'bg_name': entity_info[entitylist].bg_name,
                    'c_id': entity_info[entitylist].c_id,
                    'c_name': entity_info[entitylist].c_name,
                    'le_id': entity_info[entitylist].le_id,
                    'le_name': entity_info[entitylist].le_name,
                    'u_count': entity_info[entitylist].u_count,
                }
                tempArray.push(entityArray)
            }
        }
        finalArray[country_name] = tempArray
        countrylistdata = {
            'c_id': country_id,
            'c_name': country_name,
            'allEntitySumNew': sumValue,
            'child': tempArray
        }
        countrylisttempdata.push(countrylistdata)
        SetfinalResult({
            ...finalResult,
            Resultdata: countrylisttempdata
        })


        // }

    }, [])

    console.log(finalResult, 'finalResult');
    console.log(entity_info, 'entityInfo');

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UserManagementList",
                        {}
                    ]
                }
            ]
            getUsermanagementList({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])
    useEffect(() => {
        if (isAuth) {
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "Admindetails",
                        {}
                    ]
                }
            ]
            getAdminDetails({
                payload: payload1,
                paramid: paramid
            })
        }
    }, [isAuth])
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UserManagementPrerequisite",
                        {}
                    ]
                }
            ]
            getUsermanagementDetails({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])

    const { Search } = Input;
    const [searchValue, setSearchValue] = useState([]);
    const [searchValue1, setSearchValue1] = useState([]);
    const [searchValue2, setSearchValue2] = useState([]);
    const [searchValue3, setSearchValue3] = useState([]);
    console.log(searchValue, 'searchValue');
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handlesearch = (e, confirm, dataIndex, setSelectedKeys, leId) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            if (dataIndex == 'emp_name') {
                searchValue[leId] = e.target.value
                setSearchValue(searchValue)
            }
            else if (dataIndex == 'user_name_new') {
                searchValue1[leId] = e.target.value
                setSearchValue1(searchValue1)
            }
            else if (dataIndex == 'email_id') {
                searchValue2[leId] = e.target.value
                setSearchValue2(searchValue2)
            }
            else if (dataIndex == 'mob_no') {
                searchValue3[leId] = e.target.value
                setSearchValue3(searchValue3)
            }
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {

            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys, leId) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            searchValue[leId] = e.target.value
            setSearchValue(searchValue)
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };
    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys, i) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
        if (dataIndex == 'emp_name') {
            searchValue[i] = ''
            setSearchValue([...searchValue])
        }
        else if (dataIndex == 'user_name_new') {
            searchValue1[i] = ''
            setSearchValue1([...searchValue1])
        }
        else if (dataIndex == 'email_id') {
            searchValue2[i] = ''
            setSearchValue2([...searchValue2])
        }
        else if (dataIndex == 'mob_no') {
            searchValue3[i] = ''
            setSearchValue3([...searchValue3])
        }
    };
    const handleReset1 = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys, i) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');

    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);

    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={searchValue[particularLeId]}

                    // autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset1(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered, record) => {
            console.log(record, 'filtered');
            return (
                <SearchOutlined
                    style={{
                        //     // color: filtered ? '#1890ff' : undefined,
                        //     fontWeight: 'bold',
                        //     // backgroundColor:'blue',
                        //     fontSize: '15px',
                        //     color: 'black'
                    }}
                />

            )
        },
        onFilter: (value, record) =>
            record[dataIndex] != null && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    const getColumnSearchProperty = (dataIndex, placeholder, i) => ({

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={dataIndex == 'emp_name' ? searchValue[i] : dataIndex == 'user_name_new' ? searchValue1[i] : dataIndex == 'email_id' ? searchValue2[i] : dataIndex == 'mob_no' ? searchValue3[i] : undefined}
                    // autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handlesearch(e, confirm, dataIndex, setSelectedKeys, i)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handlesearch, confirm, dataIndex, setSelectedKeys, i)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered, record) => {
            console.log(record, 'filtered');
            return (
                <SearchOutlined
                    style={{
                        //     // color: filtered ? '#1890ff' : undefined,
                        //     fontWeight: 'bold',
                        //     // backgroundColor:'blue',
                        //     fontSize: '15px',
                        //     color: 'black'
                    }}
                />
                // <FileSearchOutlined 
                // style={{
                //     // color: filtered ? '#1890ff' : undefined,
                //     fontWeight:'bold',
                //     // backgroundColor:'blue',
                //     // fontSize:'20px',
                //     color:'black'
                // }}
                // />
                // <i class="bi bi-search"></i>
            )
        },
        onFilter: (value, record) =>
            // console.log(tableSearch[i][dataIndex], 'tableSearch[i][dataIndex]'),
            tableSearch.length > 0 && tableSearch[i] && tableSearch[i][dataIndex] !== null ? record[dataIndex].toString().toLowerCase().includes(tableSearch[i] && tableSearch[i][dataIndex] && tableSearch[i] && tableSearch[i][dataIndex].length > 0 && tableSearch[i] && tableSearch[i][dataIndex][0].toLowerCase()) : record,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    useEffect(() => {
        console.log(userfullscreen1, 'userfullscreen1');
        if (userfullscreen1 != undefined && (userfullscreen1 != '' || userfullscreen1 == true)) {
            fullscreenMode();
        }
    }, [userfullscreen1])

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [updatedSorting, setUpdatedSorting] = useState([])
    const [tableSortOrders, setTableSortOrders] = useState([]);
    const [tableSearch, setTableSearch] = useState([]);
    console.log(tableSearch, 'tableSearch')
    console.log(tableSortOrders, 'tableSortOrders');

    const tablehandleChange = (tableIndex, column, sortOrder, filters, name) => {
        const newTableSortOrders = [...tableSortOrders];
        const newTableSearch = [...tableSearch]
        newTableSortOrders[tableIndex] = { column, sortOrder };
        newTableSearch[tableIndex] = { ...filters }
        setTableSortOrders(newTableSortOrders);
        setTableSearch(newTableSearch)
        setFilteredInfo(filters);
        if (tableIndex != prevLe) {
            setPrevLe([])
        }
        setPrevLe(tableIndex)
        // const newSortOrder = { columnKey: sorter.columnKey, order: sorter.order };
        // const newSortOrders = [...updatedSorting];
        // newSortOrders[le_id] = newSortOrder;
        // setUpdatedSorting(newSortOrders)
        //
        // particularLeId[tableIndex] = extra.currentDataSource[0].le_id

        // Object.assign(sorter, {le_id : extra.currentDataSource[0].le_id})
        // console.log(filters, 'filters');
        // console.log(sorter,'sorter1')
        // if(sorter.le_id == extra.currentDataSource[0].le_id){
        //     setSortedInfo(sorter);
        //     setFilteredInfo(filters);
        //     updatedSorting[extra.currentDataSource[0].le_id] = sorter
        //     particularLeId[extra.currentDataSource[0].le_id] = extra.currentDataSource[0].le_id
        //     setPrevLe(extra.currentDataSource[0].le_id)
        // }    
    };
    const tablehandleChanges = (pagination, filters, sorter, extra) => {
        setSortedInfo(sorter);
        setFilteredInfo(filters);
        setParticularLeId(extra.currentDataSource && extra.currentDataSource[0].le_id)

    };

    useEffect(() => {
        if ((addChange == true) && (fullscreen == false)) {
            fullscreenMode()
        }
    }, [addChange])



    // const columns = [
    //     {
    //         title: '#',
    //         dataIndex: false,
    //         align: 'center',
    //         width: '18px',
    //         render: (text, record, index) => {
    //             return index + 1
    //         }
    //     },

    //     {
    //         title: 'Users',
    //         dataIndex: 'emp_name',
    //         key: 'emp_name',
    //         width: '75px',

    //         // ellipsis: true,
    //         ...getColumnSearchProps('emp_name', 'Users'),
    //         filteredValue: filteredInfo.emp_name || null,
    //         // sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
    //         sorter: (a, b) => {
    //             if (prevLe == a.le_id) {
    //                 if (a && a.emp_name && a.emp_name.length && b && b.emp_name && b.emp_name.length) {
    //                     return a.emp_name.localeCompare(b.emp_name);
    //                 } else if (a && a.emp_name && a.emp_name.length) {
    //                     // That means be has null division_name, so a will come first.
    //                     return -1;
    //                 } else if (b && b.emp_name && b.emp_name.length) {
    //                     // That means a has null division_name so b will come first.
    //                     return 1;
    //                 }
    //             }

    //             // Both division_name has null value so there will be no order change.
    //             return 0;
    //         },
    //         sortOrder: tableSortOrders[prevLe] && tableSortOrders[prevLe].columnKey === 'emp_name' && tableSortOrders[prevLe].order,
    //         render: (row, record) => {
    //             console.log(row, 'row12344948');
    //             return (
    //                 <Fragment>
    //                     {
    //                         ((record.user_name == null) && (record.is_disable == 0)) ?
    //                             // <p>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} /></p>


    //                             <><Tooltip title="Click here to resend email" > <span>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} onClick={() => { resentEmailRegistration(record) }} /></span></Tooltip>
    //                                 <Tooltip title={
    //                                     'compfie'
    //                                 }>  {record.u_cat_id == null ? <StopOutlined style={{ color: 'red' }} /> :
    //                                     <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.u_cat_id == 2 ? "#C9C9C9" : record.u_cat_id == 3 ? '#f7bf17' : record.u_cat_id == 5 ? "#6ecd2c" : record.u_cat_id == 1 ? "#FF6666" : record.u_cat_id == 6 ? "#C6E0B4" : record.u_cat_id == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}
    //                                 </Tooltip>
    //                                 <Tooltip title={
    //                                     "Vendor"
    //                                 }> {record.vndr_user_category == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.vndr_user_category == 2 ? "#C9C9C9" : record.vndr_user_category == 3 ? '#f7bf17' : record.vndr_user_category == 5 ? "#6ecd2c" : record.vndr_user_category == 1 ? "#FF6666" : record.vndr_user_category == 6 ? "#C6E0B4" : record.vndr_user_category == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}</Tooltip>

    //                                 <Tooltip title={
    //                                     "Litigation"
    //                                 }>  {record.u_type_id == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_type_id == 2 ? "#FFE699" : record.u_type_id == 3 ? "#a1b793" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
    //                             </>

    //                             : <span>{record.emp_name}
    //                                 <Tooltip title={
    //                                     'compfie'
    //                                 }>  {record.u_cat_id == null ? <StopOutlined style={{ color: 'red' }} /> :
    //                                     <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.u_cat_id == 2 ? "#C9C9C9" : record.u_cat_id == 3 ? '#f7bf17' : record.u_cat_id == 5 ? "#6ecd2c" : record.u_cat_id == 1 ? "#FF6666" : record.u_cat_id == 6 ? "#C6E0B4" : record.u_cat_id == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}
    //                                 </Tooltip>
    //                                 <Tooltip
    //                                     title={
    //                                         "Vendor"
    //                                     }
    //                                 > {record.vndr_user_category == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.vndr_user_category == 4 ? "#FFE699" : record.vndr_user_category == 2 ? "#C9C9C9" : record.vndr_user_category == 3 ? '#f7bf17' : record.vndr_user_category == 5 ? "#6ecd2c" : record.vndr_user_category == 1 ? "#FF6666" : record.vndr_user_category == 6 ? "#C6E0B4" : record.vndr_user_category == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
    //                                 <Tooltip title={
    //                                     "Litigation"
    //                                 }>  {record.u_type_id == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_type_id == 2 ? "#FFE699" : record.u_type_id == 3 ? "#a1b793" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
    //                             </span>
    //                     }

    //                 </Fragment>



    //             )
    //         }
    //     },
    //     {
    //         title: 'User ID',
    //         dataIndex: 'user_name_new',
    //         key: 'user_name_new',
    //         width: '60px',
    //         // ellipsis: true,
    //         ...getColumnSearchProps('user_name_new', 'User ID'),
    //         filteredValue: filteredInfo.user_name_new || null,

    //         sorter: (a, b) => {
    //             console.log(a.le_id, 'a.le_id');
    //             if (prevLe == a.le_id) {
    //                 if (a && a.user_name_new && a.user_name_new.length && b && b.user_name_new && b.user_name_new.length) {
    //                     return a.user_name_new.localeCompare(b.user_name_new);
    //                 } else if (a && a.user_name_new && a.user_name_new.length) {
    //                     // That means be has null division_name, so a will come first.
    //                     return -1;
    //                 } else if (b && b.user_name_new && b.user_name_new.length) {
    //                     // That means a has null division_name so b will come first.
    //                     return 1;
    //                 }

    //                 // Both division_name has null value so there will be no order change.
    //                 return 0;
    //             }
    //         },
    //         // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
    //         // sortOrder: sortedInfo.columnKey === 'user_name_new' ? sortedInfo.order : null,
    //         sortOrder: tableSortOrders[prevLe] && tableSortOrders[prevLe].columnKey === 'user_name_new' && tableSortOrders[prevLe].order,

    //     },
    //     {
    //         title: 'Email ID',
    //         dataIndex: 'email_id',
    //         width: '85px',
    //         key: 'email_id',
    //         ...getColumnSearchProps('email_id', 'Email ID'),
    //         ellipsis: true,
    //         filteredValue: filteredInfo.email_id || null,
    //         sorter: (a, b) => {
    //             if (prevLe == a.le_id) {
    //                 if (a && a.email_id && a.email_id.length && b && b.email_id && b.email_id.length) {
    //                     return a.email_id.localeCompare(b.email_id);
    //                 } else if (a && a.email_id && a.email_id.length) {
    //                     // That means be has null division_name, so a will come first.
    //                     return -1;
    //                 } else if (b && b.email_id && b.email_id.length) {
    //                     // That means a has null division_name so b will come first.
    //                     return 1;
    //                 }

    //                 // Both division_name has null value so there will be no order change.
    //                 return 0;
    //             }
    //         },
    //         // sorter: (a, b) => a.email_id && b.email_id != null ? a.email_id.localeCompare(b.email_id) : "",
    //         // sortOrder: sortedInfo.columnKey === 'email_id' ? sortedInfo.order : null,
    //         sortOrder: tableSortOrders[prevLe] && tableSortOrders[prevLe].columnKey === 'email_id' && tableSortOrders[prevLe].order,
    //     },
    //     {
    //         title: 'Mobile Number',
    //         dataIndex: 'mob_no',
    //         key: 'mob_no',
    //         ...getColumnSearchProps('mob_no', 'Mobile Number'),
    //         width: '35px',
    //         // ellipsis: true
    //         filteredValue: filteredInfo.mob_no || null,
    //         sorter: (a, b) => prevLe == a.le_id ? a.mob_no.localeCompare(b.mob_no) : false,
    //         // sorter: (a, b) => {

    //         //     if(a && a.mob_no && a.mob_no.length && b && b.mob_no && b.mob_no.length) {
    //         //         return a.user_name.localeCompare(b.mob_no);
    //         //     }else if(a && a.mob_no && a.mob_no.length) {
    //         //         // That means be has null division_name, so a will come first.
    //         //         return -1;
    //         //     } else if(b && b.mob_no && b.mob_no.length) {
    //         //         // That means a has null division_name so b will come first.
    //         //         return 1;
    //         //     }

    //         //     // Both division_name has null value so there will be no order change.
    //         //     return 0;
    //         // },
    //         // sortOrder: sortedInfo.columnKey === 'mob_no' ? sortedInfo.order : null,
    //         sortOrder: tableSortOrders[prevLe] && tableSortOrders[prevLe].columnKey === 'mob_no' && tableSortOrders[prevLe].order,
    //     },

    //     {
    //         title: <label>Replace</label>,
    //         dataIndex: 'is_active',
    //         key: 'is_active',
    //         align: 'center',
    //         width: '20px',
    //         // filteredValue: filteredInfo.mob_no || null,
    //         // sorter: (a, b) => {
    //         //     if (a && a.is_active && a.is_active.length && b && b.is_active && b.is_active.length) {
    //         //         return  Number(a.is_active) - Number(b.is_active)
    //         //     } else if (a && a.is_active && a.is_active.length) {
    //         //         // That means be has null division_name, so a will come first.
    //         //         return -1;
    //         //     } else if (b && b.is_active && b.is_active.length) {
    //         //         // That means a has null division_name so b will come first.
    //         //         return 1;
    //         //     }

    //         //     // Both division_name has null value so there will be no order change.
    //         //     return 0;
    //         // },
    //         sorter: (a, b) => prevLe == a.le_id ? Number(a.is_active) - Number(b.is_active) : false,
    //         // sortOrder: sortedInfo.columnKey === 'is_active' ? sortedInfo.order : null,
    //         sortOrder: tableSortOrders[prevLe] && tableSortOrders[prevLe].columnKey === 'is_active' && tableSortOrders[prevLe].order,

    //         render: (text, record) => {
    //             console.log(record, 'recordlist');
    //             if (((loginResponse && loginResponse.usr_cat_id == 1) && ((localStorage.getItem("SelectedEntity") == 'All Legal Entity') || entity_info && entity_info.length == 1))) {
    //                 return (
    //                     <Fragment className='row hover '>
    //                         {record.is_active == true && record.is_disable == 0 ?

    //                             <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
    //                             align-items-center justify-content-center margin0"
    //                                 onClick={(e) => {
    //                                     setExistingUserData(record)
    //                                     setExistingUser(true)
    //                                 }}>
    //                                 <Tooltip title="Click Here to Replace" >
    //                                     <RetweetOutlined /></Tooltip>
    //                                 {/* <i className="ri-edit-line"></i> */}
    //                             </span> : false}
    //                     </Fragment>
    //                 )
    //             }
    //             else {
    //                 return false
    //             }
    //         },
    //         sorter: (a, b) => a.is_active - b.is_active,
    //         sortOrder: sortedInfo.columnKey === 'is_active' ? sortedInfo.order : null,

    //     },
    //     {
    //         title: <><label>Actions</label><Tooltip placement="topRight" title={"Edit,Status,Disable"}><InfoCircleOutlined /></Tooltip></>,
    //         dataIndex: 'is_active',
    //         width: '40px',
    //         filters: [
    //             {
    //                 text: 'Active',
    //                 value: true,
    //             },
    //             {
    //                 text: 'Inactive',
    //                 value: false,
    //             },
    //         ],

    //         filteredValue: filteredInfo.is_active,
    //         onFilter: (value, record) => record.is_active == value,
    //         filterSearch: false,
    //         render: (text, record) => {
    //             console.log(record, 'recordlist');
    //             return (
    //                 <Fragment>
    //                     <div style={{ width: '95%', margin: '0 auto' }} className='row hover'>
    //                         <div className='col-4 d-flex justify-content-center'>
    //                             {record.is_disable === 0 ?
    //                                 <div>
    //                                     <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
    //                                         onClick={(e) => { editUser(record.user_id, record) }}>
    //                                         <Tooltip title="Click Here to Edit">
    //                                             <EditOutlined /></Tooltip>
    //                                         {/* <i className="ri-edit-line"></i> */}
    //                                     </span>
    //                                 </div> :
    //                                 <div >
    //                                     <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center">
    //                                         <EditOutlined style={{ cursor: 'not-allowed' }}></EditOutlined>
    //                                     </span>
    //                                 </div>
    //                             }
    //                         </div>
    //                         <div className='col-4 d-flex justify-content-center px-2'>
    //                             {
    //                                 record.is_disable === 0 ?
    //                                     <div >
    //                                         {record.is_active == true ?
    //                                             <span className="social-link btn btn-sm btn-light-success
    //                                             text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { ActivateById(record, "active") }}>
    //                                                 {/* text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record, "active") }} */}
    //                                                 <Tooltip title="Click here to Deactivate" ><LikeOutlined /></Tooltip>
    //                                             </span> :
    //                                             <span className="social-link btn btn-sm btn-light-danger
    //                                             text-danger btn-circle d-flex align-items-center justify-content-center"  onClick={(e) => { ActivateById(record, "deactive") }}>
    //                                                 <Tooltip title="Click here to Activate"><DislikeOutlined /></Tooltip>
    //                                             </span>
    //                                         }
    //                                     </div> :
    //                                     <div>
    //                                         {record.is_active == true ?
    //                                             <span className="social-link btn btn-sm btn-light-success
    //                                             text-success btn-circle d-flex align-items-center justify-content-center" >
    //                                                 <Tooltip title="" color='#39cb7f'><LikeOutlined /></Tooltip>
    //                                             </span>
    //                                             :
    //                                             <span className="social-link btn btn-sm btn-light-danger
    //                                             text-danger btn-circle d-flex align-items-center justify-content-center" >
    //                                                 <Tooltip title="De-Active" color='#fc4b6c'><DislikeOutlined /></Tooltip>
    //                                             </span>


    //                                         }
    //                                     </div>

    //                             }
    //                         </div>
    //                         <div className='col-4 d-flex justify-content-center'>
    //                             {record.is_disable === 0 ?
    //                                 <span className="social-link btn btn-sm btn-light-success
    //                                 text-success btn-circle d-flex align-items-center justify-content-center" onClick={() => {
    //                                         setShow(true)
    //                                         DisableById(record.user_id, "disable", record.le_ids, record);
    //                                     }}>
    //                                     <Tooltip placement='topLeft' title="Click here to Disable" ><CheckOutlined /></Tooltip>
    //                                 </span>
    //                                 :
    //                                 <div>
    //                                     {record.unblock_days === 0 ? '' :
    //                                         <span className="social-link btn btn-sm btn-light-danger
    //                                         text-danger btn-circle d-flex align-items-center justify-content-center" onClick={() => {
    //                                                 setShow(true)
    //                                                 DisableById(record.user_id, "unDisable", record.le_ids, record);
    //                                             }}>
    //                                             <Tooltip placement='topLeft' title={record.unblock_days + '-' + 'Days Left'} color='#fc4b6c'><StopOutlined /></Tooltip>
    //                                         </span>
    //                                     }
    //                                 </div>
    //                             }
    //                         </div>
    //                     </div>
    //                 </Fragment>
    //             )
    //         }
    //     }
    // ];
    const columns2 = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '18px',
            render: (text, record, index) => {
                return index + 1
            }
        },


        {
            title: 'Users',
            dataIndex: 'emp_name',
            key: 'emp_name',
            width: '75px',
            ellipsis: true,
            ...getColumnSearchProps('emp_name', 'Users'),
            filteredValue: filteredInfo.emp_name || null,
            // sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
            sorter: (a, b) => {
                // if (particularLeId[a.le_id] == a.le_id) {
                if (a && a.emp_name && a.emp_name.length && b && b.emp_name && b.emp_name.length) {
                    return a.emp_name.localeCompare(b.emp_name);
                } else if (a && a.emp_name && a.emp_name.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.emp_name && b.emp_name.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
                // }
            },
            sortOrder: sortedInfo.columnKey === 'emp_name' ? sortedInfo.order : null,
            render: (row, record) => {
                console.log(row, 'row12344948');
                return (
                    <Fragment>
                        {
                            ((record.user_name == null) && (record.is_disable == 0)) ?
                                // <p>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} /></p>


                                <><Tooltip title="Click here to resend email" > <span>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} onClick={() => { resentEmailRegistration(record) }} /></span></Tooltip>
                                    <Tooltip title={
                                        'compfie'
                                    }>  {record.u_cat_id == null ? <StopOutlined style={{ color: 'red' }} /> :
                                        <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.u_cat_id == 2 ? "#C9C9C9" : record.u_cat_id == 3 ? '#f7bf17' : record.u_cat_id == 5 ? "#6ecd2c" : record.u_cat_id == 1 ? "#FF6666" : record.u_cat_id == 6 ? "#C6E0B4" : record.u_cat_id == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}
                                    </Tooltip>
                                    <Tooltip title={
                                        "Vendor"
                                    }> {record.vndr_user_category == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.vndr_user_category == 2 ? "#C9C9C9" : record.vndr_user_category == 3 ? '#f7bf17' : record.vndr_user_category == 5 ? "#6ecd2c" : record.vndr_user_category == 1 ? "#FF6666" : record.vndr_user_category == 6 ? "#C6E0B4" : record.vndr_user_category == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}</Tooltip>

                                    <Tooltip title={
                                        "Litigation"
                                    }>  {record.u_type_id == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_type_id == 2 ? "#FFE699" : record.u_type_id == 3 ? "#a1b793" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
                                </>

                                : <span>{record.emp_name}
                                    <Tooltip title={
                                        'compfie'
                                    }>  {record.u_cat_id == null ? <StopOutlined style={{ color: 'red' }} /> :
                                        <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.u_cat_id == 2 ? "#C9C9C9" : record.u_cat_id == 3 ? '#f7bf17' : record.u_cat_id == 5 ? "#6ecd2c" : record.u_cat_id == 1 ? "#FF6666" : record.u_cat_id == 6 ? "#C6E0B4" : record.u_cat_id == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}
                                    </Tooltip>
                                    <Tooltip
                                        title={
                                            "Vendor"
                                        }
                                    > {record.vndr_user_category == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.vndr_user_category == 4 ? "#FFE699" : record.vndr_user_category == 2 ? "#C9C9C9" : record.vndr_user_category == 3 ? '#f7bf17' : record.vndr_user_category == 5 ? "#6ecd2c" : record.vndr_user_category == 1 ? "#FF6666" : record.vndr_user_category == 6 ? "#C6E0B4" : record.vndr_user_category == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
                                    <Tooltip title={
                                        "Litigation"
                                    }>  {record.u_type_id == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_type_id == 2 ? "#FFE699" : record.u_type_id == 3 ? "#a1b793" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
                                </span>
                        }

                    </Fragment>



                )
            }

        },
        {
            title: 'User ID',
            dataIndex: 'user_name_new',
            key: 'user_name_new',
            width: '60px',
            // ellipsis: true,
            ...getColumnSearchProps('user_name_new', 'User ID'),
            filteredValue: filteredInfo.user_name_new || null,
            sorter: (a, b) => {
                console.log(a.le_id, 'a.le_id');
                // if (particularLeId[a.le_id] == a.le_id) {
                if (a && a.user_name_new && a.user_name_new.length && b && b.user_name_new && b.user_name_new.length) {
                    return a.user_name_new.localeCompare(b.user_name_new);
                } else if (a && a.user_name_new && a.user_name_new.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.user_name_new && b.user_name_new.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
                // }
            },
            // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
            sortOrder: sortedInfo.columnKey === 'user_name_new' ? sortedInfo.order : null,

        },
        {
            title: 'Email ID',
            dataIndex: 'email_id',
            width: '85px',
            key: 'email_id',
            ...getColumnSearchProps('email_id', 'Email ID'),
            ellipsis: true,
            filteredValue: filteredInfo.email_id || null,
            sorter: (a, b) => {
                // if (particularLeId[a.le_id] == a.le_id) {
                if (a && a.email_id && a.email_id.length && b && b.email_id && b.email_id.length) {
                    return a.email_id.localeCompare(b.email_id);
                } else if (a && a.email_id && a.email_id.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.email_id && b.email_id.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
                // }
            },
            // sorter: (a, b) => a.email_id && b.email_id != null ? a.email_id.localeCompare(b.email_id) : "",
            sortOrder: sortedInfo.columnKey === 'email_id' ? sortedInfo.order : null,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mob_no',
            key: 'mob_no',
            ...getColumnSearchProps('mob_no', 'Mobile Number'),
            width: '35px',
            // ellipsis: true
            filteredValue: filteredInfo.mob_no || null,
            sorter: (a, b) => a.mob_no.localeCompare(b.mob_no),
            // sorter: (a, b) => {

            //     if(a && a.mob_no && a.mob_no.length && b && b.mob_no && b.mob_no.length) {
            //         return a.user_name.localeCompare(b.mob_no);
            //     }else if(a && a.mob_no && a.mob_no.length) {
            //         // That means be has null division_name, so a will come first.
            //         return -1;
            //     } else if(b && b.mob_no && b.mob_no.length) {
            //         // That means a has null division_name so b will come first.
            //         return 1;
            //     }

            //     // Both division_name has null value so there will be no order change.
            //     return 0;
            // },
            sortOrder: sortedInfo.columnKey === 'mob_no' ? sortedInfo.order : null,
        },
        // {
        //     title: 'Registration Email',
        //     dataIndex: 'user_name',
        //     key: 'user_name',
        //     width: '55px',
        //     align: 'center',
        //     // ellipsis: true,
        //     filteredValue: filteredInfo.user_name || null,
        //     sorter: (a, b) => {

        //         if (a && a.user_name && a.user_name.length && b && b.user_name && b.user_name.length) {
        //             return a.user_name.localeCompare(b.user_name);
        //         } else if (a && a.user_name && a.user_name.length) {
        //             // That means be has null division_name, so a will come first.
        //             return -1;
        //         } else if (b && b.user_name && b.user_name.length) {
        //             // That means a has null division_name so b will come first.
        //             return 1;
        //         }

        //         // Both division_name has null value so there will be no order change.
        //         return 0;
        //     },
        //     // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
        //     sortOrder: sortedInfo.columnKey === 'user_name' ? sortedInfo.order : null,
        //     render: (row, record) => {
        //         return (
        //             <Fragment>
        //                 {
        //                     ((row == null) && (record.is_disable == 0)) ?
        //                         <Tooltip title="Click here to resend email" ><Link onClick={() => { resentEmailRegistration(record) }}>Resend</Link></Tooltip>

        //                         : ''
        //                 }
        //             </Fragment>



        //         )
        //     }
        // },
        {
            title: <label>Replace</label>,
            dataIndex: 'is_active',
            key: 'is_active',
            align: 'center',
            // sorter: (a, b) => {
            //     if (a && a.is_active && a.is_active.length && b && b.is_active && b.is_active.length) {
            //         return  Number(a.is_active) - Number(b.is_active)
            //     } else if (a && a.is_active && a.is_active.length) {
            //         // That means be has null division_name, so a will come first.
            //         return -1;
            //     } else if (b && b.is_active && b.is_active.length) {
            //         // That means a has null division_name so b will come first.
            //         return 1;
            //     }

            //     // Both division_name has null value so there will be no order change.
            //     return 0;
            // },
            sorter: (a, b) => Number(a.is_active) - Number(b.is_active),
            sortOrder: sortedInfo.columnKey === 'is_active' ? sortedInfo.order : null,
            width: '20px',
            render: (text, record) => {
                console.log(record, 'recordlist');
                if (((loginResponse && loginResponse.usr_cat_id == 1) && ((localStorage.getItem("SelectedEntity") == 'All Legal Entity') || entity_info && entity_info.length == 1))) {
                    return (
                        <Fragment className='row hover '>
                            {record.is_active == true && record.is_disable == 0 ?

                                <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
                            align-items-center justify-content-center margin0"
                                    onClick={(e) => {
                                        setExistingUserData(record)
                                        setExistingUser(true)
                                    }}>
                                    <Tooltip title="Click Here to Replace">
                                        <RetweetOutlined /></Tooltip>
                                    {/* <i className="ri-edit-line"></i> */}
                                </span> : false}
                        </Fragment>
                    )
                }
                else {
                    return false
                }
            }
        },
        {
            title: <><label>Actions</label><Tooltip placement="topRight" title={"Edit,Status,Disable"}><InfoCircleOutlined /></Tooltip></>,
            dataIndex: 'is_active',
            width: '40px',
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                },
            ],

            filteredValue: filteredInfo.is_active,
            onFilter: (value, record) => record.le_id == particularLeId ? record.is_active == value : record,
            filterSearch: false,
            render: (text, record) => {
                console.log(record, 'recordlist');
                return (
                    <Fragment>
                        <div style={{ width: '95%', margin: '0 auto' }} className='row hover'>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <div>
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { editUser(record.user_id, record) }}>
                                            <Tooltip title="Click Here to Edit" color='blue'>
                                                <EditOutlined /></Tooltip>
                                            {/* <i className="ri-edit-line"></i> */}
                                        </span>
                                    </div> :
                                    <div >
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center">
                                            <EditOutlined style={{ cursor: 'not-allowed' }}></EditOutlined>
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center px-2'>
                                {
                                    record.is_disable === 0 ?
                                        <div >
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { ActivateById(record, "active") }}>
                                                    {/* text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record, "active") }} */}
                                                    <Tooltip title="Click here to Deactivate"><LikeOutlined /></Tooltip>
                                                </span> :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center"  onClick={(e) => { ActivateById(record, "deactive") }}>
                                                    <Tooltip title="Click here to Activate"><DislikeOutlined /></Tooltip>
                                                </span>
                                            }
                                        </div> :
                                        <div>
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span>
                                                :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="De-Active" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                </span>


                                            }
                                        </div>

                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <span className="social-link btn btn-sm btn-light-success
                                    text-success btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                            setShow(true)
                                            DisableById(record.user_id, "disable", record.le_ids, record);
                                        }}>
                                        <Tooltip placement='topLeft' title="Click here to Disable" ><CheckOutlined /></Tooltip>
                                    </span>
                                    :
                                    <div>
                                        {record.unblock_days === 0 ? '' :
                                            <span className="social-link btn btn-sm btn-light-danger
                                            text-danger btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                                    setShow(true)
                                                    DisableById(record.user_id, "unDisable", record.le_ids, record);
                                                }}>
                                                <Tooltip placement='topLeft' title={record.unblock_days + '-' + 'Days Left'} color='#fc4b6c'><StopOutlined /></Tooltip>
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }
    ];
    const columns1 = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '18px',
            render: (text, record, index) => {
                return index + 1
            }
        },


        {
            title: 'Users',
            dataIndex: 'emp_name',
            key: 'emp_name',
            width: '75px',
            ellipsis: true,
            ...getColumnSearchProps('emp_name', 'Users'),
            filteredValue: filteredInfo.emp_name || null,
            // sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
            sorter: (a, b) => {
                if (particularLeId[a.le_id] == a.le_id) {
                    if (a && a.emp_name && a.emp_name.length && b && b.emp_name && b.emp_name.length) {
                        return a.emp_name.localeCompare(b.emp_name);
                    } else if (a && a.emp_name && a.emp_name.length) {
                        // That means be has null division_name, so a will come first.
                        return -1;
                    } else if (b && b.emp_name && b.emp_name.length) {
                        // That means a has null division_name so b will come first.
                        return 1;
                    }

                    // Both division_name has null value so there will be no order change.
                    return 0;
                }
            },
            sortOrder: tableSortOrders[prevLe] && tableSortOrders[prevLe].columnKey === 'emp_name' && tableSortOrders[prevLe].order,
            render: (row, record) => {
                console.log(row, 'row12344948');
                return (
                    <Fragment>
                        {
                            ((record.user_name == null) && (record.is_disable == 0)) ?
                                // <p>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} /></p>


                                <><Tooltip title="Click here to resend email" > <span>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} onClick={() => { resentEmailRegistration(record) }} /></span></Tooltip>
                                    <Tooltip title={
                                        'compfie'
                                    }>  {record.u_cat_id == null ? <StopOutlined style={{ color: 'red' }} /> :
                                        <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.u_cat_id == 2 ? "#C9C9C9" : record.u_cat_id == 3 ? '#f7bf17' : record.u_cat_id == 5 ? "#6ecd2c" : record.u_cat_id == 1 ? "#FF6666" : record.u_cat_id == 6 ? "#C6E0B4" : record.u_cat_id == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}
                                    </Tooltip>
                                    <Tooltip title={
                                        "Vendor"
                                    }> {record.vndr_user_category == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.vndr_user_category == 2 ? "#C9C9C9" : record.vndr_user_category == 3 ? '#f7bf17' : record.vndr_user_category == 5 ? "#6ecd2c" : record.vndr_user_category == 1 ? "#FF6666" : record.vndr_user_category == 6 ? "#C6E0B4" : record.vndr_user_category == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}</Tooltip>

                                    <Tooltip title={
                                        "Litigation"
                                    }>  {record.u_type_id == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_type_id == 2 ? "#FFE699" : record.u_type_id == 3 ? "#a1b793" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
                                </>

                                : <span>{record.emp_name}
                                    <Tooltip title={
                                        'compfie'
                                    }>  {record.u_cat_id == null ? <StopOutlined style={{ color: 'red' }} /> :
                                        <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.u_cat_id == 2 ? "#C9C9C9" : record.u_cat_id == 3 ? '#f7bf17' : record.u_cat_id == 5 ? "#6ecd2c" : record.u_cat_id == 1 ? "#FF6666" : record.u_cat_id == 6 ? "#C6E0B4" : record.u_cat_id == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}
                                    </Tooltip>
                                    <Tooltip
                                        title={
                                            "Vendor"
                                        }
                                    > {record.vndr_user_category == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.vndr_user_category == 4 ? "#FFE699" : record.vndr_user_category == 2 ? "#C9C9C9" : record.vndr_user_category == 3 ? '#f7bf17' : record.vndr_user_category == 5 ? "#6ecd2c" : record.vndr_user_category == 1 ? "#FF6666" : record.vndr_user_category == 6 ? "#C6E0B4" : record.vndr_user_category == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
                                    <Tooltip title={
                                        "Litigation"
                                    }>  {record.u_type_id == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_type_id == 2 ? "#FFE699" : record.u_type_id == 3 ? "#a1b793" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
                                </span>
                        }

                    </Fragment>



                )
            }
        },
        {
            title: 'User ID',
            dataIndex: 'user_name_new',
            key: 'user_name_new',
            width: '60px',
            // ellipsis: true,
            ...getColumnSearchProps('user_name_new', 'User ID'),
            filteredValue: filteredInfo.user_name_new || null,
            sorter: (a, b) => {
                console.log(a.le_id, 'a.le_id');
                if (particularLeId[a.le_id] == a.le_id) {
                    if (a && a.user_name_new && a.user_name_new.length && b && b.user_name_new && b.user_name_new.length) {
                        return a.user_name_new.localeCompare(b.user_name_new);
                    } else if (a && a.user_name_new && a.user_name_new.length) {
                        // That means be has null division_name, so a will come first.
                        return -1;
                    } else if (b && b.user_name_new && b.user_name_new.length) {
                        // That means a has null division_name so b will come first.
                        return 1;
                    }

                    // Both division_name has null value so there will be no order change.
                    return 0;
                }
            },
            // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
            sortOrder: sortedInfo.columnKey === 'user_name_new' ? sortedInfo.order : null,

        },
        {
            title: 'Email ID',
            dataIndex: 'email_id',
            width: '85px',
            key: 'email_id',
            ...getColumnSearchProps('email_id', 'Email ID'),
            ellipsis: true,
            filteredValue: filteredInfo.email_id || null,
            sorter: (a, b) => {
                if (particularLeId[a.le_id] == a.le_id) {
                    if (a && a.email_id && a.email_id.length && b && b.email_id && b.email_id.length) {
                        return a.email_id.localeCompare(b.email_id);
                    } else if (a && a.email_id && a.email_id.length) {
                        // That means be has null division_name, so a will come first.
                        return -1;
                    } else if (b && b.email_id && b.email_id.length) {
                        // That means a has null division_name so b will come first.
                        return 1;
                    }

                    // Both division_name has null value so there will be no order change.
                    return 0;
                }
            },
            // sorter: (a, b) => a.email_id && b.email_id != null ? a.email_id.localeCompare(b.email_id) : "",
            sortOrder: sortedInfo.columnKey === 'email_id' ? sortedInfo.order : null,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mob_no',
            key: 'mob_no',
            ...getColumnSearchProps('mob_no', 'Mobile Number'),
            width: '35px',
            // ellipsis: true
            filteredValue: filteredInfo.mob_no || null,
            sorter: (a, b) => particularLeId[a.le_id] == a.le_id ? a.mob_no.localeCompare(b.mob_no) : false,
            // sorter: (a, b) => {

            //     if(a && a.mob_no && a.mob_no.length && b && b.mob_no && b.mob_no.length) {
            //         return a.user_name.localeCompare(b.mob_no);
            //     }else if(a && a.mob_no && a.mob_no.length) {
            //         // That means be has null division_name, so a will come first.
            //         return -1;
            //     } else if(b && b.mob_no && b.mob_no.length) {
            //         // That means a has null division_name so b will come first.
            //         return 1;
            //     }

            //     // Both division_name has null value so there will be no order change.
            //     return 0;
            // },
            sortOrder: sortedInfo.columnKey === 'mob_no' ? sortedInfo.order : null,
        },
        // {
        //     title: 'Registration Email',
        //     dataIndex: 'user_name',
        //     key: 'user_name',
        //     width: '55px',
        //     align: 'center',
        //     // ellipsis: true,
        //     filteredValue: filteredInfo.user_name || null,
        //     sorter: (a, b) => {

        //         if (a && a.user_name && a.user_name.length && b && b.user_name && b.user_name.length) {
        //             return a.user_name.localeCompare(b.user_name);
        //         } else if (a && a.user_name && a.user_name.length) {
        //             // That means be has null division_name, so a will come first.
        //             return -1;
        //         } else if (b && b.user_name && b.user_name.length) {
        //             // That means a has null division_name so b will come first.
        //             return 1;
        //         }

        //         // Both division_name has null value so there will be no order change.
        //         return 0;
        //     },
        //     // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
        //     sortOrder: sortedInfo.columnKey === 'user_name' ? sortedInfo.order : null,
        //     render: (row, record) => {
        //         return (
        //             <Fragment>
        //                 {
        //                     ((row == null) && (record.is_disable == 0)) ?
        //                         <Tooltip title="Click here to resend email" ><Link onClick={() => { resentEmailRegistration(record) }}>Resend</Link></Tooltip>

        //                         : ''
        //                 }
        //             </Fragment>



        //         )
        //     }
        // },
        {
            title: <label>Replace</label>,
            dataIndex: 'is_active',
            key: 'is_active',
            align: 'center',
            width: '20px',

            sorter: (a, b) => particularLeId[a.le_id] == a.le_id ? Number(a.is_active) - Number(b.is_active) : false,
            sortOrder: sortedInfo.columnKey === 'is_active' ? sortedInfo.order : null,
            render: (text, record) => {
                console.log(record, 'recordlist');
                if (((loginResponse && loginResponse.usr_cat_id == 1) && ((localStorage.getItem("SelectedEntity") == 'All Legal Entity') || entity_info && entity_info.length == 1))) {
                    return (
                        <Fragment className='row hover '>
                            {record.is_active == true && record.is_disable == 0 ?

                                <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
                            align-items-center justify-content-center margin0"
                                    onClick={(e) => {
                                        setExistingUserData(record)
                                        setExistingUser(true)
                                    }}>
                                    <Tooltip title="Click Here to Replace">
                                        <RetweetOutlined /></Tooltip>
                                    {/* <i className="ri-edit-line"></i> */}
                                </span> : false}
                        </Fragment>
                    )
                }
                else {
                    return false
                }
            }
        },
        {
            title: <><label>Actions</label><Tooltip placement="topRight" title={"Edit,Status,Disable"}><InfoCircleOutlined /></Tooltip></>,
            dataIndex: 'is_active',
            width: '40px',
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                },
            ],

            filteredValue: filteredInfo.is_active,
            onFilter: (value, record) => record.is_active == value,
            filterSearch: false,
            render: (text, record) => {
                console.log(record, 'recordlist');
                return (
                    <Fragment>
                        <div style={{ width: '95%', margin: '0 auto' }} className='row hover'>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <div>
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { editUser(record.user_id, record) }}>
                                            <Tooltip title="Click Here to Edit">
                                                <EditOutlined /></Tooltip>
                                            {/* <i className="ri-edit-line"></i> */}
                                        </span>
                                    </div> :
                                    <div >
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center">
                                            <EditOutlined style={{ cursor: 'not-allowed' }}></EditOutlined>
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center px-2'>
                                {
                                    record.is_disable === 0 ?
                                        <div >
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { ActivateById(record, "active") }}>
                                                    {/* text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record, "active") }} */}
                                                    <Tooltip title="Click here to Deactivate" ><LikeOutlined /></Tooltip>
                                                </span> :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center"  onClick={(e) => { ActivateById(record, "deactive") }}>
                                                    <Tooltip title="Click here to Activate" ><DislikeOutlined /></Tooltip>
                                                </span>
                                            }
                                        </div> :
                                        <div>
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span>
                                                :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="De-Active" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                </span>


                                            }
                                        </div>

                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <span className="social-link btn btn-sm btn-light-success
                                    text-success btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                            setShow(true)
                                            DisableById(record.user_id, "disable", record.le_ids, record);
                                        }}>
                                        <Tooltip placement='topLeft' title="Click here to Disable" ><CheckOutlined /></Tooltip>
                                    </span>
                                    :
                                    <div>
                                        {record.unblock_days === 0 ? '' :
                                            <span className="social-link btn btn-sm btn-light-danger
                                            text-danger btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                                    setShow(true)
                                                    DisableById(record.user_id, "unDisable", record.le_ids, record);
                                                }}>
                                                <Tooltip placement='topLeft' title={record.unblock_days + '-' + 'Days Left'} color='#fc4b6c'><StopOutlined /></Tooltip>
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }
    ];
    const columns3 = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '18px',
            render: (text, record, index) => {
                return index + 1
            }
        },
        // {
        //     title: 'Vendor Category',
        //     dataIndex: 'vndr_user_category',
        //     width: '37px',
        //     align: 'center',
        //     filters: [
        //         {
        //             text: <span><i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> View Only</span>,
        //             value: 2,
        //         },
        //         {
        //             text: <span><i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> Legal Entity Admin</span>,
        //             value: 3,
        //         },
        //         {
        //             text: <span><i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i> Domain Admin</span>,
        //             value: 4,
        //         },
        //         {
        //             text: <span><i style={{ color: "#FF6666" }} className="bi bi-square-fill"></i> Inhouse User</span>,
        //             value: 5,
        //         },
        //         {
        //             text: <span><i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> Service Provider User</span>,
        //             value: 6,
        //         },
        //         {
        //             text: <span><i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> View Only Restricted</span>,
        //             value: 7,
        //         },
        //         {
        //             text: <span><i style={{ color: "#00FFFF" }} className="bi bi-square-fill"></i> Litigation User
        //             </span>,
        //             value: 1,
        //         },
        //         {
        //             text: <span><i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> Not Access
        //             </span>,
        //             value: null,
        //         },
        //     ],

        //     filteredValue: filteredInfo.vndr_user_category,
        //     onFilter: (value, record) => record.vndr_user_category == value,
        //     // onFilter: (value, record) => record.is_active === value,
        //     filterSearch: false,
        //     render: (row, record) => {
        //         console.log(record, 'rowworrowwor')
        //         return (
        //             <Fragment>
        //                 {
        //                     (row == 4 ? <i style={{ color: "#45b0e2" }} className="bi bi-square-fill"></i>
        //                         : (row == 3 ? <i style={{ color: "#fbca35" }} className="bi bi-square-fill"></i> :
        //                             (row == 5 ? <i style={{ color: "#e96154" }} className="bi bi-square-fill"></i> :
        //                                 (row == 2 ? <i style={{ color: "#9a9da0" }} className="bi bi-square-fill"></i> :
        //                                     (row == 6 ? <i style={{ color: "#60667a" }} className="bi bi-square-fill"></i> :
        //                                         row == 7 ? <i style={{ color: "#b29999" }} className="bi bi-square-fill"></i> :
        //                                             row == 1 ? <i style={{ color: "#FF6666" }} className="bi bi-square-file"></i> :
        //                                                 row == null ? <i style={{ color: "#ff8acc" }} className="bi bi-square-fill"></i> : false)))))
        //                 }
        //             </Fragment>)
        //     },

        // },
        {
            title: 'Users',
            dataIndex: 'emp_name',
            key: 'emp_name',
            width: '75px',
            ellipsis: true,
            ...getColumnSearchProps('emp_name', 'Users'),
            filteredValue: filteredInfo.emp_name || null,
            // sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
            sorter: (a, b) => {
                if (particularLeId[a.le_id] == a.le_id) {
                    if (a && a.emp_name && a.emp_name.length && b && b.emp_name && b.emp_name.length) {
                        return a.emp_name.localeCompare(b.emp_name);
                    } else if (a && a.emp_name && a.emp_name.length) {
                        // That means be has null division_name, so a will come first.
                        return -1;
                    } else if (b && b.emp_name && b.emp_name.length) {
                        // That means a has null division_name so b will come first.
                        return 1;
                    }

                    // Both division_name has null value so there will be no order change.
                    return 0;
                }
            },
            sortOrder: tableSortOrders[prevLe] && tableSortOrders[prevLe].columnKey === 'emp_name' && tableSortOrders[prevLe].order,
            render: (row, record) => {
                console.log(row, 'row12344948');
                return (
                    <Fragment>
                        {
                            ((record.user_name == null) && (record.is_disable == 0)) ?
                                // <p>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} /></p>


                                <><Tooltip title="Click here to resend email" > <span>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} onClick={() => { resentEmailRegistration(record) }} /></span></Tooltip>
                                    <Tooltip title={
                                        'compfie'
                                    }>  {record.u_cat_id == null ? <StopOutlined style={{ color: 'red' }} /> :
                                        <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.u_cat_id == 2 ? "#C9C9C9" : record.u_cat_id == 3 ? '#f7bf17' : record.u_cat_id == 5 ? "#6ecd2c" : record.u_cat_id == 1 ? "#FF6666" : record.u_cat_id == 6 ? "#C6E0B4" : record.u_cat_id == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}
                                    </Tooltip>
                                    <Tooltip title={
                                        "Vendor"
                                    }> {record.vndr_user_category == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.vndr_user_category == 2 ? "#C9C9C9" : record.vndr_user_category == 3 ? '#f7bf17' : record.vndr_user_category == 5 ? "#6ecd2c" : record.vndr_user_category == 1 ? "#FF6666" : record.vndr_user_category == 6 ? "#C6E0B4" : record.vndr_user_category == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}</Tooltip>

                                    <Tooltip title={
                                        "Litigation"
                                    }>  {record.u_type_id == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_type_id == 2 ? "#FFE699" : record.u_type_id == 3 ? "#a1b793" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
                                </>

                                : <span>{record.emp_name}
                                    <Tooltip title={
                                        'compfie'
                                    }>  {record.u_cat_id == null ? <StopOutlined style={{ color: 'red' }} /> :
                                        <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.u_cat_id == 2 ? "#C9C9C9" : record.u_cat_id == 3 ? '#f7bf17' : record.u_cat_id == 5 ? "#6ecd2c" : record.u_cat_id == 1 ? "#FF6666" : record.u_cat_id == 6 ? "#C6E0B4" : record.u_cat_id == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}
                                    </Tooltip>
                                    <Tooltip
                                        title={
                                            "Vendor"
                                        }
                                    > {record.vndr_user_category == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.vndr_user_category == 4 ? "#FFE699" : record.vndr_user_category == 2 ? "#C9C9C9" : record.vndr_user_category == 3 ? '#f7bf17' : record.vndr_user_category == 5 ? "#6ecd2c" : record.vndr_user_category == 1 ? "#FF6666" : record.vndr_user_category == 6 ? "#C6E0B4" : record.vndr_user_category == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
                                    <Tooltip title={
                                        "Litigation"
                                    }>  {record.u_type_id == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_type_id == 2 ? "#FFE699" : record.u_type_id == 3 ? "#a1b793" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
                                </span>
                        }

                    </Fragment>



                )
            }
        },
        {
            title: 'User ID',
            dataIndex: 'user_name_new',
            key: 'user_name_new',
            width: '60px',
            // ellipsis: true,
            ...getColumnSearchProps('user_name_new', 'User ID'),
            filteredValue: filteredInfo.user_name_new || null,
            sorter: (a, b) => {
                console.log(a.le_id, 'a.le_id');
                if (particularLeId[a.le_id] == a.le_id) {
                    if (a && a.user_name_new && a.user_name_new.length && b && b.user_name_new && b.user_name_new.length) {
                        return a.user_name_new.localeCompare(b.user_name_new);
                    } else if (a && a.user_name_new && a.user_name_new.length) {
                        // That means be has null division_name, so a will come first.
                        return -1;
                    } else if (b && b.user_name_new && b.user_name_new.length) {
                        // That means a has null division_name so b will come first.
                        return 1;
                    }

                    // Both division_name has null value so there will be no order change.
                    return 0;
                }
            },
            // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
            sortOrder: sortedInfo.columnKey === 'user_name_new' ? sortedInfo.order : null,

        },
        {
            title: 'Email ID',
            dataIndex: 'email_id',
            width: '85px',
            key: 'email_id',
            ...getColumnSearchProps('email_id', 'Email ID'),
            ellipsis: true,
            filteredValue: filteredInfo.email_id || null,
            sorter: (a, b) => {
                if (particularLeId[a.le_id] == a.le_id) {
                    if (a && a.email_id && a.email_id.length && b && b.email_id && b.email_id.length) {
                        return a.email_id.localeCompare(b.email_id);
                    } else if (a && a.email_id && a.email_id.length) {
                        // That means be has null division_name, so a will come first.
                        return -1;
                    } else if (b && b.email_id && b.email_id.length) {
                        // That means a has null division_name so b will come first.
                        return 1;
                    }

                    // Both division_name has null value so there will be no order change.
                    return 0;
                }
            },
            // sorter: (a, b) => a.email_id && b.email_id != null ? a.email_id.localeCompare(b.email_id) : "",
            sortOrder: sortedInfo.columnKey === 'email_id' ? sortedInfo.order : null,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mob_no',
            key: 'mob_no',
            ...getColumnSearchProps('mob_no', 'Mobile Number'),
            width: '35px',
            // ellipsis: true
            filteredValue: filteredInfo.mob_no || null,
            sorter: (a, b) => particularLeId[a.le_id] == a.le_id ? a.mob_no.localeCompare(b.mob_no) : false,
            // sorter: (a, b) => {

            //     if(a && a.mob_no && a.mob_no.length && b && b.mob_no && b.mob_no.length) {
            //         return a.user_name.localeCompare(b.mob_no);
            //     }else if(a && a.mob_no && a.mob_no.length) {
            //         // That means be has null division_name, so a will come first.
            //         return -1;
            //     } else if(b && b.mob_no && b.mob_no.length) {
            //         // That means a has null division_name so b will come first.
            //         return 1;
            //     }

            //     // Both division_name has null value so there will be no order change.
            //     return 0;
            // },
            sortOrder: sortedInfo.columnKey === 'mob_no' ? sortedInfo.order : null,
        },
        // {
        //     title: 'Registration Email',
        //     dataIndex: 'user_name',
        //     key: 'user_name',
        //     width: '55px',
        //     align: 'center',
        //     // ellipsis: true,
        //     filteredValue: filteredInfo.user_name || null,
        //     sorter: (a, b) => {

        //         if (a && a.user_name && a.user_name.length && b && b.user_name && b.user_name.length) {
        //             return a.user_name.localeCompare(b.user_name);
        //         } else if (a && a.user_name && a.user_name.length) {
        //             // That means be has null division_name, so a will come first.
        //             return -1;
        //         } else if (b && b.user_name && b.user_name.length) {
        //             // That means a has null division_name so b will come first.
        //             return 1;
        //         }

        //         // Both division_name has null value so there will be no order change.
        //         return 0;
        //     },
        //     // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
        //     sortOrder: sortedInfo.columnKey === 'user_name' ? sortedInfo.order : null,
        //     render: (row, record) => {
        //         return (
        //             <Fragment>
        //                 {
        //                     ((row == null) && (record.is_disable == 0)) ?
        //                         <Tooltip title="Click here to resend email" ><Link onClick={() => { resentEmailRegistration(record) }}>Resend</Link></Tooltip>

        //                         : ''
        //                 }
        //             </Fragment>



        //         )
        //     }
        // },
        {
            title: <label>Replace</label>,
            dataIndex: 'is_active',
            key: 'is_active',
            align: 'center',
            width: '20px',
            sorter: (a, b) => particularLeId[a.le_id] == a.le_id ? Number(a.is_active) - Number(b.is_active) : false,
            sortOrder: sortedInfo.columnKey === 'is_active' ? sortedInfo.order : null,
            render: (text, record) => {
                console.log(record, 'recordlist');
                if (((loginResponse && loginResponse.usr_cat_id == 1) && ((localStorage.getItem("SelectedEntity") == 'All Legal Entity') || entity_info && entity_info.length == 1))) {
                    return (
                        <Fragment className='row hover '>
                            {record.is_active == true && record.is_disable == 0 ?

                                <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center margin0"
                                    onClick={(e) => {
                                        setExistingUserData(record)
                                        setExistingUser(true)
                                    }}>
                                    <Tooltip title="Click Here to Replace">
                                        <RetweetOutlined /></Tooltip>
                                    {/* <i className="ri-edit-line"></i> */}
                                </span> : false}
                        </Fragment>
                    )
                }
                else {
                    return false
                }
            }
        },
        {
            title: <><label>Actions</label><Tooltip placement="topRight" title={"Edit,Status,Disable"}><InfoCircleOutlined /></Tooltip></>,
            dataIndex: 'is_active',
            width: '40px',
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                },
            ],

            filteredValue: filteredInfo.is_active,
            onFilter: (value, record) => record.is_active == value,
            filterSearch: false,
            render: (text, record) => {
                console.log(record, 'recordlist');
                return (
                    <Fragment>
                        <div style={{ width: '95%', margin: '0 auto' }} className='row hover'>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <div>
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { editUser(record.user_id, record) }}>
                                            <Tooltip title="Click Here to Edit" >
                                                <EditOutlined /></Tooltip>
                                            {/* <i className="ri-edit-line"></i> */}
                                        </span>
                                    </div> :
                                    <div >
                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center">
                                            <EditOutlined style={{ cursor: 'not-allowed' }}></EditOutlined>
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center px-2'>
                                {
                                    record.is_disable === 0 ?
                                        <div >
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { ActivateById(record, "active") }}>
                                                    {/* text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record, "active") }} */}
                                                    <Tooltip title="Click here to Deactivate" ><LikeOutlined /></Tooltip>
                                                </span> :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center"  onClick={(e) => { ActivateById(record, "deactive") }}>
                                                    <Tooltip title="Click here to Activate"><DislikeOutlined /></Tooltip>
                                                </span>
                                            }
                                        </div> :
                                        <div>
                                            {record.is_active == true ?
                                                <span className="social-link btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span>
                                                :
                                                <span className="social-link btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center" >
                                                    <Tooltip title="De-Active" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                </span>


                                            }
                                        </div>

                                }
                            </div>
                            <div className='col-4 d-flex justify-content-center'>
                                {record.is_disable === 0 ?
                                    <span className="social-link btn btn-sm btn-light-success
                                    text-success btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                            setShow(true)
                                            DisableById(record.user_id, "disable", record.le_ids, record);
                                        }}>
                                        <Tooltip placement='topLeft' title="Click here to Disable"><CheckOutlined /></Tooltip>
                                    </span>
                                    :
                                    <div>
                                        {record.unblock_days === 0 ? '' :
                                            <span className="social-link btn btn-sm btn-light-danger
                                            text-danger btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                                    setShow(true)
                                                    DisableById(record.user_id, "unDisable", record.le_ids, record);
                                                }}>
                                                <Tooltip placement='topLeft' title={record.unblock_days + '-' + 'Days Left'} color='#fc4b6c'><StopOutlined /></Tooltip>
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }
    ];

    const resentEmailRegistration = (record) => {
        const payload = [
            authtoken,
            {
                "session_token": authtoken,

                "request": [
                    "ResendRegistrationEmail",
                    {
                        "user_id": record.user_id
                    }
                ]
            }
        ]
        resendEmail({
            payload: payload,
            paramid: paramid
        })

    }


    const editUser = (user_id, record) => {
        setDetails({
            ...details,
            le_id: record.le_ids,
            user_id: record.user_id
        })
        setAddType("edit")
        setShowList(true)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,

                "request": [
                    "UserManagementEditView",
                    {
                        "user_id": user_id
                    }
                ]
            }
        ]
        getById({
            payload: payload,
            paramid: paramid
        })


    }
    console.log(disableType, 'disableTypedisableType');
    const DisableById = (user_id, type, le_id, record) => {
        setDisableType(type)
        setUserCategoryId({
            u_cat_id: record.u_cat_id,
            le_id: record.le_id
        })
        type == "disable" ?
            Swal.fire({
                title: 'Are you sure? ',
                icon: 'info',
                text: ' You Want to Disable the User',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '

            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setAddFormSubmit1(false)
                    setModalData({
                        ...modalData,
                        user_id: user_id,
                        le_id: le_id
                    })
                    setTimeout(() => {
                        setFocus(serviceProviderRef1)
                    }, 500);
                }
            }) : Swal.fire({
                title: 'Are you sure? ',
                icon: 'info',
                text: ' You Want to Enable the User',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setAddFormSubmit1(false)
                    setModalData({
                        ...modalData,
                        user_id: user_id,
                        le_id: le_id
                    })
                    setTimeout(() => {
                        setFocus(serviceProviderRef1)
                    }, 500);
                }
            });
    };

    const ActivateById = (user_id, type) => {
        setDisableType(type)
        type == "active" ?
            Swal.fire({
                title: 'Are you sure? ',
                icon: 'info',
                text: ' You Want to Deactivate',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                if (result.isConfirmed) {
                    // setModaVisible(true)
                    statuschange(user_id, type)
                    Setpassvisible(true);
                    setAddFormSubmit1(false)
                    setModalData({
                        ...modalData,
                        user_id: user_id,
                    })
                    setTimeout(() => {
                        setFocus(serviceProviderRef1)
                    }, 500);
                }
            }) : Swal.fire({
                title: 'Are you sure? ',
                icon: 'info',
                text: ' You Want to Activate',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                if (result.isConfirmed) {
                    Setpassvisible(true);
                    statuschange(user_id, type)
                    setAddFormSubmit1(false)
                    // setModaVisible(true)
                    setModalData({
                        ...modalData,
                        user_id: user_id,
                    })
                    setTimeout(() => {
                        setFocus(serviceProviderRef1)
                    }, 500);
                }
            });
    };


    const statuschange = (e, type) => {
        Setpassvisible(true);
        if (type == "active") {
            Setpassword({
                ...passwordata,
                user_id: e.user_id,
                le_id: e.le_ids
            })
            setActive({
                ...active,
                u_id: e.user_id,
                emp_name: e.emp_name
            })
        }
        else {
            setActive({
                ...active,
                u_id: e.user_id,
                emp_name: e.emp_name
            })
        }
        setType(type)

    }


    useEffect(() => {
        if (entity_info) {
            setLegalEntity(entity_info)
        }
    }, [])

    useEffect(() => {

        let LegalEntityData = _.find(UserLegalDetails && UserLegalDetails, { le_id: entityid })
        setEntity(LegalEntityData)
    }, [entityid, UserLegalDetails])
    console.log(userManagementList, 'userManagementList5555');
    // useEffect(() => {
    //     if (userManagementList && userManagementList.length > 0 && selectedEntity == 'All Legal Entity') {
    //         // let temp = []
    //         // for (let i in userManagementList) {
    //         //     for (let j in UserLegalDetails) {
    //         //         if (userManagementList[i].le_id == UserLegalDetails[j].le_id) {
    //         //             let obj = {
    //         //                 "email_id": userManagementList[i].email_id,
    //         //                 "emp_code": userManagementList[i].emp_code,
    //         //                 "emp_name": userManagementList[i].emp_name,
    //         //                 "is_active": userManagementList[i].is_active,
    //         //                 "is_disable": userManagementList[i].is_disable,
    //         //                 "le_id": userManagementList[i].le_id,
    //         //                 "mob_no": userManagementList[i].mob_no,
    //         //                 "u_cat_id": userManagementList[i].u_cat_id,
    //         //                 "u_type_id": userManagementList[i].u_type_id,
    //         //                 "unblock_days": userManagementList[i].unblock_days,
    //         //                 "user_id": userManagementList[i].user_id,
    //         //                 "user_name": userManagementList[i].user_name,
    //         //                 "cont_from": UserLegalDetails[j].cont_from,
    //         //                 "cont_to": UserLegalDetails[j].cont_to,
    //         //                 "l_cont_from": UserLegalDetails[j].l_cont_from,
    //         //                 "l_cont_to": UserLegalDetails[j].l_cont_to,
    //         //                 "v_cont_from": UserLegalDetails[j].v_cont_from,
    //         //                 "v_cont_to": UserLegalDetails[j].v_cont_to,
    //         //                 "vndr_user_category": userManagementList[i].vndr_user_category

    //         //             }
    //         //             temp.push(obj)

    //         //         }
    //         //     }
    //         // }
    //         // setUserMagementData(temp)
    //     }
    //     else if (usermanagementDetails && usermanagementDetails.length > 0 && selectedEntity !== 'All Legal Entity') {
    //         let temp = []
    //         let LegalEntityData = _.find(UserLegalDetails && UserLegalDetails, { le_id: entityid })
    //         for (let i in usermanagementDetails) {
    //             let obj = {
    //                 "email_id": usermanagementDetails[i].email_id,
    //                 "emp_code": usermanagementDetails[i].emp_code,
    //                 "emp_name": usermanagementDetails[i].emp_name,
    //                 "is_active": usermanagementDetails[i].is_active,
    //                 "is_disable": usermanagementDetails[i].is_disable,
    //                 "le_id": usermanagementDetails[i].le_id,
    //                 "le_ids": userManagementList[i].le_id,
    //                 "mob_no": usermanagementDetails[i].mob_no,
    //                 "u_cat_id": usermanagementDetails[i].u_cat_id,
    //                 "u_type_id": usermanagementDetails[i].u_type_id,
    //                 "unblock_days": usermanagementDetails[i].unblock_days,
    //                 "user_id": usermanagementDetails[i].user_id,
    //                 "user_name": usermanagementDetails[i].user_name,
    //                 "cont_from": usermanagementDetails.cont_from,
    //                 "cont_to": usermanagementDetails.cont_to,
    //                 "l_cont_from": usermanagementDetails.l_cont_from,
    //                 "l_cont_to": usermanagementDetails.l_cont_to,
    //                 "v_cont_from": usermanagementDetails.v_cont_from,
    //                 "v_cont_to": usermanagementDetails.v_cont_to,
    //                 "vndr_user_category": usermanagementDetails[i].vndr_user_category

    //             }
    //             temp.push(obj)
    //         }
    //         setUserMagementData(temp)

    //     }
    // }, [UserLegalDetails, userManagementList, usermanagementDetails])
    console.log(usermangementData, 'usermangementData123');
    console.log(userManagementList, 'userManagementList');

    useEffect(() => {
        if (userManagementList.length > 0) {
            let temp = []
            for (let i in userManagementList) {
                for (let j in userManagementList[i].le_id) {
                    let filteredcountry = _.filter(UserLegalDetails, { le_id: userManagementList[i].le_id[j] })
                    let obj = {
                        "email_id": userManagementList[i].email_id,
                        "emp_code": userManagementList[i].emp_code,
                        "emp_name": userManagementList[i].emp_name,
                        "is_active": userManagementList[i].is_active,
                        "is_disable": userManagementList[i].is_disable,
                        "le_id": userManagementList[i].le_id[j],
                        "le_ids": userManagementList[i].le_id,
                        "mob_no": userManagementList[i].mob_no,
                        "u_cat_id": userManagementList[i].u_cat_id,
                        "u_type_id": userManagementList[i].u_type_id,
                        "unblock_days": userManagementList[i].unblock_days,
                        "user_id": userManagementList[i].user_id,
                        "user_name": userManagementList[i].user_name,
                        "cont_from": UserLegalDetails[j].cont_from,
                        "cont_to": UserLegalDetails[j].cont_to,
                        "l_cont_from": UserLegalDetails[j].l_cont_from,
                        "l_cont_to": UserLegalDetails[j].l_cont_to,
                        "v_cont_from": UserLegalDetails[j].v_cont_from,
                        "v_cont_to": UserLegalDetails[j].v_cont_to,
                        "vndr_user_category": userManagementList[i].vndr_user_category,
                        "country_name": filteredcountry && filteredcountry.length > 0 && filteredcountry[0].c_name,
                        'b_g_name': filteredcountry && filteredcountry.length > 0 && filteredcountry[0].b_g_name

                    }
                    temp.push(obj)
                }
            }
            console.log(temp, 'temp2324444444444444');
            setUsermanagementListData(temp)
            setTempmanagementListData(temp)
        }
    }, [userManagementList])








    useEffect(() => {
        if ((usermagementListData && usermagementListData.length > 0)) {
            let sortArray = _.orderBy(usermagementListData, 'le_id', 'desc');
            console.log(sortArray, 'sortArray');
            let uniqueObjArray = [...new Map(sortArray && sortArray.length > 0 && sortArray.map((item) => [item["le_id"], item])).values()];
            // let uniqueUser = [...new Map(uniqueObjArray && uniqueObjArray.length > 0 && uniqueObjArray.map((item) => [item["user_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.le_id
            })
            console.log(uniqueIds, 'uniqueIds');
            // let legalValue = entity_info && entity_info.length > 0 && entity_info.map((item) => {
            //     return item.le_id
            // })
            // console.log(legalValue, 'legalValue');
            let tempArr = []
            for (let i in uniqueIds) {
                // if (legalValue.includes(uniqueIds[i]) == true) {
                let filteredArr = _.filter(usermagementListData, { le_id: uniqueIds[i] })
                if (categoryValue.compfie && categoryValue.compfie.length > 0 || categoryValue.vendor.length > 0) {
                    let uniqueUser = [...new Map(filteredArr && filteredArr.length > 0 && filteredArr.map((item) => [item["user_id"], item])).values()];
                    tempArr.push(uniqueUser)
                }
                else {
                    tempArr.push(filteredArr)
                }

                // }
            }
            setFilteredArray(tempArr)

        }

    }, [usermagementListData])
    useEffect(() => {
        if (usermagementListData && usermagementListData.length > 0 && selectedEntity !== 'All Legal Entity') {
            // let data = _.uniqBy(userManagementList, function (e) {
            //     return e.user_id;
            // });
            // console.log(data, 'datadatadatadatadata');

            let temp = _.filter(usermagementListData, { le_id: entityid })
            console.log(temp, 'temptemptemptemp');
            setuserMAnagementDeails(temp)




        }

    }, [usermagementListData])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
        window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    return (
        <>
            {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} /> */}
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            {
                existingUser == true ?
                    <ReplaceUser setExistingUser={setExistingUser} existingUser={existingUser} setExistingUserData={setExistingUserData} existingUserData={existingUserData} /> :
                    showList == false ?
                        <div id='page-wrapper' className="page-wrapper">
                            <div className="page-titles py-1 page-title-sticky">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-self-center">
                                        <nav aria-label="breadcrumb">
                                            {AddType == 'add' ?
                                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                                    <li className="breadcrumb-item">
                                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                                    </li>
                                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                        <span style={{ fontSize: '16px' }}>Master</span>
                                                    </li>
                                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large' }}>
                                                        <span>Add user Management</span>
                                                    </li>
                                                </ol> : <ol className="breadcrumb mb-0 d-flex align-items-center">
                                                    <li className="breadcrumb-item">
                                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                                    </li>
                                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                        <span style={{ fontSize: '16px' }}>Master</span>
                                                    </li>
                                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                        <span >User Management</span>
                                                        {/* <Link>User Management</Link> */}
                                                    </li>
                                                </ol>}
                                        </nav>

                                    </div>
                                    <div
                                        className=" col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-between align-items-center justify-content-md-end">
                                        {loginResponse && loginResponse.usr_cat_id == 1 &&
                                            <Tooltip title="Advanced Filter" placement='top'><Button type="primary" shape="round"
                                                icon={<FilterOutlined />} size='default' onClick={() => {
                                                    setFilterModal(true)

                                                }} >
                                                Filter
                                            </Button></Tooltip>}

                                        <Tooltip title="Add User" placement='top'><Button className='mx-2' type="primary" shape="round"
                                            icon={<PlusCircleOutlined />} size='default' onClick={() => {
                                                setShowList(true)
                                                setAddType('add')
                                            }} >
                                            Add
                                        </Button></Tooltip>


                                        <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black  full-mode fullscreenradius"  >
                                            <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                        </button>

                                    </div>
                                </div>

                            </div>
                            <div className="container-fluid pt-0">
                                {((filterarray && filterarray.length == 0) || (entity_info && entity_info.length === 1)) ?
                                    filteredArray && filteredArray.length > 0 && filteredArray.map((item, i) => {
                                        let data = _.find(UserLegalDetails && UserLegalDetails.length && UserLegalDetails, { le_id: item && item.length && item[0] && item[0].le_id })
                                        console.log(data, 'data55555');
                                        if ((moment(data && data.cont_to).isSameOrAfter(date)) || (moment(date).isBefore(data && data.v_cont_to))) {
                                            const columns = [
                                                {
                                                    title: '#',
                                                    dataIndex: false,
                                                    align: 'center',
                                                    width: '18px',
                                                    render: (text, record, index) => {
                                                        return index + 1
                                                    }
                                                },

                                                {
                                                    title: 'Users',
                                                    dataIndex: 'emp_name',
                                                    key: 'emp_name',
                                                    width: '75px',
                                                    ...getColumnSearchProperty('emp_name', 'Users', i),
                                                    // FilterDropdownOpenChange: (visible) => {
                                                    //     console.log(searchInput, 'searchInput12333');
                                                    //     if (visible) {
                                                    //         console.log(searchInput, 'searchInput');
                                                    //         setTimeout(() => searchInput.current?.select(), 100);
                                                    //     }
                                                    // },
                                                    // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                                    //     <div className='d-flex' style={{ position: 'relative' }}>
                                                    //         <Input className='form-control'
                                                    //             ref={searchInput}
                                                    //             placeholder={'Search User'}
                                                    //             // value={searchValue[i]}
                                                    //             // autoFocus={'on'}
                                                    //             // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                                                    //             onChange={(e) => handlesearch(e, confirm, 'emp_name', setSelectedKeys, i)}
                                                    //         />
                                                    //         <CloseCircleOutlined className='table-filter-reset'
                                                    //             onClick={() => {
                                                    //                 handleReset(clearFilters,
                                                    //                     handlesearch, confirm, 'emp_name', setSelectedKeys, i)
                                                    //                 clearFocus(searchInput)
                                                    //             }
                                                    //             } />

                                                    //     </div>
                                                    // ),
                                                    // filterIcon: (filtered, record) => {
                                                    //     console.log(record, 'filtered');
                                                    //     return (
                                                    //         <SearchOutlined
                                                    //             style={{

                                                    //             }}
                                                    //         />

                                                    //     )
                                                    // },
                                                    // onFilter: (value, record, j) =>
                                                    //     tableSearch.length > 0 && tableSearch[i] && tableSearch[i]['emp_name'] !== null ? record['emp_name'].toString().toLowerCase().includes(tableSearch[i] && tableSearch[i]['emp_name'] && tableSearch[i] && tableSearch[i]['emp_name'].length > 0 && tableSearch[i] && tableSearch[i]['emp_name'][0].toLowerCase()) : record,

                                                    // render: (text) => text,
                                                    filteredValue: tableSearch.length > 0 && tableSearch[i] && tableSearch[i].emp_name || null,
                                                    // sorter: (a, b) => a.emp_name.localeCompare(b.emp_name)
                                                    sorter: (a, b) => {
                                                        if (a && a.emp_name && a.emp_name.length && b && b.emp_name && b.emp_name.length) {
                                                            return a.emp_name.localeCompare(b.emp_name);
                                                        } else if (a && a.emp_name && a.emp_name.length) {
                                                            // That means be has null division_name, so a will come first.
                                                            return -1;
                                                        } else if (b && b.emp_name && b.emp_name.length) {
                                                            // That means a has null division_name so b will come first.
                                                            return 1;
                                                        }

                                                        // Both division_name has null value so there will be no order change.
                                                        return 0;
                                                    },
                                                    sortOrder: tableSortOrders[i] && tableSortOrders[i].column === 'emp_name' && tableSortOrders[i].sortOrder,
                                                    render: (row, record) => {
                                                        console.log(row, 'row12344948');
                                                        return (
                                                            <Fragment>
                                                                {
                                                                    ((record.user_name == null) && (record.is_disable == 0)) ?
                                                                        // <p>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} /></p>


                                                                        <><Tooltip title="Click here to resend email" > <span>{record.emp_name}&nbsp;<MailOutlined style={{ color: '#800000' }} onClick={() => { resentEmailRegistration(record) }} /></span></Tooltip>
                                                                            <Tooltip title={
                                                                                'compfie'
                                                                            }>  {record.u_cat_id == null ? <StopOutlined style={{ color: 'red' }} /> :
                                                                                <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.u_cat_id == 2 ? "#C9C9C9" : record.u_cat_id == 3 ? '#f7bf17' : record.u_cat_id == 5 ? "#6ecd2c" : record.u_cat_id == 1 ? "#FF6666" : record.u_cat_id == 6 ? "#C6E0B4" : record.u_cat_id == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}
                                                                            </Tooltip>
                                                                            <Tooltip title={
                                                                                "Vendor"
                                                                            }> {record.vndr_user_category == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.vndr_user_category == 2 ? "#C9C9C9" : record.vndr_user_category == 3 ? '#f7bf17' : record.vndr_user_category == 5 ? "#6ecd2c" : record.vndr_user_category == 1 ? "#FF6666" : record.vndr_user_category == 6 ? "#C6E0B4" : record.vndr_user_category == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}</Tooltip>

                                                                            <Tooltip title={
                                                                                "Litigation"
                                                                            }>  {record.u_type_id == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_type_id == 2 ? "#FFE699" : record.u_type_id == 3 ? "#a1b793" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
                                                                        </>

                                                                        : <span>{record.emp_name}
                                                                            <Tooltip title={
                                                                                'compfie'
                                                                            }>  {record.u_cat_id == null ? <StopOutlined style={{ color: 'red' }} /> :
                                                                                <i style={{ color: record.u_cat_id == 4 ? "#FFE699" : record.u_cat_id == 2 ? "#C9C9C9" : record.u_cat_id == 3 ? '#f7bf17' : record.u_cat_id == 5 ? "#6ecd2c" : record.u_cat_id == 1 ? "#FF6666" : record.u_cat_id == 6 ? "#C6E0B4" : record.u_cat_id == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}
                                                                            </Tooltip>
                                                                            <Tooltip
                                                                                title={
                                                                                    "Vendor"
                                                                                }
                                                                            > {record.vndr_user_category == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.vndr_user_category == 4 ? "#FFE699" : record.vndr_user_category == 2 ? "#C9C9C9" : record.vndr_user_category == 3 ? '#f7bf17' : record.vndr_user_category == 5 ? "#6ecd2c" : record.vndr_user_category == 1 ? "#FF6666" : record.vndr_user_category == 6 ? "#C6E0B4" : record.vndr_user_category == 7 ? "#DBDBDB" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
                                                                            <Tooltip title={
                                                                                "Litigation"
                                                                            }>  {record.u_type_id == null ? <StopOutlined style={{ color: 'red' }} /> : <i style={{ color: record.u_type_id == 2 ? "#FFE699" : record.u_type_id == 3 ? "#a1b793" : '' }} className="bi bi-square-fill"></i>}</Tooltip>
                                                                        </span>
                                                                }

                                                            </Fragment>



                                                        )
                                                    }
                                                },
                                                {
                                                    title: 'User ID',
                                                    dataIndex: 'user_name_new',
                                                    key: 'user_name_new',
                                                    width: '60px',
                                                    // ellipsis: true,
                                                    // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                                    //     <div className='d-flex' style={{ position: 'relative' }}>
                                                    //         <Input className='form-control'
                                                    //             ref={searchInput}
                                                    //             placeholder={'Search User'}
                                                    //             // value={searchValue[i]}
                                                    //             // autoFocus={'on'}
                                                    //             // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                                                    //             onChange={(e) => handlesearch(e, confirm, 'user_name_new', setSelectedKeys, i)}
                                                    //         />
                                                    //         <CloseCircleOutlined className='table-filter-reset'
                                                    //             onClick={() => {
                                                    //                 handleReset(clearFilters,
                                                    //                     handlesearch, confirm, 'user_name_new', setSelectedKeys, i)
                                                    //                 clearFocus(searchInput)
                                                    //             }
                                                    //             } />

                                                    //     </div>
                                                    // ),
                                                    // filterIcon: (filtered, record) => {
                                                    //     console.log(record, 'filtered');
                                                    //     return (
                                                    //         <SearchOutlined
                                                    //             style={{

                                                    //             }}
                                                    //         />

                                                    //     )
                                                    // },
                                                    // onFilter: (value, record, j) =>
                                                    //     // console.log(value, ' tableSearch[i][0].le_id '),
                                                    //     tableSearch.length > 0 && tableSearch[i] && tableSearch[i]['user_name_new'] !== null ? record['user_name_new'].toString().toLowerCase().includes(tableSearch[i] && tableSearch[i]['user_name_new'] && tableSearch[i] && tableSearch[i]['user_name_new'].length > 0 && tableSearch[i] && tableSearch[i]['user_name_new'][0].toLowerCase()) : record,
                                                    // // tableSearch[i] && tableSearch[i].name == 'emp_name' ? record[tableSearch[i] && tableSearch[i].name] != null && record[tableSearch[i] && tableSearch[i].name].toString().toLowerCase().includes(searchValue && searchValue[i].toLowerCase()) : record,
                                                    // // tableSearch[i]['emp_name'] != null && tableSearch[i]['emp_name'].toString().toLowerCase().includes(value.toLowerCase()),

                                                    // FilterDropdownOpenChange: (visible) => {
                                                    //     if (visible) {
                                                    //         setTimeout(() => searchInput.current?.select(), 100);
                                                    //     }
                                                    // },
                                                    // render: (text) => text,
                                                    ...getColumnSearchProperty('user_name_new', 'User ID', i),
                                                    filteredValue: tableSearch.length > 0 && tableSearch[i] && tableSearch[i].user_name_new || null,

                                                    sorter: (a, b) => {

                                                        if (a && a.user_name_new && a.user_name_new.length && b && b.user_name_new && b.user_name_new.length) {
                                                            return a.user_name_new.localeCompare(b.user_name_new);
                                                        } else if (a && a.user_name_new && a.user_name_new.length) {
                                                            // That means be has null division_name, so a will come first.
                                                            return -1;
                                                        } else if (b && b.user_name_new && b.user_name_new.length) {
                                                            // That means a has null division_name so b will come first.
                                                            return 1;
                                                        }

                                                        // Both division_name has null value so there will be no order change.
                                                        return 0;
                                                    },
                                                    // sorter: (a, b) => a.user_name && b.user_name != null ? a.user_name.localeCompare(b.user_name) : "",
                                                    // sortOrder: sortedInfo.columnKey === 'user_name_new' ? sortedInfo.order : null,
                                                    sortOrder: tableSortOrders[i] && tableSortOrders[i].column === 'user_name_new' && tableSortOrders[i].sortOrder,

                                                },
                                                {
                                                    title: 'Email ID',
                                                    dataIndex: 'email_id',
                                                    width: '85px',
                                                    key: 'email_id',
                                                    ...getColumnSearchProperty('email_id', 'Email ID', i),
                                                    // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                                    //     <div className='d-flex' style={{ position: 'relative' }}>
                                                    //         <Input className='form-control'
                                                    //             ref={searchInput}
                                                    //             placeholder={'Search User'}
                                                    //             // value={searchValue[i]}
                                                    //             // autoFocus={'on'}
                                                    //             // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                                                    //             onChange={(e) => handlesearch(e, confirm, 'email_id', setSelectedKeys, i)}
                                                    //         />
                                                    //         <CloseCircleOutlined className='table-filter-reset'
                                                    //             onClick={() => {
                                                    //                 handleReset(clearFilters,
                                                    //                     handlesearch, confirm, 'email_id', setSelectedKeys, i)
                                                    //                 clearFocus(searchInput)
                                                    //             }
                                                    //             } />

                                                    //     </div>
                                                    // ),
                                                    // filterIcon: (filtered, record) => {
                                                    //     console.log(record, 'filtered');
                                                    //     return (
                                                    //         <SearchOutlined
                                                    //             style={{

                                                    //             }}
                                                    //         />

                                                    //     )
                                                    // },
                                                    // onFilter: (value, record, j) =>
                                                    //     tableSearch.length > 0 && tableSearch[i] && tableSearch[i]['email_id'] !== null ? record['email_id'].toString().toLowerCase().includes(tableSearch[i] && tableSearch[i]['email_id'] && tableSearch[i] && tableSearch[i]['email_id'].length > 0 && tableSearch[i] && tableSearch[i]['email_id'][0].toLowerCase()) : record,
                                                    // FilterDropdownOpenChange: (visible) => {
                                                    //     if (visible) {
                                                    //         setTimeout(() => searchInput.current?.select(), 100);
                                                    //     }
                                                    // },
                                                    render: (text) => text,
                                                    ellipsis: true,
                                                    filteredValue: tableSearch.length > 0 && tableSearch[i] && tableSearch[i].email_id || null,
                                                    sorter: (a, b) => {
                                                        if (a && a.email_id && a.email_id.length && b && b.email_id && b.email_id.length) {
                                                            return a.email_id.localeCompare(b.email_id);
                                                        } else if (a && a.email_id && a.email_id.length) {
                                                            // That means be has null division_name, so a will come first.
                                                            return -1;
                                                        } else if (b && b.email_id && b.email_id.length) {
                                                            // That means a has null division_name so b will come first.
                                                            return 1;
                                                        }

                                                        // Both division_name has null value so there will be no order change.
                                                        return 0;
                                                    },
                                                    // sorter: (a, b) => a.email_id && b.email_id != null ? a.email_id.localeCompare(b.email_id) : "",
                                                    // sortOrder: sortedInfo.columnKey === 'email_id' ? sortedInfo.order : null,
                                                    sortOrder: tableSortOrders[i] && tableSortOrders[i].column === 'email_id' && tableSortOrders[i].sortOrder,
                                                },
                                                {
                                                    title: 'Mobile Number',
                                                    dataIndex: 'mob_no',
                                                    key: 'mob_no',
                                                    ...getColumnSearchProperty('mob_no', 'Mobile Number', i),
                                                    width: '35px',
                                                    // ellipsis: true
                                                    filteredValue: tableSearch.length > 0 && tableSearch[i] && tableSearch[i].mob_no || null,
                                                    sorter: (a, b) => a.mob_no.localeCompare(b.mob_no),
                                                    // sorter: (a, b) => {

                                                    //     if(a && a.mob_no && a.mob_no.length && b && b.mob_no && b.mob_no.length) {
                                                    //         return a.user_name.localeCompare(b.mob_no);
                                                    //     }else if(a && a.mob_no && a.mob_no.length) {
                                                    //         // That means be has null division_name, so a will come first.
                                                    //         return -1;
                                                    //     } else if(b && b.mob_no && b.mob_no.length) {
                                                    //         // That means a has null division_name so b will come first.
                                                    //         return 1;
                                                    //     }

                                                    //     // Both division_name has null value so there will be no order change.
                                                    //     return 0;
                                                    // },
                                                    // sortOrder: sortedInfo.columnKey === 'mob_no' ? sortedInfo.order : null,
                                                    sortOrder: tableSortOrders[i] && tableSortOrders[i].column === 'mob_no' && tableSortOrders[i].sortOrder,
                                                },

                                                {
                                                    title: <label>Replace</label>,
                                                    dataIndex: 'is_active',
                                                    key: 'is_active',
                                                    align: 'center',
                                                    width: '20px',
                                                    // filteredValue: filteredInfo.mob_no || null,
                                                    // sorter: (a, b) => {
                                                    //     if (a && a.is_active && a.is_active.length && b && b.is_active && b.is_active.length) {
                                                    //         return  Number(a.is_active) - Number(b.is_active)
                                                    //     } else if (a && a.is_active && a.is_active.length) {
                                                    //         // That means be has null division_name, so a will come first.
                                                    //         return -1;
                                                    //     } else if (b && b.is_active && b.is_active.length) {
                                                    //         // That means a has null division_name so b will come first.
                                                    //         return 1;
                                                    //     }

                                                    //     // Both division_name has null value so there will be no order change.
                                                    //     return 0;
                                                    // },
                                                    sorter: (a, b) => Number(a.is_active) - Number(b.is_active),
                                                    // sortOrder: sortedInfo.columnKey === 'is_active' ? sortedInfo.order : null,
                                                    sortOrder: tableSortOrders[i] && tableSortOrders[i].column === 'is_active' && tableSortOrders[i].sortOrder,

                                                    render: (text, record) => {
                                                        console.log(record, 'recordlist');
                                                        if (((loginResponse && loginResponse.usr_cat_id == 1) && ((localStorage.getItem("SelectedEntity") == 'All Legal Entity') || entity_info && entity_info.length == 1))) {
                                                            return (
                                                                <Fragment className='row hover '>
                                                                    {record.is_active == true && record.is_disable == 0 ?

                                                                        <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
                                                                    align-items-center justify-content-center margin0"
                                                                            onClick={(e) => {
                                                                                setExistingUserData(record)
                                                                                setExistingUser(true)
                                                                            }}>
                                                                            <Tooltip title="Click Here to Replace" >
                                                                                <RetweetOutlined /></Tooltip>
                                                                            {/* <i className="ri-edit-line"></i> */}
                                                                        </span> : false}
                                                                </Fragment>
                                                            )
                                                        }
                                                        else {
                                                            return false
                                                        }
                                                    },

                                                },
                                                {
                                                    title: <><label>Actions</label><Tooltip placement="topRight" title={"Edit,Status,Disable"}><InfoCircleOutlined /></Tooltip></>,
                                                    dataIndex: 'is_active',
                                                    width: '40px',
                                                    filters: [
                                                        {
                                                            text: 'Active',
                                                            value: true,
                                                        },
                                                        {
                                                            text: 'Inactive',
                                                            value: false,
                                                        },
                                                    ],

                                                    filteredValue: filteredInfo.is_active,
                                                    onFilter: (value, record) => record.le_id == particularLeId ? record.is_active == value : record,
                                                    filterSearch: false,
                                                    render: (text, record) => {
                                                        console.log(record, 'recordlist');
                                                        return (
                                                            <Fragment>
                                                                <div style={{ width: '95%', margin: '0 auto' }} className='row hover'>
                                                                    <div className='col-4 d-flex justify-content-center'>
                                                                        {record.is_disable === 0 ?
                                                                            <div>
                                                                                <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                                                                    onClick={(e) => { editUser(record.user_id, record) }}>
                                                                                    <Tooltip title="Click Here to Edit">
                                                                                        <EditOutlined /></Tooltip>
                                                                                    {/* <i className="ri-edit-line"></i> */}
                                                                                </span>
                                                                            </div> :
                                                                            <div >
                                                                                <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center">
                                                                                    <EditOutlined style={{ cursor: 'not-allowed' }}></EditOutlined>
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className='col-4 d-flex justify-content-center px-2'>
                                                                        {
                                                                            record.is_disable === 0 ?
                                                                                <div >
                                                                                    {record.is_active == true ?
                                                                                        <span className="social-link btn btn-sm btn-light-success
                                                                                    text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { ActivateById(record, "active") }}>
                                                                                            {/* text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record, "active") }} */}
                                                                                            <Tooltip title="Click here to Deactivate" ><LikeOutlined /></Tooltip>
                                                                                        </span> :
                                                                                        <span className="social-link btn btn-sm btn-light-danger
                                                                                    text-danger btn-circle d-flex align-items-center justify-content-center"  onClick={(e) => { ActivateById(record, "deactive") }}>
                                                                                            <Tooltip title="Click here to Activate"><DislikeOutlined /></Tooltip>
                                                                                        </span>
                                                                                    }
                                                                                </div> :
                                                                                <div>
                                                                                    {record.is_active == true ?
                                                                                        <span className="social-link btn btn-sm btn-light-success
                                                                                    text-success btn-circle d-flex align-items-center justify-content-center" >
                                                                                            <Tooltip title="" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                                                        </span>
                                                                                        :
                                                                                        <span className="social-link btn btn-sm btn-light-danger
                                                                                    text-danger btn-circle d-flex align-items-center justify-content-center" >
                                                                                            <Tooltip title="De-Active" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                                                        </span>


                                                                                    }
                                                                                </div>

                                                                        }
                                                                    </div>
                                                                    <div className='col-4 d-flex justify-content-center'>
                                                                        {record.is_disable === 0 ?
                                                                            <span className="social-link btn btn-sm btn-light-success
                                                                        text-success btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                                                                    setShow(true)
                                                                                    DisableById(record.user_id, "disable", record.le_ids, record);
                                                                                }}>
                                                                                <Tooltip placement='topLeft' title="Click here to Disable" ><CheckOutlined /></Tooltip>
                                                                            </span>
                                                                            :
                                                                            <div>
                                                                                {record.unblock_days === 0 ? '' :
                                                                                    <span className="social-link btn btn-sm btn-light-danger
                                                                                text-danger btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                                                                            setShow(true)
                                                                                            DisableById(record.user_id, "unDisable", record.le_ids, record);
                                                                                        }}>
                                                                                        <Tooltip placement='topLeft' title={record.unblock_days + '-' + 'Days Left'} color='#fc4b6c'><StopOutlined /></Tooltip>
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        )
                                                    }
                                                }
                                            ];
                                            return (

                                                <Card style={{ borderRadius: "15px" }} key={i} className='userCard'>


                                                    <div className='row px-4'>


                                                        <div className='col-md-4' >
                                                            <label ><b>Country: </b>{data && data.c_name}</label>
                                                        </div>
                                                        <div className='col-md-4'  >
                                                            <label ><b>Business Group:</b>  {data && data.b_g_name}</label>
                                                        </div>
                                                        <div className='col-md-4' >
                                                            <label><b >Legal Entity:</b><span style={{ marginLeft: "10px" }}></span>{data && data.le_name}</label>
                                                        </div>

                                                        {/* <hr></hr> */}
                                                    </div>

                                                    <div className="row mt-1">
                                                        <div className="accordion" id="accordionExample ">
                                                            <div className="accordion-item" style={{ borderRadius: '15px' }}>
                                                                <h2 className="accordion-header" id="headingOne">
                                                                    <button
                                                                        className={"accordion-button accor-head p-2 "}
                                                                        type="button"
                                                                        // id={i}
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target={"#collapseOne" + i}
                                                                        aria-expanded="true"
                                                                        aria-controls="collapseOne"
                                                                    >
                                                                        <span><b>{data && data.le_name} - {item.length} Users</b></span>
                                                                    </button>
                                                                </h2>
                                                                <div
                                                                    id={"collapseOne" + i}
                                                                    class={"accordion-collapse collapse  show"}
                                                                    aria-labelledby="headingOne"
                                                                    data-bs-parent="#accordionExample"
                                                                >
                                                                    <div className="accordion-body pb-1 user-management-accordion">
                                                                        <div className="row">
                                                                            {/* <div className="col-1"></div> */}
                                                                            <div className="col-12">
                                                                                <div className="row">
                                                                                    {(data && data.total_licences) && (moment(data && data.cont_to).isSameOrAfter(date)) ?
                                                                                        <div className="col-md-4 px-2">
                                                                                            <div className="card mb-0 w-100 overflow-hidden card-hover mb-3">
                                                                                                <div className="card-body py-2 px-2">
                                                                                                    <div className="d-flex align-items-center ">
                                                                                                        <h4 className="mb-0 fs-3">Compfie Total Licence(s)</h4>

                                                                                                    </div>
                                                                                                    <div className="d-flex align-items-center my-3 mb-1">
                                                                                                        <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-white text-center rounded p-2"}>
                                                                                                            {/* :`border-end pe-3 ${localStorage.getItem("currentTheme")} text-white rounded p-2` */}
                                                                                                            <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                            <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{data && data.total_licences} </b>
                                                                                                        </div>
                                                                                                        <div className="ms-3 border-end pe-3 text-center">
                                                                                                            <h6 className="text-muted fw-normal">Used</h6>
                                                                                                            <b>{data && data.used_licences}</b>
                                                                                                        </div>
                                                                                                        <div className="ms-3 pe-3 text-center">
                                                                                                            <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                            <b>{Number(data ? data.total_licences : 0) - Number(data ? data.used_licences : 0)}</b>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <div className="d-flex">
                                                                                                        <span className="fs-2  font-weight-medium">Contract From : {data && data.cont_from}</span>
                                                                                                        <span className="fs-2 ms-3 font-weight-medium">Contract To : {data && data.cont_to}</span>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div> : ''}
                                                                                    {((data && data.l_total_licences) && (moment(data && data.l_cont_to).isSameOrAfter(date))) ? <div className="col-md-4 px-2">
                                                                                        <div className="card mb-0 w-100 overflow-hidden  card-hover mb-3">
                                                                                            <div className="card-body py-2 px-2">
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <h4 className="mb-0 fs-3">Compfie Litigation Total Licence(s)</h4>

                                                                                                </div>
                                                                                                <div className="d-flex align-items-center my-3 mb-1">
                                                                                                    <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-center text-white rounded p-2"}>
                                                                                                        <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                        <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{data && data.l_total_licences} </b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 border-end pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Used</h6>
                                                                                                        <b>{data && data.l_used_licences}</b>
                                                                                                    </div>
                                                                                                    <div className="ms-3 pe-3 text-center">
                                                                                                        <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                        <b>{Number(data ? data.l_total_licences : 0) - Number(data ? data.l_used_licences : 0)}</b>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <span className="fs-2  font-weight-medium">Contract From : {data && data.cont_from}</span>
                                                                                                    <span className="fs-2 ms-3 font-weight-medium">Contract To : {data && data.cont_to}</span>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> : ''}
                                                                                    {((data && data.v_total_licences) && (moment(data && data.v_cont_to).isSameOrAfter(date))) ?
                                                                                        <div className="col-md-4 px-2">
                                                                                            <div className="card mb-0 w-100 overflow-hidden card-hover mb-3">
                                                                                                <div className="card-body py-2 px-2">
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <h4 className="mb-0 fs-3">Compfie Vendor Total Licence(s)</h4>

                                                                                                    </div>
                                                                                                    <div className="d-flex align-items-center my-3 mb-1">
                                                                                                        <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-center text-white rounded p-2"}>
                                                                                                            <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                            <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{data && data.v_total_licences} </b>
                                                                                                        </div>
                                                                                                        <div className="ms-3 border-end pe-3 text-center">
                                                                                                            <h6 className="text-muted fw-normal">Used</h6>
                                                                                                            <b>{data && data.v_used_licences}</b>
                                                                                                        </div>
                                                                                                        <div className="ms-3 pe-3 text-center">
                                                                                                            <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                            <b>{Number(data ? data.v_total_licences : 0) - Number(data ? data.v_used_licences : 0)}</b>
                                                                                                        </div>

                                                                                                    </div>

                                                                                                    <div className="d-flex">
                                                                                                        <span className="fs-2  font-weight-medium">Contract From : {data && data.v_cont_from}</span>

                                                                                                        <span className="fs-2 ms-3 font-weight-medium">Contract To : {data && data.v_cont_to}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> : ''}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>



                                                    <div className='col-md-12  usersplittable usersplittable-um' >
                                                        <Table
                                                            className={localStorage.getItem("currentTheme")}
                                                            size={'small'}
                                                            key={i}
                                                            sortDirections={["ascend", "descend", "ascend"]}
                                                            columns={columns}
                                                            // columns={((moment(data && data.v_cont_to).isSameOrAfter(date)) && (moment(data && data.cont_to).isSameOrAfter(date))) ? columns : (moment(data && data.cont_to).isSameOrAfter(date)) ? columns2 : moment(date).isBefore(data && data.v_cont_to) ? columns3 : false}
                                                            dataSource={item && item.length > 0 && item.map((itemslist) => {
                                                                if (itemslist.user_name == null) {
                                                                    itemslist.user_name_new = ''
                                                                } else {
                                                                    itemslist.user_name_new = itemslist.user_name
                                                                }
                                                                return itemslist
                                                            })}
                                                            onChange={(pagination, filters, sorter, extra) => {
                                                                console.log(filters, sorter, extra, 'filters');
                                                                tablehandleChange(i, sorter.columnKey, sorter.order, filters, 'emp_name');
                                                                setParticularLeId(extra.currentDataSource && extra.currentDataSource[0].le_id)
                                                            }}
                                                            bordered
                                                            pagination={false}
                                                            showSorterTooltip={false}

                                                        />

                                                    </div>
                                                </Card>
                                            )
                                        }
                                        else {
                                            return false
                                        }
                                    })
                                    :
                                    <Card style={{ marginTop: "6px", borderRadius: "15px" }} className='userCard'>
                                        <div className='col-md-12'>

                                            <div className='row px-4'>


                                                <div className='col-md-4' >
                                                    <label ><b>Country: </b>{Entity && Entity.c_name}</label>
                                                </div>
                                                <div className='col-md-4'  >
                                                    <label ><b>Business Group:</b>  {Entity && Entity.b_g_name}</label>
                                                </div>
                                                <div className='col-md-4' >
                                                    <label><b >Legal Entity:</b><span style={{ marginLeft: "10px" }}></span>{Entity && Entity.le_name}</label>
                                                </div>

                                                {/* <hr></hr> */}
                                            </div>



                                            <div className="row mt-1">
                                                <div className="accordion" id="accordionExample ">
                                                    <div className="accordion-item" style={{ borderRadius: '15px' }}>
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button
                                                                className="accordion-button accor-head p-2"
                                                                type="button"
                                                                // id={i}
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseOne"
                                                                aria-expanded="true"
                                                                aria-controls="collapseOne"
                                                            >
                                                                <span><b>{Entity && Entity.le_name} - {usermangementData.length} Users</b></span>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="collapseOne"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="headingOne"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body pb-1">
                                                                <div className="row">
                                                                    <div className="col-12">


                                                                        <div
                                                                            id="carouselExampleControls"
                                                                            className="carousel slide carousel-dark user-carousal"
                                                                /*data-bs-ride="carousel"*/ data-bs-touch="false" data-bs-interval="false"
                                                                        >
                                                                            <div className="carousel-inner">
                                                                                <div className="carousel-item active">
                                                                                    <div className="row">
                                                                                        {(Entity && Entity.total_licences) && (moment(Entity && Entity.cont_to).isSameOrAfter(date)) ?
                                                                                            <div className="col-md-4 px-2">
                                                                                                <div className="card mb-0 w-100 overflow-hidden card-hover mb-3">
                                                                                                    <div className="card-body py-2 px-2">
                                                                                                        <div className="d-flex align-items-center ">
                                                                                                            <h4 className="mb-0 fs-3">Compfie Total Licence(s)</h4>

                                                                                                        </div>
                                                                                                        <div className="d-flex align-items-center my-3 mb-1">
                                                                                                            <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-white text-center rounded p-2"}>
                                                                                                                {/* :`border-end pe-3 ${localStorage.getItem("currentTheme")} text-white rounded p-2` */}
                                                                                                                <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                                <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{Entity && Entity.total_licences} </b>
                                                                                                            </div>
                                                                                                            <div className="ms-3 border-end pe-3 text-center">
                                                                                                                <h6 className="text-muted fw-normal">Used</h6>
                                                                                                                <b>{Entity && Entity.used_licences}</b>
                                                                                                            </div>
                                                                                                            <div className="ms-3 pe-3 text-center">
                                                                                                                <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                                <b>{Number(Entity && Entity.total_licences) - Number(Entity && Entity.used_licences)}</b>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div className="d-flex">
                                                                                                            <span className="fs-2  font-weight-medium">Contract From : {Entity && Entity.cont_from}</span>

                                                                                                            <span className="fs-2 ms-3 font-weight-medium">Contract To : {Entity && Entity.cont_to}</span>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> : ''}
                                                                                        {(Entity && Entity.l_total_licences) && (moment(Entity && Entity.l_cont_to).isSameOrAfter(date)) ?
                                                                                            <div className="col-md-4 px-2">
                                                                                                <div className="card mb-0 w-100 overflow-hidden  card-hover mb-3">
                                                                                                    <div className="card-body py-2 px-2">
                                                                                                        <div className="d-flex align-items-center">
                                                                                                            <h4 className="mb-0 fs-3">Compfie Litigation Total Licence(s)</h4>

                                                                                                        </div>
                                                                                                        <div className="d-flex align-items-center my-3 mb-1">
                                                                                                            <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-center text-white rounded p-2"}>
                                                                                                                <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                                <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{Entity && Entity.l_total_licences} </b>
                                                                                                            </div>
                                                                                                            <div className="ms-3 border-end pe-3 text-center">
                                                                                                                <h6 className="text-muted fw-normal">Used</h6>
                                                                                                                <b>{Entity && Entity.l_used_licences}</b>
                                                                                                            </div>
                                                                                                            <div className="ms-3 pe-3 text-center">
                                                                                                                <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                                <b>{Number(Entity && Entity.l_total_licences) - Number(Entity && Entity.l_used_licences)}</b>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div className="d-flex">
                                                                                                            <span className="fs-2  font-weight-medium">Contract From : {Entity && Entity.cont_from}</span>

                                                                                                            <span className="fs-2 ms-3 font-weight-medium">Contract To : {Entity && Entity.cont_to}</span>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> : ''}
                                                                                        {(Entity && Entity.v_total_licences) && (moment(Entity && Entity.v_cont_to).isSameOrAfter(date)) ?
                                                                                            <div className="col-md-4 px-2">
                                                                                                <div className="card mb-0 w-100 overflow-hidden card-hover mb-3">
                                                                                                    <div className="card-body py-2 px-2">
                                                                                                        <div className="d-flex align-items-center">
                                                                                                            <h4 className="mb-0 fs-3">Compfie Vendor Total Licence(s)</h4>

                                                                                                        </div>
                                                                                                        <div className="d-flex align-items-center my-3 mb-1">
                                                                                                            <div style={{ backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} className={"border-end pe-3 text-center text-white rounded p-2"}>
                                                                                                                <h6 className="fw-bold" style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>Total</h6>
                                                                                                                <b className='fw-bold' style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>{Entity && Entity.v_total_licences} </b>
                                                                                                            </div>
                                                                                                            <div className="ms-3 border-end pe-3 text-center">
                                                                                                                <h6 className="text-muted fw-normal">Used</h6>
                                                                                                                <b>{Entity && Entity.v_used_licences}</b>
                                                                                                            </div>
                                                                                                            <div className="ms-3 pe-3 text-center">
                                                                                                                <h6 className="text-muted fw-normal">Remaining</h6>
                                                                                                                <b>{Number(Entity && Entity.v_total_licences) - Number(Entity && Entity.v_used_licences)}</b>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div className="d-flex">
                                                                                                            <span className="fs-2  font-weight-medium">Contract From : {Entity && Entity.v_cont_from}</span>

                                                                                                            <span className="fs-2 ms-3 font-weight-medium">Contract To : {Entity && Entity.v_cont_to}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> : ''}
                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className='col-md-12   usersplittable usersplittable-um'>
                                            <Table
                                                id='service-user-priv'
                                                className={localStorage.getItem("currentTheme")}
                                                size={'small'}
                                                sortDirections={["ascend", "descend", "ascend"]}
                                                columns={columns2}
                                                // columns={((moment(Entity && Entity.v_cont_to).isSameOrAfter(date)) && (moment(Entity && Entity.cont_to).isSameOrAfter(date))) ? columns : (moment(Entity && Entity.cont_to).isSameOrAfter(date)) ? columns2 : moment(date).isBefore(Entity && Entity.v_cont_to) ? columns3 : false}
                                                dataSource={usermangementData && usermangementData.length > 0 && usermangementData.map((usermanlist) => {
                                                    if (usermanlist.user_name == null) {
                                                        usermanlist.user_name_new = ''
                                                    } else {
                                                        usermanlist.user_name_new = usermanlist.user_name
                                                    }
                                                    return usermanlist
                                                })}
                                                onChange={tablehandleChanges}
                                                bordered
                                                showSorterTooltip={false}
                                                pagination={false}
                                            />
                                        </div>

                                    </Card>
                                }
                                <div className='p-4 py-2 mb-0 rounded-3 user-mapping bg-white shadow-lg' >
                                    <div className="row">
                                        <div className='col-lg-3 col-md-4 col-6'>
                                            <i style={{
                                                color: "#f7bf17"
                                            }} className="bi bi-square-fill"></i> <label>Legal Entity Admin</label>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-6'>
                                            <i style={{ color: "#FFE699" }} className="bi bi-square-fill"></i> <label>Domain Admin</label>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-6'>
                                            <i style={{ color: '#6ecd2c' }} className="bi bi-square-fill"></i> <label>Client Executive</label>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-6'>
                                            <i style={{ color: "#C6E0B4" }} className="bi bi-square-fill"></i> <label>Service Provider User</label>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-6'>
                                            <i style={{ color: "#C9C9C9" }} className="bi bi-square-fill"></i> <label>View Only</label>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-6'>
                                            <i style={{ color: "#DBDBDB" }} className="bi bi-square-fill"></i> <label>View Only Restricted</label>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-6'>
                                            {/* <i style={{ color: "skyblue" }} class="bi bi-square-fill"></i> <label>Litigation User</label> */}
                                            <i style={{ color: "#FFE699" }} className="bi bi-square-fill"></i> <label>Litigation Creator</label>


                                        </div>
                                        <div className='col-lg-3 col-md-4 col-6'>
                                            <i style={{ color: "#a1b793" }} className="bi bi-square-fill"></i> <label>Litigation User</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal title="Country" className='modelradius' open={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                                maskClosable={false}>
                                <Search
                                    placeholder="Search"
                                // onSearch={onSearch}
                                // style={{
                                //     width: 200,
                                // }}
                                />
                            </Modal>
                            {/* <div className={scroll == true ? "d-block" : " "}>
                        <Button title="Scroll Back to Top" style={{ position: 'fixed', right: '30px', bottom: '50px', zIndex: 1, width: '34px', display: 'flex', justifyContent: 'center' ,borderRadius:'15px' }} onClick={() => { scrollup() }}><UpCircleOutlined style={{ fontSize: '1.5rem' }} /></Button>
                    </div> */}
                            <Modal title="Business Group" className='modelradius' open={business} onOk={handleOk} onCancel={handleCancel}
                                maskClosable={false}>
                                <Search
                                    placeholder="Search"
                                // onSearch={onSearch}
                                // style={{
                                //     width: 200,
                                // }}
                                />
                            </Modal>
                            <Modal title="Legal Entity" className='modelradius' open={legal} onOk={handleOk} onCancel={handleCancel}
                                maskClosable={false}>
                                <Search
                                    placeholder="Search"
                                // onSearch={onSearch}
                                // style={{
                                //     width: 200,
                                // }}
                                />
                            </Modal>

                            {/* <Modal visible={modalVisible} footer={null}
                        onCancel={passwordcancel}
                    >

                        <div className="row">
                            <div className="row pt-3">

                                <div className="col-md-6">
                                    <div className='mb-2'>
                                        <label className="control-label">Password:</label>
                                        <span style={{ "color": "red" }}>*</span>
                                        <input
                                            type="password"
                                            id="password"
                                            name='password'
                                            className="form-control"
                                            placeholder="Enter password"
                                            value={modalData.password}
                                            onChange={(e) => {
                                                setModalData({
                                                    ...modalData,
                                                    password: e.target.value
                                                })
                                            }}
                                        //   value={password.passwordvalue}
                                        />
                                        {formValidator.current.message(
                                            'password',
                                            modalData.password,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Password',
                                                }
                                            })}

                                    </div><br />
                                </div>


                                <div className="col-md-5">
                                    <div className="mb-2">
                                        <label className="control-label">Reason:</label>

                                        <span style={{ "color": "red" }}>*</span>
                                        <div className="col-md-2">
                                            <PlusSquareTwoTone onClick={() => { showRemarksModal() }} style={{ marginLeft: '650%' }} />
                                        </div>
                                        <textarea className='form-control'
                                            onChange={(e) => {
                                                setRemarks(e.target.value)
                                            }}
                                            value={remarks}

                                        ></textarea>

                                        {formValidator.current.message(
                                            'reason',
                                            remarks,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Reason',
                                                }
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="form-actions">
                            <div className="card-body border-top">
                                <button
                                    className="btn btn-light-success rounded-pill px-4 text-success"
                                // onClick={submitpassword}
                                >
                                    <div className="d-flex align-items-center" onClick={OnSubmited}>
                                        <i
                                            data-feather="save"
                                            className="feather-sm me-1 fill-icon"

                                        ></i>
                                        Ok
                                    </div>
                                </button>
                                <button
                                    className="btn btn-light-danger text-danger font-weight-medium
               waves-effect text-start"
                                    onClick={passwordcancel}
                                >
                                    <div className="d-flex align-items-center">
                                        <i data-feather="save" className="feather-sm me-1 fill-icon"></i>
                                        Cancel
                                    </div>
                                </button>
                            </div>
                        </div>

                    </Modal> */}

                            {/* <Modal visible={modalVisible} footer={null}
                onCancel={passwordcancel} className="newStyleModalPassword">
                <div className="modal-body" >
                            <div className="row">
                                <div className="col-md-6">
                                    <div class="mb-3">
                                    </div>
                                    <div className="mb-3">

                                        <input
                                            type="password"
                                            id="password"
                                            name='passwordvalue'
                                            className="form-control"
                                            placeholder="Enter password"
                                            onChange={(e) => {
                                                Setpassword({
                                                    ...passwordata,
                                                    passvalue: e.target.value

                                                })
                                            }}
                                            value={passwordata.passvalue}
                                        />
                                        {/* {formValidator.current.message(
                  'Password',
                  password.passwordvalue,
                  ['required'],
                  {
                    className: `invalid-feedback ${formValidator ? 'show' : 'hide'}`,
                    messages: {
                      regex: 'Enter Password'
                    }
                  })} */}
                            {/* </div>
                                </div><br />

                            </div>
                            <div className="form-actions">
                                <div className="card-body border-top">
                                    <button
                                        className="btn btn-light-success rounded-pill px-4 text-success"
                                        onClick={submitpassword}
                                    >
                                        <div className="d-flex align-items-center">
                                            <i
                                                data-feather="save"
                                                className="feather-sm me-1 fill-icon"
                                            ></i>
                                            Ok
                                        </div>
                                    </button>
                                    <button
                                        className="btn btn-light-danger text-danger font-weight-medium
               waves-effect text-start"
                                        onClick={passwordcancel}
                                    >
                                        <div className="d-flex align-items-center">
                                            <i data-feather="save" className="feather-sm me-1 fill-icon"></i>
                                            Cancel
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
            </Modal> */}
                            {loginResponse && loginResponse.usr_cat_id == 1 &&
                                <Modal title="Advanced Filter" open={filterModal} footer={null} maskClosable={false} className={"serviceProvider user-priv-modal add-service-prv modelradius " + localStorage.getItem('currentTheme')}
                                    closable={false}
                                >
                                    <div className='col-md-12'>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label ><b>Country</b><span style={{ color: "red" }}>*</span></label>
                                                {/* </div> */}
                                                {/* <div className='col-md-2'> */}
                                                <Select
                                                    title={''}
                                                    allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    size="default"
                                                    placeholder="Enter Country"
                                                    value={main.countryid || undefined}
                                                    onChange={(value, data) => {

                                                        if (value == undefined) {
                                                            setmain({
                                                                ...main,
                                                                country: '',
                                                                countryid: '',
                                                                entity: '',
                                                                business: ''
                                                            })
                                                            setCurrentEntity('')

                                                            setEntityValue(
                                                                ''
                                                            )
                                                            setBgArray([])
                                                            setCategoryValue({
                                                                ...categoryValue,
                                                                compfie: [],
                                                                vendor: []
                                                            })

                                                        }
                                                        else {
                                                            let filteredEntity = _.filter(legalEntity, { c_id: Number(value) })

                                                            // setFinallegalentity(filteredEntity)

                                                            let filteredBg = _.filter(entity_info, { c_id: Number(value) })
                                                            setFinallegalentity(filteredEntity)
                                                            if (filteredBg && filteredBg.length > 0) {
                                                                let uniqueObjArray = [...new Map(filteredBg && filteredBg.length > 0 && filteredBg.map((item) => [item["bg_id"], item])).values()];
                                                                const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                                                                    return item
                                                                })
                                                                setBgArray(uniqueIds)
                                                            }
                                                            setmain({
                                                                ...main,
                                                                country: data && data.children,
                                                                countryid: value,
                                                                business: '',
                                                                entity: ''
                                                            })
                                                            setCategoryValue({
                                                                ...categoryValue,
                                                                compfie: [],
                                                                vendor: []
                                                            })
                                                            setCurrentEntity('')
                                                        }
                                                    }}
                                                    // onSearch={onSearch}
                                                    optionFilterProp="children"

                                                    showSearch
                                                    filterOption={(input, option) =>

                                                        // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                        option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                    }
                                                    style={{ width: '100%' }}
                                                >
                                                    {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                        return (
                                                            <Option key={item.c_id}>
                                                                {item.c_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                                {entityFormvalidator.current.message(
                                                    '',
                                                    main.country,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${EntitySubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Country Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className="col-md-4">
                                                <label><b>Business Group</b></label>

                                                {/* </div> */}
                                                {/* <div className='col-md-2'> */}
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Business Group"
                                                    disabled={main.country ? false : true}
                                                    // placeholder="Enter Entity"
                                                    onChange={(data, value) => {
                                                        if (data == undefined) {
                                                            setmain({
                                                                ...main,
                                                                business: '',
                                                                entity: '',

                                                            })

                                                            setFinallegalentity(entity_info)
                                                            setCurrentEntity('')

                                                        }
                                                        else {
                                                            let filteredEntity = _.filter(entity_info, { bg_id: Number(data) })
                                                            setFinallegalentity(filteredEntity)
                                                            setmain({
                                                                ...main,
                                                                business: data,
                                                                businessName: value.children,
                                                                entity: ''
                                                            })

                                                            setCurrentEntity('')
                                                        }
                                                    }}
                                                    // onSearch={onSearch}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={main.business ? main.business : undefined}
                                                    style={{ width: '100%' }}

                                                >
                                                    {main.country ?
                                                        bgArray && bgArray.length > 0 && bgArray.map((item, i) => {
                                                            if (item.bg_name !== null) {
                                                                return (
                                                                    <Option key={item.bg_id}>
                                                                        {item.bg_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        }) : ''}
                                                </Select>
                                            </div>
                                            <div className="col-md-4">
                                                <label><b>Legal Entity</b></label>
                                                {/* </div> */}
                                                {/* <div className='col-md-2'> */}
                                                {/* {localStorage.getItem('SelectedEntity') === "All Legal Entity" ? */}
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    disabled={main.country ? false : true}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Legal Entity"
                                                    onChange={(data, value) => {
                                                        if (data == undefined) {
                                                            setmain({
                                                                ...main,
                                                                entity: ''
                                                            })
                                                        }
                                                        else {
                                                            setCurrentEntity(data)

                                                            setEntityValue(
                                                                data
                                                            )
                                                            setmain({
                                                                ...main,
                                                                entity: data
                                                            })
                                                        }
                                                        // setUnit({
                                                        //     ...unit,
                                                        //     legal_entity: data
                                                        // })
                                                    }}
                                                    // onSearch={onSearch}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={main.entity ? main.entity : undefined}
                                                    style={{ width: '100%' }}
                                                >


                                                    {finallegalEntity && finallegalEntity.length > 0 && finallegalEntity.map((item, i) => {

                                                        return (
                                                            main.country ?
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option> : ""
                                                        );
                                                    })}
                                                </Select>
                                                {/* :
                                        <p className='form-control'>{localStorage.getItem('SelectedEntity')}</p>

                                    } */}

                                                {/* {entityFormvalidator.current.message(
                                        'Entity',
                                        main.entity,
                                        'required',
                                        {
                                            className: `invalid-feedback ${EntitySubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Required Field Missing',
                                            }
                                        })} */}
                                            </div>
                                        </div>

                                        {/* <div className='row user-filter-warpper'> */}




                                        {/* <div className='col-md-1'></div> */}

                                        {/* <div className='col-md-1'></div> */}





                                    </div>
                                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                                        <div className="row">

                                            <div className="col-md-4">
                                                <label><b>Product Category</b><span style={{ color: "red" }}>*</span></label>
                                                {/* </div> */}
                                                {/* <div className='col-md-2'> */}
                                                {/* {localStorage.getItem('SelectedEntity') === "All Legal Entity" ? */}
                                                <Select
                                                    // disabled={main.country ? false : true}
                                                    allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    size="default"
                                                    placeholder="Enter Compfie Category"
                                                    onChange={(data, value) => {

                                                        console.log(data, 'data99999999');
                                                        setCategoryValue({
                                                            ...categoryValue,
                                                            compfie: data,
                                                            vendor: ''
                                                        })

                                                    }}
                                                    // onSearch={onSearch}
                                                    mode='multiple'
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={categoryValue.compfie ? categoryValue.compfie : undefined}
                                                    style={{ width: '100%' }}
                                                >



                                                    <Option key={1}>Compfie</Option>
                                                    <Option key={2}>Compfie Litigation</Option>
                                                    <Option key={3}>Compfie Vendor</Option>

                                                </Select>
                                                {/* :
                                        <p className='form-control'>{localStorage.getItem('SelectedEntity')}</p>

                                    } */}

                                                {entityFormvalidator.current.message(
                                                    'Entity',
                                                    categoryValue.compfie,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${EntitySubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Product Category Missing',
                                                        }
                                                    })}
                                            </div>
                                            <div className="col-md-4">
                                                <label><b>User Category</b></label>
                                                {/* </div> */}
                                                {/* <div className='col-md-2'> */}
                                                {/* {localStorage.getItem('SelectedEntity') === "All Legal Entity" ? */}
                                                <Select
                                                    // disabled={main.country ? false : true}
                                                    allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    size="default"
                                                    placeholder="Enter User Category"
                                                    mode='multiple'
                                                    onChange={(data, value) => {


                                                        setCategoryValue({
                                                            ...categoryValue,
                                                            vendor: data
                                                        })

                                                    }}

                                                    // onSearch={onSearch}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    disabled={categoryValue.compfie && categoryValue.compfie.length > 0 ? false : true}
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={categoryValue.vendor ? categoryValue.vendor : undefined}
                                                    style={{ width: '100%' }}
                                                >


                                                    <Option key={2}>View Only</Option>
                                                    <Option key={5}>Client Executive</Option>
                                                    <Option key={3}>Legal Entity Admin</Option>
                                                    <Option key={4}>Domain Admin</Option>
                                                    <Option key={8}>Litigation Creator</Option>
                                                    <Option key={6}>Service Provider User</Option>
                                                    <Option key={7}> View Only Restricted</Option>
                                                    <Option key={1}>Litigation User</Option>
                                                    <Option key={null}> Not Access</Option>
                                                </Select>
                                                {/* :
                                        <p className='form-control'>{localStorage.getItem('SelectedEntity')}</p>

                                    } */}

                                                {/* {entityFormvalidator.current.message(
                                        'Entity',
                                        main.entity,
                                        'required',
                                        {
                                            className: `invalid-feedback ${EntitySubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Required Field Missing',
                                            }
                                        })} */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="col-md-6" style={{ marginLeft: '33%' }}>
                                            <Button icon={<EyeOutlined />} shape="round" className='popupbtncolour' style={{
                                                borderColor: 'transparent',
                                                background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', marginLeft: '0px', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white', marginTop: '8%'
                                            }} onClick={(e) => {
                                                e.preventDefault()
                                                setEntityFormSubmit(true)
                                                if (entityFormvalidator.current.allValid()) {
                                                    if (EntityValue) {
                                                        if (tempusermagementListData && tempusermagementListData.length) {
                                                            let data = _.filter(tempusermagementListData, { le_id: EntityValue })
                                                            // setFilterArray(data)
                                                            // if (data && data.length > 0) {
                                                            //     const unique = _.uniqBy(data, function (e) {

                                                            //         return e.le_id;

                                                            //     });
                                                            //     setUserMagementData(data)

                                                            // }
                                                            if (categoryValue.compfie && categoryValue.compfie.length > 0) {
                                                                let array = []
                                                                let array2 = []
                                                                let array3 = []
                                                                let array4 = []
                                                                if (categoryValue.compfie && categoryValue.compfie.length > 0 && categoryValue.compfie.includes('1')) {
                                                                    let data1 = _.reject(data, {
                                                                        u_cat_id
                                                                            : null
                                                                    })
                                                                    if (data1 && data1.length > 0) {
                                                                        if (categoryValue.vendor.length > 0) {
                                                                            for (let i in categoryValue.vendor) {
                                                                                let UserType = _.filter(data1, {
                                                                                    u_cat_id
                                                                                        : Number(categoryValue.vendor[i])
                                                                                })
                                                                                if (UserType && UserType.length > 0) {
                                                                                    for (let i in UserType) {
                                                                                        array.push(UserType[i])
                                                                                    }
                                                                                }

                                                                            }
                                                                            console.log(array, 'UserType');
                                                                        }
                                                                        else {
                                                                            array = data1
                                                                        }
                                                                    }
                                                                }
                                                                if (categoryValue.compfie && categoryValue.compfie.length > 0 && categoryValue.compfie.includes('2')) {
                                                                    let data1 = _.reject(data, {
                                                                        u_type_id
                                                                            : null
                                                                    })
                                                                    if (data1 && data1.length > 0) {
                                                                        if (categoryValue.vendor.length > 0) {
                                                                            for (let i in categoryValue.vendor) {
                                                                                if (categoryValue.vendor[i] == '8') {
                                                                                    let UserType = _.filter(data1, {
                                                                                        u_type_id
                                                                                            : 2
                                                                                    })
                                                                                    if (UserType && UserType.length > 0) {
                                                                                        for (let i in UserType) {
                                                                                            array2.push(UserType[i])
                                                                                        }
                                                                                    }
                                                                                }
                                                                                else if (categoryValue.vendor[i] == '1') {
                                                                                    let UserType = _.filter(data1, {
                                                                                        u_type_id
                                                                                            : 3
                                                                                    })
                                                                                    if (UserType && UserType.length > 0) {
                                                                                        for (let i in UserType) {
                                                                                            array2.push(UserType[i])
                                                                                        }
                                                                                    }
                                                                                }


                                                                            }
                                                                            console.log(array, 'UserType');
                                                                        }
                                                                        else {
                                                                            array2 = data1
                                                                        }
                                                                    }
                                                                }
                                                                if (categoryValue.compfie && categoryValue.compfie.length > 0 && categoryValue.compfie.includes('3')) {
                                                                    let data1 = _.reject(data, {
                                                                        vndr_user_category
                                                                            : null
                                                                    })
                                                                    if (data1 && data1.length > 0) {
                                                                        if (categoryValue.vendor.length > 0) {
                                                                            for (let i in categoryValue.vendor) {
                                                                                let UserType = _.filter(data1, {
                                                                                    vndr_user_category
                                                                                        : Number(categoryValue.vendor[i])
                                                                                })
                                                                                if (UserType && UserType.length > 0) {
                                                                                    for (let i in UserType) {
                                                                                        array3.push(UserType[i])
                                                                                    }
                                                                                }

                                                                            }

                                                                        }
                                                                        else {
                                                                            array3 = data1
                                                                        }
                                                                    }
                                                                }
                                                                array4 = array.concat(array2, array3)
                                                                let uniqueUser = [...new Map(array4 && array4.length > 0 && array4.map((item) => [item["user_id"], item])).values()];
                                                                console.log(array, 'array');
                                                                console.log(array2, 'array2');
                                                                console.log(array3, 'array3');
                                                                console.log(array4, 'array4');
                                                                setUsermanagementListData(uniqueUser)
                                                                setFilterArray(uniqueUser)
                                                                setUserMagementData(uniqueUser)
                                                            }
                                                            else {
                                                                setFilterArray(data)
                                                                if (data && data.length > 0) {
                                                                    // const unique = _.uniqBy(data, function (e) {

                                                                    //     return e.le_id;

                                                                    // });
                                                                    setUserMagementData(data)

                                                                }
                                                            }
                                                        }
                                                        let LegalEntityData = _.find(UserLegalDetails && UserLegalDetails, { le_id: EntityValue })
                                                        setEntity(LegalEntityData)
                                                    }

                                                    else if (main.country) {
                                                        if (tempusermagementListData && tempusermagementListData.length) {
                                                            if (main.business !== '') {
                                                                console.log(main.business, 'main.business ');
                                                                let data = _.filter(tempusermagementListData, { country_name: main.country, b_g_name: main.businessName })
                                                                let array = []
                                                                let array2 = []
                                                                let array3 = []
                                                                let array4 = []
                                                                console.log(data, 'data5553533');
                                                                if (categoryValue.compfie && categoryValue.compfie.length > 0 && categoryValue.compfie.includes('1')) {
                                                                    let data1 = _.reject(data, {
                                                                        u_cat_id
                                                                            : null
                                                                    })
                                                                    if (data1 && data1.length > 0) {
                                                                        if (categoryValue.vendor.length > 0) {
                                                                            for (let i in categoryValue.vendor) {
                                                                                let UserType = _.filter(data1, {
                                                                                    u_cat_id
                                                                                        : Number(categoryValue.vendor[i])
                                                                                })
                                                                                if (UserType && UserType.length > 0) {
                                                                                    for (let i in UserType) {
                                                                                        array.push(UserType[i])
                                                                                    }
                                                                                }

                                                                            }
                                                                            console.log(array, 'UserType');
                                                                        }
                                                                        else {
                                                                            array = data1
                                                                        }
                                                                    }
                                                                }
                                                                if (categoryValue.compfie && categoryValue.compfie.length > 0 && categoryValue.compfie.includes('2')) {
                                                                    let data1 = _.reject(data, {
                                                                        u_type_id
                                                                            : null
                                                                    })
                                                                    if (data1 && data1.length > 0) {
                                                                        if (categoryValue.vendor.length > 0) {
                                                                            for (let i in categoryValue.vendor) {
                                                                                if (categoryValue.vendor[i] == '8') {
                                                                                    let UserType = _.filter(data1, {
                                                                                        u_type_id
                                                                                            : 2
                                                                                    })
                                                                                    if (UserType && UserType.length > 0) {
                                                                                        for (let i in UserType) {
                                                                                            array2.push(UserType[i])
                                                                                        }
                                                                                    }
                                                                                }
                                                                                else if (categoryValue.vendor[i] == '1') {
                                                                                    let UserType = _.filter(data1, {
                                                                                        u_type_id
                                                                                            : 3
                                                                                    })
                                                                                    if (UserType && UserType.length > 0) {
                                                                                        for (let i in UserType) {
                                                                                            array2.push(UserType[i])
                                                                                        }
                                                                                    }
                                                                                }


                                                                            }
                                                                            console.log(array, 'UserType');
                                                                        }
                                                                        else {
                                                                            array2 = data1
                                                                        }
                                                                    }
                                                                }
                                                                if (categoryValue.compfie && categoryValue.compfie.length > 0 && categoryValue.compfie.includes('3')) {
                                                                    let data1 = _.reject(data, {
                                                                        vndr_user_category
                                                                            : null
                                                                    })
                                                                    if (data1 && data1.length > 0) {
                                                                        if (categoryValue.vendor.length > 0) {
                                                                            for (let i in categoryValue.vendor) {
                                                                                let UserType = _.filter(data1, {
                                                                                    vndr_user_category
                                                                                        : Number(categoryValue.vendor[i])
                                                                                })
                                                                                if (UserType && UserType.length > 0) {
                                                                                    for (let i in UserType) {
                                                                                        array3.push(UserType[i])
                                                                                    }
                                                                                }

                                                                            }

                                                                        }
                                                                        else {
                                                                            array3 = data1
                                                                        }
                                                                    }
                                                                }
                                                                array4 = array.concat(array2, array3)
                                                                console.log(array, 'array');
                                                                console.log(array2, 'array2');
                                                                console.log(array3, 'array3');
                                                                console.log(array4, 'array4');
                                                                setUsermanagementListData(array4)
                                                            }
                                                            else if (main.business !== '' && categoryValue.compfie && categoryValue.compfie.length > 0) {
                                                                console.log('testtttttttttt11111');
                                                                let array = []
                                                                let array2 = []
                                                                let array3 = []
                                                                let array4 = []
                                                                if (categoryValue.compfie && categoryValue.compfie.length > 0 && categoryValue.compfie.includes('1')) {
                                                                    let data = _.reject(tempusermagementListData, {
                                                                        u_cat_id
                                                                            : null
                                                                    })
                                                                    if (data && data.length > 0) {
                                                                        if (categoryValue.vendor.length > 0) {
                                                                            for (let i in categoryValue.vendor) {
                                                                                let UserType = _.filter(data, {
                                                                                    u_cat_id
                                                                                        : Number(categoryValue.vendor[i])
                                                                                })
                                                                                if (UserType && UserType.length > 0) {
                                                                                    for (let i in UserType) {
                                                                                        array.push(UserType[i])
                                                                                    }
                                                                                }

                                                                            }
                                                                            console.log(array, 'UserType');
                                                                        }
                                                                        else {
                                                                            array = data
                                                                        }
                                                                    }
                                                                }
                                                                if (categoryValue.compfie && categoryValue.compfie.length > 0 && categoryValue.compfie.includes('2')) {
                                                                    let data = _.reject(tempusermagementListData, {
                                                                        u_type_id
                                                                            : null
                                                                    })
                                                                    if (data && data.length > 0) {
                                                                        if (categoryValue.vendor.length > 0) {
                                                                            for (let i in categoryValue.vendor) {
                                                                                if (categoryValue.vendor[i] == '8') {
                                                                                    let UserType = _.filter(data, {
                                                                                        u_type_id
                                                                                            : 2
                                                                                    })
                                                                                    if (UserType && UserType.length > 0) {
                                                                                        for (let i in UserType) {
                                                                                            array2.push(UserType[i])
                                                                                        }
                                                                                    }
                                                                                }
                                                                                else if (categoryValue.vendor[i] == '1') {
                                                                                    let UserType = _.filter(data, {
                                                                                        u_type_id
                                                                                            : 3
                                                                                    })
                                                                                    if (UserType && UserType.length > 0) {
                                                                                        for (let i in UserType) {
                                                                                            array2.push(UserType[i])
                                                                                        }
                                                                                    }
                                                                                }


                                                                            }
                                                                            console.log(array, 'UserType');
                                                                        }
                                                                        else {
                                                                            array2 = data
                                                                        }
                                                                    }
                                                                }
                                                                if (categoryValue.compfie && categoryValue.compfie.length > 0 && categoryValue.compfie.includes('3')) {
                                                                    let data = _.reject(tempusermagementListData, {
                                                                        vndr_user_category
                                                                            : null
                                                                    })
                                                                    if (data && data.length > 0) {
                                                                        if (categoryValue.vendor.length > 0) {
                                                                            for (let i in categoryValue.vendor) {
                                                                                let UserType = _.filter(data, {
                                                                                    vndr_user_category
                                                                                        : Number(categoryValue.vendor[i])
                                                                                })
                                                                                if (UserType && UserType.length > 0) {
                                                                                    for (let i in UserType) {
                                                                                        array3.push(UserType[i])
                                                                                    }
                                                                                }

                                                                            }

                                                                        }
                                                                        else {
                                                                            array3 = data
                                                                        }
                                                                    }
                                                                }
                                                                array4 = array.concat(array2, array3)
                                                                console.log(array, 'array');
                                                                console.log(array2, 'array2');
                                                                console.log(array3, 'array3');
                                                                console.log(array4, 'array4');
                                                                setUsermanagementListData(array4)

                                                            }

                                                            else {
                                                                let data = _.filter(tempusermagementListData, { country_name: main.country })
                                                                // setFilterArray(data)
                                                                console.log('testtttttttttt1236535');
                                                                // if (data && data.length > 0) {
                                                                //     const unique = _.uniqBy(data, function (e) {

                                                                //         return e.le_id;

                                                                //     });
                                                                //     setUsermanagementListData(data)

                                                                // }
                                                                if (categoryValue.compfie && categoryValue.compfie.length > 0) {
                                                                    let array = []
                                                                    let array2 = []
                                                                    let array3 = []
                                                                    let array4 = []
                                                                    if (categoryValue.compfie && categoryValue.compfie.length > 0 && categoryValue.compfie.includes('1')) {
                                                                        let data1 = _.reject(data, {
                                                                            u_cat_id
                                                                                : null
                                                                        })
                                                                        if (data1 && data1.length > 0) {
                                                                            if (categoryValue.vendor.length > 0) {
                                                                                for (let i in categoryValue.vendor) {
                                                                                    let UserType = _.filter(data1, {
                                                                                        u_cat_id
                                                                                            : Number(categoryValue.vendor[i])
                                                                                    })
                                                                                    if (UserType && UserType.length > 0) {
                                                                                        for (let i in UserType) {
                                                                                            array.push(UserType[i])
                                                                                        }
                                                                                    }

                                                                                }
                                                                                console.log(array, 'UserType');
                                                                            }
                                                                            else {
                                                                                array = data1
                                                                            }
                                                                        }
                                                                    }
                                                                    if (categoryValue.compfie && categoryValue.compfie.length > 0 && categoryValue.compfie.includes('2')) {
                                                                        let data1 = _.reject(data, {
                                                                            u_type_id
                                                                                : null
                                                                        })
                                                                        if (data1 && data1.length > 0) {
                                                                            if (categoryValue.vendor.length > 0) {
                                                                                for (let i in categoryValue.vendor) {
                                                                                    if (categoryValue.vendor[i] == '8') {
                                                                                        let UserType = _.filter(data1, {
                                                                                            u_type_id
                                                                                                : 2
                                                                                        })
                                                                                        if (UserType && UserType.length > 0) {
                                                                                            for (let i in UserType) {
                                                                                                array2.push(UserType[i])
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    else if (categoryValue.vendor[i] == '1') {
                                                                                        let UserType = _.filter(data1, {
                                                                                            u_type_id
                                                                                                : 3
                                                                                        })
                                                                                        if (UserType && UserType.length > 0) {
                                                                                            for (let i in UserType) {
                                                                                                array2.push(UserType[i])
                                                                                            }
                                                                                        }
                                                                                    }


                                                                                }
                                                                                console.log(array, 'UserType');
                                                                            }
                                                                            else {
                                                                                array2 = data1
                                                                            }
                                                                        }
                                                                    }
                                                                    if (categoryValue.compfie && categoryValue.compfie.length > 0 && categoryValue.compfie.includes('3')) {
                                                                        let data1 = _.reject(data, {
                                                                            vndr_user_category
                                                                                : null
                                                                        })
                                                                        if (data1 && data1.length > 0) {
                                                                            if (categoryValue.vendor.length > 0) {
                                                                                for (let i in categoryValue.vendor) {
                                                                                    let UserType = _.filter(data1, {
                                                                                        vndr_user_category
                                                                                            : Number(categoryValue.vendor[i])
                                                                                    })
                                                                                    if (UserType && UserType.length > 0) {
                                                                                        for (let i in UserType) {
                                                                                            array3.push(UserType[i])
                                                                                        }
                                                                                    }

                                                                                }

                                                                            }
                                                                            else {
                                                                                array3 = data1
                                                                            }
                                                                        }
                                                                    }
                                                                    array4 = array.concat(array2, array3)
                                                                    let uniqueUser = [...new Map(array4 && array4.length > 0 && array4.map((item) => [item["user_id"], item])).values()];
                                                                    console.log(array, 'array');
                                                                    console.log(array2, 'array2');
                                                                    console.log(array3, 'array3');
                                                                    console.log(array4, 'array4');
                                                                    setUsermanagementListData(uniqueUser)
                                                                    // setFilterArray(uniqueUser)
                                                                    setUserMagementData(uniqueUser)
                                                                }
                                                                else {
                                                                    // setFilterArray(data)
                                                                    if (data && data.length > 0) {
                                                                        // const unique = _.uniqBy(data, function (e) {

                                                                        //     return e.le_id;

                                                                        // });
                                                                        setUserMagementData(data)

                                                                    }
                                                                }
                                                            }
                                                        }
                                                        // let LegalEntityData = _.find(UserLegalDetails && UserLegalDetails, { le_id: EntityValue })
                                                        // setEntity(LegalEntityData)
                                                    }
                                                    setFilterModal(false)
                                                }
                                            }
                                            }>Show
                                            </Button>
                                            <Button icon={<CloseCircleOutlined />} shape="round" className='popupbtncolour' style={{ borderColor: 'transparent', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', marginLeft: '10px', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}
                                                onClick={() => {
                                                    setmain({
                                                        country: '',
                                                        entity: '',
                                                        business: ''
                                                    })
                                                    setFilterModal(false)
                                                    setEntityFormSubmit(false)
                                                    setCategoryValue({
                                                        compfie: [],
                                                        vendor: []
                                                    })
                                                    if (userManagementList.length > 0) {
                                                        let temp = []
                                                        for (let i in userManagementList) {
                                                            for (let j in userManagementList[i].le_id) {
                                                                let filteredcountry = _.filter(UserLegalDetails, { le_id: userManagementList[i].le_id[j] })
                                                                let obj = {
                                                                    "email_id": userManagementList[i].email_id,
                                                                    "emp_code": userManagementList[i].emp_code,
                                                                    "emp_name": userManagementList[i].emp_name,
                                                                    "is_active": userManagementList[i].is_active,
                                                                    "is_disable": userManagementList[i].is_disable,
                                                                    "le_id": userManagementList[i].le_id[j],
                                                                    "le_ids": userManagementList[i].le_id,
                                                                    "mob_no": userManagementList[i].mob_no,
                                                                    "u_cat_id": userManagementList[i].u_cat_id,
                                                                    "u_type_id": userManagementList[i].u_type_id,
                                                                    "unblock_days": userManagementList[i].unblock_days,
                                                                    "user_id": userManagementList[i].user_id,
                                                                    "user_name": userManagementList[i].user_name,
                                                                    "cont_from": UserLegalDetails[j].cont_from,
                                                                    "cont_to": UserLegalDetails[j].cont_to,
                                                                    "l_cont_from": UserLegalDetails[j].l_cont_from,
                                                                    "l_cont_to": UserLegalDetails[j].l_cont_to,
                                                                    "v_cont_from": UserLegalDetails[j].v_cont_from,
                                                                    "v_cont_to": UserLegalDetails[j].v_cont_to,
                                                                    "vndr_user_category": userManagementList[i].vndr_user_category,
                                                                    "country_name": filteredcountry && filteredcountry.length > 0 && filteredcountry[0].c_name,
                                                                    'b_g_name': filteredcountry && filteredcountry.length > 0 && filteredcountry[0].b_g_name

                                                                }
                                                                temp.push(obj)
                                                            }
                                                        }
                                                        console.log(temp, 'temp2324444444444444');
                                                        setUsermanagementListData(temp)
                                                        setFilterArray([])
                                                        setTempmanagementListData(temp)
                                                    }
                                                }}

                                            >Close
                                            </Button>
                                        </div>
                                    </div>



                                    {/* <div className="col-md-12 mt-3">
                            <div className="row">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">

                                    <Button icon={<EyeOutlined />} shape="round" className='popupbtncolour' style={{ background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', marginLeft: '10px', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} onClick={(e) => {
                                        e.preventDefault()
                                        setEntityFormSubmit(true)
                                        if (entityFormvalidator.current.allValid()) {
                                            if (EntityValue) {
                                                if (userManagementList && userManagementList.length) {
                                                    let data = _.filter(userManagementList, { le_id: EntityValue })
                                                    setFilterArray(data)
                                                }
                                                let LegalEntityData = _.find(UserLegalDetails && UserLegalDetails, { le_id: EntityValue })
                                                setEntity(LegalEntityData)
                                            }
                                            setFilterModal(false)
                                        }
                                    }}>Show
                                    </Button>

                                </div>
                                <div className="col-md-3" style={{ marginLeft: '0%' }}>
                                    <Button icon={<CloseCircleOutlined />} shape="round" className='popupbtncolour' style={{ background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', marginLeft: '-18px', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}
                                        onClick={() => {
                                            setFilterModal(false)
                                        }}
                                    >Close
                                    </Button>
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div>

                        </div> */}
                                </Modal>}

                            <Modal autoFocus={false} open={modalVisible} className='usr_modal_class modelradius' footer={null}
                                onCancel={passwordcancel} maskClosable={false}
                            >
                                {/* <div className="col-md-12"> */}
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor=""><b>Password</b> <span style={{ "color": "red" }}>*</span></label>
                                        <input
                                            ref={serviceProviderRef1}
                                            maxLength={20}
                                            autoFocus={true}
                                            type="password"
                                            id="password"
                                            name='passwordvalue'
                                            className="form-control"
                                            placeholder="Enter password"
                                            value={modalData.password}
                                            onChange={(e) => {
                                                setModalData({
                                                    ...modalData,
                                                    password: e.target.value
                                                })
                                            }}
                                            style={{ width: '95%' }}

                                        />
                                        {formValidator.current.message(
                                            'password',
                                            modalData.password,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Enter Password',
                                                }
                                            })}
                                    </div>
                                </div>
                                <br />
                                <div className="col-md-12">
                                    <div>
                                        <label htmlFor=""><b>Remarks </b> <span style={{ "color": "red" }}>*</span></label></div>
                                    <div className="d-flex align-items-end">
                                        <TextArea className='form-control' cols="3" rows="1" name='remark' placeholder="Enter Reason"

                                            onKeyPress={(event) => {
                                                if (!  /^[A-Z0-9a-z ! @  # $ % & * ( + = )  < > ? /_ .,-]+$/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={(e) => {
                                                // Setblockdata({
                                                //     ...blockdata,
                                                //     remarks: e.target.value
                                                // })

                                                const value = e.target.value;
                                                // onChange={(e) => {
                                                let chk_valid = ''
                                                console.log(value, 'value');
                                                for (let i = 0; i < value.length; i++) {
                                                    if (value[i] == '<' || value[i] == '>') {
                                                        chk_valid += value[i]
                                                    }
                                                }
                                                if (chk_valid.match('<>')) {
                                                    //   UpdatePannelRemarks[collapseId] = ''
                                                    setRemarks('')
                                                    Toaster.error('Please check the input, it having some invalid characters')
                                                } else {

                                                    const re = /[^|~^]+$/;
                                                    if (value === "" || re.test(value)) {
                                                        if (value.includes('~') || value.includes('^') || value.includes('|')) {
                                                            return false
                                                        } else {
                                                            setRemarks(e.target.value)
                                                        }
                                                    }

                                                }
                                            }}
                                            value={remarks}
                                        />
                                        <span className="text-info ms-1 d-flex 
                                align-items-center justify-content-center" style={{ float: 'right' }}
                                            onClick={() => { showRemarksModal() }}>
                                            <PlusCircleOutlined />
                                        </span>
                                    </div>
                                    {formValidator.current.message(
                                        'reason',
                                        remarks,
                                        ['required', { regex: /^[A-Z0-9a-z ! @  # $ % & * ( + = ) < > ? / _ .,-]+$/ }],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Remarks Required',
                                                regex: 'Enter Valid Remarks'
                                            }
                                        })}
                                </div>
                                {/* <div className="form-actions text-center"> */}
                                {/* <Button type="primary" shape="round" className='addbutton'
                                style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                                icon={<PlayCircleOutlined />} size='default'
                                onClick={OnSubmited}
                            >
                                Submit
                            </Button> */}
                                <div className={"form-actions pt-3  text-center popupbtncolour " + localStorage.getItem('currentTheme')}>
                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                        style={{ marginLeft: '-4%' }}
                                        icon={<PlayCircleOutlined />} size='default'
                                        onClick={OnSubmited}
                                    >
                                        Submit
                                    </Button>
                                    {/* <div className="button2" id={localStorage.getItem('currentTheme')} onClick={blockservice}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Submit
                    </div> */}
                                </div>
                                {/* <button
                                className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger
               waves-effect text-start"
                                onClick={passwordcancel}
                            > Cancel

                            </button> */}
                                {/* </div> */}
                            </Modal>

                            <Modal autoFocus={false} open={passvisible} footer={null} className="newStyleModalPassword modelradius" onCancel={passwordcancel} maskClosable={false}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-6">
                                            <label className="control-label"> <b>Password </b><span style={{ "color": "red" }}>*</span></label>
                                            <input
                                                ref={serviceProviderRef1}
                                                autoFocus={true}
                                                style={{ marginTop: "5px", width: '100%' }}
                                                type="password"
                                                id="password"
                                                name='passwordvalue'
                                                maxLength={20}
                                                className="form-control"
                                                placeholder="Enter password"
                                                onChange={(e) => {
                                                    Setpassword({
                                                        ...passwordata,
                                                        passvalue: e.target.value

                                                    })
                                                }}
                                                value={passwordata.passvalue}

                                            />
                                            {formValidator1.current.message(
                                                'password',
                                                passwordata.passvalue,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Enter Password',
                                                    }
                                                })}
                                        </div>
                                    </div>

                                </div>
                                <br />
                                <div className={"form-actions text-center popupbtncolour " + localStorage.getItem('currentTheme')}>
                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}

                                        icon={<PlayCircleOutlined />} size='default'
                                        onClick={submitpassword}
                                    >Submit
                                    </Button>
                                </div>
                            </Modal>
                            <Modal title="Remarks List" open={remarksModal} className={"remark-header modelradius add-service-prv " + localStorage.getItem('currentTheme')}
                                footer={false}
                                onCancel={handleCancel1}
                                maskClosable={false}
                            >
                                <Table
                                    // className='userprivclass'
                                    size={'small'}
                                    columns={RemarksColumn}
                                    dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                                    bordered
                                    // pagination={{
                                    //     defaultPageSize: dataTableProperties.pagesize,
                                    //     showSizeChanger: dataTableProperties.sizechanger,
                                    //     pageSizeOptions: dataTableProperties.pageSizeOptions
                                    // }}
                                    pagination={false}
                                // style={{width:'300px'}}

                                // onRowClick={{}}

                                />
                            </Modal>


                        </div >
                        :
                        <AddUserManagement details={details} setAddType={setAddType} showList={showList} setShowList={setShowList} AddType={AddType} fullscreen={fullscreen} setfullscreen={setfullscreen} />}

        </>

    )
}
export default connect(mapStateToProps, {
    getUsermanagementList,
    updatestatus,
    activeStatus,
    GetRemarksData,
    disableStatusChange,
    haveCompliance,
    getUsermanagementDetails,
    LegalAdmindisableStatusChange,
    getById,
    resendEmail,
    getAdminDetails
})(Usermanagement)


/*

{/* <div className='col-md-12'> */

/* <div className="card w-100 overflow-hidden">
<div className="card-body">
<div className="d-flex align-items-center">
<h4 className="mb-0">Outbound calls</h4>
<div className="ms-auto">
<span
className="
text-danger
fs-3
font-weight-medium
d-flex
align-items-center
"
>( <i className="ri-arrow-down-s-fill"></i> 18% High )</span
>
</div>
</div>
<div className="d-flex align-items-center my-3">
<div className="border-end pe-3">
<h6 className="text-muted fw-normal">Yearly</h6>
<b>80.40%</b>
</div>
<div className="ms-3 border-end pe-3">
<h6 className="text-muted fw-normal">Montly</h6>
<b>15.40%</b>
</div>
<div className="ms-3">
<h6 className="text-muted fw-normal">Day</h6>
<b>5.50%</b>
</div>
</div>
<a
href="javascript:void(0)"
className="btn btn-light-primary text-primary"
>Learn More</a
>
</div>
<div className="progress">
<div
className="
progress-bar progress-bar-striped
bg-primary
progress-bar-animated
"
style="width: 60%; height: 6px"
role="progressbar"
>
<span className="sr-only">60% Complete</span>
</div>
</div>
</div> 

<div className='row'>
<div className='col-md-4'>
<label ><b>Country: </b>{data && data.c_name}</label>
</div>
<div className='col-md-4' style={{ textAlign: "center" }}>
<label ><b>Business Group: </b>{data && data.b_g_name}</label>
</div>
<div className='col-md-4' tyle={{ textAlign: "center" }}>
<label><b >Legal Entity: </b>{data && data.le_name}</label>
</div>
{/* <hr></hr> 
</div>
</div>

{/* {
((data && data.total_licences) && (moment(data && data.cont_to).isSameOrAfter(date)))
?
<div className='col-md-12' style={{ marginTop: "10px" }}>
<div className='row'>
    <div className='col-md-6'>
        <label><span><b>Compfie Total Licence(s):</b></span> {data && data.total_licences} <span><b>Used:</b></span> {data && data.used_licences} | <span><b>Remaining:</b></span> {Number(data && data.total_licences) - Number(data && data.used_licences)}</label>
    </div>
    <div className='col-md-4'>
        <label style={{ textAlign: "center" }}><span><b>Contract From:</b></span> {data && data.cont_from}</label>
    </div>
    <div className='col-md-3'>
        <label style={{ textAlign: "center" }}><span><b>Contract To:</b></span> {data && data.cont_to}</label>
    </div>
</div>
</div> : ''}
{((data && data.l_total_licences) && (moment(data && data.l_cont_to).isSameOrAfter(date)))

?
<div className='col-md-12' style={{ marginTop: "10px" }}>
<div className='row'>
<div className='col-md-6'>
    <label><span><b>Compfie Litigation Total Licence(s):</b></span> {data && data.l_total_licences} <span><b>Used:</b></span> {data && data.l_used_licences} | <span><b>Remaining:</b></span> {Number(data && data.l_total_licences) - Number(data && data.l_used_licences)}</label>
</div>
<div className='col-md-3'>


    <label ><span><b>Contract From:</b></span> {data && data.l_cont_from}</label>
</div>
<div className='col-md-3'>
    <label ><span><b>Contract To:</b></span> {data && data.l_cont_to}</label>
</div>
</div>
</div>
: ''}
{((data && data.v_total_licences) && (moment(data && data.v_cont_to).isSameOrAfter(date))) ?
<div className='col-md-12'>



*/