import { Card, Tabs, Collapse, Select, Tooltip, Button, Table, Input, Upload, message, DatePicker } from 'antd';
import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import DataTable from "react-data-table-component";
import moment from 'moment';
import EncryptDecrypt from './../../../src/Libs/Encrypt&Decrypt'
import { Toaster } from '../../../src/Libs/Toaster'
import ScrollToTop from "react-scroll-to-top";
import {
    InfoCircleOutlined, ArrowRightOutlined, ArrowLeftOutlined, UpOutlined,
    FullscreenOutlined, PlayCircleOutlined, DownOutlined, ExclamationCircleTwoTone, InfoCircleTwoTone, CloudUploadOutlined
} from '@ant-design/icons';
import { getCompletedTask, getStatutoriesByUnit, SaveCompletedTask, UploadFile, Movetaskfile, getStatutoriesByUnitshowmore } from './../../Store/Action/Transcations/CompletedtaskYear'
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from '../Scrollbar';
import { dataValue } from 'react-widgets/cjs/Accessors';
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()

// import { file } from '@babel/types';
const { Option } = Select;
const _ = require("lodash");
const mapStateToProps = (state) => ({
    list: state.Completedtaskyear.completedTask,
    statutoryUnitList: state.Completedtaskyear.statutoryListByUnitshow,
    totalCount: state.Completedtaskyear.completedTask.totalCount,
    batchCount: state.Completedtaskyear.completedTask.batchCount,
    tzName: state.Completedtaskyear.completedTask.tzname,
    IsClosed: state.Completedtaskyear.completedTask.IsClosed,
    ctcyshowmoredata: state.Completedtaskyear.ctcyshowmore,


})
const CompletedTaskCurrentYear = ({
    getCompletedTask, list, getStatutoriesByUnit, statutoryUnitList, tzName, batchCount, totalCount, SaveCompletedTask, IsClosed,
    UploadFile, Movetaskfile, getStatutoriesByUnitshowmore, ctcyshowmoredata, statutoryListByUnitshow, ctcyshowmore
}) => {
    console.log(statutoryUnitList, "statutoryUnitList")
    console.log(tzName, 'tzName');
    console.log(ctcyshowmoredata, "ctcyshowmoredata");
    const [count, setCount] = useState({
        batch_count: 0,
        start_count: 0
    })
    const [uploadData, setUploadData] = useState([])
    const historynew = useHistory();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    console.log(uploadData, 'uploadData');
    const { TabPane } = Tabs;
    const { Panel } = Collapse;
    const [fullscreen, setfullscreen] = useState(false);
    const [cid, setCID] = useState(null)
    const [datacheckBox, setDataCheckbox] = useState([])
    console.log(datacheckBox, 'datacheckBox14253333333333');
    const [unit, setUnit] = useState({
        legal_entity: '',
        division: '',
        category: '',
        unit: ''
    })
    const [complianceStatus, setComplianceStatus] = useState([])
    console.log(complianceStatus, 'complianceStatus');
    const [updateDate, setUpdateDate] = useState([])
    console.log(updateDate, 'updateDate');
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '20', '30']
    })
    const [rowcomplianceid, setRowComplianceId] = useState(null)
    console.log(rowcomplianceid, "rowcomplianceid");
    const [show, setShow] = useState(false)
    const [fileData, setFileData] = useState({})
    const [option, setOption] = useState([])
    const [tempList, setTempList] = useState([])
    const [collapse, setCollapse] = useState([])
    const [collapseValue, setCollapseValue] = useState([])
    const [ellipsis, setEllipsis] = useState(false)
    const [domains, setDomain] = useState({
        domain: '',
        acts: '',
        frequency: ''
    })
    const [SavepastRecords, setSavePastRecords] = useState([])
    console.log(SavepastRecords, "SavepastRecords");
    const [checkbox, setCheckBox] = useState(false)
    const [checkboxData, setCheckBoxData] = useState([])
    const [viewfreq, setviewfreq] = useState(false);
    const [Act, setAct] = useState([])
    const [entity, setEntity] = useState([{
        le_id: localStorage.getItem('SelectedEntityid'),
        le_name: localStorage.getItem('SelectedEntity')

    }])
    const [category, setCategory] = useState([
    ])
    const [dataList, setDataList] = useState([
    ])
    console.log(dataList, 'dataList');
    const [entires, setEntires] = useState(0)
    const [status, setStatus] = useState([])
    console.log(status, "statusstatus");
    const [units, setUnits] = useState([
    ])
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    //   const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [FormSubmit, setFormSubmit] = useState(false);
    const [selectUnit, setSelectUnit] = useState(false);
    const [fileupload, setFileUpload] = useState([])
    const [selectDomain, setSelectDomain] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const Domainvalidator = useRef(new SimpleReactValidator());
    const [tab, setTab] = useState("1")
    const [date, setDate] = useState([])
    console.log(date, 'date');
    const [checkbox1, setCheckBox1] = useState([])
    const [entityid, setCurrentEntity] = useState('');
    const [updatedArray, setUpdatedArray] = useState([]);
    console.log(updatedArray, "updatedArray");
    const [updatedArrayshow, setUpdatedArrayShow] = useState([]);
    const [updatedArrayShowmore, setUpdatedArrayShowmore] = useState([]);
    const [temp1, setTemp] = useState([])
    console.log(temp1, "temp1");
    const [domainValue, setDomainValue] = useState(false)
    const [domainFilter, setDomainFilter] = useState([])
    console.log(validator.current.allValid(), 'validator.current.allValid()');
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        console.log(list.domain, 'list.domain')
        if (unit.unit != '' && list.domain && list.domain.length == 1) {
            setDomainValue(true)
            setDomain({
                ...domains,
                domain: list.domain[0].d_id,
            })
        } else {
            setDomainValue(false)
            setDomainFilter(list.domain)

        }
    }, [unit.unit, list.domain])

    const onChange = (key) => {
        setTab(key)
    };
    const showMore = () => {
        setShow(true)
        // setDataList([])
        const payloads = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetStatutoriesByUnit",
                    {
                        'le_id': localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                        'unit_id': Number(unit.unit),
                        'domain_id': Number(domains.domain),
                        'level_1_statutory_name': domains.acts,
                        'compliance_task_frequency': domains.frequency,
                        'start_count': entires,
                        'batch_count': count.batch_count + 1

                    }

                ]

            }
        ]
        getStatutoriesByUnitshowmore({
            payload: payloads,
            paramid: paramid
        })

    }
    const handlePasting = (e, regex) => {
        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
    }
    useEffect(() => {
        if (date.length > 0) {
            if (temp1.length > 0) {
                let tempArray = []

                for (let i in temp1) {
                    for (let k in date) {
                        if (temp1[i].uniqueId == date[k].uniqueId) {
                            if (updateDate[temp1[i].childId] !== '') {
                                // console.log(date[i].uniqueId, text, "indexindex");

                                tempArray.push({
                                    unit_id: Number(unit.unit),
                                    compliance_id: temp1[i].compliance_id,
                                    due_date: temp1[i].due_date,
                                    completion_date: moment(date[k].date).format('DD-MMM-YYYY'),
                                    documents: fileupload.length ? [{
                                        file_size: fileupload[0].file_size,
                                        file_name: fileupload[0].file_name,
                                        file_content: null
                                    }] : [],
                                    validity_date: null,
                                    pr_completed_by: 2,
                                    amendment_history_id: temp1[i].amendment_history_id,
                                    uniqueId: temp1[i].uniqueId,
                                    // indexValue: index,
                                    repeats_type: temp1[i].repeats_type.toString()
                                })
                            }
                        }
                    }

                }
                setSavePastRecords(tempArray)


            }
            else {
                setSavePastRecords([])
            }

        }
    }, [date, fileupload, temp1])


    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            setUnit({
                ...unit,
                legal_entity: localStorage.getItem('SelectedEntityid')
            })
        }
    }, [localStorage.getItem('SelectedEntity') != "All Legal Entity"])
    const columns = [
        {
            title: <label>Completed<span style={{ color: "red" }}>*</span></label>,
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            width: '80px',
            align: 'center',
            render: (text, record, index) => {
                // let data = _.find(date, { uniqueId: record.uniqueId })
                // console.log(data, 'data6698888888');
                return (
                    <input
                        type="checkbox"
                        // value={data && data.date}
                        checked={datacheckBox && datacheckBox.length > 0 && datacheckBox.includes(record.childId)}
                        onChange={(e) => {
                            let temparr = []
                            console.log(record, "eeeeeeeeeee");
                            let tempArray = []
                            let tempArray2 = []
                            let temp = status;
                            const index1 = temp.indexOf(index);

                            if (index1 > -1) {
                                temp.splice(index1, 1);
                                setStatus([...temp])
                            } else {
                                temp.push(index)
                                setStatus([...temp])

                            }
                            if (e.target.checked == true) {
                                tempArray.push(record)
                                setTemp([...temp1, ...tempArray])
                                tempArray2.push(record.childId)
                                setDataCheckbox([...datacheckBox, ...tempArray2])

                            }
                            else {
                                temp1 && temp1.length > 0 && temp1.map((item, i) => {
                                    if (item.uniqueId === record.uniqueId) {
                                        temp1.splice(i, 1)
                                        setTemp([...temp1, ...tempArray])
                                    }
                                })
                                datacheckBox && datacheckBox.length > 0 && datacheckBox.map((item, i) => {
                                    if (item === record.childId) {
                                        datacheckBox.splice(i, 1)
                                        setDataCheckbox([...datacheckBox, ...tempArray2])
                                    }
                                })
                                date && date.length > 0 && date.map((item, i) => {
                                    if (item.uniqueId === record.uniqueId) {


                                        date && date.length && date.splice(i, 1);

                                    }

                                })
                                temparr.push({
                                    date: "",
                                    index: index,
                                    uniqueId: record.uniqueId,
                                    collapse: record.primary_legislation
                                })
                                setDate([...date, ...temparr])
                                updateDate[record.childId] = ''
                                setUpdateDate([...updateDate])
                                SavepastRecords && SavepastRecords.length > 0 && SavepastRecords.map((item, i) => {
                                    if (item.uniqueId === record.uniqueId) {
                                        SavepastRecords && SavepastRecords.length && SavepastRecords.splice(i, 1);

                                    }

                                })
                                setSavePastRecords([...SavepastRecords])

                            }

                            console.log(date, 'indexindexxxxxxxxxxxxxxxx')
                            // if (e.target.checked) {
                            //     console.log(date, 'indexindexxxxxxxxxxxxxxxx')
                            //     for (let i in date) {
                            //         console.log(date[i].uniqueId, text, "indexindex");
                            //         if (date[i].uniqueId == text) {
                            //             console.log(date[i].uniqueId, "hhhhhhh");
                            //             console.log(record, "texttext");
                            //             tempArray.push({
                            //                 unit_id: Number(unit.unit),
                            //                 compliance_id: record.compliance_id,
                            //                 due_date: record.due_date,
                            //                 completion_date: moment(date[i].date).format('DD-MMM-YYYY'),
                            //                 documents: fileupload.length ? [{
                            //                     file_size: fileupload[0].file_size,
                            //                     file_name: fileupload[0].file_name,
                            //                     file_content: null
                            //                 }] : [],
                            //                 validity_date: null,
                            //                 pr_completed_by: 2,
                            //                 amendment_history_id: record.amendment_history_id,
                            //                 indexValue: index,
                            //                 repeats_type: record.repeats_type.toString()
                            //             })
                            //         }

                            //     }

                            //     setSavePastRecords([...SavepastRecords, ...tempArray])
                            //     setCheckBox(true)
                            //     let temparr = []
                            //     temparr.push(record.uniqueId)
                            //     setCheckBoxData([...checkboxData, ...temparr])
                            // }

                            // else {
                            //     for (let i in SavepastRecords) {
                            //         if (SavepastRecords[i].indexValue === index) {
                            //             SavepastRecords.splice(i, 1)
                            //         }

                            //     }
                            //     for (let i in checkboxData) {
                            //         if (checkboxData[i] == record.uniqueId)
                            //             checkboxData.splice(i, 1)
                            //     }

                            //     setCheckBox(false)

                            // }


                        }}
                    />
                )
            }
        },
        {
            title: 'Compliance Task',
            dataIndex: 'description',
            key: 'description',
            // ellipsis: true,
            width: '210px',
            render: (text, record) => {
                <span style={{ marginLeft: '3px' }}> <InfoCircleTwoTone /></span>
                return <><Tooltip title={text} >
                    <span style={{ marginLeft: '3px' }}> <InfoCircleTwoTone /></span>
                </Tooltip>
                    <span style={{ marginLeft: '5px' }}>{record.compliance_name}</span></>
            }
        },

        {
            title: 'Repeats Every',
            dataIndex: 'pr_statutory_date',
            key: 'pr_statutory_date',
            width: '100px',
        },
        {
            title: <label>Due Date <span style={{ color: "red" }}>*</span></label>,
            dataIndex: 'due_date',
            key: 'due_date',
            width: '80px',
        },
        {
            title: <Tooltip title="Task Completion Date / Document Issued Date">Date<span style={{ color: "red" }}>*</span></Tooltip>,
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            width: '120px',
            render: (text, record, index) => {
                return (
                    <DatePicker placeholder="DD-MM-YYYY" type="date"
                        format={'DD-MMM-YYYY'}
                        renderExtraFooter={() => <center><b>{tzName}</b></center>}
                        onKeyDown={(event) => {
                            event.preventDefault()
                        }}
                        allowClear={false}
                        defaultValue={updateDate[record.childId] ? moment(updateDate[record.childId]) : undefined}
                        key={updateDate[record.childId] ? moment(updateDate[record.childId]) : undefined}
                        max={new Date().toISOString().split('T')[0]}
                        disabledDate={(current) => {

                            return (moment().subtract(6, "years") >= current || moment() <= current

                            );

                        }}
                        style={{ marginTop: '5px' }}
                        onChange={(e) => {
                            console.log(e._d, 'e._d');
                            console.log(e, 'eeee');
                            console.log(updateDate[record.childId], 'updateDate[record.childId]');
                            let temparr = []
                            let temp = []
                            let temp2 = []
                            collapseValue.map((item, i) => {
                                if (item === text) {
                                    collapseValue.length > 0 && collapseValue.splice(i, 1);
                                    setCollapseValue([...collapseValue, ...temp])
                                }

                            })
                            let rowDate = moment(record.due_date).format('DD-MMM-YYYY')
                            let givenDate = moment(e._d).format('DD-MMM-YYYY')
                            if (moment(rowDate).isBefore(givenDate)) {
                                temp.push(text)
                                complianceStatus[record.childId] = 2

                            }
                            else {
                                complianceStatus[record.childId] = 1
                            }

                            temp2.push({
                                uniqueId: text,
                                date: e._d,
                                index: index,
                                collapse: record.primary_legislation
                            }


                            )
                            updateDate[record.childId] = e._d
                            setUpdateDate([...updateDate])
                            setCollapse([...collapse, ...temp2])
                            setCollapseValue([...collapseValue, ...temp])
                            setComplianceStatus([...complianceStatus])
                            date.map((item, i) => {
                                if (item.uniqueId === text) {
                                    date && date.length && date.splice(i, 1);
                                    setDate([...date, ...temparr])
                                }

                            })
                            temparr.push({
                                date: e._d,
                                index: index,
                                uniqueId: text,
                                collapse: record.primary_legislation
                            })
                            setDate([...date, ...temparr])
                            let temparr2 = []
                            temparr2.push(text)
                            setOption([...option, ...temparr2])

                        }

                        }
                    // disabledDate={(current) => {
                    //     return (moment().subtract(7,"years") >= current || moment() <= current
                    //     );
                    //   }}
                    ></DatePicker >
                )
            }
        },
        {
            title: 'Document Upload',
            dataIndex: 'description',
            key: 'description',
            width: '110px',
            // ellipsis: ellipsis,
            render: (text, record, index) => {
                return (
                    <>
                        <label htmlFor={index} className='btn btn-primary upload-btn p-1 d-flex justify-content-center align-items-center'><CloudUploadOutlined /></label>
                        <input
                            id={record.childId}
                            type="file"
                            multiple
                            accept='.doc, .docx, .rtf, .pdf, .txt, .png, .jpeg, .gif, .csv, .xls, .xlsx, .rar, .tar, .gz, .ppt,.pptx, .jpg, .bmp, .odt, .odf, .ods,.xml'
                            name="file"
                            className={ellipsis ? 'file-upload-ctcy' : ''}
                            onChange={(e) => {
                                SavepastRecordOnChange(record, e)
                                if (e.target.value != '' || e.target.value != (null || undefined)) {
                                    setEllipsis(true)
                                } else {
                                    setEllipsis(false)
                                }
                            }
                            }
                        />
                    </>
                )
            }
        },
        {
            title: <label>Assignee <span style={{ color: "red" }}>*</span></label>,
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            width: '100px',
        },
        {
            title: <label>Compliance Status <span style={{ color: "red" }}>*</span></label>,
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            width: '115px',
            render: (text, record) => {

                return (
                    <>
                        <Select name="option"
                            className="form-control" value={complianceStatus[record.childId]}
                            onChange={(e) => {
                                complianceStatus[record.childId] = e
                                setComplianceStatus([...complianceStatus])
                            }}
                        >
                            <Option value={1}>Complied </Option>
                            <Option value={2} >Delayed Complied</Option>
                        </Select>
                    </>
                )




            }
        },

    ];

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content
        };
        return result;
    }


    const convert_to_base64 = (file, name, size, callback) => {
        var reader = new FileReader();
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            var binary = "";
            reader.onload = function (readerEvt) {
                var bytes = new Uint8Array(readerEvt.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                callback(EncryptDecrypt.encryptdata(binary), name, size);
            }
            reader.readAsArrayBuffer(file);
        } else {
            reader.onload = function (readerEvt) {
                var binaryString = readerEvt.target.result;
                callback(EncryptDecrypt.encryptdata(binaryString), name, size);
            };
            reader.readAsBinaryString(file);
        }
    }
    const props = {
        name: 'file',

        onChange(info) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                // message.success(`${info.file.name} file uploaded successfully`)
                console.log('yyyyyyyyyyy', info.file)
            } else if (info.file.status === 'error') {
                console.log('yyyyyyyyyyy', info.file)
                // message.error(`${info.file.name} file upload failed.`);
            }
        },
    };





    const SavepastRecordOnChange = (row, e) => {
        setRowComplianceId(row.amendment_history_id)
        if (e.target.name === 'file') {
            var results = [];
            const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/;
            let FILE_TYPE = [
                "doc", "docx", "rtf", "pdf", "txt", "png", "jpeg", "gif", "csv", "xls", "xlsx",
                "rar", "tar", "gz", "ppt", "pptx", "jpg", "bmp", "odt", "odf", "ods", "xml"
            ];
            let files = e.target.files;
            let file_max_size = 26214400;
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var file_name = file.name;

                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
                if (fileRegex.test(f_name) === false) {
                    Toaster.error("File Name have not allowed special charaters");
                    e.target.value = ''
                    return;
                }
                else {
                    var file_extension = file_name.substring(file_name.lastIndexOf('.') + 1).toLowerCase();
                    console.log(file_extension, 'file_extension');
                    if (FILE_TYPE.includes(file_extension) == true) {
                        if (fileRegex.test(f_name) === false) {
                            return;
                        }
                        let file_size = file.size;
                        if (file_size > file_max_size) {
                            Toaster.error("Maximum filesize limit exceed");
                            e.target.value = ''
                            return;
                        }
                        if (file) {
                            convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
                                // if (file_content == null) {
                                //     // callback(message.file_content_empty);
                                // }
                                var fN = name.substring(0, name.lastIndexOf('.'));
                                var fE = name.substring(name.lastIndexOf('.') + 1);
                                var uniqueId = Math.floor(Math.random() * 90000) + 10000;
                                var f_Name = fN + '-' + uniqueId + '.' + fE;

                                var result = uploadFileFormat(size, f_Name, file_content);
                                setFileData(result)
                                results.push(result);
                                setFileUpload(results)
                                let temp = []
                                if (results.length > 0) {
                                    for (let i in results) {
                                        temp.push(results[i].file_name)
                                    }
                                }
                                uploadData[row.childId] = temp
                                setUploadData([...uploadData])
                                // if (results.length == files.length) {
                                //     callback(results);
                                // }
                                const filePayload = [
                                    authtoken,
                                    {
                                        "session_token": authtoken,
                                        "request": [
                                            "UploadComplianceTask_temp_upload",
                                            {
                                                "le_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                                                "c_id": cid,
                                                "d_id": Number(domains.domain),
                                                "u_id": Number(unit.unit),
                                                "start_date": moment().format("D-MMM-YYYY"),
                                                "file_info":
                                                    results,
                                                "compliance_history_id": Number(row.amendment_history_id)
                                            }
                                        ]
                                    }
                                ]
                                UploadFile({
                                    payload: filePayload,
                                    paramid: paramid
                                })
                                setFileUpload(results)
                            });
                        }

                    } else {
                        Toaster.error('Invalid File Format');
                        e.target.value = ''
                        return;
                    }
                }

            }
        }
        React.forwardRef(({ onClick, ...rest }, ref) => {
            return (
                <>
                    <div className="form-check pb-5" style={{ backgroundColor: '' }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ height: '20px', width: '20px' }}
                            ref={ref}
                            onClick={onClick}
                            {...rest}
                        />
                        <label className="form-check-label" id="booty-check" />
                    </div>
                </>
            );
        });
    };



    // Next Response
    useEffect(() => {
        if (statutoryUnitList) {
            let arrayTemp = [];
            for (let i in statutoryUnitList) {
                arrayTemp.push(statutoryUnitList[i]);
            }
            var merged = [].concat.apply([], arrayTemp);
            setUpdatedArray([...merged]);
            setUpdatedArrayShow([...merged]);
        }
    }, [statutoryUnitList]);

    // Showmore Response
    useEffect(() => {
        if (ctcyshowmoredata) {
            let arrayTemp = [];
            for (let i in ctcyshowmoredata) {
                arrayTemp.push(ctcyshowmoredata[i]);
            }
            var merged = [].concat.apply([], arrayTemp);
            console.log(merged, 'merged');
            setUpdatedArrayShowmore([...merged]);
        }
    }, [ctcyshowmoredata]);

    // Merged response
    useEffect(() => {
        if (updatedArrayShowmore.length > 0) {
            // setUpdatedArray([]);
            setUpdatedArray([...updatedArray, ...updatedArrayShowmore]);
        }
    }, [updatedArrayShowmore]);

    useEffect(() => {

        // if (list && list.category && list.category.length > 0) {
        //     setCategory(list.category)
        // }
        if (totalCount && batchCount) {
            setCount({
                start_count: totalCount,
                batch_count: batchCount
            })
        }
    }, [totalCount && batchCount])

    // useEffect(() => {
    //     if (updatedArray && updatedArray) {
    //         if (show == true) {
    //             let tempArr = tempList.concat(updatedArray)
    //             setTempList(tempArr)
    //             // setDataList([dataList,statutoryUnitList && statutoryUnitList.statutoryListByUnit])
    //         }
    //         else {
    //             setTempList(updatedArray)
    //         }

    //     }
    // }, [updatedArray, show])

    useEffect(() => {
        setDataList(updatedArray && updatedArray)
        let array = [];
        let count = 0
        let finalarray = [];
        let datavalue = [];
        if (updatedArray && updatedArray) {
            const ab = updatedArray && updatedArray.map(function (item) {
                if ((array.indexOf(item.level_1_statutory_name) < 0))
                    array.push(item.level_1_statutory_name)
            })
            array.map((data, i) => {
                // let array2 = [];
                let temp = [];
                let entityArray = [];
                if (data) {
                    updatedArray && updatedArray.map((child, k) => {
                        if (data === child.level_1_statutory_name) {
                            child && child.pr_compliances.length && child.pr_compliances.map((item, j) => {
                                console.log(item, 'itemeeee')

                                entityArray = {
                                    amendment_history_id: item.amendment_history_id,
                                    assignee_id: item.assignee_id,
                                    assignee_name: item.assignee_name,
                                    compliance_id: item.compliance_id,
                                    compliance_name: item.compliance_name,
                                    compliance_task_frequency: item.compliance_task_frequency,
                                    description: item.description,
                                    due_date: item.due_date,
                                    pr_statutory_date: item.pr_statutory_date,
                                    primary_legislation: item.primary_legislation,
                                    secondary_legislation: item.secondary_legislation,
                                    statutory_provision: item.statutory_provision,
                                    task_code: item.task_code,
                                    uniqueId: Math.floor(Math.random() * 100),
                                    repeats_type: item.repeats_type.toString(),
                                    childId: count

                                }
                                temp.push(entityArray)
                                updateDate[count] = ''
                                uploadData[count] = []
                                complianceStatus[count] = 1
                                count = count + 1
                                console.log(entityArray, 'entityArray')
                            })

                        }
                    })
                    setUpdateDate([...updateDate])
                    setUploadData([...uploadData])
                    setComplianceStatus([...complianceStatus])
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            setDataList(finalarray)
        }

    }, [updatedArray])

    useEffect(() => {
        setDataList(tempList)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tempList) {
            const ab = tempList.map(function (item) {
                if ((array.indexOf(item.level_1_statutory_name) < 0))
                    array.push(item.level_1_statutory_name)
            })
            array.map((data, i) => {
                // let array2 = [];
                let temp = [];
                let entityArray = [];
                if (data) {
                    tempList.map((child, k) => {
                        console.log(child, 'itemeeeeee')

                        if (data === child.level_1_statutory_name) {
                            child && child.pr_compliances.length && child.pr_compliances.map((item, j) => {
                                entityArray = {
                                    amendment_history_id: item.amendment_history_id,
                                    assignee_id: item.assignee_id,
                                    assignee_name: item.assignee_name,
                                    compliance_id: item.compliance_id,
                                    compliance_name: item.compliance_name,
                                    compliance_task_frequency: item.compliance_task_frequency,
                                    description: item.description,
                                    due_date: item.due_date,
                                    pr_statutory_date: item.pr_statutory_date,
                                    primary_legislation: item.primary_legislation,
                                    secondary_legislation: item.secondary_legislation,
                                    statutory_provision: item.statutory_provision,
                                    task_code: item.task_code,
                                    uniqueId: Math.floor(Math.random() * 100),
                                    childId: k
                                }
                                temp.push(entityArray)
                            })

                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            setDataList(finalarray)
        }

    }, [tempList])


    useEffect(() => {

        // if (list && list.category && list.category.length > 0) {
        //     setCategory(list.category)
        // }
        if (list && list.unit && list.unit.length > 0) {
            setUnits(list.unit)
        }
    }, [list])



    useEffect(() => {

        if (isAuth) {
            if (entityid !== '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request":
                            [
                                "GetPastRecordsFormData",
                                {
                                    "le_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid
                                }
                            ]

                    }
                ]
                if (entityid != 'null') {
                    getCompletedTask({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
        }
    }, [isAuth, entityid])
    useEffect(() => {
        validator.current.showMessages()
    }, [])
    useEffect(() => {
        if (list && list.act) {
            setAct(Object.values(list.act))

        }
    }, [list.act])
    useEffect(() => {
        Domainvalidator.current.showMessages()
    }, [])
    useEffect(() => {
        if (dataList && dataList.length) {
            const count = dataList.map((item, i) => {
                return item.child.length
            })
            const total = count.reduce((a, b) => a + b, 0)
            setEntires(total)
        }



    }, [dataList])
    useEffect(() => {
        const unitdata = units.filter((item) => {
            return item.unit_id === Number(unit.unit)
        })
        setCID(unitdata && unitdata[0] && unitdata[0].country_id)
    }, [unit, units])

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
        window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    return (
        <div className="page-wrapper" id='page-wrapper'>
            {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                    {document.body.scrollHeight > document.body.clientHeight ?
                        <div className='back-to-top'>
                            <ScrollButton />
                        </div> : ''} */}
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            <div className="page-titles pb-0 pt-2 page-title-sticky">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transaction</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }} >
                                    <span>Completed Task - Current Year</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    {viewfreq == true ?
                        <div className='col-md-4 col-6 d-flex text-center align-items-center'>


                            <p className='mb-0'><b>Frequency:</b></p><h3 style={{ backgroundColor: '#d4d4ff' }} className='text-primary rounded-pill inline-block p-1 px-2 fw-bolder mb-0 ms-2'>
                                {domains.frequency}</h3>

                        </div>
                        : ''}
                    <div className={viewfreq == true ? 'col text-md-end text-start text-end align-self-center' : 'align-self-center col col-6 text-md-end text-start'}>
                        <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black full-mode fullscreenradius"  >
                            <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                        </button>
                    </div>

                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card userCard1 p-2">
                            <div className='col-md-12'>
                                <Tabs defaultActiveKey={tab} onChange={onChange} activeKey={tab} type="card">
                                    <TabPane tab="Select Unit & Domain" key="1">
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    {/* <div className="mb-3"> */}
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Legal Entity : </b> <span style={{ "color": "red" }}>*</span></label>
                                                    </div>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Legal Entity"
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9 ( )]+$/gi)
                                                                }}
                                                                onChange={(data, value) => {
                                                                    if (data !== undefined) {
                                                                        setCurrentEntity(data)
                                                                        setUnit({
                                                                            ...unit,
                                                                            legal_entity: data,
                                                                            division: '',
                                                                            category: '',
                                                                            unit: ''
                                                                        })
                                                                        setDomain({
                                                                            domain: ''
                                                                        })
                                                                        setFormSubmit(false)
                                                                        setAddFormSubmit(false)
                                                                        let unitData = _.filter(list && list.unit && list.unit, { le_id: Number(data) })
                                                                        setUnits(unitData)
                                                                    }
                                                                    else {
                                                                        setCurrentEntity("")
                                                                        setUnit({
                                                                            ...unit,
                                                                            legal_entity: '',
                                                                            division: '',
                                                                            category: '',
                                                                            unit: ''
                                                                        })
                                                                        setDomain({
                                                                            domain: ''
                                                                        })
                                                                        setFormSubmit(false)
                                                                        setAddFormSubmit(false)
                                                                        setUnits([])
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                                style={{ width: '100%', marginTop: "5px" }}
                                                            >
                                                                {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legal_entity',
                                                                unit.legal_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p title={localStorage.getItem('SelectedEntity')} className='text-wrap-report' style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                                    }
                                                    {/* </div> */}
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label htmlFor=""><b>Division : </b></label>
                                                    </div>
                                                    <div className='form-group'>
                                                        <Select
                                                            showSearch
                                                            onPaste={(e) => {
                                                                handlePasting(e, /^[a-z 0-9]+$/gi)
                                                            }}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            allowClear={true}
                                                            size="default"
                                                            value={unit.division || undefined}
                                                            // disabled={unit.legal_entity === "" ? true : false}
                                                            placeholder="Enter Division"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    setUnit({
                                                                        ...unit,
                                                                        division: data
                                                                    })
                                                                    let CategoryData = _.filter(list && list.category && list.category, { div_id: Number(data) })
                                                                    setCategory(CategoryData)
                                                                    let unitData = _.filter(list && list.unit && list.unit, { division_id: Number(data) })
                                                                    setUnits(unitData)
                                                                }
                                                                else {
                                                                    setUnit({
                                                                        ...unit,
                                                                        division: ''
                                                                    })
                                                                    setCategory([])
                                                                    setUnits([])
                                                                }
                                                            }}

                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {list && list.division && list.division.length && list.division.map((item, i) => {
                                                                return (
                                                                    <Option key={item.div_id}>
                                                                        {item.div_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label htmlFor=""><b>Category : </b></label>
                                                    </div>
                                                    <div className='form-group'>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            disabled={unit.division === "" ? true : false}
                                                            placeholder="Enter Category"
                                                            onPaste={(e) => {
                                                                handlePasting(e, /^[a-z 0-9]+$/gi)
                                                            }}
                                                            value={unit.category || undefined}
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    setUnit({
                                                                        ...unit,
                                                                        category: data
                                                                    })
                                                                    let unitData = _.filter(list && list.unit && list.unit, { category_id: Number(data) })
                                                                    setUnits(unitData)
                                                                }
                                                                else {
                                                                    setUnit({
                                                                        ...unit,
                                                                        category: ''
                                                                    })
                                                                    setUnits([])
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {category.length && category.map((item, i) => {
                                                                return (
                                                                    <Option key={item.cat_id}>
                                                                        {item.cat_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: '10px' }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    {/* <div className="mb-3"> */}
                                                    <div className='form-group'>
                                                        <label htmlFor=""><b>Unit : </b> <span style={{ "color": "red" }}>*</span></label>
                                                    </div>
                                                    <div className='form-group'>
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            allowClear={true}
                                                            value={unit.unit || undefined}
                                                            size="default"
                                                            onPaste={(e) => {
                                                                handlePasting(e, /^[a-z 0-9 - _ , .]+$/gi)
                                                            }}
                                                            disabled={unit.legal_entity == '' ? true : false}
                                                            placeholder="Enter Unit"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    setUnit({
                                                                        ...unit,
                                                                        unit: data
                                                                    })
                                                                    setDomain({
                                                                        ...domains,
                                                                        domain: '',
                                                                        frequency: '',
                                                                        acts: '',
                                                                    })
                                                                    setFormSubmit(false)
                                                                }
                                                                else {
                                                                    setUnit({
                                                                        ...unit,
                                                                        unit: ''
                                                                    })
                                                                    setDomain({
                                                                        ...domains,
                                                                        domain: '',
                                                                        frequency: '',
                                                                        acts: '',
                                                                    })
                                                                }

                                                            }}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {units && units.map((item, i) => {
                                                                return (
                                                                    <Option key={item.unit_id}>
                                                                        {item.unit_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            ' Unit',
                                                            unit.unit,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Unit Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label htmlFor=""><b>Domain : </b> <span style={{ "color": "red" }}>*</span></label>
                                                    </div>

                                                    {domainValue == false || domainFilter && domainFilter.length > 1 ?
                                                        <>
                                                            <div className='form-group'>

                                                                <Select
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    allowClear={true}
                                                                    size="default"
                                                                    disabled={unit.unit == '' ? true : false}
                                                                    value={domains.domain || undefined}
                                                                    placeholder="Enter Domain"
                                                                    onChange={(data) => {
                                                                        if (data !== undefined) {
                                                                            setDomain({
                                                                                ...domains,
                                                                                domain: data,
                                                                                frequency: '',
                                                                                acts: '',
                                                                            })
                                                                        }
                                                                        else {
                                                                            setDomain({
                                                                                ...domains,
                                                                                domain: '',
                                                                                frequency: '',
                                                                                acts: '',
                                                                            })
                                                                        }
                                                                    }}
                                                                    style={{ width: '100%', marginTop: "5px" }}
                                                                >
                                                                    {domainFilter && domainFilter.length > 0 && domainFilter.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.d_id}>
                                                                                {item.d_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'Domain',
                                                                    domains.domain,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Domain Required',
                                                                        }
                                                                    })}
                                                            </div>
                                                        </>
                                                        : <p className='cut-text-word' style={{ width: '100%', marginTop: "10px" }}>{list.domain && list.domain.length > 0 && list.domain[0].d_name}</p>}
                                                    {/* {validator.current.message(
                                                        'Domain',
                                                        domains.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })} */}
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label htmlFor=""><b>Act : </b> </label>
                                                    </div>
                                                    <div className='form-group'>
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Act"
                                                            onPaste={(e) => {
                                                                handlePasting(e, /^[a-z 0-9 ( ) . , - _]+$/gi)
                                                            }}
                                                            disabled={domains.domain === "" ? true : false}
                                                            value={domains.acts || undefined}
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    setDomain({
                                                                        ...domains,
                                                                        acts: data
                                                                    })
                                                                }
                                                                else {
                                                                    setDomain({
                                                                        ...domains,
                                                                        acts: ''
                                                                    })
                                                                }
                                                            }}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {
                                                                Act && Act.length && Act[0].length && Act[0].map((item, i) => {

                                                                    return (
                                                                        <Option key={item}>
                                                                            {item}
                                                                        </Option>
                                                                    );
                                                                })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: '10px' }}>
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label htmlFor=""><b>Frequency : </b> <span style={{ "color": "red" }}>*</span></label>
                                                </div>
                                                <div className='form-group'>
                                                    <Select
                                                        // showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        allowClear={true}
                                                        value={domains.frequency || undefined}
                                                        size="default"
                                                        placeholder="Enter Frequency"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                setDomain({
                                                                    ...domains,
                                                                    frequency: data
                                                                })
                                                            }
                                                            else {
                                                                setDomain({
                                                                    ...domains,
                                                                    frequency: ''
                                                                })
                                                            }
                                                        }}
                                                        style={{ width: '95%', marginTop: "5px" }}
                                                    >
                                                        {list && list.frequency && list.frequency.length > 0 && list.frequency.map((item, i) => {
                                                            return (
                                                                <Option key={item.frequency}>
                                                                    {item.frequency}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'Frequency',
                                                        domains.frequency,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Frequency Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>

                                    </TabPane>

                                    <TabPane disabled={selectDomain === true ? false : true} tab={<span>Select Compliance</span>} key="3">
                                        {temp1 && temp1.length > 0 ?

                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-2" style={{ marginLeft: '1%' }}>
                                                        <h5 >Selected Tasks:{temp1 && temp1.length}</h5>
                                                    </div>

                                                </div>
                                            </div>

                                            : false}
                                        <div className="col-md-12" style={{ padding: "0px 20px", marginTop: '0%' }}>
                                            {/* <p><b>Compliance Tasks</b></p> */}
                                            <div className="row assign">

                                                {dataList && dataList.length > 0 ? dataList.map((item, i) => {
                                                    // let data=dataList.map((item)=>{
                                                    //     return item.pr_compliances
                                                    // })
                                                    // console.log(data,'dataa');
                                                    //   var merged = [].concat.apply([], dataList[i].pr_compliances);
                                                    //   console.log(item.pr_compliances,'item.pr_compliances');
                                                    //   console.log(merged,'manoj');
                                                    return (
                                                        <Collapse key={i}>
                                                            <Panel header={item.parent} key="1" extra={<><span style={{ paddingRight: '50px' }}>Total Tasks : {item.child.length}</span></>}>
                                                                <div className="container-fluid" style={{ padding: 0 }}>
                                                                    <div className="row">

                                                                        <div className="col-12" >
                                                                            <div >
                                                                                <Table
                                                                                    size={'small'}
                                                                                    id='review-setting-table'
                                                                                    // className='userprivclass'
                                                                                    columns={columns}
                                                                                    className={localStorage.getItem("currentTheme") + ' assign_table'}
                                                                                    dataSource={item.child}
                                                                                    bordered
                                                                                    // fixedHeader={dataTableProperties.fixedHeader}
                                                                                    // scroll={{ x: 1050 }}
                                                                                    scroll={{ x: 1100 }}
                                                                                    pagination={false} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Panel>
                                                        </Collapse>
                                                    )
                                                })

                                                    :

                                                    <Card> <lable style={{ marginLeft: '40%', color: "black" }}>No Records Found</lable></Card>}
                                                {IsClosed == false ?
                                                    <div className='col-md-12 mt-2'>
                                                        <div className='row' style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                            <div className='col-md-6' >
                                                                <Button type="primary" shape="round" className='showbutton'
                                                                    size='default' onClick={showMore}>
                                                                    Show More <i> <DownOutlined /></i>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : false
                                                }
                                            </div>
                                        </div>
                                        {dataList && dataList.length > 0 ?
                                            <div className='col-md-12 mt-3'>
                                                <div className='row'>

                                                    <div className='col-md-3' >
                                                        <label>Showing 1 to  <span>{entires}</span>  of {entires} entries</label>
                                                    </div>


                                                </div>
                                            </div>
                                            : ""}

                                    </TabPane>
                                </Tabs>
                                <br />
                            </div>
                            <br />

                            <div className="col-md-12" style={{ marginTop: '-5%' }}>
                                <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                    {tab === '2' || tab === '3' ?
                                        <div>
                                            <Button type="primary" shape="round" className='prevbutton addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginTop: '30px' }}
                                                icon={<ArrowLeftOutlined />} size='default'
                                                onClick={() => {
                                                    if (tab === "2") {
                                                        setTab("1")

                                                    }
                                                    if (tab === "3") {
                                                        setTab("1")
                                                    }
                                                    setCount({
                                                        start_count: 0,
                                                        batch_count: 0
                                                    })
                                                    setviewfreq(false)
                                                    setTemp([])
                                                    setDataCheckbox([])
                                                    setDataList([])
                                                    setFileUpload([])
                                                    setUpdatedArray([]);
                                                    setUpdatedArrayShow([])
                                                    setUpdatedArrayShowmore([])
                                                }}
                                            >Previous</Button>
                                            {/* <button style={{ float: "left", marginTop: '30px' }} className="usrbutton button--anthe prev" onClick={() => {
                                                if (tab === "2") {
                                                    setTab("1")

                                                }
                                                if (tab === "3") {
                                                    setTab("1")
                                                }
                                            }}><span class="text-white fs-3 fw-bold">Previous</span></button> */}
                                        </div>
                                        : <div className="col-lg-4 col-md-5 d-none d-md-flex"></div>}

                                    {tab === '1' || tab === '2' ?
                                        <div className='text-end'>
                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginTop: '30px' }}
                                                size='default'
                                                onClick={() => {
                                                    if (tab === "1") {
                                                        setAddFormSubmit(true);
                                                        if (validator.current.allValid()) {
                                                            setSelectUnit(true)
                                                            setTab("3")
                                                            setviewfreq(true);
                                                        }
                                                    }
                                                    if (tab === "1") {
                                                        setFormSubmit(true);
                                                        if (validator.current.allValid() || (domains.domain != '' && domains.frequency != '')) {
                                                            if (Domainvalidator.current.allValid()) {

                                                                setTab("3")
                                                                setSelectDomain(true)
                                                                setviewfreq(true);
                                                                setShow(false)
                                                                const payloads = [
                                                                    authtoken,
                                                                    {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "GetStatutoriesByUnit",
                                                                            {
                                                                                'le_id': localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                                                                                'unit_id': Number(unit.unit),
                                                                                'domain_id': Number(domains.domain),
                                                                                'level_1_statutory_name': domains.acts,
                                                                                'compliance_task_frequency': domains.frequency,
                                                                                'start_count': 0,
                                                                                'batch_count': 0
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                                getStatutoriesByUnit({
                                                                    payload: payloads,
                                                                    paramid: paramid
                                                                })
                                                            }
                                                        }
                                                    }
                                                }}
                                            >Next <i> <ArrowRightOutlined /></i></Button>
                                            {/* <button style={{ float: "right" }} className="usrbutton button--anthe"
                                                onClick={() => {
                                                    if (tab === "1") {
                                                        setAddFormSubmit(true);
                                                        if (validator.current.allValid()) {
                                                            setSelectUnit(true)
                                                            setTab("3")
                                                        }
                                                    }
                                                    if (tab === "1") {
                                                        setFormSubmit(true);
                                                        if (validator.current.allValid()) {
                                                            if (Domainvalidator.current.allValid()) {

                                                                setTab("3")
                                                                setSelectDomain(true)
                                                                setShow(false)
                                                                const payloads = [
                                                                    authtoken,
                                                                    {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "GetStatutoriesByUnit",
                                                                            {
                                                                                'le_id': localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                                                                                'unit_id': Number(unit.unit),
                                                                                'domain_id': Number(domains.domain),
                                                                                'level_1_statutory_name': domains.acts,
                                                                                'compliance_task_frequency': domains.frequency,
                                                                                'start_count': 0,
                                                                                'batch_count': 0
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                                getStatutoriesByUnit({
                                                                    payload: payloads,
                                                                    paramid: paramid
                                                                })
                                                            }
                                                        }
                                                    }
                                                }}><span class="text-white fs-3 fw-bold">Next</span></button> */}
                                        </div>
                                        : dataList && dataList.length > 0 ? <div >
                                            <Button type="primary" shape="round" className='addbutton'
                                                style={{ background: "#198754", borderColor: "#198754", marginTop: '30px' }}
                                                icon={<PlayCircleOutlined />} size='default'
                                                onClick={() => {
                                                    setSelectDomain(true)
                                                    const payloads = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "SavePastRecords",
                                                                {
                                                                    "le_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                                                                    "pr_compliances_1": SavepastRecords
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                    // console.log(pa)
                                                    let temp = []
                                                    for (let i in temp1) {
                                                        if (updateDate[temp1[i].childId] !== '') {
                                                            temp.push(true)
                                                        }
                                                        else {
                                                            temp.push(false)
                                                        }
                                                    }


                                                    if (temp.includes(false)) {
                                                        Toaster.error("Task Completion Date / Document Issued Date Required")
                                                    }
                                                    else if (temp1.length == 0 || SavepastRecords.length == 0) {
                                                        Toaster.error("Select atleast one completed compliance")

                                                    }
                                                    else if (((temp1.length > 0) && (date.length > 0)) && (SavepastRecords.length > 0)) {
                                                        SaveCompletedTask({
                                                            payload: payloads,
                                                            paramid: paramid
                                                        })
                                                        if (fileupload.length > 0) {


                                                            const filePayload1 = [                                             //     const filePayload = [
                                                                authtoken,
                                                                {
                                                                    "session_token": authtoken,
                                                                    "request": [
                                                                        "MoveComplianceTaskFile",
                                                                        {
                                                                            "le_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                                                                            "c_id": Number(cid),
                                                                            "d_id": Number(domains.domain),
                                                                            "u_id": Number(unit.unit),
                                                                            "start_date": moment().format("D-MMM-YYYY"),
                                                                            "file_info":
                                                                                SavepastRecords[0].documents,
                                                                            // fileupload,
                                                                            "compliance_history_id": Number(rowcomplianceid)
                                                                        }


                                                                    ]

                                                                }
                                                            ]
                                                            Movetaskfile({
                                                                payload: filePayload1,
                                                                paramid: paramid
                                                            })
                                                        }
                                                    }
                                                }}

                                            >Submit</Button>

                                        </div> : false}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}
export default connect(mapStateToProps, {
    getCompletedTask,
    getStatutoriesByUnit,
    getStatutoriesByUnitshowmore,
    SaveCompletedTask,
    UploadFile,
    Movetaskfile
})(CompletedTaskCurrentYear);

