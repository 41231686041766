import React from 'react'
import { FilterOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Progress, Tooltip, Pagination, Drawer } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useRef, useEffect, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import { LegalentityFilters, Legalentityshow, Legalentityexport } from '../../../Store/Action/Report/LegalentityReport';
import moment from 'moment';
import { ExclamationCircleTwoTone, PlayCircleOutlined, ExportOutlined, EyeOutlined, FullscreenOutlined, UpOutlined, UpCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import ScrollButton from '../../Scrollbar';
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const homepage = HomeRedirect()


const mapStateToProps = (state) => ({
    allData: state.LegalentityReport.legalreport.data,
    tableData: state.LegalentityReport.legalreport.getTableData
})

const LegalentityReport = ({
    LegalentityFilters,
    allData,
    tableData,
    Legalentityshow,
    Legalentityexport
}) => {
    console.log(allData, "allData");
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [scroll, setScroll] = useState(false)
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [key, setKey] = useState("1")
    const [statkey, setStatKey] = useState("0")
    const [paginationarray, setPaginationArray] = useState([])
    const [disableButton, setDisableButton] = useState(false)
    const [totalCount, setTotalCount] = useState('')
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [exitCollapse, setExitCollapse] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [legalentitydata, setLegalentitydata] = useState({
        country: "",
        category: "",
        user_type: "",
        legel_entity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        unit: '',
        user: "",
        domain: "",
        act: "",
        from_date: "",
        task_category: "",
        compliance_task: "",
        to_date: "",
        task_sub_category: "",
        compliance_frequency: "",
        compliance_task_status: "",
        division: "",
        domainName: '',
        countryName: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : sessionArr[0].c_name,
        unit_name: '',
        user_name: '',
        statutory_mapping: '',
        division_name: '',
        compliance_frequency_name: '',
        compliance_task_status_name: '',
        category_name: '',
        user_type_name: '',
        le_id: ''

    })

    console.log(legalentitydata, 'legalentitydata');

    const [titledata, Settitledata] = useState()

    const validator = useRef(new SimpleReactValidator());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [domainList, setDomainList] = useState([])
    const [divisionList, setDivisionList] = useState([])
    const [unitCodeLabelList, setUnitCodeLabelList] = useState([])
    const [unitLegalEntity, setUnitLegalEntity] = useState([])
    const [complianceUser, setComplianceUser] = useState([])
    console.log(complianceUser, 'complianceUser');
    const [complianceUserType, setComplianceUserType] = useState([])
    const [complianceTaskStatus, setComplianceTaskStatus] = useState([])

    const [complianceFrequencyList, setComplianceFrequencyList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [actLegalEntity, setActLegalEntity] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [actName, setActName] = useState([])
    const [actNameFilteredState, setActNameFilteredState] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    let [index, setIndex] = useState(0)
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 7, 8, 9, 10, 29, 32])

    const { Panel } = Collapse;
    const { Option } = Select;

    const onshow = () => {
        setFilteredArrayState([])
        Settitledata([])
        setFilterModalVisible(false);
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterTaskAcc(false);

            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetLegalEntityWiseReport",
                        {
                            "country_id": Number(legalentitydata.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(legalentitydata.domain),
                            "division_id": Number(legalentitydata.division),
                            "category_id": Number(legalentitydata.category) ? Number(legalentitydata.category) : 0,
                            "unit_id": Number(legalentitydata.unit) ? Number(legalentitydata.unit) : 0,
                            "parent_id": Number(legalentitydata.act) ? Number(legalentitydata.act) : 0,
                            "compliance_task": legalentitydata.compliance_task ? legalentitydata.compliance_task : null,
                            "frequency_id": Number(legalentitydata.compliance_frequency) ? Number(legalentitydata.compliance_frequency) : 0,
                            "user_type": legalentitydata.user_type ? legalentitydata.user_type : 'All',
                            "user_id": 0,
                            "due_from_date": moment(legalentitydata.from_date).format('DD-MMM-YYYY'),
                            "due_to_date": moment(legalentitydata.to_date._d).format('DD-MMM-YYYY'),
                            "task_status": legalentitydata.compliance_task_status ? legalentitydata.compliance_task_status : 'All',
                            "csv": false,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": null
                        }
                    ]
                }
            ]
            Legalentityshow({
                payload: payload,
                paramid: paramid
            })
        }
    }
    const scrollup = () => {
        window.scrollTo(0, 0)
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        if (window.scrollY >= 158) {
            setScroll(true)
        }
        else {
            setScroll(false)
        }
    }


    // useEffect(() => {
    //     if (pageState == true) {
    //         const payload = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "GetLegalEntityWiseReport",
    //                     {
    //                         "country_id": Number(legalentitydata.country),
    //                         "legal_entity_id": entityid,
    //                         "domain_id": Number(legalentitydata.domain),
    //                         "division_id": Number(legalentitydata.division),
    //                         "category_id": Number(legalentitydata.category) ? Number(legalentitydata.category) : 0,
    //                         "unit_id": Number(legalentitydata.unit) ? Number(legalentitydata.unit) : 0,
    //                         "parent_id": Number(legalentitydata.act) ? Number(legalentitydata.act) : 0,
    //                         "compliance_task": legalentitydata.compliance_task ? legalentitydata.compliance_task : null,
    //                         "frequency_id": Number(legalentitydata.compliance_frequency) ? Number(legalentitydata.compliance_frequency) : 0,
    //                         "user_type": legalentitydata.user_type ? legalentitydata.user_type : 'All',
    //                         "user_id": 0,
    //                         "due_from_date": moment(legalentitydata.from_date).format('DD-MMM-YYYY'),
    //                         "due_to_date": moment(legalentitydata.to_date._d).format('DD-MMM-YYYY'),
    //                         "task_status": legalentitydata.compliance_task_status ? legalentitydata.compliance_task_status : 'All',
    //                         "csv": false,
    //                         "from_count":  Math.max(((current - 1) * pageSize), 0) + 1,
    //                         "page_count": current != 0 ? current * pageSize : pageSize,
    //                         "list_check": null
    //                     }
    //                 ]
    //             }

    //         ]
    //         Legalentityshow({
    //             payload: payload,
    //             paramid: paramid
    //         })
    //     }
    // }, [current, pageSize, pageState])


    const showModal = () => {

        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };

    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };


    const handleOk = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetLegalEntityWiseReport",
                        {
                            "country_id": Number(legalentitydata.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(legalentitydata.domain),
                            "division_id": Number(legalentitydata.division),
                            "category_id": Number(legalentitydata.category) ? Number(legalentitydata.category) : 0,
                            "unit_id": Number(legalentitydata.unit) ? Number(legalentitydata.unit) : 0,
                            "parent_id": Number(legalentitydata.act) ? Number(legalentitydata.act) : 0,
                            "compliance_task": legalentitydata.compliance_task ? legalentitydata.compliance_task : null,
                            "frequency_id": Number(legalentitydata.compliance_frequency) ? Number(legalentitydata.compliance_frequency) : 0,
                            "user_type": legalentitydata.user_type ? legalentitydata.user_type : 'All',
                            "user_id": 0,
                            "due_from_date": moment(legalentitydata.from_date).format('DD-MMM-YYYY'),
                            "due_to_date": moment(legalentitydata.to_date._d).format('DD-MMM-YYYY'),
                            "task_status": legalentitydata.compliance_task_status ? legalentitydata.compliance_task_status : 'All',
                            "csv": false,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            Legalentityexport({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {

        if (tableData && tableData.legal_entities_compliances && tableData.legal_entities_compliances.length > 0) {

            var array = tableData.legal_entities_compliances.slice((current - 1) * pageSize, (current * pageSize))

            setPaginationArray(array)

        }

    }, [tableData.legal_entities_compliances, pageSize, current])


    useEffect(() => {
        if (totalCount == '') {
            setTotalCount(tableData && tableData.total_count)
        }
    }, [totalCount, tableData.total_count])

    console.log(tableData.total_count, 'tableData.total_count');
    console.log(totalCount, 'totalCount');

    const onChange = (key) => {
        console.log(key);
    };

    // const onStatChange = (key) => {
    //     console.log(key);
    // }

    console.log(tableData, 'tableData');



    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');

        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {

                if (ele[i].type == 'checkbox') {

                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }

            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([])
        }
    }


    useEffect(() => {
        validator.current.showMessages()
    }, [])


    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(legalentitydata.legel_entity)
        }
    }, [entityid])

    useEffect(() => {
        if (entityid != null) {
            setLegalentitydata({
                ...legalentitydata,
                country: sessionArr[0].c_id
            })
        }
    }, [entityid != null])

    // useEffect(() => {
    //     if (isAuth) {
    //         const payload = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "GetDomainWiseReportFilters",
    //                     {
    //                         "country_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? Number(consolidateddata.country) : sessionArr[0].c_id,
    //                         "legal_entity_id": entityid
    //                     }
    //                 ]
    //             }
    //         ]
    //         if (entityid != 'null') {
    //             DomainReportData({
    //                 payload: payload,
    //                 paramid: paramid
    //             })
    //         }
    //     }

    // }, [isAuth, entityid])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetLegalEntityWiseReportFilters",
                        {
                            "country_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(legalentitydata.country),
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null') {
                LegalentityFilters({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            setLegalentitydata({
                ...legalentitydata,
                le_id: localStorage.getItem('SelectedEntityid'),
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    console.log(allData, 'allData');
    useEffect(() => {
        if (allData) {
            setActLegalEntity(allData && allData.act_legal_entity)
            setCategoryList(allData && allData.categories_list)
            setComplianceFrequencyList(allData && allData.compliance_frequency_list)
            setComplianceTaskStatus(allData && allData.compliance_task_status)
            setComplianceUserType(allData && allData.compliance_user_type)
            let uniqueObjArray = _.uniqBy(allData && allData.compliance_users && allData.compliance_users.length > 0 && allData && allData.compliance_users && allData.compliance_users, function (e) {
                return e.completed_by;
            });
            setComplianceUser(uniqueObjArray)
            setUnitLegalEntity(allData && allData.unit_legal_entity)
            setUnitCodeLabelList(allData && allData.unit_code_label_list)
            setDivisionList(allData && allData.divisions_list)
            setDomainList(allData && allData.domains)
        }

    }, [allData])

    console.log(complianceUser, 'allData');

    useEffect(() => {
        if (paginationarray && paginationarray && paginationarray.length > 0) {
            let uniqueObjArray = [...new Map(paginationarray && paginationarray.length && paginationarray.map((item) => [item["unit_id"], item])).values()];

            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(paginationarray, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
                setFilteredArray([...filteredArray, tempArr])
            }
        }
    }, [paginationarray])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setFilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (paginationarray && paginationarray && paginationarray.length) {
            const ab = paginationarray && paginationarray.length && paginationarray.map(function (item) {

                if ((array.indexOf(item.statutory_mapping) < 0))
                    array.push(item.statutory_mapping)
            })


            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationarray && paginationarray.length && paginationarray.map((child, childIndex) => {

                        if (data === child.statutory_mapping) {

                            entityArray = {
                                "activity_date": child.activity_date,
                                "activity_status": child.activity_status,
                                "assignee_name": child.assignee_name,
                                "category_name": child.category_name,
                                "completion_date": child.completion_date,
                                "compliance_activity_id": child.compliance_activity_id,
                                "compliance_description": child.compliance_description,
                                "compliance_history_id": child.compliance_history_id,
                                "compliance_id": child.compliance_id,
                                "compliance_task": child.compliance_task,
                                "country_id": child.country_id,
                                "division_name": child.division_name,
                                "doc_ref_num": child.doc_ref_num,
                                "document_name": child.document_name,
                                "domain_id": child.domain_id,
                                "due_date": child.due_date,
                                "extend_due_date": child.extend_due_date,
                                "frequency_name": child.frequency_name,
                                "history_count": child.history_count,
                                "interim_count": child.interim_count,
                                "legal_entity_id": child.legal_entity_id,
                                "logo_url": child.logo_url,
                                "remarks_count": child.remarks_count,
                                "start_date": child.start_date,
                                "statutory_mapping": child.statutory_mapping,
                                "task_status": child.task_status,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                "url": child.url,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [paginationarray])
    const [fullscreen, setfullscreen] = useState(false);

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }
    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])


    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
         window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    const disablecondition = (e) => {
        var currentToDate = moment(legalentitydata.from_date).add(3, 'months')
        console.log(currentToDate, 'currentToDate')
        if (currentToDate < moment(e)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }



    return (

        <div id='page-wrapper' className="page-wrapper">
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            {document.body.scrollHeight > document.body.clientHeight ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''} */}
            <div className="page-titles pb-0 pt-0">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                    <span>Legal Entity  Report</span>
                                </li>
                            </ol>
                        </nav>

                    </div>

                    <div className="col-lg-4 mt-1 text-end">
                       <Tooltip title='Advance Filter'><Button
                            className="mx-1"
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button></Tooltip> 
                        <button style={{ float: 'right', marginTop: '5px' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                            <Tooltip placement="left" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                        </button>
                    </div>
                </div>
            </div>

            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card-body" id='only-restricted' style={{ padding: "10px 3px" }}>
                            <div className={localStorage.getItem('currentTheme')}>
                                <Collapse accordion defaultActiveKey={["1"]} className='report-collapse'
                                    style={{ display: exitCollapse ? "none" : "block" }}
                                    onChange={onChange}>
                                    <Panel header=" Legal Entity Report" key={key}>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Country"
                                                                    onChange={(value, data) => {

                                                                        setLegalentitydata({
                                                                            ...legalentitydata,
                                                                            country: value ? value : '',
                                                                            countryName: data ? data.children : '',
                                                                            category: "",
                                                                            user_type: "",
                                                                            legel_entity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                            unit: '',
                                                                            user: "",
                                                                            domain: "",
                                                                            act: "",
                                                                            from_date: "",
                                                                            task_category: "",
                                                                            compliance_task: "",
                                                                            to_date: "",
                                                                            task_sub_category: "",
                                                                            compliance_frequency: "",
                                                                            compliance_task_status: "",
                                                                            division: "",
                                                                            domainName: '',
                                                                            unit_name: '',
                                                                            user_name: '',
                                                                            statutory_mapping: '',
                                                                            division_name: '',
                                                                            compliance_frequency_name: '',
                                                                            compliance_task_status_name: '',
                                                                            category_name: '',
                                                                            user_type_name: '',
                                                                            le_id: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    //value={Settings.le_id}
                                                                    style={{ width: '100%', marginTop: '2px' }}
                                                                    value={legalentitydata.countryName || undefined}
                                                                >
                                                                    {country_info && country_info.length && country_info.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'countryName',
                                                                    legalentitydata.countryName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{sessionArr[0].c_name}</p>
                                                        }
                                                    </div>
                                                    {/* {validator.current.message(
                                                    'country',
                                                    legalentitydata.country,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Country Required ',
                                                        }
                                                    })} */}
                                                </div>
                                                <div className='col-md-4 text-wrap-report'>
                                                    <label><b>Legal Entity:</b>  <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Legal Entity"
                                                                onChange={(data, value) => {
                                                                    data && setCurrentEntity(data)
                                                                    setLegalentitydata({
                                                                        ...legalentitydata,
                                                                        le_id: data ? data : '',
                                                                        legel_entity: value ? value.children : '',
                                                                        category: "",
                                                                        user_type: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        division: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        statutory_mapping: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: '',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '2px' }}
                                                                value={legalentitydata.legel_entity || undefined}
                                                                disabled={legalentitydata.countryName == '' ? true : false}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                legalentitydata.legel_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required ',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain:</b>  <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select allowClear={true}
                                                            onChange={(value, data) => {
                                                                let uniqueObjArray = _.uniqBy(allData && allData.compliance_users && allData.compliance_users.length > 0 && allData && allData.compliance_users && allData.compliance_users, function (e) {
                                                                    return e.completed_by;
                                                                });
                                                                let filteredUser = _.filter(uniqueObjArray, { domain_id: Number(value) })
                                                                setComplianceUser(filteredUser)
                                                                setLegalentitydata({
                                                                    ...legalentitydata,
                                                                    domain: value ? value : '',
                                                                    domainName: data ? data.children : '',
                                                                    category: "",
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    statutory_mapping: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: '',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            placeholder="Enter Domain"
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                            value={legalentitydata.domainName || undefined}
                                                            disabled={legalentitydata.le_id == '' ? true : false}

                                                        >
                                                            {domainList && domainList.length && domainList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                    {validator.current.message(
                                                        'domain',
                                                        legalentitydata.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>



                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>From Date: </b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <DatePicker allowClear={true} className='form-control' format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                            value={legalentitydata.from_date ? moment(legalentitydata.from_date) : ''}
                                                            onChange={(date, dateString) => {

                                                                setLegalentitydata({
                                                                    ...legalentitydata,
                                                                    from_date: dateString,
                                                                    to_date: moment(dateString).add(3, 'months')
                                                                })
                                                            }}
                                                            disabled={legalentitydata.domain == '' ? true : false}


                                                        />
                                                    </div>
                                                    {validator.current.message(
                                                        'from_date',
                                                        legalentitydata.from_date,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'From Date Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>To Date:</b>  <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <DatePicker className='form-control' allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                            value={legalentitydata.to_date || undefined}
                                                            onChange={(date, dateString) => {
                                                                disablecondition(dateString)
                                                                setLegalentitydata({
                                                                    ...legalentitydata,
                                                                    to_date: dateString._i
                                                                })
                                                            }}
                                                            disabled={legalentitydata.domain == '' ? true : false}
                                                            disabledDate={(current) => {
                                                                return moment(legalentitydata.from_date).add(1, 'days') >= current ||
                                                                    moment(legalentitydata.from_date).add(1, 'year') < current;
                                                            }}

                                                        />
                                                    </div>
                                                    {validator.current.message(
                                                        'to_date',
                                                        legalentitydata.to_date,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'To Date Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b> Unit:</b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            onChange={(value, data) => {

                                                                setLegalentitydata({
                                                                    ...legalentitydata,
                                                                    unit: value ? value : '',
                                                                    unit_name: data ? data.children : ''
                                                                })
                                                            }}
                                                            placeholder="Enter Unit"
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            value={legalentitydata.unit_name || undefined}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            disabled={legalentitydata.domain == '' ? true : false}
                                                        >
                                                            {unitLegalEntity && unitLegalEntity.length > 0 && unitLegalEntity.map((item, i) => {

                                                                return (
                                                                    <Option key={item.unit_id}>
                                                                        {`${item.unit_code}-${item.unit_name}`}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b> User:</b></label>
                                                    <div className="form-group">
                                                        <Select allowClear={true}
                                                            onChange={(value, data) => {
                                                                setLegalentitydata({
                                                                    ...legalentitydata,
                                                                    user: value ? value : '',
                                                                    user_name: data ? data.children : ''
                                                                })
                                                            }}
                                                            placeholder="Enter User"
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            value={legalentitydata.user_name || undefined}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            disabled={legalentitydata.domain == '' ? true : false}
                                                        >
                                                            {complianceUser && complianceUser.length && complianceUser.map((item, i) => {
                                                                return (
                                                                    <Option key={item.completed_by}>
                                                                        {item.assignee_name}
                                                                    </Option>
                                                                );
                                                            })}

                                                        </Select>

                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Compliance Task:</b> </label>
                                                    <div className="form-group">
                                                        <Input className="form-control" placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '2px' }}
                                                            allowClear={true}
                                                            onChange={(e) => {
                                                                setLegalentitydata({
                                                                    ...legalentitydata,
                                                                    compliance_task: e.target.value
                                                                })
                                                            }}


                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Act:</b> </label>
                                                    <div className="form-group">
                                                        <Select allowClear={true}
                                                            onChange={(value, data) => {
                                                                setLegalentitydata({
                                                                    ...legalentitydata,
                                                                    act: value ? value : '',
                                                                    statutory_mapping: data ? data.children : ''
                                                                })
                                                            }}
                                                            placeholder="Enter Act"
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={legalentitydata.statutory_mapping || undefined}
                                                            disabled={legalentitydata.domain == '' ? true : false}

                                                        >
                                                            {actLegalEntity && actLegalEntity.length && actLegalEntity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.parent_id}>
                                                                        {item.statutory_mapping}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Division:</b></label>
                                                    <div className="form-group">
                                                        <Select allowClear={true}
                                                            onChange={(value, data) => {

                                                                setLegalentitydata({
                                                                    ...legalentitydata,
                                                                    division: value ? value : '',
                                                                    division_name: data ? data.children : ''
                                                                })
                                                                let filteredCategory = _.filter(allData && allData.categories_list, { div_id: Number(value) })
                                                                setCategoryList(filteredCategory)
                                                                let filteredUnit = _.filter(allData && allData.unit_legal_entity, { division_id: Number(value) })
                                                                setUnitLegalEntity(filteredUnit)
                                                            }}
                                                            // onClick={() => {
                                                            //     if (legalentitydata.domain == "") {
                                                            //         toast.warning("Domain Required")
                                                            //     }
                                                            // }}
                                                            placeholder="Enter Division"
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={legalentitydata.division_name || undefined}
                                                            disabled={legalentitydata.domain == '' ? true : false}

                                                        >
                                                            {divisionList && divisionList.length && divisionList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.div_id}>
                                                                        {item.div_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Compliance Frequency:</b> </label>
                                                    <div className="form-group">
                                                        <Select allowClear={true}
                                                            onChange={(value, data) => {
                                                                setLegalentitydata({
                                                                    ...legalentitydata,
                                                                    compliance_frequency: value ? value : '',
                                                                    compliance_frequency_name: data ? data.children : ''
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            disabled={legalentitydata.country == '' && legalentitydata.legel_entity == '' && legalentitydata.domain == '' ? true : false}

                                                            style={{ width: '100%', marginTop: '2px' }}

                                                            defaultValue="All"
                                                        // value={legalentitydata.compliance_frequency_name}
                                                        >
                                                            <Option key='All'>All</Option>
                                                            {complianceFrequencyList && complianceFrequencyList.length && complianceFrequencyList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.frequency_id}>
                                                                        {item.frequency_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Compliance Task Status:</b> </label>
                                                    <div className="form-group">
                                                        <Select allowClear={true}
                                                            onChange={(value, data) => {
                                                                setLegalentitydata({
                                                                    ...legalentitydata,
                                                                    compliance_task_status: value ? value : '',
                                                                    compliance_task_status_name: data ? data.children : ''
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            disabled={legalentitydata.country == '' && legalentitydata.legel_entity == '' && legalentitydata.domain == '' ? true : false}

                                                            // placeholder="Enter Domain Name"
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                            defaultValue="All"
                                                        // value={legalentitydata.compliance_task_status_name}
                                                        >
                                                            <Option key='All'>All</Option>
                                                            {complianceTaskStatus && complianceTaskStatus.length && complianceTaskStatus.map((item, i) => {

                                                                return (
                                                                    <Option key={item.task_status}>
                                                                        {item.task_status}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Category:</b></label>
                                                    <div className="form-group">
                                                        <Select allowClear={true}
                                                            onChange={(value, data) => {
                                                                setLegalentitydata({
                                                                    ...legalentitydata,
                                                                    category: value ? value : '',
                                                                    category_name: data ? data.children : ''
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                            disabled={legalentitydata.domain == '' ? true : false}
                                                            placeholder="Enter Category"
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            value={legalentitydata.category_name || undefined}
                                                        >
                                                            {categoryList && categoryList.length && categoryList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.cat_id}>
                                                                        {item.cat_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b> User Type:</b></label>
                                                    <div className="form-group">
                                                        <Select allowClear={true}
                                                            onChange={(value, data) => {
                                                                setLegalentitydata({
                                                                    ...legalentitydata,
                                                                    user_type: value ? value : '',
                                                                    user_type_name: data ? data.children : ''
                                                                })
                                                            }}
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                            // value={legalentitydata.user_type_name}
                                                            defaultValue="All"
                                                            disabled={legalentitydata.domain == '' ? true : false}

                                                        >
                                                            <Option key='All'>All</Option>
                                                            {complianceUserType && complianceUserType.length && complianceUserType.map((item, i) => {
                                                                return (
                                                                    <Option key={item.user_type_id}>
                                                                        {item.user_type}
                                                                    </Option>
                                                                );
                                                            })}

                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='text-center mt-2 popupbtncolour '>
                                            <Button type="primary" disabled={disableButton} shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                icon={<EyeOutlined />} onClick={onshow}
                                            >
                                                Show
                                            </Button>
                                            <Button type="primary" shape="round" className={'mx-1 addbutton ' + localStorage.getItem('currentTheme')}
                                                icon={<ExportOutlined />} onClick={showModal}
                                            >
                                                Export
                                            </Button>
                                        </div>

                                    </Panel>
                                </Collapse>
                                <Modal
                                    onCancel={setcancelFilter}
                                    footer={false}
                                    title="Legal Entity Report"
                                    className={
                                        "add-service-prv cons-report " +
                                        localStorage.getItem("currentTheme")
                                    }
                                    open={filterModalVisible}
                                    maskClosable={false}
                                >
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Country"
                                                                onChange={(value, data) => {

                                                                    setLegalentitydata({
                                                                        ...legalentitydata,
                                                                        country: value ? value : '',
                                                                        countryName: data ? data.children : '',
                                                                        category: "",
                                                                        user_type: "",
                                                                        legel_entity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        division: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        statutory_mapping: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: '',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                        le_id: ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '2px' }}
                                                                value={legalentitydata.countryName || undefined}
                                                            >
                                                                {country_info && country_info.length && country_info.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'countryName',
                                                                legalentitydata.countryName,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{sessionArr[0].c_name}</p>
                                                    }
                                                </div>
                                                {/* {validator.current.message(
                            'country',
                            legalentitydata.country,
                            'required',
                            {
                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Country Required ',
                                }
                            })} */}
                                            </div>
                                            <div className='col-md-4 text-wrap-report'>
                                                <label><b>Legal Entity:</b>  <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(data, value) => {
                                                                data && setCurrentEntity(data)
                                                                setLegalentitydata({
                                                                    ...legalentitydata,
                                                                    le_id: data ? data : '',
                                                                    legel_entity: value ? value.children : '',
                                                                    category: "",
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    domainName: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    statutory_mapping: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: '',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            value={legalentitydata.legel_entity || undefined}
                                                            disabled={legalentitydata.countryName == '' ? true : false}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legalentity',
                                                            legalentitydata.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required ',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain:</b>  <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onChange={(value, data) => {
                                                            let uniqueObjArray = _.uniqBy(allData && allData.compliance_users && allData.compliance_users.length > 0 && allData && allData.compliance_users && allData.compliance_users, function (e) {
                                                                return e.completed_by;
                                                            });
                                                            let filteredUser = _.filter(uniqueObjArray, { domain_id: Number(value) })
                                                            setComplianceUser(filteredUser)
                                                            setLegalentitydata({
                                                                ...legalentitydata,
                                                                domain: value ? value : '',
                                                                domainName: data ? data.children : '',
                                                                category: "",
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "",
                                                                division: "",
                                                                unit_name: '',
                                                                user_name: '',
                                                                statutory_mapping: '',
                                                                division_name: '',
                                                                compliance_frequency_name: '',
                                                                compliance_task_status_name: '',
                                                                category_name: '',
                                                                user_type_name: '',
                                                            })
                                                        }}
                                                        placeholder="Enter Domain"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                        value={legalentitydata.domainName || undefined}
                                                        disabled={legalentitydata.le_id == '' ? true : false}

                                                    >
                                                        {domainList && domainList.length && domainList.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                {validator.current.message(
                                                    'domain',
                                                    legalentitydata.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>



                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>From Date: </b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <DatePicker allowClear={true} className='form-control' format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={legalentitydata.from_date ? moment(legalentitydata.from_date) : ''}
                                                        onChange={(date, dateString) => {

                                                            setLegalentitydata({
                                                                ...legalentitydata,
                                                                from_date: dateString,
                                                                to_date: moment(dateString).add(3, 'months')
                                                            })
                                                        }}
                                                        disabled={legalentitydata.domain == '' ? true : false}


                                                    />
                                                </div>
                                                {validator.current.message(
                                                    'from_date',
                                                    legalentitydata.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'From Date Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>To Date:</b>  <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <DatePicker className='form-control' allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={legalentitydata.to_date || undefined}
                                                        onChange={(date, dateString) => {
                                                            disablecondition(dateString)
                                                            setLegalentitydata({
                                                                ...legalentitydata,
                                                                to_date: dateString._i
                                                            })
                                                        }}
                                                        disabled={legalentitydata.domain == '' ? true : false}
                                                        disabledDate={(current) => {
                                                            return moment(legalentitydata.from_date).add(1, 'days') >= current ||
                                                                moment(legalentitydata.from_date).add(1, 'year') < current;
                                                        }}

                                                    />
                                                </div>
                                                {validator.current.message(
                                                    'to_date',
                                                    legalentitydata.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Date Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b> Unit:</b></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        onChange={(value, data) => {

                                                            setLegalentitydata({
                                                                ...legalentitydata,
                                                                unit: value ? value : '',
                                                                unit_name: data ? data.children : ''
                                                            })
                                                        }}
                                                        placeholder="Enter Unit"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        value={legalentitydata.unit_name || undefined}
                                                        disabled={legalentitydata.domain == '' ? true : false}
                                                    >
                                                        {unitLegalEntity && unitLegalEntity.length > 0 && unitLegalEntity.map((item, i) => {

                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {`${item.unit_code}-${item.unit_name}`}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b> User:</b></label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onChange={(value, data) => {
                                                            setLegalentitydata({
                                                                ...legalentitydata,
                                                                user: value ? value : '',
                                                                user_name: data ? data.children : ''
                                                            })
                                                        }}
                                                        placeholder="Enter User"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        value={legalentitydata.user_name || undefined}
                                                        disabled={legalentitydata.domain == '' ? true : false}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                        {complianceUser && complianceUser.length && complianceUser.map((item, i) => {
                                                            return (
                                                                <Option key={item.completed_by}>
                                                                    {item.assignee_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>

                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task:</b> </label>
                                                <div className="form-group">
                                                    <Input placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '2px' }}
                                                        allowClear={true}
                                                        onChange={(e) => {
                                                            setLegalentitydata({
                                                                ...legalentitydata,
                                                                compliance_task: e.target.value
                                                            })
                                                        }}


                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Act:</b> </label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onChange={(value, data) => {
                                                            setLegalentitydata({
                                                                ...legalentitydata,
                                                                act: value ? value : '',
                                                                statutory_mapping: data ? data.children : ''
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        placeholder="Enter Act"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={legalentitydata.statutory_mapping || undefined}
                                                        disabled={legalentitydata.domain == '' ? true : false}

                                                    >
                                                        {actLegalEntity && actLegalEntity.length && actLegalEntity.map((item, i) => {
                                                            return (
                                                                <Option key={item.parent_id}>
                                                                    {item.statutory_mapping}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Division:</b></label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onChange={(value, data) => {

                                                            setLegalentitydata({
                                                                ...legalentitydata,
                                                                division: value ? value : '',
                                                                division_name: data ? data.children : ''
                                                            })
                                                            let filteredCategory = _.filter(allData && allData.categories_list, { div_id: Number(value) })
                                                            setCategoryList(filteredCategory)
                                                            let filteredUnit = _.filter(allData && allData.unit_legal_entity, { division_id: Number(value) })
                                                            setUnitLegalEntity(filteredUnit)
                                                        }}
                                                        // onClick={() => {
                                                        //     if (legalentitydata.domain == "") {
                                                        //         toast.warning("Domain Required")
                                                        //     }
                                                        // }}
                                                        placeholder="Enter Division"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                        value={legalentitydata.division_name || undefined}
                                                        disabled={legalentitydata.domain == '' ? true : false}

                                                    >
                                                        {divisionList && divisionList.length && divisionList.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Frequency:</b> </label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onChange={(value, data) => {
                                                            setLegalentitydata({
                                                                ...legalentitydata,
                                                                compliance_frequency: value ? value : '',
                                                                compliance_frequency_name: data ? data.children : ''
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        disabled={legalentitydata.country == '' && legalentitydata.legel_entity == '' && legalentitydata.domain == '' ? true : false}

                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        defaultValue="All"
                                                    // value={legalentitydata.compliance_frequency_name}
                                                    >
                                                        <Option key='All'>All</Option>
                                                        {complianceFrequencyList && complianceFrequencyList.length && complianceFrequencyList.map((item, i) => {
                                                            return (
                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task Status:</b> </label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onChange={(value, data) => {
                                                            setLegalentitydata({
                                                                ...legalentitydata,
                                                                compliance_task_status: value ? value : '',
                                                                compliance_task_status_name: data ? data.children : ''
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        disabled={legalentitydata.country == '' && legalentitydata.legel_entity == '' && legalentitydata.domain == '' ? true : false}

                                                        // placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        defaultValue="All"
                                                    // value={legalentitydata.compliance_task_status_name}
                                                    >
                                                        <Option key='All'>All</Option>
                                                        {complianceTaskStatus && complianceTaskStatus.length && complianceTaskStatus.map((item, i) => {

                                                            return (
                                                                <Option key={item.task_status}>
                                                                    {item.task_status}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Category:</b></label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onChange={(value, data) => {
                                                            setLegalentitydata({
                                                                ...legalentitydata,
                                                                category: value ? value : '',
                                                                category_name: data ? data.children : ''
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                        disabled={legalentitydata.domain == '' ? true : false}
                                                        placeholder="Enter Category"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        value={legalentitydata.category_name || undefined}
                                                    >
                                                        {categoryList && categoryList.length && categoryList.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b> User Type:</b></label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onChange={(value, data) => {
                                                            setLegalentitydata({
                                                                ...legalentitydata,
                                                                user_type: value ? value : '',
                                                                user_type_name: data ? data.children : ''
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        // value={legalentitydata.user_type_name}
                                                        defaultValue="All"
                                                        disabled={legalentitydata.domain == '' ? true : false}

                                                    >
                                                        <Option key='All'>All</Option>
                                                        {complianceUserType && complianceUserType.length && complianceUserType.map((item, i) => {
                                                            return (
                                                                <Option key={item.user_type_id}>
                                                                    {item.user_type}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='text-center mt-2 popupbtncolour'>
                                        <Button type="primary" disabled={disableButton} shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                            icon={<EyeOutlined />} onClick={onshow}
                                        >
                                            Show
                                        </Button>
                                        <Button type="primary" shape="round" className={'mx-1 addbutton ' + localStorage.getItem('currentTheme')}
                                            icon={<ExportOutlined />} onClick={showModal}
                                        >
                                            Export
                                        </Button>
                                    </div>
                                </Modal>
                            </div>
                            {/* <div className={localStorage.getItem('currentTheme')}>
                                {tableshow == true ?

                                    <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onStatChange} style={{ marginTop: '1%' }}>
                                        <Panel header="Overall Statistics" key={statkey}>
                                            <div className="col-md-12" style={{ marginLeft: '5%', marginRight: '5%' }}>
                                                <div className="row m-2">
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={30} width={90} format={(percent) => `${150}`} />
                                                        <h4>Complied</h4>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={20} width={90} status="exception" format={(percent) => `${80}`} />
                                                        <h4>Delayed Complied</h4>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={100} width={90} format={(percent) => `${420}`} />
                                                        <h4>Inprogress</h4>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={80} width={90} strokeColor={{ '0%': '#f5b342', '100%': '#f5b342' }} format={(percent) => `${180}`} />
                                                        <h4>Not Complied</h4>

                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse> : ''}
                            </div> */}
                            {tableshow == true ?

                                <Card className='rounded-3'>

                                    <div className='col-md-12 mt-0 mb-2' >
                                        <div className='row'>
                                            <div className='col-md-4 text-wrap-report'>
                                                <label><b>Legal Entity :</b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? legalentitydata.legel_entity : localStorage.getItem('SelectedEntity')}</label>
                                            </div>
                                            <div className='col-md-4 text-center'>
                                                <label><b>Country :</b> {legalentitydata.countryName ? legalentitydata.countryName : sessionArr[0].c_name}</label>
                                            </div>
                                            <div className='col-md-4 text-end'>
                                                <label><b>Domain :</b> {legalentitydata.domainName}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content userprivclass1'>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        <thead className="ant-table-thead antable-head" >
                                                            <tr>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '10px' }}>#</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '300px' }}>Compliance Task</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}>Frequency</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>Due Date</center></th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}>Compliance Task Status</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}><center>User Name</center></th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>Activity Status</center></th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}><center>Activity Date</center></th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }}><center>Uploaded Document</center></th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><Tooltip title='Task Completion Date/Document issued Date'>Date</Tooltip></th>
                                                            </tr>
                                                        </thead>
                                                        {filteredArrayState && filteredArrayState.length > 0 ?
                                                            filteredArrayState.map((item, i) => {
                                                                return (
                                                                    <tbody className='ant-table-tbody' key={i}>
                                                                        <tr className="bg-lightdark">
                                                                            <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                <div className='bg-white shadow-sm border-radius-reports'>
                                                                                    <div className="col-md-12">
                                                                                        <div className='row m-2'>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Unit :</b> {item[0].unit_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Division :</b> {item[0].division_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Category :</b> {item[0].category_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        {titledata && titledata.map((itemdata, i) => {
                                                                            let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                            if (data != undefined) {
                                                                                if (data.statutory_mapping == itemdata.parent) {
                                                                                    let list = itemdata.child
                                                                                    let size = 25
                                                                                    const items = list.slice(0, size)
                                                                                    return <Fragment key={i}>

                                                                                        <tr className='ant-table-row ant-table-row-level-0'>

                                                                                            <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                        </tr>

                                                                                        {itemdata.child.map((items, ijk) => {
                                                                                            if (item[0].unit_id == items.unit_id) {
                                                                                                return (
                                                                                                    <Fragment key={ijk}><tr>
                                                                                                        <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                                        <td className='ant-table-cell'><Tooltip title={items.compliance_description}>
                                                                                                            <ExclamationCircleTwoTone />
                                                                                                        </Tooltip> &nbsp;{items.compliance_task}</td>
                                                                                                        <td className='ant-table-cell'>{items.frequency_name}</td>
                                                                                                        <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                        <td className='ant-table-cell'>{items.task_status}</td>
                                                                                                        <td className='ant-table-cell'>{items.assignee_name}</td>
                                                                                                        <td className='ant-table-cell'>{items.activity_status}</td>
                                                                                                        <td className='ant-table-cell'><center>{items.activity_date}</center></td>
                                                                                                        <td className='ant-table-cell'>{items.document_name}</td>
                                                                                                        <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                                    </tr>
                                                                                                        <span hidden>{index = index + 1}</span>
                                                                                                    </Fragment>
                                                                                                )
                                                                                            }

                                                                                        })

                                                                                        }
                                                                                    </Fragment>
                                                                                }
                                                                            }
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                )
                                                            }) :
                                                            <tr>

                                                                <td colSpan="100%" style={{ paddingTop: '5%', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '5%' }}>No Records Found</td>

                                                            </tr>
                                                        }
                                                    </table>
                                                    {/* <br /> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        {index != 0 ?
                                            <div className='col-md-6' >
                                                <label>Showing {pageSize * (current - 1) + 1} to {index} of {tableData.total_count} entries</label>
                                            </div> : ''}
                                        <div className='col-md-6 text-right'>
                                            <Pagination
                                                current={current}
                                                showSizeChanger={true}
                                                // showQuickJumper={true}
                                                onShowSizeChange={() => {
                                                    setCurrent(1)
                                                    // setpageState(true)
                                                }}
                                                pageSizeOptions={[25, 50, 100]} hideOnSinglePage={tableData.total_count > 25 ? false : true} defaultPageSize={25}
                                                onChange={(page, pageSizes) => {
                                                    setpageState(true)
                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                    setPageSize(pageSizes)
                                                }} total={tableData.total_count}
                                            // showTotal={(total) => `Showing 1 to ${index} of ${tableData.total_count} entries`}
                                            />
                                        </div>
                                    </div>
                                </Card>
                                : false}
                        </div>
                    </div>
                </div>
            </div>
            <Drawer title="Select Fields" className={'add-service-prv export-checkbox resize-drawer ' + localStorage.getItem('currentTheme')} open={isModalOpen} footer={null} onCancel={handleCancel} onClose={handleCancel} width={600} maskClosable={false}>
                <div className='d-flex ms-1 align-items-center border-bottom pb-2'>
                    <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                        checked={checkboxValue.length === 32 ? true : false}
                        onClick={(e) => {
                            let checked = e.target.checked
                            selectAllCheckBox(checked)
                        }} />  <label for='select_all'>Select All</label>

                    <p className='selected'>Selected: <span className='fs-5 fw-bold ms-1'>{checkboxValue.length}</span></p>
                    <p className='un-selected'>Unselected:<span className='fs-5 fw-bold ms-1'>{32 - (checkboxValue.length)}</span></p>
                </div>
                <div className='d-flex flex-wrap pt-2'>
                    <input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /> <label for='column_1'>Unit Code</label>
                    <input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_2'> Unit Name</label>
                    <input id="column_7" name='chk' type="checkbox" value='7' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_7'> Location</label>


                    <input id="column_8" name='chk' type="checkbox" checked disabled="disabled" value='8' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_8'> Act / Rules</label>
                    <input id="column_9" name='chk' type="checkbox" checked disabled="disabled" value='9' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_9'> Compliance Task</label>
                    <input id="column_10" name='chk' type="checkbox" checked disabled="disabled" value='10' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_10'> Frequency</label>
                    <input id="column_29" name='chk' type="checkbox" checked disabled="disabled" value='29' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_29'> Statutory Status</label>


                    <input id="column_32" name='chk' type="checkbox" value='32' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_32'> Remarks</label>
                    <input id="column_3" name='chk' type="checkbox" value='3' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_3'>Unit Code 1</label>
                    <input id="column_4" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_4'>Unit Code 2</label>
                    <input id="column_5" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_5'>Unit Code 3</label>



                    <input id="column_6" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_6'>Unit Code 4</label>
                    <input id="column_11" name='chk' type="checkbox" value='11' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_11'>Periodicity</label>
                    <input id="column_12" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_12'>Division</label>
                    <input id="column_13" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_13'>Category</label>



                    <input id="column_14" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_14'>Assigned by</label>
                    <input id="column_15" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_15'>Assigned To</label>
                    <input id="column_16" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_16'>Assigned Date</label>
                    <input id="column_17" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_17'>Assignee</label>



                    <input id="column_18" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_18'>Completed on</label>
                    <input id="column_19" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_19'>Concurrer</label>
                    <input id="column_20" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_20'>Concurred on</label>
                    <input id="column_21" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_21'>Approver</label>



                    <input id="column_22" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_22'>Approved on</label>
                    <input id="column_23" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_23'>Start Date</label>
                    <input id="column_24" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_24'>Due Date </label>
                    <input id="column_25" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_25'>Extend Due Date</label>



                    <input id="column_26" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_26'>Task Completion Date / Document Issued Date</label>
                    <input id="column_27" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_27'>Month </label>
                    <input id="column_28" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_28'>Validity Date</label>
                    <input id="column_29" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_29'> Duration </label>
                    <input id="column_31" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} />  <label for='column_31'>Document Reference Number</label>

                </div>




                <div className="form-actions popupbtncolour text-center">
                    <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>
                </div>

            </Drawer >

        </div >

    )
}

export default connect(mapStateToProps, {
    LegalentityFilters,
    Legalentityshow,
    Legalentityexport
})(LegalentityReport);