import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { Redirect, useHistory } from 'react-router-dom';
import { login } from './../Store/Action/Login'
import { Toaster } from "../Libs/Toaster";
import { Button } from 'antd'
import { SaveRegistration, CheckLink, CheckuserName, CheckuserNameBlur } from '../Store/Action/RegisterUser';

const mapStateToProps = (state) => ({
    loginstatus: state.Login.success,
    linkstatus: state.RegisterUser.link_status,
    captchadata: state.RegisterUser.captchvalue
})

const Register = ({
    SaveRegistration,
    CheckLink,
    loginstatus,
    linkstatus,
    captchadata,
    CheckuserName,
    CheckuserNameBlur
}) => {
    const history = useHistory()
    console.log(linkstatus, 'linkstatus')
    const location = useLocation();
    const paramid = location.pathname;
    const [Auth, SetAuth] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [username, setUsername] = useState('')
    const [LoginData, setLoginData] = useState({
        userName: '',
        password: '',
        confirm_password: ''

    });
    const validator = useRef(new SimpleReactValidator());
    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [pwd1, setPwd1] = useState('');
    const [isRevealPwd1, setIsRevealPwd1] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        let groupvalue = paramid.split('/');
        let payload = [
            groupvalue[2],
            [
                "CheckRegistrationToken",
                {
                    "reset_token": groupvalue[3]
                }
            ]
        ]
        CheckLink({
            payload: payload,
            callername: paramid,
            key: groupvalue[3]
        })
    }, [])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        SetAuth(localStorage.getItem('isAuthenticated'));
    }, [])

    useEffect(() => {
        if (loginstatus == false) {
            setAddFormSubmit(false);
            setPwd('')
            setUsername('')
            setLoginData({
                ...LoginData,
                userName: '',
                password: '',
                groupName: '',
                captcha: '',

            })
        }
    }, [loginstatus])


    const submitLogin = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            if (linkstatus == 'expired' || linkstatus == '') {
                Toaster.error("Registration link expired.Please contact the Administrator")
            } else {
                if (LoginData.password != LoginData.confirm_password) {
                    Toaster.error('Confirm password should match with password');
                } else {
                    let groupvalue = paramid.split('/');
                    const userpay = [
                        groupvalue[2],
                        [
                            "CheckUsername",
                            {
                                "uname": LoginData.userName,
                                "reset_token": groupvalue[3]
                            }
                        ]
                    ]

                    const savepay = [
                        groupvalue[2],
                        [
                            "SaveRegistration",
                            {
                                "uname": LoginData.userName,
                                "pword": LoginData.password,
                                "captcha": captchadata,
                                "token": groupvalue[3],
                                "mob_no": "-"
                            }
                        ]
                    ]
                    CheckuserName({
                        payload: userpay,
                        callername: paramid,
                        key: groupvalue[3],
                        savepay: savepay,
                        navigate: history
                    })
                }
            }

        }
    }

    const checkUserNameExists = (e) => {
        let groupvalue = paramid.split('/');
        if (linkstatus == 'expired' || linkstatus == '') {
            Toaster.error("Registration link expired.Please contact the Administrator")
        }
        else {
            const userpay = [
                groupvalue[2],
                [
                    "CheckUsername",
                    {
                        "uname": LoginData.userName,
                        "reset_token": groupvalue[3]
                    }
                ]
            ]
            if (LoginData.userName != '') {
                CheckuserNameBlur({
                    payload: userpay,
                    callername: paramid,
                    key: groupvalue[3],
                })
            }
        }

    }



    return (

        // (Auth && Auth ? <Redirect to='/home' /> : <Fragment>

        (<Fragment>
            <body>
                <div className=" register-wrapper-bg auth-wrapper d-flex no-block justify-content-center 
                align-items-center  flex-column">
                    <div style={{ borderRadius: '2rem', maxWidth: '450px' }} className="auth-box p-3 bg-white m-0">
                        <center><img src="logo-icon.png" height="50px" alt='Logo' /></center>
                        <div id="loginform">
                            <div className="logo">
                                <center>
                                    <h3 className="box-title mb-1">Create Login Credentials</h3>
                                    <p className='mb-0 py-2'>Use the form below to create login crendentials that can be used for compfie</p>
                                </center>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <form className="mt-2" onSubmit={submitLogin}>
                                        <div className="form-group mb-3 position-relative">

                                            <input className="form-control" autoComplete='off' autoFocus='on' maxLength={20} value={username} type="text" required="" placeholder="User ID" onChange={(e) => {
                                                setUsername(e.target.value)
                                                setLoginData({
                                                    ...LoginData, userName: e.target.value
                                                })
                                            }}
                                                onBlur={(e) => {
                                                    checkUserNameExists(e)
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[a-zA-Z0-9 ]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                }}
                                            />
                                            <i style={{ position: 'absolute', top: '6px', right: '7px', cursor: 'text', fontSize: '1.3rem' }} className="ri-user-line icon-override"></i>
                                            {validator.current.message(
                                                'userName',
                                                LoginData.userName,
                                                ['required',
                                                    `max:50`
                                                ],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'User ID required',
                                                        max: 'Maximum character is 20 only'

                                                    }
                                                })}

                                        </div>

                                        <div className="form-group mb-3 position-relative">
                                            <input className="form-control"
                                                type={isRevealPwd ? "text" : "password"}
                                                value={pwd} required="" autoComplete='off' placeholder="Password"
                                                maxLength='20'
                                                onChange={(e) => {
                                                    setPwd(e.target.value)
                                                    setLoginData({
                                                        ...LoginData, password: e.target.value
                                                    })
                                                }}
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                }} />
                                            <i onClick={() => setIsRevealPwd(prevState => !prevState)} style={{ position: 'absolute', cursor: 'text', top: '6px', right: '7px', fontSize: '1.3rem' }} className={(isRevealPwd && pwd) ? "ri-eye-line icon-override" : "ri-eye-off-line icon-override"}></i>
                                            {validator.current.message(
                                                'password',
                                                LoginData.password,
                                                ['required',
                                                    `max:20`
                                                ],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Password required',
                                                        max: 'Maximum character is 20 only'

                                                    }
                                                })}


                                        </div>

                                        <div className="form-group mb-3 position-relative">
                                            <input className="form-control" name='confirm_password'
                                                type={isRevealPwd1 ? "text" : "password"}
                                                value={pwd1} required="" autoComplete='off' placeholder="Confirm Password"
                                                maxLength='20'
                                                onChange={(e) => {
                                                    setPwd1(e.target.value)
                                                    setLoginData({
                                                        ...LoginData, confirm_password: e.target.value
                                                    })
                                                }}
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                }} />
                                            <i onClick={() => setIsRevealPwd1(prevState => !prevState)} style={{ position: 'absolute', cursor: 'text', top: '6px', right: '7px', fontSize: '1.3rem' }} className={(isRevealPwd1 && pwd1) ? "ri-eye-line icon-override" : "ri-eye-off-line icon-override"}></i>
                                            {validator.current.message(
                                                'confirm_password',
                                                LoginData.confirm_password,
                                                ['required',
                                                    `max:20`
                                                ],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Confirm password required',
                                                        max: 'Maximum character is 20 only'

                                                    }
                                                })}


                                        </div>
                                        <div className="form-group mb-3 d-flex justify-content-center">
                                            {/* <button style={{ backgroundColor: '#8572ed', borderRadius: '10px'}} type='submit' className="btn btn-primary border-0 w-100 login3-btn" onClick={submitLogin}>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <span className='fs-5'> Register</span>
                                                    <i className="ri-arrow-right-line arrow-btn-hover fs-5"></i>
                                                </div>
                                            </button> */}
                                            <Button
                                                disabled={linkstatus == 'expired' ? true : false}
                                                type="primary"
                                                shape="round"
                                                className="btn btn-primary border-0 w-100 login3-btn"
                                                onClick={submitLogin}
                                            >
                                                Register
                                            </Button>
                                        </div>


                                        {/* </div> */}
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>





                </div>
            </body>

        </Fragment>)


    )
}

export default connect(mapStateToProps, {
    login,
    SaveRegistration,
    CheckLink,
    CheckuserName,
    CheckuserNameBlur
})(Register);