import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link, NavLink, useLocation } from "react-router-dom";
import { getWidgetData } from '../Store/Action/Widgets';
import { HomeRedirect } from './../Libs/country';
const homepage = HomeRedirect()


const mapStateToProps = (state) => ({
  widgetInfo: state.Widgets.widInfo,
})

const SideNav = ({
  widgetInfo
}) => {
  console.log(widgetInfo, 'widgetInfowidgetInfo')
  const history = useHistory();
  const callername = JSON.parse(localStorage.getItem('sessionValue'));
  const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
  let [cindex, setCIndex] = useState(0);
  const menu = Object.values(sessionValue.login_response.menu)
  //const menu = sessionValue.login_response.menu
  const [activeClass, setActiveClass] = useState('');
  console.log(menu, 'menuListout')
  const [temp1, setTemp1] = useState(false)
  const [temp, setTemp] = useState(false)
  const [complianceTrend, setComplianceTrend] = useState(false)
  const [comparativeTemp, setcomparativeTemp] = useState(false)
  const [RiskTrend, setRiskTrend] = useState(false)
  const [criticalityChart, setcriticalityChart] = useState(false)
  const [Task, setTask] = useState(false)
  const [overDue, setOverDue] = useState(false)
  const isAuth = localStorage.getItem('isAuthenticated');
  const [entityData, setEntityData] = useState([])
  const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
  const authtoken = localStorage.getItem('authToken');
  const sessionvalue = localStorage.getItem('sessionValue')
  const sessionParsedValue = JSON.parse(sessionvalue)
  const sessionArr = sessionParsedValue.entity_info
  const location = useLocation();
  const paramid = location.pathname;

  console.log(temp, 'temp999999999999');
  const [sidebarbg, setBarBg] = useState('skin6')
  const [tempVariable, setTempVariable] = useState([])
  console.log(tempVariable, 'tempVariable')
  useEffect(() => {
    setBarBg(localStorage.getItem("currentSkin"))
  }, [localStorage.getItem("currentSkin")])
  useEffect(() => {
    let temp = []
    if (widgetInfo) {
      if (widgetInfo && widgetInfo.widget_list && widgetInfo.widget_list.length > 0) {
        for (let i in widgetInfo && widgetInfo.widget_list && widgetInfo.widget_list) {
          temp.push(widgetInfo && widgetInfo.widget_list && widgetInfo.widget_list[i].w_name)
        }
      }
    }
    // localStorage.setItem('WidgetInfo', JSON.stringify(temp))
    setTempVariable(temp)
  }, [widgetInfo])

  useEffect(() => {
    if (tempVariable && tempVariable.length > 0) {
      if (tempVariable.includes('Compliance Status')) {
        setTemp1(true)
        setTemp(true)
      }
      if (tempVariable.includes('Compliance Trend')) {
        setTemp1(true)
        setComplianceTrend(true)
      }
      if (tempVariable.includes('Comparative Trend')) {
        setTemp1(true)
        setcomparativeTemp(true)
      }
      if (tempVariable.includes('Risk Chart')) {
        setTemp1(true)
        setRiskTrend(true)
      }
      if (tempVariable.includes('Criticality')) {
        setTemp1(true)
        setcriticalityChart(true)
      }
      if (tempVariable.includes('Task Distribution')) {
        setTemp1(true)
        setTask(true)
      }
      if (tempVariable.includes('Over Due Chart')) {
        setTemp1(true)
        setOverDue(true)
      }
    }
    else {
      if (localStorage.getItem('WidgetInfo') && localStorage.getItem('WidgetInfo').length > 0 && localStorage.getItem('WidgetInfo').includes('Compliance Status')) {
        setTemp1(true)
        setTemp(true)
      }
      if (localStorage.getItem('WidgetInfo') && localStorage.getItem('WidgetInfo').length > 0 && localStorage.getItem('WidgetInfo').includes('Compliance Trend')) {
        setTemp1(true)
        setComplianceTrend(true)
      }
      if (localStorage.getItem('WidgetInfo') && localStorage.getItem('WidgetInfo').length > 0 && localStorage.getItem('WidgetInfo').includes('Comparative Trend')) {
        setTemp1(true)
        setcomparativeTemp(true)
      }
      if (localStorage.getItem('WidgetInfo') && localStorage.getItem('WidgetInfo').length > 0 && localStorage.getItem('WidgetInfo').includes('Risk Chart')) {
        setTemp1(true)
        setRiskTrend(true)
      }
      if (localStorage.getItem('WidgetInfo') && localStorage.getItem('WidgetInfo').length > 0 && localStorage.getItem('WidgetInfo').includes('Criticality')) {
        setTemp1(true)
        setcriticalityChart(true)
      }
      if (localStorage.getItem('WidgetInfo') && localStorage.getItem('WidgetInfo').length > 0 && localStorage.getItem('WidgetInfo').includes('Task Distribution')) {
        setTemp1(true)
        setTask(true)
      }
      if (localStorage.getItem('WidgetInfo') && localStorage.getItem('WidgetInfo').length > 0 && localStorage.getItem('WidgetInfo').includes('Over Due Chart')) {
        setTemp1(true)
        setOverDue(true)
      }
    }
  }, [tempVariable, localStorage.getItem('WidgetInfo')])

  useEffect(() => {
    if (isAuth) {
      const payload = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "GetUserWidgetData",
            {}
          ]
        }
      ]
      if (paramid != "") {
        getWidgetData({
          payload: payload,
          paramid: paramid
        })
      }
    }
  }, [isAuth, entityid, entityData])

  useEffect(() => {
    // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
    let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
      return item.le_id
    })
    setEntityData(id)
    // }
  }, [])

  const homescreen = () => {
    let getUrlDetails = homepage;
    let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
    let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
    localStorage.setItem('formkey', tempkey);    
     window.location.href = tempval
}

  return (
    <Fragment>
      <aside className="left-sidebar" data-sidebarbg={sidebarbg}>
        <div className="scroll-sidebar h-100">
          <nav className="sidebar-nav mt-3">
            <ul id="sidebarnav" className='px-1'>
              {menu.length > 0 && menu.map((menuList, c_s_Id) => {
                const arr = [];
                const subarr = [];
                //cindex =0;
                sortByMonth(menuList)
                function sortByMonth(arrsort) {
                  arrsort.sort(function (a, b) {
                    return a.form_order - b.form_order
                  });
                }
                menuList.map(function (user) {

                  if ((arr.indexOf(user.parent_menu) < 0))
                    arr.push(user.parent_menu);
                  if (user.parent_menu === null) {
                    arr.push(user);
                  }
                });
                var featherIcon
                switch (menuList[0].form_type) {
                  case 'Dashboard':
                    featherIcon = 'globe'
                    break;
                  case 'Master':
                    featherIcon = 'grid'
                    break;
                  case 'Transaction':
                    featherIcon = 'file-text'
                    break;
                  case 'Report':
                    featherIcon = 'archive'
                    break;
                  case 'Static':
                    featherIcon = 'home'
                    break;
                  default:
                    featherIcon = 'list'
                    break;
                }
                //  && menuList[0].form_type !== 'Static'
                if (menuList[0].form_type !== 'My Accounts' && menuList[0].form_type !== 'Static')
                  return <li className="sidebar-item" key={c_s_Id}>
                    {c_s_Id == 0 ?
                      <>
                        <Link
                          className="sidebar-link px-1 py-1 waves-effect waves-dark sidebarparent"
                          id={"c_s_Id-home" + c_s_Id}
                          onClick={homescreen}
                          aria-expanded="false"
                        ><i data-feather={'home'}></i>
                          <span className="hide-menu">{'Home'}</span></Link>
                      </>
                      : false}
                    <Link
                      className="sidebar-link px-1 py-1 has-arrow waves-effect waves-dark sidebarparent mt-3"
                      id={"c_s_Id" + c_s_Id}
                      aria-expanded="false"
                    ><i data-feather={featherIcon}></i>
                      <span className="hide-menu">{menuList[0].form_type}</span></Link>
                    <ul className="collapse first-level subul">

                      {
                        arr.map((pmenu, pId) => {
                          if (typeof pmenu !== 'object' && pmenu) {
                            return <li className="sidebar-item" title={pmenu} key={pId}>
                              <Link className={"sidebar-link px-1 py-1 has-arrow waves-effect waves-dark sidebarparent"} id={"pId_" + pId}
                              ><i className="ri-add-circle-line"></i>
                                <span className="hide-menu fs-3" >{pmenu} </span></Link>
                              <ul className="collapse second-level subul">
                                {menuList && menuList.map((subMenuList, childIds) => {
                                  console.log(subMenuList, 'subMenuList')
                                  if (pmenu === subMenuList.parent_menu) {
                                    return <li className="sidebar-item"
                                      title={subMenuList.form_name} key={childIds}>
                                      {activeClass == "subchild_" + childIds + "_" + subMenuList.form_id ?
                                        <NavLink to={subMenuList.form_url} activeClassName="active" id={"subchild_" + childIds + "_" + subMenuList.form_id} className={"sidebar-link px-1 py-1 active"}
                                          onClick={(e) => {
                                            localStorage.setItem('formkey', subMenuList.form_key)
                                            setActiveClass("subchild_" + childIds + "_" + subMenuList.form_id)
                                          }}>
                                          <i className="ri-indeterminate-circle-line"></i>
                                          <span className="hide-menu fs-3" >{subMenuList.form_name} </span></NavLink>
                                        :
                                        <NavLink to={subMenuList.form_url} activeClassName="active" id={"subchild_" + childIds + "_" + subMenuList.form_id} className={"sidebar-link px-1 py-1"}
                                          onClick={(e) => {
                                            localStorage.setItem('formkey', subMenuList.form_key)
                                            setActiveClass("subchild_" + childIds + "_" + subMenuList.form_id)
                                          }}
                                        ><i className="ri-indeterminate-circle-line"></i>
                                          <span className="hide-menu fs-3" >{subMenuList.form_name} </span></NavLink>}
                                    </li>
                                  }
                                })
                                }
                              </ul>
                            </li>

                          }
                          else if (menuList[0].form_type == 'Dashboard' && pId == 0) {
                            return menuList.length > 0 && menuList.map((subMenuList) => {
                              if (subMenuList.parent_menu == null) {
                                return (
                                  <>
                                    {/* <li className="sidebar-item" title="Landing Page">
                                      <a href='/page' className={"sidebar-link px-1 py-1"}
                                      ><i className="ri-indeterminate-circle-line"></i>
                                        <span className="hide-menu fs-3">Landing Page</span></a>
                                    </li> */}
                                    {temp ?
                                      <li className="sidebar-item" title="Compliance Status" key={localStorage.getItem('WidgetInfo')}>
                                        <NavLink to={`${subMenuList.form_url}/compliances`} id="status-1234" activeClassName="active" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                          localStorage.setItem('formkey', subMenuList.form_key)
                                        }}
                                        ><i className="ri-indeterminate-circle-line"></i>
                                          <span className="hide-menu fs-3"> Compliance Status</span></NavLink>
                                      </li> : ''}
                                    {complianceTrend == true ?
                                      <li className="sidebar-item" title="Compliance Trend">
                                        <NavLink to={`${subMenuList.form_url}/complianceTrend`} id="compliance-1234" activeClassName="active" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                          localStorage.setItem('formkey', subMenuList.form_key)
                                        }}
                                        ><i className="ri-indeterminate-circle-line"></i>
                                          <span className="hide-menu fs-3"> Compliance Trend</span></NavLink>
                                      </li> : ''}
                                    {comparativeTemp === true ?
                                      <li className="sidebar-item" title="Comparative Trend">
                                        <NavLink to={`${subMenuList.form_url}/comparative`} id="comparitive-1234" activeClassName="active" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                          localStorage.setItem('formkey', subMenuList.form_key)
                                        }}
                                        ><i className="ri-indeterminate-circle-line"></i>
                                          <span className="hide-menu fs-3"> Comparative Trend</span></NavLink>
                                      </li> : ''}
                                    {RiskTrend == true ?
                                      <li className="sidebar-item" title="Risk Chart">
                                        <NavLink to={`${subMenuList.form_url}/risk`} id="risk-1234" activeClassName="active" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                          localStorage.setItem('formkey', subMenuList.form_key)
                                        }}
                                        ><i className="ri-indeterminate-circle-line"></i>
                                          <span className="hide-menu fs-3"> Risk Chart</span></NavLink>
                                      </li> : ''}
                                    {criticalityChart == true ?
                                      <li className="sidebar-item" title="Criticality Chart">
                                        <NavLink to={`${subMenuList.form_url}/criticality`} id='criticality-12354' activeClassName="active" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                          localStorage.setItem('formkey', subMenuList.form_key)
                                          localStorage.setItem('type', "criticality")
                                        }}
                                        ><i className="ri-indeterminate-circle-line"></i>
                                          <span className="hide-menu fs-3">Criticality Chart</span></NavLink>
                                      </li> : ''}
                                    {Task == true ?
                                      <li className="sidebar-item" title="Task Distribution">
                                        <NavLink to={`${subMenuList.form_url}/taskDistribution`} id="taskdi-1234" activeClassName="active" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                          localStorage.setItem('formkey', subMenuList.form_key)
                                        }}
                                        ><i className="ri-indeterminate-circle-line"></i>
                                          <span className="hide-menu fs-3">Task Distribution</span></NavLink>
                                      </li> : ''}
                                    {overDue == true ?
                                      <li className="sidebar-item" title="Over Due Chart">
                                        <NavLink to={`${subMenuList.form_url}/overdue`} id="overdue-1234" activeClassName="active" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                          localStorage.setItem('formkey', subMenuList.form_key)
                                        }}
                                        ><i className="ri-indeterminate-circle-line"></i>
                                          <span className="hide-menu fs-3"> Over Due Chart</span></NavLink>
                                      </li> : ''}
                                    <li className="sidebar-item" title="Assignee Wise Compliance">
                                      <NavLink to={`${subMenuList.form_url}/assigneewisecompliance`} id="assignwise-1234" activeClassName="active" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                        localStorage.setItem('formkey', subMenuList.form_key)
                                      }}
                                      ><i className="ri-indeterminate-circle-line"></i>
                                        <span className="hide-menu fs-3"> Assignee Wise Compliance</span></NavLink>
                                    </li>
                                  </>
                                )
                              }
                            })
                          }
                          else {
                            // return subarr.length > 0 && subarr.map((subMenuList, childId) => {
                            // if (subMenuList && subMenuList.parent_menu == null) {
                            // console.log(childId, pId, 'subMenuList1234')
                            // console.log(subMenuList, 'subMenuList1234') style={{ pointerEvents: 'none' }}
                            if (pmenu && pmenu.form_name != "Dashboard") {
                              return <li className="sidebar-item" title={pmenu.form_name}>
                                {activeClass == "child_" + pId + "_" + pmenu.form_id ?
                                  <NavLink to={pmenu.form_url} id={"child_" + pId + "_" + pmenu.form_id} activeClassName="active" className={"sidebar-link px-1 py-1"}
                                    onClick={(e) => {
                                      localStorage.setItem('formkey', pmenu.form_key)
                                      setActiveClass("child_" + pId + "_" + pmenu.form_id)
                                    }}>
                                    <i className="ri-indeterminate-circle-line"></i>
                                    <span className="hide-menu fs-3">{pmenu.form_name}</span></NavLink>
                                  : <NavLink to={pmenu.form_url} id={"child_" + pId + "_" + pmenu.form_id} className={"sidebar-link px-1 py-1"}
                                    onClick={(e) => {
                                      localStorage.setItem('formkey', pmenu.form_key)
                                      setActiveClass("child_" + pId + "_" + pmenu.form_id)
                                    }}
                                  ><i className="ri-indeterminate-circle-line"></i>
                                    <span className="hide-menu fs-3">{pmenu.form_name}</span></NavLink>}
                              </li>
                            }

                            // }
                            //})
                          }
                        })
                      }

                    </ul>
                  </li>
              })}
            </ul>
          </nav>
        </div>
      </aside>

    </Fragment>
  )
};
export default connect(mapStateToProps, {
  getWidgetData
})(SideNav);