import React from 'react'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Popover, Drawer, Button, Table, Popconfirm, Tooltip, Modal, Descriptions, Empty, Space, Spin } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { ArrowRightOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, ExclamationCircleFilled, FilterOutlined, UpOutlined, DownOutlined, FullscreenOutlined, ArrowLeftOutlined, PlayCircleOutlined, BookTwoTone, SearchOutlined, FilterFilled } from '@ant-design/icons';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getCommonData, getOverduechart, getOverDueChartTableData, getTimeZoneData, getOverDueShowMoreTableData } from '../../Store/Action/Dashboard/Overdue';
import { toast } from 'react-toastify';
import ScrollToTop from 'react-scroll-to-top';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";

import moment from 'moment';
import { HomeRedirect, CountrySplit } from '../../Libs/country';
import ScrollButton from '../Scrollbar';
const uniq_country_data = CountrySplit()
const homepage = HomeRedirect()


// import "core-js/stable";
// import "regenerator-runtime/runtime";

const mapStateToProps = (state) => ({
    data: state.Overdue.overdueChart.chartData,
    time: state.Overdue.overdueChart.time,
    commonData: state.Overdue.overdueChart.Data,
    tableData: state.Overdue.overdueChart.tableData,
    timeZoneData: state.Overdue.overdueChart.timeZoneData,
    showMoreData: state.Overdue.overdueChart.showMoreData

    // tableData1: state.RiskChart.riskChart.delayedTableData,

})

const OverDueChart = ({
    // getFilterValue,
    getOverduechart,
    getCommonData,
    data,
    commonData,
    getOverDueChartTableData,
    // getRiskChartDelayedTableData,
    time,
    getTimeZoneData,
    // getCriticalityTableData,
    tableData,
    timeZoneData,
    getOverDueShowMoreTableData,
    showMoreData
    // tableData1
}) => {
    var [count, setCount] = useState(1)
    const _ = require("lodash");
    const [open, setOpen] = useState(false)
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [chartId, setChartId] = useState()
    const [table, setTable] = useState(false)
    const chart = useRef(null);
    const { Option } = Select;
    const { Panel } = Collapse;
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const [timeZone, setTimeZone] = useState("Afica/Accara")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const [entityData, setEntityData] = useState([])
    const [showmoreCount, setShowMoreCount] = useState(0)
    const [dataCount, setDataCount] = useState()
    const [criticalityTable, setCriticalityTable] = useState([])
    const [tempcriticalityTable, setTempcriticalityTable] = useState([])
    const [sortingpurpose, setSortingpurpose] = useState([])
    console.log(sortingpurpose, 'sortingpurpose');
    console.log(tempcriticalityTable, 'tempcriticalityTable');
    console.log(criticalityTable, 'criticalityTable');
    const [updatedArray, setUpdatedArray] = useState([])
    const [criticalityValue, setCriticalityValue] = useState()
    const TimeZoneValue = sessionParsedValue.login_response
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const location = useLocation();
    // const paramid = location.pathname;
    const [paramid, setParamId] = useState("")
    const [filteredCountries, setFilteredCountries] = useState([])

    const [filterData, setFilterData] = useState({
        country: [],
        domain: [],
        legalentity: '',
        leIds: '',
        division: '',
        unit: '',
        domainIds: '',
        business: '',
        category: '',
        unit: '',
        countryArr: []
    })
    const [filterDataNames, setFilterDataNames] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const [field, setfield] = useState({
        country: [],
        domain: [],
        legalEntity: [],
        business: [],

    })
    console.log(field, 'field');
    const [legalEntityArray, setLegalEntityArray] = useState([])
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [unit, setUnit] = useState([])
    const [hiddenRiskData, setHiddenRiskData] = useState([])
    const [exposedRiskData, setExposedRiskData] = useState([])
    const [chartValue, setChartValue] = useState('')
    const [generatedOn, setGeneratedOn] = useState(false)
    const [fieldType, setFieldType] = useState("")
    const [go, setGo] = useState(false)
    const [domainName, setDomainName] = useState()
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const [countValue, setCountValue] = useState(0)
    const [compliedType, setCompliedType] = useState()
    const [indexValue, setIndexValue] = useState([])
    let [counts, setcounts] = useState(0)

    const { OptGroup } = Select;
    const [domainListData, setDomainListData] = useState([])
    const [divisionListData, setDivisionListData] = useState([])
    const [categoryListData, setCategoryListData] = useState([])
    const [unitListData, setUnitListData] = useState([])
    const [bgIds, setBgIds] = useState([])
    const [groupCheckBox, setGroupCheckBox] = useState(true)
    const [checkBox, setCheckBox] = useState(true)
    const [textArray, setTextArray] = useState([])
    const [countryDomain, setCountryDomain] = useState([])
    const [dIds, setDIds] = useState([])
    const [cIds, setCIds] = useState([])
    const [year, setYear] = useState(moment().year())
    const [columnState, setCoulmnState] = useState(false)
    const [type, setType] = useState('')
    const [legalEntity, setLegalEntity] = useState([])
    const [errorField, setErrorField] = useState('')
    const [fieldTypeClick, setFieldTypeClick] = useState("")
    const [newCountryDomain, setNewCountryDomain] = useState([])
    const [countryData, setCountryData] = useState([])
    const [domainData, setDomainData] = useState([])
    const sidefilterData = localStorage.getItem('filterChange')
    const parsedSideFilterData = JSON.parse(sidefilterData)
    const sideFilterState = localStorage.getItem('filterState')
    const isCountryValueTrue = localStorage.getItem('countryState')
    const [searchingValue, setSearchingValue] = useState([])
    const [searchedValue, setSearchedValue] = useState({
        bgGroup: '',
        legalEntity: '',
        division: '',
        compliance: '',
        criticality: '',
        assignedTo: '',
        overdue: ''
    })
    const myRef1 = useRef(null);
    const myRef2 = useRef(null);
    const myRef3 = useRef(null);
    const myRef4 = useRef(null);
    const myRef5 = useRef(null);
    const myRef6 = useRef(null);
    const myRef7 = useRef(null);
    const historynew = useHistory()
    const [bgGrpArray,setBgGrpArray] = useState([])

    console.log(bgGrpArray,'bgGrpArray');
    useEffect(()=>{
        if(commonData && commonData.bg_groups && commonData.bg_groups.length > 0){
            let temp = []
            for(let i in commonData.le_did_infos){
                if (commonData.le_did_infos[i].bg_id != null) {
                    let bgObj = _.find(commonData.bg_groups, { bg_id: commonData.le_did_infos[i].bg_id })
                    temp.push(bgObj)
                }
            }
            let orderedBg = _.orderBy(temp, ['bg_id'], ['desc'])
            setBgGrpArray(temp)
            console.log(orderedBg,'orderedBg');
        }

    },[commonData])

    console.log(JSON.parse(isCountryValueTrue), 'isCountryValueTrue');






    useEffect(() => {
        let newCountry = []
        let newDomain = []

        for (let j in filterData.country) {
            let filterCountry = _.filter(commonData.countries, { c_id: Number(filterData.country[j]) })
            // temp2.push(filterDomain)
            for (let i in filterCountry) {
                newCountry.push(filterCountry[i])
            }

        }
        for (let k in filterData.domain) {
            let filterDomain = _.filter(commonData.d_info, { d_id: Number(filterData.domain[k]) })
            // temp2.push(filterDomain)
            for (let l in filterDomain) {
                newDomain.push(filterDomain[l])
            }

        }
        const uniqueCountry = _.uniqBy(newCountry && newCountry, function (e) {
            return e.c_id;
        });
        setCountryData(uniqueCountry)
        // setDomainData(newDomain)
        console.log(newCountry, 'newCountry');
        console.log(newDomain, 'newDomain');
        console.log(filterData.country, 'filterData.country');
        console.log(filterData.domain, 'filterData.domain');

    }, [filterData.country, filterData.domain])

    useEffect(() => {
        let newDom = []
        for (let i in commonData.d_info) {
            newDom.push({
                "d_id": commonData.d_info[i].d_id,
                "d_name": commonData.d_info[i].d_name,
                "is_active": commonData.d_info[i].is_active,
                "c_id": commonData.d_info[i].c_id,
                "month_from": commonData.d_info[i].month_from,
                "month_to": commonData.d_info[i].month_to,
                "cd_id": `${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`
            })
        }

        let newDomain = []
        if (newDom && newDom.length > 0) {
            for (let k in filterData.domain) {
                console.log(filterData.domain[k], 'sssssss');
                let filterDomain = _.filter(newDom, { cd_id: `${filterData.domain[k]}` })
                console.log(filterDomain, 'filterDomain');
                // newDomain.push(filterDomain)
                for (let l in filterDomain) {
                    newDomain.push(filterDomain[l])
                }

            }
        }

        let newDomain1 = []
        if (newDomain && newDomain.length > 0) {
            for (let k in filterData.domain) {
                let filterDomain = _.filter(newDomain, { cd_id: `${filterData.domain[k]}` })
                // newDomain.push(filterDomain)
                for (let l in filterDomain) {
                    newDomain1.push(filterDomain[l])
                }

            }
            console.log(newDomain1, 'newDomain1');
        }
        setDomainData(newDomain)

        console.log(newDomain, 'newDomain');
        console.log(newDom, 'newDom');
    }, [commonData.d_info, filterData.domain])

    useEffect(() => {
        var obj = {}
        countryData.forEach(function (a) {
            var arr2 = []
            domainData.forEach(function (b) {
                var key = a.c_name
                if (a.c_id == b.c_id) {
                    arr2.push(b.d_name)
                    obj[key] = arr2.join()
                }
            });
        });
        let temp = []
        for (let m in obj) {
            console.log(obj[m], 'objmm');
            // let sm = `${m} - ${obj[m]}`
            let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
            temp.push(expectedCountryDomain)
        }
        let newCountryDomain = temp.join("[bold] | [/]")
        setTextArray(newCountryDomain)

    }, [countryData, domainData])

    const setFocus = (ref) => {
        setTimeout(() => {
            ref.current.focus();
        });
    };

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            let textArr = []
            let cNames = []
            let uniqueCountry = []
            let countryName;
            // setDomainData(commonData.d_info)
            for (let i in commonData.d_info) {
                tempIds.push(commonData.d_info[i].d_id)
                tempArr.push({
                    'd_id': commonData.d_info[i].d_id,
                    'c_id': commonData.d_info[i].c_id,
                    "month_from": commonData.d_info[i].month_from,
                    "month_to": commonData.d_info[i].month_to
                })
                uniqueCountry = _.uniqBy(commonData && commonData.countries, function (e) {
                    return e.c_id;
                });
                setCountryData(uniqueCountry)
                let id = uniqueCountry && uniqueCountry.length > 0 && uniqueCountry.map((item, i) => {
                    return item.c_id
                })
                setCIds(id)
                console.log(uniqueCountry, 'uniqueCountry');
                countryName = _.find(uniqueCountry, { c_id: Number(commonData.d_info[i].c_id) })

                cNames.push(commonData.d_info[i].d_name)

            }
            // var arr = []
            var obj = {}
            uniqueCountry.forEach(function (a) {
                var arr2 = []
                commonData.d_info.forEach(function (b) {
                    var key = a.c_name
                    if (a.c_id == b.c_id) {
                        arr2.push(b.d_name)
                        obj[key] = arr2.join()
                    }
                });
            });
            let temp = []
            for (let m in obj) {
                console.log(obj[m], 'objmm');
                // let sm = `${m} - ${obj[m]}`
                let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
                temp.push(expectedCountryDomain)
            }
            let newCountryDomain = temp.join("[bold] | [/]")
            // setTextArray(newCountryDomain)
            setDIds(tempIds)
            setCountryDomain(tempArr)
        }


    }, [commonData])




    useEffect(() => {
        if (fieldTypeClick === 'BusinessGroup') {
            if (filterDataNames.business === '') {
                setErrorField('Business Group Required')
            }
        }

        if (fieldTypeClick === 'LegalEntity') {
            if (filterDataNames.legalentity === '') {
                setErrorField('Legal Entity Required')
            }
        }
        if (fieldTypeClick === 'Division') {
            if (filterDataNames.division === '') {
                setErrorField('Division Required')
            }
        }
        if (fieldTypeClick === 'Category') {
            if (filterDataNames.category === '') {
                setErrorField('Category Required')
            }
        }
        if (fieldTypeClick === 'Unit') {
            if (filterDataNames.unit === '') {
                {
                    localStorage.getItem('isVendor') == "true" ?
                        setErrorField('Vendor Required') :
                        setErrorField('Unit Required')
                }
            }
        }

    }, [fieldTypeClick])

    useEffect(() => {
        if (fieldTypeClick === 'Country') {
            if (filterData.country.length === 0) {
                setErrorField('Country Required')
            }
            else {
                setErrorField('')

            }
        }
        if (fieldTypeClick === 'Domain') {
            if (filterData.domain.length === 0) {
                setErrorField('Domain Required')
            }
            else {
                setErrorField('')

            }
        }

    }, [fieldTypeClick, filterData])

    console.log(compliedType, 'compliedType');
    console.log(legalEntity, 'legalEntity');
    console.log(filterData, 'filterData');

    useEffect(() => {
        {
            var the_arr = location.pathname.split('/');
            the_arr.pop();
            let a = the_arr.join('/')
            setParamId(a)
        }

    }, []);


    // useEffect(() => {
    //     if (data) {
    //         setHiddenRiskData(data.risk_chart_data_nu)
    //         setExposedRiskData(data.risk_chart_data_dn)
    //     }
    // }, [data])



    console.log(filterData.domain, 'filterData.domain ');

    console.log(country_info, 'country_info');

    console.log(field, 'field');
    console.log(commonData, 'commonData');

    // useEffect(() => {
    //     if(field.country == ''){
    //         setfield({
    //             ...field,
    //             country:country_info
    //         })
    //     }
    // },[field.country])
    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }


    // // UseEffect for filters

    useEffect(() => {
        if (commonData) {
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                const uniqueIds = commonData.le_did_infos && commonData.le_did_infos.map((item) => {
                    return item.bg_id
                })
                setBgIds(uniqueIds)
            }
        }

    }, [commonData])

    //Country onChange

    console.log(filterData.country, 'counnnn');

    useEffect(() => {
        if (filterData.country) {
            let tempCountries = []
            let bgs = []
            let tempBgs = []
            let tempLe = []
            let filteredLe = []
            for (let i in filterData.country) {
                let filteredCountries = _.filter(uniq_country_data, { c_id: Number(filterData.country[i]) })
                console.log(filteredCountries, 'filteredCountries');
                tempCountries.push(...filteredCountries)
                if (bgGrpArray && bgGrpArray.length > 0) {
                    let filteredBg = _.filter(bgGrpArray, { c_id: Number(filterData.country[i]) })
                    bgs.push(...filteredBg)
                }
                if (bgs && bgs.length > 0) {
                    tempBgs = _.uniqBy(bgs, function (e) {
                        return e.bg_id;
                    });
                }
                if (tempBgs && tempBgs.length > 0) {
                    if (bgIds.includes(null)) {
                        filteredLe = commonData.le_did_infos.filter(function (currentElement) {
                            return currentElement.bg_id === null || currentElement.bg_id === Number(tempBgs[0].bg_id);
                        });
                    }
                    else {
                        filteredLe = _.filter(commonData.le_did_infos, { bg_id: Number(tempBgs[0].bg_id) })
                    }
                }
                else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                    tempLe = _.filter(commonData.le_did_infos, { c_id: Number(filterData.country[i]) })
                    filteredLe.push(...tempLe)
                }

            }

            setFilteredCountries(tempCountries)
            console.log(tempCountries, 'tempCountries');
            console.log(tempBgs, 'tempBgs');
            setfield({
                ...field,
                business: tempBgs,
                legalEntity: filteredLe
            })
            // setLegalEntityArray(filteredEntity)
        }

    }, [filterData.country, uniq_country_data,bgGrpArray])

    //Domain onChange

    useEffect(() => {
        if (fieldTypeClick === 'Domain') {
            if (filterData.domain) {
                let tempBgs = []
                let tempLe = []
                let filteredLe = []
                for (let i in filterData.domain) {
                    if (bgGrpArray && bgGrpArray.length > 0) {
                        let filteredBg = _.filter(bgGrpArray, { d_id: Number(filterData.domain[i]) })
                        tempBgs.push(...filteredBg)
                    }
                    console.log(tempBgs, 'tempBgs');
                    if (tempBgs && tempBgs.length > 0) {
                        if (bgIds.includes(null)) {
                            filteredLe = commonData.le_did_infos.filter(function (currentElement) {
                                return currentElement.bg_id === null || currentElement.bg_id === Number(tempBgs[0].bg_id);
                            });
                        }
                        else {
                            filteredLe = _.filter(commonData.le_did_infos, { bg_id: Number(tempBgs[0].bg_id) })
                        }
                    }
                    else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                        filteredLe = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(filterData.domain[i])))
                    }
                }
                console.log(filteredLe, 'filteredLe');
                setfield({
                    ...field,
                    business: tempBgs,
                    legalEntity: filteredLe
                })
            }
        }

    }, [filterData.domain, fieldTypeClick,bgGrpArray])


    useEffect(() => {
        if (field && field.legalEntity && field.legalEntity.length > 0) {
            setLegalEntityArray([field.legalEntity[0]])
        }

    }, [field.legalEntity])

    console.log(unit, 'unitttt');

    // Legal entity onChange

    useEffect(() => {
        if (((commonData) && (legalEntityArray && legalEntityArray.length > 0))) {
            console.log(field.legalEntity, 'field.legalEntity');
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                let filteredDiv = [];
                let Divisions = _.filter(commonData.div_infos, { le_id: legalEntityArray[0].le_id })
                if (Divisions && Divisions.length > 0) {
                    filteredDiv = _.uniqBy(Divisions, function (e) {
                        return e.div_id;
                    });
                }
                console.log(filteredDiv, 'filteredDiv');
                if (filteredDiv && filteredDiv.length > 0) {
                    setDivision(filteredDiv)
                    let filteredCat = _.filter(commonData.cat_info, { div_id: Number(filteredDiv[0].div_id) })
                    let temp = []
                    if (filteredCat && filteredCat.length > 0) {
                        temp.push({
                            'bg_id': filteredCat[0].bg_id,
                            'c_id': filteredCat[0].c_id,
                            'cat_id': filteredCat[0].cat_id,
                            'cat_name': filteredCat[0].cat_name,
                            'd_id': filteredCat[0].d_id,
                            'div_id': filteredCat[0].div_id,
                            'le_id': filteredCat[0].le_id,
                            'div_name': filteredDiv[0].div_name
                        })
                        setCategory(temp)
                    }
                    if (commonData && commonData.chart_units && commonData.chart_units.length > 0 && bgIds.includes(null) === false) {
                        let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDiv[0].div_id) })
                        console.log(filteredUnit, 'filteredUnit');
                        setUnit(filteredUnit)
                    }
                    else{
                        let filteredUnit = _.filter(commonData.chart_units, { le_id: legalEntityArray[0].le_id })
                        console.log(filteredUnit, 'filteredDivision');
                        setUnit(filteredUnit)
                    }
                }
                // else {
                //     console.log('elsepart');
                //     if (commonData && commonData.chart_units && commonData.chart_units.length > 0) {
                //         console.log('trueee');
                //         let filteredUnit = _.filter(commonData.chart_units, { le_id: legalEntityArray[0].le_id })
                //         console.log(filteredUnit, 'filteredDivision');
                //         setUnit(filteredUnit)
                //     }
                // }
                else {
                    console.log('trueee');
                    let filteredUnit = _.filter(commonData.chart_units, { le_id: legalEntityArray[0].le_id })
                    console.log(filteredUnit, 'filteredDivision');
                    setUnit(filteredUnit)
                }
            }
        }

    }, [legalEntityArray, commonData])

    // Division,Category and Unit

    useEffect(() => {
        if (legalEntityArray && legalEntityArray.length > 0) {
            if (division) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                division && division.length > 0 && division.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');

                    if (legalEntityArray && legalEntityArray[0].le_id == subunitlist.le_id) {

                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.div_name,
                            'values': subunitlist.div_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                    }

                })
                totalListunit = {
                    'label': legalEntityArray && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray[0].le_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'totalListunit');

                countrylisttempdataunit.push(totalListunit)
                console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                setDivisionListData(countrylisttempdataunit)
            }
            if (category) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                category && category.length > 0 && category.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-category');
                    if (legalEntityArray && legalEntityArray[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'cat_id': subunitlist.cat_id,
                            'cat_name': subunitlist.cat_name,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.cat_name,
                            'values': subunitlist.cat_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': legalEntityArray && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray[0].le_name,
                    'options': tempArrayunit
                }
                countrylisttempdataunit.push(totalListunit)
                setCategoryListData(countrylisttempdataunit)
            }
            if (unit) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                unit && unit.length > 0 && unit.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-unit');
                    if (legalEntityArray && legalEntityArray[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'address': subunitlist.address,
                            'business_group_id': subunitlist.business_group_id,
                            'category_id': subunitlist.category_id,
                            'country_id': subunitlist.country_id,
                            'd_ids': subunitlist.d_ids,
                            'division_id': subunitlist.division_id,
                            'le_id': subunitlist.le_id,
                            'postal_code': subunitlist.postal_code,
                            'u_id': subunitlist.u_id,
                            'u_name': subunitlist.u_name,
                            'label': subunitlist.u_name,
                            'values': subunitlist.u_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                console.log(tempArrayunit, 'tempArrayunit');
                totalListunit = {
                    'label': legalEntityArray && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray[0].le_name,
                    'options': tempArrayunit
                }

                countrylisttempdataunit.push(totalListunit)
                setUnitListData(countrylisttempdataunit)
            }
        }
    }, [legalEntityArray, division, category, unit])

    useEffect(() => {
        if (JSON.parse(sideFilterState) === false && JSON.parse(isCountryValueTrue) === false) {
            console.log(entityData, 'entityData');
            console.log(dIds, 'dIds')
            console.log(countryDomain, 'countryDomain')
            if ((entityData && entityData.length > 0) && (commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
                console.log('useEff-1');
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetNotCompliedChart",
                            {
                                "c_ids": [
                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                ],
                                "d_ids": dIds,
                                "filter_type": "Group",
                                "filter_ids": [
                                    1
                                ],
                                "le_ids": localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? entityData : [entityid],
                                "country_domains": countryDomain,
                                "chart_year": year
                            }
                        ]
                    }
                ]
                if (paramid != "") {
                    console.log(paramid, "paramid33333333333");
                    getOverduechart({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
        }
    }, [localStorage.getItem('filterState'), isAuth, entityData, year, commonData, dIds, countryDomain, year, localStorage.getItem('countryState')])

    useEffect(() => {
        if (JSON.parse(sideFilterState) === true && localStorage.getItem('filterChange') && go === false) {
            console.log('helloooo');
            console.log('useEff-2');

            if (JSON.parse(isCountryValueTrue) === false) {
                let temp = []
                for (let i in parsedSideFilterData.c_ids) {
                    temp.push(`${parsedSideFilterData.c_ids[i]}`)
                }
                console.log(temp, 'temp');
                setFilterData({
                    ...filterData,
                    country: temp,
                    // countryArr:[`${parsedSideFilterData && parsedSideFilterData.c_ids}`],
                    domain: parsedSideFilterData && parsedSideFilterData.cd_ids,
                    domainIds: parsedSideFilterData && parsedSideFilterData.d_ids,
                })
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetNotCompliedChart",
                            parsedSideFilterData
                        ]
                    }
                ]
                console.log(payload, 'payload');
                if (paramid != "") {
                    getOverduechart({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
            else {
                setFilterData({
                    ...filterData,
                    country: [],
                    countryArr: [],
                    domain: [],
                    domainIds: [],
                })
            }
        }

    }, [go, paramid, localStorage.getItem('filterState'), localStorage.getItem('filterChange'), localStorage.getItem('countryState')])


    // useEffect(() => {
    //     if (JSON.parse(sideFilterState) === false && JSON.parse(isCountryValueTrue) === false) {
    //         if (commonData) {
    //             const payload = [
    //                 authtoken,
    //                 {
    //                     "session_token": authtoken,
    //                     "request": [
    //                         "GetNotCompliedChart",
    //                         {
    //                             "c_ids": [
    //                                 commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                             ],
    //                             "d_ids": dIds,
    //                             "filter_type": "Group",
    //                             "filter_ids": [
    //                                 1
    //                             ],
    //                             "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
    //                             "country_domains": countryDomain,
    //                             "chart_year": year
    //                         }
    //                     ]
    //                 }
    //             ]
    //             if (paramid != "") {
    //                 getOverduechart({
    //                     payload: payload,
    //                     paramid: paramid
    //                 })
    //             }
    //         }
    //     }

    // }, [isAuth, entityid, commonData, dIds, countryDomain, year, localStorage.getItem('countryState')])

    console.log(sessionArr, 'commonDatacommonData');
    useEffect(() => {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

            return item.le_id
        })
        setEntityData(id)
    }, [])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCommonData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityid])


    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let domainNameArray = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_name
            })
            setDomainName(domainNameArray)
            console.log(domainName, 'domainName');

        }

    }, [commonData])

    console.log(commonData, 'aisVig');
    console.log(data, 'data12345');

    // useEffect(() => {
    //     if (commonData && commonData.g_name) {
    //         if (data) {
    //             if (table == false) {
    //                 am4core.useTheme(am4themes_animated);
    //                 var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)
    //                 // Create a Count

    //                 var container = am4core.create("container", am4core.Container);
    //                 container.width = am4core.percent(100);
    //                 container.height = am4core.percent(100);
    //                 container.layout = "vertical";
    //                 container.background.fill = am4core.color("#000");
    //                 container.background.fillOpacity = 0.1;
    //                 container.background.stroke = am4core.color("#000");
    //                 container.background.strokeOpacity = 0.2;
    //                 container.background.strokeWidth = 2;

    //                 if (((data && data.T_0_to_30_days_count == 0) && (data && data.T_31_to_60_days_count == 0) && (data && data.T_61_to_90_days_count == 0) && (data && data.Above_90_days_count == 0))) {
    //                     var title3 = container.createChild(am4core.Label)
    //                     title3.text = "No Data to Display"
    //                     title3.disabled = false;
    //                     title3.align = 'left'
    //                     title3.paddingTop = '45%'
    //                     title3.paddingLeft = '43%'
    //                     // title3.fontWeight = '500'
    //                     title3.fontSize = '16'
    //                 }
    //                 else {

    //                     // Function that creates and adds a chart
    //                     function addChart(data) {

    //                         // Create chart instance
    //                         var chart = container.createChild(am4charts.PieChart3D);

    //                         // Add data
    //                         chart.data = data;

    //                         chart.innerRadius = am4core.percent(30);
    //                         function isZero(x) { return x == 0; }
    //                         // Add and configure Series
    //                         var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //                         pieSeries.dataFields.value = "Count";
    //                         pieSeries.dataFields.category = "Over Due Compliance Of Sample";
    //                         pieSeries.slices.template.propertyFields.fill = "color";
    //                         chart.current = chart;
    //                         chart.legend = new am4charts.Legend();
    //                         pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of ${total}`;


    //                         console.log(pieSeries.dataFields, 'pieSeries.dataFields');
    //                         // pieSeries.slices.template.stroke = am4core.color("#fff");
    //                         // pieSeries.slices.template.strokeWidth = 2;
    //                         // pieSeries.slices.template.strokeOpacity = 1;

    //                         chart.legend = new am4charts.Legend();
    //                         chart.legend.useDefaultMarker = true;
    //                         chart.legend.markers.template.height = 12;
    //                         chart.legend.markers.template.width = 12;
    //                         chart.legend.valueLabels.template.disabled = true;
    //                         var marker = chart.legend.markers.template.children.getIndex(0);
    //                         marker.cornerRadius(12, 12, 12, 12);
    //                         marker.strokeWidth = 2;
    //                         marker.strokeOpacity = 1;
    //                         marker.stroke = am4core.color("#ccc");


    //                         pieSeries.tooltip.label.adapter.add("text", function (text, target) {
    //                             if (target.dataItem && target.dataItem.value == 0) {
    //                                 return "";
    //                             }
    //                             else {
    //                                 return text;
    //                             }
    //                         });

    //                         // This creates initial animation
    //                         pieSeries.hiddenState.properties.opacity = 1;
    //                         pieSeries.hiddenState.properties.endAngle = -90;
    //                         pieSeries.hiddenState.properties.startAngle = -90;

    //                         pieSeries.slices.template.events.on("hit", function (ev) {
    //                             setShowMoreCount(0)
    //                             setDataCount(ev.target.dataItem._dataContext.Count)
    //                             setCriticalityValue(ev.target.dataItem.category)
    //                             setCountValue(0)
    //                             setCompliedType((ev.target.dataItem.category === 'Above 90 days' ? 'Above 90' : (ev.target.dataItem.category === '0-30 days' ? 'Below 30' : (ev.target.dataItem.category === '31-60 days' ? 'Below 60' : (ev.target.dataItem.category === '61-90 days' ? 'Below 90' : '')))))
    //                             setTable(true)
    //                             setChartValue('exposedRisk')
    //                             const payload = [
    //                                 authtoken,
    //                                 {
    //                                     "session_token": authtoken,
    //                                     "request": [
    //                                         "GetNotCompliedDrillDown",
    //                                         {
    //                                             "d_ids":
    //                                                 filterData && filterData.domain ? filterData.domain : dIds
    //                                             ,
    //                                             "filter_type": fieldType != "" ? fieldType : "Group",
    //                                             "filter_ids": [
    //                                                 fieldType == "LegalEntity" ? filterData.legalentity :
    //                                                     fieldType == 'Division' ? Number(filterData.division) :
    //                                                         fieldType == 'Category' ? Number(filterData.category) :
    //                                                             fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                                 fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                                     1
    //                                             ],
    //                                             "le_ids":
    //                                                 filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                             ,
    //                                             "country_domains": countryDomain,
    //                                             "not_complied_type": (ev.target.dataItem.category === 'Above 90 days' ? 'Above 90' : (ev.target.dataItem.category === '0-30 days' ? 'Below 30' : (ev.target.dataItem.category === '31-60 days' ? 'Below 60' : (ev.target.dataItem.category === '61-90 days' ? 'Below 90' : '')))),
    //                                             "record_count": 0,
    //                                         }
    //                                     ]
    //                                 }
    //                             ]
    //                             if (paramid != "") {
    //                                 getOverDueChartTableData({
    //                                     payload: payload,
    //                                     paramid: paramid
    //                                 })
    //                             }


    //                         })

    //                         chart.exporting.menu = new am4core.ExportMenu();
    //                         chart.exporting.dataFields = {
    //                             "Over Due Compliance Of Sample": "Over Due Compliance Of Sample",
    //                             "Count": "Count"
    //                         }
    //                         chart.exporting.menu.items = [{
    //                             "label": "...",
    //                             "menu": [
    //                                 {
    //                                     "label": "Image",
    //                                     "menu": [
    //                                         { "type": "png", "label": "PNG" },
    //                                         { "type": "jpg", "label": "JPG" },
    //                                         { "type": "svg", "label": "SVG" },
    //                                         { "type": "pdf", "label": "PDF" },
    //                                         { "type": "gif", "label": "GIF" }
    //                                     ]
    //                                 }, {
    //                                     "label": "Data",
    //                                     "menu": [
    //                                         { "type": "csv", "label": "CSV" },
    //                                         { "type": "xlsx", "label": "XLSX" },
    //                                     ]
    //                                 }, {
    //                                     "label": "Print", "type": "print"
    //                                 }
    //                             ]
    //                         }];
    //                         chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

    //                         var title = chart.titles.create();
    //                         title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
    //                         title.disabled = false;
    //                         title.align = 'left'
    //                         title.marginTop = '2%'
    //                         title.marginLeft = '2%'

    //                         // fieldType == "LegalEntity" ? filterData.legalentity :
    //                         // fieldType == 'Division' ? Number(filterData.division) :
    //                         //     fieldType == 'Category' ? Number(filterData.category) :
    //                         //         fieldType == 'Unit' ? Number(filterData.unit) 

    //                         var title1 = chart.titles.create();
    //                         title1.text =
    //                             ((fieldType == "LegalEntity" && go == true && type === '') ? `Over due Compliance of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
    //                                 (fieldType == "Division" && go == true && type === '') ? `Over due Compliance of Division [bold]${filterDataNames.division}[/]` : (
    //                                     (fieldType == "Category" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.category}[/]` : (
    //                                         (fieldType == "BusinessGroup" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.business}[/]` : (
    //                                             (fieldType == "Unit" && go == true && type === '') ? `Over due Compliance of Unit [bold]${filterDataNames.unit}[/]` : `Over due Compliance of ${commonData && commonData.g_name}`
    //                                         )))
    //                             ))
    //                         title1.disabled = false;
    //                         title1.align = 'center'
    //                         title1.marginTop = '5%'
    //                         title1.marginLeft = '2%'
    //                         title1.fontSize = '17px'
    //                         title1.fontWeight = '400'
    //                         // title1.fontWeight = 'bold'

    //                         // <b><p>Country-Domain</p></b>
    //                         // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

    //                         var label = chart.createChild(am4core.Label);
    //                         label.text = "Country-Domain";
    //                         label.fontSize = 14;
    //                         label.align = "left";
    //                         label.marginLeft = '2%';
    //                         label.marginBottom = '2%'
    //                         label.fontWeight = 'bold'

    //                         var label1 = chart.createChild(am4core.Label);
    //                         label1.text = `${textArray}`
    //                         // label1.text = ['hiii','hello']
    //                         label1.fontSize = 14;
    //                         label1.align = "left";
    //                         label1.marginLeft = '2%';
    //                         label1.marginBottom = '5%'

    //                         // var title2 = chart.titles.create();
    //                         // title2.text = 'Country-Domain'
    //                         // title2.disabled = false;
    //                         // title2.align = 'bottom'
    //                         // title2.marginTop = '2%'
    //                         // title2.marginLeft = '2%'

    //                         // title2.fontWeight = 'bold'

    //                         // Enable title on export
    //                         // chart.exporting.events.on("exportstarted", function (ev) {
    //                         //     title.disabled = false;
    //                         //     title.parent.invalidate();
    //                         // });

    //                         // // Disable title when export finishes
    //                         // chart.exporting.events.on("exportfinished", function (ev) {
    //                         //     title.disabled = true;
    //                         // });
    //                     }

    //                     // Add charts
    //                     addChart([
    //                         (data && data.T_0_to_30_days_count > 0 ?
    //                             {
    //                                 "Over Due Compliance Of Sample": "0-30 days",
    //                                 "Count": data && data.T_0_to_30_days_count,
    //                                 "color": am4core.color("#ff9c80")
    //                             } : {}
    //                         ),
    //                         (data && data.T_31_to_60_days_count > 0 ?
    //                             {
    //                                 "Over Due Compliance Of Sample": "31-60 days",
    //                                 "Count": data && data.T_31_to_60_days_count,
    //                                 "color": am4core.color("#f2746b")
    //                             } : {}),
    //                         (data && data.T_61_to_90_days_count > 0 ?
    //                             {
    //                                 "Over Due Compliance Of Sample": "61-90 days",
    //                                 "Count": data && data.T_61_to_90_days_count,
    //                                 "color": am4core.color("#ff6054")
    //                             } : {}),
    //                         (data && data.Above_90_days_count > 0 ?
    //                             {
    //                                 "Over Due Compliance Of Sample": "Above 90 days",
    //                                 "Count": data && data.Above_90_days_count,
    //                                 "color": am4core.color("#fe4365")
    //                             }
    //                             : {})
    //                     ]);
    //                 }

    //             }
    //         }
    //     }
    // }, [commonData, data, table, timeZone, timeZoneData, localStorage.getItem('chart')])

    // useEffect(() => {
    //     if (commonData && commonData.g_name) {
    //         am4core.useTheme(am4themes_animated);
    //         var chartoverdue = am4core.create("chartdiv", am4charts.RadarChart);

    //         if (data) {
    //             if (table == false) {
    //                 if (((data && data.T_0_to_30_days_count == 0) && (data && data.T_31_to_60_days_count == 0) && (data && data.T_61_to_90_days_count == 0) && (data && data.Above_90_days_count == 0))) {

    //                     var title = chartoverdue.titles.create();
    //                     title.text = "No Data to Display"
    //                     title.disabled = false;
    //                     title.align = 'left'
    //                     title.marginTop = '45%'
    //                     title.marginLeft = '43%'
    //                 }
    //                 else {
    //                     //overduechart
    //                     var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)
    //                     // chartoverdue.height = '330'
    //                     // chartoverdue.width = '500'
    //                     chartoverdue.data = [
    //                         (data && data.T_0_to_30_days_count > 0 ?
    //                             {
    //                                 "Over Due Compliance Of Sample": "0-30 days",
    //                                 "Count": (Math.round(data && data.T_0_to_30_days_count) / (total) * 100).toFixed(2),
    //                                 "color": am4core.color("#ff9c80"),
    //                                 "full": 100,
    //                                 "custom": data && data.T_0_to_30_days_count

    //                             } : {}
    //                         ), (data && data.T_31_to_60_days_count > 0 ?
    //                             {
    //                                 "Over Due Compliance Of Sample": "31-60 days",
    //                                 "Count": (Math.round(data && data.T_31_to_60_days_count) / (total) * 100).toFixed(2),
    //                                 "color": am4core.color("#f2746b"),
    //                                 "full": 100,
    //                                 "custom": data && data.T_31_to_60_days_count

    //                             } : {}),
    //                         (data && data.T_61_to_90_days_count > 0 ?
    //                             {
    //                                 "Over Due Compliance Of Sample": "61-90 days",
    //                                 "Count": (Math.round(data && data.T_61_to_90_days_count) / (total) * 100).toFixed(2),
    //                                 "color": am4core.color("#ff6054"),
    //                                 "full": 100,
    //                                 "custom": data && data.T_61_to_90_days_count

    //                             } : {}),
    //                         (data && data.Above_90_days_count > 0 ?
    //                             {
    //                                 "Over Due Compliance Of Sample": "Above 90 days",
    //                                 "Count": (Math.round(data && data.Above_90_days_count) / (total) * 100).toFixed(2),
    //                                 "color": am4core.color("#fe4365"),
    //                                 "full": 100,
    //                                 "custom": data && data.Above_90_days_count

    //                             }
    //                             : {})
    //                     ]
    //                     chartoverdue.startAngle = -90;
    //                     chartoverdue.endAngle = 180;
    //                     chartoverdue.innerRadius = am4core.percent(20);
    //                     // chartoverdue.numberFormatter.numberFormat = "#.#'%'";
    //                     chartoverdue.colors.list = [am4core.color("#F79B80"), am4core.color("#F2746B"), am4core.color("#F35D53"), am4core.color("#F24D64")];
    //                     var categoryAxis = chartoverdue.yAxes.push(new am4charts.CategoryAxis());
    //                     categoryAxis.dataFields.category = "Over Due Compliance Of Sample";
    //                     categoryAxis.renderer.grid.template.location = 0;
    //                     categoryAxis.renderer.grid.template.strokeOpacity = 0;
    //                     categoryAxis.renderer.labels.template.horizontalCenter = "right";
    //                     categoryAxis.renderer.labels.template.fontWeight = 500;
    //                     categoryAxis.renderer.labels.template.adapter.add("fill", function (fill, target) {
    //                         return (target.dataItem.index >= 0) ? chartoverdue.colors.getIndex(target.dataItem.index) : fill;
    //                     });

    //                     categoryAxis.renderer.labels.template.events.on("hit", function (ev) {
    //                     })

    //                     categoryAxis.renderer.minGridDistance = 10;

    //                     var valueAxis = chartoverdue.xAxes.push(new am4charts.ValueAxis());
    //                     valueAxis.renderer.grid.template.strokeOpacity = 0;
    //                     valueAxis.min = 0;
    //                     valueAxis.max = 100;
    //                     valueAxis.strictMinMax = true;

    //                     // Create series
    //                     var series1 = chartoverdue.series.push(new am4charts.RadarColumnSeries());
    //                     series1.dataFields.valueX = "full";
    //                     series1.dataFields.categoryY = "Over Due Compliance Of Sample";
    //                     series1.clustered = false;
    //                     series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
    //                     series1.columns.template.fillOpacity = 0.08;
    //                     series1.columns.template.cornerRadiusTopLeft = 20;
    //                     series1.columns.template.strokeWidth = 0;
    //                     series1.columns.template.radarColumn.cornerRadius = 20;
    //                     series1.columns.template.tooltipText = `{Over Due Compliance Of Sample}: [bold]{custom}[/] out of ${total}`;




    //                     var series2 = chartoverdue.series.push(new am4charts.RadarColumnSeries());
    //                     series2.dataFields.valueX = "Count";
    //                     series2.dataFields.categoryY = "Over Due Compliance Of Sample";
    //                     series2.clustered = false;
    //                     series2.columns.template.strokeWidth = 0;
    //                     // series2.columns.template.tooltipText = "{category}: [bold]{value}({custom})[/]";
    //                     series2.columns.template.tooltipText = `{Over Due Compliance Of Sample}: [bold]{custom}[/] out of ${total} `;
    //                     series2.columns.template.radarColumn.cornerRadius = 20;

    //                     // var valueLabel = series2.bullets.push(new am4charts.LabelBullet());
    //                     // valueLabel.label.text = "{custom}";

    //                     series2.columns.template.adapter.add("fill", function (fill, target) {
    //                         return chartoverdue.colors.getIndex(target.dataItem.index);
    //                     });

    //                     series2.columns.template.events.on("hit", function (ev) {
    //                         console.log(ev.target.dataItem, 'ev.target.dataItem.category');
    //                         setShowMoreCount(0)
    //                         setDataCount(ev.target.dataItem._dataContext.custom)
    //                         setCriticalityValue(ev.target.dataItem.categories.categoryY)
    //                         setCountValue(0)
    //                         setCompliedType((ev.target.dataItem.categories.categoryY === 'Above 90 days' ? 'Above 90' : (ev.target.dataItem.categories.categoryY === '0-30 days' ? 'Below 30' : (ev.target.dataItem.categories.categoryY === '31-60 days' ? 'Below 60' : (ev.target.dataItem.categories.categoryY === '61-90 days' ? 'Below 90' : '')))))
    //                         setTable(true)
    //                         setChartValue('exposedRisk')
    //                         const payload = [
    //                             authtoken,
    //                             {
    //                                 "session_token": authtoken,
    //                                 "request": [
    //                                     "GetNotCompliedDrillDown",
    //                                     {
    //                                         "d_ids":
    //                                             filterData && filterData.domain ? filterData.domain : dIds
    //                                         ,
    //                                         "filter_type": fieldType != "" ? fieldType : "Group",
    //                                         "filter_ids": [
    //                                             fieldType == "LegalEntity" ? filterData.legalentity :
    //                                                 fieldType == 'Division' ? Number(filterData.division) :
    //                                                     fieldType == 'Category' ? Number(filterData.category) :
    //                                                         fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                             fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                                 1
    //                                         ],
    //                                         "le_ids":
    //                                             filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                         ,
    //                                         "country_domains": countryDomain,
    //                                         "not_complied_type": (ev.target.dataItem.categories.categoryY === 'Above 90 days' ? 'Above 90' : (ev.target.dataItem.categories.categoryY === '0-30 days' ? 'Below 30' : (ev.target.dataItem.categories.categoryY === '31-60 days' ? 'Below 60' : (ev.target.dataItem.categories.categoryY === '61-90 days' ? 'Below 90' : '')))),
    //                                         "record_count": 0,
    //                                     }
    //                                 ]
    //                             }
    //                         ]
    //                         if (paramid != "") {
    //                             getOverDueChartTableData({
    //                                 payload: payload,
    //                                 paramid: paramid
    //                             })
    //                         }


    //                     })

    //                     chartoverdue.legend = new am4charts.Legend();

    //                     chartoverdue.exporting.menu = new am4core.ExportMenu();
    //                     chartoverdue.exporting.dataFields = {
    //                         "Over Due Compliance Of Sample": "Over Due Compliance Of Sample",
    //                         "custom": "Count"
    //                     }
    //                     chartoverdue.exporting.menu.items = [{
    //                         "label": "...",
    //                         "menu": [
    //                             {
    //                                 "label": "Image",
    //                                 "menu": [
    //                                     { "type": "png", "label": "PNG" },
    //                                     { "type": "jpg", "label": "JPG" },
    //                                     { "type": "svg", "label": "SVG" },
    //                                     { "type": "pdf", "label": "PDF" },
    //                                     { "type": "gif", "label": "GIF" }
    //                                 ]
    //                             }, {
    //                                 "label": "Data",
    //                                 "menu": [
    //                                     { "type": "csv", "label": "CSV" },
    //                                     { "type": "xlsx", "label": "XLSX" },
    //                                 ]
    //                             }, {
    //                                 "label": "Print", "type": "print"
    //                             }
    //                         ]
    //                     }];
    //                     chartoverdue.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

    //                     var title = chartoverdue.titles.create();
    //                     title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
    //                     title.disabled = false;
    //                     title.align = 'left'
    //                     title.marginTop = '2%'
    //                     // title.marginLeft = '1%'

    //                     var title1 = chartoverdue.titles.create();
    //                     title1.text =
    //                         ((fieldType == "LegalEntity" && go == true && type === '') ? `Over due Compliance of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
    //                             (fieldType == "Division" && go == true && type === '') ? `Over due Compliance of Division [bold]${filterDataNames.division}[/]` : (
    //                                 (fieldType == "Category" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.category}[/]` : (
    //                                     (fieldType == "BusinessGroup" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.business}[/]` : (
    //                                         (fieldType == "Unit" && go == true && type === '') ? `Over due Compliance of Unit [bold]${filterDataNames.unit}[/]` : `Over due Compliance of ${commonData && commonData.g_name}`
    //                                     )))
    //                         ))
    //                     title1.disabled = false;
    //                     title1.align = 'center'
    //                     // title1.marginTop = '2%'
    //                     title1.marginLeft = '2%'
    //                     title1.fontSize = '17px'
    //                     title1.fontWeight = '400'

    //                     var label = chartoverdue.createChild(am4core.Label);
    //                     label.text = "Country-Domain";
    //                     label.fontSize = 14;
    //                     label.align = "left";
    //                     // label.marginLeft = '1%';
    //                     label.marginBottom = '2%'
    //                     label.fontWeight = 'bold'

    //                     var label1 = chartoverdue.createChild(am4core.Label);
    //                     label1.text = `${textArray}`
    //                     // label1.text = ['hiii','hello']
    //                     label1.fontSize = 14;
    //                     label1.align = "left";
    //                     // label1.marginLeft = '1%';
    //                     label1.marginBottom = '5%'

    //                     // Add cursor
    //                     // chartoverdue.cursor = new am4charts.RadarCursor();
    //                 }
    //             }
    //         }
    //     }
    // }, [commonData, data, table, timeZone, timeZoneData, localStorage.getItem('chart')])

    useEffect(() => {
        if (JSON.parse(isCountryValueTrue) === true) {
            am4core.useTheme(am4themes_animated);
            // Themes end
            // Create chart instance
            var chart = am4core.create("chartdiv1", am4charts.RadarChart);
            var title = chart.titles.create();
            title.text = "No Data to Display"
            title.disabled = false;
            title.align = 'left'
            title.marginTop = '45%'
            title.marginLeft = '43%'
        }

    }, [localStorage.getItem('countryState')])


    useEffect(() => {
        if (data) {
            am4core.useTheme(am4themes_animated);
            // Themes end
            // Create chart instance
            var chart = am4core.create("chartdiv", am4charts.RadarChart);
            var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)

            if (table == false) {
                if (((data && data.T_0_to_30_days_count == 0) && (data && data.T_31_to_60_days_count == 0) && (data && data.T_61_to_90_days_count == 0) && (data && data.Above_90_days_count == 0)) || (data.length <= 0)) {

                    var title = chart.titles.create();
                    title.text = "No Data to Display"
                    title.disabled = false;
                    title.align = 'left'
                    title.marginTop = '45%'
                    title.marginLeft = '43%'
                }
                else {

                    // Add data
                    chart.colors.list = [am4core.color("#F79B80"), am4core.color("#F2746B"), am4core.color("#F35D53"), am4core.color("#F24D64")];
                    chart.data = [
                        (data && data.T_0_to_30_days_count > 0 ?
                            {
                                "Over Due Compliance Of Sample": "0-30 days",
                                "Count": (Math.round(data && data.T_0_to_30_days_count) / (total) * 100).toFixed(2),
                                "full": 100,
                                "custom": data && data.T_0_to_30_days_count

                            } : {}
                        ), (data && data.T_31_to_60_days_count > 0 ?
                            {
                                "Over Due Compliance Of Sample": "31-60 days",
                                "Count": (Math.round(data && data.T_31_to_60_days_count) / (total) * 100).toFixed(2),
                                "full": 100,
                                "custom": data && data.T_31_to_60_days_count

                            } : {}),
                        (data && data.T_61_to_90_days_count > 0 ?
                            {
                                "Over Due Compliance Of Sample": "61-90 days",
                                "Count": (Math.round(data && data.T_61_to_90_days_count) / (total) * 100).toFixed(2),
                                "full": 100,
                                "custom": data && data.T_61_to_90_days_count

                            } : {}),
                        (data && data.Above_90_days_count > 0 ?
                            {
                                "Over Due Compliance Of Sample": "Above 90 days",
                                "Count": (Math.round(data && data.Above_90_days_count) / (total) * 100).toFixed(2),
                                "full": 100,
                                "custom": data && data.Above_90_days_count

                            }
                            : {})
                    ]


                    // Make chart not full circle
                    chart.startAngle = -90;
                    chart.endAngle = 180;
                    chart.innerRadius = am4core.percent(20);

                    // Set number format
                    chart.numberFormatter.numberFormat = "#.#'%'";

                    // Create axes
                    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = "Over Due Compliance Of Sample";
                    categoryAxis.renderer.grid.template.location = 0;
                    categoryAxis.renderer.grid.template.strokeOpacity = 0;
                    categoryAxis.renderer.labels.template.horizontalCenter = "right";
                    categoryAxis.renderer.labels.template.fontWeight = 500;
                    categoryAxis.renderer.labels.template.adapter.add("fill", function (fill, target) {
                        return (target.dataItem.index >= 0) ? chart.colors.getIndex(target.dataItem.index) : fill;
                    });
                    categoryAxis.renderer.minGridDistance = 10;

                    categoryAxis.renderer.labels.template.events.on("hit", function (ev) {
                        console.log(ev.target.dataItem, 'ev.target.dataItem.category');
                        console.log(ev.target.dataItem.properties.category, 'ev.target.dataItem.properties.category');
                        setShowMoreCount(0)
                        setDataCount(ev.target.dataItem._dataContext.custom)
                        setCriticalityValue(ev.target.dataItem.properties.category)
                        setCountValue(0)
                        setCompliedType((ev.target.dataItem.properties.category === 'Above 90 days' ? 'Above 90' : (ev.target.dataItem.properties.category === '0-30 days' ? 'Below 30' : (ev.target.dataItem.properties.category === '31-60 days' ? 'Below 60' : (ev.target.dataItem.properties.category === '61-90 days' ? 'Below 90' : '')))))
                        setTable(true)
                        setChartValue('exposedRisk')
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetNotCompliedDrillDown",
                                    {
                                        "d_ids":
                                            filterData && filterData.domain ? filterData.domainIds : dIds
                                        ,
                                        "filter_type": fieldType != "" ? fieldType : "Group",
                                        "filter_ids": [
                                            fieldType == "LegalEntity" ? filterData.legalentity :
                                                fieldType == 'Division' ? Number(filterData.division) :
                                                    fieldType == 'Category' ? Number(filterData.category) :
                                                        fieldType == 'Unit' ? Number(filterData.unit) :
                                                            fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                1
                                        ],
                                        "le_ids":
                                            filterData && filterData.leIds ? [filterData.leIds] : filterData.legalentity ? filterData.legalentity : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                        ,
                                        "country_domains": countryDomain,
                                        "not_complied_type": (ev.target.dataItem.properties.category === 'Above 90 days' ? 'Above 90' : (ev.target.dataItem.properties.category === '0-30 days' ? 'Below 30' : (ev.target.dataItem.properties.category === '31-60 days' ? 'Below 60' : (ev.target.dataItem.properties.category === '61-90 days' ? 'Below 90' : '')))),
                                        "record_count": 0,
                                    }
                                ]
                            }
                        ]
                        if (paramid != "") {
                            getOverDueChartTableData({
                                payload: payload,
                                paramid: paramid
                            })
                        }

                    })

                    categoryAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;



                    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                    valueAxis.renderer.grid.template.strokeOpacity = 0;
                    valueAxis.min = 0;
                    valueAxis.max = 100;
                    valueAxis.strictMinMax = true;

                    // Create series
                    var series1 = chart.series.push(new am4charts.RadarColumnSeries());
                    series1.dataFields.valueX = "full";
                    series1.dataFields.categoryY = "Over Due Compliance Of Sample";
                    series1.clustered = false;
                    series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
                    series1.columns.template.fillOpacity = 0.08;
                    series1.columns.template.cornerRadiusTopLeft = 20;
                    series1.columns.template.strokeWidth = 0;
                    series1.columns.template.radarColumn.cornerRadius = 20;

                    var series2 = chart.series.push(new am4charts.RadarColumnSeries());
                    series2.dataFields.valueX = "Count";
                    series2.dataFields.categoryY = "Over Due Compliance Of Sample";
                    series2.clustered = false;
                    series2.columns.template.strokeWidth = 0;
                    series2.columns.template.tooltipText = `{Over Due Compliance Of Sample}: [bold]{custom.formatNumber('#')}[/] out of ${total} ({Count}%)`;
                    series2.columns.template.radarColumn.cornerRadius = 20;

                    series2.columns.template.adapter.add("fill", function (fill, target) {
                        return chart.colors.getIndex(target.dataItem.index);
                    });

                    // series2.columns.template.adapter.add("fill", function (fill, target) {
                    //     return chart.colors.getIndex(target.dataItem.index);
                    // });

                    // Add cursor
                    // chart.cursor = new am4charts.RadarCursor();

                    // chart.legend = new am4charts.Legend();

                    series2.events.on("dataitemsvalidated", function () {
                        var data = [];
                        series2.dataItems.each(function (dataItem) {
                            data.push({ name: dataItem.categoryY, fill: dataItem.column.fill, seriesDataItem: dataItem })
                        })

                        chart.legend.data = data;
                        chart.legend.itemContainers.template.events.on("toggled", function (event) {
                            var seriesDataItem = event.target.dataItem.dataContext.seriesDataItem;
                            if (event.target.isActive) {
                                seriesDataItem.hide(series2.interpolationDuration, 0, 0, ["valueX"]);
                            }
                            else {
                                seriesDataItem.show(series2.interpolationDuration, 0, ["valueX"]);
                            }
                        })
                    })

                    series2.columns.template.events.on("hit", function (ev) {
                        console.log(ev.target.dataItem, 'ev.target.dataItem.category');
                        setShowMoreCount(0)
                        setDataCount(ev.target.dataItem._dataContext.custom)
                        setCriticalityValue(ev.target.dataItem.categories.categoryY)
                        setCountValue(0)
                        setCompliedType((ev.target.dataItem.categories.categoryY === 'Above 90 days' ? 'Above 90' : (ev.target.dataItem.categories.categoryY === '0-30 days' ? 'Below 30' : (ev.target.dataItem.categories.categoryY === '31-60 days' ? 'Below 60' : (ev.target.dataItem.categories.categoryY === '61-90 days' ? 'Below 90' : '')))))
                        setTable(true)
                        setChartValue('exposedRisk')
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetNotCompliedDrillDown",
                                    {
                                        "d_ids":
                                            filterData && filterData.domain ? filterData.domainIds : dIds
                                        ,
                                        "filter_type": fieldType != "" ? fieldType : "Group",
                                        "filter_ids": [
                                            fieldType == "LegalEntity" ? filterData.legalentity :
                                                fieldType == 'Division' ? Number(filterData.division) :
                                                    fieldType == 'Category' ? Number(filterData.category) :
                                                        fieldType == 'Unit' ? Number(filterData.unit) :
                                                            fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                1
                                        ],
                                        "le_ids":
                                            filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                        ,
                                        "country_domains": countryDomain,
                                        "not_complied_type": (ev.target.dataItem.categories.categoryY === 'Above 90 days' ? 'Above 90' : (ev.target.dataItem.categories.categoryY === '0-30 days' ? 'Below 30' : (ev.target.dataItem.categories.categoryY === '31-60 days' ? 'Below 60' : (ev.target.dataItem.categories.categoryY === '61-90 days' ? 'Below 90' : '')))),
                                        "record_count": 0,
                                    }
                                ]
                            }
                        ]
                        if (paramid != "") {
                            getOverDueChartTableData({
                                payload: payload,
                                paramid: paramid
                            })
                        }


                    })

                    chart.legend = new am4charts.Legend();

                    chart.exporting.menu = new am4core.ExportMenu();
                    chart.exporting.filePrefix = "Exported Data";
                    chart.exporting.dataFields = {
                        "Over Due Compliance Of Sample": "Over Due Compliance Of Sample",
                        "custom": "Count"
                    }
                    chart.exporting.menu.items = [{
                        "label": "...",
                        "menu": [
                            {
                                "label": "Image",
                                "menu": [
                                    { "type": "png", "label": "PNG" },
                                    { "type": "jpg", "label": "JPG" },
                                    { "type": "svg", "label": "SVG" },
                                    { "type": "pdf", "label": "PDF" },
                                    { "type": "gif", "label": "GIF" }
                                ]
                            }, {
                                "label": "Data",
                                "menu": [
                                    { "type": "csv", "label": "CSV" },
                                    { "type": "xlsx", "label": "XLSX" },
                                ]
                            }, {
                                "label": "Print", "type": "print"
                            }
                        ]
                    }];
                    chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                    var title = chart.titles.create();
                    title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                    title.disabled = false;
                    title.align = 'left'
                    title.marginTop = '2%'
                    // title.marginLeft = '1%'

                    var title1 = chart.titles.create();
                    title1.text =
                        ((fieldType == "LegalEntity" && go == true && type === '') ? `Over due Compliance of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
                            (fieldType == "Division" && go == true && type === '') ? `Over due Compliance of Division [bold]${filterDataNames.division}[/]` : (
                                (fieldType == "Category" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.category}[/]` : (
                                    (fieldType == "BusinessGroup" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.business}[/]` : (
                                        (fieldType == "Unit" && go == true && type === '') ? `Over due Compliance of ${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} [bold]${filterDataNames.unit}[/]` : `Over due Compliance of ${commonData && commonData.g_name}`
                                    )))
                        ))
                    title1.disabled = false;
                    title1.align = 'center'
                    // title1.marginTop = '2%'
                    title1.marginLeft = '2%'
                    title1.fontSize = '17px'
                    title1.fontWeight = '400'

                    var label = chart.createChild(am4core.Label);
                    label.text = "Country-Domain";
                    label.fontSize = 14;
                    label.align = "left";
                    // label.marginLeft = '1%';
                    label.marginBottom = '2%'
                    label.fontWeight = 'bold'

                    var label1 = chart.createChild(am4core.Label);
                    label1.text = `${textArray}`
                    // label1.text = ['hiii','hello']
                    label1.fontSize = 14;
                    label1.align = "left";
                    // label1.marginLeft = '1%';
                    label1.marginBottom = '5%'


                }
            }
        }

    }, [commonData, data, table, timeZone, timeZoneData, localStorage.getItem('chart')])

    console.log(data, 'data');

    const compliancetaskfilter = (e, type, searchedArray) => {
        console.log(searchedArray, "searchedArray");
        let filterTable = sortingpurpose

        if (searchingValue['business_group']) {
            filterTable = filterTable.filter((item) => {
                if ((item["bg_name"]?.toLowerCase().includes(searchingValue['business_group'].toLowerCase())) || (item["bg_name"]?.toLowerCase().includes(searchingValue['business_group'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['legal_entity']) {
            filterTable = filterTable.filter((item) => {
                if ((item["le_name"].toLowerCase().includes(searchingValue['legal_entity'].toLowerCase())) || (item["le_name"].toLowerCase().includes(searchingValue['legal_entity'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['division']) {
            filterTable = filterTable.filter((item) => {
                if ((item["div_name"]?.toLowerCase().includes(searchingValue['division'].toLowerCase())) || (item["div_name"]?.toLowerCase().includes(searchingValue['division'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['compliance']) {
            filterTable = filterTable.filter((item) => {
                if ((item["comp_name"].toLowerCase().includes(searchingValue['compliance'].toLowerCase())) || (item["comp_name"].toLowerCase().includes(searchingValue['compliance'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['criticality']) {
            filterTable = filterTable.filter((item) => {
                if ((item["criticality"]?.toLowerCase().includes(searchingValue['criticality'].toLowerCase())) || (item["criticality"]?.toLowerCase().includes(searchingValue['criticality'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['assignedTo']) {
            filterTable = filterTable.filter((item) => {
                if ((item["assignee_name"].toLowerCase().includes(searchingValue['assignedTo'].toLowerCase())) || (item["assignee_name"].toLowerCase().includes(searchingValue['assignedTo'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['overdue']) {
            filterTable = filterTable.filter((item) => {
                if ((item["ageing"].toLowerCase().includes(searchingValue['overdue'].toLowerCase())) || (item["ageing"].toLowerCase().includes(searchingValue['overdue'].toLowerCase()))) {
                    return true;
                }
            })
        }

        let arr = [];
        let filteredtemp = []
        let fiteredunitname = []

        if (filterTable && filterTable.length > 0) {
            filterTable &&
                filterTable.length > 0 &&
                filterTable.map(function (user) {
                    if (arr.indexOf(user.u_name) < 0)
                        arr.push({ u_name: user.u_name });
                });
            let uniqle = _.uniqBy(arr, function (e) {
                return e.u_name;
            });
            for (let k in uniqle) {
                let filteredObject = _.filter(filterTable, { u_name: uniqle[k].u_name })
                filteredtemp.push(filteredObject)
            }
            for (let k in filteredtemp) {
                let uniqle1 = _.uniqBy(filteredtemp[k], function (e) {
                    return e.unitname;
                });
                let uniq_unitname = uniqle1.map((item) => { return item.unitname })
                fiteredunitname.push(uniq_unitname)
            }
            let FinalfilteredObject = []
            for (let k in fiteredunitname) {
                let temp = []
                for (let j in fiteredunitname[k]) {
                    let filteredObject = _.filter(filteredtemp[k], { unitname: fiteredunitname[k][j] })
                    temp.push(filteredObject)
                }
                FinalfilteredObject.push(temp)
            }
            setTempcriticalityTable(FinalfilteredObject)
        } else {
            setTempcriticalityTable([])
        }
    };

    useEffect(() => {
        if (tableData) {
            let sortedArray = _.orderBy(tableData.n_drill_down_data, ['le_name'], ['asc'])
            setCriticalityTable(sortedArray)
            setUpdatedArray(tableData.n_drill_down_data)
        }
    }, [tableData])

    useEffect(() => {
        let temp = []
        if (criticalityTable && criticalityTable.length > 0) {
            criticalityTable.map((item, i) => {
                temp.push(`${i}`)
            }
            )
            setIndexValue(temp)
        }
    }, [criticalityTable])

    useEffect(() => {
        if (showMoreData && showMoreData.n_drill_down_data && showMoreData.n_drill_down_data.length > 0) {
            if (criticalityTable && criticalityTable.length > 0) {
                let newTable = criticalityTable
                let mergedArray = []
                let tableDataLastObj = newTable[newTable.length - 1]
                let showMoreDataFirstObj = showMoreData.n_drill_down_data[0]
                console.log(tableDataLastObj, 'tableDataLastObj');
                console.log(showMoreDataFirstObj, 'showMoreDataFirstObj');
                // if (`${tableDataLastObj && tableDataLastObj.le_name}-${tableDataLastObj && tableDataLastObj.le_name}` === `${showMoreDataFirstObj && showMoreDataFirstObj.le_name}-${showMoreDataFirstObj && showMoreDataFirstObj.le_name}`) {
                //     let showMoreNew = _.filter(showMoreData.n_drill_down_data, { le_name: tableDataLastObj.le_name })
                //     let mergedArray = newTable.concat(showMoreNew)
                //     setCriticalityTable(mergedArray)
                // }
                // else {
                //     let rejectedData = _.reject(showMoreData.n_drill_down_data, { le_name: tableDataLastObj.le_name })
                //     let mergedArray = newTable.concat(rejectedData)
                //     setCriticalityTable(mergedArray)
                // }
                if (tableDataLastObj && showMoreDataFirstObj) {
                    let previousLEUnit = tableDataLastObj.le_name + '-' + tableDataLastObj.u_name
                    let currentLEUnit = showMoreDataFirstObj.le_name + '-' + showMoreDataFirstObj.u_name
                    if (previousLEUnit == currentLEUnit) {
                        // let showMoreNew = _.filter(showMoreData.not_complied, { le_name: tableDataLastObj.le_name, u_name: tableDataLastObj.u_name })                   
                        let existDrill = tableDataLastObj.drill_compliances
                        let newDrill = showMoreDataFirstObj.drill_compliances
                        let exisObjKeys = _.keys(existDrill)
                        let newKeys = _.keys(newDrill)
                        let matchedArrayFilter = _.filter(showMoreData.n_drill_down_data, { u_name: tableDataLastObj.le_name })
                        let unMatchedArrayFilter = _.reject(showMoreData.n_drill_down_data, { u_name: tableDataLastObj.u_name })

                        if (exisObjKeys[exisObjKeys.length - 1] == newKeys[0]) {
                            let temp = {
                                [exisObjKeys[exisObjKeys.length - 1]]: existDrill[exisObjKeys[exisObjKeys.length - 1]].concat(newDrill[exisObjKeys[exisObjKeys.length - 1]])
                            }
                            delete newDrill[exisObjKeys[exisObjKeys.length - 1]]
                            existDrill = Object.assign(existDrill, temp)
                            console.log(existDrill, newDrill, 'vibinnnn');
                        } else {
                            console.log(existDrill, newDrill, 'vibinnnnelse');
                        }
                        let newAssign = Object.assign(existDrill, newDrill);
                        console.log(newAssign, 'newAssign');
                        criticalityTable[criticalityTable.length - 1].drill_compliances = newAssign
                        let merge = criticalityTable.concat(unMatchedArrayFilter)
                        console.log(merge, 'merge');
                        setCriticalityTable(merge)

                    } else {
                        console.log('mismatchhh');
                        let rejectedData = _.reject(showMoreData.n_drill_down_data, { le_name: tableDataLastObj.le_name })
                        let mergedArray = newTable.concat(rejectedData)
                        setCriticalityTable(mergedArray)
                    }
                }
            }
        }
    }, [showMoreData])

    console.log(showMoreData, 'showMoreee');
    console.log(criticalityTable, 'criticalityTable');
    console.log(compliedType, 'compliedType');

    useEffect(() => {
        if (criticalityTable && criticalityTable.length > 0) {
            let count1 = []
            for (let i in criticalityTable) {
                for (let j in criticalityTable[i].drill_compliances) {
                    let a = criticalityTable[i].drill_compliances[j]
                    let data = Object.values(a)
                    count1.push(data.length)
                    // count1.push(data[i])
                    console.log(data, ' taskDistributionTable[i].ap_compliances');
                }
            }
            console.log(count1, 'count1');
            let countValues = count1.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setShowMoreCount(countValues)
            console.log(countValues, 'countValue9999');

            // search option customize
            let temp = []
            let temp1 = []
            {
                criticalityTable && criticalityTable.length > 0 &&
                    criticalityTable.map((items) => {
                        let childDatas = []
                        let unitName = items.drill_compliances
                        console.log(unitName, 'unitName');
                        let keys = Object.keys(unitName);
                        let values = Object.values(unitName);
                        console.log(keys, 'keys');
                        console.log(values, 'values');
                        {
                            keys && keys.length > 0 && keys.map((item, i) => {
                                console.log(item, 'itemitemitemitemitemitemitem');
                                let tempArr = []
                                if (values && values.length > 0) {
                                    for (let m in values[i]) {
                                        console.log(m, 'inndexxx');
                                        console.log(values[i][m].comp_name, 'childDatas');
                                        tempArr.push({
                                            'u_name': items.u_name,
                                            'bg_name': items.bg_name,
                                            'le_name': items.le_name,
                                            'legal_entity_id': items.legal_entity_id,
                                            'div_name': items.div_name,
                                            'comp_name': values && values[i][m].comp_name,
                                            'criticality': values && values[i][m].criticality,
                                            'assignee_name': values && values[i][m].assignee_name,
                                            'descp': values && values[i][m].descp,
                                            'compfielaw_url': values && values[i][m].compfielaw_url,
                                            'ageing': values && values[i][m].ageing,
                                            'unitname': item,
                                            'index': count
                                        })
                                        count = count + 1
                                        console.log(count, 'countcountcountcount');
                                    }
                                    // temp1.concat(tempArr)
                                    Array.prototype.push.apply(temp1, tempArr)
                                    childDatas.push(tempArr)
                                }
                                console.log(childDatas, 'tempArr5557');
                                console.log(tempArr, 'tempArr5556666');
                                // temp.push([childDatas[i]])
                                // temp.push(childDatas[i])
                                // log
                            })
                            temp.push(childDatas)
                            console.log(temp, 'llllllllll');
                        }
                        setTempcriticalityTable(temp)
                    })
            }
            setSortingpurpose(temp1)
            console.log(temp1, 'temptemp');
        }

    }, [criticalityTable])

    useEffect(() => {
        if (type === 'Group')
            setGroupCheckBox(checkBox)
    }, [type, checkBox])

    //Use effects for filter

    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
    //         let arrunit = [];
    //         let totalListunit = []
    //         let countrylisttempdataunit = [];
    //         let entityArrayunit = []
    //         let tempArrayunit = []
    //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
    //             console.log(subunitlist, 'subunitlist-domain');
    //             if (country_info && country_info[0].c_id == subunitlist.c_id) {
    //                 entityArrayunit = {

    //                     'c_id': subunitlist.c_id,
    //                     'd_id': subunitlist.d_id,
    //                     'd_name': subunitlist.d_name,
    //                     'is_active': subunitlist.is_active,
    //                     'month_from': subunitlist.month_from,
    //                     'month_to': subunitlist.month_to,
    //                     'label': subunitlist.d_name,
    //                     'values': subunitlist.d_id

    //                 }
    //                 tempArrayunit.push(entityArrayunit)
    //                 console.log(entityArrayunit, 'entityArrayunit');
    //             }

    //         })
    //         totalListunit = {
    //             'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
    //             'options': tempArrayunit
    //         }

    //         countrylisttempdataunit.push(totalListunit)
    //         setDomainListData(countrylisttempdataunit)
    //     }
    // }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])


    console.log(countryData, 'countryData');
    console.log(domainData, 'domainData');


    useEffect(() => {
        if (filterData.domain) {
            let tempDom = []
            for (let i in filterData.domain) {
                let filteredDomain = _.find(domainData, { cd_id: `${filterData.domain[i]}` })
                if (filteredDomain) {
                    tempDom.push({
                        "d_id": filteredDomain.d_id,
                        "c_id": filteredDomain.c_id,
                        "month_from": filteredDomain.month_from,
                        "month_to": filteredDomain.month_to
                    })
                }
                console.log(filteredDomain, 'filteredDomain');
            }
            setNewCountryDomain(tempDom)
            console.log(tempDom, 'tempDom');
        }

    }, [domainData, filterData.domain])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === 'All Legal Entity') {
            if (uniq_country_data && uniq_country_data.length > 0) {
                console.log('hii');
                console.log(uniq_country_data, 'uniq_country_data');
                setfield({
                    ...field,
                    country: uniq_country_data
                })
            }
        }
        else {
            let filteredEntity = _.filter(commonData.le_did_infos, { le_id: localStorage.getItem('SelectedEntityid') })
            console.log(filteredEntity, 'filteredEntity');
            if (filteredEntity && filteredEntity.length > 0) {
                let filteredCountry = _.filter(uniq_country_data, { c_id: filteredEntity[0].c_id })
                setfield({
                    ...field,
                    country: filteredCountry
                })
            }
        }
    }, [commonData, uniq_country_data, localStorage.getItem('SelectedEntityid'), localStorage.getItem('SelectedEntity')])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === 'All Legal Entity' && JSON.parse(sideFilterState) === false) {
            if (uniq_country_data && uniq_country_data.length > 0) {
                console.log('hii');
                console.log(uniq_country_data, 'uniq_country_data');
                setfield({
                    ...field,
                    country: uniq_country_data
                })
                if (JSON.parse(isCountryValueTrue) === false) {
                    let temp = []
                    let temp1 = []
                    let temp2 = []
                    let temp3 = []
                    for (let i in commonData.d_info) {
                        temp3.push(commonData.d_info[i].d_id)
                        temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
                    }
                    for (let j in uniq_country_data) {
                        temp1.push(`${uniq_country_data[j].c_id}`)
                        temp2.push(uniq_country_data[j].c_id)
                    }
                    console.log(temp, 'temp');
                    console.log(temp3, 'temp3');
                    setFilterData({
                        ...filterData,
                        country: temp1,
                        countryArr: temp2,
                        domain: temp,
                        domainIds: temp3
                    })
                }
                else {
                    setFilterData({
                        ...filterData,
                        country: [],
                        countryArr: [],
                        domain: [],
                        domainIds: []
                    })
                }
            }
        }
        else if (localStorage.getItem('SelectedEntity') != 'All Legal Entity' && JSON.parse(sideFilterState) === false) {
            let filteredEntity = _.filter(commonData.le_did_infos, { le_id: localStorage.getItem('SelectedEntityid') })
            console.log(filteredEntity, 'filteredEntity');
            if (filteredEntity && filteredEntity.length > 0) {
                let filteredCountry = _.filter(uniq_country_data, { c_id: filteredEntity[0].c_id })

                setfield({
                    ...field,
                    country: filteredCountry
                })
                // if (JSON.parse(isCountryValueTrue) === false) {
                let temp = []
                let temp1 = []
                let temp2 = []
                let temp3 = []
                for (let i in commonData.d_info) {
                    temp3.push(commonData.d_info[i].d_id)
                    temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
                }
                for (let j in filteredCountry) {
                    temp1.push(`${filteredCountry[j].c_id}`)
                    temp2.push(filteredCountry[j].c_id)
                }
                console.log(temp, 'temp');
                console.log(temp3, 'temp3');
                setFilterData({
                    ...filterData,
                    country: temp1,
                    countryArr: temp2,
                    domain: temp,
                    domainIds: temp3
                })
                // }
                // else {
                //     setFilterData({
                //         ...filterData,
                //         country: [],
                //         countryArr: [],
                //         domain: [],
                //         domainIds: []
                //     })
                // }
            }
        }
    }, [commonData, uniq_country_data, localStorage.getItem('SelectedEntityid'), localStorage.getItem('SelectedEntity'), localStorage.getItem('filterState')])

    useEffect(() => {
        if (filterData.country && filterData.country.length === 0) {
            localStorage.setItem('countryState', true)
        }
        else {
            localStorage.setItem('countryState', false)
        }
    }, [filterData.country])

    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        filteredCountries && filteredCountries.length > 0 && filteredCountries.map(function (user) {
            // console.log(list.unitlist, 'list.unitlist');
            if ((arrunit.indexOf(user.c_id) < 0))
                arrunit.push(user.c_id);
        });

        arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            if (pmenu || pmenu == null) {
                commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((subunitlist) => {

                    if (pmenu == subunitlist.c_id) {
                        entityArrayunit = {

                            'c_id': subunitlist.c_id,
                            'd_id': `${subunitlist.c_id}-${subunitlist.d_id}`,
                            'd_name': subunitlist.d_name,
                            'is_active': subunitlist.is_active,
                            'month_from': subunitlist.month_from,
                            'month_to': subunitlist.month_to,
                            'label': subunitlist.d_name,
                            'values': subunitlist.d_id

                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                })
            }
            let countryName = _.find(filteredCountries, { c_id: pmenu })
            console.log(countryName, 'countryName');
            totalListunit = {
                'label': countryName.c_name == null ? 'Others' : countryName.c_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setDomainListData(countrylisttempdataunit)


    }, [filteredCountries, commonData])

    const HandelCountryChange = (obj) => {
        console.log(obj, 'objj');
        var numberArray = obj.map(Number);
        console.log(numberArray, 'numberArray');
        let temp5 = []
        let filterDomain = []
        let filteredBg = []
        let filteredLE = []
        let leTemp = []
        let bgTemp = []
        for (let j in obj) {
            filterDomain = _.filter(commonData.d_info, { c_id: Number(obj[j]) })
            // temp2.push(filterDomain)
            for (let i in filterDomain) {
                temp5.push(filterDomain[i])
            }

            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                filteredBg = _.filter(commonData && commonData.bg_groups, { c_id: Number(obj[j]) })
                console.log(filteredBg, 'filteredBg');
                if (filteredBg && filteredBg.length > 0) {
                    for (let b in filteredBg) {
                        bgTemp.push(filteredBg[b])
                    }
                }
                else {
                    filteredLE = _.filter(commonData && commonData.le_did_infos, { c_id: Number(obj[j]) })
                    if (filteredLE && filteredLE.length > 0) {
                        for (let l in filteredLE) {
                            leTemp.push(filteredLE[l])
                        }
                    }
                }
                // if (bgIds.includes(null) === true) {
                //     filteredBg = _.filter(commonData && commonData.bg_groups, { c_id: Number(obj[j]) })
                //     console.log(filteredBg,'filteredBg1111');
                // }
                // else {
                //     filteredBg = _.filter(commonData && commonData.bg_groups, { c_id: Number(obj[j]) })
                //     console.log(filteredBg, 'filteredBg');
                //     for (let b in filteredBg) {
                //         bgTemp.push(filteredBg[b])
                //     }
                // }
            }

            if (commonData && commonData.le_did_infos) {
                filteredLE = _.filter(commonData && commonData.le_did_infos, { c_id: Number(obj[j]) })
                if (filteredLE && filteredLE.length > 0) {
                    for (let l in filteredLE) {
                        leTemp.push(filteredLE[l])
                    }
                    // leTemp = _.uniq(leTemp)
                }
            }

        }
        let id = []
        for (let i in temp5) {
            id.push(`${temp5[i].c_id}-${temp5[i].d_id}`)
        }
        let dIds = []
        for (let i in temp5) {
            dIds.push(temp5[i].d_id)
        }
        let bgIds1 = bgTemp && bgTemp.length > 0 && bgTemp.map((item, i) => {
            return item.bg_id
        })
        let leIds = leTemp && leTemp.length > 0 && leTemp.map((item, i) => {
            return item.le_id
        })
        let uniqueLe = _.uniq(leIds)
        console.log(uniqueLe, 'uniqueLe');
        console.log(leTemp, 'leTemp');
        console.log(bgTemp, 'bggg');
        console.log(bgIds, 'bgIds');
        setFieldTypeClick('Country')
        setType('')
        setFilterData({
            ...filterData,
            country: obj,
            countryArr: numberArray,
            domain: id,
            business: `${bgIds1}`,
            legalentity: leIds ? uniqueLe : [],
            domainIds: dIds
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            country: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
    }

    const HandelChange = (obj) => {
        console.log(obj, 'objj');
        let tempObj = []
        if (obj && obj.length) {
            for (let i in obj) {
                let splitData = obj[i].split("-")
                console.log(splitData[1], 'splitData');
                // tempObj = [...tempObj,...splitData[1]]
                tempObj.push(Number(splitData[1]))
            }
        }
        console.log(tempObj, 'tempObj');
        setFieldTypeClick('Domain')
        setType('')
        setFilterData({
            ...filterData,
            domain: obj,
            domainIds: tempObj
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            domain: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
        let temp = []
        let bg = []
        let tempDiv = []
        let tempCat = []
        let tempUnit = []
        console.log(tempCat, 'tempCat');
        console.log(bg, 'Aishuuuu');
        console.log(temp, 'SnehaaaaaaaaAsss');

    }


    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
    //         let arrunit = [];
    //         let totalListunit = []
    //         let countrylisttempdataunit = [];
    //         let entityArrayunit = []
    //         let tempArrayunit = []
    //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
    //             console.log(subunitlist, 'subunitlist-domain');
    //             if (country_info && country_info[0].c_id == subunitlist.c_id) {
    //                 entityArrayunit = {

    //                     'c_id': subunitlist.c_id,
    //                     'd_id': subunitlist.d_id,
    //                     'd_name': subunitlist.d_name,
    //                     'is_active': subunitlist.is_active,
    //                     'month_from': subunitlist.month_from,
    //                     'month_to': subunitlist.month_to,
    //                     'label': subunitlist.d_name,
    //                     'values': subunitlist.d_id

    //                 }
    //                 tempArrayunit.push(entityArrayunit)
    //                 console.log(entityArrayunit, 'entityArrayunit');
    //             }

    //         })
    //         totalListunit = {
    //             'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
    //             'options': tempArrayunit
    //         }

    //         countrylisttempdataunit.push(totalListunit)
    //         setDomainListData(countrylisttempdataunit)
    //     }
    // }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                const uniqueIds = commonData.le_did_infos && commonData.le_did_infos.map((item) => {
                    return item.bg_id
                })
                setBgIds(uniqueIds)
                let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(commonData && commonData.bg_groups[0].bg_id) })
                console.log(filteredDivision, 'filteredDivision');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)
                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)
                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)

                }
            }
            else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                console.log('hello ais');
                let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: commonData.le_did_infos[0].le_id })
                console.log(filteredDivision, 'filteredDivision555');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)

                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)

                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)
                }
            }
        }
    }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity") {
            console.log('hiii');
            if ((commonData && commonData.bg_groups && commonData.bg_groups.length === 0) && (legalEntityArray && legalEntityArray.length > 0)) {
                console.log('hello');
                if (unit) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    unit && unit.length > 0 && unit.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-lee');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'address': subunitlist.address,
                                'business_group_id': subunitlist.business_group_id,
                                'category_id': subunitlist.category_id,
                                'country_id': subunitlist.country_id,
                                'd_ids': subunitlist.d_ids,
                                'division_id': subunitlist.division_id,
                                'le_id': subunitlist.le_id,
                                'postal_code': subunitlist.postal_code,
                                'u_id': subunitlist.u_id,
                                'u_name': subunitlist.u_name,
                                'label': subunitlist.u_name,
                                'values': subunitlist.u_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunitleee');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setUnitListData(countrylisttempdataunit)
                }
                if (division) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    division && division.length > 0 && division.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {

                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.div_name,
                                'values': subunitlist.div_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunit');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setDivisionListData(countrylisttempdataunit)
                }
                if (category) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    category && category.length > 0 && category.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-category');
                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'cat_id': subunitlist.cat_id,
                                'cat_name': subunitlist.cat_name,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.cat_name,
                                'values': subunitlist.cat_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunit');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                    setCategoryListData(countrylisttempdataunit)
                }
            }
        }

    }, [commonData, legalEntityArray, unit, division, category, localStorage.getItem('SelectedEntity') === "All Legal Entity"])


    const showmore = () => {
        setCountValue(countValue + 100)
        setSearchedValue({
            ...searchedValue,
            bgGroup: '',
            legalEntity: '',
            division: '',
            compliance: '',
            criticality: '',
            assignedTo: '',
            overdue: ''
        })
        searchingValue['business_group'] = ''
        searchingValue['legal_entity'] = ''
        searchingValue['division'] = ''
        searchingValue['compliance'] = ''
        searchingValue['criticality'] = ''
        searchingValue['assignedTo'] = ''
        searchingValue['overdue'] = ''
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetNotCompliedDrillDown",
                    {

                        "not_complied_type": compliedType,
                        "record_count": countValue + 100,
                        "d_ids":
                            filterData && filterData.domain ? filterData.domainIds : dIds
                        ,
                        "filter_type": ((fieldType != "") && (type === '')) ? fieldType : "Group",
                        "filter_ids": [
                            (fieldType == "LegalEntity" && type === '') ? filterData.legalentity :
                                (fieldType == 'Division' && type === '') ? Number(filterData.division) :
                                    (fieldType == 'Category' && type === '') ? Number(filterData.category) :
                                        (fieldType == 'Unit' && type === '') ? Number(filterData.unit) :
                                            (fieldType == 'BusinessGroup' && type === '') ? Number(filterData.business) :
                                                (fieldType != "" && type === 'Group') ? 1 :
                                                    1
                        ],
                        "le_ids":
                            filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        ,
                        "country_domains": countryDomain,
                    }
                ]
            }
        ]
        if (paramid != "") {
            getOverDueShowMoreTableData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const onClose = () => {
        setOpen(false);
    };

    const filterChange = () => {
        setGeneratedOn(false)
        setGo(true)
        if (groupCheckBox === true && errorField === '') {
            if (fieldTypeClick === 'Country' || fieldTypeClick === 'Domain') {
                let obj = {
                    "c_ids": filterData.countryArr,
                    "cd_ids": filterData.domain,
                    "d_ids": filterData.domainIds,
                    "filter_type": "Group",
                    "filter_ids": fieldTypeClick === "Country" ? filterData.countryArr : cIds,
                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                    "country_domains": newCountryDomain,
                }
                localStorage.setItem('filterState', true)
                localStorage.setItem('filterChange', JSON.stringify(obj))
            }
            else {
                let obj = {}
                localStorage.setItem('filterState', false)
                localStorage.setItem('filterChange', JSON.stringify(obj))
            }
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotCompliedChart",
                        {
                            "c_ids": filterData.countryArr,
                            "d_ids": filterData.domainIds,
                            "filter_type": ((fieldType != "") && (type === '')) ? fieldType : "Group",
                            "filter_ids": [
                                (fieldType == "LegalEntity" && type === '') ? filterData.legalentity :
                                    (fieldType == 'Division' && type === '') ? Number(filterData.division) :
                                        (fieldType == 'Category' && type === '') ? Number(filterData.category) :
                                            (fieldType == 'Unit' && type === '') ? Number(filterData.unit) :
                                                (fieldType == 'BusinessGroup' && type === '') ? Number(filterData.business) :
                                                    (fieldType != "" && type === 'Group') ? 1 :
                                                        1
                            ],
                            "le_ids":
                                filterData && filterData.leIds ? [filterData.leIds] : filterData.legalentity ? filterData.legalentity : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                            ,
                            "country_domains": newCountryDomain,
                        }
                    ]
                }
            ]

            if (paramid != "") {
                getOverduechart({
                    payload: payload,
                    paramid: paramid
                })
            }
            setOpen(false);

        }
        else {
            // toast.error('Please select the filter')
            toast.error(errorField ? errorField : 'Please select the filter')
        }
    }


    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
        // historynew.push(homepage)
        window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }


    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    autoFocus={'on'}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    console.log(count, 'countcount');

    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: '10%',
            align: 'center'
        },
        ...((fieldType === 'LegalEntity' && go === true) || (fieldType === 'Category' && go === true) || (fieldType === 'Unit' && go === true) ?
            [] :
            [{
                title: "Business Group",
                ...getColumnSearchProps('bg_name', "Business Group"),
                dataIndex: 'bg_name',
                key: 'bg_name',
                ellipsis: false,
                width: '25%',
                filteredValue: filteredInfo.bg_name || null,
            },
            {
                title: 'Legal Entity',
                ...getColumnSearchProps('le_name', 'Legal Entity'),
                dataIndex: false,
                key: 'le_name',
                ellipse: false,
                width: '25%',
                filteredValue: filteredInfo.le_name || null,
                render: (record) => {
                    return <p title={record.le_name} className='cut-text'>
                        {record.le_name}</p>
                }
            }
            ]
        ),
        {
            title: 'Division',
            ...getColumnSearchProps('div_name', 'Division'),
            dataIndex: false,
            key: 'div_name',
            // width: '30%',
            ellipsis: false,
            filteredValue: filteredInfo.div_name || null,
            render: (record) => {
                return <p title={record.div_name} className='cut-text'>
                    {record.div_name}</p>
            }

        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name', 'Compliance'),
            dataIndex: false,
            key: 'comp_name',
            width: '90%',
            ellipse: false,
            render: (row, record) => {
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Tooltip title={
                                                <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                            <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid #636262' }}><center>{record.descp ? record.descp : '-'}</center></td>
                                                            <td style={{ border: '1px solid #636262' }}><center>{record.doc_ref_num ? record.doc_ref_num : '-'}</center></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            }></Tooltip><ExclamationCircleFilled className='me-1' />
                                            {/* <Popover content={content} title="">
                                                <Button style={{ border: 0 }}>
                                                <i class="bi-info-circle-fill"></i>
                                                </Button>
                                                <ExclamationCircleFilled className='me-1' />
                                            </Popover> */}
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.div_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div>
                                {/* <Popover content={content} title="">
                                    <Button style={{ border: 0 }}>
                                    <i class="bi-info-circle-fill"></i>
                                    <ExclamationCircleFilled className='me-1' />
                                    </Button>
                                </Popover> */}
                                <Tooltip title={
                                    <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                        <tbody>
                                            <tr>
                                                <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                            </tr>
                                            <tr>
                                                <td style={{ border: '1px solid #636262' }}><center>{record.descp ? record.descp : '-'}</center></td>
                                                <td style={{ border: '1px solid #636262' }}><center>{record.doc_ref_num ? record.doc_ref_num : '-'}</center></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }><ExclamationCircleFilled className='me-1' /></Tooltip>
                                {record.comp_name}
                            </div>
                        </div>

                    </>
                )
            },
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality', "Criticality"),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: '100%',
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name', "Assigned To"),
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: '100%',
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Over Due",
            ...getColumnSearchProps('ageing', "Over Due"),
            dataIndex: 'ageing',
            key: 'ageing',
            ellipsis: false,
            render: (row, record) => {
                return (
                    <>
                        <span>{record.ageing}</span>
                    </>
                )
            },
            width: '100%',
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ];

    const columns1 = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: 7,
            align: 'center'
        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name', 'Compliance'),
            dataIndex: false,
            key: 'comp_name',
            ellipse: true,
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Popover content={content} title="">
                                                <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                            </Popover>
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.comp_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div className="mb-3">
                                <Popover content={content} title="">
                                    <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                </Popover>{record.comp_name}
                            </div>
                        </div>

                    </>
                )
            },
            width: 25,
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality', "Criticality"),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name', "Assigned To"),
            dataIndex: false,
            key: 'assignee_name',
            ellipsis: false,
            render: (record) => {
                return <span title={record.assignee_name} className='cut-text'>
                    {record.assignee_name}</span>
            },
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: 13,
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Over Due",
            ...getColumnSearchProps('ageing', "Over Due"),
            dataIndex: 'ageing',
            key: 'ageing',
            ellipsis: false,
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ]


    console.log(criticalityTable, 'cricTable');
    console.log(data, 'data');
    console.log(timeZoneData, 'timeZoneData');
    // console.log(tableData1, 'tableData1');

    // return (
    //     <div className="page-wrapper">
    //         <div className="page-titles pb-2 pt-2">
    //             <div className="col-md-12">
    //                 <div className="row">
    //                     <div className="col-md-8">
    //                         <div className="col-lg-8 col-md-6 col-12 align-self-center">
    //                             <nav aria-label="breadcrumb">
    //                                 <ol className="breadcrumb mb-0 d-flex align-items-center">
    //                                     <li className="breadcrumb-item">
    //                                         <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
    //                                     </li>
    //                                     <li className="breadcrumb-item active" aria-current="page">
    //                                         <Link>Dashboard</Link>
    //                                     </li>
    //                                     <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
    //                                         <Link>Compliance Trend</Link>
    //                                     </li>
    //                                 </ol>
    //                             </nav>

    //                         </div>
    //                     </div>
    //                     {table == false ?
    //                         <>
    //                             <div className="col-md-2">

    //                             </div>
    //                             <div className="col-md-2">
    //                                 <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '40%' }} onClick={() => {
    //                                     setOpen(true)
    //                                 }}
    //                                     size='default' >
    //                                     Filter
    //                                 </Button>
    //                             </div>
    //                         </> : ''
    //                     }

    //                 </div>
    //             </div>
    //         </div>
    //         {
    //             table == true ?
    //                 <div className="container-fluid pt-1">
    //                     {/* <div className='col-md-12'>
    //                         <div className='row'>
    //                             <div className="col-md-4">
    //                                 <p><b>Compliance Trend of {commonData && commonData.g_name}</b></p>
    //                             </div>
    //                             <div className="col-md-4">

    //                             </div>
    //                             <div className="col-md-4">
    //                                 <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#00FF7F", marginLeft: '70%' }}
    //                                     icon={<ArrowLeftOutlined />} size='default' onClick={() => {
    //                                         setTable(false)
    //                                     }}>
    //                                     Back
    //                                 </Button>
    //                             </div>
    //                         </div>
    //                     </div><br /> */}
    //                     <div className="col-md-12">
    //                         <div className="row">
    //                             <div className="col-md-4">

    //                             </div>
    //                             <div className="col-md-4">

    //                             </div>
    //                             <div className="col-md-4">
    //                                 <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#00FF7F", marginLeft: '70%' }}
    //                                     icon={<ArrowLeftOutlined />} size='default' onClick={() => {
    //                                         setTable(false)
    //                                     }}>
    //                                     Back
    //                                 </Button>
    //                             </div>
    //                         </div>
    //                     </div><br />
    //                     < CollapsedefaultActiveKey={['0']}>
    //                         {criticalityTable && criticalityTable.length > 0 &&
    //                             criticalityTable.map((item) => {
    //                                 let unitName = item.drill_compliances
    //                                 let keys = Object.keys(unitName);
    //                                 return (
    //                                     <Panel header={`${item.le_name}-${item.u_name}`}>
    //                                         {keys && keys.length > 0 && keys.map((item, i) => {
    //                                             let values = Object.values(unitName);
    //                                             let childDatas = values
    //                                             console.log(childDatas, 'childDatas');
    //                                             return (
    //                                                 <>
    //                                                     <h6 className='m-2 ml-2 mb-2'>{keys[i]}</h6>
    //                                                     <Table
    //                                                         columns={columns}
    //                                                         dataSource={childDatas[i]}
    //                                                         bordered
    //                                                         scroll={{ x: 1500 }}
    //                                                         pagination={false}
    //                                                         key={searchText.index}
    //                                                     />
    //                                                 </>
    //                                             )
    //                                         })}

    //                                     </Panel>
    //                                 )
    //                             })
    //                         }
    //                     </Collapse>
    //                     <div className='col-md-12 mt-2'>
    //                         <div className='row'>
    //                             <div className='col-md-4'></div>
    //                             <div className='col-md-4'>
    //                                 <button className="btn btn-success" style={{ float: "center", marginLeft: "50px" }}
    //                                     onClick={showmore}
    //                                 >Show More</button>
    //                             </div>
    //                             <div className='col-md-4'></div>
    //                         </div>
    //                     </div>
    //                     {/* <Table dataSource={dataSource} columns={columns} pagination={false}
    //         className='userprivclass' /> */}
    //                 </div> :
    //                 <div className="container-fluid pt-1">
    //                     <div className="row">
    //                         {(data.Above_90_days_count > 0) || (data.T_0_to_30_days_count > 0) || (data.T_31_to_60_days_count > 0) || (data.T_61_to_90_days_count > 0) ?
    //                             <>
    //                                 <div className="col-md-12 mt-3">

    //                                     {/* <div className="card">
    //                                     <div className='card-body'> */}

    //                                     <>
    //                                         <div id="container" style={{ width: "100%", height: "450px" }}></div>
    //                                         <div className="col-md-12" style={{ marginTop: '2%' }}>
    //                                             <div className="row">
    //                                                 <div className="col-md-6">

    //                                                 </div>
    //                                                 <div className="col-md-6" style={{ marginLeft: '70%' }}>
    //                                                     <label htmlFor=""><b>Time Zone :</b></label>
    //                                                     <Select
    //                                                         allowClear={true}
    //                                                         size="default"
    //                                                         onChange={(data) => {
    //                                                             setTimeZone(data)
    //                                                             setGeneratedOn(true)
    //                                                             const payload = [
    //                                                                 authtoken,
    //                                                                 {
    //                                                                     "session_token": authtoken,
    //                                                                     "request": [
    //                                                                         "GetDateTime_ByZone",
    //                                                                         {
    //                                                                             "tz_name": data
    //                                                                         }
    //                                                                     ]
    //                                                                 }
    //                                                             ]
    //                                                             getTimeZoneData({
    //                                                                 payload: payload,
    //                                                                 paramid: paramid
    //                                                             })

    //                                                         }}
    //                                                         placeholder="Enter Time Zone"
    //                                                         style={{ marginLeft: '5%' }}
    //                                                     // value={compfie.user_Group}

    //                                                     >

    //                                                         {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

    //                                                             return (

    //                                                                 <Option key={item.tz_name}>

    //                                                                     {item.tz_name}

    //                                                                 </Option>

    //                                                             );

    //                                                         })}
    //                                                         {/* );
    //                                         })} */}
    //                                                     </Select>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </>
    //                                     {/* </div>
    //                                 </div> */}
    //                                 </div>
    //                             </>
    //                             : <p style={{ marginTop: '18%', marginLeft: '40%', marginBottom: '18%' }}>No Data to Display </p>}
    //                         {/* <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-2">
    //                                     <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "25px" }}
    //                                         icon={<ArrowLeftOutlined />} size='default'
    //                                         onClick={() => {
    //                                             setYear(year - 1)
    //                                         }}
    //                                     >
    //                                         Previous Year
    //                                     </Button>
    //                                 </div>
    //                                 <div className="col-md-8"></div>
    //                                 <div className="col-md-2">
    //                                     {year != moment().year() ?
    //                                         <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginRight: "25px" }}
    //                                             size='default'
    //                                             onClick={() => {
    //                                                 setYear(year + 1)
    //                                             }}
    //                                         >
    //                                             Next Year<ArrowRightOutlined />
    //                                         </Button>
    //                                         : false}
    //                                 </div>
    //                             </div>
    //                         </div> */}
    //                     </div>

    //                     {/* } */}
    //                 </div>
    //         }


    //         <Drawer title="FILTER BY" placement="right" onClose={onClose} visible={open}>
    //             <div className="col-md-12">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label><b>Country</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Country"
    //                             onChange={(data, values) => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     country: data
    //                                 })
    //                                 setFilterDataNames({
    //                                     ...filterDataNames,
    //                                     country: values.children
    //                                 })
    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >
    //                             {field && field.country && field.country.length && field.country.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.c_id}>
    //                                         {item.c_name}
    //                                     </Option>
    //                                 );
    //                             })}

    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label><b>Domain</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Domain"
    //                             onChange={(data, values) => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     domain: data
    //                                 })
    //                                 setFilterDataNames({
    //                                     ...filterDataNames,
    //                                     domain: values.children
    //                                 })
    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >
    //                             {field && field.domain && field.domain.length && field.domain.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.d_id}>
    //                                         {item.d_name}
    //                                     </Option>
    //                                 );
    //                             })}

    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-1">
    //                         <input type='checkbox'></input>
    //                     </div>
    //                     <div className="col-md-4">
    //                         <label><b>Group</b></label>
    //                     </div>
    //                     {/* <div className="col-md-2">

    //                 </div> */}
    //                     <div className="col-md-1">
    //                         {/* <input type='checkbox' onChange={(e) => {
    //                         if (e.target.value) {
    //                             setFilterData({
    //                                 ...filterData,
    //                                 chartType: 'Consolidated'
    //                             })
    //                         }
    //                     }}></input> */}
    //                     </div>
    //                     <div className="col-md-4">
    //                         {/* <label><b>Consolidated</b></label> */}
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-6">

    //                         <label><b>From Date</b></label>
    //                         <DatePicker
    //                             disabled={true}
    //                             allowClear={() => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     from_date: null,
    //                                 })
    //                             }}
    //                             // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
    //                             // value={consolidatedData.from_date}
    //                             // disabled={consolidatedData.domainy == '' ? true : false}
    //                             onChange={(date, dateString) => {

    //                                 console.log(dateString, 'dateString');
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     from_date: dateString,
    //                                 })

    //                             }}

    //                         />

    //                     </div>
    //                     <div className="col-md-6">

    //                         <label><b>To Date</b></label>

    //                         <DatePicker
    //                             // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
    //                             // value={consolidatedData.from_date}
    //                             // disabled={consolidatedData.domainy == '' ? true : false}
    //                             // onChange={(date, dateString) => {

    //                             //     console.log(dateString, 'dateString');
    //                             //     setConsolidatedData({
    //                             //         ...consolidatedData,
    //                             //         from_date: dateString,
    //                             //         to_date: moment(dateString).add(3, 'months')
    //                             //     })

    //                             // }}
    //                             disabled={true}
    //                             onChange={(date, dateString) => {

    //                                 console.log(dateString, 'dateString');
    //                                 if (dateString != undefined) {
    //                                     setFilterData({
    //                                         ...filterData,
    //                                         to_date: dateString,
    //                                     })
    //                                 }
    //                                 else {
    //                                     setFilterData({
    //                                         ...filterData,
    //                                         to_date: null,
    //                                     })
    //                                 }


    //                             }}

    //                         />

    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label><b>Business Group</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Business Group"
    //                             onChange={(data, values) => {
    //                                 let filteredEntity = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(data) })
    //                                 setfield({
    //                                     ...field,
    //                                     legalEntity: filteredEntity
    //                                 })
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     business: data
    //                                 })
    //                                 setFilterDataNames({
    //                                     ...filterDataNames,
    //                                     business: values.children
    //                                 })
    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >
    //                             {field && field.business && field.business.length && field.business.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.bg_id}>
    //                                         {item.bg_name}
    //                                     </Option>
    //                                 );
    //                             })}


    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label style={{ color: fieldType == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Legal Entity"
    //                             onChange={(data, values) => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     legalentity: data
    //                                 })
    //                                 setFieldType('LegalEntity')
    //                                 setGo(false)
    //                                 setFilterDataNames({
    //                                     ...filterDataNames,
    //                                     legalentity: values.children
    //                                 })

    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >

    //                             {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.le_id}>
    //                                         {item.le_name}
    //                                     </Option>
    //                                 );
    //                             })}

    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label style={{ color: fieldType == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Division"
    //                             onChange={(data, values) => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     division: data
    //                                 })
    //                                 let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
    //                                 console.log(subData, 'subDatasubData');
    //                                 setCategory(subData)
    //                                 setFieldType('Division')
    //                                 setGo(false)
    //                                 setFilterDataNames({
    //                                     ...filterDataNames,
    //                                     division: values.children
    //                                 })

    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >
    //                             {division && division.length && division.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.div_id}>
    //                                         {item.div_name}
    //                                     </Option>
    //                                 );
    //                             })}


    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label style={{ color: fieldType == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Category"
    //                             onChange={(data) => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     category: data
    //                                 })
    //                                 let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
    //                                 setUnit(subData)
    //                                 setFieldType('Category')
    //                                 setGo(false)

    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >
    //                             {category && category.length && category.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.cat_id}>
    //                                         {item.cat_name}
    //                                     </Option>
    //                                 );
    //                             })}


    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label style={{ color: fieldType == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Unit"
    //                             onChange={(data, values) => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     unit: data
    //                                 })
    //                                 setFieldType('Unit')
    //                                 setGo(false)
    //                                 setFilterDataNames({
    //                                     ...filterDataNames,
    //                                     unit: values.children
    //                                 })

    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >
    //                             {unit && unit.length > 0 && unit.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.u_id}>
    //                                         {item.u_name}
    //                                     </Option>
    //                                 );
    //                             })}


    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
    //                 onClick={filterChange}
    //                 size='default' >
    //                 Go
    //             </Button>
    //             {/* <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
    //                 onClick={() => {
    //                     const payload = [
    //                         authtoken,
    //                         {
    //                             "session_token": authtoken,
    //                             "request":
    //                                 ["GetTskDistributionStatusChart",
    //                                     {
    //                                         "c_ids": [
    //                                             commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                         ],
    //                                         "d_ids": [
    //                                             commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                                         ],
    //                                         "filter_type": "Group",
    //                                         "filter_ids": [
    //                                             1
    //                                         ],
    //                                         "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
    //                                             commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
    //                                         ],
    //                                         "country_domains": [
    //                                             {
    //                                                 "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                                 "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                                 "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                                 "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                                             }
    //                                         ],
    //                                         "chart_year": year
    //                                     }
    //                                 ]
    //                         }
    //                     ]
    //                     getTaskDistributionchart({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                     setOpen(false)

    //                     setFieldType("")
    //                     setFilterData({
    //                         ...filterData,
    //                         country: '',
    //                         domain: '',
    //                         legalentity: '',
    //                         division: '',
    //                         unit: '',
    //                         business: '',
    //                         category: '',
    //                         unit: ''
    //                     })
    //                 }}
    //                 size='default' >
    //                 Reset
    //             </Button> */}
    //         </Drawer>

    //     </div>

    console.log(criticalityTable, 'criticalityTable');

    return (
        <div>
            <div className="back-to-top">
                <ScrollButton />
            </div>
            <div id='page-wrapper' className="page-wrapper">
                <div className="page-titles pb-0 pt-1 page-title-sticky">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Dashboard</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Over Due Chart</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div
                            className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex align-items-center justify-content-md-end justify-content-sm-start">
                            {/* <div> <Tooltip title="" color='#1890ff'>
                                <Button type="primary" shape="round" className='addbutton'

                                    icon={<PlusCircleOutlined />} size='default' onClick={() => {
                                        setShowList(true)
                                        setAddType('add')
                                    }}>
                                    Add
                                </Button>

                            </Tooltip></div> */}


                            {table == false ? <Button icon={<FilterOutlined />} type="primary" className='addbutton' style={{ background: "#0096FF", marginRight: '3%', borderRadius: '15px' }} onClick={() => {
                                setOpen(true)
                            }}
                                size='default' >
                                <Tooltip placement='top' title='Filter'>Filter</Tooltip>
                            </Button> : <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", marginRight: '3%' }}
                                icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                    setTable(false)
                                    setCriticalityTable([])
                                    setSearchingValue([])
                                    setCountValue(0)
                                    setTempcriticalityTable([])
                                }}>
                                Back
                            </Button>}




                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black  full-mode fullscreenradius" >
                                <Tooltip placement='left' title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>

                            {/* <button onClick={() => {
                                    setShowList(true)
                                    setAddType('add')
                                }} className="icons twitter" style={{ border: 'none', margin: '0px' }}>
                                    <span className="tooltips" style={{ right: '105%' }}>Add</span>
                                    <span><i id={localStorage.getItem('currentTheme')}><PlusCircleOutlined /></i></span>
                                </button> */}


                        </div>
                    </div>
                    {/* {table == false ?
                                <div className="col-md-4">
                                  
                                </div> : ''}
                            <div className="col-md-2">
                                <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black full-mode fullscreenradius" style={{ marginRight: '10px' }} >
                                    <Tooltip placement="left" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                </button>
                            </div> */}


                </div>
                {
                    table == true ?
                        <div className="container-fluid pt-0">
                            <p style={{ fontSize: '16px' }}><b style={{ fontSize: '16px' }}>{`Over Due Compliance Of ${commonData && commonData.g_name}`}</b></p>
                            <div
                                className={
                                    localStorage.getItem("currentTheme") +
                                    " ant-table-wrapper"
                                } style={{ width: '100%' }}
                            >
                                <div className="row">

                                    <div className="ant-table ant-table-bordered ant-table-fixed-header service-provider-table"
                                        //  id='service-table-action'
                                        style={{ width: '100%', height: '100%' }}>
                                        <div className="ant-table-container">
                                            <div className="ant-table-content taskrecalltable service-table">

                                                <table className="table-fixed" >
                                                    <thead className="ant-table-thead" id='comp-approv-thead'
                                                    // style={{ top: is_Fullscreen ? (showValueModal == true ? 0 : '35px') : (showValueModal == true ? 0 : '107px') }}
                                                    >
                                                        <tr>
                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "32px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            // color: searchingValue['compliance_task'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        #
                                                                    </p>{" "}
                                                                </div>

                                                            </th>
                                                            {(fieldType === 'BusinessGroup') || (fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                <th
                                                                    className="ant-table-cell"
                                                                    style={{ width: "100px" }}
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p
                                                                            className={"p-0 mb-0"}
                                                                            style={{
                                                                                // marginLeft: "36%",
                                                                                display: "inline",
                                                                                color: searchingValue['business_group'] ? 'red' : ''
                                                                            }}
                                                                        >
                                                                            Business Group
                                                                        </p>{" "}
                                                                        <Popconfirm
                                                                            afterVisibleChange={() => {
                                                                                setFocus(myRef1);
                                                                            }}
                                                                            placement="bottom"
                                                                            title={
                                                                                // <Input
                                                                                //     ref={myRef1}
                                                                                //     autoFocus={'on'}
                                                                                //     placeholder="Compliance Tasks"
                                                                                //     onKeyUp={(e) => {
                                                                                //         compliancetaskfilter(
                                                                                //             e.target.value,
                                                                                //             "Compliance_Task"
                                                                                //         );
                                                                                //     }}
                                                                                // />
                                                                                <div className='position-relative'>
                                                                                    <Input
                                                                                        ref={myRef1}
                                                                                        placeholder="Business Group"
                                                                                        autoFocus={'on'}
                                                                                        onChange={(e) => {
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                bgGroup: e.target.value
                                                                                            });
                                                                                            searchingValue['business_group'] = e.target.value
                                                                                        }}
                                                                                        value={searchedValue.bgGroup || undefined}
                                                                                        onKeyUp={(e) => {
                                                                                            compliancetaskfilter(
                                                                                                e.target.value,
                                                                                                "business_group",
                                                                                                "business_group"
                                                                                            );

                                                                                        }}
                                                                                    />
                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                        myRef1.current.value = "";
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            bgGroup: ''
                                                                                        });
                                                                                        searchingValue['business_group'] = ''
                                                                                        compliancetaskfilter(
                                                                                            '',
                                                                                            "business_group",
                                                                                            "business_group"

                                                                                        );

                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <SearchOutlined
                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                            />
                                                                        </Popconfirm>
                                                                    </div>

                                                                </th>
                                                            }
                                                            {(fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                <th
                                                                    className="ant-table-cell"
                                                                    style={{ width: "80px" }}
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p
                                                                            className={"p-0 mb-0"}
                                                                            style={{
                                                                                // marginLeft: "36%",
                                                                                display: "inline",
                                                                                color: searchingValue['legal_entity'] ? 'red' : ''
                                                                            }}
                                                                        >
                                                                            Legal Entity
                                                                        </p>{" "}
                                                                        <Popconfirm
                                                                            afterVisibleChange={() => {
                                                                                setFocus(myRef2);
                                                                            }}
                                                                            placement="bottom"
                                                                            title={
                                                                                // <Input
                                                                                //     ref={myRef1}
                                                                                //     autoFocus={'on'}
                                                                                //     placeholder="Compliance Tasks"
                                                                                //     onKeyUp={(e) => {
                                                                                //         compliancetaskfilter(
                                                                                //             e.target.value,
                                                                                //             "Compliance_Task"
                                                                                //         );
                                                                                //     }}
                                                                                // />
                                                                                <div className='position-relative'>
                                                                                    <Input
                                                                                        ref={myRef2}
                                                                                        placeholder="Legal Entity"
                                                                                        autoFocus={'on'}
                                                                                        onChange={(e) => {
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                legal_entity: e.target.value
                                                                                            });
                                                                                            searchingValue['legal_entity'] = e.target.value
                                                                                        }}
                                                                                        value={searchedValue.legal_entity || undefined}
                                                                                        onKeyUp={(e) => {
                                                                                            compliancetaskfilter(
                                                                                                e.target.value,
                                                                                                "legal_entity",
                                                                                                "legal_entity"
                                                                                            );

                                                                                        }}
                                                                                    />
                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                        myRef2.current.value = "";
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            legal_entity: ''
                                                                                        });
                                                                                        searchingValue['legal_entity'] = ''
                                                                                        compliancetaskfilter(
                                                                                            '',
                                                                                            "legal_entity",
                                                                                            "legal_entity"

                                                                                        );

                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <SearchOutlined
                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                            />
                                                                        </Popconfirm>
                                                                    </div>

                                                                </th>
                                                            }
                                                            {(fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                <th
                                                                    className="ant-table-cell"
                                                                    style={{ width: "80px" }}
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p
                                                                            className={"p-0 mb-0"}
                                                                            style={{
                                                                                // marginLeft: "36%",
                                                                                display: "inline",
                                                                                color: searchingValue['division'] ? 'red' : ''
                                                                            }}
                                                                        >
                                                                            Division
                                                                        </p>{" "}
                                                                        <Popconfirm
                                                                            afterVisibleChange={() => {
                                                                                setFocus(myRef3);
                                                                            }}
                                                                            placement="bottom"
                                                                            title={
                                                                                // <Input
                                                                                //     ref={myRef1}
                                                                                //     autoFocus={'on'}
                                                                                //     placeholder="Compliance Tasks"
                                                                                //     onKeyUp={(e) => {
                                                                                //         compliancetaskfilter(
                                                                                //             e.target.value,
                                                                                //             "Compliance_Task"
                                                                                //         );
                                                                                //     }}
                                                                                // />
                                                                                <div className='position-relative'>
                                                                                    <Input
                                                                                        ref={myRef3}
                                                                                        placeholder="Division"
                                                                                        autoFocus={'on'}
                                                                                        onChange={(e) => {
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                division: e.target.value
                                                                                            });
                                                                                            searchingValue['division'] = e.target.value
                                                                                        }}
                                                                                        value={searchedValue.division || undefined}
                                                                                        onKeyUp={(e) => {
                                                                                            compliancetaskfilter(
                                                                                                e.target.value,
                                                                                                "division",
                                                                                                "division"
                                                                                            );

                                                                                        }}
                                                                                    />
                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                        myRef3.current.value = "";
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            division: ''
                                                                                        });
                                                                                        searchingValue['division'] = ''
                                                                                        compliancetaskfilter(
                                                                                            '',
                                                                                            "division",
                                                                                            "division"

                                                                                        );

                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <SearchOutlined
                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                            />
                                                                        </Popconfirm>
                                                                    </div>

                                                                </th>}

                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "180px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            color: searchingValue['compliance'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        Compliance
                                                                    </p>{" "}
                                                                    <Popconfirm
                                                                        afterVisibleChange={() => {
                                                                            setFocus(myRef4);
                                                                        }}
                                                                        placement="bottom"
                                                                        title={
                                                                            // <Input
                                                                            //     ref={myRef1}
                                                                            //     autoFocus={'on'}
                                                                            //     placeholder="Compliance Tasks"
                                                                            //     onKeyUp={(e) => {
                                                                            //         compliancetaskfilter(
                                                                            //             e.target.value,
                                                                            //             "Compliance_Task"
                                                                            //         );
                                                                            //     }}
                                                                            // />
                                                                            <div className='position-relative'>
                                                                                <Input
                                                                                    ref={myRef4}
                                                                                    placeholder="Compliance"
                                                                                    autoFocus={'on'}
                                                                                    onChange={(e) => {
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            compliance: e.target.value
                                                                                        });
                                                                                        searchingValue['compliance'] = e.target.value
                                                                                    }}
                                                                                    value={searchedValue.compliance || undefined}
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "compliance",
                                                                                            "compliance"
                                                                                        );

                                                                                    }}
                                                                                />
                                                                                <CloseCircleOutlined onClick={() => {
                                                                                    myRef4.current.value = "";
                                                                                    setSearchedValue({
                                                                                        ...searchedValue,
                                                                                        compliance: ''
                                                                                    });
                                                                                    searchingValue['compliance'] = ''
                                                                                    compliancetaskfilter(
                                                                                        '',
                                                                                        "compliance",
                                                                                        "compliance"

                                                                                    );

                                                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <SearchOutlined
                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </div>

                                                            </th>
                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "80px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            color: searchingValue['criticality'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        Criticality
                                                                    </p>{" "}
                                                                    <Popconfirm
                                                                        afterVisibleChange={() => {
                                                                            setFocus(myRef5);
                                                                        }}
                                                                        placement="bottom"
                                                                        title={
                                                                            // <Input
                                                                            //     ref={myRef1}
                                                                            //     autoFocus={'on'}
                                                                            //     placeholder="Compliance Tasks"
                                                                            //     onKeyUp={(e) => {
                                                                            //         compliancetaskfilter(
                                                                            //             e.target.value,
                                                                            //             "Compliance_Task"
                                                                            //         );
                                                                            //     }}
                                                                            // />
                                                                            <div className='position-relative'>
                                                                                <Input
                                                                                    ref={myRef5}
                                                                                    placeholder="Criticality"
                                                                                    autoFocus={'on'}
                                                                                    onChange={(e) => {
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            criticality: e.target.value
                                                                                        });
                                                                                        searchingValue['criticality'] = e.target.value
                                                                                    }}
                                                                                    value={searchedValue.criticality || undefined}
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "criticality",
                                                                                            "criticality"
                                                                                        );

                                                                                    }}
                                                                                />
                                                                                <CloseCircleOutlined onClick={() => {
                                                                                    myRef5.current.value = "";
                                                                                    setSearchedValue({
                                                                                        ...searchedValue,
                                                                                        criticality: ''
                                                                                    });
                                                                                    searchingValue['criticality'] = ''
                                                                                    compliancetaskfilter(
                                                                                        '',
                                                                                        "criticality",
                                                                                        "criticality"

                                                                                    );

                                                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <SearchOutlined
                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </div>

                                                            </th>
                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "80px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            color: searchingValue['assignedTo'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        Assigned To
                                                                    </p>{" "}
                                                                    <Popconfirm
                                                                        afterVisibleChange={() => {
                                                                            setFocus(myRef6);
                                                                        }}
                                                                        placement="bottom"
                                                                        title={
                                                                            // <Input
                                                                            //     ref={myRef1}
                                                                            //     autoFocus={'on'}
                                                                            //     placeholder="Compliance Tasks"
                                                                            //     onKeyUp={(e) => {
                                                                            //         compliancetaskfilter(
                                                                            //             e.target.value,
                                                                            //             "Compliance_Task"
                                                                            //         );
                                                                            //     }}
                                                                            // />
                                                                            <div className='position-relative'>
                                                                                <Input
                                                                                    ref={myRef6}
                                                                                    placeholder="Assigned To"
                                                                                    autoFocus={'on'}
                                                                                    onChange={(e) => {
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            assignedTo: e.target.value
                                                                                        });
                                                                                        searchingValue['assignedTo'] = e.target.value
                                                                                    }}
                                                                                    value={searchedValue.assignedTo || undefined}
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "assignedTo",
                                                                                            "assignedTo"
                                                                                        );

                                                                                    }}
                                                                                />
                                                                                <CloseCircleOutlined onClick={() => {
                                                                                    myRef6.current.value = "";
                                                                                    setSearchedValue({
                                                                                        ...searchedValue,
                                                                                        assignedTo: ''
                                                                                    });
                                                                                    searchingValue['assignedTo'] = ''
                                                                                    compliancetaskfilter(
                                                                                        '',
                                                                                        "assignedTo",
                                                                                        "assignedTo"

                                                                                    );

                                                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <SearchOutlined
                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </div>

                                                            </th>
                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "80px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            color: searchingValue['overdue'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        Over Due
                                                                    </p>{" "}
                                                                    <Popconfirm
                                                                        afterVisibleChange={() => {
                                                                            setFocus(myRef7);
                                                                        }}
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            // <Input
                                                                            //     ref={myRef1}
                                                                            //     autoFocus={'on'}
                                                                            //     placeholder="Compliance Tasks"
                                                                            //     onKeyUp={(e) => {
                                                                            //         compliancetaskfilter(
                                                                            //             e.target.value,
                                                                            //             "Compliance_Task"
                                                                            //         );
                                                                            //     }}
                                                                            // />
                                                                            <div className='position-relative'>
                                                                                <Input
                                                                                    ref={myRef7}
                                                                                    placeholder="Over Due"
                                                                                    autoFocus={'on'}
                                                                                    onChange={(e) => {
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            overdue: e.target.value
                                                                                        });
                                                                                        searchingValue['overdue'] = e.target.value
                                                                                    }}
                                                                                    value={searchedValue.overdue || undefined}
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "overdue",
                                                                                            "overdue"
                                                                                        );

                                                                                    }}
                                                                                />
                                                                                <CloseCircleOutlined onClick={() => {
                                                                                    myRef7.current.value = "";
                                                                                    setSearchedValue({
                                                                                        ...searchedValue,
                                                                                        overdue: ''
                                                                                    });
                                                                                    searchingValue['overdue'] = ''
                                                                                    compliancetaskfilter(
                                                                                        '',
                                                                                        "overdue",
                                                                                        "overdue"

                                                                                    );

                                                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <SearchOutlined
                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </div>

                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tr>
                                                        <td colSpan={8}>
                                                            <Collapse defaultActiveKey={['0']}>
                                                                {tempcriticalityTable && tempcriticalityTable.length > 0 &&
                                                                    tempcriticalityTable.map((items, i) => {
                                                                        // let unitName = items.drill_compliances
                                                                        // let keys = Object.keys(unitName);
                                                                        console.log(items, 'items5599');
                                                                        return (
                                                                            <Panel style={{ marginBottom: '5px' }} header={`${items[0][0].le_name}-${items[0][0].u_name}`}>
                                                                                <table >
                                                                                    <tbody className="ant-table-tbody">
                                                                                        {items.map((item, p) => {
                                                                                            return (<>
                                                                                                <tr className="ant-table-row ant-table-row-level-0">
                                                                                                    <td colSpan={8}>
                                                                                                        <h6 className='m-2 ml-2 mb-2'>{item[0].unitname}</h6>
                                                                                                    </td>
                                                                                                </tr>

                                                                                                {item.map((childitem, m) => {
                                                                                                    return <tr className="ant-table-row ant-table-row-level-0">
                                                                                                        <td style={{ width: '55px' }}
                                                                                                            className="ant-table-cell border-end">{childitem.index}</td>
                                                                                                        {(fieldType === 'BusinessGroup') || (fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                                                            <td style={{ width: '160px' }} className="ant-table-cell border-end">{childitem.bg_name}</td>}
                                                                                                        {(fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                                                            <td style={{ width: '133px' }}
                                                                                                                className="ant-table-cell border-end">{childitem.le_name}</td>}
                                                                                                        {(fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                                                            <td style={{ width: '133px' }}
                                                                                                                className="ant-table-cell border-end">{childitem.div_name}</td>}
                                                                                                        <td style={{ width: '299px' }}
                                                                                                            className="ant-table-cell border-end">
                                                                                                            <Tooltip title={
                                                                                                                <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                                                                                            <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td style={{ border: '1px solid #636262' }}><center>{childitem.descp ? childitem.descp : '-'}</center></td>
                                                                                                                            <td style={{ border: '1px solid #636262' }}><center>{childitem.doc_ref_num ? childitem.doc_ref_num : '-'}</center></td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            }><ExclamationCircleFilled /></Tooltip>{childitem.compfielaw_url != '' ? <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                                                                                <a className='ml-3' style={{ marginLeft: '5px' }} href={`${childitem.compfielaw_url}`}>
                                                                                                                    <BookTwoTone />
                                                                                                                </a>
                                                                                                            </Tooltip> : ''}
                                                                                                            <span style={{ marginLeft: '5px' }}>{childitem.comp_name}</span> </td>
                                                                                                        <td style={{ width: '143px' }}
                                                                                                            className="ant-table-cell border-end">{childitem.criticality}</td>
                                                                                                        <td style={{ width: '134px' }}
                                                                                                            className="ant-table-cell border-end">{childitem.assignee_name}</td>
                                                                                                        <td style={{ width: '127px' }}
                                                                                                            className="ant-table-cell border-end">{childitem.ageing}</td>
                                                                                                    </tr>
                                                                                                })}</>)
                                                                                        })}
                                                                                    </tbody>
                                                                                </table>
                                                                                {/* <Table
                                                                size={'small'} className='remark-header add-service-prv'
                                                                columns={columnState === true ? columns1 : columns}
                                                                dataSource={childDatas[i]}
                                                                bordered
                                                                scroll={{ x: 1000 }}
                                                                pagination={false}
                                                                onChange={handleChange}
                                                            /> */}

                                                                                {/* </> */}
                                                                                {/* ) */}
                                                                                {/* // })} */}

                                                                            </Panel>
                                                                        )
                                                                    })
                                                                }
                                                            </Collapse>
                                                        </td>
                                                    </tr>



                                                    {/* <Table dataSource={dataSource} columns={columns} pagination={false}
                    className='userprivclass' /> */}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {tableData && tableData.n_drill_down_data && tableData.n_drill_down_data.length > 0 ?
                                dataCount <= showmoreCount ? '' :
                                    <div className='col-md-12 mt-2'>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <button className="btn btn-success" style={{ float: "center", marginLeft: "20%", borderRadius: '15px' }}
                                                    onClick={showmore}
                                                >Show more records <i> <DownOutlined /></i></button>
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div> : ''
                            }
                        </div>


                        :
                        <div className="container-fluid pt-1">
                            <div className="row" >
                                <div className="col-md-12">

                                </div>
                                {/* {(data && data.length > 0) && ((data && data.length && data[0].assigned_count == 0) && (data && data.length && data[0].not_applicable_count == 0) && (data && data.length && data[0].not_opted_count == 0) && (data && data.length && data[0].unassign_count == 0)) ? */}

                                {/* <p style={{ marginTop: '18%', marginLeft: '35%', marginBottom: '18%' }}>No Data to Display </p> : */}
                                <>
                                    <div className="col-md-12 mt-0">
                                        <>
                                            {((open === true) && (fieldTypeClick != '') && (go === false)) ? <Spin size='large' style={{ marginTop: '17%', marginLeft: '45%', marginBottom: '10%' }} /> :
                                                <Card style={{ borderRadius: '15px' }}>
                                                    {JSON.parse(isCountryValueTrue) === true ?
                                                        <p style={{ width: "100%", marginTop: '20%', marginLeft: '43%', height: "250px" }}>No Data to Display</p>
                                                        :
                                                        <div id="chartdiv" style={{ width: "100%", marginTop: '10px', height: "500px" }}></div>
                                                    }
                                                </Card>}
                                            {(data && data.length > 0) && ((data && data.length && data[0].assigned_count == 0) && (data && data.length && data[0].not_applicable_count == 0) && (data && data.length && data[0].not_opted_count == 0) && (data && data.length && data[0].unassign_count == 0)) ? '' :
                                                <div className="col-md-12 mt-0">
                                                    <div className="row">
                                                        <div className="col-md-3">

                                                        </div>
                                                        <div className="col-md-3">
                                                        </div>
                                                        <div className="col-md-6  text-md-end text-lg-end" style={{ marginTop: '1%' }}>
                                                            <label htmlFor=""><b>Time Zone :</b></label>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                onChange={(data) => {
                                                                    if (data !== undefined) {
                                                                        setTimeZone(data)
                                                                        setGeneratedOn(true)
                                                                        const payload = [
                                                                            authtoken,
                                                                            {
                                                                                "session_token": authtoken,
                                                                                "request": [
                                                                                    "GetDateTime_ByZone",
                                                                                    {
                                                                                        "tz_name": data
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                        getTimeZoneData({
                                                                            payload: payload,
                                                                            paramid: paramid
                                                                        })
                                                                    }

                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                placeholder="Enter Time Zone"
                                                                style={{ marginLeft: '2%' }}
                                                            // value={compfie.user_Group}

                                                            >

                                                                {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

                                                                    return (

                                                                        <Option key={item.tz_name}>

                                                                            {item.tz_name}

                                                                        </Option>

                                                                    );

                                                                })}
                                                                {/* );
                                                })} */}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>}

                                        </>
                                    </div>

                                </>
                                {/* } */}
                            </div>
                        </div>
                }

                <Drawer title="FILTER BY" className={localStorage.getItem("currentTheme") + ' resize-drawer'} placement="right" closable={false} onClose={false} open={open}
                    extra={
                        <Space>
                            <CloseCircleOutlined style={{ marginTop: '2%', marginLeft: '75', fontSize: '140%' }} onClick={onClose} />
                        </Space>
                    }
                >
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Country</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Country"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.country ? filterData.country : undefined}
                                    defaultValue={filterData.country ? filterData.country : undefined}
                                    key={filterData.country ? filterData.country : undefined}
                                    // onChange={(data, values) => {
                                    //     setFieldTypeClick('Country')
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         country: data,
                                    //         domain: ''
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         country: values.children,
                                    //         domain: '',
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setType('')

                                    // }
                                    // }
                                    onChange={(option) => HandelCountryChange(option)}
                                    onDeselect={(data) => {

                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {field && field.country &&
                                        field.country.length > 0 &&
                                        field.country.map((item, i) => {
                                            return (
                                                <Option key={item.c_id}>
                                                    {item.c_name}
                                                </Option>
                                            );
                                        })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Domain</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Domain"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.domain ? filterData.domain : undefined}
                                    defaultValue={filterData.domain ? filterData.domain : undefined}
                                    key={filterData.domain ? filterData.domain : undefined}
                                    // onChange={(data, values) => {
                                    //     console.log(data,'data-domain');
                                    //     let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(data) })
                                    //     let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(data) })
                                    //     // let filteredCategory = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     // let filteredUnit = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(data)))
                                    //     let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
                                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                    //     let temp = []
                                    //     temp.push({
                                    //         'bg_id': filteredCategory[0].bg_id,
                                    //         'c_id': filteredCategory[0].c_id,
                                    //         'cat_id': filteredCategory[0].cat_id,
                                    //         'cat_name': filteredCategory[0].cat_name,
                                    //         'd_id': filteredCategory[0].d_id,
                                    //         'div_id': filteredCategory[0].div_id,
                                    //         'le_id': filteredCategory[0].le_id,
                                    //         'div_name': division[0].div_name
                                    //     })
                                    //     setCategory(temp)
                                    //     setUnit(filteredUnit)
                                    //     setfield({
                                    //         ...field,
                                    //         legalEntity: filteredLegalEntity,
                                    //         business: filteredBusinessGroup
                                    //     })
                                    //     let temp1=[]
                                    //     for(let i in commonData.d_info){
                                    //         temp1.push(commonData.d_info[i].d_id)
                                    //     }
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         domain: temp1
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         domain: values.children,
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setDivision(filteredDivision)
                                    //     // let temp = []
                                    //     // temp.push({
                                    //     //     'bg_id': filteredCategory[0].bg_id,
                                    //     //     'c_id': filteredCategory[0].c_id,
                                    //     //     'cat_id': filteredCategory[0].cat_id,
                                    //     //     'cat_name': filteredCategory[0].cat_name,
                                    //     //     'd_id': filteredCategory[0].d_id,
                                    //     //     'div_id': filteredCategory[0].div_id,
                                    //     //     'le_id': filteredCategory[0].le_id,
                                    //     //     'div_name': filteredDivision[0].div_name
                                    //     // })
                                    //     // setCategory(temp)
                                    //     // setUnit(filteredUnit)
                                    // }
                                    // }
                                    onChange={(option) => HandelChange(option)}
                                    // onDeselect={(data) => {
                                    //     console.log(data,'data-deselect');
                                    //     filterData && filterData.domain.splice(data,1)
                                    // }}
                                    style={{ width: '100%' }}
                                    disabled={filterData.country == '' ? true : false}
                                >
                                    {domainListData && domainListData.length > 0 &&
                                        domainListData.map((finalList_unitlist) => (
                                            <OptGroup label={finalList_unitlist.label}>
                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                ))}
                                            </OptGroup>
                                        ))}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-1">
                                <input type='checkbox' checked={checkBox === true ? true : false} onChange={() => {
                                    setType('Group')
                                    setCheckBox(!checkBox)
                                }}></input>
                            </div>
                            <div className="col-md-4">
                                <label><b>Group</b></label>
                            </div>
                            {/* <div className="col-md-2">

                        </div> */}
                            <div className="col-md-1">
                                {/* <input type='checkbox' onChange={(e) => {
                                if (e.target.value) {
                                    setFilterData({
                                        ...filterData,
                                        chartType: 'Consolidated'
                                    })
                                }
                            }}></input> */}
                            </div>
                            <div className="col-md-4">
                                {/* <label><b>Consolidated</b></label> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-6">

                                <label><b>From Date</b></label>
                                <DatePicker
                                    disabled={true}
                                    allowClear={() => {
                                        setFilterData({
                                            ...filterData,
                                            from_date: null,
                                        })
                                    }}
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        setFilterData({
                                            ...filterData,
                                            from_date: dateString,
                                        })

                                    }}

                                />

                            </div>
                            <div className="col-md-6">

                                <label><b>To Date</b></label>

                                <DatePicker
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    // onChange={(date, dateString) => {

                                    //     console.log(dateString, 'dateString');
                                    //     setConsolidatedData({
                                    //         ...consolidatedData,
                                    //         from_date: dateString,
                                    //         to_date: moment(dateString).add(3, 'months')
                                    //     })

                                    // }}
                                    disabled={true}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        if (dateString != undefined) {
                                            setFilterData({
                                                ...filterData,
                                                to_date: dateString,
                                            })
                                        }
                                        else {
                                            setFilterData({
                                                ...filterData,
                                                to_date: null,
                                            })
                                        }


                                    }}

                                />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'BusinessGroup' ? 'blue' : 'black' }}><b>Business Group</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Business Group"
                                    value={filterDataNames.business ? filterDataNames.business : undefined}
                                    defaultValue={filterDataNames.business ? filterDataNames.business : undefined}
                                    key={filterDataNames.business ? filterDataNames.business : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('BusinessGroup')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredEntity = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(data) })
                                        // let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(data) })
                                        // console.log(filteredDivision, 'filteredDivision');
                                        // let uniqueDiv = []
                                        // if (filteredDivision && filteredDivision.length > 0) {
                                        //     uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                        //         return e.div_id;
                                        //     });
                                        // }
                                        // setDivision(uniqueDiv)
                                        // let filteredCategory = _.filter(commonData && commonData.cat_info, { bg_id: Number(data) })
                                        // let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        setfield({
                                            ...field,
                                            legalEntity: filteredEntity
                                        })
                                        setLegalEntityArray(filteredEntity)
                                        setFieldType('BusinessGroup')
                                        setFilterData({
                                            ...filterData,
                                            business: data,
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            business: values.children,
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        // let temp = []
                                        // temp.push({
                                        //     'bg_id': filteredCategory[0].bg_id,
                                        //     'c_id': filteredCategory[0].c_id,
                                        //     'cat_id': filteredCategory[0].cat_id,
                                        //     'cat_name': filteredCategory[0].cat_name,
                                        //     'd_id': filteredCategory[0].d_id,
                                        //     'div_id': filteredCategory[0].div_id,
                                        //     'le_id': filteredCategory[0].le_id,
                                        //     'div_name': filteredDivision[0].div_name
                                        // })
                                        // setCategory(temp)
                                        // setUnit(filteredUnit)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}
                                >
                                    {field && field.business && field.business.length && field.business.map((item, i) => {
                                        return (
                                            <Option key={item.bg_id}>
                                                {item.bg_name}
                                            </Option>
                                        );
                                    })}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    defaultValue={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    key={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    allowClear={false}
                                    size="default"
                                    value={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    placeholder="Enter Legal Entity"
                                    onClick={() => {
                                        setFieldTypeClick('LegalEntity')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        // let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        // let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: data })
                                        // let filteredCategory = _.filter(commonData && commonData.cat_info, { le_id: data })
                                        let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        setLegalEntityArray(filteredLegalEntity)
                                        // // setfield({
                                        // //     ...field,
                                        // //     legalEntity: filteredLegalEntity
                                        // // })
                                        // if (filteredDivision && filteredDivision.length > 0) {
                                        //     let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        //     setUnit(filteredUnit)
                                        // }
                                        // else {
                                        //     let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: data })
                                        //     console.log(filteredUnit, 'filteredUnit555');
                                        //     setUnit(filteredUnit)
                                        // }

                                        // console.log(filteredDivision, 'filteredDivisionLeg');
                                        setFilterData({
                                            ...filterData,
                                            legalentity: data,
                                            leIds: data
                                        })
                                        setFieldType('LegalEntity')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            legalentity: values.children,
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        // let uniqueDiv = []
                                        // if (filteredDivision && filteredDivision.length > 0) {
                                        //     uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                        //         return e.div_id;
                                        //     });
                                        // }
                                        // setDivision(uniqueDiv)
                                        // let temp = []
                                        // temp.push({
                                        //     'bg_id': filteredCategory[0].bg_id,
                                        //     'c_id': filteredCategory[0].c_id,
                                        //     'cat_id': filteredCategory[0].cat_id,
                                        //     'cat_name': filteredCategory[0].cat_name,
                                        //     'd_id': filteredCategory[0].d_id,
                                        //     'div_id': filteredCategory[0].div_id,
                                        //     'le_id': filteredCategory[0].le_id,
                                        //     'div_name': filteredDivision[0].div_name
                                        // })
                                        // setCategory(temp)
                                        // setUnit(filteredUnit)
                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
                                        return (
                                            <Option key={item.le_id}>
                                                {item.le_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Division"
                                    value={filterDataNames.division ? filterDataNames.division : undefined}
                                    defaultValue={filterData.division ? filterData.division : undefined}
                                    key={filterData.division ? filterData.division : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Division')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.find(commonData && commonData.div_infos, { div_id: Number(data) })
                                        let getLEname = _.find(commonData && commonData.le_did_infos, { le_id: filteredDivision.le_id })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(data) })
                                        setFilterData({
                                            ...filterData,
                                            division: data
                                        })

                                        // let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        // setDivisionObj(filteredDivision)
                                        // setLEObj(getLEname)
                                        // setCategory(subData)
                                        setFieldType('Division')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            division: values.children,
                                            unit: '',
                                            category: '',
                                        })
                                        setUnit(filteredUnit)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision.div_name
                                        })
                                        setCategory(temp)

                                        console.log(category, 'catStateeeee');

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {divisionListData && divisionListData.length > 0 &&
                                        divisionListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.div_id}>{subUnitData.div_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }

                                        }
                                        )}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Category"
                                    value={filterDataNames.category ? filterDataNames.category : undefined}
                                    defaultValue={filterData.category ? filterData.category : undefined}
                                    key={filterData.category ? filterData.category : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Category')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            category: data,
                                            unit: '',
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            category: values.children
                                        })
                                        let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
                                        setUnit(subData)
                                        setFieldType('Category')
                                        setGo(false)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {categoryListData && categoryListData.length > 0 &&
                                        categoryListData.map((finalList_unitlist) => {
                                            console.log(finalList_unitlist, 'finalList_unitlist');
                                            if (finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.cat_id}>{`${subUnitData.div_name}-${subUnitData.cat_name}`}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                {localStorage.getItem('isVendor') == "true" ?
                                    <label style={{ color: fieldTypeClick == 'Unit' ? 'blue' : 'black' }}><b>Vendor</b></label> :
                                    <label style={{ color: fieldTypeClick == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>
                                }
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder={localStorage.getItem('isVendor') == "true" ? "Enter Vendor" : "Enter Unit"}
                                    value={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    defaultValue={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    key={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Unit')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            unit: data
                                        })
                                        setFieldType('Unit')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            unit: values.children
                                        })

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {unitListData && unitListData.length > 0 &&
                                        unitListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.u_id}>{subUnitData.u_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={'popupbtncolour text-center ' + localStorage.getItem('currentTheme')}>
                        <Button shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginLeft: '15%', marginTop: '10%' }}
                            onClick={filterChange} icon={<ArrowRightOutlined />}
                            size='default' >
                            Go
                        </Button>
                    </div>
                    {/* <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
                        onClick={() => {
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request":
                                        ["GetTskDistributionStatusChart",
                                            {
                                                "c_ids": [
                                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                ],
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "filter_type": "Group",
                                                "filter_ids": [
                                                    1
                                                ],
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                    commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                ],
                                                "country_domains": [
                                                    {
                                                        "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                        "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                        "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                        "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                    }
                                                ],
                                                "chart_year": year
                                            }
                                        ]
                                }
                            ]
                            getCriticalitychart({
                                payload: payload,
                                paramid: paramid
                            })
                            setOpen(false)

                            setFieldType("")
                            setFilterData({
                                ...filterData,
                                country: '',
                                domain: '',
                                legalentity: '',
                                division: '',
                                unit: '',
                                business: '',
                                category: '',
                                unit: ''
                            })
                        }}
                        size='default' >
                        Reset
                    </Button> */}
                </Drawer>

            </div>
        </div>

    )
    // )


}

export default connect(mapStateToProps, {
    getOverduechart,
    getCommonData,
    getOverDueChartTableData,
    getTimeZoneData,
    getOverDueShowMoreTableData
    // getRiskChartDelayedTableData
})(OverDueChart);