import React, { Fragment } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Tag, Pagination } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getApproveComplianceCertificateFilter, RemarksData, approveCertificate, certificateMailData, viewApproveComplianceDetails, saveCertDocuments, AddAdditionalInformation, getApproveComplianceCertificateTable, viewApproveComplianceStatus, ApprovemappingDetails } from '../../Store/Action/Transcations/CV_ApproveCompliance';
import { getComplianceCertificateFilter, getComplianceCertificateTable, searchCompliance, submitComplianceCertificate, UploadFile, getDocumentTotalSize, Downloadfile, mappingDetails, getLabelList, viewComplianceStatus, viewComplianceDetails } from '../../Store/Action/Transcations/CV-ComplianceCertificate';
// import { MonthlyComplianceFilterReport, MonthlyComplianceTable, MonthlyComplianceExport } from '../../../src/Store/Action/Report/CV_MonthlyComplianceReport';
import { toast } from 'react-toastify';
import ScrollToTop from "react-scroll-to-top";
import Swal from 'sweetalert2';
import { CERTIFICATE_APPROVAL_SUCCESS_RESET } from "./../../Store/types/index"
import { ExclamationCircleTwoTone, ExclamationCircleOutlined, FullscreenOutlined, PlayCircleOutlined, ArrowLeftOutlined, UpOutlined, PlusCircleOutlined, PlusOutlined, DownloadOutlined, CloseOutlined, SearchOutlined, EyeOutlined, FilterOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { Toaster } from '../../Libs/Toaster';
import EncryptDecrypt from '../../Libs/Encrypt&Decrypt'
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import { validationType, validateSelect, onPasteToaster } from './../../Libs/inputValidation';
import ScrollButton from '../Scrollbar';
import { HomeRedirect, CountrySplit } from './../../Libs/country'
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()
const mapStateToProps = (state) => ({
    filtervalues: state.CV_ApproveCompliance.approvecomplianceCertificate.FilterData,
    tabledatas: state.CV_ApproveCompliance.approvecomplianceCertificate.tabledata,
    complianceStatusModals: state.CV_ApproveCompliance.approvecomplianceCertificate.complianceStatusModal,
    detailsModals: state.CV_ApproveCompliance.approvecomplianceCertificate.detailsModal,
    MappingDetail: state.CV_ComplianceCertificate.complianceCertificate.MappingDetails,
    labelLists: state.CV_ComplianceCertificate.complianceCertificate.labelList,
    searchDatas: state.CV_ComplianceCertificate.complianceCertificate.searchData,
    downloadFile: state.CV_ComplianceCertificate.complianceCertificate.downloadFile,
    mailData: state.CV_ApproveCompliance.approvecomplianceCertificate.mailDatas,
    Remarks: state.CV_ApproveCompliance.approvecomplianceCertificate.RemarksDatas,
    Successvalue: state.CV_ApproveCompliance.approvecomplianceCertificate.successvalue,
})

const CV_ApproveCompliances = ({
    getApproveComplianceCertificateFilter,
    MonthlyComplianceFilterReport,
    AddAdditionalInformation,
    saveCertDocuments,
    approveCertificate,
    UploadFile,
    Remarks,
    Successvalue,
    mailData,
    viewApproveComplianceDetails,
    filtervalues,
    certificateMailData,
    Downloadfile,
    MonthlyComplianceTable,
    RemarksData,
    MonthlyComplianceExport,
    complianceStatusModals,
    getApproveComplianceCertificateTable,
    viewApproveComplianceStatus,
    getComplianceCertificateTable,
    submitComplianceCertificate,
    getComplianceCertificateFilter,
    viewComplianceStatus,
    searchDatas,
    detailsModals,
    downloadFile,
    searchCompliance,
    labelLists,
    filerunitdatas,
    MappingDetail,
    viewComplianceDetails,
    ApprovemappingDetails,
    mappingDetails,
    getLabelList,
    tabledatas,
    getDocumentTotalSize
    // tabledatas


}) => {
    console.log(Successvalue, 'Successvalue');
    const historynew = useHistory();
    const [labelPayload, setLabelPayload] = useState({})
    const [fullscreen, setfullscreen] = useState(false);
    const _ = require("lodash");
    const [domainValue, setDomainValue] = useState(false)
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const formValidator = useRef(new SimpleReactValidator());
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();

    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [remarksModal, setRemarksModal] = useState(false)
    const [complianceSave, setComplianceSave] = useState(false)
    const [statusId, setStatusId] = useState("")
    const [entityvalue, setEntityvalue] = useState(sessionArr)
    console.log(entityvalue, 'entityvalue');
    const [dateChange, setDateChange] = useState(false)
    const [payloadData, setPayloadData] = useState([])
    const [tablelistShow, setTableListShow] = useState(false)
    console.log(tablelistShow, 'tablelistShow');
    const [finalpayloadData, setFinalPayloadData] = useState([])
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const [selectValue, setSelectValues] = useState("")
    console.log(selectValue, 'selectValue');
    const [fileName, setFileName] = useState([])
    console.log(fileName, 'fileName');
    const [RemoveDocument, setRemoveDocument] = useState([])
    const [valueOnChange, setValueOnChange] = useState(false)
    const [complaincePayload, setCompliancePayload] = useState([])
    console.log(RemoveDocument, 'RemoveDocument');
    const [AssigneeDetails, setAssigneeDetails] = useState([])
    const [assigneeDocument, setAssigneeDocument] = useState([])
    const [concurrerDocument, setconcurrerDocument] = useState([])
    console.log(concurrerDocument, 'concurrerDocument');
    const [ApproverDocument, setApproverDocument] = useState([])
    console.log(ApproverDocument, 'ApproverDocument');
    const [updatevalue, setupdatevalue] = useState([])
    console.log(assigneeDocument, 'assigneeDocument');
    const [remarksId, setRemarksId] = useState([])
    const [remarksvalue, setRemarksValue] = useState([])
    const [concurrerDetails, setconcurrerDetails] = useState([])
    console.log(concurrerDetails, 'concurrerDetails');
    const [approverDetails, setApproverDetails] = useState([])
    console.log(approverDetails, 'approverDetails');
    const [approveLabel, setApproveLabel] = useState([])
    console.log(approveLabel, 'approveLabel');
    const [approveLabelData, setApproveLabelData] = useState([])
    console.log(approveLabelData, 'approveLabelData');
    const [assigneeLabel, setassigneeLabel] = useState([])
    console.log(assigneeLabel, 'assigneeLabel');
    const [concurrerLabel, setAconcurrerLabel] = useState([])
    console.log(concurrerLabel, 'concurrerLabel');
    const [select, setSelect] = useState([])
    console.log(select, 'select');
    const [vIds, setVIds] = useState([])
    console.log(vIds, 'vIds');
    const [monthData, setMonthData] = useState("")
    const [RemarksDetail, setRemarksDetail] = useState([])
    const [changes, setChanges] = useState(false)
    console.log(changes, 'changes');
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    console.log(filterModalVisible, 'filterModalVisible');
    const [exitCollapse, setExitCollapse] = useState(false);
    const [assignee, setAssignee] = useState({
        assignee: '',
        concurrer: ''

    })
    const [validationState, setValidationState] = useState(false)
    console.log(validationState, 'validationState');
    const authtoken = localStorage.getItem('authToken');
    const [labelCheckBox, setlabelCheckBox] = useState([])
    const location = useLocation();
    const paramid = location.pathname;
    const [invoiceData, setInvoiceData] = useState({
        invoice_no: '',
        invoice_date: ''
    })
    const [entityid, setCurrentEntity] = useState("");
    const [tableshow, setTableShow] = useState(false)
    const [popUpModal, setPopUpModal] = useState(false)
    console.log(tableshow, 'tableshow');
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [complianceStatusModal, setComplianceStatusModal] = useState(false)
    const [detailsModal, setDetailsModal] = useState(false)
    const [searchModal, setsearchModal] = useState(false)
    const [labelSubmit, setLabelSubmit] = useState([])
    const [fileuploadData, setFileUploadData] = useState([])
    console.log(fileuploadData, 'fileuploadData');
    console.log(labelSubmit, 'labelSubmit');
    const [key, setKey] = useState("1")
    const [type, setType] = useState('')
    const [month, setMonth] = useState('')
    const [invoice, setInvoice] = useState(false)

    const [titledata, Settitledata] = useState()

    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [domain, setDomain] = useState([])
    console.log(domain, 'domain5555555');
    const [domainName, setDomainName] = useState(false)
    const [vendor, setVendor] = useState([])
    const [paginationdata, setPaginationdata] = useState([])
    const [count, setCount] = useState([])
    let [index, setindex] = useState(0)
    const [labelModal, setLabelModal] = useState(false)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [checkboxValue, setCheckBoxValue] = useState([0, 1, 6, 7, 8, 9, 28, 30, 33])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submit, setSubmit] = useState(false)
    const [legalstate, Setlegalstate] = useState(false)
    const [tablecount, setTablecount] = useState([])
    const [data, setData] = useState({
        country: '',
        countryName: '',
        legalEntity: '',
        entityName: '',
        domain: '',
        domainname: '',
        vendor: '',
        category: '',
        division: '',
        vendorName: '',
        vendorCode: ''
    })
    console.log(filtervalues, 'filtervalues');
    console.log(data, 'ssssss');
    const [vendorComplianceScore, setVendorComplianceScore] = useState({
        country: '',
        countryName: '',
        division: '',
        divisionName: '',
        typeid: '',
        typeName: '',
        category: '',
        categoryName: '',
        from_month: '',
        to_month: '',
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        legal_entity_id: '',
        domain: '',
        domainName: '',
        vendor_id: '',
        vendor_name: ''
    })
    console.log(tabledatas.certificate_compliances, 'tabledatas.certificate_compliances');
    const [fileData, setFileData] = useState([])
    console.log(fileData, 'fileData');
    const [fileupload, setFileUpload] = useState([])
    console.log(fileupload, 'fileupload');
    const [vendorDetails, setVendorDetails] = useState({
        code: '',
        name: '',
        invoice_no: '',
        invoice_date: '',
        month: '',
        cert_id: '',
        vendorid: '',
        concurrer: ''

    })
    console.log(vendorDetails, 'vendorDetails');
    const sessionArray = sessionParsedValue.login_response
    console.log(sessionArray, 'sessionArray');
    const [certificateFormSubmit, setcertificateFormSubmit] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const Formvalidator = useRef(new SimpleReactValidator());
    const Formvalidator1 = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;
    const [mailDataSate, setMailDataState] = useState([]);
    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        domain: '',
        division: '',
        category: '',
        vendor: '',
    })

    console.log(mailData, 'mailData');
    console.log(mailDataSate, 'mailDataSate');

    const showModal = (row) => {
        console.log(row, 'rowrow');
        const payload2 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceStatusCertifications",
                    {
                        "legal_entity_id": data.legalEntity,
                        "cert_id": row.certid

                    }
                ]
            }
        ]

        viewApproveComplianceStatus({
            payload: payload2,
            paramid: paramid
        })
        setComplianceStatusModal(true)
    };

    useEffect(() => {
        if (Successvalue[0] == 'GetComplianceCertificateApprovalSuccess') {
            if (selectValue == 'Approve' || selectValue == 'Concur') {
                dispatch({
                    type: CERTIFICATE_APPROVAL_SUCCESS_RESET
                })
                setPopUpModal(true)
            } else {
                setPopUpModal(false)
                Toaster.success("Selected certificate has been submitted successfully")
            }
            //Concur
        }
    }, [Successvalue])
    const showModalData = (row) => {
        console.log(row, 'rowrow');
        console.log(row && row.certid, 'row && row.certid');
        const payload2 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceDetailsEnclosed",
                    {
                        "legal_entity_id": data.legalEntity,
                        "cert_id": row && row.certid
                    }
                ]

            }
        ]

        viewApproveComplianceDetails({
            payload: payload2,
            paramid: paramid
        })
        setDetailsModal(true)
    };
    console.log(tabledatas.certificate_compliances, 'tabledatas.certificate_compliances');

    useEffect(() => {
        if (mailData) {
            let temp = []
            if (mailData.mail_data && mailData.mail_data.length > 0) {
                for (let i in mailData.mail_data) {
                    temp.push(mailData.mail_data[i])
                }
            }
            setMailDataState(temp)

        }
    }, [mailData])

    useEffect(() => {
        if (detailsModals) {
            if (detailsModals && detailsModals.details_enclosed && detailsModals.details_enclosed.length > 0) {
                let assignee = _.filter(detailsModals.details_enclosed, { details: "Assignee" })
                setAssigneeDetails(assignee)
                let approver = _.filter(detailsModals.details_enclosed, { details: "Approver" })
                setApproverDetails(approver)
                let concurrer = _.filter(detailsModals.details_enclosed, { details: "Concurrer" })
                setconcurrerDetails(concurrer)

            }
        }
    }, [detailsModals])
    console.log(detailsModals.details_enclosed, 'detailsModals.details_enclosed ');
    useEffect(() => {
        if (approveLabel && approveLabel.length > 0) {
            let temp = []
            approveLabel && approveLabel.length > 0 && approveLabel.map((item, i) => {
                for (let j in approveLabel[i]) {
                    temp.push(approveLabel[i][j])
                }

                console.log(temp, 'temptemptemptemptemp');
            })
            setupdatevalue(temp)
        }
    }, [approveLabel])
    // useEffect(() => {
    //     if ((labelCheckBox && labelCheckBox.length > 0) || (fileupload && fileupload.length > 0)) {
    //         setChanges(true)
    //     }
    // }, [labelCheckBox, fileupload])
    useEffect(() => {
        let obj = []
        if (payloadData && payloadData.length > 0) {
            console.log(payloadData, 'payloadData');
            for (let i in payloadData) {
                let entity = {
                    [payloadData[i].cert_id]: {
                        "is_concur": payloadData[i].is_concur,
                        "status": payloadData[i].status,
                        "remarks": payloadData[i].remarks
                    }
                }
                obj.push(entity)

            }
            if (obj && obj.length > 0) {
                let finalObj = {};
                for (let i in obj) {
                    Object.assign(finalObj, obj[i]);
                }
                console.log(finalObj, 'finalObjfinalObjfinalObj');
                setFinalPayloadData(finalObj)
            }
            else {
                setFinalPayloadData([])
            }

        }
    }, [payloadData])

    useEffect(() => {
        // if (vendorDetails.concurrer == '-1') {
        if (approverDetails && approverDetails.length > 0) {
            let temp = []
            let temp2 = []
            for (let i in approverDetails[0].lbl_info_details
            ) {
                let data = approverDetails[0].lbl_info_details[i]
                let splitdata = data.split('->')
                console.log(splitdata, 'splitdata');
                console.log(data, 'avvvvvv');
                if (splitdata && splitdata.length > 0) {
                    let obj = {
                        [splitdata[0]]: splitdata[1]
                    }
                    temp.push(obj)
                    temp2.push(splitdata[0])
                }

            }
            console.log(temp2, 'temp2');
            setApproveLabel(temp)
            setlabelCheckBox(temp2)

        }
        // }
        // else {
        //     if (concurrerDetails && concurrerDetails.length > 0) {
        //         let temp = []
        //         let temp2 = []
        //         for (let i in concurrerDetails[0].lbl_info_details
        //         ) {
        //             let data = concurrerDetails[0].lbl_info_details[i]
        //             let splitdata = data.split('->')
        //             console.log(splitdata, '9999999');
        //             console.log(data, 'avvvvvv');
        //             if (splitdata && splitdata.length > 0) {
        //                 let obj = {
        //                     [splitdata[0]]: splitdata[1]
        //                 }
        //                 temp.push(obj)
        //                 temp2.push(splitdata[0])
        //             }

        //         }
        //         console.log(temp2, 'temp2');
        //         setApproveLabel(temp)
        //         setlabelCheckBox(temp2)

        //     }
        // }
    }, [approverDetails, concurrerDetails])

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return <span>{record.r_id}</span>
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a style={{ color: record.r_description == remarksvalue ? 'blue' : 'black' }} onClick={(e) => {
                console.log(record.r_description, 'e.target.value');
                let temp = RemarksDetail
                setValueOnChange(!valueOnChange)
                RemarksDetail[remarksId] = record.r_description

                setRemarksDetail(RemarksDetail)
                setRemarksValue(record.r_description)
                setRemarksDetail(temp)
                setRemarksModal(false)

            }}>{record.r_description}</a>,
        },
    ]

    useEffect(() => {
        if (AssigneeDetails && AssigneeDetails.length > 0) {
            let temp = []

            for (let i in AssigneeDetails[0].lbl_info_details) {
                let data = AssigneeDetails[0].lbl_info_details[i]
                let splitdata = data.split('->')
                console.log(splitdata, 'splitdata');
                console.log(data, 'avvvvvv');
                if (splitdata && splitdata.length > 0) {
                    let obj = {
                        label: splitdata[0],
                        value: splitdata[1]
                    }
                    temp.push(obj)
                }

            }
            setassigneeLabel(temp)

        }
    }, [AssigneeDetails])
    useEffect(() => {
        if (concurrerDetails && concurrerDetails.length > 0) {
            let temp = []

            for (let i in concurrerDetails[0].lbl_info_details
            ) {
                let data = concurrerDetails[0].lbl_info_details[i]
                let splitdata = data.split('->')
                console.log(splitdata, 'splitdata');
                console.log(data, 'avvvvvv');
                if (splitdata && splitdata.length > 0) {
                    let obj = {
                        label: splitdata[0],
                        value: splitdata[1]
                    }
                    temp.push(obj)
                }

            }
            setAconcurrerLabel(temp)

        }
    }, [concurrerDetails])
    useEffect(() => {
        if (AssigneeDetails.length > 0) {
            let keys = []
            AssigneeDetails && AssigneeDetails.length > 0 && AssigneeDetails.map((item, i) => {
                let data = item.document_name
                keys = Object.keys(data)
            })
            console.log(keys, 'keyskeyskeys');
            setAssigneeDocument(keys)
        }
    }, [AssigneeDetails])
    useEffect(() => {
        if (concurrerDetails.length > 0) {
            let keys = []
            concurrerDetails && concurrerDetails.length > 0 && concurrerDetails.map((item, i) => {
                let data = item.document_name
                keys = Object.keys(data)
            })
            console.log(keys, 'keyskeyskeys');
            setconcurrerDocument(keys)
        }
    }, [concurrerDetails])
    useEffect(() => {
        if (approverDetails.length > 0) {
            let keys = []
            approverDetails && approverDetails.length > 0 && approverDetails.map((item, i) => {
                let data = item.document_name
                keys = Object.keys(data)
            })
            console.log(keys, 'keyskeyskeys');
            setApproverDocument(keys)
            setFileName(keys)
        }
    }, [approverDetails])
    useEffect(() => {
        if (MappingDetail) {
            if (MappingDetail && MappingDetail.Additional_Details && MappingDetail.Additional_Details.length > 0) {
                let assignee = _.filter(MappingDetail.Additional_Details, { details: "Assignee" })
                setAssigneeDetails(assignee)
                let approver = _.filter(MappingDetail.Additional_Details, { details: "Approver" })
                setApproverDetails(approver)
                let concurrer = _.filter(MappingDetail.Additional_Details, { details: "Concurrer" })
                setconcurrerDetails(concurrer)

            }
        }
    }, [MappingDetail])
    console.log(AssigneeDetails, 'AssigneeDetails');
    console.log(approverDetails, 'approverDetails');
    console.log(concurrerDetails, 'concurrerDetails');
    useEffect(() => {
        if (month != '') {
            if (searchDatas && searchDatas.cmplist && searchDatas.cmplist.length > 0) {
                if (submit == true) {
                    submitData()
                }
                else {
                    setsearchModal(true)
                }

            }
            else {

                Toaster.error("No compliance for the selected month")

            }
        }
    }, [searchDatas && searchDatas.cmplist])
    useEffect(() => {
        if (approveLabel && approveLabel.length > 0) {
            let finalObj = {};
            for (let i in approveLabel) {
                Object.assign(finalObj, approveLabel[i]);
            }
            console.log(finalObj, 'finalObjfinalObjfinalObj');
            setLabelPayload(finalObj)
        }
        else {
            setLabelPayload([])
        }
    }, [approveLabel])
    console.log(labelPayload, 'labelPayload');
    useEffect(() => {
        if (fileupload && fileupload.length > 0) {
            let temp = []
            for (let i in fileupload) {
                let obj = {
                    "file_size": fileupload[i].file_size,
                    "file_name": fileupload[i].file_name,
                    "file_content": null
                }
                temp.push(obj)
            }
            setFileUploadData(temp)
        }
    }, [fileupload])
    const submitData = () => {

        Swal.fire({
            title: 'Are You Sure,You want to submit the certificate?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                const payload1 = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "ComplianceCertificate",
                            {
                                "le_id": data.legalEntity,
                                "v_id": Number(data.vendor),
                                "inv_req": invoice == true ? 1 : 0,
                                "cer_mon": month,
                                "inv_no": invoice == true ? invoiceData.invoice_no : null,
                                "inv_date": invoice == true ? moment(new Date(invoiceData.invoice_date)).format('DD/MM/YYYY') : null,
                                "label_value": "{}",
                                "documents": fileuploadData.length > 0 ? fileuploadData : null,
                                "assignee": assignee.assignee,
                                "concurrer": assignee.concurrer,
                                "mail_data": [

                                ],
                                "c_id": Number(data.country),
                                "d_id": Number(data.domain),
                                "unit_name": data.vendorName
                            }
                        ]

                    }
                ]
                submitComplianceCertificate({
                    payload: payload1,
                    paramid: paramid
                })
                // Swal.fire('Closed.!', 'Closed...', 'success');
            }
        });
    };
    // useEffect(() => {
    //     if (MappingDetail) {
    //         setAssignee({
    //             ...assignee,
    //             assignee: MappingDetail.assignee,
    //             concurrer: MappingDetail.concurrer
    //         })
    //     }
    // }, [MappingDetail])

    const onSearch = () => {
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetVndrWiseCmp",
                    {
                        "cer_mon": month,
                        "v_id": Number(data.vendor),
                        "legal_entity_id": data.legalEntity,
                        "d_id": Number(data.domain)
                    }
                ]

            }
        ]

        searchCompliance({
            payload: payload,
            paramid: paramid
        })
    }

    console.log(data, 'datadatadatadata');
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            let domains = _.filter(filtervalues && filtervalues.domains, { le_id: localStorage.getItem('SelectedEntityid') })
            console.log(domains, 'domains999999');
            setData({
                ...data,
                country: sessionArr[0].c_name,
                legalEntity: localStorage.getItem('SelectedEntityid'),
                // domain: domains && domains.length > 0 && domains[0].d_id
            })
        }
    }, [localStorage.getItem('SelectedEntity')])

    useEffect(() => {
        console.log(filtervalues.domains, 'filtervalues.domains')

        if (data.legalEntity != '' && filtervalues.domains && filtervalues.domains.length == 1) {
            setDomainValue(true)
            // setAddFormSubmit(true)
            setData({
                ...data,
                domain: filtervalues.domains[0].d_id,
                domainname: filtervalues.domains[0].d_name
            })
        } else {
            setData({
                ...data,
                domain: '',
                domainname: ''
            })
        }
    }, [data.legalEntity, filtervalues.domains])
    console.log(ApproverDocument, 'ApproverDocument');
    console.log(fileName, 'fileName');
    const Remove = (index) => {
        console.log(index, 'fileindex');
        let temp2 = []
        let temp = fileupload
        for (let i in fileupload) {
            if (fileupload[i]['file_name'] == index) {
                fileupload.splice(i, 1)
            }
        }
        for (let j in fileuploadData) {
            if (fileuploadData[j]['file_name'] == index)
                fileuploadData.splice(j, 1)
        }
        setFileUploadData([...fileuploadData])
        if (ApproverDocument && ApproverDocument.length > 0 && ApproverDocument.includes(index)) {
            // console.log(temp2,'aishuuuuuuuuuuuuuu');
            temp2.push(index)
            console.log(temp2, 'aishuuuuuuuuuuuuuu');
            setRemoveDocument([...RemoveDocument, ...temp2])
            // for (let j in ApproverDocument) {
            //     if (ApproverDocument[j] == index) {
            //         ApproverDocument.splice(j, 1)
            //     }
            // }
            // setApproverDocument([...ApproverDocument])
        }

        else {

            for (let j in fileName) {
                if (fileName[j] == index) {
                    fileName.splice(j, 1)
                }
            }
            setFileName([...fileName])
        }
        setFileUpload([...fileupload])

    }
    console.log(fileupload, 'fileupload');
    console.log(RemoveDocument, 'RemoveDocument');
    const download = (item) => {
        console.log(item, "itemitem");




        const downloadpayload =

            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "DownloadFileCert",
                        {
                            "le_id": data.legalEntity,
                            "upload_type": ApproverDocument && ApproverDocument.length > 0 && ApproverDocument.includes(item) ? 1 : 2,
                            "u_id": vendorDetails.vendorid,
                            "file_value": item,
                            "c_id": Number(data.country)
                        }
                    ]
                }
            ]
        console.log(downloadpayload, 'downloadpayload');
        Downloadfile({
            payload: downloadpayload,
            paramid: paramid
        });
        // if (item) {

        //     // <a href="path_to_file" download="item"></a>
        //     window.open('https:/' + item);
        // }
    }
    const columns = [


        {
            title: 'Month',
            dataIndex: false,
            key: false,
            render: (text, row) => {
                return (
                    <p style={{ textAlign: 'center' }}>{row.month}</p>
                )
            }
            // width: "50px",
            // ellipsis: true,
            // record && record.comp_name
        },
        {
            title: 'Year',
            dataIndex: false,
            key: false,
            render: (text, row) => {
                return (
                    <p style={{ textAlign: 'center' }}>{row.year}</p>
                )
            },
            width: "55px",
            // ellipsis: true,
            // record && record.comp_name
        },
        {
            title: 'Invoice Number',
            dataIndex: false,
            ellipsis: true,

            render: (text, row) => {
                return (
                    <p style={{ textAlign: 'center' }}>{row.invoice_no ? row.invoice_no : '-'
                    }</p>
                )
            }
        },

        {
            title: 'Compliance Status',
            dataIndex: false,
            ellipsis: true,
            width: '150px',
            // width: '12%',
            render: (text, row) => {
                return (
                    <div className="text-center">
                        <Button type="primary" style={{ background: "#0096FF" }}
                            className='addbutton'
                            shape='round'
                            // style={{ background: "#008000"}}
                            onClick={() => {
                                showModal(row)
                                setMonthData(`${row.month}-${row.year}`)
                                // setType('unit_overdue')
                                // showModal()
                            }}
                        >View</Button></div>
                )
            }
        },
        {
            title: 'Details Enclosed',
            dataIndex: false,
            ellipsis: true,
            // width: '12%',
            render: (text, row) => {
                return (
                    <div className="text-center">
                        <Button type="primary"
                            className='addbutton'
                            shape='round'
                            style={{ background: "#0096FF" }}
                            onClick={() => {
                                setAssigneeDocument([])
                                setAssigneeDetails([])
                                setconcurrerDocument([])
                                setconcurrerDetails([])
                                setApproverDocument([])
                                setFileName([])
                                setRemoveDocument([])
                                setApproverDetails([])
                                //   setType('unit_overdue')
                                showModalData(row)
                                setVendorDetails({
                                    code: row.vendorcode,
                                    name: row.vendorname,
                                    invoice_date: row.invoice_date,
                                    invoice_no: row.invoice_no,
                                    month: `${row.month}-${row.year}`,
                                    cert_id: row.certid,
                                    vendor_id: row.vendorid,
                                    concurrer: row.is_concurr,
                                    vendorid: row.vendorid

                                })

                            }}
                        >View</Button></div>
                )
            }
        },
        {
            title: 'Additional Input',
            dataIndex: false,
            ellipsis: true,
            // width: '12%',
            render: (text, row) => {
                return (
                    <div className="text-center">
                        <PlusCircleOutlined onClick={() => {
                            setAssigneeDocument([])
                            setAssigneeDetails([])
                            setconcurrerDocument([])
                            setconcurrerDetails([])
                            setApproverDocument([])
                            setFileName([])
                            setRemoveDocument([])
                            setApproverDetails([])
                            setChanges(false)
                            onCreate(row)
                            setVendorDetails({
                                code: row.vendorcode,
                                name: row.vendorname,
                                invoice_date: row.invoice_date,
                                invoice_no: row.invoice_no,
                                month: `${row.month}-${row.year}`,
                                cert_id: row.certid,
                                vendor_id: row.vendorid,
                                concurrer: row.is_concurr,
                                vendorid: row.vendorid

                            })

                        }} /></div>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: false,
            ellipsis: true,
            render: (text, row) => {
                return (
                    <p style={{ textAlign: 'center' }}>{row.inv_date ? row.inv_date : '-'
                    }</p>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: false,
            ellipsis: true,
            width: '200px',
            render: (text, row, k) => {
                console.log(row, 'row');
                if (sessionArray && sessionArray.cmpf_user_category == 1) {
                    return (
                        <div className="text-center">
                            <Select style={{ width: '85%' }}
                                defaultValue="All"
                                value={select && select.length > 0 && select.findIndex((item) => item.index == k) != -1 ? select[select.findIndex((item) => item.index == k)].Valname : 'Select'}
                                // value={k == select && select.length > 0 && select[k].index ?select && select.length > 0 && select[k].Valname : 'Select'}
                                onChange={(data, value) => {
                                    console.log(select.findIndex((item) => item.index == k), value, '(e.target.value');
                                    setValueOnChange(!valueOnChange)
                                    setAddFormSubmit1(false)
                                    let temp = []
                                    let tempIds = []
                                    tempIds.push(row.vendorid)
                                    setVIds(tempIds)
                                    setSelectValues(value.children)
                                    setTableListShow(false)
                                    select && select.length > 0 && select.map((item, i) => {
                                        if (item.id === row.certid) {
                                            select.splice(i, 1)
                                            setSelect([...select, ...temp])
                                        }
                                    })
                                    temp.push({
                                        id: row.certid,
                                        value: `${data}`,
                                        Valname: `${value.children}`,
                                        status: `${row.is_concurr}`,
                                        index: k,
                                    })
                                    // select[k] = {
                                    //     id: row.certid,
                                    //     value: `${data}`,
                                    //     Valname: `${value.children}`,
                                    //     status: `${row.is_concurr}`,
                                    //     index: k,
                                    // }
                                    if (data !== 'All') {
                                        setSelect([...select, ...temp])
                                    }
                                }}>
                                <Option value="All">Select</Option>
                                <Option value="3">Approve</Option>
                                <Option value='1'>Rectify Assignee</Option>
                                {row.cert_concur_status == 1 ? <Option value='2'>Rectify Concurrer</Option> : false}
                            </Select></div>

                    )
                }
                else {
                    return (
                        <div className="text-center">
                            <Select defaultValue="All"
                                style={{ width: '85%', marginLeft: '15%', textAlign: 'center' }}
                                // value={k == select[k].index ? select[k].Valname : ''}
                                value={select && select.length > 0 && select.findIndex((item) => item.index == k) != -1 ? select[select.findIndex((item) => item.index == k)].Valname : 'Select'}
                                onChange={(data, value) => {
                                    console.log(data, value, '(e.target.value');
                                    setValueOnChange(!valueOnChange)
                                    let temp = []
                                    setAddFormSubmit1(false)
                                    setTableListShow(false)
                                    setSelectValues(value.children)
                                    select && select.length > 0 && select.map((item, i) => {
                                        if (item.id === row.certid) {
                                            select.splice(i, 1)
                                            setSelect([...select, ...temp])
                                        }
                                    })
                                    temp.push({
                                        id: row.certid,
                                        value: `${data}`,
                                        Valname: `${value.children}`,
                                        status: `${row.is_concurr}`,
                                        index: k
                                    })

                                    setSelect([...select, ...temp])
                                }}
                            >
                                <Option value="All">Select</Option>
                                <Option value="1">Concur</Option>
                                <Option value='2'>Rectify Assignee</Option>

                            </Select></div>
                    )
                }

            }
        },
        {
            title: "Remarks",
            dataIndex: false,
            width: '150px',
            render: (record, rowIndex, k) => {
                console.log(k, record, 'select11111');
                if (sessionArray && sessionArray.cmpf_user_category == 1) {
                    console.log('hai');
                    if (select && select.length > 0) {
                        for (let i in select) {
                            if (select[i].id == record.certid) {
                                return (

                                    <div style={{ display: ' flex', justifyContent: 'center' }}>

                                        <div> <Input name='remarks' className="form-control" placeholder='Enter Remarks' autoComplete={'off'}
                                            defaultValue={RemarksDetail[k]}
                                            id='remarks'
                                            autoFocus={true}
                                            key={RemarksDetail[k]}
                                            hidden={(sessionArray && sessionArray.cmpf_user_category == 1 && select[i].value == '3') || (sessionArray && sessionArray.cmpf_user_category == 3 && select[i].value == '1') || select[i].value == 'All'} style={{ height: "35px", width: '100%' }}
                                            onChange={(e) => {
                                                setValueOnChange(!valueOnChange)
                                                let temp = RemarksDetail
                                                // RemarksDetail && RemarksDetail.length > 0 && RemarksDetail.map((item, i) => {
                                                //     if (i == k) {
                                                //         RemarksDetail.splice(i, 1)
                                                //         setRemarksDetail([...RemarksDetail, ...temp])
                                                //     }
                                                // })
                                                // temp.push({
                                                //     id: record.certid,
                                                //     value: e.target.value,
                                                //     status: record.current_status
                                                // })
                                                RemarksDetail[k] = e.target.value

                                                setRemarksDetail(RemarksDetail)
                                            }}

                                        >
                                        </Input>
                                            {select[i].value !== '3' ? formValidator.current.message(
                                                'remarks',
                                                RemarksDetail[k],
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Remark Required',
                                                    }
                                                }) : ''}
                                        </div>
                                        <PlusCircleOutlined
                                            hidden={(sessionArray && sessionArray.cmpf_user_category == 1 && select[i].value == '3') || (sessionArray && sessionArray.cmpf_user_category == 3 && select[i].value == '1') || select[i].value == 'All'} style={{ alignSelf: 'center', marginLeft: '5px' }}
                                            // style={{ marginLeft: '10px' }}
                                            onClick={() => {
                                                let temp = []
                                                const payload = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "GetRemarksData",
                                                            {}
                                                        ]

                                                    }
                                                ]
                                                setRemarksId(k)
                                                setStatusId(record.current_status)
                                                RemarksData({
                                                    payload: payload,
                                                    paramid: paramid
                                                })
                                                setRemarksModal(true)
                                            }}
                                        />



                                    </div >
                                )

                            }
                        }
                    }
                } else {
                    console.log('hai111');
                    if (select && select.length > 0) {
                        for (let i in select) {
                            if (select[i].id == record.certid) {
                                if (select[i].value == 1) {
                                    return false
                                } else {
                                    return (

                                        <div style={{ display: ' flex', justifyContent: 'center' }}>

                                            <div> <Input name='remarks' className="form-control" placeholder='Enter Remarks' autoComplete={'off'}
                                                defaultValue={RemarksDetail[k]}
                                                id='remarks'
                                                autoFocus={true}
                                                key={RemarksDetail[k]}
                                                hidden={(sessionArray && sessionArray.cmpf_user_category == 1 && select[i].value == '3') || (sessionArray && sessionArray.cmpf_user_category == 3 && select[i].value == '1') || select[i].value == 'All'} style={{ height: "35px", width: '100%' }}
                                                onChange={(e) => {
                                                    setValueOnChange(!valueOnChange)
                                                    let temp = RemarksDetail
                                                    // RemarksDetail && RemarksDetail.length > 0 && RemarksDetail.map((item, i) => {
                                                    //     if (i == k) {
                                                    //         RemarksDetail.splice(i, 1)
                                                    //         setRemarksDetail([...RemarksDetail, ...temp])
                                                    //     }
                                                    // })
                                                    // temp.push({
                                                    //     id: record.certid,
                                                    //     value: e.target.value,
                                                    //     status: record.current_status
                                                    // })
                                                    RemarksDetail[k] = e.target.value

                                                    setRemarksDetail(RemarksDetail)
                                                }}

                                            >
                                            </Input>
                                                {select[i].value !== '3' ? formValidator.current.message(
                                                    'remarks',
                                                    RemarksDetail[k],
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Remark Required',
                                                        }
                                                    }) : ''}
                                            </div>
                                            <PlusCircleOutlined
                                                hidden={(sessionArray && sessionArray.cmpf_user_category == 1 && select[i].value == '3') || (sessionArray && sessionArray.cmpf_user_category == 3 && select[i].value == '1') || select[i].value == 'All'} style={{ alignSelf: 'center', marginLeft: '5px' }}
                                                // style={{ marginLeft: '10px' }}
                                                onClick={() => {
                                                    let temp = []
                                                    const payload = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetRemarksData",
                                                                {}
                                                            ]

                                                        }
                                                    ]
                                                    setRemarksId(k)
                                                    setStatusId(record.current_status)
                                                    RemarksData({
                                                        payload: payload,
                                                        paramid: paramid
                                                    })
                                                    setRemarksModal(true)
                                                }} />
                                        </div >
                                    )
                                }
                            }
                        }
                    }
                }




            },
            dataIndex: false,
            key: false
        },



    ]
    console.log(select, 'select');
    console.log(RemarksDetail, 'RemarksDetail');

    const okdone = () => {
        setPopUpModal(false)
        Toaster.success("Selected certificate has been submitted successfully")
    }


    const searchcolumns = [


        {
            title: 'Act',
            dataIndex: false,
            key: false,
            render: (text, row) => {
                return (
                    <p>{row.act

                    }</p>
                )
            },
            // width: "50px",
            ellipsis: true,
            // record && record.comp_name
        },

        {
            title: 'Compliance Task',
            dataIndex: false,
            ellipsis: true,

            render: (text, row) => {
                return (
                    <p >{row.compliance_task
                    }</p>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: false,
            ellipsis: true,
            render: (text, row) => {
                return (
                    <p>{row.task_status

                    }</p>
                )
            }
        },
        {
            title: 'Due Date',
            dataIndex: false,
            ellipsis: true,
            render: (text, row) => {
                return (
                    <p>{row.due_date

                    }</p>
                )
            }
        },




    ]
    const columns1 = [


        {
            title: 'Act',
            dataIndex: false,
            key: false,
            render: (text, row) => {
                return (
                    <p>{row.stat_mapping

                    }</p>
                )
            }
            // width: "50px",
            // ellipsis: true,
            // record && record.comp_name
        },

        {
            title: 'Compliance Task',
            dataIndex: false,
            // ellipsis: true,

            render: (text, row) => {
                return (
                    <p>{row.c_task
                    }</p>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: false,
            // ellipsis: true,
            render: (text, row) => {
                return (
                    <p>{row.a_status

                    }</p>
                )
            }
        },




    ]
    const labelcolumns = [


        {
            title: <Fragment>
                <input type="checkbox" checked={(labelCheckBox && labelCheckBox.length) == (labelLists && labelLists.label_name && labelLists.label_name.length)} name="allchecked" />
                <span>Select All</span>
            </Fragment>,
            dataIndex: false,
            key: false,
            ellipsis: true,
            width: '15px',
            align: 'center',
            render: (text, record) => {
                return (
                    <Fragment>
                        <input type="checkbox"
                            name={'checked'}
                            checked={labelCheckBox && labelCheckBox.length > 0 && labelCheckBox.includes(record.label_description) ? true : false}
                            onClick={(e) => {
                                let checked = e.target.checked;

                                addSelectedDataValues(
                                    checked,
                                    record
                                );
                            }}
                        />
                    </Fragment>
                )
            }
        },

        {
            title: '',
            dataIndex: false,
            ellipsis: true,
            width: '80px',
            render: (text, row) => {
                return (
                    <p>{row.label_description

                    }</p>
                )
            }
        },





    ]

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content,
        };

        return result;
    }

    const convert_to_base64 = (file, name, size, callback) => {
        var reader = new FileReader();
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            var binary = "";
            reader.onload = function (readerEvt) {
                var bytes = new Uint8Array(readerEvt.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                callback(EncryptDecrypt.encryptdata(binary), name, size);
            }
            reader.readAsArrayBuffer(file);
        } else {
            reader.onload = function (readerEvt) {
                var binaryString = readerEvt.target.result;
                callback(EncryptDecrypt.encryptdata(binaryString), name, size);
            };
            reader.readAsBinaryString(file);
        }
    }

    useEffect(() => {
        if (select && select.length > 0) {
            let temp = []
            for (let i in select) {
                temp.push({
                    cert_id: select[i].id,
                    is_concur: select[i].status,
                    status: select[i].value,
                    remarks: ""
                })
                if (RemarksDetail && RemarksDetail.length > 0) {
                    for (let j in RemarksDetail) {
                        if (select[i].index == j) {
                            temp.splice(i, 1)
                            temp.push({
                                cert_id: select[i].id,
                                is_concur: select[i].status,
                                status: select[i].value,
                                remarks: RemarksDetail[j]
                            })
                        }
                    }

                }
                if (select[i].cert_id === temp[i].cert_id) {
                    temp.splice(i, 1)
                }
                setPayloadData(temp)
                console.log(temp, 'temp5555');
            }



            setPayloadData(temp)

        }
    }, [select, RemarksDetail, valueOnChange])


    const SavepastRecordOnChange = (e) => {
        setChanges(true)
        let arr = []

        if (e.target.name === 'file') {
            var results = [];
            const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
            let files = e.target.files
            let file_max_size = 26214400
            for (var i = 0; i < files.length; i++) {
                var file = files[i];

                var file_name = file.name;
                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
                if (fileRegex.test(f_name) === false) {
                    return;
                }
                let file_size = file.size;
                if (files[i].size > file_max_size) {
                    toast.error("File max limit exceeded")
                    return;
                }
                console.log(file, 'file');
                if (file) {
                    console.log('aishuSneha');
                    convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
                        var fN = name.substring(0, name.lastIndexOf('.'));
                        var fE = name.substring(name.lastIndexOf('.') + 1);
                        var uniqueId = Math.floor(Math.random() * 90000) + 10000;
                        var f_Name = fN + '-' + uniqueId + '.' + fE;

                        var result = uploadFileFormat(size, f_Name, file_content);
                        setFileData(result)
                        results.push(result);


                        let temp1 = []
                        let temp = []
                        if (results && results.length > 0) {
                            for (let i in results) {
                                temp1.push(results[i])
                            }
                            setFileUpload([...fileupload, ...temp1])
                        }
                        arr = results && results.map((item) => {
                            return item.file_name
                        })
                        setFileName([...fileName, ...arr])
                        // else {
                        //     for (let i in results) {
                        //         temp.push(results[i])
                        //     }
                        //     setFileUpload([...temp])
                        // }

                        const payload =
                            [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "DocumentsTotalSize",
                                        {
                                            "legal_entity_id": data && data.legalEntity,
                                            "cert_id": Number(vendorDetails.cert_id)
                                        }
                                    ]
                                }
                            ]
                        const filePayload1 = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "CertDocumentsTemp",
                                    {
                                        "le_id": data && data.legalEntity,
                                        "unit_id": Number(vendorDetails.vendorid),
                                        "file_info": results,
                                    }
                                ]
                            }
                        ]
                        console.log(payload, 'payload5555');
                        getDocumentTotalSize({
                            payload: payload,
                            paramid: paramid,
                            filePayload: filePayload1
                        })



                        // console.log(filePayload, 'filePayload');
                        // UploadFile({
                        //     payload: filePayload,
                        //     paramid: paramid
                        // })
                    });
                }
            }

        }
        React.forwardRef(({ onClick, ...rest }, ref) => {
            return (
                <>
                    <div className="form-check pb-5" style={{ backgroundColor: '' }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ height: '20px', width: '20px' }}
                            ref={ref}
                            onClick={onClick}
                            {...rest}
                        />
                        <label className="form-check-label" id="booty-check" />
                    </div>
                </>
            )
        })
    }
    const addSelectedDataValues = (checked, record) => {
        console.log(record, 'record');
        var ele = document.getElementsByName('allchecked');

        let array
        let tempArray = labelCheckBox

        console.log(labelCheckBox, 'labelCheckBox');
        if (checked === true) {

            let temp = []
            tempArray.push(record.label_description)
        } else {
            for (let i in tempArray) {
                if (tempArray[i] === record.label_description) {
                    tempArray.splice(i, 1)
                }

                for (var j = 0; j < ele.length; j++) {
                    if (ele[j].type === 'checkbox')
                        ele[j].checked = false;
                }

            }

        }
        setlabelCheckBox([...tempArray])


    }

    const addAllSelectedDataValues = (checked) => {

        // console.log(data,'data');

        var ele = document.getElementsByName('checked');

        if (checked === true) {

            let data = labelLists && labelLists.label_name && labelLists.label_name.length > 0 && labelLists.label_name.map((item, i) => {
                return item.label_description
            })
            console.log(data, 'data');
            setlabelCheckBox(data)
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {

            setlabelCheckBox([])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }
    console.log(labelCheckBox, 'labelCheckBox');



    useEffect(() => {
        if (data && data.legalEntity != '') {
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetApproveComplianceCertificationsFilters",
                        {
                            "country_id": Number(data.country),
                            "legal_entity_id": data.legalEntity
                        }
                    ]
                }
            ]

            getApproveComplianceCertificateFilter({
                payload: payload2,
                paramid: paramid
            })


        }

    }, [data.legalEntity])
    const handleOk = () => {
        setIsModalOpen(true);
        if (validator.current.allValid()) {
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMonthlyComplianceReport",
                        {
                            "country_id": Number(data.country),
                            "legal_entity_id": entityid,
                            "domain_id": vendorComplianceScore.domain ? Number(vendorComplianceScore.domain) : 0,
                            "division_id": vendorComplianceScore.division ? Number(vendorComplianceScore.division) : 0,
                            "category_id": vendorComplianceScore.category ? Number(vendorComplianceScore.category) : 0,
                            "unit_id": vendorComplianceScore.vendor_id ? Number(vendorComplianceScore.vendor_id) : 0,
                            "from_date": moment(vendorComplianceScore.from_date).format("MMMM-YYYY"),
                            "to_date": moment(vendorComplianceScore.from_date).format("MMMM-YYYY"),
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            MonthlyComplianceExport({
                payload: payload2,
                paramid: paramid
            })
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setComplianceStatusModal(false)
        setDetailsModal(false)
        setLabelModal(false)
        setsearchModal(false)
        setRemarksModal(false)
        setlabelCheckBox([])
        setPopUpModal(false)
    };
    useEffect(() => {
        if (tabledatas.certificate_compliances) {
            if (tabledatas.certificate_compliances.length > 0) {
                setTableShow(true)
            }
            else {
                if (tablelistShow == true) {
                    Toaster.error("There is no Compliance Certification Available")
                }
            }
        }
    }, [tabledatas.certificate_compliances])

    const onshow = () => {

        setAddFormSubmit(true)
        console.log(validator.current.allValid(), AddFormSubmit, 'pppppppppppp');
        if (validator.current.allValid()) {
            setTableShow(true)
            setTableListShow(true)
            setAddFormSubmit1(false)
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            // setKey(0)
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceCertifications",
                        {
                            "unit_id": data.vendor != '' ? Number(data.vendor) : null,
                            "legal_entity_id": data.legalEntity,
                            "d_id": Number(data.domain),
                            "division_id": data.division != '' ? Number(data.division) : null,
                            "category_id": data.category != '' ? Number(data.category) : null,
                        }
                    ]
                }
            ]
            getApproveComplianceCertificateTable({
                payload: payload1,
                paramid: paramid
            })

        }
        // else {
        //     console.log('testtttt');
        // }
    }




    const onSubmitData = () => {
        // setPopUpModal(true)
        setTableListShow(false)
        if (select && select.length > 0) {

            setAddFormSubmit1(true)

            let datas = JSON.stringify(finalpayloadData)



            let tempMails = []
            if (mailDataSate && mailDataSate.length > 0) {
                for (let i in mailDataSate) {
                    tempMails.push(mailDataSate[i])
                }
            }
            console.log(tempMails, 'tempMails');
            if (formValidator.current.allValid()) {
                console.log(datas, 'dats8888');
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetCertificateMailData",
                            {
                                "v_ids": vIds,
                                "legal_entity_id": data.legalEntity
                            }
                        ]


                    }
                ]

                certificateMailData({
                    payload: payload,
                    paramid: paramid
                })
                const payload2 = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetComplianceCertificateApproval",
                            {
                                "certificate_status": datas,
                                "legal_entity_id": data && data.legalEntity,
                                "country_id": Number(data.country),
                                "domain_id": Number(data.domain),
                                "mail_data": tempMails
                            }
                        ]


                    }
                ]
                const payload1 = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetComplianceCertifications",
                            {
                                "unit_id": data.vendor != '' ? Number(data.vendor) : null,
                                "legal_entity_id": data.legalEntity,
                                "d_id": Number(data.domain),
                                "division_id": data.division != '' ? Number(data.division) : null,
                                "category_id": data.category != '' ? Number(data.category) : null,
                            }
                        ]
                    }
                ]
                getApproveComplianceCertificateTable({
                    payload: payload1,
                    paramid: paramid
                })
                console.log(payload2, 'AVVVV');
                approveCertificate({
                    payload: payload2,
                    paramid: paramid,
                    payload1: payload1,
                    setSelect: setSelect,
                    setTableShow: setTableShow,
                    setPopUpModal: setPopUpModal
                })

                console.log('aishuuuuuuu');
                // setKey(0)

                setAddFormSubmit1(false)
            }
        }
        else {
            Toaster.error("Approve/Rectify atleast one Compliance")
        }


    }

    const onSubmit = () => {

        let datas = JSON.stringify(labelPayload)
        let data1 = JSON.stringify(datas)
        console.log(data1, 'datas12333');
        if (changes == true || RemoveDocument.length > 0) {
            if (validationState === true || fileuploadData.length > 0 || RemoveDocument.length > 0) {
                if (Formvalidator1.current.allValid()) {
                    console.log('Snehaaaa');
                    const filepayload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveCertDocuments",
                                {
                                    "le_id": data.legalEntity,
                                    "c_id": Number(data.country),
                                    "unit_id": Number(vendorDetails.vendorid),
                                    "file_info": fileupload
                                }
                            ]
                        }
                    ]
                    const payloadchanges = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "UpdateCertificateApproverDetails",
                                {
                                    "cert_id": vendorDetails.cert_id,
                                    "certificate_status": approveLabel && approveLabel.length > 0 ? datas : '{}',
                                    "legal_entity_id": data.legalEntity,
                                    "vendorid": vendorDetails.vendor_id,
                                    "documents": fileuploadData.length > 0 ? fileuploadData : [],
                                    "removed_documents": RemoveDocument
                                }
                            ]


                        }
                    ]
                    const payload1 = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetComplianceCertifications",
                                {
                                    "unit_id": data.vendor != '' ? Number(data.vendor) : null,
                                    "legal_entity_id": data.legalEntity,
                                    "d_id": Number(data.domain),
                                    "division_id": data.division != '' ? Number(data.division) : null,
                                    "category_id": data.category != '' ? Number(data.category) : null,
                                }
                            ]
                        }
                    ]
                    // getApproveComplianceCertificateTable({
                    //     payload: payload1,
                    //     paramid: paramid
                    // })
                    if (fileupload.length > 0) {
                        saveCertDocuments({
                            payload: filepayload,
                            paramid: paramid,
                            payloadchanges: payloadchanges,
                            payload1: payload1,
                            setComplianceSave: setComplianceSave
                        })
                    } else {
                        AddAdditionalInformation({
                            payload: payloadchanges,
                            paramid: paramid,
                            payload1: payload1,
                            setComplianceSave: setComplianceSave
                        })
                    }


                }
            }
            else {
                toast.error('Please provide valid additional info')
            }
        }
        else {
            toast.error('No changes observed')
        }

    }
    const onCreate = (row) => {



        setComplianceSave(true)
        console.log('aishuuuuuuu');
        // setKey(0)
        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceAdditionalInput",
                    {
                        "cert_id": row.certid,
                        "legal_entity_id": data.legalEntity
                    }
                ]
            }
        ]
        ApprovemappingDetails({
            payload: payload1,
            paramid: paramid
        })


    }
    const onChange = (key) => {
        console.log(key);
    };

    useEffect(() => {
        validator.current.showMessages()
        Formvalidator.current.showMessages()
        Formvalidator1.current.showMessages()
        formValidator.current.showMessages()
    }, [])

    useEffect(() => {
        if (approveLabel && approveLabel.length > 0) {
            for (let i in approveLabel) {
                for (let j in approveLabel && approveLabel[i]) {
                    if (approveLabel[i][j] != '') {
                        setValidationState(true)
                        setcertificateFormSubmit(false)
                    }
                    else {
                        setValidationState(false)
                        setcertificateFormSubmit(true)
                    }
                }
            }
        }

    }, [approveLabel])

    useEffect(() => {
        if (vendorComplianceScore.legel_entity == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [vendorComplianceScore.legel_entity])

    useEffect(() => {
        if (localStorage.getItem("SelectedEntity") !== "All Legal Entity") {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setData({
                ...data,
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : '',
                legalEntity: localStorage.getItem('SelectedEntityid'),
                entityName: localStorage.getItem('SelectedEntity'),
            })
        }
    }, [localStorage.getItem("SelectedEntity") !== "All Legal Entity"])

    useEffect(() => {
        if (isAuth) {
            if (entityid != '') {
                if (legalstate === true) {
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetMonthlyComplianceReportFilters",
                                {
                                    "country_id": Number(data.country),
                                    "legal_entity_id": entityid
                                }
                            ]
                        }
                    ]
                    if (entityid != 'null') {

                        MonthlyComplianceFilterReport({
                            payload: payload,
                            paramid: paramid
                        })
                    }
                    Setlegalstate(false)
                }
            }
        }

    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(vendorComplianceScore.le_id)
        }
    }, [entityid])
    useEffect(() => {
        if (data && data.legalEntity != "") {
            if (filtervalues && filtervalues.domains && filtervalues.domains.length > 0) {
                let domainData = _.filter(filtervalues && filtervalues.domains, { le_id: data.legalEntity })
                setDomain(domainData)
            }

        }
    }, [filtervalues && filtervalues.domains, data && data.legalEntity])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setFilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            setVendorComplianceScore({
                ...vendorComplianceScore,
                le_id: localStorage.getItem('SelectedEntityid')
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (filtervalues) {
            setCategory(filtervalues && filtervalues.category_list)
            setDivision(filtervalues && filtervalues.division_list)
            setDomain(filtervalues && filtervalues.domains)
            setVendor(filtervalues && filtervalues.unit_list)
        }
    }, [filtervalues])
    console.log(domain, 'domaindomaindomain');
    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
        window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([1, 2, 3, 4, 5, 6, 11])
        }
    }

    // useEffect(() => {
    //     if (approveLabel && approveLabel.length > 0) {
    //         let temp = []
    //         for (let i in approveLabel) {
    //             for (let j in approveLabel[i]) {
    //                 temp.push(j)
    //             }
    //         }
    //         setlabelCheckBox([...labelCheckBox, ...temp])
    //     }
    // }, [approveLabel])



    // useEffect(() => {
    //     if (tabledatas) {
    //         setTableData(tabledatas && tabledatas.monthly_compliances)
    //     }
    //     if (tabledatas.total_count) {
    //         setCount(tabledata && tabledatas.total_count)
    //     }
    // }, [tabledatas, tabledatas.monthly_compliances, tabledatas.total_count, count])


    // useEffect(() => {
    //     if (pageState == true) {
    //         const payload1 = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     'GetCriticalityData', {
    //                         "country_id": vendorComplianceScore.country ? Number(vendorComplianceScore.country) : 1,
    //                         "legal_entity_id": entityid,
    //                         "domain_id": vendorComplianceScore.domain ? Number(vendorComplianceScore.domain) : 0,
    //                         "division_id": vendorComplianceScore.division ? Number(vendorComplianceScore.division) : 0,
    //                         "category_id": vendorComplianceScore.category ? Number(vendorComplianceScore.category) : 0,
    //                         "unit_id": vendorComplianceScore.vendor_id ? Number(vendorComplianceScore.vendor_id) : 0,
    //                         "from_date": moment(vendorComplianceScore.from_date).format("MMMM-YYYY"),
    //                         "to_date": moment(vendorComplianceScore.from_date).format("MMMM-YYYY"),
    //                         "csv": true,
    //                         "from_count": 0,
    //                         "page_count": 0,
    //                         "list_check": null,
    //                         'csv': false,
    //                         'f_count': Math.max(((current - 1) * pageSize), 0) + 1,
    //                         't_count': current != 0 ? current * pageSize : pageSize,
    //                         'count_qry': true,
    //                         'list_check': null
    //                     }
    //                 ]
    //             }
    //         ]
    //         MonthlyComplianceTable({
    //             payload: payload1,
    //             paramid: paramid
    //         })
    //     }
    // }, [current, pageSize, pageState])
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    const filterHandle = () => {
        setFilterTaskAcc(false);
        setFilterModalVisible(true);
    };

    const getCategory = (value) => {
        let getCat = _.filter(filtervalues.category_list, { le_id: data.legalEntity, div_id: parseInt(value) })
        setCategory(getCat)
    }
    const getVendor = (value) => {
        let getVendor = _.filter(filtervalues.unit_list, { category_id: parseInt(value), division_id: parseInt(data.division) })
        setVendor(getVendor)
    }
    return (

        <Fragment>


            <div id="page-wrapper" className="page-wrapper">
                {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                {document.body.scrollHeight > document.body.clientHeight ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''} */}
                <div className='back-to-top'>
                    <ScrollButton />
                </div>
                <div className="page-titles pb-1 pt-1 page-title-sticky">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Transaction</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Approve Compliance Certificate</span>
                                    </li>

                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-4 col-md-6 text-end">
                            {complianceSave == true ?
                                <Button type="primary" shape="round" className='addbutton' style={{ marginRight: '2%' }}
                                    icon={<ArrowLeftOutlined />} size='default' onClick={() => { setComplianceSave(false) }}>
                                    Back
                                </Button> : false}
                            <Button
                                type="primary"
                                shape="round"
                                style={{
                                    marginRight: "10px",
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button>
                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black full-mode fullscreenradius" >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                {complianceSave == false ?
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">

                                <div className="card">
                                    {/* <div className="card-body approveCard" > */}
                                    <div className={localStorage.getItem('currentTheme')} >
                                        <Collapse className='report-collapse' accordion defaultActiveKey={["1"]}
                                            style={{ display: exitCollapse ? "none" : "block" }}>
                                            <Panel header="Approve Compliance Certificate" key="1"
                                            // extra={tableshow == true ? genextra() : false}
                                            >
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Country :</b><span style={{ color: "red" }}> *</span></label><br />
                                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                    <div className="form-group">
                                                                        <Select
                                                                            placeholder='Enter Country'
                                                                            allowClear={true}
                                                                            size="default"
                                                                            onPaste={onPasteToaster}
                                                                            searchValue={selectSearchValue.country}
                                                                            onSearch={(value) => {
                                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                                setSelectSearchValue({
                                                                                    ...selectSearchValue,
                                                                                    country: result
                                                                                });
                                                                            }}
                                                                            onChange={(value, datas) => {
                                                                                setDomain([])
                                                                                setDomainValue(false)

                                                                                if (value !== undefined) {
                                                                                    console.log(value, 'valuevaluevalue');
                                                                                    let assignee = _.filter(sessionArr, { c_id: Number(value) })
                                                                                    console.log(assignee, 'assigneeassignee');
                                                                                    setEntityvalue(assignee)
                                                                                    setData({
                                                                                        ...data,
                                                                                        country: value,
                                                                                        countryName: datas.children,
                                                                                        domain: '',
                                                                                        domainname: '',
                                                                                        legalEntity: '',
                                                                                    })
                                                                                }

                                                                                else {
                                                                                    setData({
                                                                                        ...data,
                                                                                        country: "",
                                                                                        countryName: '',
                                                                                        legalEntity: '',
                                                                                        category: '',
                                                                                        division: '',
                                                                                        domain: '',
                                                                                        domainname: '',
                                                                                        vendor: '',
                                                                                        vendorName: '',
                                                                                        vendorCode: ''
                                                                                    })
                                                                                }
                                                                            }}

                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            value={data.country || undefined}
                                                                            style={{ width: '100%', marginTop: '5px' }}

                                                                        // value={unitdata.countryName || undefined}
                                                                        >
                                                                            {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.c_id}>
                                                                                        {item.c_name}
                                                                                    </Option>
                                                                                );
                                                                            })}


                                                                        </Select>
                                                                        {validator.current.message(
                                                                            'country',
                                                                            data.country,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Country Required',

                                                                                }
                                                                            })}
                                                                    </div> :
                                                                    <p>{data.countryName}</p>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Legal Entity :</b><span style={{ color: "red" }}> *</span></label><br />

                                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                                    <div className="form-group">

                                                                        <Select
                                                                            disabled={data.country == '' ? true : false}
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Enter Legal Entity"
                                                                            onPaste={onPasteToaster}
                                                                            searchValue={selectSearchValue.legalEntity}
                                                                            onSearch={(value) => {
                                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                                setSelectSearchValue({
                                                                                    ...selectSearchValue,
                                                                                    legalEntity: result
                                                                                });
                                                                            }}

                                                                            onChange={(value, datas) => {
                                                                                setDomain([])
                                                                                setDomainValue(false)

                                                                                if (value !== undefined) {
                                                                                    console.log(datas, 'valuevalue')
                                                                                    setData({
                                                                                        ...data,
                                                                                        legalEntity: value,
                                                                                        domain: '',
                                                                                        domainname: '',
                                                                                    })


                                                                                }
                                                                                else {
                                                                                    setData({
                                                                                        ...data,
                                                                                        legalEntity: '',
                                                                                        category: '',
                                                                                        division: '',
                                                                                        domain: '',
                                                                                        domainname: '',
                                                                                        vendor: '',
                                                                                        vendorName: '',
                                                                                        vendorCode: ''
                                                                                    })
                                                                                }
                                                                            }}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            value={data.legalEntity || undefined}
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            //value={Settings.le_id}
                                                                            style={{ width: '100%', marginTop: '5px' }}

                                                                        >

                                                                            {entityvalue && entityvalue.length > 0 && entityvalue.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.le_id}>
                                                                                        {item.le_name}
                                                                                    </Option>
                                                                                );
                                                                            })}


                                                                        </Select>

                                                                        {validator.current.message(
                                                                            'legal Entity',
                                                                            data.legalEntity,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Legal Entity Required',

                                                                                }
                                                                            })}
                                                                    </div> :
                                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                                }


                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Domain :</b><span style={{ color: "red" }}> *</span></label><br />
                                                                {domainValue == false || (domain && domain.length > 1) ?
                                                                    <div className="form-group">
                                                                        <Select
                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                            disabled={data.legalEntity == '' ? true : false}
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Enter Domain Name"
                                                                            onPaste={onPasteToaster}
                                                                            searchValue={selectSearchValue.domain}
                                                                            onSearch={(value) => {
                                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                                setSelectSearchValue({
                                                                                    ...selectSearchValue,
                                                                                    domain: result
                                                                                });
                                                                            }}
                                                                            onChange={(value, datas) => {
                                                                                if (value !== undefined) {
                                                                                    setData({
                                                                                        ...data,
                                                                                        domain: value,
                                                                                        domainname: datas.children
                                                                                    })
                                                                                } else {
                                                                                    setData({
                                                                                        ...data,
                                                                                        domain: '',
                                                                                        domainname: ''
                                                                                    })
                                                                                }
                                                                            }}
                                                                            value={data.domainname || undefined}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '100%', marginTop: "5px" }}
                                                                        >
                                                                            {domain && domain.length > 0 && domain.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.d_id}>
                                                                                        {item.d_name}
                                                                                    </Option>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                        {validator.current.message(
                                                                            'domain',
                                                                            data.domainname,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Domain Required',
                                                                                }
                                                                            })}

                                                                    </div> :
                                                                    <> <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain && domain.length > 0 ? domain[0].d_name : '-'}</p>
                                                                        {validator.current.message(
                                                                            'domain',
                                                                            data.domainname,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Domain Required',

                                                                                }
                                                                            })}
                                                                    </>
                                                                }

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                                    <div className='row'>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Division :</b></label><br />

                                                                <Select
                                                                    allowClear={true}
                                                                    disabled={data.legalEntity == '' ? true : false}
                                                                    size="default"
                                                                    placeholder="Enter Division Name"
                                                                    value={data.division || undefined}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.division}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            division: result
                                                                        });
                                                                    }}
                                                                    onChange={(value, datas) => {
                                                                        if (value !== undefined) {
                                                                            getCategory(value)
                                                                            setData({
                                                                                ...data,
                                                                                division: value,
                                                                                category: '',
                                                                                vendor: '',
                                                                            })
                                                                        }
                                                                        else {
                                                                            // setCategory(filtervalues.category_list)
                                                                            setData({
                                                                                ...data,
                                                                                division: '',
                                                                                category: ''
                                                                            })
                                                                        }
                                                                        if (value !== undefined) {
                                                                            let catData = _.filter(filtervalues && filtervalues.category_list, { div_id: Number(value) })
                                                                            setCategory(catData)
                                                                            let vendorData = _.filter(filtervalues && filtervalues.unit_list, { division_id: Number(value) })
                                                                            console.log(vendorData, 'vendorData');
                                                                            setVendor(vendorData)
                                                                        } else {
                                                                            // console.log(filterValue && filterValue.units_list, 'filterValue && filterValue.units_list');
                                                                            setCategory(filtervalues && filtervalues.category_list)
                                                                            setVendor(filtervalues && filtervalues.unit_list
                                                                            )
                                                                        }
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: "5px" }}
                                                                >
                                                                    {division && division.length > 0 && division.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.div_id
                                                                            }>
                                                                                {item.div_name
                                                                                }
                                                                            </Option>
                                                                        );
                                                                    })}

                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Category :</b></label><br />

                                                                <Select
                                                                    disabled={data.division == '' ? true : false}
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Category Name"
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.category}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            category: result
                                                                        });
                                                                    }}
                                                                    onChange={(value, datas) => {
                                                                        if (value !== undefined) {
                                                                            // setVendor([])
                                                                            getVendor(value)

                                                                            setData({
                                                                                ...data,
                                                                                category: value
                                                                            })
                                                                        }
                                                                        else {
                                                                            // setVendor(filtervalues.unit_list)
                                                                            setData({
                                                                                ...data,
                                                                                category: ''
                                                                            })
                                                                        }
                                                                        if (value !== undefined) {

                                                                            let vendorData = _.filter(filtervalues && filtervalues.unit_list, { category_id: Number(value) })
                                                                            console.log(vendorData, 'vendorData');
                                                                            setVendor(vendorData)
                                                                        } else {
                                                                            let vendorData = _.filter(filtervalues && filtervalues.unit_list, { division_id: Number(data.division) })
                                                                            console.log(vendorData, 'vendorData');
                                                                            setVendor(vendorData)
                                                                        }

                                                                    }}
                                                                    value={data.category || undefined}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: "5px" }}
                                                                >
                                                                    {category && category.length > 0 && category.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.cat_id}>
                                                                                {item.cat_name}
                                                                            </Option>
                                                                        );
                                                                    })}

                                                                </Select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Vendor Name :</b></label><br />

                                                                <Select
                                                                    allowClear={true}
                                                                    disabled={data.legalEntity == '' ? true : false}
                                                                    size="default"
                                                                    placeholder="Enter Vendor"
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.vendor}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            vendor: result
                                                                        });
                                                                    }}
                                                                    onChange={(value, datas) => {
                                                                        if (value !== undefined) {
                                                                            setTableShow(false)
                                                                            console.log(datas, 'datas');
                                                                            let splitName = datas.children.split('-')
                                                                            console.log(splitName, 'splitName');
                                                                            setData({
                                                                                ...data,
                                                                                vendor: value,
                                                                                vendorName: datas.children,
                                                                                vendorCode: splitName && splitName.length > 0 && splitName[0]
                                                                            })
                                                                        }
                                                                        else {
                                                                            setTableShow(false)

                                                                            setData({
                                                                                ...data,
                                                                                vendor: '',
                                                                                vendorName: '',
                                                                                vendorCode: ''
                                                                            })
                                                                        }
                                                                    }}
                                                                    value={data.vendor || undefined}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: "5px" }}
                                                                >
                                                                    {vendor && vendor.length > 0 && vendor.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.unit_id
                                                                            }>
                                                                                {`${item.unit_code}-${item.unit_name}`
                                                                                }
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='col-md-12 mb-1'>
                                                    <div className='row'>
                                                        <div className='col-md-4'></div>
                                                        <div className='col-md-4 text-center popupbtncolour'>
                                                            {/* <Button type="primary" shape="round" className='addbutton' style={{ marginRight: "10px" }}
                                                    onClick={onCreate}
                                                >

                                                    Create Certificate
                                                </Button> */}
                                                            <Button shape="round" className='addbutton' style={{ marginTop: "7px", marginRight: '11%' }}
                                                                icon={<EyeOutlined />} onClick={onshow}
                                                            >
                                                                Show
                                                            </Button>

                                                        </div>
                                                        <div className='col-md-4'></div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>



                                    </div>
                                    {/* </div> */}

                                    {tableshow == true ? <div className="col-md-12" style={{ marginTop: "1px", padding: '10px' }}>
                                        <span>{tabledatas.certificate_compliances && tabledatas.certificate_compliances.length > 0 ? `${tabledatas.certificate_compliances && tabledatas.certificate_compliances.length > 0 && tabledatas.certificate_compliances[0].vendorcode}-${tabledatas.certificate_compliances && tabledatas.certificate_compliances.length > 0 && tabledatas.certificate_compliances[0].vendorname}` : ''}</span>
                                        <div className='service-provider-table'>
                                            <Table
                                                size={'small'}
                                                // className='userprivclass'
                                                // id='service-user-priv'
                                                className={localStorage.getItem("currentTheme")}
                                                columns={columns}
                                                dataSource={tabledatas.certificate_compliances
                                                }
                                                // title={() => {
                                                //     return tabledatas.certificate_compliances ? tabledatas.certificate_compliances && tabledatas.certificate_compliances.length > 0 && tabledatas.certificate_compliances[0].vendorcode : ''
                                                // }}
                                                bordered
                                                // fixedHeader={dataTableProperties.fixedHeader}
                                                // scroll={{ x: 1500, y: 1000 }}
                                                pagination={false} />
                                        </div>
                                        {tabledatas && tabledatas.certificate_compliances && tabledatas.certificate_compliances.length > 0 ?
                                            <div className="text-center">
                                                <Button type="primary" shape="round" className='addbutton' style={{ marginTop: '1%' }}
                                                    icon={<PlayCircleOutlined />} size='default'
                                                    onClick={onSubmitData}

                                                >
                                                    Submit
                                                </Button></div> : ''}
                                    </div> : false}

                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="container-fluid pt-1">
                        {/* <div className="row">
                            <div className="col-12"> */}




                        <div className="card">
                            <div className="card-body approveCard"  >
                                <div className={"col-12 " + localStorage.getItem('currentTheme')}>
                                    <Collapse accordion defaultActiveKey={["1"]}>
                                        <Panel header="Assignee Details" key="1"
                                        // extra={tableshow == true ? genextra() : false}
                                        >

                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label htmlFor=""><b>Vendor Code:</b></label><label>{vendorDetails.code}</label>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor=""><b>Vendor Name:</b></label><label>{vendorDetails.name}</label>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor=""><b>Invoice Number:</b></label><label>{vendorDetails.invoice_no}</label>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor=""><b>Invoice Date:</b></label> <label>{vendorDetails.invoice_date}</label>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor=""><b>Certificate process for the month of:</b></label> <label>{vendorDetails.month}</label>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        {assigneeDocument && assigneeDocument.length > 0 ?
                                                            <Card size='small' className='ApproveCards' title='Assignee_Documents' headStyle={{ fontWeight: 'bold', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ height: '150px' }}>
                                                                <div className="row" style={{ height: '100px', width: '105%', overflowY: assigneeDocument && assigneeDocument.length > 2 ? "scroll" : "hidden" }}>


                                                                    {/* <thead>
                                            <tr>

                                                <th >Concurrer_Documents</th>
                                            </tr>
                                        </thead> */}

                                                                    {assigneeDocument && assigneeDocument.length > 0 && assigneeDocument.map((item) => {
                                                                        return (
                                                                            <>
                                                                                <div className="row" style={{ fontWeight: 'bold', height: '35px', width: '105%' }}>
                                                                                    <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                                        icon={<DownloadOutlined />} size='small'
                                                                                        onClick={(e) => {
                                                                                            const downloadpayload =

                                                                                                [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "DownloadFileCert",
                                                                                                            {
                                                                                                                "le_id": data.legalEntity,
                                                                                                                "upload_type": 1,
                                                                                                                "u_id": vendorDetails.vendorid,
                                                                                                                "file_value": item,
                                                                                                                "c_id": Number(data.country)
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                ]
                                                                                            Downloadfile({
                                                                                                payload: downloadpayload,
                                                                                                paramid: paramid
                                                                                            });
                                                                                        }}
                                                                                    >

                                                                                    </Button> &nbsp;
                                                                                    </p>
                                                                                </div>

                                                                            </>


                                                                        )
                                                                    })
                                                                    }


                                                                </div>
                                                            </Card> :
                                                            <Card size='small' className='ApproveCards' title='Assignee_Documents' headStyle={{ fontWeight: 'bold', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ height: '150px' }}>
                                                                <p style={{ fontWeight: 'bold', marginTop: '5%', marginLeft: '40%' }}>No Documents</p>
                                                            </Card>
                                                        }
                                                    </div>
                                                    <div className="col-md-6">
                                                        {assigneeLabel && assigneeLabel.length > 0 ?
                                                            <Card size='small' className='ApproveCards' title={<><span>Label</span><span style={{ marginLeft: '45%' }}>Value</span></>} headStyle={{ height: '10px', fontWeight: 'bold', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ marginLeft: '10%', height: '150px' }}>
                                                                <div className="row" style={{ fontWeight: 'bold', height: '100px', width: '105%', overflowY: assigneeLabel && assigneeLabel.length > 0 && assigneeLabel[0].assigneeLabel && assigneeLabel[0].assigneeLabel.length > 2 ? "scroll" : "hidden" }}>


                                                                    {/* <thead>
        <tr>

            <th >Concurrer_Documents</th>
        </tr>
    </thead> */}

                                                                    {assigneeLabel && assigneeLabel.length > 0 && assigneeLabel.map((item) => {
                                                                        for (let k in item) {
                                                                            console.log(k, 'aishuuuu');
                                                                            return (
                                                                                <>
                                                                                    <div className="col-md-12">
                                                                                        <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                <p>{item.label}</p>
                                                                                            </div>
                                                                                            <div className="col-md-6">
                                                                                                <p>{item.value}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>




                                                                                </>

                                                                            )
                                                                        }
                                                                    })
                                                                    }


                                                                </div>
                                                            </Card> : <Card className='ApproveCards' size='small' title='Label Info' headStyle={{ fontWeight: 'bold', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ height: '150px' }}>
                                                                <p style={{ fontWeight: 'bold', marginTop: '5%', marginLeft: '40%' }}>No Label</p>
                                                            </Card>

                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="row" style={{ marginLeft: '1%' }}>
                                                <div className="column" style={{ flex: '30%' }}>
                                                    <div className="row">
                                                        <div className="row-md-6">
                                                            <div className="row-md-2">   <label htmlFor=""><b>Vendor Code:</b></label></div>
                                                            <div className="row-md-4"><label>{vendorDetails.code}</label></div>
                                                        </div>
                                                        <div className="row-md-6 mt-2">
                                                            <div className="row-md-2">   <label htmlFor=""><b>Vendor Name:</b></label></div>
                                                            <div className="row-md-4"><label>{vendorDetails.name}</label></div>
                                                        </div>
                                                        <div className="row-md-6 mt-2">
                                                            <div className="row-md-2">   <label htmlFor=""><b>Invoice Number:</b></label></div>
                                                            <div className="row-md-4"><label>{vendorDetails.invoice_no}</label></div>
                                                        </div>
                                                        <div className="row-md-6 mt-2">
                                                            <div className="row-md-2">   <label htmlFor=""><b>Invoice Date:</b></label></div>
                                                            <div className="row-md-4"> <label>{vendorDetails.invoice_date}</label></div>
                                                        </div>
                                                        <div className="row-md-6 mt-2">
                                                            <div className="row-md-2">   <label htmlFor=""><b>Certificate process for the month of:</b></label></div>
                                                            <div className="row-md-4"> <label>{vendorDetails.month}</label></div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="column" style={{
                                                    flex: "30%",
                                                }}>

                                                    <div className="row">
                                                        <div style={{ height: '100px', overflowY: assigneeDocument && assigneeDocument.length > 2 ? "scroll" : "hidden" }}>
                                                            <table>
                                                                <thead>
                                                                    <tr>

                                                                        <th >Assignee_Documents</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{ marginTop: '10%' }}>
                                                                    {

                                                                        assigneeDocument && assigneeDocument.length > 0 && assigneeDocument.map((item, i) => {
                                                                            return <tr> <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                                icon={<DownloadOutlined />} size='small'
                                                                            // onClick={(e) => { download(item.file_name) }}
                                                                            >

                                                                            </Button> &nbsp;  <Button type="primary" class='fa fa-times text-primary removeicon'
                                                                                icon={< CloseOutlined />} size='small'
                                                                                onClick={(e) => { Remove(item) }}
                                                                            >
                                                                                </Button>
                                                                            </p>
                                                                            </tr>





                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column" style={{
                                                    flex: "30%",
                                                }}>

                                                    <div className="row"  >
                                                        <div className="div" style={{ height: '100px', width: '250px', overflowY: assigneeLabel && assigneeLabel.length > 3 ? "scroll" : "hidden" }}>

                                                            <table>
                                                                <thead>
                                                                    <tr>

                                                                        <th>Label_Info</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody >
                                                                    {assigneeLabel && assigneeLabel.length > 0 && assigneeLabel.map((item) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>{item.label}</td>
                                                                                    <td>{item.value}</td>
                                                                                </tr>

                                                                            </>

                                                                        )
                                                                    })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </Panel>
                                    </Collapse>
                                    {vendorDetails.concurrer == '-1' ? false :
                                        <Collapse accordion defaultActiveKey={["1"]}>
                                            <Panel header="Concurrer Details" key="1"
                                            // extra={tableshow == true ? genextra() : false}
                                            >
                                                {sessionArray && sessionArray.cmpf_user_category == 1 ?

                                                    // <div className="row" style={{ marginLeft: '1%' }}>

                                                    //     <div className="column" style={{
                                                    //         flex: "30%",
                                                    //     }}>

                                                    //         <div className="row">
                                                    //             <div style={{ height: '100px', overflowY: concurrerDocument && concurrerDocument.length > 2 ? "scroll" : "hidden" }}>
                                                    //                 <table>
                                                    //                     <thead>
                                                    //                         <tr>

                                                    //                             <th >Concurrer_Documents</th>
                                                    //                         </tr>
                                                    //                     </thead>
                                                    //                     <tbody style={{ marginTop: '10%' }}>
                                                    //                         {concurrerDocument && concurrerDocument.length > 0 && concurrerDocument.map((item) => {
                                                    //                             return (
                                                    //                                 <>
                                                    //                                     <tr> <p>{item}

                                                    //                                         <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                    //                                             icon={<DownloadOutlined />} size='small'
                                                    //                                         // onClick={(e) => { download(item.file_name) }}
                                                    //                                         >

                                                    //                                         </Button> &nbsp;
                                                    //                                         <Button type="primary" class='fa fa-times text-primary removeicon'
                                                    //                                             icon={< CloseOutlined />} size='small'
                                                    //                                             onClick={(e) => { Remove(item) }}
                                                    //                                         >
                                                    //                                         </Button>
                                                    //                                     </p>
                                                    //                                     </tr>

                                                    //                                 </>

                                                    //                             )
                                                    //                         })
                                                    //                         }

                                                    //                     </tbody>
                                                    //                 </table>

                                                    //             </div>
                                                    //         </div>
                                                    //     </div>
                                                    //     <div className="column" style={{
                                                    //         flex: "30%",
                                                    //     }}>

                                                    //         <div className="row"  >
                                                    //             <div className="div" style={{ height: '100px', width: '250px', overflowY: concurrerLabel && concurrerLabel.length > 3 ? "scroll" : "hidden" }}>
                                                    //                 <table>
                                                    //                     <thead>
                                                    //                         <tr>

                                                    //                             <th>Label_Info</th>
                                                    //                         </tr>
                                                    //                     </thead>
                                                    //                     <tbody >
                                                    //                         {concurrerLabel && concurrerLabel.length > 0 && concurrerLabel.map((item) => {
                                                    //                             return (
                                                    //                                 <>
                                                    //                                     <tr>
                                                    //                                         <td>{item.label}</td>
                                                    //                                         <td>{item.value}</td>
                                                    //                                     </tr>

                                                    //                                 </>

                                                    //                             )
                                                    //                         })
                                                    //                         }

                                                    //                     </tbody>
                                                    //                 </table>
                                                    //             </div>
                                                    //         </div>
                                                    //     </div>
                                                    // </div>



                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                {concurrerDocument && concurrerDocument.length > 0 ?
                                                                    <Card size='small' className='ApproveCards' title='Concurrer_Document' headStyle={{ fontWeight: 'bold', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ height: '150px' }}>
                                                                        <div className="row" style={{ height: '100px', width: '250px', overflowY: concurrerDocument && concurrerDocument.length > 2 ? "scroll" : "hidden" }}>


                                                                            {/* <thead>
<tr>

    <th >Concurrer_Documents</th>
</tr>
</thead> */}

                                                                            {concurrerDocument && concurrerDocument.length > 0 && concurrerDocument.map((item) => {
                                                                                return (
                                                                                    <>
                                                                                        <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                                            icon={<DownloadOutlined />} size='small'
                                                                                            onClick={(e) => { download(item) }}
                                                                                        >

                                                                                        </Button> &nbsp;
                                                                                        </p>


                                                                                    </>

                                                                                )
                                                                            })
                                                                            }


                                                                        </div>
                                                                    </Card> :
                                                                    <Card size='small' className='ApproveCards' title='Concurrer_Documents' headStyle={{ fontWeight: 'bold', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ height: '150px' }}>
                                                                        <p style={{ fontWeight: 'bold', marginTop: '5%', marginLeft: '40%' }}>No Documents</p>
                                                                    </Card>
                                                                }
                                                            </div>
                                                            <div className="col-md-6">
                                                                {concurrerLabel && concurrerLabel.length > 0 ?
                                                                    <Card size='small' className='ApproveCards' title='Label Info' headStyle={{ height: '10px', fontWeight: 'bold', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ marginLeft: '10%', height: '150px', borderRadius: '15px' }}>
                                                                        <div className="row" style={{ fontWeight: 'bold', height: '100px', width: '250px', overflowY: concurrerLabel && concurrerLabel.length > 0 && concurrerLabel[0].document_name && concurrerLabel[0].document_name.length > 2 ? "scroll" : "hidden" }}>


                                                                            {/* <thead>
<tr>

<th >Concurrer_Documents</th>
</tr>
</thead> */}

                                                                            {concurrerLabel && concurrerLabel.length > 0 && concurrerLabel.map((item) => {
                                                                                for (let k in item) {
                                                                                    console.log(k, 'aishuuuu');
                                                                                    return (
                                                                                        <>
                                                                                            <div className="col-md-12">
                                                                                                <div className="row">
                                                                                                    <div className="col-md-6">
                                                                                                        <p>{item.label}</p>
                                                                                                    </div>
                                                                                                    <div className="col-md-6">
                                                                                                        <p>{item.value}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>




                                                                                        </>

                                                                                    )
                                                                                }
                                                                            })
                                                                            }


                                                                        </div>
                                                                    </Card> : <Card className='ApproveCards' size='small' title='Label Info' headStyle={{ fontWeight: 'bold', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ height: '150px' }}>
                                                                        <p style={{ fontWeight: 'bold', marginTop: '5%', marginLeft: '40%' }}>No Label</p>
                                                                    </Card>

                                                                }
                                                            </div>
                                                        </div>
                                                    </div>




                                                    :

                                                    <>
                                                        <div className="col-md-12">
                                                            <div className="row certificate-card">
                                                                <div className="col-md-6">
                                                                    <Card className='bg-light' style={{ height: '295px', borderRadius: '20px' }}>
                                                                        <div style={{ width: '100%' }} className="d-flex align-items-center">
                                                                            <div>
                                                                                <label style={{ minWidth: '180px' }} htmlFor=""><b>Upload Documents : </b></label><br />
                                                                                <span className='m-0 fs-2 p-0'>(Maximum 25MB per file/task)</span>
                                                                            </div>
                                                                            <div className={'d-flex flex-row-reverse serv-file-upload my-1 popupbtncolour ' + localStorage.getItem('currentTheme')}>
                                                                                <label htmlFor="file-upload"
                                                                                    className={`rounded-end w-45 btn  p-1 d-flex justify-content-center align-items-center ${localStorage.getItem('currentTheme')}`}><i className='fas fa-upload me-1'></i>Select File</label>
                                                                                <input
                                                                                    className='bg-white w-50 border rounded-start p-2 d-none'
                                                                                    // value={0}
                                                                                    id="file-upload"
                                                                                    type="file"
                                                                                    name="file"
                                                                                    multiple
                                                                                    onChange={(e) => {
                                                                                        SavepastRecordOnChange(e)
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {/* <input type="file" name="" id="" /> */}
                                                                        <label style={{ minWidth: '180px' }} htmlFor=""><b>Attached Documents : </b></label>
                                                                        <div id='service-table-action' className='mt-3' style={{ overflowY: 'auto' }}>
                                                                            {fileName && fileName.length > 0 ?
                                                                                fileName.map((item, i) => {

                                                                                    return (

                                                                                        <div className='border-bottom' style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px gray solid', marginTop: '3%' }}>
                                                                                            <p style={{ wordBreak: 'break-all', color: ApproverDocument && ApproverDocument.length > 0 && ApproverDocument.includes(item) ? 'green' : RemoveDocument && RemoveDocument.length && RemoveDocument.includes(item) ? 'red' : '' }} title={item}>{item} </p>
                                                                                            <div className='d-flex'>
                                                                                                {RemoveDocument && RemoveDocument.length && RemoveDocument.includes(item) ? '' :
                                                                                                    <button style={{ marginRight: '10px', marginBottom: '8px' }} onClick={(e) => { download(item) }} className="ms-3 bg-transparent text-black full-mode fullscreenradius" >
                                                                                                        <DownloadOutlined />
                                                                                                    </button>
                                                                                                }
                                                                                                <button style={{ marginBottom: '7px' }} onClick={(e) => { Remove(item) }} className="ms-3 bg-transparent text-black full-mode fullscreenradius" >
                                                                                                    <CloseOutlined />
                                                                                                </button>
                                                                                            </div>

                                                                                        </div>
                                                                                    )
                                                                                }) : false
                                                                                // (ApproverDocument && ApproverDocument.length > 0 ?
                                                                                //     ApproverDocument.map((item, i) => {
                                                                                //         return (
                                                                                //             <div className='border-bottom' style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px gray solid', marginTop: '2%' }}>
                                                                                //                 <p title={item} style={{ color: 'green' }}>{item} </p>
                                                                                //                 <div className='d-flex'>
                                                                                //                     <button style={{ marginRight: '10px', marginBottom: '8px' }} onClick={(e) => { download(item) }} className="ms-3 bg-transparent text-black full-mode fullscreenradius" >
                                                                                //                         <DownloadOutlined />
                                                                                //                     </button>
                                                                                //                     <button style={{ marginBottom: '7px' }} onClick={(e) => { Remove(item) }} className="ms-3 bg-transparent text-black full-mode fullscreenradius" >
                                                                                //                         <CloseOutlined />
                                                                                //                     </button>
                                                                                //                 </div>

                                                                                //             </div>
                                                                                //         )
                                                                                //     }) : (
                                                                                //         <p style={{ marginTop: '13%', marginLeft: '40%', fontWeight: 'bold' }}>No files chosen</p>
                                                                                //     ))
                                                                            }
                                                                        </div>
                                                                    </Card>

                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Card className='bg-light' style={{ height: '295px', borderRadius: '20px' }}>

                                                                        <Button type="primary" style={{ padding: '20px 15px', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                                            onClick={(e) => {
                                                                                if (MappingDetail && MappingDetail.lbl_master && MappingDetail.lbl_master.length > 0) {
                                                                                    setLabelModal(true)
                                                                                    const payload2 = [
                                                                                        authtoken,
                                                                                        {
                                                                                            "session_token": authtoken,
                                                                                            "request": [
                                                                                                "GetLabelList",
                                                                                                {}
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                    getLabelList({
                                                                                        payload: payload2,
                                                                                        paramid: paramid
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    Toaster.warning("No Label")
                                                                                }
                                                                            }}
                                                                            icon={<PlusOutlined />} size='default'>
                                                                            Insert Label
                                                                        </Button>
                                                                        <div id='service-table-action' className='mt-3' style={{ overflowY: approveLabel && approveLabel.length > 3 ? 'scroll' : 'hidden' }}>
                                                                            {approveLabel && approveLabel.length > 0 ?
                                                                                <table >
                                                                                    <tbody>
                                                                                        {approveLabel && approveLabel.length > 0 && approveLabel.map((item, i) => {
                                                                                            let k = approveLabel[i]
                                                                                            for (let j in approveLabel[i]) {
                                                                                                return (
                                                                                                    <tr className='border-bottom'>
                                                                                                        <td style={{ width: '205px' }}>


                                                                                                            <label htmlFor="">{j}</label>
                                                                                                        </td>
                                                                                                        <td>


                                                                                                            <Input type="text" autocomplete="off" autoFocus={true} id={j} className='form-control' name="emp_name" key={updatevalue[i]}
                                                                                                                // onChange={(e) => {
                                                                                                                //     let temp = []
                                                                                                                //     console.log(e.target.value, 'e.target.value');
                                                                                                                //     //  approveLabel[i][j] = e.target.value
                                                                                                                //     updatevalue[i] = e.target.value
                                                                                                                //     setupdatevalue(updatevalue)
                                                                                                                //     console.log(temp, 'temp');
                                                                                                                // }} 

                                                                                                                onChange={(e) => {
                                                                                                                    console.log(approveLabel[i], 'approveLabel[i]');
                                                                                                                    let array = approveLabel
                                                                                                                    let temp = []
                                                                                                                    for (let k in array) {
                                                                                                                        for (let l in array[k]) {
                                                                                                                            console.log(l, 'llll')
                                                                                                                            console.log(e.target.id, 'iddddddddd');;

                                                                                                                            if (l == e.target.id) {

                                                                                                                                temp.push({

                                                                                                                                    [l]: e.target.value,



                                                                                                                                })

                                                                                                                            }
                                                                                                                            else {

                                                                                                                                temp.push(array[k])

                                                                                                                            }

                                                                                                                        }
                                                                                                                    }
                                                                                                                    console.log(temp, 'temp7777');
                                                                                                                    setApproveLabel(temp)
                                                                                                                    // setEmailvalue(temp)

                                                                                                                }}


                                                                                                                // style={{ width: "80%", marginLeft: '12%' }} 
                                                                                                                value={approveLabel[i][j]} />
                                                                                                        </td>

                                                                                                        <td>
                                                                                                            <td>
                                                                                                                <button
                                                                                                                    id={j}
                                                                                                                    onClick={(e) => {
                                                                                                                        let array = approveLabel
                                                                                                                        for (let j in array) {
                                                                                                                            if (i == j) {
                                                                                                                                array.splice(i, 1);
                                                                                                                            }
                                                                                                                        }
                                                                                                                        setApproveLabel([...array])
                                                                                                                        // let temp = labelSubmit
                                                                                                                        // console.log('sneha');
                                                                                                                        // // if (labelSubmit[i] === item) {
                                                                                                                        // temp.splice(i, 1)
                                                                                                                        // // }
                                                                                                                        // // console.log(temp, 'temptemptemp');
                                                                                                                        // setLabelSubmit([...temp])




                                                                                                                        // let array = approveLabel

                                                                                                                        // let temp = []

                                                                                                                        // for (let k in array) {
                                                                                                                        //     for (let l in array[k]) {
                                                                                                                        //         console.log(l, 'llll')
                                                                                                                        //         console.log(e.target.id, 'iddddddddd');;

                                                                                                                        //         if (l == e.target.id) {

                                                                                                                        //             array.splice(array[k], i)


                                                                                                                        //         }
                                                                                                                        //         setApproveLabel(approveLabel)
                                                                                                                        //     }
                                                                                                                        // }
                                                                                                                        console.log(labelSubmit, 'labelSubmit7777');
                                                                                                                    }}
                                                                                                                    className="ms-3 bg-transparent text-black full-mode fullscreenradius" >
                                                                                                                    <CloseOutlined />
                                                                                                                </button>
                                                                                                            </td>

                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )
                                                                                            }

                                                                                        })}

                                                                                    </tbody>
                                                                                </table>
                                                                                : false}
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='text-center'>
                                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", borderColor: "#00FF7F", marginTop: '1%' }}
                                                                icon={<PlayCircleOutlined />} size='default' onClick={onSubmit}

                                                            >
                                                                Submit
                                                            </Button></div>
                                                    </>
                                                    // <div className="row" style={{ marginLeft: '1%' }}>

                                                    //     <div className="column" style={{
                                                    //         flex: "30%",
                                                    //     }}>

                                                    //         <div className="row">

                                                    //             <label htmlFor=""><b>Upload Documents:</b></label>

                                                    //             <input type="file" multiple name="file"
                                                    //                 onChange={(e) => {
                                                    //                     SavepastRecordOnChange(e)
                                                    //                 }}
                                                    //                 id="" />
                                                    //             <p>(Maximum 25MB per file/task)</p>


                                                    //             <label htmlFor=""><b>Attached Documents:</b></label><br />
                                                    //             {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                                    //                 return <p>{item.file_name}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                    //                     icon={<DownloadOutlined />} size='small'
                                                    //                     onClick={(e) => { download(item.file_name) }}
                                                    //                     style={{ marginTop: '2%' }}  >
                                                    //                 </Button> &nbsp;

                                                    //                     <Button type="primary" class='fa fa-times text-primary removeicon'
                                                    //                         icon={< CloseOutlined />} size='small'
                                                    //                         onClick={(e) => { Remove(item) }}
                                                    //                     >
                                                    //                     </Button></p>
                                                    //             })}
                                                    //             <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", width: '50%', borderColor: "#00FF7F", marginTop: '10%' }}
                                                    //                 icon={<PlayCircleOutlined />} size='default' onClick={onSubmit}

                                                    //             >
                                                    //                 Submit
                                                    //             </Button>

                                                    //         </div>
                                                    //     </div>
                                                    //     <div className="column" style={{ flex: '30%' }}>
                                                    //         <div className="row" style={{ height: '100px', overflowY: ApproverDocument && ApproverDocument.length > 2 ? "scroll" : "hidden" }}>


                                                    //             <div >
                                                    //                 <table>
                                                    //                     <thead>
                                                    //                         <tr>

                                                    //                             <th >Document</th>
                                                    //                         </tr>
                                                    //                     </thead>
                                                    //                     <tbody style={{ marginTop: '10%' }}>
                                                    //                         {ApproverDocument && ApproverDocument.length > 0 && ApproverDocument.map((item) => {
                                                    //                             return (
                                                    //                                 <>
                                                    //                                     <tr> <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                    //                                         icon={<DownloadOutlined />} size='small'
                                                    //                                     // onClick={(e) => { download(item.file_name) }}
                                                    //                                     >

                                                    //                                     </Button> &nbsp;
                                                    //                                         <Button type="primary" class='fa fa-times text-primary removeicon'
                                                    //                                             icon={< CloseOutlined />} size='small'
                                                    //                                             onClick={(e) => { Remove(item) }}
                                                    //                                         >
                                                    //                                         </Button>
                                                    //                                     </p>
                                                    //                                     </tr>

                                                    //                                 </>

                                                    //                             )
                                                    //                         })
                                                    //                         }

                                                    //                     </tbody>
                                                    //                 </table>

                                                    //             </div>
                                                    //         </div>
                                                    //     </div>
                                                    //     <div className="column" style={{
                                                    //         flex: "30%",
                                                    //     }}>

                                                    //         <div className="row">
                                                    //             <Button type="primary" shape='round' className='addbutton' style={{ background: "green", width: '50%', marginLeft: '15%' }}
                                                    //                 onClick={(e) => {
                                                    //                     if (MappingDetail && MappingDetail.lbl_master && MappingDetail.lbl_master.length > 0) {
                                                    //                         setLabelModal(true)
                                                    //                         const payload2 = [
                                                    //                             authtoken,
                                                    //                             {
                                                    //                                 "session_token": authtoken,
                                                    //                                 "request": [
                                                    //                                     "GetLabelList",
                                                    //                                     {}
                                                    //                                 ]
                                                    //                             }
                                                    //                         ]
                                                    //                         getLabelList({
                                                    //                             payload: payload2,
                                                    //                             paramid: paramid
                                                    //                         })
                                                    //                     }
                                                    //                     else {
                                                    //                         Toaster.warning("No Label")
                                                    //                     }
                                                    //                 }}
                                                    //                 icon={<PlusOutlined />} size='default'>
                                                    //                 Insert Label
                                                    //             </Button>
                                                    //             {approveLabel && approveLabel.length > 0 ?
                                                    //                 <div className="row-md-6 mt-3" style={{ height: '120px', width: '300px', overflowY: labelSubmit.length > 2 ? "scroll" : "hidden" }}>
                                                    //                     <table >
                                                    //                         <tbody>
                                                    //                             {approveLabel && approveLabel.length > 0 && approveLabel.map((item, i) => {
                                                    //                                 let k = approveLabel[i]
                                                    //                                 let temp = []
                                                    //                                 for (let j in approveLabel[i]) {
                                                    //                                     return (
                                                    //                                         <tr>
                                                    //                                             <td>


                                                    //                                                 <label htmlFor="">{j}</label>
                                                    //                                             </td>
                                                    //                                             <td>


                                                    //                                                 <Input type="text" autocomplete="off" autoFocus={true} id={j} className='form-control' name="emp_name" key={updatevalue[i]}
                                                    //                                                     // onChange={(e) => {
                                                    //                                                     //     let temp = []
                                                    //                                                     //     console.log(e.target.value, 'e.target.value');
                                                    //                                                     //     //  approveLabel[i][j] = e.target.value
                                                    //                                                     //     updatevalue[i] = e.target.value
                                                    //                                                     //     setupdatevalue(updatevalue)
                                                    //                                                     //     console.log(temp, 'temp');
                                                    //                                                     // }} 

                                                    //                                                     onChange={(e) => {
                                                    //                                                         console.log(approveLabel[i], 'approveLabel[i]');
                                                    //                                                         let array = approveLabel
                                                    //                                                         // let temp = []
                                                    //                                                         for (let k in array) {
                                                    //                                                             for (let l in array[k]) {
                                                    //                                                                 console.log(l, 'llll')
                                                    //                                                                 console.log(e.target.id, 'iddddddddd');;

                                                    //                                                                 if (l == e.target.id) {

                                                    //                                                                     temp.push({

                                                    //                                                                         [l]: e.target.value,



                                                    //                                                                     })

                                                    //                                                                 }
                                                    //                                                                 else {

                                                    //                                                                     temp.push(array[k])

                                                    //                                                                 }

                                                    //                                                             }
                                                    //                                                         }
                                                    //                                                         console.log(temp, 'temp7777');
                                                    //                                                         setApproveLabel(temp)
                                                    //                                                         // setEmailvalue(temp)

                                                    //                                                     }}


                                                    //                                                     style={{ width: "80%", marginLeft: '12%' }} />

                                                    //                                                 {Formvalidator1.current.message(
                                                    //                                                     'approveLabel',
                                                    //                                                     approveLabel[i][j],
                                                    //                                                     'required',
                                                    //                                                     {
                                                    //                                                         className: `invalid-feedback ${certificateFormSubmit ? 'show' : 'hide'}`,
                                                    //                                                         messages: {
                                                    //                                                             required: 'Please provide valid additional info',

                                                    //                                                         }
                                                    //                                                     })}
                                                    //                                             </td>

                                                    //                                             <td>

                                                    //                                                 <Button style={{ marginLeft: '2%' }} id={j} icon={<CloseOutlined />} onClick={(e) => {
                                                    //                                                     let array = approveLabel
                                                    //                                                     for (let j in array) {
                                                    //                                                         if (i == j) {
                                                    //                                                             array.splice(i, 1);
                                                    //                                                         }
                                                    //                                                     }
                                                    //                                                     setApproveLabel([...array])
                                                    //                                                     // let temp = labelSubmit
                                                    //                                                     // console.log('sneha');
                                                    //                                                     // // if (labelSubmit[i] === item) {
                                                    //                                                     // temp.splice(i, 1)
                                                    //                                                     // // }
                                                    //                                                     // // console.log(temp, 'temptemptemp');
                                                    //                                                     // setLabelSubmit([...temp])




                                                    //                                                     // let array = approveLabel

                                                    //                                                     // let temp = []

                                                    //                                                     // for (let k in array) {
                                                    //                                                     //     for (let l in array[k]) {
                                                    //                                                     //         console.log(l, 'llll')
                                                    //                                                     //         console.log(e.target.id, 'iddddddddd');;

                                                    //                                                     //         if (l == e.target.id) {

                                                    //                                                     //             array.splice(array[k], i)


                                                    //                                                     //         }
                                                    //                                                     //         setApproveLabel(approveLabel)
                                                    //                                                     //     }
                                                    //                                                     // }
                                                    //                                                     console.log(labelSubmit, 'labelSubmit7777');
                                                    //                                                 }}></Button>




                                                    //                                             </td>

                                                    //                                         </tr>
                                                    //                                     )
                                                    //                                 }

                                                    //                             })}

                                                    //                         </tbody>
                                                    //                     </table>
                                                    //                 </div>
                                                    //                 : false}
                                                    //         </div>
                                                    //     </div>

                                                    // </div>
                                                }
                                            </Panel>
                                        </Collapse>}
                                    {/* <Card className='vendorCard' title="Assignee Details" style={{ borderRadius: "15px" }} headStyle={{ borderRadius: "15px", height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>
                                    <div className="row">
                                        <div className="column" style={{ flex: '30%' }}>
                                            <div className="row">
                                                <div className="row-md-6">
                                                    <div className="row-md-2">   <label htmlFor=""><b>Vendor Code:</b></label></div>
                                                    <div className="row-md-4"><label>{vendorDetails.code}</label></div>
                                                </div>
                                                <div className="row-md-6 mt-2">
                                                    <div className="row-md-2">   <label htmlFor=""><b>Vendor Name:</b></label></div>
                                                    <div className="row-md-4"><label>{vendorDetails.name}</label></div>
                                                </div>
                                                <div className="row-md-6 mt-2">
                                                    <div className="row-md-2">   <label htmlFor=""><b>Invoice Number:</b></label></div>
                                                    <div className="row-md-4"><label>{vendorDetails.invoice_no}</label></div>
                                                </div>
                                                <div className="row-md-6 mt-2">
                                                    <div className="row-md-2">   <label htmlFor=""><b>Invoice Date:</b></label></div>
                                                    <div className="row-md-4"> <label>{vendorDetails.invoice_date}</label></div>
                                                </div>
                                                <div className="row-md-6 mt-2">
                                                    <div className="row-md-2">   <label htmlFor=""><b>Certificate process for the month of:</b></label></div>
                                                    <div className="row-md-4"> <label>{vendorDetails.month}</label></div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="column" style={{
                                            flex: "30%",
                                        }}>

                                            <div className="row">
                                                <div style={{ height: '100px', overflowY: assigneeDocument && assigneeDocument.length > 2 ? "scroll" : "hidden" }}>
                                                    <table>
                                                        <thead>
                                                            <tr>

                                                                <th >Assignee_Documents</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ marginTop: '10%' }}>
                                                            {

                                                                assigneeDocument && assigneeDocument.length > 0 && assigneeDocument.map((item, i) => {
                                                                    return <tr> <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                        icon={<DownloadOutlined />} size='small'
                                                                    // onClick={(e) => { download(item.file_name) }}
                                                                    >

                                                                    </Button> &nbsp;  <Button type="primary" class='fa fa-times text-primary removeicon'
                                                                        icon={< CloseOutlined />} size='small'
                                                                        onClick={(e) => { Remove(item) }}
                                                                    >
                                                                        </Button>
                                                                    </p>
                                                                    </tr>





                                                                })
                                                            }

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="column" style={{
                                            flex: "30%",
                                        }}>

                                            <div className="row"  >
                                                <div className="div" style={{ height: '100px', width: '250px', overflowY: assigneeLabel && assigneeLabel.length > 3 ? "scroll" : "hidden" }}>

                                                    <table>
                                                        <thead>
                                                            <tr>

                                                                <th>Label_Info</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {assigneeLabel && assigneeLabel.length > 0 && assigneeLabel.map((item) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{item.label}</td>
                                                                            <td>{item.value}</td>
                                                                        </tr>

                                                                    </>

                                                                )
                                                            })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card> */}
                                    {/* <Card className='vendorCard' title="Concurrer Details" style={{ borderRadius: "15px" }} headStyle={{ borderRadius: "15px", height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>

                                    </Card> */}
                                    {sessionArray && sessionArray.cmpf_user_category == 1 || vendorDetails.concurrer == '-1' ?
                                        <Collapse accordion defaultActiveKey={["1"]}>
                                            <Panel header="Approver Details" key="1"
                                            // extra={tableshow == true ? genextra() : false}
                                            >

                                                {/* <div className="row">

                                                    <div className="column" style={{
                                                        flex: "30%",
                                                    }}>

                                                        <div className="row">

                                                            <label htmlFor=""><b>Upload Documents:</b></label>

                                                            <input type="file" multiple name="file"
                                                                onChange={(e) => {
                                                                    SavepastRecordOnChange(e)
                                                                }}
                                                                id="" />
                                                            <p>(Maximum 25MB per file/task)</p>


                                                            <label htmlFor=""><b>Attached Documents:</b></label><br />
                                                            {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                                                return <p>{item.file_name}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                    icon={<DownloadOutlined />} size='small'
                                                                    onClick={(e) => { download(item.file_name) }}
                                                                    style={{ marginTop: '2%' }}  >
                                                                </Button> &nbsp;

                                                                    <Button type="primary" class='fa fa-times text-primary removeicon'
                                                                        icon={< CloseOutlined />} size='small'
                                                                        onClick={(e) => { Remove(item) }}
                                                                    >
                                                                    </Button></p>
                                                            })}
                                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", width: '30%', marginLeft: '130%', borderColor: "#00FF7F", marginTop: '10%' }}
                                                                icon={<PlayCircleOutlined />} size='default' onClick={onSubmit}

                                                            >
                                                                Submit
                                                            </Button>

                                                        </div>
                                                    </div>
                                                    <div className="column" style={{ flex: '30%' }}>
                                                        <div className="row" style={{ height: '100px', overflowY: ApproverDocument && ApproverDocument.length > 2 ? "scroll" : "hidden" }}>


                                                            <div >
                                                                <table>
                                                                    <thead>
                                                                        <tr>

                                                                            <th >Document</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody style={{ marginTop: '10%' }}>
                                                                        {ApproverDocument && ApproverDocument.length > 0 && ApproverDocument.map((item) => {
                                                                            return (
                                                                                <>
                                                                                    <tr> <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                                        icon={<DownloadOutlined />} size='small'
                                                                                    // onClick={(e) => { download(item.file_name) }}
                                                                                    >

                                                                                    </Button> &nbsp;
                                                                                        <Button type="primary" class='fa fa-times text-primary removeicon'
                                                                                            icon={< CloseOutlined />} size='small'
                                                                                            onClick={(e) => { Remove(item) }}
                                                                                        >
                                                                                        </Button>
                                                                                    </p>
                                                                                    </tr>

                                                                                </>

                                                                            )
                                                                        })
                                                                        }

                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="column" style={{
                                                        flex: "30%",
                                                    }}>

                                                        <div className="row">
                                                            <Button type="primary" shape='round' className='addbutton' style={{ background: "green", width: '50%', marginLeft: '15%' }}
                                                                onClick={(e) => {
                                                                    if (MappingDetail && MappingDetail.lbl_master && MappingDetail.lbl_master.length > 0) {
                                                                        setLabelModal(true)
                                                                        const payload2 = [
                                                                            authtoken,
                                                                            {
                                                                                "session_token": authtoken,
                                                                                "request": [
                                                                                    "GetLabelList",
                                                                                    {}
                                                                                ]
                                                                            }
                                                                        ]
                                                                        getLabelList({
                                                                            payload: payload2,
                                                                            paramid: paramid
                                                                        })
                                                                    }
                                                                    else {
                                                                        Toaster.warning("No Label")
                                                                    }
                                                                }}
                                                                icon={<PlusOutlined />} size='default'>
                                                                Insert Label
                                                            </Button>
                                                            {approveLabel && approveLabel.length > 0 ?
                                                                <div className="row-md-6 mt-3" style={{ height: '120px', width: '300px', overflowY: labelSubmit.length > 2 ? "scroll" : "hidden" }}>
                                                                    <table >
                                                                        <tbody>
                                                                            {approveLabel && approveLabel.length > 0 && approveLabel.map((item, i) => {
                                                                                let k = approveLabel[i]
                                                                                for (let j in approveLabel[i]) {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>


                                                                                                <label htmlFor="">{j}</label>
                                                                                            </td>
                                                                                            <td>


                                                                                                <Input type="text" autocomplete="off" autoFocus={true} id={j} className='form-control' name="emp_name" key={updatevalue[i]}
                                                                                                    // onChange={(e) => {
                                                                                                    //     let temp = []
                                                                                                    //     console.log(e.target.value, 'e.target.value');
                                                                                                    //     //  approveLabel[i][j] = e.target.value
                                                                                                    //     updatevalue[i] = e.target.value
                                                                                                    //     setupdatevalue(updatevalue)
                                                                                                    //     console.log(temp, 'temp');
                                                                                                    // }} 

                                                                                                    onChange={(e) => {
                                                                                                        console.log(approveLabel[i], 'approveLabel[i]');
                                                                                                        let array = approveLabel
                                                                                                        let temp = []
                                                                                                        for (let k in array) {
                                                                                                            for (let l in array[k]) {
                                                                                                                console.log(l, 'llll')
                                                                                                                console.log(e.target.id, 'iddddddddd');;

                                                                                                                if (l == e.target.id) {

                                                                                                                    temp.push({

                                                                                                                        [l]: e.target.value,



                                                                                                                    })

                                                                                                                }
                                                                                                                else {

                                                                                                                    temp.push(array[k])

                                                                                                                }

                                                                                                            }
                                                                                                        }
                                                                                                        console.log(temp, 'temp7777');
                                                                                                        setApproveLabel(temp)
                                                                                                        // setEmailvalue(temp)

                                                                                                    }}


                                                                                                    style={{ width: "80%", marginLeft: '12%' }} value={approveLabel[i][j]} />
                                                                                            </td>

                                                                                            <td>

                                                                                                <Button style={{ marginLeft: '2%' }} id={j} icon={<CloseOutlined />} onClick={(e) => {
                                                                                                    let array = approveLabel
                                                                                                    for (let j in array) {
                                                                                                        if (i == j) {
                                                                                                            array.splice(i, 1);
                                                                                                        }
                                                                                                    }
                                                                                                    setApproveLabel([...array])
                                                                                                    // let temp = labelSubmit
                                                                                                    // console.log('sneha');
                                                                                                    // // if (labelSubmit[i] === item) {
                                                                                                    // temp.splice(i, 1)
                                                                                                    // // }
                                                                                                    // // console.log(temp, 'temptemptemp');
                                                                                                    // setLabelSubmit([...temp])




                                                                                                    // let array = approveLabel

                                                                                                    // let temp = []

                                                                                                    // for (let k in array) {
                                                                                                    //     for (let l in array[k]) {
                                                                                                    //         console.log(l, 'llll')
                                                                                                    //         console.log(e.target.id, 'iddddddddd');;

                                                                                                    //         if (l == e.target.id) {

                                                                                                    //             array.splice(array[k], i)


                                                                                                    //         }
                                                                                                    //         setApproveLabel(approveLabel)
                                                                                                    //     }
                                                                                                    // }
                                                                                                    console.log(labelSubmit, 'labelSubmit7777');
                                                                                                }}></Button>




                                                                                            </td>

                                                                                        </tr>
                                                                                    )
                                                                                }

                                                                            })}

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : false}
                                                        </div>
                                                    </div>

                                                </div> */}



                                                <div className="col-md-12">
                                                    <div className="row certificate-card">
                                                        <div className="col-md-6">
                                                            <Card className='bg-light' style={{ height: '295px', borderRadius: '20px' }}>
                                                                <div style={{ width: '100%' }} className="d-flex align-items-center">
                                                                    <div>
                                                                        <label style={{ minWidth: '180px' }} htmlFor=""><b>Upload Documents : </b></label><br />
                                                                        <span className='m-0 fs-2 p-0'>(Maximum 25MB per file/task)</span>
                                                                    </div>

                                                                    <div className={'d-flex flex-row-reverse serv-file-upload my-1 popupbtncolour ' + localStorage.getItem('currentTheme')}>

                                                                        <label htmlFor="file-upload"
                                                                            className={`rounded-end w-45 btn  p-1 d-flex justify-content-center align-items-center ${localStorage.getItem('currentTheme')}`}><i className='fas fa-upload me-1'></i>Select File</label>
                                                                        <input
                                                                            className='bg-white w-50 border rounded-start p-2 d-none'
                                                                            // value={0}
                                                                            id="file-upload"
                                                                            type="file"
                                                                            name="file"
                                                                            multiple
                                                                            onChange={(e) => {
                                                                                SavepastRecordOnChange(e)
                                                                            }
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* <input type="file" name="" id="" /> */}
                                                                <label style={{ minWidth: '180px' }} htmlFor=""><b>Attached Documents : </b></label>
                                                                <div id='service-table-action' className='mt-3' style={{ overflowY: fileName && fileName.length > 2 ? 'scroll' : 'hidden' }}>
                                                                    {fileName && fileName.length > 0 ?
                                                                        fileName.map((item, i) => {

                                                                            return (

                                                                                <div className='border-bottom' style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px gray solid', marginTop: '3%' }}>
                                                                                    <p style={{ wordBreak: 'break-all', color: RemoveDocument && RemoveDocument.length && RemoveDocument.includes(item) ? 'red' : ApproverDocument && ApproverDocument.length > 0 && ApproverDocument.includes(item) ? 'green' : '' }} title={item} >{item} </p>
                                                                                    <div className='d-flex'>
                                                                                        {RemoveDocument && RemoveDocument.length && RemoveDocument.includes(item) ? '' :
                                                                                            <button style={{ marginRight: '10px', marginBottom: '8px' }} onClick={(e) => { download(item) }} className="ms-3 bg-transparent text-black full-mode fullscreenradius" >
                                                                                                <DownloadOutlined />
                                                                                            </button>
                                                                                        }
                                                                                        <button style={{ marginBottom: '7px' }} onClick={(e) => { Remove(item) }} className="ms-3 bg-transparent text-black full-mode fullscreenradius" >
                                                                                            <CloseOutlined />
                                                                                        </button>
                                                                                    </div>

                                                                                </div>
                                                                            )
                                                                        }) : false
                                                                        // (ApproverDocument && ApproverDocument.length > 0 ?
                                                                        //     ApproverDocument.map((item, i) => {
                                                                        //         return (
                                                                        //             <div className='border-bottom' style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px gray solid', marginTop: '2%' }}>
                                                                        //                 <p title={item} style={{ color: 'green' }}>{item} </p>
                                                                        //                 <div className='d-flex'>
                                                                        //                     <button style={{ marginRight: '10px', marginBottom: '8px' }} onClick={(e) => { download(item) }} className="ms-3 bg-transparent text-black full-mode fullscreenradius" >
                                                                        //                         <DownloadOutlined />
                                                                        //                     </button>
                                                                        //                     <button style={{ marginBottom: '7px' }} onClick={(e) => { Remove(item) }} className="ms-3 bg-transparent text-black full-mode fullscreenradius" >
                                                                        //                         <CloseOutlined />
                                                                        //                     </button>
                                                                        //                 </div>

                                                                        //             </div>
                                                                        //         )
                                                                        //     }) : (
                                                                        //         <p style={{ marginTop: '13%', marginLeft: '40%', fontWeight: 'bold' }}>No files chosen</p>
                                                                        //     ))
                                                                    }
                                                                </div>
                                                            </Card>

                                                        </div>
                                                        <div className="col-md-6">
                                                            <Card className='bg-light' style={{ height: '295px', borderRadius: '20px' }}>

                                                                <Button type="primary" style={{ padding: '20px 15px', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                                    onClick={(e) => {
                                                                        if (MappingDetail && MappingDetail.lbl_master && MappingDetail.lbl_master.length > 0) {
                                                                            setLabelModal(true)
                                                                            const payload2 = [
                                                                                authtoken,
                                                                                {
                                                                                    "session_token": authtoken,
                                                                                    "request": [
                                                                                        "GetLabelList",
                                                                                        {}
                                                                                    ]
                                                                                }
                                                                            ]
                                                                            getLabelList({
                                                                                payload: payload2,
                                                                                paramid: paramid
                                                                            })
                                                                        }
                                                                        else {
                                                                            Toaster.warning("No Label")
                                                                        }
                                                                    }}
                                                                    icon={<PlusOutlined />} size='default'>
                                                                    Insert Label
                                                                </Button>
                                                                <div id='service-table-action' className='mt-3' style={{ overflowY: approveLabel && approveLabel.length > 3 ? 'scroll' : 'hidden' }}>
                                                                    {approveLabel && approveLabel.length > 0 ?
                                                                        <table >
                                                                            <tbody>
                                                                                {approveLabel && approveLabel.length > 0 && approveLabel.map((item, i) => {
                                                                                    let k = approveLabel[i]
                                                                                    for (let j in approveLabel[i]) {
                                                                                        return (
                                                                                            <tr className='border-bottom'>
                                                                                                <td style={{ width: '205px' }}>


                                                                                                    <label htmlFor="">{j}</label>
                                                                                                </td>
                                                                                                <td>


                                                                                                    <Input type="text" autocomplete="off" autoFocus={true} id={j} className='form-control' name="emp_name" key={updatevalue[i]}
                                                                                                        // onChange={(e) => {
                                                                                                        //     let temp = []
                                                                                                        //     console.log(e.target.value, 'e.target.value');
                                                                                                        //     //  approveLabel[i][j] = e.target.value
                                                                                                        //     updatevalue[i] = e.target.value
                                                                                                        //     setupdatevalue(updatevalue)
                                                                                                        //     console.log(temp, 'temp');
                                                                                                        // }} 

                                                                                                        onChange={(e) => {
                                                                                                            console.log(approveLabel[i], 'approveLabel[i]');
                                                                                                            let array = approveLabel
                                                                                                            let temp = []
                                                                                                            for (let k in array) {
                                                                                                                for (let l in array[k]) {
                                                                                                                    console.log(l, 'llll')
                                                                                                                    console.log(e.target.id, 'iddddddddd');;

                                                                                                                    if (l == e.target.id) {

                                                                                                                        temp.push({

                                                                                                                            [l]: e.target.value,



                                                                                                                        })

                                                                                                                    }
                                                                                                                    else {

                                                                                                                        temp.push(array[k])

                                                                                                                    }

                                                                                                                }
                                                                                                            }
                                                                                                            console.log(temp, 'temp7777');
                                                                                                            setApproveLabel(temp)
                                                                                                            // setEmailvalue(temp)

                                                                                                        }}


                                                                                                        // style={{ width: "80%", marginLeft: '12%' }} 
                                                                                                        value={approveLabel[i][j]} />
                                                                                                </td>

                                                                                                <td>
                                                                                                    <td>
                                                                                                        <button
                                                                                                            id={j}
                                                                                                            onClick={(e) => {
                                                                                                                let array = approveLabel
                                                                                                                for (let j in array) {
                                                                                                                    if (i == j) {
                                                                                                                        array.splice(i, 1);
                                                                                                                    }
                                                                                                                }
                                                                                                                setApproveLabel([...array])


                                                                                                                for (let v in labelCheckBox) {
                                                                                                                    console.log(j, 'labelCheckBox[v] == i');
                                                                                                                    if (labelCheckBox[v] == j) {
                                                                                                                        labelCheckBox.splice(v, 1)
                                                                                                                        setChanges(true)
                                                                                                                    }
                                                                                                                }
                                                                                                                setlabelCheckBox([...labelCheckBox])
                                                                                                                // let temp = labelSubmit
                                                                                                                // console.log('sneha');
                                                                                                                // // if (labelSubmit[i] === item) {
                                                                                                                // temp.splice(i, 1)
                                                                                                                // // }
                                                                                                                // // console.log(temp, 'temptemptemp');
                                                                                                                // setLabelSubmit([...temp])




                                                                                                                // let array = approveLabel

                                                                                                                // let temp = []

                                                                                                                // for (let k in array) {
                                                                                                                //     for (let l in array[k]) {
                                                                                                                //         console.log(l, 'llll')
                                                                                                                //         console.log(e.target.id, 'iddddddddd');;

                                                                                                                //         if (l == e.target.id) {

                                                                                                                //             array.splice(array[k], i)


                                                                                                                //         }
                                                                                                                //         setApproveLabel(approveLabel)
                                                                                                                //     }
                                                                                                                // }
                                                                                                                console.log(labelSubmit, 'labelSubmit7777');
                                                                                                            }}
                                                                                                            className="ms-3 bg-transparent text-black full-mode fullscreenradius" >
                                                                                                            <CloseOutlined />
                                                                                                        </button>
                                                                                                    </td>

                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    }

                                                                                })}

                                                                            </tbody>
                                                                        </table>
                                                                        : false}
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='text-center'>
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", borderColor: "#00FF7F", marginTop: '1%' }}
                                                        icon={<PlayCircleOutlined />} size='default' onClick={onSubmit}

                                                    >
                                                        Submit
                                                    </Button></div>
                                            </Panel>
                                        </Collapse>


                                        : false}
                                </div>

                            </div>

                            {/* </div>
                            </div> */}
                        </div>

                    </div>}
            </div>
            <Modal title='Submit Status' className={'usr_modal_class usr_modal_class_submit user-priv-modal add-service-prv ' + localStorage.getItem("currentTheme")} onCancel={handleCancel} open={popUpModal} footer={null} width={350} maskClosable={false}>
                <p>Compliance Certificate generated successfully and download from Report</p>
                <div className="text-center">
                    <Button type="primary" shape="round" className='addbutton' style={{ marginTop: '1%' }}
                        size='default'
                        onClick={okdone}
                    >
                        Ok
                    </Button></div>
            </Modal>
            <Modal title='Compliance List' className={"remark-header modelradius add-service-prv c  " + localStorage.getItem('currentTheme')} onCancel={handleCancel} open={complianceStatusModal} footer={null} width={350} maskClosable={false}>
                <p><b>{monthData}</b></p>
                <Table
                    size={'small'}
                    // id='service-user-priv'
                    className={localStorage.getItem("currentTheme")}
                    // className='userprivclass'
                    columns={columns1}
                    dataSource={complianceStatusModals.compliancestatus_certifications}
                    bordered
                    // fixedHeader={dataTableProperties.fixedHeader}
                    // scroll={{ x: 1500, y: 1000 }}
                    pagination={false} />


            </Modal>
            <Modal autoFocus={false} title='Details Enclosed' className={"remark-header modelradius add-service-prv complianceStatus popupbtncolour  " + localStorage.getItem('currentTheme')} onCancel={handleCancel} open={detailsModal} footer={null} width={500} maskClosable={false}>

                {AssigneeDetails && AssigneeDetails.length > 0 ? <>  <p style={{ fontWeight: 'bold', background: '#337ab7', color: 'white' }}>{`Details Submitted by:${AssigneeDetails && AssigneeDetails.length > 0 && AssigneeDetails[0].cert_by}`}</p>
                    <div className="row">
                        <div className="column" style={{ flex: '50%' }}>
                            <div className="row">
                                <div className="row-md-6">
                                    <div className="row-md-2">   <label htmlFor=""><b>Vendor Code:</b></label></div>
                                    <div className="row-md-4"> <Input autoFocus={true} type="text" autocomplete="off" className='form-control' name="emp_name"
                                        value={vendorDetails.code}
                                        disabled
                                        style={{ marginTop: "5px", width: "80%" }} /></div>
                                </div>
                                <div className="row-md-6" style={{ marginTop: '5px' }}>
                                    <div className="row-md-2">   <label htmlFor=""><b>Vendor Name:</b></label></div>
                                    <div className="row-md-4"> <Input type="text" autocomplete="off" className='form-control' name="emp_name"
                                        value={vendorDetails.name}
                                        disabled

                                        style={{ marginTop: "5px", width: "80%" }} /></div>
                                </div>
                                <div className="row-md-6" style={{ marginTop: '5px' }}>
                                    <div className="row-md-2">   <label htmlFor=""><b>Invoice Number:</b></label></div>
                                    <div className="row-md-4"> <Input type="text" autocomplete="off" className='form-control' name="emp_name"
                                        value={vendorDetails.invoice_no}
                                        disabled
                                        style={{ marginTop: "5px", width: "80%" }} /></div>
                                </div>
                                <div className="row-md-6" style={{ marginTop: '5px' }}>
                                    <div className="row-md-2">   <label htmlFor=""><b>Invoice Date:</b></label></div>
                                    <div className="row-md-4"> <Input type="text" autocomplete="off" className='form-control' name="emp_name"
                                        value={vendorDetails.invoice_date}
                                        disabled
                                        style={{ marginTop: "5px", width: "80%" }} /></div>
                                </div>
                                <div className="row-md-6" style={{ marginTop: '5px' }}>
                                    <div className="row-md-2">   <label htmlFor=""><b>Certificate process for the month of:</b></label></div>
                                    <div className="row-md-4"> <Input type="text" autocomplete="off" className='form-control' name="emp_name"
                                        value={vendorDetails.month}
                                        disabled
                                        style={{ marginTop: "5px", width: "80%" }} /></div>
                                </div>
                            </div>

                        </div>
                        <div className="column" style={{ flex: '50%' }}>

                            {AssigneeDetails && AssigneeDetails.length && AssigneeDetails[0].document_name && AssigneeDetails[0].document_name.length > 0 ? <div className="row">
                                <Card className='ApproveCards' size='small' title='Assignee_Documents' headStyle={{ fontWeight: 'bold', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ height: '150px' }}>
                                    <div className="row" style={{ height: '100px', width: '320px', overflowY: AssigneeDetails && AssigneeDetails.length > 0 ? "scroll" : "hidden" }}>


                                        {/* <thead>
                                            <tr>

                                                <th >Concurrer_Documents</th>
                                            </tr>
                                        </thead> */}

                                        {AssigneeDetails && AssigneeDetails.length > 0 && AssigneeDetails[0].document_name && AssigneeDetails[0].document_name.map((item) => {
                                            return (
                                                <>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            {/* <div className="row" style={{ height: '100px', width: '250px', overflowY: AssigneeDetails && AssigneeDetails.length > 0 ? "scroll" : "hidden" }}> */}
                                                            <div className="col-md-9">
                                                                <p>{item} </p>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <Button className={'fa fa-download  c-pointer  addbutton ' + localStorage.getItem('currentTheme')}
                                                                    icon={<DownloadOutlined />} size='small'
                                                                    onClick={(e) => {
                                                                        const downloadpayload =

                                                                            [
                                                                                authtoken,
                                                                                {
                                                                                    "session_token": authtoken,
                                                                                    "request": [
                                                                                        "DownloadFileCert",
                                                                                        {
                                                                                            "le_id": data.legalEntity,
                                                                                            "upload_type": 1,
                                                                                            "u_id": vendorDetails.vendorid,
                                                                                            "file_value": item,
                                                                                            "c_id": Number(data.country)
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        Downloadfile({
                                                                            payload: downloadpayload,
                                                                            paramid: paramid
                                                                        });
                                                                    }}
                                                                >

                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* </div> */}
                                                </>

                                            )
                                        })
                                        }


                                    </div>
                                </Card>
                            </div> : false}
                            {assigneeLabel && assigneeLabel.length > 0 ?
                                <div className="row">
                                    <Card className='ApproveCards' size='small' title='Label Info' headStyle={{ height: '10px', fontWeight: 'bold', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ marginTop: "5px", height: '150px' }}>
                                        <div className="row" style={{ height: '100px', width: '250px', overflowY: assigneeLabel && assigneeLabel.length > 0 && assigneeLabel[0].document_name && assigneeLabel[0].document_name.length > 2 ? "scroll" : "hidden" }}>


                                            {/* <thead>
        <tr>

            <th >Concurrer_Documents</th>
        </tr>
    </thead> */}

                                            {assigneeLabel && assigneeLabel.length > 0 && assigneeLabel.map((item) => {
                                                for (let k in item) {
                                                    console.log(k, 'aishuuuu');
                                                    return (
                                                        <>
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <p>{item.label}</p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p>{item.value}</p>
                                                                    </div>
                                                                </div>
                                                            </div>




                                                        </>

                                                    )
                                                }
                                            })
                                            }


                                        </div>
                                    </Card>
                                </div>
                                // <div className="row" >
                                //     <div className="div" style={{ height: '100px', width: '250px', overflowY: assigneeLabel && assigneeLabel.length > 3 ? "scroll" : "hidden" }} >
                                //         <table>
                                //             <thead>
                                //                 <tr>

                                //                     <th>Label_Info</th>
                                //                 </tr>
                                //             </thead>
                                //             <tbody >
                                //                 {assigneeLabel && assigneeLabel.length > 0 && assigneeLabel.map((item) => {
                                //                     for (let k in item) {
                                //                         console.log(k, 'aishuuuu');
                                //                         return (
                                //                             <>
                                //                                 <tr>
                                //                                     <td>{k}</td>
                                //                                     <td>{item[k]}</td>
                                //                                 </tr>

                                //                             </>

                                //                         )
                                //                     }
                                //                 })
                                //                 }

                                //             </tbody>
                                //         </table>
                                //     </div>
                                // </div>
                                : false}
                        </div>
                    </div></> : false}
                {concurrerDetails && concurrerDetails.length > 0 ? <div style={{ marginTop: '5%' }}>    <p style={{
                    fontWeight: 'bold',
                    background: '#337ab7', color: 'white'
                }}>{`Details Submitted by:${concurrerDetails && concurrerDetails.length > 0 && concurrerDetails[0].cert_by}`}</p>
                    <div className="row">


                        {concurrerDetails && concurrerDetails.length && concurrerDetails[0].document_name && concurrerDetails[0].document_name.length > 0 ?
                            <div className="column" style={{ flex: '50%' }}>


                                <Card size='small' className='ApproveCards' title='Concurrer_Documents' headStyle={{ fontWeight: 'bold', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ height: '150px' }}>
                                    <div className="row" style={{ height: '100px', width: '250px', overflowY: concurrerDetails && concurrerDetails.length > 0 && concurrerDetails[0].document_name && concurrerDetails[0].document_name.length > 2 ? "scroll" : "hidden" }}>


                                        {/* <thead>
                                            <tr>

                                                <th >Concurrer_Documents</th>
                                            </tr>
                                        </thead> */}

                                        {concurrerDetails && concurrerDetails.length > 0 && concurrerDetails[0].document_name && concurrerDetails[0].document_name.length > 0 && concurrerDetails[0].document_name.map((item) => {
                                            return (
                                                <>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <div className="row" style={{ height: '100px', width: '250px', overflowY: concurrerDetails && concurrerDetails.length > 0 ? "scroll" : "hidden" }}>
                                                                    <p>{item} </p>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <Button className={'fa fa-download  c-pointer  addbutton ' + localStorage.getItem('currentTheme')}
                                                                        icon={<DownloadOutlined />} size='small'
                                                                        onClick={(e) => {
                                                                            const downloadpayload =

                                                                                [
                                                                                    authtoken,
                                                                                    {
                                                                                        "session_token": authtoken,
                                                                                        "request": [
                                                                                            "DownloadFileCert",
                                                                                            {
                                                                                                "le_id": data.legalEntity,
                                                                                                "upload_type": 1,
                                                                                                "u_id": vendorDetails.vendorid,
                                                                                                "file_value": item,
                                                                                                "c_id": Number(data.country)
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            Downloadfile({
                                                                                payload: downloadpayload,
                                                                                paramid: paramid
                                                                            });
                                                                        }}
                                                                    >

                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </>

                                            )
                                        })
                                        }


                                    </div>
                                </Card>




                            </div> : false}
                        {concurrerLabel && concurrerLabel.length > 0 ?
                            <div className="column" style={{ flex: '50%' }}>
                                <Card className='ApproveCards' size='small' title='Label Info' headStyle={{ fontWeight: 'bold', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ marginLeft: '10%', height: '150px' }}>
                                    <div className="row" style={{ height: '100px', width: '250px', overflowY: concurrerDetails && concurrerDetails.length > 0 && concurrerDetails[0].document_name && concurrerDetails[0].document_name.length > 2 ? "scroll" : "hidden" }}>


                                        {/* <thead>
                                            <tr>

                                                <th >Concurrer_Documents</th>
                                            </tr>
                                        </thead> */}

                                        {concurrerLabel && concurrerLabel.length > 0 && concurrerLabel.map((item) => {
                                            for (let k in item) {
                                                console.log(k, 'aishuuuu');
                                                return (
                                                    <>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <p>{item.label}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{item.value}</p>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </>

                                                )
                                            }
                                        })
                                        }


                                    </div>
                                </Card>
                                {/* <div className="row" >
                                    <div className="div" style={{ height: '100px', width: '250px', overflowY: concurrerLabel && concurrerLabel.length > 3 ? "scroll" : "hidden" }} >

                                        <table>
                                            <thead>
                                                <tr>

                                                    <th>Label_Info</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {concurrerLabel && concurrerLabel.length > 0 && concurrerLabel.map((item) => {
                                                    for (let k in item) {
                                                        console.log(k, 'aishuuuu');
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>{k}</td>
                                                                    <td>{item[k]}</td>
                                                                </tr>

                                                            </>

                                                        )
                                                    }
                                                })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}
                            </div>
                            : false}
                    </div></div> : false}
                {approverDetails && approverDetails.length > 0 ? <div style={{ marginTop: '5%' }}> <p style={{
                    fontWeight: 'bold',
                    background: '#337ab7', color: 'white'
                }}>{`Details Submitted by:${approverDetails && approverDetails.length > 0 && approverDetails[0].cert_by}`}</p>
                    <div className="row">
                        <div className="column" style={{ flex: '50%' }}>
                            {approverDetails && approverDetails.length > 0 && approverDetails[0].document_name && approverDetails[0].document_name.length > 0 ?

                                <Card size='small' className='ApproveCards' title='DocumentName' headStyle={{ fontWeight: 'bold', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ height: '150px' }}>
                                    <div className="row" style={{ height: '100px', width: '290px', overflowY: approverDetails && approverDetails.length > 0 && approverDetails[0].document_name && approverDetails[0].document_name.length > 0 ? "scroll" : "hidden" }}>


                                        {/* <thead>
                                            <tr>

                                                <th >Concurrer_Documents</th>
                                            </tr>
                                        </thead> */}

                                        {approverDetails && approverDetails.length > 0 && approverDetails[0].document_name && approverDetails[0].document_name.length > 0 && approverDetails[0].document_name.map((item) => {
                                            return (
                                                <>
                                                    {/* <p>   &nbsp;
                                                    </p> */}

                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <p>{item} </p>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <Button className={'fa fa-download  c-pointer  addbutton ' + localStorage.getItem('currentTheme')}
                                                                    icon={<DownloadOutlined />} size='small'
                                                                    onClick={(e) => {
                                                                        const downloadpayload =

                                                                            [
                                                                                authtoken,
                                                                                {
                                                                                    "session_token": authtoken,
                                                                                    "request": [
                                                                                        "DownloadFileCert",
                                                                                        {
                                                                                            "le_id": data.legalEntity,
                                                                                            "upload_type": 1,
                                                                                            "u_id": Number(vendorDetails.vendorid),
                                                                                            "file_value": item,
                                                                                            "c_id": Number(data.country)
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        console.log(downloadpayload, 'downloadpayload');
                                                                        Downloadfile({
                                                                            payload: downloadpayload,
                                                                            paramid: paramid
                                                                        });
                                                                    }}
                                                                >

                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>

                                            )
                                        })
                                        }
                                    </div>


                                </Card>





                                : false}     </div>
                        <div className="column" style={{ flex: '50%' }}>
                            {approveLabel && approveLabel.length > 0 ?

                                <Card size='small' className='ApproveCards' title='Label Info' headStyle={{ fontWeight: 'bold', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} style={{ marginLeft: '10%', height: '150px' }}>
                                    <div className="row" style={{ height: '100px', width: '250px', overflowY: approveLabel && approveLabel.length > 0 ? "scroll" : "hidden" }}>


                                        {/* <thead>
                                            <tr>

                                                <th >Concurrer_Documents</th>
                                            </tr>
                                        </thead> */}

                                        {approveLabel && approveLabel.length > 0 && approveLabel.map((item) => {
                                            for (let k in item) {
                                                console.log(k, 'aishuuuu');
                                                return (
                                                    <>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <p>{k}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{item[k]}</p>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </>

                                                )
                                            }
                                        })
                                        }


                                    </div>
                                </Card>



                                : false}  </div>
                    </div></div> : false}



                {/* <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-2">
                            <label>Vendor Code:</label>
                        </div>
                        <div className="col-md-4">  <input type='text' className='form-control' value={vendorDetails.code} ></input></div>
                        <div className="col-md-2">
                            <label>Certification process for the month of:</label>
                        </div>
                        <div className="col-md-4">  <input type='text' value={`${vendorDetails.month}-${vendorDetails.year}`} className='form-control' ></input></div>

                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-2">
                            <label>Vendor Name:</label>
                        </div>
                        <div className="col-md-4">  <input type='text' value={vendorDetails.name} className='form-control' ></input></div>
                        <div className="col-md-2">
                            <label>Attached Documents:</label>
                        </div>
                        <div className="col-md-4">  <input type='text' className='form-control' ></input></div>

                    </div>
                </div> */}
            </Modal>
            <Modal title='Select Additional Title' className={"remark-header modelradius add-service-prv labelModal  " + localStorage.getItem('currentTheme')} onCancel={handleCancel} open={labelModal} footer={null} width={350} maskClosable={false}>
                <Table
                    size={'small'}
                    // id='service-user-priv'
                    className={localStorage.getItem("currentTheme")}
                    // className='userprivclass'
                    columns={labelcolumns}
                    dataSource={labelLists && labelLists.label_name
                    }
                    bordered
                    // fixedHeader={dataTableProperties.fixedHeader}
                    // scroll={{ x: 1500, y: 1000 }}
                    pagination={false}
                    onHeaderRow={(columns, index) => {

                        return {
                            onClick: (e) => {

                                let checked = e.target.checked;
                                addAllSelectedDataValues(
                                    checked,
                                );
                            }

                        };
                    }}

                />
                <div className={"form-actions text-center popupbtncolour " + localStorage.getItem('currentTheme')}>


                    <Button type="primary" shape="round" className={'mt-2 addbutton ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={() => {
                            setLabelSubmit(labelCheckBox)
                            setLabelModal(false)
                            setChanges(true)
                            // if (labelCheckBox.length > 0) {
                            //     let temp = []
                            //     // temp = approveLabel
                            //     // for (let i in labelCheckBox) {
                            //     //     temp.push({
                            //     //         labelCheckBox[i]: ''
                            //     //     })
                            //     // }
                            //     // for (let k in approveLabel) {
                            //     //     for (let s in approveLabel[k]) {
                            //     if (approveLabel.length > 0) {
                            //         for (let j in approveLabel) {
                            //             console.log(approveLabel[j], 'approveLabel[j]');
                            //             for (let l in approveLabel[j]) {
                            //                 console.log(l, 'kkkkkkkkkkkkk');
                            //                 labelCheckBox && labelCheckBox.map((item, i) => {

                            //                     if (item == l) {
                            //                         approveLabel.splice(l, 1)
                            //                     }
                            //                 })
                            //             }
                            //         }
                            //     }
                            //     labelCheckBox && labelCheckBox.map((item, i) => {
                            //         // console.log(item !== k, 'item !== k');
                            //         // console.log(item, 'ishu');
                            //         // console.log(approveLabel[k][s], 'kkkkkkkk');
                            //         // if (approveLabel[k][s] !== k) {
                            //         temp.push({
                            //             [item]: ''
                            //         })
                            //         // }
                            //     })
                            //     //     }
                            //     // }

                            //     setApproveLabel([...temp])
                            // }

                            if (labelCheckBox.length > 0) {
                                let temp = []
                                let temp2 = []
                                // temp = approveLabel
                                console.log(approveLabel, 'approveLabel');
                                for (let j in approveLabel) {
                                    console.log(approveLabel[j], 'approveLabel[j]');
                                    for (let l in approveLabel[j]) {
                                        temp2.push(l)
                                    }
                                }
                                labelCheckBox && labelCheckBox.map((item, i) => {
                                    if (approveLabel.length > 0) {
                                        if (temp2.includes(item)) {
                                            for (let j in approveLabel) {
                                                console.log(approveLabel[j], 'approveLabel[j]');
                                                for (let l in approveLabel[j]) {
                                                    console.log(l, '689144444444');
                                                    if (item == l) {
                                                        if (Object.values(approveLabel[j]) == '') {
                                                            console.log('testttttttttttttttt');
                                                            approveLabel.splice(l, 1)
                                                            temp.push({
                                                                [item]: ''
                                                            })
                                                        }
                                                        else {
                                                            temp2.push(l)
                                                            temp.push({
                                                                [item]: Object.values(approveLabel[j]).toString()
                                                            })
                                                        }
                                                    }

                                                }
                                            }
                                        }
                                        else {

                                            temp.push({
                                                [item]: ''
                                            })


                                        }
                                    }
                                    else {
                                        temp.push({
                                            [item]: ''
                                        })
                                    }
                                })
                                console.log(temp, 'temp69696633');
                                // if (approveLabel.length > 0) {
                                //     for (let j in approveLabel) {
                                //         console.log(approveLabel[j], 'approveLabel[j]');
                                //         for (let l in approveLabel[j]) {
                                //             console.log(l, 'kkkkkkkkkkkkk');
                                //             labelCheckBox && labelCheckBox.map((item, i) => {
                                //                 console.log(Object.values(approveLabel[j]), 'approveLabel[j]');
                                //                 if (item == l) {
                                //                     if (Object.values(approveLabel[j]) == '') {
                                //                         approveLabel.splice(l, 1)
                                //                         temp.push({
                                //                             [item]: ''
                                //                         })
                                //                     }
                                //                     else {
                                //                         temp2.push(l)
                                //                         temp.push({
                                //                             [item]: Object.values(approveLabel[j])
                                //                         })
                                //                     }
                                //                 }
                                //             })
                                //         }
                                //     }
                                // }
                                // else {
                                //     labelCheckBox && labelCheckBox.map((item, i) => {


                                //         temp.push({
                                //             [item]: ''
                                //         })


                                //     })
                                // }



                                setApproveLabel([...temp])
                            }
                            else {
                                setApproveLabel([])
                            }
                        }
                        }
                    // onClick={userDetailsOnSubmit
                    // }
                    >
                        Submit
                    </Button>
                </div>

            </Modal>
            <Modal title='Compliance Status' className={"remark-header modelradius add-service-prv complianceStatus  " + localStorage.getItem('currentTheme')} onCancel={handleCancel} open={searchModal} footer={null} width={350} maskClosable={false}>
                <Table
                    // className='userprivclass'

                    // id='service-user-priv'
                    className={localStorage.getItem("currentTheme")}
                    size={'small'}
                    columns={searchcolumns}
                    dataSource={searchDatas && searchDatas.cmplist}
                    bordered
                    // fixedHeader={dataTableProperties.fixedHeader}
                    // scroll={{ x: 1500, y: 1000 }}
                    pagination={false} />


            </Modal>

            <Modal title="Remarks List" footer={null} open={remarksModal} className={"remark-header add-service-prv " + localStorage.getItem('currentTheme')}
                onOk={handleOk}
                onCancel={handleCancel} maskClosable={false}>
                <div style={{ height: '300px', overflowY: "scroll" }}>
                    <Table
                        size={'small'}
                        columns={RemarksColumn}
                        dataSource={Remarks}
                        bordered
                        pagination={false}
                    />
                </div>

            </Modal>
            <Modal
                title="Approve Compliance Certificate"
                className={
                    "add-service-prv " +
                    localStorage.getItem("currentTheme")
                }
                footer={false}
                open={filterModalVisible}
                onCancel={setcancelFilter}
                maskClosable={false}
            >
                <div className='col-md-12'>
                    <div className='row'>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor=""><b>Country :</b><span style={{ color: "red" }}> *</span></label><br />
                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                    <div className="form-group">
                                        <Select
                                            getPopupContainer={trigger => trigger.parentNode}

                                            placeholder='Enter Country'
                                            allowClear={true}
                                            size="default"
                                            onPaste={onPasteToaster}
                                            searchValue={selectSearchValue.country}
                                            onSearch={(value) => {
                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                setSelectSearchValue({
                                                    ...selectSearchValue,
                                                    country: result
                                                });
                                            }}
                                            onChange={(value, datas) => {
                                                setDomain([])
                                                setDomainValue(false)

                                                if (value !== undefined) {
                                                    console.log(value, 'valuevaluevalue');
                                                    let assignee = _.filter(sessionArr, { c_id: Number(value) })
                                                    console.log(assignee, 'assigneeassignee');
                                                    setEntityvalue(assignee)
                                                    setData({
                                                        ...data,
                                                        country: value,
                                                        countryName: datas.children,
                                                        domain: '',
                                                        domainname: '',
                                                        legalEntity: '',
                                                    })
                                                }

                                                else {
                                                    setData({
                                                        ...data,
                                                        country: "",
                                                        countryName: '',
                                                        legalEntity: '',
                                                        category: '',
                                                        division: '',
                                                        domain: '',
                                                        domainname: '',
                                                        vendor: '',
                                                        vendorName: '',
                                                        vendorCode: ''
                                                    })
                                                }
                                            }}

                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            value={data.country || undefined}
                                            style={{ width: '100%', marginTop: '5px' }}

                                        // value={unitdata.countryName || undefined}
                                        >
                                            {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                return (
                                                    <Option key={item.c_id}>
                                                        {item.c_name}
                                                    </Option>
                                                );
                                            })}


                                        </Select>
                                        {validator.current.message(
                                            'country',
                                            data.country,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Country Required',

                                                }
                                            })}
                                    </div> :
                                    <p>{data.countryName}</p>
                                }

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor=""><b>Legal Entity :</b><span style={{ color: "red" }}> *</span></label><br />

                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                    <div className="form-group">

                                        <Select
                                            getPopupContainer={trigger => trigger.parentNode}

                                            disabled={data.country == '' ? true : false}
                                            allowClear={true}
                                            size="default"
                                            placeholder="Enter Legal Entity"
                                            onPaste={onPasteToaster}
                                            searchValue={selectSearchValue.legalEntity}
                                            onSearch={(value) => {
                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                setSelectSearchValue({
                                                    ...selectSearchValue,
                                                    legalEntity: result
                                                });
                                            }}

                                            onChange={(value, datas) => {
                                                setDomain([])
                                                setDomainValue(false)

                                                if (value !== undefined) {
                                                    console.log(datas, 'valuevalue')
                                                    setData({
                                                        ...data,
                                                        legalEntity: value,
                                                        domain: '',
                                                        domainname: '',
                                                    })


                                                }
                                                else {
                                                    setData({
                                                        ...data,
                                                        legalEntity: '',
                                                        category: '',
                                                        division: '',
                                                        domain: '',
                                                        domainname: '',
                                                        vendor: '',
                                                        vendorName: '',
                                                        vendorCode: ''
                                                    })
                                                }
                                            }}
                                            showSearch
                                            optionFilterProp="children"
                                            value={data.legalEntity || undefined}
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            //value={Settings.le_id}
                                            style={{ width: '100%', marginTop: '5px' }}

                                        >

                                            {entityvalue && entityvalue.length > 0 && entityvalue.map((item, i) => {
                                                return (
                                                    <Option key={item.le_id}>
                                                        {item.le_name}
                                                    </Option>
                                                );
                                            })}


                                        </Select>

                                        {validator.current.message(
                                            'legal Entity',
                                            data.legalEntity,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Legal Entity Required',

                                                }
                                            })}
                                    </div> :
                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                }


                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor=""><b>Domain :</b><span style={{ color: "red" }}> *</span></label><br />
                                {domainValue == false || (domain && domain.length > 1) ?
                                    <div className="form-group">
                                        <Select
                                            getPopupContainer={trigger => trigger.parentNode}
                                            disabled={data.legalEntity == '' ? true : false}
                                            allowClear={true}
                                            size="default"
                                            placeholder="Enter Domain Name"
                                            onPaste={onPasteToaster}
                                            searchValue={selectSearchValue.domain}
                                            onSearch={(value) => {
                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                setSelectSearchValue({
                                                    ...selectSearchValue,
                                                    domain: result
                                                });
                                            }}
                                            onChange={(value, datas) => {
                                                if (value !== undefined) {
                                                    setData({
                                                        ...data,
                                                        domain: value,
                                                        domainname: datas.children
                                                    })
                                                } else {
                                                    setData({
                                                        ...data,
                                                        domain: '',
                                                        domainname: ''
                                                    })
                                                }
                                            }}
                                            value={data.domainname || undefined}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            style={{ width: '100%', marginTop: "5px" }}
                                        >
                                            {domain && domain.length > 0 && domain.map((item, i) => {
                                                return (
                                                    <Option key={item.d_id}>
                                                        {item.d_name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                        {validator.current.message(
                                            'domain',
                                            data.domainname,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Domain Required',
                                                }
                                            })}

                                    </div> :
                                    <> <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain && domain.length > 0 ? domain[0].d_name : '-'}</p>
                                        {validator.current.message(
                                            'domain',
                                            data.domainname,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Domain Required',

                                                }
                                            })}
                                    </>
                                }

                            </div>
                        </div>

                    </div>
                </div>
                <div className='col-md-12' style={{ marginTop: "5px" }}>
                    <div className='row'>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor=""><b>Division :</b></label><br />

                                <Select
                                    allowClear={true}
                                    disabled={data.legalEntity == '' ? true : false}
                                    size="default"
                                    placeholder="Enter Division Name"
                                    getPopupContainer={trigger => trigger.parentNode}
                                    value={data.division || undefined}
                                    onChange={(value, datas) => {
                                        if (value !== undefined) {
                                            setCategory([])
                                            getCategory(value)
                                            setData({
                                                ...data,
                                                division: value,
                                                category: '',
                                                vendor: '',
                                            })
                                        }
                                        else {
                                            setCategory(filtervalues.category_list)
                                            setData({
                                                ...data,
                                                division: '',
                                                category: ''
                                            })
                                        }
                                        if (value !== undefined) {
                                            let catData = _.filter(filtervalues && filtervalues.category_list, { div_id: Number(value) })
                                            setCategory(catData)
                                            let vendorData = _.filter(filtervalues && filtervalues.unit_list, { division_id: Number(value) })
                                            console.log(vendorData, 'vendorData');
                                            setVendor(vendorData)
                                        } else {
                                            // console.log(filterValue && filterValue.units_list, 'filterValue && filterValue.units_list');
                                            setCategory(filtervalues && filtervalues.category_list)
                                            setVendor(filtervalues && filtervalues.unit_list
                                            )
                                        }
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{ width: '100%', marginTop: "5px" }}
                                >
                                    {division && division.length > 0 && division.map((item, i) => {
                                        return (
                                            <Option key={item.div_id
                                            }>
                                                {item.div_name
                                                }
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor=""><b>Category :</b></label><br />

                                <Select
                                    disabled={data.legalEntity == '' ? true : false}
                                    allowClear={true}
                                    size="default"
                                    placeholder="Enter Category Name"
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={(value, datas) => {
                                        if (value !== undefined) {
                                            // setVendor([])
                                            getVendor(value)

                                            setData({
                                                ...data,
                                                category: value,
                                                vendor: '',
                                            })
                                        }
                                        else {
                                            // setVendor(filtervalues.unit_list)
                                            setData({
                                                ...data,
                                                category: '',
                                                vendor: '',
                                            })
                                        }
                                        if (value !== undefined) {

                                            let vendorData = _.filter(filtervalues && filtervalues.unit_list, { category_id: Number(value) })
                                            console.log(vendorData, 'vendorData');
                                            setVendor(vendorData)
                                        } else {
                                            let vendorData = _.filter(filtervalues && filtervalues.unit_list, { division_id: Number(data.division) })
                                            console.log(vendorData, 'vendorData');
                                            setVendor(vendorData)
                                        }

                                    }}
                                    value={data.category || undefined}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{ width: '100%', marginTop: "5px" }}
                                >
                                    {category && category.length > 0 && category.map((item, i) => {
                                        return (
                                            <Option key={item.cat_id}>
                                                {item.cat_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor=""><b>Vendor Name :</b></label><br />

                                <Select
                                    allowClear={true}
                                    disabled={data.legalEntity == '' ? true : false}
                                    size="default"
                                    placeholder="Enter Vendor"
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={(value, datas) => {
                                        if (value !== undefined) {
                                            setTableShow(false)
                                            console.log(datas, 'datas');
                                            let splitName = datas.children.split('-')
                                            console.log(splitName, 'splitName');
                                            setData({
                                                ...data,
                                                vendor: value,
                                                vendorName: datas.children,
                                                vendorCode: splitName && splitName.length > 0 && splitName[0]
                                            })
                                        }
                                        else {
                                            setTableShow(false)

                                            setData({
                                                ...data,
                                                vendor: '',
                                                vendorName: '',
                                                vendorCode: ''
                                            })
                                        }
                                    }}
                                    value={data.vendor || undefined}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{ width: '100%', marginTop: "5px" }}
                                >
                                    {vendor && vendor.length > 0 && vendor.map((item, i) => {
                                        return (
                                            <Option key={item.unit_id
                                            }>
                                                {`${item.unit_code}-${item.unit_name}`
                                                }
                                            </Option>
                                        );
                                    })}
                                </Select>

                            </div>
                        </div>

                    </div>
                </div>
                <div className='col-md-12 mb-1'>
                    <div className='row'>
                        <div className='col-md-4'></div>
                        <div className='col-md-4 text-center popupbtncolour'>
                            {/* <Button type="primary" shape="round" className='addbutton' style={{ marginRight: "10px" }}
                                                    onClick={onCreate}
                                                >

                                                    Create Certificate
                                                </Button> */}
                            <Button shape="round" className='addbutton' style={{ marginTop: "7px", marginRight: '11%' }}
                                icon={<EyeOutlined />} onClick={onshow}
                            >
                                Show
                            </Button>

                        </div>
                        <div className='col-md-4'></div>
                    </div>
                </div>
            </Modal>
        </Fragment >

    )


}
export default connect(mapStateToProps, {
    getComplianceCertificateFilter,
    getApproveComplianceCertificateTable,
    getComplianceCertificateTable,
    viewComplianceStatus,
    viewComplianceDetails,
    viewApproveComplianceStatus,
    ApprovemappingDetails,
    mappingDetails,
    getLabelList,
    UploadFile,
    Downloadfile,
    approveCertificate,
    submitComplianceCertificate,
    searchCompliance,
    viewApproveComplianceDetails,
    getApproveComplianceCertificateFilter,
    AddAdditionalInformation,
    certificateMailData,
    RemarksData,
    getDocumentTotalSize,
    saveCertDocuments
})(CV_ApproveCompliances);