import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { COMPLETED_TASK_LIST, STATUTORY_BY_UNIT, STATUTORY_BY_UNIT_SHOWMORE } from '../../types/index'


const initialState = {
    completedTask: {
        division: '',
        category: '',
        unit: '',
        frequency: '',
        domain: '',
        act: '',
        entity: '',
        statutoryName: '',
        totalCount: '',
        batchCount: ''

    },
    statutoryListByUnitshow: '',
    ctcyshowmore: ''

}
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case COMPLETED_TASK_LIST:
            {
                return {
                    ...state,
                    completedTask: {
                        ...state.completedTask,
                        frequency: payload[1].compliance_frequency,
                        division: payload[1].client_divisions,
                        category: payload[1].pr_categories,
                        unit: payload[1].in_units,
                        domain: payload[1].domains,
                        act: payload[1].level_1_statutories,
                        entity: payload[1].pr_legal_entities

                    },
                };
            }
        case STATUTORY_BY_UNIT:
            {
                return {
                    ...state,
                    completedTask: {
                        ...state.completedTask,
                        totalCount: payload[1].total_count,
                        batchCount: payload[1].batch_count,
                        IsClosed: payload[1].is_closed,
                        tzname: payload[1].tz_name
                    },
                    statutoryListByUnitshow: payload[1].statutory_wise_compliances,

                };
            }
        case STATUTORY_BY_UNIT_SHOWMORE:
            {
                console.log(payload, 'payloadpayload');
                return {
                    ...state,
                    completedTask: {
                        ...state.completedTask,
                        totalCount: payload[1].total_count,
                        batchCount: payload[1].batch_count,
                        IsClosed: payload[1].is_closed,
                        tz_name: payload[1].tz_name
                    },
                    ctcyshowmore: [payload[1].statutory_wise_compliances],
                    // ctcyshowmore: [...state.ctcyshowmore,payload[1].statutory_wise_compliances],


                };
            }


        default:
            return state;
    }
}