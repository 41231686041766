import { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import { connect, Provider } from 'react-redux';
import { Card, Select, Progress, Spin, Button } from 'antd';
import { Link } from 'react-router-dom';
import { Area, Line } from '@ant-design/plots';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useLocation, useHistory } from 'react-router-dom';
import {
    getWidgetData, getChartFilters, getRiskChart, getOverDueChart,
    getComplianceStatusChart, getTaskDistributionChart, getCriticalityChart,
    getComplianceTrend, getCountryData, getDomainscorecard
} from '../../Store/Action/Widgets';
import { SaveWidgetlist } from '../../Store/Action/Dashboard'
import { Toaster } from '../../Libs/Toaster';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';


const mapStateToProps = (state) => ({
    widgetInfo: state.Widgets.widInfo,
    chartInfo: state.Widgets.chartFilter,
    riskWidget: state.Widgets.riskWid,
    overdueWidget: state.Widgets.overDueWid,
    complianceStatusWidget: state.Widgets.complianceStatusChart,
    taskDistributionWidget: state.Widgets.taskdistributionWid,
    criticalityWidget: state.Widgets.criticalityWid,
    complianceTrendWidget: state.Widgets.complianceTrendWid,
    countryWidgetData: state.Widgets.countryInfo,
    DomainscorecardData: state.Widgets.domaincardid
    // commonData: state.ComplianceStatusChart.complianceStatusChart.Data,

})
const LandingPage = ({
    getWidgetData,
    widgetInfo,
    getRiskChart,
    riskWidget,
    getCommonData,
    commonData,
    getOverDueChart,
    overdueWidget,
    getComplianceStatusChart,
    complianceStatusWidget,
    getTaskDistributionChart,
    taskDistributionWidget,
    getCriticalityChart,
    criticalityWidget,
    getComplianceTrend,
    complianceTrendWidget,
    getCountryData,
    countryWidgetData,
    getChartFilters,
    chartInfo,
    getDomainscorecard,
    DomainscorecardData,
    SaveWidgetlist
}) => {
    const _ = require("lodash");
    const history = useHistory();
    const [dayStat, setDayStat] = useState(false)
    const [complianceApprovalUrl, setComplianceApprovalUrl] = useState('')
    const [complianceFormKey, setComplianceFormKey] = useState('')
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const isAuth = localStorage.getItem('isAuthenticated');
    const login = callername && callername.login_response.menu.Static && callername.login_response.menu.Static[0];
    const dashboardurl = callername && callername.login_response.menu.Dashboard && callername.login_response.menu.Dashboard[0];
    const [entityData, setEntityData] = useState([])
    const [countryDomain, setCountryDomain] = useState([])
    const [overdueId, setOverdueId] = useState()
    const [cricId, setCricId] = useState()
    const [taskDisId, setTaskDisId] = useState()
    const [compTrendId, setcompTrendId] = useState()
    const [comparativeTrendId, setcomparativeTrendId] = useState()
    const [type, setType] = useState('')
    const [riskId, setRiskId] = useState()
    const [compStatusId, setCompStatusId] = useState()

    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const sessionArr = sessionParsedValue.entity_info
    const location = useLocation();
    const { Option } = Select;
    const paramid = location.pathname;
    const [compTrendData, setCompTrendData] = useState([])
    const [compliedArray, setCompliedArray] = useState([])
    const [delayedCompliedArray, setDelayedArray] = useState([])
    const [notCompliedArray, setNotCompliedArray] = useState([])
    const [myWidgets, setMyWidgets] = useState([])
    const [landingWidgets, setLandingWidgets] = useState([])
    const [DomaincardId, setDomaincardId] = useState()
    const [newTableArray, setNewTableArray] = useState([])

    const [totalArray, setTotalArray] = useState([])
    const [totalArray1, setTotalArray1] = useState(false)
    console.log(totalArray, 'totalArray');
    console.log(totalArray1, 'totalArray1');
    const [LegalName, setLegalName] = useState({
        legal_name: 'All',
        legal_id: ''
    })
    const [leArray, setLeArray] = useState([])
    console.log(LegalName, "LegalNameLegalName");
    const [calendarId, setCalendarId] = useState()
    const [eventData, setEventData] = useState([]);
    const [calendarLeValue, setCalendarLeValue] = useState('All')
    const [complianceStatusChartData, setComplianceStatusChartData] = useState([])
    const [compliedCount, setCompliedCount] = useState()
    const [compliedPercent, setCompliedPercent] = useState()
    const [delayedPercent, setDelayedPercent] = useState()
    const [inProgressPercent, setInProgressPercent] = useState()
    const [notCompliedPercent, setnotCompliedPercent] = useState()
    const [delayedCount, setDelayedCount] = useState()
    const [inprogressCount, setInprogressCount] = useState()
    const [notCompliedCount, setNotCompliedCount] = useState()
    const [totalValue, setTotalValue] = useState()
    console.log(eventData, 'eventData');
    console.log(compliedPercent, 'compliedCount');
    console.log(delayedPercent, 'delayedCount');
    console.log(inProgressPercent, 'inprogressCount');
    console.log(notCompliedPercent, 'notCompliedCount');
    const localizer = momentLocalizer(moment);
    const { views } = useMemo(() => ({
        views: {
            month: true,

        },


    }), []);

    const CustomToolbar = (e) => {
        return <div className="col-md-4 text-center align-self-center">
            <label ><b>{e.label.replace(" ", " - ")}</b></label>
        </div>

    };




    const data =
        [
            { "Year": "2022", "date": 'Jan', "value": 1390 },
            { "Year": "2022", "date": 'Feb', "value": 1469 },
            { "Year": "2022", "date": 'Mar', "value": 1521 },
            { "Year": "2022", "date": 'Apr', "value": 1615 },
            { "Year": "2022", "date": 'May', "value": 1703 },
            { "Year": "2022", "date": 'Jun', "value": 1767 },
            { "Year": "2022", "date": 'Jul', "value": 1806 },
            { "Year": "2022", "date": 'Aug', "value": 1903 },
            { "Year": "2022", "date": 'Sep', "value": 1986 },
            { "Year": "2022", "date": 'Oct', "value": 1952 },
            { "Year": "2022", "date": 'Nov', "value": 1910 },
            { "Year": "2022", "date": 'Dec', "value": 2015 },
            { "Year": "2023", "date": 'Jan', "value": 1000 },
            { "Year": "2023", "date": 'Feb', "value": 1100 },
            { "Year": "2023", "date": 'Mar', "value": 1200 },
            { "Year": "2023", "date": 'Apr', "value": 1300 },
            { "Year": "2023", "date": 'May', "value": 1541 },
            { "Year": "2023", "date": 'Jun', "value": 1364 },
            { "Year": "2023", "date": 'Jul', "value": 3541 },
            { "Year": "2023", "date": 'Aug', "value": 1955 },
            { "Year": "2023", "date": 'Sep', "value": 5410 },
            { "Year": "2023", "date": 'Oct', "value": 1345 },
            { "Year": "2023", "date": 'Nov', "value": 2052 },
            { "Year": "2023", "date": 'Dec', "value": 3546 },
        ]
    const dataline =
        [
            {
                "year": "Jan",
                "value": 30,
                "category": "Complied"
            },
            {
                "year": "Jan",
                "value": 54,
                "category": "Delayed Compliance"
            },
            {
                "year": "Jan",
                "value": 90,
                "category": "Not Complied"
            },
            {
                "year": "Feb",
                "value": 110,
                "category": "Complied"
            },
            {
                "year": "Feb",
                "value": 94,
                "category": "Delayed Compliance"
            },
            {
                "year": "Feb",
                "value": 80,
                "category": "Not Complied"
            },
            {
                "year": "Mar",
                "value": 75,
                "category": "Complied"
            },
            {
                "year": "Mar",
                "value": 45,
                "category": "Delayed Compliance"
            },
            {
                "year": "Mar",
                "value": 56,
                "category": "Not Complied"
            },
            {
                "year": "Apr",
                "value": 40,
                "category": "Complied"
            },
            {
                "year": "Apr",
                "value": 44,
                "category": "Delayed Compliance"
            },
            {
                "year": "Apr",
                "value": 64,
                "category": "Not Complied"
            },
            {
                "year": "May",
                "value": 65,
                "category": "Complied"
            },
            {
                "year": "May",
                "value": 75,
                "category": "Delayed Compliance"
            },
            {
                "year": "May",
                "value": 65,
                "category": "Not Complied"
            },
            {
                "year": "Jun",
                "value": 74,
                "category": "Complied"
            },
            {
                "year": "Jun",
                "value": 54,
                "category": "Delayed Compliance"
            },
            {
                "year": "Jun",
                "value": 90,
                "category": "Not Complied"
            },
            {
                "year": "Jul",
                "value": 80,
                "category": "Complied"
            },
            {
                "year": "Jul",
                "value": 70,
                "category": "Delayed Compliance"
            },
            {
                "year": "Jul",
                "value": 80,
                "category": "Not Complied"
            },
            {
                "year": "Aug",
                "value": 75,
                "category": "Complied"
            },
            {
                "year": "Aug",
                "value": 46,
                "category": "Delayed Compliance"
            },
            {
                "year": "Aug",
                "value": 90,
                "category": "Not Complied"
            },
            {
                "year": "Sep",
                "value": 65,
                "category": "Complied"
            },
            {
                "year": "Sep",
                "value": 54,
                "category": "Delayed Compliance"
            },
            {
                "year": "Sep",
                "value": 84,
                "category": "Not Complied"
            },
            {
                "year": "Oct",
                "value": 76,
                "category": "Complied"
            },
            {
                "year": "Oct",
                "value": 45,
                "category": "Delayed Compliance"
            },
            {
                "year": "Oct",
                "value": 96,
                "category": "Not Complied"
            },
            {
                "year": "Nov",
                "value": 60,
                "category": "Complied"
            },
            {
                "year": "Nov",
                "value": 74,
                "category": "Delayed Compliance"
            },
            {
                "year": "Nov",
                "value": 80,
                "category": "Not Complied"
            },
            {
                "year": "Dec",
                "value": 110,
                "category": "Complied"
            },
            {
                "year": "Dec",
                "value": 90,
                "category": "Delayed Compliance"
            },
            {
                "year": "Dec",
                "value": 70,
                "category": "Not Complied"
            },

        ]
    const configs = {
        data: data,
        xField: 'date',
        yField: 'value',
        seriesField: 'Year',
        legend: {
            position: 'bottom'
        }
    };
    useEffect(() => {
        localStorage.setItem('ApprovalPending', false)
    }, [])

    useEffect(() => {
        let temp = []
        if (widgetInfo) {
            if (widgetInfo && widgetInfo.widget_list && widgetInfo.widget_list.length > 0
            ) {
                for (let i in widgetInfo && widgetInfo.widget_list && widgetInfo.widget_list) {
                    temp.push(widgetInfo && widgetInfo.widget_list && widgetInfo.widget_list[i].w_name)
                }
            }
        }
        localStorage.setItem('WidgetInfo', JSON.stringify(temp))

    }, [widgetInfo])

    const configline = {
        data: dataline,
        xField: 'year',
        yField: 'value',
        seriesField: 'category',
        yAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        color: ['#1979C9', '#D62A0D', '#FAA219'],
        legend: {
            position: 'bottom',
        },
        smooth: false,

    };


    useEffect(() => {
        if (countryWidgetData) {
            localStorage.setItem('commonData1', JSON.stringify(countryWidgetData))
            localStorage.setItem('filterState', false)
            localStorage.setItem('countryState', false)
        }

    }, [countryWidgetData])

    console.log(countryWidgetData, 'countryWidgetData');

    useEffect(() => {
        if (countryWidgetData && countryWidgetData.d_info && countryWidgetData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            for (let i in countryWidgetData.d_info) {
                tempIds.push(countryWidgetData.d_info[i].d_id)
                tempArr.push({
                    'd_id': countryWidgetData.d_info[i].d_id,
                    'c_id': countryWidgetData.d_info[i].c_id,
                    "month_from": countryWidgetData.d_info[i].month_from,
                    "month_to": countryWidgetData.d_info[i].month_to
                })
            }

            // setDIds(tempIds)
            setCountryDomain(tempArr)

        }

    }, [countryWidgetData])

    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
            return item.le_id
        })
        setEntityData(id)
        // }
    }, [])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === 'All Legal Entity') {
            setLeArray(sessionArr)
        }
        else {
            let leObj = _.filter(sessionArr, { le_id: entityid })
            console.log(leObj, 'leObj');
            setLeArray(leObj)
            setCalendarLeValue(leObj && leObj[0].le_id)
            setLegalName({
                ...LegalName,
                legal_id: leObj && leObj[0].le_id,
                legal_name: leObj && leObj[0].le_name
            })

        }
    }, [localStorage.getItem('SelectedEntity')])

    console.log(leArray, 'leArray');

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCountryData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityData, entityid])


    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUserWidgetData",
                        {}
                    ]
                }
            ]
            // const payload1 = [
            //     authtoken,
            //     {
            //         "session_token": authtoken,
            //         "request": [
            //             "GetChartFilters",
            //             {
            //                 "le_ids": entityData
            //             }
            //         ]
            //     }
            // ]
            if (paramid != "") {
                getWidgetData({
                    payload: payload,
                    paramid: paramid
                })
                // getChartFilters({
                //     payload: payload1,
                //     paramid: paramid
                // })

            }
        }
    }, [isAuth, entityid, entityData])



    useEffect(() => {
        if (widgetInfo && countryDomain.length > 0) {
            if (widgetInfo && widgetInfo.widget_info && widgetInfo.widget_info.length > 0) {

                let overId = _.find(widgetInfo.widget_info, { w_id: 3 })
                if (overId) {
                    setOverdueId(overId.w_id)
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetNotCompliedChart",
                                {
                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    getOverDueChart({
                        payload: payload,
                        caller: login.form_url
                    })
                }
                let critiId = _.find(widgetInfo.widget_info, { w_id: 12 })
                if (critiId) {
                    setCricId(critiId.w_id)
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetTaskCriticality",
                                {
                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    getCriticalityChart({
                        payload: payload,
                        caller: login.form_url
                    })
                }
                let taskId = _.find(widgetInfo.widget_info, { w_id: 11 })
                if (taskId) {
                    setTaskDisId(taskId.w_id)
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetTaskDistributionWidget",
                                {
                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    getTaskDistributionChart({
                        payload: payload,
                        caller: login.form_url
                    })
                }
                let complianceTrend = _.find(widgetInfo.widget_info, { w_id: 10 })
                if (complianceTrend) {
                    setcompTrendId(complianceTrend.w_id)
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetComplianceTrend",
                                {
                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    getComplianceTrend({
                        payload: payload,
                        caller: login.form_url
                    })
                }
                let comparativeTrend = _.find(widgetInfo.widget_info, { w_id: 4 })
                if (comparativeTrend) {
                    setcomparativeTrendId(comparativeTrend.w_id)
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetComplianceTrend",
                                {
                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    getComplianceTrend({
                        payload: payload,
                        caller: login.form_url
                    })
                }

                let riskWId = _.find(widgetInfo.widget_info, { w_id: 5 })
                if (riskWId) {
                    setRiskId(riskWId.w_id)
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetRiskChart",
                                {
                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    getRiskChart({
                        payload: payload,
                        caller: login.form_url
                    })
                }
                let complianceStatusId = _.find(widgetInfo.widget_info, { w_id: 1 })
                if (complianceStatusId) {
                    setCompStatusId(complianceStatusId.w_id)
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetComplianceChart",
                                {
                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    getComplianceStatusChart({
                        payload: payload,
                        caller: login.form_url
                    })
                }


                let calendar = _.find(widgetInfo.widget_info, { w_id: 8 })
                if (calendar) {
                    setCalendarId(calendar.w_id)
                    const payload1 = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetCalendarView",
                                {
                                    "le_ids": localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? entityData : [entityid]
                                }
                            ]
                        }
                    ]
                    getChartFilters({
                        payload: payload1,
                        caller: login.form_url
                    })
                }


            }
        }

    }, [widgetInfo, countryDomain])


    console.log(countryDomain, 'countryDomain');

    //Domain Scorecard API
    useEffect(() => {
        if (widgetInfo && widgetInfo.widget_info && widgetInfo.widget_info.length > 0) {
            let Domainscorecardid = _.find(widgetInfo.widget_info, { w_id: 7 })
            if (Domainscorecardid) {
                setDomaincardId(Domainscorecardid.w_id)
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetDomainScoreCard",
                            {
                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],

                            }
                        ]
                    }
                ]
                getDomainscorecard({
                    payload: payload,
                    caller: login.form_url
                })
            }
        }
    }, [widgetInfo, entityData, entityid])

    // useEffect(() => {
    //     if (LegalName.legal_id !== '' && localStorage.getItem('SelectedEntity') != "All Legal Entity") {
    //         const payload = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "GetDomainScoreCard",
    //                     {
    //                         "le_ids": LegalName.legal_id == 'all' ? entityData : [LegalName.legal_id],

    //                     }
    //                 ]
    //             }
    //         ]
    //         getDomainscorecard({
    //             payload: payload,
    //             caller: login.form_url
    //         })
    //     }
    // }, [LegalName, localStorage.getItem('SelectedEntity')])


    useEffect(() => {
        if (overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0) {
            am4core.useTheme(am4themes_animated);
            //overduechart
            var chartoverdue = am4core.create("chartdiv", am4charts.RadarChart);
            let total = (overdueWidget && overdueWidget.widget_data && overdueWidget && overdueWidget.widget_data[0].y) + (overdueWidget && overdueWidget.widget_data && overdueWidget && overdueWidget.widget_data[1].y) + (overdueWidget && overdueWidget.widget_data && overdueWidget && overdueWidget.widget_data[2].y) + (overdueWidget && overdueWidget.widget_data && overdueWidget && overdueWidget.widget_data[3].y)
            chartoverdue.height = '330'
            chartoverdue.width = '500'
            chartoverdue.data = [
                overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[0].y > 0 ?
                    {
                        "category": "0-30 days",
                        "value": (Math.round(overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[0].y) / (total) * 100).toFixed(2),
                        "full": 100,
                        "custom": overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[0].y
                    } : {},
                overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[1].y > 0 ?
                    {
                        "category": "31-60 days",
                        "value": (Math.round(overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[1].y) / (total) * 100).toFixed(2),
                        "full": 100,
                        "custom": overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[1].y

                    } : {},
                overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[2].y > 0 ?
                    {
                        "category": "61-90 days",
                        "value": (Math.round(overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[2].y) / (total) * 100).toFixed(2),
                        "full": 100,
                        "custom": overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[2].y

                    } : {},
                overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[3].y > 0 ?
                    {
                        "category": "Above 90 days",
                        "value": (Math.round(overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[3].y) / (total) * 100).toFixed(2),
                        "full": 100,
                        "custom": overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[3].y

                    } : {}];
            chartoverdue.startAngle = -90;
            chartoverdue.endAngle = 180;
            chartoverdue.innerRadius = am4core.percent(20);
            // chartoverdue.numberFormatter.numberFormat = "#.#'%'";
            chartoverdue.colors.list = [am4core.color("#FC9C80"), am4core.color("#EF736A"), am4core.color("#FA5F53"), am4core.color("#F94B64")];
            var categoryAxis = chartoverdue.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "category";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.grid.template.strokeOpacity = 0;
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.fontWeight = 500;
            categoryAxis.renderer.labels.template.adapter.add("fill", function (fill, target) {
                return (target.dataItem.index >= 0) ? chartoverdue.colors.getIndex(target.dataItem.index) : fill;
            });
            categoryAxis.renderer.minGridDistance = 10;

            var valueAxis = chartoverdue.xAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.grid.template.strokeOpacity = 0;
            valueAxis.min = 0;
            valueAxis.max = 100;
            valueAxis.strictMinMax = true;

            // Create series
            var series1 = chartoverdue.series.push(new am4charts.RadarColumnSeries());
            series1.dataFields.valueX = "full";
            series1.dataFields.categoryY = "category";
            series1.clustered = false;
            series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
            series1.columns.template.fillOpacity = 0.08;
            series1.columns.template.cornerRadiusTopLeft = 20;
            series1.columns.template.strokeWidth = 0;
            series1.columns.template.radarColumn.cornerRadius = 20;
            series1.columns.template.tooltipText = `{category}: [bold]{custom}[/] out of ${total}`;


            var series2 = chartoverdue.series.push(new am4charts.RadarColumnSeries());
            series2.dataFields.valueX = "value";
            series2.dataFields.categoryY = "category";
            series2.clustered = false;
            series2.columns.template.strokeWidth = 0;
            // series2.columns.template.tooltipText = "{category}: [bold]{value}({custom})[/]";
            series2.columns.template.tooltipText = `{category}: [bold]{custom}[/] out of ${total} ({value}%)`;
            series2.columns.template.radarColumn.cornerRadius = 20;

            series2.columns.template.adapter.add("fill", function (fill, target) {
                return chartoverdue.colors.getIndex(target.dataItem.index);
            });

            series2.events.on("dataitemsvalidated", function () {
                var data = [];
                series2.dataItems.each(function (dataItem) {
                    data.push({ name: dataItem.categoryY, fill: dataItem.column.fill, seriesDataItem: dataItem })
                })

                chartoverdue.legend.data = data;
                chartoverdue.legend.itemContainers.template.events.on("toggled", function (event) {
                    var seriesDataItem = event.target.dataItem.dataContext.seriesDataItem;
                    if (event.target.isActive) {
                        seriesDataItem.hide(series2.interpolationDuration, 0, 0, ["valueX"]);
                    }
                    else {
                        seriesDataItem.show(series2.interpolationDuration, 0, ["valueX"]);
                    }
                })
            })

            chartoverdue.legend = new am4charts.Legend();

            // Add cursor
            chartoverdue.cursor = new am4charts.RadarCursor();
        }
    }, [overdueWidget, landingWidgets])

    //Criticality Widget useEffect 

    useEffect(() => {
        if (criticalityWidget) {
            am4core.useTheme(am4themes_animated);
            var criticalitychart = am4core.create("criticalityDiv", am4charts.XYChart);
            criticalitychart.hiddenState.properties.opacity = 0; // this creates initial fade-in         
            criticalitychart.paddingBottom = 20;
            let total = (criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 && criticalityWidget.widget_data[0].y) + (criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 && criticalityWidget.widget_data[1].y) + (criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 && criticalityWidget.widget_data[2].y)
            criticalitychart.data = [{
                "name": "(A) High",
                "steps": criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 && criticalityWidget.widget_data[0].y,
                "href": "High.png",
                color: '#F94B64',
                "percent": (Math.round(criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 && criticalityWidget.widget_data[0].y) / (total) * 100).toFixed(2),
            }, {
                "name": "(B) Medium",
                "steps": criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 && criticalityWidget.widget_data[1].y,
                "href": "Medium.png",
                color: '#FA5F53',
                "percent": (Math.round(criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 && criticalityWidget.widget_data[1].y) / (total) * 100).toFixed(2),
            }, {
                "name": "(C) Low",
                "steps": criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 && criticalityWidget.widget_data[2].y,
                "href": "Low.png",
                color: '#F2746B',
                "percent": (Math.round(criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 && criticalityWidget.widget_data[2].y) / (total) * 100).toFixed(2),
            }];
            var criticalitycategoryAxis = criticalitychart.xAxes.push(new am4charts.CategoryAxis());
            criticalitycategoryAxis.dataFields.category = "name";
            criticalitycategoryAxis.renderer.grid.template.strokeOpacity = 0;
            criticalitycategoryAxis.renderer.minGridDistance = 5;
            criticalitycategoryAxis.renderer.labels.template.dy = 30;
            criticalitycategoryAxis.renderer.labels.template.marginTop = 15;
            criticalitycategoryAxis.renderer.tooltip.dy = 30; var criticalityvalueAxis = criticalitychart.yAxes.push(new am4charts.ValueAxis());
            criticalityvalueAxis.renderer.inside = true;
            criticalityvalueAxis.renderer.labels.template.fillOpacity = 0.5;
            criticalityvalueAxis.renderer.grid.template.strokeOpacity = 0;
            criticalityvalueAxis.min = 0;
            criticalityvalueAxis.cursorTooltipEnabled = false;
            criticalityvalueAxis.renderer.baseGrid.strokeOpacity = 0; var scriticalityseries = criticalitychart.series.push(new am4charts.ColumnSeries);
            scriticalityseries.dataFields.valueY = "steps";
            scriticalityseries.dataFields.categoryX = "name";
            scriticalityseries.tooltipText = `{name}: {valueY.value} out of ${Number(total)} ({percent}%)`;
            scriticalityseries.tooltip.pointerOrientation = "vertical";
            scriticalityseries.tooltip.dy = - 6;
            scriticalityseries.columnsContainer.zIndex = 100;
            scriticalityseries.columns.template.propertyFields.fill = "color"; var columnTemplate = scriticalityseries.columns.template;
            columnTemplate.width = am4core.percent(50);
            columnTemplate.maxWidth = 55;
            columnTemplate.column.cornerRadius(60, 60, 10, 10);
            columnTemplate.strokeOpacity = 0; scriticalityseries.heatRules.push({ target: columnTemplate, property: "fill", dataField: "valueY", min: am4core.color("#e5dc36"), max: am4core.color("#5faa46") });
            scriticalityseries.mainContainer.mask = undefined;

            // criticalitychart.lineX.disabled = true;
            // criticalitychart.lineY.disabled = true;

            var cursor = new am4charts.XYCursor();
            criticalitychart.cursor = cursor;
            cursor.lineX.disabled = true;
            cursor.lineY.disabled = true;
            cursor.behavior = "none";

            criticalitychart.scrollbarY = new am4core.Scrollbar();


            criticalitychart.behavior = "none";
            var bullet = columnTemplate.createChild(am4charts.CircleBullet);
            bullet.circle.radius = 30;
            bullet.valign = "bottom";
            bullet.align = "center";
            bullet.isMeasured = true;
            bullet.mouseEnabled = false;
            bullet.verticalCenter = "bottom";
            bullet.interactionsEnabled = false;
            var hoverState = bullet.states.create("hover");
            // var outlineCircle = bullet.createChild(am4core.Circle);
            // outlineCircle.adapter.add("radius", function (radius, target) {
            //     var circleBullet = target.parent;
            //     return circleBullet.circle.pixelRadius + 10;
            // })
            var image = bullet.createChild(am4core.Image);
            image.width = 60;
            image.height = 60;
            image.horizontalCenter = "middle";
            image.verticalCenter = "middle";
            image.propertyFields.href = "href"; image.adapter.add("mask", function (mask, target) {
                var circleBullet = target.parent;
                return circleBullet.circle;
            })
            var previousBullet;
            criticalitychart.cursor.events.on("cursorpositionchanged", function (event) {
                var dataItem = scriticalityseries.tooltipDataItem; if (dataItem.column) {
                    var bullet = dataItem.column.children.getIndex(1); if (previousBullet && previousBullet != bullet) {
                        previousBullet.isHover = false;
                    } if (previousBullet != bullet) {
                        var hs = bullet.states.getKey("hover");
                        hs.properties.dy = -bullet.parent.pixelHeight + 30;
                        bullet.isHover = true; previousBullet = bullet;
                    }
                }
            })
        }

    }, [criticalityWidget, landingWidgets])

    //Task Distribution Widget

    console.log(taskDistributionWidget,'taskDistributionWidget');

    useEffect(() => {
        if (taskDistributionWidget) {
            console.log(taskDistributionWidget, 'taskDistributionWidget');
            am4core.useTheme(am4themes_animated);
            var taskchart = am4core.create("taskchartdiv", am4charts.XYChart);
            let totalTask = taskDistributionWidget && taskDistributionWidget.Total_task;
            let totalNumber;
            if (totalTask) {
                totalNumber = Number(totalTask)
            }
            console.log(totalTask, totalNumber, 'totalTask');
            taskchart.responsive.enabled = 'true'
            taskchart.data = [
                taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[0].y > 0 ?
                    {
                        "country": "Assigned",
                        "visits": taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[0].y,
                        "percent": (Math.round(taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[0].y) / totalNumber * 100).toFixed(2),
                        color: taskchart.colors.getIndex(3),
                    } : {},
                taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[2].y > 0 ?
                    {
                        "country": "Not Opted",
                        "visits": taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[2].y,
                        "percent": (Math.round(taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[2].y) / totalNumber * 100).toFixed(2),
                        color: taskchart.colors.getIndex(5),

                    } : {},
                taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[1].y > 0 ?
                    {
                        "country": "Unassigned",
                        "visits": taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[1].y,
                        "percent": (Math.round(taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[1].y) / totalNumber * 100).toFixed(2),
                        color: taskchart.colors.getIndex(9),

                    } : {},
                taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[3].y > 0 ?
                    {
                        "country": "Not Applicable",
                        "visits": taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[3].y,
                        "percent": (Math.round(taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[3].y) / totalNumber * 100).toFixed(2),
                        color: taskchart.colors.getIndex(11),
                    } : {}
            ];
            taskchart.padding(30, 30, 30, 30);
            taskchart.colors.list = [am4core.color("#3BA4A3"), am4core.color("#DE3D1F"), am4core.color("#F79B80"), am4core.color("#808080")];
            var categoryAxis = taskchart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.dataFields.category = "country";
            categoryAxis.renderer.minGridDistance = 60;
            categoryAxis.renderer.inversed = true;
            categoryAxis.renderer.grid.template.disabled = true;
            var valueAxis = taskchart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.extraMax = 0.1;
            categoryAxis.title.text = `[bold]Total Task : ${totalTask}[/]`;
            // categoryAxis.title.marginLeft
            //valueAxis.rangeChangeEasing = am4core.ease.linear;
            //valueAxis.rangeChangeDuration = 1500;
            var taskseries = taskchart.series.push(new am4charts.ColumnSeries());
            taskseries.dataFields.categoryX = "country";
            taskseries.dataFields.valueY = "visits";
            // taskseries.tooltipText = "{valueY.value}"
            // taskseries.tooltipText = `[bold]{visits}[/]`;
            taskseries.columns.template.strokeOpacity = 0;
            taskseries.columns.template.column.cornerRadiusTopRight = 10;
            taskseries.columns.template.column.cornerRadiusTopLeft = 10;
            taskseries.columns.template.propertyFields.fill = 'color';
            // taskseries.columns.template.tooltipText = "Hiiiii"
            taskseries.columns.template.tooltipText = `{country}: {visits} out of ${totalNumber} ({percent}%)`;

            //series.interpolationDuration = 1500;
            //series.interpolationEasing = am4core.ease.linear;
            var labelBullet = taskseries.bullets.push(new am4charts.LabelBullet());
            labelBullet.label.verticalCenter = "bottom";
            labelBullet.label.dy = -10;
            labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";
            taskchart.zoomOutButton.disabled = true;
            // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
            taskseries.columns.template.adapter.add("fill", function (fill, target) {
                return taskchart.colors.getIndex(target.dataItem.index);
            });

            taskchart.scrollbarY = new am4core.Scrollbar();

            // setInterval(function () {
            //     am4core.array.each(taskchart.data, function (item) {
            //         item.visits += Math.round(Math.random() * 200 - 100);
            //         item.visits = Math.abs(item.visits);
            //     })
            //     taskchart.invalidateRawData();
            // }, 2000)
            // categoryAxis.sortBySeries = taskseries;
        }

    }, [taskDistributionWidget, landingWidgets])

    //Compliance Trend Widget

    useEffect(() => {
        if (complianceTrendWidget && complianceTrendWidget.yaxis && complianceTrendWidget.yaxis.length > 0) {
            let tempComplied = []
            if (complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data.length > 0) {
                for (let i in complianceTrendWidget.widget_data[0].data) {
                    const removeZero = item => (
                        Object.keys(item)
                            .filter(key => item[key] !== 0)
                            .reduce((newObj, key) => {
                                newObj[key] = item[key]; return newObj;
                            }, {})); const result = removeZero(complianceTrendWidget.widget_data[0].data[i]);
                    console.log(result, 'result');
                    tempComplied.push(result)
                }
            }
            let tempDelayed = []
            if (complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data.length > 0) {
                for (let i in complianceTrendWidget.widget_data[1].data) {
                    const removeZero = item => (
                        Object.keys(item)
                            .filter(key => item[key] !== 0)
                            .reduce((newObj, key) => {
                                newObj[key] = item[key]; return newObj;
                            }, {})); const result = removeZero(complianceTrendWidget.widget_data[1].data[i]);
                    console.log(result, 'result');
                    tempDelayed.push(result)
                }
            }
            let tempNotComplied = []
            if (complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data.length > 0) {
                for (let i in complianceTrendWidget.widget_data[2].data) {
                    const removeZero = item => (
                        Object.keys(item)
                            .filter(key => item[key] !== 0)
                            .reduce((newObj, key) => {
                                newObj[key] = item[key]; return newObj;
                            }, {})); const result = removeZero(complianceTrendWidget.widget_data[2].data[i]);
                    console.log(result, 'result');
                    tempNotComplied.push(result)
                }
            }
            setCompliedArray(tempComplied)
            setDelayedArray(tempDelayed)
            setNotCompliedArray(tempNotComplied)
            console.log(tempNotComplied, 'tempNotComplied');
            console.log(tempDelayed, 'tempDelayed');
            console.log(tempComplied, 'tempComplied');

        }

    }, [complianceTrendWidget, landingWidgets])

    useEffect(() => {
        if (complianceTrendWidget && complianceTrendWidget.yaxis && complianceTrendWidget.yaxis.length > 0) {
            am4core.useTheme(am4themes_animated);
            var chart = am4core.create("Compliancechartdiv", am4charts.XYChart3D);
            chart.responsive.enabled = 'true';
            let temp = [];
            let tempData = [{
                "year": "2018",
                "Complied": compliedArray && compliedArray.length > 0 && compliedArray[0] && compliedArray[0].total != 0 ? Number(compliedArray && compliedArray.length > 0 && compliedArray[0] && compliedArray[0].y ? (compliedArray[0].y).toFixed(2) : 0) : 0,
                "Delayed_Compliance": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[0] && delayedCompliedArray[0].y ? (delayedCompliedArray[0].y).toFixed(2) : 0),
                "Not_Complied": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[0] && notCompliedArray[0].y ? (notCompliedArray[0].y).toFixed(2) : 0),
                "comData": Number(compliedArray && compliedArray.length > 0 && compliedArray[0] && compliedArray[0].data ? compliedArray[0].data : 0),
                "delData": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[0] && delayedCompliedArray[0].data ? delayedCompliedArray[0].data : 0),
                "notData": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[0] && notCompliedArray[0].data ? notCompliedArray[0].data : 0),
                "comTotal": Number(compliedArray && compliedArray.length > 0 && compliedArray[0] && compliedArray[0].y ? compliedArray[0].total : 0),
                "delTotal": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[0] && delayedCompliedArray[0].total ? delayedCompliedArray[0].total : 0),
                "notTotal": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[0] && notCompliedArray[0].total ? notCompliedArray[0].total : 0),

            }, {
                "year": "2019",
                "Complied": Number(compliedArray && compliedArray.length > 0 && compliedArray[1] && compliedArray[1].y ? (compliedArray[1].y).toFixed(2) : ''),
                "Delayed_Compliance": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[1] && delayedCompliedArray[1].y ? (delayedCompliedArray[1].y).toFixed(2) : ''),
                "Not_Complied": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[1] && notCompliedArray[1].y ? (notCompliedArray[1].y).toFixed(2) : ''),
                "comData": Number(compliedArray && compliedArray.length > 0 && compliedArray[1] && compliedArray[1].data ? compliedArray[1].data : ''),
                "delData": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[1] && delayedCompliedArray[1].data ? delayedCompliedArray[1].data : ''),
                "notData": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[1] && notCompliedArray[1].data ? notCompliedArray[1].data : ''),
                "comTotal": Number(compliedArray && compliedArray.length > 0 && compliedArray[1] && compliedArray[1].y ? compliedArray[1].total : ''),
                "delTotal": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[1] && delayedCompliedArray[1].total ? delayedCompliedArray[1].total : ''),
                "notTotal": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[1] && notCompliedArray[1].total ? notCompliedArray[1].total : ''),
            }, {
                "year": "2020",
                "Complied": Number(compliedArray && compliedArray.length > 0 && compliedArray[2] && compliedArray[2].y ? (compliedArray[2].y).toFixed(2) : ''),
                "Delayed_Compliance": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[2] && delayedCompliedArray[2].y ? (delayedCompliedArray[2].y).toFixed(2) : ''),
                "Not_Complied": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[2] && notCompliedArray[2].y ? (notCompliedArray[2].y).toFixed(2) : ''),
                "comData": Number(compliedArray && compliedArray.length > 0 && compliedArray[2] && compliedArray[2].data ? compliedArray[2].data : ''),
                "delData": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[2] && delayedCompliedArray[2].data ? delayedCompliedArray[2].data : ''),
                "notData": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[2] && notCompliedArray[2].data ? notCompliedArray[2].data : ''),
                "comTotal": Number(compliedArray && compliedArray.length > 0 && compliedArray[2] && compliedArray[2].y ? compliedArray[2].total : ''),
                "delTotal": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[2] && delayedCompliedArray[2].total ? delayedCompliedArray[2].total : ''),
                "notTotal": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[2] && notCompliedArray[2].total ? notCompliedArray[2].total : ''),
            }, {
                "year": "2021",
                "Complied": Number(compliedArray && compliedArray.length > 0 && compliedArray[3] && compliedArray[3].y ? (compliedArray[3].y).toFixed(2) : ''),
                "Delayed_Compliance": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[3] && delayedCompliedArray[3].y ? (delayedCompliedArray[3].y).toFixed(2) : ''),
                "Not_Complied": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[3] && notCompliedArray[3].y ? (notCompliedArray[3].y).toFixed(2) : ''),
                "comData": Number(compliedArray && compliedArray.length > 0 && compliedArray[3] && compliedArray[3].data ? compliedArray[3].data : ''),
                "delData": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[3] && delayedCompliedArray[3].data ? delayedCompliedArray[3].data : ''),
                "notData": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[3] && notCompliedArray[3].data ? notCompliedArray[3].data : ''),
                "comTotal": Number(compliedArray && compliedArray.length > 0 && compliedArray[3] && compliedArray[3].y ? compliedArray[3].total : ''),
                "delTotal": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[3] && delayedCompliedArray[3].total ? delayedCompliedArray[3].total : ''),
                "notTotal": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[3] && notCompliedArray[3].total ? notCompliedArray[3].total : ''),
            }, {
                "year": "2022",
                "Complied": Number(compliedArray && compliedArray.length > 0 && compliedArray[4] && compliedArray[4].y ? (compliedArray[4].y).toFixed(2) : ''),
                "Delayed_Compliance": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[4] && delayedCompliedArray[4].y ? (delayedCompliedArray[4].y).toFixed(2) : ''),
                "Not_Complied": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[4] && notCompliedArray[4].y ? (notCompliedArray[4].y).toFixed(2) : ''),
                "comData": Number(compliedArray && compliedArray.length > 0 && compliedArray[4] && compliedArray[4].data ? compliedArray[4].data : ''),
                "delData": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[4] && delayedCompliedArray[4].data ? delayedCompliedArray[4].data : ''),
                "notData": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[4] && notCompliedArray[4].data ? notCompliedArray[4].data : ''),
                "comTotal": Number(compliedArray && compliedArray.length > 0 && compliedArray[4] && compliedArray[4].y ? compliedArray[4].total : ''),
                "delTotal": Number(delayedCompliedArray && delayedCompliedArray.length > 0 && delayedCompliedArray[4] && delayedCompliedArray[4].total ? delayedCompliedArray[4].total : ''),
                "notTotal": Number(notCompliedArray && notCompliedArray.length > 0 && notCompliedArray[4] && notCompliedArray[4].total ? notCompliedArray[4].total : ''),
            }]

            if (tempData && tempData.length > 0) {
                for (let i in tempData) {
                    const removeZero = item => (
                        Object.keys(item)
                            .filter(key => item[key] !== 0)
                            .reduce((newObj, key) => {
                                newObj[key] = item[key]; return newObj;
                            }, {})); const result = removeZero(tempData[i]);
                    console.log(result, 'result');
                    temp.push(result)
                }
            }

            chart.data = temp && temp.length > 0 && temp;

            var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)

            // chart.hiddenState.properties.opacity = 0;
            chart.colors.list = [
                am4core.color("#0cce73"),
                am4core.color("#366cc2"),
                am4core.color("#e53c5b"),
                // am4core.color("#fe4365"),
            ];
            // chart.legend = new am4charts.Legend();

            chart.legend = new am4charts.Legend();
            chart.legend.useDefaultMarker = true;
            // chart.legend.valueLabels.template.disabled = true;
            var marker = chart.legend.markers.template.children.getIndex(0);
            marker.cornerRadius(12, 12, 12, 12);
            marker.strokeWidth = 2;
            marker.strokeOpacity = 1;
            marker.stroke = am4core.color("#ccc");

            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "year";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 1
            categoryAxis.renderer.cellStartLocation = 0.2;
            categoryAxis.renderer.cellEndLocation = 0.8;
            categoryAxis.title.text = "year";
            categoryAxis.calculateTotals = true;


            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.max = 100;
            valueAxis.strictMinMax = true;
            valueAxis.calculateTotals = true;
            valueAxis.renderer.minWidth = 40;
            valueAxis.renderer.minGridDistance = 40
            valueAxis.title.text = "Total Compliance(%)";

            var series1 = chart.series.push(new am4charts.ColumnSeries3D());
            series1.columns.template.tooltipText =
                `{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{comData}[\] out of [bold]{comTotal}[/])`;
            series1.name = "Complied";
            series1.dataFields.customValue = "Complied";
            series1.dataFields.categoryX = "year";
            series1.dataFields.valueY = "Complied";
            // series1.dataFields.valueYShow = "totalPercent";
            series1.dataItems.template.locations.categoryX = 0.5;
            series1.stacked = true;
            series1.tooltip.pointerOrientation = "vertical";

            var bullet1 = series1.bullets.push(new am4charts.LabelBullet());
            bullet1.interactionsEnabled = false;
            bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
            bullet1.label.fill = am4core.color("#ffffff");
            bullet1.locationY = 0.5;

            var series2 = chart.series.push(new am4charts.ColumnSeries3D());
            // series2.columns.template.width = am4core.percent(toggleButton === 'Month' ? 90 : 50);
            series2.columns.template.tooltipText =
                "{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{delData}[/] out of [bold]{delTotal}[/][/])";
            series2.name = "Delayed Compliance";
            series2.dataFields.customValue = "Delayed_Compliance";
            series2.dataFields.categoryX = "year";
            series2.dataFields.valueY = "Delayed_Compliance";
            // series2.dataFields.valueYShow = "totalPercent";
            series2.dataItems.template.locations.categoryX = 0.5;
            series2.stacked = true;
            series2.tooltip.pointerOrientation = "vertical";

            var bullet2 = series2.bullets.push(new am4charts.LabelBullet());
            bullet2.interactionsEnabled = false;
            bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
            bullet2.locationY = 0.5;
            bullet2.label.fill = am4core.color("#ffffff");

            var series3 = chart.series.push(new am4charts.ColumnSeries3D());
            // series3.columns.template.width = am4core.percent(toggleButton === 'Month' ? 90 : 50);
            series3.columns.template.tooltipText =
                "{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{notData}[/] out of [bold]{notTotal}[/][/])";
            series3.name = "Not Complied";
            series3.dataFields.customValue = "Not_Complied";
            series3.dataFields.categoryX = "year";
            series3.dataFields.valueY = "Not_Complied";
            // series3.dataFields.valueYShow = "totalPercent";
            series3.dataItems.template.locations.categoryX = 0.5;
            series3.stacked = true;
            series3.tooltip.pointerOrientation = "vertical";

            var bullet3 = series3.bullets.push(new am4charts.LabelBullet());
            bullet3.interactionsEnabled = false;
            bullet3.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
            bullet3.locationY = 0.5;
            bullet3.label.fill = am4core.color("#ffffff");

        }

    }, [complianceTrendWidget, compliedArray, delayedCompliedArray, notCompliedArray, landingWidgets])

    //Comparative Trend

    useEffect(() => {
        if (complianceTrendWidget && complianceTrendWidget.yaxis && complianceTrendWidget.yaxis.length > 0) {
            var Comprativechart = am4core.create("Comprativechartdiv", am4charts.XYChart);
            // Comprativechart.height = '350'
            // Comprativechart.width = '450'
            Comprativechart.responsive.enabled = true;

            // Add data
            Comprativechart.data = [{
                "year": "2018",
                "Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[0].y).toFixed(2),
                "Delayed Compliance": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[0].y).toFixed(2),
                "Not Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[0].y).toFixed(2),
                "comData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[0].data,
                "comTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[0].total,
                "delData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[0].data,
                "delTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[0].total,
                "notData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[0].data,
                "notTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[0].total
            }, {
                "year": "2019",
                "Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[1].y).toFixed(2),
                "Delayed Compliance": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[1].y).toFixed(2),
                "Not Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[1].y).toFixed(2),
                "comData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[1].data,
                "comTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[1].total,
                "delData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[1].data,
                "delTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[1].total,
                "notData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[1].data,
                "notTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[1].total
            }, {
                "year": "2020",
                "Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[2].y).toFixed(2),
                "Delayed Compliance": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[2].y).toFixed(2),
                "Not Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[2].y).toFixed(2),
                "comData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[2].data,
                "comTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[2].total,
                "delData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[2].data,
                "delTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[2].total,
                "notData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[2].data,
                "notTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[2].total
            }, {
                "year": "2021",
                "Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[3].y).toFixed(2),
                "Delayed Compliance": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[3].y).toFixed(2),
                "Not Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[3].y).toFixed(2),
                "comData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[3].data,
                "comTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[3].total,
                "delData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[3].data,
                "delTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[3].total,
                "notData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[3].data,
                "notTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[3].total
            }, {
                "year": "2022",
                "Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[4].y).toFixed(2),
                "Delayed Compliance": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[4].y).toFixed(2),
                "Not Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[4].y).toFixed(2),
                "comData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[4].data,
                "comTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[4].total,
                "delData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[4].data,
                "delTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[4].total,
                "notData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[4].data,
                "notTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[4].total
            }];

            Comprativechart.dateFormatter.inputDateFormat = "yyyy";
            var dateAxis = Comprativechart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.minGridDistance = 60;
            dateAxis.startLocation = 0.5;
            dateAxis.endLocation = 0.5;
            dateAxis.baseInterval = {
                timeUnit: "year",
                count: 1
            }

            var comvalueAxis = Comprativechart.yAxes.push(new am4charts.ValueAxis());
            comvalueAxis.tooltip.disabled = true;

            var comseries = Comprativechart.series.push(new am4charts.LineSeries());
            comseries.dataFields.dateX = "year";
            comseries.name = "Complied";
            comseries.dataFields.valueY = "Complied";
            // comseries.tooltipHTML = "<span style='font-size:14px; color:#000000;'>Complied: {valueY.formatNumber('#.00')}% (<b>{comData}</b> out of <b>{comTotal})</b></span>";
            // comseries.tooltipText = "[#000]{comData}[/]";
            // // comseries.tooltipHTML = `{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{comData}[\] out of [bold]{comTotal}[/])`;
            // // comseries.tooltipText = "[#000]{valueY.value}[/]";
            comseries.tooltipHTML = "<span style='font-size:14px; color:#000000;'>Complied: {valueY.formatNumber('#.00')}% (<b>{comData}</b> out of <b>{comTotal})</b></span>";
            comseries.tooltipText = "[#000]{comData}[/]";
            // comseries2.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{delData}[/] out of [bold]{delTotal}[/][/])"
            // comseries2.tooltipHTML = "{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{delData}[/] out of [bold]{delTotal}[/][/])";
            // comseries2.tooltipText = "[#000]{valueY.value}[/]";
            comseries.tooltip.background.fill = am4core.color("#FFF");
            comseries.tooltip.getFillFromObject = false;
            comseries.tooltip.getStrokeFromObject = true;
            comseries.tooltip.background.strokeWidth = 3;
            comseries.sequencedInterpolation = true;
            comseries.fillOpacity = 0.6;
            comseries.stacked = false;
            comseries.strokeWidth = 2;

            var comseries2 = Comprativechart.series.push(new am4charts.LineSeries());
            comseries2.name = "Delayed Compliance";
            comseries2.dataFields.dateX = "year";
            comseries2.dataFields.valueY = "Delayed Compliance";
            comseries2.tooltipHTML = "<span style='font-size:14px; color:#000000;'>Delayed Compliance: {valueY.formatNumber('#.00')}% (<b>{delData}</b> out of <b>{delTotal})</b></span>";
            comseries2.tooltipText = "[#000]{delData}[/]";
            // comseries2.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{delData}[/] out of [bold]{delTotal}[/][/])"
            // comseries2.tooltipHTML = "{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{delData}[/] out of [bold]{delTotal}[/][/])";
            // comseries2.tooltipText = "[#000]{valueY.value}[/]";
            comseries2.tooltip.background.fill = am4core.color("#FFF");
            comseries2.tooltip.getFillFromObject = false;
            comseries2.tooltip.getStrokeFromObject = true;
            comseries2.tooltip.background.strokeWidth = 3;
            comseries2.sequencedInterpolation = true;
            comseries2.fillOpacity = 0.6;
            comseries2.stacked = false;
            comseries2.strokeWidth = 2;

            var comseries3 = Comprativechart.series.push(new am4charts.LineSeries());
            comseries3.name = "Not Complied";
            comseries3.dataFields.dateX = "year";
            comseries3.dataFields.valueY = "Not Complied";
            comseries3.tooltipHTML = "<span style='font-size:14px; color:#000000;'>Not Complied: {valueY.formatNumber('#.00')}% (<b>{notData}</b> out of <b>{notTotal})</b></span>";
            comseries3.tooltipText = "[#000]{notData}[/]";
            // comseries3.tooltipHTML = "{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{notData}[/] out of [bold]{notTotal}[/][/])";
            // comseries3.tooltipText = "[#000]{valueY.value}[/]";
            comseries3.tooltip.background.fill = am4core.color("#FFF");
            comseries3.tooltip.getFillFromObject = false;
            comseries3.tooltip.getStrokeFromObject = true;
            comseries3.tooltip.background.strokeWidth = 3;
            comseries3.sequencedInterpolation = true;
            comseries3.fillOpacity = 0.6;
            comseries3.defaultState.transitionDuration = 1000;
            comseries3.stacked = false;
            comseries3.strokeWidth = 2;

            Comprativechart.cursor = new am4charts.XYCursor();
            Comprativechart.cursor.xAxis = dateAxis;
            Comprativechart.scrollbarX = new am4core.Scrollbar();

            Comprativechart.legend = new am4charts.Legend();
            // Comprativechart.legend.position = "top";

            // axis ranges
            var range = dateAxis.axisRanges.create();
            range.date = new Date(2001, 0, 1);
            range.endDate = new Date(2003, 0, 1);
            range.axisFill.fill = Comprativechart.colors.getIndex(7);
            range.axisFill.fillOpacity = 0.2;

            range.label.text = "";
            range.label.inside = true;
            range.label.rotation = 90;
            range.label.horizontalCenter = "right";
            range.label.verticalCenter = "bottom";

            var range2 = dateAxis.axisRanges.create();
            range2.date = new Date(2007, 0, 1);
            range2.grid.stroke = Comprativechart.colors.getIndex(7);
            range2.grid.strokeOpacity = 0.6;
            range2.grid.strokeDasharray = "5,2";


            range2.label.text = "Motorcycle fee introduced";
            range2.label.inside = true;
            range2.label.rotation = 90;
            range2.label.horizontalCenter = "right";
            range2.label.verticalCenter = "bottom";
        }

        else {
            am4core.useTheme(am4themes_animated);
            var Comprativechart = am4core.create("Comprativechartdiv", am4charts.XYChart);
            var title3 = Comprativechart.createChild(am4core.Label)
            title3.text = "No Data to Display"
            title3.disabled = false;
            title3.align = 'left'
            title3.paddingTop = '25%'
            title3.paddingLeft = '40%'
            // title3.fontWeight = '500'
            title3.fontSize = '16'

        }

    }, [complianceTrendWidget, landingWidgets])

    //Risk Widget

    useEffect(() => {
        if (riskWidget) {
            am4core.useTheme(am4themes_animated);

            //Hidden Risk

            var chart1 = am4core.create("Riskchartdiv", am4charts.PieChart3D);
            let total = (riskWidget && riskWidget.widget_data && riskWidget.widget_data[3].y) + (riskWidget && riskWidget.widget_data && riskWidget.widget_data[2].y) + (riskWidget && riskWidget.widget_data && riskWidget.widget_data[4].y);
            // Add data
            chart1.data = [
                riskWidget && riskWidget.widget_data && riskWidget.widget_data[3].y > 0 ?
                    {
                        "country": "Not Opted",
                        "litres": riskWidget && riskWidget.widget_data && riskWidget.widget_data[3].y,
                        color: am4core.color('#DE3D1F')
                    } : {},
                riskWidget && riskWidget.widget_data && riskWidget.widget_data[2].y > 0 ?
                    {
                        "country": "Unassigned",
                        "litres": riskWidget && riskWidget.widget_data && riskWidget.widget_data[2].y,
                        color: am4core.color('#F79B80')
                    } : {},
                riskWidget && riskWidget.widget_data && riskWidget.widget_data[4].y > 0 ?
                    {
                        "country": "Not Applicable",
                        "litres": riskWidget && riskWidget.widget_data && riskWidget.widget_data[4].y,
                        color: am4core.color('#808080')
                    } : {}
            ];
            chart1.innerRadius = am4core.percent(35);
            var pieSeries = chart1.series.push(new am4charts.PieSeries3D());
            pieSeries.dataFields.value = "litres";
            pieSeries.dataFields.category = "country";
            pieSeries.slices.template.propertyFields.fill = "color";
            chart1.current = chart1;
            chart1.legend = new am4charts.Legend();
            pieSeries.slices.template.tooltipText = `{category} : [bold]{value}[/] out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;



            chart1.numberFormatter.numberFormat = "#.";

            chart1.legend = new am4charts.Legend();
            chart1.legend.position = "bottom";
            chart1.legend.useDefaultMarker = true;
            chart1.legend.valueLabels.template.disabled = true;
            var marker = chart1.legend.markers.template.children.getIndex(0);
            marker.cornerRadius(12, 12, 12, 12);
            marker.strokeWidth = 2;
            marker.strokeOpacity = 1;
            marker.stroke = am4core.color("#ccc");

            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.hiddenState.properties.endAngle = -90;
            pieSeries.hiddenState.properties.startAngle = -90;

            //Exposed Risk

            var chart = am4core.create("ExposedRiskchartdiv", am4charts.PieChart3D);
            let total1 = (riskWidget && riskWidget.widget_data && riskWidget.widget_data[0].y) + (riskWidget && riskWidget.widget_data && riskWidget.widget_data[1].y);
            // Add data
            chart.data = [
                riskWidget && riskWidget.widget_data && riskWidget.widget_data[0].y > 0 ?
                    {
                        "country": "Delayed Compliance",
                        "litres": riskWidget && riskWidget.widget_data && riskWidget.widget_data[0].y,
                        "color": am4core.color("#3c78d8")
                    } : {}
                ,
                riskWidget && riskWidget.widget_data && riskWidget.widget_data[1].y > 0 ?
                    {
                        "country": "Not Complied",
                        "litres": riskWidget && riskWidget.widget_data && riskWidget.widget_data[1].y,
                        "color": am4core.color("#fe4365")
                    } : {},
            ];
            // Set inner radius
            chart.innerRadius = am4core.percent(35);

            // Add and configure Series
            var pieSeries = chart.series.push(new am4charts.PieSeries3D());
            pieSeries.dataFields.value = "litres";
            pieSeries.dataFields.category = "country";
            pieSeries.slices.template.propertyFields.fill = "color";
            chart.current = chart;
            chart.legend = new am4charts.Legend();


            chart.legend = new am4charts.Legend();
            chart.legend.useDefaultMarker = true;
            chart.legend.valueLabels.template.disabled = true;
            var marker = chart.legend.markers.template.children.getIndex(0);
            marker.cornerRadius(12, 12, 12, 12);
            marker.strokeWidth = 2;
            marker.strokeOpacity = 1;
            marker.stroke = am4core.color("#ccc");


            // This creates initial animation
            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.hiddenState.properties.endAngle = -90;
            pieSeries.hiddenState.properties.startAngle = -90;

            // chart.exporting.menu = new am4core.ExportMenu();
            // chart.exporting.dataFields = {
            //     "Status": "Status",
            //     "Count": "Count"
            // }
            // chart.exporting.menu.items = [{
            //     "label": "...",
            //     "menu": [
            //         {
            //             "label": "Image",
            //             "menu": [
            //                 { "type": "png", "label": "PNG" },
            //                 { "type": "jpg", "label": "JPG" },
            //                 { "type": "svg", "label": "SVG" },
            //                 { "type": "pdf", "label": "PDF" },
            //                 { "type": "gif", "label": "GIF" }
            //             ]
            //         }, {
            //             "label": "Data",
            //             "menu": [
            //                 { "type": "csv", "label": "CSV" },
            //                 { "type": "xlsx", "label": "XLSX" },
            //             ]
            //         }, {
            //             "label": "Print", "type": "print"
            //         }
            //     ]
            // }];
            // chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";


        }

    }, [riskWidget, landingWidgets])

    useEffect(() => {
        if (complianceStatusWidget) {
            let temp = []
            let temp1 = []
            for (let i in complianceStatusWidget.xaxis) {
                temp.push({
                    "year": complianceStatusWidget.xaxis[i],
                    "complied_count": complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[0].data && complianceStatusWidget.widget_data[0].data.length > 0 && complianceStatusWidget.widget_data[0].data[i].y,
                    "delayed_compliance_count": complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[1].data && complianceStatusWidget.widget_data[1].data.length > 0 && complianceStatusWidget.widget_data[1].data[i].y,
                    "inprogress_compliance_count": complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[2].data && complianceStatusWidget.widget_data[2].data.length > 0 && complianceStatusWidget.widget_data[2].data[i].y,
                    "not_complied_count": complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[3].data && complianceStatusWidget.widget_data[3].data.length > 0 && complianceStatusWidget.widget_data[3].data[i].y,
                    'total': (complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[0].data && complianceStatusWidget.widget_data[0].data.length > 0 && complianceStatusWidget.widget_data[0].data[i].y) +
                        (complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[1].data && complianceStatusWidget.widget_data[1].data.length > 0 && complianceStatusWidget.widget_data[1].data[i].y) +
                        (complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[2].data && complianceStatusWidget.widget_data[2].data.length > 0 && complianceStatusWidget.widget_data[2].data[i].y) +
                        (complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[3].data && complianceStatusWidget.widget_data[3].data.length > 0 && complianceStatusWidget.widget_data[3].data[i].y)
                })
                temp1.push({
                    'compliedCount': complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[0].data && complianceStatusWidget.widget_data[0].data.length > 0 && complianceStatusWidget.widget_data[0].data[i].y,
                    'delayedCount': complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[1].data && complianceStatusWidget.widget_data[1].data.length > 0 && complianceStatusWidget.widget_data[1].data[i].y,
                    'inProgressCount': complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[2].data && complianceStatusWidget.widget_data[2].data.length > 0 && complianceStatusWidget.widget_data[2].data[i].y,
                    'notCompliedCount': complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[3].data && complianceStatusWidget.widget_data[3].data.length > 0 && complianceStatusWidget.widget_data[3].data[i].y,
                    'total': (complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[0].data && complianceStatusWidget.widget_data[0].data.length > 0 && complianceStatusWidget.widget_data[0].data[i].y) +
                        (complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[1].data && complianceStatusWidget.widget_data[1].data.length > 0 && complianceStatusWidget.widget_data[1].data[i].y) +
                        (complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[2].data && complianceStatusWidget.widget_data[2].data.length > 0 && complianceStatusWidget.widget_data[2].data[i].y) +
                        (complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 && complianceStatusWidget.widget_data[3].data && complianceStatusWidget.widget_data[3].data.length > 0 && complianceStatusWidget.widget_data[3].data[i].y)
                })
            }

            for (let m in temp1) {
                function sumObjectsByKey(...objs) {
                    return objs.reduce((a, b) => {
                        for (let k in b) {
                            if (b.hasOwnProperty(k))
                                a[k] = (a[k] || 0) + b[k];
                        }
                        return a;
                    }, {});
                }

                console.log(sumObjectsByKey(temp1[m]), 'sumObjectsByKey');
            }

            if (temp1 && temp1.length > 0) {
                // for (let m in temp1) {
                //     function sumObjectsByKey(...objs) {
                //         return objs.reduce((a, b) => {
                //             for (let k in b) {
                //                 if (b.hasOwnProperty(k))
                //                     a[k] = (a[k] || 0) + b[k];
                //             }
                //             return a;
                //         }, {});
                //     }

                //     console.log(sumObjectsByKey(temp1[m]), 'sumObjectsByKey123');
                // }
                // var result = temp1.reduce(function(acc, val){
                //     var o = acc.filter(function(obj){
                //         return obj.compliedCount === val.compliedCount;
                //     }).pop() || {compliedCount:val.compliedCount, value:0};

                //     o.value += val.value;
                //     acc.push(o);
                //     return acc;
                // },[]);
                let compliedValue = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue.compliedCount
                }, 0)
                let delayedValue = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue.delayedCount
                }, 0)
                let inProgressValue = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue.inProgressCount
                }, 0)
                let notCompliedValue = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue.notCompliedCount
                }, 0)
                let totalVal = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue.total
                }, 0)
                // console.log(countValues,'countValues');

                let compliedPer = compliedValue / totalVal * 100;
                let delPer = delayedValue / totalVal * 100;
                let inProgPer = inProgressValue / totalVal * 100;
                let notComPer = notCompliedValue / totalVal * 100

                setCompliedCount(compliedValue)
                setCompliedPercent(compliedPer.toFixed(1))
                setDelayedCount(delayedValue)
                setDelayedPercent(delPer.toFixed(1))
                setInprogressCount(inProgressValue)
                setInProgressPercent(inProgPer.toFixed(1))
                setNotCompliedCount(notCompliedValue)
                setnotCompliedPercent(notComPer.toFixed(1))
                setTotalValue(totalVal)


            }
            console.log(temp, 'tempppp');
            console.log(temp1, 'tempp1');
            setComplianceStatusChartData(temp)
        }


    }, [complianceStatusWidget, landingWidgets])

    console.log(complianceStatusChartData, 'complianceStatusChartData');


    //Compliance Status Chart

    useEffect(() => {
        if (complianceStatusChartData) {
            am4core.useTheme(am4themes_animated);
            var chart = am4core.create("compliancestatusdiv", am4charts.XYChart3D);

            if (complianceStatusChartData && complianceStatusChartData.length > 0) {
                // Add data
                chart.data = complianceStatusChartData;
                chart.colors.list = [
                    am4core.color("#0de580"),
                    am4core.color("#3c78d8"),
                    am4core.color("#f9c80e"),
                    am4core.color("#fe4365"),


                ];
                chart.legend = new am4charts.Legend();
                chart.legend.position = "buttom";
                chart.legend.contentAlign = 'right'
                chart.legend.useDefaultMarker = true;
                chart.height = 350

                var marker = chart.legend.markers.template.children.getIndex(0);
                marker.cornerRadius(12, 12, 12, 12);
                marker.strokeWidth = 1;
                // marker.strokeOpacity = 0.5;
                chart.scrollbarX = new am4core.Scrollbar();
                // chart.scrollbarY = new am4core.Scrollbar();

                // chart.scrollbarY.parent = chart.rightAxesContainer;
                var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "year";
                // categoryAxis.title.text =
                //     ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                //         (fieldType == "Division" && go == true) ? `Division` : (
                //             (fieldType == "Category" && go == true) ? `Category` : (
                //                 (fieldType == "Unit" && go == true) ? `Unit` : `Countries`
                //             ))
                //     ))
                categoryAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                // categoryAxis.renderer.labels.template.events.on("hit", function (event) {
                //     if (chartData.length > 0) {
                //         setLabelCountryName(event.target.dataItem._dataContext.type)
                //         setmultiSelectChart(event.target.dataItem._dataContext)
                //         setmultiSelect(true)
                //     }
                //     console.log(event.target.dataItem._dataContext, 'event5555');

                // })
                var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                valueAxis.calculateTotals = true;
                valueAxis.title.text = "Total Compliance (2023)";
                valueAxis.renderer.cellStartLocation = 0.2;
                valueAxis.renderer.cellEndLocation = 0.8;

                function createSeries(field, name) {
                    var series = chart.series.push(new am4charts.ColumnSeries3D());
                    series.dataFields.valueX = field;
                    series.dataFields.categoryY = "year";
                    // series.dataFields.valueXShow = "total";
                    series.stacked = true;
                    series.name = name;
                    // series.clustered = false;
                    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                    labelBullet.label.text = "{valueX}";
                    labelBullet.locationX = 0.5;

                    series.columns.template.maxHeight = 30;

                    series.columns.template.height = am4core.percent(70);
                    // series.columns.template.column3D.stroke = am4core.color("#fff");
                    // series.columns.template.column3D.strokeOpacity = 0.2;
                    series.calculatePercent = true;
                    series.calculateTotals = true;
                    series.columns.template.tooltipText = "{name}: {valueX.totalPercent.formatNumber('#.00')}[/]% ({valueX} out of {valueX.total}[/])";
                }

                createSeries("complied_count", "Complied");
                createSeries("delayed_compliance_count", "Delayed Compliance");
                createSeries("inprogress_compliance_count", "Inprogress");
                createSeries("not_complied_count", "Not Complied");
            }
            // else {
            //     var title3 = chart.createChild(am4core.Label)
            //     title3.text = "No Data to Display"
            //     title3.disabled = false;
            //     title3.align = 'left'
            //     title3.paddingTop = '10%'
            //     title3.paddingLeft = '30%'
            //     // title3.fontWeight = '500'
            //     title3.fontSize = '16'

            // }

        }

    }, [complianceStatusChartData, landingWidgets])




    useEffect(() => {
        if (DomainscorecardData && DomainscorecardData.widget_data && DomainscorecardData.widget_data.length > 0) {
            setNewTableArray(DomainscorecardData && DomainscorecardData.widget_data)
        }
    }, [DomainscorecardData, landingWidgets])

    useEffect(() => {
        if (DomainscorecardData && DomainscorecardData.widget_data && DomainscorecardData.widget_data.length > 0) {
            let temp = []
            let temp1 = []
            let temp2 = []
            let temp3 = []
            let temp4 = []
            for (let i in DomainscorecardData && DomainscorecardData.widget_data) {
                temp.push(Number(DomainscorecardData && DomainscorecardData.widget_data[i].assigned))
                temp1.push(Number(DomainscorecardData && DomainscorecardData.widget_data[i].unassinged))
                temp2.push(Number(DomainscorecardData && DomainscorecardData.widget_data[i].notopted))
                temp3.push(Number(DomainscorecardData && DomainscorecardData.widget_data[i].not_applicable))
            }
            let assignedCount = temp.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            let unAssignedCount = temp1.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            let notOptedCount = temp2.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            let notApplicableCount = temp3.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)

            temp4.push({
                domain_name: 'Total',
                assigned_count: assignedCount,
                unassigned_count: unAssignedCount,
                not_opted_count: notOptedCount,
                not_applicable_count: notApplicableCount,
                total: <b>{Number(assignedCount) + Number(unAssignedCount) + Number(notOptedCount) + Number(notApplicableCount)}</b>
            })
            setTotalArray(temp4)
            setTotalArray1(true)
        }
    }, [DomainscorecardData])

    useEffect(() => {
        if (chartInfo) {
            let temp = [];
            if (chartInfo && chartInfo.widget_data && chartInfo.widget_data.length) {
                let arr = chartInfo.widget_data[0].CurrentMonth.split('-');
                arr.pop();
                // arr.toString("-")
                for (let i in chartInfo.widget_data[0].data) {
                    if (chartInfo.widget_data[0].data[i].overdue > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${chartInfo.widget_data[0].data[i].date}`).toString();
                        temp.push(
                            {
                                id: "over Due",
                                title: chartInfo.widget_data[0].data[i].overdue,
                                allDay: true,
                                start: date,
                                end: date,
                                color: '#f32d2b',
                                // entity: showdata.legal_entity,
                                // frequency: showdata.frequency,
                                // domain_id: showdata.domain,
                                // division: showdata.division,
                                // category: showdata.category,
                                // unit: showdata.unit,
                                tooltip: `Over Due ${chartInfo.widget_data[0].data[i].overdue}`


                            },
                        );
                    }
                    if (chartInfo.widget_data[0].data[i].inprogress > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${chartInfo.widget_data[0].data[i].date}`).toString();
                        temp.push(
                            {
                                id: "Inprogress",
                                title: chartInfo.widget_data[0].data[i].inprogress,
                                allDay: true,
                                start: date,
                                end: date,
                                // frequency: showdata.frequency,
                                // domain_id: showdata.domain,
                                // division: showdata.division,
                                // category: showdata.category,
                                // unit: showdata.unit,
                                color: 'rgb(255 174 44)',
                                // entity: showdata.legal_entity,
                                tooltip: `Inprogress ${chartInfo.widget_data[0].data[i].inprogress}`
                            },
                        );
                    }
                    if (chartInfo.widget_data[0].data[i].upcoming > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${chartInfo.widget_data[0].data[i].date}`).toString();
                        temp.push(
                            {
                                id: "Upcoming",
                                title: chartInfo.widget_data[0].data[i].upcoming,
                                allDay: true,
                                start: date,
                                // frequency: showdata.frequency,
                                // domain_id: showdata.domain,
                                // division: showdata.division,
                                // category: showdata.category,
                                // unit: showdata.unit,
                                end: date,
                                color: 'rgb(42 125 153)',
                                // entity: showdata.legal_entity,
                                tooltip: `Upcoming ${chartInfo.widget_data[0].data[i].upcoming}`
                            },
                        );
                    }
                    if (chartInfo.widget_data[0].data[i].duedate > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${chartInfo.widget_data[0].data[i].date}`).toString();
                        temp.push(
                            {
                                id: "Due Date",
                                title: chartInfo.widget_data[0].data[i].duedate,
                                allDay: true,
                                start: date,
                                end: date,
                                // frequency: showdata.frequency,
                                // domain_id: showdata.domain,
                                // division: showdata.division,
                                // category: showdata.category,
                                // unit: showdata.unit,
                                color: 'rgb(149 46 154)',
                                // entity: showdata.legal_entity,
                                tooltip: `Due Date ${chartInfo.widget_data[0].data[i].duedate}`
                            },
                        );
                    }
                    if (chartInfo.widget_data[0].data[i].taskrecall > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${chartInfo.widget_data[0].data[i].date}`).toString();
                        temp.push(
                            {
                                id: "Task Recall",
                                title: chartInfo.widget_data[0].data[i].taskrecall,
                                // frequency: showdata.frequency,
                                allDay: true,
                                start: date,
                                end: date,
                                color: '#da6d25',
                                // entity: showdata.legal_entity,
                                // domain_id: showdata.domain,
                                // division: showdata.division,
                                // category: showdata.category,
                                // unit: showdata.unit,
                                tooltip: `Task Recall ${chartInfo.widget_data[0].data[i].taskrecall}`

                            },
                        );
                    }


                }
            }
            setEventData(temp);
        }

    }, [chartInfo])


    useEffect(() => {
        if (callername && callername.login_response && callername.login_response.usr_cat_id === 1 || callername && callername.login_response && callername.login_response.usr_cat_id === 3 || callername && callername.login_response && callername.login_response.usr_cat_id === 4) {
            if (callername && callername.login_response && callername.login_response.menu && callername.login_response.menu.Transaction && callername.login_response.menu.Transaction.length > 0) {
                let complianceApproval = _.find(callername.login_response.menu.Transaction, { form_name: 'Compliance Approval' })

                setComplianceApprovalUrl(complianceApproval && complianceApproval.form_url)
                setComplianceFormKey(complianceApproval && complianceApproval.form_key
                )
            }
        }
    }, [])

    const handleHide = (index) => {
        const check = [...myWidgets]
        check[index] = {
            ...check[index],
            checked: !check[index].checked
        }
        setMyWidgets(check)

    }

    const handleHiddenWidgets = (e) => {
        let temparr = [];
        const check = [...myWidgets]
        // const finalCheck = check.filter( f => f.checked == 'true')
        // console.log('checking', finalCheck)
        setLandingWidgets(check)
        let filterwidget = _.filter(check, { checked: true })
        for (let i = 0; i < filterwidget.length; i++) {
            let widgetdata = {

                "w_id": filterwidget[i].id,
                "width": '0px',
                "height": '0px',
                "pin_status": filterwidget[i].checked
            }
            temparr.push(widgetdata)
        }
        const savewidget_payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "SaveWidgetData",
                    {
                        "widget_info": temparr
                    }
                ]
            }
        ]

        const payload2 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetUserWidgetData",
                    {}
                ]
            }
        ]
        SaveWidgetlist({
            payload: savewidget_payload,
            paramid: paramid,
            widgetpay: payload2

        })
        handleDropDown(e)

    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleDraggable = useCallback(({ source, destination }) => {
        if (!destination) return

        const orderedWidgets = reorder(myWidgets, source.index, destination.index)
        setMyWidgets(orderedWidgets)

    }, [myWidgets])


    const ComplianceStatus = (compStatusId, complianceStatusWidget) => () => {
        return (
            <>
                {compStatusId ?
                    <div className='col-md-6'>
                        <div className="card border-danger">
                            <div className="card-header">
                                <Link to={`${dashboardurl && dashboardurl.form_url}/compliances`} id="to" className={"sidebar-link px-1 text-dark py-1 card-title"} onClick={(e) => {
                                    localStorage.setItem('formkey', dashboardurl && dashboardurl.form_key)
                                }}
                                >Compliance Status</Link>
                            </div>
                            {complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 ?
                                <div className='row'>
                                    <div className='col-12'>
                                        {complianceStatusWidget && complianceStatusWidget.widget_data && complianceStatusWidget.widget_data.length > 0 ?
                                            <div className="card-body">
                                                <div id="compliancestatusdiv" style={{ height: '330px' }}></div>
                                            </div> :
                                            <Spin style={{ marginTop: '10%', marginBottom: '10%' }} />}
                                    </div>
                                </div>
                                :
                                <h5 style={{ marginTop: '30%', marginLeft: '36%', marginBottom: '35%' }}>No Data to Display</h5>}
                        </div>
                    </div> : null}
            </>
        )
    }

    const ComparativeTrend = (comparativeTrendId, complianceTrendWidget) => () => {
        return (
            <>
                {comparativeTrendId ?
                    <div className='col-md-6'>
                        <div className="card border-danger" style={{ height: '455px' }}>  {/* c3-area.js */}
                            <div className="card-header">
                                <Link to={`${dashboardurl && dashboardurl.form_url}/comparative`} id="to" className={"sidebar-link text-dark px-1 py-1 card-title"} onClick={(e) => {
                                    localStorage.setItem('formkey', dashboardurl && dashboardurl.form_key)
                                }}
                                >Comparative Trend</Link>
                            </div>
                            {complianceTrendWidget && complianceTrendWidget.yaxis && complianceTrendWidget.yaxis.length > 0 ?
                                <div className='row'>
                                    <div className='col-12'>
                                        {complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data.length > 0 ?
                                            <div className="card-body">
                                                <div id="Comprativechartdiv" style={{ height: '330px' }}></div>
                                            </div> :
                                            <Spin style={{ marginLeft: '50%', marginTop: '10%', marginBottom: '10%' }} />}
                                    </div>
                                </div>
                                :
                                <h5 style={{ marginTop: '30%', marginLeft: '36%' }}>No Data to Display</h5>}
                        </div>
                    </div> : null}
            </>
        )
    }
    const CriticalityChart = (cricId, criticalityWidget) => () => {
        return (
            <>
                {cricId ?
                    <div className='col-lg-6'>
                        <div className="card border-danger">
                            <div className='card-header'>
                                {/* <h5 className='card-title'>

                                </h5> */}
                                <Link to={`${dashboardurl && dashboardurl.form_url}/criticality`} id="to" className={"sidebar-link text-dark px-1 py-1 card-title"} onClick={(e) => {
                                    localStorage.setItem('formkey', dashboardurl && dashboardurl.form_key)
                                }}
                                >Criticality Chart</Link>
                            </div>
                            {criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 ?
                                <div className='row'>
                                    <div className='col-12'>
                                        {criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 ?
                                            <div className='card-body'>
                                                <div id="criticalityDiv" style={{ height: '330px' }}></div>
                                            </div> :
                                            <Spin style={{ marginTop: '10%', marginBottom: '10%' }} />}
                                    </div>
                                </div> :
                                <h5 style={{ marginTop: '30%', marginLeft: '36%', marginBottom: '35%' }}>No Data to Display</h5>}
                        </div>
                    </div> : null
                }
            </>
        )
    }

    const TaskDistribution = (taskDisId, taskDistributionWidget) => () => {
        return (
            <>
                {taskDisId ?
                    <div className='col-md-6'>
                        <div className="card-group">
                            <div className="card border-danger">
                                <div className="card-header">
                                    <Link to={`${dashboardurl && dashboardurl.form_url}/taskDistribution`} id="to" className={"sidebar-link text-dark px-1 py-1 card-title"} onClick={(e) => {
                                        localStorage.setItem('formkey', dashboardurl && dashboardurl.form_key)
                                    }}
                                    >Task Distribution</Link>
                                </div>
                                {taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 ?
                                    <div className='row'>
                                        <div className='col-12'>
                                            {taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 ?
                                                <div className='card-body'>
                                                    <div id="taskchartdiv" style={{ height: '330px' }}></div>
                                                </div> :
                                                <Spin style={{ marginTop: '10%', marginBottom: '10%' }} />}
                                        </div>
                                    </div> :
                                    <h5 style={{ marginTop: '30%', marginLeft: '36%', marginBottom: '35%' }}>No Data to Display</h5>}
                            </div>
                        </div>
                    </div> : null}
            </>
        )
    }
    const ComplianceTrend = (compTrendId, complianceTrendWidget) => () => {
        return (
            <>
                {compTrendId ?
                    <div className='col-md-6'>
                        <div className="card border-danger">
                            <div className="card-header">
                                <Link to={`${dashboardurl.form_url}/complianceTrend`} id="to" className={"sidebar-link text-dark px-1 py-1 card-title"} onClick={(e) => {
                                    localStorage.setItem('formkey', dashboardurl && dashboardurl.form_key)
                                }}
                                >Compliance Trend</Link>
                            </div>
                            {complianceTrendWidget && complianceTrendWidget.yaxis && complianceTrendWidget.yaxis.length > 0 ?
                                <div className='row'>
                                    <div className='col-12'>
                                        {complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data.length > 0 ?
                                            <div className="card-body">
                                                <div id="Compliancechartdiv" style={{ height: '330px' }}></div>
                                            </div> :
                                            <Spin style={{ marginLeft: '50%', marginTop: '10%', marginBottom: '10%' }} />}
                                    </div>
                                </div> :
                                <h5 style={{ marginTop: '30%', marginLeft: '36%', marginBottom: '35%' }}>No Data to Display</h5>}
                        </div>
                    </div> : null}
            </>
        )
    }

    const RiskChart = (riskId, riskWidget) => () => {
        console.log(riskWidget, 'riskWidgetriskWidget');
        return (
            <>
                {riskId ?
                    <div className='col-md-12'>
                        <div className="card border-danger">
                            <div className="card-header">
                                <Link to={`${dashboardurl && dashboardurl.form_url}/risk`} id="to" className={"sidebar-link text-dark px-1 py-1 card-title"} onClick={(e) => {
                                    localStorage.setItem('formkey', dashboardurl && dashboardurl.form_key)
                                }}
                                >Risk Chart</Link>
                            </div>
                            <div className='card-body'>
                                {/* <div className='row'>
                                                <div className='col-6'>
                                                    <div id='Riskchartdiv' style={{ height: '330px' }}></div>
                                                </div>
                                                <div className='col-6'>
                                                    <div id='ExposedRiskchartdiv' style={{ height: '330px' }}></div>
                                                </div>
                                            </div> */}
                                <div className="row">

                                    {riskWidget && riskWidget.widget_data && riskWidget.widget_data.length > 0 ?
                                        <>
                                            <div className="col-md-6">
                                                <b style={{ marginLeft: '42%' }}>Hidden Risk</b>
                                                <div id="Riskchartdiv" style={{ width: "100%", marginTop: '10px', height: "220px" }}></div>
                                            </div>
                                            <div className="col-md-6">
                                                <b style={{ marginLeft: '42%' }}>Exposed Risk</b>
                                                <div id="ExposedRiskchartdiv" style={{ width: "100%", marginTop: '10px', marginRight: '20%', height: "220px" }}></div>
                                            </div></> :
                                        <Spin style={{ marginTop: '10%', marginBottom: '10%' }} />}
                                </div>


                            </div>
                        </div>
                    </div> : null}
            </>
        )
    }
    const OverDueChart = (overdueId, overdueWidget) => () => {
        return (
            <>
                {overdueId ?
                    <div className='col-lg-6'>
                        <div className="card border-danger">
                            <div className='card-header'>
                                <Link to={`${dashboardurl && dashboardurl.form_url}/overdue`} id="to" className={"sidebar-link text-dark px-1 py-1 card-title"} onClick={(e) => {
                                    localStorage.setItem('formkey', dashboardurl && dashboardurl.form_key)
                                }}
                                >Over Due Chart</Link>
                            </div>
                            {overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 ?
                                <div className='row'>
                                    <div className='col-12'>
                                        {overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 ?
                                            <div className="card-body">
                                                <div id="chartdiv" style={{ height: '330px' }}></div>
                                            </div> :
                                            <Spin style={{ marginTop: '10%', marginBottom: '10%' }} />}
                                    </div>
                                </div> :
                                <h5 style={{ marginTop: '30%', marginLeft: '36%', marginBottom: '35%' }}>No Data to Display</h5>}
                        </div>
                    </div> : null}
            </>
        )
    }

    const domainscorecard = (DomaincardId, DomainscorecardData) => () => {
        let temp = []
        let temp1 = []
        let temp2 = []
        let temp3 = []
        let temp4 = []
        for (let i in DomainscorecardData && DomainscorecardData.widget_data) {
            temp.push(Number(DomainscorecardData && DomainscorecardData.widget_data[i].assigned))
            temp1.push(Number(DomainscorecardData && DomainscorecardData.widget_data[i].unassinged))
            temp2.push(Number(DomainscorecardData && DomainscorecardData.widget_data[i].notopted))
            temp3.push(Number(DomainscorecardData && DomainscorecardData.widget_data[i].not_applicable))
        }
        let assignedCount = temp.reduce(function (accumulator, curValue) {
            return accumulator + curValue
        }, 0)
        let unAssignedCount = temp1.reduce(function (accumulator, curValue) {
            return accumulator + curValue
        }, 0)
        let notOptedCount = temp2.reduce(function (accumulator, curValue) {
            return accumulator + curValue
        }, 0)
        let notApplicableCount = temp3.reduce(function (accumulator, curValue) {
            return accumulator + curValue
        }, 0)

        temp4.push({
            domain_name: 'Total',
            assigned_count: assignedCount,
            unassigned_count: unAssignedCount,
            not_opted_count: notOptedCount,
            not_applicable_count: notApplicableCount,
            total: <b>{Number(assignedCount) + Number(unAssignedCount) + Number(notOptedCount) + Number(notApplicableCount)}</b>
        })
        return (
            <>
                {DomaincardId ?
                    <div className='col-md-6'>
                        <div className="card border-danger">
                            <div className="card-header">
                                {/* <h3 className="card-title"> */}
                                <Link to={`${dashboardurl.form_url}/compliances`} id="to" className={"sidebar-link px-1 text-dark py-1 card-title"} onClick={(e) => {
                                    localStorage.setItem('formkey', dashboardurl.form_key)
                                }}>
                                    Domain Scorecard</Link>
                                {/* </h3> */}
                            </div>
                            <div className={localStorage.getItem('currentTheme')}>
                                <div className='ant-table-wrapper domaincardtotal' id="service-table-action" style={{ height: '390px' }}>
                                    <div className='mb-2'>
                                        <Select

                                            size="default"
                                            // defaultValue={"All"}
                                            onDrop={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                            value={LegalName.legal_name}
                                            onChange={(value, data) => {

                                                setLegalName({
                                                    ...LegalName,
                                                    legal_name: data ? data.children : '',
                                                    legal_id: value ? value : ''
                                                })
                                                setTotalArray1(false)
                                                const payload = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "GetDomainScoreCard",
                                                            {
                                                                "le_ids": value == 'All' ? entityData : [value],

                                                            }
                                                        ]
                                                    }
                                                ]
                                                getDomainscorecard({
                                                    payload: payload,
                                                    caller: login.form_url
                                                })

                                            }}
                                            style={{ width: '98%', marginLeft: '2%' }}
                                        >
                                            {localStorage.getItem('SelectedEntity') == "All Legal Entity" ?
                                                <Option key={'All'}>All</Option> : ''}
                                            {leArray && leArray.length > 0 && leArray.map((item, i) => {

                                                return (
                                                    <Option key={item.le_id}>
                                                        {item.le_name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </div>

                                    <div className='ant-table ant-table-bordered' style={{ marginLeft: '2%' }}>
                                        <div className='ant-table-container'>
                                            <div className='ant-table-content scorecardtable'>
                                                <table>
                                                    <colgroup>
                                                        <col style={{ width: '10px' }}></col>
                                                    </colgroup>
                                                    <thead className="ant-table-thead ">
                                                        <tr>
                                                            <th className='ant-table-cell custom-width1'>Domain</th>
                                                            <th className='ant-table-cell custom-width1'>Assigned</th>
                                                            <th className='ant-table-cell custom-width1'>Unassigned</th>
                                                            <th className='ant-table-cell custom-width1'>Not Opted</th>
                                                            <th className='ant-table-cell custom-width1'>Not Applicable</th>
                                                            <th className='ant-table-cell custom-width1'>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='ant-table-tbody'>
                                                        {DomainscorecardData && DomainscorecardData.widget_data && DomainscorecardData.widget_data.length > 0 ? DomainscorecardData && DomainscorecardData.widget_data && DomainscorecardData.widget_data.length > 0 && DomainscorecardData.widget_data.map((item, i) => {
                                                            return (
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>{item.d_name}</center></td>
                                                                    <td className='ant-table-cell'><center>{item.assigned}</center></td>
                                                                    <td className='ant-table-cell'><center>{item.unassinged}</center></td>
                                                                    <td className='ant-table-cell'><center>{item.notopted}</center></td>
                                                                    <td className='ant-table-cell'><center>{item.not_applicable}</center></td>
                                                                    <td className='ant-table-cell'><center><b>{Number(item.assigned) + Number(item.unassinged) + Number(item.notopted) + Number(item.not_applicable)}</b></center></td>

                                                                </tr>
                                                            )
                                                        }) :
                                                            <tr>
                                                                <td className='ant-table-cell'><b><center>Total</center></b></td>
                                                                <td className='ant-table-cell'><center>{0}</center></td>
                                                                <td className='ant-table-cell'><center>{0}</center></td>
                                                                <td className='ant-table-cell'><center>{0}</center></td>
                                                                <td className='ant-table-cell'><center>{0}</center></td>
                                                                <td className='ant-table-cell'><center>{0}</center></td>
                                                            </tr>
                                                        }

                                                        {(DomainscorecardData && DomainscorecardData.widget_data && DomainscorecardData.widget_data.length > 0) && (temp4 && temp4.length > 0)

                                                            ?
                                                            <tr>
                                                                <td className='ant-table-cell'><b><center>Total</center></b></td>
                                                                <td className='ant-table-cell'><center>{temp4 && temp4[0].assigned_count}</center></td>
                                                                <td className='ant-table-cell'><center>{temp4 && temp4[0].unassigned_count}</center></td>
                                                                <td className='ant-table-cell'><center>{temp4 && temp4[0].not_opted_count}</center></td>
                                                                <td className='ant-table-cell'><center>{temp4 && temp4[0].not_applicable_count}</center></td>
                                                                <td className='ant-table-cell'><center>{temp4 && temp4[0].total}</center></td>
                                                            </tr> : ''}
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
            </>
        )
    }


    const calendarview = (calendarId, eventData) => () => {
        return (
            <>
                {
                    calendarId ?
                        <div className='col-md-6'>
                            <div className="card border-danger">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Calendar View
                                    </h3>
                                </div>
                                {chartInfo && chartInfo.widget_data && chartInfo.widget_data.length > 0 ?
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className="card-body">
                                                <Select
                                                    // getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={false}
                                                    size="default"
                                                    // defaultValue={'All'}
                                                    value={calendarLeValue ? calendarLeValue : ''}
                                                    // placeholder="Enter Business Group"
                                                    // value={filterDataNames.business ? filterDataNames.business : undefined}
                                                    // onClick={() => {
                                                    //     setFieldTypeClick('BusinessGroup')
                                                    //     setGroupCheckBox(true)
                                                    //     setCheckBox(false)
                                                    // }}
                                                    onChange={(data, values) => {
                                                        setCalendarLeValue(data)
                                                        const payload1 = [
                                                            authtoken,
                                                            {
                                                                "session_token": authtoken,
                                                                "request": [
                                                                    "GetCalendarView",
                                                                    {
                                                                        "le_ids": data === 'All' ? entityData : [data]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                        getChartFilters({
                                                            payload: payload1,
                                                            caller: login.form_url
                                                        })
                                                    }}
                                                    style={{ width: '100%' }}
                                                // disabled={filterData.domain == '' ? true : false}
                                                >
                                                    {localStorage.getItem('SelectedEntity') == "All Legal Entity" ?
                                                        <Option key={'All'}>All</Option> : ''}
                                                    {leArray && leArray.length > 0 && leArray.map((item, i) => {
                                                        return (
                                                            <Option key={item.le_id}>
                                                                {item.le_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <Calendar

                                                style={{ height: '500pt' }}
                                                tooltipAccessor={(e) => {
                                                    return e.tooltip;
                                                }}
                                                views={views}

                                                components={{
                                                    toolbar: CustomToolbar,
                                                }}

                                                // components={(e) => {
                                                //     Event(e)
                                                // }}
                                                className='calendarviewoutline'
                                                eventPropGetter={(event, start, end, isSelected) => {
                                                    var backgroundColor = event.color;
                                                    var style = {
                                                        backgroundColor: backgroundColor,
                                                        borderRadius: '25px',
                                                        cursor: 'default',
                                                        float: 'left',
                                                        fontSize: '8px !important',
                                                        height: '18px',
                                                        textAlign: 'center',
                                                        lineHeight: '18px',
                                                        // verticalAlign: 'middle',
                                                        horizontalAlign: 'middle',
                                                        marginLeft: '5px',
                                                        width: '40px',
                                                        // opacity: 0.8,
                                                        // color: 'black',
                                                        // border: '0px',
                                                        // display: 'block'
                                                    };
                                                    return {
                                                        style: style
                                                    };
                                                }}
                                                // date={date}
                                                events={eventData}
                                                startAccessor="start"
                                                showAllEvents='true'
                                                endAccessor="end"
                                                defaultDate={moment().toDate()}
                                                localizer={localizer}
                                                eventOrder="horizontal"
                                                defaultView='month'
                                            />
                                        </div>
                                        <div className="col-md-12 mt-4 text-center">
                                            <div className="row">

                                                <div style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                                    <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                        <div style={{ background: ' #da6d25', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                        <div style={{ float: 'left' }}>Task Recall</div>
                                                    </div>
                                                    <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                        <div style={{ background: 'rgb(149 46 154)', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                        <div style={{ float: 'left' }}>Due Date</div>
                                                    </div>
                                                    <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                        <div style={{ background: 'rgb(255 174 44)', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                        <div style={{ float: 'left' }}>Inprogress</div>
                                                    </div>
                                                    <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                        <div style={{ background: '#f32d2b', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                        <div style={{ float: 'left' }}>Over Due</div>
                                                    </div>
                                                    <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                        <div style={{ background: '  rgb(42 125 153)', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                        <div style={{ float: 'left' }}>Upcoming</div>
                                                    </div>

                                                    &nbsp; </div>


                                            </div>
                                        </div>
                                    </div>
                                    : <Spin style={{ marginTop: '10%', marginBottom: '10%' }} />}
                            </div>
                        </div> : null

                }   </>)
    }

    const compfielaw = () => () => {
        return (
            <>
                <div className='col-md-6'>
                    <div className="card border-danger" style={{ height: '455px' }}>
                        <div className="card-header">
                            {/* <Link to={'compfielaw.compfie.com'} id="to" className={"sidebar-link text-dark px-1 py-1 card-title"} onClick={(e) => {
                                localStorage.setItem('formkey', dashboardurl.form_key)
                            }}
                            >Compfie Law</Link> */}
                            <a className={"sidebar-link text-dark px-1 py-1 card-title"} href="https://compfielaw.compfie.com" target="_blank" onClick={(e) => {
                                localStorage.setItem('formkey', dashboardurl.form_key)
                            }}>
                                Compfie Law</a>
                        </div>
                        <div className={localStorage.getItem('currentTheme')} style={{ textAlign: 'center' }}>
                            <a href="https://compfielaw.compfie.com" target="_blank">
                                <img src='assets/images/comfielaws.png' style={{ marginTop: '25%' }} /></a>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const [widgetlist, setwidgetlist] = useState();

    useEffect(() => {
        const widgets = [
            {
                id: 1,
                name: 'Compliance Status',
                checked: widgetInfo && widgetInfo.widget_info.find(obj => obj.w_id == 1) ? true : false,
                component: ComplianceStatus(compStatusId, complianceStatusWidget),
                visible: true
            },
            {
                id: 4,
                name: 'Comparative Trend',
                checked: widgetInfo && widgetInfo.widget_info.find(obj => obj.w_id == 4) ? true : false,
                component: ComparativeTrend(comparativeTrendId, complianceTrendWidget),
                visible: true

            },

            {
                id: 12,
                name: 'Criticality Chart',
                checked: widgetInfo && widgetInfo.widget_info.find(obj => obj.w_id == 12) ? true : false,
                component: CriticalityChart(cricId, criticalityWidget),
                visible: true
            },

            {
                id: 11,
                name: 'Task Distribution',
                checked: widgetInfo && widgetInfo.widget_info.find(obj => obj.w_id == 11) ? true : false,
                component: TaskDistribution(taskDisId, taskDistributionWidget),
                visible: true

            },

            {
                id: 10,
                name: 'Compliance Trend',
                checked: widgetInfo && widgetInfo.widget_info.find(obj => obj.w_id == 10) ? true : false,
                component: ComplianceTrend(compTrendId, complianceTrendWidget),
                visible: true
            },

            {
                id: 5,
                name: 'Risk Chart',
                checked: widgetInfo && widgetInfo.widget_info.find(obj => obj.w_id == 5) ? true : false,
                component: RiskChart(riskId, riskWidget),
                visible: true
            },

            {
                id: 3,
                name: 'Over Due Chart',
                checked: widgetInfo && widgetInfo.widget_info.find(obj => obj.w_id == 3) ? true : false,
                component: OverDueChart(overdueId, overdueWidget),
                visible: overdueId

            },

            {
                id: 7,
                name: 'Domain Scorecard',
                checked: widgetInfo && widgetInfo.widget_info.find(obj => obj.w_id == 7) ? true : false,
                component: domainscorecard(DomaincardId, DomainscorecardData),
                visible: true
            },
            {
                id: 9,
                name: 'Compfie Law',
                checked: widgetInfo && widgetInfo.widget_info.find(obj => obj.w_id == 9) ? true : false,
                component: compfielaw(),
                visible: true
            },
            {
                id: 8,
                name: 'Calendar View',
                checked: widgetInfo && widgetInfo.widget_info.find(obj => obj.w_id == 8) ? true : false,
                component: calendarview(calendarId, eventData),
                visible: true
            }
        ]
        let temparray = [];
        let listarray = [];
        widgetInfo && widgetInfo.widget_info && widgetInfo.widget_info.map((item) => {
            const filter = _.find(widgets, { id: item.w_id })
            temparray.push(filter)
        })
        widgetInfo && widgetInfo.widget_list && widgetInfo.widget_list.map((list) => {
            const filter_data = _.find(widgets, { id: list.w_id })
            listarray.push(filter_data)
        })

        var merged = [].concat.apply([], temparray);
        var listmerged = [].concat.apply([], listarray);

        const totalwidgets = merged.concat(listmerged.filter((item) => merged.indexOf(item) < 0))

        setMyWidgets(totalwidgets)
        setLandingWidgets(totalwidgets)



    }, [widgetInfo, compStatusId, complianceStatusWidget, comparativeTrendId, complianceTrendWidget, taskDisId, taskDistributionWidget,
        cricId, criticalityWidget, compTrendId, riskId, riskWidget, overdueId, overdueWidget, DomaincardId,
        DomainscorecardData, calendarId, eventData])

    const handleDropDown = (e) => {
        // new bootstrap.Dropdown(closeBtn).hide()
        document.getElementById('dropdownMenuButton').classList.remove("show");
        document.getElementById('service-table-action').classList.remove("show")

    }


    return (
        <div className="page-wrapper">
            <div className="pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                        </nav>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className='row'>
                        <div className='col-md-12'>
                            {(callername && callername.login_response && callername.login_response.usr_cat_id != 5) || (callername && callername.login_response && callername.login_response.usr_cat_id != 6) ?
                                <div className='row'>
                                    {dayStat === false ?
                                        <>
                                            <div className='col px-1 ms-2'>
                                                <div className="card bg-success card-hover text-white card-flip h-80">
                                                    <div className="card-body p-2 card-front">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">{compliedPercent ? `${compliedPercent}%` : '0'}</h3>
                                                                <span className="text-white h5"><b>Complied</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-2 card-back">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">{compliedCount}</h3>
                                                                <p style={{ lineHeight: '14px' }} className="text-white h6"><b>Total Records</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col px-1'>
                                                <div className="card bg-info card-hover text-white card-flip h-80">
                                                    <div className="card-body p-2 card-front">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">{delayedPercent ? `${delayedPercent}%` : '0'}</h3>
                                                                <span className="text-white h5"><b style={{ fontSize: '0.9rem !important' }}>Delayed Compliance</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-2 card-back">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">{delayedCount}</h3>
                                                                <p style={{ lineHeight: '14px' }} className="text-white h6"><b>Total Records</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col px-1'>
                                                <div className="card bg-warning card-hover text-white card-flip h-80">
                                                    <div className="card-body p-2 card-front">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">{inProgressPercent ? `${inProgressPercent}%` : '0'}</h3>
                                                                <span className="text-white h5"><b>In Progress</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-2 card-back">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">{inprogressCount}</h3>
                                                                <p style={{ lineHeight: '14px' }} className="text-white h6"><b>Total Records</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col px-1'>
                                                <div className="card bg-danger card-hover text-white card-flip h-80">
                                                    <div className="card-body p-2 card-front">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">{notCompliedPercent ? `${notCompliedPercent}%` : '0'}</h3>
                                                                <span className="text-white h5"><b>Not Complied</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-2 card-back">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">{notCompliedCount}</h3>
                                                                <p style={{ lineHeight: '14px' }} className="text-white h6"><b>Total Records</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </> :
                                        <>
                                            <div className='col px-1 pe-2'>
                                                <div className="card text-white card-flip h-80" style={{ backgroundColor: '#F79B80', cursor: 'pointer' }} onClick={() => {
                                                    history.push(`${complianceApprovalUrl}`)
                                                }}>
                                                    <div className="card-body p-2">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">{type == 'individual' ? complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.individual && complianceStatusWidget.approval_pending.individual['0_30_days']
                                                                    : complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.total && complianceStatusWidget.approval_pending.total['0_30_days']}</h3>
                                                                <span className="text-white h5"><b>0 - 30 Days</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col px-1'>
                                                <div className="card text-white card-flip h-80" style={{ backgroundColor: '#F2746B', cursor: 'pointer' }} onClick={() => {
                                                    history.push(`${complianceApprovalUrl}`)
                                                }}>
                                                    <div className="card-body p-2">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">{type == 'individual' ? complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.individual && complianceStatusWidget.approval_pending.individual['31_60_days']
                                                                    : complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.total && complianceStatusWidget.approval_pending.total['31_60_days']}</h3>
                                                                <span className="text-white h5"><b>31 - 60 Days</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col px-1'>
                                                <div className="card text-white card-flip h-80" style={{ backgroundColor: '#F35D53', cursor: 'pointer' }} onClick={() => {
                                                    history.push(`${complianceApprovalUrl}`)
                                                }}>
                                                    <div className="card-body p-2">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">{type == 'individual' ? complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.individual && complianceStatusWidget.approval_pending.individual['61_90_days']
                                                                    : complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.total && complianceStatusWidget.approval_pending.total['61_90_days']}</h3>
                                                                <span className="text-white h5"><b></b>61 - 90 Days</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col px-1'>
                                                <div className="card text-white card-flip h-80" style={{ backgroundColor: '#F24D64', cursor: 'pointer' }} onClick={() => {
                                                    history.push(`${complianceApprovalUrl}`)
                                                }}>
                                                    <div className="card-body p-2">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">{type == 'individual' ? complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.individual && complianceStatusWidget.approval_pending.individual['above_90_days']
                                                                    : complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.total && complianceStatusWidget.approval_pending.total['above_90_days']}</h3>
                                                                <span className="text-white h5"><b>Above 90 Days</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {callername && callername.login_response && callername.login_response.usr_cat_id === 1 || callername && callername.login_response && callername.login_response.usr_cat_id === 7 || callername && callername.login_response && callername.login_response.usr_cat_id === 4 || callername && callername.login_response && callername.login_response.usr_cat_id === 3 || callername && callername.login_response && callername.login_response.usr_cat_id === 2 ?
                                        <div className='col px-1 pe-2'>
                                            <div className="card bg-secondary text-white card-flip h-80">
                                                <div className="card-body p-2">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <div className="ms-2 align-self-center">
                                                            {callername && callername.login_response && callername.login_response.usr_cat_id === 7 || callername && callername.login_response && callername.login_response.usr_cat_id === 2 ?
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7"><span style={{ cursor: 'pointer' }} onClick={() => {
                                                                    setDayStat(true)
                                                                }}>{((Number(complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.individual && complianceStatusWidget.approval_pending.individual['0_30_days'])) + (Number(complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.individual && complianceStatusWidget.approval_pending.individual['31_60_days'])) + (Number(complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.individual && complianceStatusWidget.approval_pending.individual['61_90_days'])) + (Number(complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.individual && complianceStatusWidget.approval_pending.individual['above_90_days'])))}</span></h3>
                                                                :
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7 pointer"><span style={{ cursor: 'pointer' }} onClick={() => {
                                                                    setDayStat(true)
                                                                    setType('individual')
                                                                }}>{((Number(complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.individual && complianceStatusWidget.approval_pending.individual['0_30_days'])) + (Number(complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.individual && complianceStatusWidget.approval_pending.individual['31_60_days'])) + (Number(complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.individual && complianceStatusWidget.approval_pending.individual['61_90_days'])) + (Number(complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.individual && complianceStatusWidget.approval_pending.individual['above_90_days'])))}</span>

                                                                    {/* <span style={{ cursor: 'pointer' }} onClick={() => {
                                                                    setDayStat(true)
                                                                    setType('total')
                                                                }}>{(Number((complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.total && complianceStatusWidget.approval_pending.total['0_30_days'])) + (Number(complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.total && complianceStatusWidget.approval_pending.total['31_60_days'])) + (Number(complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.total && complianceStatusWidget.approval_pending.total['61_90_days'])) + (Number(complianceStatusWidget && complianceStatusWidget.approval_pending && complianceStatusWidget.approval_pending.total && complianceStatusWidget.approval_pending.total['above_90_days'])))}</span> */}
                                                                </h3>
                                                            }

                                                            <span className="text-white h5 pointer" onClick={() => {
                                                                history.push(`${complianceApprovalUrl}`)
                                                                localStorage.setItem('ApprovalPending', true)
                                                                localStorage.setItem('formkey', complianceFormKey)
                                                                localStorage.setItem('ApprovalFormKey', complianceFormKey)
                                                            }}><b>Approval Pending</b></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="card-body p-2 card-back">
                                            <div className="d-flex flex-row align-items-center">
                                                <div className="round rounded-circle txte-danger d-inline-block text-center bg-white d-flex justify-content-center align-items-center">
                                                    <i data-feather='minus-circle'></i>
                                                </div>
                                                <div className="ms-2 align-self-center">
                                                    <h3 className="mb-0 text-white fw-bold h1 fs-7">357</h3>
                                                    <p style={{ lineHeight: '14px' }} className="text-white h6"><b>Total Records</b></p>
                                                </div>
                                            </div>
                                        </div> */}
                                            </div>

                                        </div> : ''}

                                </div> :
                                <div className="row">
                                    <div className='col px-1 pe-2'>
                                        <div className="card text-white card-flip h-80" style={{ backgroundColor: 'rgb(149 46 154)', cursor: 'pointer' }}
                                        // onClick={() => {
                                        //     history.push(`${complianceApprovalUrl}`)
                                        // }}
                                        >
                                            <div className="card-body p-2">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="ms-2 align-self-center">
                                                        <h3 className="mb-0 text-white fw-bold h1 fs-7">30</h3>
                                                        <span className="text-white h5"><b>Due Today</b></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div><div className='col px-1 pe-2'>
                                        <div className="card text-white card-flip h-80" style={{ backgroundColor: 'rgb(255 174 44)', cursor: 'pointer' }}
                                        // onClick={() => {
                                        //     history.push(`${complianceApprovalUrl}`)
                                        // }}
                                        >
                                            <div className="card-body p-2">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="ms-2 align-self-center">
                                                        <h3 className="mb-0 text-white fw-bold h1 fs-7">20</h3>
                                                        <span className="text-white h5"><b>Inprogress</b></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div><div className='col px-1 pe-2'>
                                        <div className="card text-white card-flip h-80" style={{ backgroundColor: '#f32d2b', cursor: 'pointer' }}
                                        // onClick={() => {
                                        //     history.push(`${complianceApprovalUrl}`)
                                        // }}
                                        >
                                            <div className="card-body p-2">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="ms-2 align-self-center">
                                                        <h3 className="mb-0 text-white fw-bold h1 fs-7">50</h3>
                                                        <span className="text-white h5"><b>Over Due</b></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div><div className='col px-1 pe-2'>
                                        <div className="card text-white card-flip h-80" style={{ backgroundColor: 'rgb(42 125 153)', cursor: 'pointer' }}
                                        // onClick={() => {
                                        //     history.push(`${complianceApprovalUrl}`)
                                        // }}
                                        >
                                            <div className="card-body p-2">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="ms-2 align-self-center">
                                                        <h3 className="mb-0 text-white fw-bold h1 fs-7">60</h3>
                                                        <span className="text-white h5"><b>Upcoming</b></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div><div className='col px-1 pe-2'>
                                        <div className="card text-white card-flip h-80" style={{ backgroundColor: '#da6d25', cursor: 'pointer' }}
                                        // onClick={() => {
                                        //     history.push(`${complianceApprovalUrl}`)
                                        // }}
                                        >
                                            <div className="card-body p-2">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="ms-2 align-self-center">
                                                        <h3 className="mb-0 text-white fw-bold h1 fs-7">25</h3>
                                                        <span className="text-white h5"><b>Task Recall</b></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                        </div>
                    </div>
                    {widgetInfo && widgetInfo.widget_list && widgetInfo.widget_list.length > 0 ?
                        <>
                            <div className="container-fluid ">
                                <button
                                    className="customizer-widget btn p-2 shadow-lg"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i class="fas fa-ellipsis-h"></i>

                                </button>
                                <div
                                    onClick={(e) => { e.stopPropagation(); }}
                                    style={{ minWidth: '24rem', height: (myWidgets.length > 8) ? '400px ' : 'fit-content' }}
                                    className="dropdown-menu dropDownMenuFixed p-2"
                                    id="service-table-action"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    <DragDropContext onDragEnd={handleDraggable}>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <div>
                                                <div className="rounded-top d-flex align-items-center">
                                                    <h3 className="card-title mb-0 fs-4 ">My Widgets</h3>
                                                    <div className="ms-auto">
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={(e) => { handleDropDown(e) }}
                                                        >
                                                            <CloseCircleOutlined style={{ fontSize: '1rem' }} />
                                                        </span>
                                                    </div>

                                                </div>

                                                <Droppable droppableId="droppable-1">
                                                    {(provided, _) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                        >
                                                            {myWidgets.map((widgets, i) => (
                                                                <Draggable
                                                                    key={widgets.id}
                                                                    draggableId={"draggable_" + widgets.id}
                                                                    index={i}
                                                                >
                                                                    {(provided, _) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className="dragable-item"
                                                                        // style={{
                                                                        //   ...provided.dragHandleProps.style,
                                                                        //   // boxShadow: snapshot.isDragging ? '0 0 .4rem #666' : 'none',
                                                                        //   // position: snapshot.isDragging ? 'unset' : ''
                                                                        // }}
                                                                        >
                                                                            <div className="px-2 dropdown-item border-bottom d-flex align-items-center justify-content-between py-2">
                                                                                <div className="text-dark">
                                                                                    <span class="ri-drag-move-line"></span>
                                                                                    <span className="ms-1">
                                                                                        {widgets.name}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="form-check form-switch">
                                                                                    <input
                                                                                        checked={widgets.checked}
                                                                                        onClick={() => handleHide(i)}
                                                                                        class="form-check-input"
                                                                                        type="checkbox"
                                                                                        id={widgets.id}
                                                                                    ></input>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>

                                            </div>
                                            <div className="dragable-item">
                                                {/* <button
                                            className="btn btn-primary w-100 mt-2"
                                            onClick={() => handleHiddenWidgets()}
                                        >
                                            Save
                                        </button> */}
                                                <Button type="primary" shape="round" className='addbutton w-100 mt-2'
                                                    size='default'
                                                    onClick={(e) => handleHiddenWidgets(e)}
                                                >Save <SaveOutlined /></Button>
                                            </div>
                                        </div>

                                    </DragDropContext>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    {widgetInfo && widgetInfo.widget_info && widgetInfo.widget_info.length > 0 ?
                                        <div className="row">
                                            {landingWidgets && landingWidgets.length > 0 && landingWidgets.map((widget, index) => {
                                                const Component = widget.component && widget.component;
                                                // console.log((!widget.visible || !widget.checked), landingWidgets, 'visiblecheck')
                                                if (!widget.visible || !widget.checked) return;
                                                return <Component key={`widgetId_` + index} />;
                                            })}
                                        </div> : <div class="text-center col-sm-12 m-t-30">
                                            <h4 class="text-center" style={{ marginTop: '15%' }}>Welcome {callername && callername.login_response && callername.login_response.emp_name}!</h4>
                                        </div>
                                    }
                                </div>
                            </div>
                        </> : ''}
                </div>
            </div>

        </div>
    )
}
export default connect(mapStateToProps, {
    getWidgetData,
    getRiskChart,
    getCountryData,
    getOverDueChart,
    getComplianceStatusChart,
    getTaskDistributionChart,
    getCriticalityChart,
    getComplianceTrend,
    getChartFilters,
    getDomainscorecard,
    SaveWidgetlist
})(LandingPage);