import { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Input, Button, Tooltip } from 'antd';
import SimpleReactValidator from "simple-react-validator";
import { Updatepassword } from './../../src/Store/Action/Profile';
import { useLocation, Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleTwoTone, PlayCircleOutlined } from '@ant-design/icons';
import { Toaster } from '../Libs/Toaster';
import { CountrySplit, HomeRedirect } from './../Libs/country'

const _ = require("lodash");

const homepage = HomeRedirect()
const mapStateToProps = () => ({});

const ChangePassword = ({
    Updatepassword
}) => {
    const { Search } = Input;
    const location = useLocation();
    const validator = useRef(new SimpleReactValidator());
    const formvalidator = useRef(new SimpleReactValidator());
    const [password, setPassword] = useState({
        current_password: '',
        new_password: '',
        confirm_password: ''
    })
    const [formdata, Setformdata] = useState({
        form_url: '',
        form_key: ''
    });
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const paramid = location.pathname;
    console.log(callername, 'callernamecallername')
    // const [Entity, setEntity] = useState("All Legal Entity")
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    useEffect(() => {
        validator.current.showMessages()
        formvalidator.current.showMessages()
        if (callername.login_response.password_expire_days < 0) {
            Toaster.error('Your password expired');
        }
    }, [])

    useEffect(() => {
        if (isAuth) {
            const getUrl = callername.login_response.menu['My Accounts']
            const ChangePassword = _.filter(getUrl, { form_name: 'Change Password' })
            // Setformurl(profile[0].form_url)
            Setformdata({
                ...formdata,
                form_url: ChangePassword[0].form_url,
                form_key: ChangePassword[0].form_key
            })
        }
    }, [isAuth])

    const SubmitPassword = () => {
        setAddFormSubmit1(true);
        //  && confirmpass === 'none'
        if (formvalidator.current.allValid()) {
            if (password.new_password == password.confirm_password) {
                const pay = [
                    authtoken,
                    [
                        "ChangePassword",
                        {
                            "session_token": "undefined-" + authtoken,
                            "current_password": password.current_password,
                            "new_password": password.new_password,
                            "confirm_password": password.confirm_password
                        }
                    ]
                ]

                Updatepassword({
                    payload: pay,
                    paramid: paramid,
                    formkey: formdata.form_key
                })
            } else {
                Toaster.error('New Password & Confirm Password should match');
            }

        }
    }

    const homescreen = () => {
        if (callername.login_response.password_expire_days < 0) {
            window.location.href = paramid
        } else {
            let getUrlDetails = homepage;
            let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
            let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
            localStorage.setItem('formkey', tempkey);
            //historynew.push(tempval);
            window.location.href = tempval
            document.getElementById('homehover').style.color = "blue"
        }

        document.getElementById('homehover').style.color = "blue"
    }
    const [confirmpass, showconfirmpass] = useState('none');
    const [confirmpasswrd, setConfirmPasswrd] = useState('')
    console.log(confirmpasswrd, 'confirmpasswrd')
    const confirmpasswordcheck = (e) => {

        if (password.new_password != e.target.value) {
            showconfirmpass('block');
            setPassword({
                ...password,
                confirm_password: e.target.value
            })
        } else {
            setPassword({
                ...password,
                confirm_password: e.target.value
            })
            showconfirmpass('none');
        }
    }

    const deletepassword = (e) => {
        console.log(e, 'check');
    }
    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-1">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                {/* <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <Link>My Accounts</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large' }}>
                                            <Link>Change Password</Link>
                                        </li>
                                    </ol>
                                </nav> */}
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>My Accounts</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span >Change Password</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                            </div>
                        </div>
                    </div>
                    <div className="page-titles  pb-2 pt-1">
                        {/* <Card> */}
                        <div className="container-fluid pt-1">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card p-2" >
                                        <div className="col-md-12">
                                            <div className='col-md-12'>
                                                <div className='row align-center'>
                                                    <div className='col-md-4'>
                                                        <label><b>Current Password</b> <span style={{ color: "red" }}>*</span></label>&nbsp;
                                                        <input maxLength={20} type='password' autoFocus="on" placeholder="Enter Current Password" name='Password'
                                                            className='form-control'
                                                            style={{ width: '100%' }}
                                                            onKeyPress={(event) => {
                                                                if (!/^\S/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                setPassword({
                                                                    ...password,
                                                                    current_password: e.target.value
                                                                })
                                                            }} />
                                                        {formvalidator.current.message(
                                                            'Password',
                                                            password.current_password,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Current Password Required'
                                                                }
                                                            })}

                                                    </div>
                                                    <div className='col-md-4'>
                                                        <span className="d-flex justify-content-start align-items-center">
                                                            <label><b>New Password</b> <span style={{ color: "red" }}>*</span></label>

                                                            <Tooltip placement='right' title={<div>
                                                                <p> * The password is at least 8 characters long</p>
                                                                <p> * The password has at least one uppercase letter</p>
                                                                <p> * The password has at least one lowercase letter</p>
                                                                <p> * The password has at least one digit</p>
                                                                <p> * The password has at least one special character</p>
                                                            </div>} >
                                                                <span className='mx-1 text-primary'>
                                                                    <i className="ri-information-fill" /></span>
                                                            </Tooltip>
                                                        </span>
                                                        <input type='password' maxLength={20}
                                                            placeholder="Enter New Password" name='New_Password' style={{ width: '100%' }}
                                                            className='form-control'
                                                            onKeyPress={(event) => {
                                                                if (!/^\S/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                setPassword({
                                                                    ...password,
                                                                    new_password: e.target.value
                                                                })
                                                                if (confirmpasswrd !== '') {
                                                                    if (confirmpasswrd !== e.target.value) {
                                                                        showconfirmpass('block')
                                                                    } else (
                                                                        showconfirmpass('none')
                                                                    )
                                                                }
                                                            }} />
                                                        {/* <i onClick={() => setIsRevealPwd(prevState => !prevState)} className={(isRevealPwd && pwd) ?  "ri-eye-line": "ri-eye-off-line"} style={{cursor: 'text'}} ></i> */}

                                                        {formvalidator.current.message(
                                                            'New_Password',
                                                            password.new_password,
                                                            ['required', { regex: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    regex: 'Enter Strong Password',
                                                                    required: 'New Password Required'
                                                                }
                                                            })}
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Confirm Password</b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className='position-relative'>
                                                            <input maxLength={20} type='password' name='Confirm_Password' value={confirmpasswrd} placeholder="Enter Confirm Password" style={{ width: '100%' }}
                                                                className='form-control'
                                                                onKeyPress={(event) => {
                                                                    if (!/^\S/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                onChange={(e) => {
                                                                    setConfirmPasswrd(e.target.value)
                                                                    confirmpasswordcheck(e)
                                                                }}
                                                            />
                                                            <span className='validation-icon' style={{ display: confirmpasswrd.length == 0 ? 'none' : 'block', color: confirmpasswrd === password.new_password ? 'green' : 'red' }}> {confirmpasswrd === password.new_password ? <CheckCircleOutlined /> : <CloseCircleOutlined />}</span>
                                                        </div>
                                                        {formvalidator.current.message(
                                                            'Confirm_Password',
                                                            password.confirm_password,
                                                            ['required', { regex: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    regex: 'Enter Strong Password',
                                                                    required: 'Confirm Password Required'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Button type="primary" shape="round" className='addbutton'
                                                onClick={SubmitPassword}
                                                icon={<PlayCircleOutlined />} >
                                                Submit
                                            </Button>


                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        {/* </Card> */}
                    </div>
                </div >

            </div>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
    Updatepassword
})(ChangePassword);
