import { Toaster } from './Toaster';

export const validationType = {
    textWithoutNumberSpChr: /[^a-zA-Z \n\r]+/g,
    textWithNumbersNoSpChr: /[^a-zA-Z 0-9\n\r]+/g,
    complianceTask: /[^a-zA-Z0-9_@#&*()<>?.]*$/g,
    email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g,
    numbersOnly: /[^\d]+/g,
    countryCode: /[^(\+?\d{1,3}|\d{1,4})]/g,
    statutoryremarks: /[^A-Z0-9a-z !@#$%&*()-+=`><_ .,-]/g,
    email1: /[^0-9a-z  @ # _ ; : , < . > / ? -]/g,
    address: /[^0-9a-zA-Z  @ # _ ; : , < . > / ? -]/g,
    ECuploadRemark: /[^A-Z0-9a-z _ .,-]/g
}

export const validateInput = (e, arg) => {
    const { value } = e.target;
    let finalValue = value.toString().replace(arg, '');
    return finalValue;
}

export const validateSelect = (value, arg) => {
    let finalValue = value.toString().replace(arg, '');
    return finalValue;
}

export const onPasteToaster = () => {
    Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.');
}