import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom'
import { Modal, Input, Table, Tooltip, Descriptions } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import DataTable from "react-data-table-component";
import { notificationshowall, updatenotification } from '../../Store/Action/Login';
import { Toaster } from '../../Libs/Toaster';
import { PlusSquareTwoTone, LikeOutlined, DislikeOutlined, PoweroffOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import url, { getUrl } from './../../Libs/URL'
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()
const urls = getUrl()

const mapStateToProps = (state) => ({
    lists: state.Login.notificationshowall.statutory,
    modal: state.Login.notificationmodal.statutory_notification_details

})
const Notifications = ({
    notificationshowall,
    updatenotification,
    lists,
    modal
}) => {
    const historynew = useHistory();
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    // const [remark, setRemark] = useState()
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [modalVisible, setModaVisible] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const { Search } = Input;
    const header = paramid.split('/');
    var breadcrumb = '';
    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    var type = '';
    if (header[1] == 'reminders') {
        breadcrumb = 'Remainders';
        type = 2;
    } else if (header[1] == 'notifications') {
        breadcrumb = 'Notifications';
    } else if (header[1] == 'messages') {
        breadcrumb = 'Messages';
        type = 4;
    } else if (header[1] == 'escalations') {
        breadcrumb = 'Escalations';
        type = 3;
    }

    const downloadreport = (e, record) => {
        window.open(urls + '/' + e);
        markread(record)

    }

    const [modalData, setmodalData] = useState({
        compliance_id: '',
        statutory_provision: '',
        compliance_task: '',
        compliance_description: '',
        penal_consequences: '',
        freq_name: '',
        summary: '',
        reference_link: '',
    })
    const [showModal, setshowModal] = useState(false);

    useEffect(() => {
        if (isAuth && modal) {
            setmodalData({
                ...modalData,
                compliance_id: modal[0].compliance_id,
                statutory_provision: modal[0].statutory_provision,
                compliance_task: modal[0].compliance_task,
                compliance_description: modal[0].compliance_description,
                penal_consequences: modal[0].penal_consequences,
                freq_name: modal[0].freq_name,
                summary: modal[0].summary,
                reference_link: modal[0].reference_link

            })
        }
    }, [isAuth, modal])

    const handleCancel = () => {
        setshowModal(false)
        setmodalData(false);
        let payload;
        if (legalid != 'null') {
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]
        } else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotifications",
                        {
                            "le_ids": temp,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]
        }



        notificationshowall({
            payload: payload,
            caller: paramid
        })
    }

    const markread = (record) => {
        let notifypayload;
        setshowModal(true)
        if (authtoken && legalid && legalid != "null") {
            notifypayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UpdateStatutoryNotificationsStatus",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_id": record.notification_id,
                            "has_read": true,
                        }
                    ]
                }
            ]

        } else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            notifypayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UpdateStatutoryNotificationsStatus",
                        {
                            "le_ids": temp,
                            "notification_id": record.notification_id,
                            "has_read": true,
                        }
                    ]
                }
            ]
        }
        updatenotification({
            payload: notifypayload,
            paramid: paramid
        })
        setmodalData({
            ...modalData,
            compliance_id: '',
            statutory_provision: '',
            compliance_task: '',
            compliance_description: '',
            penal_consequences: '',
            freq_name: '',
            summary: '',
            reference_link: '',
        })

    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_on',
            key: 'created_on',
            width: '20px'

        },
        {
            title: 'Title',
            dataIndex: 'notification_text',
            key: 'notification_text',
            width: '300px',
            render: (text, record) => {
                return <span className='pointer' onClick={(e) => { markread(record) }}>{record.notification_text}</span>

            }

        },
        {
            title: 'Name',
            dataIndex: 'user_name',
            key: 'user_name',
            width: '12%'
        }
    ];

    useEffect(() => {
        if (isAuth) {
            let payload;
            if (legalid != 'null') {
                payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetStatutoryNotifications",
                            {
                                "le_ids": [
                                    legalid
                                ],
                                "start_count": 0,
                                "end_count": 3
                            }
                        ]
                    }
                ]
            }
            else {
                let temp = [];
                for (let i = 0; i < callername.entity_info.length; i++) {
                    temp.push(callername.entity_info[i].le_id);
                }
                payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetStatutoryNotifications",
                            {
                                "le_ids": temp,
                                "start_count": 0,
                                "end_count": 3
                            }
                        ]
                    }
                ]
            }



            notificationshowall({
                payload: payload,
                caller: paramid
            })

        }
    }, [isAuth])

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
        window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    return (
        <Fragment>
            <div>

                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Notification</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span>Statutory Notification</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">

                                    <div className="card-body" style={{ padding: '0px' }}>
                                        <div className={localStorage.getItem("currentTheme")}>
                                            <Table
                                                className='userprivclass'
                                                columns={columns}
                                                dataSource={lists}
                                                bordered
                                                pagination={{
                                                    defaultPageSize: dataTableProperties.pagesize,
                                                    showSizeChanger: dataTableProperties.sizechanger
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

                <Modal title="Messages" className={" add-service-prv " + localStorage.getItem('currentTheme')} open={showModal} footer={false} onCancel={handleCancel} maskClosable={false}>
                    <Descriptions layout="vertical" className='labelclass'>
                        <Descriptions.Item label="Statutory Provision">{modalData && modalData.statutory_provision}</Descriptions.Item>
                        <Descriptions.Item label="Compliance Task">{modalData && modalData.compliance_task}</Descriptions.Item>
                        <Descriptions.Item label="Compliance Description">{modalData && modalData.compliance_description}</Descriptions.Item>
                        <Descriptions.Item label="Penal Consequences">{modalData && modalData.penal_consequences}</Descriptions.Item>
                        <Descriptions.Item label="Compliance Frequency">{modalData && modalData.freq_name}</Descriptions.Item>
                        <Descriptions.Item label="Compliance Occurrence">
                            {modalData && modalData.summary}
                        </Descriptions.Item>
                        <Descriptions.Item label="Reference Link">
                            {modalData && modalData.reference_link}
                        </Descriptions.Item>
                    </Descriptions>
                </Modal>

            </div>

        </Fragment>
    )
}
export default connect(mapStateToProps, {
    notificationshowall,
    updatenotification
})(Notifications);