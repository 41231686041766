import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url, { getUrl } from '../../../Libs/URL'
import EncryptDecrypt from '../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { EASY_UPLOAD_FILTERDATA, COMPLIANCE_UPCOMING_TASK, COMPLIANCE_TASK_CALENDER_VIEW, EASY_UPLOAD_FILTER, EASY_UPLOAD_SHOWDATA, EASY_UPLOAD_TABLEDATA, TOGGLE_COMMON_LOADER, IS_INVALID, REMARKS_HISTORY_TABLE, EASY_FILTER_MODAl } from '../../types/index';
import alertmessage from '../../../Libs/Altermessage';
const urls = getUrl()

export const getFilterDataValues = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {

                dispatch({
                    type: EASY_UPLOAD_FILTERDATA,
                    payload: decryptData,
                });
            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const getShowdata = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, '99999556');
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        dispatch({
            type: EASY_FILTER_MODAl,
            payload: false
        })
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123452");
                dispatch({
                    type: EASY_FILTER_MODAl,
                    payload: false
                })

                dispatch({
                    type: EASY_UPLOAD_SHOWDATA,
                    payload: decryptData,
                });
            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const getTabledata = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload, 'payload555');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, "decryptData1111");
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else if (decryptData[0] === 'EasyUploadRecordsSuccess') {

                dispatch({
                    type: EASY_UPLOAD_TABLEDATA,
                    payload: decryptData,
                });

            } else {

                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const EasyUploadFile = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/files`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));

            if (decryptData[0] == 'FileUploadSuccess') {
                // Toaster.success("File Upload Successfully"
                // )
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

                dispatch({
                    type: IS_INVALID,
                    payload: false
                })
            } else if (decryptData[0] == "UnSupportedFile") {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: IS_INVALID,
                    payload: true
                })
                Toaster.error("Invalid File Format")
            }
            else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Failed")
            }

            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            }
            // dispatch({
            //     type: COMPLETED_TASK_LIST,
            //     payload: decryptData,
            // });
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

// RemarkHistory

export const RemarkHistory = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, 'GetComplianceRemarksHistoryDataSuccess')

            if (decryptData[0] == "GetComplianceRemarksHistoryDataSuccess") {
                // Toaster.success("File Upload Successfully"
                // )
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: REMARKS_HISTORY_TABLE,
                    payload: decryptData
                })

                // dispatch({
                //     type: IS_INVALID,
                //     payload: false
                // })
            }
            // else if(decryptData[0] ==  "UnSupportedFile"){
            //     dispatch({
            //         type: TOGGLE_COMMON_LOADER
            //     });
            //     // dispatch({
            //     //     type: IS_INVALID,
            //     //     payload: true
            //     // })
            //     Toaster.error("Invalid File Format")
            // }
            //  else {
            //     dispatch({
            //         type: TOGGLE_COMMON_LOADER
            //     });
            //     Toaster.error("Failed")
            // }

            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            }
            // dispatch({
            //     type: COMPLETED_TASK_LIST,
            //     payload: decryptData,
            // });
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const saveuploadStatus = ({ payload, executeCancel, paramid, payload1, payload2, setCheckBoxValue1,setCheckBoxValue }) => async dispatch => {
    try {
        console.log('555555855555');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else if (decryptData[0] == 'InvalidPassword') {

                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Invalid Password")
            } else if (decryptData[0] == 'ComplianceUpdateSuccess') {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                Toaster.success("Compliance Submitted Successfully")
                // setValueModal({
                //     ...valueModal,
                //     document_reference_number: ''
                // })
                // setCompletionDate("")
                // SetRemarks("")
                setCheckBoxValue1([])
                setCheckBoxValue([])
                dispatch(

                    getTabledata({
                        payload: payload1,
                        paramid: paramid
                    })

                )
                dispatch(
                    getShowdata({
                        payload: payload2,
                        paramid: paramid
                    })
                )
                // setTimeout(() => {
                //     window.location.reload(false);
                // }, 2500);

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])


            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};

// export const downloadfiletemp = ({ payload, executeCancel, paramid }) => async dispatch => {
//     try {
//         dispatch({
//             type: TOGGLE_COMMON_LOADER
//         });
//         const encryptkey = localStorage.getItem('formkey')
//         const head = {
//             headers: {
//                 'Caller-Name': paramid
//             },
//         }
//         let encoded = base64_decode(encryptkey);
//         const data1 = EncryptDecrypt.encryptdata(payload, encoded);
//         const createRequest = HTTP.commonpost({
//             url: urls + `/api/files`,
//             headers: head,
//             executeCancel,
//             body: data1

//         })
//         const executeRequest = await HTTP.execute(createRequest)
//         if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
//                 executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
//             (executeRequest.status === 400 || executeRequest.status === 401)) {
//             dispatch({
//                 type: TOGGLE_COMMON_LOADER
//             });
//             Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
//         } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
//                 executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
//             (executeRequest.status === 401 || executeRequest.status === 503)) {
//             dispatch({
//                 type: TOGGLE_COMMON_LOADER
//             });
//             alertmessage.sweetalert();

//         } else {
//             const response = HTTP.parseResponse(executeRequest)
//             const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
//             console.log(decryptData, "decryptData");
//             // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
//             dispatch({
//                 type: TOGGLE_COMMON_LOADER
//             });
//             if (decryptData[0] === 'InvalidSessionToken') {
//                 dispatch({
//                     type: TOGGLE_COMMON_LOADER
//                 });
//                 alertmessage.sweetalert();
//             } else {
//                 if (decryptData[1].link == null) {
//                     Toaster.error("No record to Export!")
//                 } else {
//                     // dispatch({
//                     //     type: ASSIGNEE_WISE_COMPLIANCE_EXPORT,
//                     //     payload: decryptData[1],
//                     // });
//                 }
//             }
//         }


//     } catch (err) {
//         Toaster.error(err)
//         dispatch({
//             type: TOGGLE_COMMON_LOADER
//         });
//     }
// };
export const downloadfiletemp = ({ payload, executeCancel, paramid, content }) => async dispatch => {
    try {

        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/files`,
            headers: head,
            executeCancel,
            body: data1,

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            console.log(content, "decryptDatadecryptDtata5");
            var a = document.createElement('a');
            var blob = dataURItoBlob(response, 'application/octet-stream');
            a.href = window.URL.createObjectURL(blob);
            console.log(payload, 'payloadpayload123');
            console.log(payload[1].request[1].file_name, 'payload[1].request[1].file_name');
            a.download = payload[1].request[1].file_name;
            a.click();
            // var blob = dataURItoBlob(executeRequest.data, 'application/octet-stream');
            // console.log(blob, 'blobs')
            // let fileReader = new FileReader();
            // fileReader.readAsDataURL(blob)
            // fileReader.onload = function (e) {
            //     console.log(e.target.result, 'e.target.result')
            //     var link = document.createElement('a');
            //     link.setAttribute(
            //         'download',
            //     );
            //     link.click();
            //     link.parentNode.removeChild(link);
            // };
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const downloadfiletemp1 = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload, 'payload');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/files`,
            headers: head,
            executeCancel,
            body: data1,

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });

            var a = document.createElement('a');
            var blob = dataURItoBlob(response, 'application/octet-stream');
            a.href = window.URL.createObjectURL(blob);
            console.log(payload, 'payloadpayload123');
            console.log(payload[1].request[1].file_name, 'payload[1].request[1].file_name');
            a.download = payload[1].request[1].file_name;
            a.click();
            // var blob = dataURItoBlob(executeRequest.data, 'application/octet-stream');
            // console.log(blob, 'blobs')
            // let fileReader = new FileReader();
            // fileReader.readAsDataURL(blob)
            // fileReader.onload = function (e) {
            //     console.log(e.target.result, 'e.target.result')
            //     var link = document.createElement('a');
            //     link.setAttribute(
            //         'download',
            //     );
            //     link.click();
            //     link.parentNode.removeChild(link);
            // };
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const removedocument = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {

        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/files`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, "decryptData111");

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                // dispatch({
                //     type: COMPLIANCE_APPROVAL_REMOVE,
                //     payload: decryptData,
                // });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

function dataURItoBlob(dataURI, contentType) {
    console.log(dataURI, 'blobs')
    var str = dataURI.replace(/\s/g, '');
    var binary = base64_decode(str);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: contentType });
}