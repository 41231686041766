import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url, { getUrl } from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { PRIVILEGES, TOGGLE_COMMON_LOADER, CURRENTSTATUS } from './../../types/index';
import alertmessage from '../../../Libs/Altermessage';
import { toast } from 'react-toastify';
const urls = getUrl()


export const privilegeslist = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        const encryptkey = localStorage.getItem('formkey')

        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: PRIVILEGES,
                    payload: decryptData,
                });
            }
        }
    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const updatestatususerpreviliges = ({ payload, executeCancel, paramid, type, initialPayload, modal }) => async dispatch => {
    console.log(type, "typelisttypelist");
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            }
            else {
                if (decryptData[0] == 'InvalidPassword') {
                    dispatch({
                        type: TOGGLE_COMMON_LOADER
                    });
                    modal(true)
                    Toaster.error('Invalid Password');
                    dispatch({
                        type: CURRENTSTATUS,
                        payload: 'Invalidpassword'
                    })

                    // setTimeout(() => {
                    //     Setpassvisible(true)
                    // }, 3000);
                } else if (decryptData[0] == 'UserPrivilegesExists.CannotDeactivateUserPrivileges.') {
                    Toaster.error("User Privileges Exists Cannot Deactivate User Privileges")
                    // setTimeout(() => {
                    //     window.location.reload(false);
                    // }, 3000);
                } else {
                    console.log(decryptData[0], 'decryptData[0]');
                    if (decryptData[0] === "ChangeUserPrivilegeStatusSuccess") {
                        type === 0 ?
                            Toaster.success("User Privileges Activated Successfully") :
                            Toaster.success("User Privileges Deactivated Successfully")
                        dispatch(
                            privilegeslist({
                                payload: initialPayload,
                                paramid: paramid
                            })
                        )
                        dispatch({
                            type: CURRENTSTATUS,
                            payload: 'Success'
                        })
                        // setTimeout(() => {
                        //     window.location.reload(false);
                        // }, 3000);
                    } else if (decryptData[0] == "CannotDeactivateUserExists") {
                        Toaster.error('User Privileges Exists. Cannot Deactivate User Privileges')
                        dispatch({
                            type: CURRENTSTATUS,
                            payload: 'Success'
                        })
                    } else {
                        Toaster.error(decryptData[0])
                        setTimeout(() => {
                            window.location.reload(false);
                        }, 3000);
                    }
                }
            }
        }


    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const saveuserpreviliges = ({ payload, executeCancel, paramid, initialPayload }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            }
            else {
                if (decryptData[0] == 'SaveUserPrivilegesSuccess') {
                    Toaster.success('User Privileges Added Successfully')
                    dispatch(
                        privilegeslist({
                            payload: initialPayload,
                            paramid: paramid
                        })
                    )
                    dispatch({
                        type: CURRENTSTATUS,
                        payload: 'Success'
                    })
                    // setTimeout(() => {
                    //     window.location.reload(false);
                    // }, 2000);
                } else if (decryptData[0] === 'UserGroupNameAlreadyExists') {
                    console.log("trueeee123");
                    toast.error('User Group Name Already Exists')
                    dispatch({
                        type: TOGGLE_COMMON_LOADER
                    });
                    // setTimeout(() => {
                    //     window.location.reload(false);
                    // }, 2000);
                } else {
                    dispatch({
                        type: TOGGLE_COMMON_LOADER
                    });
                    Toaster.error(decryptData[0]);
                }
            }
        }

    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const updateUserPrivileges = ({ payload, executeCancel, paramid, initialPayload }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            }
            else {
                if (decryptData[0] == 'UpdateUserPrivilegesSuccess') {
                    Toaster.success('User Privileges Updated Successfully')
                    dispatch(
                        privilegeslist({
                            payload: initialPayload,
                            paramid: paramid
                        })
                    )
                    dispatch({
                        type: CURRENTSTATUS,
                        payload: 'Update'
                    })
                    // setTimeout(() => {
                    //     window.location.reload(false);
                    // }, 2000);
                } else if (decryptData[0] === 'UserGroupNameAlreadyExists') {
                    toast.error('User Group Name Already Exists')
                    dispatch({
                        type: TOGGLE_COMMON_LOADER
                    });
                    // setTimeout(() => {
                    //     window.location.reload(false);
                    // }, 2000);
                } else {
                    dispatch({
                        type: TOGGLE_COMMON_LOADER
                    });
                    Toaster.error(decryptData[0]);
                }
            }
        }




    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const clearcurrentstatus = (initial) => async dispatch => {
    dispatch({
        type: CURRENTSTATUS,
        payload: initial
    })
    dispatch({
        type: TOGGLE_COMMON_LOADER
    })
}