import React from 'react'
import { Collapse, Select, Input, DatePicker, Card, Drawer, Button, Pagination, Table, Modal, Progress, Tooltip } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, Fragment } from 'react';
import { FilterValues, getServiceProviderReportData, getRemarksData, getserviceProviderExport } from '../../../Store/Action/Report/ServiceProviderComplinaceReport';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import moment from 'moment';
import { Toaster } from '../../../Libs/Toaster';
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import useStickyHeight from "../../../Libs/useStickyHeight";
import { validationType, validateSelect, onPasteToaster } from './../../../Libs/inputValidation';
import { ExclamationCircleTwoTone, ExportOutlined, EyeOutlined, InfoCircleOutlined, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, DownloadOutlined, UpOutlined, FullscreenOutlined, FilterOutlined } from '@ant-design/icons';
import ScrollToTop from "react-scroll-to-top";
import { downloadDocument } from '../../../Store/Action/Report/RiskReport';
import ScrollButton from '../../Scrollbar';
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
import { interimrecord } from '../../../Store/Action/Report/TaskCategoryReport';
import { Downloadfile } from '../../../Store/Action/Transcations/ComplianceApproval';

const uniq_country_data = CountrySplit()
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    filterValue: state.ServiceProviderComplianceReport.ServiceProviderComplianceReport,
    interim_record: state.TaskCategoryReport.taskCategoryReport.interim_data,
})
const ServiceProviderComplianceReports = (({
    FilterValues,
    filterValue,
    getServiceProviderReportData,
    getTaskwiseReportData,
    getUnitData,
    getRemarksData,
    getserviceProviderExport,
    interimrecord,
    Downloadfile,
    interim_record,
    downloadDocument
}) => {
    console.log(filterValue, 'filterValuefilterValue');
    useStickyHeight();
    const historynew = useHistory();
    const _ = require("lodash");
    const validator = useRef(new SimpleReactValidator());
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [RemarksModal, setRemarksModal] = useState(false);
    const [titledata, Settitledata] = useState()
    console.log(titledata, 'titledata');
    const [tableshow, setTableShow] = useState(false)
    const [childCompliceId, setChildComplinaceId] = useState([])
    console.log(childCompliceId, 'childCompliceId');
    const [key, setKey] = useState("1")
    let [index, setIndex] = useState(0)
    const [entity, setEntity] = useState([])
    const [complianceID, setComplianceID] = useState([])
    const [uniqueData, setUniqueData] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    console.log(filteredArrayState, 'filteredArrayState');
    const [current, setCurrent] = useState(1);
    console.log(current, 'current');
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [paginationArray, setPaginationArray] = useState([])
    const [temppaginationArray, settemppaginationArray] = useState([])
    const [currentpaginationArray, setcurrentpaginationArray] = useState([])
    console.log(currentpaginationArray, 'currentpaginationArray');
    console.log(temppaginationArray, 'temppaginationArray');
    console.log(paginationArray, 'paginationArray');
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    console.log(filterValue, 'filterValue');
    const [consolidatedData, setConsolidatedData] = useState({
        serviceProviderName: "",
        country: "",
        category: "",
        user_type: "",
        legel_entity: "",
        legal_entity_name: "",
        unit: '',
        user: "",
        domain: "",
        act: "",
        from_date: "",
        task_category: "",
        compliance_task: "",
        to_date: "",
        task_sub_category: "",
        compliance_frequency: "",
        compliance_task_status: "",
        division: "",
        domainName: "",
        countryName: '',
        complianceName: "",
        serviceProvider: '',
        countryLabel: '',
        domainLabel: '',
        legalEntityLabel: '',
        serviceProviderLabel: ''

    })

    const [open, setOpen] = useState(false);
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const sessionArr = sessionParsedValue.entity_info
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [domain, setDomain] = useState([])
    console.log('doamain', domain)
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 3, 8, 9, 10, 11, 32, 35])
    const [exportButton, setExportButton] = useState(false)
    const [division, setDivision] = useState([])
    const [ComplianceTask, setComplianceTask] = useState([])
    const [Acts, setActs] = useState([])
    console.log(Acts, 'Acts');
    const [complianceTaskStatus, setcomplianceTaskStatus] = useState([])
    const [user, setuser] = useState([])
    console.log(user, 'user');
    const [serviceProvider, setServiceProvider] = useState([])
    const [frequency, setfrequency] = useState([])
    const [category, setCategoryList] = useState([])
    const [unitcode, setUnitcode] = useState([])
    const [units, setUnits] = useState([])
    const [disableButton, setDisableButton] = useState(false)
    const [statkey, setStatKey] = useState("0");
    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        serviceProvider: '',
        BusinessGroup: '',
        domain: '',
        actRules: '',
        complianceTask: '',
        user: '',
        division: '',
        category: '',
        unit: '',
    });

    const { Panel } = Collapse;
    const { Option } = Select;

    const ComplianceTaskName = e => {
        console.log(e, 'eeeeeeee')
        const { value } = e.target;
        // onChange={(e) => {
        let chk_valid = ''

        for (let i = 0; i < value.length; i++) {
            if (value[i] == '<' || value[i] == '>') {
                chk_valid += value[i]
            }
        }
        if (chk_valid.match('<>')) {
            //   UpdatePannelRemarks[collapseId] = ''
            setConsolidatedData({
                ...consolidatedData,
                compliance_task: ''
            })
            Toaster.error('Please check the input, it having some invalid characters')
        } else {

            const re = /[^|~^]+$/;
            if (value === "" || re.test(value)) {
                if (value.includes('~') || value.includes('^') || value.includes('|')) {
                    return false
                } else {
                    setConsolidatedData({
                        ...consolidatedData,
                        compliance_task: e.target.value
                    })
                }
            }
        }
    }

    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            Settitledata([])
            setfilteredArrayState([])
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            setExitCollapse(true);
            setFilterTaskAcc(false);
            setFilterModalVisible(false);
            setIndex(0);
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            setConsolidatedData({
                ...consolidatedData,
                countryLabel: consolidatedData.countryName,
                domainLabel: consolidatedData.domainName,
                legalEntityLabel: consolidatedData.legal_entity_name,
                serviceProviderLabel: consolidatedData.serviceProviderName
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviderWiseReport",
                        {
                            "country_id": Number(consolidatedData.country),
                            "legal_entity_id": entityid,
                            "sp_id": Number(consolidatedData.serviceProvider),
                            "domain_id": Number(consolidatedData.domain),
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
                            "parent_id": consolidatedData.act ? Number(consolidatedData.act) : 0,
                            "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                            "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
                            "due_from_date": moment(consolidatedData.from_date).format('DD-MMM-YYYY'),
                            "due_to_date": moment(consolidatedData.to_date._d).format('DD-MMM-YYYY'),
                            "task_status": consolidatedData.compliance_task_status ? Number(consolidatedData.compliance_task_status) : 'All',
                            "csv": false,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": null
                        }
                    ]
                }
            ]
            getServiceProviderReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const onClose = () => {
        setOpen(false);
    };

    const disablecondition = (e) => {
        var currentToDate = moment(consolidatedData.from_date).add(3, 'months')
        if (currentToDate < moment(e)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
        else {
            Toaster.error('Please Select Required Fields')
        }
    };

    const handleOk = () => {
        const remarks = checkboxValue.includes(35);
        const myArray = checkboxValue;
        let status = myArray.includes(0);
        if (!status) {
            myArray.unshift(0);
        }

        if (remarks) {
            const index = myArray.indexOf(35);
            const x = myArray.splice(index, 1);
        }
        setCheckBoxValue(myArray);

        setAddFormSubmit(true)
        setExportButton(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviderWiseReport",
                        {
                            "country_id": Number(consolidatedData.country),
                            "legal_entity_id": entityid,
                            "sp_id": Number(consolidatedData.serviceProvider),
                            "domain_id": Number(consolidatedData.domain),
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
                            "parent_id": consolidatedData.act ? Number(consolidatedData.act) : 0,
                            "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                            "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
                            "due_from_date": moment(consolidatedData.from_date).format('DD-MMM-YYYY'),
                            "due_to_date": moment(consolidatedData.to_date._d).format('DD-MMM-YYYY'),
                            "task_status": consolidatedData.compliance_task_status ? Number(consolidatedData.compliance_task_status) : "All",
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            console.log('Payload', payload);
            getserviceProviderExport({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false);
        // setCheckBoxValue([1, 2, 3, 8, 9, 10, 11, 32, 35])
    };

    const checkBoxOnChange = (e) => {
        let temp = checkboxValue;
        let tempIds = [];
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1);
                }
            }
            setCheckBoxValue([...temp]);
        } else {
            tempIds.push(Number(e.target.value));
            setCheckBoxValue([...checkboxValue, ...tempIds]);
        }
    };


    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([1, 2, 3, 8, 9, 10, 11, 32, 35])
        }
    }
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsModalVisible(false)
        setRemarksModal(false)
        setCheckBoxValue([1, 2, 3, 8, 9, 10, 11, 32, 35])
    };
    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidatedData.legel_entity)

        }
    }, [entityid])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setConsolidatedData({
                ...consolidatedData,
                legel_entity: localStorage.getItem('SelectedEntityid'),
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
            })
        }

    }, [localStorage.getItem('SelectedEntity')])

    const ComplianceTaskOnChange = (data, value) => {
        setConsolidatedData({
            ...consolidatedData,
            compliance_task: data,
            complianceName: value.children
        })
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetTaskWiseActReportFilters",
                    {
                        "country_id": Number(consolidatedData.country),
                        "legal_entity_id": entityid,
                        "domain_id": Number(consolidatedData.domain),
                        "comp_tsk_id": Number(data)
                    }
                ]
            }
        ]
        getUnitData({
            payload: payload,
            paramid: paramid
        })
    }
    useEffect(() => {
        let tempArr = []
        if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 25) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {
                let uniqueObjArray = [...new Map(item && item.length && item.map((items) => [items["compliance_id"], items])).values()];
                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.compliance_id
                })

                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { compliance_id: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })

            setUniqueData(tempArr)

        }
    }, [filteredArrayState])



    useEffect(() => {
        console.log(filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances, 'filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances');
        if (currentpaginationArray && currentpaginationArray.length > 0) {
            var array = currentpaginationArray && currentpaginationArray.slice((current - 1) * pageSize, (current * pageSize))
            // setPaginationArray(array)
            console.log(array, 'arrayarrayarrayarray');
            setPaginationArray(array)
        }
    }, [currentpaginationArray, pageSize, current])

    useEffect(() => {
        console.log(filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances, 'filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances');
        if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0) {
            // var array = filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances.slice((current - 1) * pageSize, (current * pageSize))
            settemppaginationArray(filterValue.ServiceProviderComplianceReportList.sp_compliances)
        }
    }, [filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances])

    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];


        // setTempeditdata(editdata)
        if (paginationArray && paginationArray.length > 0) {
            const ab = paginationArray && paginationArray.length && paginationArray.map(function (item) {
                if ((array.indexOf(item.statutory_mapping) < 0))
                    array.push(item.statutory_mapping)
            })

            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationArray && paginationArray.length && paginationArray.map((child, childIndex) => {
                        if (data === child.statutory_mapping) {
                            entityArray = {
                                "activity_date": child.activity_date,
                                "activity_status": child.activity_status,
                                "assignee_name": child.assignee_name,
                                "category_name": child.category_name,
                                "completion_date": child.completion_date,
                                "compliance_activity_id": child.compliance_activity_id,
                                "compliance_description": child.compliance_description,
                                "compliance_history_id": child.compliance_history_id,
                                "compliance_id": child.compliance_id,
                                "child": child.child,
                                "compliance_task": child.compliance_task,
                                "country_id": child.country_id,
                                "criticality_name": child.criticality_name,
                                "division_name": child.division_name,
                                "doc_ref_num": child.doc_ref_num,
                                "document_name": child.document_name,
                                "domain_id": child.domain_id,
                                "due_date": child.due_date,
                                "extend_due_date": child.extend_due_date,
                                "frequency_name": child.frequency_name,
                                "history_count": child.history_count,
                                "interim_count": child.interim_count,
                                "legal_entity_id": child.legal_entity_id,
                                "logo_url": child.logo_url,
                                "remarks_count": child.remarks_count,
                                "start_date": child.start_date,
                                "statutory_mapping": child.statutory_mapping,
                                "task_status": child.task_status,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                " unit_name": child.unit_name,
                                "url": child.url,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

        // else {
        //     if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0) {
        //         const ab = filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0 && filterValue.ServiceProviderComplianceReportList.sp_compliances.map(function (item) {
        //             if ((array.indexOf(item.statutory_mapping) < 0))
        //                 array.push(item.statutory_mapping)
        //         })

        //         array.map((data, i) => {
        //             let temp = [];
        //             let entityArray = [];
        //             let datavalue = [];
        //             if (data) {
        //                 filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0 && filterValue.ServiceProviderComplianceReportList.sp_compliances.map((child, childIndex) => {
        //                     if (data === child.statutory_mapping) {
        //                         entityArray = {
        //                             "activity_date": child.activity_date,
        //                             "activity_status": child.activity_status,
        //                             "assignee_name": child.assignee_name,
        //                             "category_name": child.category_name,
        //                             "completion_date": child.completion_date,
        //                             "compliance_activity_id": child.compliance_activity_id,
        //                             "compliance_description": child.compliance_description,
        //                             "compliance_history_id": child.compliance_history_id,
        //                             "compliance_id": child.compliance_id,
        //                             "compliance_task": child.compliance_task,
        //                             "country_id": child.country_id,
        //                             "criticality_name": child.criticality_name,
        //                             "division_name": child.division_name,
        //                             "doc_ref_num": child.doc_ref_num,
        //                             "document_name": child.document_name,
        //                             "domain_id": child.domain_id,
        //                             "due_date": child.due_date,
        //                             "extend_due_date": child.extend_due_date,
        //                             "frequency_name": child.frequency_name,
        //                             "history_count": child.history_count,
        //                             "interim_count": child.interim_count,
        //                             "legal_entity_id": child.legal_entity_id,
        //                             "logo_url": child.logo_url,
        //                             "remarks_count": child.remarks_count,
        //                             "start_date": child.start_date,
        //                             "statutory_mapping": child.statutory_mapping,
        //                             "task_status": child.task_status,
        //                             "tz_name": child.tz_name,
        //                             "unit_address": child.unit_address,
        //                             "unit_id": child.unit_id,
        //                             " unit_name": child.unit_name,
        //                             "url": child.url,
        //                             'childId': childIndex,
        //                             'parentId': i
        //                         }
        //                         temp.push(entityArray)
        //                     }
        //                 })
        //             }

        //             datavalue = {
        //                 index: i,
        //                 parent: data,
        //                 child: temp
        //             }
        //             finalarray.push(datavalue)
        //         })
        //         Settitledata(finalarray)

        //     }
        // }


    }, [paginationArray])

    useEffect(() => {
        if (paginationArray && paginationArray.length > 0) {
            let uniqueObjArray = [...new Map(paginationArray && paginationArray.length && paginationArray.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(paginationArray, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
                setFilteredArray([...filteredArray, tempArr])
            }
        }
    }, [paginationArray])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }

    }, [filteredArray])

    useEffect(() => {
        // setTempeditdata(editdata)

        if (temppaginationArray && temppaginationArray.length > 0) {
            let expected_arr = [];
            for (let i = 0; i < temppaginationArray.length; i++) {
                let check_child = _.findIndex(expected_arr, { "compliance_history_id": temppaginationArray[i].compliance_history_id });
                if (check_child >= 0) {
                    expected_arr[check_child].child.push(temppaginationArray[i]);
                } else {
                    temppaginationArray[i].child = [];
                    expected_arr.push(temppaginationArray[i])
                }
            }
            console.log(expected_arr, 'expected_arr');
            // Settitledata(expected_arr)
            // setPaginationArray(expected_arr)
            setcurrentpaginationArray(expected_arr)


        }

    }, [temppaginationArray])

    const chilData = (id, unitId, compliancehistoryID) => {
        console.log(id, unitId, compliancehistoryID, 'pppppppppppppp');
        setIsModalVisible(true)
        if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 25) {
            let tempArr = []
            for (let i in filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances) {
                if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i].compliance_history_id == compliancehistoryID) {
                    if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i].unit_id == unitId) {
                        tempArr.push(filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i])
                    }
                }
            }
            // setChildComplinaceId(tempArr)

        }
    }
    const columns = [
        {
            title: 'User Name',
            ellipsis: true,
            width: '50px',
            dataIndex: 'assignee_name',
            key: 'assignee_name',
        },
        {
            title: 'Activity Status',
            ellipsis: true,
            width: '40px',
            dataIndex: 'activity_status',
            key: 'activity_status',
        },
        {
            title: 'Activity Date',
            ellipsis: true,
            width: '40px',
            dataIndex: 'activity_date',
            key: 'activity_date',
        },
        {
            title: 'Task Completion Date/Document issued Date',
            ellipsis: false,
            width: '70px',
            dataIndex: 'completion_date',
            key: 'completion_date',
        },

    ];
    const column = [
        {
            title: 'Activity Date',
            ellipsis: true,
            width: '50px',
            dataIndex: 'activity_on',
            key: 'activity_on',
        },
        {
            title: 'User Type',
            ellipsis: true,
            width: '50px',
            dataIndex: 'user_category_id',
            key: 'user_category_id',
            render: (row, index, record) => {

                return (
                    <>
                        <p>Assignee</p>
                    </>
                )
            }
        },
        {
            title: 'Reviewer Report',
            ellipsis: true,
            width: '50px',
            dataIndex: 'activity_date',
            key: 'activity_date',
        },
        {
            title: 'Remarks',
            ellipsis: true,
            width: '50px',
            dataIndex: 'remarks',
            key: 'remarks',
        },

    ];
    const item = [
        {
            compliance: "manoj"
        }
    ]
    const onChange = (key) => {
        console.log(key);
    };

    const onStatChange = (key) => {
        console.log(key);
    }

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviderWiseReportFilters",
                        {
                            "country_id": Number(consolidatedData.country),
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null') {
                FilterValues({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid])

    useEffect(() => {
        validator.current.showMessages()
    }, [])


    useEffect(() => {
        if (filterValue) {
            setServiceProvider(filterValue && filterValue.Data && filterValue.Data.sp_list)
            setComplianceTask(filterValue && filterValue.Data && filterValue.Data.compliance_task_wise_list)
            setDomain(filterValue && filterValue.Data && filterValue.Data.sp_domains_list)
            setActs(filterValue && filterValue.Data && filterValue.Data.sp_act_task_list)
            setfrequency(filterValue && filterValue.Data && filterValue.Data.compliance_frequency)
            setCategoryList(filterValue && filterValue.Data && filterValue.Data.categories_list)
            setcomplianceTaskStatus(filterValue && filterValue.Data && filterValue.Data.compliance_task_status)
            setUnitcode(filterValue && filterValue.Data && filterValue.Data.unit_code_label_list)
            // setUnits(filterValue && filterValue.Data && filterValue.Data.sp_unit_list)
            setDivision(filterValue && filterValue.Data && filterValue.Data.divisions_list)
            // setuser(filterValue && filterValue.Data && filterValue.Data.sp_users_list)

            if (filterValue && filterValue.Data && filterValue.Data.sp_users_list && filterValue.Data.sp_users_list.length > 0) {
                let uniqueObjArray = [...new Map(filterValue.Data.sp_users_list.map((item) => [item[" user_id"], item])).values()];
                setuser(uniqueObjArray)
            }


        }


    }, [filterValue])

    console.log(domain, 'domain');
    console.log(filterValue, 'filterValue');

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
         window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    const filterHandle = () => {
        if (consolidatedData.serviceProvider != '') {
            let filteredDomain = _.filter(filterValue && filterValue.Data && filterValue.Data.sp_domains_list, { sp_id_optional: Number(consolidatedData.serviceProvider) })
            const ids = filteredDomain.map(o => o.domain_name)
            const filtered = filteredDomain.filter(({ domain_name }, index) => !ids.includes(domain_name, index + 1))
            setDomain(filtered)
            let filteredUnit = _.filter(filterValue && filterValue.Data && filterValue.Data.sp_unit_list, { sp_id_optional: Number(consolidatedData.serviceProvider) })
            setUnits(filteredUnit)
        }
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const [interimmodal, setinterimmodal] = useState(false);
    const [datas, setDatas] = useState({
        le_id: '',
        start_date: '',
        country_id: '',
        domain_id: '',
        unit_id: ''
    })

    const view_interim = (int_details) => {
        setDatas({
            ...datas,
            le_id: int_details.legal_entity_id,
            start_date: int_details.start_date,
            country_id: int_details.country_id,
            domain_id: int_details.domain_id,
            unit_id: int_details.unit_id
        })
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetInterimComplianceDetails",
                    {
                        "legal_entity_id": consolidatedData.legel_entity,
                        "compliance_history_id": int_details.compliance_history_id
                    }
                ]
            }
        ]
        interimrecord({
            payload: payload,
            paramid: paramid
        })
        setinterimmodal(true)
    }

    const interimcancel = () => {
        setinterimmodal(false);
    }

    const downloaddocument = (unit_id, start_date, document_name, countryId, domainId) => {
        let result = document_name.replaceAll(", ", "");
        const pay = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "DownloadFile",
                    {
                        "le_id": consolidatedData.legel_entity,
                        "c_id": Number(countryId),
                        "d_id": Number(domainId),
                        "u_id": Number(unit_id),
                        "start_date": start_date,
                        "file_name": document_name
                    }
                ]
            }
        ]
        console.log(pay, 'paypaypaypaypay');
        downloadDocument({
            payload: pay,
            paramid: paramid
        })
    }

    const interim_columns = [
        {
            title: '#',
            key: 'index',
            align: "center",
            width: "30px",
            render: (text, record, index) => {
                return (
                    index + 1
                )
            },
        },
        {
            title: 'Documents',
            key: 'documents',
            align: "center",
            width: "50px",
            dataIndex: 'documents',
            render: (data, record) => {
                let file_name = data.split('||')
                return file_name && file_name.map((item) => {
                    return <><span onClick={() => {
                        const downloadpayload =
                            [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "DownloadInterimFile",
                                        {
                                            "le_id": entityid,
                                            "c_id": datas.country_id,
                                            "d_id": datas.domain_id,
                                            "u_id": datas.unit_id,
                                            "start_date": datas.start_date,
                                            "file_name": item
                                        }
                                    ]
                                }
                            ]
                        Downloadfile({
                            payload: downloadpayload,
                            paramid: paramid
                        });
                    }}><Link>{item}</Link><br /></span></>
                })
            }
        },
        {
            title: 'Remarks',
            key: 'remarks',
            align: "center",
            width: "50px",
            dataIndex: 'remarks',
        },
        {
            title: 'Updated On',
            key: 'activity_on',
            align: "center",
            width: "50px",
            dataIndex: 'activity_on',
        },
    ]

    return (
        <>
            <div className="page-wrapper" id="page-wrapper">
                {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                {document.body.scrollHeight > document.body.clientHeight ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''} */}
                {tableshow == true ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''}
                <div className="page-titles pb-2 pt-1 page-title-sticky">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Service Provider Reports</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Service Provider Compliances</span>
                                    </li>

                                </ol>
                            </nav>

                        </div>
                        <div className="col-lg-4 text-end">
                            <Tooltip title={'Advance Filter'}> <Button
                                className="mx-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button></Tooltip>
                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className={localStorage.getItem('currentTheme')} id='only-restricted' style={{ position: 'relative', padding: "15px 5px" }}>
                                <Collapse style={{ display: exitCollapse ? "none" : "block" }} accordion defaultActiveKey={["1"]} className='report-collapse' onChange={onChange} >
                                    <Panel header="Service Provider Compliances" key={key} >
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Country"
                                                                value={consolidatedData.country || undefined}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.country}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        country: result
                                                                    });
                                                                }}
                                                                onChange={(data, value) => {
                                                                    if (data !== undefined) {
                                                                        let le = _.filter(sessionArr, { c_id: Number(data) })
                                                                        setEntity(le)
                                                                    }
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        country: data != undefined ? data : '',
                                                                        countryName: value != undefined ? value.children : '',
                                                                        serviceProviderName: "",
                                                                        category: "",
                                                                        user_type: "",
                                                                        legel_entity: "",
                                                                        legal_entity_name: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: "",
                                                                        serviceProvider: ''
                                                                    })

                                                                }
                                                                }
                                                                // value={compfie.user_Group}
                                                                style={{ width: '100%', marginTop: '2px' }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            >
                                                                {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );

                                                                })}
                                                                {/* <Option key={sessionArr && sessionArr.length && sessionArr[0].c_id}>
                                                                    {sessionArr && sessionArr.length > 0 && sessionArr[0].c_name}
                                                                </Option> */}
                                                            </Select>

                                                            {validator.current.message(
                                                                'Country',
                                                                consolidatedData.country,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required ',
                                                                    }
                                                                })}

                                                        </div> :
                                                        <p>{consolidatedData.countryName}</p>
                                                    }

                                                </div>
                                                <div className='col-md-4 text-wrap-report'>
                                                    <label><b>Legal Entity : </b><span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                value={consolidatedData.legel_entity || undefined}
                                                                placeholder="Enter Legal Entity"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.legalEntity}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        legalEntity: result
                                                                    });
                                                                }}
                                                                onChange={(data, value) => {
                                                                    // setCurrentEntity(data)
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        legel_entity: data != undefined ? data : '',
                                                                        legal_entity_name: value != undefined ? value.children : '',
                                                                        serviceProviderName: "",
                                                                        category: "",
                                                                        user_type: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: "",
                                                                        serviceProvider: ''
                                                                    })
                                                                    setCurrentEntity(data)
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '2px' }}
                                                                disabled={consolidatedData.country == '' ? true : false}
                                                            >
                                                                {entity && entity.length && entity.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalEntity',
                                                                consolidatedData.legel_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}

                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }

                                                </div>
                                                <div className='col-md-4' >
                                                    <label><b>Service Provider : </b><span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        // showSearch
                                                        // optionFilterProp="children"
                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Service Provider"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.serviceProvider}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                serviceProvider: result
                                                            });
                                                        }}
                                                        onChange={(data, value) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                serviceProvider: data != undefined ? data : '',
                                                                serviceProviderName: value != undefined ? value.children : '',
                                                                category: "",
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                domain: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "",
                                                                division: "",
                                                                domainName: "",
                                                                complianceName: "",
                                                            })
                                                            let filteredDomain = _.filter(filterValue && filterValue.Data && filterValue.Data.sp_domains_list, { sp_id_optional: Number(data) })
                                                            console.log(filteredDomain, 'filteredDomain');
                                                            let uniqDomain = _.uniqBy(filteredDomain && filteredDomain, function (e) {
                                                                return e.domain_id;
                                                            });
                                                            setDomain(uniqDomain)

                                                            let filteredUnit = _.filter(filterValue && filterValue.Data && filterValue.Data.sp_unit_list, { sp_id_optional: Number(data) })
                                                            console.log(filteredUnit, 'filteredUnit');
                                                            let uniqUnit = _.uniqBy(filteredUnit && filteredUnit, function (e) {
                                                                return e.unit_id;
                                                            });
                                                            console.log(uniqUnit, 'uniqUnit');
                                                            setUnits(uniqUnit)
                                                        }
                                                        }
                                                        value={consolidatedData.serviceProvider || undefined}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        disabled={consolidatedData.legel_entity == '' ? true : false}
                                                    >
                                                        {serviceProvider && serviceProvider.length && serviceProvider.map((item, i) => {
                                                            return (
                                                                <Option key={item.sp_id}>
                                                                    {item.sp_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>

                                                    {validator.current.message(
                                                        'serviceprovider',
                                                        consolidatedData.serviceProvider,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Service Provider Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        disabled={consolidatedData.serviceProvider == '' ? true : false}
                                                        // showSearch
                                                        // optionFilterProp="children"
                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z - .]+$/gi)
                                                        // }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.domain}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                domain: result
                                                            });
                                                        }}
                                                        allowClear={true}
                                                        size="default"
                                                        value={consolidatedData.domain || undefined}
                                                        placeholder="Enter Domain"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onChange={(data, value) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                domain: data != undefined ? data : '',
                                                                domainName: value != undefined ? value.children : '',
                                                                category: "",
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "",
                                                                division: "",
                                                                complianceName: "",
                                                            })
                                                            // let domainData = _.filter(taskCategory && taskCategory.length > 0 && taskCategory, { task_domain_id: Number(data) })
                                                            // setaskCategory(domainData)
                                                        }
                                                        }
                                                        // onChange={(data) => {
                                                        //     setValue({
                                                        //         ...value,
                                                        //         userGroup: data
                                                        //     })
                                                        //     setCompfie({
                                                        //         ...compfie,
                                                        //         user_Group: data
                                                        //     })
                                                        // }
                                                        // }
                                                        // value={compfie.user_Group}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                    >
                                                        {domain && domain.length && domain.map((item, i) => {
                                                            return (
                                                                <Option key={item.domain_id}>
                                                                    {item.domain_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                    {validator.current.message(
                                                        'domain',
                                                        consolidatedData.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>From Date : </b><span style={{ color: "red" }}>*</span></label>
                                                    <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                        allowClear={true}
                                                        value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                                        onKeyDown={(event) => {
                                                            event.preventDefault()
                                                        }}
                                                        onChange={(date, dateString) => {
                                                            if (dateString == '') {
                                                                setConsolidatedData({
                                                                    ...consolidatedData,
                                                                    from_date: '',
                                                                    to_date: ''
                                                                })
                                                            } else {
                                                                
                                                                setConsolidatedData({
                                                                    ...consolidatedData,
                                                                    from_date: dateString,
                                                                    to_date: moment(dateString).add(3, 'months')
                                                                })
                                                            }
                                                        }}

                                                    />
                                                    {validator.current.message(
                                                        'from_date',
                                                        consolidatedData.from_date,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'From Date Required ',
                                                            }
                                                        })}
                                                </div>

                                                <div className='col-md-4'>

                                                    <label><b>To Date : </b> <span style={{ color: "red" }}>*</span></label>
                                                    <DatePicker allowClear={true} name='To-Date' format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                        onKeyDown={(event) => {
                                                            event.preventDefault()
                                                        }}
                                                        onChange={(date, dateString) => {
                                                            if (dateString == undefined) {
                                                                setConsolidatedData({
                                                                    ...consolidatedData,
                                                                    to_date: ''
                                                                })
                                                            } else {
                                                                disablecondition(dateString)
                                                                setConsolidatedData({
                                                                    ...consolidatedData,
                                                                    to_date: dateString
                                                                })
                                                            }
                                                        }}
                                                        value={consolidatedData.to_date ? moment(consolidatedData.to_date) : ''}
                                                        disabledDate={(current) => {
                                                            return moment(consolidatedData.from_date).add(1, 'days') >= current ||
                                                                moment(consolidatedData.from_date).add(1, 'year') < current;
                                                        }}
                                                    />

                                                    {validator.current.message(
                                                        'To-Date',
                                                        consolidatedData.to_date,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'To Date Required ',
                                                            }
                                                        })}
                                                    {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Division : </b></label>
                                                    <Select
                                                        disabled={consolidatedData.domain == '' ? true : false}
                                                        allowClear={true}
                                                        size="default"
                                                        value={consolidatedData.division || undefined}
                                                        placeholder="Enter Division"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.division}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                division: result
                                                            });
                                                        }}
                                                        onChange={(data) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                division: data != undefined ? data : ''
                                                            })
                                                            let categorydata = _.filter(category, { div_id: Number(data) })
                                                            setCategoryList(categorydata)
                                                        }
                                                        }
                                                        // onChange={(data) => {
                                                        //     setValue({
                                                        //         ...value,
                                                        //         userGroup: data
                                                        //     })
                                                        //     setCompfie({
                                                        //         ...compfie,
                                                        //         user_Group: data
                                                        //     })
                                                        // }
                                                        // }
                                                        // value={compfie.user_Group}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                    >
                                                        {division && division.length > 0 && division.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>


                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Category : </b></label>
                                                    <Select
                                                        value={consolidatedData.category || undefined}
                                                        // showSearch
                                                        // optionFilterProp="children"
                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                        // }} 
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.category}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                category: result
                                                            });
                                                        }}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter category"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onChange={(data) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                category: data != undefined ? data : ''
                                                            })
                                                            // let unitData = _.filter(units && units.length > 0 && units, { category_id: Number(data) })
                                                            // setUnits(unitData)
                                                        }
                                                        }
                                                        // value={compfie.user_Group}
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                    >
                                                        {category && category.length > 0 && category.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                </div>

                                                <div className='col-md-4'>
                                                    <label><b>Unit : </b></label>
                                                    <Select
                                                        disabled={consolidatedData.domain == '' ? true : false}
                                                        value={consolidatedData.unit || undefined}
                                                        allowClear={true}
                                                        size="default"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        placeholder="Enter Unit"
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                        // }} 
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.unit}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                unit: result
                                                            });
                                                        }}
                                                        onChange={(data) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                unit: data != undefined ? data : ''
                                                            })
                                                        }
                                                        }
                                                        // onChange={(data) => {
                                                        //     setValue({
                                                        //         ...value,
                                                        //         userGroup: data
                                                        //     })
                                                        //     setCompfie({
                                                        //         ...compfie,
                                                        //         user_Group: data
                                                        //     })
                                                        // }
                                                        // }
                                                        // value={compfie.user_Group}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                    >
                                                        {units && units.length > 0 && units.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {`${item.unit_code}-${item.unit_name}`}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Act : </b></label>
                                                    <Select
                                                        disabled={consolidatedData.domain == '' ? true : false}
                                                        value={consolidatedData.act || undefined}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Act"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 - . ()]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.actRules}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                actRules: result
                                                            });
                                                        }}
                                                        onChange={(data) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                act: data != undefined ? data : ''
                                                            })
                                                        }
                                                        }
                                                        // onChange={(data) => {
                                                        //     setValue({
                                                        //         ...value,
                                                        //         userGroup: data
                                                        //     })
                                                        //     setCompfie({
                                                        //         ...compfie,
                                                        //         user_Group: data
                                                        //     })
                                                        // }
                                                        // }
                                                        // value={compfie.user_Group}
                                                        style={{ width: '100%', margintop: '5px' }}
                                                    >
                                                        {Acts && Acts.length > 0 && Acts.map((item, i) => {
                                                            return (
                                                                <Option key={item.parent_id}>
                                                                    {item.statutory_mapping}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>

                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Compliance Task : </b></label>
                                                    {/* <Input placeholder="Enter Compliance Task" style={{ width: '280px' }}
                                                // onChange={(e) => {
                                                //     setConsolidatedDate({
                                                //         ...consolidateddata,
                                                //         compliance_task: e.target.value
                                                //     })
                                                // }}
                                                /> */}
                                                    <Input placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '2px' }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9 - . ()]+$/gi)
                                                        }}
                                                        onChange={ComplianceTaskName}
                                                        value={consolidatedData.compliance_task}
                                                        // onChange={(e) => {
                                                        //     setConsolidatedDate({
                                                        //         ...consolidateddata,
                                                        //         compliance_task: e.target.value
                                                        //     })
                                                        // }}
                                                        disabled={consolidatedData.domain == '' ? true : false} />
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Compliance Task Status : </b></label>
                                                    <Select
                                                        allowClear={true}
                                                        placeholder='Enter Compliance Task Status'
                                                        disabled={consolidatedData.domain == '' ? true : false}
                                                        value={consolidatedData.compliance_task_status || undefined}
                                                        // showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onChange={(value) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                compliance_task_status: value != undefined ? value : ''
                                                            })
                                                        }}
                                                        // placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                    >
                                                        <Option key='All'>All</Option>
                                                        {complianceTaskStatus && complianceTaskStatus.length && complianceTaskStatus.map((item, i) => {
                                                            return (
                                                                <Option key={item.task_status}>
                                                                    {item.task_status}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>User : </b></label>
                                                    <Select
                                                        disabled={consolidatedData.domain == '' ? true : false}
                                                        value={consolidatedData.user || undefined}
                                                        // showSearch
                                                        // optionFilterProp="children"
                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                        // }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.user}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                user: result
                                                            });
                                                        }}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter User"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onChange={(data) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user: data != undefined ? data : ''
                                                            })
                                                        }
                                                        }
                                                        // onChange={(data) => {
                                                        //     setValue({
                                                        //         ...value,
                                                        //         userGroup: data
                                                        //     })
                                                        //     setCompfie({
                                                        //         ...compfie,
                                                        //         user_Group: data
                                                        //     })
                                                        // }
                                                        // }
                                                        // value={compfie.user_Group}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                    >
                                                        {user && user.length && user.map((item, i) => {
                                                            return (
                                                                <Option key={item.user_id}>
                                                                    {item.user_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                    {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'text-center popupbtncolour ' + localStorage.getItem('currentTheme')} style={{ marginTop: "5px" }}>
                                            <Button type="primary" shape="round" className={'addbutton  me-1 ' + localStorage.getItem('currentTheme')} onClick={onshow}
                                                icon={<EyeOutlined />} disabled={disableButton} >
                                                Show
                                            </Button>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} onClick={showModal}
                                                icon={<ExportOutlined />} >
                                                Export
                                            </Button>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <Modal
                                    onCancel={setcancelFilter}
                                    footer={false}
                                    title="Service Provider Compliances"
                                    className={
                                        "add-service-prv cons-report " +
                                        localStorage.getItem("currentTheme")
                                    }
                                    open={filterModalVisible}
                                    maskClosable={false}
                                >
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            value={consolidatedData.country || undefined}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.country}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    country: result
                                                                });
                                                            }}
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    let le = _.filter(sessionArr, { c_id: Number(data) })
                                                                    setEntity(le)
                                                                }
                                                                setConsolidatedData({
                                                                    ...consolidatedData,
                                                                    country: data != undefined ? data : '',
                                                                    countryName: value != undefined ? value.children : '',
                                                                    serviceProviderName: "",
                                                                    category: "",
                                                                    user_type: "",
                                                                    legel_entity: "",
                                                                    legal_entity_name: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    domainName: "",
                                                                    complianceName: "",
                                                                    serviceProvider: ''
                                                                })

                                                            }
                                                            }
                                                            // value={compfie.user_Group}
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        >
                                                            {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );

                                                            })}
                                                            {/* <Option key={sessionArr && sessionArr.length && sessionArr[0].c_id}>
                                                                {sessionArr && sessionArr.length > 0 && sessionArr[0].c_name}
                                                            </Option> */}
                                                        </Select>

                                                        {validator.current.message(
                                                            'Country',
                                                            consolidatedData.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required ',
                                                                }
                                                            })}

                                                    </div> :
                                                    <p>{consolidatedData.countryName}</p>
                                                }

                                            </div>
                                            <div className='col-md-4 text-wrap-report'>
                                                <label><b>Legal Entity : </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            allowClear={true}
                                                            size="default"
                                                            value={consolidatedData.legel_entity || undefined}
                                                            placeholder="Enter Legal Entity"
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.legalEntity}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    legalEntity: result
                                                                });
                                                            }}
                                                            onChange={(data, value) => {
                                                                // setCurrentEntity(data)
                                                                setConsolidatedData({
                                                                    ...consolidatedData,
                                                                    legel_entity: data != undefined ? data : '',
                                                                    legal_entity_name: value != undefined ? value.children : '',
                                                                    serviceProviderName: "",
                                                                    category: "",
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    domainName: "",
                                                                    complianceName: "",
                                                                    serviceProvider: ''
                                                                })
                                                                setCurrentEntity(data)
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            disabled={consolidatedData.country == '' ? true : false}
                                                        >
                                                            {entity && entity.length && entity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legalEntity',
                                                            consolidatedData.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}

                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }

                                            </div>
                                            <div className='col-md-4' >
                                                <label><b>Service Provider : </b><span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    // showSearch
                                                    // optionFilterProp="children"
                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Service Provider"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.serviceProvider}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            serviceProvider: result
                                                        });
                                                    }}
                                                    onChange={(data, value) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            serviceProvider: data != undefined ? data : '',
                                                            serviceProviderName: value != undefined ? value.children : '',
                                                            category: "",
                                                            user_type: "",
                                                            unit: '',
                                                            user: "",
                                                            domain: "",
                                                            act: "",
                                                            from_date: "",
                                                            task_category: "",
                                                            compliance_task: "",
                                                            to_date: "",
                                                            task_sub_category: "",
                                                            compliance_frequency: "",
                                                            compliance_task_status: "",
                                                            division: "",
                                                            domainName: "",
                                                            complianceName: "",
                                                        })
                                                        let filteredDomain = _.filter(filterValue && filterValue.Data && filterValue.Data.sp_domains_list, { sp_id_optional: Number(data) })
                                                        setDomain(filteredDomain)
                                                        let filteredUnit = _.filter(filterValue && filterValue.Data && filterValue.Data.sp_unit_list, { sp_id_optional: Number(data) })
                                                        setUnits(filteredUnit)
                                                    }
                                                    }
                                                    value={consolidatedData.serviceProvider || undefined}
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    disabled={consolidatedData.legel_entity == '' ? true : false}
                                                >
                                                    {serviceProvider && serviceProvider.length && serviceProvider.map((item, i) => {
                                                        return (
                                                            <Option key={item.sp_id}>
                                                                {item.sp_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>

                                                {validator.current.message(
                                                    'serviceprovider',
                                                    consolidatedData.serviceProvider,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Service Provider Required',
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    disabled={consolidatedData.serviceProvider == '' ? true : false}
                                                    allowClear={true}
                                                    size="default"
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z - .]+$/gi)
                                                    // }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.domain}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            domain: result
                                                        });
                                                    }}
                                                    value={consolidatedData.domain || undefined}
                                                    placeholder="Enter Domain"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(data, value) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            domain: data != undefined ? data : '',
                                                            domainName: value != undefined ? value.children : '',
                                                            category: "",
                                                            user_type: "",
                                                            unit: '',
                                                            user: "",
                                                            act: "",
                                                            from_date: "",
                                                            task_category: "",
                                                            compliance_task: "",
                                                            to_date: "",
                                                            task_sub_category: "",
                                                            compliance_frequency: "",
                                                            compliance_task_status: "",
                                                            division: "",
                                                            complianceName: "",
                                                        })

                                                    }
                                                    }
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    {domain && domain.length && domain.map((item, i) => {
                                                        return (
                                                            <Option key={item.domain_id}>
                                                                {item.domain_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidatedData.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>From Date : </b><span style={{ color: "red" }}>*</span></label>
                                                <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                    allowClear={true}
                                                    value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onKeyDown={(event) => {
                                                        event.preventDefault()
                                                    }}

                                                    onChange={(date, dateString) => {
                                                        if (dateString == '') {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                from_date: '',
                                                                to_date: ''
                                                            })
                                                        } else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                from_date: dateString,
                                                                to_date: moment(dateString).add(3, 'months')
                                                            })
                                                        }
                                                    }}

                                                />
                                                {validator.current.message(
                                                    'from_date',
                                                    consolidatedData.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'From Date Required ',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>

                                                <label><b>To Date : </b> <span style={{ color: "red" }}>*</span></label>
                                                <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                    onKeyDown={(event) => {
                                                        event.preventDefault()
                                                    }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onChange={(date, dateString) => {
                                                        if (dateString == undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                to_date: ''
                                                            })
                                                        } else {
                                                            disablecondition(dateString)
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                to_date: dateString
                                                            })
                                                        }
                                                    }}
                                                    value={consolidatedData.to_date ? moment(consolidatedData.to_date) : ''}
                                                    disabledDate={(current) => {
                                                        return moment(consolidatedData.from_date).add(1, 'days') >= current ||
                                                            moment(consolidatedData.from_date).add(1, 'year') < current;
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'To-Date',
                                                    consolidatedData.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Date Required ',
                                                        }
                                                    })}
                                                {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Division : </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    allowClear={true}
                                                    size="default"
                                                    value={consolidatedData.division || undefined}
                                                    placeholder="Enter Division"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.division}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            division: result
                                                        });
                                                    }}
                                                    onChange={(data) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            division: data != undefined ? data : ''
                                                        })
                                                        let categorydata = _.filter(category, { div_id: Number(data) })
                                                        setCategoryList(categorydata)
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>


                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Category : </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    value={consolidatedData.category || undefined}
                                                    // showSearch
                                                    // optionFilterProp="children"
                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                    // }} 
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.category}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            category: result
                                                        });
                                                    }}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter category"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(data) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            category: data != undefined ? data : ''
                                                        })
                                                        // let unitData = _.filter(units && units.length > 0 && units, { category_id: Number(data) })
                                                        // setUnits(unitData)
                                                    }
                                                    }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {category && category.length > 0 && category.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>

                                            <div className='col-md-4'>
                                                <label><b>Unit : </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    value={consolidatedData.unit || undefined}
                                                    allowClear={true}
                                                    size="default"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.unit}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            unit: result
                                                        });
                                                    }}
                                                    placeholder="Enter Unit"
                                                    onChange={(data) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            unit: data != undefined ? data : ''
                                                        })
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    {units && units.length > 0 && units.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {`${item.unit_code}-${item.unit_name}`}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Act : </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    value={consolidatedData.act || undefined}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Act"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 - . ()]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.actRules}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            actRules: result
                                                        });
                                                    }}
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(data) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            act: data != undefined ? data : ''
                                                        })
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', margintop: '5px' }}
                                                >
                                                    {Acts && Acts.length > 0 && Acts.map((item, i) => {
                                                        return (
                                                            <Option key={item.parent_id}>
                                                                {item.statutory_mapping}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>

                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task : </b></label>
                                                {/* <Input placeholder="Enter Compliance Task" style={{ width: '280px' }}
                                                // onChange={(e) => {
                                                //     setConsolidatedDate({
                                                //         ...consolidateddata,
                                                //         compliance_task: e.target.value
                                                //     })
                                                // }}
                                                /> */}
                                                <Input placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '2px' }}
                                                    // onChange={(e) => {
                                                    //     setConsolidatedDate({
                                                    //         ...consolidateddata,
                                                    //         compliance_task: e.target.value
                                                    //     })
                                                    // }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9 - . ()]+$/gi)
                                                    }}
                                                    onChange={ComplianceTaskName}
                                                    value={consolidatedData.compliance_task}
                                                    disabled={consolidatedData.domain == '' ? true : false} />
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task Status : </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    placeholder='Enter Compliance Task Status'
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    value={consolidatedData.compliance_task_status || undefined}
                                                    // showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(value) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            compliance_task_status: value != undefined ? value : ''
                                                        })
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    <Option key='All'>All</Option>
                                                    {complianceTaskStatus && complianceTaskStatus.length && complianceTaskStatus.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_status}>
                                                                {item.task_status}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>User : </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    value={consolidatedData.user || undefined}
                                                    // showSearch
                                                    // optionFilterProp="children"
                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter User"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                    // }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.user}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            user: result
                                                        });
                                                    }}
                                                    onChange={(data) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            user: data != undefined ? data : ''
                                                        })
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    {user && user.length && user.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_id}>
                                                                {item.user_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                                {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'text-center popupbtncolour ' + localStorage.getItem('currentTheme')} style={{ marginTop: "5px" }}>
                                        <Button type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')} onClick={onshow}
                                            icon={<EyeOutlined />} disabled={disableButton} >
                                            Show
                                        </Button>
                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                            icon={<ExportOutlined />} >
                                            Export
                                        </Button>
                                    </div>
                                </Modal>
                                {/* {tableshow === true ?
                                    <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onStatChange} style={{ marginTop: '1%', backgroundColor: '#5daae3' }}>
                                        <Panel header="Overall Statistics" key={statkey}>
                                            <div className="col-md-12" style={{ marginLeft: '5%', marginRight: '5%' }}>
                                                <div className="row m-2">
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={30} width={90} format={(percent) => `${110}`} />
                                                        <h4>Complied</h4>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress style={{ marginLeft: '8%' }} type="circle" percent={20} width={90} status="exception" format={(percent) => `${30}`} />
                                                        <h4 style={{ marginRight: '12%' }}>Delayed Complied</h4>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={100} width={90} format={(percent) => `${80}`} />
                                                        <h4>Inprogress</h4>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={80} width={90} strokeColor={{ '0%': '#f5b342', '100%': '#f5b342' }} format={(percent) => `${75}`} />
                                                        <h4>Not Complied</h4>

                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse> : ''
                                } */}

                                {tableshow == true ?
                                    <Card className='rounded-3'>

                                        <div className='col-md-12 mt-0 mb-2 filter-detial-sticky extraFilter' >
                                            <div className='row'>
                                                <div className='col-md-4 text-wrap-report'>
                                                    <label><b>Legal Entity :</b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? consolidatedData.legalEntityLabel : localStorage.getItem('SelectedEntity')}</label>

                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ marginLeft: '35%' }}><b>Country :</b> {consolidatedData.countryLabel}</label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ marginLeft: '15%' }}><b>Domain :</b> {consolidatedData.domainLabel}</label>
                                                </div>


                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-3"></div>
                                                    <div className='col-md-6'>
                                                        <center> <label><b>Service Provider :</b> {consolidatedData.serviceProviderLabel}</label></center>
                                                    </div>
                                                    <div className="col-md-3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content userprivclass1'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className="ant-table-thead table-head-stick table-head-stick-extra">
                                                                <tr>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }}> # </th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '250px' }}>Compliance Task</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}>Criticality</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}>Frequency</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}><center>Due Date</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }}>Compliance Task Status</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>User Name</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}><center>Activity Status</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }}><center>Activity Date</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}><center>Uploaded Document</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '240px' }}><center>Task Completion Date/Document issued Date</center></th>
                                                                </tr>
                                                            </thead>
                                                            {filteredArrayState && filteredArrayState.length > 0 ?
                                                                filteredArrayState.map((item, i) => {
                                                                    return (
                                                                        <tbody className='ant-table-tbody' key={i}>
                                                                            <tr className="bg-lightdark">
                                                                                <td colSpan="100%">
                                                                                    <div className='bg-white shadow-sm border-radius-reports'>
                                                                                        <div className="col-md-12">
                                                                                            <div className='row m-2'>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Unit :</b> {item[0].unit_name}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Division :</b> {item[0].division_name}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Category :</b> {item[0].category_name}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            {titledata && titledata.map((itemdata, i) => {
                                                                                let data = _.find(filteredArrayState[0], { unit_id: item[0].unit_id })
                                                                                // if (data != undefined) {
                                                                                //     if (data.statutory_mapping == itemdata.statutory_mapping) {
                                                                                // if (item[0].unit_id == itemdata.unit_id) {
                                                                                return <Fragment>
                                                                                    <tr className='ant-table-row ant-table-row-level-0'>
                                                                                        <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                    </tr>
                                                                                    {itemdata.child.map((items) => {
                                                                                        console.log(items, 'itemspppppp');
                                                                                        let tempDoc
                                                                                        if (items.document_name) {
                                                                                            let result = items.document_name.replaceAll("||", ", ||");
                                                                                            tempDoc = result.split(", ||")
                                                                                        }
                                                                                        console.log(tempDoc, 'tempDoctempDoc');
                                                                                        {/* let array = [] */ }
                                                                                        {/* // for (let i in filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances) {
                      //     if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i].compliance_id == items.compliance_id) {
                      //         if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i].unit_id == items.unit_id) {
                      //             array.push(filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i])
                      //         }
                      //     }
                      // }
                      // return  */}
                                                                                        return <tr>
                                                                                            <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                            <td className='ant-table-cell'>
                                                                                                <span className="d-flex justify-content-start align-items-end">
                                                                                                    <Tooltip title={items.compliance_description}>
                                                                                                        <span className="text-primary">
                                                                                                            <i className="ri-information-fill" />
                                                                                                        </span>
                                                                                                    </Tooltip> &nbsp;{items.child && items.child.length > 0 ? <a href='javascript:;' onClick={() => {
                                                                                                        setChildComplinaceId(items.child)
                                                                                                        chilData(items.compliance_id, items.unit_id, items.compliance_history_id)
                                                                                                    }}>{items.compliance_task}</a> : <a >{items.compliance_task}</a>}</span></td>
                                                                                            <td className='ant-table-cell'>{items.criticality_name}</td>
                                                                                            <td className='ant-table-cell'>{items.frequency_name}</td>
                                                                                            <td className='ant-table-cell'><center>
                                                                                                {items.extend_due_date != null ?
                                                                                                    <span className="ms-1 d-flex justify-content-start align-items-center">{items.due_date}<Tooltip title={"The Due Date has been extended to " + items.extend_due_date}>
                                                                                                        &nbsp;<i className='ri-information-fill' style={{ color: 'orange' }} /></Tooltip></span> :
                                                                                                    <span className="ms-1 d-flex justify-content-start align-items-center">
                                                                                                        {items.due_date}
                                                                                                    </span>
                                                                                                }
                                                                                            </center></td>
                                                                                            <td className='ant-table-cell'><center>{items.task_status}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.assignee_name}</center></td>
                                                                                            {console.log(items, 'itemmmmmmmmssssss')}
                                                                                            <td className='ant-table-cell text-center'>
                                                                                                {items.activity_status}
                                                                                                {items.history_count != 0 && items.remarks_count != 0 ?
                                                                                                    <Tooltip title="Click to view Remarks history">
                                                                                                        <span className="text-primary">
                                                                                                            <i className="ri-information-fill"
                                                                                                                onClick={() => {
                                                                                                                    const payload = [
                                                                                                                        authtoken,
                                                                                                                        {
                                                                                                                            "session_token": authtoken,
                                                                                                                            "request": [
                                                                                                                                "GetComplianceRemarksHistoryData",
                                                                                                                                {
                                                                                                                                    "legal_entity_id": entityid,
                                                                                                                                    "unit_id": items.unit_id,
                                                                                                                                    "compliance_history_id": items.compliance_history_id
                                                                                                                                }
                                                                                                                            ]
                                                                                                                        }
                                                                                                                    ]

                                                                                                                    getRemarksData({
                                                                                                                        payload: payload,
                                                                                                                        paramid: paramid
                                                                                                                    })
                                                                                                                    setRemarksModal(true)
                                                                                                                }} />
                                                                                                        </span>
                                                                                                    </Tooltip> : ''}
                                                                                                {items.interim_count != 0 ?
                                                                                                    <span className="text-primary me-1">
                                                                                                        <Tooltip title='Click to view Interim Detailss'>
                                                                                                            <i className='ri-information-fill' style={{ color: '#05A4FA' }} onClick={() => { view_interim(items) }} /></Tooltip></span> : ''}
                                                                                            </td>
                                                                                            <td className='ant-table-cell'><center>{items.activity_date}</center></td>
                                                                                            <td className='ant-table-cell'>
                                                                                                {items.doc_ref_num != null && (items.document_name == null || items.document_name == "") ?
                                                                                                    <td className='ant-table-cell'><center>{items.doc_ref_num}</center></td>
                                                                                                    : items.doc_ref_num != null && items.document_name != null ?
                                                                                                        <td ><center>{
                                                                                                            tempDoc && tempDoc.length > 0 && tempDoc.map((itemcheck, index) => {
                                                                                                                if (index == 0) {
                                                                                                                    return <><Link onClick={() => {
                                                                                                                        downloaddocument(items.unit_id, items.start_date, itemcheck, items.country_id, items.domain_id)
                                                                                                                    }}>{items.doc_ref_num}</Link><br /></>
                                                                                                                } else {
                                                                                                                    return <><Link onClick={() => {
                                                                                                                        downloaddocument(items.unit_id, items.start_date, itemcheck, items.country_id, items.domain_id)
                                                                                                                    }}>{itemcheck}</Link><br /></>
                                                                                                                }

                                                                                                            })
                                                                                                        }</center></td>
                                                                                                        :
                                                                                                        <td className='ant-table-cell'><center>{
                                                                                                            tempDoc && tempDoc.length > 0 && tempDoc.map((itemcheck) => {
                                                                                                                return <><Link onClick={() => {
                                                                                                                    downloaddocument(items.unit_id, items.start_date, itemcheck, items.country_id, items.domain_id)
                                                                                                                }}>{itemcheck}</Link><br /></>
                                                                                                            })
                                                                                                        }</center></td>
                                                                                                }
                                                                                            </td>
                                                                                            <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                            {/* </tr> */}
                                                                                            <span hidden>{index = index + 1}</span>

                                                                                        </tr>

                                                                                    })

                                                                                    }
                                                                                </Fragment>
                                                                                // }
                                                                                // }
                                                                                // }
                                                                                // }
                                                                            })
                                                                            }

                                                                        </tbody>
                                                                    )
                                                                }) :
                                                                <tr>
                                                                    <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                </tr>
                                                            }
                                                        </table>
                                                        <div>
                                                            {/* <button
                                                    className="btn btn-light-success rounded-pill px-4 text-success"
                                                    onClick={submitrec}>Submit</button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            {index != 0 ?
                                                <><div className='col-md-6'>
                                                    <label>Showing {pageSize * (current - 1) + 1} to {index} of {filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.total_count} entries</label>
                                                </div>
                                                    <div className='col-md-6 text-right'>
                                                        <Pagination
                                                            current={current}
                                                            showSizeChanger={true}
                                                            // showQuickJumper={true}
                                                            onShowSizeChange={() => {
                                                                setCurrent(1)
                                                                // setpageState(true)
                                                            }}
                                                            pageSizeOptions={[25, 50, 100]}
                                                            hideOnSinglePage={filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.total_count > 25 ? false : true}
                                                            defaultPageSize={25}
                                                            onChange={(page, pageSizes) => {
                                                                setPaginationArray([])
                                                                setpageState(true)
                                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                                setPageSize(pageSizes)
                                                            }} total={filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.total_count}
                                                        // showTotal={(total) => `Showing 1 to ${index} of ${filterValue && filterValue.count} entries`}
                                                        />


                                                    </div>
                                                </> : ""}
                                        </div>
                                    </Card>
                                    : ''}

                                {/* <Modal title="Select Fields" visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                                <div id="form">
                                    <input type="hidden" class="popup_mode" autocomplete="off" />
                                    <div id="form-list">
                                        <div class="portlet-body p-t-0">
                                            <div class="table-responsive">
                                                <table id="datatable-responsive2" class="table table-striped dt-responsive nowrap" cellspacing="0" width="50%">
                                                    <thead>
                                                        <tr class="custom-modal-title">
                                                            <th>
                                                                <input id="select_all" width="100%" type="checkbox" autocomplete="off" onClick={(e) => {
                                                                    let checked = e.target.checked
                                                                    selectAllCheckBox(checked)
                                                                }} /> Select All
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody id="tbody-form-list"><tr><th>
                                                        <input id="column_1" name='chk' type="checkbox" disabled="disabled" checked value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /> Legal Entity</th>
                                                        <th><input id="column_2" name='chk' type="checkbox" disabled="disabled" checked value='2' onChange={(e) => checkBoxOnChange(e)} /> Unit Code</th></tr>
                                                        <tr><th><input id="column_3" name='chk' type="checkbox" disabled="disabled" checked value='3' onChange={(e) => checkBoxOnChange(e)} /> Unit Name</th>
                                                            <th><input id="column_4" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 1</th></tr><tr><th>
                                                                <input id="column_5" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 2</th><th>
                                                                <input id="column_6" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 3</th></tr>
                                                        <tr><th><input id="column_7" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 4</th>
                                                            <th><input id="column_8" name='chk' type="checkbox" disabled="disabled" checked value='8' onChange={(e) => checkBoxOnChange(e)} /> Location</th></tr>
                                                        <tr><th><input id="column_9" name='chk' type="checkbox" disabled="disabled" value='9' checked onChange={(e) => checkBoxOnChange(e)} /> Act / Rules</th>
                                                            <th><input id="column_10" name='chk' type="checkbox" disabled="disabled" value='10' checked onChange={(e) => checkBoxOnChange(e)} /> Compliance Task</th></tr>
                                                        <tr><th><input id="column_11" name='chk' type="checkbox" disabled="disabled" value='11' checked onChange={(e) => checkBoxOnChange(e)} /> Frequency</th>
                                                            <th><input id="column_12" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Periodicity</th></tr>
                                                        <tr><th><input id="column_13" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Criticality</th>
                                                            <th><input id="column_14" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Division</th></tr>
                                                        <tr><th><input id="column_15" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Category</th>
                                                            <th><input id="column_16" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assigned by</th></tr>
                                                        <tr><th><input id="column_17" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assigned To</th>
                                                            <th><input id="column_18" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date</th></tr>
                                                        <tr><th><input id="column_19" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Assignee</th>
                                                            <th><input id="column_20" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Completed on</th></tr>
                                                        <tr><th><input id="column_21" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Concurrer</th>
                                                            <th><input id="column_22" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Concurred on</th></tr>
                                                        <tr><th><input id="column_23" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Approver</th>
                                                            <th><input id="column_24" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Approved on</th></tr>
                                                        <tr><th><input id="column_25" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> Activity Status</th>
                                                            <th><input id="column_26" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Start Date</th></tr>
                                                        <tr><th><input id="column_27" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} /> Due Date</th>
                                                            <th><input id="column_28" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} /> Extend Due Date</th></tr>
                                                        <tr><th><input id="column_29" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} />Task Completion Date / Document Issued Date</th>
                                                            <th><input id="column_30" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} />Month</th></tr>
                                                        <tr><th><input id="column_31" name='chk' type="checkbox" value='31' onChange={(e) => checkBoxOnChange(e)} /> Validity Date </th>
                                                            <th><input id="column_32" name='chk' type="checkbox" disabled="disabled" checked value='32' onChange={(e) => checkBoxOnChange(e)} /> Statutory Status</th></tr>
                                                        <tr><th><input id="column_33" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} />Duration</th>
                                                            <th><input id="column_34" name='chk' type="checkbox" value='34' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number</th></tr>
                                                        <tr><th><input id="column_33" name='chk' type="checkbox" disabled="disabled" checked value='35' onChange={(e) => checkBoxOnChange(e)} />Remarks</th></tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-actions">
                                    <Button type="primary" shape="round" className='addbutton'
                                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '40%' }}
                                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                                    </Button>
                                </div>
                            </Modal> */}
                                <Drawer title="Select Fields" className={'add-service-prv export-checkbox resize-drawer ' + localStorage.getItem('currentTheme')} open={isModalOpen} footer={null} onCancel={handleCancel} onClose={handleCancel} width={600} maskClosable={false}>
                                    <div className='d-flex flex-wrap'>
                                        <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                                            checked={checkboxValue.length === 35 ? true : false}
                                            onClick={(e) => {
                                                let checked = e.target.checked
                                                selectAllCheckBox(checked)
                                            }} /><label for='select_all'> Select All</label>

                                        <input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /><label for='column_1'>Legal Entity </label>
                                        <input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /><label for='column_2'> Unit Code</label>
                                        <input id="column_3" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_3'>Unit Name</label>
                                        <input id="column_8" name='chk' type="checkbox" value='8' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_8'>Location</label>

                                        <input id="column_9" name='chk' type="checkbox" value='9' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_9'> Act / Rules</label>
                                        <input id="column_10" name='chk' type="checkbox" value='10' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_10'> Compliance Task</label>
                                        <input id="column_11" name='chk' type="checkbox" value='11' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_11'>Frequency</label>
                                        <input id="column_32" name='chk' type="checkbox" value='32' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_32'> Statutory Status</label>

                                        <input id="column_35" name='chk' type="checkbox" value='35' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_35'> Remarks</label>
                                        <input id="column_4" name='chk' type="checkbox" value='4' checked={checkboxValue.includes(4)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_4'>Unit Code 1</label>
                                        <input id="column_5" name='chk' type="checkbox" value='5' checked={checkboxValue.includes(5)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_5'> Unit Code 2</label>
                                        <input id="column_6" name='chk' type="checkbox" value='6' checked={checkboxValue.includes(6)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_6'> Unit Code 3 </label>


                                        <input id="column_7" name='chk' type="checkbox" value='7' checked={checkboxValue.includes(7)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_7'>Unit Code 4</label>
                                        <input id="column_12" name='chk' type="checkbox" value='12' checked={checkboxValue.includes(12)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_12'> Periodicaly </label>
                                        <input id="column_13" name='chk' type="checkbox" value='13' checked={checkboxValue.includes(13)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_13'> Criticality</label>
                                        <input id="column_14" name='chk' type="checkbox" value='14' checked={checkboxValue.includes(14)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_14'> Division</label>

                                        <input id="column_15" name='chk' type="checkbox" value='15' checked={checkboxValue.includes(15)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_15'> Category</label>
                                        <input id="column_16" name='chk' type="checkbox" value='16' checked={checkboxValue.includes(16)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_16'> Assigned By</label>
                                        <input id="column_17" name='chk' type="checkbox" value='17' checked={checkboxValue.includes(17)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_17'> Assigned To</label>
                                        <input id="column_18" name='chk' type="checkbox" value='18' checked={checkboxValue.includes(18)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_18'> Assigned Date</label>

                                        <input id="column_19" name='chk' type="checkbox" value='19' checked={checkboxValue.includes(19)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_19'> Assignee </label>
                                        <input id="column_20" name='chk' type="checkbox" value='20' checked={checkboxValue.includes(20)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_20'> Completed On</label>
                                        <input id="column_21" name='chk' type="checkbox" value='21' checked={checkboxValue.includes(21)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_21'> Concurrer </label>
                                        <input id="column_22" name='chk' type="checkbox" value='22' checked={checkboxValue.includes(22)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_22'> Concurred On </label>

                                        <input id="column_23" name='chk' type="checkbox" value='23' checked={checkboxValue.includes(23)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_23'> Approver </label>
                                        <input id="column_24" name='chk' type="checkbox" value='24' checked={checkboxValue.includes(24)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_24'> Approved On</label>
                                        <input id="column_25" name='chk' type="checkbox" value='25' checked={checkboxValue.includes(25)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_25'> Activity Status</label>
                                        <input id="column_26" name='chk' type="checkbox" value='26' checked={checkboxValue.includes(26)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_26'> Start Date</label>

                                        <input id="column_27" name='chk' type="checkbox" value='27' checked={checkboxValue.includes(27)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_27'> Due Date </label>
                                        <input id="column_28" name='chk' type="checkbox" value='28' checked={checkboxValue.includes(28)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_28'>Extend Due Date </label>
                                        <input id="column_29" name='chk' type="checkbox" value='29' checked={checkboxValue.includes(29)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_29'> Task Completion Date / Document Issued Date</label>
                                        <input id="column_30" name='chk' type="checkbox" value='30' checked={checkboxValue.includes(30)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_30'> Month</label>

                                        <input id="column_31" name='chk' type="checkbox" value='31' checked={checkboxValue.includes(31)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_31'> Validity Date</label>
                                        <input id="column_33" name='chk' type="checkbox" value='33' checked={checkboxValue.includes(33)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_33'>Duration</label>
                                        <input id="column_34" name='chk' type="checkbox" value='34' checked={checkboxValue.includes(34)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_34'> Document Reference Number</label>

                                    </div>

                                    <div className={"form-actions popupbtncolour text-center " + localStorage.getItem('currentTheme')}>
                                        <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                                            icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                                        </Button>
                                    </div>

                                </Drawer>
                                <Modal footer={null} open={isModalVisible} onCancel={handleCancel}
                                    className={" add-service-prv cons-report " + localStorage.getItem("currentTheme")}
                                    title="Task Details"
                                    onOk={() => {
                                        setIsModalVisible(false)
                                    }} maskClosable={false}>
                                    <Table style={{ marginTop: '25px' }}
                                        className={localStorage.getItem('currentTheme')}
                                        columns={columns}
                                        dataSource={childCompliceId}
                                        bordered
                                        pagination={false} />
                                </Modal>
                                {console.log(filterValue, 'fileteree')}
                                <Modal open={RemarksModal} title='Activity History' className={
                                    "add-service-prv cons-report " +
                                    localStorage.getItem("currentTheme")
                                } onCancel={handleCancel} footer={null} maskClosable={false}>
                                    <Table
                                        className='userprivclass'
                                        columns={column}
                                        dataSource={filterValue && filterValue.RemarksData && filterValue.RemarksData.remarks_history}
                                        bordered
                                        pagination={false} />
                                </Modal>
                                <Modal open={interimmodal}
                                    title="Interim Status History"
                                    className={" add-service-prv " + localStorage.getItem('currentTheme')}
                                    footer={null}
                                    onCancel={interimcancel} maskClosable={false}>
                                    <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                                        columns={interim_columns}
                                        dataSource={interim_record && interim_record}
                                        pagination={false}
                                    />
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
export default connect(mapStateToProps, {
    FilterValues,
    getServiceProviderReportData,
    getserviceProviderExport,
    getRemarksData,
    interimrecord,
    Downloadfile,
    downloadDocument
})(ServiceProviderComplianceReports);