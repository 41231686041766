import React, { Fragment } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Pagination } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { VCSFilters, VCSexport } from '../../../Store/Action/Report/CV_VendorComplianceScore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { VENDOR_COMPLIANCE_SCORE_RESET } from './../../../Store/types/index'
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../../Libs/fullscreenmode";
import useStickyHeight from "../../../Libs/useStickyHeight";
import moment from 'moment';
import { FullscreenOutlined, ExportOutlined } from '@ant-design/icons';
import { Toaster } from '../../../Libs/Toaster';
import url, { getUrl } from './../../../Libs/URL'
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()
const urls = getUrl()

const mapStateToProps = (state) => ({
    // domain_data: state.Assigneescorecard.assigneescoredata.domain_data,
    // tableData: state.Assigneescorecard.assigneescoredata.getTableData
    Vendorcompliancescore: state.Vendorcompliancescore.vendorcompliancescore,
})

const Vendorcompliancescore = ({
    VCSFilters,
    Vendorcompliancescore: { vendorcompliancescorefilter, vendorcompliancescoreexport },
    VCSexport,
    tableData
}) => {
    useStickyHeight()
    const _ = require("lodash");
    const historynew = useHistory();
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const dispatch = useDispatch();
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);

    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [tableshow, setTableShow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    const [datasource, setDatasource] = useState([])
    const [type, setType] = useState('')
    const [division, setDivision] = useState([])
    const [divisionData, setdivisionData] = useState([])
    console.log(divisionData, 'divisionData');
    const [categoryDatas, setCategoryDats] = useState([])
    console.log(division, "division");
    const [categoryList, setCategoryList] = useState([])
    console.log(categoryList, 'pskjigh')
    const [domain, setDomain] = useState([])
    const [vendorfilter, setVendorfilter] = useState([])
    console.log(vendorfilter, "vendorfilter");
    const [totalvendor, setTotalvendor] = useState([])
    console.log(totalvendor, "totalvendortotalvendor");
    const [filteredArray, setFilteredArray] = useState([])
    const [finaltable, setFinaltable] = useState([])
    const [due, setDue] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [legalstate, Setlegalstate] = useState(false)

    const [vendorComplianceScore, setVendorComplianceScore] = useState({
        country: '',
        countryName: '',
        division: 0,
        divisionName: '',
        typeid: '',
        typeName: '',
        category: 0,
        categoryName: '',
        from_month: '',
        to_month: '',
        legel_entity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        le_id: '',
        domain: '',
        domainName: '',
        units: [],
        vendorunits: [],
        bg_id: '',
        bg_name: ''
    })
    console.log(vendorComplianceScore, "vendorComplianceScore");
    const [entity, setEntity] = useState([])
    // const [all, setAll] = useState([]) ;
    // const [alll, setAlll] = (localStorage.getItem('SelectedEntity') === "All Legal Entity" ? ([]) : [localStorage.getItem('SelectedEntityid').toString()]);
    const [all, setAll] = useState((localStorage.getItem('SelectedEntity') === "All Legal Entity" ? ([]) : [localStorage.getItem('SelectedEntityid').toString()]))
    const [allvendor, setAllvendor] = useState([]);
    const [allvendornumber, setAllvendornumber] = useState([]);
    const [selectcheckbox, Setselectcheckbox] = useState('block');
    const [unselectcheckbox, Setunselectcheckbox] = useState('none');
    const [selectcheckboxvendor, Setselectcheckboxvendor] = useState('block');
    const [unselectcheckboxvendor, Setunselectcheckboxvendor] = useState('none');
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option, OptGroup } = Select;
    const [bggroup, setBggroup] = useState([])

    useEffect(() => {
        if (sessionArr && sessionArr.length > 0) {
            let unique = _.uniqBy(sessionArr, function (e) {
                return e.bg_id;
            });
            setBggroup(unique)
        }
    }, [])

    useEffect(() => {
        validator.current.showMessages()
    }, [])


    useEffect(() => {
        if (vendorComplianceScore.legel_entity == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [vendorComplianceScore.legel_entity])

    useEffect(() => {
        if (isAuth) {
            // if (legalstate === true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVCSFilters",
                        {}
                    ]
                }
            ]
            // if (entityid != 'null') {

            VCSFilters({
                payload: payload,
                paramid: paramid
            })
            // }
            Setlegalstate(false)
            // }
        }

    }, [isAuth, entityid, legalstate])

    console.log(vendorcompliancescorefilter, 'vendorcompliancescorefilter')

    useEffect(() => {
        if (categoryDatas.length > 0) {
            // setaskCategory(filterValue && filterValue.Data && filterValue.Data.task_category_list)
            // setAct(userwisereports && userwisereports.user_act_task_list)
            let uniq1 = _.uniqBy(categoryDatas, function (e) {
                return e.le_name
            })
            let parentSep1 = uniq1.map((e) => {
                return e.le_name
            })
            let totalList1 = []
            parentSep1.map((pmenu, index) => {
                let temp = []
                categoryDatas.map((subMenuList, childIndex) => {
                    if (pmenu === subMenuList.le_name) {
                        temp.push({
                            bg_id: subMenuList.bg_id,
                            cat_id: subMenuList.cat_id,
                            cat_name: subMenuList.cat_name,
                            div_id: subMenuList.div_id,
                            le_id: subMenuList.le_id,
                            le_name: subMenuList.le_name,
                            childIndex: childIndex
                        })
                    }
                })
                totalList1.push({
                    index: index,
                    parentData: pmenu,
                    child: temp,
                })

            })
            setCategoryList(totalList1)

        }
        else {
            setCategoryList([])

        }
        if (divisionData.length > 0) {
            let uniq = _.uniqBy(divisionData, function (e) {
                return e.le_name
            })
            let parentSep = uniq.map((e) => {
                return e.le_name
            })
            let totalList = []
            parentSep.map((pmenu, index) => {
                let temp = []
                divisionData?.map((subMenuList, childIndex) => {
                    if (pmenu === subMenuList.le_name) {
                        temp.push({
                            bg_id: subMenuList.bg_id,
                            c_id: subMenuList.c_id,
                            d_id: subMenuList.d_id,
                            div_id: subMenuList.div_id,
                            div_name: subMenuList.div_name,
                            le_id: subMenuList.le_id,
                            le_name: subMenuList.le_name,
                            childIndex: childIndex
                        })
                    }
                })
                totalList.push({
                    index: index,
                    parentData: pmenu,
                    child: temp,
                })

            })

            console.log(totalList, 'uniquee')
            setDivision(totalList)
        }
        else {
            setDivision([])
        }
    }, [categoryDatas, divisionData])

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(vendorComplianceScore.le_id)
        }
    }, [entityid])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setVendorComplianceScore({
                ...vendorComplianceScore,
                le_id: localStorage.getItem('SelectedEntityid'),
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            if (all && all.length > 0) {
                let domain = _.filter(vendorcompliancescorefilter.domains, { le_id: all[0] })
                setDomain(domain)
            }
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity', vendorcompliancescorefilter])

    useEffect(() => {
        if (vendorcompliancescoreexport && vendorcompliancescoreexport.link) {
            window.open(urls + '/' + vendorcompliancescoreexport.link);
            dispatch({
                type: VENDOR_COMPLIANCE_SCORE_RESET
            });
        }

    }, [vendorcompliancescoreexport])

    const showModal = () => {

        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onexport = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid() && vendorComplianceScore.domain != '') {
            // setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVCS",
                        {
                            "country_id": Number(vendorComplianceScore.country),
                            "legal_entity_list": all,
                            "domain_id": Number(vendorComplianceScore.domain),
                            "division_id": Number(vendorComplianceScore.division),
                            "category_id": Number(vendorComplianceScore.category),
                            "vendor_ids": allvendor,
                            "export_type": Number(vendorComplianceScore.typeid),
                            "from_date": moment(vendorComplianceScore.from_month).format("MMM YYYY"),
                            "to_date": moment(vendorComplianceScore.to_month).format("MMM YYYY")
                        }
                    ]
                }
            ]
            VCSexport({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const onChange = (key) => {
        console.log(key);
    };

    const HandelChange = (obj) => {
        let allArray = [];
        if (obj && obj.length > 0) {
            if (obj.includes('all')) {
                if (sessionArr && sessionArr.length > 0) {
                    let temp = []
                    for (let i in sessionArr) {
                        let domain = _.filter(vendorcompliancescorefilter.domains, { le_id: sessionArr[i].le_id })
                        if (domain && domain.length > 0) {
                            for (let i in domain) {
                                temp.push(domain[i])
                            }
                        }

                    }
                    let uniqueObjArray = [...new Map(temp && temp.length > 0 && temp.map((items) => [items["d_id"], items])).values()];
                    setDomain(uniqueObjArray)
                }
            } else {
                let temp = []
                for (let i in obj) {
                    let domain = _.filter(vendorcompliancescorefilter.domains, { le_id: obj[i] })
                    // temp.push(domain)

                    if (domain && domain.length > 0) {
                        for (let i in domain) {
                            temp.push(domain[i])
                        }
                    }
                }
                let uniqueObjArray = [...new Map(temp && temp.length > 0 && temp.map((items) => [items["d_id"], items])).values()];
                setDomain(uniqueObjArray)
            }
        }
        if (obj.includes('all')) {
            if (sessionArr && sessionArr.length > 0) {
                let temp = []
                let cou_filter = _.filter(sessionArr, { c_id: parseInt(vendorComplianceScore.country) });

                for (let i in cou_filter) {
                    temp.push(cou_filter[i].le_id)
                    allArray.push(cou_filter[i].le_id)
                }

                setAll(temp)
                setVendorComplianceScore({
                    ...vendorComplianceScore,
                    units: allArray,
                    domain: '',
                    domainName: '',
                    division: 0,
                    divisionName: '',
                    category: 0,
                    categoryName: '',
                    typeid: '',
                    typeName: '',
                    from_month: '',
                    to_date: ''
                })
                setAllvendor([])
                setAllvendornumber([])
            }
            Setselectcheckbox('none');
            Setunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setVendorComplianceScore({
                ...vendorComplianceScore,
                units: [],
                domain: '',
                domainName: '',
                division: 0,
                divisionName: '',
                category: 0,
                categoryName: '',
                typeid: '',
                typeName: '',
                from_month: '',
                to_date: ''
            })
            setAllvendor([])
            setAllvendornumber([])
            setAll(temp);
            Setselectcheckbox('block');
            Setunselectcheckbox('none');
        } else {
            if (obj.length == sessionArr.length) {
                Setselectcheckbox('none');
                Setunselectcheckbox('block');
            } else {
                Setselectcheckbox('block');
                Setunselectcheckbox('none');
            }
            setAll(obj)
            setVendorComplianceScore({
                ...vendorComplianceScore,
                units: obj,
                domain: '',
                domainName: '',
                division: 0,
                divisionName: '',
                category: 0,
                categoryName: '',
                typeid: '',
                typeName: '',
                from_month: '',
                to_date: ''
            })
            setAllvendor([])
            setAllvendornumber([])
        }
    };

    const HandelChangeVendor = (obj) => {
        let allArray = [];
        if (obj.includes('all')) {
            if (vendorfilter && vendorfilter.length > 0) {
                let temp = []
                let temparr = []
                for (let i in vendorfilter) {
                    for (let j in vendorfilter[i]) {
                        temp.push((vendorfilter[i][j].unit_id).toString())
                        temparr.push(vendorfilter[i][j].unit_id)
                        allArray.push(vendorfilter[i][j].unit_name)
                    }
                    // temp.push((vendorfilter[i].unit_id).toString())
                    // allArray.push(vendorfilter[i].unit_name)
                }
                setAllvendor(temp)
                setAllvendornumber(temparr)
                setVendorComplianceScore({
                    ...vendorComplianceScore,
                    vendorunits: allArray,
                })
            }
            Setselectcheckboxvendor('none');
            Setunselectcheckboxvendor('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setVendorComplianceScore({
                ...vendorComplianceScore,
                vendorunits: [],
            })
            setAllvendor(temp);
            setAllvendornumber(temp)
            Setselectcheckboxvendor('block');
            Setunselectcheckboxvendor('none');
        } else {
            if (obj.length == totalvendor.length) {
                Setselectcheckboxvendor('none');
                Setunselectcheckboxvendor('block');
            } else {
                Setselectcheckboxvendor('block');
                Setunselectcheckboxvendor('none');
            }
            setAllvendor(obj)
            setAllvendornumber(obj)
            setVendorComplianceScore({
                ...vendorComplianceScore,
                vendorunits: obj,
            })
        }

    };

    // useEffect(() => {
    //     if (tableData && tableData.work_flow_score_card_list && tableData.work_flow_score_card_list.length > 0) {
    //         setFilteredArray(tableData.work_flow_score_card_list)
    //     }
    // }, [tableData.work_flow_score_card_list])

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
        window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }


    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    useEffect(() => {
        if (all.length > 0) {
            let temp = []
            let tempData = []
            let tempData2 = []
            let temp2 = []

            let tempDivision = []
            let catDiv = []
            for (let i in all) {
                let division = _.filter(vendorcompliancescorefilter.divisions_list, { le_id: all[i] })
                let categoryArray = _.filter(vendorcompliancescorefilter.categories_list
                    , { le_id: all[i] })

                // console.log(datas, 'datadata');

                for (let k in division) {
                    tempDivision.push(division[k])
                }
                for (let v in categoryArray) {
                    catDiv.push(categoryArray[v])
                }
            }
            // if (AddType == 'add') {
            setdivisionData(tempDivision)
            setCategoryDats(catDiv)
        }
    }, [all, vendorcompliancescorefilter])

    return (
        <Fragment>
            <div className="page-wrapper" id="page-wrapper">
                <div className="page-titles pb-2 pt-2 page-title-sticky">

                    <div className="row">
                        <nav className="col-lg-6 col-md-6 col-12 align-self-center" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center p-0">
                                <li className="breadcrumb-item">
                                    <span className="pointer" id='homehover' onClick={homescreen}>
                                        <i className="ri-home-3-line fs-5"></i>
                                    </span>
                                </li>
                                <li className="breadcrumb-item active " aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page" style={{ fontSize: "15px", fontWeight: "bold" }}>
                                    <span>Customized Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: "large", fontWeight: "bold" }}>
                                    <span>Vendor Compliance Score</span>
                                </li>
                            </ol>
                        </nav>
                        <div className="col-lg-6 text-end">
                            <button onClick={(e) => {
                                fullscreenMode();
                            }}
                                className="bg-transparent text-black ms-1 full-mode fullscreenradius">
                                <Tooltip
                                    placement="left"
                                    title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                                >
                                    <FullscreenOutlined />
                                </Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className={"card-body " + localStorage.getItem('currentTheme')} style={{ padding: "0px 5px" }}>
                                <Collapse defaultActiveKey={["0"]} className="report-collapse" onChange={onChange} >
                                    <Panel header="Vendor Compliance Score" >
                                        {/* key={key} */}
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Country: </b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Country"
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        handlePasting(e, /^[a-z]+$/gi)
                                                                    }}
                                                                    onChange={(value, data) => {
                                                                        if (value != undefined) {
                                                                            let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                            setEntity(le)
                                                                        }
                                                                        setVendorComplianceScore({
                                                                            ...vendorComplianceScore,
                                                                            country: value ? value : '',
                                                                            countryName: data ? data.children : '',
                                                                            domain: '',
                                                                            domainName: '',



                                                                        })
                                                                        setAll([])
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    //value={Settings.le_id}
                                                                    style={{ width: '100%', marginTop: '3px' }}
                                                                    value={vendorComplianceScore.countryName || undefined}
                                                                >
                                                                    {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'countryName',
                                                                    vendorComplianceScore.countryName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{vendorComplianceScore.countryName}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ marginBottom: "3px" }} htmlFor=""><b>Business Group: </b></label>
                                                    </div>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Business Group Name"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                }}
                                                                onChange={(data, value) => {

                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        bg_id: data ? data : '',
                                                                        bg_name: value.children ? value.children : '',
                                                                        domain: '',
                                                                        domainName: '',
                                                                        division: '',
                                                                        division: '',
                                                                        divisionName: '',
                                                                        category: '',
                                                                        categoryName: '',
                                                                        vendorunits: []
                                                                    })
                                                                    setAll([])


                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //  value={userDetails.legal_entity_id}
                                                                style={{ width: '100%' }}
                                                            >
                                                                {bggroup && bggroup.length && bggroup.map((item, i) => {

                                                                    if (item.bg_name !== null) {
                                                                        return (
                                                                            <Option key={item.bg_id}>
                                                                                {item.bg_name}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                })}
                                                            </Select>

                                                        </div> :
                                                        <p> - </p>
                                                    }
                                                </div>
                                                <div className='col-md-4 text-wrap-report'>
                                                    <label><b>Legal Entity: </b>  <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Legal Entity"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                }}
                                                                onChange={(option) => HandelChange(option)}
                                                                // onChange={(data, value) => {
                                                                //     Setlegalstate(true)
                                                                //     setCurrentEntity(data)
                                                                //     setVendorComplianceScore({
                                                                //         ...vendorComplianceScore,
                                                                //         le_id: data,
                                                                //         legel_entity: value.children
                                                                //     })
                                                                // }}
                                                                mode="multiple"
                                                                maxTagCount='responsive'
                                                                style={{ width: '100%', marginTop: '3px' }}
                                                                // value={vendorComplianceScore.legel_entity || undefined}
                                                                value={all}
                                                                disabled={vendorComplianceScore.country == '' ? true : false}
                                                            >
                                                                <Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                                <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                {entity && entity.length > 0 && entity.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                all,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{ marginTop: "5px" }}>
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Domain: </b>  <span style={{ color: "red" }}> *</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            onPaste={(e) => {
                                                                handlePasting(e, /^[a-z - .]+$/gi)
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onChange={(value, data) => {
                                                                if (value !== undefined) {
                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        domain: value ? value : '',
                                                                        domainName: data.children ? data.children : '',
                                                                        division: '',
                                                                        division: '',
                                                                        divisionName: '',
                                                                        category: '',
                                                                        categoryName: '',
                                                                        vendorunits: []

                                                                    })

                                                                }
                                                                else {
                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        domain: '',
                                                                        domainName: ''
                                                                    })
                                                                }
                                                                // setCategoryList(category)
                                                                let temp = []
                                                                for (let i in all) {
                                                                    let category
                                                                    if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
                                                                        category = _.filter(vendorcompliancescorefilter && vendorcompliancescorefilter.unit_legal_entity.length > 0 && vendorcompliancescorefilter.unit_legal_entity, { legal_entity_id: all[0] })
                                                                    } else {
                                                                        category = _.filter(vendorcompliancescorefilter && vendorcompliancescorefilter.unit_legal_entity.length > 0 && vendorcompliancescorefilter.unit_legal_entity, { legal_entity_id: all[i] })
                                                                    }
                                                                    temp.push(category)
                                                                }
                                                                let temparray = []
                                                                for (let j in temp) {
                                                                    temp[j].map((items, i) => {
                                                                        temparray.push(items)
                                                                    })
                                                                }
                                                                setTotalvendor(temparray)
                                                                setVendorfilter(temp)
                                                            }}
                                                            placeholder="Enter Domain"
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            value={vendorComplianceScore.domainName || undefined}
                                                            // disabled={vendorComplianceScore.legel_entity == '' ? true : false}
                                                            disabled={all && all.length > 0 ? false : true}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                        >
                                                            {domain && domain.length > 0 && domain.map((item, i) => {
                                                                if (item != undefined) {
                                                                    return (
                                                                        <Option key={item.d_id}>
                                                                            {item.d_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            })}
                                                        </Select>
                                                    </div>
                                                    {validator.current.message(
                                                        'domain',
                                                        vendorComplianceScore.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division: </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            onChange={(value, data) => {
                                                                if (value == undefined) {
                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        division: '',
                                                                        divisionName: '',
                                                                        category: '',
                                                                        categoryName: '',
                                                                        vendorunits: []
                                                                    })
                                                                } else {
                                                                    let category = _.filter(vendorcompliancescorefilter.categories_list, { div_id: Number(value) })
                                                                    let uniq1 = _.uniqBy(category, function (e) {
                                                                        return e.le_name
                                                                    })
                                                                    let parentSep1 = uniq1.map((e) => {
                                                                        return e.le_name
                                                                    })
                                                                    let totalList1 = []
                                                                    parentSep1.map((pmenu, index) => {
                                                                        let temp = []
                                                                        category.map((subMenuList, childIndex) => {
                                                                            if (pmenu === subMenuList.le_name) {
                                                                                temp.push({
                                                                                    bg_id: subMenuList.bg_id,
                                                                                    cat_id: subMenuList.cat_id,
                                                                                    cat_name: subMenuList.cat_name,
                                                                                    div_id: subMenuList.div_id,
                                                                                    le_id: subMenuList.le_id,
                                                                                    le_name: subMenuList.le_name,
                                                                                    childIndex: childIndex
                                                                                })
                                                                            }
                                                                        })
                                                                        totalList1.push({
                                                                            index: index,
                                                                            parentData: pmenu,
                                                                            child: temp,
                                                                        })

                                                                    })
                                                                    setCategoryList(totalList1)
                                                                    // setCategoryList(category)
                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        division: value,
                                                                        divisionName: data.children,
                                                                        category: '',
                                                                        categoryName: '',
                                                                        vendorunits: []
                                                                    })
                                                                }
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                            showSearch
                                                            placeholder="Select Division"
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            value={vendorComplianceScore.divisionName || undefined}
                                                            disabled={vendorComplianceScore.domainName == '' ? true : false}
                                                        // disabled={vendorComplianceScore.legel_entity == '' ? true : false}
                                                        >
                                                            {division && division.length > 0 && division.map((item, i) => {
                                                                console.log(item, "itemitemitem");
                                                                return (

                                                                    <OptGroup label={item && item.parentData}>
                                                                        {item.child.map((itemdata, indexed) => {
                                                                            // console.log(itemdata,'lslkjsnhdijo')
                                                                            return (
                                                                                <>
                                                                                    <Option key={itemdata.div_id}>
                                                                                        {itemdata.div_name}
                                                                                    </Option>
                                                                                </>)

                                                                        })}
                                                                    </OptGroup>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>

                                                <div className='col-md-4'>
                                                    <label><b>Category: </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            onChange={(value, data) => {
                                                                let temp = []
                                                                if (value == undefined) {
                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        category: '',
                                                                        categoryName: '',
                                                                        vendorunits: []

                                                                    })
                                                                } else {
                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        category: value,
                                                                        categoryName: data.children,
                                                                        vendorunits: []
                                                                    })
                                                                    let a = vendorcompliancescorefilter && vendorcompliancescorefilter.unit_legal_entity.filter(item => item.d_ids.includes(Number(vendorComplianceScore.domain)))
                                                                    const vend_filter = _.filter(a, { category_id: parseInt(value) })
                                                                    temp.push(vend_filter)
                                                                    setVendorfilter(temp);
                                                                }
                                                            }
                                                            }
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                            showSearch
                                                            placeholder="Select Category"
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            value={vendorComplianceScore.categoryName || undefined}
                                                            disabled={vendorComplianceScore.domainName == '' ? true : false}
                                                        // disabled={vendorComplianceScore.legel_entity == '' ? true : false}
                                                        >
                                                            {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                                return (
                                                                    <OptGroup label={item && item.parentData}>
                                                                        {item.child.map((itemdata, indexed) => {
                                                                            return (
                                                                                <Option key={itemdata.cat_id}>
                                                                                    {itemdata.cat_name}
                                                                                </Option>
                                                                            )
                                                                        })}
                                                                    </OptGroup>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {console.log(vendorfilter, 'ananyasri')}
                                        <div className="col-md-12" style={{ marginTop: "5px" }}>
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Vendor: </b></label>
                                                    <div className="form-group">
                                                        <Select

                                                            allowClear={true}
                                                            size="default"
                                                            onChange={(option) => HandelChangeVendor(option)}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                            showSearch
                                                            // onChange={(value) => (console.log(value,'option'))}
                                                            placeholder="Select Vendor"
                                                            mode="multiple"
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            value={allvendornumber || undefined}
                                                            maxTagCount='responsive'
                                                            // value={vendorComplianceScore.domainName || undefined}
                                                            disabled={vendorComplianceScore.domainName == '' ? true : false}
                                                        >
                                                            {/* <Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                                <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option> */}
                                                            {vendorfilter && vendorfilter[0] && vendorfilter[0]?.length > 0 ?
                                                                <><Option value="all" key="all" style={{ display: selectcheckboxvendor }}>---SELECT ALL---</Option>
                                                                    <Option value="unselect" key="unselect" style={{ display: unselectcheckboxvendor }}>--UNSELECT ALL--</Option>
                                                                </> : ''}

                                                            {vendorfilter && vendorfilter.length > 0 && vendorfilter.map((item, i) => {
                                                                if (item && item[0]?.le_name) {
                                                                    return (
                                                                        <OptGroup label={item && item[0].le_name}>
                                                                            {item.map((items, i) => {
                                                                                console.log(items, "itemsitemsitemsitems");
                                                                                return (<Option key={items.unit_id} value={items.unit_id}>{items.unit_name}</Option>)
                                                                            })}
                                                                        </OptGroup>
                                                                    );
                                                                }
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Type: </b>  <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            onChange={(value, data) => {
                                                                setVendorComplianceScore({
                                                                    ...vendorComplianceScore,
                                                                    typeid: value ? value : '',
                                                                    typeName: data && data.children ? data.children : ''
                                                                })
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                            showSearch
                                                            placeholder="Select"
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            value={vendorComplianceScore.typeName || undefined}
                                                            disabled={vendorComplianceScore.domainName == '' ? true : false}
                                                        // disabled={vendorComplianceScore.legel_entity == '' ? true : false}
                                                        >
                                                            <Option key={"1"}>Consolidated</Option>
                                                            <Option key={"2"}>Month wise</Option>
                                                            {/* {domain_data && domain_data.length && domain_data.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })} */}
                                                        </Select>
                                                    </div>
                                                    {validator.current.message(
                                                        'typeName',
                                                        vendorComplianceScore.typeid,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Type Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>From Month: <span style={{ color: "red" }} >*</span></label>
                                                    {due == false ? (
                                                        <DatePicker
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            format={'MMM YYYY'}
                                                            allowClear={true}
                                                            picker="month"
                                                            value={vendorComplianceScore.from_month ? moment(vendorComplianceScore.from_month) : ''}
                                                            onKeyDown={(event) => {
                                                                event.preventDefault()
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                let correctDate = moment(dateString._d).format('DD-MMM-YYYY')
                                                                console.log(correctDate, "correctDatecorrectDate");
                                                                setDue(true);
                                                                setVendorComplianceScore({
                                                                    ...vendorComplianceScore,
                                                                    from_month: dateString ? dateString : '',
                                                                    to_month: dateString == dateString ? moment(dateString).add(2, 'months') : ''
                                                                })
                                                            }}
                                                            monthCellRender={(current) => {
                                                                const style = {};
                                                                if (
                                                                    moment(current).format("MMM-YYYY") ===
                                                                    moment().format("MMM-YYYY")
                                                                ) {
                                                                    // style.border = '1px solid #1890ff';
                                                                    style.borderRadius = "2px";
                                                                    style.lineHeight = "24px";
                                                                    style.minWidth = "24px";
                                                                    style.position = "relative";
                                                                    style.display = "inlineBlock";
                                                                    style.backgroundColor = "#1890ff";
                                                                    style.color = "white";
                                                                    style.marginTop = "5px";
                                                                    style.fontSize = "15px"
                                                                }
                                                                return (
                                                                    <div
                                                                        className="ant-picker-cell-inner"
                                                                        style={style}
                                                                    >
                                                                        {moment(current).format("MMM")}
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    )
                                                        :
                                                        <DatePicker
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            format={'MMM YYYY'}
                                                            allowClear={true}
                                                            picker="month"
                                                            value={vendorComplianceScore.from_month ? moment(vendorComplianceScore.from_month) : ''}
                                                            onKeyDown={(event) => {
                                                                event.preventDefault()
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                setDue(true);
                                                                if (dateString == '') {
                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        from_month: '',
                                                                        to_month: ''
                                                                    })
                                                                } else {
                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        from_month: dateString ? dateString : '',
                                                                        to_month: dateString == dateString ? moment(dateString).add(2, 'months') : ''
                                                                    })
                                                                }

                                                            }}


                                                        />
                                                    }

                                                    {validator.current.message(
                                                        'from_month',
                                                        vendorComplianceScore.from_month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'From Month Required',
                                                            }
                                                        })}
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-12" style={{ marginTop: "5px" }}>
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>To Month: <span style={{ color: "red" }} >*</span></label>
                                                    <DatePicker style={{ width: '100%', marginTop: '3px' }} format={'MMM YYYY'}
                                                        allowClear={true} picker="month"
                                                        onKeyDown={(event) => {
                                                            event.preventDefault()
                                                        }}
                                                        value={vendorComplianceScore.to_month ? moment(vendorComplianceScore.to_month) : ''}
                                                        onChange={(date, dateString) => {
                                                            setVendorComplianceScore({
                                                                ...vendorComplianceScore,

                                                                to_month: dateString ? dateString : ''
                                                            })
                                                        }}
                                                        disabledDate={(value) => {
                                                            return moment(vendorComplianceScore.from_month) >= value || moment(vendorComplianceScore.from_month).add(1, 'years') <= value;
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'to_month',
                                                        vendorComplianceScore.to_month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'To Month Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>


                                        <div style={{ textAlign: 'center', marginTop: "15px" }}>
                                            <Button type="primary" shape="round" className='addbutton'
                                                icon={<ExportOutlined />} onClick={onexport}
                                            >
                                                Export
                                            </Button>
                                        </div>


                                    </Panel>
                                </Collapse>

                                {tableshow == true ?
                                    <>
                                        <div className='col-md-12 mt-1 filter-detial-sticky' >
                                            <div className='row'>
                                                <div className='col-md-5 text-wrap-report'>
                                                    <label><b>Legal Entity :</b>{localStorage.getItem('SelectedEntity')}</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label><b>Country :</b>{sessionArr && sessionArr[0].c_name}</label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain :</b>{vendorComplianceScore.domainName}</label>
                                                </div>


                                            </div>
                                        </div>
                                        <div className='ant-table-wrapper'>
                                            <div className='ant-table ant-table-bordered'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content fh userprivclass1'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className='ant-table-thead table-head-stick' >
                                                                <tr>
                                                                    <th className='ant-table-cell w10px'>#</th>
                                                                    <th title='Compliance Task' className='ant-table-cell custom-width1'>Task Status</th>
                                                                    <th className='ant-table-cell custom-width1'>Assignee</th>
                                                                    <th className='ant-table-cell custom-width1'><center>Concur</center></th>
                                                                    <th className='ant-table-cell custom-width1'>Approver</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='ant-table-tbody'>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>1</center></td>
                                                                    <td className='ant-table-cell'><center><Link type="primary"
                                                                        onClick={() => {
                                                                            setType('completed')
                                                                            showModal()
                                                                        }}
                                                                    >Completed</Link></center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_assignee}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_concur}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_approver}</center></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>2</center></td>
                                                                    <td className='ant-table-cell'><center><Link type="primary"
                                                                        onClick={() => {
                                                                            setType('inprogress')
                                                                            showModal()

                                                                        }}
                                                                    >In progress within due date</Link></center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].inp_assignee}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].inp_concur}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].inp_approver}</center></td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>3</center></td>
                                                                    <td className='ant-table-cell'><center><Link type="primary"
                                                                        onClick={() => {
                                                                            setType('overdue')
                                                                            showModal()
                                                                        }}
                                                                    >In progress over due</Link></center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].ov_assignee}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].ov_concur}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].ov_approver}</center></td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center></center></td>
                                                                    <td className='ant-table-cell'><center><b>Total</b></center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_assignee + filteredArray[0].inp_assignee + filteredArray[0].ov_assignee}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_concur + filteredArray[0].inp_concur + filteredArray[0].ov_concur}</center></td>
                                                                    <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_approver + filteredArray[0].inp_approver + filteredArray[0].ov_approver}</center></td>
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : ''
                                    //   <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default connect(mapStateToProps, {
    VCSFilters,
    VCSexport
})(Vendorcompliancescore);