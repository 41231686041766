import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { APPROVE_TASK_RECALL, APPROVE_TASK_RECALL_LIST, APPROVE_INVALID_PASS, APPROVE_INVALID_PASS_RESET, APPROVETASKRECALL_HISTORY, APPROVETASKRECALL_INTERIM } from '../../types/index'

const initialState = {
    AssignTaskRecall: {
        unitdata: '',
        domain: "",
        frequency: "",
        approverecallcompliance: '',
        approverecallcompliancestatus: '',
        history_details: '',
        interim_details: '',
        resetvalue: ''
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case APPROVE_TASK_RECALL:
            {
                return {
                    ...state,
                    AssignTaskRecall: {
                        ...state.AssignTaskRecall,
                        unitdata: payload.user_units,
                        domain: payload.domains_list,
                        frequency: payload.frequency_list,
                        assignee: payload.assignee_name_list

                    },
                };
            }
        case APPROVE_TASK_RECALL_LIST:
            {
                return {
                    ...state,
                    AssignTaskRecall: {
                        ...state.AssignTaskRecall,
                        approverecallcompliance: payload.recall_compliances,
                        //     domain:payload.domains_list,
                        //    frequency:payload.frequency_list

                    },
                };
            }
        case APPROVETASKRECALL_HISTORY:
            {
                return {
                    ...state,
                    AssignTaskRecall: {
                        ...state.AssignTaskRecall,
                        history_details: payload,
                    },
                };
            }
        case APPROVETASKRECALL_INTERIM:
            {
                return {
                    ...state,
                    AssignTaskRecall: {
                        ...state.AssignTaskRecall,
                        interim_details: payload,
                    },
                };
            }
        case APPROVE_INVALID_PASS:
            {
                return {
                    ...state,
                    AssignTaskRecall: {
                        ...state.AssignTaskRecall,
                        resetvalue: payload,
                    },
                };
            }
        case APPROVE_INVALID_PASS_RESET:
            {
                return {
                    ...state,
                    AssignTaskRecall: {
                        ...state.AssignTaskRecall,
                        resetvalue: '',
                    },
                };
            }
        default:
            return state;
    }
}