import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url, { getUrl } from '../../../Libs/URL'
import EncryptDecrypt from '../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { COMPLIANCE_TASK_DETAILS_DATA, COMPLIANCE_UPCOMING_TASK, FILE_SIZE, NEXT_RECORD, GET_INTERIM_DETAILS, COMPLIANCE_UPCOMING_FILE_RESET, COMPLIANCE_UPCOMING_FILE, COMPLIANCE_TASK_CALENDER_VIEW, COMPLIANCE_CURRENT_TASK, TOGGLE_COMMON_LOADER } from '../../types/index';
import alertmessage from '../../../Libs/Altermessage';
const urls = getUrl()

export const getFilterDataValues = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (executeRequest.message == 'Request failed with status code 400') {
                Toaster.error("Request Process Failed")
            }
            else {
                Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
            }
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log('filterchechj', decryptData)
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123");

                dispatch({
                    type: COMPLIANCE_TASK_DETAILS_DATA,
                    payload: decryptData,
                });
            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const getCalenderView = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (executeRequest.message == 'Request failed with status code 400') {
                Toaster.error("Request Process Failed")
            }
            else {
                Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
            }
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            console.log(decryptData, 'current data ?')
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123");

                dispatch({
                    type: COMPLIANCE_TASK_CALENDER_VIEW,
                    payload: decryptData,
                });
            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const getCurrentTaskDetails = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload, 'getCurrentTaskDetails');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (executeRequest.message == 'Request failed with status code 400') {
                Toaster.error("Request Process Failed")
            }
            else {
                Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
            }
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));

            console.log(decryptData, 'current data ???')
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123ttt");
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: COMPLIANCE_CURRENT_TASK,
                    payload: decryptData,
                    count: decryptData[1].current_compliances?.length
                });
            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const getUpcomigTaskDetails = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (executeRequest.message == 'Request failed with status code 400') {
                Toaster.error("Request Process Failed")
            }
            else {
                Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
            }
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123");

                dispatch({
                    type: COMPLIANCE_UPCOMING_TASK,
                    payload: decryptData,
                });
            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};

export const updateStatus = ({ payload, executeCancel, paramid, payload2, setFileUpload, setCompletionDate, setFileSize, SetRemarks, setValueModal, valueModal }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (executeRequest.message == 'Request failed with status code 400') {
                Toaster.error("Request Process Failed")
            }
            else {
                Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
            }
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, 'decrrrrr')

            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else if (decryptData[0] == 'SaveInterimComplianceHistorySuccess') {
                Toaster.success("Interim Status Updated Successfully")
                // if (nextData !== undefined) {
                //     dispatch({
                //         type: NEXT_RECORD,
                //         payload: nextData,
                //     });
                // }
                // else {
                //     setShowValueModal(false)
                // }
                setCompletionDate('')
                setFileUpload([])
                dispatch({
                    type: COMPLIANCE_UPCOMING_FILE_RESET,
                    payload: ''
                })
                setFileSize([])
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch(
                    getCurrentTaskDetails({
                        payload: payload2,
                        paramid: paramid,

                    })
                )

                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });

                // setShowValueModal(false)
                setValueModal({
                    ...valueModal,
                    document_reference_number: ''
                })
                setCompletionDate("")
                SetRemarks("")
                // if (nextData !== undefined) {
                //     dispatch({
                //         type: TOGGLE_COMMON_LOADER
                //     });
                // }
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                // dispatch({
                //     type: COMPLIANCE_UPCOMING_TASK,
                //     payload: decryptData,
                // });
            } else if (decryptData[0] === "UnSupportedFile") {
                Toaster.error('UnSupported File')
            }
            // else if(decryptData[0] === "FileServerFailed"){
            //     Toaster.err('File Server Failed')
            // }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const saveStatus = ({ payload, executeCancel, Setpassvisible, paramid, valueModal, nextData, payload2, setShowValueModal, setValueModal, setCompletionDate, SetRemarks, setEventValue, eventValue }) => async dispatch => {
    console.log(payload, 'payloadllllll');
    try {

        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (executeRequest.message == 'Request failed with status code 400') {
                Toaster.error("Request Process Failed")
            }
            else {
                Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
            }
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, 'decryptData')
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else if (decryptData[0] == 'InvalidPassword') {
                Toaster.error("Invalid Password")


            } else if (decryptData[0] == 'UpdateComplianceDetailSuccess') {
                Toaster.success("Compliance Submitted Successfully")

                setValueModal({
                    ...valueModal,
                    document_reference_number: ''
                })
                setEventValue(eventValue - 1)
                dispatch({
                    type: COMPLIANCE_UPCOMING_FILE_RESET,
                    payload: ''
                })
                setCompletionDate("")
                SetRemarks("")
                Setpassvisible(false)
                dispatch(
                    getCurrentTaskDetails({
                        payload: payload2,
                        paramid: paramid,

                    })
                )
                if (nextData !== undefined) {
                    dispatch({
                        type: NEXT_RECORD,
                        payload: nextData,
                    });
                }
                else {
                    setShowValueModal(false)
                }

            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};

export const UploadFile = ({ payload, executeCancel, paramid, setFileUpload }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/files`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (executeRequest.message == 'Request failed with status code 400') {
                Toaster.error("Request Process Failed")
            }
            else {
                Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
            }
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));

            if (decryptData[0] == 'FileUploadSuccess') {
                // Toaster.success("File Upload Successfully"
                // )
            }
            else if (decryptData[0] == 'UnSupportedFile') {
                Toaster.error('Invalid File Format')
                // setFileUpload([])
            }
            // else {
            //     // dispatch({
            //     //     type: TOGGLE_COMMON_LOADER
            //     // });
            //     Toaster.error("Failed")
            // }

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            }
            // dispatch({
            //     type: COMPLETED_TASK_LIST,
            //     payload: decryptData,
            // });
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const UploadFile1 = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload, 'payload655555555555555');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/files`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (executeRequest.message == 'Request failed with status code 400') {
                Toaster.error("Request Process Failed")
            }
            else {
                Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
            }
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, 'decryptData6857855');
            if (decryptData[0] == 'FileUploadSuccess') {
                // Toaster.success("File Upload Successfully"
                // )
                dispatch({
                    type: COMPLIANCE_UPCOMING_FILE,
                    payload: decryptData,
                });
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
            } else {

                Toaster.error("Failed")
            }

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            }
            // dispatch({
            //     type: COMPLETED_TASK_LIST,
            //     payload: decryptData,
            // });
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const Downloadfile = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload, 'payload5656555555555');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                // 'Content-Type': 'text/plain;charset=UTF-8'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/files`,
            headers: head,
            executeCancel,
            body: data1,

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (executeRequest.message == 'Request failed with status code 400') {
                Toaster.error("Request Process Failed")
            }
            else {
                Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
            }
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            console.log(response, "decryptDatadecryptData5");
            var a = document.createElement('a');
            var blob = dataURItoBlob(response, 'application/octet-stream');
            a.href = window.URL.createObjectURL(blob);
            a.download = payload[1].request[1].file_name;
            a.click();
            // var blob = dataURItoBlob(executeRequest.data, 'application/octet-stream');
            // console.log(blob, 'blobs')
            // let fileReader = new FileReader();
            // fileReader.readAsDataURL(blob)
            // fileReader.onload = function (e) {
            //     console.log(e.target.result, 'e.target.result')
            //     var link = document.createElement('a');
            //     link.setAttribute(
            //         'download',
            //     );
            //     link.click();
            //     link.parentNode.removeChild(link);
            // };
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const removedocument = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {

        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/files`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (executeRequest.message == 'Request failed with status code 400') {
                Toaster.error("Request Process Failed")
            }
            else {
                Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
            }
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, "decryptData111");
            dispatch({
                type: FILE_SIZE,
                payload: decryptData,
            })
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                // dispatch({
                //     type: COMPLIANCE_APPROVAL_REMOVE,
                //     payload: decryptData,
                // });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getInterimDetails = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (executeRequest.message == 'Request failed with status code 400') {
                Toaster.error("Request Process Failed")
            }
            else {
                Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
            }
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123");

                dispatch({
                    type: GET_INTERIM_DETAILS,
                    payload: decryptData,
                });
            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
function dataURItoBlob(dataURI, contentType) {
    console.log(contentType, 'blobs')
    var str = dataURI.replace(/\s/g, '');
    var binary = base64_decode(str);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: contentType });
}

export const fileListTemp = ({ payload, executeCancel, paramid, setShowValueModal, setValueModal, setCompletionDate, SetRemarks, valueModal }) => async dispatch => {
    try {
        console.log(payload, 'payload566666666666666');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/files`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (executeRequest.message == 'Request failed with status code 400') {
                Toaster.error("Request Process Failed")
            }
            else {
                Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
            }
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, "decryptData1234555");
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else if (decryptData[0] == 'SaveInterimComplianceHistorySuccess') {
                Toaster.success("Interim Status Updated Successfully")

                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                setShowValueModal(false)
                setValueModal({
                    ...valueModal,
                    document_reference_number: ''
                })
                setCompletionDate("")
                SetRemarks("")
            }
            else {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};