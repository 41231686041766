import { Fragment, useState, useRef, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom'
import { PlusOutlined, ExclamationCircleTwoTone, BookTwoTone, RetweetOutlined, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, SearchOutlined, FilterFilled, PlusCircleOutlined, DownOutlined, EyeOutlined, FullscreenOutlined, UpOutlined, FilterOutlined, InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Modal, Input, Card, Collapse, Tabs, Select, Tooltip, Table, Button, Popconfirm, TextArea, DatePicker, Form, Checkbox } from 'antd';
import DataTable from "react-data-table-component";
import SimpleReactValidator from "simple-react-validator";
import { reassignlist, reassignunitlist, reassigndatatable, getusercomplaince, savereassigncomplaince, showmoredata } from './../../Store/Action/Transcations/ReassignComplaince'
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import { toast } from 'react-toastify';
import moment from 'moment';
import ScrollToTop from "react-scroll-to-top";
import FeatherIcon from 'feather-icons-react';
import { REMARKS_MODAL_RESET } from '../../Store/types';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from '../Scrollbar';
import Stickicon from "./Stickyicon";
import { Toaster } from '../../Libs/Toaster';
import { validationType, validateSelect, onPasteToaster } from './../../Libs/inputValidation';
import { HomeRedirect } from './../../Libs/country'
import { dayjsLocalizer } from 'react-big-calendar';
import { indexOf } from 'lodash';
const homepage = HomeRedirect()

const _ = require("lodash");
const mapStateToProps = (state) => ({
    lists: state.ReassignComplaince.reassigndata,
    remarks: state.recallfilters.recallfilters,
    reassigndatatablelists: state.ReassignComplaince.reassigndatatablelist,
    reassigndatatablelistsnew: state.ReassignComplaince.reassigndatatablelist,
    showmorelists: state.ReassignComplaince.showmorelist,
    showmorelistsnew: state.ReassignComplaince.showmorelist,
})
const ReassignCompliance = ({
    reassignlist, reassignunitlist, reassigndatatable, getusercomplaince, savereassigncomplaince, GetRemarksData, showmoredata, reassigndatatablelists, showmorelists, reassigndatatablelistsnew, showmorelistsnew,
    lists: { reassigndatalist, reassignunitdata, reassignusercompliance, savereassigndatalist },
    remarks: { recallremarks },
    reassigndatatablelists: { reassigndatatablelist },
    showmorelists: { showmorelist }

}) => {
    console.log(reassigndatalist, "reassigndatatablelists");
    console.log(reassignunitdata, "reassignunitdata");
    console.log(reassignunitdata.reassign_units, 'reassign_unitsreassign_unitsreassign_units')
    const is_modal_true = useSelector((state) => state.recallfilters.is_modal_true)
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const history = useHistory();
    const location = useLocation();
    const paramid = location.pathname;
    const [domainValue, setDomainValue] = useState(false)
    const { TextArea } = Input;
    const isAuth = localStorage.getItem('isAuthenticated');
    const [checked, setChecked] = useState({
        All: ['', false],
        periodical: ['', false],
        flexi_review: ['', false],
        review: ['', false],
        on_occurrence: ['', false],
        one_time: ['', false]

    })
    const [domain, setDomain] = useState([])
    const [filterValues, setFilterValues] = useState(
        {
            All: '',
            periodical: '',
            flexi_review: '',
            review: '',
            on_occurrence: '',
            one_time: ''
        }
    )
    console.log(filterValues, 'filterValuesXSD')
    const [filterValues1, setFilterValues1] = useState([])
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue');
    const historynew = useHistory();
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const LEvalue = sessionParsedValue.login_response
    console.log(LEvalue, "LEvalueLEvalue123");
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddSubmitForm, setAddSubmitForm] = useState(false);
    const [searchedUpdatedArrayFilter, setSearchedUpdatedArrayFilter] = useState(false)
    const [searchingValue, setSearchingValue] = useState([])
    const [filslArray, setFilslArray] = useState()
    const [indexValue, setIndexValue] = useState([])
    const [objectValue, setObjectValue] = useState([])
    const [tableConditionArray, setTableConditionArray] = useState([])
    const [temparr, setTemparr] = useState([])
    const [temparr1, setTemparr1] = useState([])
    console.log(temparr1, "temparr1123");
    // const [handleEditDate,setHandleEditDate] = useState(false)
    const [selectedInput, setSelectedInput] = useState([])
    const [totTempArr1, setTotTempArr1] = useState([])
    const filteredArrayNew = Object.entries(indexValue);
    console.log(indexValue, filteredArrayNew, "indexValueindexValue");
    const [legalstate, Setlegalstate] = useState(false)
    const [unitcategory, Setunitcategory] = useState([])
    console.log(unitcategory, "unitcategory");
    // const [selectedRows, setSelectedRows] = useState([]);
    const [unitid, Setunitid] = useState([])
    const [childlist, Setchildlist] = useState([])
    console.log(childlist, "childlist");
    // const [checkrow, Setcheckrow] = useState([])
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [samplepage, setPagesample] = useState("1");
    const [key, setKey] = useState("1")
    const { TabPane } = Tabs;
    const { Search } = Input;
    const { Option } = Select
    const [tab, setTab] = useState(false)
    const { Panel } = Collapse;
    const [titledata, Settitledata] = useState([]);
    const [tempTitleData, setTempTitleData] = useState([])
    console.log(titledata, "titledata");
    const [checkboxtitle, Setcheckboxtitle] = useState([])
    console.log(checkboxtitle, "checkboxtitle");
    const [remarksModal, setRemarksModal] = useState(false);
    const wrapperRef = useRef(null);
    const [checkedRow, setCheckedRow] = useState(false)
    const [reassignPayloadId, setReassignPayloadId] = useState({
        u_id: [],
        user_type_id: [],
        no_of_comp_data: []
    })
    const [tempTaskType, setTempTaskType] = useState(['Current Task', 'Upcoming Task'])
    console.log(reassignPayloadId, 'reassignPayloadIdv')
    console.log(checkedRow, "checkedRow");
    const [checkedRow1, setCheckedRow1] = useState([])
    const [singlereassignPayload, setsingleReassignPayload] = useState({
        u_id: [],
        userType: [],
        no_of_comp_data: []
    })
    console.log(singlereassignPayload, reassignPayloadId, 'RRERRRTFYFYFYGGb')
    const [checkBoxTrue, setcheckBoxTrue] = useState([])
    console.log(singlereassignPayload, "singlereassignPayload");
    const [Assignpayload, setAssignPayload] = useState([])
    const [Entriescount, SetEntriescount] = useState(0)
    const [datacount, Setdatacount] = useState([])
    console.log(Assignpayload, 'selectedDataIdselectedDataIdselectedDataId')
    console.log(Entriescount, "entires");
    const [trigger, setTrigger] = useState([{
        comp_id: '',
    }])
    const [updateduedate, setUpdateduedate] = useState([])
    console.log(updateduedate, 'updateduedateupdateduedate')
    const [rowcompid, setRowCompId] = useState([])
    const [clearData, setClearData] = useState(false)
    console.log(clearData, 'clearData')
    const [diffid, setDiffId] = useState([])
    const dispatch = useDispatch();
    const [recalldata, Setrecalldata] = useState({
        reasons: ''
    })
    console.log(diffid, "recalldata123");
    const [categorylist, Setcategorylist] = useState({
        employee_name: '',
        employee_code: ''
    })
    console.log(categorylist, "categorylistcategorylist")
    const [assigneelist, Setassigneelist] = useState([])
    const [allassigneelist, Setallassigneelist] = useState([])
    console.log(allassigneelist, 'allassigneelistallassigneelist')
    const [allassigneecode, Setallassigneecode] = useState([])
    const [finalassignee, Setfinalassignee] = useState([])
    const [finalallassignee, Setfinalallassignee] = useState([])
    const [finalallassigneecode, Setfinalallassigneecode] = useState([])
    const [newassigneelist, Setnewasigneelist] = useState([])
    console.log(newassigneelist, 'newassigneelistnewassigneelist')
    const [newassigneelistshow, Setnewasigneelistshow] = useState([])
    console.log(newassigneelistshow, 'newassigneelistshow')
    const [newassigneelistshowData, SetnewasigneelistshowData] = useState([])
    const [dataa, setDataa] = useState({
        d_id: '',
        usr_id: '',
        u_ids: '',
        emp_code: '',
        s_u_name: '',
        employee_name: '',
        usr_name: '',
        legalentity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        d_name: '',
        user_type: '0',
        unit_name: '',
        su_name: '',
        su_num: '',
        su_id: ''
    })
    console.log(dataa, 'dataadataa')
    const [filteredArray, setFilteredArray] = useState([])
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [userType, SetuserType] = useState("ALL")
    const [showTable, SetshowTable] = useState(false)
    console.log(showTable, 'showTableshowTablej')
    const [entityid, setCurrentEntity] = useState("")
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 50,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    const [triggerstate, setTriggerstate] = useState(false)
    const [collapseAssignee, setCollapseAssignee] = useState(0)
    const [collapseAssignee1, setCollapseAssignee1] = useState(false)
    console.log(collapseAssignee1, 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaakakak')
    const date = new Date();
    console.log(moment(date).format("DD-MMM-YYYY"), "datedatedate");
    const [updatedArray, setUpdatedArray] = useState([]);
    const [updatedTempArray, setUpdatedTempArray] = useState([])
    const [updatedTempArrayShow, setUpdatedTempArrayShow] = useState([])
    const [updatedTemp, setUpdatedTemp] = useState([])
    const [partiSelect, setPartiSelect] = useState([])
    console.log(updatedArray, "updatedArray");
    const [updatedArrayshow, setUpdatedArrayShow] = useState([]);
    console.log(updatedArrayshow, "updatedArrayshow");
    const [updatedArrayShowmore, setUpdatedArrayShowmore] = useState([]);
    console.log(updatedArrayShowmore, "updatedArrayShowmore");
    const [filterArray, setfilteredArray] = useState([]);
    console.log(filterArray, "filterArray");
    const [filteredArrayState, setfilteredArrayState] = useState([]);
    console.log(filteredArrayState, "filteredArrayState");
    let [counts, setcounts] = useState(0);
    const [all, setAll] = useState("All");
    const [titleDynamic, setTitleDynamic] = useState([]);
    const [totalRecord, settotalRecord] = useState([]);

    const [mergedArray, setMergedArray] = useState([]);
    const [tempFilterArray, setTempFilterArray] = useState([]);

    const [temp, setTemp] = useState([]);
    const [assigneename, setAssigneename] = useState([]);
    const [searchValues, setSearchValues] = useState({
        legalEntity: '',
        domain: '',
        user: '',
        unit: ''
    })

    useEffect(() => {
        let tempArr = [];
        if (updatedArray && updatedArray.length > 0) {
            filteredArrayState &&
                filteredArrayState.length > 0 &&
                filteredArrayState.map((item, i) => {
                    let uniqueObjArray = [
                        ...new Map(
                            item &&
                            item.length > 0 &&
                            item.map((items) => [items["assignee_name"], items])
                        ).values(),
                    ];
                    const uniqueIds =
                        uniqueObjArray.length > 0 &&
                        uniqueObjArray.map((itemData) => {
                            return itemData.assignee_name;
                        });
                    for (let i in uniqueIds) {
                        let filteredArr = _.find(item && item.length > 0 && item, {
                            assignee_name: uniqueIds[i],
                        });
                        tempArr.push(filteredArr);
                    }
                });
            setAssigneename(tempArr);
        }
    }, [filteredArrayState]);


    console.log(all, 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
    const [tableArray, setTableArray] = useState()
    console.log(tableArray, 'tableArrayAAAA')
    const [unitName, setUnitName] = useState({
        1: 'Assignee',
        2: 'Concurrer',
        3: 'Approver'
    })
    const [unitList, setUnitList] = useState([])
    const [showDropDown, setshowDropDown] = useState({});
    const callername = JSON.parse(localStorage.getItem('sessionValue'));

    console.log(tableArray, 'tableArraytableArray')

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != "null") {
            setCurrentEntity(localStorage.getItem('SelectedEntityid').replaceAll('"', ''))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    const handleOk = () => {
        setRemarksModal(false)
    };

    const resetfilter = (id, itemKey, actId, unitId) => {
        setFilterValues({
            ...filterValues,
            All: '',
            periodical: '',
            flexi_review: '',
            review: '',
            on_occurrence: '',
            one_time: ''
        })
        setChecked({
            ...checked,
            All: [id, false],
            periodical: [id, false],
            flexi_review: [id, false],
            review: [id, false],
            on_occurrence: [id, false],
            one_time: [id, false]
        })
        delete indexValue[itemKey + '_periodical']
        delete indexValue[itemKey + '_review']
        delete indexValue[itemKey + '_flexi_review']
        delete indexValue[itemKey + '_on_occurrence']
        delete indexValue[itemKey + '_one_time']

        setSearchedUpdatedArrayFilter(true)

    }

    useEffect(() => {
        if (dataa.legalentity != '' && reassigndatalist.domains && reassigndatalist.domains.length == 1) {
            console.log(dataa.legalentity, 'inserting into if con')
            setDomainValue(true)
            setDataa({
                ...dataa,
                d_id: reassigndatalist.domains[0].d_id,
                d_name: reassigndatalist.domains[0].d_name
            })
        } else {
            console.log(dataa.legalentity, 'inserting into else con')
            setDomainValue(false)
            setDomain(reassigndatalist.domains)

        }
    }, [reassigndatalist.domains])

    const onFinishs = (ID) => {
        let array;
        let commonId
        let finalarray = [];
        let datavalue = [];
        let datas = [];
        let parId = []
        let u_id
        let filteredArr;
        let filteredArr1;
        let split_units = filslArray
        let sample
        let uniqunit = _.uniqBy(reassigndatatablelists.reassign_compliances && reassigndatatablelists.reassign_compliances.length > 0 && reassigndatatablelists.reassign_compliances, function (e) {
            return e.u_id;
        });
        // uniqunit.map((items) => {
        //     sample = reassigndatatablelists.reassign_compliances.filter((item) => {
        //         return items.u_id === item.u_id
        //     })
        //     split_units.push(sample)
        // }) v_date d_date approver_name concurrer_name assignee_name trigger_before_days summary  compliance_name  

        if (searchingValue['compliance_name']) {
            split_units = split_units.filter((item) => {
                if ((item["statu"]?.toLowerCase().includes(searchingValue['compliance_name']?.toLowerCase())) || (item["compliance_name"]?.toLowerCase().includes(searchingValue['compliance_name']?.toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['summary']) {
            split_units = split_units.filter((item) => {
                if ((item["summary"]?.toLowerCase().includes(searchingValue['summary']?.toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['trigger_before_days']) {
            split_units = split_units.filter((item) => {
                if ((item["trigger_before_days"].match(searchingValue['trigger_before_days']))) {
                    return true;
                }
            })
        }
        if (searchingValue['assignee_name']) {
            split_units = split_units.filter((item) => {
                if ((item["assignee_name"]?.toLowerCase().includes(searchingValue['assignee_name']?.toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['concurrer_name']) {
            split_units = split_units.filter((item) => {
                if ((item["concurrer_name"].replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['concurrer_name']?.toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['approver_name']) {
            split_units = split_units.filter((item) => {
                if ((item["approver_name"].replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['approver_name']?.toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['d_date']) {
            split_units = split_units.filter((item) => {
                if ((item["d_date"].replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['d_date']?.toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['v_date']) {
            split_units = split_units.filter((item) => {
                if ((item["v_date"].replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['v_date']?.toLowerCase()))) {
                    return true;
                }
            })
        }



        split_units.map((itemss, index) => {
            let sampletemp = []
            let tempArray1 = []
            if (itemss) {
                const ab = itemss.map(function (item) {
                    if ((sampletemp.indexOf(item.act_name) < 0))
                        sampletemp.push(item.act_name)
                })
                sampletemp.map((data, i) => {
                    datas = data
                    let temp = [];
                    let entityArray = []
                    let tempArray = []
                    let checkarray = []
                    let checkarray1 = []
                    let filterXArr = []
                    let filterXArr2 = []
                    const res = []
                    const upres = []
                    const keys = Object.keys(filterValues)
                    let name = []
                    keys.forEach(key => {
                        if (filterValues[key] === true) {
                            if (key == 'on_occurrence') {
                                key = 'on occurrence'
                                name = 'On Occurrence'
                            }
                            if (key == 'one_time') {
                                key = 'one time'
                                name = 'One Time'
                            }
                            if (key == 'periodical') {
                                key = 'periodical'
                                name = 'Periodical'
                            }
                            if (key == 'review') {
                                key = 'review'
                                name = 'Review'
                            }
                            if (key == 'flexi_review') {
                                key = 'flexi_review'
                                name = 'Flexi Review'
                            }
                            res.push(name)
                            console.log(key, 'keykey')
                            upres.push(
                                name.toUpperCase().replace(/_/g, " ")
                            )
                            setFilterValues1(res)
                        }
                        // filterValues[key] === true && upres.push(
                        //   key.toUpperCase().replace(/_/g, " ")
                        // )

                        // all?.toString().charAt(0).toUpperCase() + all?.toString().slice(1)
                        // if(key == )
                    })
                    if (upres.length > 0 && upres.length != 5) {
                        console.log(upres, 'upres')
                        setAll(upres.join(', '))
                    } else {
                        setAll('All')
                    }

                    if (datas) {
                        itemss.map((child) => {
                            if (datas === child.act_name) {
                                entityArray = {
                                    'ac_id': child.ac_id,
                                    'act_name': child.act_name,
                                    'approval_person': child.approval_person,
                                    'approver_name': child.approver_name,
                                    'assignee': child.assignee,
                                    'assignee_name': child.assignee_name,
                                    'c_h_id': child.c_h_id,
                                    'comp_id': child.comp_id,
                                    'compfielaw_url': child.compfielaw_url,
                                    'compliance_description': child.compliance_description,
                                    'compliance_name': child.compliance_name,
                                    'concurrence_person': child.concurrence_person,
                                    'concurrer_name': child.concurrer_name,
                                    'd_date': child.d_date,
                                    'extend_due_date': child.extend_due_date,
                                    'f_id': child.f_id,
                                    'frequency': child.frequency,
                                    'summary': child.summary,
                                    'task_type': child.task_type,
                                    'trigger_before_days': child.trigger_before_days && child.trigger_before_days?.toString(),
                                    'tz_date': child.tz_date,
                                    'tz_name': child.tz_name,
                                    'u_id': child.u_id,
                                    'u_name': child?.u_name,
                                    'v_date': child.v_date,
                                    'parentId': i,
                                    'common_id': child.u_id && child.u_id?.toString() + i
                                }
                                u_id = child.u_id
                                tempArray.push(entityArray)
                                console.log(tempArray, 'entityArray')
                                filteredArr = tempArray.filter((item) => {
                                    return item.task_type === 'Current Task'
                                })
                                console.log(filteredArr, "filteredArr111");
                                filteredArr1 = tempArray.filter((item) => {
                                    return item.task_type === 'Upcoming Task'
                                })

                            }

                        })
                        let listArray_One_time = _.filter(filteredArr, { frequency: "One Time" });
                        if (listArray_One_time && listArray_One_time.length > 0) {
                            for (let i in listArray_One_time) {
                                checkarray.push(listArray_One_time[i])
                            }
                        }
                        let listArray_periodical = _.filter(filteredArr, { frequency: "Periodical" });
                        if (listArray_periodical && listArray_periodical.length > 0) { for (let i in listArray_periodical) { checkarray.push(listArray_periodical[i]) } }
                        let listArray_Review = _.filter(filteredArr, { frequency: "Review" });
                        if (listArray_Review && listArray_Review.length > 0) { for (let i in listArray_Review) { checkarray.push(listArray_Review[i]) } }
                        let listArray_Flexi_Review = _.filter(filteredArr, { frequency: "Flexi Review" });
                        if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) { for (let i in listArray_Flexi_Review) { checkarray.push(listArray_Flexi_Review[i]) } }
                        let listArray_On_Occurrence = _.filter(filteredArr, { frequency: "On Occurrence" });
                        if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) { for (let i in listArray_On_Occurrence) { checkarray.push(listArray_On_Occurrence[i]) } }

                        let listArray_One_time1 = _.filter(filteredArr1, { frequency: "One Time" });
                        if (listArray_One_time1 && listArray_One_time1.length > 0) {
                            for (let i in listArray_One_time1) {
                                checkarray1.push(listArray_One_time1[i])
                            }


                        }
                        let listArray_periodical1 = _.filter(filteredArr1, { frequency: "Periodical" });
                        if (listArray_periodical1 && listArray_periodical1.length > 0) { for (let i in listArray_periodical) { checkarray1.push(listArray_periodical1[i]) } }

                        let listArray_Review1 = _.filter(filteredArr1, { frequency: "Review" });

                        if (listArray_Review1 && listArray_Review1.length > 0) { for (let i in listArray_Review) { checkarray1.push(listArray_Review1[i]) } }
                        let listArray_Flexi_Review1 = _.filter(filteredArr1, { frequency: "Flexi Review" });

                        if (listArray_Flexi_Review1 && listArray_Flexi_Review1.length > 0) { for (let i in listArray_Flexi_Review) { checkarray1.push(listArray_Flexi_Review1[i]) } }
                        let listArray_On_Occurrence1 = _.filter(filteredArr1, { frequency: "On Occurrence" });

                        if (listArray_On_Occurrence1 && listArray_On_Occurrence1.length > 0) { for (let i in listArray_On_Occurrence) { checkarray1.push(listArray_On_Occurrence1[i]) } }

                    }
                    console.log(checkarray, 'chkchkchk')
                    if (i == ID && res.length > 0) {
                        let filtered1 = _.filter(checkarray, { parentId: ID })
                        let filtered2 = _.filter(checkarray1, { parentId: ID })
                        console.log(filtered1, filtered2, res.length, res, 'filtered2filtered1')
                        for (let i = 0; i < res.length; i++) {
                            console.log(i, 'XcX')
                            let listArray_One_time1 = _.filter(filtered1, { frequency: res[i] });
                            for (let j in listArray_One_time1) {
                                filterXArr.push(listArray_One_time1[j])
                            }
                        }
                        for (let i = 0; i < res.length; i++) {

                            let listArray_One_time1 = _.filter(filtered2, { frequency: res[i] });
                            for (let j in listArray_One_time1) {
                                filterXArr2.push(listArray_One_time1[j])
                            }
                        }
                        // if (listArray_Flexi_Review1 && listArray_Flexi_Review1.length > 0) { for (let i in listArray_Flexi_Review) { checkarray1.push(listArray_Flexi_Review1[i]) } }
                        // let listArray_On_Occurrence1 = _.filter(filteredArr1, { frequency: "On Occurrence" });
                        datavalue = {
                            'index': i,
                            'parent': datas,
                            'common_id': commonId,
                            'child': [filterXArr, filterXArr2],
                            // 'child': filterXArr.concat(filterXArr2),
                            'u_id': u_id
                        }
                    } else {
                        datavalue = {
                            'index': i,
                            'parent': datas,
                            'common_id': commonId,
                            'child': [checkarray, checkarray1],
                            // 'child': checkarray.concat(checkarray1),
                            'u_id': u_id
                        }
                    }
                    console.log(datavalue, "datavalue3333");
                    tempArray1.push(datavalue)
                    parId.push(i);
                    array = tempArray1
                })
                setSearchedUpdatedArrayFilter(true)
            }
            console.log(array, 'arrayXS')
            finalarray.push({
                mainparent: uniqunit[index],
                subchild: array
            })
        })


        Settitledata(finalarray)
        setshowDropDown({})
    }

    console.log(filterValues1, 'filterXvals')



    const handleClickOutside = event => {
        const activeId = Object.keys(showDropDown)[0]
        console.log('dropdowm', showDropDown, activeId)
        const currentActiveForm = document.getElementById('form_' + activeId)
        if (currentActiveForm) {

            if (!currentActiveForm.contains(event.target)) {
                setshowDropDown({})
            }
        }
        // if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // }

        // if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        //     setshowDropDown(false)
        // }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, [showDropDown]);
    const handleCancel = () => {
        setRemarksModal(false)
        dispatch({
            type: REMARKS_MODAL_RESET,
            payload: false
        })
    };
    const previous = () => {
        setTab(false)
        Setcheckboxtitle([])
        Settitledata([])
        // setReassignPayloadId({
        //     u_id: [],
        //     user_type_id: [],
        //     no_of_comp_data: []
        // })
        // setsingleReassignPayload({
        //     u_id: [],
        //     userType: [],
        //     no_of_comp_data: []
        // })
        Setdatacount([])
        // setCheckedRow1([])
        setTemparr([])
        setTemparr1([])
        setCollapseAssignee('0')
        setCollapseAssignee1(false)
        setFilterTaskAcc(false)
        setUpdatedArrayShowmore([])
        setUpdatedArrayShow([]);
        setUpdatedArray([]);
        Setrecalldata({
            reasons: ''
        });

        setTimeout(() => {
            let ID = 0
            if (singlereassignPayload.userType != '' || singlereassignPayload.userType.length != 0) {
                ID = singlereassignPayload.userType
            } else {
                ID = reassignPayloadId.user_type_id
            }


            let chk = [1, 2, 3]

            let b = chk.filter(function (e) {
                return e != ID
            })
            console.log(b, 'itemdata345itemdaaaaata345itemdata345')
            for (var j = 0; j < b.length; j++) {
                console.log(unitName[b[j]], 'gagagagagagagagag')
                let unchkEle = document.getElementsByName(unitName[b[j]])
                if ((document.getElementById(unitName[b[j]]) != null && unitName[b[j]] != undefined) && tableArray.length > 1) {

                    document.getElementById(unitName[b[j]]).disabled = true
                }
                for (let x = 0; x < unchkEle.length; x++) {

                    if (unchkEle[x].type === 'checkbox')
                        unchkEle[x].disabled = true;
                }
            }
        }, 300)



    }
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [searchedValue, setSearchedValue] = useState({
        complianceTask: '',
        statutoryDate: '',
        triggerBeforeDays: '',
        assignee: '',
        concur: '',
        approver: '',
        duedate: '',
        validityDate: ''
    })
    const inputRef1 = useRef([]);
    const inputRef2 = useRef([]);
    const inputRef3 = useRef([]);
    const inputRef4 = useRef([]);
    const inputRef5 = useRef([]);
    const inputRef6 = useRef([]);
    const inputRef7 = useRef([]);
    const inputRef8 = useRef([]);
    const inputRef = useRef([])
    console.log(inputRef, 'inputRef')
    const setFocus = (ref) => {
        setTimeout(() => {
            // inputRef.current[index].focus({
            //     preventScroll: true,
            // });
            ref.focus({
                preventScroll: true,
            })
        });
    };


    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    useEffect(() => {
        console.log(reassignunitdata.reassign_units, 'reassignunitdata.reassign_unitsreassignunitdata.reassign_units')
        let uniqueByUnitId = _.uniqBy(reassignunitdata.reassign_units, (e) => {
            return e.user_type_id
        })

        let parentData = uniqueByUnitId.map((parentItem) => {
            return parentItem.user_type_id
        })

        console.log(parentData, 'parentDataparentData')
        let totalList = []

        parentData.length != 0 ? (
            parentData.map((pmenu, index) => {
                // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
                let entityArray = [];
                let tempArray = [];
                let unit_name = [];
                if (pmenu) {
                    reassignunitdata.reassign_units &&
                        reassignunitdata.reassign_units.map((subMenuList, childIndex) => {
                            console.log(pmenu, subMenuList, 'entityArrayentityArrayentityArray')
                            if (pmenu === subMenuList.user_type_id) {
                                entityArray = {
                                    address: subMenuList.address,
                                    no_of_compliances: subMenuList.no_of_compliances,
                                    postal_code: subMenuList.postal_code,
                                    reassign: subMenuList.reassign,
                                    u_id: subMenuList.u_id,
                                    u_name: subMenuList?.u_name,
                                    user_type_id: subMenuList.user_type_id,
                                    parentId: index,
                                    childId: childIndex,
                                    indexNo: childIndex + 1,
                                };
                                tempArray.push(entityArray);

                                unit_name.push(subMenuList.unit_name);
                            } else {
                                setTableArray([])
                            }


                        })

                    console.log(tempArray, 'tempArraytempArray')

                }

                totalList.push({
                    index: index,
                    parentData: pmenu,
                    child: tempArray,
                })


                setTableArray(totalList)
                console.log(totalList, 'totalListtotalList')

            })
        ) : (

            setTableArray([])
        )

        // console.log()

        // let finalTableAray = parentData.map((ele) => reassignunitdata.reassign_units.filter((sepeaterArrayEle) => {
        //     return ele == sepeaterArrayEle.user_type_id
        // } ))
        // setTableArray(finalTableAray)
        // console.log(finalTableAray,'finalTableAray')
    }, [reassignunitdata.reassign_units])

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    /* Filter Units*/
    useEffect(() => {
        let domainArray = [];
        let tempArray = [];
        if (dataa.d_id) {
            reassigndatalist.units && reassigndatalist.units.length > 0 && reassigndatalist.units.map((unitsublist) => {
                const checkUnitExists = unitsublist.d_ids.includes(parseInt(dataa.d_id));
                if (checkUnitExists) {
                    tempArray = {
                        'unit_id': unitsublist.unit_id,
                        'unit_name': unitsublist.unit_name,
                        'unit_code': unitsublist.unit_code
                    }
                    domainArray.push(tempArray)
                }
            })
            setUnitList(domainArray)
        }
    }, [dataa.d_id])
    /* Filter Units */

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div>
                <Input
                    ref={searchInput}
                    // autoFocus={'on'}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex]?.toString()?.toLowerCase().includes(value?.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    useEffect(() => {
        if (reassigndatatablelists) {
            let arrayTemp = [];
            for (let i in reassigndatatablelists) {
                arrayTemp.push(reassigndatatablelists[i]);
            }
            var merged = [].concat.apply([], arrayTemp);
            setUpdatedArray([...merged]);
            setUpdatedArrayShow([...merged]);
        }
        console.log('reassigndatatablelists')
    }, [reassigndatatablelists]);

    //For Searching handling sepearate State starts
    useEffect(() => {
        if (reassigndatatablelistsnew) {
            let arrayTempNew = [];
            for (let i in reassigndatatablelistsnew) {
                arrayTempNew.push(reassigndatatablelistsnew[i]);
            }
            var mergedNew = [].concat.apply([], arrayTempNew);
            setUpdatedTempArray([...mergedNew]);
            setUpdatedTempArrayShow([...mergedNew])
        }

    }, [reassigndatatablelistsnew])

    useEffect(() => {
        if (showmorelistsnew) {
            let arrayTempShow = [];
            for (let i in showmorelistsnew) {
                arrayTempShow.push(showmorelistsnew[i]);
            }
            var mergedTempShow = [].concat.apply([], arrayTempShow);
            setUpdatedTempArrayShow([...mergedTempShow]);
        }
    }, [showmorelistsnew]);

    useEffect(() => {
        if (updatedTempArrayShow.length > 0) {
            setUpdatedTempArray([...updatedTempArray, ...updatedTempArrayShow]);
        }
    }, [updatedTempArrayShow]);

    //For Searching handling sepearate State ends

    // Showmore Response
    useEffect(() => {
        if (showmorelists) {
            let arrayTemp = [];
            for (let i in showmorelists) {
                arrayTemp.push(showmorelists[i]);
            }
            var merged = [].concat.apply([], arrayTemp);
            setUpdatedArrayShowmore([...merged]);
        }
    }, [showmorelists]);

    // Merged response
    useEffect(() => {
        if (updatedArrayShowmore.length > 0) {
            // setUpdatedArray([]);
            setUpdatedArray([...updatedArray, ...updatedArrayShowmore]);
        }
        console.log('showmorw')
    }, [updatedArrayShowmore]);

    const showmore = () => {
        let entry_counts = reassigndatatablelists?.reassign_compliances?.length
        console.log(entry_counts, 'entry_counts')
        const reassigncomplainceunit = [
            authtoken,
            reassignPayloadId.u_id.length ? {
                "session_token": authtoken,
                "request":
                    [
                        "GetReAssignComplianceForUnits",
                        {
                            "le_id": entityid,
                            "d_id": Number(dataa.d_id),
                            "usr_id": Number(dataa.usr_id),
                            "user_type_id": reassignPayloadId.user_type_id,
                            "u_ids": reassignPayloadId.u_id,
                            "r_count": updatedArray?.length
                        }
                    ]
            } : singlereassignPayload.u_id.length ? {
                "session_token": authtoken,
                "request":
                    [
                        "GetReAssignComplianceForUnits",
                        {
                            "le_id": entityid,
                            "d_id": Number(dataa.d_id),
                            "usr_id": Number(dataa.usr_id),
                            "user_type_id": singlereassignPayload.userType,
                            "u_ids": singlereassignPayload.u_id,
                            "r_count": updatedArray?.length
                        }
                    ]
            } : ''
        ]
        // const reassignshowlist = [
        //     authtoken,
        //     {
        //         "session_token": authtoken,
        //         "request":
        //             [
        //                 "GetReAssignComplianceForUnits",
        //                 {
        //                     "le_id": entityid,
        //                     "d_id": Number(dataa.d_id),
        //                     "usr_id": Number(dataa.usr_id),
        //                     "user_type_id": reassignunitdata && reassignunitdata.reassign_units && reassignunitdata.reassign_units[0] && reassignunitdata.reassign_units[0].user_type_id,
        //                     "u_ids": checkrow,
        //                     "r_count": entires
        //                 }
        //             ]
        //     }
        // ]
        showmoredata({
            payload: reassigncomplainceunit,
            paramid: paramid
        })
    }

    useEffect(() => {
        let tempArr = []
        for (let k in childlist) {
            for (let n in childlist[k]) {
                if (childlist[k][n] && childlist[k][n].length > 0) {
                    tempArr.push(...childlist[k][n])
                    Setdatacount(tempArr)
                }
            }
        }
    }, [childlist])

    useEffect(() => {
        let ss = updateduedate.map((item) => {
            return item.comp_id
        })
        var difference = rowcompid.filter(x => ss.indexOf(x) === -1);
        let finaldata = [...new Set(difference)]
        setDiffId(finaldata)
    }, [updateduedate])


    const ontableChange = (e, i) => {
        let propertyName = e.target.name;
        let inputValue = e.target.value;
        let newArray = trigger;
        for (let j in newArray) {
            if (i == j) {
                newArray[j][propertyName] = inputValue;
                break;
            }
        }
        setTrigger([...newArray]);
    }
    const Onsubmit2 = () => {
        const getuserassigncompliance = [
            authtoken,
            {
                "session_token": authtoken,
                "request":
                    [
                        "GetUserToAssignCompliance",
                        {
                            "d_id": Number(dataa.d_id),
                            "u_ids": reassignPayloadId.u_id.length ? reassignPayloadId.u_id : singlereassignPayload.u_id,
                            "le_id": entityid
                        }
                    ]
            }
        ]
        if (temparr1.length > 0) {
            getusercomplaince({
                payload: getuserassigncompliance,
                paramid: paramid
            })
            setKey("2")
            setTab(true)
            window.scrollTo(0, 0);
            setCollapseAssignee('1')
            setCollapseAssignee1(true)
        }
        else {
            toast.warning("No Compliance selected for Reassign")
        }
    }
    const Onsubmit1 = () => {
        Setdatacount([])
        Settitledata([])
        setTempTitleData([])
        // setFormShow(true);
        setTemp([]);
        // setKey("0");
        setAssigneename([]);
        setfilteredArrayState([]);
        // setUpdatedArray([]);
        setAll("All");

        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterTaskAcc(false);
            setSelectedInput([])

            setReassignPayloadId({
                u_id: [],
                user_type_id: [],
                no_of_comp_data: []
            })
            setsingleReassignPayload({
                u_id: [],
                userType: [],
                no_of_comp_data: []
            })



            setFilterModalVisible(false);
            const reassigntableAPI = [
                authtoken,
                {
                    "session_token": authtoken,

                    "request":
                        [
                            "GetReAssignComplianceUnits",
                            {
                                "le_id": entityid,
                                "d_id": Number(dataa.d_id),
                                "usr_id": Number(dataa.usr_id),
                                "user_type_id": Number(dataa.user_type),
                                "unit_id": dataa.u_ids ? Number(dataa.u_ids) : null,
                            }
                        ]

                }
            ]
            reassignunitlist({
                payload: reassigntableAPI,
                paramid: paramid
            })
            Setnewasigneelist([])
            Setnewasigneelistshow([])
            SetshowTable(true)
            setTemparr1([])
        }
    }
    const [tableShow, setTableShow] = useState(false)
    const OnSubmit = () => {
        Setcheckboxtitle([])

        const reassigncomplainceunit = [
            authtoken,
            reassignPayloadId.u_id.length ? {
                "session_token": authtoken,
                "request":
                    [
                        "GetReAssignComplianceForUnits",
                        {
                            "le_id": entityid,
                            "d_id": Number(dataa.d_id),
                            "usr_id": Number(dataa.usr_id),
                            "user_type_id": reassignPayloadId.user_type_id,
                            "u_ids": reassignPayloadId.u_id, // chkpoint
                            "r_count": 0
                        }
                    ]
            } : singlereassignPayload.u_id.length ? {
                "session_token": authtoken,
                "request":
                    [
                        "GetReAssignComplianceForUnits",
                        {
                            "le_id": entityid,
                            "d_id": Number(dataa.d_id),
                            "usr_id": Number(dataa.usr_id),
                            "user_type_id": singlereassignPayload.userType,
                            "u_ids": singlereassignPayload.u_id,
                            "r_count": 0
                        }
                    ]
            } : ''
        ]
        if (reassignPayloadId.u_id.length || singlereassignPayload.u_id.length) {

            setTableShow(true);
            reassigndatatable({
                payload: reassigncomplainceunit,
                paramid: paramid
            })

            setKey("1")
            setTab(true)
            setFilterTaskAcc(true);
        }
        else {
            toast.warning("Select Atleast one Unit")

        }

    }


    const Onsubmit3 = () => {
        setAddSubmitForm(true)
        if (validator1.current.allValid() && isAuth) {
            let chk = reassignPayloadId.u_id.length > 0 ? unitName[Number(reassignPayloadId.user_type_id)] : unitName[Number(singlereassignPayload.userType)]
            const savereassigncomplianceapi = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request":
                        [
                            "ReassignCompliance",
                            {
                                "le_id": entityid,
                                "r_from": 2,
                                "assignee": chk == 'Assignee' && dataa.employee_name ? Number(dataa.employee_name) : null,
                                "assignee_name": chk == 'Assignee' ? `${dataa.su_num} - ${dataa.su_name}` : null,
                                "concurrence_person": chk == 'Concurrer' && dataa.employee_name ? Number(dataa.employee_name) : null,
                                "approval_person": chk == 'Approver' && dataa.employee_name ? Number(dataa.employee_name) : null,
                                "reassigned_compliance": Assignpayload,
                                "reason": recalldata.reasons ? recalldata.reasons : '',
                                "d_id": Number(dataa.d_id),
                                "d_name": dataa.d_name

                            }
                        ]
                }
            ]
            savereassigncomplaince({
                payload: savereassigncomplianceapi,
                paramid: paramid
            })
            // setTimeout(() => {
            //     window.location.reload(false);
            // }, 4000);
        }
    }

    useEffect(() => {

        if (temparr1.length !== 0) {

            let chk = reassignPayloadId.u_id.length > 0 ? unitName[Number(reassignPayloadId.user_type_id)] : unitName[Number(singlereassignPayload.userType)]
            let tempArray = []

            //     'Approver',
            // 2: 'Concurrer',
            // 3: 'Assignee'

            temparr1 && temparr1.length > 0 && temparr1.map((item, i) => {
                Setunitid(temparr1 && temparr1[i].u_id)
                if (updateduedate.length != 0) {


                    for (let k in updateduedate) {
                        if (temparr1[i].comp_id == updateduedate[k].comp_id) {
                            tempArray.push({
                                "u_id": temparr1[i].u_id,
                                "comp_id": temparr1[i].comp_id,
                                "compliance_name": temparr1[i].compliance_name,
                                "c_h_id": temparr1[i].c_h_id,
                                "d_date": moment(updateduedate[k].date).format("DD-MMM-YYYY"),
                                "o_assignee": chk == 'Assignee' ? temparr1[i].assignee : null,
                                "o_concurrence_person": chk == 'Concurrer' ? temparr1[i].assignee : null,
                                "o_approval_person": temparr1[i].approval_person ? temparr1[i].approval_person : null,
                                "tz_date": temparr1[i].tz_date,
                                "ac_id": temparr1[i].ac_id,
                            })
                        }
                    }
                } else {
                    temparr1 && temparr1.length > 0 && temparr1.map((item, i) => {
                        tempArray.push({
                            "u_id": temparr1[i].u_id,
                            "comp_id": temparr1[i].comp_id,
                            "compliance_name": temparr1[i].compliance_name,
                            "c_h_id": temparr1[i].c_h_id,
                            "d_date": temparr1[i].d_date,
                            "o_assignee": chk == 'Assignee' ? temparr1[i].assignee : null,
                            "o_concurrence_person": chk == 'Concurrer' ? temparr1[i].assignee : null,
                            "o_approval_person": temparr1[i].approval_person ? temparr1[i].approval_person : null,
                            "tz_date": temparr1[i].tz_date,
                            "ac_id": temparr1[i].ac_id,
                        })
                    })
                }
                console.log(tempArray, 'tempArrayjjjjjj');
                if (tempArray != "") {
                    setAssignPayload(tempArray)
                }
            })
        }
    }, [temparr1, updateduedate, diffid])


    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        dispatch({
            type: REMARKS_MODAL_RESET,
            payload: true
        })
        // setRemarksModal(true);
    };
    useEffect(() => {
        if (recallremarks && is_modal_true) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
            if (temp.length == 0) {
                Toaster.error('No Remarks Available')
                // setModaVisible(false)
                // setModalData({
                //     ...modalData,
                //     password: ""
                // })
                // setRemarks("")
                // setAddFormSubmit(false)
                dispatch({
                    type: REMARKS_MODAL_RESET,
                    payload: false
                })
            } else {
                setRemarksModal(true);
            }
        }

    }, [recallremarks])

    useEffect(() => {
        if (isAuth) {
            if (entityid != '') {
                if (legalstate === true) {
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                'GetReassignComplianceFilters', {
                                    "le_id": entityid,
                                }
                            ]
                        }
                    ]
                    if (entityid != "null") {
                        reassignlist({
                            payload: payload,
                            paramid: paramid
                        })
                    }
                    Setlegalstate(false)
                }
            }
        }
    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()

    }, [])
    console.log(reassigndatalist.legal_entity_users, 'reassigndatalist.legal_entity_users')
    useEffect(() => {
        let unit_category = reassigndatalist && reassigndatalist.legal_entity_users.filter((item) => {
            return item.employee_name == dataa.usr_name
        })
        Setunitcategory(unit_category)
    }, [reassigndatalist.legal_entity_users, dataa])

    // useEffect(() => {
    //     if (checkedRow1 && checkedRow1.length > 0) {
    //         let temp_uid = []
    //         for (let i in checkedRow1) {
    //             temp_uid.push(checkedRow1[i].u_id)

    //         }
    //         // Setcheckrow([...new Set(temp_uid)])
    //     }
    //     else {
    //         // Setcheckrow([])
    //     }

    // }, [checkedRow1.length])

    useEffect(() => {
        let temp = [];
        reassigndatalist.legal_entity_users && reassigndatalist.legal_entity_users.map((item) => {


            if (item.user_category_id !== 7) {
                if (userType == "1") {
                    if (item.user_category_id == 5 || item.user_category_id == 6) {
                        if (item.is_active == true) {

                            temp.push({
                                employee_name: item.employee_name,
                                employee_code: item.user_id
                            })
                        }
                    }
                }
                else if (userType == "2") {
                    if (item.user_category_id == 3 || item.user_category_id == 4) {
                        if (item.is_active == true) {
                            temp.push({
                                employee_name: item.employee_name,
                                employee_code: item.user_id
                            })
                        }
                    }
                }
                else if (userType == "3") {
                    if (item.user_category_id == 1 || item.user_category_id == 3 || item.user_category_id == 4) {
                        if (item.is_active == true) {

                            temp.push({
                                employee_name: item.employee_name,
                                employee_code: item.user_id
                            })
                        }
                    }
                }
                else if (userType == "0") {
                    temp.push({
                        employee_name: item.employee_name,
                        employee_code: item.user_id
                    })
                }
            }
        })
        Setcategorylist({
            ...categorylist,
            employee_name: temp,
        },
        )
    }, [userType, dataa])


    const addAllSelectedDataValues = (checked, uniqname, mainData) => {
        console.log(mainData, 'dayjsLocalizer')

        if (1 == 1) {
            let tempArray = temparr;
            var ele = document.getElementsByName(uniqname);



            if (checked === true) {
                setCheckedRow(true);
                for (let i in datacount) {
                    tempArray.push(datacount[i]);
                }
                /*Remove Duplicate*/
                let comp_id = tempArray.map((item) => {
                    return item.comp_id;
                });
                var difference = tempArray.filter(
                    (x) => comp_id.indexOf(x) === -1
                );
                let finaldata = [...new Set(difference)];
                /*Remove Duplicate*/

                setTemparr([...finaldata]);
                for (var i = 0; i < ele.length; i++) {
                    console.log(ele[i], 'ele[i]ele[i]')
                    if (ele[i].type === "checkbox") ele[i].checked = true;
                }
            } else {
                setCheckedRow(false);
                tempArray = [];
                setTemparr([...tempArray]);

                for (var j = 0; j < ele.length; j++) {
                    if (ele[j].type === "checkbox") ele[j].checked = false;
                }
            }
        } else {
            var eleclass = document.getElementsByClassName("allChecked");
            eleclass[0].checked = false;
            Toaster.error("No Compliance Available");
        }
    };

    const selectAllTitleData = (checked, uniqname, mainData, chID) => {
        let inputEle = document.getElementsByName(chID)
        console.log(mainData, 'inputEleinputEle')
        let a = []
        let tempArray = []
        let temp = temparr1
        if (checked == true) {
            for (let i in mainData) {
                temp.push(mainData[i])
            }

            setTemparr1([...new Set(temp)])

            for (let x = 0; x < inputEle.length; x++) {

                if (inputEle[x].type === 'checkbox') {
                    inputEle[x].checked = true;
                }
            }
        }
        else {
            for (let i in mainData) {
                // temp.push(mainData[i])
                for (let j in temp) {
                    if (temp[j] == mainData[i]) {
                        console.log(temp[j], mainData[i], 'temp[j],mainData[i]')
                        temp.splice(j, 1)
                    }
                }
            }
            setTemparr1([...temp])



            for (let x = 0; x < inputEle.length; x++) {

                if (inputEle[x].type === 'checkbox') {
                    inputEle[x].checked = false;
                }

            }
        }
        console.log(tempArray, 'tempArraytempArraytempArraytempArraytempArraytempArraytempArraytempArray')

        // if (checked) {
        //     for (let i in mainData) {
        //         console.log(mainData[i], 'hhhhhhhhhhhhhhhhhhhhhh')
        //         a.push(mainData[i].map((unit_id) => {
        //             return unit_id.u_id
        //         }
        //         ))

        //     }
        //     // if( selectedDataId.length > 0){
        //     //     selectedDataId(Array.prototype.push.apply(selectedDataId,a))
        //     // } else{

        //     // setSelectedDataId(a)
        //     // }
        //     console.log(a, 'Sucess')
        // } else {
        //     console.log(selectedDataId, 'failed')
        // }
    }

    // const addSelectedDataValues = (checked, comp_id, act_name) => {
    //     var ele = document.getElementsByName('allchecked');
    //     let array
    //     let tempArray = temparr1
    //     if (checkedRow === true) {
    //         if (checked === true) {
    //             array = _.find(updatedArray, { comp_id: comp_id });
    //             tempArray.push(array)
    //         } else {
    //             for (let i in tempArray) {
    //                 if (tempArray[i].comp_id === comp_id) {
    //                     tempArray.splice(i, 1)
    //                 }
    //                 if (updatedArray.length !== temparr1.length) {
    //                     setCheckedRow(false)
    //                     for (var j = 0; j < ele.length; j++) {
    //                         if (ele[j].type === 'checkbox')
    //                             ele[j].checked = false;
    //                     }
    //                 }
    //             }
    //         }
    //         setTemparr([...tempArray])
    //     } else {
    //         if (checked === true) {
    //             array = _.find(updatedArray, { comp_id: comp_id });
    //             tempArray.push(array)
    //             if (updatedArray.length === temparr.length) {
    //                 setCheckedRow(true)
    //                 for (var i = 0; i < ele.length; i++) {
    //                     if (ele[i].type === 'checkbox')
    //                         ele[i].checked = true;
    //                 }
    //             }

    //         } else {
    //             for (let i in tempArray) {
    //                 if (tempArray[i].comp_id === comp_id) {
    //                     tempArray.splice(i, 1)
    //                 }
    //             }
    //         }
    //         setTemparr1([...tempArray])
    //     }
    // }

    const addSelectedDataValues = (checked, comp_id, chID, checkedData) => {
        var ele = document.getElementsByName(comp_id);
        var eleclass = document.getElementById(comp_id);

        let array;
        let temp = []
        let secondarray
        // console.log(selectedID, 'arrayarrayarrayarrayarrayarray')
        let count = 0;
        let array2 = [];
        let tempArray = temparr1;

        if (checked === true) {

            tempArray.push(checkedData)
            // temp.push(selectedID)
            for (let i in ele) {
                if (ele[i].checked == true) {
                    count += 1
                    console.log(ele[i].checked, ele, 'ele[i].checkedele[i].checked')
                    if (count == ele.length) {
                        eleclass.checked = true
                    }
                    console.log(count, 'lklklklklklklkkl')
                }

            }
            // setSelectedInput([...temp])

            // array1 = _.filter(updatedArray, { ac_id: chID });
            // console.log(array1, "array1array1");
            // array = _.find(updatedArray, { ac_id: chID });
            // tempArray.push(array);
            // array2 = _.filter(tempArray, { ac_id: chID });
            // if (array2.length === array1[0].child.length) {
            //     eleclass[0].checked = true;
            // }
        } else {
            // let x=  temparr1.find((e) => {
            //     if( e === checkedData) {

            //     }
            // })
            for (let i in ele) {
                if (ele[i].checked == false) {
                    // console.log(ele[i].checked, ele,'ele[i].checkedele[i].checked')
                    // if(ele.length >0){
                    eleclass.checked = false
                    // }
                    // console.log(count,'lklklklklklklkkl')
                }

            }
            for (let i in temparr1) {
                if (temparr1[i] == checkedData) {
                    temparr1.splice(i, 1)
                }
            }
            // console.log(x,'vvvvvvvvvvvvvvvvitemdataitemdata')
            for (let i in tempArray) {
                console.log(tempArray[i], 'uhtempArray')
                if (tempArray[i].ac_id === chID) {
                    tempArray.splice(i, 1);
                }
                // if (updatedArray.length !== temparr.length) {
                //     setCheckedRow(false);
                //     eleclass[0].checked = false;
                // }
            }
        }
        console.log(tempArray, 'tempArraytempArraynn')
        setTemparr1([...tempArray]);

        // if (checked === true) {
        //     // array = _.find(updatedArray, {
        //     //     comp_id: comp_id,
        //     // });
        //     // console.log(array,'arrayarrsayarray')
        //     secondarray = updatedArray.filter((item) => {
        //         return item.comp_id == comp_id && item.c_h_id == chID
        //     })
        //     if(temp.length == 0){
        //         temp = secondarray
        //     }else{
        //         temp.push(Array.prototype.push.apply(temp,secondarray))
        //     }

        //     console.log(temp,'lllllllllllllllllllllllllllllll')
        //     array2.push(...array2,secondarray[0])
        //     setPartiSelect([...partiSelect,secondarray])

        // } else {
        //     // for (let i in tempArray) {
        //     //     if (tempArray[i].comp_id === comp_id) {
        //     //         tempArray.splice(i, 1);
        //     //     }
        //     // }
        //     // eleclass[0].checked = false;
        // }
        // setTemparr([...tempArray]);


        console.log(array2, 'arrayarrhhhhay')
    };






    // Checkbox for Unit table
    // const addSelectedDataValuesunit = (checked, u_id, comp_id) => {
    //     var ele = document.getElementsByName('allchecked');
    //     var ele_id = document.getElementById(u_id)
    //     let u_id_data = comp_id.u_id
    //     let no_of_comp_data = comp_id.no_of_compliances
    //     console.log(ele, 'comp_idcomp_idcomp_idcomp_idcssssssomp_id')
    //     let array
    //     let tempArray = checkedRow1
    //     let filterData = tableArray.map((items) => items.child.filter((ele) => ele.user_type_id == comp_id))
    //     console.log(reassignunitdata.reassign_units, 'filterDatafilterData')
    //     let tempcheck = checkBoxTrue
    //     if (checkedRow === true) {
    //         if (checked === true) {
    //             console.log("11111");
    //             array = _.find(reassignunitdata.reassign_units, { u_id: u_id });
    //             tempcheck.push(u_id)
    //             tempArray.push(array)
    //             console.log(array, tempcheck, "array123");
    //         } else {
    //             console.log("2222");

    //             for (let i in tempArray) {
    //                 if (tempArray[i].u_id === u_id) {
    //                     tempArray.splice(i, 1)
    //                 }
    //                 if (tempcheck[i] === u_id) {
    //                     tempcheck.splice(i, 1)
    //                 }
    //                 if (reassignunitdata.reassign_units !== checkedRow1.length) {
    //                     setCheckedRow(false)
    //                     for (var j = 0; j < ele.length; j++) {
    //                         if (ele[j].type === 'checkbox')
    //                             ele[j].checked = false;
    //                     }
    //                 }
    //             }
    //         }
    //         setcheckBoxTrue([...tempcheck])
    //         setCheckedRow1([...tempArray])
    //     }
    //     else {
    //         console.log("33333333");

    //         if (checked === true) {
    //             // console.log(comp_id.u_id , )
    //             let temp_chk = singlereassignPayload?.u_id?.some((e) => e == comp_id.u_id)
    //             console.log(temp_chk, 'asasasasasasas')
    //             temp_chk === false && setsingleReassignPayload({
    //                 u_id: [...singlereassignPayload.u_id, u_id_data],
    //                 userType: comp_id.user_type_id,
    //                 no_of_comp_data: [...no_of_comp_data.reduce((partialSum, a) => partialSum + a, 0)]
    //             })
    //             array = _.find(reassignunitdata.reassign_units, { u_id: u_id });
    //             tempArray.push(array)
    //             tempcheck.push(u_id)
    //             if (reassignunitdata.reassign_units.length === checkedRow1.length) {
    //                 setCheckedRow(true)
    //                 for (var i = 0; i < ele.length; i++) {
    //                     if (ele[i].type === 'checkbox')
    //                         ele[i].checked = true;
    //                 }
    //             }
    //             if (reassignunitdata.reassign_units.length === checkBoxTrue.length) {
    //                 setCheckedRow(true)
    //                 for (var i = 0; i < ele.length; i++) {
    //                     if (ele[i].type === 'checkbox')
    //                         ele[i].checked = true;
    //                 }
    //             }
    //         } else {
    //             console.log("4444");
    //             if (singlereassignPayload.u_id.length) {
    //                 let x = singlereassignPayload.u_id
    //                 let y = singlereassignPayload.no_of_comp_data
    //                 const indexed = singlereassignPayload.no_of_comp_data.indexOf(no_of_comp_data)
    //                 const index = singlereassignPayload.u_id.indexOf(u_id_data);

    //                 const xy = x.splice(index, 1);
    //                 const yx = y.splice(indexed, 1);
    //                 console.log(singlereassignPayload, 'xyxyxy')

    //             } else {
    //                 setsingleReassignPayload({
    //                     u_id: [],
    //                     userType: [],
    //                     no_of_comp_data: []
    //                 })
    //             }

    //             for (let i in tempArray) {
    //                 // console.log(tempcheck[i].u_id == u_id,tempcheck,tempcheck[i],u_id,'llllllllllll');
    //                 if (tempArray[i].u_id === u_id) {
    //                     tempArray.splice(i, 1)
    //                 }
    //                 if (tempcheck[i] === u_id) {
    //                     tempcheck.splice(i, 1)
    //                 }
    //             }
    //         }
    //         setCheckedRow1([...tempArray])
    //         setcheckBoxTrue([...tempcheck])

    //     }
    // }
    const checkedData = (checked, u_id, TotDatas, comp_id, ID, selectedID) => {
        var ele = document.getElementById(unitName[ID]);
        let inputEle = document.getElementsByName(unitName[ID])
        let chk = [1, 2, 3]
        let count = 0;
        console.log(u_id, 'oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo')
        // check


        var ele_id = document.getElementById(u_id)
        let u_id_data = comp_id.u_id
        let no_of_comp_data = comp_id.no_of_compliances
        console.log(comp_id, 'comp_idcomp_idcomp_idcomp_idcomp_id')
        let array
        let tempArray = checkedRow1
        let filterData = tableArray.map((items) => items.child.filter((ele) => ele.user_type_id == comp_id))
        console.log(filterData, 'filterDatafilterData')
        let tempcheck = checkBoxTrue
        let tempSelected = selectedInput
        if (checked === true) {
            // setSelectedInput([...selectedInput, selectedID])
            tempSelected.push(selectedID)
            for (let i in inputEle) {
                if (inputEle[i].checked == true) {
                    count += 1
                }
            }
            if (count == inputEle.length) {
                tempSelected.push(unitName[ID])
                ele.checked = true
                // setSelectedInput([...selectedInput, unitName[ID]])

            }
            let b = chk.filter(function (e) {
                return e != ID
            })
            console.log(b, 'itemdata345itemdata345itemdata345')
            for (var j = 0; j < b.length; j++) {
                console.log(unitName[b[j]], 'gagagagagagagagag')
                let unchkEle = document.getElementsByName(unitName[b[j]])
                if ((document.getElementById(unitName[b[j]]) != null && unitName[b[j]] != undefined) && tableArray.length > 1) {

                    document.getElementById(unitName[b[j]]).disabled = true
                }
                for (let x = 0; x < unchkEle.length; x++) {

                    if (unchkEle[x].type === 'checkbox')
                        unchkEle[x].disabled = true;
                }
            }
            // console.log(comp_id.u_id , )
            let temp_chk = singlereassignPayload?.u_id?.some((e) => e == comp_id.u_id)
            console.log(temp_chk, 'asasasasasasas')
            setsingleReassignPayload({
                u_id: [...singlereassignPayload.u_id, u_id_data],
                userType: comp_id.user_type_id,
                no_of_comp_data: [(singlereassignPayload.no_of_comp_data && Number(singlereassignPayload.no_of_comp_data)) + Number(no_of_comp_data)]
            })



        } else {

            for (let i in tempSelected) {
                // setSelectedInput([...selectedInput.splice])
                if (tempSelected[i] == selectedID) {
                    tempSelected.splice(i, 1)
                }
            }

            if (tempSelected.length > 0) {
                for (let r = 1; r <= chk.length; r++) {

                    if (tempSelected.includes(unitName[r])) {
                        tempSelected.splice(tempSelected.indexOf(unitName[r]), 1)

                    }
                }
            }


            let b = chk.filter(function (e) {
                return e != ID
            })
            for (let i in inputEle) {
                if (inputEle[i].checked == false) {
                    ele.checked = false
                }
            }
            console.log(tempSelected, 'itemdata345itemdata345itemdata345')
            if (tempSelected.length == 0) {
                for (var j = 0; j < b.length; j++) {
                    console.log(unitName[b[j] - 1], 'gagagagagagagagag')
                    let unchkEle = document.getElementsByName(unitName[b[j]])
                    if (document.getElementById(unitName[b[j]]) != null && unitName[b[j]] != undefined && tableArray.length > 1) {

                        document.getElementById(unitName[b[j]]).disabled = false
                    }
                    console.log(unchkEle, 'unchkEleunchkEle')
                    for (let x = 0; x < unchkEle.length; x++) {

                        if (unchkEle[x].type === 'checkbox')
                            unchkEle[x].disabled = false;
                    }
                }

            }
            console.log(singlereassignPayload.u_id.length, "4444");
            if (reassignPayloadId.u_id.length > 0) {
                setsingleReassignPayload({
                    userType: reassignPayloadId.user_type_id,
                    u_id: totTempArr1.filter((e) => e != u_id),
                    no_of_comp_data: reassignPayloadId.no_of_comp_data - Number(no_of_comp_data)
                })

                setReassignPayloadId({
                    user_type_id: [],
                    u_id: [],
                    no_of_comp_data: []
                })
            } else if (singlereassignPayload.u_id.length > 1) {
                let temp = singlereassignPayload.u_id
                let tempTot = singlereassignPayload.no_of_comp_data
                let x = singlereassignPayload
                let y = singlereassignPayload
                console.log(temp, tempTot, 'temp,tempTot')
                for (let i in temp) {
                    if (temp[i] === comp_id.u_id) {
                        temp.splice(i, 1)
                    }
                }
                for (let i in tempTot) {
                    if (tempTot[i] === comp_id.no_of_compliances) {
                        tempTot.splice(i, 1)
                    }
                }
                setsingleReassignPayload({
                    ...singlereassignPayload,
                    u_id: temp,
                    no_of_comp_data: singlereassignPayload.no_of_comp_data > Number(no_of_comp_data) ? singlereassignPayload.no_of_comp_data - Number(no_of_comp_data) : Number(no_of_comp_data) - singlereassignPayload.no_of_comp_data
                })
            } else {
                setsingleReassignPayload({
                    userType: [],
                    u_id: [],
                    no_of_comp_data: []
                })
            }







        }

        setSelectedInput(tempSelected)

        setCheckedRow1([...tempArray])
        setcheckBoxTrue([...tempcheck])
    }
    const addAllSelectedDataValuesunit = (checked, u_id, specific_userid, mainData) => {
        console.log(checked, u_id, specific_userid, mainData, 'checked, u_id, specific_userid, mainDatachecked, u_id, specific_userid, mainData')
        let allArray = [u_id];
        var ele = document.getElementsByName(u_id);

        console.log(ele, 'table Array')
        let unitIDNAme = ['Approver',
            'Concurrer',
            'Assignee']

        if (checked == true) {
            let b = unitIDNAme.filter(function (e) {
                return e != u_id
            })
            for (let x = 0; x < mainData.length; x++) {
                allArray.push(u_id + mainData[x].childId)
            }
            let vs = document.getElementsByTagName('input')
            for (var j = 0; j < vs.length; j++) {
                if (vs[j].type == 'checkbox') {
                    console.log(vs[j], 'vs[j]')
                    for (var h = 0; h < vs.length; h++) {
                        if (vs[j].id.includes(b[h])) {
                            vs[j].disabled = true
                        }
                    }
                }
            }
            console.log(b, 'itemdata345itemdata345itemdata345')
            for (var j = 0; j < b.length; j++) {
                let unchkEle = document.getElementsByName(b[j])
                console.log(unchkEle, 'unchkEleunchkEle')
                for (let x = 0; x < unchkEle.length; x++) {

                    if (unchkEle[x].type === 'checkbox')
                        unchkEle[x].disabled = true;
                }
            }
        } else {
            let b = unitIDNAme.filter(function (e) {
                return e != u_id
            })
            allArray = []
            setsingleReassignPayload({
                userType: [],
                no_of_comp_data: [],
                u_id: []
            })
            console.log(b, 'itemdata345itemdata345itemdata345')
            for (var j = 0; j < b.length; j++) {
                console.log(unitIDNAme[b[j]], 'gagagagagagagagag')
                let unchkEle = document.getElementsByName(b[j])
                console.log(unchkEle, 'unchkEleunchkEle')
                let vs = document.getElementsByTagName('input')
                for (var j = 0; j < vs.length; j++) {
                    if (vs[j].type == 'checkbox') {
                        console.log(vs[j], 'vs[j]')
                        for (var h = 0; h < vs.length; h++) {
                            if (vs[j].id.includes(b[h])) {
                                vs[j].disabled = false
                            }
                        }
                    }
                }
                for (let x = 0; x < unchkEle.length; x++) {

                    if (unchkEle[x].type === 'checkbox')
                        unchkEle[x].disabled = false;
                }
            }
        }

        setSelectedInput(allArray)

        let a = _.filter(unitName, (e) => {
            return e !== u_id
        })
        let tempNode
        for (let i in a) {
            tempNode = document.getElementsByName(a[i])
        }
        let payload_u_id = mainData.map((e) => {
            return e.u_id
        })
        let totalComp = mainData.map((e) => {
            return e.no_of_compliances
        })
        setTotTempArr1(payload_u_id)

        if (checked === true) {
            // payload_u_id(payload_u_id)
            setReassignPayloadId({
                u_id: payload_u_id,
                user_type_id: specific_userid,
                no_of_comp_data: totalComp.reduce((partialSum, a) => partialSum + a, 0)
            })
            setsingleReassignPayload({
                u_id: [],
                userType: [],
                no_of_comp_data: []
            })
            setCheckedRow(true)
            let temp1 = []
            let tempcheck = []
            for (let i in mainData) {
                temp1.push(mainData[i])
                tempcheck.push(mainData[i].u_id)
            }
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
            // setSelectedRows(temp1)
            setCheckedRow1(temp1)
            setcheckBoxTrue(tempcheck)
        } else {
            // for (let i in payload_u_id) {
            //     const index = reassignPayloadId?.indexOf(payload_u_id[i]);

            //     const x = reassignPayloadId.u_id.splice(index, 1);
            // }
            setReassignPayloadId({
                u_id: [],
                user_type_id: [],
                no_of_comp_data: []
            })
            setCheckedRow(false)
            // setSelectedRows([])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
            setCheckedRow1([])
            setcheckBoxTrue([])
        }
    };

    // const rowSelection = {
    //     renderCell: (checked, record, index, originNode) => {
    //         console.log(record.u_id, checkBoxTrue, checkBoxTrue.indexOf(record.u_id) > -1, 'record00');
    //         return (
    //             <Fragment>
    //                 <input type="checkbox"
    //                     name="checked"
    //                     id={record.u_id}
    //                     onClick={(e) => {
    //                         let checked = e.target.checked;
    //                         console.log(record, 'recordrecord')
    //                         addSelectedDataValuesunit(
    //                             checked,
    //                             record.u_id,
    //                             // record.childId
    //                         );
    //                         setTriggerstate(false)
    //                     }}
    //                     checked={checkBoxTrue.length ? checkBoxTrue.indexOf(record.u_id) > -1 : false}
    //                 />
    //             </Fragment>
    //         )
    //     }

    // }





    useEffect(() => {
        let temp = checkboxtitle
        if (titledata) {
            for (let i in titledata) {
                if (titledata[i] !== undefined) {
                    temp.push(titledata[i])
                }
            }
            Setcheckboxtitle([...temp]);
        }
    }, [titledata])

    useEffect(() => {
        if (checkboxtitle) {
            let tempARR = []
            for (let i in checkboxtitle) {
                tempARR.push(checkboxtitle[i].child)
            }
            Setchildlist(tempARR)
        }
    }, [checkboxtitle])
    console.log(reassignusercompliance.assign_users, 'reassignusercompliance.assign_users')
    useEffect(() => {
        let temp = [];
        let temp1 = [];
        let temp2 = [];
        let is_check = reassignPayloadId.u_id.length > 0 ? unitName[Number(reassignPayloadId.user_type_id)] : unitName[Number(singlereassignPayload.userType)]

        reassignusercompliance.assign_users && reassignusercompliance.assign_users.map((item) => {
            console.log(is_check == 'Approver' ? item.is_approver : item.is_assignee, 'nsnssnsnsnsnsnnsnsnsn')
            if (is_check == 'Approver' ? item.is_approver === true : item.is_assignee === true) {
                if (Number(item.s_u_id) === Number(dataa.s_u_name)) {
                    temp.push(`${item.emp_code} - ${item.emp_name}`)
                }
            }
            if (is_check == 'Approver' ? item.is_approver === true : item.is_assignee === true) {
                temp1.push(`${item.emp_code} - ${item.emp_name}`)
                temp2.push(item.emp_code)
            }
            if (temp.length === 0) {
                Setallassigneelist([...temp1])
            }
            else {
                Setallassigneelist([])
            }
            Setassigneelist([...temp])
        })
    }, [dataa])

    useEffect(() => {
        assigneelist && assigneelist.length && assigneelist.map((item) => {
            if (item === dataa.usr_name[0]) {
                let array = _.remove(assigneelist, (item) => item === dataa.usr_name[0])
            }
            Setfinalassignee([...assigneelist])
        })
    }, [assigneelist])

    useEffect(() => {
        if (allassigneelist.length !== 0) {
            allassigneelist && allassigneelist.length && allassigneelist.map((item) => {
                if (item === dataa.usr_name[0]) {
                    let array = _.remove(allassigneelist, (item) => item === dataa.usr_name[0])
                }
                Setfinalallassignee([...allassigneelist])
            })
        }
        else {
            Setfinalallassignee([])
        }
    }, [allassigneelist])

    useEffect(() => {
        if (finalallassignee.length !== 0) {
            let temp = []
            finalallassignee && finalallassignee.length > 0 && finalallassignee.map((item) => {
                const myArray = item.split(" ");
                temp.push(myArray[0])
            })
            Setallassigneecode(temp)
        }
    }, [finalallassignee])
    useEffect(() => {
        if (reassignusercompliance.assign_users && reassignusercompliance.assign_users.length > 0) {

            if (unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 5 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 6) {


                let array = _.filter(reassignusercompliance.assign_users, { is_assignee: true });
                Setnewasigneelist(array)
            }
            else if (unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 1 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4) {
                let array = _.filter(reassignusercompliance.assign_users, { is_approver: true });
                Setnewasigneelist(array)
            }
        }
    }, [reassignusercompliance.assign_users])


    useEffect(() => {
        let temp = 0
        let temparray = 0
        for (let i in checkedRow1) {
            temp = checkedRow1[i].no_of_compliances
            temparray = Number(temparray) + Number(temp)
        }
        SetEntriescount(temparray)

    }, [checkedRow1])


    const columns = [
        {
            title: "Unit",
            dataIndex: false,
            key: false,
            align: 'left',
            render: (record) => {
                console.log(record, "recordrtdrtgfgv")
                return (
                    <div className='row'>
                        <span >
                            <Tooltip title={`${record.address},${record.postal_code}`}>
                                <InfoCircleOutlined style={{ color: 'blue' }} />
                            </Tooltip>
                            <span className='ms-0' title={record?.u_name}>  &nbsp;{record?.u_name}</span>

                        </span>
                    </div>
                )
            },
            width: '65%',
        },
        {
            title: "No. of Compliance Tasks",
            align: 'center',
            render: (record) => record.no_of_compliances,
            sortable: false,
        },
        {
            title: "Reassign",
            dataIndex: false,
            key: false,
            align: 'center',
            width: '10%',
            render: (record) => {
                if (record.reassign) {
                    const obj = {
                        children: <div className='row'>
                            <span >
                                <Button type="primary" shape="round" width="100%" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                    size='default' onClick={OnSubmit}>
                                    <RetweetOutlined />Reassign
                                </Button>
                            </span>
                        </div>,
                        props: {}
                    };
                    obj.props.rowSpan = reassignunitdata.reassign_units.length
                    setPagesample("2")
                    return (obj)
                } else return null

            },
        }
        // : {title: "Reassign"}
    ]

    const columns2 = [
        {
            title: "S.No.",
            // render: (row,record) => record.r_id,
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            },
            sortable: true,
            reorder: true,
            dataIndex: false,
            width: '30px',
            align: 'center',

        },
        {
            title: "Description",
            render: (row, record) => <a onClick={() => {
                Setrecalldata({ ...recalldata, reasons: record.r_description })
                setRemarksModal(false)
                dispatch({
                    type: REMARKS_MODAL_RESET,
                    payload: false
                })
            }}>{record.r_description}</a>,
            sortable: true,
            ellipsis: true,
            width: '200px',
            reorder: true,

        }
    ]

    useEffect(() => {
        if (dataa.legalentity == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [dataa.legalentity])

    useEffect(() => {
        if (updatedArray && updatedArray.length > 0) {
            console.log(updatedArray, "updatedArray7788");
            let uniqueObjArray = [
                ...new Map(
                    updatedArray &&
                    updatedArray.length > 0 &&
                    updatedArray.map((item) => [item["u_id"], item])
                ).values(),
            ];
            console.log(uniqueObjArray, "uniqueObjArray");
            const uniqueIds =
                uniqueObjArray &&
                uniqueObjArray.map((item) => {
                    return item.u_id;
                });
            let tempArr = [];
            for (let i in uniqueIds) {
                let filteredArr = _.filter(
                    updatedArray && updatedArray.length > 0 && updatedArray,
                    { u_id: uniqueIds[i] }
                );
                tempArr.push(filteredArr);
                console.log(tempArr, "tempArr123");
            }
            setfilteredArray([tempArr]);
        } else {
            setfilteredArray([]);
        }
    }, [updatedArray]);

    useEffect(() => {
        if (filterArray.length > 0) {
            let temp = [];
            for (let i in filterArray) {
                temp = filterArray[i];
            }
            setfilteredArrayState([...temp]);
        }
    }, [filterArray]);


    useEffect(() => {
        let array;
        let commonId
        let finalarray = [];
        let datavalue = [];
        let datas = [];
        let parId = []
        let u_id
        let filteredArr;
        let filteredArr1;
        let split_units = []
        let sample
        let uniqunit = _.uniqBy(updatedArray && updatedArray.length > 0 && updatedArray, function (e) {
            return e.u_id;
        });
        console.log(uniqunit, 'uniqunit');
        uniqunit.map((items) => {
            sample = updatedArray.filter((item) => {
                return items.u_id === item.u_id
            })
            split_units.push(sample)
        })
        console.log(split_units, 'temptemp');
        split_units.map((itemss, index) => {
            let sampletemp = []
            let tempArray1 = []
            console.log(itemss, 'temptemp123456');
            if (itemss) {
                const ab = itemss.map(function (item) {
                    if ((sampletemp.indexOf(item.act_name) < 0))
                        sampletemp.push(item.act_name)
                })
                console.log(sampletemp, 'aaaaaaaa')
                sampletemp.map((data, i) => {
                    datas = data
                    let temp = [];
                    let entityArray = []
                    let tempArray = []
                    let checkarray = []
                    let checkarray1 = []
                    console.log(datas, 'datas')

                    if (datas) {
                        itemss.map((child) => {
                            console.log(i, 'ddddddddd')
                            if (datas === child.act_name) {
                                entityArray = {
                                    'ac_id': child.ac_id,
                                    'act_name': child.act_name,
                                    'approval_person': child.approval_person,
                                    'approver_name': child.approver_name,
                                    'assignee': child.assignee,
                                    'assignee_name': child.assignee_name,
                                    'c_h_id': child.c_h_id,
                                    'comp_id': child.comp_id,
                                    'compfielaw_url': child.compfielaw_url,
                                    'compliance_description': child.compliance_description,
                                    'compliance_name': child.compliance_name,
                                    'concurrence_person': child.concurrence_person,
                                    'concurrer_name': child.concurrer_name,
                                    'd_date': child.d_date,
                                    'extend_due_date': child.extend_due_date,
                                    'f_id': child.f_id,
                                    'frequency': child.frequency,
                                    'summary': child.summary,
                                    'task_type': child.task_type,
                                    'trigger_before_days': child.trigger_before_days && child.trigger_before_days?.toString(),
                                    'tz_date': child.tz_date,
                                    'tz_name': child.tz_name,
                                    'u_id': child.u_id,
                                    'u_name': child?.u_name,
                                    'v_date': child.v_date,
                                    'parentId': i,
                                    'common_id': child.u_id && child.u_id?.toString() + i
                                }
                                commonId = child.u_id && child.u_id?.toString() + i
                                u_id = child.u_id
                                tempArray.push(entityArray)
                                console.log(tempArray, 'entityArray')
                                filteredArr = tempArray.filter((item) => {
                                    return item.task_type === 'Current Task'
                                })
                                filteredArr1 = tempArray.filter((item) => {
                                    return item.task_type === 'Upcoming Task'
                                })
                                console.log(filteredArr1, "filteredArr111");

                            }

                        })
                        let listArray_One_time = _.filter(filteredArr, { frequency: "One Time" });
                        if (listArray_One_time && listArray_One_time.length > 0) {
                            for (let i in listArray_One_time) {
                                checkarray.push(listArray_One_time[i])
                            }
                        }
                        let listArray_periodical = _.filter(filteredArr, { frequency: "Periodical" });
                        if (listArray_periodical && listArray_periodical.length > 0) { for (let i in listArray_periodical) { checkarray.push(listArray_periodical[i]) } }
                        let listArray_Review = _.filter(filteredArr, { frequency: "Review" });
                        if (listArray_Review && listArray_Review.length > 0) { for (let i in listArray_Review) { checkarray.push(listArray_Review[i]) } }
                        let listArray_Flexi_Review = _.filter(filteredArr, { frequency: "Flexi Review" });
                        if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) { for (let i in listArray_Flexi_Review) { checkarray.push(listArray_Flexi_Review[i]) } }
                        let listArray_On_Occurrence = _.filter(filteredArr, { frequency: "On Occurrence" });
                        if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) { for (let i in listArray_On_Occurrence) { checkarray.push(listArray_On_Occurrence[i]) } }
                        console.log(checkarray, "checkarray456456");

                        let listArray_One_time1 = _.filter(filteredArr1, { frequency: "One Time" });
                        if (listArray_One_time1 && listArray_One_time1.length > 0) {
                            for (let i in listArray_One_time1) {
                                checkarray1.push(listArray_One_time1[i])
                            }
                            console.log(checkarray1, "checkarray4564511116");


                        }
                        let listArray_periodical1 = _.filter(filteredArr1, { frequency: "Periodical" });
                        if (listArray_periodical1 && listArray_periodical1.length > 0) {
                            for (let i in listArray_periodical1) {
                                checkarray1.push(listArray_periodical1[i])
                                console.log(i, 'listArray_periodical1[i]')
                            }
                        }

                        console.log(listArray_periodical1, "listArray_periodical1");
                        let listArray_Review1 = _.filter(filteredArr1, { frequency: "Review" });

                        console.log(checkarray1, "checkarray4564511116");
                        if (listArray_Review1 && listArray_Review1.length > 0) { for (let i in listArray_Review1) { checkarray1.push(listArray_Review1[i]) } }
                        let listArray_Flexi_Review1 = _.filter(filteredArr1, { frequency: "Flexi Review" });

                        console.log(checkarray1, "checkarray4564511116");
                        if (listArray_Flexi_Review1 && listArray_Flexi_Review1.length > 0) { for (let i in listArray_Flexi_Review1) { checkarray1.push(listArray_Flexi_Review1[i]) } }
                        let listArray_On_Occurrence1 = _.filter(filteredArr1, { frequency: "On Occurrence" });

                        console.log(checkarray1, "checkarray4564511116");
                        if (listArray_On_Occurrence1 && listArray_On_Occurrence1.length > 0) { for (let i in listArray_On_Occurrence1) { checkarray1.push(listArray_On_Occurrence1[i]) } }
                        console.log(checkarray1, "checkarray4564511116");

                    }
                    datavalue = {
                        'index': i,
                        'common_id': commonId,
                        'parent': datas,
                        // 'child': [checkarray, checkarray1],
                        'child': checkarray.concat(checkarray1),
                        'u_id': u_id
                    }
                    console.log(datavalue, "datavalue3333");
                    // for (let i in datavalue) {
                    //     if (datavalue[i] !== undefined) {

                    //     }
                    //     console.log(finalarray,"finalarray123");
                    // }
                    tempArray1.push(datavalue)
                    // console.log(datavalue, "datavalue3333555555");
                    parId.push(i);
                    array = tempArray1
                    // sampletemp.push(finalarray)
                    // console.log(sampletemp, 'finalarrayfinalarrayfinalarray');
                })

                // datavalue = {
                //     'index': i,
                //     'parent': datas,
                //     'child': [checkarray, checkarray1],
                // }
                console.log(tempArray1, 'tempArray1tempArray1tempArray1')
                // Settitledata(tempArray1)

            }
            finalarray.push({
                mainparent: uniqunit[index],
                subchild: array
            })
            console.log(finalarray, 'finalarrayfinalarray1111')

        })
        Settitledata(finalarray)
        // setTempTitleData(finalarray)
        console.log(finalarray, 'finalarrayfinalarray')

    }, [updatedArray])


    useEffect(() => {
        let array;
        let commonId
        let finalarray = [];
        let datavalue = [];
        let datas = [];
        let parId = []
        let u_id
        let filteredArr;
        let filteredArr1;
        let split_units = []
        let sample
        let uniqunit = _.uniqBy(updatedArray && updatedArray.length > 0 && updatedArray, function (e) {
            return e.u_id;
        });
        console.log(uniqunit, 'uniqunit');
        uniqunit.map((items) => {
            sample = updatedArray.filter((item) => {
                return items.u_id === item.u_id
            })
            split_units.push(sample)
        })
        console.log(split_units, 'temptemp');
        split_units.map((itemss, index) => {
            let sampletemp = []
            let tempArray1 = []
            console.log(itemss, 'temptemp123456');
            if (itemss) {
                const ab = itemss.map(function (item) {
                    if ((sampletemp.indexOf(item.act_name) < 0))
                        sampletemp.push(item.act_name)
                })
                console.log(sampletemp, 'aaaaaaaa')
                sampletemp.map((data, i) => {
                    datas = data
                    let temp = [];
                    let entityArray = []
                    let tempArray = []
                    let checkarray = []
                    let checkarray1 = []
                    console.log(datas, 'datas')

                    if (datas) {
                        itemss.map((child) => {
                            console.log(i, 'ddddddddd')
                            if (datas === child.act_name) {
                                entityArray = {
                                    'ac_id': child.ac_id,
                                    'act_name': child.act_name,
                                    'approval_person': child.approval_person,
                                    'approver_name': child.approver_name,
                                    'assignee': child.assignee,
                                    'assignee_name': child.assignee_name,
                                    'c_h_id': child.c_h_id,
                                    'comp_id': child.comp_id,
                                    'compfielaw_url': child.compfielaw_url,
                                    'compliance_description': child.compliance_description,
                                    'compliance_name': child.compliance_name,
                                    'concurrence_person': child.concurrence_person,
                                    'concurrer_name': child.concurrer_name,
                                    'd_date': child.d_date,
                                    'extend_due_date': child.extend_due_date,
                                    'f_id': child.f_id,
                                    'frequency': child.frequency,
                                    'summary': child.summary,
                                    'task_type': child.task_type,
                                    'trigger_before_days': child.trigger_before_days && child.trigger_before_days?.toString(),
                                    'tz_date': child.tz_date,
                                    'tz_name': child.tz_name,
                                    'u_id': child.u_id,
                                    'u_name': child?.u_name,
                                    'v_date': child.v_date,
                                    'parentId': i,
                                    'common_id': child.u_id && child.u_id?.toString() + i
                                }
                                commonId = child.u_id && child.u_id?.toString() + i
                                u_id = child.u_id
                                tempArray.push(entityArray)
                                console.log(tempArray, 'entityArray')
                                filteredArr = tempArray.filter((item) => {
                                    return item.task_type === 'Current Task'
                                })
                                filteredArr1 = tempArray.filter((item) => {
                                    return item.task_type === 'Upcoming Task'
                                })
                                console.log(filteredArr1, "filteredArr111");

                            }

                        })
                        let listArray_One_time = _.filter(filteredArr, { frequency: "One Time" });
                        if (listArray_One_time && listArray_One_time.length > 0) {
                            for (let i in listArray_One_time) {
                                checkarray.push(listArray_One_time[i])
                            }
                        }
                        let listArray_periodical = _.filter(filteredArr, { frequency: "Periodical" });
                        if (listArray_periodical && listArray_periodical.length > 0) { for (let i in listArray_periodical) { checkarray.push(listArray_periodical[i]) } }
                        let listArray_Review = _.filter(filteredArr, { frequency: "Review" });
                        if (listArray_Review && listArray_Review.length > 0) { for (let i in listArray_Review) { checkarray.push(listArray_Review[i]) } }
                        let listArray_Flexi_Review = _.filter(filteredArr, { frequency: "Flexi Review" });
                        if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) { for (let i in listArray_Flexi_Review) { checkarray.push(listArray_Flexi_Review[i]) } }
                        let listArray_On_Occurrence = _.filter(filteredArr, { frequency: "On Occurrence" });
                        if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) { for (let i in listArray_On_Occurrence) { checkarray.push(listArray_On_Occurrence[i]) } }
                        console.log(checkarray, "checkarray456456");

                        let listArray_One_time1 = _.filter(filteredArr1, { frequency: "One Time" });
                        if (listArray_One_time1 && listArray_One_time1.length > 0) {
                            for (let i in listArray_One_time1) {
                                checkarray1.push(listArray_One_time1[i])
                            }
                            console.log(checkarray1, "checkarray4564511116");


                        }
                        let listArray_periodical1 = _.filter(filteredArr1, { frequency: "Periodical" });
                        if (listArray_periodical1 && listArray_periodical1.length > 0) {
                            for (let i in listArray_periodical1) {
                                checkarray1.push(listArray_periodical1[i])
                                console.log(i, 'listArray_periodical1[i]')
                            }
                        }

                        console.log(listArray_periodical1, "listArray_periodical1");
                        let listArray_Review1 = _.filter(filteredArr1, { frequency: "Review" });

                        console.log(checkarray1, "checkarray4564511116");
                        if (listArray_Review1 && listArray_Review1.length > 0) { for (let i in listArray_Review1) { checkarray1.push(listArray_Review1[i]) } }
                        let listArray_Flexi_Review1 = _.filter(filteredArr1, { frequency: "Flexi Review" });

                        console.log(checkarray1, "checkarray4564511116");
                        if (listArray_Flexi_Review1 && listArray_Flexi_Review1.length > 0) { for (let i in listArray_Flexi_Review1) { checkarray1.push(listArray_Flexi_Review1[i]) } }
                        let listArray_On_Occurrence1 = _.filter(filteredArr1, { frequency: "On Occurrence" });

                        console.log(checkarray1, "checkarray4564511116");
                        if (listArray_On_Occurrence1 && listArray_On_Occurrence1.length > 0) { for (let i in listArray_On_Occurrence1) { checkarray1.push(listArray_On_Occurrence1[i]) } }
                        console.log(checkarray1, "checkarray4564511116");

                    }
                    datavalue = {
                        'index': i,
                        'common_id': commonId,
                        'parent': datas,
                        // 'child': [checkarray, checkarray1],
                        'child': checkarray.concat(checkarray1),
                        'u_id': u_id
                    }
                    console.log(datavalue, "datavalue3333");
                    // for (let i in datavalue) {
                    //     if (datavalue[i] !== undefined) {

                    //     }
                    //     console.log(finalarray,"finalarray123");
                    // }
                    tempArray1.push(datavalue)
                    // console.log(datavalue, "datavalue3333555555");
                    parId.push(i);
                    array = tempArray1
                    // sampletemp.push(finalarray)
                    // console.log(sampletemp, 'finalarrayfinalarrayfinalarray');
                })

                // datavalue = {
                //     'index': i,
                //     'parent': datas,
                //     'child': [checkarray, checkarray1],
                // }
                console.log(tempArray1, 'tempArray1tempArray1tempArray1')
                // Settitledata(tempArray1)

            }
            finalarray.push({
                mainparent: uniqunit[index],
                subchild: array
            })
            console.log(finalarray, 'finalarrayfinalarray1111')

        })
        setTempTitleData(finalarray)
        console.log(finalarray, 'finalarrayfinalarray')

    }, [updatedTempArray])

    useEffect(() => {
        let array = []
        let k = newassigneelist
        newassigneelist && newassigneelist.length > 0 && newassigneelist.map((item, i) => {
            // if (item.is_assignee === true) {
            let a = `${item.emp_code} - ${item.emp_name}`
            let result = dataa.usr_name[0].includes(item.emp_code);
            if ((`${item.emp_code} - ${item.emp_name}`) == dataa.usr_name[0]) {
                //  array = _.remove(newassigneelist,(item) => a == dataa.usr_name[0])
                // for(let i in k){
                if (dataa.usr_name[0] == a) {
                    k.splice(i, 1)
                }
                // }
            }
            Setnewasigneelistshow([...k])
        })
    }, [newassigneelist])

    useEffect(() => {

        if (temparr1.length == 0) {
            setCollapseAssignee('0')
            setCollapseAssignee1(false)
            setAddSubmitForm(false)
        }

    }, [temparr1])

    useEffect(() => {
        let split_units = []
        let sample
        let uniqunit = _.uniqBy(reassigndatatablelists.reassign_compliances && reassigndatatablelists.reassign_compliances.length > 0 && reassigndatatablelists.reassign_compliances, function (e) {
            return e.u_id;
        });
        uniqunit.map((items) => {
            sample = reassigndatatablelists.reassign_compliances.filter((item) => {
                return items.u_id === item.u_id
            })
            split_units.push(sample)
        })
        setFilslArray(split_units)
    }, [updatedArray])

    const compliancetaskfilters = (e, type, ID, parentuId, mainparentID, itemKey, actId, unitId) => {
        console.log(type, e, ID, "typeeeee");
        let uniqunit = _.uniqBy(reassigndatatablelists.reassign_compliances && reassigndatatablelists.reassign_compliances.length > 0 && reassigndatatablelists.reassign_compliances, function (e) {
            return e.u_id;
        });

        let array;
        let chkidmeregd = []
        let commonId
        let finalarray = [];
        let datavalue = [];
        let datas = [];
        let flag = 0
        let parId = []
        let u_id
        let filteredArr;
        let filteredArr1;
        let split_units = filslArray
        console.log(split_units, 'split_units,searchingValue')
        console.log(mainparentID, 'mainparentID')
        for (let i = 0; i < split_units.length; i++) {
            split_units[i].filter((item) => {
                console.log(item, parentuId, 'parentUIDah')
                if (item?.u_name == parentuId) {
                    flag = i
                }
            })
        }

        console.log(flag, split_units[flag], 'splitfalsg')

        //    split_units =   [split_units[flag].filter((e) => {
        //     console.log(e,'weeweew')
        //         if ((e[type]?.toLowerCase().includes(searchingValue[type]?.toLowerCase())) || e.act_name != parentuId) {
        //             return true;
        //         }
        //     })]



        console.log(split_units, 'posplitpo')

        // setFilslArray(split_units)
        console.log(split_units, 'split_units');
        split_units?.map((itemss, index) => {
            let sampletemp = []
            let tempArray1 = []
            if (itemss) {
                const ab = itemss?.map(function (item) {
                    if (item?.act_name) {
                        if ((sampletemp.indexOf(item && item.act_name && item?.act_name) < 0))
                            sampletemp.push(item?.act_name)
                    }
                })
                sampletemp.map((data, i) => {
                    datas = data
                    let temp = [];
                    let entityArray = []
                    let tempArray = []
                    let checkarray = []
                    let checkarray1 = []

                    if (datas) {
                        itemss.map((child) => {
                            if (datas === child?.act_name) {
                                entityArray = {
                                    'ac_id': child?.ac_id,
                                    'act_name': child?.act_name,
                                    'approval_person': child?.approval_person,
                                    'approver_name': child?.approver_name,
                                    'assignee': child?.assignee,
                                    'assignee_name': child?.assignee_name,
                                    'c_h_id': child?.c_h_id,
                                    'comp_id': child?.comp_id,
                                    'compfielaw_url': child?.compfielaw_url,
                                    'compliance_description': child?.compliance_description,
                                    'compliance_name': child?.compliance_name,
                                    'concurrence_person': child?.concurrence_person,
                                    'concurrer_name': child?.concurrer_name,
                                    'd_date': child?.d_date,
                                    'extend_due_date': child?.extend_due_date,
                                    'f_id': child?.f_id,
                                    'frequency': child?.frequency,
                                    'summary': child?.summary,
                                    'task_type': child?.task_type,
                                    'trigger_before_days': child?.trigger_before_days && child?.trigger_before_days?.toString(),
                                    'tz_date': child?.tz_date,
                                    'tz_name': child?.tz_name,
                                    'u_id': child?.u_id,
                                    'u_name': child?.u_name,
                                    'v_date': child?.v_date,
                                    'parentId': i,
                                    'common_id': child?.u_id && child?.u_id?.toString() + i
                                }
                                u_id = child?.u_id
                                tempArray.push(entityArray)
                                console.log(tempArray, 'entityArray')
                                filteredArr = tempArray.filter((item) => {
                                    return item.task_type === 'Current Task'
                                })
                                console.log(filteredArr, "filteredArr111");
                                filteredArr1 = tempArray.filter((item) => {
                                    return item.task_type === 'Upcoming Task'
                                })

                            }

                        })
                        let listArray_One_time = _.filter(filteredArr, { frequency: "One Time" });
                        if (listArray_One_time && listArray_One_time.length > 0) {
                            for (let i in listArray_One_time) {
                                checkarray.push(listArray_One_time[i])
                            }
                        }
                        let listArray_periodical = _.filter(filteredArr, { frequency: "Periodical" });
                        if (listArray_periodical && listArray_periodical.length > 0) { for (let i in listArray_periodical) { checkarray.push(listArray_periodical[i]) } }
                        let listArray_Review = _.filter(filteredArr, { frequency: "Review" });
                        if (listArray_Review && listArray_Review.length > 0) { for (let i in listArray_Review) { checkarray.push(listArray_Review[i]) } }
                        let listArray_Flexi_Review = _.filter(filteredArr, { frequency: "Flexi Review" });
                        if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) { for (let i in listArray_Flexi_Review) { checkarray.push(listArray_Flexi_Review[i]) } }
                        let listArray_On_Occurrence = _.filter(filteredArr, { frequency: "On Occurrence" });
                        if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) { for (let i in listArray_On_Occurrence) { checkarray.push(listArray_On_Occurrence[i]) } }

                        let listArray_One_time1 = _.filter(filteredArr1, { frequency: "One Time" });
                        if (listArray_One_time1 && listArray_One_time1.length > 0) {
                            for (let i in listArray_One_time1) {
                                checkarray1.push(listArray_One_time1[i])
                            }


                        }
                        let listArray_periodical1 = _.filter(filteredArr1, { frequency: "Periodical" });
                        if (listArray_periodical1 && listArray_periodical1.length > 0) { for (let i in listArray_periodical) { checkarray1.push(listArray_periodical1[i]) } }

                        let listArray_Review1 = _.filter(filteredArr1, { frequency: "Review" });

                        if (listArray_Review1 && listArray_Review1.length > 0) { for (let i in listArray_Review) { checkarray1.push(listArray_Review1[i]) } }
                        let listArray_Flexi_Review1 = _.filter(filteredArr1, { frequency: "Flexi Review" });

                        if (listArray_Flexi_Review1 && listArray_Flexi_Review1.length > 0) { for (let i in listArray_Flexi_Review) { checkarray1.push(listArray_Flexi_Review1[i]) } }
                        let listArray_On_Occurrence1 = _.filter(filteredArr1, { frequency: "On Occurrence" });

                        if (listArray_On_Occurrence1 && listArray_On_Occurrence1.length > 0) { for (let i in listArray_On_Occurrence) { checkarray1.push(listArray_On_Occurrence1[i]) } }

                    }
                    console.log(checkarray, 'chkchkchk')
                    chkidmeregd = chkidmeregd.concat(checkarray, checkarray1);
                    if (i == ID) {
                        let filtered1 = _.filter(checkarray, { parentId: ID })
                        let filtered2 = _.filter(checkarray1, { parentId: ID })


                        if (indexValue[itemKey + '_compliance_name']) {
                            filtered1 = filtered1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["compliance_name"]?.toLowerCase().includes(indexValue[itemKey + '_compliance_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['summary']) {
                            filtered1 = filtered1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["summary"]?.toLowerCase().includes(searchingValue['summary']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['trigger_before_days']) {
                            filtered1 = filtered1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["trigger_before_days"].toString().match(searchingValue['trigger_before_days']))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['assignee_name']) {
                            filtered1 = filtered1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["assignee_name"]?.toLowerCase().includes(searchingValue['assignee_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['concurrer_name']) {
                            filtered1 = filtered1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["concurrer_name"].replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['concurrer_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['approver_name']) {
                            filtered1 = filtered1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["approver_name"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['approver_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['d_date']) {
                            filtered1 = filtered1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["d_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['d_date']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['v_date']) {
                            filtered1 = filtered1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["v_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['v_date']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }

                        if (searchingValue['compliance_name']) {
                            filtered2 = filtered2.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["compliance_name"]?.toLowerCase().includes(searchingValue['compliance_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['summary']) {
                            filtered2 = filtered2.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["summary"]?.toLowerCase().includes(searchingValue['summary']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['trigger_before_days']) {
                            filtered2 = filtered2.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["trigger_before_days"].toString().match(searchingValue['trigger_before_days']))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['assignee_name']) {
                            filtered2 = filtered2.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["assignee_name"]?.toLowerCase().includes(searchingValue['assignee_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['concurrer_name']) {
                            filtered2 = filtered2.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["concurrer_name"].replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['concurrer_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['approver_name']) {
                            filtered2 = filtered2.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["approver_name"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['approver_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['d_date']) {
                            filtered2 = filtered2.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["d_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['d_date']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['v_date']) {
                            filtered2 = filtered2.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["v_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['v_date']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }


                        // console.log()

                        // let xfilter = filtered1.filter((item) => {
                        //     if (type == 'trigger_before_days') {
                        //         if (item[type].match(e)) {
                        //             return true
                        //         }
                        //     } else {
                        //         if (item[type]?.toLowerCase().includes(e?.toLowerCase())) {
                        //             return true
                        //         }
                        //     }
                        // })
                        // let xfilter1 = filtered2.filter((item) => {
                        //     if (type == 'trigger_before_days') {
                        //         if (item[type].match(e)) {
                        //             return true
                        //         }
                        //     } else {
                        //         if (item[type]?.toLowerCase().includes(e?.toLowerCase())) {
                        //             return true
                        //         }
                        //     }
                        // })

                        // console.log(xfilter, 'checkarraycheckarraycheckarray')


                        datavalue = {
                            'index': i,
                            'parent': datas,
                            'common_id': commonId,
                            // 'child': [filtered1, filtered2],
                            'child': filtered1.concat(filtered2),
                            'u_id': u_id
                        }
                    } else {

                        if (searchingValue['compliance_name']) {
                            checkarray = checkarray.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["compliance_name"]?.toLowerCase().includes(searchingValue['compliance_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['summary']) {
                            checkarray = checkarray.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["summary"]?.toLowerCase().includes(searchingValue['summary']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['trigger_before_days']) {
                            checkarray = checkarray.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["trigger_before_days"].toString().match(searchingValue['trigger_before_days']))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['assignee_name']) {
                            checkarray = checkarray.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["assignee_name"]?.toLowerCase().includes(searchingValue['assignee_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['concurrer_name']) {
                            checkarray = checkarray.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["concurrer_name"].replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['concurrer_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['approver_name']) {
                            checkarray = checkarray.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["approver_name"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['approver_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['d_date']) {
                            checkarray = checkarray.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["d_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['d_date']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['v_date']) {
                            checkarray = checkarray.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["v_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['v_date']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }

                        if (searchingValue['compliance_name']) {
                            checkarray1 = checkarray1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["compliance_name"]?.toLowerCase().includes(searchingValue['compliance_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['summary']) {
                            checkarray1 = checkarray1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["summary"]?.toLowerCase().includes(searchingValue['summary']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['trigger_before_days']) {
                            checkarray1 = checkarray1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["trigger_before_days"].toString().match(searchingValue['trigger_before_days']))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['assignee_name']) {
                            checkarray1 = checkarray1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["assignee_name"]?.toLowerCase().includes(searchingValue['assignee_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['concurrer_name']) {
                            checkarray1 = checkarray1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["concurrer_name"].replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['concurrer_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['approver_name']) {
                            checkarray1 = checkarray1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["approver_name"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['approver_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['d_date']) {
                            checkarray1 = checkarray1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["d_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['d_date']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['v_date']) {
                            checkarray1 = checkarray1.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["v_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['v_date']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }



                        console.log("dataffsdsfsfsfsfsfsvalue3333");
                        datavalue = {
                            'index': i,
                            'parent': datas,
                            'common_id': commonId,
                            // 'child': [checkarray, checkarray1],
                            'child': checkarray.concat(checkarray1),
                            'u_id': u_id
                        }
                    }
                    tempArray1.push(datavalue)
                    parId.push(i);
                    array = tempArray1
                })
            }
            finalarray.push({
                mainparent: uniqunit[index],
                subchild: array
            })
        })
        console.log(chkidmeregd.filter((e) => {
            return e
        }), 'chkidmeregdd')
        Settitledata(finalarray)
        // setFilslArray([chkidmeregd])
    }

    const compliancetaskfiltersss = (e, type, ID, parentuId, mainparentID, itemKey, actId, unitId) => {
        console.log(searchingValue, "typeeeeeeee");
        let array = titledata;
        let tempArrayNewArray = tempTitleData
        let mergeChildSearch
        let mainTempChildSearch
        let secondChild

        for (let i = 0; i < array.length; i++) {
            let mainParentArray = array[i].mainparent
            let mainChildArray = array[i].subchild
            let mainTempChildArray = tempArrayNewArray[i].subchild
            console.log(mainChildArray, 'mainChildArray');
            if (mainParentArray.u_id == parseInt(unitId)) {
                for (let j = 0; j < mainChildArray.length; j++) {
                    if (mainChildArray[j].common_id == actId) {
                        mergeChildSearch = mainChildArray[j].child
                        mainTempChildSearch = mainTempChildArray[j].child

                        let filteredChildArr = mergeChildSearch;
                        let newChildArr = mainTempChildSearch
                        // const filteredChildArr = mergeChildSearch.filter(item => 
                        //     item.u_name !== mainparentID ||
                        //     item.act_name !== parentuId ||
                        //     (searchingValue['compliance_name'] && item.compliance_name.toLowerCase().includes(searchingValue['compliance_name'].toLowerCase())) ||
                        //     (searchingValue['assignee_name'] && item.assignee_name.toLowerCase().includes(searchingValue['assignee_name'].toLowerCase()))
                        // );

                        /* const filteredArr = mergeChildSearch.filter(obj => {
                             return (!searchingValue['compliance_name'] || obj.compliance_name.toLowerCase().includes(searchingValue['compliance_name']?.toLowerCase())) &&
                             (!searchingValue['assignee_name'] || obj.assignee_name.toLowerCase().includes(searchingValue['assignee_name']?.toLowerCase()))
                         });*/
                        if (searchingValue['compliance_name']) {
                            console.log(searchingValue['compliance_name'], 'compliance_name_if');
                            filteredChildArr = filteredChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["compliance_name"]?.toLowerCase().includes(searchingValue['compliance_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        } else {
                            newChildArr = newChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["compliance_name"]?.toLowerCase().includes(searchingValue['compliance_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['assignee_name']) {
                            filteredChildArr = filteredChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["assignee_name"]?.toLowerCase().includes(searchingValue['assignee_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        } else {
                            newChildArr = newChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["assignee_name"]?.toLowerCase().includes(searchingValue['compliance_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['summary']) {
                            filteredChildArr = filteredChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["summary"]?.toLowerCase().includes(searchingValue['summary']?.toLowerCase()))) {
                                    return true;
                                }
                            })

                        } else {
                            newChildArr = newChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["summary"]?.toLowerCase().includes(searchingValue['summary']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['trigger_before_days']) {
                            filteredChildArr = filteredChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["trigger_before_days"].toString().match(searchingValue['trigger_before_days']))) {
                                    return true;
                                }
                            })
                        } else {
                            newChildArr = newChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["trigger_before_days"].toString().match(searchingValue['trigger_before_days']))) {
                                    return true;
                                }
                            })
                        }

                        if (searchingValue['concurrer_name']) {
                            filteredChildArr = filteredChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["concurrer_name"].replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['concurrer_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        } else {
                            newChildArr = newChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["concurrer_name"].replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['concurrer_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['approver_name']) {
                            filteredChildArr = filteredChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["approver_name"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['approver_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        } else {
                            newChildArr = newChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["approver_name"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['approver_name']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['d_date']) {
                            filteredChildArr = filteredChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["d_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['d_date']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        } else {
                            newChildArr = newChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["d_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['d_date']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        if (searchingValue['v_date']) {
                            filteredChildArr = filteredChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["v_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['v_date']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        } else {
                            newChildArr = newChildArr.filter((item) => {
                                if (item?.u_name != mainparentID || item.act_name != parentuId || (item["v_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(searchingValue['v_date']?.toLowerCase()))) {
                                    return true;
                                }
                            })
                        }
                        mainChildArray[j].child = filteredChildArr ? filteredChildArr : newChildArr

                    }
                }

            }
        }
        Settitledata([...array])


    }

    const compliancetaskfilter = (e, type, ID, parentuId, mainparentID, itemKey, actId, unitId) => {
        let array = titledata;
        let tempArrayNewArray = tempTitleData
        let mergeChildSearch;
        let mainTempChildSearch;
        let frequencyPushedArray = []
        let isFiltering = false; // flag to indicate whether any filters are active

        // check if any search field is non-empty indexValue
        Object.keys(indexValue).forEach((key) => {
            if (indexValue[key]) {
                isFiltering = true;
            }
        });

        console.log(isFiltering, 'isFiltering', Object.keys(indexValue), 'Object.keys(searchingValue)');

        for (let i = 0; i < tempArrayNewArray.length; i++) {
            let mainParentArray = array[i].mainparent;
            let mainChildArray = array[i].subchild;
            let mainTempChildArray = tempArrayNewArray[i].subchild
            if (mainParentArray.u_id == parseInt(unitId)) {
                for (let j = 0; j < mainTempChildArray.length; j++) {
                    if (mainChildArray[j].common_id == actId) {
                        mergeChildSearch = mainChildArray[j].child;
                        mainTempChildSearch = mainTempChildArray[j].child
                        if (isFiltering) { // apply filters only if any search field is non-empty
                            if (!indexValue[itemKey + '_periodical'] && !indexValue[itemKey + '_flexi_review'] && !indexValue[itemKey + '_on_occurrence']
                                && !indexValue[itemKey + '_one_time'] && !indexValue[itemKey + '_review']) {
                                let filteredChildArr = mainTempChildSearch; // initialize the filtered results to an empty array                           
                                if (indexValue[itemKey + '_compliance_name']) {
                                    console.log(searchingValue['compliance_name'], 'compliance_name_if');
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name == mainparentID && item.act_name == parentuId && item["compliance_name"]?.toLowerCase().includes(indexValue[itemKey + '_compliance_name']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_assignee_name']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name == mainparentID && item.act_name == parentuId && item["assignee_name"]?.toLowerCase().includes(indexValue[itemKey + '_assignee_name']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_summary']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name == mainparentID && item.act_name == parentuId && item["summary"]?.toLowerCase().includes(indexValue[itemKey + '_summary']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_trigger_before_days']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name == mainparentID && item.act_name == parentuId && item["trigger_before_days"] == indexValue[itemKey + '_trigger_before_days'];
                                    });
                                }
                                if (indexValue[itemKey + '_concurrer_name']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name == mainparentID && item.act_name == parentuId && item["concurrer_name"]?.toLowerCase().includes(indexValue[itemKey + '_concurrer_name']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_approver_name']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name == mainparentID && item.act_name == parentuId && item["approver_name"]?.toLowerCase().includes(indexValue[itemKey + '_approver_name']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_d_date']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name != mainparentID || item.act_name != parentuId || (item["d_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(indexValue[itemKey + '_d_date']?.toLowerCase()));
                                    });
                                }
                                if (indexValue[itemKey + '_v_date']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name != mainparentID || item.act_name != parentuId || (item["v_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(indexValue[itemKey + '_v_date']?.toLowerCase()));
                                    });
                                }

                                mainChildArray[j].child = filteredChildArr;
                            } else {
                                let filteredChildArr = mainTempChildSearch; // initialize the filtered results to an empty array                           
                                if (indexValue[itemKey + '_compliance_name']) {
                                    console.log(searchingValue['compliance_name'], 'compliance_name_if');
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name == mainparentID && item.act_name == parentuId && item["compliance_name"]?.toLowerCase().includes(indexValue[itemKey + '_compliance_name']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_assignee_name']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name == mainparentID && item.act_name == parentuId && item["assignee_name"]?.toLowerCase().includes(indexValue[itemKey + '_assignee_name']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_summary']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name == mainparentID && item.act_name == parentuId && item["summary"]?.toLowerCase().includes(indexValue[itemKey + '_summary']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_trigger_before_days']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name == mainparentID && item.act_name == parentuId && item["trigger_before_days"]?.toLowerCase().includes(indexValue[itemKey + '_trigger_before_days']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_concurrer_name']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name == mainparentID && item.act_name == parentuId && item["concurrer_name"]?.toLowerCase().includes(indexValue[itemKey + '_concurrer_name']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_approver_name']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name == mainparentID && item.act_name == parentuId && item["approver_name"]?.toLowerCase().includes(indexValue[itemKey + '_approver_name']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_d_date']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name != mainparentID || item.act_name != parentuId || (item["d_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(indexValue[itemKey + '_d_date']?.toLowerCase()));
                                    });
                                }
                                if (indexValue[itemKey + '_v_date']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item?.u_name != mainparentID || item.act_name != parentuId || (item["v_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(indexValue[itemKey + '_v_date']?.toLowerCase()));
                                    });
                                }

                                let updatedFilter = filteredChildArr

                                if (indexValue[itemKey + '_periodical']) {
                                    filteredChildArr = updatedFilter.filter((item) => {
                                        return item['frequency'] == 'Periodical'
                                    });
                                    frequencyPushedArray.push(filteredChildArr)

                                }
                                if (indexValue[itemKey + '_flexi_review']) {
                                    filteredChildArr = updatedFilter.filter((item) => {
                                        return item['frequency'] == 'Flexi Review'
                                    });
                                    frequencyPushedArray.push(filteredChildArr)

                                }
                                if (indexValue[itemKey + '_review']) {
                                    filteredChildArr = updatedFilter.filter((item) => {
                                        return item['frequency'] == 'Review'
                                    });
                                    frequencyPushedArray.push(filteredChildArr)

                                }
                                if (indexValue[itemKey + '_on_occurrence']) {
                                    filteredChildArr = updatedFilter.filter((item) => {
                                        return item['frequency'] == 'On Occurrence'
                                    });
                                    frequencyPushedArray.push(filteredChildArr)

                                }
                                if (indexValue[itemKey + '_one_time']) {
                                    filteredChildArr = updatedFilter.filter((item) => {
                                        return item['frequency'] == 'One Time'
                                    });
                                    frequencyPushedArray.push(filteredChildArr)

                                }
                                mainChildArray[j].child = [].concat.apply([], frequencyPushedArray);
                            }

                        } else { // if no filters are active, display the original array
                            mainChildArray[j].child = mainTempChildSearch;
                        }
                    }
                }
            }
        }

        Settitledata([...array]);
    }

    const onFinish = (ID, itemKey, actId, unitId) => {
        let array = titledata;
        let tempArrayNewArray = tempTitleData
        let mergeChildSearch;
        let mainTempChildSearch;
        let frequencyPushedArray = []
        let isFiltering = false; // flag to indicate whether any filters are active

        // check if any search field is non-empty indexValue
        Object.keys(indexValue).forEach((key) => {
            if (indexValue[key]) {
                isFiltering = true;
            }
        });

        for (let i = 0; i < tempArrayNewArray.length; i++) {
            let mainParentArray = array[i].mainparent;
            let mainChildArray = array[i].subchild;
            let mainTempChildArray = tempArrayNewArray[i].subchild
            if (mainParentArray.u_id == parseInt(unitId)) {
                for (let j = 0; j < mainTempChildArray.length; j++) {
                    if (mainChildArray[j].common_id == actId) {
                        mergeChildSearch = mainChildArray[j].child;
                        mainTempChildSearch = mainTempChildArray[j].child
                        if (isFiltering) { // apply filters only if any search field is non-empty
                            let filteredChildArr = mainTempChildSearch; // initialize the filtered results to an empty array
                            if (!indexValue[itemKey + '_compliance_name'] && !indexValue[itemKey + '_assignee_name'] && !indexValue[itemKey + '_summary'] &&
                                !indexValue[itemKey + '_trigger_before_days'] && !indexValue[itemKey + '_concurrer_name'] && !indexValue[itemKey + '_approver_name'] &&
                                !indexValue[itemKey + '_d_date'] && !indexValue[itemKey + '_v_date']) {
                                if (indexValue[itemKey + '_periodical']) {
                                    filteredChildArr = mainTempChildSearch.filter((item) => {
                                        return item['frequency'] == 'Periodical'
                                    });
                                    frequencyPushedArray.push(filteredChildArr)
                                }
                                if (indexValue[itemKey + '_flexi_review']) {
                                    filteredChildArr = mainTempChildSearch.filter((item) => {
                                        return item['frequency'] == 'Flexi Review'
                                    });
                                    frequencyPushedArray.push(filteredChildArr)
                                }
                                if (indexValue[itemKey + '_review']) {
                                    filteredChildArr = mainTempChildSearch.filter((item) => {
                                        return item['frequency'] == 'Review'
                                    });
                                    frequencyPushedArray.push(filteredChildArr)
                                }
                                if (indexValue[itemKey + '_on_occurrence']) {
                                    filteredChildArr = mainTempChildSearch.filter((item) => {
                                        return item['frequency'] == 'On Occurrence'
                                    });
                                    frequencyPushedArray.push(filteredChildArr)
                                }
                                if (indexValue[itemKey + '_one_time']) {
                                    filteredChildArr = mainTempChildSearch.filter((item) => {
                                        return item['frequency'] == 'One Time'
                                    });
                                    frequencyPushedArray.push(filteredChildArr)
                                }
                                mainChildArray[j].child = [].concat.apply([], frequencyPushedArray);
                            } else {
                                if (indexValue[itemKey + '_compliance_name']) {
                                    console.log(searchingValue['compliance_name'], 'compliance_name_if');
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item["compliance_name"]?.toLowerCase().includes(indexValue[itemKey + '_compliance_name']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_assignee_name']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item["assignee_name"]?.toLowerCase().includes(indexValue[itemKey + '_assignee_name']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_summary']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item["summary"]?.toLowerCase().includes(indexValue[itemKey + '_summary']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_trigger_before_days']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item["summary"]?.toLowerCase().includes(indexValue[itemKey + '_trigger_before_days']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_concurrer_name']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item["summary"]?.toLowerCase().includes(indexValue[itemKey + '_concurrer_name']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_approver_name']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return item["summary"]?.toLowerCase().includes(indexValue[itemKey + '_approver_name']?.toLowerCase());
                                    });
                                }
                                if (indexValue[itemKey + '_d_date']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return (item["d_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(indexValue[itemKey + '_d_date']?.toLowerCase()));
                                    });
                                }
                                if (indexValue[itemKey + '_v_date']) {
                                    filteredChildArr = filteredChildArr.filter((item) => {
                                        return (item["v_date"]?.replace(/\s\s+/g, ' ')?.toLowerCase().includes(indexValue[itemKey + '_v_date']?.toLowerCase()));
                                    });
                                }
                                //Based on empty filter and value in text need to make condition here. using frequency filter not exists then need to push filteredChildArr other wise use below code.
                                let updatedFilter = filteredChildArr
                                if(!indexValue[itemKey + '_periodical'] && !indexValue[itemKey + '_flexi_review'] && !indexValue[itemKey + '_review'] &&
                                !indexValue[itemKey + '_on_occurrence'] && !indexValue[itemKey + '_one_time']){
                                    frequencyPushedArray.push(filteredChildArr)
                                    mainChildArray[j].child = [].concat.apply([], frequencyPushedArray);
                                }else{
                                    if (indexValue[itemKey + '_periodical']) {
                                        filteredChildArr = updatedFilter.filter((item) => {
                                            return item['frequency'] == 'Periodical'
                                        });
                                        frequencyPushedArray.push(filteredChildArr)
    
                                    }
                                    if (indexValue[itemKey + '_flexi_review']) {
                                        filteredChildArr = updatedFilter.filter((item) => {
                                            return item['frequency'] == 'Flexi Review'
                                        });
                                        frequencyPushedArray.push(filteredChildArr)
    
                                    }
                                    if (indexValue[itemKey + '_review']) {
                                        filteredChildArr = updatedFilter.filter((item) => {
                                            return item['frequency'] == 'Review'
                                        });
                                        frequencyPushedArray.push(filteredChildArr)
                                    }
                                    if (indexValue[itemKey + '_on_occurrence']) {
                                        filteredChildArr = updatedFilter.filter((item) => {
                                            return item['frequency'] == 'On Occurrence'
                                        });
                                        frequencyPushedArray.push(filteredChildArr)
                                    }
                                    if (indexValue[itemKey + '_one_time']) {
                                        filteredChildArr = updatedFilter.filter((item) => {
                                            return item['frequency'] == 'One Time'
                                        });
                                        frequencyPushedArray.push(filteredChildArr)
                                    }
                                    mainChildArray[j].child = [].concat.apply([], frequencyPushedArray);
                                }
                            }

                        } else { // if no filters are active, display the original array
                            mainChildArray[j].child = mainTempChildSearch;
                        }
                    }
                }
            }
        }

        Settitledata([...array]);
        setshowDropDown({})
    }



    useEffect(() => {
        if (reassignusercompliance.assign_users && reassignusercompliance.assign_users.length > 0) {

            let uniqueObjArray = [...new Map(reassignusercompliance.assign_users && reassignusercompliance.assign_users.length && reassignusercompliance.assign_users.map((item) => [item["s_u_name"], item])).values()];
            setFilteredArray([...uniqueObjArray])
        }
    }, [reassignusercompliance.assign_users])

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
        window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    //  Full screen mode
    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }


    return (
        <Fragment>
            <div>
                <div className='back-to-top'>
                    <ScrollButton />
                </div>
                <div id='page-wrapper' className="page-wrapper">
                    <div className="page-titles pb-0 pt-2 page-title-sticky">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Transaction</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span>Reassign Compliance</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-6 col-md-4 col-sm-12 col-xs-12 d-flex align-items-center justify-content-md-end justify-content-sm-start">
                                {temparr1.length > 0 ? <>

                                    {triggerstate == false ? <Tooltip placement='top' title=''><Button type="primary" shape="round" className='addbutton mx-2' style={{ background: "#0096FF", borderColor: "#0096FF", color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'black' }}
                                        size='default' onClick={Onsubmit2}>
                                        Select {reassignPayloadId.u_id.length > 0 ? unitName[Number(reassignPayloadId.user_type_id)] : unitName[Number(singlereassignPayload.userType)]}  <i> </i>
                                    </Button></Tooltip> : <Tooltip placement='top' title=''><Button type="primary" shape="round" className='addbutton mx-2' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'black' }}

                                        size='default' onClick={() => { toast.error("Trigger before days should not exceed 100") }}>
                                        Select {reassignPayloadId.u_id.length > 0 ? unitName[Number(reassignPayloadId.user_type_id)] : unitName[Number(singlereassignPayload.userType)]}  <i></i>
                                    </Button></Tooltip>}
                                </>
                                    : false}
                                <Tooltip title='Advanced Filter' placement='top'> <Button
                                    className="mx-1"
                                    type="primary"
                                    shape="round"
                                    style={{
                                        display: filterTaskAcc ? "none" : "initial",
                                    }}
                                    onClick={filterHandle}
                                >
                                    <FilterOutlined /> Filter
                                </Button>
                                </Tooltip>
                                <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black full-mode fullscreenradius" >
                                    <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid pt-1">
                        <div className='row assign'>
                            <div className="col-12">
                                <div className="row">
                                    {tab === true ?
                                        <>
                                            <div className={localStorage.getItem("currentTheme")} style={{ padding: '0px', marginBottom: '3px' }}>
                                                {collapseAssignee1 == true && temparr1 && temparr1.length > 0 ? <>
                                                    <Collapse key={0}
                                                        onChange={(e) => {
                                                            if (collapseAssignee == '0') {
                                                                setCollapseAssignee('1')
                                                            }
                                                            else {
                                                                setCollapseAssignee('0')
                                                            }
                                                        }}
                                                        defaultActiveKey={'0'}
                                                        activeKey={collapseAssignee}
                                                    >
                                                        <Panel header={collapseAssignee1 == false ? 'Assignment Settings' : `Selected Compliances : ${temparr1.length}`} key='1' disabled={collapseAssignee1 == false ? true : false} >
                                                            <div className="col-md-12" style={{ marginTop: '0px' }}>
                                                                <div className="row">
                                                                    <b>Current {unitName[reassignPayloadId.u_id.length > 0 ? Number(reassignPayloadId.user_type_id) : Number(singlereassignPayload.userType)]} - {dataa.usr_name}</b>
                                                                    {/* <b>{unitcategory && (unitcategory[0].user_category_id == 1 || unitcategory[0].user_category_id == 4 || unitcategory[0].user_category_id == 3) ? `Current Approver ${dataa.usr_name}` : `Current Assignee ${dataa.usr_name}`}</b> */}
                                                                    {/* <span>{unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 5 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 2 ? <><label><h5> <b>Current Assignee : {dataa.usr_name}</b></h5></label> <br></br></> : unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4 ? <><label> <b>Current Approver: {dataa.usr_name}</b></label> <br></br></> : unitcategory && unitcategory[0] && unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 1 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4 ? <><label> <b>Current Approver: {dataa.usr_name}</b></label> <br></br></> : ''}</span> */}
                                                                    <div className='col-md-4' >
                                                                        {/* {unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 5 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 6 ? <><label> <b>New Assignee</b><span style={{ color: "red" }}> *</span></label> <br></br></>
                                                                            : unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4 ? <><label> <b>New Concurrer</b><span style={{ color: "red" }}> *</span></label> <br></br></> : unitcategory && unitcategory[0] && unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 1 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4 ? <><label> <b>New Approver</b><span style={{ color: "red" }}> *</span></label> <br></br></> : ''} */}
                                                                        <b>New {unitName[reassignPayloadId.u_id.length > 0 ? Number(reassignPayloadId.user_type_id) : Number(singlereassignPayload.userType)]} : <span style={{ color: 'red' }}> * </span></b>
                                                                        {/* <span>{unitcategory && (unitcategory[0].user_category_id == 1 || unitcategory[0].user_category_id == 4 || unitcategory[0].user_category_id == 3) ? 'New Approver' : 'New Assignee'}</span> */}
                                                                        <Select
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Search"
                                                                            onChange={(data, value) => {

                                                                                if (data !== undefined) {
                                                                                    setDataa({
                                                                                        ...dataa,
                                                                                        employee_name: data,
                                                                                        su_name: value.children[2],
                                                                                        su_num: value.children[0],
                                                                                        su_id: value.children[1]
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    setDataa({
                                                                                        ...dataa,
                                                                                        employee_name: '',
                                                                                        su_name: '',
                                                                                        su_num: '',
                                                                                        su_id: ''
                                                                                    })
                                                                                }
                                                                            }}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children?.toLowerCase().includes(input?.toLowerCase())}
                                                                            style={{ width: '100%', marginTop: "5px" }}
                                                                        >

                                                                            {newassigneelistshow && newassigneelistshow.length > 0 && newassigneelistshow.map((item) => {
                                                                                console.log(item, "itemitem123");
                                                                                if (dataa.usr_name != item.emp_name) {
                                                                                    if (Number(reassignPayloadId.user_type_id) == 1 || Number(singlereassignPayload.userType) == 1) {
                                                                                        if (item.usr_id != 1) {
                                                                                            return (
                                                                                                <Option key={item.usr_id}>
                                                                                                    {item.emp_code == null ? item.emp_name : item.emp_code + ' - ' + item.emp_name}
                                                                                                </Option>
                                                                                            );
                                                                                        }
                                                                                    } else {
                                                                                        return (
                                                                                            <Option key={item.usr_id}>
                                                                                                {item.emp_code == null ? item.emp_name : item.emp_code + ' - ' + item.emp_name}
                                                                                            </Option>
                                                                                        );

                                                                                    }


                                                                                }
                                                                            })


                                                                            }
                                                                        </Select>
                                                                        {validator1.current.message(
                                                                            'su_name',
                                                                            dataa.su_name,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddSubmitForm ? 'show' : 'hide'} mx-1`,
                                                                                messages: {
                                                                                    required: `New ${unitName[reassignPayloadId.u_id.length > 0 ? Number(reassignPayloadId.user_type_id) : Number(singlereassignPayload.userType)]} Required`
                                                                                }

                                                                            })}


                                                                    </div>

                                                                    <div className="col-md-4 form-group">

                                                                        <label htmlFor=""><b>Reason : </b> <span style={{ color: "red" }}> *</span></label>
                                                                        <div className='d-flex align-items-end'>
                                                                            <TextArea
                                                                                name="remarks"
                                                                                className='form-control'
                                                                                placeholder='Enter Reason here'
                                                                                rows={1}
                                                                                maxLength={500}
                                                                                value={recalldata.reasons || undefined}
                                                                                id="remarks"
                                                                                style={{ width: '100%', marginTop: "5px" }}
                                                                                onKeyPress={(event) => {
                                                                                    if (! /^[A-Z0-9a-z _ .,-]+$/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    Setrecalldata({
                                                                                        ...recalldata,
                                                                                        reasons: e.target.value,
                                                                                    });
                                                                                }}

                                                                            >
                                                                            </TextArea>
                                                                            <span className="text-info ms-1 d-flex 
                                align-items-center justify-content-center" style={{ float: 'right' }}
                                                                                onClick={() => { showRemarksModal() }}>
                                                                                <PlusCircleOutlined />
                                                                            </span>
                                                                        </div>
                                                                        {validator1.current.message(
                                                                            'reasons',
                                                                            recalldata.reasons,
                                                                            ["required",
                                                                                // { regex: /^[A-Z0-9a-z _ .,-]+$/ }
                                                                            ],
                                                                            {
                                                                                className: `invalid-feedback ${AddSubmitForm ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Reason Required',
                                                                                    // regex: "Enter Valid Remarks"

                                                                                }

                                                                            })}

                                                                    </div>
                                                                    <Modal
                                                                        title="Remarks List" open={remarksModal} footer={null} onOk={handleOk}
                                                                        onCancel={handleCancel} className={"remarksClass add-service-prv modelradius close_ressign  " + localStorage.getItem('currentTheme')}
                                                                        maskClosable={false}>
                                                                        <Table
                                                                            className='userprivclass'
                                                                            columns={columns2}
                                                                            dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                                                                            bordered
                                                                            pagination={false}
                                                                            scroll={{ y: 400 }}
                                                                        />
                                                                    </Modal>

                                                                    <div className='col-md-4 mt-4'>
                                                                        <Button type="primary" shape="round" className='addbutton'
                                                                            style={{ background: "#198754", borderColor: "#198754" }}
                                                                            icon={<PlayCircleOutlined />} size='default' onClick={Onsubmit3}>
                                                                            Submit
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Panel>
                                                    </Collapse>
                                                </> : false}
                                            </div>
                                            <div className="mt-0 mx-2 " >
                                                {titledata && titledata.length > 0 &&
                                                    titledata.map((tempitem, i) => (
                                                        <>
                                                            <div className={`${localStorage.getItem('currentTheme')}`}>
                                                                <p className='text-white p-2 mt-2 mb-0 unit-header' style={{ borderRadius: '10px' }}>
                                                                    {tempitem.mainparent?.u_name}
                                                                </p>
                                                            </div>
                                                            {
                                                                tempitem.subchild.map((itemdata, i) => {
                                                                    console.log(itemdata, 'itemdataitemdata')
                                                                    const itemKey = itemdata.u_id && itemdata.u_id?.toString() + itemdata.index
                                                                    let currentTask = _.filter(itemdata.child, { task_type: 'Current Task' });
                                                                    let upcomingTask = _.filter(itemdata.child, { task_type: 'Upcoming Task' });
                                                                    console.log(currentTask, 'currentTask', upcomingTask, 'upcomingTask')
                                                                    return (

                                                                        <Collapse defaultActiveKey={['0']} className='cric'>

                                                                            {/* <Panel header={itemdata.parent}
                                                                                extra={<div style={{ width: '120px', textAlign: 'left' }}><span id={'parentid-' + itemdata.index} style={{ display: 'none' }}>{itemdata.index}</span><span>Total Tasks : {indexValue == '' ? itemdata.child[0].length + itemdata.child[1].length : itemdata.child.length}</span></div>}
                                                                                key={itemdata.index} > */}
                                                                            <Panel header={itemdata.parent}
                                                                                extra={<div style={{ width: '120px', textAlign: 'left' }}><span id={'parentid-' + itemdata.index} style={{ display: 'none' }}>{itemdata.child.length}</span></div>}
                                                                                key={itemdata.index} >

                                                                                <>
                                                                                    <div className={localStorage.getItem("currentTheme") + " ant-table-wrapper   "} >
                                                                                        <div className="ant-table ant-table-bordered ant-table-fixed-header">
                                                                                            <div className="ant-table-container">
                                                                                                <div className="ant-table-content taskrecalltable  ">
                                                                                                    <table >
                                                                                                        <thead className="ant-table-thead">
                                                                                                            <tr>
                                                                                                                <th
                                                                                                                    className="ant-table-cell"
                                                                                                                    style={{ width: '20px' }}
                                                                                                                >
                                                                                                                    <div className=' d-flex justify-content-evenly'>
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            title=""
                                                                                                                            id={itemKey}
                                                                                                                            name="allchecked"
                                                                                                                            className={"allChecked"}
                                                                                                                            onClick={(e) => {
                                                                                                                                let checked = e.target.checked;
                                                                                                                                selectAllTitleData(checked, tempitem.mainparent?.u_name + itemdata.parent, itemdata.child, itemdata.u_id && itemdata.u_id?.toString() + itemdata.index);

                                                                                                                            }}
                                                                                                                        />
                                                                                                                        <div className="position-relative">
                                                                                                                            <FilterFilled onClick={(e) => {
                                                                                                                                setshowDropDown(s => ({ [itemKey]: !s[itemKey] || false }))
                                                                                                                                e.stopPropagation()

                                                                                                                            }} />
                                                                                                                            {showDropDown[itemKey] && (
                                                                                                                                <Form key={itemdata.index} id={'form_' + itemKey} i className='bg-white position-absolute text-start shadow-lg' style={{ borderRadius: '15px', top: '32px', width: '165px', zIndex: 3 }}>

                                                                                                                                    <Form.Item className="mt-1" name={'periodical'} >
                                                                                                                                        &nbsp;<Checkbox id={'periodical'} value={'periodical'} checked={checked.periodical[0] == itemdata.index && checked.periodical[1]} onChange={(e) => {
                                                                                                                                            e.target.checked ? setChecked({
                                                                                                                                                ...checked,
                                                                                                                                                periodical: [itemdata.index, true]
                                                                                                                                            }) : setChecked({
                                                                                                                                                ...checked,
                                                                                                                                                periodical: [itemdata.index, false]
                                                                                                                                            })
                                                                                                                                            if (e.target.checked) {
                                                                                                                                                indexValue[itemKey + '_periodical'] = e.target.checked
                                                                                                                                            } else {
                                                                                                                                                delete indexValue[itemKey + '_periodical']
                                                                                                                                            }

                                                                                                                                            setFilterValues({
                                                                                                                                                ...filterValues,
                                                                                                                                                periodical: e.target.checked
                                                                                                                                            })
                                                                                                                                        }} />&nbsp; <label className="fw-normal user-select-none" htmlFor="periodical"><i style={{ color: 'green' }} className="bi bi-square-fill"></i> Periodical</label>
                                                                                                                                    </Form.Item>
                                                                                                                                    <Form.Item name={'flexi_review'}>
                                                                                                                                        &nbsp;<Checkbox id="flexi_review" value={'flexi review'} checked={checked.flexi_review[0] == itemdata.index && checked.flexi_review[1]} onChange={(e) => {
                                                                                                                                            e.target.checked ? setChecked({
                                                                                                                                                ...checked,
                                                                                                                                                flexi_review: [itemdata.index, true]
                                                                                                                                            }) : setChecked({
                                                                                                                                                ...checked,
                                                                                                                                                flexi_review: [itemdata.index, false]
                                                                                                                                            })

                                                                                                                                            if (e.target.checked) {
                                                                                                                                                indexValue[itemKey + '_flexi_review'] = e.target.checked
                                                                                                                                            } else {
                                                                                                                                                delete indexValue[itemKey + '_flexi_review']
                                                                                                                                            }
                                                                                                                                            setFilterValues({
                                                                                                                                                ...filterValues,
                                                                                                                                                flexi_review: e.target.checked
                                                                                                                                            })
                                                                                                                                        }} /> &nbsp;
                                                                                                                                        <label className="fw-normal user-select-none" htmlFor="flexi_review"><i style={{ color: "pink" }} className="bi bi-square-fill"></i> Flexi Review</label>
                                                                                                                                    </Form.Item>
                                                                                                                                    <Form.Item name={'Review'}>
                                                                                                                                        &nbsp;<Checkbox id="Review" value={'Review'} checked={checked.review[0] == itemdata.index && checked.review[1]} onChange={(e) => {
                                                                                                                                            e.target.checked ? setChecked({
                                                                                                                                                ...checked,
                                                                                                                                                review: [itemdata.index, true]
                                                                                                                                            }) : setChecked({
                                                                                                                                                ...checked,
                                                                                                                                                review: [itemdata.index, false]
                                                                                                                                            })

                                                                                                                                            if (e.target.checked) {
                                                                                                                                                indexValue[itemKey + '_review'] = e.target.checked
                                                                                                                                            } else {
                                                                                                                                                delete indexValue[itemKey + '_review']
                                                                                                                                            }
                                                                                                                                            setFilterValues({
                                                                                                                                                ...filterValues,
                                                                                                                                                review: e.target.checked
                                                                                                                                            })
                                                                                                                                        }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="Review"><i style={{ color: "orange" }} className="bi bi-square-fill"></i> Review</label>
                                                                                                                                    </Form.Item>
                                                                                                                                    <Form.Item name={'On Occurrence'}>
                                                                                                                                        &nbsp;<Checkbox id="On-Occurrence" value={'On Occurrence'} checked={checked.on_occurrence[0] == itemdata.index && checked.on_occurrence[1]} onChange={(e) => {
                                                                                                                                            e.target.checked ? setChecked({
                                                                                                                                                ...checked,
                                                                                                                                                on_occurrence: [itemdata.index, true]
                                                                                                                                            }) : setChecked({
                                                                                                                                                ...checked,
                                                                                                                                                on_occurrence: [itemdata.index, false]
                                                                                                                                            })

                                                                                                                                            if (e.target.checked) {
                                                                                                                                                indexValue[itemKey + '_on_occurrence'] = e.target.checked
                                                                                                                                            } else {
                                                                                                                                                delete indexValue[itemKey + '_on_occurrence']
                                                                                                                                            }
                                                                                                                                            setFilterValues({
                                                                                                                                                ...filterValues,
                                                                                                                                                on_occurrence: e.target.checked
                                                                                                                                            })
                                                                                                                                        }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="On-Occurrence"><i style={{ color: "yellow" }} className="bi bi-square-fill"></i> On Occurrence</label>
                                                                                                                                    </Form.Item>
                                                                                                                                    <Form.Item className="mb-1" name={' One Time'}>
                                                                                                                                        &nbsp;<Checkbox id="One-Time" value={'One Time'} checked={checked.one_time[0] == itemdata.index && checked.one_time[1]} onChange={(e) => {
                                                                                                                                            e.target.checked ? setChecked({
                                                                                                                                                ...checked,
                                                                                                                                                one_time: [itemdata.index, true]
                                                                                                                                            }) : setChecked({
                                                                                                                                                ...checked,
                                                                                                                                                one_time: [itemdata.index, false]
                                                                                                                                            })
                                                                                                                                            if (e.target.checked) {
                                                                                                                                                indexValue[itemKey + '_one_time'] = e.target.checked
                                                                                                                                            } else {
                                                                                                                                                delete indexValue[itemKey + '_one_time']
                                                                                                                                            }

                                                                                                                                            setFilterValues({
                                                                                                                                                ...filterValues,
                                                                                                                                                one_time: e.target.checked
                                                                                                                                            })
                                                                                                                                        }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="One-Time"><i style={{ color: "red" }} className="bi bi-square-fill"></i> One Time</label>
                                                                                                                                    </Form.Item>
                                                                                                                                    <Form.Item className="submitButtonFrm" >
                                                                                                                                        <Button style={{ borderRadius: '10px' }} className="statistic" type="primary" onClick={() => {
                                                                                                                                            onFinish(itemdata.index, itemKey,
                                                                                                                                                itemdata.common_id,
                                                                                                                                                itemdata.u_id)
                                                                                                                                        }} >OK</Button>
                                                                                                                                        <a className="link ms-2" onClick={() => {
                                                                                                                                            resetfilter(itemdata.index, itemKey,
                                                                                                                                                itemdata.common_id,
                                                                                                                                                itemdata.u_id)
                                                                                                                                        }}>Reset</a>
                                                                                                                                    </Form.Item>
                                                                                                                                </Form>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    className="ant-table-cell"
                                                                                                                    style={{ width: "200px" }}
                                                                                                                >
                                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                                        <p
                                                                                                                            className="p-0 mb-0"
                                                                                                                            style={{
                                                                                                                                color: indexValue[itemKey + '_compliance_name'] ? 'red' : ''
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            Compliance Task
                                                                                                                        </p>{" "}
                                                                                                                        <Popconfirm
                                                                                                                            id={itemdata.index}
                                                                                                                            afterVisibleChange={() => {
                                                                                                                                setFocus(inputRef.current[itemKey]);
                                                                                                                            }}

                                                                                                                            placement="bottom"
                                                                                                                            title={

                                                                                                                                <div className='position-relative'>
                                                                                                                                    <Input
                                                                                                                                        ref={element => inputRef.current[itemKey] = element}
                                                                                                                                        placeholder="Compliance Tasks"
                                                                                                                                        // autoFocus={'on'}
                                                                                                                                        onChange={(e) => {
                                                                                                                                            console.log(e.target.value, 'e.target.value')
                                                                                                                                            setSearchedValue({
                                                                                                                                                ...searchedValue,
                                                                                                                                                complianceTask: e.target.value
                                                                                                                                            });
                                                                                                                                            if (e.target.value) {
                                                                                                                                                searchingValue['compliance_name'] = e.target.value
                                                                                                                                                indexValue[itemKey + '_compliance_name'] = e.target.value
                                                                                                                                            } else {
                                                                                                                                                delete searchingValue['compliance_name']
                                                                                                                                                delete indexValue[itemKey + '_compliance_name']
                                                                                                                                            }

                                                                                                                                        }}
                                                                                                                                        value={indexValue[itemKey + '_compliance_name'] || undefined}
                                                                                                                                        onKeyUp={(e) => {

                                                                                                                                            compliancetaskfilter(
                                                                                                                                                e.target.value,
                                                                                                                                                "compliance_name",
                                                                                                                                                itemdata.index,
                                                                                                                                                itemdata.parent,
                                                                                                                                                tempitem.mainparent?.u_name,
                                                                                                                                                itemKey,
                                                                                                                                                itemdata.common_id,
                                                                                                                                                itemdata.u_id

                                                                                                                                            );

                                                                                                                                        }}

                                                                                                                                    />
                                                                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                                                                        inputRef.current[itemKey]['input'].value = '';
                                                                                                                                        setSearchedValue({
                                                                                                                                            ...searchedValue,
                                                                                                                                            complianceTask: ''
                                                                                                                                        });
                                                                                                                                        delete searchingValue['compliance_name']
                                                                                                                                        delete indexValue[itemKey + '_compliance_name']
                                                                                                                                        compliancetaskfilter(
                                                                                                                                            '',
                                                                                                                                            "compliance_name",
                                                                                                                                            itemdata.index,
                                                                                                                                            itemdata.parent,
                                                                                                                                            tempitem.mainparent?.u_name,
                                                                                                                                            itemKey,
                                                                                                                                            itemdata.common_id,
                                                                                                                                            itemdata.u_id

                                                                                                                                        );
                                                                                                                                        // indexValue[itemKey + '_compliance_name'] = ''

                                                                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <SearchOutlined
                                                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                                                            />
                                                                                                                        </Popconfirm>
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    className="ant-table-cell"
                                                                                                                    style={{ width: "90px" }}
                                                                                                                >
                                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                                        <p
                                                                                                                            className="p-0 mb-0"
                                                                                                                            style={{

                                                                                                                                display: "inline",

                                                                                                                                color: indexValue[itemKey + '_summary'] ? 'red' : ''

                                                                                                                            }}
                                                                                                                        >
                                                                                                                            Statutory Date
                                                                                                                        </p>{" "}
                                                                                                                        <Popconfirm
                                                                                                                            id={itemdata.index}
                                                                                                                            afterVisibleChange={() => {
                                                                                                                                setFocus(inputRef1.current[itemKey]);
                                                                                                                            }}
                                                                                                                            placement="bottom"
                                                                                                                            title={

                                                                                                                                <div className='position-relative'>
                                                                                                                                    <Input
                                                                                                                                        ref={element => inputRef1.current[itemKey] = element}
                                                                                                                                        placeholder="Statutory Date"
                                                                                                                                        onChange={(e) => {
                                                                                                                                            setSearchedValue({
                                                                                                                                                ...searchedValue,
                                                                                                                                                statutoryDate: e.target.value
                                                                                                                                            });
                                                                                                                                            if (e.target.value) {
                                                                                                                                                searchingValue['summary'] = e.target.value
                                                                                                                                                indexValue[itemKey + '_summary'] = e.target.value
                                                                                                                                            } else {
                                                                                                                                                delete searchingValue['summary']
                                                                                                                                                delete indexValue[itemKey + '_summary']
                                                                                                                                            }


                                                                                                                                        }}
                                                                                                                                        value={indexValue[itemKey + '_summary'] || undefined}
                                                                                                                                        onKeyUp={(e) => {

                                                                                                                                            compliancetaskfilter(
                                                                                                                                                e.target.value,
                                                                                                                                                "summary",
                                                                                                                                                itemdata.index,
                                                                                                                                                itemdata.parent,
                                                                                                                                                tempitem.mainparent?.u_name,
                                                                                                                                                itemKey,
                                                                                                                                                itemdata.common_id,
                                                                                                                                                itemdata.u_id

                                                                                                                                            );

                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                                                                        inputRef1.current[itemKey]['input'].value = '';
                                                                                                                                        setSearchedValue({
                                                                                                                                            ...searchedValue,
                                                                                                                                            statutoryDate: ''
                                                                                                                                        });
                                                                                                                                        delete searchingValue['summary']
                                                                                                                                        delete indexValue[itemKey + '_summary']
                                                                                                                                        compliancetaskfilter(
                                                                                                                                            '',
                                                                                                                                            "summary",
                                                                                                                                            itemdata.index,
                                                                                                                                            itemdata.parent,
                                                                                                                                            tempitem.mainparent?.u_name,
                                                                                                                                            itemKey,
                                                                                                                                            itemdata.common_id,
                                                                                                                                            itemdata.u_id

                                                                                                                                        );

                                                                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <SearchOutlined
                                                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                                                            />
                                                                                                                        </Popconfirm>
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    className="ant-table-cell"
                                                                                                                    style={{ width: "80px" }}
                                                                                                                >
                                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                                        <p
                                                                                                                            className="p-0 mb-0"
                                                                                                                            style={{

                                                                                                                                display: "inline",

                                                                                                                                color: indexValue[itemKey + '_trigger_before_days'] ? 'red' : ''

                                                                                                                            }}
                                                                                                                        >
                                                                                                                            Trigger Before
                                                                                                                            (Days)
                                                                                                                        </p>{" "}
                                                                                                                        <Popconfirm
                                                                                                                            id={itemdata.index}
                                                                                                                            afterVisibleChange={() => {
                                                                                                                                setFocus(inputRef2.current[itemKey]);
                                                                                                                            }}
                                                                                                                            placement="bottom"
                                                                                                                            title={

                                                                                                                                <div className='position-relative'>
                                                                                                                                    <Input
                                                                                                                                        ref={element => inputRef2.current[itemKey] = element}
                                                                                                                                        placeholder="Trigger Before (Days)"
                                                                                                                                        onChange={(e) => {
                                                                                                                                            setSearchedValue({
                                                                                                                                                ...searchedValue,
                                                                                                                                                triggerBeforeDays: e.target.value
                                                                                                                                            });
                                                                                                                                            if (e.target.value) {
                                                                                                                                                searchingValue['trigger_before_days'] = e.target.value
                                                                                                                                                indexValue[itemKey + '_trigger_before_days'] = e.target.value
                                                                                                                                            } else {
                                                                                                                                                delete searchingValue['trigger_before_days']
                                                                                                                                                delete indexValue[itemKey + '_trigger_before_days']
                                                                                                                                            }
                                                                                                                                        }}
                                                                                                                                        value={indexValue[itemKey + '_trigger_before_days'] || undefined}
                                                                                                                                        onKeyUp={(e) => {

                                                                                                                                            compliancetaskfilter(
                                                                                                                                                e.target.value,
                                                                                                                                                "trigger_before_days",
                                                                                                                                                itemdata.index,
                                                                                                                                                itemdata.parent,
                                                                                                                                                tempitem.mainparent?.u_name,
                                                                                                                                                itemKey,
                                                                                                                                                itemdata.common_id,
                                                                                                                                                itemdata.u_id

                                                                                                                                            );

                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                                                                        inputRef2.current[itemKey]['input'].value = '';
                                                                                                                                        setSearchedValue({
                                                                                                                                            ...searchedValue,
                                                                                                                                            triggerBeforeDays: ''
                                                                                                                                        });
                                                                                                                                        delete searchingValue['trigger_before_days']
                                                                                                                                        delete indexValue[itemKey + '_trigger_before_days']

                                                                                                                                        compliancetaskfilter(
                                                                                                                                            '',
                                                                                                                                            "trigger_before_days",
                                                                                                                                            itemdata.index,
                                                                                                                                            itemdata.parent,
                                                                                                                                            tempitem.mainparent?.u_name,
                                                                                                                                            itemKey,
                                                                                                                                            itemdata.common_id,
                                                                                                                                            itemdata.u_id
                                                                                                                                        );

                                                                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <SearchOutlined
                                                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                                                            />
                                                                                                                        </Popconfirm>
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    className="ant-table-cell"
                                                                                                                    style={{ width: "70px" }}
                                                                                                                >
                                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                                        <p
                                                                                                                            className="p-0 mb-0"
                                                                                                                            style={{

                                                                                                                                display: "inline",

                                                                                                                                color: indexValue[itemKey + '_assignee_name'] ? 'red' : ''

                                                                                                                            }}
                                                                                                                        >
                                                                                                                            Assignee
                                                                                                                        </p>{" "}
                                                                                                                        <Popconfirm
                                                                                                                            id={itemdata.index}
                                                                                                                            afterVisibleChange={() => {
                                                                                                                                setFocus(inputRef4.current[itemKey]);
                                                                                                                            }}
                                                                                                                            placement="bottom"
                                                                                                                            title={

                                                                                                                                <div className='position-relative'>
                                                                                                                                    <Input
                                                                                                                                        ref={element => inputRef4.current[itemKey] = element}
                                                                                                                                        placeholder="Assignee"
                                                                                                                                        onChange={(e) => {
                                                                                                                                            setSearchedValue({
                                                                                                                                                ...searchedValue,
                                                                                                                                                assignee: e.target.value
                                                                                                                                            });
                                                                                                                                            if (e.target.value) {
                                                                                                                                                searchingValue['assignee_name'] = e.target.value
                                                                                                                                                indexValue[itemKey + '_assignee_name'] = e.target.value
                                                                                                                                            } else {
                                                                                                                                                delete searchingValue['assignee_name']
                                                                                                                                                delete indexValue[itemKey + '_assignee_name']
                                                                                                                                            }
                                                                                                                                        }}
                                                                                                                                        value={indexValue[itemKey + '_assignee_name'] || undefined}
                                                                                                                                        onKeyUp={(e) => {

                                                                                                                                            compliancetaskfilter(
                                                                                                                                                e.target.value,
                                                                                                                                                "assignee_name",
                                                                                                                                                itemdata.index,
                                                                                                                                                itemdata.parent,
                                                                                                                                                tempitem.mainparent?.u_name,
                                                                                                                                                itemKey,
                                                                                                                                                itemdata.common_id,
                                                                                                                                                itemdata.u_id

                                                                                                                                            );

                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                                                                        inputRef4.current[itemKey]['input'].value = '';
                                                                                                                                        setSearchedValue({
                                                                                                                                            ...searchedValue,
                                                                                                                                            assignee: ''
                                                                                                                                        });
                                                                                                                                        delete searchingValue['assignee_name']
                                                                                                                                        delete indexValue[itemKey + '_assignee_name']
                                                                                                                                        compliancetaskfilter(
                                                                                                                                            '',
                                                                                                                                            "assignee_name",
                                                                                                                                            itemdata.index,
                                                                                                                                            itemdata.parent,
                                                                                                                                            tempitem.mainparent?.u_name,
                                                                                                                                            itemKey,
                                                                                                                                            itemdata.common_id,
                                                                                                                                            itemdata.u_id

                                                                                                                                        );

                                                                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <SearchOutlined
                                                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                                                            />
                                                                                                                        </Popconfirm>
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    className="ant-table-cell"
                                                                                                                    style={{ width: "70px" }}
                                                                                                                >
                                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                                        <p
                                                                                                                            className="p-0 mb-0"
                                                                                                                            style={{

                                                                                                                                display: "inline",

                                                                                                                                color: indexValue[itemKey + '_concurrer_name'] ? 'red' : ''

                                                                                                                            }}
                                                                                                                        >
                                                                                                                            Concur
                                                                                                                        </p>{" "}
                                                                                                                        <Popconfirm
                                                                                                                            id={itemdata.index}
                                                                                                                            afterVisibleChange={() => {
                                                                                                                                setFocus(inputRef5.current[itemKey]);
                                                                                                                            }}
                                                                                                                            placement="bottom"
                                                                                                                            title={

                                                                                                                                <div className='position-relative'>
                                                                                                                                    <Input
                                                                                                                                        ref={element => inputRef5.current[itemKey] = element}
                                                                                                                                        placeholder="Concur"
                                                                                                                                        onChange={(e) => {
                                                                                                                                            setSearchedValue({
                                                                                                                                                ...searchedValue,
                                                                                                                                                concur: e.target.value
                                                                                                                                            });
                                                                                                                                            if (e.target.value) {
                                                                                                                                                searchingValue['concurrer_name'] = e.target.value
                                                                                                                                                indexValue[itemKey + '_concurrer_name'] = e.target.value
                                                                                                                                            } else {
                                                                                                                                                delete searchingValue['concurrer_name']
                                                                                                                                                delete indexValue[itemKey + '_concurrer_name']
                                                                                                                                            }
                                                                                                                                        }}
                                                                                                                                        value={indexValue[itemKey + '_concurrer_name'] || undefined}
                                                                                                                                        onKeyUp={(e) => {

                                                                                                                                            compliancetaskfilter(
                                                                                                                                                e.target.value,
                                                                                                                                                "concurrer_name",
                                                                                                                                                itemdata.index,
                                                                                                                                                itemdata.parent,
                                                                                                                                                tempitem.mainparent?.u_name,
                                                                                                                                                itemKey,
                                                                                                                                                itemdata.common_id,
                                                                                                                                                itemdata.u_id

                                                                                                                                            );

                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                                                                        inputRef5.current[itemKey]['input'].value = '';
                                                                                                                                        setSearchedValue({
                                                                                                                                            ...searchedValue,
                                                                                                                                            concur: ''
                                                                                                                                        });
                                                                                                                                        delete searchingValue['concurrer_name']
                                                                                                                                        delete indexValue[itemKey + '_concurrer_name']

                                                                                                                                        compliancetaskfilter(
                                                                                                                                            '',
                                                                                                                                            "concurrer_name",
                                                                                                                                            itemdata.index,
                                                                                                                                            itemdata.parent,
                                                                                                                                            tempitem.mainparent?.u_name,
                                                                                                                                            itemKey,
                                                                                                                                            itemdata.common_id,
                                                                                                                                            itemdata.u_id

                                                                                                                                        );

                                                                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <SearchOutlined
                                                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                                                            />
                                                                                                                        </Popconfirm>
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    className="ant-table-cell"
                                                                                                                    style={{ width: "70px" }}
                                                                                                                >
                                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                                        <p
                                                                                                                            className="p-0 mb-0"
                                                                                                                            style={{

                                                                                                                                display: "inline",

                                                                                                                                color: indexValue[itemKey + '_approver_name'] ? 'red' : ''

                                                                                                                            }}
                                                                                                                        >
                                                                                                                            Approver
                                                                                                                        </p>{" "}
                                                                                                                        <Popconfirm
                                                                                                                            id={itemdata.index}
                                                                                                                            afterVisibleChange={() => {
                                                                                                                                setFocus(inputRef6.current[itemKey]);
                                                                                                                            }}
                                                                                                                            placement="bottom"
                                                                                                                            title={

                                                                                                                                <div className='position-relative'>
                                                                                                                                    <Input
                                                                                                                                        ref={element => inputRef6.current[itemKey] = element}
                                                                                                                                        placeholder="Approver"
                                                                                                                                        onChange={(e) => {
                                                                                                                                            setSearchedValue({
                                                                                                                                                ...searchedValue,
                                                                                                                                                approver: e.target.value
                                                                                                                                            });
                                                                                                                                            if (e.target.value) {
                                                                                                                                                searchingValue['approver_name'] = e.target.value
                                                                                                                                                indexValue[itemKey + '_approver_name'] = e.target.value
                                                                                                                                            } else {
                                                                                                                                                delete searchingValue['approver_name']
                                                                                                                                                delete indexValue[itemKey + '_approver_name']
                                                                                                                                            }
                                                                                                                                        }}
                                                                                                                                        value={indexValue[itemKey + '_approver_name'] || undefined}
                                                                                                                                        onKeyUp={(e) => {

                                                                                                                                            compliancetaskfilter(
                                                                                                                                                e.target.value,
                                                                                                                                                "approver_name",
                                                                                                                                                itemdata.index,
                                                                                                                                                itemdata.parent,
                                                                                                                                                tempitem.mainparent?.u_name,
                                                                                                                                                itemKey,
                                                                                                                                                itemdata.common_id,
                                                                                                                                                itemdata.u_id

                                                                                                                                            );

                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                                                                        inputRef6.current[itemKey]['input'].value = '';
                                                                                                                                        setSearchedValue({
                                                                                                                                            ...searchedValue,
                                                                                                                                            approver: ''
                                                                                                                                        });
                                                                                                                                        delete searchingValue['approver_name']
                                                                                                                                        delete indexValue[itemKey + '_approver_name']

                                                                                                                                        compliancetaskfilter(
                                                                                                                                            '',
                                                                                                                                            "approver_name",
                                                                                                                                            itemdata.index,
                                                                                                                                            itemdata.parent,
                                                                                                                                            tempitem.mainparent?.u_name,
                                                                                                                                            itemKey,
                                                                                                                                            itemdata.common_id,
                                                                                                                                            itemdata.u_id

                                                                                                                                        );

                                                                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <SearchOutlined
                                                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                                                            />
                                                                                                                        </Popconfirm>
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    className="ant-table-cell"
                                                                                                                    style={{ width: "90px" }}
                                                                                                                >
                                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                                        <p
                                                                                                                            className="p-0 mb-0"
                                                                                                                            style={{

                                                                                                                                display: "inline",

                                                                                                                                color: indexValue[itemKey + '_d_date'] ? 'red' : ''

                                                                                                                            }}
                                                                                                                        >
                                                                                                                            Due Date
                                                                                                                        </p> {" "}
                                                                                                                        <Popconfirm
                                                                                                                            id={itemdata.index}
                                                                                                                            afterVisibleChange={() => {
                                                                                                                                setFocus(inputRef7.current[itemKey]);
                                                                                                                            }}
                                                                                                                            placement="bottom"
                                                                                                                            title={

                                                                                                                                <div className='position-relative'>
                                                                                                                                    <Input
                                                                                                                                        ref={element => inputRef7.current[itemKey] = element}
                                                                                                                                        placeholder="Due Date"
                                                                                                                                        onChange={(e) => {
                                                                                                                                            setSearchedValue({
                                                                                                                                                ...searchedValue,
                                                                                                                                                duedate: e.target.value
                                                                                                                                            });
                                                                                                                                            if (e.target.value) {
                                                                                                                                                searchingValue['d_date'] = e.target.value
                                                                                                                                                indexValue[itemKey + '_d_date'] = e.target.value
                                                                                                                                            } else {
                                                                                                                                                delete searchingValue['d_date']
                                                                                                                                                delete indexValue[itemKey + '_d_date']
                                                                                                                                            }
                                                                                                                                        }}
                                                                                                                                        value={indexValue[itemKey + '_d_date'] || undefined}
                                                                                                                                        hidden={itemdata.frequency == "On Occurrence"}

                                                                                                                                        onKeyUp={(e) => {

                                                                                                                                            compliancetaskfilter(
                                                                                                                                                e.target.value,
                                                                                                                                                "d_date",
                                                                                                                                                itemdata.index,
                                                                                                                                                itemdata.parent,
                                                                                                                                                tempitem.mainparent?.u_name,
                                                                                                                                                itemKey,
                                                                                                                                                itemdata.common_id,
                                                                                                                                                itemdata.u_id

                                                                                                                                            );

                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                                                                        inputRef7.current[itemKey]['input'].value = '';
                                                                                                                                        indexValue[itemKey + '_d_date'] = ''
                                                                                                                                        setSearchedValue({
                                                                                                                                            ...searchedValue,
                                                                                                                                            duedate: ''
                                                                                                                                        });
                                                                                                                                        delete searchingValue['d_date']
                                                                                                                                        delete indexValue[itemKey + '_d_date']

                                                                                                                                        compliancetaskfilter(
                                                                                                                                            '',
                                                                                                                                            "d_date",
                                                                                                                                            itemdata.index,
                                                                                                                                            itemdata.parent,
                                                                                                                                            tempitem.mainparent?.u_name,
                                                                                                                                            itemKey,
                                                                                                                                            itemdata.common_id,
                                                                                                                                            itemdata.u_id
                                                                                                                                        );

                                                                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <SearchOutlined
                                                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                                                            />
                                                                                                                        </Popconfirm>
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    className="ant-table-cell"
                                                                                                                    style={{ width: "90px" }}
                                                                                                                >
                                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                                        <p
                                                                                                                            className="mb-0"
                                                                                                                            style={{
                                                                                                                                marginLeft: "2px",
                                                                                                                                display: "inline",

                                                                                                                                color: indexValue[itemKey + '_v_date'] ? 'red' : ''

                                                                                                                            }}
                                                                                                                        >
                                                                                                                            Validity Date
                                                                                                                        </p> {" "}
                                                                                                                        <Popconfirm
                                                                                                                            id={itemdata.index}
                                                                                                                            afterVisibleChange={() => {
                                                                                                                                setFocus(inputRef8.current[itemKey]);
                                                                                                                            }}
                                                                                                                            placement="bottomLeft"
                                                                                                                            title={

                                                                                                                                <div className='position-relative'>
                                                                                                                                    <Input
                                                                                                                                        ref={element => inputRef8.current[itemKey] = element}
                                                                                                                                        placeholder="Validity Date"
                                                                                                                                        onChange={(e) => {
                                                                                                                                            setSearchedValue({
                                                                                                                                                ...searchedValue,
                                                                                                                                                validityDate: e.target.value
                                                                                                                                            });
                                                                                                                                            if (e.target.value) {
                                                                                                                                                searchingValue['v_date'] = e.target.value
                                                                                                                                                indexValue[itemKey + '_v_date'] = e.target.value;
                                                                                                                                            } else {
                                                                                                                                                delete searchingValue['v_date']
                                                                                                                                                delete indexValue[itemKey + '_v_date']
                                                                                                                                            }

                                                                                                                                        }}
                                                                                                                                        value={indexValue[itemKey + '_v_date'] || undefined}
                                                                                                                                        onKeyUp={(e) => {

                                                                                                                                            compliancetaskfilter(
                                                                                                                                                e.target.value,
                                                                                                                                                "v_date",
                                                                                                                                                itemdata.index,
                                                                                                                                                itemdata.parent,
                                                                                                                                                tempitem.mainparent?.u_name,
                                                                                                                                                itemKey,
                                                                                                                                                itemdata.common_id,
                                                                                                                                                itemdata.u_id

                                                                                                                                            );

                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                                                                        inputRef8.current[itemKey]['input'].value = '';
                                                                                                                                        setSearchedValue({
                                                                                                                                            ...searchedValue,
                                                                                                                                            validityDate: ''
                                                                                                                                        });
                                                                                                                                        delete searchingValue['v_date']
                                                                                                                                        delete indexValue[itemKey + '_v_date']

                                                                                                                                        compliancetaskfilter(
                                                                                                                                            '',
                                                                                                                                            "v_date",
                                                                                                                                            itemdata.index,
                                                                                                                                            itemdata.parent,
                                                                                                                                            tempitem.mainparent?.u_name,
                                                                                                                                            itemKey,
                                                                                                                                            itemdata.common_id,
                                                                                                                                            itemdata.u_id

                                                                                                                                        );

                                                                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <SearchOutlined
                                                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                                                            />
                                                                                                                        </Popconfirm>

                                                                                                                    </div>

                                                                                                                </th>

                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody className="ant-table-tbody">
                                                                                                            {/* {(() =>  */}
                                                                                                            <>
                                                                                                                {currentTask && currentTask.length > 0 ?
                                                                                                                    <>
                                                                                                                        <>
                                                                                                                            {currentTask && currentTask.length > 0 ?
                                                                                                                                <Fragment>
                                                                                                                                    <tr className="ant-table-row ant-table-row-level-0">
                                                                                                                                        <td colSpan={12} className="ant-table-cell"><b>Current Task</b></td>
                                                                                                                                    </tr></Fragment> : ''}</>

                                                                                                                        <>{
                                                                                                                            currentTask.map((currentList, index) => {
                                                                                                                                return <Fragment >

                                                                                                                                    <tr className="ant-table-row ant-table-row-level-0">

                                                                                                                                        <td className="ant-table-cell">
                                                                                                                                            <center>

                                                                                                                                                <input type="checkbox"
                                                                                                                                                    name={currentList.common_id}
                                                                                                                                                    id={tempitem.mainparent?.u_name + currentList.act_name}
                                                                                                                                                    onClick={(e) => {
                                                                                                                                                        // let temp = [] selectA
                                                                                                                                                        // temp.push(itemdata.comp_id)
                                                                                                                                                        // setRowCompId([...rowcompid, ...temp])
                                                                                                                                                        let checked = e.target.checked;
                                                                                                                                                        addSelectedDataValues(
                                                                                                                                                            checked,
                                                                                                                                                            currentList.common_id,
                                                                                                                                                            currentList.ac_id,
                                                                                                                                                            currentList,
                                                                                                                                                        );
                                                                                                                                                    }}
                                                                                                                                                />


                                                                                                                                                {currentList.frequency ==
                                                                                                                                                    "Periodical" ? (
                                                                                                                                                    <p>
                                                                                                                                                        <i
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "green",
                                                                                                                                                            }}
                                                                                                                                                            title={
                                                                                                                                                                currentList.frequency
                                                                                                                                                            }
                                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                                        ></i>
                                                                                                                                                    </p>
                                                                                                                                                ) : currentList.frequency ==
                                                                                                                                                    "Flexi Review" ? (
                                                                                                                                                    <p>
                                                                                                                                                        <i
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "pink",
                                                                                                                                                            }}
                                                                                                                                                            title={
                                                                                                                                                                currentList.frequency
                                                                                                                                                            }
                                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                                        ></i>
                                                                                                                                                    </p>
                                                                                                                                                ) : currentList.frequency ==
                                                                                                                                                    "Review" ? (
                                                                                                                                                    <p>
                                                                                                                                                        <i
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "orange",
                                                                                                                                                            }}
                                                                                                                                                            title={
                                                                                                                                                                currentList.frequency
                                                                                                                                                            }
                                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                                        ></i>
                                                                                                                                                    </p>
                                                                                                                                                ) : currentList.frequency ==
                                                                                                                                                    "On Occurrence" ? (
                                                                                                                                                    <p>
                                                                                                                                                        <i
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "yellow",
                                                                                                                                                            }}
                                                                                                                                                            title={
                                                                                                                                                                currentList.frequency
                                                                                                                                                            }
                                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                                        ></i>
                                                                                                                                                    </p>
                                                                                                                                                ) : (
                                                                                                                                                    <p>
                                                                                                                                                        <i
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "red",
                                                                                                                                                            }}
                                                                                                                                                            title={
                                                                                                                                                                currentList.frequency
                                                                                                                                                            }
                                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                                        ></i>
                                                                                                                                                    </p>
                                                                                                                                                )}

                                                                                                                                            </center>
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            <>
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        cursor:
                                                                                                                                                            "pointer",
                                                                                                                                                    }}

                                                                                                                                                >


                                                                                                                                                    {<div className='row'>
                                                                                                                                                        {currentList.compfielaw_url ?
                                                                                                                                                            <span >
                                                                                                                                                                <Tooltip title={currentList.compliance_description}>
                                                                                                                                                                    <InfoCircleOutlined style={{ marginRight: '1px', color: 'blue' }} />
                                                                                                                                                                </Tooltip>
                                                                                                                                                                <Tooltip title={"Compfie Law Knowledge Portal"}>
                                                                                                                                                                    <a href={`${currentList.compfielaw_url}`}>
                                                                                                                                                                        <BookTwoTone />
                                                                                                                                                                    </a>
                                                                                                                                                                </Tooltip>&nbsp;
                                                                                                                                                                <span >{currentList.compliance_name}</span>
                                                                                                                                                            </span> : <span style={{}}>
                                                                                                                                                                <Tooltip title={currentList.compliance_description}>
                                                                                                                                                                    <InfoCircleOutlined style={{ marginRight: '1px', color: 'blue' }} />
                                                                                                                                                                </Tooltip>
                                                                                                                                                                <span>  &nbsp;{currentList.compliance_name}</span>
                                                                                                                                                            </span>}
                                                                                                                                                    </div>}

                                                                                                                                                </span>


                                                                                                                                            </>
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            {
                                                                                                                                                currentList.summary
                                                                                                                                            }
                                                                                                                                        </td>

                                                                                                                                        <td><center>
                                                                                                                                            {
                                                                                                                                                currentList.trigger_before_days == 0 ? "-" : currentList.trigger_before_days 
                                                                                                                                            }
                                                                                                                                        </center>

                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            {
                                                                                                                                                currentList.assignee_name
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            {
                                                                                                                                                currentList.concurrer_name
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            {
                                                                                                                                                currentList.approver_name
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                        <td style={{ textAlign: 'center' }}>
                                                                                                                                            {
                                                                                                                                                <>
                                                                                                                                                    {currentList.c_h_id !== null ?

                                                                                                                                                        <label> <center>  {currentList.d_date ? moment(currentList.d_date).format("DD-MMM-YYYY") : ''}

                                                                                                                                                        </center>   </label>
                                                                                                                                                        :


                                                                                                                                                        <>
                                                                                                                                                            <label>{currentList.d_date ? moment(currentList.d_date).format("DD-MMM-YYYY") : ''}</label>
                                                                                                                                                            {(temparr1.filter((e) => e.comp_id == currentList.comp_id).length > 0) ?
                                                                                                                                                                <center>
                                                                                                                                                                    <DatePicker format={'DD-MMM-YYYY'}
                                                                                                                                                                        renderExtraFooter={() => <center><b>{currentList.tz_name && currentList.tz_name}</b></center>}
                                                                                                                                                                        style={{ width: "140px", height: "30px", visibility: currentList.frequency == "On Occurrence" ? 'hidden' : 'visible' }} className="form-control"
                                                                                                                                                                        disabledDate={(current) => {
                                                                                                                                                                            //    return current && current < moment().startOf('day')
                                                                                                                                                                            return (current < moment().startOf('day') || current > moment(currentList.d_date).endOf('day'))
                                                                                                                                                                        }}
                                                                                                                                                                        // max={moment(itemdata?.d_date).format("YYYY-MM-DD")}
                                                                                                                                                                        hidden={currentList.frequency == "On Occurrence"}
                                                                                                                                                                        max={moment(currentList?.d_date).format("YYYY-MM-DD")}
                                                                                                                                                                        min={moment(date).format("YYYY-MM-DD")}
                                                                                                                                                                        placeholder={'DD-MM-YYYY'}
                                                                                                                                                                        onKeyDown={(event) => {
                                                                                                                                                                            event.preventDefault()
                                                                                                                                                                        }}
                                                                                                                                                                        // value={moment(itemdata?.d_date).format("DD-mmm-YYYY")}
                                                                                                                                                                        onChange={(e) => {
                                                                                                                                                                            console.log(e._d, 'duedateissue')
                                                                                                                                                                            let temparr = []
                                                                                                                                                                            temparr.push({
                                                                                                                                                                                date: e._d,
                                                                                                                                                                                comp_id: currentList.comp_id,
                                                                                                                                                                                ac_id: currentList.ac_id,
                                                                                                                                                                                index: i
                                                                                                                                                                            })

                                                                                                                                                                            setUpdateduedate([...updateduedate, ...temparr])

                                                                                                                                                                        }}

                                                                                                                                                                    /></center>
                                                                                                                                                                : ""}
                                                                                                                                                        </>

                                                                                                                                                    }

                                                                                                                                                </>
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            {
                                                                                                                                                currentList.v_date
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                    </tr>


                                                                                                                                </Fragment>
                                                                                                                            })
                                                                                                                        } </>
                                                                                                                    </> : false}
                                                                                                                {upcomingTask && upcomingTask.length > 0 ?
                                                                                                                    <>
                                                                                                                        <>
                                                                                                                            {upcomingTask && upcomingTask.length > 0 ?
                                                                                                                                <Fragment>
                                                                                                                                    <tr className="ant-table-row ant-table-row-level-0">
                                                                                                                                        <td colSpan={12} className="ant-table-cell"><b>Upcoming Task</b></td>
                                                                                                                                    </tr></Fragment> : ''}
                                                                                                                        </>
                                                                                                                        <>{
                                                                                                                            upcomingTask.map((upcomingList, index) => {
                                                                                                                                return <Fragment >

                                                                                                                                    <tr className="ant-table-row ant-table-row-level-0">

                                                                                                                                        <td className="ant-table-cell">
                                                                                                                                            <center>

                                                                                                                                                <input type="checkbox"
                                                                                                                                                    name={upcomingList.common_id}
                                                                                                                                                    id={tempitem.mainparent?.u_name + upcomingList.act_name}
                                                                                                                                                    onClick={(e) => {
                                                                                                                                                        // let temp = [] selectA
                                                                                                                                                        // temp.push(itemdata.comp_id)
                                                                                                                                                        // setRowCompId([...rowcompid, ...temp])
                                                                                                                                                        let checked = e.target.checked;
                                                                                                                                                        addSelectedDataValues(
                                                                                                                                                            checked,
                                                                                                                                                            upcomingList.common_id,
                                                                                                                                                            upcomingList.ac_id,
                                                                                                                                                            upcomingList,
                                                                                                                                                        );
                                                                                                                                                    }}
                                                                                                                                                />


                                                                                                                                                {upcomingList.frequency ==
                                                                                                                                                    "Periodical" ? (
                                                                                                                                                    <p>
                                                                                                                                                        <i
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "green",
                                                                                                                                                            }}
                                                                                                                                                            title={
                                                                                                                                                                upcomingList.frequency
                                                                                                                                                            }
                                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                                        ></i>
                                                                                                                                                    </p>
                                                                                                                                                ) : upcomingList.frequency ==
                                                                                                                                                    "Flexi Review" ? (
                                                                                                                                                    <p>
                                                                                                                                                        <i
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "pink",
                                                                                                                                                            }}
                                                                                                                                                            title={
                                                                                                                                                                upcomingList.frequency
                                                                                                                                                            }
                                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                                        ></i>
                                                                                                                                                    </p>
                                                                                                                                                ) : upcomingList.frequency ==
                                                                                                                                                    "Review" ? (
                                                                                                                                                    <p>
                                                                                                                                                        <i
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "orange",
                                                                                                                                                            }}
                                                                                                                                                            title={
                                                                                                                                                                upcomingList.frequency
                                                                                                                                                            }
                                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                                        ></i>
                                                                                                                                                    </p>
                                                                                                                                                ) : upcomingList.frequency ==
                                                                                                                                                    "On Occurrence" ? (
                                                                                                                                                    <p>
                                                                                                                                                        <i
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "yellow",
                                                                                                                                                            }}
                                                                                                                                                            title={
                                                                                                                                                                upcomingList.frequency
                                                                                                                                                            }
                                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                                        ></i>
                                                                                                                                                    </p>
                                                                                                                                                ) : (
                                                                                                                                                    <p>
                                                                                                                                                        <i
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "red",
                                                                                                                                                            }}
                                                                                                                                                            title={
                                                                                                                                                                upcomingList.frequency
                                                                                                                                                            }
                                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                                        ></i>
                                                                                                                                                    </p>
                                                                                                                                                )}

                                                                                                                                            </center>
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            <>
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        cursor:
                                                                                                                                                            "pointer",
                                                                                                                                                    }}

                                                                                                                                                >


                                                                                                                                                    {<div className='row'>
                                                                                                                                                        {upcomingList.compfielaw_url ?
                                                                                                                                                            <span >
                                                                                                                                                                <Tooltip title={upcomingList.compliance_description}>
                                                                                                                                                                    <InfoCircleOutlined style={{ marginRight: '1px', color: 'blue' }} />
                                                                                                                                                                </Tooltip>
                                                                                                                                                                <Tooltip title={"Compfie Law Knowledge Portal"}>
                                                                                                                                                                    <a href={`${upcomingList.compfielaw_url}`}>
                                                                                                                                                                        <BookTwoTone />
                                                                                                                                                                    </a>
                                                                                                                                                                </Tooltip>&nbsp;
                                                                                                                                                                <span >{upcomingList.compliance_name}</span>
                                                                                                                                                            </span> : <span style={{}}>
                                                                                                                                                                <Tooltip title={upcomingList.compliance_description}>
                                                                                                                                                                    <InfoCircleOutlined style={{ marginRight: '1px', color: 'blue' }} />
                                                                                                                                                                </Tooltip>
                                                                                                                                                                <span>  &nbsp;{upcomingList.compliance_name}</span>
                                                                                                                                                            </span>}
                                                                                                                                                    </div>}

                                                                                                                                                </span>


                                                                                                                                            </>
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            {
                                                                                                                                                upcomingList.summary
                                                                                                                                            }
                                                                                                                                        </td>

                                                                                                                                        <td><center>
                                                                                                                                            {
                                                                                                                                                upcomingList.trigger_before_days
                                                                                                                                            }
                                                                                                                                        </center>

                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            {
                                                                                                                                                upcomingList.assignee_name
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            {
                                                                                                                                                upcomingList.concurrer_name
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            {
                                                                                                                                                upcomingList.approver_name
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                        <td style={{ textAlign: 'center' }}>
                                                                                                                                            {
                                                                                                                                                <>
                                                                                                                                                    {upcomingList.c_h_id !== null ?

                                                                                                                                                        <label> <center>  {upcomingList.d_date ? moment(upcomingList.d_date).format("DD-MMM-YYYY") : ''}

                                                                                                                                                        </center>   </label>
                                                                                                                                                        :


                                                                                                                                                        <>
                                                                                                                                                            <label>{upcomingList.d_date ? moment(upcomingList.d_date).format("DD-MMM-YYYY") : ''}</label>
                                                                                                                                                            {(temparr1.filter((e) => e.comp_id == upcomingList.comp_id).length > 0) ?
                                                                                                                                                                <center>
                                                                                                                                                                    <DatePicker format={'DD-MMM-YYYY'}
                                                                                                                                                                        renderExtraFooter={() => <center><b>{upcomingList.tz_name && upcomingList.tz_name}</b></center>}
                                                                                                                                                                        style={{ width: "140px", height: "30px", visibility: upcomingList.frequency == "On Occurrence" ? 'hidden' : 'visible' }} className="form-control"
                                                                                                                                                                        disabledDate={(current) => {
                                                                                                                                                                            //    return current && current < moment().startOf('day')
                                                                                                                                                                            return (current < moment().startOf('day') || current > moment(upcomingList.d_date).endOf('day'))
                                                                                                                                                                        }}
                                                                                                                                                                        // max={moment(itemdata?.d_date).format("YYYY-MM-DD")}
                                                                                                                                                                        hidden={upcomingList.frequency == "On Occurrence"}
                                                                                                                                                                        max={moment(upcomingList?.d_date).format("YYYY-MM-DD")}
                                                                                                                                                                        min={moment(date).format("YYYY-MM-DD")}
                                                                                                                                                                        placeholder={'DD-MM-YYYY'}
                                                                                                                                                                        onKeyDown={(event) => {
                                                                                                                                                                            event.preventDefault()
                                                                                                                                                                        }}
                                                                                                                                                                        // value={moment(itemdata?.d_date).format("DD-mmm-YYYY")}
                                                                                                                                                                        onChange={(e) => {
                                                                                                                                                                            console.log(e._d, 'duedateissue')
                                                                                                                                                                            let temparr = []
                                                                                                                                                                            temparr.push({
                                                                                                                                                                                date: e._d,
                                                                                                                                                                                comp_id: upcomingList.comp_id,
                                                                                                                                                                                ac_id: upcomingList.ac_id,
                                                                                                                                                                                index: i
                                                                                                                                                                            })

                                                                                                                                                                            setUpdateduedate([...updateduedate, ...temparr])

                                                                                                                                                                        }}

                                                                                                                                                                    /></center>
                                                                                                                                                                : ""}
                                                                                                                                                        </>

                                                                                                                                                    }

                                                                                                                                                </>
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            {
                                                                                                                                                upcomingList.v_date
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                    </tr>


                                                                                                                                </Fragment>
                                                                                                                            })
                                                                                                                        } </>

                                                                                                                    </>
                                                                                                                    : false}

                                                                                                            </>




                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>


                                                                            </Panel>

                                                                        </Collapse>
                                                                    )
                                                                })}
                                                        </>
                                                    ))}
                                            </div>
                                            {/* <div className="mt-0 mx-2">
                                                <Collapse defaultActiveKey={["0"]} className='cric' accordion>
                                                    
                                                    <Panel header={"itemdata.parent"}
                                                    // extra={<div style={{ width: '120px', textAlign: 'left' }}><span id={'parentid-' + itemdata.index} style={{ display: 'none' }}>{itemdata.index}</span><span>Total Tasks : {itemdata.child[0].length + itemdata.child[1].length}</span></div>}
                                                    // key={itemdata.index} 
                                                    >
                                                       
                                                    </Panel>
                                                </Collapse>
                                            </div> */}
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-md-6' >
                                                        <label >Showing 1 to <span>{updatedArray?.length}</span> of {reassignPayloadId.u_id.length > 0 ? reassignPayloadId.no_of_comp_data : singlereassignPayload?.no_of_comp_data} entries</label>
                                                    </div>

                                                    <div className='col-lg-6 text-start text-md-end' >
                                                        <span >Selected Compliance :<span style={{ marginLeft: "5px" }}>{temparr1.length}</span></span>

                                                    </div>
                                                </div>

                                            </div>

                                            <div className='col-md-12' style={{ padding: "10px", paddingBottom: '0px' }}>
                                                <div className='row'>
                                                    <div className='col-6' >
                                                        {/* <button className="btn btn-primary btn-md" style={{ float: "left", width: "100px" }} onClick={previous}>Previous</button> */}
                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'left', marginLeft: '-8px' }}
                                                            icon={<ArrowLeftOutlined />} size='default' onClick={previous}>
                                                            Previous
                                                        </Button>
                                                        {/* <button style={{ float: "left" }} className="usrbutton button--anthe prev" onClick={previous}><span class="text-white fs-3 fw-bold">Previous</span></button> */}

                                                    </div>
                                                    <div className='col-6 col-md-12 text-end text-md-center' >
                                                        {(reassigndatatablelists?.reassign_compliances?.length != 100) || updatedArray.length == singlereassignPayload?.no_of_comp_data || updatedArray.length == reassignPayloadId?.no_of_comp_data ?
                                                            ''
                                                            : <Button type="primary" shape="round" className='showbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                                size='default' onClick={showmore}>
                                                                Show More  <i> <DownOutlined /> </i>
                                                            </Button>}


                                                    </div>
                                                    {/* <div className='col-md-4'>
                                                        {triggerstate == false ? <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right' }}
                                                            size='default' onClick={Onsubmit2}>
                                                            Select {reassignPayloadId.u_id.length > 0 ? unitName[Number(reassignPayloadId.user_type_id)] : unitName[Number(singlereassignPayload.userType)]} <i> </i>
                                                        </Button> : <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right' }}

                                                            size='default' onClick={() => { toast.error("Trigger before days should not exceed 100") }}>
                                                            Select  {reassignPayloadId.u_id.length > 0 ? unitName[Number(reassignPayloadId.user_type_id)] : unitName[Number(singlereassignPayload.userType)]}<i></i>
                                                        </Button>}

                                                        {/* <button style={{ float: "right" }} className="usrbutton button--anthe" onClick={Onsubmit2}><span class="text-white fs-3 fw-bold">Next</span></button> */}
                                                    {/* </div> */}
                                                </div>
                                            </div>




                                            {/* </TabPane> */}

                                            {/* </Tabs>  */}
                                        </> :
                                        < >

                                            <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'}>
                                                <Collapse className='report-collapse' defaultActiveKey={["1"]}
                                                    style={{ display: exitCollapse ? "none" : "block" }}>
                                                    <Panel header="Reassign Compliance" key={key}>
                                                        <div className='col-md-12'>
                                                            <div className='row'>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor=""><b>Legal Entity :</b></label><span style={{ color: "red" }}> *</span>
                                                                    </div>
                                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                        <div className="form-group">
                                                                            <Select
                                                                                allowClear={true}
                                                                                size="default"
                                                                                placeholder="Select Legal Entity"
                                                                                onDrop={(e) => {
                                                                                    e.preventDefault();
                                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                                    return false;
                                                                                }}
                                                                                // onPaste={(e) => {
                                                                                //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                                // }}
                                                                                onPaste={onPasteToaster}
                                                                                searchValue={searchValues.legalEntity}
                                                                                onSearch={(value) => {
                                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                                    setSearchValues({
                                                                                        ...searchValues,
                                                                                        legalEntity: result
                                                                                    });
                                                                                }}
                                                                                onChange={(data, value) => {
                                                                                    setAddFormSubmit(false)
                                                                                    if (data !== undefined) {
                                                                                        Setlegalstate(true)
                                                                                        setDomain([])
                                                                                        setCurrentEntity(data)
                                                                                        setDataa({
                                                                                            ...dataa,
                                                                                            legalentity: data,
                                                                                            d_name: '',
                                                                                            usr_name: '',
                                                                                            unit_name: '',
                                                                                            user_type: '0'
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        Setlegalstate(false)
                                                                                        setCurrentEntity('')
                                                                                        setDataa({
                                                                                            ...dataa,
                                                                                            legalentity: '',
                                                                                            d_name: '',
                                                                                            usr_name: '',
                                                                                            unit_name: '',
                                                                                            user_type: '0'
                                                                                        })
                                                                                    }
                                                                                }
                                                                                }
                                                                                showSearch

                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) => option.children?.toLowerCase().includes(input?.toLowerCase())}
                                                                                value={dataa.legalentity || undefined}
                                                                                style={{ width: '100%', marginTop: "5px" }}
                                                                            >
                                                                                {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.le_id}>
                                                                                            {item.le_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select>
                                                                            {validator.current.message(
                                                                                'legalentity',
                                                                                dataa.legalentity,
                                                                                'required',
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Legal Entity Required',
                                                                                    }
                                                                                })}
                                                                        </div> :
                                                                        <p title={localStorage.getItem('SelectedEntity')} className='statictable text-wrap-report' style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>


                                                                    }
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor=""><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                                                    </div>
                                                                    <Select
                                                                        allowClear={true}
                                                                        disabled={dataa.legalentity == "" ? true : false}
                                                                        size="default"
                                                                        placeholder="Select Domain"
                                                                        // onClick={() => {
                                                                        //     if (dataa.legalentity == "") {
                                                                        //         toast.warning("Legal Entity Required")
                                                                        //     }
                                                                        // }}
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                            return false;
                                                                        }}
                                                                        // onPaste={(e) => {
                                                                        //     handlePasting(e, /^[a-z -]+$/gi)
                                                                        // }}
                                                                        onPaste={onPasteToaster}
                                                                        searchValue={searchValues.domain}
                                                                        onSearch={(value) => {
                                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                            setSearchValues({
                                                                                ...searchValues,
                                                                                domain: result
                                                                            });
                                                                        }}
                                                                        onChange={(data, value) => {

                                                                            setAddFormSubmit(false)
                                                                            if (data !== undefined) {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    d_id: data,
                                                                                    d_name: value.children,
                                                                                    usr_name: '',
                                                                                    unit_name: '',
                                                                                    user_type: '0'
                                                                                })
                                                                                Settitledata([])
                                                                                Setdatacount([])
                                                                            }
                                                                            else {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    d_id: '',
                                                                                    d_name: '',
                                                                                    usr_name: '',
                                                                                    unit_name: '',
                                                                                    user_type: '0'
                                                                                })
                                                                                Settitledata([])
                                                                                Setdatacount([])
                                                                            }
                                                                        }}
                                                                        value={dataa.d_name || undefined}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children?.toLowerCase().includes(input?.toLowerCase())}
                                                                        style={{ width: '100%', marginTop: "5px" }}
                                                                    >
                                                                        {reassigndatalist.domains && reassigndatalist.domains.length > 0 && reassigndatalist.domains.map((item) => {
                                                                            return (
                                                                                <Option key={item.d_id}>
                                                                                    {item.d_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'domain',
                                                                        dataa.d_name,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Domain Required',

                                                                            }
                                                                        })}
                                                                </div>
                                                                <div className="col-md-4" >
                                                                    <div className="form-group">
                                                                        <label htmlFor=""><b>User Type : </b></label>
                                                                    </div>
                                                                    <Select
                                                                        allowClear={true}
                                                                        size="default"
                                                                        placeholder="Select User Type"
                                                                        defaultValue="All"

                                                                        // onDrop={(e) => {
                                                                        //     e.preventDefault();
                                                                        //     Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        //     return false;
                                                                        //   }}
                                                                        //   onPaste={(e) => {
                                                                        //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                        //   }}
                                                                        onChange={(value) => {
                                                                            SetuserType(value)
                                                                            setAddFormSubmit(false)
                                                                            if (value !== undefined) {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    user_type: [value],
                                                                                    usr_id: '',
                                                                                    usr_name: '',
                                                                                    unit_name: '',
                                                                                })
                                                                            }
                                                                            else {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    user_type: '0',
                                                                                    usr_id: '',
                                                                                    usr_name: '',
                                                                                    unit_name: '',
                                                                                })
                                                                            }

                                                                        }}
                                                                        // showSearch
                                                                        optionFilterProp="children"
                                                                        value={dataa.user_type || undefined}
                                                                        filterOption={(input, option) => option.children?.toLowerCase().includes(input?.toLowerCase())}
                                                                        style={{ width: '100%', marginTop: "5px" }}
                                                                    >
                                                                        <Option defaultValue value="0">All</Option>
                                                                        <Option value="1">Assignee</Option>
                                                                        <Option value="2">Concurrence</Option>
                                                                        <Option value="3">Approver</Option>

                                                                    </Select>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='col-md-12' style={{ marginTop: '10px' }}>
                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                    <div className="form-group">
                                                                        <label> <b>User : </b><span style={{ color: "red" }}>*</span></label>
                                                                    </div>
                                                                    <Select
                                                                        getPopupContainer={trigger => trigger.parentNode}

                                                                        allowClear={true}
                                                                        // disabled={dataa.d_id == "" ? true : false}
                                                                        disabled={dataa.d_name == '' ? true : false}
                                                                        size="default"
                                                                        placeholder="Select User"
                                                                        // onClick={() => {
                                                                        //     if (dataa.d_id == "") {

                                                                        //         toast.warning("Domain Required")
                                                                        //     }
                                                                        // }}
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                            return false;
                                                                        }}
                                                                        // onPaste={(e) => {
                                                                        //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                                        // }}
                                                                        onPaste={onPasteToaster}
                                                                        searchValue={searchValues.user}
                                                                        onSearch={(value) => {
                                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                            setSearchValues({
                                                                                ...searchValues,
                                                                                user: result
                                                                            });
                                                                        }}
                                                                        onChange={(data, value) => {
                                                                            setAddFormSubmit(false)
                                                                            if (value !== undefined) {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    usr_id: [data],
                                                                                    usr_name: [value.children],
                                                                                    unit_name: '',

                                                                                })
                                                                            }
                                                                            else {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    usr_id: '',
                                                                                    usr_name: '',
                                                                                    unit_name: '',

                                                                                })
                                                                            }
                                                                        }}

                                                                        value={dataa.usr_name || undefined}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children?.toLowerCase().includes(input?.toLowerCase())}
                                                                        style={{ width: '100%', marginTop: "5px" }}
                                                                    >
                                                                        {/* {callername && callername.login_response && callername.login_response.usr_cat_id === 1 ?
                                                                            <Option key={1}>Administrator</Option>
                                                                            : ''} */}
                                                                        {
                                                                            categorylist.employee_name == null || categorylist.employee_name == '' ? reassigndatalist.legal_entity_users && reassigndatalist.legal_entity_users.map((item) => {
                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                );


                                                                            })
                                                                                :
                                                                                categorylist.employee_name && categorylist.employee_name.map((item) => {

                                                                                    return (
                                                                                        <Option key={item.employee_code}>
                                                                                            {item.employee_name}
                                                                                        </Option>
                                                                                    );
                                                                                })
                                                                        }
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'user',
                                                                        dataa.usr_name,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'User Required'
                                                                            }
                                                                        })}
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor=""><b>Unit : </b></label><br />
                                                                    </div>

                                                                    <Select
                                                                        allowClear={true}
                                                                        size="default"
                                                                        placeholder="Select Unit"
                                                                        // disabled={dataa.d_id == "" ? true : false}
                                                                        disabled={dataa.usr_name == "" ? true : false}

                                                                        // onClick={() => {
                                                                        //     if (dataa.d_id == "") {

                                                                        //         toast.warning("Domain Required")
                                                                        //     }
                                                                        // }}
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                            return false;
                                                                        }}
                                                                        // onPaste={(e) => {
                                                                        //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                                        // }}
                                                                        onPaste={onPasteToaster}
                                                                        searchValue={searchValues.unit}
                                                                        onSearch={(value) => {
                                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                            setSearchValues({
                                                                                ...searchValues,
                                                                                unit: result
                                                                            });
                                                                        }}
                                                                        onChange={(data, value) => {
                                                                            setAddFormSubmit(false)
                                                                            if (data !== undefined) {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    u_ids: data,
                                                                                    unit_name: [value.children]
                                                                                })
                                                                            }
                                                                            else {
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    u_ids: '',
                                                                                    unit_name: ''
                                                                                })
                                                                            }
                                                                        }}
                                                                        value={dataa.unit_name || undefined}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children?.toLowerCase().includes(input?.toLowerCase())}
                                                                        style={{ width: '100%', marginTop: "5px" }}
                                                                    >
                                                                        {unitList && unitList.length > 0 && unitList.map((item) => {
                                                                            return (
                                                                                <Option key={item.unit_id}>
                                                                                    {item.unit_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div className='text-center mb-2 mt-2'>
                                                            {/* <button className="btn btn-primary btn-md" style={{ marginTop: "25px", width: "80px" }} onClick={Onsubmit1}>Show</button> */}
                                                            <Button type="primary" shape="round" icon={<EyeOutlined />} className='addbutton'
                                                                size='default' onClick={Onsubmit1}>
                                                                Show
                                                            </Button>
                                                        </div>
                                                    </Panel>
                                                </Collapse>
                                                <Modal
                                                    onCancel={setcancelFilter}
                                                    footer={false}
                                                    title="Reassign Compliance"
                                                    className={
                                                        "add-service-prv modal-service-priv " +
                                                        localStorage.getItem("currentTheme")
                                                    }
                                                    open={filterModalVisible}
                                                    maskClosable={false}
                                                >

                                                    <div className='row'>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Legal Entity :</b></label><span style={{ color: "red" }}> *</span>
                                                            </div>
                                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                <div className="form-group">
                                                                    <Select
                                                                        allowClear={true}
                                                                        getPopupContainer={trigger => trigger.parentNode}

                                                                        size="default"
                                                                        placeholder="Select Legal Entity"
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                            return false;
                                                                        }}
                                                                        // onPaste={(e) => {
                                                                        //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                        // }}
                                                                        onPaste={onPasteToaster}
                                                                        searchValue={searchValues.legalEntity}
                                                                        onSearch={(value) => {
                                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                            setSearchValues({
                                                                                ...searchValues,
                                                                                legalEntity: result
                                                                            });
                                                                        }}
                                                                        onChange={(data, value) => {
                                                                            setAddFormSubmit(false)
                                                                            if (data !== undefined) {
                                                                                Setlegalstate(true)
                                                                                setDomain([])
                                                                                setCurrentEntity(data)
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    legalentity: data,
                                                                                    d_name: '',
                                                                                    usr_name: '',
                                                                                    unit_name: '',
                                                                                    user_type: ''
                                                                                })
                                                                            }
                                                                            else {
                                                                                // SetshowTable(false)
                                                                                setCurrentEntity('')
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    legalentity: '',
                                                                                    d_name: '',
                                                                                    usr_name: '',
                                                                                    unit_name: '',
                                                                                    user_type: ''
                                                                                })
                                                                            }
                                                                        }
                                                                        }
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children?.toLowerCase().includes(input?.toLowerCase())}
                                                                        value={dataa.legalentity || undefined}
                                                                        style={{ width: '100%', marginTop: "5px" }}
                                                                    >
                                                                        {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.le_id}>
                                                                                    {item.le_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'legalentity',
                                                                        dataa.legalentity,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Legal Entity Required',
                                                                            }
                                                                        })}
                                                                </div> :
                                                                <p title={localStorage.getItem('SelectedEntity')} className='statictable text-wrap-report' style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>


                                                            }
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                                            </div>
                                                            <Select
                                                                allowClear={true}
                                                                getPopupContainer={trigger => trigger.parentNode}

                                                                disabled={dataa.legalentity == "" ? true : false}
                                                                size="default"
                                                                placeholder="Select Domain"
                                                                // onClick={() => {
                                                                //     if (dataa.legalentity == "") {

                                                                //         toast.warning("Legal Entity Required")
                                                                //     }
                                                                // }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z -]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={searchValues.domain}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                    setSearchValues({
                                                                        ...searchValues,
                                                                        domain: result
                                                                    });
                                                                }}
                                                                onChange={(data, value) => {

                                                                    setAddFormSubmit(false)
                                                                    if (data !== undefined) {
                                                                        setDataa({
                                                                            ...dataa,
                                                                            d_id: data,
                                                                            d_name: value.children,
                                                                            usr_name: '',
                                                                            unit_name: '',
                                                                            user_type: ''
                                                                        })
                                                                        Settitledata([])
                                                                        Setdatacount([])
                                                                    }
                                                                    else {
                                                                        setDataa({
                                                                            ...dataa,
                                                                            d_id: '',
                                                                            d_name: '',
                                                                            usr_name: '',
                                                                            unit_name: '',
                                                                            user_type: ''
                                                                        })
                                                                        Settitledata([])
                                                                        Setdatacount([])
                                                                    }
                                                                }}
                                                                value={dataa.d_name || undefined}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children?.toLowerCase().includes(input?.toLowerCase())}
                                                                style={{ width: '100%', marginTop: "5px" }}
                                                            >
                                                                {reassigndatalist.domains && reassigndatalist.domains.length > 0 && reassigndatalist.domains.map((item) => {
                                                                    return (
                                                                        <Option key={item.d_id}>
                                                                            {item.d_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'domain',
                                                                dataa.d_name,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Domain Required',

                                                                    }
                                                                })}
                                                        </div>
                                                        <div className="col-md-6" >
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>User Type : </b></label>
                                                            </div>
                                                            <Select
                                                                allowClear={true}
                                                                getPopupContainer={trigger => trigger.parentNode}

                                                                size="default"
                                                                placeholder="Select User Type"
                                                                defaultValue="All"

                                                                // onDrop={(e) => {
                                                                //     e.preventDefault();
                                                                //     Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                //     return false;
                                                                //   }}
                                                                //   onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                //   }}
                                                                onChange={(value) => {
                                                                    SetuserType(value)
                                                                    setAddFormSubmit(false)
                                                                    if (value !== undefined) {
                                                                        setDataa({
                                                                            ...dataa,
                                                                            user_type: [value],
                                                                            usr_id: '',
                                                                            usr_name: '',
                                                                            unit_name: '',
                                                                        })
                                                                    }
                                                                    else {
                                                                        setDataa({
                                                                            ...dataa,
                                                                            user_type: '',
                                                                            usr_id: '',
                                                                            usr_name: '',
                                                                            unit_name: '',
                                                                        })
                                                                    }

                                                                }}
                                                                // showSearch
                                                                optionFilterProp="children"
                                                                value={dataa.user_type || undefined}
                                                                filterOption={(input, option) => option.children?.toLowerCase().includes(input?.toLowerCase())}
                                                                style={{ width: '100%', marginTop: "5px" }}
                                                            >
                                                                <Option defaultValue value="0">All</Option>
                                                                <Option value="1">Assignee</Option>
                                                                <Option value="2">Concurrence</Option>
                                                                <Option value="3">Approver</Option>

                                                            </Select>

                                                        </div>


                                                        <div className='col-md-6'>
                                                            <div className="form-group">
                                                                <label> <b>User : </b><span style={{ color: "red" }}>*</span></label>
                                                            </div>
                                                            <Select
                                                                getPopupContainer={trigger => trigger.parentNode}

                                                                allowClear={true}
                                                                // disabled={dataa.d_id == "" ? true : false}
                                                                disabled={dataa.d_name == '' ? true : false}
                                                                size="default"
                                                                placeholder="Select User"
                                                                // onClick={() => {
                                                                //     if (dataa.d_id == "") {

                                                                //         toast.warning("Domain Required")
                                                                //     }
                                                                // }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={searchValues.user}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSearchValues({
                                                                        ...searchValues,
                                                                        user: result
                                                                    });
                                                                }}
                                                                onChange={(data, value) => {
                                                                    setAddFormSubmit(false)
                                                                    if (value !== undefined) {
                                                                        setDataa({
                                                                            ...dataa,
                                                                            usr_id: [data],
                                                                            usr_name: [value.children],
                                                                            unit_name: '',

                                                                        })
                                                                    }
                                                                    else {
                                                                        setDataa({
                                                                            ...dataa,
                                                                            usr_id: '',
                                                                            usr_name: '',
                                                                            unit_name: '',

                                                                        })
                                                                    }
                                                                }}

                                                                value={dataa.usr_name || undefined}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children?.toLowerCase().includes(input?.toLowerCase())}
                                                                style={{ width: '100%', marginTop: "5px" }}
                                                            >
                                                                {/* {callername && callername.login_response && callername.login_response.usr_cat_id === 1 ?
                                                                    <Option key={1}>Administrator</Option>
                                                                    : ''} */}
                                                                {
                                                                    categorylist.employee_name == null || categorylist.employee_name == '' ? reassigndatalist.legal_entity_users && reassigndatalist.legal_entity_users.map((item) => {

                                                                        return (
                                                                            <Option key={item.user_id}>
                                                                                {item.employee_name}
                                                                            </Option>
                                                                        );

                                                                    })
                                                                        :
                                                                        categorylist.employee_name && categorylist.employee_name.map((item) => {
                                                                            return (
                                                                                <Option key={item.employee_code}>
                                                                                    {item.employee_name}
                                                                                </Option>
                                                                            );
                                                                        })
                                                                }
                                                            </Select>
                                                            {validator.current.message(
                                                                'user',
                                                                dataa.usr_name,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'User Required'
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Unit : </b></label><br />
                                                            </div>

                                                            <Select
                                                                allowClear={true}
                                                                getPopupContainer={trigger => trigger.parentNode}

                                                                size="default"
                                                                placeholder="Select Unit"
                                                                // disabled={dataa.d_id == "" ? true : false}
                                                                disabled={dataa.usr_name == "" ? true : false}
                                                                // onClick={() => {
                                                                //     if (dataa.d_id == "") {

                                                                //         toast.warning("Domain Required")
                                                                //     }
                                                                // }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={searchValues.unit}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSearchValues({
                                                                        ...searchValues,
                                                                        unit: result
                                                                    });
                                                                }}
                                                                onChange={(data, value) => {
                                                                    setAddFormSubmit(false)
                                                                    if (data !== undefined) {
                                                                        setDataa({
                                                                            ...dataa,
                                                                            u_ids: data,
                                                                            unit_name: [value.children]
                                                                        })
                                                                    }
                                                                    else {
                                                                        setDataa({
                                                                            ...dataa,
                                                                            u_ids: '',
                                                                            unit_name: ''
                                                                        })
                                                                    }
                                                                }}
                                                                value={dataa.unit_name || undefined}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children?.toLowerCase().includes(input?.toLowerCase())}
                                                                style={{ width: '100%', marginTop: "5px" }}
                                                            >
                                                                {unitList && unitList.length > 0 && unitList.map((item) => {
                                                                    return (
                                                                        <Option key={item.unit_id}>
                                                                            {item.unit_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>


                                                    </div>
                                                    <div className='text-center mb-2 mt-2'>
                                                        {/* <button className="btn btn-primary btn-md" style={{ marginTop: "25px", width: "80px" }} onClick={Onsubmit1}>Show</button> */}
                                                        <Button type="primary" shape="round" icon={<EyeOutlined />} className='addbutton'
                                                            size='default' onClick={() => {
                                                                Onsubmit1()
                                                                let ele_inp = document.getElementsByTagName('input')
                                                                console.log(ele_inp, 'ele_inp')
                                                                for (let i = 0; i < ele_inp.length; i++) {
                                                                    if (ele_inp[i].type == 'checkbox') {
                                                                        ele_inp[i].checked = false
                                                                        ele_inp[i].disabled = false
                                                                    }
                                                                }
                                                            }}>
                                                            Show
                                                        </Button>
                                                    </div>
                                                </Modal>
                                            </div>


                                            {
                                                showTable ?
                                                    <div>
                                                        <div className="row rounded-3 mt-1">
                                                            <div className="col-12 rounded-3 reassign_table1 reassign_card reassign_card1 reassign_card2 reassign_card2 ">

                                                                <div
                                                                    className={
                                                                        localStorage.getItem("currentTheme") +
                                                                        " ant-table-wrapper reassign-comp-table"
                                                                    }
                                                                >

                                                                    <div className="ant-table ant-table-bordered ant-table-fixed-header">
                                                                        <div className="ant-table-container">
                                                                            <div className="ant-table-content taskrecalltable  ">
                                                                                <table className="table-fixed ">
                                                                                    <thead className="ant-table-thead">
                                                                                        <tr>
                                                                                            <th className="ant-table-cell" ></th>
                                                                                            <th className="ant-table-cell">Unit</th>
                                                                                            <th className="ant-table-cell">No. of Compliance Tasks</th>
                                                                                            <th className="ant-table-cell">Reassign</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className="ant-table-tbody">
                                                                                        {
                                                                                            tableArray?.length > 0 ? tableArray.map((tabledatas) => {
                                                                                                console.log(selectedInput, unitName[tabledatas.parentData], 'tabledatas')
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                            <td colSpan={4} className="ant-table-cell">
                                                                                                                <div className='my-1'>
                                                                                                                    <input type="checkbox"
                                                                                                                        id={unitName[tabledatas.parentData]}
                                                                                                                        // name={unitName[tabledatas.parentData]}
                                                                                                                        className='checkclass ms-1 me-3'
                                                                                                                        onClick={(e) => {
                                                                                                                            console.log(e.target.value, 'allcheck')
                                                                                                                            let checked = e.target.checked;

                                                                                                                            addAllSelectedDataValuesunit(
                                                                                                                                checked,
                                                                                                                                unitName[tabledatas.parentData],
                                                                                                                                tabledatas.parentData,
                                                                                                                                tabledatas.child
                                                                                                                            );
                                                                                                                            setTriggerstate(false)

                                                                                                                        }}
                                                                                                                        checked={selectedInput.length ? selectedInput.indexOf(unitName[tabledatas.parentData]) > -1 : false}

                                                                                                                    // checked={checkedRow}
                                                                                                                    />

                                                                                                                    <span style={{ cursor: 'pointer' }} className='selected h5'>{unitName[tabledatas.parentData]}</span>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        {tabledatas?.child?.map((childData, index) => {
                                                                                                            console.log(childData, index, 'childDatachildData')
                                                                                                            return (
                                                                                                                <tr className="ant-table-row ant-table-row-level-0">

                                                                                                                    <td className="ant-table-cell ">
                                                                                                                        <input type="checkbox"
                                                                                                                            name={unitName[tabledatas.parentData]}
                                                                                                                            className={'ms-1 '}
                                                                                                                            id={unitName[tabledatas.parentData] + childData.childId}
                                                                                                                            onClick={(e) => {
                                                                                                                                let checked = e.target.checked;
                                                                                                                                console.log(childData, 'recordrecord')
                                                                                                                                checkedData(
                                                                                                                                    checked,
                                                                                                                                    childData.u_id,
                                                                                                                                    tabledatas,
                                                                                                                                    childData,
                                                                                                                                    tabledatas?.parentData,
                                                                                                                                    unitName[tabledatas.parentData] + childData.childId
                                                                                                                                    // record.childId
                                                                                                                                );
                                                                                                                                setTriggerstate(false)
                                                                                                                            }}
                                                                                                                            checked={selectedInput.length ? selectedInput.indexOf(unitName[tabledatas.parentData] + childData.childId) > -1 : false}
                                                                                                                        // checked={selectedInput.includes(unitName[tabledatas.parentData] + childData.parentId)}
                                                                                                                        />
                                                                                                                    </td>
                                                                                                                    {/* <td className="ant-table-cell">
                                                                                                                        <span><Tooltip title={childData.address} ><InfoCircleOutlined className='text-info' /></Tooltip></span>
                                                                                                                        {childData?.u_name} </td> */}
                                                                                                                    <td className="ant-table-cell">  <span >
                                                                                                                        <Tooltip title={`${childData.address}, ${childData.postal_code}`}>
                                                                                                                            <InfoCircleOutlined className='text-info' />
                                                                                                                        </Tooltip>
                                                                                                                        <span className='ms-0' title={childData?.u_name}>  &nbsp;{childData?.u_name}</span>

                                                                                                                    </span></td>

                                                                                                                    <td className="ant-table-cell"><center>{childData.no_of_compliances}</center></td>
                                                                                                                    {(index == 0) &&

                                                                                                                        <td rowSpan={tabledatas.child.length} className="ant-table-cell text-center">
                                                                                                                            <Button type="primary" shape="round" className='addbutton'
                                                                                                                                size='default' onClick={OnSubmit}>
                                                                                                                                <RetweetOutlined /> Reassign
                                                                                                                            </Button>
                                                                                                                        </td>

                                                                                                                    }
                                                                                                                </tr>
                                                                                                            )
                                                                                                        })

                                                                                                        }

                                                                                                    </>
                                                                                                )
                                                                                            }) :

                                                                                                <tr>
                                                                                                    <td></td>
                                                                                                    <td></td>

                                                                                                    <td style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                                                    <td></td>

                                                                                                </tr>

                                                                                        }
                                                                                    </tbody>

                                                                                </table>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    : ' '
                                            }
                                        </>
                                    }
                                </div>

                            </div>
                        </div>
                        {tableShow ?
                            <Stickicon />
                            : false}
                    </div >

                </div >

            </div >

        </Fragment >
    )
}
export default connect(mapStateToProps, {
    reassignlist, reassignunitlist, reassigndatatable, getusercomplaince, savereassigncomplaince, GetRemarksData, showmoredata,
})(ReassignCompliance);