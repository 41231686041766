import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import {
    REMARK_HISTORY_MODAL, NEXT_RECORD, COMPLIANCE_APPROVAL_LIST,
    COMPLIANCE_APPROVAL_FILTER_LIST, COMPLIANCE_APPROVAL_DOWNLOAD, COMPLIANCE_APPROVAL_INTERIM_STATUS,
    COMPLIANCE_APPROVAL_REMOVE, COMPLIANCE_PASSWORD
} from '../../types/index'


const initialState = {
    complianceApproval: {
        complianceList: '',
        complianceFrequencyList: '',
        complianceUnitList: '',
        complianceDomainList: '',
        complianceUserList: '',
        editdatas: '',
        filterdata: '',
        downloadfile: '',
        removefile: '',
        remarkmodaldata: "",
        nextrecord: [],
        passwordstate: '',
        interimvalue: [],
        Count: 0

    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case COMPLIANCE_APPROVAL_LIST: {
            return {
                ...state,
                complianceApproval: {
                    ...state.complianceApproval,
                    complianceList: payload[1].approval_list,
                    Count: payload[1].total_count

                },
            };
        }
        case COMPLIANCE_APPROVAL_INTERIM_STATUS: {
            return {
                ...state,
                complianceApproval: {
                    ...state.complianceApproval,
                    interimvalue: payload[1],
                },
            };
        }

        case COMPLIANCE_APPROVAL_FILTER_LIST: {
            return {
                ...state,
                complianceApproval: {
                    ...state.complianceApproval,
                    complianceFrequencyList: payload[1].frequency_list,
                    complianceUnitList: payload[1].user_units,
                    complianceDomainList: payload[1].domains_list,
                    complianceUserList: payload[1].users_list
                },
            };
        }
        case COMPLIANCE_APPROVAL_DOWNLOAD: {
            console.log(payload[0], "payload222222");

            return {
                ...state,
                complianceApproval: {
                    ...state.complianceApproval,
                    downloadfile: payload[0],
                },
            };
        }
        case COMPLIANCE_APPROVAL_REMOVE: {
            console.log(payload[0], "payload1111");
            return {
                ...state,
                complianceApproval: {
                    ...state.complianceApproval,
                    removefile: payload[0],
                },
            };
        }
        case REMARK_HISTORY_MODAL: {
            console.log(payload[0], "payload1888");
            return {
                ...state,
                complianceApproval: {
                    ...state.complianceApproval,
                    remarkmodaldata: payload,
                },
            };
        }
        case NEXT_RECORD: {
            console.log(payload[0], "payload1111");
            return {
                ...state,
                complianceApproval: {
                    ...state.complianceApproval,
                    nextrecord: payload,
                },
            };
        }
        case COMPLIANCE_PASSWORD: {
            return {
                ...state,
                complianceApproval: {
                    ...state.complianceApproval,
                    passwordstate: payload,
                },
            };
        }
        default:
            return state;
    }
}