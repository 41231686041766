import {RISK_CHART,COMMON_DATA, TOGGLE_COMMON_LOADER,RISK_CHART_TABLE_DATA,RISK_CHART_DELAYED_TABLE_DATA,TIME_ZONE_DATA,REMARKS_HISTORY_DATA,SHOW_MORE_TABLE_DATA,SHOW_MORE_HIDDEN_TABLE_DATA } from './../../types/index'
const initialState = {
    riskChart: {
       chartData:[],
       time:0,
       Data:[],
       tableData:[],
       delayedTableData:[],
       timeZoneData:[],
       remarksHistoryData:[],
       showMoreData:[],
       hiddenRiskShowMoreData:[]

       
    },
    notAssigned: 0
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case RISK_CHART: {
            console.log(payload,"payload4444");
            return {
                ...state,
                riskChart: {
                    ...state.riskChart,
                    chartData: payload,
                    time:payload.current_date
                },
                notAssigned:payload.risk_chart_data_nu[0].unassign_count
            };
        }
        case COMMON_DATA: {
            console.log(payload,"payload4444");
            return {
                ...state,
                riskChart: {
                    ...state.riskChart,
                    Data: payload,
                },
            };
        }
        case RISK_CHART_TABLE_DATA:{
            return {
                ...state,
                riskChart: {
                    ...state.riskChart,
                    tableData: payload,
                },
            };
        }
        case RISK_CHART_DELAYED_TABLE_DATA:{
            return {
                ...state,
                riskChart: {
                    ...state.riskChart,
                    delayedTableData: payload.drill_down_data,
                },
            };
        }
        case TIME_ZONE_DATA:{
            return {
                ...state,
                riskChart: {
                    ...state.riskChart,
                    timeZoneData: payload,
                },
            };
        }
        
        case REMARKS_HISTORY_DATA:{
            return {
                ...state,
                riskChart: {
                    ...state.riskChart,
                    remarksHistoryData: payload,
                },
            };
        }
        case SHOW_MORE_TABLE_DATA:{
            return {
                ...state,
                riskChart: {
                    ...state.riskChart,
                    showMoreData: payload,
                },
            };
        }
        case SHOW_MORE_HIDDEN_TABLE_DATA:{
            return {
                ...state,
                riskChart: {
                    ...state.riskChart,
                    hiddenRiskShowMoreData: payload,
                },
            };
        }
        default:
            return state;
    }
}
