import React, { Fragment } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Pagination, Popover, Drawer } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { MonthlyComplianceFilterReport, MonthlyComplianceTable, MonthlyComplianceExport, Downloadfile, getRemarksFilter } from '../../../Store/Action/Report/CV_MonthlyComplianceReport';
import { getdownloaddocument } from '../../../Store/Action/Report/TaskWiseReport';
import { getHistoryRemarks } from '../../../Store/Action/Report/ConsolidatedReports'
import { toast } from 'react-toastify';
import { Toaster } from '../../../Libs/Toaster';
import ScrollToTop from "react-scroll-to-top";
import { ExclamationCircleTwoTone, ExclamationCircleOutlined, PlayCircleOutlined, BookTwoTone, FullscreenOutlined, FilterOutlined, EyeOutlined, ExportOutlined, UpOutlined, FileTextOutlined } from '@ant-design/icons';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../../Libs/fullscreenmode";
import { validationType, validateSelect, onPasteToaster } from './../../../Libs/inputValidation';
import useStickyHeight from "../../../Libs/useStickyHeight"
import ScrollButton from '../../Scrollbar';
import url, { getUrl } from './../../../Libs/URL'
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()
const urls = getUrl()

const mapStateToProps = (state) => ({
    filterValue: state.TaskWiseReport.TaskWiseReports,
    filerunitdatas: state.MonthlyComplianceReport.unitlistreports.unitlist,
    tabledatas: state.MonthlyComplianceReport.unitlistreports.tabledata,
    interimdata: state.ConsolidatedReports.ConsolidatedReports.interimData
})

const MonthlyComplianceReport = ({
    MonthlyComplianceFilterReport,
    MonthlyComplianceTable,
    MonthlyComplianceExport,
    Downloadfile,
    filerunitdatas,
    tabledatas,
    getRemarksFilter,
    getdownloaddocument,
    filterValue,
    getHistoryRemarks,
    interimdata

}) => {
    useStickyHeight();
    const historynew = useHistory();
    console.log(tabledatas, 'tabledatas')
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [getreportlist, setGetReportList] = useState([])
    const [entity, setEntity] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [tableshow, setTableShow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [domainValue, setDomainValue] = useState(false)
    const [domainFilter, setDomainFilter] = useState([])
    const [key, setKey] = useState("1")
    const [interimModal, setInterimModal] = useState(false);
    const [type, setType] = useState('')
    const [division, setDivision] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [domain, setDomain] = useState([])
    const [vendorlist, setVendorlist] = useState([])
    const [tabledata, setTableData] = useState([])
    const [page, setPage] = useState(1);
    const [titledata, Settitledata] = useState([])
    const [tempTitleData, setTempTitleData] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [paginationdata, setPaginationdata] = useState([])
    const [count, setCount] = useState([])
    let [index, setindex] = useState(0)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [checkboxValue, setCheckBoxValue] = useState([0, 1, 2, 7, 8, 9, 10, 29, 31])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [legalstate, Setlegalstate] = useState(false)
    const [tablecount, setTablecount] = useState([])
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [isModalOpenChild, setModalOpenChild] = useState(false);
    const [modalData, setModalData] = useState({})
    const [documentName, setDocumentName] = useState([])
    const [approvalDocName, setApprovalDocName] = useState([])
    const [concurDocName, setConcurDocName] = useState([])
    const [commonArray, setCommonArray] = useState([])
    const [uploadModal, setUploadModal] = useState(false)
    let [sno, setSNo] = useState(0)
    const [datas, setDatas] = useState({
        le_id: '',
        start_date: '',
        country_id: '',
        domain_id: '',
        unit_id: ''
      });
    const [vendorComplianceScore, setVendorComplianceScore] = useState({
        country: '',
        countryName: '',
        division: '',
        divisionName: '',
        typeid: '',
        typeName: '',
        category: '',
        categoryName: '',
        from_month: '',
        to_month: '',
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        legal_entity_id: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntityid'),
        domain: '',
        domainName: '',
        vendor_id: '',
        vendor_name: '',
        legalEntityLabel: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        countryLabel: '',
        domainLabel: ''
    })
    console.log(tabledata, 'tabledata');
    console.log(filteredArrayState, 'filteredArrayState')
    console.log(tempTitleData, 'tempTitleData')
    const [Documentdata, setDocumentdata] = useState([{
        assignee_document: '',
        approver_document: '',
        concurrer_document: '',
        startdate: ''
    }])
    const [TempDocumentdata, setTempDocumentdata] = useState([])
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;

    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        domain: '',
        user: '',
        division: '',
        category: '',
        vendor: '',
    });

    const d = new Date();
    let month = d.getMonth();
    let year = d.getFullYear();
    console.log(d, month, year, 'monthmonth');

    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid() || (vendorComplianceScore.domainName != '' && vendorComplianceScore.from_month != '' && vendorComplianceScore.to_month != '')) {
            // setFilterModalVisible(false);
            setIsModalOpen(true);
        }
    };
    const handleOk = () => {
        const remarks = checkboxValue.includes(34);
        const myArray = checkboxValue;
        let status = myArray.includes(0);
        if (!status) {
            myArray.unshift(0);
        }

        if (remarks) {
            const index = myArray.indexOf(34);
            const x = myArray.splice(index, 1);
        }
        setCheckBoxValue(myArray);

        setIsModalOpen(true);
        if (validator.current.allValid() || (vendorComplianceScore.domainName != '' && vendorComplianceScore.from_month != '' && vendorComplianceScore.to_month != '')) {
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMonthlyComplianceReport",
                        {
                            "country_id": Number(vendorComplianceScore.country),
                            "legal_entity_id": entityid,
                            "domain_id": vendorComplianceScore.domain ? Number(vendorComplianceScore.domain) : 0,
                            "division_id": vendorComplianceScore.division ? Number(vendorComplianceScore.division) : 0,
                            "category_id": vendorComplianceScore.category ? Number(vendorComplianceScore.category) : 0,
                            "unit_id": vendorComplianceScore.vendor_id ? Number(vendorComplianceScore.vendor_id) : 0,
                            "from_date": moment(vendorComplianceScore.from_month).format("MMMM-YYYY"),
                            "to_date": moment(vendorComplianceScore.to_month).format("MMMM-YYYY"),
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            console.log("payLoad", payload2);
            MonthlyComplianceExport({
                payload: payload2,
                paramid: paramid
            })
        }
        // setCheckBoxValue([1, 2, 3, 4, 5, 6, 11])
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsModalVisible(false)
    };

    const onshow = () => {
        setAddFormSubmit(true)
        setFilterModalVisible(false);
        if (validator.current.allValid() || (vendorComplianceScore.domain != '' && vendorComplianceScore.from_month != '' && vendorComplianceScore.to_month != '')) {
            setFilteredArrayState([])
            Settitledata([])
            setExitCollapse(true);
            setFilterTaskAcc(false);
            setTableShow(true)
            setKey(0)
            setindex(0);
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            setVendorComplianceScore({
                ...vendorComplianceScore,
                domainLabel: vendorComplianceScore.domainName,
                countryLabel: vendorComplianceScore.countryName,
                legalEntityLabel: vendorComplianceScore.legal_entity_name
            })
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMonthlyComplianceReport",
                        {
                            "country_id": Number(vendorComplianceScore.country),
                            "legal_entity_id": entityid,
                            "domain_id": vendorComplianceScore.domain ? Number(vendorComplianceScore.domain) : 0,
                            "division_id": vendorComplianceScore.division ? Number(vendorComplianceScore.division) : 0,
                            "category_id": vendorComplianceScore.category ? Number(vendorComplianceScore.category) : 0,
                            "unit_id": vendorComplianceScore.vendor_id ? Number(vendorComplianceScore.vendor_id) : 0,
                            "from_date": moment(vendorComplianceScore.from_month).format("MMMM-YYYY"),
                            "to_date": moment(vendorComplianceScore.to_month).format("MMMM-YYYY"),
                            "csv": false,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": null
                        }
                    ]
                }
            ]
            MonthlyComplianceTable({
                payload: payload1,
                paramid: paramid
            })

        }
    }

    const onChange = (key) => {
        console.log(key);
    };

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (vendorComplianceScore.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [vendorComplianceScore.legal_entity_name])

    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetMonthlyComplianceReportFilters",
                            {
                                "country_id": Number(vendorComplianceScore.country),
                                "legal_entity_id": entityid
                            }
                        ]
                    }
                ]
                if (entityid != 'null') {

                    MonthlyComplianceFilterReport({
                        payload: payload,
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }

    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(vendorComplianceScore.legal_entity_id)
        }
    }, [entityid])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setFilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setVendorComplianceScore({
                ...vendorComplianceScore,
                le_id: localStorage.getItem('SelectedEntityid'),
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (filerunitdatas) {
            setCategoryList(filerunitdatas && filerunitdatas.categories_list)
            setDivision(filerunitdatas && filerunitdatas.divisions_list)
            setDomain(filerunitdatas && filerunitdatas.domains)
            setVendorlist(filerunitdatas && filerunitdatas.unit_legal_entity)
        }
    }, [filerunitdatas, entityid])
    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
         window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }
    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const handleClose = () => {
        setInterimModal(false);
    
      };

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([1, 2, 3, 4, 5, 6, 11])
        }
    }
    // useEffect(() => {
    //     if (tabledatas) {
    //         setTableData(tabledatas && tabledatas.monthly_compliances)
    //     }
    //     if (tabledatas.total_count) {
    //         setCount(tabledata && tabledatas.total_count)
    //     }
    // }, [tabledatas, tabledatas.monthly_compliances, tabledatas.total_count, count])

    useEffect(() => {
        if (tabledatas.monthly_compliances && tabledatas.monthly_compliances.length > 0) {
            let expected_arr = [];
            for (let ijk = 0; ijk < tabledatas.monthly_compliances.length; ijk++) {
                let check_child = _.findIndex(expected_arr, { "compliance_history_id": tabledatas.monthly_compliances[ijk].compliance_history_id });
                if (check_child >= 0) {
                    expected_arr[check_child].child.push(tabledatas.monthly_compliances[ijk]);
                } else {
                    tabledatas.monthly_compliances[ijk].child = [];
                    expected_arr.push(tabledatas.monthly_compliances[ijk])
                }
            }
            setTempTitleData(expected_arr)
        }

    }, [tabledatas.monthly_compliances])

    useEffect(() => {
        if (tempTitleData && tempTitleData.length > 0) {
            var array = tempTitleData.slice((current - 1) * pageSize, (current * pageSize))
            setTableData(array)
        } else {
            setTableData([])
        }

    }, [tempTitleData, pageSize, current])

    useEffect(() => {
        let temp = [];
        if (tabledata && tabledata.length > 0) {
            for (let i in tabledata) {
                temp.push(tabledata[i])
            }
            setTablecount([...temp]);
        }
    }, [tabledata])
    useEffect(() => {
        if (tabledata && tabledata && tabledata.length > 0) {
            let uniqueObjArray = [...new Map(tabledata && tabledata.length && tabledata.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(tabledata && tabledata && tabledata.length && tabledata, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])
        }
    }, [tabledata])

    const handlePasting = (e, regex) => {
        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
    }

    useEffect(() => {

        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tabledata && tabledata.length && tabledata) {
            const ab = tabledata && tabledata.length && tabledata.map(function (item) {
                if ((array.indexOf(item.statutory_mapping) < 0))
                    array.push(item.statutory_mapping)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    tabledata && tabledata.length && tabledata.map((child, childIndex) => {
                        if (data === child.statutory_mapping) {
                            entityArray = {
                                "ac_id": child.ac_id,
                                "activity_date": child.activity_date,
                                "activity_status": child.activity_status,
                                "approval_document_names": child.approval_document_names,
                                "assignee_name": child.assignee_name,
                                "category_name": child.category_name,
                                "completion_date": child.completion_date,
                                "compliance_activity_id": child.compliance_activity_id,
                                "compliance_description": child.compliance_description,
                                "compliance_history_id": child.compliance_history_id,
                                "compliance_id": child.compliance_id,
                                "compliance_task": child.compliance_task,
                                "concur_document_name": child.concur_document_name,
                                "country_id": child.country_id,
                                "criticality_name": child.criticality_name,
                                "division_name": child.division_name,
                                "doc_ref_num": child.doc_ref_num,
                                "document_name": child.document_name,
                                "domain_id": child.domain_id,
                                "due_date": child.unit_id,
                                "extend_due_date": child.extend_due_date,
                                "frequency_name": child.frequency_name,
                                "history_count": child.history_count,
                                "interim_count": child.interim_count,
                                "legal_entity_id": child.legal_entity_id,
                                "month_name": child.month_name,
                                "remarks_count": child.remarks_count,
                                "start_date": child.start_date,
                                "statutory_mapping": child.statutory_mapping,
                                "task_status": child.task_status,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_name": child.unit_name,
                                "unit_id": child.unit_id,
                                "url": child.url,
                                "user_name": child.user_name,
                                'childId': childIndex,
                                'parentId': i,
                                'childData': child.child
                            }
                            temp.push(entityArray)
                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)
        }
    }, [tabledata && tabledata.length && tabledata])




    useEffect(() => {
        if (pageState === true) {
            setindex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const chilData = (document_name, approval_document_names, concur_document_name, start_date) => {
        setIsModalVisible(true)
        setTempDocumentdata([document_name, approval_document_names, concur_document_name])
        setDocumentdata([{
            assignee_document: document_name,
            approver_document: approval_document_names,
            concurrer_document: concur_document_name,
            startdate: start_date,
        }])
    }
    const download = (item) => {
        const downloadpayload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "DownloadFile",
                        {
                            "le_id": entityid,
                            "c_id": Number(vendorComplianceScore.country),
                            "d_id": vendorComplianceScore.domain ? Number(vendorComplianceScore.domain) : 0,
                            "u_id": 38625,
                            "start_date": "22-Feb-2022",
                            "file_name": item,
                        }
                    ]
                }
            ]
        Downloadfile({
            payload: downloadpayload,
            paramid: paramid
        });
        if (item) {
            // window.open('https:/' + item);
            window.open(urls + item);

        }
    }

    const columns = [
        {
            title: "#",
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            },
            sortable: true,
            reorder: true,
            dataIndex: false,
            width: '30px',
            align: 'center',

        },
        {
            title: 'Assignee',
            ellipsis: true,
            width: '50px',
            dataIndex: 'false',
            key: 'false',
            render: (row, record) => {
                return (
                    <a href={`${urls + '/compliance_format/' + record.assignee_document}`} target="_blank" onClick={(e) => { download(record.assignee_document) }} >
                        {record.assignee_document}
                    </a>
                )
            },
        },
        {
            title: 'Approver',
            ellipsis: true,
            width: '50px',
            dataIndex: 'false',
            key: 'false',
            render: (row, record) => {
                return (
                    <a href={`${urls + '/compliance_format/' + record.approver_document}`} target="_blank" onClick={(e) => { download(record.approver_document) }} >
                        {record.approver_document}
                    </a>
                )
            },

        },
        {
            title: 'Concurrer',
            ellipsis: true,
            width: '50px',
            dataIndex: 'false',
            key: 'false',
            render: (row, record) => {
                return (
                    <a href={`${urls + '/compliance_format/' + record.concurrer_document}`} target="_blank" onClick={(e) => { download(record.concurrer_document) }} >
                        {record.concurrer_document}
                    </a>
                )
            },
        },
    ];

    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    useEffect(() => {
        if (vendorComplianceScore.country != '' && vendorComplianceScore.legal_entity_id != '' && domain && domain.length == 1) {
            setDomainValue(true)
            setVendorComplianceScore({
                ...vendorComplianceScore,
                domain: domain[0].d_id,
                domainName: domain[0].d_name,
            })

        } else {
            setDomainValue(false)
            setDomainFilter(domain)

        }
    }, [domain])

    const [selectAllText, setSelectAllText] = useState('Select All')

    useEffect(() => {
        if (checkboxValue.length == 34) {
            setSelectAllText('Unselect All')
        } else {
            setSelectAllText('Select All')
        }
    }, [checkboxValue])

    const getDomainBasedRecords = (domainId) => {
        setDivision(filerunitdatas && filerunitdatas.divisions_list.length > 0 ? filerunitdatas.divisions_list : '')
        setCategoryList(filerunitdatas && filerunitdatas.categories_list.length > 0 ? filerunitdatas.categories_list : '')
        if (domainId != undefined) {
            let vendor = _.filter(filerunitdatas.unit_legal_entity, { domain_id: Number(domainId) })
            setVendorlist(vendor)
        } else {
            setVendorlist([])
        }
    }

    const getDivisionBasedRecords = (divisionId) => {
        if (divisionId != undefined) {
            let category = _.filter(filerunitdatas.categories_list, { div_id: Number(divisionId) })
            setCategoryList(category)
            let vendor = _.filter(filerunitdatas.unit_legal_entity, { domain_id: Number(vendorComplianceScore.domain), division_id: parseInt(divisionId) })
            setVendorlist(vendor)
        } else {
            setCategoryList(filerunitdatas && filerunitdatas.categories_list.length > 0 ? filerunitdatas.categories_list : '')
            let vendor = _.filter(filerunitdatas.unit_legal_entity, { domain_id: Number(vendorComplianceScore.domain) })
            setVendorlist(vendor)
        }
    }

    const getCategoryBasedRecords = (categoryId) => {
        if (categoryId != undefined) {
            let vendor = filerunitdatas.unit_legal_entity.filter((items) => {
                return items.category_id == parseInt(categoryId)
            })
            setVendorlist(vendor)
        } else {
            let vendor = filerunitdatas.unit_legal_entity.filter((items) => {
                return items.domain_id == Number(vendorComplianceScore.domain) || items.division_id == parseInt(vendorComplianceScore.division)
            })
            setVendorlist(vendor)
        }
    }
    const [modalDataState, setModalDataState] = useState([])
    const getChildDetails = (getrecords) => {
        setModalOpenChild(true)
        setModalDataState(getrecords)
        console.log(getrecords, 'getrecords')
    }

    const handleOkChild = () => {
        setModalOpenChild(false)
        setModalDataState([])
    };

    const handleCancelChild = () => {
        setModalOpenChild(false)
        setModalDataState([])
    };

    const columnData = [
        {
            title: "User Name",
            dataIndex: "assignee_name",
            key: "assignee_name",
        },
        {
            title: "Activity Status",
            dataIndex: "activity_status",
            key: "activity_status",
        },
        {
            title: "Due Date",
            dataIndex: "activity_date",
            key: "activity_date",
        },
        {
            title: "Complied Status",
            dataIndex: "task_status",
            key: "task_status",
        },
        {
            title: "Due Date",
            dataIndex: "completion_date",
            key: "completion_date",
        }
    ];

    const interimcolumns = [
        {
            title: "#",
            // ellipsis: true,
            align: 'center',
            width: "2%",
            render: (data, record, index) => {
                return index + 1;
            }
        },
        {
            title: "Documents",
            ellipsis: true,
            width: "20%",
            dataIndex: "documents",
            key: "documents",
            render: (data, record) => {
                let file_name = data.split('||');
                return file_name && file_name.map((item) => {
                    return <><span className='pointer' onClick={() => {
                        const downloadpayload =
                            [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "DownloadInterimFile",
                                        {
                                            "le_id": entityid,
                                            "c_id": datas.country_id,
                                            "d_id": datas.domain_id,
                                            "u_id": datas.unit_id,
                                            "start_date": datas.start_date,
                                            "file_name": item
                                        }
                                    ]
                                }
                            ];
                        console.log(downloadpayload, 'downloadpayload');
                        Downloadfile({
                            payload: downloadpayload,
                            paramid: paramid
                        });
                    }}><Link>{item}</Link><br/></span></>;
                });
            }
        },
        {
            title: "Remarks",
            ellipsis: true,
            width: "15%",
            dataIndex: "remarks",
            key: "remarks",
        },
        {
            title: "Updated On",
            ellipsis: true,
            width: "10%",
            dataIndex: "activity_on",
            key: "activity_on",
        },
    ];

    const [isRemarksModal, setRemarksModal] = useState(false)
    const [downloadState, setDownloadState] = useState([])
    const getRemarksList = (getrecord) => {
        setRemarksModal(true)
        setDownloadState(getrecord)
        const remarkspayload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceRemarksHistoryData",
                    {
                        "legal_entity_id": entityid,
                        "unit_id": parseInt(getrecord.unit_id),
                        "compliance_history_id": parseInt(getrecord.compliance_history_id)
                    }
                ]
            }
        ]
        getRemarksFilter({
            payload: remarkspayload,
            paramid: paramid
        })
    }

    const remarksColumn = [
        {
            title: "Activity Date",
            dataIndex: "activity_on",
            key: "activity_on",
        },
        {
            title: "User Type",
            dataIndex: "user_category_id",
            key: "user_category_id",
            render: (text, record) => {
                if (record.user_category_id == 5 || record.user_category_id == 6) {
                    return 'Assignee'
                } else if ((record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Rectified' || record.action == 'Concurrence Rejected' || record.action == 'Concurred')) {
                    return 'Concurrer'
                } else if (record.user_category_id == 1 && (record.action == 'RectifyApproval' || record.action == 'Approval Rejected' || record.action == 'Approved') ||
                    (record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Approval Rejected' || record.action == 'RectifyApproval' || record.action == 'Approved')) {
                    return 'Approver'
                }

            }
        },
        {
            title: "Reviewer Report",
            dataIndex: "documents",
            key: "documents",
            render: (text, record) => {
                if (text != null) {
                    return <Link onClick={(e) => downloadInteral(record)}>{text}</Link>
                }
            }
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
        },
    ]

    const downloadInteral = (record) => {
        console.log(record, 'record')
        const pay = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "DownloadFile",
                    {
                        "le_id": entityid,
                        "c_id": parseInt(vendorComplianceScore.country),
                        "d_id": parseInt(vendorComplianceScore.domain),
                        "u_id": record.unit_id,
                        "start_date": downloadState.start_date,
                        "file_name": record.documents
                    }
                ]
            }
        ]
        getdownloaddocument({
            payload: pay,
            paramid: paramid
        })
    }

    const handleOkRemarks = () => {
        setRemarksModal(false)
    };

    const handleCancelRemarks = () => {
        setRemarksModal(false)
    };

    const getDownloadRecords = (records) => {
        console.log(records, 'recordsrecords')
        setUploadModal(true)
        setModalData(records)
        let documentTemp = []
        let concurTemp = []
        let approvalTemp = []



        let specialChar = /[|]/;

        // let documentResult = records.document_name.replaceAll("||", ", ||");
        documentTemp.push(specialChar.test(records.document_name) ? records.document_name.split("||") : records.document_name)
        //let concurResult = records.concur_document_name.replaceAll("||", ", ||");
        concurTemp.push(specialChar.test(records.concur_document_name) ? records.concur_document_name.split("||") : records.concur_document_name)
        // let approvalResult = records.approval_document_names.replaceAll("||", ", ||");
        approvalTemp.push(specialChar.test(records.approval_document_names) ? records.approval_document_names.split("||") : records.approval_document_names)

        setDocumentName([].concat.apply([], documentTemp.filter(Boolean))) //remove null value and merge array of array to single array
        setApprovalDocName([].concat.apply([], approvalTemp.filter(Boolean)))
        setConcurDocName([].concat.apply([], concurTemp.filter(Boolean)))
    }
    console.log(documentName, 'documentName')
    console.log(approvalDocName, 'approvalDocName')
    console.log(concurDocName, 'concurDocName')

    useEffect(() => {
        const maxLength = Math.max(documentName.length, approvalDocName.length, concurDocName.length);
        let entityArray = []
        for (let i = 0; i < maxLength; i++) {
            entityArray.push({
                assignee: documentName[i] || null,
                approver: approvalDocName[i] || null,
                concur: concurDocName[i] || null
            });
        }
        setCommonArray(entityArray)

    }, [documentName, approvalDocName, concurDocName])

    const downloaddocument = (unit_id, start_date, document_name) => {
        const pay = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "DownloadFile",
                    {
                        "le_id": entityid,
                        "c_id": parseInt(vendorComplianceScore.country),
                        "d_id": parseInt(vendorComplianceScore.domain),
                        "u_id": unit_id,
                        "start_date": start_date,
                        "file_name": document_name
                    }
                ]
            }
        ]
        getdownloaddocument({
            payload: pay,
            paramid: paramid
        })
    }

    const modalOnChane = () => {
        setUploadModal(false)
        setDocumentName([])
        setApprovalDocName([])
        setConcurDocName([])
    }

    return (
        <Fragment>
            <div className="page-wrapper" id="page-wrapper">
                {document.body.scrollHeight > document.body.clientHeight ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''}
                <div className="page-titles pb-2 pt-1 page-title-sticky">
                    <div className="row">
                        <nav className="col-lg-6 col-md-6 col-12 align-self-center" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center p-0">
                                <li className="breadcrumb-item">
                                    <span className="pointer" id='homehover' onClick={homescreen}>
                                        <i className="ri-home-3-line fs-5"></i>
                                    </span>
                                </li>
                                <li className="breadcrumb-item active " aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page" style={{ fontSize: "15px", fontWeight: "bold" }}>
                                    <span>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: "large", fontWeight: "bold" }}>
                                    <span>Monthly Compliance Report</span>
                                </li>
                            </ol>
                        </nav>
                        <div className="col-lg-6 py-0 text-end">
                            <Tooltip title='Advance Filter'>  <Button className="mx-1" type="primary" shape="round" style={{ display: filterTaskAcc ? "none" : "initial" }} onClick={filterHandle}>
                                <FilterOutlined /> Filter
                            </Button></Tooltip>
                            <button onClick={(e) => {
                                fullscreenMode();
                            }} className="bg-transparent text-black ms-1 full-mode fullscreenradius">
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"} >
                                    <FullscreenOutlined />
                                </Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className={"card-body " + localStorage.getItem('currentTheme')} style={{ position: 'relative', padding: "0px 5px" }}>
                                <Collapse defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }} className="report-collapse" onChange={onChange} >
                                    <Panel header="Monthly Compliance Report" key={key}>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Country"
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    // onPaste={(e) => {
                                                                    //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                                    // }}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.country}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            country: result
                                                                        });
                                                                    }}
                                                                    onChange={(value, data) => {
                                                                        setDomainValue(false)
                                                                        setDomainFilter([])
                                                                        if (value != undefined) {
                                                                            let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                            setEntity(le)
                                                                        }
                                                                        setVendorComplianceScore({
                                                                            ...vendorComplianceScore,
                                                                            country: value != undefined ? value : '',
                                                                            countryName: data != undefined ? data.children : '',
                                                                            divisionName: '',
                                                                            categoryName: '',
                                                                            from_month: '',
                                                                            to_month: '',
                                                                            legal_entity_name: '',
                                                                            domainName: '',
                                                                            vendor_name: ''


                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                    value={vendorComplianceScore.countryName || undefined}
                                                                >
                                                                    {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'countryName',
                                                                    vendorComplianceScore.countryName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{vendorComplianceScore.countryName}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4 text-wrap-report'>
                                                    <label><b>Legal Entity : </b>  <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Legal Entity"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.legalEntity}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        legalEntity: result
                                                                    });
                                                                }}
                                                                onChange={(data, value) => {
                                                                    setDomainValue(false)
                                                                    setDomainFilter([])
                                                                    Setlegalstate(true)
                                                                    setCurrentEntity(data != undefined ? data : localStorage.getItem('SelectedEntityid'))
                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        legal_entity_id: data != undefined ? data : '',
                                                                        legal_entity_name: value != undefined ? value.children : '',
                                                                        divisionName: '',
                                                                        categoryName: '',
                                                                        from_month: '',
                                                                        to_month: '',
                                                                        domainName: '',
                                                                        vendor_name: ''

                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%', marginTop: '5px' }}

                                                                value={vendorComplianceScore.legal_entity_name || undefined}
                                                                disabled={vendorComplianceScore.country == '' ? true : false}
                                                            >
                                                                {entity && entity.length > 0 && entity.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                vendorComplianceScore.legal_entity_name,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b>  <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {domainValue == false || domainFilter && domainFilter.length > 1 ?
                                                            <>
                                                                <Select
                                                                    onChange={(value, data) => {
                                                                        getDomainBasedRecords(value)
                                                                        setVendorComplianceScore({
                                                                            ...vendorComplianceScore,
                                                                            domain: value != undefined ? value : '',
                                                                            domainName: data != undefined ? data.children : '',
                                                                            divisionName: '',
                                                                            categoryName: '',
                                                                            from_month: '',
                                                                            to_month: '',
                                                                            vendor_name: ''
                                                                        })
                                                                    }}
                                                                    allowClear={true}
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    // onPaste={(e) => {
                                                                    //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                                    // }}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.domain}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            domain: result
                                                                        });
                                                                    }}
                                                                    placeholder="Enter Domain Name"
                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                    value={vendorComplianceScore.domainName || undefined}
                                                                    disabled={entityid == '' ? true : false}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}


                                                                >
                                                                    {domainFilter && domainFilter.length > 0 && domainFilter.map((item, i) => {

                                                                        if (item != undefined) {
                                                                            return (
                                                                                <Option key={item.d_id}>
                                                                                    {item.d_name}
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'domain',
                                                                    vendorComplianceScore.domain,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Domain Required',
                                                                        }
                                                                    })}
                                                            </>
                                                            : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain && domain.length > 0 && domain[0].d_name}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: "5px" }}>
                                                <div className="row">
                                                    <div className='col-md-4'>
                                                        <label style={{ "fontWeight": "bold" }}>From Month : <span style={{ color: "red" }} >*</span></label>
                                                        <DatePicker allowClear={true} style={{ width: '100%', marginTop: '5px' }} picker="month" format={'MMMM-YYYY'}
                                                            value={vendorComplianceScore.from_month ? moment(vendorComplianceScore.from_month) : ''}
                                                            disabled={vendorComplianceScore.legal_entity_name ? false : true}
                                                            disabledDate={(current) => {
                                                                let customDate = moment().format("YYYY-MM-DD");
                                                                return current.year() < year - 1 || current && current > moment(customDate, "YYYY-MM-DD")
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                let temp = moment(dateString).add(2, 'months')
                                                                setVendorComplianceScore({
                                                                    ...vendorComplianceScore,
                                                                    from_month: dateString,
                                                                    to_month: temp._d == 'Invalid Date' ? '' : temp
                                                                })
                                                            }}
                                                            onKeyDown={(event) => {
                                                                event.preventDefault();
                                                            }}
                                                        />
                                                        {validator.current.message(
                                                            'from_month',
                                                            vendorComplianceScore.from_month,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'From Month Required',
                                                                }
                                                            })}
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label style={{ "fontWeight": "bold" }}>To Month : <span style={{ color: "red" }} >*</span></label>
                                                        <DatePicker allowClear={true} format={'MMMM-YYYY'} style={{ width: '100%', marginTop: '5px' }} picker="month"
                                                            value={vendorComplianceScore.to_month ? moment(vendorComplianceScore.to_month) : ''}
                                                            disabled={vendorComplianceScore.legal_entity_name ? false : true}
                                                            disabledDate={(current) => {
                                                                return (current > moment((moment(vendorComplianceScore.from_month).add(11, 'M').format("YYYY-MM-DD")))) || (current < moment(moment(vendorComplianceScore.from_month).format("YYYY-MM-DD"), "YYYY-MM-DD"))
                                                            }}
                                                            onKeyDown={(event) => {
                                                                event.preventDefault();
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                console.log(dateString, 'dateStrings');
                                                                setVendorComplianceScore({
                                                                    ...vendorComplianceScore,
                                                                    // from_month: dateString,
                                                                    to_month: dateString
                                                                })
                                                            }}
                                                        />
                                                        {validator.current.message(
                                                            'to_month',
                                                            vendorComplianceScore.to_month,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'To Month Required',
                                                                }
                                                            })}
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Division : </b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(value, data) => {
                                                                    getDivisionBasedRecords(value)
                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        division: value != undefined ? value : '',
                                                                        divisionName: data != undefined ? data.children : '',
                                                                        categoryName: '',
                                                                        vendor_name: ''
                                                                    })
                                                                }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.division}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        division: result
                                                                    });
                                                                }}
                                                                placeholder="Select Division"
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                value={vendorComplianceScore.divisionName || undefined}
                                                                disabled={vendorComplianceScore.entityid == '' ? true : false}
                                                                showSearch
                                                                allowClear={true}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                            >
                                                                {division && division.length > 0 && division.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.div_id}>
                                                                            {item.div_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: "5px" }}>
                                                <div className="row">
                                                    <div className='col-md-4'>
                                                        <label><b>Category : </b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.category}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        category: result
                                                                    });
                                                                }}
                                                                onChange={(value, data) => {
                                                                    getCategoryBasedRecords(value)
                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        category: value != undefined ? value : '',
                                                                        categoryName: data != undefined ? data.children : '',
                                                                        vendor_name: ''
                                                                    })
                                                                }}
                                                                allowClear={true}
                                                                placeholder="Select Category"
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                value={vendorComplianceScore.categoryName || undefined}
                                                                disabled={vendorComplianceScore.entityid == '' ? true : false}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                            >
                                                                {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.cat_id}>
                                                                            {item.cat_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Vendor :</b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.vendor}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        vendor: result
                                                                    });
                                                                }}
                                                                onChange={(value, data) => {
                                                                    console.log(data, 'datadatadata')
                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        vendor_id: value != undefined ? value : '',
                                                                        vendor_name: data != undefined ? `${data.children[0]}-${data.children[2]}` : ''
                                                                    })
                                                                }}
                                                                allowClear={true}
                                                                placeholder="Select Vendor"
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                value={vendorComplianceScore.vendor_name || undefined}
                                                                disabled={vendorComplianceScore.domainName == '' ? true : false}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                            >
                                                                {vendorlist && vendorlist.length > 0 && vendorlist.map((item, i) => {

                                                                    return (
                                                                        <Option key={item.unit_id}>
                                                                            {item.unit_code} - {item.unit_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 popupbtncolour'>
                                                        <Button type="primary" shape="round" className={"addbutton " + localStorage.getItem("currentTheme")} style={{ marginLeft: "70px", marginRight: "10px" }} onClick={onshow} icon={<EyeOutlined />}
                                                        >
                                                            Show
                                                        </Button>
                                                        <Button type="primary" shape="round" className={"addbutton " + localStorage.getItem("currentTheme")} style={{ background: "#32a852", borderColor: "#32a852" }} icon={<ExportOutlined />} onClick={showModal} >
                                                            Export
                                                        </Button>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                                {tableshow == true ?
                                    <Card className="rounded-3">
                                        <div className='col-md-12 mt-0 mb-1 filter-detial-sticky' >
                                            <div className='row'>
                                                <div className='col-md-5 text-wrap-report'>
                                                    <label><b>Legal Entity : </b>{vendorComplianceScore.legalEntityLabel}</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label><b>Country : </b>{vendorComplianceScore.countryLabel}</label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b>{vendorComplianceScore.domainLabel}</label>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='ant-table-wrapper'>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content userprivclass1'>
                                                        <table >
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className="ant-table-thead table-head-stick" >
                                                                <tr>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'}> <centre>#</centre> </th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} title='Compliance Task' style={{ width: '180px' }} >Act</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '170px' }}  ><centre>Compliance Task</centre></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }} ><centre>Criticality</centre></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }} ><center>User Name</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }} >Activity Status</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}  ><center>Due Date</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }} ><center>Compliance Status</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '80px' }} ><center>Uploaded Document</center></th>
                                                                </tr>
                                                            </thead>
                                                            {filteredArrayState && filteredArrayState.length ? filteredArrayState.map((item, i) => {
                                                                return (
                                                                    <tbody className='ant-table-tbody'>
                                                                        <tr className="bg-lightdark">
                                                                            <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                <div className='bg-white shadow-sm border-radius-reports'>
                                                                                    <div className="col-md-12">
                                                                                        <div className='row m-2'>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Vendor :</b> {item[0].unit_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Division :</b> {item[0].division_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Category :</b> {item[0].category_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        {titledata && titledata.map((itemdata, i) => {

                                                                            // let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                            // if (data != undefined) {
                                                                            // if (data.act_name == itemdata.parent) {
                                                                            return <Fragment>
                                                                                {itemdata.child.map((items) => {
                                                                                    if (item[0].unit_id == items.unit_id) {
                                                                                        return <tr>
                                                                                            <td className='ant-table-cell'><centre>{index + 1}</centre></td>
                                                                                            <td className='ant-table-cell'>{items.statutory_mapping}</td>

                                                                                            <td title={items.compliance_task} className='ant-table-cell'>
                                                                                                <span className='d-flex justify-content-start align-items-center'>
                                                                                                    <Tooltip title={items.compliance_description} >
                                                                                                        <span className='mx-1 text-primary'><i className="ri-information-fill pointer"></i></span>
                                                                                                    </Tooltip> &nbsp;
                                                                                                    {items.compliance_task}
                                                                                                </span></td>
                                                                                            <td className='ant-table-cell'><center>{items.criticality_name}</center></td>
                                                                                            {items.childData && items.childData.length > 0 ?
                                                                                                <td className='ant-table-cell'><center><Link onClick={(e) => { getChildDetails(items.childData) }}>{items.user_name}</Link></center></td> :
                                                                                                <td className='ant-table-cell'><center>{items.user_name}</center></td>
                                                                                            }
                                                                                            <td className='ant-table-cell text-center'>
                                                                                                <span className='d-flex align-items-center justify-content-center'>{items.activity_status}&nbsp;
                                                                                                    <span className='text-primary'>
                                                                                                        {items.history_count = 1 && items.remarks_count == 1 ? <Tooltip title='Click to View Remarks History'>
                                                                                                            <i className='ri-information-fill pointer' onClick={(e) => getRemarksList(items)} /></Tooltip> : false}</span>
                                                                                                    <span className='text-primary'>
                                                                                                        {
                                                                                                            items.interim_count != 0 ?
                                                                                                                <>
                                                                                                                    <Tooltip
                                                                                                                        title={
                                                                                                                            "Click to View Interim History"
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <i className="ri-information-fill"
                                                                                                                            onClick={() => {
                                                                                                                                const payload = [
                                                                                                                                    authtoken,
                                                                                                                                    {
                                                                                                                                        session_token: authtoken,
                                                                                                                                        request: [
                                                                                                                                            "GetComplianceRemarksHistoryData",
                                                                                                                                            {
                                                                                                                                                "legal_entity_id": entityid,
                                                                                                                                                "unit_id": items.unit_id,
                                                                                                                                                "compliance_history_id": items.compliance_history_id
                                                                                                                                            }
                                                                                                                                        ]
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                                getHistoryRemarks({
                                                                                                                                    payload: payload,
                                                                                                                                    paramid: paramid,
                                                                                                                                });
                                                                                                                                setRemarksModal(true);
                                                                                                                            }
                                                                                                                            }

                                                                                                                        // remarksHistory(items.le_id, items.compliance_history_id, items.unit_id)}
                                                                                                                        />
                                                                                                                    </Tooltip>
                                                                                                                </>
                                                                                                                : ''}
                                                                                                    </span>
                                                                                                </span>
                                                                                            </td>
                                                                                            {/* <td className='ant-table-cell'>{items.user_name}</td> */}
                                                                                            {/* <td className='ant-table-cell'>{items.activity_status}</td> */}
                                                                                            <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                            <td className='ant-table-cell'>{items.task_status}</td>
                                                                                            <td className='ant-table-cell'><center>
                                                                                                {items.document_name || items.approval_document_names || items.concur_document_name ?
                                                                                                    <a href='javascript::'><FileTextOutlined style={{ fontSize: '20px' }} onClick={(e) => getDownloadRecords(items)} /></a>
                                                                                                    : false}
                                                                                            </center></td>
                                                                                            {/*<td className='ant-table-cell'>
                                                                                                <center>{
                                                                                                    <a target="_blank" onClick={() => {
                                                                                                        chilData(items.document_name, items.approval_document_names, items.concur_document_name, items.start_date)
                                                                                                    }}>
                                                                                                        {items.document_name ? <BookTwoTone /> : ''}
                                                                                                    </a>

                                                                                                }
                                                                                            </center></td>*/}
                                                                                            <Modal title={'Uploaded Documents'} className={"remark-header add-service-prv " + localStorage.getItem("currentTheme")} open={isModalVisible} footer={null} onCancel={handleCancel} maskClosable={false}>
                                                                                                <Table
                                                                                                    className={localStorage.getItem("currentTheme")}
                                                                                                    columns={columns}
                                                                                                    dataSource={Documentdata}
                                                                                                    bordered
                                                                                                    pagination={false} />
                                                                                            </Modal>
                                                                                            <Modal
                                                                                                open={interimModal}
                                                                                                className={" add-service-prv " + localStorage.getItem('currentTheme')}
                                                                                                title="Interim Status History"
                                                                                                footer={null}
                                                                                                onCancel={handleClose}
                                                                                                maskClosable={false}
                                                                                            >
                                                                                                <Table
                                                                                                    style={{ marginTop: "2%" }}
                                                                                                    className={localStorage.getItem("currentTheme")}
                                                                                                    columns={interimcolumns}
                                                                                                    dataSource={interimdata && interimdata}
                                                                                                    bordered
                                                                                                    pagination={false}
                                                                                                />
                                                                                            </Modal>
                                                                                            <span hidden>{index = index + 1}</span>

                                                                                        </tr>
                                                                                    }

                                                                                })

                                                                                }

                                                                            </Fragment>

                                                                            // }
                                                                            // }
                                                                        })
                                                                        }

                                                                    </tbody>
                                                                )
                                                            }) :
                                                                <tr>
                                                                    <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                </tr>
                                                            }
                                                        </table>
                                                        <br />
                                                        <Modal
                                                            title="Mapping Lists"
                                                            className={"remark-header modelradius add-service-prv " + localStorage.getItem("currentTheme")}
                                                            open={isModalOpenChild}
                                                            footer={null}
                                                            onOk={handleOkChild}
                                                            onCancel={handleCancelChild}
                                                            maskClosable={false}
                                                        >
                                                            <Table
                                                                className={localStorage.getItem("currentTheme")}
                                                                size={"small"}
                                                                columns={columnData}
                                                                dataSource={modalDataState}
                                                                bordered
                                                                pagination={false}
                                                            />
                                                        </Modal>
                                                        <Modal
                                                            title="Remarks History"
                                                            className={"remark-header modelradius add-service-prv " + localStorage.getItem("currentTheme")}
                                                            open={isRemarksModal}
                                                            footer={null}
                                                            onOk={handleOkRemarks}
                                                            onCancel={handleCancelRemarks}
                                                            maskClosable={false}
                                                        >
                                                            <Table
                                                                className={localStorage.getItem("currentTheme")}
                                                                size={"small"}
                                                                columns={remarksColumn}
                                                                dataSource={filterValue.remarks && filterValue.remarks.remarks_history && filterValue.remarks.remarks_history.length > 0 && filterValue.remarks.remarks_history}
                                                                bordered
                                                                pagination={false}
                                                            />
                                                        </Modal>
                                                        <Modal open={uploadModal}
                                                            title="Uploaded Documents"
                                                            className={" add-service-prv " + localStorage.getItem('currentTheme')}
                                                            footer={null}
                                                            onCancel={modalOnChane}
                                                            maskClosable={false}
                                                        >
                                                            <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                                    <div className='ant-table-container'>
                                                                        <div className='ant-table-content  userprivclass1'></div>
                                                                        <table>
                                                                            <colgroup>
                                                                                <col style={{ width: '10px' }}></col>
                                                                            </colgroup>
                                                                            <thead className="ant-table-thead table-head-stick" >
                                                                                <tr>
                                                                                    <th className={'ant-table-cell'} style={{ width: '5%' }}>#</th>
                                                                                    <th className={'ant-table-cell'} style={{ width: '30%' }}>Assignee</th>
                                                                                    <th className={'ant-table-cell'} style={{ width: '30%' }}>Concurrer</th>
                                                                                    <th className={'ant-table-cell'} style={{ width: '30%' }}>Approver</th>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className='ant-table-tbody'>
                                                                                {commonArray && commonArray.length > 0 && commonArray.map((commonList) => {
                                                                                    return (
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td className={'ant-table-cell'}><center>{sno + 1}</center></td>
                                                                                            <td className={'ant-table-cell'}><Link onClick={() => {
                                                                                                downloaddocument(modalData.unit_id, modalData.start_date, commonList.assignee)
                                                                                            }}>{commonList.assignee ? commonList.assignee : ''}</Link></td>
                                                                                            <td className={'ant-table-cell'}><Link onClick={() => {
                                                                                                downloaddocument(modalData.unit_id, modalData.start_date, commonList.concur)
                                                                                            }}>{commonList.concur ? commonList.concur : ''}</Link></td>
                                                                                            <td className={'ant-table-cell'}><Link onClick={() => {
                                                                                                downloaddocument(modalData.unit_id, modalData.start_date, commonList.approver)
                                                                                            }}>{commonList.approver ? commonList.approver : ''}</Link></td>
                                                                                            <span hidden>
                                                                                                {
                                                                                                    (sno = sno + 1)
                                                                                                }
                                                                                            </span>
                                                                                        </tr>
                                                                                    )
                                                                                })}

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Modal>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {/* {index === 0 ? '' :
                                                <div className='col-md-12 mt-2' style={{ position: 'absolute', bottom: '-25px', left: 0 }}>
                                                    <label style={{ marginLeft: "15px" }}>Showing {pageSize * (current - 1) + 1} to {index} of {tabledatas && tabledatas.total_count} entries</label>
                                                </div>
                                            } */}

                                            <div
                                                // className="btn btn-light-success rounded-pill px-4 text-success"
                                                style={{ position: 'absolute', bottom: '-25px', right: 0 }}
                                            // onClick={submitrec}
                                            >
                                                {/* <div style={{ float: 'right' }}>
                                                    <Pagination
                                                        current={current}
                                                        showSizeChanger={true}
                                                        // showQuickJumper={true}
                                                        onShowSizeChange={() => {
                                                            setCurrent(1)
                                                            // setpageState(true)
                                                        }}
                                                        pageSizeOptions={[25, 50, 100]}
                                                        hideOnSinglePage={true}
                                                        defaultPageSize={25}
                                                        onChange={(page, pageSizes) => {
                                                            setpageState(true)
                                                            setCurrent(pageSize !== pageSizes ? 1 : page);
                                                            setPageSize(pageSizes)
                                                        }}
                                                        total={tabledatas && tabledatas.total_count}
                                                    />


                                                </div> */}

                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            {index === 0 ? '' : <>
                                                <div className="col-md-6">
                                                    <label style={{ marginLeft: "15px" }}>Showing {pageSize * (current - 1) + 1} to {index} of {tabledatas && tabledatas.total_count} entries</label>
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <Pagination
                                                        current={current}
                                                        showSizeChanger={true}
                                                        // showQuickJumper={true}
                                                        onShowSizeChange={() => {
                                                            setCurrent(1)
                                                            // setpageState(true)
                                                        }}
                                                        pageSizeOptions={[25, 50, 100]}
                                                        hideOnSinglePage={true}
                                                        defaultPageSize={25}
                                                        onChange={(page, pageSizes) => {
                                                            setpageState(true)
                                                            setCurrent(pageSize !== pageSizes ? 1 : page);
                                                            setPageSize(pageSizes)
                                                        }}
                                                        total={tabledatas && tabledatas.total_count}
                                                    />
                                                </div>
                                            </>
                                            }
                                        </div>
                                    </Card>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal onCancel={setcancelFilter} footer={false} title="Monthly Compliance Report" maskClosable={false}
                className={"add-service-prv cons-report " + localStorage.getItem("currentTheme")} open={filterModalVisible}>
                <div className="col-md-12">
                    <div className="row">
                        <div className='col-md-4'>
                            <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                            <div className="form-group">
                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                    <div className="form-group">
                                        <Select
                                            getPopupContainer={trigger => trigger.parentNode}
                                            allowClear={true}
                                            size="default"
                                            placeholder="Enter Country"
                                            onDrop={(e) => {
                                                e.preventDefault();
                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                return false;
                                            }}
                                            // onPaste={(e) => {
                                            //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                            // }}
                                            onPaste={onPasteToaster}
                                            searchValue={selectSearchValue.country}
                                            onSearch={(value) => {
                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                setSelectSearchValue({
                                                    ...selectSearchValue,
                                                    country: result
                                                });
                                            }}
                                            onChange={(value, data) => {
                                                setDomainValue(false)
                                                setDomainFilter([])
                                                if (value != undefined) {
                                                    let le = _.filter(sessionArr, { c_id: Number(value) })
                                                    setEntity(le)
                                                }
                                                setVendorComplianceScore({
                                                    ...vendorComplianceScore,
                                                    country: value != undefined ? value : '',
                                                    countryName: data != undefined ? data.children : '',
                                                    divisionName: '',
                                                    categoryName: '',
                                                    from_month: '',
                                                    to_month: '',
                                                    legal_entity_name: '',
                                                    domainName: '',
                                                    vendor_name: ''
                                                })
                                            }}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            style={{ width: '100%', marginTop: '5px' }}
                                            value={vendorComplianceScore.countryName || undefined}
                                        >
                                            {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                return (
                                                    <Option key={item.c_id}>
                                                        {item.c_name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                        {validator.current.message(
                                            'countryName',
                                            vendorComplianceScore.countryName,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Country Required',
                                                }
                                            })}
                                    </div> :
                                    <p>{vendorComplianceScore.countryName}</p>
                                }
                            </div>
                        </div>
                        <div className='col-md-4 text-wrap-report'>
                            <label><b>Legal Entity : </b>  <span style={{ color: "red" }}>*</span></label>
                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                <div className="form-group">
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        allowClear={true}
                                        size="default"
                                        placeholder="Enter Legal Entity"
                                        onDrop={(e) => {
                                            e.preventDefault();
                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                            return false;
                                        }}
                                        // onPaste={(e) => {
                                        //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                        // }}
                                        onPaste={onPasteToaster}
                                        searchValue={selectSearchValue.legalEntity}
                                        onSearch={(value) => {
                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                            setSelectSearchValue({
                                                ...selectSearchValue,
                                                legalEntity: result
                                            });
                                        }}
                                        onChange={(data, value) => {
                                            setDomainValue(false)
                                            setDomainFilter([])
                                            Setlegalstate(true)
                                            setCurrentEntity(data != undefined ? data : localStorage.getItem('SelectedEntityid'))
                                            setVendorComplianceScore({
                                                ...vendorComplianceScore,
                                                legal_entity_id: data != undefined ? data : '',
                                                legal_entity_name: value != undefined ? value.children : '',
                                                divisionName: '',
                                                categoryName: '',
                                                from_month: '',
                                                to_month: '',
                                                domainName: '',
                                                vendor_name: ''

                                            })
                                        }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%', marginTop: '5px' }}

                                        value={vendorComplianceScore.legal_entity_name || undefined}
                                        disabled={vendorComplianceScore.country == '' ? true : false}
                                    >
                                        {entity && entity.length > 0 && entity.map((item, i) => {
                                            return (
                                                <Option key={item.le_id}>
                                                    {item.le_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {validator.current.message(
                                        'legalentity',
                                        vendorComplianceScore.legal_entity_name,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Legal Entity Required',
                                            }
                                        })}
                                </div> :
                                <p>{localStorage.getItem('SelectedEntity')}</p>
                            }
                        </div>
                        <div className='col-md-4'>
                            <label><b>Domain : </b>  <span style={{ color: "red" }}>*</span></label>
                            <div className="form-group">
                                {domainValue == false || domainFilter && domainFilter.length > 1 ?
                                    <>
                                        <Select
                                            getPopupContainer={trigger => trigger.parentNode}

                                            onChange={(value, data) => {
                                                getDomainBasedRecords(value)
                                                setVendorComplianceScore({
                                                    ...vendorComplianceScore,
                                                    domain: value != undefined ? value : '',
                                                    domainName: data != undefined ? data.children : '',
                                                    divisionName: '',
                                                    categoryName: '',
                                                    from_month: '',
                                                    to_month: '',
                                                    vendor_name: ''
                                                })
                                            }}
                                            allowClear={true}
                                            onDrop={(e) => {
                                                e.preventDefault();
                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                return false;
                                            }}
                                            // onPaste={(e) => {
                                            //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                            // }}
                                            onPaste={onPasteToaster}
                                            searchValue={selectSearchValue.domain}
                                            onSearch={(value) => {
                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                setSelectSearchValue({
                                                    ...selectSearchValue,
                                                    domain: result
                                                });
                                            }}
                                            placeholder="Enter Domain Name"
                                            style={{ width: '100%', marginTop: '5px' }}
                                            value={vendorComplianceScore.domainName || undefined}
                                            disabled={entityid == '' ? true : false}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}


                                        >
                                            {domainFilter && domainFilter.length > 0 && domainFilter.map((item, i) => {

                                                if (item != undefined) {
                                                    return (
                                                        <Option key={item.d_id}>
                                                            {item.d_name}
                                                        </Option>
                                                    );
                                                }
                                            })}
                                        </Select>
                                        {validator.current.message(
                                            'domain',
                                            vendorComplianceScore.domain,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Domain Required',
                                                }
                                            })}
                                    </>
                                    : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain && domain.length > 0 && domain[0].d_name}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: "5px" }}>
                        <div className="row">
                            <div className='col-md-4'>
                                <label style={{ "fontWeight": "bold" }}>From Month : <span style={{ color: "red" }} >*</span></label>
                                <DatePicker allowClear={true} style={{ width: '100%', marginTop: '5px' }} picker="month" format={'MMMM-YYYY'}
                                    value={vendorComplianceScore.from_month ? moment(vendorComplianceScore.from_month) : ''}
                                    disabled={vendorComplianceScore.legal_entity_name ? false : true}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    disabledDate={(current) => {
                                        let customDate = moment().format("YYYY-MM-DD");
                                        return current.year() < year - 1 || current && current > moment(customDate, "YYYY-MM-DD")
                                    }}
                                    onKeyDown={(event) => {
                                        event.preventDefault();
                                    }}
                                    onChange={(date, dateString) => {
                                        let temp = moment(dateString).add(1, 'months')
                                        setVendorComplianceScore({
                                            ...vendorComplianceScore,
                                            from_month: dateString,
                                            to_month: temp._d == 'Invalid Date' ? '' : temp
                                        })
                                    }}
                                />
                                {validator.current.message(
                                    'from_month',
                                    vendorComplianceScore.from_month,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'From Month Required',
                                        }
                                    })}
                            </div>
                            <div className='col-md-4'>
                                <label style={{ "fontWeight": "bold" }}>To Month : <span style={{ color: "red" }} >*</span></label>
                                <DatePicker allowClear={true} format={'MMMM-YYYY'} style={{ width: '100%', marginTop: '5px' }} picker="month"
                                    value={vendorComplianceScore.to_month ? moment(vendorComplianceScore.to_month) : ''}
                                    disabled={vendorComplianceScore.legal_entity_name ? false : true}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    disabledDate={(current) => {
                                        return (current > moment((moment(vendorComplianceScore.from_month).add(11, 'M').format("YYYY-MM-DD")))) || (current < moment(moment(vendorComplianceScore.from_month).format("YYYY-MM-DD"), "YYYY-MM-DD"))
                                    }}
                                    onKeyDown={(event) => {
                                        event.preventDefault();
                                    }}
                                    onChange={(date, dateString) => {
                                        console.log(dateString, 'dateStrings');
                                        setVendorComplianceScore({
                                            ...vendorComplianceScore,
                                            // from_month: dateString,
                                            to_month: dateString
                                        })
                                    }}
                                />
                                {validator.current.message(
                                    'to_month',
                                    vendorComplianceScore.to_month,
                                    'required',
                                    {
                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'To Month Required',
                                        }
                                    })}
                            </div>
                            <div className='col-md-4'>
                                <label><b>Division : </b></label>
                                <div className="form-group">
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}

                                        onChange={(value, data) => {
                                            getDivisionBasedRecords(value)
                                            setVendorComplianceScore({
                                                ...vendorComplianceScore,
                                                division: value != undefined ? value : '',
                                                divisionName: data != undefined ? data.children : '',
                                                categoryName: '',
                                                vendor_name: ''
                                            })
                                        }}
                                        onDrop={(e) => {
                                            e.preventDefault();
                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                            return false;
                                        }}
                                        // onPaste={(e) => {
                                        //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                        // }}
                                        onPaste={onPasteToaster}
                                        searchValue={selectSearchValue.division}
                                        onSearch={(value) => {
                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                            setSelectSearchValue({
                                                ...selectSearchValue,
                                                division: result
                                            });
                                        }}
                                        placeholder="Select Division"
                                        style={{ width: '100%', marginTop: '5px' }}
                                        value={vendorComplianceScore.divisionName || undefined}
                                        disabled={vendorComplianceScore.entityid == '' ? true : false}
                                        showSearch
                                        allowClear={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                    >
                                        {division && division.length > 0 && division.map((item, i) => {
                                            return (
                                                <Option key={item.div_id}>
                                                    {item.div_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: "5px" }}>
                        <div className="row">
                            <div className='col-md-4'>
                                <label><b>Category : </b></label>
                                <div className="form-group">
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}

                                        onDrop={(e) => {
                                            e.preventDefault();
                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                            return false;
                                        }}
                                        // onPaste={(e) => {
                                        //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                        // }}
                                        onPaste={onPasteToaster}
                                        searchValue={selectSearchValue.category}
                                        onSearch={(value) => {
                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                            setSelectSearchValue({
                                                ...selectSearchValue,
                                                category: result
                                            });
                                        }}
                                        onChange={(value, data) => {
                                            getCategoryBasedRecords(value)
                                            setVendorComplianceScore({
                                                ...vendorComplianceScore,
                                                category: value != undefined ? value : '',
                                                categoryName: data != undefined ? data.children : '',
                                                from_month: '',
                                                to_month: '',
                                                vendor_name: ''
                                            })
                                        }}
                                        allowClear={true}
                                        placeholder="Select Category"
                                        style={{ width: '100%', marginTop: '5px' }}
                                        value={vendorComplianceScore.categoryName || undefined}
                                        disabled={vendorComplianceScore.entityid == '' ? true : false}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                    >
                                        {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                            return (
                                                <Option key={item.cat_id}>
                                                    {item.cat_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <label><b>Vendor :</b></label>
                                <div className="form-group">
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}

                                        onDrop={(e) => {
                                            e.preventDefault();
                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                            return false;
                                        }}
                                        // onPaste={(e) => {
                                        //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                        // }}
                                        onPaste={onPasteToaster}
                                        searchValue={selectSearchValue.vendor}
                                        onSearch={(value) => {
                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                            setSelectSearchValue({
                                                ...selectSearchValue,
                                                vendor: result
                                            });
                                        }}
                                        onChange={(value, data) => {
                                            setVendorComplianceScore({
                                                ...vendorComplianceScore,
                                                vendor_id: value != undefined ? value : '',
                                                vendor_name: data != undefined ? data.children : ''
                                            })
                                        }}
                                        allowClear={true}
                                        placeholder="Select Vendor"
                                        style={{ width: '100%', marginTop: '5px' }}
                                        value={vendorComplianceScore.vendor_name || undefined}
                                        disabled={vendorComplianceScore.domainName == '' ? true : false}
                                        showSearch
                                        optionFilterProp="children"
                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                    >
                                        {vendorlist && vendorlist.length > 0 && vendorlist.map((item, i) => {

                                            return (
                                                <Option key={item.unit_id}>
                                                    {item.unit_code} - {item.unit_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                        <div className='row'>
                            <div className='col-md-4'></div>
                            <div className='col-md-4 popupbtncolour'>
                                <Button type="primary" shape="round" className={"addbutton " + localStorage.getItem("currentTheme")} style={{ marginLeft: "70px", marginRight: "10px" }} onClick={onshow} icon={<EyeOutlined />}
                                >
                                    Show
                                </Button>
                                <Button type="primary" shape="round" className={"addbutton " + localStorage.getItem("currentTheme")} style={{ background: "#32a852", borderColor: "#32a852" }} icon={<ExportOutlined />} onClick={showModal} >
                                    Export
                                </Button>
                            </div>
                            <div className='col-md-4'></div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Drawer title="Select Fields" width={500} className={'add-service-prv export-checkbox resize-drawer ' + localStorage.getItem('currentTheme')} open={isModalOpen} footer={null} onClose={handleCancel} onCancel={handleCancel} maskClosable={false}>
                <div className='d-flex flex-wrap'>

                    <input id="select_all"
                        checked={checkboxValue.length === 34 ? true : false}
                        width="100%" type="checkbox" autocomplete="off" onClick={(e) => {
                            let checked = e.target.checked
                            selectAllCheckBox(checked)
                        }} /><label for='select_all'>{selectAllText}</label>



                    <input id="column_1" name='chk' type="checkbox" value='1' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_1'>Vendor Code</label>
                    <input id="column_2" name='chk' type="checkbox" value='2' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_2'> Vendor Name </label>
                    <input id="column_7" name='chk' type="checkbox" value='7' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_7'>Location</label>
                    <input id="column_8" name='chk' type="checkbox" value='8' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_8'>Act / Rules</label>
                    <input id="column_9" name='chk' type="checkbox" checked disabled="disabled" value='9' onChange={(e) => (e) => checkBoxOnChange(e)} /> <label for='column_9'>Compliance Task</label>
                    <input id="column_10" name='chk' type="checkbox" checked disabled="disabled" value='10' onChange={(e) => checkBoxOnChange(e)} /><label for='column_10'>Frequency</label>
                    <input id="column_29" name='chk' type="checkbox" value='29' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_29'>Month</label>
                    <input id="column_31" name='chk' type="checkbox" value='31' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_31'>Statutory Status</label>
                    <input id="column_34" name='chk' type="checkbox" value='34' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_34'>Remarks</label>

                    <input id="column_3" name='chk' type="checkbox" value='3' onChange={(e) => checkBoxOnChange(e)} /><label for='column_3'>Vendor Code 1</label>
                    <input id="column_4" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_4'>Vendor Code 2</label>
                    <input id="column_5" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /><label for='column_5'>Vendor Code 3</label>
                    <input id="column_6" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /><label for='column_6'>Vendor Code 4</label>

                    <input id="column_11" name='chk' type="checkbox" value='11' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_11'>Periodicity</label>
                    <input id="column_12" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_12'>Criticality</label>
                    <input id="column_13" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_13'>Division</label>
                    <input id="column_14" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_14'>Category</label>
                    <input id="column_15" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /><label for='column_15'>Assigned By</label>
                    <input id="column_16" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_16'>Assigned To </label>
                    <input id="column_17" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /><label for='column_17'>Assigned Date</label>
                    <input id="column_18" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_18'>Assignee</label>
                    <input id="column_19" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_19'>Completed On</label>
                    <input id="column_20" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_20'>Concurrer</label>
                    <input id="column_21" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_21'>Concurred On</label>

                    <input id="column_22" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_22'>Approver</label>
                    <input id="column_23" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_23'>Approved On</label>
                    <input id="column_24" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_24'>Activity Status </label>
                    <input id="column_25" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_25'>Start Date</label>
                    <input id="column_26" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_26'>Due Date</label>

                    <input id="column_27" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} /><label for='column_27'>Extend Due Date</label>
                    <input id="column_28" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_28'>Task Completion Date / Document Issued Date </label>
                    <input id="column_30" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_30'>Validity Date</label>
                    <input id="column_32" name='chk' type="checkbox" value='32' onChange={(e) => checkBoxOnChange(e)} /><label for='column_32'>Duration</label>
                    <input id="column_33" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} /><label for='column_33'>Document Reference Number</label>
                </div>


                <div className="form-actions text-center popupbtncolour">
                    <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>
                </div>
            </Drawer>

            {/* <Drawer title="Select Fields" width={500} className={'add-service-prv ' + localStorage.getItem('currentTheme')} visible={isModalOpen} footer={null} onClose={handleCancel} onCancel={handleCancel}>
                <div className='d-flex flex-wrap'>
                    <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                        checked={checkboxValue.length === 36 ? true : false}
                        onClick={(e) => {
                            let checked = e.target.checked
                            selectAllCheckBox(checked)
                        }} /> <label for='select_all'>Select All</label>


                    <input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /> <label for='column_1'>  Legal Entity</label>
                    <input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_2'>  Vendor Code</label>
                    <input id="column_3" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_3'>  Vendor Name</label>
                    <input id="column_4" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_4'> Vendor Code 1</label>


                    <input id="column_5" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_5'>Vendor Code 2 </label>
                    <input id="column_6" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_6'>Vendor Code 3 </label>
                    <input id="column_7" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} /><label for='column_7'>Vendor Code 4</label>
                    <input id="column_8" name='chk' type="checkbox" value='8' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_8'> Location</label>


                    <input id="column_9" name='chk' type="checkbox" value='9' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_9'> Domain</label>
                    <input id="column_10" name='chk' type="checkbox" value='10' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_10'> Act / Rules</label>
                    <input id="column_11" name='chk' type="checkbox" value='11' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_11'> Compliance Task</label>
                    <input id="column_12" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /><label for='column_12'> Frequency</label>


                    <input id="column_13" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /><label for='column_13'> Periodicity</label>
                    <input id="column_14" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_14'> Criticality </label>
                    <input id="column_15" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_15'> Division</label>
                    <input id="column_16" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_16'> Category</label>


                    <input id="column_17" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_17'> Assigned By </label>
                    <input id="column_18" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_18'> Assigned To </label>
                    <input id="column_19" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_19'> Assigned Date </label>
                    <input id="column_20" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /><label for='column_20'> Assignee</label>


                    <input id="column_21" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /><label for='column_21'> Completed On</label>
                    <input id="column_22" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_22'> Concurrer</label>
                    <input id="column_23" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_23'> Concurrer On</label>
                    <input id="column_24" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_24'> Approver</label>


                    <input id="column_25" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_25'> Approved On</label>
                    <input id="column_26" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_26'> Activity Status</label>
                    <input id="column_27" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} /><label for='column_27'> Start Date</label>
                    <input id="column_28" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_28'> Due Date</label>



                    <input id="column_29" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} /><label for='column_29'> Extend Due Date</label>
                    <input id="column_30" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_30' > Task Completion Date / Document Issued Date</label>
                    <input id="column_31" name='chk' type="checkbox" value='31' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_31'> Month </label>
                    <input id="column_32" name='chk' type="checkbox" value='32' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_32'> Validate Date</label>


                    <input id="column_33" name='chk' type="checkbox" value='33' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_33'> Statutory Status</label>
                    <input id="column_34" name='chk' type="checkbox" value='34' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_34'> Duration</label>
                    <input id="column_35" name='chk' type="checkbox" value='35' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_35'>Document Reference Number </label>
                    <input id="column_36" name='chk' type="checkbox" value='36' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_36'>Remarks</label>
                </div>
                <div className="form-actions text-center popupbtncolour">
                    <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>
                </div>

            </Drawer> */}



        </Fragment>
    )
}
export default connect(mapStateToProps, {
    MonthlyComplianceFilterReport,
    MonthlyComplianceTable,
    MonthlyComplianceExport,
    Downloadfile,
    getRemarksFilter,
    getdownloaddocument,
    getHistoryRemarks
})(MonthlyComplianceReport);