import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom'
import { Modal, Input, Table, Tooltip, Button } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { remarkslist, remarksupdate } from './../../Store/Action/Master/Remarksmaster';
import { Toaster } from '../../Libs/Toaster';
import { PlayCircleOutlined, SearchOutlined, FullscreenOutlined, UpOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown, setFocus } from "./../../Libs/fullscreenmode";
import ScrollButton from '../Scrollbar';
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    lists: state.Remarksmaster.remarksmasters,
})
const Remarksmaster = ({
    remarkslist,
    lists,
    remarksupdate,
}) => {
    const historynew = useHistory();
    //  Full screen mode //
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    console.log(is_Fullscreen, 'full_screen')
    const location = useLocation();
    const paramid = location.pathname;
    const [pageSize, setPageSize] = useState(10)
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    const serviceProviderRef1 = useRef(null);
    // const [remark, setRemark] = useState()
    const [current, setCurrent] = useState(1);
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [modalVisible, setModaVisible] = useState(false)
    const [remarkValue, setRemarkValue] = useState({
        id: '',
        description: '',
        desc: ''
    })
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const [page1, setPage1] = useState(1);
    const [paginationSize, setPaginationSize] = useState(25);
    const [paginationSize1, setPaginationSize1] = useState(25);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const { Search } = Input;
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');
    // const getColumnSearchProps = (dataIndex, placeholder) => ({
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    //         <div>
    //             <Input
    //                 ref={searchInput}
    //                 placeholder={'Search ' + placeholder}
    //                 value={selectedKeys[0]}
    //                 autoFocus={'on'}
    //                 // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}/>
    //         </div>
    //     ),
    //     filterIcon: (filtered) => (
    //         <SearchOutlined
    //             style={{
    //                 color: filtered ? '#1890ff' : undefined,
    //             }}
    //         />
    //     ),
    //     onFilter: (value, record) =>
    //         record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    //     onFilterDropdownOpenChange: (visible) => {
    //         if (visible) {
    //             setTimeout(() => searchInput.current?.select(), 100);
    //         }
    //     },
    //     render: (text) => text
    // });
    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input  className='form-control'
                    ref={searchInput}
                    placeholder={ placeholder}
                    value={selectedKeys[0]}
                    autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };

    const handleOk = () => {
        setAddFormSubmit(true)
        if (formValidator.current.allValid()) {
            if (remarkValue.desc == remarkValue.description) {
                Toaster.success('No Changes Done');
                setModaVisible(false)
            } else {
                const updateremarks = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateRemarksMaster",
                            {
                                "r_id": remarkValue.id,
                                "r_description": remarkValue.description,
                            }
                        ]
                    }
                ]
                remarksupdate({
                    payload: updateremarks,
                    paramid: paramid,
                    authtoken: authtoken
                })
                setModaVisible(false)
            }
        }
    }
    const handleCancel = () => {
        setModaVisible(false)
    }
    const openModal = (r_id, description) => {
        setRemarkValue({
            ...remarkValue,
            id: r_id,
            description: description,
            desc: description
        })
        setModaVisible(true)
        setTimeout(() => {
            setFocus(serviceProviderRef1)
        }, 500);
    }

    useEffect(() => {
        formValidator.current.showMessages()
    }, [])


    const columns = [
        {
            title: '#',
            key: 'false',
            align: "center",
            width: "15px",
            render: (text, record, index) => {
                return (
                    (page - 1) * paginationSize + index + 1
                )
            },
        },
        {
            title: 'Remarks',
            ...getColumnSearchProps('r_description', 'Remarks'),
            dataIndex: 'r_description',
            key: 'r_description',
            width: '140px',
            ellipsis: true
        },
        {
            title: "Edit",
            dataIndex: 'u_c_name',
            align: 'center',
            width: '30px',
            render: (text, record) => {
                return (
                    <div className='hover'>
                        <Link className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center margin0" onClick={(e) => {
                                openModal(record.r_id, record.r_description)
                                setAddFormSubmit(false)
                            }}>
                            <Tooltip title="Click here to Edit">
                                <EditOutlined /></Tooltip>
                        </Link>
                    </div>
                )
            },
            sortable: false,
        },
    ];

    const columns1 = [
        {
            title: '#',
            key: 'false',
            align: "center",
            width: "15px",
            render: (text, record, index) => {
                return (
                    (page1 - 1) * paginationSize1 + index + 26
                )
            },
        },
        {
            title: 'Remarks',
            ...getColumnSearchProps('r_description', 'Remarks'),
            dataIndex: 'r_description',
            key: 'r_description',
            width: '140px',
            ellipsis: true
        },
        {
            title: "Edit",
            dataIndex: 'u_c_name',
            align: 'center',
            width: '30px',
            render: (text, record) => {
                return (
                    <div className='hover'>
                        <Link className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center margin0" onClick={(e) => {
                                openModal(record.r_id, record.r_description)
                                setAddFormSubmit(false)
                            }}>
                            <Tooltip title="Click here to Edit">
                                <EditOutlined /></Tooltip>
                        </Link>
                    </div>
                )
            },
            sortable: false,
        },
    ];

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetRemarksData",
                        {

                        }
                    ]
                }
            ]

            remarkslist({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])

    const FilterComponent = ({ filterText, onFilter, onClear }) => {
        return (
            <>

                <div className='row'>
                    <div className="col-md-12">
                        <Input
                            type="text"
                            placeholder="Search Here..."
                            value={filterText}
                            onChange={onFilter}
                            autoFocus
                        />
                    </div>
                </div>

            </>
        )
    }

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <>
                <FilterComponent
                    onFilter={(e) => {
                        setFilterText(e.target.value)
                    }}
                    onClear={handleClear}
                    filterText={filterText}
                />

            </>

        );
    }, [filterText, resetPaginationToggle]);

    const filteredItems = lists && lists.length && lists.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const [baseData, setBaseData] = useState([]);
    const [baseData2, setBaseData2] = useState([]);
    const [baseData3, setBaseData3] = useState([]);
    // const [commonSearch, setCommonSearch] = useState();
    useEffect(() => {
        if (lists && lists.length > 0) {
            setBaseData(lists);
        }
    }, [lists])

    useEffect(() => {
        if (lists && lists.length > 0) {
            let temp = []
            let temp1 = []
            for (let i in lists) {
                if (i < 25) {
                    temp.push(lists[i])
                }
                else {
                    temp1.push(lists[i])
                }
            }
            setBaseData3(temp1);
            setBaseData2(temp);
        }
    }, [lists])


    const searchRecords = (e) => {
        if (e == 'clear') {
            setClearText('')
            setBaseData(lists)
            setIconEnable(false)
        } else {
            setClearText(e.target.value)
            if (e.target.value.length > 0) {
                setIconEnable(true)
            } else {
                setIconEnable(false)
            }
            const filterTable = lists.filter(o =>
                Object.keys(o).some(k =>
                    String(o[k])
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
            setBaseData(filterTable)
        }

    };

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
         window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    const onInputChange = e => {
        const { value } = e.target;
        // onChange={(e) => {
        let chk_valid = ''

        for (let i = 0; i < value.length; i++) {
            if (value[i] == '<' || value[i] == '>') {
                chk_valid += value[i]
            }
        }
        if (chk_valid.match('<>')) {
            //   UpdatePannelRemarks[collapseId] = ''
            setRemarkValue({
                ...remarkValue,
                description: ''
            })
            Toaster.error('Please check the input, it having some invalid characters')
        } else {

            const re = /[^|~^]+$/;
            if (value === "" || re.test(value)) {
                if (value.includes('~') || value.includes('^') || value.includes('|')) {
                    return false
                } else {
                    setRemarkValue({
                        ...remarkValue,
                        description: e.target.value
                    })
                }
            }
        }
    }


    const dispatch = useDispatch();
    //  Full screen mode //
    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    //  Full screen mode //
    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    return (
        <Fragment>
            <div>
                <div id='page-wrapper' className="page-wrapper">
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div>
                    <div className="page-titles py-1 pt-2 page-title-sticky">

                        <div className="row">
                            <div className="col-10 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span >Remarks Master</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-2 text-end">
                                <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                    {/* //  Full screen mode // */}
                                    <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid pt-0">
                        <div className="row">
                            <div className="col-md-6 remarksplittable">
                                <div className='service-provider-table'>


                                    <Table

                                        className={localStorage.getItem("currentTheme") + ' remarkstable'}

                                        size={'small'}
                                        columns={columns}
                                        dataSource={baseData2}
                                        bordered
                                        pagination={false}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 remarksplittable">
                                <div className='service-provider-table1'>

                                    <Table

                                        className={localStorage.getItem("currentTheme") + '  remarkstable'}
                                        size={'small'}
                                        columns={columns1}
                                        dataSource={baseData3}
                                        bordered
                                        pagination={false}
                                    // scroll={{ y: 1000 }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>
            <Modal autoFocus={false} open={modalVisible} footer={null} maskClosable={false} onCancel={handleCancel} className="newStyleModalPassword-remarks modelradius" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label className="control-label"> <b>Remarks </b><span style={{ "color": "red" }}>*</span></label>
                            <textarea ref={serviceProviderRef1} autoFocus={true} className='form-control' name='remark' value={remarkValue.description}
                                row='1' cols='4' style={{ resize: 'none', height: '150px' }} maxLength={500} onChange={onInputChange}
                            >
                            </textarea>

                            {formValidator.current.message(
                                'remark',
                                remarkValue.description,
                                ['required', { regex: /[^|~^]+$/ }],
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Remarks Required',
                                        regex: '500 Characters only allowed'
                                    }
                                })}
                        </div>
                    </div>
                </div>
                <div className={"form-actions text-center " + localStorage.getItem('currentTheme')}>

                    {/* <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={handleOk}
                    >Submit
                    </Button> */}
                    {/* <div class="button2" id={localStorage.getItem('currentTheme')} onClick={handleOk}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Submit
                                        </div> */}
                    {/* <button className="noselect viewicon-button addbutton" onClick={handleOk} style={{ marginLeft: '31%', marginTop: '3%', backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} ><span className='text'>Submit</span>
                        <span style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} className="icon"><PlayCircleOutlined />
                        </span>
                    </button> */}

                    <div className={"form-actions text-center pt-3 popupbtncolour " + localStorage.getItem('currentTheme')} >
                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                            icon={<PlayCircleOutlined />} size='default' onClick={handleOk}>
                            Submit
                        </Button>
                    </div>
                </div>

            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
    remarkslist,
    remarksupdate
})(Remarksmaster);