import React, { useEffect, useState, useRef, Fragment } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useHistory } from "react-router-dom";
import {
  Collapse,
  Modal,
  Select,
  Tooltip,
  Card,
  DatePicker,
  Space,
  Descriptions,
  Button,
  Popover,
  Table,
  Typography,
  Input,
  Popconfirm,
  Drawer,
  Form,
  Checkbox,
} from "antd";
import {
  PlusOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  FilterFilled,
  UpOutlined,
  PlayCircleOutlined,
  EyeOutlined,
  DownOutlined,
  UpCircleOutlined,
  FullscreenOutlined,
  FilterOutlined,
  ExclamationCircleTwoTone,
  InfoOutlined,
  ArrowUpOutlined,
  DownloadOutlined,
  RollbackOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import {
  TaskRecallFilters,
  GetTaskRecallList,
  GetRemarksData,
  UpdateTaskRecallStatus,
  GetTaskRecallListShowmore,
  historyrecord, interimrecord
} from "./../../Store/Action/Transcations/Taskrecall";
import { reassignlist, reassignunitlist, reassigndatatable, getusercomplaince, savereassigncomplaince, showmoredata } from './../../Store/Action/Transcations/ReassignComplaince'
import { toast } from "react-toastify";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { update } from "lodash";
import { INVALID_PASSWORD_RESET } from "./../../../src/Store/types/index";
import dayjs from "dayjs";
import { Toaster } from "../../Libs/Toaster";
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown, setFocus } from "./../../Libs/fullscreenmode";
import { validationType, validateSelect, validateInput, onPasteToaster } from './../../Libs/inputValidation';
import ScrollButton from "../Scrollbar";
import Stickicon from "./Stickyicon";
import { HomeRedirect } from './../../Libs/country'
import { getdownloadpath } from '../../Store/Action/Transcations/ComplianceApproval';

const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
  lists: state.ReassignComplaince.reassigndata,
  filterrecall: state.recallfilters.recallfilters,
  filterdatas: state.Statutorysetting.statutorysettings.filterdata,
  recallcompliance: state.recallfilters.recallcompliance,
  recallcomplianceshowmore: state.recallfilters.recallcomplianceshowmore,
  passwrdmodal: state.recallfilters.passwrdmodal,
  historydata: state.recallfilters.recallfilters.history_details,

  interimdata: state.recallfilters.recallfilters.interim_details
});

const TaskRecall = ({
  TaskRecallFilters,
  GetTaskRecallList,
  passwrdmodal,
  GetRemarksData,
  reassignlist,
  UpdateTaskRecallStatus,
  getdownloadpath,
  lists: { reassigndatalist, reassignunitdata, reassignusercompliance, savereassigndatalist },
  filterrecall: {
    userunitsrecall,
    recallremarks,
    recallcompliancestatus,
    count,
    password_reset,
  },
  filterdatas,
  recallcompliance,
  recallcomplianceshowmore,
  GetTaskRecallListShowmore,
  historydata,
  historyrecord, interimrecord,
  interimdata
}) => {
  const historynew = useHistory();
  console.log(recallcompliance, 'recallcompliance');
  console.log(recallcomplianceshowmore, 'recallcomplianceshowmore');
  console.log(userunitsrecall, "userunitsrecall");
  console.log(reassigndatalist, 'reassigndatalist');
  console.log(historydata, "historydata");
  const [dataTableProperties, setDataTableProperties] = useState({
    fixedHeader: true,
    pagesize: 10,
    sizechanger: true,
    pageSizeOptions: ["10", "30", "50"],
  });
  const [page, setPage] = useState(1);
  const { TextArea } = Input;
  const [scroll, setScroll] = useState(false);
  const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
  const [showDropDown, setshowDropDown] = useState(false);
  let [counts, setcounts] = useState(0);
  const callername = JSON.parse(localStorage.getItem('sessionValue'));
  const formValidator2 = useRef(new SimpleReactValidator());
  const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
  const [showValueModal, setShowValueModal] = useState(false);
  const [acccordionTrue, setAccordionTrue] = useState(false);
  const [assignee, setAssignee] = useState([])
  console.log(assignee, 'assignee');
  const [remarksModal, setRemarksModal] = useState(false);
  const [passwordmodal, Setpasswordmodal] = useState(false);
  const [FormShow, setFormShow] = useState(false);
  const [filterTaskAcc, setFilterTaskAcc] = useState(true);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [exitCollapse, setExitCollapse] = useState(false);
  const dispatch = useDispatch();
  const [due, setDue] = useState(false);
  const [checkedRow, setCheckedRow] = useState(false);
  const [mapFilter, setMapFilter] = useState([]);
  const [FormRecall, setFormRecall] = useState(false);
  const [filteredArray, setFilteredArray] = useState([]);
  const [legalstate, Setlegalstate] = useState(false);
  const location = useLocation();
  const paramid = location.pathname;
  const authtoken = localStorage.getItem("authToken");
  //  const entityid = localStorage.getItem('SelectedEntityid');
  const isAuth = localStorage.getItem("isAuthenticated");
  const sessionValue = localStorage.getItem("sessionValue");
  const sessionParsedValue = JSON.parse(sessionValue);
  const sessionArr = sessionParsedValue.entity_info;
  const { Option } = Select;
  const [totalRecord, settotalRecord] = useState([]);
  const [fullscreen, setfullscreen] = useState(false);
  const _ = require("lodash");
  const formValidator = useRef(new SimpleReactValidator());
  const recallSubmit = useRef(new SimpleReactValidator());
  const [entires, setEntires] = useState(0);
  const serviceProviderRef1 = useRef(null);
  const [filterValues, setFilterValues] = useState(
    {
      All: '',
      periodical: '',
      flexi_review: '',
      review: '',
      on_occurrence: '',
      one_time: ''
    }
  )
  const [fileSize, setFileSize] = useState(0);

  const [checked, setChecked] = useState({
    All: false,
    periodical: false,
    flexi_review: false,
    review: false,
    on_occurrence: false,
    one_time: false

  })
  const filterLength = []
  const filterLen = Object.keys(filterValues)
  filterLen.forEach(key => {
    filterValues[key] === true && filterLength.push(
      key
    )
  })


  console.log(passwrdmodal, 'setinvalidmod')

  const [countvalue, setCountvalue] = useState(false);
  const [remarksvalue, Setremarksvalue] = useState();
  const [showdata, Setshowdata] = useState({
    domain: "",
    domainName: '',
    unit: "",
    frequency: 0,
    due_month: "",
    Assignee: null,
    assigne_name: '',
    act: "",
    // legal_entity: '',
    legal_entity:
      localStorage.getItem("SelectedEntity") === "All Legal Entity"
        ? ""
        : localStorage.getItem("SelectedEntity"),
  });
  console.log(showdata, 'showdata');
  const [temp, setTemp] = useState([]);

  const [titleDynamic, setTitleDynamic] = useState([]);
  const [mergedArray, setMergedArray] = useState([]);
  const [temparr, setTemparr] = useState([]);
  console.log(temparr, 'temparr');
  const [finalchild, Setfinalchild] = useState([]);
  const [childlist, Setchildlist] = useState([]);
  const [recalldata, Setrecalldata] = useState({
    action: "",
    remarks: "",
  });
  console.log(recalldata, "recalldatarecalldata");

  const [password, Setpassword] = useState({
    passwordvalue: "",
  });
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [filteredArrayState, setfilteredArrayState] = useState([]);
  const [assigneename, setAssigneename] = useState([]);
  const [updatedArray, setUpdatedArray] = useState([]);
  const [updatedArrayshow, setUpdatedArrayShow] = useState([]);
  const [updatedArrayShowmore, setUpdatedArrayShowmore] = useState([]);
  const [domainValue, setDomainValue] = useState(false)
  const [domainFilter, setDomainFilter] = useState([])
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const [tempFilterArray, setTempFilterArray] = useState([]);
  const [all, setAll] = useState("All");
  const [key, setKey] = useState("1");

  const [searchedValue, setSearchedValue] = useState({
    complianceTasks: '',
    complianceStatus: '',
    dueDate: '',
    completionDate: ''
  })
  const [selectSearchValue, setSelectSearchValue] = useState({
    legalEntity: '',
    domain: '',
    act: '',
    vendor: '',
    assignee: ''
  })

  const [searchedUpdatedArrayFilter, setSearchedUpdatedArrayFilter] = useState(false)

  const [searchingValue, setSearchingValue] = useState([])
  const myRef1 = useRef(null);
  const myRef2 = useRef(null);
  const myRef3 = useRef(null);
  const myRef4 = useRef(null);

  const setcancelFilter = () => {
    setFilterModalVisible(false);
    setFilterTaskAcc(false);
  };

  const [historymodal, sethistorymodal] = useState(false)
  const [interimmodal, setinterimmodal] = useState(false);


  useEffect(() => {

    let filterData = _.filter(reassigndatalist.legal_entity_users, { user_category_id: 5 })
    setAssignee(filterData)
  }, [reassigndatalist])

  useEffect(() => {
    if (fullscreen == true) {
      document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
      document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
      return () => {
        document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
        document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
        document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
      };
    }
  }, [fullscreen]);
  const [domain, setDomain] = useState([])

  useEffect(() => {
    if (userunitsrecall) {
      setDomain(userunitsrecall.domains_list)
    }

  }, [userunitsrecall.domains_list])

  const setFocus = (ref) => {
    setTimeout(() => {
      ref.current.focus();
    })
  };
  // useEffect(() => {
  //   if (domain && domain.length > 0) {
  //     Setshowdata({
  //       ...showdata,
  //       domain: domain && domain.length > 0 && domain[0].d_id
  //     })
  //   }
  // }, [domain])

  const fullscreenMode = () => {
    if (fullscreen == false) {
      fullscreenmode(dispatch);
      setfullscreen(true);
    } else {
      fullscreenexit(dispatch);
      setfullscreen(false);
    }
  }

  const filterHandle = () => {
    setFilterTaskAcc(true);
    setFilterModalVisible(true);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder="Search"
          autoFocus={'on'}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] != null
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const [entityid, setCurrentEntity] = useState(localStorage.getItem(""));



  useEffect(() => {
    if (recallcompliance) {
      let arrayTemp = [];
      for (let i in recallcompliance) {
        arrayTemp.push(recallcompliance[i]);
      }
      var merged = [].concat.apply([], arrayTemp);
      setUpdatedArray([...merged]);
      setUpdatedArrayShow([...merged]);
    }
  }, [recallcompliance]);

  useEffect(() => {
    if (recallcomplianceshowmore) {
      let arrayTemp = [];
      for (let i in recallcomplianceshowmore) {
        arrayTemp.push(recallcomplianceshowmore[i]);
      }
      var merged = [].concat.apply([], arrayTemp);
      setUpdatedArrayShowmore([...merged]);
    }
  }, [recallcomplianceshowmore]);

  useEffect(() => {
    if (updatedArrayShowmore.length > 0) {
      setUpdatedArray([]);
      setUpdatedArray([...updatedArrayshow, ...updatedArrayShowmore]);
    }
  }, [updatedArrayShowmore]);

  useEffect(() => {
    if (localStorage.getItem("SelectedEntityid") != "null") {
      setCurrentEntity(
        localStorage.getItem("SelectedEntityid").replaceAll('"', "")
      );
    }
  }, [localStorage.getItem("SelectedEntityid")]);

  useEffect(() => {
    if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
      Setshowdata({
        ...showdata,
        legal_entity: localStorage.getItem('SelectedEntityid'),
      })
    }

  }, [])

  useEffect(() => {
    if (showdata.legal_entity == localStorage.getItem("SelectedEntity")) {
      Setlegalstate(true);
    }
  }, [showdata.legal_entity]);
  useEffect(() => {
    if (password_reset == "InvalidPassword") {
      Setpasswordmodal(true);
      setAddFormSubmit2(false);
      Setpassword({
        ...password,
        passwordvalue: "",
      });
      dispatch({
        type: INVALID_PASSWORD_RESET,
      });
      setTimeout(() => {
        setFocus(serviceProviderRef1)
      }, 500);
    }
  }, [password_reset]);
  useEffect(() => {
    if (temp) {
      let tempARR = [];

      for (let i in temp) {
        tempARR.push(temp[i].child);
      }
      Setchildlist(tempARR);
    }
  }, [temp]);
  useEffect(() => {
    if (finalchild && finalchild.length > 0) {
      setEntires(finalchild.length);
    }
  }, [finalchild]);
  useEffect(() => {
    let tempArr = [];
    for (let k in childlist) {
      tempArr.push(...childlist[k]);
      Setfinalchild(tempArr);
    }
  }, [childlist]);
  const [valueModal, setValueModal] = useState({
    unit: "",
    address: "",
    compliance_history_id: "",
    compliance_task: "",
    compliance_frequency: "",
    uploaded_document: "",
    document_issued_date: "",
    document_reference_number: "",
    validity_date: "",
    next_due_date: "",
    status: "",
    remarks: "",
    action: "",
    compliance_description: "",
    interim_details: "",
    history_details: '',
    start_date: "",
    domain_id: null,
    country_id: " ",
    occurrence_remarks: null,
    is_not_applicable: 0
  });
  console.log(valueModal, "valueModal123");

  useEffect(() => {
    if (recallremarks) {
      let temp = [];
      for (let i = 0; i < recallremarks.length; i++) {
        if (recallremarks[i].r_description != "") {
          temp.push(recallremarks[i]);
        }
        Setremarksvalue(temp);
      }
    }
  }, [recallremarks]);

  const column = [
    {
      title: "S.No.",
      dataIndex: false,
      width: "30px",
      align: "center",
      render: (text, record, index) => {
        return (page - 1) * 10 + index + 1;
      },
    },
    {
      title: "Description",
      dataIndex: "r_description",
      key: "r_description",
      width: "200px",
      ellipsis: true,
      render: (text, record) => (
        <a
          onClick={() => {
            Setrecalldata({ ...recalldata, remarks: record.r_description });
            setRemarksModal(false);
          }}
        >
          {record.r_description}
        </a>
      ),
    },
  ];

  const Active = () => {
    if (temparr.length > 0) {
      setFormRecall(true);
    } else {
      toast.error("Please Select Compliance to recall");
    }
    if (recallSubmit.current.allValid()) {
      if (temparr.length !== 0) {
        Swal.fire({
          title: "Are you sure?",
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            Setpasswordmodal(true);
            setTimeout(() => {
              setFocus(serviceProviderRef1)
            }, 500);
          }
        });
      }
    }
  };

  const addAllSelectedDataValues = (checked) => {
    if (mergedArray.length > 0) {
      let tempArray = temparr;
      var ele = document.getElementsByName("checked");
      if (checked === true) {
        setCheckedRow(true);
        for (let i in mergedArray) {
          tempArray.push(mergedArray[i]);
        }
        /*Remove Duplicate*/
        let compliance_id = tempArray.map((item) => {
          return item.compliance_id;
        });
        var difference = tempArray.filter(
          (x) => compliance_id.indexOf(x) === -1
        );
        let finaldata = [...new Set(difference)];
        /*Remove Duplicate*/

        setTemparr([...finaldata]);
        for (var i = 0; i < ele.length; i++) {
          if (ele[i].type === "checkbox") ele[i].checked = true;
        }
      } else {
        setCheckedRow(false);
        tempArray = [];
        setTemparr([...tempArray]);
        for (var j = 0; j < ele.length; j++) {
          if (ele[j].type === "checkbox") ele[j].checked = false;
        }
      }
    } else {
      var eleclass = document.getElementsByClassName("allChecked");
      eleclass[0].checked = false;
      Toaster.error("No Compliance Available");
    }
  };

  const scrollup = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY >= 213) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };



  const addSelectedDataValues = (checked, compliance_history_id, parentId) => {
    var ele = document.getElementsByName("allchecked");
    var eleclass = document.getElementsByClassName("allChecked");
    let array;
    let array1;
    let array2;
    let tempArray = temparr;
    if (checkedRow === true) {
      if (checked === true) {
        // array = _.find(recallcompliance, { compliance_history_id: compliance_history_id });
        // tempArray.push(array)
        array1 = _.filter(temp, { index: parentId });
        array = _.find(mergedArray, { index: parentId });
        tempArray.push(array);
        array2 = _.filter(tempArray, { index: parentId });
        if (array2.length === array1[0].child.length) {
          eleclass[0].checked = true;
        }
      } else {
        for (let i in tempArray) {
          if (tempArray[i].compliance_history_id === compliance_history_id) {
            tempArray.splice(i, 1);
          }
          if (updatedArray.length !== temparr.length) {
            setCheckedRow(false);
            eleclass[0].checked = false;
          }
        }
      }
      setTemparr([...tempArray]);
    } else {
      if (checked === true) {
        array = _.find(mergedArray, {
          compliance_history_id: compliance_history_id,
        });
        tempArray.push(array);
        if (tempArray.length === mergedArray.length) {
          eleclass[0].checked = true;
        }
      } else {
        for (let i in tempArray) {
          if (tempArray[i].compliance_history_id === compliance_history_id) {
            tempArray.splice(i, 1);
          }
        }
        eleclass[0].checked = false;
      }
      setTemparr([...tempArray]);
    }
  };

  const submitpassword = (e) => {
    setAddFormSubmit2(true);

    e.preventDefault();
    if (formValidator2.current.allValid()) {
      let temp = [];
      for (let i in temparr) {
        temp.push({
          compliance_id: temparr[i].compliance_id,
          compliance_history_id: temparr[i].compliance_history_id,
          unit_idl: temparr[i].unit_id,
          download_doc: temparr[i].download_url,
          reviewer_doc: temparr[i].reviwer_newdoc,
          interm_doc: temparr[i].interm_newdoc,
          interim_docs_size: Number(temparr[i].interim_docs_size),
          reviwer_docs_size: Number(temparr[i].reviwer_docs_size),
          document_size: temparr[i].document_size,
          recall_status: temparr[i].recall_status,
          recall_id: temparr[i].recall_id,
          is_not_applicable: temparr[i].is_not_applicable,
          concur_download_doc: temparr[i].concur_download_doc,
          approve_download_doc: temparr[i].approve_download_doc,
          concur_doc_size: temparr[i].concur_doc_size,
          approve_doc_size: temparr[i].approve_doc_size,
        });
      }
      UpdateTaskRecallStatus({
        payload: [
          authtoken,
          {
            session_token: authtoken,
            request: [
              "UpdateTaskRecallStatus",
              {
                le_id: entityid,
                check_list: temp,
                action: recalldata.action != "" ? recalldata.action : "0",
                remarks: recalldata.remarks,
                password: password.passwordvalue,
                pwd_validate: true,
              },
            ],
          },
        ],
        paramid: paramid,
      });
    }
    //Setpasswordmodal(false);
  };

  const showValue = () => {
    setShowValueModal(true);
  };

  const passwordcancel = () => {
    Setpasswordmodal(false);
    setAddFormSubmit2(false);
    Setpassword({
      ...password,
      passwordvalue: "",
    });
  };

  const { Panel } = Collapse;

  const handleOk = () => {
    setRemarksModal(false);
  };

  const handleCancel = () => {
    setRemarksModal(false);
    setShowValueModal(false);
  };

  const gettaskrecalllist = () => {
    setFilteredArray([]);
    setAssigneename([]);
    setfilteredArrayState([]);
    setTemp([]);
    setUpdatedArray([]);
    setFormShow(true);
    Setrecalldata({
      ...recalldata,
      action: '',
      remarks: ''
    })
    if (temparr && temparr.length > 0) {
      var ele = document.getElementsByName("allchecked");
      ele[0].checked = false;
      console.log(ele, 'eleele');
    }
    setFormRecall(false)
    if (formValidator.current.allValid() || showdata.domain != '' && showdata.due_month != '') {
      setExitCollapse(true);
      setFilterModalVisible(false);
      setFilterTaskAcc(false);
      setAccordionTrue(true);
      setKey("0");
      GetTaskRecallList({
        payload: [
          authtoken,
          {
            session_token: authtoken,
            request: [
              "GetTaskRecallList",
              {
                le_id: entityid,
                unit_id: showdata.unit ? Number(showdata.unit) : null,
                d_id: Number(showdata.domain),
                frequency_id: showdata.frequency ? Number(showdata.frequency) : 0,
                compl_date: showdata.due_month,
                parent_id: Number(showdata.act),
                user_id: showdata.Assignee !== null && showdata.Assignee !== '' ? Number(showdata.Assignee) : null,
                start_count: 0,
                task_status: "Task Recall",
              },
            ],
          },
        ],
        paramid: paramid,
      });
    }
  };

  const gettaskrecalllistshowmore = () => {
    setFormShow(true);
    setAll("All");
    if (formValidator.current.allValid()) {
      setAccordionTrue(true);
      GetTaskRecallListShowmore({
        payload: [
          authtoken,
          {
            session_token: authtoken,
            request: [
              "GetTaskRecallList",
              {
                le_id: entityid,
                unit_id: showdata.unit ? Number(showdata.unit) : null,
                d_id: Number(showdata.domain),
                frequency_id: showdata.frequency
                  ? Number(showdata.frequency)
                  : 0,
                compl_date: showdata.due_month,
                parent_id: Number(showdata.act),
                user_id: showdata.Assignee !== null && showdata.Assignee !== '' ? Number(showdata.Assignee) : null,
                start_count: entires,
                task_status: "Task Recall",
              },
            ],
          },
        ],
        paramid: paramid,
      });
    }
  };



  const showRemarksModal = () => {
    GetRemarksData({
      payload: [
        authtoken,
        {
          session_token: authtoken,
          request: ["GetRemarksData", {}],
        },
      ],
      paramid: paramid,
    });
    setRemarksModal(true);
  };

  useEffect(() => {
    formValidator.current.showMessages();
    recallSubmit.current.showMessages();
    formValidator2.current.showMessages();
  }, []);

  useEffect(() => {
    if (filteredArray.length > 0) {
      {
        filteredArray.map((item) => {
          return setMapFilter(item);
        });
      }
    } else {
      setMapFilter([]);
    }
  }, [filteredArray]);

  useEffect(() => {
    if (updatedArray && updatedArray.length > 0) {
      let uniqueObjArray = [
        ...new Map(
          updatedArray &&
          updatedArray.length > 0 &&
          updatedArray.map((item) => [item["unit_id"], item])
        ).values(),
      ];
      const uniqueIds =
        uniqueObjArray &&
        uniqueObjArray.map((item) => {
          return item.unit_id;
        });
      let tempArr = [];
      for (let i in uniqueIds) {
        let filteredArr = _.filter(
          updatedArray && updatedArray.length > 0 && updatedArray,
          { unit_id: uniqueIds[i] }
        );
        tempArr.push(filteredArr);
      }
      setFilteredArray([tempArr]);
    } else {
      setFilteredArray([]);
    }
  }, [updatedArray]);

  const handlePasting = (e, regex) => {
    var regex = regex;
    var key = e.clipboardData.getData('text')
    if (!regex.test(key)) {
      e.preventDefault();
      Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
      return false;
    }
  }
  useEffect(() => {
    if (filteredArray.length > 0) {
      let temp = [];
      for (let i in filteredArray) {
        temp = filteredArray[i];
      }
      setfilteredArrayState([...temp]);
    }
  }, [filteredArray]);

  useEffect(() => {
    let tempArr = [];
    if (updatedArray && updatedArray.length > 0) {
      filteredArrayState &&
        filteredArrayState.length > 0 &&
        filteredArrayState.map((item, i) => {
          let uniqueObjArray = [
            ...new Map(
              item &&
              item.length > 0 &&
              item.map((items) => [items["assignee_name"], items])
            ).values(),
          ];
          const uniqueIds =
            uniqueObjArray.length > 0 &&
            uniqueObjArray.map((itemData) => {
              return itemData.assignee_name;
            });
          for (let i in uniqueIds) {
            let filteredArr = _.find(item && item.length > 0 && item, {
              assignee_name: uniqueIds[i],
            });
            tempArr.push(filteredArr);
          }
        });
      setAssigneename(tempArr);
    }
  }, [filteredArrayState]);

  useEffect(() => {
    if (updatedArray !== null) {
      settotalRecord(updatedArray.length);
      let totalList = [];
      let arr = [];
      let countrylisttempdata = [];
      let tempSetArray = [];
      let parId = [];
      assigneename &&
        assigneename.length > 0 &&
        assigneename.map(function (user) {
          if (arr.indexOf(user.assignee_name) < 0)
            arr.push({
              assignee_name: user.assignee_name,
              unit_name: user.unit_name,
            });
        });
      arr.map((pmenu, index) => {
        // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
        let entityArray = [];
        let tempArray = [];
        let unit_name = [];
        if (pmenu) {
          updatedArray &&
            updatedArray.map((subMenuList, childIndex) => {
              if (
                pmenu.assignee_name === subMenuList.assignee_name &&
                pmenu.unit_name === subMenuList.unit_name) {
                entityArray = {
                  address: subMenuList.address,
                  approve_status: subMenuList.approve_status,
                  approved_on: subMenuList.approved_on,
                  assigned_on: subMenuList.assigned_on,
                  assignee_name: subMenuList.assignee_name,
                  category_name: subMenuList.category_name,
                  compfielaw_url: subMenuList.compfielaw_url,
                  completion_date: subMenuList.completion_date,
                  compliance_description: subMenuList.compliance_description,
                  compliance_frequency: subMenuList.compliance_frequency,
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_id: subMenuList.compliance_id,
                  compliance_name: subMenuList.compliance_name,
                  country_id: subMenuList.country_id,
                  division_name: subMenuList.division_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  document_size: subMenuList.document_size,
                  domain_id: subMenuList.domain_id,
                  domain_name: subMenuList.domain_name,
                  download_url: subMenuList.download_url,
                  due_date: subMenuList.due_date,
                  duration_type: subMenuList.duration_type,
                  extn_due_date: subMenuList.extn_due_date,
                  file_names: subMenuList.file_names,
                  format_file_name: subMenuList.format_file_name,
                  history_count: subMenuList.history_count,
                  interim_count: subMenuList.interim_count,
                  interim_docs: subMenuList.interim_docs,
                  interim_docs_size: subMenuList.interim_docs_size,
                  interm_newdoc: subMenuList.interm_newdoc,
                  next_due_date: subMenuList.next_due_date,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  penal_consequences: subMenuList.penal_consequences,
                  recall_id: subMenuList.recall_id,
                  recall_status: subMenuList.recall_status,
                  remarks: subMenuList.remarks,
                  remarks_count: subMenuList.remarks_count,
                  reviwer_docs: subMenuList.reviwer_docs,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  start_date: subMenuList.start_date,
                  statu: subMenuList.statu,
                  system_remarks: subMenuList.system_remarks,
                  task_status: subMenuList.task_status,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  unit_id: subMenuList.unit_id,
                  unit_name: subMenuList.unit_name,
                  upload_docs: subMenuList.upload_docs,
                  validity_date: subMenuList.validity_date,
                  validity_settings_days: subMenuList.validity_settings_days,
                  is_not_applicable: subMenuList.is_not_applicable,
                  concur_download_doc: subMenuList.concur_download_urls,
                  approve_download_doc: subMenuList.approve_download_urls,
                  concur_doc_size: subMenuList.concur_doc_size,
                  approve_doc_size: subMenuList.approve_doc_size,
                  parentId: index,
                  childId: childIndex,
                  indexNo: childIndex + 1,
                };
                tempArray.push(entityArray);

                unit_name.push(subMenuList.unit_name);
              }
            });
        }
        let checkarray = []
        let listArray_One_time = _.filter(tempArray, { compliance_frequency: "One Time" });
        if (listArray_One_time && listArray_One_time.length > 0) {
          for (let i in listArray_One_time) {
            checkarray.push(listArray_One_time[i])
          }
        }
        let listArray_periodical = _.filter(tempArray, { compliance_frequency: "Periodical" });
        if (listArray_periodical && listArray_periodical.length > 0) {
          for (let i in listArray_periodical) {
            checkarray.push(listArray_periodical[i])
          }
        }
        let listArray_Review = _.filter(tempArray, { compliance_frequency: "Review" });
        if (listArray_Review && listArray_Review.length > 0) {
          for (let i in listArray_Review) {
            checkarray.push(listArray_Review[i])
          }
        }
        let listArray_Flexi_Review = _.filter(tempArray, { compliance_frequency: "Flexi Review" });
        if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
          for (let i in listArray_Flexi_Review) {
            checkarray.push(listArray_Flexi_Review[i])
          }
        }
        let listArray_On_Occurrence = _.filter(tempArray, { compliance_frequency: "On Occurrence" });
        if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
          for (let i in listArray_On_Occurrence) {
            checkarray.push(listArray_On_Occurrence[i])
          }
        }

        totalList = {
          index: index,
          parentData: pmenu,
          child: checkarray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
        tempSetArray.push(tempArray);
        parId.push(unit_name);
      });
      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], tempSetArray);
      setTemp(countrylisttempdata);
      setMergedArray(merged);
      setTempFilterArray([...new Set([].concat.apply([], parId))]);
    }
  }, [updatedArray, assigneename]);

  useEffect(() => {
    if (isAuth) {
      if (entityid !== "") {
        if (entityid != "null" && entityid !== null) {
          TaskRecallFilters({
            payload: [
              authtoken,
              {
                session_token: authtoken,
                request: [
                  "TaskRecallFilters",
                  {
                    le_id: entityid,
                  },
                ],
              },
            ],
            paramid: paramid,
          });
          const payload1 = [
            authtoken,
            {
              "session_token": authtoken,
              "request": [
                'GetReassignComplianceFilters', {
                  "le_id": entityid,
                }
              ]
            }
          ]
          if (entityid != "null") {
            console.log('5556595959595');
            reassignlist({
              payload: payload1,
              paramid: paramid
            })
          }

        }
      }
    }
  }, [isAuth, entityid]);

  const homescreen = () => {
    let getUrlDetails = homepage;
    let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
    let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
    localStorage.setItem('formkey', tempkey);
    //historynew.push(tempval);
    window.location.href = tempval
    document.getElementById('homehover').style.color = "blue"

  };


  const onFinish = () => {
    const res = []
    const upres = []
    const keys = Object.keys(filterValues)
    keys.forEach(key => {
      if (filterValues[key] === true) {
        if (key == 'on_occurrence') {
          key = 'on occurrence'
        }
        if (key == 'one_time') {
          key = 'one time'
        }
        res.push(key)
        upres.push(
          key.toUpperCase().replace(/_/g, " ")
        )
      }
      // filterValues[key] === true && upres.push(
      //   key.toUpperCase().replace(/_/g, " ")
      // )
    })
    if (upres.length > 0 && upres.length != 5) {
      setAll(upres.join(', '))
    } else {
      setAll('All')
    }
    let filterTable;
    let temp = []
    let a
    if (res[0] == "All" || res.length == 0) {
      filterTable = updatedArray;
      settotalRecord(filterTable.length);
    } else {
      for (let i in res) {
        a = updatedArray.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(res[i].toLowerCase())
          )
        );


        Array.prototype.push.apply(temp, a);


      }
      filterTable = temp

      settotalRecord(filterTable.length);
    }
    /*Include Type to search function*/

    if (searchingValue['compliance_task']) {
      filterTable = filterTable.filter((item) => {
        if ((item["statu"].toLowerCase().includes(searchingValue['compliance_task'].toLowerCase())) || (item["compliance_name"].toLowerCase().includes(searchingValue['compliance_task'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['domain']) {
      filterTable = filterTable.filter((item) => {
        if ((item["domain_name"].toLowerCase().includes(searchingValue['domain'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['start_date']) {
      filterTable = filterTable.filter((item) => {
        if ((item["start_date"].toLowerCase().includes(searchingValue['start_date'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['due_date']) {
      filterTable = filterTable.filter((item) => {
        if ((item["due_date"].toLowerCase().includes(searchingValue['due_date'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['time_line']) {
      filterTable = filterTable.filter((item) => {
        if ((item["delayed_by"].replace(/\s\s+/g, ' ').toLowerCase().includes(searchingValue['time_line'].toLowerCase()))) {
          return true;
        }
      })
    }

    let totalList = [];
    let arr = [];
    let countrylisttempdata = [];
    let tempSetArray = [];
    let parId = [];
    if (filterTable && filterTable.length > 0) {
      assigneename &&
        assigneename.length > 0 &&
        assigneename.map(function (user) {
          if (arr.indexOf(user.assignee_name) < 0)
            arr.push({
              assignee_name: user.assignee_name,
              unit_name: user.unit_name,
            });
        });

      arr.map((pmenu, index) => {
        // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
        let entityArray = [];
        let tempArray = [];
        let unit_name = [];
        if (pmenu) {
          filterTable &&
            filterTable.map((subMenuList, childIndex) => {
              if (
                pmenu.assignee_name === subMenuList.assignee_name &&
                pmenu.unit_name === subMenuList.unit_name
              ) {
                entityArray = {
                  address: subMenuList.address,
                  approve_status: subMenuList.approve_status,
                  approved_on: subMenuList.approved_on,
                  assigned_on: subMenuList.assigned_on,
                  assignee_name: subMenuList.assignee_name,
                  category_name: subMenuList.category_name,
                  compfielaw_url: subMenuList.compfielaw_url,
                  completion_date: subMenuList.completion_date,
                  compliance_description: subMenuList.compliance_description,
                  compliance_frequency: subMenuList.compliance_frequency,
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_id: subMenuList.compliance_id,
                  compliance_name: subMenuList.compliance_name,
                  country_id: subMenuList.country_id,
                  division_name: subMenuList.division_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  document_size: subMenuList.document_size,
                  domain_id: subMenuList.domain_id,
                  domain_name: subMenuList.domain_name,
                  download_url: subMenuList.download_url,
                  due_date: subMenuList.due_date,
                  duration_type: subMenuList.duration_type,
                  extn_due_date: subMenuList.extn_due_date,
                  file_names: subMenuList.file_names,
                  format_file_name: subMenuList.format_file_name,
                  history_count: subMenuList.history_count,
                  interim_count: subMenuList.interim_count,
                  interim_docs: subMenuList.interim_docs,
                  interim_docs_size: subMenuList.interim_docs_size,
                  interm_newdoc: subMenuList.interm_newdoc,
                  next_due_date: subMenuList.next_due_date,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  penal_consequences: subMenuList.penal_consequences,
                  recall_id: subMenuList.recall_id,
                  recall_status: subMenuList.recall_status,
                  remarks: subMenuList.remarks,
                  remarks_count: subMenuList.remarks_count,
                  reviwer_docs: subMenuList.reviwer_docs,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  start_date: subMenuList.start_date,
                  statu: subMenuList.statu,
                  system_remarks: subMenuList.system_remarks,
                  task_status: subMenuList.task_status,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  unit_id: subMenuList.unit_id,
                  unit_name: subMenuList.unit_name,
                  upload_docs: subMenuList.upload_docs,
                  validity_date: subMenuList.validity_date,
                  validity_settings_days: subMenuList.validity_settings_days,
                  parentId: index,
                  childId: childIndex,
                  indexNo: childIndex + 1,
                };
                tempArray.push(entityArray);
                unit_name.push(subMenuList.unit_name);
              }
            });
        }
        totalList = {
          index: index,
          parentData: pmenu,
          child: tempArray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
        tempSetArray.push(tempArray);
        parId.push(unit_name);
      });

      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], tempSetArray);
      setTemp(countrylisttempdata);
      setMergedArray(merged);
      setTempFilterArray([...new Set([].concat.apply([], parId))]);
      setSearchedUpdatedArrayFilter(true)
      // setChecked({
      //   All: false,
      //   periodical: false,
      //   flexi: false,
      //   review: false,
      //   on_occurrence: false,
      //   one_time: false

      // })
    } else {
      setMergedArray([]);
      setTemp([]);
      setTitleDynamic([]);
      setTempFilterArray([]);
      setSearchedUpdatedArrayFilter(false)
    }
    setshowDropDown(false)
  }

  const onSubmit = (e) => {
    e.preventDefault()

  }
  const compliancetaskfilter = (e, type) => {
    // let filterTable;
    // if (type == "Compliance_Task") {
    //   console.log(all, 'allallallall')
    //   filterTable = updatedArray.filter((o) => {
    //     if (all == "All") {
    //       if (o["statu"].toLowerCase().includes(e.toLowerCase()) || o["compliance_name"].toLowerCase().includes(e.toLowerCase()) || o["assigned_on"].toLowerCase().includes(e.toLowerCase())) {
    //         return true;
    //       }
    //     } else {
    //       if (
    //         (o["statu"].toLowerCase().includes(e.toLowerCase()) || o["compliance_name"].toLowerCase().includes(e.toLowerCase()) || o["assigned_on"].toLowerCase().includes(e.toLowerCase())) &&
    //         o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
    //       ) {
    //         return true;
    //       }
    //     }
    //   });
    // } else if (type == "Assignee") {
    //   filterTable = updatedArray.filter((o) => {
    //     if (all == "All") {
    //       if (o["assignee_name"].toLowerCase().includes(e.toLowerCase())) {
    //         return true;
    //       }
    //     } else {
    //       if (
    //         o["assignee_name"].toLowerCase().includes(e.toLowerCase()) &&
    //         o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
    //       ) {
    //         return true;
    //       }
    //     }
    //   });
    // } else if (type == "Compliance_Status") {
    //   filterTable = updatedArray.filter((o) => {
    //     if (all == "All") {
    //       if (o["task_status"].toLowerCase().includes(e.toLowerCase())) {
    //         return true;
    //       }
    //     } else {
    //       if (
    //         o["task_status"].toLowerCase().includes(e.toLowerCase()) &&
    //         o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
    //       ) {
    //         return true;
    //       }
    //     }
    //   });
    // } else if (type == "Due_Date") {
    //   filterTable = updatedArray.filter((o) => {
    //     if (all == "All") {
    //       if (o["due_date"].toLowerCase().includes(e.toLowerCase())) {
    //         return true;
    //       }
    //     } else {
    //       if (
    //         o["due_date"].toLowerCase().includes(e.toLowerCase()) &&
    //         o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
    //       ) {
    //         return true;
    //       }
    //     }
    //   });
    // } else if (type == "Completion_Date") {
    //   filterTable = updatedArray.filter((o) => {
    //     if (all == "All") {
    //       if (o["completion_date"].toLowerCase().includes(e.toLowerCase())) {
    //         return true;
    //       }
    //     } else {
    //       if (
    //         o["completion_date"].toLowerCase().includes(e.toLowerCase()) &&
    //         o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
    //       ) {
    //         return true;
    //       }
    //     }
    //   });
    // }

    let filterTable = updatedArray;

    if (searchingValue['compliance_task']) {
      filterTable = filterTable.filter((item) => {
        if ((item["statu"].toLowerCase().includes(searchingValue['compliance_task'].toLowerCase())) || (item["compliance_name"].toLowerCase().includes(searchingValue['compliance_task'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['compliance_status']) {
      filterTable = filterTable.filter((item) => {
        if ((item["task_status"].toLowerCase().includes(searchingValue['compliance_status'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['due_date']) {
      filterTable = filterTable.filter((item) => {
        if ((item["due_date"].toLowerCase().includes(searchingValue['due_date'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['completion_date']) {
      filterTable = filterTable.filter((item) => {
        if ((item["completion_date"].toLowerCase().includes(searchingValue['completion_date'].toLowerCase()))) {
          return true;
        }
      })
    }

    if (searchedUpdatedArrayFilter) {
      const res = [];
      const keys = Object.keys(filterValues);
      keys.forEach(key => {
        if (filterValues[key] === true) {
          if (key == 'on_occurrence') {
            key = 'on occurrence';
          }
          if (key == 'one_time') {
            key = 'one time';
          }
          res.push(key);
        }
      });
      let temp = [];
      let a;
      if (res[0] == "All" || res.length == 0) {
        filterTable = updatedArray;
        settotalRecord(filterTable.length);
      } else {

        for (let i in res) {
          a = filterTable.filter((o) =>
            Object.keys(o).some((k) =>
              String(o[k]).toLowerCase().includes(res[i].toLowerCase())
            )
          );
          Array.prototype.push.apply(temp, a);
        }

        filterTable = temp;
        settotalRecord(filterTable.length);
      }
    }

    let totalList = [];
    let arr = [];
    let countrylisttempdata = [];
    let tempSetArray = [];
    let parId = [];
    if (filterTable && filterTable.length > 0) {
      assigneename &&
        assigneename.length > 0 &&
        assigneename.map(function (user) {
          if (arr.indexOf(user.assignee_name) < 0)
            arr.push({
              assignee_name: user.assignee_name,
              unit_name: user.unit_name,
            });
        });

      arr.map((pmenu, index) => {
        // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
        let entityArray = [];
        let tempArray = [];
        let unit_name = [];
        if (pmenu) {
          filterTable &&
            filterTable.map((subMenuList, childIndex) => {
              if (
                pmenu.assignee_name === subMenuList.assignee_name &&
                pmenu.unit_name === subMenuList.unit_name
              ) {
                entityArray = {
                  address: subMenuList.address,
                  approve_status: subMenuList.approve_status,
                  approved_on: subMenuList.approved_on,
                  assigned_on: subMenuList.assigned_on,
                  assignee_name: subMenuList.assignee_name,
                  category_name: subMenuList.category_name,
                  compfielaw_url: subMenuList.compfielaw_url,
                  completion_date: subMenuList.completion_date,
                  compliance_description: subMenuList.compliance_description,
                  compliance_frequency: subMenuList.compliance_frequency,
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_id: subMenuList.compliance_id,
                  compliance_name: subMenuList.compliance_name,
                  country_id: subMenuList.country_id,
                  division_name: subMenuList.division_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  document_size: subMenuList.document_size,
                  domain_id: subMenuList.domain_id,
                  domain_name: subMenuList.domain_name,
                  download_url: subMenuList.download_url,
                  due_date: subMenuList.due_date,
                  duration_type: subMenuList.duration_type,
                  extn_due_date: subMenuList.extn_due_date,
                  file_names: subMenuList.file_names,
                  format_file_name: subMenuList.format_file_name,
                  history_count: subMenuList.history_count,
                  interim_count: subMenuList.interim_count,
                  interim_docs: subMenuList.interim_docs,
                  interim_docs_size: subMenuList.interim_docs_size,
                  interm_newdoc: subMenuList.interm_newdoc,
                  next_due_date: subMenuList.next_due_date,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  penal_consequences: subMenuList.penal_consequences,
                  recall_id: subMenuList.recall_id,
                  recall_status: subMenuList.recall_status,
                  remarks: subMenuList.remarks,
                  remarks_count: subMenuList.remarks_count,
                  reviwer_docs: subMenuList.reviwer_docs,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  start_date: subMenuList.start_date,
                  statu: subMenuList.statu,
                  system_remarks: subMenuList.system_remarks,
                  task_status: subMenuList.task_status,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  unit_id: subMenuList.unit_id,
                  unit_name: subMenuList.unit_name,
                  upload_docs: subMenuList.upload_docs,
                  validity_date: subMenuList.validity_date,
                  validity_settings_days: subMenuList.validity_settings_days,
                  parentId: index,
                  childId: childIndex,
                  indexNo: childIndex + 1,
                };
                tempArray.push(entityArray);
                unit_name.push(subMenuList.unit_name);
              }
            });
        }
        totalList = {
          index: index,
          parentData: pmenu,
          child: tempArray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
        tempSetArray.push(tempArray);
        parId.push(unit_name);
      });

      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], tempSetArray);
      setTemp(countrylisttempdata);
      setMergedArray(merged);
      setTempFilterArray([...new Set([].concat.apply([], parId))]);
    } else {
      setMergedArray([]);
      setTemp([]);
      setTitleDynamic([]);
      setTempFilterArray([]);
    }
  };

  const gettooltipfun = (record) => {
    const content = (
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #636262",
          tableLayout: "auto",
        }}
      >
        <tbody>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Vendor Name</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.unit_name}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Address</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.address}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Division</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.division_name}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Category</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.category_name}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Act/Rules</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.statu}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Compliance Description</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.compliance_description}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Penal Consequences</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.penal_consequences}</b>
            </td>
          </tr>
        </tbody>
      </table>
    );
    return content;
  };

  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setshowDropDown(false)
    }
  };

  const viewhistory = (data) => {

    const payload = [
      authtoken,
      {
        "session_token": authtoken,
        "request": [
          "GetComplianceRemarksHistoryData",
          {
            "legal_entity_id": showdata.legal_entity,
            "unit_id": data.unit_id,
            "compliance_history_id": data.compliance_history_id
          }
        ]
      }
    ]
    historyrecord({
      payload: payload,
      paramid: paramid
    })
    sethistorymodal(true);
  }

  const viewinterim = (data) => {
    const payload = [
      authtoken,
      {
        "session_token": authtoken,
        "request": [
          "GetInterimComplianceDetails",
          {
            "legal_entity_id": showdata.legal_entity,
            "compliance_history_id": data.compliance_history_id
          }
        ]
      }
    ]
    interimrecord({
      payload: payload,
      paramid: paramid
    })
    setinterimmodal(true);
  }

  const historycancel = () => {
    sethistorymodal(false);
  }

  useEffect(() => {
    if (valueModal.uploaded_document && valueModal.uploaded_document.length > 0) {
      var sizeInMB = (valueModal.document_size / (1024 * 1024)).toFixed(2);
      console.log(sizeInMB, 'sizeInMB');
      setFileSize(sizeInMB);
    }
  }, [valueModal.uploaded_document]);

  const history_columns = [
    {
      title: '#',
      key: 'index',
      align: "center",
      width: "30px",
      render: (text, record, index) => {
        return (
          index + 1
        )
      },
    },
    {
      title: 'Activity Date',
      key: 'activity_on',
      align: "center",
      width: "50px",
      dataIndex: 'activity_on',
    },
    {
      title: 'User Type',
      key: 'action',
      align: "center",
      width: "50px",
      dataIndex: 'action',
      render: (text, record) => {
        if (record.user_category_id == 5 || record.user_category_id == 6) {
          return 'Assignee'
        } else if ((record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Rectified' || record.action == 'Concurrence Rejected' || record.action == 'Concurred')) {
          return 'Concurrer'
        } else if (record.user_category_id == 1 && (record.action == 'RectifyApproval' || record.action == 'Approval Rejected' || record.action == 'Approved') ||
          (record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Approval Rejected' || record.action == 'RectifyApproval' || record.action == 'Approved')) {
          return 'Approver'
        }

      }
    },
    {
      title: 'Reviewer Report',
      key: 'documents',
      align: "center",
      width: "50px",
      dataIndex: 'documents',
      render: (text, record) => {
        if (record.documents != null) {
          let doc = record.documents;
          let splittedDocs = doc.split('||')
          console.log(splittedDocs, 'splittedDocs');
          // for (let i in splittedDocs) {
          {
            splittedDocs.map((item, i) => {
              return <a href="javascript:;">{item}</a>
            })
          }

          // }
        }

      }
    },
    {
      title: 'Remarks',
      key: 'remarks',
      align: "center",
      width: "50px",
      dataIndex: 'remarks',
    },
  ]

  const interim_columns = [
    {
      title: '#',
      key: 'index',
      align: "center",
      width: "30px",
      render: (text, record, index) => {
        return (
          index + 1
        )
      },
    },
    {
      title: 'Documents',
      key: 'documents',
      align: "center",
      width: "50px",
      dataIndex: 'documents',
    },
    {
      title: 'Remarks',
      key: 'remarks',
      align: "center",
      width: "50px",
      dataIndex: 'remarks',
    },
    {
      title: 'Updated On',
      key: 'activity_on',
      align: "center",
      width: "50px",
      dataIndex: 'activity_on',
    },
  ]

  const interimcancel = () => {
    setinterimmodal(false);
  }

  const resetfilter = () => {
    setFilterValues({
      ...filterValues,
      All: '',
      periodical: '',
      flexi_review: '',
      review: '',
      on_occurrence: '',
      one_time: ''
    })
    setChecked({
      ...checked,
      All: false,
      periodical: false,
      flexi_review: false,
      review: false,
      on_occurrence: false,
      one_time: false
    })
  }

  useEffect(() => {
    if (showdata.legal_entity != '' && domain && domain.length == 1) {
      setDomainValue(true)
      Setshowdata({
        ...showdata,
        domain: domain[0].d_id,
        domainName: domain[0].d_name
      })

    } else {
      setDomainValue(false)
      setDomainFilter(domain)

    }
  }, [domain])

  return (
    <div id="page-wrapper" className="page-wrapper">
      {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
      {document.body.scrollHeight > document.body.clientHeight ?
        <div className='back-to-top'>
          <ScrollButton />
        </div> : ''} */}
      <div className='back-to-top'>
        <ScrollButton />
      </div>
      <div className="page-titles pb-0 pt-0 page-title-sticky">
        <div className="row">
          <div className="col-lg-8 col-md-6 col-12 align-self-center">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 d-flex align-items-center">
                <li className="breadcrumb-item">
                  <span className="pointer" onClick={homescreen}>
                    <i className="ri-home-3-line fs-5"></i>
                  </span>
                </li>
                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                  <span style={{ fontSize: '16px' }}>Transaction</span>
                </li>
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  style={{ fontSize: "large", fontWeight: "bold" }}
                >
                  <span>Task Recall</span>
                </li>
              </ol>
            </nav>
            {/* <h2 className="mb-0 fw-bold">Task Recall</h2> */}
          </div>
          <div
            className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end py-1"
            style={{ paddingRight: "14px" }}
          >
            {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                        icon={<ArrowLeftOutlined />} size='default' onClick={(e) => { previous(e) }}>
                        Back
                      </Button> */}

            <Tooltip title='Advanced Filter' placement="top">
              <Button
                type="primary"
                shape="round"
                style={{
                  marginRight: "10px",
                  display: filterTaskAcc ? "none" : "block",
                }}
                onClick={filterHandle}
              >
                <FilterOutlined /> Filter
              </Button>
            </Tooltip>
            <button
              onClick={(e) => {
                fullscreenMode();
              }}
              className="bg-transparent text-black ms-2 full-mode fullscreenradius"
            >
              <Tooltip
                placement="left"
                title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
              >
                <FullscreenOutlined />
              </Tooltip>
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-1">
        <div className="row">
          <div className="col-lg-12">
            <div
              className={
                localStorage.getItem("currentTheme") +
                " card1 card-body1-statuatory "
              }
            >
              <Collapse
                accordion
                defaultActiveKey={["1"]}
                id="taskRecall"
                style={{ display: exitCollapse ? "none" : "block" }}
              >
                <Panel
                  className="report-collapse"
                  header="Task Recall"
                  key={key}
                >
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        {/* <div className="col-md-8" > */}
                        {/* <div className="mb-3"> */}
                        {/* <label>Legal Entity:</label> */}
                        <label htmlFor="">
                          <b>Legal Entity: </b>
                        </label>
                        <span style={{ color: "red" }}> *</span>
                        <br />
                        {localStorage.getItem("SelectedEntity") ===
                          "All Legal Entity" ? (
                          <div className="form-group">
                            <Select
                              allowClear={true}
                              size="default"
                              placeholder="Select Legal Entity"
                              // onPaste={(e) => {
                              //   handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                              // }}
                              onPaste={onPasteToaster}
                              searchValue={selectSearchValue.legalEntity}
                              onSearch={(value) => {
                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                setSelectSearchValue({
                                  ...selectSearchValue,
                                  legalEntity: result
                                });
                              }}
                              onChange={(data, value) => {
                                setDomain([])
                                setDomainFilter([])
                                setDomainValue(false)
                                if (data !== undefined) {
                                  Setlegalstate(true);
                                  setCurrentEntity(data);
                                  Setshowdata({
                                    ...showdata,
                                    legal_entity: data,
                                    domain: "",
                                    unit: "",
                                    frequency: "",
                                    asignee: "",
                                    due_month: "",
                                    Assignee: ''
                                  });
                                } else {
                                  Setlegalstate(false);
                                  setCurrentEntity("");
                                  Setshowdata({
                                    ...showdata,
                                    legal_entity: "",
                                    domain: "",
                                    unit: "",
                                    frequency: "",
                                    asignee: "",
                                    due_month: "",
                                    Assignee: ''
                                  });
                                }
                              }}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.legal_entity || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              {sessionArr &&
                                sessionArr.length > 0 &&
                                sessionArr.map((item, i) => {
                                  return (
                                    <Option key={item.le_id}>
                                      {item.le_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                            {formValidator.current.message(
                              "legal_entity",
                              showdata.legal_entity,
                              "required",
                              {
                                className: `invalid-feedback ${FormShow ? "show" : "hide"
                                  }`,
                                messages: {
                                  required: "Legal Entity Required",
                                },
                              }
                            )}
                          </div>
                        ) : (

                          <p title={localStorage.getItem('SelectedEntity')} className='text-wrap-report' style={{ width: "100%", marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                        )}
                        {/* </div> */}
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Domain: </b>
                          </label>
                          <span style={{ color: "red" }}> *</span>
                          <br />

                          {domainValue == false || domainFilter && domainFilter.length > 1 ?
                            <>
                              <Select
                                allowClear={true}
                                size="default"
                                name="domain"
                                id="domain"
                                placeholder="Enter domain"
                                // onPaste={(e) => {
                                //   handlePasting(e, /^[a-z 0-9]+$/gi)
                                // }}
                                onPaste={onPasteToaster}
                                searchValue={selectSearchValue.domain}
                                onSearch={(value) => {
                                  let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                  setSelectSearchValue({
                                    ...selectSearchValue,
                                    domain: result
                                  });
                                }}
                                onChange={(data, value) => {
                                  if (data !== undefined) {
                                    Setshowdata({
                                      ...showdata,
                                      domain: data,
                                      domainName: value.children,
                                      unit: "",
                                      frequency: "",
                                      asignee: "",
                                      due_month: "",
                                      act: "",
                                    });
                                  } else {
                                    Setshowdata({
                                      ...showdata,
                                      domain: "",
                                      unit: "",
                                      frequency: "",
                                      domainName: '',
                                      asignee: "",
                                      due_month: "",
                                      act: "",
                                    });
                                  }
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                value={showdata.domainName || undefined}
                                style={{ width: "100%", marginTop: "5px" }}
                                disabled={
                                  showdata.legal_entity === "" ? true : false
                                }
                              >
                                {domainFilter &&
                                  domainFilter.length > 0 &&
                                  domainFilter.map((item, i) => {
                                    return (
                                      <Option key={item.d_id}>{item.d_name}</Option>
                                    );
                                  })}
                              </Select>
                              {/* } */}
                              {formValidator.current.message(
                                "domain",
                                showdata.domain,
                                ["required"],
                                {
                                  className: `invalid-feedback ${FormShow ? "show" : "hide"
                                    }`,
                                  messages: {
                                    required: "Domain Required",
                                  },
                                }
                              )}
                            </>
                            : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain && domain.length > 0 && domain[0].d_name}</p>}

                          {/* {((domain && domain.length === 1) && (showdata.legal_entity != '')) ?
                            <p style={{ marginTop: '10px' }} value={domain && domain.length > 0 && domain[0].d_name}>{domain && domain.length > 0 && domain[0].d_name}</p>
                            : 
                          <Select
                            allowClear={true}
                            size="default"
                            name="domain"
                            id="domain"
                            placeholder="Enter domain"
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9]+$/gi)
                            }}
                            onChange={(data, value) => {
                              if (data !== undefined) {
                                Setshowdata({
                                  ...showdata,
                                  domain: data,
                                  domainName: value.children,
                                  unit: "",
                                  frequency: "",
                                  asignee: "",
                                  due_month: "",
                                  act: "",
                                });
                              } else {
                                Setshowdata({
                                  ...showdata,
                                  domain: "",
                                  unit: "",
                                  frequency: "",
                                  asignee: "",
                                  due_month: "",
                                  act: "",
                                  domainName: ''
                                });
                              }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={showdata.domainName || undefined}
                            style={{ width: "100%", marginTop: "5px" }}
                            disabled={
                              showdata.legal_entity === "" ? true : false
                            }
                          >
                            {domain &&
                              domain.length &&
                              domain.map((item, i) => {
                                return (
                                  <Option key={item.d_id}>{item.d_name}</Option>
                                );
                              })}
                          </Select>
                          {/* } 
                          {formValidator.current.message(
                            "domain",
                            showdata.domain,
                            ["required"],
                            {
                              className: `invalid-feedback ${FormShow ? "show" : "hide"
                                }`,
                              messages: {
                                required: "Domain Required",
                              },
                            }
                          )}*/}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Vendor: </b>
                          </label>
                          <br />
                          <Select
                            allowClear={true}
                            size="default"
                            placeholder="Enter unit"
                            // onPaste={(e) => {
                            //   handlePasting(e, /^[a-z 0-9 , . - _]+$/gi)
                            // }}
                            onPaste={onPasteToaster}
                            searchValue={selectSearchValue.vendor}
                            onSearch={(value) => {
                              let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                              setSelectSearchValue({
                                ...selectSearchValue,
                                vendor: result
                              });
                            }}
                            onChange={(data) => {
                              if (data !== undefined) {
                                Setshowdata({
                                  ...showdata,
                                  unit: data,
                                  frequency: "",

                                  due_month: "",
                                  act: "",
                                });
                              } else {
                                Setshowdata({
                                  ...showdata,
                                  unit: "",
                                  frequency: "",
                                  due_month: "",
                                  act: "",
                                });
                              }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={showdata.unit || undefined}
                            style={{ width: "100%", marginTop: "5px" }}
                            disabled={
                              showdata.legal_entity === "" ? true : false
                            }
                          >
                            {userunitsrecall &&
                              userunitsrecall.user_units &&
                              userunitsrecall.user_units.map((item, i) => {
                                return (
                                  <Option key={item.unit_id}>
                                    {item.unit_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: "10px" }}>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Frequency: </b>
                          </label>

                          <Select
                            defaultValue="All"
                            allowClear={false}
                            size="default"
                            // placeholder="Enter frequecy"
                            onChange={(data, value) => {
                              setAll(value.children === undefined ? 'All' : value.children)
                              if (data !== undefined) {
                                Setshowdata({
                                  ...showdata,
                                  frequency: data,
                                });
                              } else {
                                Setshowdata({
                                  ...showdata,
                                  frequency: "",
                                });
                              }
                            }}
                            // showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={showdata.frequency || undefined}
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            <Option key={0}>All</Option>
                            {userunitsrecall &&
                              userunitsrecall.frequency_list &&
                              userunitsrecall.frequency_list.map((item, i) => {
                                return (
                                  <Option key={item.frequency_id}>
                                    {item.frequency_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Due Month: </b>
                          </label>{" "}
                          <span style={{ color: "red" }}>*</span>
                          {due == false ? (
                            <DatePicker
                              popupStyle={{
                                height: "150px",
                                width: "280px",
                                marginTop: "5px",
                              }}
                              onKeyDown={(event) => {
                                event.preventDefault()
                              }}
                              onPaste={(e) => {
                                handlePasting(e, /^[a-z 0-9 , . - _]+$/gi)
                              }}
                              format={"MMMM-YYYY"}
                              disabled={showdata.domain === "" ? true : false}
                              style={{ width: "100%", marginTop: "5px" }}
                              allowClear={false}
                              onChange={(date, dateString) => {
                                setDue(true);
                                Setshowdata({
                                  ...showdata,
                                  due_month:
                                    moment(dateString).format("MMMM-YYYY"),
                                });
                              }}
                              picker="month"
                              value={
                                showdata.due_month
                                  ? moment(showdata.due_month)
                                  : ""
                              }
                              monthCellRender={(current) => {
                                const style = {};
                                if (
                                  moment(current).format("MMM-YYYY") ===
                                  moment().format("MMM-YYYY")
                                ) {
                                  // style.border = '1px solid #1890ff';
                                  style.borderRadius = "2px";
                                  style.lineHeight = "24px";
                                  style.minWidth = "24px";
                                  style.position = "relative";
                                  style.display = "inlineBlock";
                                  style.backgroundColor = "#1890ff";
                                  style.color = "white";
                                  style.marginTop = "5px";
                                  style.fontSize = "15px"
                                }
                                return (
                                  <div
                                    className="ant-picker-cell-inner"
                                    style={style}
                                  >
                                    {moment(current).format("MMM")}
                                  </div>
                                );
                              }}
                            />
                          ) : (
                            <DatePicker
                              popupStyle={{
                                height: "150px",
                                width: "280px",
                                marginTop: "5px",
                              }}
                              format={"MMMM-YYYY"}
                              disabled={showdata.domain === "" ? true : false}
                              style={{
                                width: "100%",
                                marginTop: "5px !important",
                              }}
                              allowClear={false}
                              onChange={(date, dateString) => {
                                setDue(true);
                                Setshowdata({
                                  ...showdata,
                                  due_month:
                                    moment(dateString).format("MMMM-YYYY"),
                                });
                              }}
                              picker="month"
                              onKeyDown={(event) => {
                                event.preventDefault()
                              }}
                              onPaste={(e) => {
                                handlePasting(e, /^[a-z 0-9 , . - _]+$/gi)
                              }}
                              value={
                                showdata.due_month
                                  ? moment(showdata.due_month)
                                  : ""
                              }
                            />
                          )}
                          {formValidator.current.message(
                            "date",
                            showdata.due_month,
                            ["required"],
                            {
                              className: `invalid-feedback ${FormShow ? "show" : "hide"
                                }`,
                              messages: {
                                required: "Due Month Required",
                              },
                            }
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Act: </b>
                          </label>
                          <Select
                            allowClear={true}
                            size="default"
                            placeholder="Enter act"
                            // onPaste={(e) => {
                            //   handlePasting(e, /^[a-z 0-9 () , . _ -]+$/gi)
                            // }}
                            onPaste={onPasteToaster}
                            searchValue={selectSearchValue.act}
                            onSearch={(value) => {
                              let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                              setSelectSearchValue({
                                ...selectSearchValue,
                                act: result
                              });
                            }}
                            onChange={(data) => {
                              if (data !== undefined) {
                                Setshowdata({
                                  ...showdata,
                                  act: data,
                                });
                              } else {
                                Setshowdata({
                                  ...showdata,
                                  act: "",
                                });
                              }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={showdata.act || undefined}
                            style={{ width: "100%", marginTop: "5px" }}
                            disabled={
                              showdata.due_month === "" ? true : false
                            }
                          >
                            {userunitsrecall &&
                              userunitsrecall.act_list &&
                              userunitsrecall.act_list.map((item, i) => {
                                return (
                                  <Option key={item.parent_id}>
                                    {item.statutory_mapping}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: '10px' }}>
                    <div className="row">
                      {callername && callername.login_response && callername.login_response.usr_cat_id === 1 || callername && callername.login_response && callername.login_response.usr_cat_id === 3 || callername && callername.login_response && callername.login_response.usr_cat_id === 4 ?
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Assignee: </b>
                            </label>
                            <br />
                            <Select
                              allowClear={true}
                              size="default"
                              name="domain"
                              id="domain"
                              placeholder="Enter Assignee"
                              value={showdata.Assignee || undefined}
                              showSearch
                              // onPaste={(e) => {
                              //   handlePasting(e, /^[a-z 0-9 -]+$/gi)
                              // }}
                              onPaste={onPasteToaster}
                              searchValue={selectSearchValue.assignee}
                              onSearch={(value) => {
                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                setSelectSearchValue({
                                  ...selectSearchValue,
                                  assignee: result
                                });
                              }}
                              disabled={showdata.legal_entity == '' ? true : false}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onChange={(data, value) => {
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    Assignee: data,
                                    assigne_name: value.children
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    Assignee: "",
                                    assigne_name: ''
                                  });
                                }
                              }}
                              style={{ width: "100%", marginTop: "5px" }}>
                              {assignee &&
                                assignee.length > 0 &&
                                assignee.map((item, i) => {
                                  return (
                                    <Option key={item.user_id}>
                                      {item.employee_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div> :
                        <div className="col-md-4">
                        </div>
                      }

                      <div className="col-md-4 text-center popupbtncolour mb-2">
                        <Button
                          type="primary"
                          shape="round"
                          className={"addbutton  " + localStorage.getItem('currentTheme')}
                          style={{ background: "#0096FF", borderColor: "#0096FF", 'margin-top': '27px' }}
                          size="default"
                          icon={<EyeOutlined />}
                          onClick={gettaskrecalllist}>
                          Show
                        </Button>
                      </div>
                    </div>
                  </div>

                </Panel>
              </Collapse>
              {acccordionTrue === true ? (
                <>
                  {/* <div className={scroll == true ? "d-block" : "d-none"}>
                                    <Button style={{ position: 'fixed', right: '30px', bottom: '50px', zIndex: 1, width: '34px', display: 'flex', justifyContent: 'center' }} onClick={() => { scrollup() }}><UpCircleOutlined style={{ fontSize: '1.5rem' }} /></Button>
                                </div> */}
                  <div className="mt-0 mx-2">
                    <Collapse defaultActiveKey={["0"]} accordion>
                      <Panel
                        className="report-collapse"
                        header="Compliance Table"
                        key="0"
                        extra={
                          <>
                            <span style={{ paddingRight: "80px" }}>
                              Selected Assignee : {showdata.assigne_name || undefined}
                            </span>
                            <span style={{ paddingRight: "80px" }}>
                              Selected Frequency : {all.toString()}
                            </span>
                            <span style={{ paddingRight: "80px" }}>
                              Total Compliance : {totalRecord}
                            </span>
                          </>
                        }
                      >
                        <div
                          className={
                            localStorage.getItem("currentTheme") +
                            " ant-table-wrapper service-provider-table"
                          }
                        >
                          <div className="ant-table ant-table-bordered ant-table-fixed-header">
                            <div className="ant-table-container">
                              <div className="ant-table-content taskrecalltable service-table">
                                <table className="table-fixed">
                                  <thead className="ant-table-thead">
                                    <tr>
                                      <th className="ant-table-cell" style={{ width: "5px" }}>
                                        <div className="d-flex justify-content-center align-items-center">
                                          <span className="me-1"># </span>
                                          <div className="position-relative" ref={wrapperRef}>
                                            <button onClick={(e) => { setshowDropDown(current => !current) }}>
                                              <FilterFilled />
                                            </button>
                                            {showDropDown && (
                                              <Form onFinish={onFinish} className='bg-white position-absolute text-start shadow-lg' style={{ borderRadius: '15px', top: '32px', width: '165px', zIndex: 3 }}>

                                                <Form.Item className="mt-1" name={'periodical'} >
                                                  &nbsp;<Checkbox id={'periodical'} value={'periodical'} checked={checked.periodical} onChange={(e) => {
                                                    e.target.checked ? setChecked({
                                                      ...checked,
                                                      periodical: true
                                                    }) : setChecked({
                                                      ...checked,
                                                      periodical: false
                                                    })

                                                    setFilterValues({
                                                      ...filterValues,
                                                      periodical: e.target.checked
                                                    })
                                                  }} />&nbsp; <label className="fw-normal user-select-none" htmlFor="periodical"><i style={{ color: 'green' }} className="bi bi-square-fill"></i> Periodical</label>
                                                </Form.Item>
                                                <Form.Item name={'flexi_review'}>
                                                  &nbsp;<Checkbox id="flexi_review" value={'Flexi Review'} checked={checked.flexi_review} onChange={(e) => {
                                                    e.target.checked ? setChecked({
                                                      ...checked,
                                                      flexi_review: true
                                                    }) : setChecked({
                                                      ...checked,
                                                      flexi_review: false
                                                    })

                                                    setFilterValues({
                                                      ...filterValues,
                                                      flexi_review: e.target.checked
                                                    })
                                                  }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="flexi_review"><i style={{ color: "pink" }} className="bi bi-square-fill"></i> Flexi Review</label>
                                                </Form.Item>
                                                <Form.Item name={'Review'}>
                                                  &nbsp;<Checkbox id="Review" value={'Review'} checked={checked.review} onChange={(e) => {
                                                    e.target.checked ? setChecked({
                                                      ...checked,
                                                      review: true
                                                    }) : setChecked({
                                                      ...checked,
                                                      review: false
                                                    })

                                                    setFilterValues({
                                                      ...filterValues,
                                                      review: e.target.checked
                                                    })
                                                  }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="Review"><i style={{ color: "orange" }} className="bi bi-square-fill"></i> Review</label>
                                                </Form.Item>
                                                <Form.Item name={'On Occurrence'}>
                                                  &nbsp;<Checkbox id="On-Occurrence" value={'On Occurrence'} checked={checked.on_occurrence} onChange={(e) => {
                                                    e.target.checked ? setChecked({
                                                      ...checked,
                                                      on_occurrence: true
                                                    }) : setChecked({
                                                      ...checked,
                                                      on_occurrence: false
                                                    })

                                                    setFilterValues({
                                                      ...filterValues,
                                                      on_occurrence: e.target.checked
                                                    })
                                                  }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="On-Occurrence"><i style={{ color: "yellow" }} className="bi bi-square-fill"></i> On Occurrence</label>
                                                </Form.Item>
                                                <Form.Item className="mb-1" name={' One Time'}>
                                                  &nbsp;<Checkbox id="One-Time" value={'One Time'} checked={checked.one_time} onChange={(e) => {
                                                    e.target.checked ? setChecked({
                                                      ...checked,
                                                      one_time: true
                                                    }) : setChecked({
                                                      ...checked,
                                                      one_time: false
                                                    })

                                                    setFilterValues({
                                                      ...filterValues,
                                                      one_time: e.target.checked
                                                    })
                                                  }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="One-Time"><i style={{ color: "red" }} className="bi bi-square-fill"></i> One Time</label>
                                                </Form.Item>
                                                <Form.Item className="submitButtonFrm" >
                                                  <Button style={{ borderRadius: '10px', border: 'unset' }} className="statistic" type="primary" htmlType="submit" onClick={() => {
                                                    var ele = document.getElementsByName("allchecked");
                                                    ele[0].checked = false;
                                                  }}>OK</Button>
                                                  <a className="link ms-2" onClick={() => { resetfilter() }}>Reset</a>
                                                </Form.Item>
                                              </Form>
                                            )}
                                          </div>
                                        </div>
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "20px" }}
                                      >
                                        {/* Action {' '} */}
                                        <input
                                          type="checkbox"
                                          title="Click here to approve all"
                                          name="allchecked"
                                          className={"allChecked"}
                                          onClick={(e) => {
                                            let checked = e.target.checked;
                                            addAllSelectedDataValues(checked);
                                          }}
                                        />
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "610px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p
                                            className="p-0 mb-0"
                                            style={{
                                              marginLeft: "36%",
                                              display: "inline",
                                              color: searchingValue['compliance_task'] ? 'red' : ''
                                            }}
                                          >
                                            Compliance Task
                                          </p>{" "}
                                          <Popconfirm
                                            afterVisibleChange={() => {
                                              setFocus(myRef1)
                                            }}
                                            placement="bottom"
                                            title={
                                              // <Input
                                              //   ref={myRef1}
                                              //   placeholder="Compliance Task"
                                              //   autoFocus={'on'}
                                              //   onKeyUp={(e) => {
                                              //     compliancetaskfilter(
                                              //       e.target.value,
                                              //       "Compliance_Task"
                                              //     );
                                              //   }}
                                              // />
                                              <div className='position-relative'>
                                                <Input
                                                  ref={myRef1}
                                                  placeholder="Compliance Task"
                                                  autoFocus={'on'}
                                                  onChange={(e) => {
                                                    setSearchedValue({
                                                      ...searchedValue,
                                                      complianceTasks: e.target.value
                                                    });
                                                    searchingValue['compliance_task'] = e.target.value

                                                  }}
                                                  value={searchedValue.complianceTasks || undefined}
                                                  onKeyUp={(e) => {
                                                    compliancetaskfilter(
                                                      e.target.value,
                                                      "Compliance_Task",
                                                    );
                                                  }}
                                                />
                                                <CloseCircleOutlined onClick={() => {
                                                  myRef1.current.value = "";
                                                  setSearchedValue({
                                                    ...searchedValue,
                                                    complianceTasks: ''
                                                  });
                                                  searchingValue['compliance_task'] = ''
                                                  compliancetaskfilter(
                                                    '',
                                                    "Compliance_Task",
                                                    'compliance_task'

                                                  );
                                                  // inputRef.current.input.value = "";
                                                  // console.log(inputRef.current.,'inputRef');
                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                              </div>
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px' }}
                                            />
                                          </Popconfirm>
                                        </div>
                                        {/* <Input placeholder='Compliance Task' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Compliance_Task')
                                                                        }} /> */}
                                      </th>
                                      {/* <th className='ant-table-cell' style={{ width: '10%' }}>Assignee <Input placeholder='Assignee' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Assignee')
                                                                        }} /></th> */}
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "90px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p
                                            className="mb-0"
                                            style={{
                                              marginLeft: "2px",
                                              display: "inline",
                                              color: searchingValue['compliance_status'] ? 'red' : ''

                                            }}
                                          >
                                            Compliance Status
                                          </p>
                                          <Popconfirm
                                            afterVisibleChange={() => {
                                              setFocus(myRef2)
                                            }}
                                            placement="bottom"
                                            title={
                                              // <Input
                                              // ref={myRef2}
                                              //   placeholder="Compliance Status"
                                              //   autoFocus={'on'}
                                              //   onKeyUp={(e) => {
                                              //     compliancetaskfilter(
                                              //       e.target.value,
                                              //       "Compliance_Status"
                                              //     );
                                              //   }}
                                              // />
                                              <div className='position-relative'>
                                                <Input
                                                  ref={myRef2}
                                                  placeholder="Compliance Status"
                                                  autoFocus={'on'}
                                                  onChange={(e) => {
                                                    setSearchedValue({
                                                      ...searchedValue,
                                                      complianceStatus: e.target.value
                                                    });
                                                    searchingValue['compliance_status'] = e.target.value
                                                  }}
                                                  value={searchedValue.complianceStatus || undefined}
                                                  onKeyUp={(e) => {
                                                    compliancetaskfilter(
                                                      e.target.value,
                                                      "Compliance_Status",
                                                    );
                                                  }}
                                                />
                                                <CloseCircleOutlined onClick={() => {
                                                  myRef2.current.value = "";
                                                  setSearchedValue({
                                                    ...searchedValue,
                                                    complianceStatus: ''
                                                  });
                                                  searchingValue['compliance_status'] = ''

                                                  compliancetaskfilter(
                                                    '',
                                                    "Compliance_Status",
                                                    'compliance_status'

                                                  );
                                                  // inputRef.current.input.value = "";
                                                  // console.log(inputRef.current.,'inputRef');
                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                              </div>
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px' }}
                                            />
                                          </Popconfirm>
                                        </div>
                                        {/* <Input placeholder='Compliance Status' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Compliance_Status')
                                                                        }} /> */}
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "80px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p className="mb-0"
                                            style={{
                                              marginLeft: "20%",
                                              display: "inline",
                                              color: searchingValue['due_date'] ? 'red' : ''
                                            }}
                                          >
                                            Due Date
                                          </p>{" "}
                                          <Popconfirm
                                            afterVisibleChange={() => {
                                              setFocus(myRef3)
                                            }}
                                            placement="bottom"
                                            title={
                                              // <Input
                                              // ref={myRef3}
                                              //   placeholder="Due Date"
                                              //   autoFocus={'on'}
                                              //   onKeyUp={(e) => {
                                              //     compliancetaskfilter(
                                              //       e.target.value,
                                              //       "Due_Date"
                                              //     );
                                              //   }}
                                              // />
                                              <div className='position-relative'>
                                                <Input
                                                  ref={myRef3}
                                                  placeholder="Due Date"
                                                  autoFocus={'on'}
                                                  onChange={(e) => {
                                                    setSearchedValue({
                                                      ...searchedValue,
                                                      dueDate: e.target.value
                                                    });
                                                    searchingValue['due_date'] = e.target.value
                                                  }}
                                                  value={searchedValue.dueDate || undefined}
                                                  onKeyUp={(e) => {
                                                    compliancetaskfilter(
                                                      e.target.value,
                                                      "Due_Date",
                                                    );
                                                  }}
                                                />
                                                <CloseCircleOutlined onClick={() => {
                                                  myRef3.current.value = "";
                                                  setSearchedValue({
                                                    ...searchedValue,
                                                    dueDate: ''
                                                  });
                                                  searchingValue['due_date'] = ''
                                                  compliancetaskfilter(
                                                    '',
                                                    "Due_Date",
                                                    'due_date'
                                                  );
                                                  // inputRef.current.input.value = "";
                                                  // console.log(inputRef.current.,'inputRef');
                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                              </div>
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px' }}
                                            />
                                          </Popconfirm>
                                        </div>

                                        {/* <Input placeholder='Due Date' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Due_Date')
                                                                        }} /> */}
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "90px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center c_date">
                                          <p className="mb-0"
                                            style={{
                                              marginLeft: "12%",
                                              display: "inline",
                                              color: searchingValue['completion_date'] ? 'red' : ''
                                            }}
                                          >
                                            Completion Date
                                          </p>
                                          <Popconfirm
                                            afterVisibleChange={() => {
                                              setFocus(myRef4)
                                            }}
                                            placement="bottomRight"
                                            title={
                                              // <Input
                                              // ref={myRef4}
                                              //   placeholder="Completion Date"
                                              //   autoFocus={'on'}
                                              //   onKeyUp={(e) => {
                                              //     compliancetaskfilter(
                                              //       e.target.value,
                                              //       "Completion_Date"
                                              //     );
                                              //   }}
                                              // />
                                              <div className='position-relative'>
                                                <Input
                                                  ref={myRef4}
                                                  placeholder="Completion Date"
                                                  autoFocus={'on'}
                                                  onChange={(e) => {
                                                    setSearchedValue({
                                                      ...searchedValue,
                                                      completionDate: e.target.value
                                                    });
                                                    searchingValue['completion_date'] = e.target.value
                                                  }}
                                                  value={searchedValue.completionDate || undefined}
                                                  onKeyUp={(e) => {
                                                    compliancetaskfilter(
                                                      e.target.value,
                                                      "Completion_Date",
                                                    );
                                                  }}
                                                />
                                                <CloseCircleOutlined onClick={() => {
                                                  myRef4.current.value = "";
                                                  setSearchedValue({
                                                    ...searchedValue,
                                                    completionDate: ''
                                                  });
                                                  searchingValue['completion_date'] = ''
                                                  compliancetaskfilter(
                                                    '',
                                                    "Completion_Date",
                                                    'completion_date'

                                                  );
                                                  // inputRef.current.input.value = "";
                                                  // console.log(inputRef.current.,'inputRef');
                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                              </div>
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px' }}
                                            />
                                          </Popconfirm>
                                        </div>

                                        {/* <Input placeholder='Completion Date' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Completion_Date')
                                                                        }} /> */}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="ant-table-tbody">
                                    {filteredArrayState &&
                                      filteredArrayState.length > 0 ? (
                                      filteredArrayState.map((list, ii) => {
                                        return (
                                          <Fragment key={ii}>
                                            {tempFilterArray.includes(
                                              list[0].unit_name
                                            ) == true ? (
                                              <tr className="bg-lightdark">
                                                <td colSpan={7}>
                                                  <div
                                                    className="ant-table-title row"
                                                  >
                                                    <span className="col-md-6 text-start"> Vendor : {list[0].unit_name}</span>
                                                    {/* <span className="col-md-6 text-end"> Domain : {showdata.domainName}</span> */}
                                                  </div>
                                                </td>
                                              </tr>
                                            ) : (
                                              ""
                                            )}
                                            {temp && temp.length > 0 ? (
                                              temp.map((itemdata, i) => {
                                                let data = _.find(
                                                  itemdata.child,
                                                  {
                                                    unit_name:
                                                      list[0].unit_name,
                                                  }
                                                );
                                                if (data != undefined) {
                                                  if (
                                                    data.assignee_name ==
                                                    itemdata.parentData
                                                      .assignee_name
                                                  ) {
                                                    return (
                                                      <Fragment key={i}>
                                                        <tr className="ant-table-row ant-table-row-level-0">
                                                          <td
                                                            colSpan={12}
                                                            className="ant-table-cell"
                                                          >
                                                            <b>
                                                              {
                                                                itemdata
                                                                  .parentData
                                                                  .assignee_name
                                                              }
                                                            </b>
                                                          </td>
                                                        </tr>
                                                        {itemdata.child.map(
                                                          (items, l) => {
                                                            console.log(items, "itemsitems");

                                                            return (
                                                              <tr
                                                                className="ant-table-row ant-table-row-level-0"
                                                                key={l}
                                                              >
                                                                <td className="ant-table-cell">
                                                                  <center>
                                                                    {counts + 1}
                                                                    <span
                                                                      hidden
                                                                    >
                                                                      {
                                                                        (counts =
                                                                          counts +
                                                                          1)
                                                                      }
                                                                    </span>
                                                                    {items.compliance_frequency ==
                                                                      "Periodical" ? (
                                                                      <p>

                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "green",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    ) : items.compliance_frequency ==
                                                                      "Flexi Review" ? (
                                                                      <p>
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "pink",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    ) : items.compliance_frequency ==
                                                                      "Review" ? (
                                                                      <p>
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "orange",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    ) : items.compliance_frequency ==
                                                                      "On Occurrence" ? (
                                                                      <p>
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "yellow",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    ) : (
                                                                      <p>
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "red",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    )}
                                                                  </center>
                                                                </td>
                                                                <td>
                                                                  <Fragment>
                                                                    <center>
                                                                      <input
                                                                        type="checkbox"
                                                                        title="Click here to Task Recall"
                                                                        name={
                                                                          "checked"
                                                                        }
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          let checked =
                                                                            e
                                                                              .target
                                                                              .checked;
                                                                          addSelectedDataValues(
                                                                            checked,
                                                                            items.compliance_history_id,
                                                                            items.parentId
                                                                          );
                                                                        }}
                                                                      />
                                                                    </center>
                                                                  </Fragment>
                                                                </td>
                                                                <td>
                                                                  <>
                                                                    <span
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() => {
                                                                        console.log(items, "itemssssss56575");
                                                                        showValue();
                                                                        setValueModal(
                                                                          {

                                                                            compliance_history_id:
                                                                              items.compliance_history_id,
                                                                            unit: items.unit_name,
                                                                            compliance_task:
                                                                              items.compliance_name,
                                                                            compliance_frequency:
                                                                              items.compliance_frequency,
                                                                            document_issued_date:
                                                                              items.completion_date,
                                                                            document_reference_number:
                                                                              items.document_reference_number,
                                                                            next_due_date:
                                                                              items.next_due_date,
                                                                            address:
                                                                              items.address,
                                                                            compliance_description:
                                                                              items.compliance_description,
                                                                            uploaded_document:
                                                                              items.download_url,
                                                                            interim_details:
                                                                              items.interim_count,
                                                                            remarks:
                                                                              items.remarks,
                                                                            history_details: items.remarks_count,
                                                                            unit_id: items.unit_id,
                                                                            start_date: items.start_date,
                                                                            domain_id: items.domain_id,
                                                                            country_id: items.country_id,
                                                                            occurrence_remarks: items.occurrence_remarks,
                                                                            document_size: items.document_size,
                                                                            is_not_applicable: items.is_not_applicable

                                                                          }
                                                                        );
                                                                      }}
                                                                    >

                                                                      {items.statu}
                                                                      <div className="approve-task-reacall-tooltip">
                                                                        <div className="mb-0 d-flex align-items-center">
                                                                          <Tooltip
                                                                            placement="topRight"
                                                                            overlayStyle={{
                                                                              maxWidth:
                                                                                "420px",
                                                                            }}
                                                                            title={gettooltipfun(
                                                                              items
                                                                            )}
                                                                          >
                                                                            <span className='mx-1 text-primary'>
                                                                              <i className="ri-information-fill" /></span>
                                                                            {/* gettooltipfun(items)
                                                                                                                            }> <ExclamationCircleTwoTone/> */}
                                                                          </Tooltip>
                                                                          &nbsp;


                                                                          {
                                                                            items.compliance_name
                                                                          }<br />Assigned on : {items.assigned_on}
                                                                        </div>
                                                                      </div>
                                                                    </span>

                                                                  </>
                                                                </td>
                                                                {/* <td>
                                                                                                                {items.assignee_name}
                                                                                                            </td> */}
                                                                <td>
                                                                  <span
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                      console.log(items, "itemssssss56575");
                                                                      showValue();
                                                                      setValueModal(
                                                                        {

                                                                          compliance_history_id:
                                                                            items.compliance_history_id,
                                                                          unit: items.unit_name,
                                                                          compliance_task:
                                                                            items.compliance_name,
                                                                          compliance_frequency:
                                                                            items.compliance_frequency,
                                                                          document_issued_date:
                                                                            items.completion_date,
                                                                          document_reference_number:
                                                                            items.document_reference_number,
                                                                          next_due_date:
                                                                            items.next_due_date,
                                                                          address:
                                                                            items.address,
                                                                          compliance_description:
                                                                            items.compliance_description,
                                                                          uploaded_document:
                                                                            items.download_url,
                                                                          interim_details:
                                                                            items.interim_count,
                                                                          remarks:
                                                                            items.remarks,
                                                                          history_details: items.remarks_count,
                                                                          unit_id: items.unit_id,
                                                                          start_date: items.start_date,
                                                                          domain_id: items.domain_id,
                                                                          country_id: items.country_id,
                                                                          occurrence_remarks: items.occurrence_remarks,
                                                                          document_size: items.document_size,
                                                                          is_not_applicable: items.is_not_applicable

                                                                        }
                                                                      );
                                                                    }}
                                                                  >
                                                                    {
                                                                      items.task_status === 'Not Complied' ? 'Rejected' : items.task_status
                                                                    }
                                                                  </span>
                                                                </td>
                                                                <td>

                                                                  <center>
                                                                    <span
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() => {
                                                                        console.log(items, "itemssssss56575");
                                                                        showValue();
                                                                        setValueModal(
                                                                          {

                                                                            compliance_history_id:
                                                                              items.compliance_history_id,
                                                                            unit: items.unit_name,
                                                                            compliance_task:
                                                                              items.compliance_name,
                                                                            compliance_frequency:
                                                                              items.compliance_frequency,
                                                                            document_issued_date:
                                                                              items.completion_date,
                                                                            document_reference_number:
                                                                              items.document_reference_number,
                                                                            next_due_date:
                                                                              items.next_due_date,
                                                                            address:
                                                                              items.address,
                                                                            compliance_description:
                                                                              items.compliance_description,
                                                                            uploaded_document:
                                                                              items.download_url,
                                                                            interim_details:
                                                                              items.interim_count,
                                                                            remarks:
                                                                              items.remarks,
                                                                            history_details: items.remarks_count,
                                                                            unit_id: items.unit_id,
                                                                            start_date: items.start_date,
                                                                            domain_id: items.domain_id,
                                                                            country_id: items.country_id,
                                                                            occurrence_remarks: items.occurrence_remarks,
                                                                            document_size: items.document_size,
                                                                            is_not_applicable: items.is_not_applicable


                                                                          }
                                                                        );
                                                                      }}
                                                                    >



                                                                      {((items.compliance_frequency == "On Occurrence") && (moment(items.due_date).hours() != 0 && moment(items.due_date).minutes() != 0)) ? items.due_date : moment(items.due_date).format('DD-MMM-YYYY')}



                                                                    </span>

                                                                  </center>
                                                                </td>
                                                                <td>
                                                                  <center>
                                                                    <span
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() => {
                                                                        console.log(items, "itemssssss56575");
                                                                        showValue();
                                                                        setValueModal(
                                                                          {

                                                                            compliance_history_id:
                                                                              items.compliance_history_id,
                                                                            unit: items.unit_name,
                                                                            compliance_task:
                                                                              items.compliance_name,
                                                                            compliance_frequency:
                                                                              items.compliance_frequency,
                                                                            document_issued_date:
                                                                              items.completion_date,
                                                                            document_reference_number:
                                                                              items.document_reference_number,
                                                                            next_due_date:
                                                                              items.next_due_date,
                                                                            address:
                                                                              items.address,
                                                                            compliance_description:
                                                                              items.compliance_description,
                                                                            uploaded_document:
                                                                              items.download_url,
                                                                            interim_details:
                                                                              items.interim_count,
                                                                            remarks:
                                                                              items.remarks,
                                                                            history_details: items.remarks_count,
                                                                            unit_id: items.unit_id,
                                                                            start_date: items.start_date,
                                                                            domain_id: items.domain_id,
                                                                            country_id: items.country_id,
                                                                            occurrence_remarks: items.occurrence_remarks,
                                                                            document_size: items.document_size,
                                                                            is_not_applicable: items.is_not_applicable


                                                                          }
                                                                        );
                                                                      }}
                                                                    >

                                                                      {((items.compliance_frequency == "On Occurrence") && (moment(items.due_date).hours() != 0 && moment(items.due_date).minutes() != 0)) ? items.completion_date : items.completion_date !== null ? moment(items.completion_date).format('DD-MMM-YYYY') : ''}


                                                                      {/* {items.compliance_frequency == 'On Occurrence' ? items.completion_date : moment(items.completion_date).format('DD-MMM-YYYY')} */}

                                                                    </span>

                                                                  </center>
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                      </Fragment>
                                                    );
                                                  }
                                                }
                                              })
                                            ) : ii == 0 ? (
                                              <tr>
                                                <td
                                                  colSpan={7}
                                                  style={{
                                                    textAlign: "center",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  No Compliance Available
                                                </td>
                                              </tr>
                                            ) : (
                                              false
                                            )}
                                          </Fragment>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan={7}
                                          style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          No Compliance Available
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                    <Modal
                      title="Task Recall"
                      className={
                        "add-service-prv " +
                        localStorage.getItem("currentTheme")
                      }
                      footer={false}
                      open={filterModalVisible}
                      onCancel={setcancelFilter}
                      maskClosable={false}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          {/* <div className="col-md-8" > */}
                          {/* <div className="mb-3"> */}
                          {/* <label>Legal Entity:</label> */}
                          <label htmlFor="">
                            <b>Legal Entity: </b>
                          </label>
                          <span style={{ color: "red" }}> *</span>
                          <br />
                          {localStorage.getItem("SelectedEntity") ===
                            "All Legal Entity" ? (
                            <div className="form-group">
                              <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                allowClear={true}
                                size="default"
                                placeholder="Select Legal Entity"
                                // onPaste={(e) => {
                                //   handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                // }}
                                onPaste={onPasteToaster}
                                searchValue={selectSearchValue.legalEntity}
                                onSearch={(value) => {
                                  let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                  setSelectSearchValue({
                                    ...selectSearchValue,
                                    legalEntity: result
                                  });
                                }}
                                onChange={(data, value) => {
                                  setDomain([])
                                  setDomainFilter([])
                                  setDomainValue(false)
                                  if (data !== undefined) {
                                    Setlegalstate(true);
                                    setCurrentEntity(data);
                                    Setshowdata({
                                      ...data,
                                      legal_entity: data,
                                      domain: "",
                                      unit: "",
                                      frequency: "",
                                      asignee: "",
                                      due_month: "",
                                      Assignee: ''
                                    });
                                  } else {
                                    Setlegalstate(false);
                                    setCurrentEntity("");
                                    Setshowdata({
                                      ...data,
                                      legal_entity: "",
                                      domain: "",
                                      unit: "",
                                      frequency: "",
                                      asignee: "",
                                      due_month: "",
                                      Assignee: ''
                                    });
                                  }
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                value={showdata.legal_entity || undefined}
                                style={{ width: "100%", marginTop: "5px" }}
                              >
                                {sessionArr &&
                                  sessionArr.length > 0 &&
                                  sessionArr.map((item, i) => {
                                    return (
                                      <Option key={item.le_id}>
                                        {item.le_name}
                                      </Option>
                                    );
                                  })}
                              </Select>
                              {formValidator.current.message(
                                "legal_entity",
                                showdata.legal_entity,
                                "required",
                                {
                                  className: `invalid-feedback ${FormShow ? "show" : "hide"
                                    }`,
                                  messages: {
                                    required: "Legal Entity Required",
                                  },
                                }
                              )}
                            </div>
                          ) : (
                            <p title={localStorage.getItem('SelectedEntity')} className='text-wrap-report' style={{ width: "100%", marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                          )}
                          {/* </div> */}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Domain: </b>
                            </label>
                            <span style={{ color: "red" }}> *</span>
                            <br />
                            {domainValue == false || domainFilter && domainFilter.length > 1 ?
                              <>
                                <Select
                                  getPopupContainer={trigger => trigger.parentNode}
                                  allowClear={true}
                                  size="default"
                                  name="domain"
                                  id="domain"
                                  placeholder="Enter domain"
                                  // onPaste={(e) => {
                                  //   handlePasting(e, /^[a-z 0-9]+$/gi)
                                  // }}
                                  onPaste={onPasteToaster}
                                  searchValue={selectSearchValue.domain}
                                  onSearch={(value) => {
                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                    setSelectSearchValue({
                                      ...selectSearchValue,
                                      domain: result
                                    });
                                  }}
                                  onChange={(data, value) => {
                                    if (data !== undefined) {
                                      Setshowdata({
                                        ...showdata,
                                        domain: data,
                                        domainName: value.children,
                                        unit: "",
                                        frequency: "",
                                        asignee: "",
                                        due_month: "",
                                        act: "",
                                      });
                                    } else {
                                      Setshowdata({
                                        ...showdata,
                                        domain: "",
                                        unit: "",
                                        frequency: "",
                                        asignee: "",
                                        due_month: "",
                                        act: "",
                                        domainName: ''
                                      });
                                    }
                                  }}
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  value={showdata.domainName || undefined}
                                  style={{ width: "100%", marginTop: "5px" }}
                                  disabled={
                                    showdata.legal_entity === "" ? true : false
                                  }
                                >
                                  {domainFilter &&
                                    domainFilter.length &&
                                    domainFilter.map((item, i) => {
                                      return (
                                        <Option key={item.d_id}>{item.d_name}</Option>
                                      );
                                    })}
                                </Select>
                                {/* } */}
                                {formValidator.current.message(
                                  "domain",
                                  showdata.domain,
                                  ["required"],
                                  {
                                    className: `invalid-feedback ${FormShow ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required: "Domain Required",
                                    },
                                  }
                                )}
                              </>
                              : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain && domain.length > 0 && domain[0].d_name}</p>}
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Vendor: </b>
                            </label>
                            <br />
                            <Select
                              getPopupContainer={trigger => trigger.parentNode}
                              allowClear={true}
                              size="default"
                              placeholder="Enter unit"
                              // onPaste={(e) => {
                              //   handlePasting(e, /^[a-z 0-9 , . - _]+$/gi)
                              // }}
                              onPaste={onPasteToaster}
                              searchValue={selectSearchValue.vendor}
                              onSearch={(value) => {
                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                setSelectSearchValue({
                                  ...selectSearchValue,
                                  vendor: result
                                });
                              }}
                              onChange={(data) => {
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    unit: data,
                                    frequency: "",
                                    due_month: "",
                                    act: "",
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    unit: "",
                                    frequency: "",
                                    due_month: "",
                                    act: "",
                                  });
                                }
                              }}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.unit || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                              disabled={
                                showdata.legal_entity === "" ? true : false
                              }
                            >
                              {userunitsrecall &&
                                userunitsrecall.user_units &&
                                userunitsrecall.user_units.map((item, i) => {
                                  return (
                                    <Option key={item.unit_id}>
                                      {item.unit_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Frequency: </b>
                            </label>

                            <Select
                              getPopupContainer={trigger => trigger.parentNode}
                              defaultValue="All"
                              allowClear={false}
                              size="default"
                              // placeholder="Enter frequecy"
                              onChange={(data, value) => {
                                setAll(value.children === undefined ? 'All' : value.children)
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    frequency: data,
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    frequency: "",
                                  });
                                }
                              }}
                              // showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.frequency || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              <Option key={0}>All</Option>
                              {userunitsrecall &&
                                userunitsrecall.frequency_list &&
                                userunitsrecall.frequency_list.map((item, i) => {
                                  return (
                                    <Option key={item.frequency_id}>
                                      {item.frequency_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-6" >
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Due Month: </b>
                            </label>{" "}
                            <span style={{ color: "red" }}>*</span>
                            {due == false ? (
                              <DatePicker
                                getPopupContainer={trigger => trigger.parentNode}
                                popupStyle={{
                                  height: "150px",
                                  marginTop: "5px",
                                }}
                                onKeyDown={(event) => {
                                  event.preventDefault()
                                }}

                                onPaste={(e) => {
                                  handlePasting(e, /^[a-z 0-9 , . - _]+$/gi)
                                }}
                                format={"MMMM-YYYY"}
                                disabled={showdata.domain === "" ? true : false}
                                style={{ width: "100%", marginTop: "5px" }}
                                allowClear={false}


                                onChange={(date, dateString) => {

                                  setDue(true);
                                  Setshowdata({
                                    ...showdata,
                                    due_month:
                                      moment(dateString).format("MMMM-YYYY"),
                                  });
                                }}
                                picker="month"
                                value={
                                  showdata.due_month
                                    ? moment(showdata.due_month)
                                    : ""
                                }
                                monthCellRender={(current) => {
                                  const style = {};
                                  if (
                                    moment(current).format("MMM-YYYY") ===
                                    moment().format("MMM-YYYY")
                                  ) {
                                    // style.border = '1px solid #1890ff';
                                    style.borderRadius = "2px";
                                    style.lineHeight = "24px";
                                    style.minWidth = "24px";
                                    style.position = "relative";
                                    style.display = "inlineBlock";
                                    style.backgroundColor = "#1890ff";
                                    style.color = "white";
                                    style.marginTop = "5px";

                                  }
                                  return (
                                    <div
                                      className="ant-picker-cell-inner"
                                      style={style}
                                    >
                                      {moment(current).format("MMM")}
                                    </div>
                                  );
                                }}
                              />
                            ) : (
                              <DatePicker
                                getPopupContainer={trigger => trigger.parentNode}
                                popupStyle={{
                                  height: "150px",
                                  width: "280px",
                                  marginTop: "5px",
                                }}
                                format={"MMMM-YYYY"}
                                disabled={showdata.domain === "" ? true : false}
                                style={{
                                  width: "100%",
                                  marginTop: "5px",
                                }}
                                allowClear={false}


                                onKeyDown={(event) => {
                                  event.preventDefault()
                                }}

                                onPaste={(e) => {
                                  handlePasting(e, /^[a-z 0-9 , . - _]+$/gi)
                                }}
                                onChange={(date, dateString) => {
                                  setDue(true);
                                  Setshowdata({
                                    ...showdata,
                                    due_month:
                                      moment(dateString).format("MMMM-YYYY"),
                                  });
                                }}
                                picker="month"
                                value={
                                  showdata.due_month
                                    ? moment(showdata.due_month)
                                    : ""
                                }
                              />
                            )}
                            {formValidator.current.message(
                              "date",
                              showdata.due_month,
                              ["required"],
                              {
                                className: `invalid-feedback ${FormShow ? "show" : "hide"
                                  }`,
                                messages: {
                                  required: "Due Month Required",
                                },
                              }
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Act: </b>
                            </label>
                            <Select
                              getPopupContainer={trigger => trigger.parentNode}
                              allowClear={true}
                              size="default"
                              placeholder="Enter act"
                              // onPaste={(e) => {
                              //   handlePasting(e, /^[a-z 0-9 () - _ , .]+$/gi)
                              // }}
                              onPaste={onPasteToaster}
                              searchValue={selectSearchValue.act}
                              onSearch={(value) => {
                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                setSelectSearchValue({
                                  ...selectSearchValue,
                                  act: result
                                });
                              }}
                              onChange={(data) => {
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    act: data,
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    act: "",
                                  });
                                }
                              }}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.act || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                              disabled={
                                showdata.due_month === "" ? true : false
                              }
                            >
                              {userunitsrecall &&
                                userunitsrecall.act_list &&
                                userunitsrecall.act_list.map((item, i) => {
                                  return (
                                    <Option key={item.parent_id}>
                                      {item.statutory_mapping}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: '10px' }}>
                        <div className="col-md-6">
                          <label htmlFor="">
                            <b>Assignee: </b>
                          </label>
                          <br />
                          <Select
                            allowClear={true}
                            size="default"
                            getPopupContainer={trigger => trigger.parentNode}
                            placeholder="Enter Assignee"
                            value={showdata.Assignee || undefined}
                            showSearch
                            // onPaste={(e) => {
                            //   handlePasting(e, /^[a-z 0-9 -]+$/gi)
                            // }}
                            onPaste={onPasteToaster}
                            searchValue={selectSearchValue.assignee}
                            onSearch={(value) => {
                              let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                              setSelectSearchValue({
                                ...selectSearchValue,
                                assignee: result
                              });
                            }}
                            disabled={showdata.legal_entity == '' ? true : false}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(data, value) => {
                              if (data !== undefined) {
                                Setshowdata({
                                  ...showdata,
                                  Assignee: data,
                                  assigne_name: value.children
                                });
                              } else {
                                Setshowdata({
                                  ...showdata,
                                  Assignee: "",
                                  assigne_name: ''
                                });
                              }
                            }}
                            style={{ width: "100%", marginTop: "5px" }}>
                            {assignee &&
                              assignee.length > 0 &&
                              assignee.map((item, i) => {
                                return (
                                  <Option key={item.user_id}>
                                    {item.employee_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                        <div className="col-md-12 text-center popupbtncolour mt-2" style={{ marginTop: '27px' }}>
                          <Button
                            type="primary"
                            shape="round"
                            className={"addbutton  " + localStorage.getItem('currentTheme')}
                            style={{ 'margin-top': '27px' }}
                            size="default"
                            icon={<EyeOutlined />}
                            onClick={gettaskrecalllist}>
                            Show
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-12 mt-1 popupbtncolour text-center">

                      </div>
                    </Modal>
                    <div
                      className="row"
                      style={{ textAlign: "center", marginLeft: "38%" }}
                    >
                      <div className="col-md-4">
                        {temp && temp.length > 0 && updatedArray.length < count ? (
                          <Button
                            type="primary"
                            shape="round"
                            className="showbutton"
                            style={{
                              background: "#0096FF",
                              borderColor: "#0096FF",
                              marginLeft: "20%",
                              marginTop: "5%",
                            }}
                            size="default"
                            onClick={gettaskrecalllistshowmore}
                          >
                            Show More{" "}
                            <i>
                              {" "}
                              <DownOutlined />
                            </i>
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {temp && temp.length > 0 ? (
                      <>
                        <div>
                          <div
                            className="col-md-12"
                            style={{ marginTop: "10px" }}
                          >
                            <div className="row">
                              {/* <label htmlFor="" style={{ marginLeft: '3px' }}>Total Compliance : {count}</label> */}

                              <div
                                className="col-md-4"
                                style={{ marginLeft: "15px" }}
                              >
                                {/* <label>
                                  Total Compliance : <span>{count}</span>
                                </label> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            {callername && callername.login_response && callername.login_response.usr_cat_id === 1 || callername && callername.login_response && callername.login_response.usr_cat_id === 3 || callername.login_response && callername.login_response.usr_cat_id === 4 ?
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="">
                                    <b>Action : </b>
                                  </label>
                                  <span style={{ color: "red" }}> *</span>
                                  <Select
                                    name="action"
                                    className="select2 form-control custom-select bg-white"
                                    id="action"
                                    placeholder="Please Select"
                                    defaultValue={"Please Select"}
                                    onChange={(e) => {
                                      Setrecalldata({
                                        ...recalldata,
                                        action: e,
                                      });
                                    }}
                                  >
                                    <Option value=""> Please Select </Option>
                                    <Option value="1">
                                      {" "}
                                      Rectify & Retain Document{" "}
                                    </Option>
                                    <Option value="2">
                                      {" "}
                                      Rectify & Remove Document{" "}
                                    </Option>
                                  </Select>
                                  {recallSubmit.current.message(
                                    "action",
                                    recalldata.action,
                                    ["required"],
                                    {
                                      className: `invalid-feedback ${FormRecall ? "show" : "hide"
                                        }`,
                                      messages: {
                                        required:
                                          "Please select Action to Recall",
                                      },
                                    }
                                  )}
                                </div>
                              </div> : null}
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="">
                                  <b>Remarks : </b>
                                </label>
                                <span style={{ color: "red" }}> *</span>
                                <div class="d-flex align-items-end">
                                  <TextArea
                                    name="remarks"
                                    className="form-control"
                                    placeholder="Enter Remarks"
                                    value={recalldata.remarks}
                                    maxLength={500}
                                    id="remarks"
                                    cols="5"
                                    rows="1"
                                    // onKeyPress={(event) => {
                                    //   if (! /^[A-Z0-9a-z _ .,-]+$/.test(event.key)) {
                                    //     event.preventDefault();
                                    //   }
                                    // }}
                                    onPaste={(event) => {
                                      console.log(event.clipboardData.getData('text'), 'pasting data')
                                      if (event.clipboardData.getData('text').match('<') && event.clipboardData.getData('text').match('>')) {
                                        Toaster.error('Please check the input, it having some invalid characters')
                                        Setrecalldata({
                                          ...recalldata,
                                          remarks: '',
                                        });
                                        event.preventDefault()
                                        return false
                                      } else {
                                        if (! /^[A-Z0-9a-z !@#$%&*()-+=`><_ .,-]+$/.test(event.clipboardData.getData('text'))) {
                                          Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.');
                                        }
                                      }
                                    }}
                                    onChange={(e) => {
                                      let chk_valid = ''
                                      let res = validateInput(e, validationType.statutoryremarks)
                                      for (let i = 0; i < res.length; i++) {
                                        if (res[i] == '<' || res[i] == '>') {
                                          chk_valid += res[i]
                                        }
                                      }
                                      if (chk_valid.match('<>')) {
                                        Setrecalldata({
                                          ...recalldata,
                                          remarks: '',
                                        });
                                        Toaster.error('Please check the input, it having some invalid characters')
                                      } else {
                                        Setrecalldata({
                                          ...recalldata,
                                          remarks: res,
                                        });
                                      }
                                      // Setrecalldata({
                                      //   ...recalldata,
                                      //   remarks: res,
                                      // });
                                    }}
                                  ></TextArea>
                                  <span
                                    className="ms-1 text-info d-flex 
                                align-items-center justify-content-center"
                                    style={{ float: "right" }}
                                    onClick={() => {
                                      showRemarksModal();
                                    }}
                                  >
                                    <PlusCircleOutlined />
                                  </span>
                                </div>
                                {recallSubmit.current.message(
                                  "remarks",
                                  recalldata.remarks,
                                  ["required"],
                                  {
                                    className: `invalid-feedback ${FormRecall ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required: "Remarks Required",
                                      // regex: "Enter Valid Remarks"
                                    },
                                  }
                                )}
                              </div>
                            </div>
                            {/* <div className="col-md-1 ml25">
                                                <PlusCircleOutlined onClick={() => { showRemarksModal() }} />
                                            </div> */}

                            <Modal
                              title="Remarks List"
                              className={
                                "remark-header modelradius add-service-prv " +
                                localStorage.getItem("currentTheme")
                              }
                              open={remarksModal}
                              footer={null}
                              onOk={handleOk}
                              onCancel={handleCancel}
                              maskClosable={false}
                            >
                              <Table
                                // className='userprivclass'
                                className={localStorage.getItem("currentTheme")}
                                size={"small"}
                                columns={column}
                                dataSource={
                                  remarksvalue &&
                                  remarksvalue.length > 0 &&
                                  remarksvalue
                                }
                                bordered
                                pagination={false}
                              // pagination={{
                              //     defaultPageSize: dataTableProperties.pagesize,
                              //     showSizeChanger: dataTableProperties.sizechanger,
                              //     pageSizeOptions: dataTableProperties.pageSizeOptions
                              // }}
                              />
                            </Modal>
                            <div className="col-md-3">
                              <div className="form-group">
                                {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                {/* <a href={() => false} onClick={Active} style={{ marginLeft: '10%' }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Recall</a> */}
                                <Button
                                  type="primary"
                                  shape="round"
                                  className="addbutton"
                                  style={{
                                    background: "#0096FF",
                                    borderColor: "#0096FF",
                                    // marginLeft: "10%",
                                    marginTop: "8%",
                                    // float: "right",
                                  }}
                                  size="default"
                                  onClick={Active}
                                  icon={<RollbackOutlined />}
                                >

                                  Recall
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              <Modal title="" open={showValueModal} footer={null} cancelText={false} onCancel={handleCancel} maskClosable={false}>
                {/* <div className="row"> */}
                {valueModal.is_not_applicable === 1 ?
                  <>
                    <div className="not_applicable_modal">Not Applicable</div>
                    <div className="col-md-12 d-flex" >
                      <div>
                        <button
                          type="button"
                          className="btn btn-light-primary text-primary btn-circle btn-xl me-3 mb-1 pe-none"
                        >
                          <i
                            className="fas fa-list-alt"
                          ></i>
                        </button>
                      </div>
                      <div>
                        <p className='mb-1'>Vendor :</p>
                        <h4 className='fw-bolder'> {valueModal.unit}&nbsp;<Tooltip title={valueModal.address} >
                          <InfoCircleOutlined style={{ fontSize: '14px', color: 'blue' }} />
                        </Tooltip></h4>
                      </div>
                    </div>
                    <div className="container py-2">
                      <div className="row">
                        <div className="col-4 border rounded-start p-2 bg-light mb-2">
                          <p className="mb-1">Compliance Task :</p>
                          <h5>{valueModal.compliance_task}&nbsp;<Tooltip placement='bottom' title={valueModal.compliance_description} >
                            <InfoCircleOutlined style={{ fontSize: '12px', color: 'blue' }} />
                          </Tooltip></h5>
                        </div>
                        <div className="col-4 border p-2 bg-light mb-2">
                          <p className="mb-1">Compliance Frequency :</p>
                          <h5>{valueModal.compliance_frequency}</h5>
                        </div>
                        <div className="col-4 border rounded-end p-2 bg-light mb-2">
                          <p className="mb-1">Uploaded File Size :</p>
                          {valueModal.uploaded_document && valueModal.uploaded_document.length > 0 ? `${fileSize} MB` : '0.00 MB'}
                        </div>

                        <div className="col-md-12 border rounded-end p-2 bg-light mb-2">
                          <p className="mb-1">Task Completion Date/Document Issued Date :</p>
                          <h5>{valueModal.document_issued_date}</h5>
                        </div>


                        <div className="col-md-12 border rounded-start p-2 bg-light mb-2">
                          <p className='mb-1'>Attached Documents :</p>
                          {valueModal.uploaded_document != null ?
                            valueModal.uploaded_document.length > 0 && valueModal.uploaded_document.map((item, i) => {
                              return (
                                <div className='d-flex justify-content-between pb-1'> <h5 className='mb-0 pb-0' style={{ wordBreak: 'break-all' }}> {item}</h5>
                                  <span
                                    onClick={(e) => {
                                      console.log("trueeee");
                                      const remarkapi = [
                                        authtoken,
                                        {
                                          "session_token": authtoken,
                                          "request": [
                                            "DownloadFile",

                                            {
                                              "le_id": entityid,
                                              "c_id": valueModal.country_id ? Number(valueModal.country_id) : 1,
                                              "d_id": Number(showdata.domain),
                                              "u_id": Number(valueModal.unit_id),
                                              "start_date": valueModal.start_date,
                                              "file_name": item
                                            }
                                          ]
                                        }
                                      ]
                                      console.log(remarkapi, "trueeee123");
                                      if (entityid != "null" && entityid !== null) {
                                        console.log("trueeee123");
                                        getdownloadpath({
                                          payload: remarkapi,
                                          paramid: paramid,
                                        })
                                      }
                                    }}

                                    className={'btn btn-primary p-2 py-0 me-2 ' + localStorage.getItem('currentTheme')}> <DownloadOutlined

                                    /> Download</span>
                                </div>)
                            }) : ""}
                        </div>
                        <div className="col-md-12 border rounded-end p-2 bg-light mb-2" >
                          <p className='mb-1'>Document Reference Number :</p>
                          <span>{valueModal.document_reference_number ? valueModal.document_reference_number : '---'}</span>
                        </div>

                        <div className={valueModal.compliance_frequency == 'On Occurrence' ? "col-4 border rounded-start p-2 bg-light mb-2" : "col-6 border rounded-start p-2 bg-light mb-2"}>
                          <p className="mb-1">Remarks History :</p>

                          {valueModal.history_details != 0 ?
                            <span className="btn btn-sm btn-light-success
                    text-success btn-circle d-flex align-items-center justify-content-center" style={{ marginLeft: '100px' }}>
                              <Tooltip title='Click to View Remarks History' ><InfoOutlined style={{ fontSize: '25px' }} onClick={() => { viewhistory(valueModal) }} /></Tooltip></span> : ''}
                        </div>
                        <div className={valueModal.compliance_frequency == 'On Occurrence' ? "col-4 border p-2 bg-light mb-2" : "col-6 border p-2 bg-light mb-2"}>
                          <p className="mb-1">Interim Details : </p>
                          <h5>  {valueModal.interim_details != 0 ?
                            <span className="btn btn-sm btn-light-danger
                    text-danger btn-circle d-flex align-items-center justify-content-center" style={{ marginLeft: '100px' }}>
                              <Tooltip title='Click here to View Interim Details' >
                                <ArrowUpOutlined style={{ fontSize: '25px' }} onClick={() => { viewinterim(valueModal) }} /></Tooltip></span> : ''}</h5>
                        </div>
                        {valueModal.compliance_frequency == 'On Occurrence' && valueModal.occurrence_remarks != null &&
                          <div className="col-4 border p-2 bg-light mb-2">
                            <p className="mb-1">On Occurrence Remarks : </p>
                            <h5>{valueModal.occurrence_remarks}</h5>
                          </div>
                        }

                        {/* <div className="col-md-6 border rounded-end p-2 bg-light mb-2">
                  <p className="mb-1">Remarks History</p>
                  <h5>{valueModal.remarks}</h5>

                </div> */}
                        {/* <div className="col-md-6 border rounded-start p-2 bg-light mb-2">
                  <p className="mb-1">Interim Details</p>
                  <h5> {valueModal.interim_details}</h5>
                </div> */}

                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="col-md-12 d-flex" >
                      <div>
                        <button
                          type="button"
                          className="btn btn-light-primary text-primary btn-circle btn-xl me-3 mb-1 pe-none"
                        >
                          <i
                            className="fas fa-list-alt"
                          ></i>
                        </button>
                      </div>
                      <div>
                        <p className='mb-1'>Vendor :</p>
                        <h4 className='fw-bolder'> {valueModal.unit}&nbsp;<Tooltip title={valueModal.address} >
                          <InfoCircleOutlined style={{ fontSize: '14px', color: 'blue' }} />
                        </Tooltip></h4>
                      </div>
                    </div>
                    <div className="container py-2">
                      <div className="row">
                        <div className="col-4 border rounded-start p-2 bg-light mb-2">
                          <p className="mb-1">Compliance Task :</p>
                          <h5>{valueModal.compliance_task}&nbsp;<Tooltip placement='bottom' title={valueModal.compliance_description} >
                            <InfoCircleOutlined style={{ fontSize: '12px', color: 'blue' }} />
                          </Tooltip></h5>
                        </div>
                        <div className="col-4 border p-2 bg-light mb-2">
                          <p className="mb-1">Compliance Frequency :</p>
                          <h5>{valueModal.compliance_frequency}</h5>
                        </div>
                        <div className="col-4 border rounded-end p-2 bg-light mb-2">
                          <p className="mb-1">Uploaded File Size :</p>
                          {valueModal.uploaded_document && valueModal.uploaded_document.length > 0 ? `${fileSize} MB` : '0.00 MB'}
                        </div>

                        <div className="col-md-12 border rounded-end p-2 bg-light mb-2">
                          <p className="mb-1">Task Completion Date/Document Issued Date :</p>
                          <h5>{valueModal.document_issued_date}</h5>
                        </div>


                        <div className="col-md-12 border rounded-start p-2 bg-light mb-2">
                          <p className='mb-1'>Attached Documents :</p>
                          {valueModal.uploaded_document != null ?
                            valueModal.uploaded_document.length > 0 && valueModal.uploaded_document.map((item, i) => {
                              return (
                                <div className='d-flex justify-content-between pb-1'> <h5 className='mb-0 pb-0' style={{ wordBreak: 'break-all' }}> {item}</h5>
                                  <span
                                    onClick={(e) => {
                                      console.log("trueeee");
                                      const remarkapi = [
                                        authtoken,
                                        {
                                          "session_token": authtoken,
                                          "request": [
                                            "DownloadFile",

                                            {
                                              "le_id": entityid,
                                              "c_id": valueModal.country_id ? Number(valueModal.country_id) : 1,
                                              "d_id": Number(showdata.domain),
                                              "u_id": Number(valueModal.unit_id),
                                              "start_date": valueModal.start_date,
                                              "file_name": item
                                            }
                                          ]
                                        }
                                      ]
                                      console.log(remarkapi, "trueeee123");
                                      if (entityid != "null" && entityid !== null) {
                                        console.log("trueeee123");
                                        getdownloadpath({
                                          payload: remarkapi,
                                          paramid: paramid,
                                        })
                                      }
                                    }}

                                    className={'btn btn-primary p-2 py-0 me-2 ' + localStorage.getItem('currentTheme')}> <DownloadOutlined

                                    /> Download</span>
                                </div>)
                            }) : ""}
                        </div>
                        <div className="col-md-12 border rounded-end p-2 bg-light mb-2" >
                          <p className='mb-1'>Document Reference Number :</p>
                          <span>{valueModal.document_reference_number ? valueModal.document_reference_number : '---'}</span>
                        </div>

                        <div className={valueModal.compliance_frequency == 'On Occurrence' ? "col-4 border rounded-start p-2 bg-light mb-2" : "col-6 border rounded-start p-2 bg-light mb-2"}>
                          <p className="mb-1">Remarks History :</p>

                          {valueModal.history_details != 0 ?
                            <span className="btn btn-sm btn-light-success
                        text-success btn-circle d-flex align-items-center justify-content-center" style={{ marginLeft: '100px' }}>
                              <Tooltip title='Click to View Remarks History' ><InfoOutlined style={{ fontSize: '25px' }} onClick={() => { viewhistory(valueModal) }} /></Tooltip></span> : ''}
                        </div>
                        <div className={valueModal.compliance_frequency == 'On Occurrence' ? "col-4 border p-2 bg-light mb-2" : "col-6 border p-2 bg-light mb-2"}>
                          <p className="mb-1">Interim Details : </p>
                          <h5>  {valueModal.interim_details != 0 ?
                            <span className="btn btn-sm btn-light-danger
                        text-danger btn-circle d-flex align-items-center justify-content-center" style={{ marginLeft: '100px' }}>
                              <Tooltip title='Click here to View Interim Details' >
                                <ArrowUpOutlined style={{ fontSize: '25px' }} onClick={() => { viewinterim(valueModal) }} /></Tooltip></span> : ''}</h5>
                        </div>
                        {valueModal.compliance_frequency == 'On Occurrence' && valueModal.occurrence_remarks != null &&
                          <div className="col-4 border p-2 bg-light mb-2">
                            <p className="mb-1">On Occurrence Remarks : </p>
                            <h5>{valueModal.occurrence_remarks}</h5>
                          </div>
                        }

                        {/* <div className="col-md-6 border rounded-end p-2 bg-light mb-2">
                      <p className="mb-1">Remarks History</p>
                      <h5>{valueModal.remarks}</h5>

                    </div> */}
                        {/* <div className="col-md-6 border rounded-start p-2 bg-light mb-2">
                      <p className="mb-1">Interim Details</p>
                      <h5> {valueModal.interim_details}</h5>
                    </div> */}

                      </div>
                    </div>
                  </>}

              </Modal>
              <Modal
                autoFocus={false}
                open={passwordmodal}
                footer={null}
                onCancel={passwordcancel}
                className="newStyleModalPassword modal-service-priv"
                maskClosable={false}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-6">
                      <label className="control-label">Password:</label>
                      <span style={{ color: "red" }}>*</span>
                      <input
                        ref={serviceProviderRef1}
                        autoFocus={true}
                        style={{ marginTop: "10px" }}
                        type="password"
                        id="password"
                        name="passwordvalue"
                        className="form-control"
                        placeholder="Enter password"
                        maxLength={20}
                        onChange={(e) => {
                          Setpassword({
                            ...password,
                            passwordvalue: e.target.value,
                          });
                        }}
                        value={password.passwordvalue}
                      />
                      {formValidator2.current.message(
                        "Password",
                        password.passwordvalue,
                        ["required"],
                        {
                          className: `invalid-feedback ${AddFormSubmit2 ? "show" : "hide"
                            }`,
                          messages: {
                            required: "Password Required",
                          },
                        }
                      )}
                    </div>
                  </div>
                </div>
                <br />
                <div className={"form-actions text-center popupbtncolour " + localStorage.getItem('currentTheme')}>
                  <Button
                    type="primary"
                    shape="round"
                    className={'addbutton ' + localStorage.getItem('currentTheme')}
                    icon={<PlayCircleOutlined />}
                    size="default"
                    onClick={submitpassword}
                  >
                    Submit
                  </Button>
                  {/* <div className="button2 mt-0" id={localStorage.getItem('currentTheme')} onClick={submitpassword}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        Submit
                                    </div> */}
                  {/* <button
                                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start" onClick={passwordcancel}>
                                        <div className="d-flex align-items-center">
                                        <i data-feather="save" className="feather-sm me-1 fill-icon"></i>
                                        Cancel
                                        </div>
                                    </button> */}
                </div>
              </Modal>

              <Modal open={historymodal}
                title="Remarks History"
                className={
                  "add-service-prv " +
                  localStorage.getItem("currentTheme")
                }
                footer={null}
                onCancel={historycancel} maskClosable={false}>
                {/* <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                  columns={history_columns}
                  dataSource={historydata && historydata.remarks_history}
                  pagination={false}
                /> */}
                <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                  <div className='ant-table ant-table-bordered'>
                    <div className='ant-table-container'>
                      <table>
                        <colgroup>
                          <col style={{ width: '10px' }}></col>
                        </colgroup>
                        <thead className="ant-table-thead " >
                          <tr>
                            <th className={'ant-table-cell'} style={{ width: '40px' }}>#</th>
                            <th className={'ant-table-cell'} style={{ width: '100px' }}>Activity Date</th>
                            <th className={'ant-table-cell'} style={{ width: '100px' }}>User Type</th>
                            <th className={'ant-table-cell'} style={{ width: '300px' }}>Reviewer Report</th>
                            <th className={'ant-table-cell'} style={{ width: '100px' }}>Remarks</th>
                          </tr>
                        </thead>
                        <tbody className='ant-table-tbody'>
                          {historydata && historydata.remarks_history && historydata.remarks_history.length > 0 && historydata.remarks_history.map((item, i) => {
                            console.log(item, 'itemitemitem');
                            let splittedDocs;
                            if (item.documents != null) {
                              let doc = item.documents;
                              splittedDocs = doc.split('||')
                            }
                            console.log(splittedDocs, 'splittedDocs');
                            return <tr>
                              <td className='ant-table-cell'>
                                <center>
                                  {i + 1}
                                </center>
                              </td>
                              <td><center>{item.activity_on}</center></td>
                              <td><center>{((item.user_category_id == 5 || item.user_category_id == 6) ? 'Assignee' : ((item.user_category_id == 3 || item.user_category_id == 4) && (item.action == 'Rectified' || item.action == 'Concurrence Rejected' || item.action == 'Concurred')) ? 'Concurrer' : (item.user_category_id == 1 && (item.action == 'RectifyApproval' || item.action == 'Approval Rejected' || item.action == 'Approved') ||
                                (item.user_category_id == 3 || item.user_category_id == 4) && (item.action == 'Approval Rejected' || item.action == 'RectifyApproval' || item.action == 'Approved') ? 'Approver' : ''))}</center></td>
                              <td>{splittedDocs && splittedDocs.length > 0 ?
                                splittedDocs.map((items, l) => {
                                  return <tr><a href="javascript:;" style={{ wordBreak: 'break-all' }} onClick={() => {
                                    const remarkapi = [
                                      authtoken,
                                      {
                                        "session_token": authtoken,
                                        "request": [
                                          "DownloadFile",

                                          {
                                            "le_id": entityid,
                                            "c_id": valueModal.country_id ? Number(valueModal.country_id) : 1,
                                            "d_id": Number(showdata.domain),
                                            "u_id": Number(valueModal.unit_id),
                                            "start_date": valueModal.start_date,
                                            "file_name": items
                                          }
                                        ]
                                      }
                                    ]
                                    console.log(remarkapi, "trueeee123");
                                    if (entityid != "null" && entityid !== null) {
                                      console.log("trueeee123");
                                      getdownloadpath({
                                        payload: remarkapi,
                                        paramid: paramid,
                                      })
                                    }
                                  }}>{items}</a></tr>
                                }) : ''}</td>
                              <td><center>{item.remarks}</center></td>


                            </tr>
                          })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal open={interimmodal}
                title="Interim Details"
                className={
                  "add-service-prv " +
                  localStorage.getItem("currentTheme")
                }
                footer={null}
                onCancel={interimcancel} maskClosable={false}>
                <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                  columns={interim_columns}
                  dataSource={interimdata && interimdata}
                  pagination={false}
                />
              </Modal>
            </div>
          </div>
        </div>
        {acccordionTrue === true &&
          temp &&
          temp.length > 0 ? (
          <>
            <Stickicon />
          </>
        ) : (
          false
        )}

      </div>


    </div>
  );
};

export default connect(mapStateToProps, {
  TaskRecallFilters,
  GetTaskRecallList,
  GetTaskRecallListShowmore,
  GetRemarksData,
  UpdateTaskRecallStatus,
  reassignlist,
  historyrecord, interimrecord, getdownloadpath
})(TaskRecall);
