import React, { Fragment } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Pagination } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { Assigneescorecardfilter, Assigneescoretabledata } from '../../../Store/Action/Report/Assigneescorecard';
import { connect } from 'react-redux';
import { ExclamationCircleTwoTone, EyeOutlined, PlayCircleOutlined, FullscreenOutlined, UpOutlined, UpCircleOutlined, FilterOutlined } from '@ant-design/icons';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import ScrollButton from '../../Scrollbar';
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    domain_data: state.Assigneescorecard.assigneescoredata.domain_data,
    tableData: state.Assigneescorecard.assigneescoredata.getTableData
})

const Assigneescorecard = ({
    Assigneescorecardfilter,
    Assigneescoretabledata,
    domain_data,
    tableData
}) => {

    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [tableshow, setTableShow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    const [datasource, setDatasource] = useState([])
    const [type, setType] = useState('')

    const [filteredArray, setFilteredArray] = useState([])
    const [finaltable, setFinaltable] = useState([])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [legalstate, Setlegalstate] = useState(false)

    const [legalEntityScoreCard, setLegalEntityScoreCard] = useState({
        country: '',
        countryName: '',
        legel_entity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        le_id: '',
        domain: '',
        domainName: ''
    })
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);

    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    const columns = [
        {
            title: 'Vendors',
            // ellipsis: true,
            width: '50px',
            dataIndex: 'unit',
            key: 'unit',
        },
        {
            title: type === 'completed' ? 'You Submitted' : 'Yet to Submit',
            // ellipsis: true,
            width: '50px',
            align: 'center',
            dataIndex: type === 'completed' ? 'c_assignee' : type === 'inprogress' ? 'inp_assignee' : 'ov_assignee',
            key: 'c_assignee',
        },
        {
            title: type === 'completed' ? 'You Concurred' : 'Yet to Concur',
            // ellipsis: true,
            width: '50px',
            align: 'center',
            dataIndex: type === 'completed' ? 'c_concur' : type === 'inprogress' ? 'inp_concur' : 'ov_concur',
            key: 'c_concur',
        },
        {
            title: type === 'completed' ? 'You Approved' : 'Yet to Approve',
            width: '50px',
            // ellipsis: true,
            align: 'center',
            dataIndex: type === 'completed' ? 'c_approver' : type === 'inprogress' ? 'inp_approver' : 'ov_approver',
            key: 'c_approver',
        },

    ];

    const showModal = () => {

        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onshow = () => {
        setExitCollapse(true);
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetWorkFlowScoreCard",
                        {
                            "c_id": Number(legalEntityScoreCard.country),
                            "le_id": entityid,
                            "d_id": Number(legalEntityScoreCard.domain),
                            "csv": false
                        }
                    ]
                }
            ]

            Assigneescoretabledata({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const onChange = (key) => {
        console.log(key);
    };

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (legalEntityScoreCard.legel_entity == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [legalEntityScoreCard.legel_entity])

    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {

                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetWorkFlowScoreCardFilters",
                            {
                                "le_id": entityid
                            }
                        ]
                    }
                ]
                if (entityid != 'null') {

                    Assigneescorecardfilter({
                        payload: payload,
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }

    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(legalEntityScoreCard.le_id)
        }
    }, [entityid])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            setLegalEntityScoreCard({
                ...legalEntityScoreCard,
                le_id: localStorage.getItem('SelectedEntityid')
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (tableData && tableData.work_flow_score_card_list && tableData.work_flow_score_card_list.length > 0) {
            setFilteredArray(tableData.work_flow_score_card_list)
        }
    }, [tableData.work_flow_score_card_list])
    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            if (type === 'completed') {
                setFinaltable(filteredArray[0].completed_task_count)

            } else if (type === 'inprogress') {
                // setFinaltable(filteredArray[0].inprogress_within_duedate_task_count) Parameter Changed Confirmation by Sakthivel
                setFinaltable(filteredArray[0].inprogress_within_due_task_count)
            } else {
                if (type === 'overdue')
                    setFinaltable(filteredArray[0].over_due_task_count)
            }

        }
    }, [filteredArray, type])
    useEffect(() => {
        if (finaltable && finaltable.length > 0) {
            setDatasource(finaltable)
        }

    }, [finaltable])
    useEffect(() => {
        if (finaltable && finaltable.length > 0) {
            if (type === 'completed') {
                const newObj = {
                    unit: <b>Total</b>,
                    c_assignee: <b>{filteredArray[0].c_assignee}</b>,
                    c_concur: <b>{filteredArray[0].c_concur}</b>,
                    c_approver: <b>{filteredArray[0].c_approver}</b>,
                }
                setDatasource([...finaltable, newObj])
            } else if (type === 'inprogress') {
                const newObj = {
                    unit: <b>Total</b>,
                    inp_assignee: <b>{filteredArray[0].inp_assignee}</b>,
                    inp_concur: <b>{filteredArray[0].inp_concur}</b>,
                    inp_approver: <b>{filteredArray[0].inp_approver}</b>
                }
                setDatasource([...finaltable, newObj])
            } else {
                const newObj = {
                    unit: <b>Total</b>,
                    ov_assignee: <b>{filteredArray[0].ov_assignee}</b>,
                    ov_concur: <b>{filteredArray[0].ov_concur}</b>,
                    ov_approver: <b>{filteredArray[0].ov_approver}</b>
                }
                setDatasource([...finaltable, newObj])
            }
        }
    }, [finaltable, type])

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
     window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }
    return (
        <Fragment>
            <div id='page-wrapper' className="page-wrapper">
                <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                {document.body.scrollHeight > document.body.clientHeight ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''}
                <div className="page-titles pb-0 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Score Cards</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Assignee Score Card</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-4 text-end">
                           <Tooltip title='Advance Filter'></Tooltip> <Button
                                className="mx-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button>
                            <button style={{ float: 'right', marginTop: '5px' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <Modal className={localStorage.getItem('currentTheme') + " add-service-prv                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             "} title={type === 'completed' ? 'Vendors Wise - Completed Task Count' : type === 'inprogress' ? 'User Wise - In Progress Within Due Date Task Count' : 'User Wise - In Progress Over Due Task Count'} visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} maskClosable={false}>
                    <div className={localStorage.getItem('currentTheme')}>
                        <Table
                            className='userprivclass'
                            columns={columns}
                            dataSource={datasource}
                            bordered
                            pagination={false} />
                    </div>
                </Modal>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" id='only-restricted' style={{ padding: "10px 3px" }}>
                                <div className={localStorage.getItem('currentTheme')}>
                                    <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onChange} style={{ display: exitCollapse ? "none" : "block", marginLeft: '10%', marginRight: '10%' }}>
                                        <Panel header="Assignee Score Card" key={key} >
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className='col-md-4'>
                                                        <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                <div className="form-group">
                                                                    <Select
                                                                        allowClear={true}
                                                                        size="default"
                                                                        placeholder="Enter Country"
                                                                        onChange={(value, data) => {

                                                                            setLegalEntityScoreCard({
                                                                                ...legalEntityScoreCard,
                                                                                country: value != undefined ? value : '',
                                                                                countryName: data != undefined ? data.children : '',
                                                                                legel_entity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                                le_id: '',
                                                                                domain: '',
                                                                                domainName: ''
                                                                            })
                                                                        }}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        //value={Settings.le_id}
                                                                        style={{ width: '100%' }}
                                                                        value={legalEntityScoreCard.countryName || undefined}
                                                                    >
                                                                        {country_info && country_info.length && country_info.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.c_id}>
                                                                                    {item.c_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'countryName',
                                                                        legalEntityScoreCard.countryName,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Country Required',
                                                                            }
                                                                        })}
                                                                </div> :
                                                                <p>{sessionArr[0].c_name}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 text-wrap-report'>
                                                        <label><b>Legal Entity : </b>  <span style={{ color: "red" }}>*</span></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Legal Entity"
                                                                    onChange={(data, value) => {
                                                                        Setlegalstate(true)
                                                                        setCurrentEntity(data)
                                                                        setLegalEntityScoreCard({
                                                                            ...legalEntityScoreCard,
                                                                            le_id: data != undefined ? data : '',
                                                                            legel_entity: value != undefined ? value.children : '',
                                                                            domain: '',
                                                                            domainName: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                                    style={{ width: '100%' }}
                                                                    value={legalEntityScoreCard.legel_entity || undefined}
                                                                    disabled={legalEntityScoreCard.country == '' ? true : false}
                                                                >
                                                                    {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.le_id}>
                                                                                {item.le_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'legalentity',
                                                                    legalEntityScoreCard.legel_entity,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Legal Entity Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{localStorage.getItem('SelectedEntity')}</p>
                                                        }
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Domain : </b>  <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                onChange={(value, data) => {
                                                                    setLegalEntityScoreCard({
                                                                        ...legalEntityScoreCard,
                                                                        domain: value != undefined ? value : '',
                                                                        domainName: data != undefined ? data.children : ''
                                                                    })
                                                                }}
                                                                placeholder="Enter Domain"
                                                                style={{ width: '100%' }}
                                                                value={legalEntityScoreCard.domainName || undefined}
                                                                disabled={legalEntityScoreCard.legel_entity == '' ? true : false}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                            >
                                                                {domain_data && domain_data.length && domain_data.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.d_id}>
                                                                            {item.d_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                        {validator.current.message(
                                                            'domain',
                                                            legalEntityScoreCard.domain,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Domain Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    {/* <div className='col-md-4'></div>
                        <div className='col-md-4'></div> */}
                                                    <div className={'col-md-4 popupbtncolour ' + localStorage.getItem('currentTheme')} style={{ marginLeft: '35%' }}>
                                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginLeft: "35%" }}
                                                            icon={<EyeOutlined />} onClick={onshow}
                                                        >
                                                            Show
                                                        </Button>

                                                    </div>
                                                </div>
                                            </div>


                                        </Panel>
                                    </Collapse>

                                    <Modal
                                        onCancel={setcancelFilter}
                                        footer={false}
                                        title="Vendors List"
                                        className={
                                            "add-service-prv cons-report " +
                                            localStorage.getItem("currentTheme")
                                        }
                                        open={filterModalVisible}
                                        maskClosable={false}
                                    >
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Country"
                                                                    onChange={(value, data) => {

                                                                        setLegalEntityScoreCard({
                                                                            ...legalEntityScoreCard,
                                                                            country: value != undefined ? value : '',
                                                                            countryName: data != undefined ? data.children : '',
                                                                            legel_entity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                            le_id: '',
                                                                            domain: '',
                                                                            domainName: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    //value={Settings.le_id}
                                                                    style={{ width: '100%' }}
                                                                    value={legalEntityScoreCard.countryName || undefined}
                                                                >
                                                                    {country_info && country_info.length && country_info.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'countryName',
                                                                    legalEntityScoreCard.countryName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{sessionArr[0].c_name}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4 text-wrap-report'>
                                                    <label><b>Legal Entity:</b>  <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Legal Entity"
                                                                onChange={(data, value) => {
                                                                    Setlegalstate(true)
                                                                    setCurrentEntity(data)
                                                                    setLegalEntityScoreCard({
                                                                        ...legalEntityScoreCard,
                                                                        le_id: data != undefined ? data : '',
                                                                        legel_entity: value != undefined ? value.children : '',
                                                                        domain: '',
                                                                        domainName: ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                                style={{ width: '100%' }}
                                                                value={legalEntityScoreCard.legel_entity || undefined}
                                                                disabled={legalEntityScoreCard.country == '' ? true : false}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                legalEntityScoreCard.legel_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain:</b>  <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            onChange={(value, data) => {
                                                                setLegalEntityScoreCard({
                                                                    ...legalEntityScoreCard,
                                                                    domain: value != undefined ? value : '',
                                                                    domainName: data != undefined ? data.children : ''
                                                                })
                                                            }}
                                                            placeholder="Enter Domain"
                                                            style={{ width: '100%' }}
                                                            value={legalEntityScoreCard.domainName || undefined}
                                                            disabled={legalEntityScoreCard.legel_entity == '' ? true : false}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                        >
                                                            {domain_data && domain_data.length && domain_data.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                    {validator.current.message(
                                                        'domain',
                                                        legalEntityScoreCard.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                {/* <div className='col-md-4'></div>
<div className='col-md-4'></div> */}
                                                <div className={'col-md-4 popupbtncolour ' + localStorage.getItem('currentTheme')} style={{ marginLeft: '35%' }}>
                                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginLeft: "35%" }}
                                                        icon={<EyeOutlined />} onClick={onshow}
                                                    >
                                                        Show
                                                    </Button>

                                                </div>
                                            </div>
                                        </div>


                                    </Modal>
                                </div>

                                {tableshow == true ?
                                    <Card className='rounded-3'>
                                        <div >
                                            <div className='col-md-12 mt-1 mb-1' style={{ marginLeft: '1%' }} >
                                                <div className='row'>
                                                    <div className='col-md-5 text-wrap-report'>
                                                        <label><b>Legal Entity :</b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? legalEntityScoreCard.legel_entity : localStorage.getItem('SelectedEntity')}</label>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label><b>Country :</b> {sessionArr && sessionArr[0].c_name}</label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Domain :</b> {legalEntityScoreCard.domainName}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={localStorage.getItem('currentTheme')}>
                                                <div className='ant-table-wrapper'>
                                                    <div className='ant-table ant-table-bordered'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content scorecardtable'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className="ant-table-thead antable-head">
                                                                        <tr>
                                                                            <th className='ant-table-cell w10px'>#</th>
                                                                            <th className='ant-table-cell custom-width1'>Task Status</th>
                                                                            <th className='ant-table-cell custom-width1'>Assignee</th>
                                                                            <th className='ant-table-cell custom-width1'><center>Concur</center></th>
                                                                            <th className='ant-table-cell custom-width1'>Approver</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center>1</center></td>
                                                                            <td className='ant-table-cell'><center><Link type="primary"
                                                                                onClick={() => {
                                                                                    setType('completed')
                                                                                    showModal()
                                                                                }}
                                                                            >Completed</Link></center></td>
                                                                            <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_assignee}</center></td>
                                                                            <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_concur}</center></td>
                                                                            <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_approver}</center></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center>2</center></td>
                                                                            <td className='ant-table-cell'><center><Link type="primary"
                                                                                onClick={() => {
                                                                                    setType('inprogress')
                                                                                    showModal()

                                                                                }}
                                                                            >In progress within due date</Link></center></td>
                                                                            <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].inp_assignee}</center></td>
                                                                            <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].inp_concur}</center></td>
                                                                            <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].inp_approver}</center></td>

                                                                        </tr>
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center>3</center></td>
                                                                            <td className='ant-table-cell'><center><Link type="primary"
                                                                                onClick={() => {
                                                                                    setType('overdue')
                                                                                    showModal()
                                                                                }}
                                                                            >In progress over due</Link></center></td>
                                                                            <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].ov_assignee}</center></td>
                                                                            <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].ov_concur}</center></td>
                                                                            <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].ov_approver}</center></td>

                                                                        </tr>
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center></center></td>
                                                                            <td className='ant-table-cell'><center><b>Total</b></center></td>
                                                                            <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_assignee + filteredArray[0].inp_assignee + filteredArray[0].ov_assignee}</center></td>
                                                                            <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_concur + filteredArray[0].inp_concur + filteredArray[0].ov_concur}</center></td>
                                                                            <td className='ant-table-cell'><center>{filteredArray && filteredArray.length > 0 && filteredArray[0].c_approver + filteredArray[0].inp_approver + filteredArray[0].ov_approver}</center></td>
                                                                        </tr>
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    : ''
                                    //   <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default connect(mapStateToProps, {
    Assigneescorecardfilter,
    Assigneescoretabledata
})(Assigneescorecard);