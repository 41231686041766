import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom'
import { Modal, Input, Table, Tooltip, Descriptions } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import DataTable from "react-data-table-component";
import { notificationshowall, updatenotification, updatenotificationnew } from '../../Store/Action/Login';
import { Toaster } from '../../Libs/Toaster';
import { PlusSquareTwoTone, LikeOutlined, DislikeOutlined, PoweroffOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import url, { getUrl } from './../../Libs/URL'
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()
const urls = getUrl()

const mapStateToProps = (state) => ({
    lists: state.Login.notificationshowall.reminders,
    modal: state.Login.notificationmodal.notification_details

})
const Reminders = ({
    notificationshowall,
    lists,
    modal,
    updatenotificationnew
}) => {
    const historynew = useHistory();
    console.log(lists, 'listslists');
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    // const [remark, setRemark] = useState()
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [modalVisible, setModaVisible] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const { Search } = Input;
    const header = paramid.split('/');
    var breadcrumb = '';
    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const [showModal, setshowModal] = useState(false);
    var type = '';
    if (header[1] == 'reminders') {
        breadcrumb = 'Remainders';
        type = 2;
    } else if (header[1] == 'notifications') {
        breadcrumb = 'Notifications';
    } else if (header[1] == 'messages') {
        breadcrumb = 'Messages';
        type = 4;
    } else if (header[1] == 'escalations') {
        breadcrumb = 'Escalations';
        type = 3;
    }

    const [modalData, setmodalData] = useState({
        act_name: '',
        unit: '',
        compliance: '',
        due_date: '',
        delayed_by: '',
        assignee: '',
        concurrence: '',
        approval: '',
    })


    useEffect(() => {
        if (isAuth && modal) {
            if (modal && modal.length > 0) {
                setmodalData({
                    ...modalData,
                    act_name: modal[0].act_name,
                    unit: modal[0].unit,
                    compliance: modal[0].compliance_name,
                    due_date: modal[0].due_date,
                    delayed_by: modal[0].delayed_by,
                    assignee: modal[0].assignee_name,
                    concurrence: modal[0].concurrer_name,
                    approval: modal[0].approver_name

                })
            }
        }
    }, [isAuth, modal])

    const handleCancel = () => {
        setshowModal(false)
        setmodalData(false);
        let payload;

        if (legalid != 'null') {
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 2,
                            "start_count": 0,
                            "end_count": 50
                        }
                    ]
                }
            ]
        } else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 2,
                            "start_count": 0,
                            "end_count": 50
                        }
                    ]
                }
            ]
        }



        notificationshowall({
            payload: payload,
            caller: paramid
        })
    }

    const downloadreport = (e, record) => {
        window.open(urls + '/' + e);
        markread(record)

    }

    const markread = (record, status) => {
        if (status == 'modal') {
            setshowModal(true);
            let notifypayload;
            if (authtoken && legalid && legalid != "null") {
                notifypayload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateNotificationStatus",
                            {
                                "le_ids": [
                                    legalid
                                ],
                                "notification_id": record.notification_id,
                                "has_read": true,
                                "extra_details": record.extra_details
                            }
                        ]
                    }
                ]

            } else {
                let temp = [];
                for (let i = 0; i < callername.entity_info.length; i++) {
                    temp.push(callername.entity_info[i].le_id);
                }
                notifypayload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateNotificationStatus",
                            {
                                "le_ids": temp,
                                "notification_id": record.notification_id,
                                "has_read": true,
                                "extra_details": record.extra_details
                            }
                        ]
                    }
                ]
            }
            updatenotificationnew({
                payload: notifypayload,
                paramid: paramid
            })
            setmodalData({
                ...modalData,
                act_name: '',
                unit: '',
                compliance: '',
                due_date: '',
                delayed_by: '',
                assignee: '',
                concurrence: '',
                approval: '',
            })
        } else {
            let notifypayload;
            if (authtoken && legalid && legalid != "null") {
                notifypayload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateNotificationStatus",
                            {
                                "le_ids": [
                                    legalid
                                ],
                                "notification_id": record.notification_id,
                                "has_read": true,
                                "extra_details": record.extra_details
                            }
                        ]
                    }
                ]

            } else {
                let temp = [];
                for (let i = 0; i < callername.entity_info.length; i++) {
                    temp.push(callername.entity_info[i].le_id);
                }
                notifypayload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateNotificationStatus",
                            {
                                "le_ids": temp,
                                "notification_id": record.notification_id,
                                "has_read": true,
                                "extra_details": record.extra_details
                            }
                        ]
                    }
                ]
            }
            updatenotificationnew({
                payload: notifypayload,
                paramid: paramid
            })
        }

    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_on',
            key: 'created_on',
            width: '20px'

        },
        {
            title: 'Title',
            dataIndex: 'notification_text',
            key: 'notification_text',
            width: '300px',
            // render: (text, record) => {
            //     return <span className='pointer' >{record.notification_text}{record.extra_details ? <a href="javascript:;" onClick={(e) => { markread(record) }}> here</a> : ''}</span>
            // }
            render: (text, record) => {
                console.log(record, "recordrecordrecord");
                var link = (record.extra_details).trim();
                link = (link).trim();
                if (Number.isInteger(parseInt(link.substring(0, 1)))) {
                    return <span className='pointer' onClick={(e) => { markread(record, 'modal') }}>{record.notification_text}</span>

                }
                else {
                    if (record.extra_details == 'Remainder For LE') {

                        return <span className='pointer' onClick={(e) => { markread(record, '') }}>{record.notification_text}</span>
                    } else if (record.notification_text.toLowerCase().indexOf("you can download documents") == -1) {

                        return <span className='pointer'><span>{record.notification_text}</span><Link onClick={(e) => { downloadreport(record.extra_details, record) }}> you can download documents here</Link></span>
                    }
                    else {
                        return <span className='pointer'><span>{record.notification_text}</span><Link onClick={(e) => { downloadreport(record.extra_details, record) }}>here</Link></span>
                        // <span>{record.notification_text}</span>

                        // <a href="javascript:;" onClick={(e) => { markread(record) }}> here</a>
                    }
                }
            }

        },
    ];

    useEffect(() => {
        if (isAuth) {
            let payload;
            if (legalid != 'null') {
                payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetNotifications",
                            {
                                "le_ids": [
                                    legalid
                                ],
                                "notification_type": 2,
                                "start_count": 0,
                                "end_count": 50
                            }
                        ]
                    }
                ]
            } else {
                let temp = [];
                for (let i = 0; i < callername.entity_info.length; i++) {
                    temp.push(callername.entity_info[i].le_id);
                }
                payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetNotifications",
                            {
                                "le_ids": temp,
                                "notification_type": 2,
                                "start_count": 0,
                                "end_count": 50
                            }
                        ]
                    }
                ]
            }



            notificationshowall({
                payload: payload,
                caller: paramid
            })

        }
    }, [isAuth])

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
        window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    return (
        <Fragment>
            <div>

                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Notification</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span>Remainders</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">

                                    <div className="card-body" style={{ padding: '0px' }}>
                                        <div className={localStorage.getItem("currentTheme")}>
                                            <Table
                                                className='userprivclass'
                                                columns={columns}
                                                dataSource={lists}
                                                bordered
                                                pagination={{
                                                    defaultPageSize: dataTableProperties.pagesize,
                                                    showSizeChanger: dataTableProperties.sizechanger
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
                <Modal title="Messages" className={" add-service-prv " + localStorage.getItem('currentTheme')} open={showModal} footer={false} onCancel={handleCancel} maskClosable={false}>
                    <div className="row">

                        <div className='col-md-4 border-endspan'>
                            <span>Act</span>
                            <h4 className='fw-bolder'>{modalData && modalData.act_name}</h4>
                        </div>
                        <div className='col-md-4'>
                            <span>Unit</span>
                            <h4 className='fw-bolder'>{modalData && modalData.unit}</h4>
                        </div>

                        <div className='col-md-4  '>
                            <span>Compliance</span>
                            <h4 className='fw-bolder'>{modalData && modalData.compliance}</h4>
                        </div>


                        <div className='col-md-4 bg-light border-end '>
                            <span>Due Date</span>
                            <h4 className='fw-bolder'>{modalData && modalData.due_date}</h4>
                        </div>
                        <div className='col-md-4 bg-light border-end '>
                            <span>Delayed By</span>
                            <h4 className='fw-bolder'>{modalData && modalData.delayed_by}</h4>
                        </div>
                        <div className='col-md-4 bg-light '>
                            <span>Assignee</span>
                            <h4 className='fw-bolder'>{modalData && modalData.assignee}</h4>
                        </div>


                        <div className='col-md-4  border-end'>
                            <span>Concurrence</span>
                            <h4 className='fw-bolder'>{modalData && modalData.concurrence}</h4>
                        </div>
                        <div className='col-md-4 border-end'>
                            <span>Approval</span>
                            <h4 className='fw-bolder'>{modalData && modalData.approval}</h4>
                        </div>
                    </div>
                    {/* <Descriptions layout="vertical" className='labelclass'>
                        <Descriptions.Item label="Act">{modalData && modalData.act_name}</Descriptions.Item>
                        <Descriptions.Item label="Unit">{modalData && modalData.unit}</Descriptions.Item>
                        <Descriptions.Item label="Compliance">{modalData && modalData.compliance}</Descriptions.Item>
                        <Descriptions.Item label="Due Date">{modalData && modalData.due_date}</Descriptions.Item>
                        <Descriptions.Item label="Delayed By">{modalData && modalData.delayed_by}</Descriptions.Item>
                        <Descriptions.Item label="Assignee">
                            {modalData && modalData.assignee}
                        </Descriptions.Item>
                        <Descriptions.Item label="Concurrence">
                            {modalData && modalData.concurrence}
                        </Descriptions.Item>
                        <Descriptions.Item label="Approval">
                            {modalData && modalData.approval}
                        </Descriptions.Item>
                    </Descriptions> */}
                </Modal>

            </div>

        </Fragment>
    )
}
export default connect(mapStateToProps, {
    notificationshowall,
    updatenotificationnew
})(Reminders);