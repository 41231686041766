import React, { Fragment, useState, useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { message, Modal, Tooltip, Card, Button, Input } from 'antd';
import { sessionLogout, notifications, Escalations, remainder, Message, Loginvendor, LoginLitigation, LoginCompfieproduct, loginOtp, resendOTP } from './../Store/Action/Login';
import { useHistory, Link, useLocation, Redirect, NavLink } from "react-router-dom";
import { CloseCircleOutlined, LogoutOutlined, ExclamationCircleTwoTone, CheckCircleOutlined, PlayCircleOutlined, HistoryOutlined } from '@ant-design/icons'
import { changeTheme } from '../Store/Action/Dashboard/header';
import SimpleReactValidator from "simple-react-validator";
import ReactTooltip from "react-tooltip";
import { Toaster } from '../Libs/Toaster';
import { Updatepassword, UpdatepasswordLogin } from './../../src/Store/Action/Profile';
import { validateInput } from '../Libs/inputValidation';
import { validationType } from '../Libs/inputValidation';
import { TOGGLE_COMMON_LOADER } from '../Store/types';
import { useSelector } from 'react-redux';


const _ = require("lodash");


const mapStateToProps = (state) => ({
    notify: state.Login.notifications,
    escalation: state.Login.escalations,
    remainderr: state.Login.remainder,
    messages: state.Login.message,
    loginvendor: state.Login.loginvendor,
    // theme : state.changeTheme.current_theme
});

const Header = ({
    sessionLogout,
    notifications,
    loginOtp,
    resendOTP,
    Escalations,
    changeTheme,
    notify,
    escalation,
    remainder, Message, remainderr, messages,
    Loginvendor,
    LoginLitigation,
    LoginCompfieproduct,
    UpdatepasswordLogin
}) => {

    const isAuth = localStorage.getItem('isAuthenticated');
    const history = useHistory();
    const [entityinfo, Setentityinfo] = useState();
    const [otpModal, setOtpmodal] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPasswordModal, setIsPasswordModal] = useState(false);
    const isIpsetting = useSelector((state) => state.Login.isIpsetting)

    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const vendorlogo = localStorage.getItem('isVendor')
    const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
    const sessionArray = sessionValue.login_response
    const entity_info = sessionValue.entity_info
    const dispatch = useDispatch()
    const country_info = sessionValue.country_info
    const [notificationdata, Setnotificationdata] = useState();
    const [escalationdata, Setescalationdata] = useState();
    const [reminderdata, Setreminderdata] = useState();
    const [formurl, Setformurl] = useState();
    // const otpinp = useRef();
    const [settingurl, setSettingUrl] = useState()
    const [themesUrl, setThemesUrl] = useState()
    const [userGuide, setUserGuide] = useState(false)
    const [changepassword, Setchangepassword] = useState();
    const [messagedata, Setmessagedata] = useState();
    const [modalType, setModalType] = useState('Product')
    const [notificationValue, SetnotificationValue] = useState({
        remainderValue: '',
        statutoryNotificationValue: '',
        escalationValue: '',
        messagesValue: '',
    });
    const [userId, SetUserId] = useState(JSON.parse((localStorage.getItem('sessionValue'))).login_response.usr_id);
    const authtoken = localStorage.getItem('authToken');

    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const login = callername.login_response.menu.Static && callername.login_response.menu.Static[0];

    const getUrl = callername.login_response.menu['My Accounts']
    const [valueIn, setValueIn] = useState('');
    const location = useLocation();
    // const paramid = location.pathname;
    const [isModalVisibleVendor, setIsModalVisibleVendor] = useState(false);
    const [otp, setOtp] = useState()

    const [openThemeModalPopup, setOpenThemeModalPopup] = useState(false);
    const [closeTrue, setCloseTrue] = useState(false);
    const [paramid, setParamId] = useState(location.pathname)
    const [sidebar, setSidebar] = useState()
    const [defaultTime, setDefaultTime] = useState(60)
    const [resendOtpcount, setResendOtpcount] = useState(0)
    const [isDashboard, setIsDashboard] = useState(false)
    console.log(isDashboard, 'isDashboard');
    let time = sessionArray && sessionArray.last_seen

    var timeValue = time.substring(0, time.length - 3);

    var currentTime = timeValue.replace("-", "") + " UTC"

    var Day = currentTime.split("/")

    var currentDayTime = new Date(Day[1] + "/" + Day[0] + "/" + Day[2]);
    // var currentDayTime = new Date('04/18/23 13:28 PM UTC');     



    useEffect(() => {
        const body = document.getElementsByTagName('body')[0]
        if (sidebar) {
            body.classList.add('mini-sidebar')
            return
        }
        body.classList.remove('mini-sidebar')
    },)

    useEffect(() => {
        let text = localStorage.getItem('CurrentPath')
        if (text) {
            let splittedText = text.split('/')
            if (splittedText.includes('dashboard') === true) {
                setIsDashboard(true)
            }
            else {
                setIsDashboard(false)
            }
        }

    }, [localStorage.getItem('CurrentPath')])

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0]
        const pathname = (location.pathname).split("/")
        if (pathname[1] == 'cv') {
            body.classList.add(pathname[2])
            return () => body.classList.remove(pathname[2])

        } else {
            body.classList.add(pathname[1])
            return () => body.classList.remove(pathname[1])

        }


    }, [location])

    // useEffect(() => {
    //     otpinp.current.focus();
    //     }, []);
    const [otpmessage, setotpmessage] = useState(0);
    const submitOtp = (e) => {
        e.preventDefault();

        if (otpmessage == 1) {
            setotpmessage(0);
        }
        if (otp != undefined) {
            const payload = [
                sessionStorage.getItem('shortname'),
                [
                    "OTP",
                    {
                        "login_type": "Web",
                        "otp_value": otp,
                        "user_id": userId,
                        "session_token": authtoken,
                        "device_id": null
                    }
                ]
            ]

            loginOtp({
                payload: payload,
                caller: login.form_url,
                setotp: setOtp
            })
        } else {
            Toaster.error("Please Enter OTP")
        }

    }


    const handleMenuClick = useCallback(() => {
        setSidebar(s => !s)
    }, [])

    useEffect(() => {
        if (defaultTime != 0 && resendOtpcount < 3) {
            setTimeout(() => {
                setDefaultTime(defaultTime - 1)
            }, 1000)
        }
    }, [defaultTime])


    useEffect(() => {
        if (isAuth) {
            const getUrl = callername.login_response.menu['My Accounts']
            const remainderArray = _.filter(getUrl, { form_name: 'Reminders' })
            const statutoryArray = _.filter(getUrl, { form_name: 'Statutory Notifications' })
            const escalationArray = _.filter(getUrl, { form_name: 'Escalations' })
            const messageArray = _.filter(getUrl, { form_name: 'Messages' })
            const profile = _.filter(getUrl, { form_name: 'View Profile' })
            const ChangePassword = _.filter(getUrl, { form_name: 'Change Password' })
            const settings = _.filter(getUrl, { form_name: "Settings" })
            const userGuideValue = _.filter(getUrl, { form_name: "User Guide" })
            const themesArray = _.filter(getUrl, { form_name: "Themes" })

            setSettingUrl(settings && settings.length > 0 && settings[0].form_url)
            setThemesUrl(themesArray && themesArray.length > 0 && themesArray[0].form_url)
            setUserGuide(userGuideValue && userGuideValue.length > 0 && userGuideValue[0].form_url)
            if (profile && profile.length > 0) {
                Setformurl(profile[0].form_url)
            }
            if (ChangePassword && ChangePassword.length > 0) {
                Setchangepassword(ChangePassword[0].form_url)
            }
            SetnotificationValue({
                ...notificationValue,
                remainderValue: remainderArray && remainderArray.length > 0 && remainderArray[0].form_url,
                statutoryNotificationValue: statutoryArray && statutoryArray.length > 0 && statutoryArray[0].form_url,
                escalationValue: escalationArray && escalationArray.length > 0 && escalationArray[0].form_url,
                messagesValue: messageArray && messageArray.length > 0 && messageArray[0].form_url
            })
        }
    }, [isAuth])



    const setFormKey = (currentFormName) => {
        switch (currentFormName) {
            case 'profile':
                let profile = _.filter(getUrl, { form_name: 'View Profile' })
                let prevData = localStorage.getItem('formkey')
                prevData = profile[0].form_key
                localStorage.setItem('formkey', prevData)
                break;

            case 'changePassword':
                let changePassword = _.filter(getUrl, { form_name: 'Change Password' })
                let changePasswordprevData = localStorage.getItem('formkey')
                changePasswordprevData = changePassword[0].form_key
                localStorage.setItem('formkey', changePasswordprevData)
                break;

            case 'settings':
                let settings = _.filter(getUrl, { form_name: 'Settings' })
                let settingsprevData = localStorage.getItem('formkey')
                settingsprevData = settings[0].form_key
                localStorage.setItem('formkey', settingsprevData)
                break;

            case 'userGuide':
                let userGuide = _.filter(getUrl, { form_name: 'User Guide' })
                let userGuideprevData = localStorage.getItem('formkey')
                userGuideprevData = userGuide[0].form_key
                localStorage.setItem('formkey', userGuideprevData)
                break;

            case 'remainder':
                let remainder = _.filter(getUrl, { form_name: 'Reminders' })
                let remainderprevData = localStorage.getItem('formkey')
                remainderprevData = remainder[0].form_key
                localStorage.setItem('formkey', remainderprevData)
                break;

            case 'notification':
                let notification = _.filter(getUrl, { form_name: 'Statutory Notifications' })
                let notificationprevData = localStorage.getItem('formkey')
                notificationprevData = notification[0].form_key
                localStorage.setItem('formkey', notificationprevData)
                break;

            case 'escalation':
                let escalation = _.filter(getUrl, { form_name: 'Escalations' })
                let escalationprevData = localStorage.getItem('formkey')
                escalationprevData = escalation[0].form_key
                localStorage.setItem('formkey', escalationprevData)
                break;

            case 'message':
                let message = _.filter(getUrl, { form_name: 'Messages' })
                let messageprevData = localStorage.getItem('formkey')
                messageprevData = message[0].form_key
                localStorage.setItem('formkey', messageprevData)
                break;

        }
    }

    useEffect(() => {
        let notifypayload;
        let escalationpayload;
        let remainderpay;
        let messagepay;



        if (authtoken && legalid && legalid != "null") {
            notifypayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]

            escalationpayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 3,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]

            remainderpay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 2,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]

            messagepay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 4,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        }
        else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            notifypayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotifications",
                        {
                            "le_ids": temp,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]

            escalationpayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 3,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]

            remainderpay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 2,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]

            messagepay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 4,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        }
        notifications({
            payload: notifypayload,
            caller: login.form_url
        })
        Escalations({
            payload: escalationpayload,
            caller: login.form_url
        })
        remainder({
            payload: remainderpay,
            caller: login.form_url
        })
        Message({
            payload: messagepay,
            caller: login.form_url
        })

    }, [authtoken && legalid])

    useEffect(() => {
        let entity = localStorage.getItem('SelectedEntity');
        if (sessionArray.password_expire_days > 0) {
            if (!entity && entity_info.length > 1) {
                if (sessionStorage.getItem('otpauth') == 'true') {
                    if (sessionStorage.getItem('isIpSetting1') == 'true') {
                        setIsModalVisible(false)
                    } else {
                        setIsModalVisible(true)
                    }
                    setOtpmodal(false)
                } else {
                    setOtpmodal(true)
                }

            } else {
                if (sessionStorage.getItem('otpauth') == 'true') {
                    setOtpmodal(false)
                } else {
                    setOtpmodal(true)
                }

                if (!localStorage.getItem('SelectedEntity') && !localStorage.getItem('SelectedEntityid')) {
                    entityselected(entity_info[0].le_name, entity_info[0].le_id)
                    if (sessionStorage.getItem('isIpSetting') == 'true') {
                        history.push(callername.login_response.menu.Master[0]?.form_url)
                        window.location.reload(false);

                    } else {
                        history.push(callername.login_response.menu.Static[0].form_url);
                        window.location.reload(false);
                    }
                }
            }
        }
        // else{
        //     const ChangePasswordNew = _.filter(getUrl, { form_name: 'Change Password' })        
        //     history.push(ChangePasswordNew[0].form_url)
        //     // window.location.reload(false);
        // }

    }, [sessionStorage.getItem('otpauth'), sessionStorage.getItem('isIpSetting')])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity')) {
            setCloseTrue(true);
        }
    })

    useEffect(() => {
        let entityvalue = localStorage.getItem('SelectedEntity');
        if (entityvalue) {
            Setentityinfo(entityvalue)
        }

    }, [entityinfo])

    const entityselection = () => {
        // localStorage.removeItem('SelectedEntity');
        // window.location.reload(false);
        setModalType('LegalEntity')
        setIsModalVisible(true)
    }

    //get unique array
    const getUnique = (arr, index) => {
        const unique = arr
            .map(e => e[index])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }


    const entity_count = entity_info.length

    const entity_sum = entity_info && entity_info.length > 0 && entity_info.reduce((total, entity_info) => total = total + entity_info.u_count, 0)

    useEffect(() => {
        let finalArray = [];
        let countrylistdata = [];
        let countrylisttempdata = [];
        let arr = [];
        entity_info && entity_info.length > 0 && entity_info.map(function (user) {
            if ((arr.indexOf(user.c_name) < 0))
                arr.push({ 'country_name': user.c_name, 'country_id': user.c_id });
            //    arr.push(user.c_name)
        });
        let newArray = getUnique(arr, 'country_id');
        for (let countrylist in newArray) {
            let country_id = newArray[countrylist].country_id
            let country_name = newArray[countrylist].country_name
            let entityArray = []
            let tempArray = []
            let sumValue = 0;
            for (let entitylist in entity_info) {
                let entitylist_cid = entity_info[entitylist].c_id
                if (entitylist_cid === country_id) {
                    sumValue = entity_info[entitylist].u_count + sumValue
                    entityArray = {
                        'bg_id': entity_info[entitylist].bg_id,
                        'bg_name': entity_info[entitylist].bg_name,
                        'c_id': entity_info[entitylist].c_id,
                        'c_name': entity_info[entitylist].c_name,
                        'le_id': entity_info[entitylist].le_id,
                        'le_name': entity_info[entitylist].le_name,
                        'u_count': entity_info[entitylist].u_count,
                    }
                    tempArray.push(entityArray)
                }
            }
            finalArray[country_name] = tempArray
            countrylistdata = {
                'c_id': country_id,
                'c_name': country_name,
                'allEntitySumNew': sumValue,
                'child': tempArray
            }
            countrylisttempdata.push(countrylistdata)
        }
        SetfinalResult({
            ...finalResult,
            Resultdata: countrylisttempdata
        })

    }, [])


    const entityselected = (c_name, c_id) => {
        localStorage.setItem('SelectedEntity', c_name);
        localStorage.setItem('SelectedEntityid', c_id);
        setIsModalVisible(false);
        window.location.reload(false);
    }

    const logout = () => {
        sessionLogout({})
    }



    const getnotifications = () => {
        let payload;
        if (legalid != 'null') {
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]
        }
        else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotifications",
                        {
                            "le_ids": temp,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]
        }
        notifications({
            payload: payload,
            caller: login.form_url
        })
    }

    const getescalations = () => {
        let payload;
        if (legalid != 'null') {
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 3,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]
        } else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 3,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]
        }
        Escalations({
            payload: payload,
            caller: login.form_url
        })
    }
    const getreminders = () => {
        let payload;
        if (legalid != 'null') {
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 2,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        } else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 2,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        }
        remainder({
            payload: payload,
            caller: login.form_url
        })
    }

    const getmessages = () => {
        let messagepay;
        if (legalid != 'null') {
            messagepay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 4,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        } else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            messagepay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 4,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        }
        Message({
            payload: messagepay,
            caller: login.form_url
        })
    }

    useEffect(() => {
        if (notify || escalation || remainderr || messages) {
            const data = notify.statutory;
            const es = escalation.escalations;
            const re = remainderr.reminders;
            const mes = messages.messages;
            Setnotificationdata(data);
            Setescalationdata(es);
            Setreminderdata(re);
            Setmessagedata(mes);
            localStorage.setItem('notificationscount', notify.statutory_count);
            localStorage.setItem('escalationcount', escalation.escalation_count);
            localStorage.setItem('remainderscount', remainderr.reminder_count);
            localStorage.setItem('messagecount', messages.messages_count)
        }

    }, [notify, escalation, remainderr, messages])

    useEffect(() => {
        {
            var the_arr = location.pathname.split('/');
            if (the_arr.includes("dashboard")) {
                the_arr.pop();
                let a = the_arr.join('/')
                setParamId(a)
            } else {
                setParamId(location.pathname)
            }

        }
    }, [location]);

    const vendorLogin = () => {
        setModalType('Product')
        let loginvendorpayload;
        loginvendorpayload = [
            authtoken,
            [
                "LoginFormLess", {
                    "session_token": authtoken,
                    "is_logged_in_through_sso": false,
                    "product_id": "3"
                }
            ]
        ]

        Loginvendor({
            payload: loginvendorpayload,
            paramid: paramid,
        })

        localStorage.removeItem("SelectedEntity");
        localStorage.removeItem("SelectedEntityid");
    }

    const Compfieproduct = () => {
        setModalType('Product')
        let compfieproductpayload;
        compfieproductpayload = [
            authtoken,
            [
                "LoginFormLess", {
                    "session_token": authtoken,
                    "is_logged_in_through_sso": false,
                    "product_id": "1"
                }
            ]
        ]
        LoginCompfieproduct({
            payload: compfieproductpayload,
            paramid: paramid,
        })
        localStorage.removeItem("SelectedEntity");
        localStorage.removeItem("SelectedEntityid");

    }

    const LitigationLogin = () => {
        setModalType('Product')
        let loginlitipayload;
        loginlitipayload = [
            authtoken,
            [
                "LoginFormLess", {
                    "session_token": authtoken,
                    "is_logged_in_through_sso": false,
                    "product_id": "1"
                }
            ]
        ]
        LoginLitigation({
            payload: loginlitipayload,
            paramid: paramid,
        })

        localStorage.removeItem("SelectedEntity");
        localStorage.removeItem("SelectedEntityid");

    }



    const closepopup = () => {
        setIsModalVisible(false)
    }

    const resendOtp = () => {
        if (defaultTime == 0 && resendOtpcount < 3) {
            setotpmessage(1);
            const payload = [
                sessionStorage.getItem('shortname'),
                [
                    "OTP",
                    {
                        "login_type": "Web",
                        "otp_value": "resend",
                        "user_id": userId,
                        "session_token": authtoken,
                        "device_id": null
                    }
                ]
            ]
            resendOTP({
                payload: payload,
                caller: login.form_url
            })
            setDefaultTime(60)
            setResendOtpcount(resendOtpcount + 1)
        }

    }

    const closepopup1 = () => {
        setIsModalVisibleVendor(false)
    }

    const openThemeModal = () => {
        setOpenThemeModalPopup(true)
    }

    const closethememodalpopup1 = () => {
        setOpenThemeModalPopup(false)
    }

    const themes = (theme, color_param, skin_param) => {
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "ChangeThemes",
                    {
                        "theme": theme
                    }
                ]
            }
        ]
        changeTheme({
            payload: payload,
            paramid: paramid
        })
        localStorage.setItem('currentTheme', theme)
        localStorage.setItem('currentSkin', skin_param)
        localStorage.setItem('text-font', theme == 'theme_four' || theme == 'theme_one' ? 'text-dark' : 'text-white')
        localStorage.setItem('iconColor', theme == 'theme_four' || theme == 'theme_one' ? 'iconColorGrey' : 'iconColorWhite')

        window.location.reload()
    }

    const [notificationIconColor, setNotificationIconColor] = useState('point bg-primary')

    console.log(callername.login_response.menu.Static[0].form_url, 'callername.login_response.menu.Static[0].form_url');
    console.log(localStorage.getItem('CurrentPath'), 'localStorage.getItem');

    useEffect(() => {
        if (localStorage.getItem('currentTheme') == 'theme_one') {
            setNotificationIconColor('point bg-primary')
        } else if (localStorage.getItem('currentTheme') == 'theme_two') {
            setNotificationIconColor('point bg-warning')
        } else if (localStorage.getItem('currentTheme') == 'theme_three') {
            setNotificationIconColor('point bg-error')
        } else if (localStorage.getItem('currentTheme') == 'theme_four') {
            setNotificationIconColor('point bg-error')
        } else {
            setNotificationIconColor('point bg-primary')
        }
    })

    const [sidebarbg, setBarBg] = useState('skin6')
    useEffect(() => {
        setBarBg(localStorage.getItem("currentSkin"))
    }, [localStorage.getItem("currentSkin")])

    useEffect(() => {
        if (resendOtpcount >= 3) {
            setDefaultTime(0);
        }
    }, [resendOtpcount])

    return (
        <Fragment>
            <header id='top-nav' className="topbar">
                <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                    <div className="navbar-header" style={{ 'overflow': 'hidden' }}>
                        <a
                            className="nav-toggler waves-effect waves-light d-block d-md-none"
                            href="/#"
                        ><i className="ri-close-line ri-menu-2-line fs-6"></i></a>
                        <div style={{ "height": "73px", 'overflow': 'hidden' }} className="row border-bottom ">

                            <div className="col-3 col-md-2" onClick={() => {
                                handleMenuClick()
                            }}>
                                <a id="menu-icon" className="nav-link sidebartoggler d-none d-md-block"><i data-feather="menu"></i></a>
                                <a className="nav-link sidebartoggler d-md-block">
                                    <img id="star-icon" className="d-none staricon mt-2" src="favicon.png" alt=""></img>
                                </a>
                            </div>
                            {vendorlogo == "true" ? <>
                                <div id="vendor-logo" className=" col-md-10 px-0">
                                    <Link className="navbar-brand  text-center vendor-logo">
                                        <img
                                            src="vendor-logo.png"
                                            alt="homepage"
                                            className="dark-logo w-75 mt-3 "
                                        />
                                    </Link>
                                </div> </> : <div id="logo-icon" className="col col-md-10 px-0">
                                <Link className="navbar-brand  text-center logo-icon">
                                    <img src="logo-icon.png" alt="homepage"
                                        className="dark-logo w-75 mt-3 "
                                    />
                                </Link>
                            </div>}
                        </div>
                        <a
                            className="topbartoggler d-block d-md-none waves-effect waves-light"
                            href="/#"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        ><i className="ri-more-line fs-6"></i></a>
                    </div>
                    <div className="navbar-collapse collapse h-70" id="navbarSupportedContent" data-navbarbg={sidebarbg}>
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">

                            </li>

                        </ul>

                        <ul className="navbar-nav d-flex align-items-center list-style-none mb-0">
                            {sessionArray.password_expire_days > 0 && sessionStorage.getItem('isIpSetting') != 'true' ?
                                <>
                                    {sessionValue.entity_info.length > 1 ? <li className="nav-item dropdown"><div className={"nav-link d-flex align-items-center " + localStorage.getItem("text-font")} data-bs-toggle="modal"
                                        data-bs-target="#bs-example-modal-xlg"><span className={'entity-top-nav text-right me-2 ' + localStorage.getItem("text-font")}>{entityinfo}</span>&nbsp;&nbsp;<Tooltip placement='bottom' title='Click here to Change Legal Entity' getPopupContainer={(triggerNode) => {
                                            return triggerNode.parentNode;
                                        }}>
                                            <i className={localStorage.getItem("text-font") + " ri-list-settings-line"} onClick={(e) => {
                                                entityselection()
                                            }}></i></Tooltip></div></li> : <Tooltip title=''><li className="nav-item dropdown"><div className={"nav-link " + localStorage.getItem("text-font")} data-bs-toggle="modal"
                                                data-bs-target="#bs-example-modal-xlg"><span className={localStorage.getItem("text-font")}>{entityinfo}</span>&nbsp;&nbsp;</div></li></Tooltip>}
                                </> : false}
                            <li className="nav-item dropdown mt-2">
                                <Link id={notificationValue.remainderValue ? '' : 'activemenu'}
                                    className="nav-link dropdown-toggle"
                                    href="/#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={getreminders}>
                                    <Tooltip title='Reminders' getPopupContainer={(triggerNode) => {

                                        return triggerNode.parentNode;

                                    }}><div><i data-feather="clock" className={localStorage.getItem('iconColor')}></i></div></Tooltip>
                                    <div className="notify">
                                        {
                                            localStorage.getItem('remainderscount') != 0 ?
                                                <span className={notificationIconColor}></span> : null}
                                    </div>
                                </Link>
                                <div style={{ 'overflow': 'hidden' }} className="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up pb-0">
                                    <ul className="list-style-none">
                                        <li className='p-2'>
                                            <div className="h-50 message-center message-body position-relative ">
                                                {

                                                    reminderdata && reminderdata.length > 0 && reminderdata.map((item, itemk) => {
                                                        if (itemk < 2) {
                                                            return (<Link className="message-item d-flex align-items-center border-bottom p-0" key={itemk}>
                                                                <div className=" d-inline-block v-middle ps-2">
                                                                    <Link className='m-0 p-0' to={notificationValue.remainderValue}><h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium cut-text">{item.notification_text.replace(/['"]+/g, '')}</h5></Link>
                                                                    <span className="fs-2 text-nowrap d-block time text-truncate fw-normal mt-1 text-muted">{item.created_on}</span>
                                                                </div>
                                                            </Link>)
                                                        }

                                                    })
                                                }
                                                {reminderdata && reminderdata.length > 0 ?
                                                    <div className="mt-2 popupbtncolour">
                                                        <Link
                                                            className={"btn text-white " + localStorage.getItem('currentTheme')}
                                                            to={notificationValue.remainderValue}
                                                            onClick={(e) => {
                                                                setFormKey('remainder')
                                                            }}
                                                        >
                                                            {`Read More >`}
                                                        </Link>
                                                    </div>
                                                    : <div className="rounded-top p-2 text-center">
                                                        <h6 className="card-title mb-0">No Records Found</h6></div>
                                                }
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown mt-2">
                                <Link
                                    id={notificationValue.statutoryNotificationValue ? '' : 'activemenu'}
                                    className="nav-link dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={getnotifications}>
                                    <Tooltip title='Statutory Notifications' getPopupContainer={(triggerNode) => {

                                        return triggerNode.parentNode;

                                    }}><div><i data-feather="bell" className={localStorage.getItem('iconColor')} title='Notifications'></i></div></Tooltip>
                                    <div className="notify">
                                        {
                                            localStorage.getItem('notificationscount') != 0 ?
                                                <span className={notificationIconColor}></span> : null
                                        }
                                    </div>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up pb-0">
                                    <ul className="list-style-none">
                                        <li className='p-2'>
                                            <div className="h-50 message-center message-body position-relative ">
                                                {

                                                    notificationdata && notificationdata.length > 0 && notificationdata.map((item, itema) => {
                                                        if (itema < 2) {
                                                            return (<Link key={itema} className="message-item d-flex align-items-center border-bottom p-0 m-0 ">
                                                                <div className=" d-inline-block v-middle ps-2">
                                                                    <Link className='p-0 m-0' to={notificationValue.statutoryNotificationValue}><h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium cut-text">{item.notification_text.replace(/['"]+/g, '')}</h5></Link>
                                                                    <span className="fs-2 text-nowrap d-block time text-truncate fw-normal mt-1 text-muted">{item.created_on}</span>
                                                                </div>
                                                            </Link>)
                                                        }



                                                    })
                                                }
                                                {notificationdata && notificationdata.length > 0 ?
                                                    <div className="mt-2 popupbtncolour">
                                                        <Link
                                                            className={"btn text-white " + localStorage.getItem('currentTheme')}
                                                            to={notificationValue.statutoryNotificationValue}
                                                            onClick={(e) => {
                                                                setFormKey('notification')
                                                            }}
                                                        >
                                                            {`Read More >`}
                                                        </Link>
                                                    </div>
                                                    : <div className="rounded-top p-2 text-center">
                                                        <h6 className="card-title mb-0">No Records Found</h6></div>
                                                }
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown mt-2">
                                <Link
                                    id={notificationValue.escalationValue ? '' : 'activemenu'}
                                    className="nav-link dropdown-toggle"
                                    href="/#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={getescalations}>
                                    <Tooltip title='Escalations' getPopupContainer={(triggerNode) => {

                                        return triggerNode.parentNode;

                                    }}><div><i data-feather="zap" className={localStorage.getItem('iconColor')}></i></div></Tooltip>


                                    <div className="notify">
                                        {
                                            localStorage.getItem('escalationcount') != 0 ?
                                                <span className={notificationIconColor}></span> : null}
                                    </div>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up pb-0">
                                    <ul className="list-style-none">
                                        <li className='p-2'>
                                            <div className="h-50 message-center message-body position-relative ">
                                                {

                                                    escalationdata && escalationdata.length > 0 && escalationdata.map((item, itemj) => {
                                                        if (itemj < 2) {
                                                            return (<Link className="message-item d-flex align-items-center border-bottom p-0" key={itemj}>
                                                                <div className=" d-inline-block v-middle ps-2">
                                                                    <Link className='p-0 m-0' to={notificationValue.escalationValue}><h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium cut-text">{item.notification_text.replace(/['"]+/g, '')}</h5></Link>
                                                                    <span className="fs-2 text-nowrap d-block time text-truncate fw-normal mt-1 text-muted">{item.created_on}</span>
                                                                </div>
                                                            </Link>)
                                                        }



                                                    })
                                                }
                                                {escalationdata && escalationdata.length > 0 ?
                                                    <div className="mt-2 popupbtncolour">
                                                        <Link
                                                            className={"btn text-white " + localStorage.getItem('currentTheme')}
                                                            to={notificationValue.escalationValue}
                                                            onClick={(e) => {
                                                                setFormKey('escalation')
                                                            }}

                                                        >
                                                            {`Read More >`}
                                                        </Link>
                                                    </div>
                                                    : <div className="rounded-top p-2 text-center">
                                                        <h6 className="card-title mb-0">No Records Found</h6></div>
                                                }
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown mt-2">
                                <Link className="nav-link dropdown-toggle"
                                    // id="2"
                                    id={notificationValue.messagesValue ? '' : 'activemenu'}
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={() => { getmessages() }}
                                >
                                    <Tooltip title='Messages' getPopupContainer={(triggerNode) => {

                                        return triggerNode.parentNode;

                                    }}><div><i data-feather="message-square" className={localStorage.getItem('iconColor')}></i></div></Tooltip>
                                    <div className="notify">

                                        {
                                            localStorage.getItem('messagecount') != 0 ?
                                                <span className={notificationIconColor}></span> : null}
                                    </div>
                                </Link>
                                <div className="dropdown-menu mailbox dropdown-menu-end dropdown-menu-animate-up p-0" aria-labelledby="2">
                                    <ul className="list-style-none">
                                        <li className='p-2'>
                                            <div className="h-50 message-center message-body position-relative ">
                                                {

                                                    messagedata && messagedata.length > 0 && messagedata.map((item, itemm) => {
                                                        if (itemm < 2) {
                                                            return (<Link className="message-item d-flex align-items-center border-bottom p-0" key={itemm}>
                                                                <div className=" d-inline-block v-middle ps-2">
                                                                    <Link className='p-0 m-0' to={notificationValue.messagesValue}><h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium cut-text">{item.notification_text.replace(/['"]+/g, '')}</h5></Link>
                                                                    <span className="fs-2 text-nowrap d-block time text-truncate fw-normal mt-1 text-muted">{item.created_on}</span>
                                                                </div>
                                                            </Link>)

                                                        }

                                                    })
                                                }
                                                {messagedata && messagedata.length > 0 ?
                                                    <div className={"mt-2 popupbtncolour"}>
                                                        <Link
                                                            // id='logout_btn'
                                                            className={"btn text-white " + localStorage.getItem('currentTheme')}
                                                            to={notificationValue.messagesValue}
                                                            onClick={(e) => {
                                                                setFormKey('message')
                                                            }}
                                                        >
                                                            {`Read More >`}
                                                        </Link>
                                                    </div>
                                                    : <div className="rounded-top p-2 text-center">
                                                        <h6 className="card-title mb-0">No Records Found</h6></div>
                                                }
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown profile-dropdown">
                                <Link
                                    className="nav-link dropdown-toggle d-flex align-items-center"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img
                                        src="3.png"
                                        alt="user"
                                        width="30"
                                        className="profile-pic rounded-circle"
                                    />
                                    <div className="d-none d-md-flex align-items-center">
                                        <span className="ms-2"
                                        >
                                            <span className={localStorage.getItem("text-font") + " fw-bold"}>{sessionValue.login_response.emp_name}</span>

                                        </span>
                                        <span className='mt-1'>
                                            <i data-feather="chevron-down" className={localStorage.getItem('iconColor')}></i>
                                        </span>
                                    </div>
                                </Link>
                                <div
                                    className="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up">
                                    <ul className={"list-style-none " + localStorage.getItem('currentTheme')}>
                                        <li className="p-3 pb-2 pt-3">
                                            <div className="rounded-top d-flex align-items-center">
                                                <h3 className="card-title mb-0 fs-4">My Accounts</h3>
                                                <div className="ms-auto">
                                                    <Link className="link py-0">
                                                        <i data-feather="x-circle"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li id='myAccounts' className="p-3 pb-2 pt-0 ml-2 position-relative" style={{ 'overflowY': 'scroll' }}>
                                            <div className="message-body position-relative">
                                                <Link id={formurl ? '' : 'activemenu'} to={formurl} onClick={(e) => {
                                                    setFormKey('profile')
                                                }}
                                                    className="message-item px-2 d-flex align-items-center border-bottom py-1">
                                                    <span className="btn btn-light-info btn-rounded-lg text-info profile-icons p-1">
                                                        <i data-feather="user"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-2">
                                                        <h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium">
                                                            View Profile
                                                        </h5>
                                                    </div>
                                                </Link>
                                                <Link id={changepassword ? '' : 'activemenu'} to={changepassword} onClick={(e) => {
                                                    setFormKey('changePassword')
                                                }}
                                                    className="message-item px-2 d-flex align-items-center border-bottom py-1">
                                                    <span className="btn btn-light-info btn-rounded-lg text-info profile-icons p-1">
                                                        <i
                                                            data-feather="lock"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-2">

                                                        <h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium">
                                                            Change Password
                                                        </h5>
                                                    </div>
                                                </Link>
                                                {/* {settingurl ?  */}
                                                <Link id={settingurl ? '' : 'activemenu'} to={settingurl} onClick={(e) => {
                                                    setFormKey('settings')
                                                }}
                                                    className="message-item px-2 d-flex align-items-center border-bottom py-1">
                                                    <span className="btn btn-light-info btn-rounded-lg text-info profile-icons p-1">
                                                        <i
                                                            data-feather="settings"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-2">
                                                        <h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium">
                                                            Settings
                                                        </h5>
                                                    </div>
                                                </Link>
                                                <Link id={userGuide ? '' : 'activemenu'}
                                                    to={userGuide} onClick={(e) => {
                                                        setFormKey('userGuide')
                                                    }}
                                                    className="message-item px-2 d-flex align-items-center border-bottom py-1">
                                                    <span className="btn btn-light-info btn-rounded-lg text-info profile-icons p-1">
                                                        <i
                                                            data-feather="help-circle"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-2">
                                                        <h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium">
                                                            User Guide
                                                        </h5>
                                                    </div>
                                                </Link>
                                                <Link id={themesUrl ? '' : 'activemenu'} onClick={(e) => {
                                                    openThemeModal()
                                                }}
                                                    className="message-item px-2 d-flex align-items-center border-bottom py-1">
                                                    <span className="btn btn-light-info btn-rounded-lg text-info profile-icons p-1">

                                                        <i
                                                            data-feather="copy"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-2">
                                                        <h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium">
                                                            Themes
                                                        </h5>


                                                    </div>
                                                </Link>

                                            </div>

                                        </li>
                                        <li className='my-0 mx-2'>
                                            <div className="mt-2 mb-2">
                                                <Link
                                                    style={{ borderRadius: '10px' }}
                                                    className="btn"
                                                    id='logout_btn'
                                                    onClick={logout}

                                                >
                                                    Logout
                                                    <LogoutOutlined style={{ marginLeft: '20px' }} />

                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            {sessionValue.login_response.vndr_access == false && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.cf_access == true && sessionStorage.getItem('isIpSetting') == 'true' ?
                                false
                                : sessionValue.login_response.vndr_access == true && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.cf_access == false ? false : sessionArray.password_expire_days > 0 && sessionStorage.getItem('isIpSetting') != 'true' ?
                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="/#"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <Tooltip title='Click here for Products' placement='topLeft' getPopupContainer={(triggerNode) => {

                                                return triggerNode.parentNode;

                                            }}><div><i className={localStorage.getItem('iconColor') + " bi-grid-3x3-gap-fill"}></i></div></Tooltip>
                                            {/* <Tooltip title='Click here for Products'><div><i className="bi-grid-3x3-gap-fill text-primary"></i></div></Tooltip> */}
                                            <div >
                                                <span className={notificationIconColor}></span>
                                            </div>
                                        </a>

                                        <div className="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up pb-0 w-75">
                                            <ul className="list-style-none">
                                                <li className="p-3 pt-0 pb-0">
                                                    <div className="message-center message-body position-relative"
                                                        style={{ "height": "100%" }}
                                                    >
                                                        {sessionValue.login_response.cf_access ?
                                                            <Link onClick={(e) => { Compfieproduct() }}
                                                                href="/#" className="message-item px-1 d-flex align-items-center border-bottom py-1">
                                                                <img src="logo-icon.png" style={{ width: "75px", marginLeft: '5px' }} alt="" />
                                                            </Link>
                                                            : ''}
                                                        {sessionValue.login_response.ltmg_access ?
                                                            <Link onClick={(e) => { LitigationLogin() }}
                                                                href="/#" className="message-item px-1 d-flex align-items-center border-bottom py-1">
                                                                <img src="litigation-logo.png" style={{ width: "140px" }} alt="" />
                                                            </Link>
                                                            : ''}
                                                        {sessionValue.login_response.vndr_access ?
                                                            <Link onClick={(e) => { vendorLogin() }}
                                                                className="message-item px-1 d-flex align-items-center border-bottom py-1">
                                                                <img src="vendor-logo.png" style={{ width: "115px" }} alt="" />
                                                            </Link>
                                                            : ''}
                                                    </div>

                                                </li>
                                            </ul>
                                        </div>
                                    </li> : false}
                        </ul>
                    </div>
                </nav >
            </header >
            <Modal autoFocus={false} open={otpModal} className='modelradius allclose allclose1 w-25' closable={false} maskClosable={false} style={{ padding: 0 }} maskStyle={{ backdropFilter: 'blur(15px)' }} footer={false}>
                <div className='text-center'>
                    <div>
                        <p className='m-0 p-0'>We have sent an OTP to your registered mobile number</p>
                        <h5 className='mt-3 text-primary p-0'>ONE TIME PASSWORD</h5>
                        <span>00 : {defaultTime > 9 ? defaultTime : `0${defaultTime}`}</span>
                        <form onSubmit={submitOtp}>


                            <div >

                                <input maxLength={6} autoFocus value={otp}
                                    // style={{'Cursor':'pointer'}} 
                                    onChange={(e) => {
                                        let result = validateInput(e, validationType.numbersOnly)
                                        setOtp(result)
                                    }} id="partitioned" type="text" />


                                {
                                    otpmessage == 1 ?
                                        resendOtpcount != 0 && (resendOtpcount < 3 ?
                                            <p className='ms-2 mt-2 p-0' style={{ color: 'red' }}>OTP has been sent!</p> : <p style={{ color: 'red' }}>Resend request limit exceeded</p>)
                                        : ''
                                }


                                <div className="mt-4">

                                    <button type="button" disabled={defaultTime != 0 || resendOtpcount > 2} style={{ cursor: defaultTime != 0 ? 'not-allowed' : 'pointer' }} onClick={resendOtp} className="btn btn-secondary" data-dismiss="modal">Resend OTP</button>
                                    <button type="button" onClick={submitOtp} className="btn btn-primary ms-4">Verify OTP</button>

                                </div>
                                {/* </form> */}
                                {/* <p style={{ color: 'blueviolet', cursor: defaultTime != 0 ? 'not-allowed' : 'pointer', position: 'absolute', top: '70px', right: '55px', opacity: defaultTime != 0 ? .5 : 1 }} onClick={resendOtp}>Resend OTP</p> */}
                            </div>
                        </form>

                        <div className='text-center mt-4'>
                            {/* <Button className='me-1' onClick={submitOtp}>Submit</Button> */}
                            <Button className='link border-0 bg-transparent p-0 m-0' onClick={() => {
                                history.goBack()
                                let entity = localStorage.getItem('SelectedEntity');
                                localStorage.clear()
                                sessionStorage.clear()
                                if (!entity && entity_info.length > 1) {
                                    dispatch({
                                        type: TOGGLE_COMMON_LOADER
                                    })
                                }
                            }}>Login with another account</Button>
                        </div>

                    </div>
                </div>
            </Modal>
            <Modal open={isModalVisible} className='modelradius allclose allclose1 ' maskStyle={{ backdropFilter: 'blur(15px)' }} footer={false} onCancel={closepopup}
                closable={false} keyboard={false} maskClosable={false} style={{ padding: 0 }}>

                <div className="d-flex justify-content-between">
                    <span><i className='ri-rest-time-line me-1'></i>Last Seen : <b>{currentDayTime.toLocaleString('en-US', { hour24: true })}</b></span>
                    {localStorage.getItem('SelectedEntity') != '' && localStorage.getItem('SelectedEntity') != null ?
                        <span className='text-right'><CloseCircleOutlined style={{ fontSize: '18px', color: 'red' }} onClick={() => {
                            closepopup()
                        }} /></span> : ''}
                </div>

                <div className="accordion" id="accordionExample" style={{ marginTop: "15px", padding: 0 }}>
                    <div className="accordion-item" id="EntityCard">
                        <h2 className="accordion-header">
                            <button
                                className="btn btn-primary w-100 rounded-0 py-1 pe-none"
                                type="button">
                                <div className="row w-100 text-start rounded-0 p-2 py-1">
                                    <div className="col-5" style={{ textAlign: "left" }}>
                                        Select Legal Entity
                                    </div>
                                    <div className="col-3" style={{ textAlign: "center" }}>
                                        Legal<br /> Entity Count
                                    </div>
                                    <div className="col " style={{ textAlign: "center" }}>
                                        {localStorage.getItem('isVendor') == 'true' ? 'Vendor Count' : 'Unit Count'}
                                    </div>
                                    <div className="col" style={{ textAlign: "center" }}>

                                    </div>
                                    {/* <div className="col" style={{ textAlign: "center" }}>
                                        Last<br />Selected
                                    </div> */}

                                </div>
                            </button>
                        </h2>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" >
                            <button
                                className="accordion-button collapsed custo py-1"
                                type="button"
                            >
                                <div className="row w-100 text-start rounded-0 p-2 py-3">
                                    <div className="col-5" style={{ textAlign: "left" }}
                                        onClick={() => {
                                            if (modalType == 'Product') {
                                                localStorage.setItem('SelectedEntity', "All Legal Entity")
                                                localStorage.setItem('SelectedEntityid', "null")
                                                setIsModalVisible(false);
                                                history.push(callername.login_response.menu.Static[0].form_url);
                                                // if (localStorage.getItem('isVendor') == false) {
                                                //     history.push('/page');
                                                // } else {
                                                //     history.push(callername.login_response.menu.Static[0].form_url);
                                                //     // <Link to={'/page'} />
                                                //     // history.push('/page');
                                                //     // <Redirect to={'/page'} />
                                                // }
                                                window.location.reload(false);
                                            }
                                            else {

                                                localStorage.setItem('SelectedEntity', "All Legal Entity")
                                                localStorage.setItem('SelectedEntityid', "null")
                                                setIsModalVisible(false);
                                                if (isDashboard === true) {
                                                    history.push(`${callername.login_response.menu.Dashboard[0].form_url}/compliances`)
                                                    window.location.reload(false);
                                                }
                                                else {
                                                    history.push(localStorage.getItem('CurrentPath'))
                                                    window.location.reload(false);
                                                }
                                            }
                                        }}>
                                        <label className='pointer'>  All Legal Entities</label>
                                    </div>
                                    <div className="col-3" onClick={() => {
                                        if (modalType == 'Product') {
                                            localStorage.setItem('SelectedEntity', "All Legal Entity")
                                            localStorage.setItem('SelectedEntityid', "null")
                                            setIsModalVisible(false);
                                            if (isDashboard === true) {
                                             
                                                history.push(`${callername.login_response.menu.Dashboard[0].form_url}/compliances`)
                                                window.location.reload(false);
                                            }
                                            history.push(callername.login_response.menu.Static[0].form_url);
                                            window.location.reload(false);
                                        }
                                        else {
                                            localStorage.setItem('SelectedEntity', "All Legal Entity")
                                            localStorage.setItem('SelectedEntityid', "null")
                                            setIsModalVisible(false);
                                            if (isDashboard === true) {
                                         
                                                history.push(`${callername.login_response.menu.Dashboard[0].form_url}/compliances`)
                                                window.location.reload(false);
                                            }
                                            history.push(localStorage.getItem('CurrentPath'))
                                            window.location.reload(false);
                                        }
                                    }} style={{ textAlign: "center" }}>
                                        <label className='pointer' style={{ textAlign: "center" }}> {entity_count}</label>
                                    </div>
                                    <div className="col " onClick={() => {
                                        if (modalType == 'Product') {
                                            localStorage.setItem('SelectedEntity', "All Legal Entity")
                                            localStorage.setItem('SelectedEntityid', "null")
                                            setIsModalVisible(false);
                                            if (isDashboard === true) {
                                              
                                                history.push(`${callername.login_response.menu.Dashboard[0].form_url}/compliances`)
                                                window.location.reload(false);
                                            }
                                            history.push(callername.login_response.menu.Static[0].form_url);
                                            window.location.reload(false);
                                        }
                                        else {
                                            localStorage.setItem('SelectedEntity', "All Legal Entity")
                                            localStorage.setItem('SelectedEntityid', "null")
                                            setIsModalVisible(false);
                                            if (isDashboard === true) {
                                             
                                                history.push(`${callername.login_response.menu.Dashboard[0].form_url}/compliances`)
                                                window.location.reload(false);
                                            }
                                            history.push(localStorage.getItem('CurrentPath'))
                                            window.location.reload(false);
                                        }
                                    }} style={{ textAlign: "center" }}>
                                        <label className='pointer' style={{ textAlign: "center" }}> {entity_sum}</label>
                                    </div>
                                    <div className="col" style={{ textAlign: "center" }}>

                                    </div>
                                    {/* <div className="col " style={{ textAlign: "center" }}>
                                        <label className='pointer' style={{ textAlign: "center" }}> <i className='ri-checkbox-circle-line text-success fs-5'></i></label>
                                    </div> */}

                                </div>
                            </button>
                        </h2>

                    </div>

                    {finalResult.Resultdata.length > 0 &&
                        finalResult.Resultdata.map((finalList, n) => (
                            <div className="accordion-item" key={n}>
                                <>
                                    <h2 className="accordion-header" id="headingOne">

                                        <button
                                            className="accordion-button py-2"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target={"#collapse" + finalList.c_id}
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                            style={{ backgroundColor: '#e8f5ff' }}
                                        >
                                            <div className="row w-100 text-start rounded-0 p-2 py-2">
                                                <div className="col-5 pointer " style={{ textAlign: "left" }}>
                                                    {finalList.c_name}
                                                </div>
                                                <div className="col-3 pointer" style={{ textAlign: "center" }}>
                                                    {finalList.child.length}
                                                </div>
                                                <div className="col pointer" style={{ textAlign: "center" }}>
                                                    {finalList.allEntitySumNew}
                                                </div>
                                                <div className="col pointer" style={{ textAlign: "center" }}>
                                                    <label className='pointer' style={{ textAlign: "center" }}></label>

                                                </div>

                                            </div>
                                        </button>

                                    </h2>

                                    <div
                                        id={"collapse" + finalList.c_id}
                                        className="accordion-collapse collapse in collapse-border-end"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordionExample">
                                        <div className="accordion-body overflow-hidden px-0 py-1">
                                            {finalList.child.length > 0 &&
                                                finalList.child.map((childList, l) => (
                                                    <div className="accordion-button bg-transparent py-1" key={l}>
                                                        <div className="row  w-100 p-2 py-1" key={l}>
                                                            <div className="col-5 pointer">
                                                                <a data-bs-dismiss="modal" className="cur-point" onClick={(e) => {
                                                                    entityselected(childList.le_name, childList.le_id)
                                                                    // history.push('/dashboard');
                                                                    if (modalType == 'Product') {
                                                                        history.push(callername.login_response.menu.Static[0].form_url);
                                                                        window.location.reload(false);
                                                                        if (isDashboard === true) {
                                                                           
                                                                            history.push(`${callername.login_response.menu.Dashboard[0].form_url}/compliances`)
                                                                            window.location.reload(false);
                                                                        }
                                                                    }
                                                                    else {

                                                                        history.push(localStorage.getItem('CurrentPath'))
                                                                        window.location.reload(false);
                                                                        if (isDashboard === true) {
                                                                           
                                                                            history.push(`${callername.login_response.menu.Dashboard[0].form_url}/compliances`)
                                                                            window.location.reload(false);
                                                                        }
                                                                    }

                                                                }}>{childList.le_name}</a>
                                                            </div>
                                                            <div className="col-3 pointer" style={{ textAlign: "left" }}>

                                                            </div>
                                                            <div className="col pointer" onClick={(e) => {
                                                                entityselected(childList.le_name, childList.le_id)
                                                                // history.push('/dashboard');
                                                                if (modalType == 'Product') {
                                                                    history.push(callername.login_response.menu.Static[0].form_url);
                                                                    window.location.reload(false);
                                                                }
                                                                else {

                                                                    history.push(localStorage.getItem('CurrentPath'))
                                                                    window.location.reload(false);
                                                                }


                                                            }} style={{ textAlign: "center" }}>
                                                                {childList.u_count}
                                                            </div>
                                                            <div className="col pointer" style={{ textAlign: "center" }}>
                                                                <label className='pointer' style={{ textAlign: "center" }}> </label>

                                                            </div>

                                                        </div>
                                                    </div>

                                                ))}
                                        </div>
                                    </div>
                                </>
                            </div>
                        ))}

                </div>

            </Modal>
            <Modal title={'Themes'} open={openThemeModalPopup} maskStyle={{ backdropFilter: 'blur(15px)' }} footer={false} onCancel={closethememodalpopup1} closable={closeTrue} keyboard={false} maskClosable={false} style={{ padding: 0 }}>
                <div className="row pointer">
                    <div className="col-6 col-md-3">
                        <Card title="Theme1" className='theme-card' id={localStorage.getItem('currentTheme') === 'theme_one' ? 'activateddd' : ''} onClick={(e) => { themes('theme_one', 'default', 'skin6') }}>
                            <img style={{ width: '100%' }} src="Theme1.png" alt="Theme1" />

                        </Card>
                    </div>
                    <div className="col-6 col-md-3">
                        <Card title="Theme2" className='theme-card' id={localStorage.getItem('currentTheme') === 'theme_two' ? 'activateddd' : ''} onClick={(e) => { themes('theme_two', 'red', 'skin7') }}>
                            <img style={{ width: '100%' }} src="Theme2.png" alt="Theme2" />

                        </Card>
                    </div>
                    <div className="col-6 col-md-3">
                        <Card title="Theme3" className='theme-card' id={localStorage.getItem('currentTheme') === 'theme_three' ? 'activateddd' : ''} onClick={(e) => { themes('theme_three', 'green', 'skin8') }}>
                            <img style={{ width: '100%' }} src="Theme3.png" alt="Theme3" />

                        </Card>
                    </div>
                    <div className="col-6 col-md-3">
                        <Card title="Theme4" className='theme-card' id={localStorage.getItem('currentTheme') === 'theme_four' ? 'activateddd' : ''} onClick={(e) => { themes('theme_four', 'lightblue', 'skin9') }}>
                            <img style={{ width: '100%' }} src="Theme4.png" alt="Theme4" />

                        </Card>
                    </div>
                </div>
            </Modal>


        </Fragment >
    )
};


export default connect(mapStateToProps, {
    sessionLogout,
    notifications,
    Escalations,
    remainder, Message,
    Loginvendor,
    LoginLitigation,
    loginOtp,
    LoginCompfieproduct,
    changeTheme,
    Updatepassword,
    resendOTP,
    UpdatepasswordLogin
})(Header);