import HTTP from '../../Libs/http'
import { Toaster } from '../../Libs/Toaster'
import url, { getUrl } from './../../Libs/URL'
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt'
import { encode as base64_encode, decode as base64_decode } from 'base-64';
import { LOGINSUCCESS, TOGGLE_COMMON_LOADER, NOTIFICATIONS, LOGINFAILED, ESCALATION, REMAINDER, MESSAGE, NOTIFICATIONSHOWALL, NOTIFICATIONMODAL, LOGINVENDOR, LOGINLITIGATION, LOGINCOMPFIEPRODUCT, isIpsetting } from './../types/index';
import alertmessage from './../../Libs/Altermessage'
const urls = getUrl()

export const login = ({ payload, navigate, short, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const loginpayload = [
            payload.groupName, [
                'Login', {
                    'login_type': 'Web',
                    'password': payload.password,
                    'short_name': payload.groupName,
                    'username': payload.userName,

                }
            ]
        ]
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded
            },
        }
        const data1 = EncryptDecrypt.encryptdata(loginpayload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)


        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 0)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error('Invalid Credentials')
            // setTimeout(() => {
            //     window.location.reload(false);
            // }, 2000);
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            console.log(decryptData, "decryptData5555");
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'UserLoginSuccess') {
                dispatch({
                    type: LOGINSUCCESS,
                    payload: decryptData[1],
                });
                console.log(decryptData[1], 'decryptData[1]w')
                console.log(decryptData[1].e_vry21, 'decryptData[1]323')
                sessionStorage.setItem('LoginValue', decryptData[1].e_vry21)
                sessionStorage.setItem('otpactive', true)
                sessionStorage.setItem('shortname', short)


                if (decryptData[1].e_vry21 == 'ackxGT883Agzrg==') {

                    sessionStorage.setItem('otpauth', false)
                } else {

                    sessionStorage.setItem('otpauth', true)
                }

                if (decryptData[1].password_expire_days < 0) {

                    navigate.push(decryptData[1].menu['My Accounts'][0].form_url)

                    window.location.reload(false);
                } else {

                    if (decryptData[1]?.menu?.Master && decryptData[1]?.menu?.Master[0]?.form_name === "IP Settings") {



                        navigate.push(decryptData[1]?.menu?.Master[0]?.form_url)
                        dispatch({
                            type: isIpsetting,
                            payload: true
                        })
                        sessionStorage.setItem('isIpSetting', true)
                        sessionStorage.setItem('isIpSetting1', true)
                        sessionStorage.setItem('isIpSettingKey', decryptData[1]?.menu?.Master[0]?.form_key)
                        window.location.reload(false);
                    } else {

                        navigate.push(decryptData[1].menu['Static'][1].form_url)
                        sessionStorage.setItem('isIpSetting', false)
                        sessionStorage.setItem('isIpSetting1', false)
                        dispatch({
                            type: isIpsetting,
                            payload: false
                        })
                    }
                }


            } else if (decryptData[0] == 'InvalidCredentials') {

                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                // dispatch({
                //     type: LOGINFAILED,
                //     payload: true,
                // });
                Toaster.error("Invalid Credentials")

            } else if (decryptData[0] === 'UserLocked') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Your account has been locked due to few unsuccessful login attempts,please try after some times.')
            } else if (decryptData[0] === 'DisabledUser') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Login prohibited. Kindly contact your Administrator');
            } else if (decryptData[0] == 'NotConfigured') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Please Wait...Your account configuration is under progress..')
            } else if (decryptData[0] == 'client not found') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Invalid Credentials');
            } else if (decryptData[0] == 'ClientIpAddressNotAllowed') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Login restricted due to unauthenticated IP');
            } else if (decryptData[0] == 'UserInactive') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Inactive User');
            } else if (decryptData[0] == 'ResetPending') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Your previous password reset process is incomplete, Please reset using forgot password and complete the reset process inorder to continue');
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                console.log(decryptData[0], "decryptData");
                Toaster.error(decryptData[0])
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const loginOtp = ({ payload, navigate, executeCancel, setotp }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        console.log(payload, 'otpPayloads')
        const loginpayload = [
            payload.groupName, [
                'Login', {
                    'login_type': 'Web',
                    'password': payload.password,
                    'short_name': payload.groupName,
                    'username': payload.userName,

                }
            ]
        ]
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)


        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            console.log(decryptData, "decryptData123456");
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] == 'OTP authenticated') {
                sessionStorage.setItem('otpauth', true)
                const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
                let entity = localStorage.getItem('SelectedEntity');
                const entity_info = sessionValue.entity_info
                if (!entity && entity_info.length > 1) {

                }
                else {
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 1000);
                }
            } else if (decryptData[0] == "InvalidSession") {
                Toaster.error("Please Enter valid OTP!")
                setotp('')

            } else if (decryptData[0] == 'Invalid OTP') {
                Toaster.error('Please enter valid OTP!');
                setotp('')
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const resendOTP = ({ payload, navigate, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        console.log(payload, 'resendotpPayloads')
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)


        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 0)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error('Invalid Credentials')
            // setTimeout(() => {
            //     window.location.reload(false);
            // }, 2000);
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};



export const sessionLogout = ({ payload, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let token = localStorage.getItem('authToken');
        const loginpayload = [
            token, [
                "Logout",
                {
                    "session_token": token
                }
            ]
        ]
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded
            },
        }
        const data1 = EncryptDecrypt.encryptdata(loginpayload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'LogoutSuccess') {
                localStorage.clear();
                sessionStorage.clear()
                window.location.reload(false);
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Invalid Error")
            }
        }



    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
}

export const notifications = ({ payload, caller, executeCancel }) => async dispatch => {
    const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
    try {

        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let urlValue;
        if (localStorage.getItem('isVendor') == "true") {
            const vendorurl = url.VendorURL + '/cv'
            urlValue = `${vendorurl}`
        } else {
            if (sessionValue.login_response.cf_access == true && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == true) {
                urlValue = `${url.Login}`
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == false) {
                urlValue = `${url.Login}`
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.vndr_access == true) {
                const vendorurl = url.VendorURL + '/cv'
                urlValue = `${vendorurl}`
            } else if (sessionValue.login_response.cf_access == true && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.vndr_access == false) {
                urlValue = `${url.Login}`
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == true) {
                const vendorurl = url.VendorURL + '/cv'
                urlValue = `${vendorurl}`
            }
            else {
                urlValue = `${url.Login}`
            }

        }
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: urlValue + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        }
        // else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
        //     executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
        //     (executeRequest.status === 401 || executeRequest.status === 503)) {
        //     dispatch({
        //         type: TOGGLE_COMMON_LOADER
        //     });
        //     Toaster.error("Service Unavailable")
        // }
        else if (executeRequest.code == 'ERR_NETWORK') {
            dispatch({
                type: TOGGLE_COMMON_LOADER,
                // payload: true
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'GetStatutorySuccess') {
                dispatch({
                    type: NOTIFICATIONS,
                    payload: decryptData[1],
                });
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const Escalations = ({ payload, caller, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
        let urlValue;
        if (localStorage.getItem('isVendor') == "true") {
            const vendorurl = url.VendorURL + '/cv'
            urlValue = `${vendorurl}`
        } else {
            if (sessionValue.login_response.cf_access == true && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == true) {
                urlValue = `${url.Login}`
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == false) {
                urlValue = `${url.Login}`
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.vndr_access == true) {
                const vendorurl = url.VendorURL + '/cv'
                urlValue = `${vendorurl}`
            } else if (sessionValue.login_response.cf_access == true && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.vndr_access == false) {
                urlValue = `${url.Login}`
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == true) {
                const vendorurl = url.VendorURL + '/cv'
                urlValue = `${vendorurl}`
            }
            else {
                urlValue = `${url.Login}`
            }
        }
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: urlValue + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)

        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        }
        // else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
        //     executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
        //     (executeRequest.status === 401 || executeRequest.status === 503)) {
        //     dispatch({
        //         type: TOGGLE_COMMON_LOADER
        //     });
        //     Toaster.error("Service Unavailable")
        // } 
        else if (executeRequest.code == 'ERR_NETWORK') {
            dispatch({
                type: TOGGLE_COMMON_LOADER,
                // payload: true
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'GetEscalationsSuccess') {
                dispatch({
                    type: ESCALATION,
                    payload: decryptData[1],
                });
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const remainder = ({ payload, caller, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
        let urlValue;
        if (localStorage.getItem('isVendor') == "true") {
            const vendorurl = url.VendorURL + '/cv'
            urlValue = `${vendorurl}`
        } else {
            if (sessionValue.login_response.cf_access == true && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == true) {
                urlValue = `${url.Login}`
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == false) {
                urlValue = `${url.Login}`
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.vndr_access == true) {
                const vendorurl = url.VendorURL + '/cv'
                urlValue = `${vendorurl}`
            } else if (sessionValue.login_response.cf_access == true && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.vndr_access == false) {
                urlValue = `${url.Login}`
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == true) {
                const vendorurl = url.VendorURL + '/cv'
                urlValue = `${vendorurl}`
            }
            else {
                urlValue = `${url.Login}`
            }
        }
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: urlValue + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        }
        // else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
        //     executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
        //     (executeRequest.status === 401 || executeRequest.status === 503)) {
        //     dispatch({
        //         type: TOGGLE_COMMON_LOADER
        //     });
        //     Toaster.error("Service Unavailable")
        // } 
        else if (executeRequest.code == 'ERR_NETWORK') {
            dispatch({
                type: TOGGLE_COMMON_LOADER,
                // payload: true
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'GetRemindersSuccess') {
                dispatch({
                    type: REMAINDER,
                    payload: decryptData[1],
                });
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const Message = ({ payload, caller, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
        let urlValue;
        if (localStorage.getItem('isVendor') == "true") {
            const vendorurl = url.VendorURL + '/cv'
            urlValue = `${vendorurl}`
        } else {
            if (sessionValue.login_response.cf_access == true && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == true) {
                urlValue = `${url.Login}`
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == false) {
                urlValue = `${url.Login}`
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.vndr_access == true) {
                const vendorurl = url.VendorURL + '/cv'
                urlValue = `${vendorurl}`
            } else if (sessionValue.login_response.cf_access == true && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.vndr_access == false) {
                urlValue = `${url.Login}`
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == true) {
                const vendorurl = url.VendorURL + '/cv'
                urlValue = `${vendorurl}`
            }
            else {
                urlValue = `${url.Login}`
            }
        }
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: urlValue + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        }
        // else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
        //     executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
        //     (executeRequest.status === 401 || executeRequest.status === 503)) {
        //     dispatch({
        //         type: TOGGLE_COMMON_LOADER
        //     });
        //     Toaster.error("Service Unavailable")
        // } 
        else if (executeRequest.code == 'ERR_NETWORK') {
            dispatch({
                type: TOGGLE_COMMON_LOADER,
                // payload: true
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'GetMessagesSuccess') {
                dispatch({
                    type: MESSAGE,
                    payload: decryptData[1],
                });
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

//success GetRemindersSuccess GetStatutorySuccess GetEscalationsSuccess
export const notificationshowall = ({ payload, caller, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'GetRemindersSuccess' || decryptData[0] === 'GetEscalationsSuccess' || decryptData[0] === 'GetMessagesSuccess' || decryptData[0] === 'GetStatutorySuccess') {
                dispatch({
                    type: NOTIFICATIONSHOWALL,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const updatenotification = ({ payload, paramid, executeCancel, getpayload }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': paramid
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'UpdateNotificationStatusSuccess') {
                if (decryptData[1] && decryptData[1].notification_details.length > 0) {
                    dispatch({
                        type: NOTIFICATIONMODAL,
                        payload: decryptData[1].notification_details[0],
                    });
                }
                setTimeout(() => {
                    dispatch(
                        notificationshowall({
                            payload: getpayload,
                            caller: paramid
                        })
                    )
                }, 1000)

            } else if (decryptData[0] === 'UpdateStatutoryNotificationStatusSuccess') {
                console.log(decryptData[0] ,'UpdateStatutoryNotificationStatusSuccess')
                if (decryptData[1] && decryptData[1].statutory_notification_details.length > 0) {
                    dispatch({
                        type: NOTIFICATIONMODAL,
                        payload: decryptData[1],
                    });
                }
                setTimeout(() => {
                    dispatch(
                        notificationshowall({
                            payload: getpayload,
                            caller: paramid
                        })
                    )
                }, 1000)

            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER,
                    payload: true
                });
                // Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const updatenotificationnew = ({ payload, paramid, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': paramid
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'UpdateNotificationStatusSuccess' || decryptData[0] === 'UpdateStatutoryNotificationStatusSuccess') {
                dispatch({
                    type: NOTIFICATIONMODAL,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const Loginvendor = ({ payload, executeCancel, paramid, navigate }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/login`,
            headers: head,
            executeCancel,
            body: data1
        })

        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))

            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else if (decryptData[0] === 'Session Expired') {
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: LOGINVENDOR,
                    payload: decryptData,
                });
                window.location.href = decryptData[1][0].Static[1].form_url
            }
        }
    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err);
    }
};

export const LoginCompfieproduct = ({ payload, executeCancel, paramid, navigate }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/login`,
            headers: head,
            executeCancel,
            body: data1
        })


        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))

            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else if (decryptData[0] === 'Session Expired') {
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: LOGINCOMPFIEPRODUCT,
                    payload: decryptData,
                });
                window.location.href = decryptData[1][0].Static[1].form_url
            }
        }
    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err);
    }
};

export const LoginLitigation = ({ payload, executeCancel, paramid, navigate }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))

            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {

                dispatch({
                    type: LOGINLITIGATION,
                    payload: decryptData,
                });
                window.location.href = decryptData[1][0].Static[1].form_url
            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err);
    }
};