import React, { Fragment, useState, useRef, useEffect } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Popover } from 'antd';
// import { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { AssigneewiseComplianceFilters, AssigneeWiseCompliancesChart, AssigneeWiseCompliancesChartexport, AssigneeWiseComplianceDrillDown, AssigneewiseYearwiseCompliances } from '../../../src/Store/Action/Dashboard/Assigneewisecompliance';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RightOutlined, ArrowLeftOutlined, EyeOutlined, ExportOutlined, FullscreenOutlined, FilterOutlined, ExclamationCircleFilled, ExclamationOutlined, InfoCircleTwoTone, DownOutlined } from '@ant-design/icons';
import { ASSIGNEE_WISE_COMPLIANCE_EXPORT_RESET } from './../../../src/Store/types/index'
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../Libs/fullscreenmode';
import ScrollButton from '../Scrollbar';
import { findIndex, isArray } from 'lodash';
import url, { getUrl } from './../../Libs/URL'
import { HomeRedirect, CountrySplit } from './../../Libs/country'
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()
const urls = getUrl()

const mapStateToProps = (state) => ({
    domain_data: state.Assigneescorecard.assigneescoredata.domain_data,
    tableData: state.Assigneescorecard.assigneescoredata.getTableData,
    assigneewisecompliancefilter: state.AssigneeWiseCompliance.assigneewisecompliance,
})

const AssigneeWiseCompliance = ({
    AssigneewiseComplianceFilters,
    AssigneeWiseCompliancesChart,
    AssigneeWiseCompliancesChartexport,
    AssigneewiseYearwiseCompliances,
    AssigneeWiseComplianceDrillDown,
    assigneewisecompliancefilter: { assigneewisecompliancefilters, assigneewisecomplianceshow, assigneewisecompliancedrilldown, assigneeyearwisecompliance, assigneewisecomplianceexport }
}) => {

    const _ = require("lodash");
    const dispatch = useDispatch();
    const historynew = useHistory();
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const SelectedEntity = localStorage.getItem('SelectedEntity')
    const [yearlyModalOpen, setYearlyModalOpen] = useState(false);
    const [historytab, setHistorytab] = useState(false);
    const [division, setDivision] = useState([])
    const [assigneeview, setAssigneeview] = useState(false)

    const [showmorecount, Setshowmorecount] = useState([])

    const [finalcountry, setFinalcountry] = useState([])
    const [unit, setUnit] = useState([])
    const [exitCollapse, setExitCollapse] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [assigneeName, setAssigneeName] = useState('')
    const [user, setUser] = useState([])
    // const paramid = location.pathname;
    let [count, setcount] = useState(0)
    let [historycount, setHistorycount] = useState(0)
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [tableshow, setTableShow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    const [businessGroup, setBusinessGroup] = useState([])
    const [showmorehistory, setShowmorehistory] = useState({
        user_id: '',
        domain_id: '',
        unit_id: ''
    })
    const [compliedmap, setCompliedmap] = useState([])
    const [tempcompliedmap, setTempCompliedmap] = useState([])
    const [delayedmap, setDelayedmap] = useState([])
    const [tempdelayedmap, setTempDelayedmap] = useState([])
    const [inprogressmap, setInprogressmap] = useState([])
    const [tempinprogressmap, setTempInprogressmap] = useState([])
    const [inprogressoverduemap, setInprogressoverduemap] = useState([])
    const [tempinprogressoverduemap, setTempInprogressoverduemap] = useState([])
    const [notcompliedmap, setNotcompliedmap] = useState([])
    const [tempnotcompliedmap, setTempNotcompliedmap] = useState([])
    const [finallegalentity, setFinallegalentity] = useState([])
    const [tempfinallegalentity, setTempfinallegalentity] = useState([])
    const [assigneewisecompliance, setAssigneewisecompliance] = useState({
        country: '',
        countryName: '',
        bg: '',
        bgname: '',
        division: '',
        divisionName: '',
        unit: '',
        unitName: '',
        user: '',
        userName: '',
        legal_entity: '',
        le_id: '',
        domain: '',
        domainName: ''
    })
    const [checkBgId, setCheckBgId] = useState([])
    // assigneewisecompliance.legal_entity
    const [entityData, setEntityData] = useState([])
    const [yearlyview, setYearlyview] = useState({
        user_id: '',
        domain_id: '',
        domain_name: '',
        unit_id: '',
        year: ''
    })
    const [paramid, setParamId] = useState("")

    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;

    // useEffect(()=>{
    //     let uniqueDiv = _.uniqBy(assigneewisecompliancefilters && assigneewisecompliancefilters.countries, function (e) {
    //         return e.c_id;
    //     });
    //     setFinalcountry(uniqueDiv)
    // },[assigneewisecompliancefilters])

    // useEffect(()=>{
    //     if(assigneewisecompliancefilters && assigneewisecompliancefilters.business_groups && assigneewisecompliancefilters.business_groups.length <= 0){
    //         setLe_entity(assigneewisecompliancefilters.legal_entities)
    //     }
    //     else{
    //         if (assigneewisecompliancefilters && Object.keys(assigneewisecompliancefilters).length) {
    //             let uniqle = _.uniqBy(assigneewisecompliancefilters && assigneewisecompliancefilters.business_groups, function (e) {
    //                 return e.bg_id;
    //             });
    //             let uniqle_id = uniqle.map((item) => { return item.bg_id })
    //             let uniqle_bg = assigneewisecompliancefilters &&  assigneewisecompliancefilters.legal_entities.length > 0 &&  assigneewisecompliancefilters.legal_entities.filter(exist => uniqle_id.some(exist2 => exist.bg_id === exist2));
    //             setLe_entity(uniqle_bg)


    //         // setDivision(assigneewisecompliancefilters && assigneewisecompliancefilters.client_divisions)
    //             // setUser(assigneewisecompliancefilters && assigneewisecompliancefilters.users)
    //             // setUnit(assigneewisecompliancefilters && assigneewisecompliancefilters.units)

    //         }
    //     }

    // },[assigneewisecompliancefilters])

    // useEffect(() => {
    //     if (entityid != null) {
    //         let temp = localStorage.getItem("SelectedEntity")
    //         let tempcountry = _.filter(sessionArr, { le_name: temp })
    //         setAssigneewisecompliance({
    //             ...assigneewisecompliance,
    //             // legal_entity : localStorage.getItem('SelectedEntity') ,
    //             // le_id:localStorage.getItem('SelectedEntityid'),
    //             country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
    //             countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
    //         })
    //     }
    // }, [entityid != null])

    useEffect(() => {
        if (assigneewisecompliancefilters) {
            if (assigneewisecompliancefilters && assigneewisecompliancefilters.legal_entities && assigneewisecompliancefilters.legal_entities.length > 0) {
                let id = assigneewisecompliancefilters.legal_entities.map((item, i) => {
                    return item.bg_id
                })
                setCheckBgId(id)
            }
        }

    }, [assigneewisecompliancefilters])

    useEffect(() => {
        if (assigneewisecompliancefilters) {
            let uniqCountry = _.uniqBy(assigneewisecompliancefilters && assigneewisecompliancefilters.countries
                , function (e) {
                    return e.c_id;
                });
            setFinalcountry(uniqCountry)
            // if (assigneewisecompliancefilters && assigneewisecompliancefilters.business_groups && assigneewisecompliancefilters.business_groups.length > 0) {
            //     if (checkBgId.includes(null)) {
            //         setFinallegalentity(assigneewisecompliancefilters.legal_entities)
            //     }
            //     else {
            //         let uniqBusiness = _.uniqBy(assigneewisecompliancefilters && assigneewisecompliancefilters.business_groups
            //             , function (e) {
            //                 return e.bg_id;
            //             });
            //         const filteredEntity = assigneewisecompliancefilters && assigneewisecompliancefilters.legal_entities.filter((elem) => uniqBusiness.find(({ bg_id }) => elem.bg_id === bg_id));
            //         if (finallegalentity && finallegalentity.length === 0) {
            //             setFinallegalentity(filteredEntity)
            //         }
            //     }
            //     // for(let i in uniqBusiness){
            //     //     let filteredLegalEntity = _.filter(assigneewisecompliancefilters && assigneewisecompliancefilters.legal_entities,{bg_id:uniqBusiness[i].bg_id})
            //     //     console.log(filteredLegalEntity,'filteredLegalEntity');
            //     // }
            // }
            // else if ((assigneewisecompliancefilters && assigneewisecompliancefilters.business_groups && assigneewisecompliancefilters.business_groups.length <= 0) && (assigneewisecompliancefilters && assigneewisecompliancefilters.legal_entities && assigneewisecompliancefilters.legal_entities.length > 0)) {
            //     if (finallegalentity && finallegalentity.length === 0) {
            //         setFinallegalentity(assigneewisecompliancefilters && assigneewisecompliancefilters.legal_entities)
            //     }
            // }
        }

    }, [assigneewisecompliancefilters, finallegalentity.length === 0, checkBgId])


    useEffect(() => {
        // if (assigneewisecompliancefilters && assigneewisecompliancefilters.legal_entities && assigneewisecompliancefilters.legal_entities.length <= 0) {
        if (finallegalentity && finallegalentity.length === 0) {
            setFinallegalentity(sessionArr)
        }
        // }

    }, [finallegalentity])

    const gettooltipfun = (record) => {
        const content = (
            <table
                style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    border: "1px solid #636262",
                    tableLayout: "auto",
                }}
            >
                <tbody>
                    <tr>
                        <th style={{ border: "1px solid #636262" }}>
                            {localStorage.getItem('isVendor') == 'true' ? <b>Vendor Name</b> : <b>Unit Name</b>}
                        </th>
                        <td style={{ border: "1px solid #636262" }}>
                            <b>{record.unit_name}</b>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ border: "1px solid #636262" }}>
                            <b>Division Name</b>
                        </th>
                        <td style={{ border: "1px solid #636262" }}>
                            <b>{record.division_name}</b>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ border: "1px solid #636262" }}>
                            <b>Category Name</b>
                        </th>
                        <td style={{ border: "1px solid #636262" }}>
                            <b>{record.category_name}</b>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ border: "1px solid #636262" }}>
                            <b>Act/Rules</b>
                        </th>
                        <td style={{ border: "1px solid #636262" }}>
                            <b>{record.statutory_mapping}</b>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ border: "1px solid #636262" }}>
                            <b>Compliance Description</b>
                        </th>
                        <td style={{ border: "1px solid #636262" }}>
                            <b>{record.description}</b>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ border: "1px solid #636262" }}>
                            <b>Penal Consequqnces</b>
                        </th>
                        <td style={{ border: "1px solid #636262" }}>
                            <b>{record.penal_consequences}</b>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
        return content;
    };

    useEffect(() => {
        if (assigneewisecomplianceexport && assigneewisecomplianceexport.link) {
            window.open(urls + '/' + assigneewisecomplianceexport.link);
            dispatch({
                type: ASSIGNEE_WISE_COMPLIANCE_EXPORT_RESET
            });
        }

    }, [assigneewisecomplianceexport])

    const handleOk = () => {
        setYearlyModalOpen(false);
    };
    const handleCancel = () => {
        setYearlyModalOpen(false);
    };

    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetAssigneeWiseCompliancesChart",
                        {
                            "c_id": Number(assigneewisecompliance.country),
                            "bg_id": assigneewisecompliance.bg ? Number(assigneewisecompliance.bg) : null,
                            // "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [assigneewisecompliance.country],
                            // "le_ids": assigneewisecompliance.le_id ? [assigneewisecompliance.le_id] : (localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : ''),
                            "le_ids": [assigneewisecompliance.le_id],
                            "div_id": assigneewisecompliance.division ? Number(assigneewisecompliance.division) : null,
                            "unit_id": assigneewisecompliance.unit ? Number(assigneewisecompliance.unit) : null,
                            "usr_id": assigneewisecompliance.user ? Number(assigneewisecompliance.user) : null,
                            "csv": false
                        }
                    ]
                }
            ]
            AssigneeWiseCompliancesChart({
                payload: payload,
                paramid: paramid
            })
        }
    }
    const download = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            // setTableShow(true)
            // setKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetAssigneeWiseCompliancesChart",
                        {
                            "c_id": Number(assigneewisecompliance.country),
                            "bg_id": assigneewisecompliance.bg ? Number(assigneewisecompliance.bg) : null,
                            "le_ids": [assigneewisecompliance.le_id],
                            "div_id": assigneewisecompliance.division ? Number(assigneewisecompliance.division) : null,
                            "unit_id": assigneewisecompliance.unit ? Number(assigneewisecompliance.unit) : null,
                            "usr_id": assigneewisecompliance.user ? Number(assigneewisecompliance.user) : null,
                            "csv": true
                        }
                    ]
                }
            ]

            AssigneeWiseCompliancesChartexport({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const onChange = (key) => {
        console.log(key);
    };

    const yearview = (assignee_name, domain_name, unit_id, user_id, domain_id) => {
        setAssigneeName(assignee_name)
        setYearlyModalOpen(true)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    'GetAssigneewiseYearwiseCompliances', {
                        'c_id': Number(assigneewisecompliance.country),
                        'u_id': unit_id,
                        'usr_id': user_id,
                        "le_ids": [assigneewisecompliance.le_id],
                        'd_ids': [Number(domain_id)],

                    }
                ]
            }
        ]
        AssigneewiseYearwiseCompliances({
            payload: payload,
            paramid: paramid
        })
    }

    const historyview = (user_id, domain_id, unit_id) => {
        setHistorytab(true)
        setFilterTaskAcc(true)
        setTableShow(false)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetAssigneeWiseComplianceDrillDown",
                    {
                        "c_id": Number(assigneewisecompliance.country),
                        "assignee_id": Number(user_id),
                        "d_ids": [Number(domain_id)],
                        "chart_year": 2023,
                        "unit_id": Number(unit_id),
                        "start_count": showmorecount.length,
                        "le_ids": [assigneewisecompliance.le_id],
                    }
                ]

            }
        ]
        AssigneeWiseComplianceDrillDown({
            payload: payload,
            paramid: paramid
        })
    }

    const historyviewnew = (year) => {
        setHistorytab(true)
        setFilterTaskAcc(true)
        setTableShow(false)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetAssigneeWiseComplianceDrillDown",
                    {
                        "c_id": Number(assigneewisecompliance.country),
                        "assignee_id": Number(yearlyview.user_id),
                        "d_ids": [Number(yearlyview.domain_id)],
                        "chart_year": Number(year),
                        "unit_id": Number(yearlyview.unit_id),
                        "start_count": showmorecount.length,
                        "le_ids": [assigneewisecompliance.le_id],
                    }
                ]

            }
        ]
        AssigneeWiseComplianceDrillDown({
            payload: payload,
            paramid: paramid
        })
    }

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down) {
            let compliedval = Object.values(assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.complied_map)
            if (compliedval.length > 0) {
                setCompliedmap([assigneewisecompliancedrilldown.assignee_wise_drill_down.complied_map])
            } else {
                setCompliedmap([])
            }
            let delayedval = Object.values(assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.delayed_map)
            if (delayedval.length > 0) {
                setDelayedmap([assigneewisecompliancedrilldown.assignee_wise_drill_down.delayed_map])
            } else {
                setDelayedmap([])
            }
            let inprogressval = Object.values(assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.inprogress_map)
            if (inprogressval.length > 0) {
                setInprogressmap([assigneewisecompliancedrilldown.assignee_wise_drill_down.inprogress_map])
            } else {
                setInprogressmap([])
            }
            let inprogressoverdueval = Object.values(assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.inprogress_overdue_map)
            if (inprogressoverdueval.length > 0) {
                setInprogressoverduemap([assigneewisecompliancedrilldown.assignee_wise_drill_down.inprogress_overdue_map])
            } else {
                setInprogressoverduemap([])
            }
            let notcompliedval = Object.values(assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.not_complied_map)
            if (notcompliedval.length > 0) {
                setNotcompliedmap([assigneewisecompliancedrilldown.assignee_wise_drill_down.not_complied_map])
            } else {
                setNotcompliedmap([])
            }
        }
    }, [assigneewisecompliancedrilldown])

    useEffect(() => {
        // {compliedmap && compliedmap.length > 0 ? compliedmap.map((item, i) => {
        //     let keys = Object.keys(item);
        //     let values = Object.values(item);
        //     let k = []
        //     for (let i in values) {
        //         for (let j in values[i]) {
        //             k.push(values[i][j])
        //         }
        //     }
        // }):''}
        // if (compliedmap.length > 0 || delayedmap.length > 0 || inprogressmap.length > 0 || inprogressoverduemap.length > 0 || notcompliedmap.length > 0) {
        let temp = showmorecount
        if (compliedmap && compliedmap.length > 0) {
            compliedmap.map((item, i) => {
                let sample = tempcompliedmap
                let keys = Object.keys(item);
                let values = Object.values(item);
                // let k = []
                for (let i in values) {
                    for (let j in values[i]) {
                        temp.push(values[i][j])
                        sample.push(values[i][j])
                    }
                }
                setTempCompliedmap([...sample])
            })
        }
        // if (delayedmap && delayedmap.length > 0) {
        //     for (let i in delayedmap) {
        //         temp.push(delayedmap[i])
        //     }
        // }
        if (delayedmap && delayedmap.length > 0) {
            delayedmap.map((item, i) => {
                let sample = tempdelayedmap
                let keys = Object.keys(item);
                let values = Object.values(item);
                // let k = []
                for (let i in values) {
                    for (let j in values[i]) {
                        temp.push(values[i][j])
                        sample.push(values[i][j])
                    }
                }
                setTempDelayedmap([...sample])
            })
        }
        // if (inprogressmap && inprogressmap.length > 0) {
        //     for (let i in inprogressmap) {
        //         temp.push(inprogressmap[i])
        //     }
        // }
        if (inprogressmap && inprogressmap.length > 0) {
            inprogressmap.map((item, i) => {
                let sample = tempinprogressmap
                let keys = Object.keys(item);
                let values = Object.values(item);
                // let k = []
                for (let i in values) {
                    for (let j in values[i]) {
                        temp.push(values[i][j])
                        sample.push(values[i][j])
                    }
                }
                setTempInprogressmap([...sample])
            })
        }
        // if (inprogressoverduemap && inprogressoverduemap.length > 0) {
        //     for (let i in inprogressoverduemap) {
        //         temp.push(inprogressoverduemap[i])
        //     }
        // }
        if (inprogressoverduemap && inprogressoverduemap.length > 0) {
            inprogressoverduemap.map((item, i) => {
                let sample = tempinprogressoverduemap
                let keys = Object.keys(item);
                let values = Object.values(item);
                // let k = []
                for (let i in values) {
                    for (let j in values[i]) {
                        temp.push(values[i][j])
                        sample.push(values[i][j])
                    }
                }
                setTempInprogressoverduemap([...sample])
            })
        }
        // if (notcompliedmap && notcompliedmap.length > 0) {
        //     for (let i in notcompliedmap) {
        //         temp.push(notcompliedmap[i])
        //     }
        // }
        if (notcompliedmap && notcompliedmap.length > 0) {
            notcompliedmap.map((item, i) => {
                let sample = tempnotcompliedmap
                let keys = Object.keys(item);
                let values = Object.values(item);
                // let k = []
                for (let i in values) {
                    for (let j in values[i]) {
                        temp.push(values[i][j])
                        sample.push(values[i][j])
                    }
                }
                setTempNotcompliedmap([...sample])
            })
        }
        Setshowmorecount([...temp])
        // }
    }, [compliedmap, delayedmap, inprogressmap, inprogressoverduemap, notcompliedmap])

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    useEffect(() => {
        if (SelectedEntity != 'All Legal Entity') {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            // setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            // setAssigneewisecompliance({
            //     ...assigneewisecompliance,

            // })
            let businessName = _.filter(sessionArr, { le_name: SelectedEntity })
            setAssigneewisecompliance({
                ...assigneewisecompliance,
                bg: businessName && businessName.length > 0 && businessName[0].bg_id,
                bgname: businessName && businessName.length > 0 && businessName[0].bg_name,
                legal_entity: localStorage.getItem('SelectedEntity'),
                le_id: localStorage.getItem('SelectedEntityid'),
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
            })
        }
    }, [SelectedEntity])

    useEffect(() => {
        if (isAuth) {
            // if (legalstate === true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetAssigneewiseComplianesFilters",
                        {}
                    ]

                }
            ]
            if (paramid != '') {

                AssigneewiseComplianceFilters({
                    payload: payload,
                    paramid: paramid
                })
            }
            // Setlegalstate(false)
            // }
        }

    }, [isAuth, entityid, paramid])

    useEffect(() => {
        {
            var the_arr = location.pathname.split('/');
            the_arr.pop();
            let a = the_arr.join('/')
            setParamId(a)
        }
    }, []);

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(assigneewisecompliance.legal_entity)
            let unit = _.filter(assigneewisecompliancefilters.units, { legal_entity_id: localStorage.getItem('SelectedEntityid') })
            setUnit(unit)
        }
    }, [entityid])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            let unit = _.filter(assigneewisecompliancefilters.units, { legal_entity_id: localStorage.getItem('SelectedEntityid') })
            setUnit(unit)
            let users = _.filter(assigneewisecompliancefilters.users, { le_id: localStorage.getItem('SelectedEntityid') })
            setUser(users)
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity', assigneewisecompliancefilters])

    // useEffect(() => {

    //     if (localStorage.getItem('SelectedEntityid') != null) {
    //         setCurrentEntity(localStorage.getItem('SelectedEntityid'))
    //     }
    // }, [localStorage.getItem('SelectedEntityid')])

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
        window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    return (
        <Fragment>
            <div id='page-wrapper' className="page-wrapper">
                {historytab == true ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''}
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-sm-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Dashboard</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Assignee Wise Compliance</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-4 col-md-6 text-md-end text-sm-start">
                            <Tooltip title='Filter' placement='top'><Button
                                className="me-2"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button></Tooltip>
                            {historytab == true ? <>
                                <Tooltip placement='top'> <Button type="primary" shape="round" className='addbutton'
                                    icon={<ArrowLeftOutlined />} size='default' onClick={() => { window.location.reload() }}>
                                    Back
                                </Button></Tooltip>
                            </> : ''}
                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>

                        </div>

                    </div>
                </div>
                <div className="container-fluid pt-0">
                    <div className="row">
                        <div className="col-12">
                            <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory card-body '} style={{ padding: "0px" }}>
                                {historytab == false ? <>
                                    <Collapse className='cric' defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }} onChange={onChange} >
                                        <Panel style={{ marginBottom: '5px' }} header="Assignee Wise Compliance" key={key}>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className='col-md-4'>
                                                        <label htmlFor=""><b>Country:</b> <span style={{ "color": "red" }}>*</span></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select style={{ marginTop: "5px", width: "100%" }}
                                                                    size="default"
                                                                    placeholder="Enter Country"
                                                                    onChange={(value, data) => {
                                                                        let filteredEntity = _.filter(finallegalentity, { c_id: Number(value) })
                                                                        // setFinallegalentity(filteredEntity)
                                                                        setTempfinallegalentity(filteredEntity)
                                                                        let business = _.filter(assigneewisecompliancefilters.business_groups, { c_id: Number(value) })
                                                                        let uniqueDiv = _.uniqBy(business, function (e) {
                                                                            return e.bg_id;
                                                                        });
                                                                        setBusinessGroup(uniqueDiv)
                                                                        setAssigneewisecompliance({
                                                                            ...assigneewisecompliance,
                                                                            country: value != undefined ? value : '',
                                                                            countryName: data != undefined ? data.children : '',
                                                                            bg: '',
                                                                            bgname: '',
                                                                            division: '',
                                                                            divisionName: '',
                                                                            unit: '',
                                                                            unitName: '',
                                                                            user: '',
                                                                            userName: '',
                                                                            legal_entity: '',
                                                                            le_id: '',
                                                                            domain: '',
                                                                            domainName: ''

                                                                        })
                                                                    }}
                                                                    value={assigneewisecompliance.countryName || undefined}
                                                                    allowClear={true}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    display="none"
                                                                >
                                                                    {finalcountry && finalcountry.length > 0 && finalcountry.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'country',
                                                                    assigneewisecompliance.countryName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p style={{ marginLeft: "10px" }}>{assigneewisecompliance.countryName}</p>
                                                        }
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label htmlFor=""><b>Legal Entity:</b> <span style={{ "color": "red" }}>*</span></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select style={{ marginTop: "5px", width: "100%" }}
                                                                    allowClear={true}
                                                                    size="default"
                                                                    disabled={assigneewisecompliance.countryName == "" ? true : false}
                                                                    placeholder="Select Legal Entity"
                                                                    onChange={(data, value) => {
                                                                        let users = _.filter(assigneewisecompliancefilters.users, { le_id: data })
                                                                        setUser(users)
                                                                        let unit = _.filter(assigneewisecompliancefilters.units, { legal_entity_id: data })
                                                                        setUnit(unit)
                                                                        let filteredDiv = _.filter(assigneewisecompliancefilters && assigneewisecompliancefilters.client_divisions, { le_id: data })
                                                                        setDivision(filteredDiv)
                                                                        setCurrentEntity(data)
                                                                        setAssigneewisecompliance({
                                                                            ...assigneewisecompliance,
                                                                            le_id: data != undefined ? data : '',
                                                                            legal_entity: data != undefined ? value.children : '',
                                                                            bg: '',
                                                                            bgname: '',
                                                                            unit: '',
                                                                            unitName: '',
                                                                            division: '',
                                                                            divisionName: '',
                                                                            user: '',
                                                                            userName: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={assigneewisecompliance.legal_entity || undefined}
                                                                    display="none"
                                                                >
                                                                    {tempfinallegalentity && tempfinallegalentity.length > 0 && tempfinallegalentity.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.le_id}>
                                                                                {item.le_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'legalentity',
                                                                    assigneewisecompliance.le_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Legal Entity Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p style={{ marginLeft: "10px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                                        }
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Business Group:</b></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    disabled={assigneewisecompliance.country == "" ? true : false}
                                                                    placeholder="Select Business Group"
                                                                    onChange={(value, data) => {
                                                                        setAssigneewisecompliance({
                                                                            ...assigneewisecompliance,
                                                                            bg: value != undefined ? value : '',
                                                                            bgname: data != undefined ? data.children : '',
                                                                            le_id: '',
                                                                            legal_entity: '',
                                                                        })
                                                                        setCurrentEntity()
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={assigneewisecompliance.bgname || undefined}
                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                >
                                                                    {businessGroup && businessGroup.length > 0 && businessGroup.map((item, i) => {
                                                                        if (item.bg_name !== null) {
                                                                            return (
                                                                                <Option key={item.bg_id}>
                                                                                    {item.bg_name}
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    })}
                                                                </Select>
                                                            </div> :
                                                            <p>{assigneewisecompliance.bgname ? assigneewisecompliance.bgname : '-'}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: "10px" }}>
                                                <div className="row">
                                                    <div className='col-md-4'>
                                                        <label> {localStorage.getItem('isVendor') == 'true' ? <b>Vendor:</b> : <b>Unit:</b>}</label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                disabled={assigneewisecompliance.legal_entity == "" ? true : false}
                                                                size="default"
                                                                placeholder={localStorage.getItem('isVendor') == 'true' ? "Enter Vendor" : "Enter Unit"}
                                                                onChange={(value, data) => {
                                                                    // let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                    // setCategory(CategoryData)
                                                                    setAssigneewisecompliance({
                                                                        ...assigneewisecompliance,
                                                                        unit: value != undefined ? value : '',
                                                                        unitName: data != undefined ? data.children : '',
                                                                        division: '',
                                                                        divisionName: '',
                                                                        user: '',
                                                                        userName: '',
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneewisecompliance.unitName || undefined}
                                                                style={{ width: "100%", marginTop: "5px" }}
                                                            >
                                                                {unit && unit.length > 0 && unit.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.unit_id}>
                                                                            {item.unit_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Division:</b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                disabled={assigneewisecompliance.legal_entity == "" ? true : false}
                                                                size="default"
                                                                placeholder="Enter Division"
                                                                onChange={(value, data) => {
                                                                    // let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                    // setCategory(CategoryData)
                                                                    setAssigneewisecompliance({
                                                                        ...assigneewisecompliance,
                                                                        division: value != undefined ? value : '',
                                                                        divisionName: data != undefined ? data.children : ''
                                                                    })
                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneewisecompliance.divisionName || undefined}
                                                                style={{ width: "100%", marginTop: "5px" }}
                                                            >
                                                                {division && division.length > 0 && division.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.div_id}>
                                                                            {item.div_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>User:</b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                disabled={assigneewisecompliance.legal_entity == "" ? true : false}
                                                                size="default"
                                                                placeholder="Enter User"
                                                                onChange={(value, data) => {
                                                                    //     let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                    //     setCategory(CategoryData)
                                                                    setAssigneewisecompliance({
                                                                        ...assigneewisecompliance,
                                                                        user: value != undefined ? value : '',
                                                                        userName: data != undefined ? data.children : ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneewisecompliance.userName || undefined}
                                                                style={{ width: "100%", marginTop: "5px" }}
                                                            >
                                                                {user && user.length > 0 && user.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.user_id}>
                                                                            {item.employee_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'text-center popupbtncolour mt-2 mb-1 ' + localStorage.getItem('currentTheme')}>
                                                <Button type="primary" shape="round" icon={<EyeOutlined />} className={'addbutton me-1 ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                    onClick={() => { onshow() }}>
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" icon={<ExportOutlined />} className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852" }}
                                                    onClick={() => { download() }}
                                                >
                                                    Export
                                                </Button>
                                            </div>
                                        </Panel>
                                    </Collapse>

                                    {tableshow == true ?
                                        <>
                                            <Card className='rounded-3'>
                                                {assigneewisecompliance.userName ? <p>User Selected : {assigneewisecompliance.userName}</p> : ''}
                                                <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                                    <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content audittrailtable assign-wise-compliance'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            {assigneewisecompliance.userName ?
                                                                                <>
                                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "200px" }} rowspan="2">{localStorage.getItem('isVendor') == 'true' ? 'Vendor' : 'Unit'}</th>
                                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "200px" }} rowspan="2">Domain</th>
                                                                                </> :
                                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "200px" }} rowspan="2">Assignee</th>
                                                                            }
                                                                            {/* <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "200px" }} rowspan="2">Assignee</th> */}
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "100px" }} rowspan="2">Total Compliance</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "80px" }} rowspan="2">Complied</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "120px" }} rowspan="2"><center>Compliance Delayed</center></th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "100px" }} colspan="2">In progress</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "100px" }} rowspan="2">Not Complied</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "60px" }} rowspan="2">History</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "100px" }}>Within Due Date</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "100px" }}>OverDue</th>
                                                                        </tr>
                                                                    </thead>
                                                                    {assigneewisecomplianceshow && assigneewisecomplianceshow.assingee_data && assigneewisecomplianceshow.assingee_data.length > 0 ?
                                                                        assigneewisecomplianceshow.assingee_data.map((item, i) => {
                                                                            return <>
                                                                                <tbody className='ant-table-tbody'>
                                                                                    <tr>
                                                                                        {/* <td colspan='3'>
                                                                                            <label>Unit Name : </label>{item.unit_name}
                                                                                        </td>
                                                                                        <td colspan='5'>
                                                                                            <label>Domain : </label>{item.assignee_wise_details[0].domain_wise_details[0].domain_name}
                                                                                        </td> */}
                                                                                        {assigneewisecompliance.userName ?
                                                                                            // <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                            //     <div className='bg-white border-radius-reports'>
                                                                                            //         <div className="col-md-12">
                                                                                            //             <div className='row m-2'>
                                                                                            //                 <div className='col-md-6' >
                                                                                            //                     <p> {item.unit_name}</p>
                                                                                            //                 </div>
                                                                                            //                 <div className='col-md-6' >
                                                                                            //                     <p>{item.assignee_wise_details[0].domain_wise_details[0].domain_name}</p>
                                                                                            //                 </div>
                                                                                            //                 {/* <div className='col-md-3' >
                                                                                            //              <label><b>Category :</b> {item[0].category_name}</label>
                                                                                            //          </div>
                                                                                            //          <div className='col-md-3' >
                                                                                            //              <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                            //          </div> */}
                                                                                            //             </div>
                                                                                            //         </div>
                                                                                            //     </div>
                                                                                            // </td>
                                                                                            ''
                                                                                            :
                                                                                            <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                                <div className='bg-white border-radius-reports'>
                                                                                                    <div className="col-md-12">
                                                                                                        <div className='row m-2'>
                                                                                                            <div className='col-md-6' >
                                                                                                                <label><b>{localStorage.getItem('isVendor') == 'true' ? 'Vendor Name :' : 'Unit Name '}</b> {item.unit_name}</label>
                                                                                                            </div>
                                                                                                            <div className='col-md-6' >
                                                                                                                <label><b>Domain : </b> {item.assignee_wise_details[0].domain_wise_details[0].domain_name}</label>
                                                                                                            </div>
                                                                                                            {/* <div className='col-md-3' >
                                                                                                            <label><b>Category :</b> {item[0].category_name}</label>
                                                                                                        </div>
                                                                                                        <div className='col-md-3' >
                                                                                                            <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                                        </div> */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                        }
                                                                                    </tr>
                                                                                    {item.assignee_wise_details.map((items, i) => {
                                                                                        return <><tr>
                                                                                            {assigneewisecompliance.userName ?
                                                                                                <>
                                                                                                    <td className='ant-table-cell'><center>{item.unit_name}</center></td>
                                                                                                    <td className='ant-table-cell'><center>{item.assignee_wise_details[0].domain_wise_details[0].domain_name}</center></td>
                                                                                                </>
                                                                                                :
                                                                                                <td><a className='ant-table-cell' onClick={() => {
                                                                                                    setYearlyview({
                                                                                                        ...yearlyview,
                                                                                                        user_id: items.user_id,
                                                                                                        domain_id: items.domain_wise_details[0].domain_id,
                                                                                                        domain_name: items.domain_wise_details[0].domain_name,
                                                                                                        unit_id: item.unit_id
                                                                                                    })
                                                                                                    yearview(items.assignee_name, items.domain_wise_details[0].domain_name, item.unit_id, items.user_id, items.domain_wise_details[0].domain_id)
                                                                                                }}>{items.assignee_name}</a></td>}
                                                                                            <td className='ant-table-cell'><center>{items.domain_wise_details[0].total_compliances}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.domain_wise_details[0].complied_count}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.domain_wise_details[0].assigned_count}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.domain_wise_details[0].inprogress_compliance_count}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.domain_wise_details[0].inprogress_overdue}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.domain_wise_details[0].not_complied_count}</center></td>
                                                                                            <td className='ant-table-cell' onClick={(() => {
                                                                                                setYearlyview({
                                                                                                    ...yearlyview,
                                                                                                    user_id: items.user_id,
                                                                                                    domain_id: items.domain_wise_details[0].domain_id,
                                                                                                    unit_id: item.unit_id
                                                                                                })
                                                                                                setShowmorehistory({
                                                                                                    ...showmorehistory,
                                                                                                    user_id: items.user_id,
                                                                                                    domain_id: items.domain_wise_details[0].domain_id,
                                                                                                    unit_id: item.unit_id
                                                                                                })
                                                                                                historyview(items.user_id, items.domain_wise_details[0].domain_id, item.unit_id)
                                                                                            })}><center>{<RightOutlined />}</center></td>
                                                                                        </tr>
                                                                                        </>
                                                                                    })}
                                                                                </tbody>
                                                                            </>
                                                                        }) :
                                                                        <tr>
                                                                            <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                        </tr>
                                                                    }
                                                                    {/* // <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>} */}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </>
                                        : ''}
                                    <Modal onCancel={setcancelFilter} footer={false} title="Assignee Wise Compliance"
                                        className={"add-service-prv cons-report " + localStorage.getItem("currentTheme")}
                                        open={filterModalVisible} maskClosable={false}>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label htmlFor=""><b>Country:</b> <span style={{ "color": "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select style={{ marginTop: "5px", width: "100%" }}
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                size="default"
                                                                placeholder="Enter Country"
                                                                onChange={(value, data) => {
                                                                    let filteredEntity = _.filter(finallegalentity, { c_id: Number(value) })
                                                                    // setFinallegalentity(filteredEntity)
                                                                    setTempfinallegalentity(filteredEntity)
                                                                    let business = _.filter(assigneewisecompliancefilters.business_groups, { c_id: Number(value) })
                                                                    setBusinessGroup(business)
                                                                    setAssigneewisecompliance({
                                                                        ...assigneewisecompliance,
                                                                        country: value != undefined ? value : '',
                                                                        countryName: data != undefined ? data.children : '',
                                                                        bg: '',
                                                                        bgname: '',
                                                                        division: '',
                                                                        divisionName: '',
                                                                        unit: '',
                                                                        unitName: '',
                                                                        user: '',
                                                                        userName: '',
                                                                        legal_entity: '',
                                                                        le_id: '',
                                                                        domain: '',
                                                                        domainName: ''

                                                                    })
                                                                }}
                                                                value={assigneewisecompliance.countryName || undefined}
                                                                allowClear={true}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                display="none"
                                                            >
                                                                {finalcountry && finalcountry.length > 0 && finalcountry.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'country',
                                                                assigneewisecompliance.countryName,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p style={{ marginLeft: "10px" }}>{assigneewisecompliance.countryName}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label htmlFor=""><b>Legal Entity:</b> <span style={{ "color": "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select style={{ marginTop: "5px", width: "100%" }}
                                                                allowClear={true}
                                                                size="default"
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                disabled={assigneewisecompliance.countryName == "" ? true : false}
                                                                placeholder="Select Legal Entity"
                                                                onChange={(data, value) => {
                                                                    let users = _.filter(assigneewisecompliancefilters.users, { le_id: data })
                                                                    setUser(users)
                                                                    let unit = _.filter(assigneewisecompliancefilters.units, { legal_entity_id: data })
                                                                    setUnit(unit)
                                                                    let filteredDiv = _.filter(assigneewisecompliancefilters && assigneewisecompliancefilters.client_divisions, { le_id: data })
                                                                    setDivision(filteredDiv)
                                                                    setCurrentEntity(data)
                                                                    setAssigneewisecompliance({
                                                                        ...assigneewisecompliance,
                                                                        le_id: data != undefined ? data : '',
                                                                        legal_entity: data != undefined ? value.children : '',
                                                                        bg: '',
                                                                        bgname: '',
                                                                        unit: '',
                                                                        unitName: '',
                                                                        division: '',
                                                                        divisionName: '',
                                                                        user: '',
                                                                        userName: ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneewisecompliance.legal_entity || undefined}
                                                                display="none"
                                                            >
                                                                {tempfinallegalentity && tempfinallegalentity.length > 0 && tempfinallegalentity.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                assigneewisecompliance.le_id,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p style={{ marginLeft: "10px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Business Group:</b></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                disabled={assigneewisecompliance.country == "" ? true : false}
                                                                placeholder="Select Business Group"
                                                                onChange={(value, data) => {
                                                                    setAssigneewisecompliance({
                                                                        ...assigneewisecompliance,
                                                                        bg: value != undefined ? value : '',
                                                                        bgname: data != undefined ? data.children : '',
                                                                        le_id: '',
                                                                        legal_entity: '',
                                                                    })
                                                                    setCurrentEntity()
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneewisecompliance.bgname || undefined}
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                            >
                                                                {businessGroup && businessGroup.length > 0 && businessGroup.map((item, i) => {
                                                                    if (item.bg_name !== null) {
                                                                        return (
                                                                            <Option key={item.bg_id}>
                                                                                {item.bg_name}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                })}
                                                            </Select>
                                                        </div> :
                                                        <p>{assigneewisecompliance.bgname ? assigneewisecompliance.bgname : '-'}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{ marginTop: "10px" }}>
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>{localStorage.getItem('isVendor') == 'true' ? 'Vendor:' : 'Unit:'}</b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            disabled={assigneewisecompliance.legal_entity == "" ? true : false}
                                                            size="default"
                                                            placeholder={localStorage.getItem('isVendor') == 'true' ? "Enter Vendor" : "Enter Unit"}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            onChange={(value, data) => {
                                                                // let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                // setCategory(CategoryData)
                                                                setAssigneewisecompliance({
                                                                    ...assigneewisecompliance,
                                                                    unit: value != undefined ? value : '',
                                                                    unitName: data != undefined ? data.children : '',
                                                                    division: '',
                                                                    divisionName: '',
                                                                    user: '',
                                                                    userName: '',
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={assigneewisecompliance.unitName || undefined}
                                                            style={{ width: "100%", marginTop: "5px" }}
                                                        >
                                                            {unit && unit.length > 0 && unit.map((item, i) => {
                                                                return (
                                                                    <Option key={item.unit_id}>
                                                                        {item.unit_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division:</b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            disabled={assigneewisecompliance.legal_entity == "" ? true : false}
                                                            size="default"
                                                            placeholder="Enter Division"
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            onChange={(value, data) => {
                                                                // let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                // setCategory(CategoryData)
                                                                setAssigneewisecompliance({
                                                                    ...assigneewisecompliance,
                                                                    division: value != undefined ? value : '',
                                                                    divisionName: data != undefined ? data.children : ''
                                                                })
                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={assigneewisecompliance.divisionName || undefined}
                                                            style={{ width: "100%", marginTop: "5px" }}
                                                        >
                                                            {division && division.length > 0 && division.map((item, i) => {
                                                                return (
                                                                    <Option key={item.div_id}>
                                                                        {item.div_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>User:</b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            disabled={assigneewisecompliance.legal_entity == "" ? true : false}
                                                            size="default"
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            placeholder="Enter User"
                                                            onChange={(value, data) => {
                                                                //     let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                //     setCategory(CategoryData)
                                                                setAssigneewisecompliance({
                                                                    ...assigneewisecompliance,
                                                                    user: value != undefined ? value : '',
                                                                    userName: data != undefined ? data.children : ''
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={assigneewisecompliance.userName || undefined}
                                                            style={{ width: "100%", marginTop: "5px" }}
                                                        >
                                                            {user && user.length > 0 && user.map((item, i) => {
                                                                return (
                                                                    <Option key={item.user_id}>
                                                                        {item.employee_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'text-center popupbtncolour mt-2 mb-1 ' + localStorage.getItem('currentTheme')}>
                                            <Button type="primary" shape="round" icon={<EyeOutlined />} className={'addbutton me-1 ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                onClick={() => { onshow() }}>
                                                Show
                                            </Button>
                                            <Button type="primary" shape="round" icon={<ExportOutlined />} className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852" }}
                                                onClick={() => { download() }}
                                            >
                                                Export
                                            </Button>
                                        </div>
                                    </Modal>
                                    <Modal title={assigneeName} className={"add-service-prv cons-report " + localStorage.getItem("currentTheme")} open={yearlyModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100} maskClosable={false}>
                                        <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content audittrailtable'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className='ant-table-thead'>
                                                                <tr>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '20px' }} rowspan="2">#</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '60px' }} rowspan="2">Year</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '60px' }} rowspan="2">Total</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }} rowspan="2">Complied</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }} rowspan="2"><center>Delayed</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }} colspan="2">In progress</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }} rowspan="2">Not Complied</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }} rowspan="2">History</th>
                                                                </tr>
                                                                <tr>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '90px' }}>Within Due Date</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '90px' }}>OverDue</th>
                                                                </tr>
                                                            </thead>
                                                            {assigneeyearwisecompliance && assigneeyearwisecompliance.year_wise_data && assigneeyearwisecompliance.year_wise_data.length > 0 ? assigneeyearwisecompliance.year_wise_data.map((items, i) => {
                                                                return <>
                                                                    <tbody className='ant-table-tbody'>
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center>{count + 1}</center></td>
                                                                            <td className='ant-table-cell'><center>{items.year}</center></td>
                                                                            <td className='ant-table-cell'><center>{items.total_compliances}</center></td>
                                                                            <td className='ant-table-cell'><center>{items.complied_count}</center></td>
                                                                            <td className='ant-table-cell'><center>{items.delayed_compliance_count}</center></td>
                                                                            <td className='ant-table-cell'><center>{items.inprogress_compliance_count}</center></td>
                                                                            <td className='ant-table-cell'><center>{items.inprogressoverdue_count}</center></td>
                                                                            <td className='ant-table-cell'><center>{items.not_complied_count}</center></td>
                                                                            {/* <td className='ant-table-cell'><center>{<RightOutlined />}</center></td> */}
                                                                            <td className='ant-table-cell' onClick={(() => {
                                                                                setYearlyview({
                                                                                    ...yearlyview,
                                                                                    year: items.year
                                                                                })
                                                                                setAssigneeview(true)
                                                                                historyviewnew(items.year)
                                                                            })}><center>{<RightOutlined />}</center></td>
                                                                            <span hidden>{count = count + 1}</span>
                                                                        </tr>
                                                                    </tbody>
                                                                </>
                                                            }) : ''}

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </> : ''}
                                {historytab == true ? <>
                                    {tempcompliedmap && tempcompliedmap.length > 0 ?
                                        // let keys = Object.keys(item);
                                        // let values = Object.values(item);
                                        // let k = []
                                        // for (let i in values) {
                                        //     for (let j in values[i]) {
                                        //         k.push(values[i][j])
                                        //     }
                                        // }
                                        <Collapse className='cric' defaultActiveKey={["0"]} onChange={onChange} >
                                            <Panel style={{ marginBottom: '5px' }} header="Complied" key={key}>
                                                <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                                    <div className='ant-table ant-table-bordered  '>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content audittrailtable'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '20px' }}>#</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}>Compliance Task</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}>Start Date</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}><center>Actual Due Date</center></th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '80px' }}>Task Completion Date/ Document issued Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        {tempcompliedmap.map((items, j) => {
                                                                            return <tr>
                                                                                <td className='ant-table-cell'><center>{historycount + 1}</center></td>
                                                                                <td className='ant-table-cell'>
                                                                                    <div>
                                                                                        <Tooltip placement="topRight" overlayStyle={{ maxWidth: "420px" }}
                                                                                            title={gettooltipfun(items)}>
                                                                                            <InfoCircleTwoTone />
                                                                                        </Tooltip>{" "}
                                                                                        {items.compliance_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='ant-table-cell'><center>{items.assigned_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.due_date}{" "}{items.extn_due_date ? <Tooltip placement="topRight" overlayStyle={{ maxWidth: "420px" }}
                                                                                    title={"The Due Date has been extended to " + items.extn_due_date}>

                                                                                    <InfoCircleTwoTone />
                                                                                </Tooltip> : ''}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                <span hidden>{historycount = historycount + 1}</span>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                        : ''}
                                    {tempdelayedmap && tempdelayedmap.length > 0 ?
                                        // let keys = Object.keys(item);
                                        // let values = Object.values(item);
                                        // let k = []
                                        // for (let i in values) {
                                        //     for (let j in values[i]) {
                                        //         k.push(values[i][j])
                                        //     }
                                        // }
                                        <Collapse className='cric' defaultActiveKey={["0"]} onChange={onChange} >
                                            <Panel style={{ marginBottom: '5px' }} header="Delayed" key={key}>
                                                <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                                    <div className='ant-table ant-table-bordered  '>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content audittrailtable'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '20px' }}>#</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}>Compliance Task</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}>Start Date</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}><center>Actual Due Date</center></th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '80px' }}>Task Completion Date/ Document issued Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        {tempdelayedmap.map((items, j) => {
                                                                            return <tr>
                                                                                <td className='ant-table-cell'><center>{historycount + 1}</center></td>
                                                                                <td className='ant-table-cell'>
                                                                                    <div>
                                                                                        <Tooltip placement="topRight" overlayStyle={{ maxWidth: "420px" }}
                                                                                            title={gettooltipfun(items)}>
                                                                                            < InfoCircleTwoTone />
                                                                                        </Tooltip>{" "}
                                                                                        {items.compliance_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='ant-table-cell'><center>{items.assigned_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.due_date}{" "}{items.extn_due_date ? <Tooltip placement="topRight" overlayStyle={{ maxWidth: "420px" }}
                                                                                    title={"The Due Date has been extended to " + items.extn_due_date}>
                                                                                    < InfoCircleTwoTone />
                                                                                </Tooltip> : ''}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                <span hidden>{historycount = historycount + 1}</span>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                        : ''}
                                    {tempinprogressmap && tempinprogressmap.length > 0 ?
                                        // let keys = Object.keys(item);
                                        // let values = Object.values(item);
                                        // let k = []
                                        // for (let i in values) {
                                        //     for (let j in values[i]) {
                                        //         k.push(values[i][j])
                                        //     }
                                        // }
                                        <Collapse className='cric' defaultActiveKey={["0"]} onChange={onChange} >
                                            <Panel style={{ marginBottom: '5px' }} header="Inprogress" key={key}>
                                                <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                                    <div className='ant-table ant-table-bordered  '>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content audittrailtable'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '20px' }}>#</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}>Compliance Task</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}>Start Date</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}><center>Actual Due Date</center></th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '80px' }}>Task Completion Date/ Document issued Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        {tempinprogressmap.map((items, j) => {
                                                                            return <tr>
                                                                                <td className='ant-table-cell'> <center>{historycount + 1}</center></td>
                                                                                <td className='ant-table-cell'>
                                                                                    <div>
                                                                                        <Tooltip placement="topRight" overlayStyle={{ maxWidth: "420px" }}
                                                                                            title={gettooltipfun(items)}>
                                                                                            < InfoCircleTwoTone />
                                                                                        </Tooltip>{" "}
                                                                                        {items.compliance_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='ant-table-cell'><center>{items.assigned_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.due_date}{" "}{items.extn_due_date ? <Tooltip placement="topRight" overlayStyle={{ maxWidth: "420px" }}
                                                                                    title={"The Due Date has been extended to " + items.extn_due_date}>
                                                                                    < InfoCircleTwoTone />
                                                                                </Tooltip> : ''}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                <span hidden>{historycount = historycount + 1}</span>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                        : ''}
                                    {tempinprogressoverduemap && tempinprogressoverduemap.length > 0 ?
                                        // let keys = Object.keys(item);
                                        // let values = Object.values(item);
                                        // let k = []
                                        // for (let i in values) {
                                        //     for (let j in values[i]) {
                                        //         k.push(values[i][j])
                                        //     }
                                        // }
                                        <Collapse className='cric' defaultActiveKey={["0"]} onChange={onChange} >
                                            <Panel style={{ marginBottom: '5px' }} header="Inprogress Overdue" key={key}>
                                                <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                                    <div className='ant-table ant-table-bordered  '>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content audittrailtable'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '20px' }}>#</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}>Compliance Task</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}>Start Date</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}><center>Actual Due Date</center></th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '80px' }}>Task Completion Date/ Document issued Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        {tempinprogressoverduemap.map((items, j) => {
                                                                            return <tr>
                                                                                <td className='ant-table-cell'><center>{historycount + 1}</center></td>
                                                                                <td className='ant-table-cell'>
                                                                                    <div>
                                                                                        <Tooltip placement="topRight" overlayStyle={{ maxWidth: "420px" }}
                                                                                            title={gettooltipfun(items)}>
                                                                                            < InfoCircleTwoTone />
                                                                                        </Tooltip>{" "}
                                                                                        {items.compliance_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='ant-table-cell'><center>{items.assigned_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.due_date}{" "}{items.extn_due_date ? <Tooltip placement="topRight" overlayStyle={{ maxWidth: "420px" }}
                                                                                    title={"The Due Date has been extended to " + items.extn_due_date}>
                                                                                    < InfoCircleTwoTone />
                                                                                </Tooltip> : ''}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                <span hidden>{historycount = historycount + 1}</span>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                        : ''}
                                    {tempnotcompliedmap && tempnotcompliedmap.length > 0 ?
                                        // let keys = Object.keys(item);
                                        // let values = Object.values(item);
                                        // let k = []
                                        // for (let i in values) {
                                        //     for (let j in values[i]) {
                                        //         k.push(values[i][j])
                                        //     }
                                        // }
                                        <Collapse className='cric' defaultActiveKey={["0"]} onChange={onChange} >
                                            <Panel style={{ marginBottom: '5px' }} header="Not Complied" key={key}>
                                                <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                                    <div className='ant-table ant-table-bordered  '>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content audittrailtable'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '20px' }}>#</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}>Compliance Task</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}>Start Date</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}><center>Actual Due Date</center></th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '80px' }}>Task Completion Date/ Document issued Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        {tempnotcompliedmap.map((items, j) => {
                                                                            return <tr>
                                                                                <td className='ant-table-cell'> <center>{historycount + 1}</center></td>
                                                                                <td className='ant-table-cell'>
                                                                                    <div>
                                                                                        <Tooltip placement="topRight" overlayStyle={{ maxWidth: "420px" }}
                                                                                            title={gettooltipfun(items)}>
                                                                                            < InfoCircleTwoTone />
                                                                                        </Tooltip>{" "}
                                                                                        {items.compliance_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='ant-table-cell'><center>{items.assigned_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.due_date}{" "}{items.extn_due_date ? <Tooltip placement="topRight" overlayStyle={{ maxWidth: "420px" }}
                                                                                    title={"The Due Date has been extended to " + items.extn_due_date}>
                                                                                    < InfoCircleTwoTone />
                                                                                </Tooltip> : ''}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                <span hidden>{historycount = historycount + 1}</span>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                        : ''}
                                    {tempcompliedmap && tempcompliedmap.length == 0 && tempdelayedmap && tempdelayedmap.length == 0 && tempinprogressmap && tempinprogressmap.length == 0
                                        && tempinprogressoverduemap && tempinprogressoverduemap.length == 0 && tempnotcompliedmap && tempnotcompliedmap.length == 0 ?
                                        //     <>
                                        //     <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Foundd</h4>
                                        // </> 
                                        // <Collapse className='cric' defaultActiveKey={["0"]} onChange={onChange} >
                                        // <Panel style={{ marginBottom: '5px' }} key={key}>
                                        <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                            <div className='ant-table ant-table-bordered  '>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content audittrailtable'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className='ant-table-thead'>
                                                                <tr>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '20px' }}>#</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}>Compliance Task</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}>Start Date</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}><center>Actual Due Date</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }}>Task Completion Date/ Document issued Date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='ant-table-tbody'>
                                                                <tr>
                                                                    <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        : ''
                                    }
                                    <div className='text-center'>
                                        {assigneewisecompliancedrilldown.total_count > 100 ? showmorecount.length >= assigneewisecompliancedrilldown.total_count ? false :
                                            <>{console.log(showmorecount, showmorecount.length, assigneewisecompliancedrilldown.total_count, showmorecount.length == assigneewisecompliancedrilldown.total_count, 'haiiiiiiiiii')}
                                                {assigneeview ?
                                                    <Button type="primary" shape="round" className='showbutton' style={{ background: "#0096FF", borderColor: "#0096FF", textAlign: 'center' }}
                                                        size='default'
                                                        onClick={(e) => { historyviewnew(yearlyview.year) }}>
                                                        Show more records<i> <DownOutlined /></i>
                                                    </Button>
                                                    : <Button type="primary" shape="round" className='showbutton' style={{ background: "#0096FF", borderColor: "#0096FF", textAlign: 'center' }}
                                                        size='default'
                                                        onClick={(e) => { historyview(showmorehistory.user_id, showmorehistory.domain_id, showmorehistory.unit_id) }}>
                                                        Show more records<i> <DownOutlined /></i>
                                                    </Button>
                                                }</>
                                            : ''}
                                    </div>
                                </> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}


export default connect(mapStateToProps, {
    AssigneewiseComplianceFilters,
    AssigneeWiseCompliancesChart,
    AssigneeWiseComplianceDrillDown,
    AssigneewiseYearwiseCompliances,
    AssigneeWiseCompliancesChartexport
})(AssigneeWiseCompliance);