import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import moment from 'moment';
import { Collapse, Select, Input, DatePicker, Card, Pagination, Popover, Popconfirm, Spin, Drawer, Button, Table, Tooltip, Modal, Descriptions, Empty, Space } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { RetweetOutlined, ArrowRightOutlined, CheckOutlined, CloseCircleOutlined, InfoCircleFilled, ExclamationCircleFilled, CloseOutlined, UpOutlined, SearchOutlined, DownOutlined, FullscreenOutlined, FilterFilled, FilterOutlined, ExclamationCircleTwoTone, ArrowLeftOutlined, PlayCircleOutlined, BookTwoTone, DownCircleOutlined } from '@ant-design/icons';
import { getCompliancestatuschart, getCriticalityFilter, getCommonData, getComplianceTrendShowMoreTableData, getCompliancestatusTable, getTimeZoneData } from '../../Store/Action/Dashboard/ComplianceStatusChart';
import { toast } from 'react-toastify';
import ScrollToTop from 'react-scroll-to-top';
import { getdownloadpath } from '../../Store/Action/Transcations/ComplianceApproval';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import url, { getUrl } from './../../Libs/URL'
import { HomeRedirect, CountrySplit } from './../../Libs/country'
import ScrollButton from '../Scrollbar';
import { getRemarksHistoryData } from '../../Store/Action/Dashboard/RiskChart';

const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()
const urls = getUrl()

const mapStateToProps = (state) => ({
    dataValue: state.ComplianceStatusChart.complianceStatusChart.chartData,
    // criticalityChart: state.ComplianceStatusChart.complianceStatusChart.criticality,
    time: state.ComplianceStatusChart.complianceStatusChart.time,
    commonData: state.ComplianceStatusChart.complianceStatusChart.Data,
    TableValue: state.ComplianceStatusChart.complianceStatusChart.Table,
    timeZoneData: state.ComplianceStatusChart.complianceStatusChart.timeZoneData,
    showMoreData: state.ComplianceStatusChart.complianceStatusChart.showMoreData,
    remarksHistoryData: state.RiskChart.riskChart.remarksHistoryData,

})

const ComplianceStatusChart = ({
    getCompliancestatuschart,
    getCriticalityFilter,
    getCompliancestatusTable,
    getComplianceTrendShowMoreTableData,
    dataValue,
    commonData,
    TableValue,
    getCommonData,
    getTimeZoneData,
    timeZoneData,
    showMoreData,
    getRemarksHistoryData,
    // criticalityChart,
    time,
    remarksHistoryData,
    getdownloadpath
}) => {
    console.log(dataValue, 'dataValue');
    const _ = require("lodash");
    var [serialNo, setserialNo] = useState(1)
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [chartType, setChartType] = useState('count')
    const [open, setOpen] = useState(false)
    console.log(open, 'open');
    const { Panel } = Collapse;
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    let [counts, setcounts] = useState(0)
    const [chartId, setChartId] = useState()
    const [groupCheckBox, setGroupCheckBox] = useState(true)
    const [checkBox, setCheckBox] = useState(true)
    console.log(checkBox, 'checkBox');
    const [consolidated, setConsolidated] = useState(false)
    console.log(consolidated, 'consolidated');
    const [compliance, setCompliance] = useState("")
    const [categoryName, setCategoryName] = useState()
    const [indexValue, setIndexValue] = useState([])
    const [errorField, setErrorField] = useState('')
    console.log(errorField, 'errorField');
    const [fieldTypeClick, setFieldTypeClick] = useState("")
    console.log(fieldTypeClick, 'fieldTypeClick');
    const [chartName, setChartName] = useState("stacked")
    const [domainName, setDomainName] = useState()
    const [columChart, setCloumnChart] = useState([])
    console.log(columChart, 'columChart');
    const [country, setCountry] = useState([])
    console.log(country, 'country');
    const [cIds, setCIds] = useState([])
    const [domainListData, setDomainListData] = useState([])
    const [showmoreCount, setShowMoreCount] = useState(0)
    const [filterChartName, setfilterChartName] = useState('donut')
    const [dataCount, setDataCount] = useState()
    const [LegalEntityList, setLegalEntityList] = useState([]);
    console.log(LegalEntityList, 'LegalEntityListLegalEntityList');
    const [EntityDatas, setEntityDatas] = useState([])
    const [DomainList, setDomainList] = useState([]);
    const [textArray, setTextArray] = useState([])
    const [countryDomain, setCountryDomain] = useState([])
    console.log(countryDomain, "countryDomain");
    const [domainDatas, setDomainDatas] = useState([])
    const [multiSelectChart, setmultiSelectChart] = useState([])
    console.log(multiSelectChart, 'multiSelectChart');
    const [selectChart, setSelectChart] = useState([])
    const [multiSelect, setmultiSelect] = useState(false)
    console.log(multiSelect, 'multiSelect');
    const [businessGroupData, setBusinessGroupdata] = useState([])
    const [categoryList, setCategoryList] = useState([]);
    const [divisionList, setDivisionList] = useState([]);
    const [categoryDatas, setcategoryDatas] = useState([])
    const [unitList, setUnitList] = useState([]);
    const [unitListOptionLength, setUnitListOptionList] = useState([])
    const [categoryOptionList, setCategoryOptionList] = useState([])
    const [divisionOptionList, setDivisionOptionList] = useState([])
    console.log(unitListOptionLength.length, 'unitListOptionLength');
    const [unitDatas, setunitDatas] = useState([])
    console.log(dataCount, 'dataCount');
    const [countryId, setcountryId] = useState([])
    const [division, setDivision] = useState([])
    const [chartData, setchartData] = useState([])
    console.log(chartData, "chartDatachartData");
    const [go, setGo] = useState(false)
    console.log(go, 'gogo');
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '20', '30']
    })
    const [historymodal, sethistorymodal] = useState(false)
    const [remarksModalData, setRemarksModalData] = useState({
        le_id: '',
        c_id: '',
        d_id: '',
        u_id: '',
        start_date: '',
    })
    const [totalCount, setTotalCount] = useState(0)
    const [year, setYear] = useState(moment().year())
    const [prevYear, setPrevYear] = useState(moment().year() - 4)
    var [count, setCount] = useState(1)
    const [countValue, setCountValue] = useState(0)
    const [columncount, setcolumncount] = useState(0)
    const [chordCount, setchordCount] = useState(0)
    const [chordChart, setChordChart] = useState([])
    const [chordChartData, setchordChartData] = useState([])
    const [countryselectcheckbox, Setcountryselectcheckbox] = useState('block');
    const [countryunselectcheckbox, Setcountryunselectcheckbox] = useState('none');
    const [domainselectcheckbox, Setdomainselectcheckbox] = useState('block');
    const [domainunselectcheckbox, Setdomainunselectcheckbox] = useState('none');
    const [domain, setDomain] = useState([])
    const [domainValue, setDomainValue] = useState([])
    const [divisionselectcheckbox, Setdivisionselectcheckbox] = useState('block');
    const [divisionUnselectcheckbox, SetdivisionUnselectcheckbox] = useState('none');
    const [divisionDatas, setdivisionDatas] = useState([])
    const [divisionData, setdivisionData] = useState([])
    const [categoryselectcheckbox, Setcategoryselectcheckbox] = useState('block');
    const [categoryunselectcheckbox, SetcategoryUnselectcheckbox] = useState('none');
    const [categoryData, setcategoryData] = useState([])
    console.log(categoryData, 'categoryData');
    const [unitselectcheckbox, Setunitselectcheckbox] = useState('block');
    const [unitUnselectcheckbox, SetunitUnselectcheckbox] = useState('none');
    const [unitData, setunitData] = useState([])
    const [filterId, setFilterId] = useState()
    const [Entityselectcheckbox, SetEntityselectcheckbox] = useState('block');
    const [EntityUnselectcheckbox, SetEntityUnselectcheckbox] = useState('none');
    const [Entity, setEntity] = useState([])
    const [businessselectcheckbox, Setbusinessselectcheckbox] = useState('block');
    const [businessunselectcheckbox, Setbusinessunselectcheckbox] = useState('none');
    const [business, setBusiness] = useState([])
    const [businessIds, setBusinessIds] = useState([])
    const [businessgroupValue, setBusinessGroupValue] = useState([])
    const [domainIds, setDomainIds] = useState([])
    console.log(year, 'yearyear');
    const [category, setCategory] = useState([])
    const [table, setTable] = useState(false)
    const [dateState, setDateState] = useState(false)
    const [columnState, setCoulmnState] = useState(false)
    const [labelCountryName, setLabelCountryName] = useState()
    console.log(Entity, 'Entity');
    console.log(multiSelect, 'multiSelect');
    const [tempcriticalityTable, setTempcriticalityTable] = useState([])
    const [sortingpurpose, setSortingpurpose] = useState([])
    const [searchingValue, setSearchingValue] = useState([])
    const [searchedValue, setSearchedValue] = useState({
        bgGroup: '',
        legalEntity: '',
        division: '',
        compliance: '',
        criticality: '',
        assignedTo: '',
        overdue: ''
    })
    const searchInput = useRef(null);
    const myRef1 = useRef(null);
    const myRef2 = useRef(null);
    const myRef3 = useRef(null);
    const myRef4 = useRef(null);
    const myRef5 = useRef(null);
    const myRef6 = useRef(null);
    const myRef7 = useRef(null);
    const historynew = useHistory()

    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const [unit, setUnit] = useState([])
    const [show, setShow] = useState(false)
    const [fieldType, setFieldType] = useState("")
    console.log(fieldType, 'fieldType');
    const [filterData, setFilterData] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: '',
        chartType: 'Group',
        from_date: null,
        to_date: null,
        from_year: null,
        from_month: null,
        from_date_only: null
    })
    console.log(multiSelectChart, 'multiSelectChart');
    console.log(filterData.from_date, 'frommm');
    console.log(filterData.to_date, 'tooo');
    console.log(dataValue, 'chartName');
    console.log(fieldType, 'fieldType');
    console.log(dateState, 'dateState');

    const [field, setfield] = useState({
        country: [],
        domain: [],
        legalEntity: [],
        business: [],
        unit: [],

    })
    const chart = useRef(null);
    const { Option, OptGroup } = Select;
    console.log(commonData, 'commonData');
    console.log(commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id, ' commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id');
    const [criticaltityStatus, setCriticalityStatus] = useState(false)
    const [criticality, setCriticality] = useState("A")
    const [timeZone, setTimeZone] = useState("Afica/Accara")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const country_info = sessionParsedValue.country_info
    const [entityData, setEntityData] = useState([])
    const [tableData, setTableData] = useState([])
    console.log(tableData, 'tableData');
    const TimeZoneValue = sessionParsedValue.login_response
    const sessionArr = sessionParsedValue.entity_info
    console.log(time, 'dataaassssss');
    console.log(TableValue, 'TableValue');
    console.log(domain, 'domain');
    const location = useLocation();
    const [generatedOn, setGeneratedOn] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const [tempState, setTempState] = useState({
        bg: [],
        le: [],
        div: [],
        cat: [],
        unit: []
    })
    const [textValue, setTextValue] = useState()
    const [trueState, setTrueState] = useState([])
    const [divArrLen, setDivArrLen] = useState([])
    const [catArrLen, setCatArrLen] = useState([])
    const [unitArrLen, setUnitArrLen] = useState([])
    const [leLen, setLeLen] = useState([])
    const [divCount, setDivCount] = useState()
    const [catCount, setCatCount] = useState()
    const [unitCount, setUnitCount] = useState()
    const [leCount, setLeCount] = useState()
    const [uniqueCharacters, setUniqueCharacters] = useState()
    const [countryData, setCountryData] = useState([])
    const [domainData, setDomainData] = useState([])
    const [spinnerTrue, setSpinnerTrue] = useState(false)
    console.log(spinnerTrue, 'spinnerTrue');
    const sidefilterData = localStorage.getItem('filterChange')
    const parsedSideFilterData = JSON.parse(sidefilterData)
    const sideFilterState = localStorage.getItem('filterState')
    const [uniqueDomain, setUniqueDomain] = useState([])
    const isCountryValueTrue = localStorage.getItem('countryState')
    const [bgGrpArray, setBgGrpArray] = useState([])

    useEffect(() => {
        if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
            let temp = []
            for (let i in commonData.le_did_infos) {
                if (commonData.le_did_infos[i].bg_id != null) {
                    let bgObj = _.find(commonData.bg_groups, { bg_id: commonData.le_did_infos[i].bg_id })
                    temp.push(bgObj)
                }
            }
            let orderedBg = _.orderBy(temp, ['bg_id'], ['desc'])
            setBgGrpArray(temp)
            console.log(orderedBg, 'orderedBg');
        }

    }, [commonData])

    console.log(chartData, 'chartData');
    console.log(filterId, 'filterId');
    console.log(cIds, 'cIds');


    const setFocus = (ref) => {
        setTimeout(() => {
            ref.current.focus();
        });
    };

    const historycancel = () => {
        sethistorymodal(false);
    }


    useEffect(() => {
        if (TableValue && TableValue.length > 0) {
            console.log('secondTime');
            let sortedArray = _.orderBy(TableValue, ['le_name'], ['asc'])
            setTableData(sortedArray)
        }
    }, [TableValue])


    useEffect(() => {
        if (consolidated === true && go === true) {
            if (chartData && chartData.length > 0) {
                setmultiSelectChart(chartData[0])
                setmultiSelect(true)
            }
        }
        else {
            setmultiSelect(false)
            console.log('checkkk');
        }
    }, [consolidated, chartData, go])

    useEffect(() => {
        if (country && country.length > 0) {
            let tempCIds = []
            for (let i in country) {
                tempCIds.push(Number(country[i]))
            }
            setCIds(tempCIds)
            console.log(tempCIds, 'tempCIds');
        }
    }, [country])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === 'All Legal Entity') {
            if (uniq_country_data && uniq_country_data.length > 0) {
                console.log('hii');
                console.log(uniq_country_data, 'uniq_country_data');
                setfield({
                    ...field,
                    country: uniq_country_data
                })
                // if (JSON.parse(isCountryValueTrue) === false) {
                let temp = []
                let temp1 = []
                let temp2 = []
                let temp3 = []
                for (let i in commonData.d_info) {
                    temp3.push(commonData.d_info[i].d_id)
                    temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
                }
                for (let j in uniq_country_data) {
                    temp1.push(`${uniq_country_data[j].c_id}`)
                    temp2.push(uniq_country_data[j].c_id)
                }
                console.log(temp, 'temp');
                console.log(temp3, 'temp3');
                // setFilterData({
                //     ...filterData,
                //     country: temp1,
                //     countryArr: temp2,
                //     domain: temp,
                //     domainIds: temp3
                // })
                setCountry(temp1)
                setDomain(temp3)
                setDomainValue(temp)
                // }
                // else {
                //     setFilterData({
                //         ...filterData,
                //         country: [],
                //         countryArr: [],
                //         domain: [],
                //         domainIds: []
                //     })
                // }
            }
        }
        else {
            let filteredEntity = _.filter(commonData.le_did_infos, { le_id: localStorage.getItem('SelectedEntityid') })
            console.log(filteredEntity, 'filteredEntity');
            if (filteredEntity && filteredEntity.length > 0) {
                let filteredCountry = _.filter(uniq_country_data, { c_id: filteredEntity[0].c_id })

                setfield({
                    ...field,
                    country: filteredCountry
                })
                // if (JSON.parse(isCountryValueTrue) === false) {
                let temp = []
                let temp1 = []
                let temp2 = []
                let temp3 = []
                for (let i in commonData.d_info) {
                    temp3.push(commonData.d_info[i].d_id)
                    temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
                }
                for (let j in filteredCountry) {
                    temp1.push(`${filteredCountry[j].c_id}`)
                    temp2.push(filteredCountry[j].c_id)
                }
                console.log(temp, 'temp');
                console.log(temp3, 'temp3');
                // setFilterData({
                //     ...filterData,
                //     country: temp1,
                //     countryArr: temp2,
                //     domain: temp,
                //     domainIds: temp3
                // })
                setCountry(temp1)
                setDomain(temp3)
                setDomainValue(temp)
                // }
                // else {
                //     setFilterData({
                //         ...filterData,
                //         country: [],
                //         countryArr: [],
                //         domain: [],
                //         domainIds: []
                //     })
                // }
            }
        }
    }, [commonData, uniq_country_data, localStorage.getItem('SelectedEntityid'), localStorage.getItem('SelectedEntity')])

    // useEffect(() => {
    //     if (commonData) {
    //         let temp = []
    //         let temp1 = []
    //         let temp2 = []
    //         let temp3 = []
    //         // if (JSON.parse(isCountryValueTrue) === false) {
    //             for (let i in commonData.d_info) {
    //                 temp3.push(commonData.d_info[i].d_id)
    //                 temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
    //             }
    //             for (let j in field.country) {
    //                 temp1.push(`${field.country[j].c_id}`)
    //                 temp2.push(field.country[j].c_id)
    //             }
    //             // setFilterData({
    //             //     ...filterData,
    //             //     country: temp1,
    //             //     countryArr: temp2,
    //             //     domain: temp,
    //             //     domainIds: temp3
    //             // })
    //             setCountry(temp1)
    //             setDomain(temp)
    //         // }
    //         // else {
    //         //     setFilterData({
    //         //         ...filterData,
    //         //         country: [],
    //         //         countryArr: [],
    //         //         domain: [],
    //         //         domainIds: []
    //         //     })
    //         // }
    //     }
    // }, [commonData, field.country, localStorage.getItem('countryState'), localStorage.getItem('filterState')])


    useEffect(() => {
        var obj = {}
        countryData.forEach(function (a) {
            var arr2 = []
            domainData.forEach(function (b) {
                var key = a.c_name
                if (a.c_id == b.c_id) {
                    arr2.push(b.d_name)
                    obj[key] = arr2.join()
                }
            });
        });
        let temp = []
        for (let m in obj) {
            console.log(obj[m], 'objmm');
            // let sm = `${m} - ${obj[m]}`
            let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
            temp.push(expectedCountryDomain)
        }
        let newCountryDomain = temp.join("[bold] | [/]")
        setTextArray(newCountryDomain)

    }, [countryData, domainData])

    useEffect(() => {
        let newCountry = []
        let newDomain = []

        for (let j in country) {
            let filterCountry = _.filter(commonData.countries, { c_id: Number(country[j]) })
            // temp2.push(filterDomain)
            for (let i in filterCountry) {
                newCountry.push(filterCountry[i])
            }

        }
        for (let k in domain) {
            let filterDomain = _.filter(commonData.d_info, { d_id: Number(domain[k]) })
            // temp2.push(filterDomain)
            for (let l in filterDomain) {
                newDomain.push(filterDomain[l])
            }

        }
        const uniqueCountry = _.uniqBy(newCountry && newCountry, function (e) {
            return e.c_id;
        });
        setCountryData(uniqueCountry)
        setDomainData(newDomain)
        console.log(newCountry, 'newCountry');
        console.log(newDomain, 'newDomain');
        console.log(filterData.country, 'filterData.country');
        console.log(filterData.domain, 'filterData.domain');

    }, [country, domain])

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            let textArr = []
            let cNames = []
            let uniqueCountry = []
            let countryName;
            setDomainData(commonData.d_info)
            for (let i in commonData.d_info) {
                tempIds.push(commonData.d_info[i].d_id)
                tempArr.push({
                    'd_id': commonData.d_info[i].d_id,
                    'c_id': commonData.d_info[i].c_id,
                    "month_from": commonData.d_info[i].month_from,
                    "month_to": commonData.d_info[i].month_to
                })
                uniqueCountry = _.uniqBy(commonData && commonData.countries, function (e) {
                    return e.c_id;
                });
                setCountryData(uniqueCountry)
                let id = uniqueCountry && uniqueCountry.length > 0 && uniqueCountry.map((item, i) => {
                    return item.c_id
                })
                // setCIds(id)
                console.log(uniqueCountry, 'uniqueCountry');
                countryName = _.find(uniqueCountry, { c_id: Number(commonData.d_info[i].c_id) })

                cNames.push(commonData.d_info[i].d_name)

            }
            // var arr = []
            var obj = {}
            uniqueCountry.forEach(function (a) {
                var arr2 = []
                commonData.d_info.forEach(function (b) {
                    var key = a.c_name
                    if (a.c_id == b.c_id) {
                        arr2.push(b.d_name)
                        obj[key] = arr2.join()
                    }
                });
            });
            let temp = []
            for (let m in obj) {
                console.log(obj[m], 'objmm');
                // let sm = `${m} - ${obj[m]}`
                let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
                temp.push(expectedCountryDomain)
            }
            let newCountryDomain = temp.join("[bold] | [/]")
            // setTextArray(newCountryDomain)
            // setDIds(tempIds)
            setCountryDomain(tempArr)
        }


    }, [commonData])

    // const HandelCountryChange = (obj) => {
    //     console.log(obj, 'objj');
    //     var numberArray = obj.map(Number);
    //     console.log(numberArray, 'numberArray');
    //     let temp5 = []
    //     let filterDomain = []
    //     for (let j in obj) {
    //         filterDomain = _.filter(commonData.d_info,{c_id:Number(obj[j])})
    //         // temp2.push(filterDomain)
    //         for(let i in filterDomain){
    //             temp5.push(filterDomain[i])
    //         }

    //     }
    //     let id = temp5 && temp5.length > 0 && temp5.map((item, i) => {
    //         return item.d_id
    //     })
    //     console.log(id,'temp5');
    //     setFieldTypeClick('Country')
    //     setType('')
    //     setFilterData({
    //         ...filterData,
    //         country: obj,
    //         countryArr: numberArray,
    //         domain:id
    //     })
    //     setFieldType('')
    //     setFilterDataNames({
    //         ...filterDataNames,
    //         country: obj,
    //         legalentity: '',
    //         division: '',
    //         unit: '',
    //         business: '',
    //         category: '',
    //         unit: ''
    //     })
    // }
    console.log(showmoreCount, 'showmoreCount');

    console.log(business, 'business');
    console.log(textValue, 'aishuuu');
    console.log(divArrLen, 'divArrLen');
    console.log(businessIds, 'businessIds');

    const [paramid, setParamId] = useState("")

    // useEffect(() => {
    //     if (fieldType != '') {
    //         // if (fieldType == 'BusinessGroup' && business.length > 0) {
    //         //     setTempState({
    //         //         ...tempState,
    //         //         bg: business
    //         //     })
    //         // }
    //         // else if (fieldType == 'LegalEntity' && Entity.length > 0) {
    //         //     setTempState({
    //         //         ...tempState,
    //         //         le: Entity
    //         //     })
    //         // }
    //         setTempState({
    //             bg: fieldType == 'BusinessGroup' && business.length > 0 ? business : [],
    //             le: fieldType == 'LegalEntity' && Entity.length > 0 ? Entity : []
    //         })
    //     }
    // }, [fieldType != ''])


    // useEffect(() => {
    //     if (trueState) {
    //         let uniqueChars = [...new Set(trueState && trueState.length > 0 && trueState)];
    //         console.log(uniqueChars, 'uniqueChars');
    //         setUniqueCharacters(uniqueChars)
    //     }
    // }, [trueState])

    // console.log(uniqueCharacters,'uniqueCharacters');


    useEffect(() => {
        if ((fieldTypeClick === 'BusinessGroup' || fieldType === 'BusinessGroup') && checkBox === false) {
            if (business.length === 0) {
                setErrorField('Business Group Required')
            }
        } if
            ((fieldTypeClick === 'LegalEntity' || fieldType === 'LegalEntity') && checkBox === false) {
            if (Entity.length === 0) {
                setErrorField('Legal Entity Required')
            }
        }
        if ((fieldTypeClick === 'Division' || fieldType === 'Division') && checkBox === false) {
            if (divisionDatas.length === 0) {
                setErrorField('Division Required')
            }
        } if ((fieldTypeClick === 'Category' || fieldType === 'Category') && checkBox === false) {
            if (categoryData.length === 0) {
                setErrorField('Category Required')
            }
        }
        if ((fieldTypeClick === 'Unit' || fieldType === 'Unit') && checkBox === false) {
            if (unitData.length === 0) {
                setErrorField('Unit Required')
            }
        }


    }, [fieldTypeClick, businessIds, Entity, divisionDatas, categoryData, unitData])


    console.log(country, 'country');
    console.log(fieldTypeClick, 'fieldTypeClick');

    useEffect(() => {
        if ((fieldTypeClick === 'Country' || fieldType === 'Country')) {
            if (country.length === 0) {
                setErrorField('Country Required')
            }
            else {
                setErrorField('')

            }
        }
        if ((fieldTypeClick === 'Domain' || fieldType === 'Domain')) {
            if (domain.length === 0) {
                setErrorField('Domain Required')
            }
            else {
                setErrorField('')

            }
        }

    }, [fieldTypeClick, country, domain])

    useEffect(() => {
        if (((checkBox === true) && (country.length > 0 && domain.length > 0))) {
            setErrorField('')
            setFieldType('')
            setFieldTypeClick('')
        }
    }, [checkBox, country, domain])

    useEffect(() => {
        if (divisionList && divisionList.length > 0) {
            let temp = []
            for (let i in divisionList) {
                console.log(divisionList[i].options, 'divisionList[i].options');
                temp.push(divisionList[i].options.length)
            }
            setDivArrLen([...temp])
        }
        if (categoryList && categoryList.length > 0) {
            let temp1 = []
            for (let i in categoryList) {
                console.log(categoryList[i].options, 'divisionList[i].options');
                temp1.push(categoryList[i].options.length)
            }
            setCatArrLen([...temp1])
        }
        if (unitList && unitList.length > 0) {
            let temp2 = []
            for (let i in unitList) {
                console.log(unitList[i].options, 'divisionList[i].options');
                temp2.push(unitList[i].options.length)
            }
            setUnitArrLen([...temp2])
        }
        if (LegalEntityList && LegalEntityList.length > 0) {
            let temp3 = []
            for (let i in LegalEntityList) {
                console.log(LegalEntityList[i].options, 'divisionList[i].options');
                temp3.push(LegalEntityList[i].options.length)
            }
            setLeLen([...temp3])
        }


    }, [divisionList, categoryList, unitList, LegalEntityList])



    useEffect(() => {
        if (divArrLen) {
            // for(let i in divArrLen){
            let divC = divArrLen.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setDivCount(divC)
            // }
        }
        if (catArrLen) {
            let catL = catArrLen.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setCatCount(catL)
        }
        if (unitArrLen) {
            let unitL = unitArrLen.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setUnitCount(unitL)
        }
        if (leLen) {
            let leL = leLen.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setLeCount(leL)
        }

    }, [divArrLen, catArrLen, unitArrLen])

    useEffect(() => {
        if (trueState && trueState.length) {
            console.log('hloooo');
            setTempState({
                bg: ((trueState && trueState.includes('Business')) && (business && business.length > 0)) ? business : [],
                le: ((trueState && trueState.includes('Legal')) && (Entity && Entity.length > 0)) ? Entity : [],
                div: ((trueState && trueState.includes('Division')) && (divisionDatas && divisionDatas.length > 0)) ? divisionDatas : [],
                cat: ((trueState && trueState.includes('Category')) && (categoryData && categoryData.length > 0)) ? categoryData : [],
                unit: ((trueState && trueState.includes('Unit')) && (unitData && unitData.length > 0)) ? unitData : [],
            })
        }

    }, [trueState.length, business, Entity, categoryData, divisionDatas, unitData])

    useEffect(() => {
        if (tempState) {
            let text1 = tempState && tempState.bg && tempState.bg.length > 0 ? `| Business Group : Selected ${tempState.bg.length} out of ${businessGroupData.length}` : ''
            let text2 = tempState && tempState.le && tempState.le.length > 0 ? ` | Legal Entity : Selected  ${tempState.le.length} out of ${Entity.length}` : ''
            let text3 = tempState && tempState.div && tempState.div.length > 0 ? ` | Division : Selected ${tempState.div.length} out of ${divCount}` : ''
            let text4 = tempState && tempState.cat && tempState.cat.length > 0 ? ` | Category : Selected ${tempState.cat.length} out of ${catCount}` : ''
            let text5 = tempState && tempState.unit && tempState.unit.length > 0 ? ` | Unit : Selected ${tempState.unit.length} out of ${unitCount}` : ''
            // let text3 = text1 + text2
            let text7 = text1 + text2 + text3 + text4 + text5
            setTextValue(text7)
            console.log(text7, 'text7');
        }

    }, [tempState, divCount, catCount, unitCount])

    useEffect(() => {
        if (country.length > 0) {
            let temp = []
            for (let i in country) {
                temp.push(Number(country[i]))
            }
            setcountryId(temp)
        }
    }, [country])
    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let domainNameArray = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_name
            })
            let domainIds = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_id
            })
            setDomainName(domainNameArray)
            setDomainIds(domainIds)
            console.log(domainName, 'domainName');

        }

    }, [commonData])

    console.log(domainIds, 'domainIds')

    useEffect(() => {
        {

            var the_arr = location.pathname.split('/');

            the_arr.pop();

            let a = the_arr.join('/')

            setParamId(a)

        }

    }, []);
    console.log(tableData, 'tableData');



    useEffect(() => {
        if (tableData && tableData.length > 0) {
            let count = []
            for (let i in tableData) {
                for (let j in tableData[i].drill_compliances) {
                    let a = tableData[i].drill_compliances[j]
                    let data = Object.values(a)


                    count.push(data.length)

                    // count.push(data[i])
                    console.log(data, ' taskDistributionTable[i].ap_compliances');

                }
            }
            console.log(count, 'count');
            let countValues = count.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setShowMoreCount(countValues)
            console.log(countValues, 'countValue9999');
        }

    }, [tableData])


    console.log(divisionData, 'divisionData');

    useEffect(() => {

        if (chartName == "pie") {
            if (dataValue && dataValue.length > 0) {
                let datacount
                let temp = []
                let temp2 = []
                let temp3 = []
                for (let i in dataValue) {
                    temp.push(dataValue[i].c_data)
                }
                console.log(temp, 'temppp');
                for (let j in temp) {
                    temp2.push(temp[j][0])
                }
                for (let k in temp2) {
                    datacount = temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count
                    if (dataValue[k].filter_type == "LegalEntity") {

                        let data = _.find(commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos, { le_id: dataValue[k].filter_type_id_le })
                        console.log(data, 'datadata');
                        let obj = {
                            "data": data.le_name,
                            "count": temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count,
                            "pieValue": [

                                {
                                    "value": temp2[k].complied_count,
                                    "title": "Complied",
                                    "color": "#0de580"
                                },
                                {
                                    "value": temp2[k].delayed_compliance_count,
                                    "title": "Delayed Compliance",
                                    "color": "#3c78d8"
                                },
                                {
                                    "value": temp2[k].inprogress_compliance_count,
                                    "title": "Inprogress",
                                    "color": "#f9c80e"
                                },
                                {
                                    "value": temp2[k].not_complied_count,
                                    "title": "Not Complied",
                                    "color": "#fe4365"
                                }
                            ],
                            "type": data.le_name,
                            "typeId": data.le_id
                            // "type":

                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Division") {
                        let data = _.find(commonData && commonData.div_infos.length > 0 && commonData.div_infos, { div_id: dataValue[k].filter_type_id })
                        let obj = {
                            "data": data.div_name,
                            "count": temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count,
                            "pieValue": [

                                {
                                    "value": temp2[k].complied_count,
                                    "title": "Complied",
                                    "color": "#0de580"
                                },
                                {
                                    "value": temp2[k].delayed_compliance_count,
                                    "title": "Delayed Compliance",
                                    "color": "#3c78d8"
                                },
                                {
                                    "value": temp2[k].inprogress_compliance_count,
                                    "title": "Inprogress",
                                    "color": "#f9c80e"
                                },
                                {
                                    "value": temp2[k].not_complied_count,
                                    "title": "Not Complied",
                                    "color": "#fe4365"
                                }
                            ],
                            "type": data.div_name,
                            "typeId": data.div_id

                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Category") {
                        let data = _.find(commonData && commonData.cat_info.length > 0 && commonData.cat_info, { cat_id: dataValue[k].filter_type_id })
                        console.log(data, 'datadata');
                        let obj = {
                            "data": data.cat_name,
                            "count": temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count,
                            "pieValue": [

                                {
                                    "value": temp2[k].complied_count,
                                    "title": "Complied",
                                    "color": "#0de580"
                                },
                                {
                                    "value": temp2[k].delayed_compliance_count,
                                    "title": "Delayed Compliance",
                                    "color": "#3c78d8"
                                },
                                {
                                    "value": temp2[k].inprogress_compliance_count,
                                    "title": "Inprogress",
                                    "color": "#f9c80e"
                                },
                                {
                                    "value": temp2[k].not_complied_count,
                                    "title": "Not Complied",
                                    "color": "#fe4365"
                                }
                            ],
                            "type": data.cat_name,
                            "typeId": data.cat_id

                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Unit") {
                        let data = _.find(commonData && commonData.chart_units.length > 0 && commonData.chart_units, { u_id: dataValue[k].filter_type_id })
                        let obj = {
                            "data": data.u_name,
                            "count": temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count,
                            "pieValue": [

                                {
                                    "value": temp2[k].complied_count,
                                    "title": "Complied",
                                    "color": "#0de580"
                                },
                                {
                                    "value": temp2[k].delayed_compliance_count,
                                    "title": "Delayed Compliance",
                                    "color": "#3c78d8"
                                },
                                {
                                    "value": temp2[k].inprogress_compliance_count,
                                    "title": "Inprogress",
                                    "color": "#f9c80e"
                                },
                                {
                                    "value": temp2[k].not_complied_count,
                                    "title": "Not Complied",
                                    "color": "#fe4365"
                                }
                            ],
                            "type": data.u_name,
                            "typeId": data.u_id

                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Group") {
                        console.log('testtttttttttt');
                        let data = _.find(commonData && commonData.countries.length > 0 && commonData.countries, { c_id: dataValue[k].filter_type_id })
                        let obj = {
                            "data": data.c_name,
                            "count": temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count,
                            "pieValue": [

                                {
                                    "value": temp2[k].complied_count,
                                    "title": "Complied",
                                    "color": "#0de580"
                                },
                                {
                                    "value": temp2[k].delayed_compliance_count,
                                    "title": "Delayed Compliance",
                                    "color": "#3c78d8"
                                },
                                {
                                    "value": temp2[k].inprogress_compliance_count,
                                    "title": "Inprogress",
                                    "color": "#f9c80e"
                                },
                                {
                                    "value": temp2[k].not_complied_count,
                                    "title": "Not Complied",
                                    "color": "#fe4365"
                                }
                            ],
                            "type": data.c_name,
                            "typeId": data.c_id

                        }
                        temp3.push(obj)
                    }
                    setcolumncount(datacount)

                }
                setCloumnChart(temp3)
                console.log(temp2, 'temp2');
                console.log(temp3, 'temp3');


            }
        }

    }, [dataValue, chartName])


    // Customizeed table for search
    useEffect(() => {
        if (tableData && tableData.length > 0) {
            let count1 = []
            for (let i in tableData) {
                for (let j in tableData[i].drill_compliances) {
                    let a = tableData[i].drill_compliances[j]
                    let data = Object.values(a)
                    count1.push(data.length)
                    // count1.push(data[i])
                    console.log(data, ' taskDistributionTable[i].ap_compliances');
                }
            }
            console.log(count1, 'count1');
            let countValues = count1.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setShowMoreCount(countValues)
            console.log(countValues, 'countValue9999');

            // search option customize
            let temp = []
            let temp1 = []
            {
                tableData && tableData.length > 0 &&
                    tableData.map((items) => {
                        let childDatas = []
                        let unitName = items.drill_compliances
                        console.log(unitName, 'unitName');
                        let keys = Object.keys(unitName);
                        let values = Object.values(unitName);
                        console.log(keys, 'keys');
                        console.log(values, 'values');
                        {
                            keys && keys.length > 0 && keys.map((item, i) => {
                                console.log(item, 'itemitemitemitemitemitemitem');
                                let tempArr = []
                                if (values && values.length > 0) {
                                    for (let m in values[i]) {
                                        console.log(m, 'inndexxx');
                                        console.log(values[i][m].comp_name, 'childDatas');
                                        tempArr.push({
                                            'u_name': items.u_name,
                                            'bg_name': items.bg_name,
                                            'le_name': items.le_name,
                                            'legal_entity_id': items.legal_entity_id,
                                            'div_name': items.div_name,
                                            'comp_name': values && values[i][m].comp_name,
                                            'criticality': values && values[i][m].criticality,
                                            'assignee_name': values && values[i][m].assignee_name,
                                            'descp': values && values[i][m].descp,
                                            'compfielaw_url': values && values[i][m].compfielaw_url,
                                            'ageing': values && values[i][m].ageing,
                                            'unitname': item,
                                            'index': count
                                        })
                                        count = count + 1
                                        console.log(count, 'countcountcountcount');
                                    }
                                    // temp1.concat(tempArr)
                                    Array.prototype.push.apply(temp1, tempArr)
                                    childDatas.push(tempArr)
                                }
                                console.log(childDatas, 'tempArr5557');
                                console.log(tempArr, 'tempArr5556666');
                                // temp.push([childDatas[i]])
                                // temp.push(childDatas[i])
                                // log
                            })
                            temp.push(childDatas)
                            console.log(temp, 'llllllllll');
                        }
                        setTempcriticalityTable(temp)
                    })
            }
            setSortingpurpose(temp1)
            console.log(temp1, 'temptemp');

            // tableData.drill_compliances
        }

    }, [tableData])

    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map(function (user) {
            // console.log(list.unitlist, 'list.unitlist');
            if ((arrunit.indexOf(user.c_id) < 0))
                arrunit.push(user.c_id);
        });

        arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            if (pmenu || pmenu == null) {
                commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((subunitlist) => {

                    if (pmenu == subunitlist.c_id) {
                        entityArrayunit = {

                            'c_id': subunitlist.c_id,
                            'd_id': `${subunitlist.c_id}-${subunitlist.d_id}`,
                            'd_name': subunitlist.d_name,
                            'is_active': subunitlist.is_active,
                            'month_from': subunitlist.month_from,
                            'month_to': subunitlist.month_to,
                            'label': subunitlist.d_name,
                            'values': subunitlist.d_id

                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                })
            }
            let countryName = _.find(uniq_country_data, { c_id: pmenu })
            console.log(countryName, 'countryName');
            totalListunit = {
                'label': countryName.c_name == null ? 'Others' : countryName.c_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setDomainListData(countrylisttempdataunit)


    }, [uniq_country_data, commonData])





    useEffect(() => {
        let colors = {
            type: "skyblue",
            Complied: '#0de580',
            delayed: '#3c78d8',
            notComplied: "#fe4365",
            inprogress: "#f9c80e"
        }
        if (chartName == "chord") {
            if (dataValue && dataValue.length > 0) {
                let datacount
                let temp = []
                let temp2 = []
                let temp3 = []
                for (let i in dataValue) {
                    temp.push(dataValue[i].c_data)
                }
                console.log(temp, 'temppp');
                for (let j in temp) {
                    temp2.push(temp[j][0])
                }
                for (let k in temp2) {
                    datacount = temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count
                    if (dataValue[k].filter_type == "LegalEntity") {

                        let data = _.find(commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos, { le_id: dataValue[k].filter_type_id_le })
                        let obj = [

                            { "from": data.le_name, "color": colors.type, "value": data.le_id },


                            // real data
                            // { "from": "India", "to": "Complied"+"DelayedCompliance"+"Inprogress"+"NotComplied", "value": ( dataValue && dataValue.length && dataValue[0].complied_count) +( dataValue && dataValue.length && dataValue[0].delayed_compliance_count)+(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)+(dataValue && dataValue.length && dataValue[0].not_complied_count)},
                            { "from": "Complied", "to": data.le_name, "value": temp2[k].complied_count, "color": colors.Complied },
                            { "from": "Delayed Compliance", "to": data.le_name, "value": temp2[k].delayed_compliance_count, "color": colors.delayed },
                            { "from": "Inprogress", "to": data.le_name, "value": temp2[k].inprogress_compliance_count, 'color': colors.inprogress },
                            { "from": "Not Complied", "to": data.le_name, "value": temp2[k].not_complied_count, 'color': colors.notComplied }



                        ]
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Division") {
                        let data = _.find(commonData && commonData.div_infos.length > 0 && commonData.div_infos, { div_id: dataValue[k].filter_type_id })
                        let obj = [

                            { "from": data.div_name, "color": colors.type, "value": data.div_id },


                            // real data
                            // { "from": "India", "to": "Complied"+"DelayedCompliance"+"Inprogress"+"NotComplied", "value": ( dataValue && dataValue.length && dataValue[0].complied_count) +( dataValue && dataValue.length && dataValue[0].delayed_compliance_count)+(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)+(dataValue && dataValue.length && dataValue[0].not_complied_count)},
                            { "from": "Complied", "to": data.div_name, "value": temp2[k].complied_count, "color": colors.Complied },
                            { "from": "Delayed Compliance", "to": data.div_name, "value": temp2[k].delayed_compliance_count, "color": colors.delayed },
                            { "from": "Inprogress", "to": data.div_name, "value": temp2[k].inprogress_compliance_count, 'color': colors.inprogress },
                            { "from": "Not Complied", "to": data.div_name, "value": temp2[k].not_complied_count, 'color': colors.notComplied }



                        ]
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Category") {
                        let data = _.find(commonData && commonData.cat_info.length > 0 && commonData.cat_info, { cat_id: dataValue[k].filter_type_id })
                        let obj = [

                            { "from": data.cat_name, "color": colors.type, "value": data.cat_id },


                            // real data
                            // { "from": "India", "to": "Complied"+"DelayedCompliance"+"Inprogress"+"NotComplied", "value": ( dataValue && dataValue.length && dataValue[0].complied_count) +( dataValue && dataValue.length && dataValue[0].delayed_compliance_count)+(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)+(dataValue && dataValue.length && dataValue[0].not_complied_count)},
                            { "from": "Complied", "to": data.cat_name, "value": temp2[k].complied_count, "color": colors.Complied },
                            { "from": "Delayed Compliance", "to": data.cat_name, "value": temp2[k].delayed_compliance_count, "color": colors.delayed },
                            { "from": "Inprogress", "to": data.cat_name, "value": temp2[k].inprogress_compliance_count, 'color': colors.inprogress },
                            { "from": "Not Complied", "to": data.cat_name, "value": temp2[k].not_complied_count, 'color': colors.notComplied }


                        ]
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Unit") {
                        let data = _.find(commonData && commonData.chart_units.length > 0 && commonData.chart_units, { u_id: dataValue[k].filter_type_id })
                        let obj = [
                            { "from": data.u_name, "color": colors.type, "value": data.unit_id },


                            // real data
                            // { "from": "India", "to": "Complied"+"DelayedCompliance"+"Inprogress"+"NotComplied", "value": ( dataValue && dataValue.length && dataValue[0].complied_count) +( dataValue && dataValue.length && dataValue[0].delayed_compliance_count)+(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)+(dataValue && dataValue.length && dataValue[0].not_complied_count)},
                            { "from": "Complied", "to": data.u_name, "value": temp2[k].complied_count, "color": colors.Complied },
                            { "from": "Delayed Compliance", "to": data.u_name, "value": temp2[k].delayed_compliance_count, "color": colors.delayed },
                            { "from": "Inprogress", "to": data.u_name, "value": temp2[k].inprogress_compliance_count, 'color': colors.inprogress },
                            { "from": "Not Complied", "to": data.u_name, "value": temp2[k].not_complied_count, 'color': colors.notComplied }


                        ]
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Group") {
                        console.log('testtttttttttt');
                        let data = _.find(commonData && commonData.countries.length > 0 && commonData.countries, { c_id: dataValue[k].filter_type_id })
                        let obj = [

                            { "from": data.c_name, "color": colors.type, "value": data.c_id },


                            // real data
                            // { "from": "India", "to": "Complied"+"DelayedCompliance"+"Inprogress"+"NotComplied", "value": ( dataValue && dataValue.length && dataValue[0].complied_count) +( dataValue && dataValue.length && dataValue[0].delayed_compliance_count)+(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)+(dataValue && dataValue.length && dataValue[0].not_complied_count)},
                            { "from": "Complied", "to": data.c_name, "value": temp2[k].complied_count, "color": colors.Complied },
                            { "from": "Delayed Compliance", "to": data.c_name, "value": temp2[k].delayed_compliance_count, "color": colors.delayed },
                            { "from": "Inprogress", "to": data.c_name, "value": temp2[k].inprogress_compliance_count, 'color': colors.inprogress },
                            { "from": "Not Complied", "to": data.c_name, "value": temp2[k].not_complied_count, 'color': colors.notComplied }


                        ]
                        temp3.push(obj)
                    }


                }
                setChordChart(temp3)
                console.log(temp2, 'temp2');
                console.log(temp3, 'temp3');
                setchordCount(datacount)

            }
        }

    }, [dataValue, chartName])
    console.log(columncount, 'columncount');
    console.log(chordChart, 'chordChart');
    console.log(showMoreData, 'showMoreDatashowMoreData');
    console.log(tableData, 'tableData');
    console.log(chordCount, 'chordCount');

    useEffect(() => {
        let temp = []
        let temp2 = []
        if (chordChart && chordChart.length > 0) {
            for (let j in chordChart) {


                for (let k in chordChart[j]) {
                    temp2.push(chordChart[j][k])
                }
            }
            setchordChartData(temp2)
            console.log(temp2, 'temp2temp2');
        }
    }, [chordChart])
    console.log(tableData, 'tableData');
    console.log(LegalEntityList.length, 'LegalEntityList');
    console.log(Entity.length, 'entyLen');
    useEffect(() => {
        console.log(tableData, 'tableData');
        console.log(showMoreData.r_drill_down_data, 'showMoreData.r_drill_down_data5555');
        if (showMoreData && showMoreData.drill_down_data && showMoreData.drill_down_data.length > 0) {
            if (tableData && tableData.length > 0) {
                let newTable = tableData
                let mergedArray = []
                let tableDataLastObj = newTable[newTable.length - 1]
                let showMoreDataFirstObj = showMoreData.drill_down_data[0]
                console.log(tableDataLastObj, 'tableDataLastObj');
                console.log(showMoreDataFirstObj, 'showMoreDataFirstObj');
                // if (`${tableDataLastObj && tableDataLastObj.le_name}-${tableDataLastObj && tableDataLastObj.le_name}` === `${showMoreDataFirstObj && showMoreDataFirstObj.le_name}-${showMoreDataFirstObj && showMoreDataFirstObj.le_name}`) {
                //     let showMoreNew = _.filter(showMoreData.n_drill_down_data, { le_name: tableDataLastObj.le_name })
                //     let mergedArray = newTable.concat(showMoreNew)
                //     setCriticalityTable(mergedArray)
                // }
                // else {
                //     let rejectedData = _.reject(showMoreData.n_drill_down_data, { le_name: tableDataLastObj.le_name })
                //     let mergedArray = newTable.concat(rejectedData)
                //     setCriticalityTable(mergedArray)
                // }
                if (tableDataLastObj && showMoreDataFirstObj) {
                    let previousLEUnit = tableDataLastObj.le_name + '-' + tableDataLastObj.u_name
                    let currentLEUnit = showMoreDataFirstObj.le_name + '-' + showMoreDataFirstObj.u_name
                    if (previousLEUnit == currentLEUnit) {
                        // let showMoreNew = _.filter(showMoreData.not_complied, { le_name: tableDataLastObj.le_name, u_name: tableDataLastObj.u_name })                   
                        let existDrill = tableDataLastObj.drill_compliances
                        let newDrill = showMoreDataFirstObj.drill_compliances
                        let exisObjKeys = _.keys(existDrill)
                        let newKeys = _.keys(newDrill)
                        let matchedArrayFilter = _.filter(showMoreData.drill_down_data, { u_name: tableDataLastObj.le_name })
                        let unMatchedArrayFilter = _.reject(showMoreData.drill_down_data, { u_name: tableDataLastObj.u_name })

                        if (exisObjKeys[exisObjKeys.length - 1] == newKeys[0]) {
                            let temp = {
                                [exisObjKeys[exisObjKeys.length - 1]]: existDrill[exisObjKeys[exisObjKeys.length - 1]].concat(newDrill[exisObjKeys[exisObjKeys.length - 1]])
                            }
                            delete newDrill[exisObjKeys[exisObjKeys.length - 1]]
                            existDrill = Object.assign(existDrill, temp)
                            console.log(existDrill, newDrill, 'vibinnnn');
                        } else {
                            console.log(existDrill, newDrill, 'vibinnnnelse');
                        }
                        let newAssign = Object.assign(existDrill, newDrill);
                        console.log(newAssign, 'newAssign');
                        tableData[tableData.length - 1].drill_compliances = newAssign
                        let merge = tableData.concat(unMatchedArrayFilter)
                        console.log(merge, 'merge');
                        setTableData(merge)

                    } else {
                        console.log('mismatchhh');
                        let rejectedData = _.reject(showMoreData.drill_down_data, { le_name: tableDataLastObj.le_name })
                        let mergedArray = newTable.concat(rejectedData)
                        setTableData(mergedArray)
                    }
                }
            }
        }

    }, [showMoreData])

    console.log(showMoreData, 'showMoreData');

    // useEffect(() => {

    //     if (showMoreData && showMoreData.drill_down_data && showMoreData.drill_down_data.length > 0) {
    //         let temp = []
    //         let temp2 = []
    //         for (let i in tableData) {
    //             for (let j in showMoreData.drill_down_data) {
    //                 console.log(tableData, 'tableData');
    //                 console.log(showMoreData, 'showMoreData.drill_down_data');
    //                 console.log(showMoreData.drill_down_data, 'showMoreData');
    //                 console.log(tableData[i].u_name != showMoreData.drill_down_data[j].u_name, 'tableData[i].u_name != showMoreData.drill_down_data[j].u_name');
    //                 console.log(tableData[i].u_name == showMoreData.drill_down_data[j].u_name, 'tableData[i].u_name == showMoreData.drill_down_data[j].u_name');
    //                 if (tableData[i].u_name == showMoreData.drill_down_data[j].u_name) {

    //                     temp2.push(tableData[i].u_name)
    //                     let array = tableData[i].drill_compliances

    //                     var newArray = Object.assign({}, tableData[i].drill_compliances, showMoreData.drill_down_data[j].drill_compliances);
    //                     tableData[i].drill_compliances = newArray

    //                 }






    //                 // else {

    //                 //     console.log(tableData[i], 'aishuu');
    //                 //     console.log(showMoreData.drill_down_data[j], '=showMoreData.drill_down_data[j]');


    //                 //     tableData[i] = showMoreData.drill_down_data[j]


    //                 // }

    //             }

    //         }
    //         console.log(temp2, 'temp212355');
    //         for (let i in showMoreData.drill_down_data) {

    //             if ((temp2.includes(showMoreData.drill_down_data[i].u_name)) == false) {
    //                 // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
    //                 console.log(showMoreData.drill_down_data[i], 'datadata');
    //                 temp.push(showMoreData.drill_down_data[i])
    //             }



    //         }
    //         setTableData([...tableData, ...temp])
    //         // setTableData(tableData)
    //     }




    // }, [showMoreData])
    console.log(tableData, 'tableDatatableData');
    const onClose = () => {
        setOpen(false);
        setFilterOpen(false)
        if (go === false) {
            setSpinnerTrue(true)
        }
        else {
            setSpinnerTrue(false)
        }
    };
    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

            return item.le_id
        })
        setEntityData(id)

        // }
    }, [])



    console.log(criticaltityStatus, 'criticaltityStatus');

    useEffect(() => {
        let temparr = []
        if (criticaltityStatus == false) {
            if (dataValue && dataValue.length > 0) {

                let temp = []
                let temp2 = []
                let temp3 = []
                for (let i in dataValue) {
                    if (dataValue[i].c_data && dataValue[i].c_data.length > 0 && dataValue[i].c_data[0] != undefined) {
                        temp.push(dataValue[i].c_data)
                    }
                    else {
                        temp = []
                    }
                }
                console.log(temp, 'temppp');
                if (temp && temp.length > 0) {
                    for (let j in temp) {
                        temp2.push(temp && temp[j][0])
                    }
                }
                for (let k in temp2) {

                    if (dataValue[k].filter_type == "LegalEntity") {
                        let data = _.find(commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos, { le_id: dataValue[k].filter_type_id_le })
                        let obj = {
                            "type": data.le_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "typeId": dataValue[k].filter_type_id_le
                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Division") {
                        let data = _.find(commonData && commonData.div_infos.length > 0 && commonData.div_infos, { div_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.div_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "typeId": dataValue[k].filter_type_id
                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Category") {
                        let data = _.find(commonData && commonData.cat_info.length > 0 && commonData.cat_info, { cat_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.cat_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "typeId": dataValue[k].filter_type_id
                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Unit") {
                        let data = _.find(commonData && commonData.chart_units.length > 0 && commonData.chart_units, { u_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.u_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "typeId": dataValue[k].filter_type_id
                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Group") {
                        console.log('testtttttttttt');
                        let data = _.find(commonData && commonData.countries.length > 0 && commonData.countries, { c_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.c_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,

                            "typeId": dataValue[k].filter_type_id
                        }
                        temp3.push(obj)
                    }


                }
                if (temp3 && temp3.length > 0) {
                    for (let i in temp3) {
                        const removeZero = item => (
                            Object.keys(item)
                                .filter(key => item[key] !== 0)
                                .reduce((newObj, key) => {
                                    newObj[key] = item[key]; return newObj;
                                }, {})); const result = removeZero(temp3[i]);
                        console.log(result, 'result');
                        temparr.push(result)
                    }
                }
                setchartData(temparr)
                console.log(temp2, 'temp2');
                console.log(temp3, 'temp3');


            }
        }
        else if (criticaltityStatus == true) {
            if (dataValue && dataValue.length > 0) {
                let temp = []
                let temp2 = []
                let temp3 = []
                console.log(dataValue, 'dataValue689188');
                for (let i in dataValue) {
                    if (dataValue[i].criticality_data && dataValue[i].criticality_data.length > 0 && dataValue[i].criticality_data[0] != undefined) {
                        temp.push(dataValue[i].criticality_data)
                    }
                    else {
                        temp = []
                    }
                }
                console.log(temp, '9829999999999');
                for (let j in temp) {
                    temp2.push(temp[j][0])
                }
                console.log(temp2, 'temp2');
                for (let k in temp2) {

                    if (dataValue[k].filter_type == "LegalEntity") {
                        let data = _.find(commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos, { le_id: dataValue[k].filter_type_id_le })
                        let obj = {
                            "type": data.le_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "typeId": dataValue[k].filter_type_id_le

                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Division") {
                        let data = _.find(commonData && commonData.div_infos.length > 0 && commonData.div_infos, { div_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.div_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "typeId": dataValue[k].filter_type_id

                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Category") {
                        let data = _.find(commonData && commonData.cat_info.length > 0 && commonData.cat_info, { cat_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.cat_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "typeId": dataValue[k].filter_type_id

                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Unit") {
                        let data = _.find(commonData && commonData.chart_units.length > 0 && commonData.chart_units, { u_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.u_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "typeId": dataValue[k].filter_type_id
                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Group") {
                        console.log('testtttttttttt');
                        let data = _.find(commonData && commonData.countries.length > 0 && commonData.countries, { c_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.c_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "typeId": dataValue[k].filter_type_id

                        }
                        temp3.push(obj)
                    }


                }
                if (temp3 && temp3.length > 0) {
                    for (let i in temp3) {
                        const removeZero = item => (
                            Object.keys(item)
                                .filter(key => item[key] !== 0)
                                .reduce((newObj, key) => {
                                    newObj[key] = item[key]; return newObj;
                                }, {})); const result = removeZero(temp3[i]);
                        console.log(result, 'result');
                        temparr.push(result)
                    }
                }
                setchartData(temparr)
                console.log(temp2, 'temp2');
                console.log(temp3, 'temp3');


            }
        }


    }, [dataValue])
    console.log(chartData, 'chartData');

    console.log(LegalEntityList, 'LegalEntityListLegalEntityList');
    console.log(EntityDatas, 'EntityDatasEntityDatas');
    console.log(Entity, 'EntityEntity');

    useEffect(() => {
        console.log(compliance, 'compliancecompliance');
        if (compliance == 'Not Complied') {
            setTotalCount(dataValue && dataValue.length && dataValue[0].not_complied_count)
        }
        else if (compliance == 'Inprogress') {
            setTotalCount(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)
        }
        else if (compliance == 'Complied') {
            setTotalCount(dataValue && dataValue.length && dataValue[0].complied_count)
        }
        else if (compliance == 'Delayed Compliance') {
            setTotalCount(dataValue && dataValue.length && dataValue[0].delayed_compliance_count)
        }
    }, [compliance])
    useEffect(() => {
        if (divisionDatas.length > 0) {
            let temp = []
            for (let i in divisionDatas) {
                let data = _.filter(commonData && commonData.cat_info, { div_id: Number(divisionDatas[i]) })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            setcategoryDatas(temp)
        }
        else {
            setcategoryDatas([])
        }
    }, [divisionDatas])

    // useEffect(() => {
    //     if (categoryData.length > 0) {
    //         let temp = []
    //         for (let i in categoryData) {
    //             let data = _.filter(commonData && commonData.chart_units, { category_id: Number(categoryData[i]) })
    //             console.log(data, 'aaaaaaa');
    //             for (let j in data) {
    //                 temp.push(data[j])
    //             }
    //             // temp.push(data)
    //             console.log(temp, 'tempppp');
    //         }
    //         setunitDatas(temp)
    //     }
    //     else {
    //         setunitDatas([])
    //     }
    // }, [categoryData])


    console.log(TableValue, 'TableValue');
    console.log(bgGrpArray, 'bgGrpArray');


    useEffect(() => {
        if (commonData) {
            // setfield({
            //     ...field,
            //     country: commonData && commonData.countries,
            // })
            // setDivision(commonData && commonData.div_infos)
            // setCategory(commonData && commonData.cat_info)
            // setUnit(commonData && commonData.chart_units)
            if (bgGrpArray && bgGrpArray.length > 0) {
                const unique = _.uniqBy(bgGrpArray, function (e) {

                    return e.bg_id;

                });

                console.log(unique, 'unique');

                setBusinessGroupdata(unique)
            }
            // setBusinessGroupdata(commonData && commonData.bg_groups)
            setDomainDatas(commonData && commonData.d_info)
            setEntityDatas(commonData && commonData.le_did_infos)
            setcategoryDatas(commonData && commonData.cat_info)
            setdivisionData(commonData && commonData.div_infos)
            setunitDatas(commonData && commonData.chart_units)

        }

    }, [isAuth, entityid, commonData, bgGrpArray])

    useEffect(() => {
        if (commonData) {
            // setfield({
            //     ...field,
            //     country: commonData && commonData.countries,
            // })
            // // setDivision(commonData && commonData.div_infos)
            // // setCategory(commonData && commonData.cat_info)
            // // setUnit(commonData && commonData.chart_units)
            // setDomainDatas(commonData && commonData.d_info)
            // setEntityDatas(commonData && commonData.le_did_infos)
            // setcategoryDatas(commonData && commonData.cat_info)
            // setdivisionDatas(commonData && commonData.div_infos)
            // setunitDatas(commonData && commonData.chart_units)
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {

                let data = commonData && commonData.bg_groups && commonData.bg_groups.length && commonData.bg_groups.map((item, i) => {
                    return `${item.bg_id}`
                })
                setBusiness([...new Set(data)])
            }
            // if (commonData && commonData.d_info && commonData.d_info.length > 0) {

            //     let data = commonData && commonData.d_info && commonData.d_info.length && commonData.d_info.map((item, i) => {
            //         return item.d_id
            //     })
            //     setDomain([...new Set(data)])
            // }
            // if (country_info && country_info.length > 0) {

            //     let data = country_info && country_info.length && country_info.map((item, i) => {
            //         return `${item.c_id}`
            //     })
            //     setCountry([...new Set(data)])
            // }
            if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {

                let data = commonData && commonData.le_did_infos && commonData.le_did_infos.length && commonData.le_did_infos.map((item, i) => {
                    return item.le_id
                })
                setEntity(data)
            }

        }

    }, [isAuth, entityid, commonData])

    // useEffect(() => {
    //     if (business.length > 0) {
    //         setBusinessGroupValue([...new Set()])
    //     }

    // }, [business])
    console.log(business, 'businessValue');

    // useEffect(() => {
    //     if (commonData) {
    //         let temp = []
    //         let temp1 = []
    //         let temp2 = []
    //         for (let i in commonData.d_info) {
    //             temp.push(commonData.d_info[i].d_id)
    //         }
    //         for (let j in uniq_country_data) {
    //             temp1.push(`${uniq_country_data[j].c_id}`)
    //             temp2.push(uniq_country_data[j].c_id)
    //         }
    //         console.log(temp, 'temp');
    //         // setFilterData({
    //         //     ...filterData,
    //         //     country: temp1,
    //         //     countryArr: temp2,
    //         //     domain: temp
    //         // })
    //         setCountry(temp1)
    //         setDomain(temp)
    //     }
    // }, [commonData, uniq_country_data])


    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        // entityValue && entityValue.length > 0 && entityValue.map(function (user) {

        //     console.log(EntityData, 'EntityData');
        //     console.log(user, 'user.le_id ');
        //     let entityData = _.filter(EntityData, { le_id: user })
        //     console.log(entityData, 'entityData');
        //     arrunit.push(entityData[0])

        // });
        let id = []
        if (business && business.length > 0) {


            for (let i in business) {
                let entityData = _.filter(businessGroupData && businessGroupData.length > 0 && businessGroupData, { bg_id: Number(business[i]) })
                console.log(entityData, 'entityData');
                arrunit.push(entityData[0])
            }
            console.log(arrunit, 'arrunit');

            arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
                let entityArrayunit = []
                let tempArrayunit = []

                EntityDatas && EntityDatas.length > 0 && EntityDatas.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');
                    if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                        entityArrayunit = {
                            'bg_id': subunitlist.bg_id,
                            'le_name': subunitlist.le_name,
                            'le_id': subunitlist.le_id,

                            'label': subunitlist.le_name,
                            'values': subunitlist.le_id
                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                    else {
                        entityArrayunit = {
                            'bg_id': subunitlist.bg_id,
                            'le_name': subunitlist.le_name,
                            'le_id': subunitlist.le_id,

                            'label': subunitlist.le_name,
                            'values': subunitlist.le_id
                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                })
                if (tempArrayunit && tempArrayunit.length > 0) {
                    for (let i in tempArrayunit) {
                        id.push(tempArrayunit[i].le_id)
                    }
                }
                totalListunit = {
                    'label': pmenu && pmenu.bg_name == null ? 'Others' : pmenu && pmenu.bg_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'aishwarya');
                console.log(totalListunit, 'unitListData')
                countrylisttempdataunit.push(totalListunit)
            })
            console.log(id, 'oooooooooo');
            // setEntity(id)
            setLegalEntityList(countrylisttempdataunit)

        }
        // else {


        //     for (let i in businessGroupData) {
        //         let entityData = _.filter(businessGroupData && businessGroupData.length > 0 && businessGroupData, { bg_id: Number(businessGroupData[i].bg_id) })
        //         console.log(entityData, 'entityData');
        //         arrunit.push(entityData[0])
        //     }
        //     console.log(arrunit, 'arrunit');

        //     arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
        //         let entityArrayunit = []
        //         let tempArrayunit = []

        //         EntityData && EntityData.length > 0 && EntityData.map((subunitlist) => {
        //             console.log(subunitlist, 'subunitlist');
        //             if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
        //                 entityArrayunit = {
        //                     'bg_id': subunitlist.bg_id,
        //                     'le_name': subunitlist.le_name,
        //                     'le_id': subunitlist.le_id,
        //                     'label': subunitlist.le_name,
        //                     'values': subunitlist.le_id
        //                 }
        //                 tempArrayunit.push(entityArrayunit)
        //             }
        //         })

        //         totalListunit = {
        //             'label': pmenu && pmenu.bg_name == null ? 'Others' : pmenu && pmenu.bg_name,
        //             'options': tempArrayunit
        //         }
        //         console.log(totalListunit, 'aishwarya');
        //         console.log(totalListunit, 'unitListData')
        //         countrylisttempdataunit.push(totalListunit)
        //     })
        //     setLegalEntityList(countrylisttempdataunit)

        // }
    }, [business, EntityDatas])
    console.log(LegalEntityList, 'LegalEntityList');
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCommonData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityData, entityid])

    const showmore = () => {
        setShow(true)
        // setCount(count + 100)
        setCountValue(countValue + 100)

        searchingValue['business_group'] = ''
        searchingValue['legal_entity'] = ''
        searchingValue['division'] = ''
        searchingValue['compliance'] = ''
        searchingValue['criticality'] = ''
        searchingValue['assignedTo'] = ''
        searchingValue['overdue'] = ''
        setSearchedValue({
            ...searchedValue,
            bgGroup: '',
            legalEntity: '',
            division: '',
            compliance: '',
            criticality: '',
            assignedTo: '',
            overdue: ''
        })

        // const payload = [
        //     authtoken,
        //     {
        //         "session_token": authtoken,
        //         "request": [
        //             criticaltityStatus === true ? 'GetComplianceCriticalityStatusDrillDownData' : "GetComplianceStatusDrillDownData",
        //             {
        //                 "d_ids": domainIds,
        //                 "from_date": null,
        //                 "to_date": null,
        //                 "filter_type": ((fieldType != "") && (fieldType != 'Date')) ? fieldType : filterData.chartType,
        //                 "filter_ids":
        //                     filterId
        //                 ,
        //                 "compliance_status":compliance,
        //                 "chart_year": year,
        //                 "record_count": countValue + 100,
        //                 "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
        //                 "country_domains": countryDomain,
        //                 "criticality": criticaltityStatus === true ? criticality : ''
        //             }
        //         ]
        //     }
        // ]
        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    criticaltityStatus === true ? 'GetComplianceCriticalityStatusDrillDownData' : "GetComplianceStatusDrillDownData",
                    {
                        "d_ids": domainIds,
                        "from_date": dateState === true ? moment(filterData.from_date).format('DD-MMM-YYYY') : null,
                        "to_date": dateState === true ? moment(filterData.to_date).format('DD-MMM-YYYY') : null,
                        "filter_type": ((fieldType != "") && (fieldType != 'Date')) ? fieldType : filterData.chartType,
                        "filter_id": filterId,
                        "compliance_status": compliance,
                        "chart_year": year,
                        "record_count": countValue + 100,
                        "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                        "country_domains": countryDomain,
                        "criticality": criticaltityStatus === true ? criticality : ''
                    }
                ]
            }
        ]
        if (paramid != null) {
            getComplianceTrendShowMoreTableData({
                payload: payload1,
                paramid: paramid
            })
        }
        console.log(payload1, 'snehaa');

    }

    const showmore1 = () => {
        setShow(true)
        // setCount(count + 100)
        setCountValue(countValue + 100)

        searchingValue['business_group'] = ''
        searchingValue['legal_entity'] = ''
        searchingValue['division'] = ''
        searchingValue['compliance'] = ''
        searchingValue['criticality'] = ''
        searchingValue['assignedTo'] = ''
        searchingValue['overdue'] = ''
        setSearchedValue({
            ...searchedValue,
            bgGroup: '',
            legalEntity: '',
            division: '',
            compliance: '',
            criticality: '',
            assignedTo: '',
            overdue: ''
        })

        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    criticaltityStatus === true ? 'GetComplianceCriticalityStatusDrillDownData' : "GetComplianceStatusDrillDownData",
                    {
                        "d_ids": domainIds,
                        "from_date": dateState === true ? moment(filterData.from_date).format('DD-MMM-YYYY') : null,
                        "to_date": dateState === true ? moment(filterData.to_date).format('DD-MMM-YYYY') : null,
                        "filter_type": ((fieldType != "") && (fieldType != 'Date')) ? fieldType : filterData.chartType,
                        "filter_id": filterId,
                        "compliance_status": compliance,
                        "chart_year": year,
                        "record_count": countValue + 100,
                        "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                        "country_domains": countryDomain,
                        "criticality": criticaltityStatus === true ? criticality : ''
                    }
                ]
            }
        ]
        if (paramid != null) {
            getComplianceTrendShowMoreTableData({
                payload: payload1,
                paramid: paramid
            })
        }
    }

    console.log(fieldType, 'fieldType');



    const filterChange = () => {
        setGo(true)
        if (((groupCheckBox == true) || (checkBox == true)) && errorField === '') {

            if (criticaltityStatus == false) {

                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetComplianceStatusChart",
                            {
                                "c_ids": cIds,
                                "d_ids": domain.length > 0 ? domain : domainIds,
                                "from_date": dateState === true ? moment(filterData.from_date).format('DD-MMM-YYYY') : null,
                                "to_date": dateState === true ? moment(filterData.to_date).format('DD-MMM-YYYY') : null,
                                "filter_type": ((fieldType != "") && (fieldType != 'Date')) ? fieldType : filterData.chartType,
                                // filterData && filterData.chartType,
                                "filter_ids":
                                    fieldType == "LegalEntity" ? Entity :
                                        fieldType == 'Division' ? divisionDatas :
                                            fieldType == 'Category' ? categoryData :
                                                fieldType == 'Unit' ? unitData :
                                                    fieldType == 'BusinessGroup' ? businessIds :
                                                        fieldType == 'Date' || consolidated === true || filterData.chartType === "Group" ? null :
                                                            1
                                ,
                                "le_ids": Entity && Entity.length > 0 ? Entity : [entityid],
                                "country_domains": countryDomain,
                                "chart_year": year,
                                "criticality": ""
                            }
                        ]
                    }
                ]
                const payload1 = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request":
                            ["GetComplianceStatusChart",
                                {
                                    "c_ids": cIds,
                                    "d_ids": domain.length > 0 ? domain : domainIds,
                                    "filter_type": ((fieldType != "") && (fieldType != 'Date')) ? fieldType : filterData.chartType,
                                    // filterData && filterData.chartType,
                                    "filter_ids":
                                        fieldType == "LegalEntity" ? Entity :
                                            fieldType == 'Division' ? divisionDatas :
                                                fieldType == 'Category' ? categoryData :
                                                    fieldType == 'Unit' ? unitData :
                                                        fieldType == 'BusinessGroup' ? businessIds :
                                                            fieldType == 'Date' || consolidated === true || filterData.chartType === "Group" ? null :
                                                                1
                                    ,
                                    "from_date": dateState === true ? moment(filterData.from_date).format('DD-MMM-YYYY') : null,
                                    "to_date": dateState === true ? moment(filterData.to_date).format('DD-MMM-YYYY') : null,
                                    "criticality": "",
                                    "le_ids": entityData,
                                    "country_domains": countryDomain,
                                    "chart_year": year
                                }
                            ]
                    }
                ]
                console.log(payload, 'iiiiiiiiiiiii');
                if (checkBox == true) {
                    getCompliancestatuschart({
                        payload: payload1,
                        paramid: paramid
                    })
                }
                else {
                    getCompliancestatuschart({
                        payload: payload,
                        paramid: paramid
                    })
                }
                setOpen(false);
            }
            else {
                console.log(criticaltityStatus, 'criticaltityStatus');
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request":
                            ["GetComplianceCriticalityStatusChart",
                                {
                                    "c_ids": cIds,
                                    "d_ids": domainIds,
                                    "criticality": criticaltityStatus == true ? criticality : "",
                                    "from_date": dateState === false ? null : moment(filterData.from_date).format('DD-MMM-YYYY'),
                                    "to_date": dateState === false ? null : moment(filterData.to_date).format('DD-MMM-YYYY'),
                                    "filter_type": ((fieldType != "") && (fieldType != 'Date')) ? fieldType : filterData.chartType,
                                    // filterData && filterData.chartType,
                                    "filter_ids":
                                        fieldType == "LegalEntity" ? Entity :
                                            fieldType == 'Division' ? divisionDatas :
                                                fieldType == 'Category' ? categoryData :
                                                    fieldType == 'Unit' ? unitData :
                                                        fieldType == 'BusinessGroup' ? businessIds :
                                                            fieldType == 'Date' || consolidated === true || filterData.chartType === "Group" ? null :
                                                                1
                                    ,
                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                    "country_domains": countryDomain,
                                    "chart_year": year
                                }
                            ]
                    }
                ]
                const payload1 = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request":
                            ["GetComplianceCriticalityStatusChart",
                                {
                                    "c_ids": cIds,
                                    "d_ids": domainIds,
                                    "filter_type": ((fieldType != "") && (fieldType != 'Date')) ? fieldType : filterData.chartType,
                                    "from_date": dateState === false ? null : moment(filterData.from_date).format('DD-MMM-YYYY'),
                                    "to_date": dateState === false ? null : moment(filterData.to_date).format('DD-MMM-YYYY'),
                                    "filter_ids":
                                        fieldType == "LegalEntity" ? Entity :
                                            fieldType == 'Division' ? divisionDatas :
                                                fieldType == 'Category' ? categoryData :
                                                    fieldType == 'Unit' ? unitData :
                                                        fieldType == 'BusinessGroup' ? businessIds :
                                                            fieldType == 'Date' || consolidated === true || filterData.chartType === "Group" ? null :
                                                                1
                                    ,
                                    "criticality": criticality,
                                    "le_ids": entityData,
                                    "country_domains": countryDomain,
                                    "chart_year": year
                                }
                            ]
                    }
                ]
                getCriticalityFilter({
                    payload: checkBox == true ? payload1 : payload,
                    paramid: paramid
                })
                setOpen(false);

            }
        }
        else { // toast.error('Please select the filter')         

            toast.error(errorField ? errorField : 'Please select the filter')
        }


    }



    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let id = []
        for (let i in Entity) {
            let entityData = _.filter(EntityDatas && EntityDatas.length > 0 && EntityDatas, { le_id: Entity[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            let arr = []

            divisionData && divisionData.length > 0 && divisionData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'd_id': subunitlist.div_id,
                        'd_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.div_name,
                        'values': subunitlist.div_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }

            })

            console.log(tempArrayunit.length, 'tempArrayunit');

            if (tempArrayunit.length > 0) {
                const unique = _.uniqBy(tempArrayunit, function (e) {

                    return e.d_id;

                });
                arr = unique

            }
            if (arr.length > 0) {
                for (let i in arr) {
                    id.push(arr[i].d_id)
                }
            }



            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': arr
            }

            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })

        // if (localStorage.getItem('SelectedEntity') == 'All Legal Entity') {
        setdivisionDatas(id)
        // }

        setDivisionList(countrylisttempdataunit)


    }, [divisionData, EntityDatas, Entity])

    console.log(divisionData, 'divisionData');
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let id = []
        // entityValue && entityValue.length > 0 && entityValue.map(function (user) {

        //     console.log(EntityData, 'EntityData');
        //     console.log(user, 'user.le_id ');
        //     let entityData = _.filter(EntityData, { le_id: user })
        //     console.log(entityData, 'entityData');
        //     arrunit.push(entityData[0])

        // });
        let divisionDatas = []

        // for (let j in DivisionValue) {
        //     let division = _.filter(divisionData, { d_id: DivisionValue[j] })
        //     divisionDatas.push(division[0])
        // }
        console.log(divisionDatas, 'divisionDatassssss');
        for (let i in Entity) {
            let entityData = _.filter(EntityDatas && EntityDatas.length > 0 && EntityDatas, { le_id: Entity[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])

            console.log(arrunit, 'arrunit');
        }
        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            let arr = []
            categoryDatas && categoryDatas.length > 0 && categoryDatas.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    if (divisionData.length > 0) {
                        let data = _.find(divisionData, { div_id: subunitlist.div_id })
                        console.log(data, 'datadatadatadata');
                        entityArrayunit = {
                            'cat_id': subunitlist.cat_id,
                            'cat_name': subunitlist.cat_name,
                            'div_name': data && data.div_name,
                            'le_id': subunitlist.le_id,
                            'bg_id': subunitlist.bg_id,
                            'label': subunitlist.cat_name,
                            'values': subunitlist.cat_id
                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                }
            })
            if (tempArrayunit.length > 0) {
                const unique = _.uniqBy(tempArrayunit, function (e) {

                    return e.cat_id;

                });
                arr = unique

            }
            if (arr.length > 0) {
                for (let i in arr) {
                    id.push(arr[i].cat_id)
                }
            }
            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': arr
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        // if (localStorage.getItem('SelectedEntity') == 'All Legal Entity') {
        setcategoryData(id)
        // }
        setCategoryList(countrylisttempdataunit)


    }, [categoryDatas, EntityDatas, Entity, divisionData])
    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
    //         if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
    //             let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
    //             console.log(filteredLegalEntity, 'filteredLegalEntity');
    //             let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity && filteredLegalEntity[0].bg_id })
    //             console.log(filteredBusinessGroup, 'filteredBusinessGroup');
    //             setBusinessGroupdata(filteredBusinessGroup)
    //             setBusiness(`${filteredBusinessGroup && filteredBusinessGroup.length > 0 && filteredBusinessGroup[0].bg_id}`)
    //             // const unique = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
    //             //     return e.bg_id;
    //             // });
    //             // console.log(unique, 'unique');
    //             // setfield({
    //             //     ...field,
    //             //     business: unique,
    //             //     legalEntity: filteredLegalEntity
    //             // })
    //         }
    //     }


    // }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    //     useEffect(()=>{
    // if(selectChart.length>0){}
    //     },[])
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let id = []
        // entityValue && entityValue.length > 0 && entityValue.map(function (user) {

        //     console.log(EntityData, 'EntityData');
        //     console.log(user, 'user.le_id ');
        //     let entityData = _.filter(EntityData, { le_id: user })
        //     console.log(entityData, 'entityData');
        //     arrunit.push(entityData[0])

        // });
        let divisionDatas = []

        // for (let j in DivisionValue) {
        //     let division = _.filter(divisionData, { d_id: DivisionValue[j] })
        //     divisionDatas.push(division[0])
        // }
        console.log(divisionDatas, 'divisionDatassssss');
        for (let i in Entity) {
            let entityData = _.filter(EntityDatas && EntityDatas.length > 0 && EntityDatas, { le_id: Entity[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])

            console.log(arrunit, 'arrunit');
        }
        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            let arr = []
            unitDatas && unitDatas.length > 0 && unitDatas.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {


                    entityArrayunit = {
                        'cat_id': subunitlist.category_id,
                        // 'cat_name': subunitlist.cat_name,
                        // 'div_name': data && data.div_name,
                        'le_id': subunitlist.le_id,
                        'bg_id': subunitlist.business_group_id,
                        'd_ids': subunitlist.d_ids,
                        'division_id': subunitlist.division_id,
                        'u_id': subunitlist.u_id,
                        'u_name': subunitlist.u_name,
                        'label': subunitlist.u_name,
                        'values': subunitlist.u_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }

            })
            if (tempArrayunit.length > 0) {
                for (let i in tempArrayunit) {
                    id.push(tempArrayunit[i].u_id)
                }
            }
            // if (tempArrayunit.length > 0) {
            //     const unique = _.uniqBy(tempArrayunit, function (e) {

            //         return e.cat_id;

            //     });
            //     arr = unique

            // }
            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        // if (localStorage.getItem('SelectedEntity') == 'All Legal Entity') {
        setunitData(id)
        // }
        setUnitList(countrylisttempdataunit)


    }, [unitDatas, EntityDatas, Entity])

    useEffect(() => {
        if (unitList && unitList.length > 0) {
            let temp = []
            for (let i in unitList) {
                // temp.push(unitList[i].options)
                temp = [...temp, ...unitList[i].options]
            }
            console.log(temp, 'smsmsm');
            setUnitListOptionList(temp)
        }
        if (categoryList && categoryList.length > 0) {
            let temp = []
            for (let i in categoryList) {
                // temp.push(unitList[i].options)
                temp = [...temp, ...categoryList[i].options]
            }
            console.log(temp, 'smsmsm');
            setCategoryOptionList(temp)
        }
        if (divisionList && divisionList.length > 0) {
            let temp = []
            for (let i in divisionList) {
                // temp.push(unitList[i].options)
                temp = [...temp, ...divisionList[i].options]
            }
            console.log(temp, 'smsmsm');
            setDivisionOptionList(temp)
        }

    }, [divisionList, categoryList, unitList])
    console.log(divisionList, 'divisionList');
    console.log(categoryList, 'categoryList');
    console.log(unitList, 'unitList77777');

    useEffect(() => {
        if ((criticaltityStatus == false) && (criticality != '')) {
            if ((commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id != undefined) && ((countryDomain && countryDomain.length > 0) && (domainIds && domainIds.length > 0)) && (entityData && entityData.length > 0)) {
                console.log(entityid, 'entttttt');
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request":
                            ["GetComplianceStatusChart",
                                {
                                    "c_ids": cIds,
                                    "d_ids": domainIds,
                                    "filter_type": ((fieldType != "") && (fieldType != 'Date')) ? fieldType : filterData.chartType,
                                    "from_date": dateState === false ? null : moment(filterData.from_date).format('DD-MMM-YYYY'),
                                    "to_date": dateState === false ? null : moment(filterData.to_date).format('DD-MMM-YYYY'),
                                    "filter_ids":
                                        fieldType == "LegalEntity" ? Entity :
                                            fieldType == 'Division' ? divisionDatas :
                                                fieldType == 'Category' ? categoryData :
                                                    fieldType == 'Unit' ? unitData :
                                                        fieldType == 'BusinessGroup' ? businessIds :
                                                            fieldType == 'Date' || consolidated === true || filterData.chartType === "Group" ? null :
                                                                1
                                    ,
                                    "criticality": "",
                                    "le_ids": entityData,
                                    "country_domains": countryDomain,
                                    "chart_year": year
                                }
                            ]
                    }
                ]
                if (paramid != "") {
                    getCompliancestatuschart({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
        }

    }, [isAuth, entityid, commonData, entityData, entityid, year, countryDomain, domainIds])
    console.log(country, 'country');

    const CountryHandelChange = (obj) => {
        setErrorField('')
        setFieldTypeClick('Country')
        console.log(obj, 'objjjj');
        let allArray = [];
        let temp5 = []
        let filterDomain = []
        for (let j in obj) {
            filterDomain = _.filter(commonData.d_info, { c_id: Number(obj[j]) })
            // temp2.push(filterDomain)
            console.log(filterDomain, 'filterDomain');
            for (let i in filterDomain) {
                temp5.push(filterDomain[i])
            }

        }
        let id = temp5 && temp5.length > 0 && temp5.map((item, i) => {
            return item.d_id
        })
        console.log(id, 'id');
        setDomain(id)
        if (obj.includes('all')) {
            if (country_info && country_info.length > 0) {
                let temp = []
                for (let i in country_info) {
                    temp.push(country_info[i].c_id)


                }
                setCountry(temp)

            }
            Setcountryselectcheckbox('none');
            Setcountryunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setDomain([])
            setEntity([])
            setBusiness([])
            setBusinessIds([])
            setdivisionDatas([])
            setcategoryData([])
            setunitData([])

            setCountry([])
            Setcountryselectcheckbox('block');
            Setcountryunselectcheckbox('none');
        } else {
            setCountry(obj)
            setDomain(id)
            setEntity([])
            setBusiness([])
            setBusinessIds([])
            setdivisionDatas([])
            setcategoryData([])
            setunitData([])

        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    useEffect(() => {
        if (businessGroupData.length > 0 && business.length > 0) {
            if (business.length == businessGroupData.length) {
                Setbusinessselectcheckbox('none');
                Setbusinessunselectcheckbox('block');
            } else {
                Setbusinessselectcheckbox('block');
                Setbusinessunselectcheckbox('none');
            }
        }
        if ((EntityDatas && EntityDatas.length > 0) && (Entity && Entity.length > 0)) {
            if (Entity.length == EntityDatas.length) {
                SetEntityselectcheckbox('none');
                SetEntityUnselectcheckbox('block');
            } else {
                SetEntityselectcheckbox('block');
                SetEntityUnselectcheckbox('none');
            }
        }
        if ((divisionData && divisionData.length > 0) && (divisionDatas && divisionDatas.length > 0)) {
            if (divisionData.length == divisionDatas.length) {
                Setdivisionselectcheckbox('none');
                SetdivisionUnselectcheckbox('block');
            } else {
                Setdivisionselectcheckbox('block');
                SetdivisionUnselectcheckbox('none');
            }
        }
        if ((categoryDatas && categoryDatas.length > 0) && (categoryData && categoryData.length > 0)) {
            if (categoryDatas.length == categoryData.length) {
                Setcategoryselectcheckbox('none');
                SetcategoryUnselectcheckbox('block');
            } else {
                Setcategoryselectcheckbox('block')
                SetcategoryUnselectcheckbox('none');
            }
        }
        if ((unitData && unitData.length > 0) && (unitDatas && unitDatas.length > 0)) {
            if (unitData.length == unitDatas.length) {
                Setunitselectcheckbox('none');
                SetunitUnselectcheckbox('block');
            } else {
                Setunitselectcheckbox('block');
                SetunitUnselectcheckbox('none');
            }
        }
    }, [business, businessGroupData, EntityDatas, Entity, divisionDatas, divisionData, categoryData, categoryDatas, unitData, unitDatas])


    // useEffect(() => {
    //     if (Entity.length > 0) {

    //     }

    // }, [Entity])

    const BusinessHandelChange = (obj, data) => {
        if (obj == undefined) {
            setBusiness([])
            setBusinessIds([])
        }
        setErrorField('')
        console.log(obj, 'objjjj');
        setFieldType('BusinessGroup')
        setGroupCheckBox(true)
        setCheckBox(false)
        setConsolidated(false)
        setGo(false)
        let temp = ['Business', 'Legal', 'Division', 'Category', 'Unit']
        setTrueState(temp)
        // let temp = 'Business'
        // trueState.push(temp)
        let allArray = [];
        if (obj.includes('all')) {
            if (businessGroupData && businessGroupData && businessGroupData.length > 0) {
                let temp = []
                let temp1 = []
                for (let i in businessGroupData) {
                    temp.push(`${businessGroupData[i].bg_id}`)
                    temp1.push(Number(businessGroupData[i].bg_id))
                    allArray.push(businessGroupData[i].bg_id)

                }
                setBusiness(temp)
                setBusinessIds(temp1)
            }
            Setbusinessselectcheckbox('none');
            Setbusinessunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            setFieldType('BusinessGroup')

            let temp = [];
            setBusiness([]);
            setBusinessIds([])
            Setbusinessselectcheckbox('block');
            Setbusinessunselectcheckbox('none');
        } else {
            setBusiness(obj)
            setBusinessIds(Number(obj))
            setGroupCheckBox(true)
            setCheckBox(false)
            setConsolidated(false)
            setEntity([])
            setdivisionDatas([])
            setcategoryData([])
            setunitData([])
            setFieldType('BusinessGroup')
            if (obj.length == businessGroupData.length) {
                Setbusinessselectcheckbox('none');
                Setbusinessunselectcheckbox('block');
            } else {
                Setbusinessselectcheckbox('block');
                Setbusinessunselectcheckbox('none');
            }
            // if (obj.length > 0) {
            //     let temp = []
            //     for (let i in obj) {
            //         let data = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { bg_id: Number(obj[i]) })

            //         for (let j in data) {
            //             temp.push(data[j])
            //         }
            //         // temp.push(data)
            //         console.log(temp, 'tempppp');
            //     }
            //     setEntityData(temp)
            // }
            // else {
            //     setEntityData(userManagementDetails && userManagementDetails.um_legal_entity)
            // }
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };



    const compliancetaskfilter = (e, type, searchedArray) => {
        console.log(searchedArray, "searchedArray");
        let filterTable = sortingpurpose

        if (searchingValue['business_group']) {
            filterTable = filterTable.filter((item) => {
                if ((item["bg_name"]?.toLowerCase().includes(searchingValue['business_group'].toLowerCase())) || (item["bg_name"].toLowerCase().includes(searchingValue['business_group'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['legal_entity']) {
            filterTable = filterTable.filter((item) => {
                if ((item["le_name"].toLowerCase().includes(searchingValue['legal_entity'].toLowerCase())) || (item["le_name"].toLowerCase().includes(searchingValue['legal_entity'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['division']) {
            filterTable = filterTable.filter((item) => {
                if ((item["div_name"]?.toLowerCase().includes(searchingValue['division'].toLowerCase())) || (item["div_name"]?.toLowerCase().includes(searchingValue['division'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['compliance']) {
            filterTable = filterTable.filter((item) => {
                if ((item["comp_name"].toLowerCase().includes(searchingValue['compliance'].toLowerCase())) || (item["comp_name"].toLowerCase().includes(searchingValue['compliance'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['criticality']) {
            filterTable = filterTable.filter((item) => {
                if ((item["criticality"]?.toLowerCase().includes(searchingValue['criticality'].toLowerCase())) || (item["criticality"]?.toLowerCase().includes(searchingValue['criticality'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['assignedTo']) {
            filterTable = filterTable.filter((item) => {
                if ((item["assignee_name"].toLowerCase().includes(searchingValue['assignedTo'].toLowerCase())) || (item["assignee_name"].toLowerCase().includes(searchingValue['assignedTo'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['overdue']) {
            filterTable = filterTable.filter((item) => {
                if ((item["ageing"].toLowerCase().includes(searchingValue['overdue'].toLowerCase())) || (item["ageing"].toLowerCase().includes(searchingValue['overdue'].toLowerCase()))) {
                    return true;
                }
            })
        }

        let arr = [];
        let filteredtemp = []
        let fiteredunitname = []

        if (filterTable && filterTable.length > 0) {
            filterTable &&
                filterTable.length > 0 &&
                filterTable.map(function (user) {
                    if (arr.indexOf(user.u_name) < 0)
                        arr.push({ u_name: user.u_name });
                });
            let uniqle = _.uniqBy(arr, function (e) {
                return e.u_name;
            });
            for (let k in uniqle) {
                let filteredObject = _.filter(filterTable, { u_name: uniqle[k].u_name })
                filteredtemp.push(filteredObject)
            }
            for (let k in filteredtemp) {
                let uniqle1 = _.uniqBy(filteredtemp[k], function (e) {
                    return e.unitname;
                });
                let uniq_unitname = uniqle1.map((item) => { return item.unitname })
                fiteredunitname.push(uniq_unitname)
            }
            let FinalfilteredObject = []
            for (let k in fiteredunitname) {
                let temp = []
                for (let j in fiteredunitname[k]) {
                    let filteredObject = _.filter(filteredtemp[k], { unitname: fiteredunitname[k][j] })
                    temp.push(filteredObject)
                }
                FinalfilteredObject.push(temp)
            }
            setTempcriticalityTable(FinalfilteredObject)
            console.log(FinalfilteredObject, 'FinalfilteredObject');
        } else {
            setTempcriticalityTable([])
        }
    };

    // const BusinessHandelChange = (obj) => {
    //     console.log(obj, 'objjjj');
    //     let allArray = [];
    //     if (obj.includes('all')) {
    //         if (businessGroupData && businessGroupData.length > 0) {
    //             let temp = []
    //             for (let i in businessGroupData) {
    //                 temp.push(businessGroupData[i].bg_id)


    //             }
    //             setBusiness(temp)
    //             // setfield({
    //             //     ...field,
    //             //     legalEntity: commonData && commonData.le_did_infos
    //             // })

    //         }
    //         Setbusinessselectcheckbox('none');
    //         Setbusinessunselectcheckbox('block');
    //     } else if (obj.includes('unselect')) {
    //         let temp = [];
    //         // setfield({
    //         //     ...field,
    //         //     legalEntity: commonData && commonData.le_did_infos
    //         // })
    //         setBusiness([])
    //         Setbusinessselectcheckbox('block');
    //         Setbusinessunselectcheckbox('none');
    //     } else {
    //         setBusiness(obj)
    //         // if (obj.length > 0) {
    //         //     let temp = []
    //         //     for (let i in obj) {
    //         //         let data = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(obj[i]) })

    //         //         for (let j in data) {
    //         //             temp.push(data[j])
    //         //         }
    //         //         // temp.push(data)
    //         //         console.log(temp, 'tempppp');
    //         //     }
    //         //     setfield({
    //         //         ...field,
    //         //         legalEntity: temp
    //         //     })
    //         // }

    //     }
    //     // setUserDetails({
    //     //     ...userDetails,
    //     //     units: (allArray && allArray.length > 0 ? allArray : obj)
    //     // })
    //     return obj;

    // };
    console.log(division, 'division');
    // const EntityHandleChane = (obj) => {
    //     console.log(field.legalEntity, 'objjfield.domainjj');
    //     let allArray = [];
    //     if (obj.includes('all')) {
    //         if (field.legalEntity && field.legalEntity.length > 0) {
    //             let temp = []
    //             for (let i in field.legalEntity) {
    //                 console.log(field.legalEntity[i].le_id, 'domain[i].d_id');
    //                 temp.push(field.legalEntity[i].le_id)


    //             }
    //             console.log(temp, 'temp');
    //             setEntity(temp)
    //             setFieldType("LegalEntity")

    //         }
    //         SetEntityselectcheckbox('none');
    //         SetEntityUnselectcheckbox('block');

    //     } else if (obj.includes('unselect')) {
    //         let temp = [];

    //         setEntity([])
    //         setFieldType("")
    //         SetEntityselectcheckbox('block');
    //         SetEntityUnselectcheckbox('none');
    //     } else {
    //         console.log(obj, 'obj');
    //         setFieldType("LegalEntity")
    //         setEntity(obj)
    //         // if (obj.length > 0) {
    //         //     let temp = []
    //         //     let temp2 = []
    //         //     for (let i in obj) {
    //         //         console.log(obj,'objobjobj');
    //         //         console.log(commonData && commonData.div_infos,'commonData && commonData.div_infos');
    //         //         let data = _.filter(commonData && commonData.div_infos, { le_id: Number(obj[i]) })
    //         //         console.log(data, 'data3333');
    //         //         for (let j in data) {
    //         //             temp.push(data[j])
    //         //         }
    //         //         let datas = _.filter(commonData && commonData.cat_info, { le_id: Number(obj[i]) })

    //         //         for (let j in datas) {
    //         //             temp2.push(datas[j])
    //         //         }
    //         //         // temp.push(data)
    //         //         console.log(temp, 'tempppp');
    //         //     }
    //         //     setDivision(temp)
    //         //     setCategory(temp2)
    //         // }
    //     }
    //     // setUserDetails({
    //     //     ...userDetails,
    //     //     units: (allArray && allArray.length > 0 ? allArray : obj)
    //     // })
    //     return obj;

    // };
    const EntityHandleChane = (obj, value) => {
        console.log(obj, 'obj');
        console.log(value, 'value');
        if (obj == undefined) {
            console.log('sneais');
            setEntityData([])
        }
        else {
            setErrorField('')
            console.log(value, 'datadatadatadatadatadata');
            setGroupCheckBox(true)
            setGo(false)
            setConsolidated(false)
            setCheckBox(false)
            setFieldType('LegalEntity')
            let temp = ['Business', 'Legal', 'Division', 'Category', 'Unit']
            setTrueState(temp)
            // let temp = 'Legal'
            // trueState.push(temp)
            let allArray = [];
            if (obj.includes('all')) {
                if (EntityDatas && EntityDatas && EntityDatas.length > 0) {
                    let temp = []
                    let temp2 = []
                    for (let i in EntityDatas) {
                        temp.push(EntityDatas[i].le_id)
                        allArray.push(EntityDatas[i].le_id)
                        // let data = _.find(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: EntityData[i].le_id })
                        // console.log(data, 'ppp');
                        // if (data != undefined) {
                        //     temp2.push(data)
                        // }

                    }
                    // (EntityData)
                    setEntity(temp)
                    // setDomainData(userManagementDetails && userManagementDetails.um_legal_domain)
                }
                SetEntityselectcheckbox('none');
                SetEntityUnselectcheckbox('block');
            } else if (obj.includes('unselect')) {
                setFieldType('LegalEntity')

                let temp = [];
                setEntity([]);
                SetEntityselectcheckbox('block');
                SetEntityUnselectcheckbox('none');
            } else {
                console.log(obj, 'objobjobjobjobj');
                setFieldType('LegalEntity')
                setGroupCheckBox(true)
                setConsolidated(false)
                setCheckBox(false)

                if (obj.length == EntityDatas.length) {
                    SetEntityselectcheckbox('none');
                    SetEntityUnselectcheckbox('block');
                } else {
                    SetEntityselectcheckbox('block');
                    SetEntityUnselectcheckbox('none');
                }


                // setLegalEntity(datas)
                // setDomainData(data)

                setEntity(obj)



            }
            // setUserDetails({
            //     ...userDetails,
            //     units: (allArray && allArray.length > 0 ? allArray : obj)
            // })
            return obj;
        }

    };



    console.log(divisionDatas, 'divisionDatas');
    // const DivisionHandleChane = (obj) => {
    //     console.log(field.domain, 'objjfield.domainjj');
    //     let allArray = [];
    //     if (obj.includes('all')) {
    //         if (division && division.length > 0) {
    //             let temp = []
    //             for (let i in division) {
    //                 console.log(division[i].div_id, 'domain[i].d_id');
    //                 temp.push(division[i].div_id)


    //             }
    //             console.log(temp, 'temp');
    //             setdivisionDatas(temp)
    //             setFieldType("Division")
    //         }
    //         Setdivisionselectcheckbox('none');
    //         SetdivisionUnselectcheckbox('block');
    //     } else if (obj.includes('unselect')) {
    //         let temp = [];

    //         setdivisionDatas([])
    //         setFieldType("")
    //         Setdivisionselectcheckbox('block');
    //         SetdivisionUnselectcheckbox('none');
    //     } else {
    //         console.log(obj, 'obj');
    //         let temp = []
    //         // for (let i in obj) {
    //         //     temp.push({obj[i]}`)
    //         // }
    //         setFieldType("Division")
    //         setdivisionDatas(obj)

    //     }
    //     // setUserDetails({
    //     //     ...userDetails,
    //     //     units: (allArray && allArray.length > 0 ? allArray : obj)
    //     // })
    //     return obj;

    // };

    console.log(EntityDatas, 'EntityDatas');
    console.log(Entity, 'Entity');
    console.log(LegalEntityList, 'LegalEntityList');



    const DivisionHandleChane = (obj) => {
        if (obj == undefined) {
            setdivisionDatas([])
        }
        else {
            setErrorField('')
            setFieldType('Division')
            setGo(false)
            setGroupCheckBox(true)
            setCoulmnState(true)
            setConsolidated(false)
            setCheckBox(false)
            // let temp = 'Division'
            // trueState.push(temp)
            console.log(obj, 'objjjj');
            let allArray = [];
            if (obj.includes('all')) {
                if (divisionData && divisionData.length > 0) {
                    let temp = []
                    let temp2 = []
                    for (let i in divisionData) {
                        temp.push(`${divisionData[i].d_id}`)
                        allArray.push(divisionData[i].div_id)


                    }
                    setdivisionDatas(allArray)
                    // setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
                }
                Setdivisionselectcheckbox('none');
                SetdivisionUnselectcheckbox('block');
            } else if (obj.includes('unselect')) {
                setFieldType('Division')
                let temp = [];
                setdivisionDatas([]);
                // setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
                Setdivisionselectcheckbox('block');
                SetdivisionUnselectcheckbox('none');
            } else {
                setFieldType('Division')
                setGroupCheckBox(true)
                setConsolidated(false)
                setCheckBox(false)
                if (obj.length == divisionData.length) {
                    Setdivisionselectcheckbox('none');
                    SetdivisionUnselectcheckbox('block');
                } else {
                    Setdivisionselectcheckbox('block');
                    SetdivisionUnselectcheckbox('none');
                }
                let temp = []
                setdivisionDatas(obj)



                console.log(obj, 'obj');
                // setCategoryDatas(data)
            }
            // setUserDetails({
            //     ...userDetails,
            //     units: (allArray && allArray.length > 0 ? allArray : obj)
            // })
            return obj;
        }

    };
    // const UnitHandleChane = (obj) => {
    //     console.log(field.domain, 'objjfield.domainjj');
    //     let allArray = [];
    //     if (obj.includes('all')) {
    //         if (unit && unit.length > 0) {
    //             let temp = []
    //             for (let i in unit) {
    //                 console.log(unit[i].u_id, 'domain[i].d_id');
    //                 temp.push(unit[i].u_id)


    //             }
    //             console.log(temp, 'temp');
    //             setunitData(temp)
    //             setFieldType("Unit")
    //         }
    //         Setunitselectcheckbox('none');
    //         SetunitUnselectcheckbox('block');
    //     } else if (obj.includes('unselect')) {
    //         let temp = [];

    //         setunitData([])
    //         setFieldType("")
    //         Setunitselectcheckbox('block');
    //         SetunitUnselectcheckbox('none');
    //     } else {
    //         console.log(obj, 'obj');
    //         let temp = []
    //         // for (let i in obj) {
    //         //     temp.push(obj[i]}`)
    //         // }
    //         setFieldType("Unit")
    //         setunitData(obj)

    //     }
    //     // setUserDetails({
    //     //     ...userDetails,
    //     //     units: (allArray && allArray.length > 0 ? allArray : obj)
    //     // })
    //     return obj;

    // };
    // const CategoryHandleChane = (obj) => {
    //     console.log(field.domain, 'objjfield.domainjj');
    //     let allArray = [];
    //     if (obj.includes('all')) {
    //         if (category && category.length > 0) {
    //             let temp = []
    //             for (let i in category) {
    //                 console.log(category[i].cat_id, 'domain[i].d_id');
    //                 temp.push(category[i].cat_id)


    //             }
    //             console.log(temp, 'temp');
    //             setcategoryData(temp)

    //         }
    //         setFieldType("Category")
    //         Setcategoryselectcheckbox('none');
    //         SetcategoryUnselectcheckbox('block');
    //     } else if (obj.includes('unselect')) {
    //         let temp = [];

    //         setcategoryData([])
    //         Setcategoryselectcheckbox('block');
    //         SetcategoryUnselectcheckbox('none');
    //         setFieldType("")
    //     } else {
    //         console.log(obj, 'obj');
    //         let temp = []
    //         // for (let i in obj) {
    //         //     temp.push(obj[i]}`)
    //         // }
    //         setcategoryData(obj)
    //         setFieldType("Category")
    //     }
    //     // setUserDetails({
    //     //     ...userDetails,
    //     //     units: (allArray && allArray.length > 0 ? allArray : obj)
    //     // })
    //     return obj;

    // };
    const CategoryHandleChange = (obj) => {

        if (obj == undefined) {
            setcategoryData([])
        }
        else {
            setErrorField('')
            setFieldType('Category')
            setGroupCheckBox(true)
            setGo(false)
            setCoulmnState(true)
            setConsolidated(false)
            setCheckBox(false)
            // let temp = 'Category'
            // trueState.push(temp)
            console.log(obj, 'objjjj');
            let allArray = [];
            if (obj.includes('all')) {
                if (categoryDatas && categoryDatas.length > 0) {
                    let temp = []
                    let temp2 = []
                    for (let i in categoryDatas) {
                        temp.push(`${categoryDatas[i].cat_id}`)
                        allArray.push(categoryDatas[i].cat_id)


                    }
                    setcategoryData(allArray)
                    // setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
                }
                Setcategoryselectcheckbox('none');
                SetcategoryUnselectcheckbox('block');
            } else if (obj.includes('unselect')) {
                setFieldType('Category')
                let temp = [];
                setcategoryData([]);
                // setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
                Setcategoryselectcheckbox('block');
                SetcategoryUnselectcheckbox('none');
            } else {
                setFieldType('Category')
                setGroupCheckBox(true)
                setConsolidated(false)
                setCheckBox(false)
                if (obj.length == categoryDatas.length) {
                    Setcategoryselectcheckbox('none');
                    SetdivisionUnselectcheckbox('block');
                } else {
                    Setcategoryselectcheckbox('block');
                    SetcategoryUnselectcheckbox('none');
                }
                let temp = []
                setcategoryData(obj)



                console.log(obj, 'obj');
                // setCategoryDatas(data)
            }
            // setUserDetails({
            //     ...userDetails,
            //     units: (allArray && allArray.length > 0 ? allArray : obj)
            // })
            return obj;
        }

    };
    const UnitHandleChane = (obj) => {
        if (obj == undefined) {
            setunitData([])
        }
        else {
            setErrorField('')
            setFieldType('Unit')
            setGroupCheckBox(true)
            setGo(false)
            setCoulmnState(true)
            setConsolidated(false)
            setCheckBox(false)
            setFieldTypeClick('Unit')
            // let temp = 'Unit'
            // trueState.push(temp)
            console.log(obj, 'objjjj');
            let allArray = [];
            if (obj.includes('all')) {
                if (unitDatas && unitDatas.length > 0) {
                    let temp = []
                    let temp2 = []
                    for (let i in unitDatas) {
                        temp.push(`${unitDatas[i].u_id}`)
                        allArray.push(unitDatas[i].u_id)


                    }
                    setunitData(allArray)
                    // setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
                }
                Setunitselectcheckbox('none');
                SetunitUnselectcheckbox('block');
            } else if (obj.includes('unselect')) {
                setFieldType('Unit')
                let temp = [];
                setunitData([]);
                // setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
                Setunitselectcheckbox('block');
                SetunitUnselectcheckbox('none');
            } else {
                setFieldType('Unit')
                setGroupCheckBox(true)
                setConsolidated(false)
                setCheckBox(false)
                if (obj.length == categoryDatas.length) {
                    Setunitselectcheckbox('none');
                    SetdivisionUnselectcheckbox('block');
                } else {
                    Setunitselectcheckbox('block');
                    SetunitUnselectcheckbox('none');
                }
                let temp = []
                setunitData(obj)



                console.log(obj, 'obj');
                // setCategoryDatas(data)
            }
            // setUserDetails({
            //     ...userDetails,
            //     units: (allArray && allArray.length > 0 ? allArray : obj)
            // })
            return obj;
        }

    };

    useEffect(() => {
        let temp = []
        if (tableData && tableData.length > 0) {
            tableData.map((item, i) => {
                temp.push(`${i}`)
            }
            )
            setIndexValue(temp)
        }
    }, [tableData])
    console.log(indexValue, 'indexValue');

    // useEffect(() => {
    //     if (country.length > 0) {
    //         let temp
    //         if (commonData && commonData.d_info && commonData.d_info.length > 0) {
    //             for (let i in country) {
    //                 let data = _.filter(commonData && commonData.d_info, { c_id: Number(country[i]) })
    //                 console.log(data, 'dataaaaa');
    //                 temp = data
    //             }
    //             console.log(temp, 'vicky');
    //             if (temp && temp.length > 0) {
    //                 let id = temp && temp.map((item, i) => {
    //                     return item.d_id
    //                 })
    //                 console.log(id, 'ppppp');
    //                 setDomain(id)
    //             }

    //         }
    //     }
    // }, [country])

    console.log(country, 'country');
    console.log(domain, 'domainnn');



    // useEffect(() => {
    //     if (commonData) {
    //         let temp = []
    //         let temp1 = []
    //         let temp2 = []
    //         for (let i in commonData.d_info) {
    //             temp.push(commonData.d_info[i].d_id)
    //         }
    //         for (let j in uniq_country_data) {
    //             temp1.push(`${uniq_country_data[j].c_id}`)
    //             temp2.push(uniq_country_data[j].c_id)
    //         }
    //         setCountry(temp1)
    //         setDomain(temp2)
    //     }
    // }, [commonData, uniq_country_data])


    useEffect(() => {
        if (domain.length > 0) {
            let temparr = []
            let temp2 = []
            let id = []
            if (bgGrpArray && bgGrpArray.length > 0) {
                for (let i in domain) {
                    let catData = _.filter(bgGrpArray, { d_id: Number(domain[i]) })
                    console.log(catData[0], 'Aishuuuuu');
                    // temp2.push(catData)
                    if (catData && catData.length > 0) {
                        for (let i in catData) {
                            temp2.push(catData[i])
                            id.push(`${catData[i].bg_id}`)
                        }
                    }
                }
            }
            else {
                if (domain && domain.length > 0) {
                    for (let i in domain) {
                        let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(domain[i])))
                        console.log(filteredLegalEntity, 'filteredLegalEntity');
                        temparr = filteredLegalEntity
                    }
                }
            }
            if (temp2.length > 0) {
                const unique = _.uniqBy(temp2, function (e) {

                    return e.bg_id;

                });

                console.log(unique, 'unique');

                // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
                setBusiness([...new Set(id)])
                setBusinessGroupdata(unique)
                // }
            }
        }
    }, [domain])
    const DomainHandleChane = (obj) => {
        setErrorField('')
        console.log(field.domain, 'objjfield.domainjj');
        let allArray = [];
        let tempObj = []
        if (obj.includes('all')) {
            if (domainDatas && domainDatas.length > 0) {
                let temp = []
                for (let i in domainDatas) {
                    let splitData = obj[i].split("-")
                    console.log(splitData[1], 'splitData');
                    // tempObj = [...tempObj,...splitData[1]]
                    tempObj.push(Number(splitData[1]))
                    temp.push(domainDatas[i].d_id)


                }
                console.log(temp, 'temp');
                setDomain(tempObj)
                setDomainValue(temp)

            }
            Setdomainselectcheckbox('none');
            Setdomainunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];

            setDomain([])
            Setdomainselectcheckbox('block');
            Setdomainunselectcheckbox('none');
        } else {
            console.log(obj, 'obj');
            let temp = []
            for (let i in obj) {
                let splitData = obj[i].split("-")
                console.log(splitData[1], 'splitData');
                // tempObj = [...tempObj,...splitData[1]]
                tempObj.push(Number(splitData[1]))
                temp.push(obj[i])
            }
            console.log(temp, 'temp');
            setDomain(tempObj)
            setDomainValue(temp)


            let temparr = []
            let temp2 = []
            let id = []
            for (let i in obj) {
                console.log(obj[i], 'obj[i');
                console.log(i, 'i');
                if (bgGrpArray && bgGrpArray.length > 0) {
                    let catData = _.filter(bgGrpArray, { d_id: Number(obj[i]) })
                    console.log(catData[0], 'Aishuuuuu');
                    // temp2.push(catData)
                    if (catData && catData.length > 0) {
                        for (let i in catData) {
                            temp2.push(catData[i])
                            id.push(`${catData[i].bg_id}`)
                        }
                    }
                }
                else {
                    let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(obj[i])))
                    console.log(filteredLegalEntity, 'filteredLegalEntity');
                    temparr = filteredLegalEntity
                }
            }
            console.log(temp2, 'temp2temp2');
            console.log(id, 'id999999');
            if (temp2.length > 0) {
                const unique = _.uniqBy(temp2, function (e) {

                    return e.bg_id;

                });

                console.log(unique, 'unique');


                setBusiness([...new Set(id)])
                setBusinessGroupdata(unique)
            }
            // else {
            //     setEntityDatas(temparr)
            // }



        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    useEffect(() => {

        if ((criticaltityStatus == true) && (criticality != '')) {
            console.log(entityid, 'entttttt');
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request":
                        ["GetComplianceCriticalityStatusChart",
                            {
                                "c_ids": cIds,
                                "d_ids": domain.length > 0 ? domain : domainIds,
                                "from_date": dateState === true ? moment(filterData.from_date).format('DD-MMM-YYYY') : null,
                                "to_date": dateState === true ? moment(filterData.to_date).format('DD-MMM-YYYY') : null,
                                "filter_type": ((fieldType != "") && (fieldType != 'Date')) ? fieldType : filterData.chartType,
                                // filterData && filterData.chartType,
                                "filter_ids":
                                    fieldType == "LegalEntity" ? Entity :
                                        fieldType == 'Division' ? divisionDatas :
                                            fieldType == 'Category' ? categoryData :
                                                fieldType == 'Unit' ? unitData :
                                                    fieldType == 'BusinessGroup' ? businessIds :
                                                        fieldType == 'Date' || consolidated === true || filterData.chartType === "Group" ? null :
                                                            1
                                ,
                                "criticality": criticaltityStatus == true ? criticality : "",
                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                "country_domains": countryDomain,
                                "chart_year": year
                            }
                        ]
                }
            ]

            getCriticalityFilter({
                payload: payload,
                paramid: paramid
            })

        }


    }, [isAuth, entityid, year, localStorage.getItem('SelectedEntity') == "All Legal Entity", criticaltityStatus, criticality])

    console.log(Table, 'TableTable');

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }


    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    autoFocus={'on'}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
        // historynew.push(homepage)
        window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: 6,
            align: 'center'
        },
        ...((fieldType === 'LegalEntity' && go === true) || (fieldType === 'Category' && go === true) || (fieldType === 'Unit' && go === true) ?
            [] :
            [{
                title: "Business Group",
                ...getColumnSearchProps('bg_name', 'Business Group'),
                dataIndex: 'bg_name',
                key: 'bg_name',
                ellipsis: false,
                width: 18,
                filteredValue: filteredInfo.bg_name || null,
            },
            {
                title: 'Legal Entity',
                ...getColumnSearchProps('le_name', 'Legal Entity'),
                dataIndex: false,
                key: 'le_name',
                ellipse: false,
                width: 15,
                filteredValue: filteredInfo.le_name || null,
                render: (record) => {
                    return <p title={record.le_name} className='cut-text'>
                        {record.le_name}</p>
                }
            }
            ]
        ),
        {
            title: 'Division',
            ...getColumnSearchProps('div_name', 'Division'),
            dataIndex: false,
            key: 'div_name',
            width: 12,
            ellipsis: false,
            filteredValue: filteredInfo.div_name || null,
            render: (record) => {
                return <p title={record.div_name} className='cut-text'>
                    {record.div_name}</p>
            }

        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name', 'Compliance'),
            dataIndex: false,
            key: 'comp_name',
            ellipse: false,
            // render: (row, record) => {
            //     console.log(record, 'record');
            //     if (record.compfielaw_url != '') {
            //         const content = (
            //             <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
            //                 <tbody>
            //                     <tr>
            //                         <th>Compliance Description</th>
            //                         <td>{record.descp}</td>
            //                     </tr>
            //                     <tr>
            //                         <th>Document Reference Number</th>
            //                         <td>{record.doc_ref_num}</td>
            //                     </tr>
            //                 </tbody>
            //             </table>
            //         );
            //         return (
            //             <>
            //                 <div>
            //                     <div className="col-md-12">
            //                         <div className="row">
            //                             <div className="col-md-2">
            //                                 <Popover content={content} title="">
            //                                     <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
            //                                 </Popover>
            //                             </div>
            //                             <div className="col-md-10" style={{ marginTop: '2%' }}>
            //                                 <p title={record.div_name} className='cut-text'>
            //                                     <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
            //                                         <a href={`${record.compfielaw_url}`}>
            //                                             <BookTwoTone />
            //                                         </a>
            //                                     </Tooltip>&nbsp;{record.comp_name}</p>
            //                             </div>
            //                         </div>
            //                     </div>

            //                 </div>

            //             </>
            //         )
            //     }
            //     const content = (
            //         <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
            //             <tbody>
            //                 <tr>
            //                     <th>Compliance Description</th>
            //                     <td>{record.descp}</td>
            //                 </tr>
            //                 <tr>
            //                     <th>Document Reference Number</th>
            //                     <td>{record.doc_ref_num}</td>
            //                 </tr>
            //             </tbody>
            //         </table>
            //     );
            //     return (
            //         <>
            //             <div>
            //                 <div className="mb-1">
            //                     <Popover content={content} title="">
            //                         <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
            //                     </Popover>{record.comp_name}
            //                 </div>
            //             </div>

            //         </>
            //     )
            // },
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>



                                <Tooltip title={
                                    <table style={{ borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                        <tr>
                                            <th>Compliance Description</th>
                                            <td>{record.descp}</td>
                                        </tr>
                                        <tr>
                                            <th>Document Reference Number</th>
                                            <td>{record.doc_ref_num}</td>
                                        </tr>



                                    </table>
                                }> <i class="bi-info-circle-fill"></i></Tooltip> <Tooltip title='Compfie Law Knowlegde Portal'>
                                    <a href={`${record.compfielaw_url}`}>
                                        <BookTwoTone />
                                    </a>
                                </Tooltip>&nbsp;{record.comp_name}
                                {/* <div className="col-md-2">
                                            <Popover content={content} title="">
                                                <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                            </Popover>
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.div_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div> */}

                            </div>



                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div className="mb-3">
                                {/* <Popover content={content} title="">
                                    <span style={{ border: 0 }}><i class="bi-info-circle-fill"></i></span>
                                </Popover>&nbsp;{record.comp_name} */}

                                <Tooltip title={
                                    <table style={{ borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                        <tr>
                                            <th>Compliance Description</th>
                                            <td>{record.descp}</td>
                                        </tr>
                                        <tr>
                                            <th>Document Reference Number</th>
                                            <td>{record.doc_ref_num}</td>
                                        </tr>



                                    </table>
                                }> <i class="bi-info-circle-fill"></i>&nbsp;</Tooltip>{record.comp_name}

                            </div>


                        </div>

                    </>
                )
            },
            width: 20,
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality', 'Criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: 15,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name', 'Assigned To'),
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: 15,
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: (compliance === "Complied" ? "Assigned to" : (compliance === "Delayed Compliance" ? "Delayed By" : (compliance === "Inprogress" ? "Days Left to Complete" : "Over Due"))),
            ...getColumnSearchProps('ageing', 'Over Due'),
            dataIndex: 'ageing',
            key: 'ageing',
            width: 12,

            ellipsis: false,
            render: (row, record) => {
                return (
                    <>
                        <p>{record.ageing}</p>
                    </>
                )
            },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ];

    const columns1 = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: 7,
            align: 'center'
        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name', 'Compliance'),
            dataIndex: false,
            key: 'comp_name',
            ellipse: true,
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>



                                <Tooltip title={
                                    <table style={{ borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                        <tr>
                                            <th>Compliance Description</th>
                                            <td>{record.descp}</td>
                                        </tr>
                                        <tr>
                                            <th>Document Reference Number</th>
                                            <td>{record.doc_ref_num}</td>
                                        </tr>



                                    </table>
                                }> <i class="bi-info-circle-fill"></i></Tooltip> <Tooltip title='Compfie Law Knowlegde Portal'>
                                    <a href={`${record.compfielaw_url}`}>
                                        <BookTwoTone />
                                    </a>
                                </Tooltip>&nbsp;{record.comp_name}
                                {/* <div className="col-md-2">
                                            <Popover content={content} title="">
                                                <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                            </Popover>
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.div_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div> */}

                            </div>



                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div className="mb-3">
                                {/* <Popover content={content} title="">
                                    <span style={{ border: 0 }}><i class="bi-info-circle-fill"></i></span>
                                </Popover>&nbsp;{record.comp_name} */}

                                <Tooltip title={
                                    <table style={{ borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                        <tr>
                                            <th>Compliance Description</th>
                                            <td>{record.descp}</td>
                                        </tr>
                                        <tr>
                                            <th>Document Reference Number</th>
                                            <td>{record.doc_ref_num}</td>
                                        </tr>



                                    </table>
                                }> <i class="bi-info-circle-fill"></i>&nbsp;</Tooltip>{record.comp_name}

                            </div>


                        </div>

                    </>
                )
            },
            width: 25,
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality', 'Criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name', 'Assigned To'),
            dataIndex: false,
            key: 'assignee_name',
            ellipsis: false,
            render: (record) => {
                return <p title={record.assignee_name} className='cut-text'>
                    {record.assignee_name}</p>
            },
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: 13,
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: (compliance === "Complied" ? "Assigned to" : (compliance === "Delayed Compliance" ? "Delayed By" : (compliance === "Inprogress" ? "Days Left to Complete" : "Over Due"))),
            ...getColumnSearchProps('ageing', "Over Due"),
            dataIndex: 'ageing',
            key: 'ageing',
            ellipsis: false,
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ]







    console.log(dataValue, 'chartTypeee');

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Create a container

        var chart = am4core.create("containerNoData", am4charts.XYChart3D);

        var title3 = chart.createChild(am4core.Label)
        title3.text = "No Data to Display"
        title3.disabled = false;
        title3.align = 'left'
        // title3.marginTop = '25%'
        title3.marginLeft = '43%'
        title3.marginBottom = '50%'
        // title3.fontWeight = '500'
        title3.fontSize = '16'

        if (dateState === true) {

            var title5 = chart.createChild(am4core.Label)
            title5.text = `No Data to Display`
            title5.disabled = false;
            title5.align = 'center'
            // title3.marginTop = '95%'
            title5.marginBottom = '-30%'
            title5.marginLeft = '35%'
            // title3.fontWeight = '500'
            title5.fontSize = '16'

            var title4 = chart.createChild(am4core.Label)
            title4.text = `Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
            title4.disabled = false;
            title4.align = 'left'
            // title3.marginTop = '25%'
            title4.marginTop = '80%'
            title4.marginLeft = '2%'
            // title3.fontWeight = '500'
            title4.fontSize = '16'



        }

    }, [dataValue, dateState])


    useEffect(() => {
        if (chartName == 'stacked') {
            if (multiSelect == false) {
                if ((table == false) && criticaltityStatus == true) {
                    console.log('ppppppppppp');
                    if (dataValue && dataValue.length > 0) {
                        if (chartType == 'count') {
                            am4core.useTheme(am4themes_animated);
                            // Create a container
                            // var container = am4core.create("container", am4core.Container);
                            // container.width = am4core.percent(100);
                            // container.height = am4core.percent(100);
                            // container.layout = "vertical";
                            // container.background.fill = am4core.color("#000");
                            // container.background.fillOpacity = 0.1;
                            // container.background.stroke = am4core.color("#000");
                            // container.background.strokeOpacity = 0.2;
                            // container.background.strokeWidth = 2;




                            var chart = am4core.create("container", am4charts.XYChart3D);
                            // Add data
                            chart.data = chartData;
                            chart.colors.list = [
                                am4core.color("#0de580"),
                                am4core.color("#3c78d8"),
                                am4core.color("#f9c80e"),
                                am4core.color("#fe4365"),


                            ];
                            chart.legend = new am4charts.Legend();
                            chart.legend.position = "buttom";
                            chart.legend.useDefaultMarker = true;
                            chart.height = 600

                            var marker = chart.legend.markers.template.children.getIndex(0);
                            marker.cornerRadius(12, 12, 12, 12);
                            marker.strokeWidth = 1;
                            // marker.strokeOpacity = 0.5;
                            chart.scrollbarX = new am4core.Scrollbar();
                            // chart.scrollbarY = new am4core.Scrollbar();

                            chart.scrollbarY = new am4core.Scrollbar();
                            chart.scrollbarY.startGrip.disabled = true;
                            chart.scrollbarY.endGrip.disabled = true;

                            chart.scrollbarY.parent = chart.rightAxesContainer;
                            var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                            categoryAxis.dataFields.category = "type";
                            categoryAxis.title.text =
                                ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                                    (fieldType == "Division" && go == true) ? `Division` : (
                                        (fieldType == "Category" && go == true) ? `Category` : (
                                            (fieldType == "Unit" && go == true) ? `Unit` : `Countries`
                                        ))
                                ))
                            categoryAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                            categoryAxis.renderer.labels.template.events.on("hit", function (event) {
                                if (chartData.length > 0) {
                                    setFilterId(event.target.dataItem._dataContext.typeId)
                                    setLabelCountryName(event.target.dataItem._dataContext.type)
                                    setmultiSelectChart(event.target.dataItem._dataContext)
                                    setmultiSelect(true)
                                }
                                console.log(event.target.dataItem._dataContext, 'event5555');

                            })
                            var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                            valueAxis.calculateTotals = true;
                            valueAxis.title.text = "Total Compliance";
                            valueAxis.renderer.cellStartLocation = 0.2;
                            valueAxis.renderer.cellEndLocation = 0.8;
                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.filePrefix = "Exported Data";
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            let countryHeading = ((fieldType == "LegalEntity" && go == true) ? `Legal Entities` : (
                                (fieldType == "Division" && go == true) ? `Divisions` : (
                                    (fieldType == "Category" && go == true) ? "Categories" : (
                                        (fieldType == "BusinessGroup" && go == true) ? "Business Groups" : (
                                            (fieldType == "Unit" && go == true) ? "Units" : "Countries"
                                        )))
                            ))
                            chart.exporting.dataFields = {
                                "type": `${countryHeading}`,
                                "complied_count": "Complied",
                                "delayed_compliance_count": "Delayed Compliance",
                                "inprogress_compliance_count": "Inprogress",
                                "not_complied_count": "Not Complied",
                            }
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            chart.exporting.menu.align = "right";
                            chart.exporting.menu.verticalAlign = "top";
                            function createSeries(field, name) {
                                var series = chart.series.push(new am4charts.ColumnSeries3D());
                                series.dataFields.valueX = field;
                                series.dataFields.categoryY = "type";
                                // series.dataFields.valueXShow = "total";
                                series.stacked = true;
                                series.name = name;
                                // series.clustered = false;
                                var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                                labelBullet.label.text = "{valueX}";
                                labelBullet.locationX = 0.5;

                                series.columns.template.maxHeight = chartData.length > 1 ? 20 : 50
                                series.columns.template.events.on("hit", function (ev) {
                                    console.log(ev.target.dataItem, 'ev.target.dataItem');
                                    setCategoryName(ev.target.dataItem.categories.categoryY)
                                    // setCount(1)
                                    setFilterId(ev.target.dataItem._dataContext.typeId)

                                    setCompliance(ev.target.dataItem.component.name);
                                    setShowMoreCount(0)
                                    setDataCount(ev.target.dataItem.component.name == "Inprogress" ? ev.target.dataItem._dataContext.inprogress_compliance_count
                                        : ev.target.dataItem.component.name == "Complied" ? ev.target.dataItem._dataContext.complied_count
                                            : ev.target.dataItem.component.name == "Not Complied" ? ev.target.dataItem._dataContext.not_complied_count
                                                : ev.target.dataItem.component.name == "Delayed Compliance" ? ev.target.dataItem._dataContext.delayed_compliance_count : 0)
                                    console.log(ev.target.dataItem, ' ev.target.dataItem.component.name');
                                    console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                                    const payload = [

                                        authtoken,

                                        {

                                            "session_token": authtoken,

                                            "request": [
                                                "GetComplianceCriticalityStatusDrillDownData",
                                                {
                                                    "d_ids": [
                                                        commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                    ],
                                                    "from_date": null,
                                                    "to_date": null,
                                                    "filter_type": ((fieldType == "LegalEntity" && go == true) ? `LegalEntity` : (
                                                        (fieldType == "Division" && go == true) ? `Division` : (
                                                            (fieldType == "Category" && go == true) ? "Category" : (
                                                                (fieldType == "BusinessGroup" && go == true) ? "BusinessGroup" : (
                                                                    (fieldType == "Unit" && go == true) ? "Unit" : "Group"
                                                                )))
                                                    )),
                                                    "filter_id": ev.target.dataItem._dataContext.typeId,
                                                    "compliance_status": ev.target.dataItem.component.name,
                                                    "chart_year": year,
                                                    "record_count": 0,
                                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                    "country_domains": countryDomain,
                                                    "criticality": criticality
                                                }
                                            ]
                                        }
                                    ]
                                    getCompliancestatusTable({
                                        payload: payload,
                                        paramid: paramid
                                    })
                                    setTable(true)
                                })
                                series.columns.template.height = am4core.percent(70);
                                // series.columns.template.column3D.stroke = am4core.color("#fff");
                                // series.columns.template.column3D.strokeOpacity = 0.2;
                                series.calculatePercent = true;
                                series.calculateTotals = true;
                                series.columns.template.tooltipText = "{name}: {valueX.totalPercent}[/]% : ({valueX} out of {valueX.total}[/])";
                            }

                            createSeries("complied_count", "Complied");
                            createSeries("delayed_compliance_count", "Delayed Compliance");
                            createSeries("inprogress_compliance_count", "Inprogress");
                            createSeries("not_complied_count", "Not Complied");
                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.filePrefix = "Exported Data";
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                            (fieldType == "Unit" && go == true) ? `${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} Wise Compliance(2022)` : `Country Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            // title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `${textArray}`
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                            var label7 = chart.createChild(am4core.Label);
                            label7.text = `${textValue}`
                            label7.fontSize = 14;
                            label7.align = "left";
                            label7.marginLeft = '1%';
                            label7.marginRight = '2%';
                            label7.marginTop = '2%';
                            label7.fontWeight = 'bold'
                            label7.height = '5%'
                            label7.maxWidth = 700;
                            label7.wrap = true;

                            if (dateState === true && go === true) {
                                var label2 = chart.createChild(am4core.Label)
                                label2.text = `Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                                label2.align = "left";
                                label2.marginLeft = '1%';
                                label2.marginRight = '2%';
                                label2.marginTop = '2%';
                                label2.marginBottom = '5%'
                            }


                            var label3 = chart.createChild(am4core.Label);
                            label3.text = criticaltityStatus === true ? `[bold]Criticality[/]-${criticality}` : ''
                            label3.fontSize = 14;
                            label3.align = "left";
                            label3.marginLeft = '2%';
                            label3.marginBottom = '5%'






                        }
                    }
                }
            }
        }
    }, [dataValue, table, multiSelect, chartType, criticaltityStatus, chartData, timeZone, timeZoneData, localStorage.getItem('chart')])

    useEffect(() => {
        if ((table == false) && (criticaltityStatus == false)) {
            if (multiSelect == false) {
                if (chartName == 'stacked') {
                    if (chartType == 'count') {
                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        // var container = am4core.create("container", am4core.Container);
                        // container.width = am4core.percent(100);
                        // container.height = am4core.percent(100);
                        // container.layout = "vertical";
                        // container.background.fill = am4core.color("#000");
                        // container.background.fillOpacity = 0.1;
                        // container.background.stroke = am4core.color("#000");
                        // container.background.strokeOpacity = 0.2;
                        // container.background.strokeWidth = 2;

                        // function addChart(data) {
                        //     console.log(data, 'aishuuuuuuuu');
                        var chart = am4core.create("container", am4charts.XYChart3D);
                        // Add data
                        chart.data = chartData;
                        chart.colors.list = [
                            am4core.color("#0de580"),
                            am4core.color("#3c78d8"),
                            am4core.color("#fe4365"),
                            am4core.color("#f9c80e"),

                        ];
                        chart.legend = new am4charts.Legend();
                        chart.legend.position = "buttom";
                        chart.legend.useDefaultMarker = true;
                        chart.height = 600
                        var marker = chart.legend.markers.template.children.getIndex(0);
                        marker.cornerRadius(12, 12, 12, 12);
                        marker.strokeWidth = 1;
                        chart.scrollbarX = new am4core.Scrollbar();
                        // chart.scrollbarY = new am4core.Scrollbar();
                        chart.scrollbarY = new am4core.Scrollbar();
                        chart.scrollbarY.startGrip.disabled = true;
                        chart.scrollbarY.endGrip.disabled = true;
                        // chart.scrollbarY.parent = chart.rightAxesContainer;


                        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());

                        categoryAxis.dataFields.category = "type";
                        categoryAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                        categoryAxis.renderer.labels.template.events.on("hit", function (event) {
                            if (chartData.length > 0) {
                                setFilterId(event.target.dataItem._dataContext.typeId)
                                setLabelCountryName(event.target.dataItem._dataContext.type)
                                setmultiSelectChart(event.target.dataItem._dataContext)
                                setmultiSelect(true)
                            }
                            console.log(event.target.dataItem._dataContext, 'event5555');

                        })
                        categoryAxis.title.text =
                            ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                                (fieldType == "Division" && go == true) ? `Division` : (
                                    (fieldType == "Category" && go == true) ? `Category` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Groups` : (
                                            (fieldType == "Unit" && go == true) ? `Unit` : `Countries`
                                        )))
                            ))

                        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                        valueAxis.renderer.cellStartLocation = 0.2;
                        valueAxis.renderer.cellEndLocation = 0.8;
                        valueAxis.calculateTotals = true;
                        valueAxis.title.text = "Total Compliance";
                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.filePrefix = "Exported Data";
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        let countryHeading = ((fieldType == "LegalEntity" && go == true) ? `Legal Entities` : (
                            (fieldType == "Division" && go == true) ? `Divisions` : (
                                (fieldType == "Category" && go == true) ? "Categories" : (
                                    (fieldType == "BusinessGroup" && go == true) ? "Business Groups" : (
                                        (fieldType == "Unit" && go == true) ? "Units" : "Countries"
                                    )))
                        ))
                        chart.exporting.dataFields = {
                            "type": `${countryHeading}`,
                            "complied_count": "Complied",
                            "delayed_compliance_count": "Delayed Compliance",
                            "inprogress_compliance_count": "Inprogress",
                            "not_complied_count": "Not Complied",
                        }
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        chart.exporting.menu.align = "right";
                        chart.exporting.menu.verticalAlign = "top";
                        function createSeries(field, name, id) {
                            console.log(field, 'field');
                            console.log(name, 'name');
                            console.log(id, 'idddd');
                            var series = chart.series.push(new am4charts.ColumnSeries3D());
                            series.dataFields.valueX = field;
                            series.dataFields.categoryY = "type";
                            series.dataFields.customValue = id
                            // series.columns.template.maxWidth = 10

                            series.columns.template.maxHeight = chartData.length > 1 ? 20 : 50
                            console.log(series.dataFields.customValue, ' series.dataFields.customValue');
                            // series.dataFields.valueXShow = "total";
                            series.stacked = true;
                            series.name = name;
                            // series.clustered = false;
                            var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                            labelBullet.label.text = "{valueX}";
                            labelBullet.locationX = 0.5;


                            series.columns.template.events.on("hit", function (ev) {
                                console.log(ev.target.dataItem, 'ev.target.dataItem');
                                setCategoryName(ev.target.dataItem.categories.categoryY)
                                setFilterId(ev.target.dataItem._dataContext.typeId)
                                // setCount(1)
                                setCompliance(ev.target.dataItem.component.name);
                                setShowMoreCount(0)
                                setDataCount(ev.target.dataItem.component.name == "Inprogress" ? ev.target.dataItem._dataContext.inprogress_compliance_count
                                    : ev.target.dataItem.component.name == "Complied" ? ev.target.dataItem._dataContext.complied_count
                                        : ev.target.dataItem.component.name == "Not Complied" ? ev.target.dataItem._dataContext.not_complied_count
                                            : ev.target.dataItem.component.name == "Delayed Compliance" ? ev.target.dataItem._dataContext.delayed_compliance_count : 0)
                                console.log(ev.target.dataItem, ' ev.target.dataItem.component.name');
                                console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                                const payload = [

                                    authtoken,

                                    {

                                        "session_token": authtoken,

                                        "request": [
                                            "GetComplianceStatusDrillDownData",
                                            {
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "from_date": null,
                                                "to_date": null,
                                                "filter_type": ((fieldType == "LegalEntity" && go == true) ? `LegalEntity` : (
                                                    (fieldType == "Division" && go == true) ? `Division` : (
                                                        (fieldType == "Category" && go == true) ? "Category" : (
                                                            (fieldType == "BusinessGroup" && go == true) ? "BusinessGroup" : (
                                                                (fieldType == "Unit" && go == true) ? "Unit" : "Group"
                                                            )))
                                                )),
                                                "filter_id": ev.target.dataItem._dataContext.typeId,
                                                "compliance_status": ev.target.dataItem.component.name,
                                                "chart_year": year,
                                                "record_count": 0,
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                "country_domains": countryDomain,
                                                "criticality": ""
                                            }
                                        ]
                                    }
                                ]
                                getCompliancestatusTable({
                                    payload: payload,
                                    paramid: paramid
                                })
                                setTable(true)
                            })
                            // series.columns.template.height = am4core.percent(70);
                            // series.columns.template.column3D.stroke = am4core.color("#fff");
                            // series.columns.template.column3D.strokeOpacity = 0.2;
                            series.calculatePercent = true;
                            series.calculateTotals = true;
                            series.columns.template.tooltipText = "{name}: {valueX.totalPercent.formatNumber('#.00')}[/]% : ({valueX} out of {valueX.total}[/])";


                        }

                        createSeries("complied_count", "Complied", "typeId");
                        createSeries("delayed_compliance_count", "Delayed Compliance", "typeId");
                        createSeries("not_complied_count", "Not Complied", "typeId");
                        createSeries("inprogress_compliance_count", "Inprogress", "typeId");

                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.filePrefix = "Exported Data";
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        var title = chart.titles.create();
                        title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'

                        var title1 = chart.titles.create();
                        title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                            (fieldType == "Division" && go == true) ? `Division Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                (fieldType == "Category" && go == true) ? `Category Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                    (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                        (fieldType == "Unit" && go == true) ? `${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} Wise Compliance(2022)` : `Country Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})`
                                    )))
                        ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '1%';
                        label.marginBottom = '1%'
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `${textArray}`
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '1%';
                        // label1.marginBottom = '5%'

                        var label7 = chart.createChild(am4core.Label);
                        label7.text = `${textValue}`
                        label7.fontSize = 14;
                        label7.align = "left";
                        label7.marginLeft = '1%';
                        label7.marginRight = '2%';
                        label7.marginTop = '2%';
                        label7.fontWeight = 'bold'
                        label7.height = '5%'
                        label7.maxWidth = 700;
                        label7.wrap = true;

                        if (dateState === true && go === true) {
                            var label2 = chart.createChild(am4core.Label)
                            label2.text = `Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                            label2.align = "left";
                            label2.marginLeft = '1%';
                            label2.marginRight = '2%';
                            label2.marginTop = '2%';
                            label2.marginBottom = '5%'
                        }


                        // var label5 = chart.createChild(am4core.Label);
                        // label5.text =tempState && tempState.bg && tempState.bg.length > 0 ? '|Business Group : Selected 4 out of 4' : '' | `${tempState && tempState.le && tempState.le.length > 0}` ? '|Legal Entity Group : Selected 4 out of 4' : ''
                        // var label2 = chart.createChild(am4core.Label);
                        // label2.text = ((Entity.length > 0) ? `| Legal Entity: Selected ${Entity && Entity.length} out of${entityData && entityData.length}` : (
                        //     (divisionDatas && divisionDatas.length > 0) ? `| Division: Selected ${divisionDatas && divisionDatas.length} out of ${division && division.length}` : (
                        //         (categoryData && categoryData.length > 0) ? `| Category: Selected ${categoryData && categoryData.length}` : (
                        //             (unitData && unitData.length > 0) ? `| Unit Selected:Selected ${unitData && unitData.length} out of ${unit && unit.length}` : (
                        //                 (filterData.from_date && filterData.to_date ? `| Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                        //                     : null
                        //                 ))))
                        // ))
                        // label2.fontSize = 14;
                        // label2.align = "left";
                        // label2.marginLeft = '2%';
                        // label2.marginBottom = '5%'
                        // }
                        // addChart(chartData);
                    }
                    else if (chartType == 'Percentage') {
                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        // var container = am4core.create("container", am4core.Container);
                        // container.width = am4core.percent(100);
                        // container.height = am4core.percent(100);
                        // container.layout = "vertical";
                        // container.background.fill = am4core.color("#000");
                        // container.background.fillOpacity = 0.1;
                        // container.background.stroke = am4core.color("#000");
                        // container.background.strokeOpacity = 0.2;
                        // container.background.strokeWidth = 2;

                        // function addChart(data) {
                        //     var chart = container.createChild(am4charts.XYChart3D);
                        var chart = am4core.create("container", am4charts.XYChart3D);

                        // Add data
                        chart.data = chartData;
                        chart.colors.list = [
                            am4core.color("#0de580"),
                            am4core.color("#3c78d8"),
                            am4core.color("#f9c80e"),
                            am4core.color("#fe4365"),
                        ];
                        chart.legend = new am4charts.Legend();
                        chart.legend.position = "buttom";
                        chart.legend.useDefaultMarker = true;
                        chart.height = 600
                        var marker = chart.legend.markers.template.children.getIndex(0);
                        marker.cornerRadius(12, 12, 12, 12);
                        marker.strokeWidth = 1;
                        chart.scrollbarX = new am4core.Scrollbar();
                        chart.scrollbarY = new am4core.Scrollbar();
                        chart.scrollbarY.parent = chart.rightAxesContainer;
                        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.dataFields.category = "type";
                        categoryAxis.title.text =
                            ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                                (fieldType == "Division" && go == true) ? `Division` : (
                                    (fieldType == "Category" && go == true) ? `Category` : (
                                        (fieldType == "Unit" && go == true) ? `Unit` : `Countries`
                                    ))
                            ))
                        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                        valueAxis.calculateTotals = true;
                        valueAxis.renderer.cellStartLocation = 0.2;
                        valueAxis.renderer.cellEndLocation = 0.8;
                        valueAxis.min = 0;
                        valueAxis.max = 100;
                        valueAxis.title.text = "Total Compliance";
                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.filePrefix = "Exported Data";
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        let countryHeading = ((fieldType == "LegalEntity" && go == true) ? `Legal Entities` : (
                            (fieldType == "Division" && go == true) ? `Divisions` : (
                                (fieldType == "Category" && go == true) ? "Categories" : (
                                    (fieldType == "BusinessGroup" && go == true) ? "Business Groups" : (
                                        (fieldType == "Unit" && go == true) ? "Units" : "Countries"
                                    )))
                        ))
                        chart.exporting.dataFields = {
                            "type": `${countryHeading}`,
                            "complied_count": "Complied",
                            "delayed_compliance_count": "Delayed Compliance",
                            "inprogress_compliance_count": "Inprogress",
                            "not_complied_count": "Not Complied",
                        }
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        chart.exporting.menu.align = "right";
                        chart.exporting.menu.verticalAlign = "top";
                        function createSeries(field, name) {
                            var series = chart.series.push(new am4charts.ColumnSeries3D());
                            series.dataFields.valueX = field;
                            series.dataFields.categoryY = "type";
                            series.dataFields.valueXShow = "totalPercent";
                            series.columns.template.maxHeight = chartData.length > 1 ? 20 : 50
                            series.stacked = true;
                            series.name = name;
                            // series.clustered = false;
                            var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                            labelBullet.label.text = "{valueX.totalPercent.formatNumber('#.00')}%";
                            labelBullet.locationX = 0.5;
                            series.columns.template.events.on("hit", function (ev) {
                                // setCount(1)
                                setShowMoreCount(0)
                                setFilterId(ev.target.dataItem._dataContext.typeId)
                                setDataCount(ev.target.dataItem.component.name == "Inprogress" ? ev.target.dataItem._dataContext.inprogress_compliance_count
                                    : ev.target.dataItem.component.name == "Complied" ? ev.target.dataItem._dataContext.complied_count
                                        : ev.target.dataItem.component.name == "Not Complied" ? ev.target.dataItem._dataContext.not_complied_count
                                            : ev.target.dataItem.component.name == "Delayed Compliance" ? ev.target.dataItem._dataContext.delayed_compliance_count : 0)
                                console.log(ev.target.dataItem, ' ev.target.dataItem.component.name');
                                setCompliance(ev.target.dataItem.component.name);
                                console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                                const payload = [

                                    authtoken,

                                    {

                                        "session_token": authtoken,

                                        "request": [
                                            "GetComplianceStatusDrillDownData",
                                            {
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "from_date": null,
                                                "to_date": null,
                                                "filter_type": ((fieldType == "LegalEntity" && go == true) ? `LegalEntity` : (
                                                    (fieldType == "Division" && go == true) ? `Division` : (
                                                        (fieldType == "Category" && go == true) ? "Category" : (
                                                            (fieldType == "Unit" && go == true) ? "Unit" : "Group"
                                                        ))
                                                )),
                                                "filter_id": ev.target.dataItem._dataContext.typeId,
                                                "compliance_status": ev.target.dataItem.component.name,
                                                "chart_year": year,
                                                "record_count": 0,
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                "country_domains": countryDomain,
                                                "criticality": ""
                                            }
                                        ]

                                    }

                                ]



                                getCompliancestatusTable({

                                    payload: payload,

                                    paramid: paramid

                                })
                                setTable(true)
                            })

                            series.columns.template.height = am4core.percent(70);

                            // series.columns.template.column3D.stroke = am4core.color("#fff");
                            // series.columns.template.column3D.strokeOpacity = 0.2;
                            series.calculatePercent = true;
                            series.calculateTotals = true;
                            series.columns.template.tooltipText = "{name}: {valueX.totalPercent}[/]% : ({valueX} out of {valueX.total}[/])";
                        }
                        createSeries("complied_count", "Complied");
                        createSeries("delayed_compliance_count", "Delayed Compliance");
                        createSeries("not_complied_count", "Not Complied");
                        createSeries("inprogress_compliance_count", "Inprogress");

                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.filePrefix = "Exported Data";
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        var title = chart.titles.create();
                        title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'

                        var title1 = chart.titles.create();
                        title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                            (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                    (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                        (fieldType == "Unit" && go == true) ? `${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                    )))
                        ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `${textArray}`
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '5%'

                        var label7 = chart.createChild(am4core.Label);
                        label7.text = `${textValue}`
                        label7.fontSize = 14;
                        label7.align = "left";
                        label7.marginLeft = '1%';
                        label7.marginRight = '2%';
                        label7.marginTop = '2%';
                        label7.fontWeight = 'bold'
                        label7.height = '5%'
                        label7.maxWidth = 700;
                        label7.wrap = true;

                        if (dateState === true && go === true) {
                            var label2 = chart.createChild(am4core.Label)
                            label2.text = `Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                            label2.align = "left";
                            label2.marginLeft = '1%';
                            label2.marginRight = '2%';
                            label2.marginTop = '2%';
                            label2.marginBottom = '5%'
                        }
                        // }
                        // addChart(chartData);
                    }
                }
            }
        }

    }, [dataValue, timeZoneData, multiSelect, timeZone, table, chartType, multiSelect, criticaltityStatus, chartName, chartData, textValue, localStorage.getItem('chart')])

    useEffect(() => {
        if ((table == false) && (criticaltityStatus == false)) {
            console.log('Avvvvvvvvs');
            let total = ((dataValue && dataValue.length && dataValue[0].complied_count) + (dataValue && dataValue.length && dataValue[0].delayed_compliance_count) + (dataValue && dataValue.length && dataValue[0].inprogress_compliance_count) + (dataValue && dataValue.length && dataValue[0].not_complied_count))
            let totalValue = 100 / ((dataValue && dataValue.length && dataValue[0].complied_count) + (dataValue && dataValue.length && dataValue[0].delayed_compliance_count) + (dataValue && dataValue.length && dataValue[0].inprogress_compliance_count) + (dataValue && dataValue.length && dataValue[0].not_complied_count))
            if (multiSelect == false) {
                if (dataValue && dataValue.length > 0) {
                    if (chartName == 'chord') {
                        console.log('testinggggg');
                        if (chartType == 'count') {
                            am4core.useTheme(am4themes_animated);
                            // Create a container
                            // var container = am4core.create("container", am4core.Container);
                            // container.width = am4core.percent(100);
                            // container.height = am4core.percent(100);
                            // container.layout = "vertical";
                            // container.background.fill = am4core.color("#000");
                            // container.background.fillOpacity = 0.1;
                            // container.background.stroke = am4core.color("#000");
                            // container.background.strokeOpacity = 0.2;
                            // container.background.strokeWidth = 2;

                            // function addChart(data) {

                            //     // Create chart instance
                            //     var chart = container.createChild(am4charts.ChordDiagram);
                            var chart = am4core.create("container", am4charts.ChordDiagram);
                            var colors = {
                                India: chart.colors.next(),
                                Complied: "#0de580",
                                DelayedCompliance: "#3c78d8",
                                Inprogress: "#fe4365",
                                NotComplied: "#f9c80e",

                            }

                            // Add data
                            chart.data = chordChartData;
                            chart.dataFields.fromName = "from";
                            chart.dataFields.toName = "to";
                            chart.dataFields.value = "value";
                            chart.nodePadding = 0.9;
                            chart.minNodeSize = 0.01;
                            chart.startAngle = 80;
                            chart.endAngle = chart.startAngle + 360;
                            chart.sortBy = "value";
                            chart.fontSize = 15;

                            var nodeTemplate = chart.nodes.template;
                            //  nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
                            nodeTemplate.showSystemTooltip = true;
                            nodeTemplate.propertyFields.fill = "color";
                            nodeTemplate.tooltipText = "{name}";

                            // when rolled over the node, make all the links rolled-over
                            nodeTemplate.events.on("over", function (event) {
                                var node = event.target;
                                node.outgoingDataItems.each(function (dataItem) {
                                    if (dataItem.toNode) {
                                        dataItem.link.isHover = true;
                                        dataItem.toNode.label.isHover = true;
                                    }
                                })
                                node.incomingDataItems.each(function (dataItem) {
                                    if (dataItem.fromNode) {
                                        dataItem.link.isHover = true;
                                        dataItem.fromNode.label.isHover = true;
                                    }
                                })

                                node.label.isHover = true;
                            })

                            // when rolled out from the node, make all the links rolled-out
                            nodeTemplate.events.on("out", function (event) {
                                var node = event.target;
                                node.outgoingDataItems.each(function (dataItem) {
                                    if (dataItem.toNode) {
                                        dataItem.link.isHover = false;
                                        dataItem.toNode.label.isHover = false;
                                    }
                                })
                                node.incomingDataItems.each(function (dataItem) {
                                    if (dataItem.fromNode) {
                                        dataItem.link.isHover = false;
                                        dataItem.fromNode.label.isHover = false;
                                    }
                                })

                                node.label.isHover = false;
                            })

                            var label = nodeTemplate.label;
                            label.relativeRotation = 90;

                            label.fillOpacity = 0.4;
                            let labelHS = label.states.create("hover");
                            labelHS.properties.fillOpacity = 1;
                            nodeTemplate.events.on("hit", function (event) {
                                console.log(event, 'eventsss');
                                setLabelCountryName(event.target.currentText)
                                if (event.target.currentText === 'Complied' || event.target.currentText === 'Delayed Compliance' || event.target.currentText === 'Inprogress' || event.target.currentText === 'Not Complied') {
                                    return null
                                }
                                else {
                                    console.log('trueee');
                                    let temp = []
                                    let temp1 = []
                                    temp.push({
                                        "inprogress_compliance_count": chordChartData[3].value,
                                        "not_complied_count": chordChartData[4].value,
                                        "complied_count": chordChartData[1].value,
                                        "delayed_compliance_count": chordChartData[2].value,
                                        "type": chordChartData[0].from,
                                        "typeId": chordChartData[0].value,

                                    })
                                    if (temp && temp.length > 0) {
                                        for (let i in temp) {
                                            const removeZero = item => (
                                                Object.keys(item)
                                                    .filter(key => item[key] !== 0)
                                                    .reduce((newObj, key) => {
                                                        newObj[key] = item[key]; return newObj;
                                                    }, {})); const result = removeZero(temp[i]);
                                            console.log(result, 'result');
                                            temp1.push(result)
                                        }
                                    }
                                    setmultiSelectChart(temp1[0])
                                    setmultiSelect(true)
                                    console.log(temp1[0], 'temp1[0]temp1[0]');
                                }
                            })
                            nodeTemplate.events.on("ready", function (event) {
                                console.log(event, 'snehaAishu');
                            })
                            nodeTemplate.label.events.on("hit", function (event) {
                                console.log(event.target.currentText, 'aishuSneha');
                                console.log(chordChartData, 'chordChartData');
                                setLabelCountryName(event.target.currentText)
                                if (event.target.currentText === 'Complied' || event.target.currentText === 'Delayed Compliance' || event.target.currentText === 'Inprogress' || event.target.currentText === 'Not Complied') {
                                    return null
                                }
                                else {
                                    console.log('trueee');
                                    let temp = []
                                    let temp1 = []
                                    temp.push({
                                        "inprogress_compliance_count": chordChartData[3].value,
                                        "not_complied_count": chordChartData[4].value,
                                        "complied_count": chordChartData[1].value,
                                        "delayed_compliance_count": chordChartData[2].value,
                                        "type": chordChartData[0].from,
                                        "typeId": chordChartData[0].value,

                                    })
                                    if (temp && temp.length > 0) {
                                        for (let i in temp) {
                                            const removeZero = item => (
                                                Object.keys(item)
                                                    .filter(key => item[key] !== 0)
                                                    .reduce((newObj, key) => {
                                                        newObj[key] = item[key]; return newObj;
                                                    }, {})); const result = removeZero(temp[i]);
                                            console.log(result, 'result');
                                            temp1.push(result)
                                        }
                                    }
                                    setmultiSelectChart(temp1[0])
                                    setFilterId(temp1[0].typeId)
                                    setmultiSelect(true)
                                    console.log(temp1[0], 'temp1[0]temp1[0]');
                                }
                            })
                            nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                            // this adapter makes non-main character nodes to be filled with color of the main character which he/she kissed most
                            nodeTemplate.adapter.add("fill", function (fill, target) {
                                let node = target;
                                let counters = {};
                                let mainChar = false;
                                node.incomingDataItems.each(function (dataItem) {
                                    if (colors[dataItem.toName]) {
                                        mainChar = true;
                                    }

                                    if (isNaN(counters[dataItem.fromName])) {
                                        counters[dataItem.fromName] = dataItem.value;
                                    }
                                    else {
                                        counters[dataItem.fromName] += dataItem.value;
                                    }
                                })
                                if (mainChar) {
                                    return fill;
                                }

                                let count = 0;
                                let color;
                                let biggest = 0;
                                let biggestName;

                                for (var name in counters) {
                                    if (counters[name] > biggest) {
                                        biggestName = name;
                                        biggest = counters[name];
                                    }
                                }
                                if (colors[biggestName]) {
                                    fill = colors[biggestName];
                                }

                                return fill;
                            })

                            // link template
                            var linkTemplate = chart.links.template;
                            linkTemplate.strokeOpacity = 0;
                            linkTemplate.fillOpacity = 0.15;
                            linkTemplate.tooltipText = `{fromName}:{value} out of ${chordCount}`;

                            var hoverState = linkTemplate.states.create("hover");
                            hoverState.properties.fillOpacity = 0.9;
                            hoverState.properties.strokeOpacity = 0.9
                            linkTemplate.events.on("hit", function (ev) {
                                console.log("clicked on ", ev.target.link._dataItem.properties.fromName
                                );
                                console.log(ev, 'aishuSneha');
                                // setCount(1)
                                setCategoryName(ev.target.link.dataItem.categories.categoryY)
                                setCompliance(ev.target.link._dataItem.properties.fromName);
                                console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                                const payload = [

                                    authtoken,

                                    {

                                        "session_token": authtoken,

                                        "request": [
                                            "GetComplianceStatusDrillDownData",
                                            {
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "from_date": null,
                                                "to_date": null,
                                                "filter_type": "Group",
                                                "filter_id": 1,
                                                "compliance_status": ev.target.link._dataItem.properties.fromName,
                                                "chart_year": year,
                                                "record_count": 0,
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                "country_domains": countryDomain,
                                                "criticality": ""
                                            }
                                        ]

                                    }
                                ]
                                getCompliancestatusTable({
                                    payload: payload,
                                    paramid: paramid
                                })
                                setTable(true)

                            }, this);

                            let countryHeading = ((fieldType == "LegalEntity" && go == true) ? `Legal Entities` : (
                                (fieldType == "Division" && go == true) ? `Divisions` : (
                                    (fieldType == "Category" && go == true) ? "Categories" : (
                                        (fieldType == "BusinessGroup" && go == true) ? "Business Groups" : (
                                            (fieldType == "Unit" && go == true) ? "Units" : "Countries"
                                        )))
                            ))
                            chart.exporting.dataFields = {
                                "type": `${countryHeading}`,
                                "complied_count": "Complied",
                                "delayed_compliance_count": "Delayed Compliance",
                                "inprogress_compliance_count": "Inprogress",
                                "not_complied_count": "Not Complied",
                            }

                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.filePrefix = "Exported Data";
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    },
                                    //  {
                                    //     "label": "Data",
                                    //     "menu": [
                                    //         { "type": "csv", "label": "CSV" },
                                    //         { "type": "xlsx", "label": "XLSX" },
                                    //     ]
                                    // }, 
                                    {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'
                            title.fontSize = '14px'

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                            (fieldType == "Unit" && go == true) ? `${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} Wise Compliance(2022)` : `Country Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            // title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `${textArray}`
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                            var label7 = chart.createChild(am4core.Label);
                            label7.text = `${textValue}`
                            label7.fontSize = 14;
                            label7.align = "left";
                            label7.marginLeft = '1%';
                            label7.marginRight = '2%';
                            label7.marginTop = '2%';
                            label7.fontWeight = 'bold'
                            label7.height = '5%'
                            label7.maxWidth = 700;
                            label7.wrap = true;

                            if (dateState === true && go === true) {
                                var label2 = chart.createChild(am4core.Label)
                                label2.text = `Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                                label2.align = "left";
                                label2.marginLeft = '1%';
                                label2.marginRight = '2%';
                                label2.marginTop = '2%';
                                label2.marginBottom = '5%'
                            }

                            // }

                            // addChart(chordChartData);
                        }
                        else if (chartType == 'Percentage') {
                            am4core.useTheme(am4themes_animated);
                            // Create a container
                            // var container = am4core.create("container", am4core.Container);
                            // container.width = am4core.percent(100);
                            // container.height = am4core.percent(100);
                            // container.layout = "vertical";
                            // container.background.fill = am4core.color("#000");
                            // container.background.fillOpacity = 0.1;
                            // container.background.stroke = am4core.color("#000");
                            // container.background.strokeOpacity = 0.2;
                            // container.background.strokeWidth = 2;

                            // function addChart(data) {

                            //     // Create chart instance
                            //     var chart = container.createChild(am4charts.ChordDiagram);
                            var chart = am4core.create("container", am4charts.ChordDiagram);
                            var colors = {
                                India: chart.colors.next(),
                                Complied: "#0de580",
                                DelayedCompliance: "#3c78d8",
                                Inprogress: "#fe4365",
                                NotComplied: "#f9c80e",

                            }

                            // Add data
                            chart.data = chordChartData;

                            chart.dataFields.fromName = "from";
                            chart.dataFields.toName = "to";
                            chart.dataFields.value = "value";

                            chart.nodePadding = 0.9;
                            chart.minNodeSize = 0.01;
                            chart.startAngle = 80;
                            chart.endAngle = chart.startAngle + 360;
                            chart.sortBy = "value";
                            chart.fontSize = 10;
                            chart.events.on("hit", function (ev) {
                                console.log('ev.target.baseSprite');
                            });

                            var nodeTemplate = chart.nodes.template;
                            // nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
                            nodeTemplate.showSystemTooltip = true;
                            nodeTemplate.propertyFields.fill = "color";
                            nodeTemplate.tooltipText = "{name}";
                            nodeTemplate.clickable = true;

                            // when rolled over the node, make all the links rolled-over
                            nodeTemplate.events.on("over", function (event) {
                                var node = event.target;
                                node.outgoingDataItems.each(function (dataItem) {
                                    if (dataItem.toNode) {
                                        dataItem.link.isHover = true;
                                        dataItem.toNode.label.isHover = true;
                                    }
                                })
                                node.incomingDataItems.each(function (dataItem) {
                                    if (dataItem.fromNode) {
                                        dataItem.link.isHover = true;
                                        dataItem.fromNode.label.isHover = true;
                                    }
                                })

                                node.label.isHover = true;
                            })

                            // when rolled out from the node, make all the links rolled-out
                            nodeTemplate.events.on("out", function (event) {
                                var node = event.target;
                                node.outgoingDataItems.each(function (dataItem) {
                                    if (dataItem.toNode) {
                                        dataItem.link.isHover = false;
                                        dataItem.toNode.label.isHover = false;
                                    }
                                })
                                node.incomingDataItems.each(function (dataItem) {
                                    if (dataItem.fromNode) {
                                        dataItem.link.isHover = false;
                                        dataItem.fromNode.label.isHover = false;
                                    }
                                })

                                node.label.isHover = false;
                            })

                            var label = nodeTemplate.label;
                            label.relativeRotation = 90;

                            label.fillOpacity = 0.4;
                            let labelHS = label.states.create("hover");
                            labelHS.properties.fillOpacity = 1;



                            nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                            // this adapter makes non-main character nodes to be filled with color of the main character which he/she kissed most
                            nodeTemplate.adapter.add("fill", function (fill, target) {
                                let node = target;
                                let counters = {};
                                let mainChar = false;
                                node.incomingDataItems.each(function (dataItem) {
                                    if (colors[dataItem.toName]) {
                                        mainChar = true;
                                    }

                                    if (isNaN(counters[dataItem.fromName])) {
                                        counters[dataItem.fromName] = dataItem.value;
                                    }
                                    else {
                                        counters[dataItem.fromName] += dataItem.value;
                                    }
                                })
                                if (mainChar) {
                                    return fill;
                                }

                                let count = 0;
                                let color;
                                let biggest = 0;
                                let biggestName;

                                for (var name in counters) {
                                    if (counters[name] > biggest) {
                                        biggestName = name;
                                        biggest = counters[name];
                                    }
                                }
                                if (colors[biggestName]) {
                                    fill = colors[biggestName];
                                }

                                return fill;
                            })

                            // link template
                            var linkTemplate = chart.links.template;
                            linkTemplate.strokeOpacity = 0;
                            linkTemplate.fillOpacity = 0.15;
                            linkTemplate.adapter.add("tooltipText", function (text, target, key) {
                                console.log(text);
                                console.log(target);
                                console.log(key);
                                console.log(target.dataItem.dataContext);
                                let count = ((target.dataItem.dataContext.value) / chordCount) * 100
                                return `India-{fromName}:(${count.toFixed(2)}%)`;
                            });
                            // linkTemplate.tooltipText = `{fromName}:({value}${totalValue})`;

                            var hoverState = linkTemplate.states.create("hover");
                            hoverState.properties.fillOpacity = 0.9;
                            hoverState.properties.strokeOpacity = 0.9

                            linkTemplate.events.on("hit", function (ev) {
                                console.log("clicked on ", ev.target.link._dataItem.properties.fromName
                                );
                                setCategoryName(ev.target.link.dataItem.categories.categoryY)
                                setCompliance(ev.target.link._dataItem.properties.fromName);
                                console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                                const payload = [

                                    authtoken,

                                    {

                                        "session_token": authtoken,

                                        "request": [
                                            "GetComplianceStatusDrillDownData",
                                            {
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "from_date": null,
                                                "to_date": null,
                                                "filter_type": "Group",
                                                "filter_id": 1,
                                                "compliance_status": ev.target.link._dataItem.properties.fromName,
                                                "chart_year": year,
                                                "record_count": 0,
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                "country_domains": countryDomain,
                                                "criticality": ""
                                            }
                                        ]

                                    }

                                ]



                                getCompliancestatusTable({

                                    payload: payload,

                                    paramid: paramid

                                })
                                setTable(true)

                            }, this);

                            let countryHeading = ((fieldType == "LegalEntity" && go == true) ? `Legal Entities` : (
                                (fieldType == "Division" && go == true) ? `Divisions` : (
                                    (fieldType == "Category" && go == true) ? "Categories" : (
                                        (fieldType == "BusinessGroup" && go == true) ? "Business Groups" : (
                                            (fieldType == "Unit" && go == true) ? "Units" : "Countries"
                                        )))
                            ))
                            chart.exporting.dataFields = {
                                "type": `${countryHeading}`,
                                "complied_count": "Complied",
                                "delayed_compliance_count": "Delayed Compliance",
                                "inprogress_compliance_count": "Inprogress",
                                "not_complied_count": "Not Complied",
                            }

                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.filePrefix = "Exported Data";
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    },
                                    //  {
                                    //     "label": "Data",
                                    //     "menu": [
                                    //         { "type": "csv", "label": "CSV" },
                                    //         { "type": "xlsx", "label": "XLSX" },
                                    //     ]
                                    // }, 
                                    {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'
                            title.fontSize = '14px'


                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                            (fieldType == "Unit" && go == true) ? `${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} Wise Compliance(2022)` : `Country Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            // title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `${textArray}`
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                            var label7 = chart.createChild(am4core.Label);
                            label7.text = `${textValue}`
                            label7.fontSize = 14;
                            label7.align = "left";
                            label7.marginLeft = '1%';
                            label7.marginRight = '2%';
                            label7.marginTop = '2%';
                            label7.fontWeight = 'bold'
                            label7.height = '5%'
                            label7.maxWidth = 700;
                            label7.wrap = true;

                            if (dateState === true && go === true) {
                                var label2 = chart.createChild(am4core.Label)
                                label2.text = `Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                                label2.align = "left";
                                label2.marginLeft = '1%';
                                label2.marginRight = '2%';
                                label2.marginTop = '2%';
                                label2.marginBottom = '5%'
                            }

                            // }

                            // addChart(chordChartData);


                        }
                    }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, multiSelect, chartName, chordChartData, timeZone, timeZoneData, localStorage.getItem('chart')])

    useEffect(() => {
        if ((table == false) && (criticaltityStatus == false)) {
            let total = ((dataValue && dataValue.length && dataValue[0].complied_count) + (dataValue && dataValue.length && dataValue[0].delayed_compliance_count) + (dataValue && dataValue.length && dataValue[0].inprogress_compliance_count) + (dataValue && dataValue.length && dataValue[0].not_complied_count))
            console.log('Avvvvvvvvs');
            if (multiSelect == false) {
                if (dataValue && dataValue.length > 0) {
                    if (chartName == 'pie') {
                        console.log('testinggggg');
                        if (chartType == 'count') {
                            am4core.useTheme(am4themes_animated);
                            // Create a container
                            // var container = am4core.create("container", am4core.Container);
                            // container.width = am4core.percent(100);
                            // container.height = am4core.percent(100);
                            // container.layout = "vertical";
                            // container.background.fill = am4core.color("#000");
                            // container.background.fillOpacity = 0.1;
                            // container.background.stroke = am4core.color("#000");
                            // container.background.strokeOpacity = 0.2;
                            // container.background.strokeWidth = 2;

                            // function addChart(data) {
                            //     var chart = container.createChild(am4charts.XYChart3D);
                            var chart = am4core.create("container", am4charts.XYChart3D);
                            // Add data
                            // chart.data = columChart;
                            chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

                            // Add data
                            chart.data = columChart;

                            // Create axes
                            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                            categoryAxis.dataFields.category = "data";
                            categoryAxis.title.text =
                                ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                                    (fieldType == "Division" && go == true) ? `Division` : (
                                        (fieldType == "Category" && go == true) ? `Category` : (
                                            (fieldType == "BusinessGroup" && go == true) ? `Business Groups` : (
                                                (fieldType == "Unit" && go == true) ? `Unit` : `Countries`
                                            )))
                                ))

                            // dateAxis.renderer.labels.template.events.on("hit", function (event) {}
                            // categoryAxis.renderer.grid.template.disabled = true;
                            categoryAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                            categoryAxis.renderer.labels.template.events.on("hit", function (event) {
                                setFilterId(event.target.dataItem._dataContext.typeId)

                                console.log(event, 'aishuuuuViii');
                                setLabelCountryName(event.target.dataItem._dataContext.data)
                                if (chartData.length > 0) {
                                    console.log(event, 'eventttt');
                                    console.log(event.target.dataItem._dataContext, 'event.target.dataItem._dataContext');
                                    let temp = []
                                    let temp1 = []
                                    temp.push({
                                        "inprogress_compliance_count": event.target.dataItem._dataContext.pieValue[2].value,
                                        "not_complied_count": event.target.dataItem._dataContext.pieValue[3].value,
                                        "complied_count": event.target.dataItem._dataContext.pieValue[0].value,
                                        "delayed_compliance_count": event.target.dataItem._dataContext.pieValue[1].value,
                                        "type": event.target.dataItem._dataContext.type,
                                        "typeId": event.target.dataItem._dataContext.typeId,

                                    })
                                    if (temp && temp.length > 0) {
                                        for (let i in temp) {
                                            const removeZero = item => (
                                                Object.keys(item)
                                                    .filter(key => item[key] !== 0)
                                                    .reduce((newObj, key) => {
                                                        newObj[key] = item[key]; return newObj;
                                                    }, {})); const result = removeZero(temp[i]);
                                            console.log(result, 'result');
                                            temp1.push(result)
                                        }
                                    }
                                    setmultiSelectChart(temp1[0])
                                    setmultiSelect(true)
                                    // setSelectChart(temp[0])

                                }
                            })
                            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                            valueAxis.title.text = "Total Compliance";
                            valueAxis.min = 0;
                            valueAxis.renderer.baseGrid.disabled = true;
                            valueAxis.renderer.grid.template.strokeOpacity = 0.07;
                            chart.scrollbarX = new am4core.Scrollbar();
                            chart.scrollbarX.parent = chart.topAxesContainer;
                            // chart.plotContainer.stroke = "#000000";
                            // chart.plotContainer.strokeWidth = 5;
                            chart.scrollbarY = new am4core.Scrollbar();
                            chart.scrollbarY.parent = chart.rightAxesContainer;
                            // Create series
                            var series = chart.series.push(new am4charts.ColumnSeries());
                            series.dataFields.valueY = "count";
                            series.dataFields.categoryX = "data";
                            series.tooltip.pointerOrientation = "vertical";
                            var columnTemplate = series.columns.template;
                            // add tooltip on column, not template, so that slices could also have tooltip
                            columnTemplate.column.tooltipText = "{categoryX}:{valueY} ";
                            columnTemplate.column.tooltipY = 0;
                            columnTemplate.column.cornerRadiusTopLeft = 20;
                            columnTemplate.column.cornerRadiusTopRight = 20;
                            columnTemplate.strokeOpacity = 0;
                            // create pie chart as a column child
                            var pieChart = series.columns.template.createChild(am4charts.PieChart3D);
                            pieChart.width = am4core.percent(80);
                            pieChart.height = am4core.percent(80);
                            pieChart.align = "center";
                            pieChart.valign = "middle";
                            pieChart.dataFields.data = "pieValue";
                            var pieSeries = pieChart.series.push(new am4charts.PieSeries());
                            pieSeries.dataFields.value = "value";
                            pieSeries.dataFields.category = "title";
                            pieSeries.calculatePercent = true;
                            pieSeries.calculateTotals = true;
                            pieSeries.slices.template.tooltipText = `{category}: {value} out of ${columncount}`;
                            pieSeries.labels.template.disabled = true;
                            pieSeries.ticks.template.disabled = true;
                            pieSeries.slices.template.stroke = am4core.color("#ffffff");
                            pieSeries.slices.template.strokeWidth = 1;
                            pieSeries.slices.template.strokeOpacity = 0;
                            pieSeries.slices.template.propertyFields.fill = "color";
                            pieSeries.hiddenState.properties.startAngle = -90;
                            pieSeries.hiddenState.properties.endAngle = 270;

                            let countryHeading = ((fieldType == "LegalEntity" && go == true) ? `Legal Entities` : (
                                (fieldType == "Division" && go == true) ? `Divisions` : (
                                    (fieldType == "Category" && go == true) ? "Categories" : (
                                        (fieldType == "BusinessGroup" && go == true) ? "Business Groups" : (
                                            (fieldType == "Unit" && go == true) ? "Units" : "Countries"
                                        )))
                            ))
                            chart.exporting.dataFields = {
                                "data": "Countries",
                                "count": "Count"
                            }

                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.filePrefix = "Exported Data";
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'
                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                            (fieldType == "Unit" && go == true) ? `${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} Wise Compliance(2022)` : `Country Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            // title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `${textArray}`
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '2%'

                            var label7 = chart.createChild(am4core.Label);
                            label7.text = `${textValue}`
                            label7.fontSize = 14;
                            label7.align = "left";
                            label7.marginLeft = '1%';
                            label7.marginRight = '2%';
                            label7.marginTop = '2%';
                            label7.fontWeight = 'bold'
                            label7.height = '5%'
                            label7.maxWidth = 700;
                            label7.wrap = true;

                            if (dateState === true && go === true) {
                                var label2 = chart.createChild(am4core.Label)
                                label2.text = `Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                                label2.align = "left";
                                label2.marginLeft = '1%';
                                label2.marginRight = '2%';
                                label2.marginTop = '2%';
                                label2.marginBottom = '5%'
                            }

                            // }

                            // addChart(columChart);

                        }
                        else if (chartType == 'Percentage') {
                            am4core.useTheme(am4themes_animated);
                            // Create a container
                            // var container = am4core.create("container", am4core.Container);
                            // container.width = am4core.percent(100);
                            // container.height = am4core.percent(100);
                            // container.layout = "vertical";
                            // container.background.fill = am4core.color("#000");
                            // container.background.fillOpacity = 0.1;
                            // container.background.stroke = am4core.color("#000");
                            // container.background.strokeOpacity = 0.2;
                            // container.background.strokeWidth = 2;

                            // function addChart(data) {
                            //     var chart = container.createChild(am4charts.XYChart3D);
                            var chart = am4core.create("container", am4charts.XYChart3D);
                            // Add data
                            // chart.data = columChart;
                            chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

                            // Add data
                            chart.data = columChart;

                            // Create axes
                            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                            categoryAxis.dataFields.category = "data";
                            categoryAxis.title.text =
                                ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                                    (fieldType == "Division" && go == true) ? `Division` : (
                                        (fieldType == "Category" && go == true) ? `Category` : (
                                            (fieldType == "Unit" && go == true) ? `Unit` : `Countries`
                                        ))
                                ))
                            // categoryAxis.renderer.grid.template.disabled = true;

                            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                            valueAxis.title.text = "Total Compliance";
                            valueAxis.min = 0;
                            valueAxis.renderer.baseGrid.disabled = true;
                            valueAxis.renderer.grid.template.strokeOpacity = 0.07;

                            // Create series
                            var series = chart.series.push(new am4charts.ColumnSeries());
                            series.dataFields.valueY = "count";
                            series.dataFields.categoryX = "data";
                            series.tooltip.pointerOrientation = "vertical";


                            var columnTemplate = series.columns.template;
                            // add tooltip on column, not template, so that slices could also have tooltip
                            columnTemplate.column.tooltipText = "{categoryX}: {count}";
                            columnTemplate.column.tooltipY = 0;
                            columnTemplate.column.cornerRadiusTopLeft = 20;
                            columnTemplate.column.cornerRadiusTopRight = 20;
                            columnTemplate.strokeOpacity = 0;


                            // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
                            // columnTemplate.adapter.add("fill", (fill, target) => {
                            //     var color = chart.colors.getIndex(target.dataItem.index * 3);
                            //     return color;
                            // });

                            // create pie chart as a column child
                            var pieChart = series.columns.template.createChild(am4charts.PieChart3D);
                            pieChart.width = am4core.percent(80);
                            pieChart.height = am4core.percent(80);
                            pieChart.align = "center";
                            pieChart.valign = "middle";
                            pieChart.dataFields.data = "pieValue";

                            var pieSeries = pieChart.series.push(new am4charts.PieSeries());
                            pieSeries.dataFields.value = "value";
                            pieSeries.dataFields.category = "title";
                            pieSeries.labels.template.disabled = true;
                            pieSeries.ticks.template.disabled = true;
                            pieSeries.slices.template.stroke = am4core.color("#ffffff");
                            pieSeries.slices.template.strokeWidth = 1;
                            pieSeries.slices.template.strokeOpacity = 0;
                            pieSeries.slices.template.propertyFields.fill = "color";
                            pieSeries.calculatePercent = true;
                            pieSeries.calculateTotals = true;
                            pieSeries.slices.template.tooltipText = "{category}: {value.percent.formatNumber('#.00')}[/]%";
                            // pieSeries.slices.template.adapter.add("fill", (fill, target) => {
                            //     return am4core.color("#ffffff")
                            // });

                            // pieSeries.slices.template.adapter.add("fillOpacity", (fillOpacity, target) => {
                            //     return (target.dataItem.index + 1) * 0.2;
                            // });

                            pieSeries.hiddenState.properties.startAngle = -90;
                            pieSeries.hiddenState.properties.endAngle = 270;

                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.filePrefix = "Exported Data";
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})` : (
                                            (fieldType == "Unit" && go == true) ? `${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} Wise Compliance(2022)` : `Country Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            // title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `${textArray}`
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                            // }

                            // addChart(columChart);
                        }

                    }
                }
            }
        }

    }, [dataValue, chartData, table, multiSelect, chartType, criticaltityStatus, chartName, columChart, timeZone, timeZoneData, localStorage.getItem('chart')])
    useEffect(() => {
        if (filterChartName == 'donut') {
            if ((table == false)) {
                var result = _.omit(multiSelectChart, ['type', 'typeId']);
                const sumValues = result => Object.values(result).reduce((a, b) => a + b, 0);
                var total = sumValues(result)
                // var total = multiSelectChart.complied_count + multiSelectChart.delayed_compliance_count + multiSelectChart.not_complied_count + multiSelectChart.inprogress_compliance_count
                console.log(total, 'Avvvvvvvvs');
                if (multiSelect == true) {
                    if (multiSelectChart) {
                        console.log('testinggggg');

                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        // var container = am4core.create("container", am4core.Container);
                        // container.width = am4core.percent(100);
                        // container.height = am4core.percent(100);
                        // container.layout = "vertical";
                        // container.background.fill = am4core.color("#000");
                        // container.background.fillOpacity = 0.1;
                        // container.background.stroke = am4core.color("#000");
                        // container.background.strokeOpacity = 0.2;
                        // container.background.strokeWidth = 2;

                        // function addChart(data) {

                        //     // Create chart instance
                        //     var chart = container.createChild(am4charts.PieChart3D);
                        var chart = am4core.create("container", am4charts.PieChart3D);
                        // Add data
                        chart.data = [
                            (multiSelectChart && multiSelectChart.complied_count > 0 ?
                                {
                                    "type": "Complied",
                                    "count": multiSelectChart && multiSelectChart.complied_count,
                                    "color": am4core.color("#0de580"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {})
                            ,
                            (multiSelectChart && multiSelectChart.delayed_compliance_count > 0 ?
                                {
                                    "type": "Delayed Compliance",
                                    "count": multiSelectChart && multiSelectChart.delayed_compliance_count,
                                    "color": am4core.color("#3c78d8"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                            (multiSelectChart && multiSelectChart.not_complied_count > 0 ?
                                {
                                    "type": "Not Complied",
                                    "count": multiSelectChart && multiSelectChart.not_complied_count,
                                    "color": am4core.color("#fe4365"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                            (multiSelectChart && multiSelectChart.inprogress_compliance_count > 0 ?
                                {
                                    "type": "Inprogress",
                                    "count": multiSelectChart && multiSelectChart.inprogress_compliance_count,
                                    "color": am4core.color("#f9c80e"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                        ];

                        chart.innerRadius = am4core.percent(30);
                        function isZero(x) { return x == 0; }
                        // Add and configure Series
                        var pieSeries = chart.series.push(new am4charts.PieSeries3D());
                        pieSeries.dataFields.value = "count";
                        pieSeries.dataFields.category = "type";
                        pieSeries.slices.template.propertyFields.fill = "color";
                        chart.current = chart;
                        chart.legend = new am4charts.Legend();
                        pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;



                        // pieSeries.slices.template.stroke = am4core.color("#fff");
                        // pieSeries.slices.template.strokeWidth = 2;
                        // pieSeries.slices.template.strokeOpacity = 1;

                        chart.legend = new am4charts.Legend();
                        chart.legend.useDefaultMarker = true;
                        chart.legend.valueLabels.template.disabled = true;
                        var marker = chart.legend.markers.template.children.getIndex(0);
                        marker.cornerRadius(12, 12, 12, 12);
                        marker.strokeWidth = 2;
                        marker.strokeOpacity = 1;
                        marker.stroke = am4core.color("#ccc");


                        pieSeries.tooltip.label.adapter.add("text", function (text, target) {
                            if (target.dataItem && target.dataItem.value == 0) {
                                return "";
                            }
                            else {
                                return text;
                            }
                        });

                        // This creates initial animation
                        pieSeries.hiddenState.properties.opacity = 1;
                        pieSeries.hiddenState.properties.endAngle = -90;
                        pieSeries.hiddenState.properties.startAngle = -90;

                        pieSeries.slices.template.events.on("hit", function (ev) {

                            // setCompliedType((ev.target.dataItem.category === 'Above 90 days' ? 'Above 90' : (ev.target.dataItem.category === '0-30 days' ? 'Below 30' : (ev.target.dataItem.category === '31-60 days' ? 'Below 60' : (ev.target.dataItem.category === '61-90 days' ? 'Below 90' : '')))))
                            // setCount(1)
                            setCompliance(ev.target.dataItem._dataContext.type);
                            setShowMoreCount(0)
                            setDataCount(ev.target.dataItem._dataContext.type == "Inprogress" ? ev.target.dataItem._dataContext.count
                                : ev.target.dataItem._dataContext.type == "Complied" ? ev.target.dataItem._dataContext.count
                                    : ev.target.dataItem._dataContext.type == "Not Complied" ? ev.target.dataItem._dataContext.count
                                        : ev.target.dataItem._dataContext.type == "Delayed Compliance" ? ev.target.dataItem._dataContext.count : 0)
                            console.log(ev.target.dataItem, ' ev.target.dataItem.component.name');
                            console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "GetComplianceStatusDrillDownData",
                                        {
                                            "d_ids": domainIds,
                                            "from_date": null,
                                            "to_date": null,
                                            "filter_type": ((fieldType == "LegalEntity" && go == true) ? `LegalEntity` : (
                                                (fieldType == "Division" && go == true) ? `Division` : (
                                                    (fieldType == "Category" && go == true) ? "Category" : (
                                                        (fieldType == "Unit" && go == true) ? "Unit" : "Group"
                                                    ))
                                            )),
                                            "filter_id": ev.target.dataItem._dataContext.typeId,
                                            "compliance_status": ev.target.dataItem._dataContext.type,
                                            "chart_year": year,
                                            "record_count": 0,
                                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                            "country_domains": countryDomain,
                                            "criticality": criticaltityStatus === true ? criticality : ""
                                        }
                                    ]
                                }
                            ]
                            getCompliancestatusTable({
                                payload: payload,
                                paramid: paramid
                            })
                            setTable(true)


                        })

                        // let countryHeading = ((fieldType == "LegalEntity" && go == true) ? `Legal Entities` : (
                        //     (fieldType == "Division" && go == true) ? `Divisions` : (
                        //         (fieldType == "Category" && go == true) ? "Categories" : (
                        //             (fieldType == "BusinessGroup" && go == true) ? "Business Groups" : (
                        //                 (fieldType == "Unit" && go == true) ? "Units" : "Countries"
                        //             )))
                        // ))
                        // chart.exporting.dataFields = {
                        //     "type": `${countryHeading}`,
                        //     "complied_count": "Complied",
                        //     "delayed_compliance_count": "Delayed Compliance",
                        //     "inprogress_compliance_count": "Inprogress",
                        //     "not_complied_count": "Not Complied",
                        // }

                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.filePrefix = "Exported Data";
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                        var title = chart.titles.create();
                        title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'

                        // fieldType == "LegalEntity" ? filterData.legalentity :
                        // fieldType == 'Division' ? Number(filterData.division) :
                        //     fieldType == 'Category' ? Number(filterData.category) :
                        //         fieldType == 'Unit' ? Number(filterData.unit) 

                        var title1 = chart.titles.create();
                        title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                            (fieldType == "Division" && go == true) ? `Division Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                (fieldType == "Category" && go == true) ? `Category Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                    (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                        (consolidated === true && go == true) ? `Consolidated Chart` : (
                                            (fieldType == "Unit" && go == true) ? `${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} Wise Compliance(2022)` : `Country Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${labelCountryName}[/]`
                                        ))))
                        ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `${textArray}`
                        // label1.text = ['hiii','hello']
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '5%'

                        // var title2 = chart.titles.create();
                        // title2.text = 'Country-Domain'
                        // title2.disabled = false;
                        // title2.align = 'bottom'
                        // title2.marginTop = '2%'
                        // title2.marginLeft = '2%'

                        // title2.fontWeight = 'bold'

                        // Enable title on export
                        // chart.exporting.events.on("exportstarted", function (ev) {
                        //     title.disabled = false;
                        //     title.parent.invalidate();
                        // });

                        // // Disable title when export finishes
                        // chart.exporting.events.on("exportfinished", function (ev) {
                        //     title.disabled = true;
                        // });
                        // }

                        // Add charts
                        // addChart(multiSelectChart
                        // );
                        // addChart([
                        //     (multiSelectChart && multiSelectChart.complied_count > 0 ?
                        //         {
                        //             "type": "Complied",
                        //             "count": multiSelectChart && multiSelectChart.complied_count
                        //             ,
                        //             "color": am4core.color("#0de580")
                        //         } : {})
                        //     ,
                        //     (multiSelectChart && multiSelectChart.delayed_compliance_count > 0 ?
                        //         {
                        //             "type": "Delayed Complaince",
                        //             "count": multiSelectChart && multiSelectChart.delayed_compliance_count,
                        //             "color": am4core.color("#3c78d8")
                        //         } : {}),
                        //     (multiSelectChart && multiSelectChart.not_complied_count > 0 ?
                        //         {
                        //             "type": "Not Complied",
                        //             "count": multiSelectChart && multiSelectChart.not_complied_count,
                        //             "color": am4core.color("#fe4365")
                        //         } : {}),
                        //     (multiSelectChart && multiSelectChart.inprogress_compliance_count > 0 ?
                        //         {
                        //             "type": "Inprogress",
                        //             "count": multiSelectChart && multiSelectChart.inprogress_compliance_count,
                        //             "color": am4core.color("#f9c80e")
                        //         } : {}),
                        // ]);


                    }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, multiSelect, filterChartName, multiSelectChart, timeZone, timeZoneData, localStorage.getItem('chart')])
    useEffect(() => {
        if (filterChartName == 'bar') {
            if ((table == false)) {
                var result = _.omit(multiSelectChart, ['type', 'typeId']);
                const sumValues = result => Object.values(result).reduce((a, b) => a + b, 0);
                var total = sumValues(result)
                // var total = multiSelectChart.complied_count + multiSelectChart.delayed_compliance_count + multiSelectChart.not_complied_count + multiSelectChart.inprogress_compliance_count
                console.log(total, 'Avvvvvvvvs');
                if (multiSelect == true) {
                    if (multiSelectChart) {
                        console.log('testinggggg');

                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        // var container = am4core.create("container", am4core.Container);
                        // container.width = am4core.percent(100);
                        // container.height = am4core.percent(100);
                        // container.layout = "vertical";
                        // container.background.fill = am4core.color("#000");
                        // container.background.fillOpacity = 0.1;
                        // container.background.stroke = am4core.color("#000");
                        // container.background.strokeOpacity = 0.2;
                        // container.background.strokeWidth = 2;

                        // function addChart(data) {

                        //     // Create chart instance
                        //     var chart = container.createChild(am4charts.XYChart3D);
                        var chart = am4core.create("container", am4charts.XYChart3D);
                        // Add data
                        chart.data = [
                            (multiSelectChart && multiSelectChart.complied_count > 0 ?
                                {
                                    "type": "Complied",
                                    "count": multiSelectChart && multiSelectChart.complied_count
                                    ,
                                    "color": am4core.color("#0de580"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {})
                            ,
                            (multiSelectChart && multiSelectChart.delayed_compliance_count > 0 ?
                                {
                                    "type": "Delayed Compliance",
                                    "count": multiSelectChart && multiSelectChart.delayed_compliance_count,
                                    "color": am4core.color("#3c78d8"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                            (multiSelectChart && multiSelectChart.not_complied_count > 0 ?
                                {
                                    "type": "Not Complied",
                                    "count": multiSelectChart && multiSelectChart.not_complied_count,
                                    "color": am4core.color("#fe4365"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                            (multiSelectChart && multiSelectChart.inprogress_compliance_count > 0 ?
                                {
                                    "type": "Inprogress",
                                    "count": multiSelectChart && multiSelectChart.inprogress_compliance_count,
                                    "color": am4core.color("#f9c80e"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                        ];
                        chart.scrollbarX = new am4core.Scrollbar();
                        chart.scrollbarY = new am4core.Scrollbar();
                        chart.scrollbarY.parent = chart.rightAxesContainer;
                        chart.height = 500
                        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.dataFields.category = "type";
                        // categoryAxis.numberFormatter.numberFormat = "#";
                        // categoryAxis.renderer.inversed = true;

                        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());

                        // Create series
                        var series = chart.series.push(new am4charts.ColumnSeries3D());
                        series.dataFields.valueX = "count";
                        series.dataFields.categoryY = "type";
                        // series.name = "count";
                        series.columns.template.propertyFields.fill = "color";
                        series.columns.template.tooltipText = `{type}:  ({valueX} out of ${total})`;
                        // series.columns.template.column3D.stroke = am4core.color("#fff");
                        series.columns.template.maxHeight = 50

                        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                        labelBullet.label.text = "{valueX}";
                        labelBullet.locationX = 0.5;
                        labelBullet.locationY = 0.5;

                        series.columns.template.events.on("hit", function (ev) {

                            // setCompliedType((ev.target.dataItem.category === 'Above 90 days' ? 'Above 90' : (ev.target.dataItem.category === '0-30 days' ? 'Below 30' : (ev.target.dataItem.category === '31-60 days' ? 'Below 60' : (ev.target.dataItem.category === '61-90 days' ? 'Below 90' : '')))))
                            // setCount(1)
                            setFilterId(ev.target.dataItem._dataContext.typeId)
                            setCompliance(ev.target.dataItem._dataContext.type);
                            setShowMoreCount(0)
                            setDataCount(ev.target.dataItem._dataContext.type == "Inprogress" ? ev.target.dataItem._dataContext.count
                                : ev.target.dataItem._dataContext.type == "Complied" ? ev.target.dataItem._dataContext.count
                                    : ev.target.dataItem._dataContext.type == "Not Complied" ? ev.target.dataItem._dataContext.count
                                        : ev.target.dataItem._dataContext.type == "Delayed Compliance" ? ev.target.dataItem._dataContext.count : 0)
                            console.log(ev.target.dataItem, ' ev.target.dataItem.component.name');
                            console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "GetComplianceStatusDrillDownData",
                                        {
                                            "d_ids": domainIds,
                                            "from_date": null,
                                            "to_date": null,
                                            "filter_type": ((fieldType == "LegalEntity" && go == true) ? `LegalEntity` : (
                                                (fieldType == "Division" && go == true) ? `Division` : (
                                                    (fieldType == "Category" && go == true) ? "Category" : (
                                                        (fieldType == "Unit" && go == true) ? "Unit" : "Group"
                                                    ))
                                            )),
                                            "filter_id": ev.target.dataItem._dataContext.typeId,
                                            "compliance_status": ev.target.dataItem._dataContext.type,
                                            "chart_year": year,
                                            "record_count": 0,
                                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                            "country_domains": countryDomain,
                                            "criticality": criticaltityStatus === true ? criticality : ""
                                        }
                                    ]
                                }
                            ]
                            getCompliancestatusTable({
                                payload: payload,
                                paramid: paramid
                            })
                            setTable(true)


                        })

                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.filePrefix = "Exported Data";
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                        var title = chart.titles.create();
                        title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'

                        // fieldType == "LegalEntity" ? filterData.legalentity :
                        // fieldType == 'Division' ? Number(filterData.division) :
                        //     fieldType == 'Category' ? Number(filterData.category) :
                        //         fieldType == 'Unit' ? Number(filterData.unit) 

                        var title1 = chart.titles.create();
                        title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                            (fieldType == "Division" && go == true) ? `Division Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                (fieldType == "Category" && go == true) ? `Category Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                    (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                        (consolidated === true && go == true) ? `Consolidated Chart` : (
                                            (fieldType == "Unit" && go == true) ? `${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} Wise Compliance(2022)` : `Country Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${labelCountryName}[/]`
                                        ))))
                        ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `${textArray}`
                        // label1.text = ['hiii','hello']
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '5%'

                        // var title2 = chart.titles.create();
                        // title2.text = 'Country-Domain'
                        // title2.disabled = false;
                        // title2.align = 'bottom'
                        // title2.marginTop = '2%'
                        // title2.marginLeft = '2%'

                        // title2.fontWeight = 'bold'

                        // Enable title on export
                        // chart.exporting.events.on("exportstarted", function (ev) {
                        //     title.disabled = false;
                        //     title.parent.invalidate();
                        // });

                        // // Disable title when export finishes
                        // chart.exporting.events.on("exportfinished", function (ev) {
                        //     title.disabled = true;
                        // });
                        // }

                        // Add charts





                    }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, multiSelect, filterChartName, multiSelectChart, timeZone, timeZoneData, localStorage.getItem('chart')])

    useEffect(() => {
        if (filterChartName == '3d') {
            if ((table == false)) {
                var result = _.omit(multiSelectChart, ['type', 'typeId']);

                const sumValues = result => Object.values(result).reduce((a, b) => a + b, 0);
                var total = sumValues(result)
                // var total = multiSelectChart.complied_count + multiSelectChart.delayed_compliance_count + multiSelectChart.not_complied_count + multiSelectChart.inprogress_compliance_count
                console.log(total, 'Avvvvvvvvs');
                if (multiSelect == true) {
                    if (multiSelectChart) {
                        console.log('testinggggg');

                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        // var container = am4core.create("container", am4core.Container);
                        // container.width = am4core.percent(100);
                        // container.height = am4core.percent(100);
                        // container.layout = "vertical";
                        // container.background.fill = am4core.color("#000");
                        // container.background.fillOpacity = 0.1;
                        // container.background.stroke = am4core.color("#000");
                        // container.background.strokeOpacity = 0.2;
                        // container.background.strokeWidth = 2;

                        // function addChart(data) {

                        //     // Create chart instance
                        //     var chart = container.createChild(am4charts.XYChart3D);
                        var chart = am4core.create("container", am4charts.XYChart3D);
                        // Add data
                        chart.data = [
                            (multiSelectChart && multiSelectChart.complied_count > 0 ?
                                {
                                    "type": "Complied",
                                    "count": multiSelectChart && multiSelectChart.complied_count
                                    ,
                                    "color": am4core.color("#0de580"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {})
                            ,
                            (multiSelectChart && multiSelectChart.delayed_compliance_count > 0 ?
                                {
                                    "type": "Delayed Compliance",
                                    "count": multiSelectChart && multiSelectChart.delayed_compliance_count,
                                    "color": am4core.color("#3c78d8"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                            (multiSelectChart && multiSelectChart.not_complied_count > 0 ?
                                {
                                    "type": "Not Complied",
                                    "count": multiSelectChart && multiSelectChart.not_complied_count,
                                    "color": am4core.color("#fe4365"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                            (multiSelectChart && multiSelectChart.inprogress_compliance_count > 0 ?
                                {
                                    "type": "Inprogress",
                                    "count": multiSelectChart && multiSelectChart.inprogress_compliance_count,
                                    "color": am4core.color("#f9c80e"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                        ];
                        chart.height = 500
                        chart.scrollbarX = new am4core.Scrollbar();
                        chart.scrollbarY = new am4core.Scrollbar();
                        chart.scrollbarY.parent = chart.rightAxesContainer;
                        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.dataFields.category = "type";
                        // categoryAxis.numberFormatter.numberFormat = "#";
                        // categoryAxis.renderer.inversed = true;

                        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                        // Create series
                        var series = chart.series.push(new am4charts.ColumnSeries3D());
                        series.dataFields.categoryX = "type";
                        series.dataFields.valueY = "count";
                        // series.name = "count";
                        series.columns.template.propertyFields.fill = "color";
                        series.columns.template.tooltipText = `{type}:  ({valueY} out of ${total})`;
                        series.columns.template.column3D.stroke = am4core.color("#fff");
                        series.columns.template.column3D.strokeOpacity = 0.2;
                        series.columns.template.maxHeight = 50

                        series.columns.template.events.on("hit", function (ev) {
                            console.log(ev.target.dataItem._dataContext.type, 'ev.target.dataItem');
                            setFilterId(ev.target.dataItem._dataContext.typeId)
                            setCategoryName(ev.target.dataItem._dataContext.type)
                            // setCount(1)
                            setCompliance(ev.target.dataItem._dataContext.type);
                            setShowMoreCount(0)
                            setDataCount(ev.target.dataItem._dataContext.type == "Inprogress" ? ev.target.dataItem._dataContext.inprogress_compliance_count
                                : ev.target.dataItem._dataContext.type == "Complied" ? ev.target.dataItem._dataContext.complied_count
                                    : ev.target.dataItem._dataContext.type == "Not Complied" ? ev.target.dataItem._dataContext.not_complied_count
                                        : ev.target.dataItem._dataContext.type == "Delayed Compliance" ? ev.target.dataItem._dataContext.delayed_compliance_count : 0)
                            console.log(ev.target.dataItem, ' ev.target.dataItem.component.name');
                            console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                            const payload = [

                                authtoken,

                                {

                                    "session_token": authtoken,

                                    "request": [
                                        "GetComplianceStatusDrillDownData",
                                        {
                                            "d_ids": [
                                                commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                            ],
                                            "from_date": null,
                                            "to_date": null,
                                            "filter_type": ((fieldType == "LegalEntity" && go == true) ? `LegalEntity` : (
                                                (fieldType == "Division" && go == true) ? `Division` : (
                                                    (fieldType == "Category" && go == true) ? "Category" : (
                                                        (fieldType == "BusinessGroup" && go == true) ? "BusinessGroup" : (
                                                            (fieldType == "Unit" && go == true) ? "Unit" : "Group"
                                                        )))
                                            )),
                                            "filter_id": ev.target.dataItem._dataContext.typeId,
                                            "compliance_status": ev.target.dataItem._dataContext.type,
                                            "chart_year": year,
                                            "record_count": 0,
                                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                            "country_domains": countryDomain,
                                            "criticality": criticaltityStatus === true ? criticality : ""
                                        }
                                    ]
                                }
                            ]
                            getCompliancestatusTable({
                                payload: payload,
                                paramid: paramid
                            })
                            setTable(true)
                        })

                        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                        labelBullet.label.text = "{valueY}";
                        labelBullet.locationX = 0.5;
                        labelBullet.locationY = 0.5;
                        labelBullet.marginTop = '15%'
                        labelBullet.label.marginTop = '10%'

                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.filePrefix = "Exported Data";
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                        var title = chart.titles.create();
                        title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'

                        // fieldType == "LegalEntity" ? filterData.legalentity :
                        // fieldType == 'Division' ? Number(filterData.division) :
                        //     fieldType == 'Category' ? Number(filterData.category) :
                        //         fieldType == 'Unit' ? Number(filterData.unit) 


                        var title1 = chart.titles.create();
                        title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                            (fieldType == "Division" && go == true) ? `Division Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                (fieldType == "Category" && go == true) ? `Category Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                    (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                        (consolidated === true && go == true) ? `Consolidated Chart` : (
                                            (fieldType == "Unit" && go == true) ? `${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} Wise Compliance(2022)` : `Country Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${labelCountryName}[/]`
                                        ))))
                        ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `${textArray}`
                        // label1.text = ['hiii','hello']
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '5%'

                        // var title2 = chart.titles.create();
                        // title2.text = 'Country-Domain'
                        // title2.disabled = false;
                        // title2.align = 'bottom'
                        // title2.marginTop = '2%'
                        // title2.marginLeft = '2%'

                        // title2.fontWeight = 'bold'

                        // Enable title on export
                        // chart.exporting.events.on("exportstarted", function (ev) {
                        //     title.disabled = false;
                        //     title.parent.invalidate();
                        // });

                        // // Disable title when export finishes
                        // chart.exporting.events.on("exportfinished", function (ev) {
                        //     title.disabled = true;
                        // });
                        // }

                        // Add charts
                        // addChart(multiSelectChart
                        // );




                    }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, multiSelect, filterChartName, multiSelectChart, timeZone, timeZoneData, localStorage.getItem('chart')])



    useEffect(() => {
        if (filterChartName == 'curve') {
            if ((table == false)) {
                var result = _.omit(multiSelectChart, ['type', 'typeId']);
                const sumValues = result => Object.values(result).reduce((a, b) => a + b, 0);
                var total = sumValues(result)
                // var total = multiSelectChart.complied_count + multiSelectChart.delayed_compliance_count + multiSelectChart.not_complied_count + multiSelectChart.inprogress_compliance_count
                console.log(total, 'Avvvvvvvvs');
                if (multiSelect == true) {
                    if (multiSelectChart) {
                        console.log('testinggggg');

                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        // var container = am4core.create("container", am4core.Container);
                        // container.width = am4core.percent(100);
                        // container.height = am4core.percent(100);
                        // container.layout = "vertical";
                        // container.background.fill = am4core.color("#000");
                        // container.background.fillOpacity = 0.1;
                        // container.background.stroke = am4core.color("#000");
                        // container.background.strokeOpacity = 0.2;
                        // container.background.strokeWidth = 2;

                        // function addChart(data) {
                        //     var chart = container.createChild(am4charts.XYChart3D);
                        var chart = am4core.create("container", am4charts.XYChart3D);
                        chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

                        chart.data = [
                            (multiSelectChart && multiSelectChart.complied_count > 0 ?
                                {
                                    "type": "Complied",
                                    "count": multiSelectChart && multiSelectChart.complied_count
                                    ,
                                    "color": am4core.color("#0de580"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {})
                            ,
                            (multiSelectChart && multiSelectChart.delayed_compliance_count > 0 ?
                                {
                                    "type": "Delayed Compliance",
                                    "count": multiSelectChart && multiSelectChart.delayed_compliance_count,
                                    "color": am4core.color("#3c78d8"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                            (multiSelectChart && multiSelectChart.not_complied_count > 0 ?
                                {
                                    "type": "Not Complied",
                                    "count": multiSelectChart && multiSelectChart.not_complied_count,
                                    "color": am4core.color("#fe4365"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                            (multiSelectChart && multiSelectChart.inprogress_compliance_count > 0 ?
                                {
                                    "type": "Inprogress",
                                    "count": multiSelectChart && multiSelectChart.inprogress_compliance_count,
                                    "color": am4core.color("#f9c80e"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                        ]
                        chart.height = 500

                        // chart.colors.list = [
                        //     am4core.color("#0cce73"),
                        //     am4core.color("#f9c80e"),
                        //     am4core.color("#e53c5b"),
                        //     // am4core.color("#fe4365"),
                        // ];


                        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.renderer.grid.template.location = 0;
                        categoryAxis.dataFields.category = "type";
                        categoryAxis.renderer.minGridDistance = 40;

                        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                        var series = chart.series.push(new am4charts.CurvedColumnSeries());
                        series.dataFields.categoryX = "type";
                        series.dataFields.valueY = "count";
                        // series.tooltipText = "{valueY.value}"
                        series.columns.template.strokeOpacity = 0;
                        // series.columns.template.tension = 1;
                        series.columns.template.propertyFields.fill = "color";
                        series.columns.template.fillOpacity = 0.75;
                        series.columns.template.maxHeight = 50
                        series.columns.template.tooltipText = `{type}:  ({valueY} out of ${total})`;
                        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                        labelBullet.label.text = "{valueY}";
                        labelBullet.locationX = 0.5;
                        labelBullet.locationY = 0.5;

                        series.columns.template.events.on("hit", function (ev) {
                            console.log(ev.target.dataItem._dataContext.type, 'ev.target.dataItem');
                            setCategoryName(ev.target.dataItem._dataContext.type)
                            // setCount(1)
                            setFilterId(ev.target.dataItem._dataContext.typeId)
                            setCompliance(ev.target.dataItem._dataContext.type);
                            setShowMoreCount(0)
                            setDataCount(ev.target.dataItem._dataContext.type == "Inprogress" ? ev.target.dataItem._dataContext.inprogress_compliance_count
                                : ev.target.dataItem._dataContext.type == "Complied" ? ev.target.dataItem._dataContext.complied_count
                                    : ev.target.dataItem._dataContext.type == "Not Complied" ? ev.target.dataItem._dataContext.not_complied_count
                                        : ev.target.dataItem._dataContext.type == "Delayed Compliance" ? ev.target.dataItem._dataContext.delayed_compliance_count : 0)
                            console.log(ev.target.dataItem, ' ev.target.dataItem.component.name');
                            console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                            const payload = [

                                authtoken,

                                {

                                    "session_token": authtoken,

                                    "request": [
                                        "GetComplianceStatusDrillDownData",
                                        {
                                            "d_ids": [
                                                commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                            ],
                                            "from_date": null,
                                            "to_date": null,
                                            "filter_type": ((fieldType == "LegalEntity" && go == true) ? `LegalEntity` : (
                                                (fieldType == "Division" && go == true) ? `Division` : (
                                                    (fieldType == "Category" && go == true) ? "Category" : (
                                                        (fieldType == "BusinessGroup" && go == true) ? "BusinessGroup" : (
                                                            (fieldType == "Unit" && go == true) ? "Unit" : "Group"
                                                        )))
                                            )),
                                            "filter_id": ev.target.dataItem._dataContext.typeId,
                                            "compliance_status": ev.target.dataItem._dataContext.type,
                                            "chart_year": year,
                                            "record_count": 0,
                                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                            "country_domains": countryDomain,
                                            "criticality": criticaltityStatus === true ? criticality : ""
                                        }
                                    ]
                                }
                            ]
                            getCompliancestatusTable({
                                payload: payload,
                                paramid: paramid
                            })
                            setTable(true)
                        })

                        // var hoverState = series.columns.template.states.create("hover");
                        // hoverState.properties.fillOpacity = 1;
                        // hoverState.properties.tension = 0.8;

                        // chart.cursor = new am4charts.XYCursor();

                        // Add distinctive colors for each column using adapter
                        // series.columns.template.adapter.add("fill", function (fill, target) {
                        //     return chart.colors.getIndex(target.dataItem.index);
                        // });

                        // chart.scrollbarX = new am4core.Scrollbar();
                        chart.scrollbarY = new am4core.Scrollbar();
                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.filePrefix = "Exported Data";
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                        var title = chart.titles.create();
                        title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'

                        // fieldType == "LegalEntity" ? filterData.legalentity :
                        // fieldType == 'Division' ? Number(filterData.division) :
                        //     fieldType == 'Category' ? Number(filterData.category) :
                        //         fieldType == 'Unit' ? Number(filterData.unit) 

                        var title1 = chart.titles.create();
                        title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                            (fieldType == "Division" && go == true) ? `Division Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                (fieldType == "Category" && go == true) ? `Category Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                    (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                        (consolidated === true && go == true) ? `Consolidated Chart` : (
                                            (fieldType == "Unit" && go == true) ? `${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} Wise Compliance(2022)` : `Country Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${labelCountryName}[/]`
                                        ))))
                        ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `${textArray}`
                        // label1.text = ['hiii','hello']
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '5%'

                    }

                    // }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, multiSelect, filterChartName, multiSelectChart, timeZone, timeZoneData, localStorage.getItem('chart')])
    useEffect(() => {
        if (filterChartName == 'triangle') {
            if ((table == false)) {
                var result = _.omit(multiSelectChart, ['type', 'typeId']);
                const sumValues = result => Object.values(result).reduce((a, b) => a + b, 0);
                var total = sumValues(result)
                // var total = multiSelectChart.complied_count + multiSelectChart.delayed_compliance_count + multiSelectChart.not_complied_count + multiSelectChart.inprogress_compliance_count
                console.log(total, 'Avvvvvvvvs');
                if (multiSelect == true) {
                    if (multiSelectChart) {
                        console.log('testinggggg');

                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        // var container = am4core.create("container", am4core.Container);
                        // container.width = am4core.percent(100);
                        // container.height = am4core.percent(100);
                        // container.layout = "vertical";
                        // container.background.fill = am4core.color("#000");
                        // container.background.fillOpacity = 0.1;
                        // container.background.stroke = am4core.color("#000");
                        // container.background.strokeOpacity = 0.2;
                        // container.background.strokeWidth = 2;

                        // function addChart(data) {
                        //     var chart = container.createChild(am4charts.XYChart3D);
                        var chart = am4core.create("container", am4charts.XYChart3D);
                        chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

                        chart.data = [
                            (multiSelectChart && multiSelectChart.complied_count > 0 ?
                                {
                                    "type": "Complied",
                                    "count": multiSelectChart && multiSelectChart.complied_count
                                    ,
                                    "color": am4core.color("#0de580"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {})
                            ,
                            (multiSelectChart && multiSelectChart.delayed_compliance_count > 0 ?
                                {
                                    "type": "Delayed Compliance",
                                    "count": multiSelectChart && multiSelectChart.delayed_compliance_count,
                                    "color": am4core.color("#3c78d8"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                            (multiSelectChart && multiSelectChart.not_complied_count > 0 ?
                                {
                                    "type": "Not Complied",
                                    "count": multiSelectChart && multiSelectChart.not_complied_count,
                                    "color": am4core.color("#fe4365"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                            (multiSelectChart && multiSelectChart.inprogress_compliance_count > 0 ?
                                {
                                    "type": "Inprogress",
                                    "count": multiSelectChart && multiSelectChart.inprogress_compliance_count,
                                    "color": am4core.color("#f9c80e"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                        ]
                        chart.height = 500
                        // chart.colors.list = [
                        //     am4core.color("#0cce73"),
                        //     am4core.color("#f9c80e"),
                        //     am4core.color("#e53c5b"),
                        //     // am4core.color("#fe4365"),
                        // ];


                        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.renderer.grid.template.location = 0;
                        categoryAxis.dataFields.category = "type";
                        categoryAxis.renderer.minGridDistance = 40;

                        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                        var series = chart.series.push(new am4charts.CurvedColumnSeries());
                        series.dataFields.categoryX = "type";
                        series.dataFields.valueY = "count";
                        // series.tooltipText = "{valueY.value}"
                        series.columns.template.strokeOpacity = 0;
                        series.columns.template.tension = 1;
                        series.columns.template.propertyFields.fill = "color";
                        series.columns.template.fillOpacity = 0.75;
                        series.columns.template.maxHeight = 50
                        series.columns.template.tooltipText = `{type}:  ({valueY} out of ${total})`;


                        series.columns.template.events.on("hit", function (ev) {
                            console.log(ev.target.dataItem._dataContext.type, 'ev.target.dataItem');
                            setCategoryName(ev.target.dataItem._dataContext.type)
                            // setCount(1)
                            setFilterId(ev.target.dataItem._dataContext.typeId)
                            setCompliance(ev.target.dataItem._dataContext.type);
                            setShowMoreCount(0)
                            setDataCount(ev.target.dataItem._dataContext.type == "Inprogress" ? ev.target.dataItem._dataContext.inprogress_compliance_count
                                : ev.target.dataItem._dataContext.type == "Complied" ? ev.target.dataItem._dataContext.complied_count
                                    : ev.target.dataItem._dataContext.type == "Not Complied" ? ev.target.dataItem._dataContext.not_complied_count
                                        : ev.target.dataItem._dataContext.type == "Delayed Compliance" ? ev.target.dataItem._dataContext.delayed_compliance_count : 0)
                            console.log(ev.target.dataItem, ' ev.target.dataItem.component.name');
                            console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                            const payload = [

                                authtoken,

                                {

                                    "session_token": authtoken,

                                    "request": [
                                        "GetComplianceStatusDrillDownData",
                                        {
                                            "d_ids": [
                                                commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                            ],
                                            "from_date": null,
                                            "to_date": null,
                                            "filter_type": ((fieldType == "LegalEntity" && go == true) ? `LegalEntity` : (
                                                (fieldType == "Division" && go == true) ? `Division` : (
                                                    (fieldType == "Category" && go == true) ? "Category" : (
                                                        (fieldType == "BusinessGroup" && go == true) ? "BusinessGroup" : (
                                                            (fieldType == "Unit" && go == true) ? "Unit" : "Group"
                                                        )))
                                            )),
                                            "filter_id": ev.target.dataItem._dataContext.typeId,
                                            "compliance_status": ev.target.dataItem._dataContext.type,
                                            "chart_year": year,
                                            "record_count": 0,
                                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                            "country_domains": countryDomain,
                                            "criticality": criticaltityStatus === true ? criticality : ""
                                        }
                                    ]
                                }
                            ]
                            getCompliancestatusTable({
                                payload: payload,
                                paramid: paramid
                            })
                            setTable(true)
                        })

                        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                        labelBullet.label.text = "{valueY}";
                        labelBullet.locationX = 0.5;
                        labelBullet.locationY = 0.5;
                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.filePrefix = "Exported Data";
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                        var title = chart.titles.create();
                        title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'

                        // fieldType == "LegalEntity" ? filterData.legalentity :
                        // fieldType == 'Division' ? Number(filterData.division) :
                        //     fieldType == 'Category' ? Number(filterData.category) :
                        //         fieldType == 'Unit' ? Number(filterData.unit) 

                        var title1 = chart.titles.create();
                        title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                            (fieldType == "Division" && go == true) ? `Division Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                (fieldType == "Category" && go == true) ? `Category Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                    (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                        (consolidated === true && go == true) ? `Consolidated Chart` : (
                                            (fieldType == "Unit" && go == true) ? `${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} Wise Compliance(2022)` : `Country Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${labelCountryName}[/]`
                                        ))))
                        ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `${textArray}`
                        // label1.text = ['hiii','hello']
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '5%'

                        // var hoverState = series.columns.template.states.create("hover");
                        // hoverState.properties.fillOpacity = 1;
                        // hoverState.properties.tension = 0.8;

                        // chart.cursor = new am4charts.XYCursor();

                        // Add distinctive colors for each column using adapter
                        // series.columns.template.adapter.add("fill", function (fill, target) {
                        //     return chart.colors.getIndex(target.dataItem.index);
                        // });

                        // chart.scrollbarX = new am4core.Scrollbar();
                        chart.scrollbarY = new am4core.Scrollbar();

                        // }

                    }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, multiSelect, filterChartName, multiSelectChart, timeZone, timeZoneData, localStorage.getItem('chart')])
    useEffect(() => {
        if (filterChartName == 'cylinder') {
            if ((table == false)) {
                var result = _.omit(multiSelectChart, ['type', 'typeId']);
                const sumValues = result => Object.values(result).reduce((a, b) => a + b, 0);
                var total = sumValues(result)
                // var total = multiSelectChart.complied_count + multiSelectChart.delayed_compliance_count + multiSelectChart.not_complied_count + multiSelectChart.inprogress_compliance_count
                console.log(total, 'Avvvvvvvvs');
                if (multiSelect == true) {
                    if (multiSelectChart) {
                        console.log('testinggggg');

                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        // var container = am4core.create("container", am4core.Container);
                        // container.width = am4core.percent(100);
                        // container.height = am4core.percent(100);
                        // container.layout = "vertical";
                        // container.background.fill = am4core.color("#000");
                        // container.background.fillOpacity = 0.1;
                        // container.background.stroke = am4core.color("#000");
                        // container.background.strokeOpacity = 0.2;
                        // container.background.strokeWidth = 2;
                        // function addChart(data) {

                        //     // Themes end

                        //     // Create chart instance
                        //     var chart = container.createChild(am4charts.XYChart3D);
                        var chart = am4core.create("container", am4charts.XYChart3D);
                        chart.paddingBottom = 30;
                        chart.angle = 35;

                        // Add data
                        chart.data = [
                            (multiSelectChart && multiSelectChart.complied_count > 0 ?
                                {
                                    "type": "Complied",
                                    "count": multiSelectChart && multiSelectChart.complied_count
                                    ,
                                    "color": am4core.color("#0de580"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {})
                            ,
                            (multiSelectChart && multiSelectChart.delayed_compliance_count > 0 ?
                                {
                                    "type": "Delayed Compliance",
                                    "count": multiSelectChart && multiSelectChart.delayed_compliance_count,
                                    "color": am4core.color("#3c78d8"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                            (multiSelectChart && multiSelectChart.not_complied_count > 0 ?
                                {
                                    "type": "Not Complied",
                                    "count": multiSelectChart && multiSelectChart.not_complied_count,
                                    "color": am4core.color("#fe4365"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                            (multiSelectChart && multiSelectChart.inprogress_compliance_count > 0 ?
                                {
                                    "type": "Inprogress",
                                    "count": multiSelectChart && multiSelectChart.inprogress_compliance_count,
                                    "color": am4core.color("#f9c80e"),
                                    "typeId": multiSelectChart && multiSelectChart.typeId
                                } : {}),
                        ]
                        chart.height = 500
                        chart.colors.list = [
                            am4core.color("#0cce73"),
                            am4core.color("#f9c80e"),
                            am4core.color("#e53c5b"),
                            // am4core.color("#fe4365"),
                        ];

                        // Create axes
                        // var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                        // categoryAxis.dataFields.category = "country";
                        // categoryAxis.renderer.grid.template.location = 0;
                        // categoryAxis.renderer.minGridDistance = 20;
                        // categoryAxis.renderer.inside = true;
                        // categoryAxis.renderer.grid.template.disabled = true;

                        // let labelTemplate = categoryAxis.renderer.labels.template;
                        // labelTemplate.rotation = -90;
                        // labelTemplate.horizontalCenter = "left";
                        // labelTemplate.verticalCenter = "middle";
                        // labelTemplate.dy = 10; // moves it a bit down;
                        // labelTemplate.inside = false; // this is done to avoid settings which are not suitable when label is rotated

                        // var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.renderer.grid.template.location = 0;
                        categoryAxis.dataFields.category = "type";
                        categoryAxis.renderer.minGridDistance = 40;

                        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                        // valueAxis.renderer.grid.template.disabled = true;

                        // Create series
                        var series = chart.series.push(new am4charts.ConeSeries());
                        series.dataFields.valueY = "count";
                        series.dataFields.categoryX = "type";
                        series.columns.template.strokeOpacity = false
                        series.columns.template.propertyFields.fill = "color";
                        // series.columns.template.maxHeight = 50
                        series.columns.template.tooltipText = `{type}:  ({valueY} out of ${total})`;
                        // var columnTemplate = series.columns.template;
                        // // columnTemplate.adapter.add("fill", function (fill, target) {
                        // //     return chart.colors.getIndex(target.dataItem.index);
                        // // })


                        // columnTemplate.adapter.add("stroke", function (stroke, target) {
                        //     return chart.colors.getIndex(target.dataItem.index);
                        // })

                        series.columns.template.events.on("hit", function (ev) {
                            console.log(ev.target.dataItem._dataContext.type, 'ev.target.dataItem');
                            setFilterId(ev.target.dataItem._dataContext.typeId)
                            setCategoryName(ev.target.dataItem._dataContext.type)
                            // setCount(1)
                            setCompliance(ev.target.dataItem._dataContext.type);
                            setShowMoreCount(0)
                            setDataCount(ev.target.dataItem._dataContext.type == "Inprogress" ? ev.target.dataItem._dataContext.inprogress_compliance_count
                                : ev.target.dataItem._dataContext.type == "Complied" ? ev.target.dataItem._dataContext.complied_count
                                    : ev.target.dataItem._dataContext.type == "Not Complied" ? ev.target.dataItem._dataContext.not_complied_count
                                        : ev.target.dataItem._dataContext.type == "Delayed Compliance" ? ev.target.dataItem._dataContext.delayed_compliance_count : 0)
                            console.log(ev.target.dataItem, ' ev.target.dataItem.component.name');
                            console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                            const payload = [

                                authtoken,

                                {

                                    "session_token": authtoken,

                                    "request": [
                                        "GetComplianceStatusDrillDownData",
                                        {
                                            "d_ids": [
                                                commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                            ],
                                            "from_date": null,
                                            "to_date": null,
                                            "filter_type": ((fieldType == "LegalEntity" && go == true) ? `LegalEntity` : (
                                                (fieldType == "Division" && go == true) ? `Division` : (
                                                    (fieldType == "Category" && go == true) ? "Category" : (
                                                        (fieldType == "BusinessGroup" && go == true) ? "BusinessGroup" : (
                                                            (fieldType == "Unit" && go == true) ? "Unit" : "Group"
                                                        )))
                                            )),
                                            "filter_id": ev.target.dataItem._dataContext.typeId,
                                            "compliance_status": ev.target.dataItem._dataContext.type,
                                            "chart_year": year,
                                            "record_count": 0,
                                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                            "country_domains": countryDomain,
                                            "criticality": criticaltityStatus === true ? criticality : ""
                                        }
                                    ]
                                }
                            ]
                            getCompliancestatusTable({
                                payload: payload,
                                paramid: paramid
                            })
                            setTable(true)
                        })

                        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                        labelBullet.label.text = "{valueY}";
                        labelBullet.locationX = 0.5;
                        labelBullet.locationY = 0.5;
                        labelBullet.label.truncate = false;
                        labelBullet.label.hideOversized = false;

                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.filePrefix = "Exported Data";
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                        var title = chart.titles.create();
                        title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'

                        // fieldType == "LegalEntity" ? filterData.legalentity :
                        // fieldType == 'Division' ? Number(filterData.division) :
                        //     fieldType == 'Category' ? Number(filterData.category) :
                        //         fieldType == 'Unit' ? Number(filterData.unit) 

                        var title1 = chart.titles.create();
                        title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                            (fieldType == "Division" && go == true) ? `Division Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                (fieldType == "Category" && go == true) ? `Category Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                    (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${multiSelectChart && multiSelectChart.type}[/]` : (
                                        (consolidated === true && go == true) ? `Consolidated Chart` : (
                                            (fieldType == "Unit" && go == true) ? `${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} Wise Compliance(2022)` : `Country Wise Compliance (${dateState === false ? year : `${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`}) - [bold]${labelCountryName}[/]`
                                        ))))
                        ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.marginTop = "13%"
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `${textArray}`
                        // label1.text = ['hiii','hello']
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '2%'
                        // label1.marginTop = '5%'

                    }


                    // }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, multiSelect, filterChartName, multiSelectChart, timeZone, timeZoneData, localStorage.getItem('chart')])


    // useEffect(()=>{
    //     if((open === true) && (go === false)){
    //         setSpinnerTrue(true)
    //     }
    //     else if((open === false) && (go === false)){
    //         setSpinnerTrue(true)

    //     }
    //     else{
    //         setSpinnerTrue(false)
    //     }
    // },[open,go])

    return (
        <div id='page-wrapper' className="page-wrapper">
            <div className="back-to-top">
                <ScrollButton />
            </div>

            <div className="page-titles py-1 page-title-sticky">
                <div className="row">
                    {/* <div className="col-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span to="/dashboard"><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Dashboard</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Compliance Status Chart</span>
                                </li>
                            </ol>
                        </nav>

                    </div> */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Dashboard</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ fontSize: '16px' }}>Compliance Status</span>
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div
                        className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex align-items-center justify-content-md-end justify-content-sm-start">
                        {/* <div> <Tooltip title="" color='#1890ff'>
        <Button type="primary" shape="round" className='addbutton'

            icon={<PlusCircleOutlined />} size='default' onClick={() => {
                setShowList(true)
                setAddType('add')
            }}>
            Add
        </Button>

    </Tooltip></div> */}


                        {table == false ? <Button icon={<FilterOutlined />} type="primary" className='addbutton' style={{ background: "#0096FF", marginRight: '3%', borderRadius: '15px' }} onClick={() => {
                            setOpen(true)
                            setGo(false)

                        }}
                            size='default' >
                            <Tooltip placement='top' title='Filter'>Filter</Tooltip>
                        </Button> : <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", marginRight: '3%' }}
                            icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                setTable(false)
                                setTableData([])
                                setCountValue(0)
                                setSearchingValue([])
                            }}>
                            Back
                        </Button>}




                        <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black  full-mode fullscreenradius" >
                            <Tooltip placement='left' title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                        </button>

                        {/* <button onClick={() => {
            setShowList(true)
            setAddType('add')
        }} className="icons twitter" style={{ border: 'none', margin: '0px' }}>
            <span className="tooltips" style={{ right: '105%' }}>Add</span>
            <span><i id={localStorage.getItem('currentTheme')}><PlusCircleOutlined /></i></span>
        </button> */}


                    </div>
                </div>
            </div>
            {/* { table == false ?
            <div className="accordion mb-1" id="accordionExample" style={{marginLeft:'21px',marginRight:'23px'}}>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button bg-white text-primary border-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                        >
                            Compliance Status Counts
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">

                            <div className="row">
                                <div className="col-md-3">

                                    <div className="card w-100 overflow-hidden card-hover">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <h3 className="pt-1 mb-0 h1">
                                                        1260
                                                    </h3>
                                                    <h6 className="text-muted mb-0 fw-normal">Not Complied</h6>
                                                </div>
                                                <div className="ms-auto">
                                                    <span
                                                        className=" btn btn-xl btn-light-danger text-danger btn-circle
                                      d-flex align-items-center justify-content-center" >
                                                        <i data-feather="alert-circle"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="monthly-sales"></div>
                                    </div>

                                </div>
                                <div className="col-md-3">
                                    <div className="card w-100 overflow-hidden card-hover">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <h3 className="pt-1 mb-0 h1">
                                                        21
                                                    </h3>
                                                    <h6 className="text-muted mb-0 fw-normal">Inprogress</h6>
                                                </div>
                                                <div className="ms-auto">
                                                    <span
                                                        className="
                                  btn btn-xl btn-light-info
                                  text-info
                                  btn-circle
                                  d-flex
                                  align-items-center
                                  justify-content-center
                                "
                                                    >
                                                        <i data-feather="check-square"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="monthly-sales1"></div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <a href="../main/compliance-approval.html"><div className="card w-100 overflow-hidden card-hover">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <h3 className="pt-1 mb-0 h1">
                                                        56
                                                    </h3>
                                                    <h6 className="text-muted mb-0 fw-normal">Action Pending</h6>
                                                </div>
                                                <div className="ms-auto">
                                                    <span
                                                        className="
                                  btn btn-xl btn-light-warning
                                  text-warning
                                  btn-circle
                                  d-flex
                                  align-items-center
                                  justify-content-center
                                "
                                                    >
                                                        <i data-feather="folder-minus"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="monthly-sales4"></div>
                                    </div></a>
                                </div>
                                <div className="col-md-3">
                                    <a href="../main/compliance-approval.html"><div className="card w-100 overflow-hidden card-hover">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <h3 className="pt-1 mb-0 h1">
                                                        125
                                                    </h3>
                                                    <h6 className="text-muted mb-0 fw-normal fs-2">Approver Pending</h6>
                                                </div>
                                                <div className="ms-auto">
                                                    <span
                                                        className="
                                  btn btn-xl btn-light-secondary
                                  text-secondary
                                  btn-circle
                                  d-flex
                                  align-items-center
                                  justify-content-center
                                "
                                                    >
                                                        <i data-feather="user-minus"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="monthly-sales3"></div>
                                    </div></a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div> : ''} */}
            {
                table == true ?
                    <div className="container-fluid pt-1">
                        <div className='col-md-12 mt-0'>
                            <div className='row'>

                                {fieldType === '' && go === false ?
                                    criticaltityStatus === false ?
                                        <div className="col-md-4">
                                            <p><b>{`Compliance - Country : India, Status : ${compliance}`}</b></p>
                                        </div> :
                                        <div className="col-md-4">
                                            <p><b>{`Criticality ${criticality}- Country : India, Status : ${compliance}`}</b></p>
                                        </div>
                                    :
                                    criticaltityStatus === false ?
                                        <div className="col-md-4">
                                            <p><b>{`Compliance-${fieldType === 'BusinessGroup' ? 'Business Group' : fieldType === 'LegalEntity' ? 'Legal Entity' : fieldType} : ${categoryName},Status : ${compliance}`}</b></p>
                                        </div> :
                                        <div className="col-md-4">
                                            <p><b>{`Criticality ${criticality}-${fieldType === 'BusinessGroup' ? 'Business Group' : fieldType === 'LegalEntity' ? 'Legal Entity' : fieldType} : ${categoryName},Status : ${compliance}`}</b></p>
                                        </div>
                                }
                                <div className="col-md-4">

                                </div>
                                <div className="col-md-4">
                                    {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '70%' }}
                                        icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                            setTable(false)
                                        }}>
                                        Back
                                    </Button> */}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="container-fluid pt-0">
                                <div
                                    className={
                                        localStorage.getItem("currentTheme") +
                                        " ant-table-wrapper"
                                    }
                                    style={{ width: '100%' }}
                                >
                                    <div className="row">

                                        <div className="ant-table ant-table-bordered ant-table-fixed-header service-provider-table"
                                            //  id='service-table-action'
                                            style={{ width: '100%', height: '100%' }}>
                                            <div className="ant-table-container">
                                                <div className="ant-table-content taskrecalltable service-table">

                                                    <table className="table-fixed" >
                                                        <thead className="ant-table-thead" id='comp-approv-thead'
                                                        // style={{ top: is_Fullscreen ? (showValueModal == true ? 0 : '35px') : (showValueModal == true ? 0 : '107px') }}
                                                        >
                                                            <tr>
                                                                <th
                                                                    className="ant-table-cell"
                                                                    style={{ width: "32px" }}
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p
                                                                            className={"p-0 mb-0"}
                                                                            style={{
                                                                                // marginLeft: "36%",
                                                                                display: "inline",
                                                                                // color: searchingValue['compliance_task'] ? 'red' : ''
                                                                            }}
                                                                        >
                                                                            #
                                                                        </p>{" "}
                                                                    </div>

                                                                </th>
                                                                {(fieldType === 'BusinessGroup') || (fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "100px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue['business_group'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Business Group
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef1);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef1}
                                                                                            placeholder="Business Group"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    bgGroup: e.target.value
                                                                                                });
                                                                                                searchingValue['business_group'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue.bgGroup || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter(
                                                                                                    e.target.value,
                                                                                                    "business_group",
                                                                                                    "business_group"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef1.current.value = "";
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                bgGroup: ''
                                                                                            });
                                                                                            searchingValue['business_group'] = ''
                                                                                            compliancetaskfilter(
                                                                                                '',
                                                                                                "business_group",
                                                                                                "business_group"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                }
                                                                {(fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "80px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue['legal_entity'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Legal Entity
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef2);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef2}
                                                                                            placeholder="Legal Entity"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    legal_entity: e.target.value
                                                                                                });
                                                                                                searchingValue['legal_entity'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue.legal_entity || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter(
                                                                                                    e.target.value,
                                                                                                    "legal_entity",
                                                                                                    "legal_entity"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef2.current.value = "";
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                legal_entity: ''
                                                                                            });
                                                                                            searchingValue['legal_entity'] = ''
                                                                                            compliancetaskfilter(
                                                                                                '',
                                                                                                "legal_entity",
                                                                                                "legal_entity"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                }
                                                                {(fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "80px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue['division'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Division
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef3);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef3}
                                                                                            placeholder="Division"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    division: e.target.value
                                                                                                });
                                                                                                searchingValue['division'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue.division || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter(
                                                                                                    e.target.value,
                                                                                                    "division",
                                                                                                    "division"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef3.current.value = "";
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                division: ''
                                                                                            });
                                                                                            searchingValue['division'] = ''
                                                                                            compliancetaskfilter(
                                                                                                '',
                                                                                                "division",
                                                                                                "division"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>}

                                                                <th
                                                                    className="ant-table-cell"
                                                                    style={{ width: "180px" }}
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p
                                                                            className={"p-0 mb-0"}
                                                                            style={{
                                                                                // marginLeft: "36%",
                                                                                display: "inline",
                                                                                color: searchingValue['compliance'] ? 'red' : ''
                                                                            }}
                                                                        >
                                                                            Compliance
                                                                        </p>{" "}
                                                                        <Popconfirm
                                                                            afterVisibleChange={() => {
                                                                                setFocus(myRef4);
                                                                            }}
                                                                            placement="bottom"
                                                                            title={
                                                                                // <Input
                                                                                //     ref={myRef1}
                                                                                //     autoFocus={'on'}
                                                                                //     placeholder="Compliance Tasks"
                                                                                //     onKeyUp={(e) => {
                                                                                //         compliancetaskfilter(
                                                                                //             e.target.value,
                                                                                //             "Compliance_Task"
                                                                                //         );
                                                                                //     }}
                                                                                // />
                                                                                <div className='position-relative'>
                                                                                    <Input
                                                                                        ref={myRef4}
                                                                                        placeholder="Compliance"
                                                                                        autoFocus={'on'}
                                                                                        onChange={(e) => {
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                compliance: e.target.value
                                                                                            });
                                                                                            searchingValue['compliance'] = e.target.value
                                                                                        }}
                                                                                        value={searchedValue.compliance || undefined}
                                                                                        onKeyUp={(e) => {
                                                                                            compliancetaskfilter(
                                                                                                e.target.value,
                                                                                                "compliance",
                                                                                                "compliance"
                                                                                            );

                                                                                        }}
                                                                                    />
                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                        myRef4.current.value = "";
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            compliance: ''
                                                                                        });
                                                                                        searchingValue['compliance'] = ''
                                                                                        compliancetaskfilter(
                                                                                            '',
                                                                                            "compliance",
                                                                                            "compliance"

                                                                                        );

                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <SearchOutlined
                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                            />
                                                                        </Popconfirm>
                                                                    </div>

                                                                </th>
                                                                <th
                                                                    className="ant-table-cell"
                                                                    style={{ width: "80px" }}
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p
                                                                            className={"p-0 mb-0"}
                                                                            style={{
                                                                                // marginLeft: "36%",
                                                                                display: "inline",
                                                                                color: searchingValue['criticality'] ? 'red' : ''
                                                                            }}
                                                                        >
                                                                            Criticality
                                                                        </p>{" "}
                                                                        <Popconfirm
                                                                            afterVisibleChange={() => {
                                                                                setFocus(myRef5);
                                                                            }}
                                                                            placement="bottom"
                                                                            title={
                                                                                // <Input
                                                                                //     ref={myRef1}
                                                                                //     autoFocus={'on'}
                                                                                //     placeholder="Compliance Tasks"
                                                                                //     onKeyUp={(e) => {
                                                                                //         compliancetaskfilter(
                                                                                //             e.target.value,
                                                                                //             "Compliance_Task"
                                                                                //         );
                                                                                //     }}
                                                                                // />
                                                                                <div className='position-relative'>
                                                                                    <Input
                                                                                        ref={myRef5}
                                                                                        placeholder="Criticality"
                                                                                        autoFocus={'on'}
                                                                                        onChange={(e) => {
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                criticality: e.target.value
                                                                                            });
                                                                                            searchingValue['criticality'] = e.target.value
                                                                                        }}
                                                                                        value={searchedValue.criticality || undefined}
                                                                                        onKeyUp={(e) => {
                                                                                            compliancetaskfilter(
                                                                                                e.target.value,
                                                                                                "criticality",
                                                                                                "criticality"
                                                                                            );

                                                                                        }}
                                                                                    />
                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                        myRef5.current.value = "";
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            criticality: ''
                                                                                        });
                                                                                        searchingValue['criticality'] = ''
                                                                                        compliancetaskfilter(
                                                                                            '',
                                                                                            "criticality",
                                                                                            "criticality"

                                                                                        );

                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <SearchOutlined
                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                            />
                                                                        </Popconfirm>
                                                                    </div>

                                                                </th>
                                                                <th
                                                                    className="ant-table-cell"
                                                                    style={{ width: "80px" }}
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p
                                                                            className={"p-0 mb-0"}
                                                                            style={{
                                                                                // marginLeft: "36%",
                                                                                display: "inline",
                                                                                color: searchingValue['assignedTo'] ? 'red' : ''
                                                                            }}
                                                                        >
                                                                            Assigned To
                                                                        </p>{" "}
                                                                        <Popconfirm
                                                                            afterVisibleChange={() => {
                                                                                setFocus(myRef6);
                                                                            }}
                                                                            placement="bottom"
                                                                            title={
                                                                                // <Input
                                                                                //     ref={myRef1}
                                                                                //     autoFocus={'on'}
                                                                                //     placeholder="Compliance Tasks"
                                                                                //     onKeyUp={(e) => {
                                                                                //         compliancetaskfilter(
                                                                                //             e.target.value,
                                                                                //             "Compliance_Task"
                                                                                //         );
                                                                                //     }}
                                                                                // />
                                                                                <div className='position-relative'>
                                                                                    <Input
                                                                                        ref={myRef6}
                                                                                        placeholder="Assigned To"
                                                                                        autoFocus={'on'}
                                                                                        onChange={(e) => {
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                assignedTo: e.target.value
                                                                                            });
                                                                                            searchingValue['assignedTo'] = e.target.value
                                                                                        }}
                                                                                        value={searchedValue.assignedTo || undefined}
                                                                                        onKeyUp={(e) => {
                                                                                            compliancetaskfilter(
                                                                                                e.target.value,
                                                                                                "assignedTo",
                                                                                                "assignedTo"
                                                                                            );

                                                                                        }}
                                                                                    />
                                                                                    <CloseCircleOutlined onClick={() => {
                                                                                        myRef6.current.value = "";
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            assignedTo: ''
                                                                                        });
                                                                                        searchingValue['assignedTo'] = ''
                                                                                        compliancetaskfilter(
                                                                                            '',
                                                                                            "assignedTo",
                                                                                            "assignedTo"

                                                                                        );

                                                                                    }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <SearchOutlined
                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                            />
                                                                        </Popconfirm>
                                                                    </div>

                                                                </th>
                                                                {compliance === 'Complied' ? '' :
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "80px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue['overdue'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                {compliance === 'Delayed Compliance' ? 'Delayed By' : compliance === 'Not Complied' ? 'Over Due' : 'Days Left to Complete'}
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef7);
                                                                                }}
                                                                                placement="bottomLeft"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef7}
                                                                                            placeholder="Over Due"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    overdue: e.target.value
                                                                                                });
                                                                                                searchingValue['overdue'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue.overdue || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter(
                                                                                                    e.target.value,
                                                                                                    "overdue",
                                                                                                    "overdue"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef7.current.value = "";
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                overdue: ''
                                                                                            });
                                                                                            searchingValue['overdue'] = ''
                                                                                            compliancetaskfilter(
                                                                                                '',
                                                                                                "overdue",
                                                                                                "overdue"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tr>
                                                            <td colSpan={8}>
                                                                <Collapse defaultActiveKey={['0']}>
                                                                    {tempcriticalityTable && tempcriticalityTable.length > 0 &&
                                                                        tempcriticalityTable.map((items, i) => {
                                                                            // let unitName = items.drill_compliances
                                                                            // let keys = Object.keys(unitName);
                                                                            console.log(items, 'items5599');
                                                                            return (
                                                                                <Panel style={{ marginBottom: '5px' }} header={`${items[0][0].le_name}-${items[0][0].u_name}`}>
                                                                                    <table >
                                                                                        <tbody className="ant-table-tbody">
                                                                                            {items.map((item, p) => {
                                                                                                return (<>
                                                                                                    <tr className="ant-table-row ant-table-row-level-0">
                                                                                                        <td colSpan={8}>
                                                                                                            <h6 className='m-2 ml-2 mb-2'>{item[0].unitname}</h6>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    {item.map((childitem, m) => {
                                                                                                        return <tr className="ant-table-row ant-table-row-level-0">
                                                                                                            <td style={{ width: '55px' }}
                                                                                                                className="ant-table-cell border-end"><center>{childitem.index}</center></td>
                                                                                                            {(fieldType === 'BusinessGroup') || (fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                                                                <td style={{ width: '160px' }} className="ant-table-cell border-end">{childitem.bg_name}</td>}
                                                                                                            {(fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                                                                <td style={{ width: '133px' }}
                                                                                                                    className="ant-table-cell border-end">{childitem.le_name}</td>}
                                                                                                            {(fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                                                                <td style={{ width: '133px' }}
                                                                                                                    className="ant-table-cell border-end">{childitem.div_name}</td>}
                                                                                                            <td style={{ width: '299px' }}
                                                                                                                className="ant-table-cell border-end">
                                                                                                                <Tooltip title={
                                                                                                                    <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                                                                                                <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td style={{ border: '1px solid #636262' }}><center>{childitem.descp ? childitem.descp : '-'}</center></td>
                                                                                                                                <td style={{ border: '1px solid #636262' }}><center>{childitem.doc_ref_num ? childitem.doc_ref_num : '-'}</center></td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                }><InfoCircleFilled /></Tooltip>{childitem.remarks_count > 0 ? <a style={{ marginLeft: '5px' }} href="javascript:;"
                                                                                                                    onClick={() => {

                                                                                                                        setRemarksModalData({
                                                                                                                            ...remarksModalData,
                                                                                                                            le_id: childitem.le_id,
                                                                                                                            c_id: Number(childitem.c_id),
                                                                                                                            d_id: Number(childitem.d_id),
                                                                                                                            u_id: Number(childitem.u_id),
                                                                                                                            start_date: childitem.start_date
                                                                                                                        })
                                                                                                                        const payload = [
                                                                                                                            authtoken,
                                                                                                                            {
                                                                                                                                "session_token": authtoken,
                                                                                                                                "request": [
                                                                                                                                    "GetComplianceRemarksHistoryData",
                                                                                                                                    {
                                                                                                                                        "legal_entity_id": childitem.le_id,
                                                                                                                                        "unit_id": Number(childitem.u_id),
                                                                                                                                        "compliance_history_id": Number(childitem.compliance_history_id)
                                                                                                                                    }
                                                                                                                                ]
                                                                                                                            }
                                                                                                                        ]
                                                                                                                        getRemarksHistoryData({
                                                                                                                            payload: payload,
                                                                                                                            paramid: paramid
                                                                                                                        })
                                                                                                                        sethistorymodal(true);

                                                                                                                    }}
                                                                                                                ><Tooltip title='Click to View Remarks History'><InfoCircleFilled color='blue' /></Tooltip></a> : ''}
                                                                                                                {childitem.compfielaw_url != '' ? <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                                                                                    <a className='ml-3' style={{ marginLeft: '5px' }} href={`${childitem.compfielaw_url}`}>
                                                                                                                        <BookTwoTone />
                                                                                                                    </a>
                                                                                                                </Tooltip> : ''}
                                                                                                                <span style={{ marginLeft: '5px' }}>{childitem.comp_name}</span> </td>
                                                                                                            <td style={{ width: '143px' }}
                                                                                                                className="ant-table-cell border-end"><center>{childitem.criticality}</center></td>
                                                                                                            <td style={{ width: '134px' }}
                                                                                                                className="ant-table-cell border-end">{childitem.assignee_name}</td>
                                                                                                            {compliance === 'Complied' ? '' :
                                                                                                                <td style={{ width: '127px' }}
                                                                                                                    className="ant-table-cell border-end">{childitem.ageing}</td>}
                                                                                                        </tr>
                                                                                                    })}</>)
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                    {/* <Table
                                                                size={'small'} className='remark-header add-service-prv'
                                                                columns={columnState === true ? columns1 : columns}
                                                                dataSource={childDatas[i]}
                                                                bordered
                                                                scroll={{ x: 1000 }}
                                                                pagination={false}
                                                                onChange={handleChange}
                                                            /> */}

                                                                                    {/* </> */}
                                                                                    {/* ) */}
                                                                                    {/* // })} */}

                                                                                </Panel>
                                                                            )
                                                                        })
                                                                    }
                                                                </Collapse>
                                                            </td>
                                                        </tr>



                                                        {/* <Table dataSource={dataSource} columns={columns} pagination={false}
                    className='userprivclass' /> */}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {criticaltityStatus === true ?
                            dataCount <= showmoreCount ? false : <div className='col-md-12 mt-2'>
                                <div className='row'>
                                    <div className='col-md-4'></div>
                                    <div className='col-md-4'>
                                        <Button icon={<DownOutlined />} className="btn btn-success" style={{ float: "center", marginLeft: "20%", borderRadius: '15px' }} onClick={multiSelect === false ? showmore : showmore1} >Show more records</Button>
                                    </div>
                                    <div className='col-md-4'></div>
                                </div>
                            </div> :
                            dataCount <= showmoreCount ? false : <div className='col-md-12 mt-2'>
                                <div className='row'>
                                    <div className='col-md-4'></div>
                                    <div className='col-md-4'>
                                        <Button icon={<DownOutlined />} className="btn btn-success" style={{ float: "center", marginLeft: "20%", borderRadius: '15px' }} onClick={multiSelect === false ? showmore : showmore1} >Show more records</Button>
                                    </div>
                                    <div className='col-md-4'></div>
                                </div>
                            </div>
                        }

                        {/* <Table dataSource={dataSource} columns={columns} pagination={false}
                    className='userprivclass' /> */}
                    </div> : <div className="container-fluid pt-1">
                        <div className="row" >
                            <div className="col-md-12 mt-2">
                                <div className="row">

                                    {multiSelect == false ?
                                        <>
                                            <div className="col-md-2">
                                                {
                                                    criticaltityStatus == false ? <Select style={{ width: '100%' }} onChange={(data) => {
                                                        setChartType(data)
                                                    }} value={chartType}>
                                                        <Option key='count'>Count</Option>
                                                        <Option key='Percentage'>Percentage</Option>
                                                    </Select> : <Button type="primary" className='addbutton' style={{ borderRadius: '15px', marginLeft: '50%' }} onClick={() => {
                                                        setCriticalityStatus(false)
                                                        const payload = [
                                                            authtoken,
                                                            {
                                                                "session_token": authtoken,
                                                                "request":
                                                                    ["GetComplianceStatusChart",
                                                                        {
                                                                            "c_ids": cIds,
                                                                            "d_ids": domain.length > 0 ? domain : domainIds,
                                                                            "from_date": dateState === true ? moment(filterData.from_date).format('DD-MMM-YYYY') : null,
                                                                            "to_date": dateState === true ? moment(filterData.to_date).format('DD-MMM-YYYY') : null,
                                                                            "filter_type": ((fieldType != "") && (fieldType != 'Date')) ? fieldType : filterData.chartType,
                                                                            // filterData && filterData.chartType,
                                                                            "filter_ids":
                                                                                fieldType == "LegalEntity" ? Entity :
                                                                                    fieldType == 'Division' ? divisionDatas :
                                                                                        fieldType == 'Category' ? categoryData :
                                                                                            fieldType == 'Unit' ? unitData :
                                                                                                fieldType == 'BusinessGroup' ? businessIds :
                                                                                                    fieldType == 'Date' || consolidated === true || filterData.chartType === "Group" ? null :
                                                                                                        1
                                                                            ,
                                                                            "criticality": "",
                                                                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                                            "country_domains": countryDomain,
                                                                            "chart_year": year
                                                                        }
                                                                    ]
                                                            }
                                                        ]
                                                        getCompliancestatuschart({
                                                            payload: payload,
                                                            paramid: paramid
                                                        })
                                                    }}
                                                        size='default' >
                                                        Compliance Status
                                                    </Button>
                                                }

                                            </div>
                                            <div className="col-md-2">
                                                {criticaltityStatus == false ? <Select className='mb-sm-2' style={{ width: '100%' }} onChange={(data) => {
                                                    setChartName(data)
                                                }} value={chartName}>
                                                    <Option key='stacked'>3D Stacked Bar</Option>
                                                    <Option key='pie'>Column With Pie</Option>
                                                    <Option key='chord'>Chord Diagram</Option>
                                                </Select> : false}

                                            </div>
                                            <div className="col-md-2">
                                                {criticaltityStatus == false ?
                                                    <Button type="primary" className='addbutton' style={{ borderRadius: '15px' }} onClick={() => {
                                                        setCriticalityStatus(true)
                                                        setChartName("stacked")
                                                        setChartType("count")
                                                    }}
                                                        size='default' >
                                                        Criticality
                                                    </Button> : <Select style={{ width: '100%' }} value={criticality} onChange={(data) => {
                                                        setCriticalityStatus(true)
                                                        setCriticality(data)
                                                    }}>
                                                        <Option key='A'>A</Option>
                                                        <Option key='B'>B</Option>
                                                        <Option key='C'>C</Option>
                                                    </Select>}

                                            </div>
                                            <div className="col-md-6" style={{ textAlign: 'right' }}>
                                                {/* <Button icon={<FilterOutlined />} type="primary" className='addbutton' style={{ borderRadius: '15px' }} onClick={() => {
                                                    setOpen(true)
                                                    setFilterOpen(true)
                                                }}
                                                    size='default' >
                                                    Filter
                                                </Button> */}
                                            </div>
                                        </> :
                                        <>
                                            <div className="col-md-4">

                                            </div>
                                            <div className="col-md-2">

                                            </div>
                                            <div className="col-md-2">

                                            </div>
                                            <div className="col-md-2">
                                                <Select style={{ width: '100%' }}
                                                    onChange={(data) => {
                                                        setfilterChartName(data)
                                                    }}
                                                    value={filterChartName}
                                                >
                                                    <Option key='donut'>3D Donut</Option>
                                                    <Option key='3d'>3D Column</Option>
                                                    <Option key='bar'>3D  Bar chart</Option>
                                                    <Option key='curve'>Curved Column</Option>
                                                    <Option key='triangle'>Triangle Column</Option>
                                                    <Option key='cylinder'>Cylinder Column</Option>
                                                </Select>

                                            </div>
                                            {consolidated === true ? '' :
                                                <div className="col-md-2 d-none d-md-flex
align-items-center
justify-content-end">

                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#00FF7F", marginLeft: '70%' }}
                                                        icon={<ArrowLeftOutlined />} size='default'
                                                        onClick={() => {
                                                            setmultiSelect(false)
                                                        }}
                                                    >
                                                        Back
                                                    </Button>


                                                </div>
                                            }

                                        </>}
                                </div>
                            </div>
                            {/* {dataValue && dataValue.length > 0 ? */}
                            <>
                                {/* {((open === true) && (go === false)) ? <Card style={{ width: "100%", height: "590px" }}><Spin size='large' style={{ marginTop: '17%', marginLeft: '45%', marginBottom: '10%' }} /></Card> : */}
                                <div className="col-md-12 mt-1">

                                    {/* <div className="card">
                                            <div className='card-body'> */}
                                    {dataValue && dataValue.length > 0 ?
                                        <>
                                            {((open === true) && (go === false) && (fieldTypeClick != '')) ?

                                                <Card style={{ borderRadius: '15px' }}>
                                                    {/* <Spin size='large' style={{ marginTop: '17%', marginLeft: '45%', marginBottom: '10%' }} /> */}
                                                    <div id="container" key="container" style={{ width: "100%", height: chartName === 'stacked' ? "590px" : '550px' }}></div>
                                                </Card>
                                                :
                                                <Card style={{ borderRadius: '15px' }}>
                                                    <div id="container" key="container" style={{ width: "100%", height: chartName === 'stacked' ? "590px" : '550px' }}></div>
                                                </Card>
                                            }

                                            <div className="col-md-12" style={{ marginTop: '2%' }}>
                                                <div className="row">
                                                    <div className="col-md-6">

                                                    </div>
                                                    <div className="col-md-6 text-md-end text-lg-end">
                                                        <label htmlFor=""><b>Time Zone :</b></label>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            onChange={(data) => {
                                                                setTimeZone(data)
                                                                setGeneratedOn(true)
                                                                const payload = [
                                                                    authtoken,
                                                                    {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "GetDateTime_ByZone",
                                                                            {
                                                                                "tz_name": data
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                                getTimeZoneData({
                                                                    payload: payload,
                                                                    paramid: paramid
                                                                })

                                                            }}
                                                            placeholder="Enter Time Zone"
                                                            style={{ marginLeft: '5%' }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={compfie.user_Group}

                                                        >

                                                            {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

                                                                return (

                                                                    <Option key={item.tz_name}>

                                                                        {item.tz_name}

                                                                    </Option>

                                                                );

                                                            })}
                                                            {/* );
                                                })} */}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>

                                        </> :
                                        <Card style={{ borderRadius: '15px' }}>
                                            <div id="containerNoData" key="container" style={{ width: "100%", height: "590px" }}></div></Card>}




                                    {/* </div>
                                        </div> */}
                                </div>
                                {/* } */}
                            </>
                            {/* : <p style={{ marginTop: '18%', marginLeft: '40%', marginBottom: '18%' }}>No Data to Display </p>} */}
                            {/* {filterOpen == false && go == false ?
                                <div className="col-md-12 mt-3">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "25px" }}
                                                icon={<ArrowLeftOutlined />} size='default'
                                                onClick={() => {
                                                    setYear(year - 1)
                                                }}
                                            >
                                                Previous Year
                                            </Button>
                                        </div>
                                        <div className="col-md-5"></div>
                                        <div className="col-md-3"></div>

                                        <div className="col-md-2">
                                            {year != moment().year() ?
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", }}
                                                    size='default'
                                                    onClick={() => {
                                                        setYear(year + 1)
                                                    }}
                                                >
                                                    Next Year<ArrowRightOutlined />
                                                </Button>
                                                : false}
                                        </div>
                                    </div>
                                </div> :
                                filterOpen == true && go == true && dataValue && dataValue.length == 0 ?
                                    <div>
                                        <p>Date:{moment(filterData.from_date).format('DD-MMM-YYYY')} to {moment(filterData.to_date).format('DD-MMM-YYYY')}</p>
                                    </div> : ''
                            } */}
                            {((dateState === true) && (go === true)) || (multiSelect === true) ? '' :
                                <div className="col-md-12 mt-3">
                                    <div className="row">
                                        <div className="col-md-2">
                                            {year === prevYear ? '' :
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                    icon={<ArrowLeftOutlined />} size='default'
                                                    onClick={() => {
                                                        setYear(year - 1)
                                                    }}
                                                >
                                                    Previous Year
                                                </Button>}
                                        </div>
                                        <div className="col-md-5"></div>
                                        <div className="col-md-3"></div>

                                        <div className="col-md-2 d-none d-md-flex
align-items-center
justify-content-end">
                                            {year != moment().year() ?
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", }}
                                                    size='default'
                                                    onClick={() => {
                                                        setYear(year + 1)
                                                    }}
                                                >
                                                    Next Year<ArrowRightOutlined />
                                                </Button>
                                                : false}
                                        </div>
                                    </div>
                                </div>
                            }


                        </div>
                    </div>
            }

            <Drawer className={localStorage.getItem("currentTheme") + ' resize-drawer'} title="FILTER BY" placement="right" closable={false} onClose={false} open={open}
                extra={
                    <Space>
                        <CloseCircleOutlined style={{ marginTop: '2%', fontSize: '140%' }} onClick={onClose} />
                    </Space>
                }
            >
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4">
                            <label><b>Country</b></label>&nbsp;
                        </div>
                        <div className="col-md-8">
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                placeholder="Enter Country"
                                name="units"
                                id="units"
                                className='form-control'
                                onChange={(option) => CountryHandelChange(option)}
                                onClick={() => {
                                    setFieldTypeClick('Country')

                                }}
                                mode="multiple"
                                value={country}
                                maxTagCount='responsive'
                                allowClear={false}
                            >
                                {field && field.country &&
                                    field.country.length > 0 &&
                                    field.country.map((item, i) => {
                                        return (
                                            <Option key={item.c_id}>
                                                {item.c_name}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            <label><b>Domain</b></label>&nbsp;
                        </div>
                        <div className="col-md-8">
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                placeholder="Enter Domain"
                                name="units"
                                id="units"
                                className='form-control'
                                allowClear={false}
                                onChange={(option) => DomainHandleChane(option)}
                                onClick={() => {
                                    setFieldTypeClick('Domain')

                                }}
                                mode="multiple"
                                value={domain.length > 0 ? domainValue : undefined}
                                disabled={country.length > 0 ? false : true}
                                maxTagCount='responsive'
                            >
                                {domainDatas && domainDatas.length > 0 ? <>  <Option key="all" value="all" style={{ display: domainListData.length != domain.length ? 'block' : 'none' }}>---SELECT ALL---</Option>
                                    <Option key="unselect" value="unselect" style={{ display: domainListData.length === domain.length ? 'block' : 'none' }}>--UNSELECT ALL--</Option></> : false}
                                {domainListData && domainListData.length > 0 &&
                                    domainListData.map((finalList_unitlist) => {
                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                            return (
                                                <OptGroup label={finalList_unitlist.label}>
                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                        <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                    ))}
                                                </OptGroup>
                                            )
                                    })}

                            </Select>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-1">
                            <input type='checkbox' checked={checkBox === true ? true : false} onChange={(e) => {
                                setGroupCheckBox(false)
                                if (e.target.checked == true) {
                                    setCheckBox(true)
                                    setConsolidated(false)

                                }
                                else {
                                    setCheckBox(false)
                                    setConsolidated(false)

                                }
                            }}></input>
                        </div>
                        <div className="col-md-4">
                            <label><b>Group</b></label>
                        </div>
                        {/* <div className="col-md-2">

                        </div> */}
                        <div className="col-md-1">
                            <input type='checkbox' checked={consolidated === true ? true : false} onChange={(e) => {
                                if (e.target.checked == true) {
                                    setCheckBox(false)
                                    setConsolidated(true)
                                    setFilterData({
                                        ...filterData,
                                        chartType: "Consolidated"
                                    })
                                }
                                else {
                                    setConsolidated(false)

                                }
                            }}></input>
                        </div>
                        <div className="col-md-4">
                            <label><b>Consolidated</b></label>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">

                            <label><b>From Date</b></label>
                            <DatePicker
                                allowClear={() => {
                                    setFilterData({
                                        ...filterData,
                                        from_date: null,
                                    })
                                }}
                                getPopupContainer={trigger => trigger.parentNode}

                                value={filterData.from_date ? moment(filterData.from_date) : ''}
                                // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                // value={consolidatedData.from_date}
                                disabled={domain.length > 0 ? false : true}
                                onChange={(date, dateString) => {
                                    setDateState(true)
                                    setFieldType('Date')
                                    console.log(dateString, 'dateString');
                                    setFilterData({
                                        ...filterData,
                                        from_date: dateString,
                                        from_year: moment(dateString).year(),
                                        from_month: moment(dateString).month(),
                                        from_date_only: moment(dateString).date()
                                    })

                                }}



                            />

                        </div>
                        <div className="col-md-4">

                            <label><b>To Date</b></label>

                            <DatePicker
                                disabled={domain.length > 0 ? false : true}
                                // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                // value={consolidatedData.from_date}
                                // disabled={consolidatedData.domainy == '' ? true : false}
                                // onChange={(date, dateString) => {

                                //     console.log(dateString, 'dateString');
                                //     setConsolidatedData({
                                //         ...consolidatedData,
                                //         from_date: dateString,
                                //         to_date: moment(dateString).add(3, 'months')
                                //     })

                                // }}
                                getPopupContainer={trigger => trigger.parentNode}
                                value={filterData.to_date ? moment(filterData.to_date) : ''}
                                onChange={(date, dateString) => {
                                    setDateState(true)
                                    setFieldType('Date')
                                    if (dateString != undefined) {
                                        setFilterData({
                                            ...filterData,
                                            to_date: dateString,
                                        })
                                    }
                                    else {
                                        setFilterData({
                                            ...filterData,
                                            to_date: null,
                                        })
                                    }
                                }}
                                disabledDate={(current) => {
                                    console.log(current.date(), 'current.date()');
                                    console.log(moment(current).date(), 'currrrrr');
                                    console.log(current.year(), 'coreectCuurentYear');
                                    console.log(current.month(), 'month');
                                    console.log(current.date(), 'date');
                                    console.log(current, 'current');
                                    return moment(current) < moment(filterData.from_date)
                                }}




                            />

                        </div>
                        <div className="col-md-4">
                            <Button type="primary" className='addbutton' style={{ background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', marginLeft: '0px', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white', borderRadius: '15px', marginLeft: '5%', marginTop: '13%' }} disabled={dateState === false ? true : false}
                                icon={<CloseCircleOutlined />}
                                onClick={() => {
                                    setFilterData({
                                        ...filterData,
                                        from_date: null,
                                        to_date: null
                                    })
                                    setDateState(false)
                                }}
                                size='default' >
                                Clear
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            <label style={{ color: fieldType == 'BusinessGroup' ? 'blue' : 'black' }}><b>Business Group</b></label>&nbsp;
                        </div>
                        <div className="col-md-8">
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                placeholder="Enter Business Group"
                                name="units"
                                id="units"
                                allowClear={true}
                                className='form-control'
                                onChange={(option) => BusinessHandelChange(option)}
                                onClick={() => {
                                    setFieldTypeClick('BusinessGroup')
                                    setConsolidated(false)
                                    setCheckBox(false)
                                    setFieldType('BusinessGroup')
                                    let temp = 'Business'
                                    trueState.push(temp)
                                }}
                                mode="multiple"
                                value={business}
                                maxTagCount='responsive'
                                disabled={domain.length > 0 ? false : true}
                                filterOption={(input, option) => {
                                    if (option.children) {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    } else if (option.label) {
                                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }
                                }}
                            >
                                {businessGroupData && businessGroupData.length > 0 ? <>  <Option key="all" value="all" style={{ display: businessGroupData.length != business.length ? 'block' : 'none' }}>---SELECT ALL---</Option>
                                    <Option key="unselect" value="unselect" style={{ display: businessGroupData.length === business.length ? 'block' : 'none' }}>--UNSELECT ALL--</Option></> : false}
                                {businessGroupData && businessGroupData.length > 0 &&
                                    businessGroupData.map((item) => {
                                        return (

                                            <Option key={item.bg_id}>
                                                {item.bg_name}
                                            </Option>

                                        )

                                    }

                                    )}
                            </Select>
                            {/* <Select
                                allowClear={true}
                                size="default"
                                placeholder="Enter Business Group"
                                value={filterData.business}
                                onChange={(data) => {
                                    setFilterData({
                                        ...filterData,
                                        business: data
                                    })
                                }
                                }
                                style={{ width: '100%' }}
                            >
                                {field && field.business && field.business.length && field.business.map((item, i) => {
                                    return (
                                        <Option key={item.bg_id}>
                                            {item.bg_name}
                                        </Option>
                                    );
                                })}


                            </Select> */}

                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            <label style={{ color: fieldType == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
                        </div>
                        <div className="col-md-8">
                            {/* <Select
                                allowClear={true}
                                size="default"
                                placeholder="Enter Legal Entity"
                                value={filterData.legalentity}
                                onChange={(data) => {
                                    setFilterData({
                                        ...filterData,
                                        legalentity: data
                                    })
                                    setFieldType('LegalEntity')
                                    setGo(false)
                                }
                                }
                                style={{ width: '100%' }}
                            >

                                {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
                                    return (
                                        <Option key={item.le_id}>
                                            {item.le_name}
                                        </Option>
                                    );
                                })}

                            </Select> */}
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                name="units"
                                id="units"
                                className='form-control'
                                allowClear={true}
                                onChange={(option) => EntityHandleChane(option)}
                                onClick={() => {
                                    setFieldType('LegalEntity')
                                    setConsolidated(false)
                                    setCheckBox(false)
                                    setFieldTypeClick('LegalEntity')
                                    let temp = 'Legal'
                                    trueState.push(temp)
                                }}
                                mode="multiple"
                                value={Entity}
                                maxTagCount='responsive'
                                placeholder="Enter Entity"
                                disabled={domain.length > 0 ? false : true}
                                filterOption={(input, option) => {
                                    if (option.children) {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    } else if (option.label) {
                                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }
                                }}
                            >
                                {EntityDatas && EntityDatas.length > 1 ? <>  <Option key="all" value="all" style={{ display: EntityDatas.length != Entity.length ? 'block' : 'none' }}>---SELECT ALL---</Option>
                                    <Option key="unselect" value="unselect" style={{ display: EntityDatas.length === Entity.length ? 'block' : 'none' }}>--UNSELECT ALL--</Option></> : false}

                                {EntityDatas && EntityDatas.map((item, i) => {
                                    return (

                                        <Option key={item.le_id}>
                                            {item.le_name}
                                        </Option>

                                    );
                                })}


                            </Select>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            <label style={{ color: fieldType == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
                        </div>
                        <div className="col-md-8">
                            {/* <Select
                                allowClear={true}
                                size="default"
                                placeholder="Enter Division"
                                value={filterData.division}
                                onChange={(data) => {
                                    setFilterData({
                                        ...filterData,
                                        division: data
                                    })
                                    let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                    console.log(subData, 'subDatasubData');
                                    setCategory(subData)
                                    setFieldType('Division')
                                    setGo(false)
                                }
                                }
                                style={{ width: '100%' }}
                            >
                                {division && division.length && division.map((item, i) => {
                                    return (
                                        <Option key={item.div_id}>
                                            {item.div_name}
                                        </Option>
                                    );
                                })}


                            </Select> */}
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                name="units"
                                id="units"
                                className='form-control'
                                onChange={(option) => DivisionHandleChane(option)}
                                onClick={() => {
                                    setCoulmnState(true)
                                    setFieldTypeClick('Division')
                                    setConsolidated(false)
                                    setCheckBox(false)
                                    setFieldType('Division')
                                    let temp = 'Division'
                                    trueState.push(temp)
                                }}
                                mode="multiple"
                                value={divisionDatas}
                                allowClear={true}
                                maxTagCount='responsive'
                                placeholder="Enter Division"
                                disabled={domain.length > 0 ? false : true}
                                filterOption={(input, option) => {
                                    if (option.children) {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    } else if (option.label) {
                                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }
                                }}
                            >
                                {divisionList && divisionList.length > 0 ? <>  <Option key="all" value="all" style={{ display: divisionOptionList.length != divisionDatas.length ? 'block' : 'none' }}>---SELECT ALL---</Option>
                                    <Option key="unselect" value="unselect" style={{ display: divisionOptionList.length === divisionDatas.length ? 'block' : 'none' }}>--UNSELECT ALL--</Option></> : false}

                                {divisionList && divisionList.length > 0 &&
                                    divisionList.map((finalList_unitlist) => {
                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                            return (
                                                <OptGroup label={finalList_unitlist.label}>
                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                        <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                    ))}
                                                </OptGroup>
                                            )
                                    })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            <label style={{ color: fieldType == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
                        </div>
                        <div className="col-md-8">
                            {/* <Select
                                allowClear={true}
                                size="default"
                                placeholder="Enter Category"
                                value={filterData.category}
                                onChange={(data) => {
                                    setFilterData({
                                        ...filterData,
                                        category: data
                                    })
                                    let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
                                    setUnit(subData)
                                    setFieldType('Category')
                                    setGo(false)
                                }
                                }
                                style={{ width: '100%' }}
                            >
                                {category && category.length && category.map((item, i) => {
                                    return (
                                        <Option key={item.cat_id}>
                                            {item.cat_name}
                                        </Option>
                                    );
                                })}


                            </Select> */}
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                placeholder="Enter Category"
                                name="units"
                                id="units"
                                className='form-control'
                                onChange={(option) => CategoryHandleChange(option)}
                                onClick={() => {
                                    setFieldTypeClick('Category')
                                    setFieldType('Category')
                                    setConsolidated(false)
                                    setCheckBox(false)
                                    setCoulmnState(true)
                                    let temp = 'Category'
                                    trueState.push(temp)
                                }}
                                mode="multiple"
                                allowClear={true}
                                value={categoryData}
                                maxTagCount='responsive'
                                disabled={domain.length > 0 ? false : true}
                                filterOption={(input, option) => {
                                    if (option.children) {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    } else if (option.label) {
                                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }
                                }}
                            >
                                {categoryList && categoryList.length > 0 ? <>  <Option key="all" value="all" style={{ display: categoryOptionList.length != categoryData.length ? 'block' : 'none' }}>---SELECT ALL---</Option>
                                    <Option key="unselect" value="unselect" style={{ display: categoryOptionList.length === categoryData.length ? 'block' : 'none' }}>--UNSELECT ALL--</Option></> : false}

                                {categoryList && categoryList.length > 0 &&
                                    categoryList.map((finalList_unitlist) => {
                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                            return (
                                                <OptGroup label={finalList_unitlist.label}>
                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                        <Option value={subUnitData.cat_id}>{`${subUnitData.div_name}-${subUnitData.cat_name}`}</Option>

                                                    ))}
                                                </OptGroup>
                                            )
                                    })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            {localStorage.getItem('isVendor') == "true" ?
                                <label style={{ color: fieldTypeClick == 'Unit' ? 'blue' : 'black' }}><b>Vendor</b></label> :
                                <label style={{ color: fieldTypeClick == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>
                            }
                        </div>
                        <div className="col-md-8">
                            {/* <Select
                                allowClear={true}
                                size="default"
                                placeholder="Enter Unit"
                                value={filterData.unit}
                                onChange={(data) => {
                                    setFilterData({
                                        ...filterData,
                                        unit: data
                                    })
                                    setFieldType('Unit')
                                    setGo(false)
                                }
                                }
                                style={{ width: '100%' }}
                            >
                                {unit && unit.length > 0 && unit.map((item, i) => {
                                    return (
                                        <Option key={item.u_id}>
                                            {item.u_name}
                                        </Option>
                                    );
                                })}


                            </Select> */}

                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                name="units"
                                id="units"
                                className='form-control'
                                placeholder={localStorage.getItem('isVendor') == "true" ? "Enter Vendor" : "Enter Unit"}
                                onChange={(option) => UnitHandleChane(option)}
                                onClick={() => {
                                    setFieldTypeClick('Unit')
                                    setCoulmnState(true)
                                    setConsolidated(false)
                                    setFieldType('Unit')
                                    setCheckBox(false)
                                    let temp = 'Unit'
                                    trueState.push(temp)
                                }}
                                mode="multiple"
                                value={unitData}
                                allowClear={true}
                                maxTagCount='responsive'
                                disabled={domain.length > 0 ? false : true}
                                filterOption={(input, option) => {
                                    if (option.children) {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    } else if (option.label) {
                                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }
                                }}
                            >
                                {unitList && unitList.length > 0 ?
                                    <>  <Option key="all" value="all" style={{ display: unitListOptionLength.length != unitData.length ? 'block' : 'none' }}>---SELECT ALL---</Option>
                                        <Option key="unselect" value="unselect" style={{ display: unitListOptionLength.length === unitData.length ? 'block' : 'none' }}>--UNSELECT ALL--</Option></> : false}

                                {unitList && unitList.length > 0 &&
                                    unitList.map((finalList_unitlist) => {
                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                            return (
                                                <OptGroup label={finalList_unitlist.label}>
                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                        <Option value={subUnitData.u_id}>{subUnitData.u_name}</Option>

                                                    ))}
                                                </OptGroup>
                                            )
                                    })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <Button className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', borderRadius: '15px', marginLeft: '0px', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white', marginLeft: '25%', marginRight: '-50px', marginTop: '10%' }}
                        onClick={filterChange} icon={<ArrowRightOutlined />}
                        size='default' >
                        Go
                    </Button>
                    <Button type="primary"
                        className={'addbutton ' + localStorage.getItem('currentTheme')} icon={<RetweetOutlined />}
                        style={{
                            marginRight: '10px',
                            borderRadius: '15px',
                            background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', marginLeft: '0px', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white', marginLeft: '25%', marginTop: '10%', borderColor: 'transparent'
                        }}
                        onClick={() => {
                            // const payload = [
                            //     authtoken,
                            //     {
                            //         "session_token": authtoken,
                            //         "request":
                            //             ["GetComplianceStatusChart",
                            //                 {
                            //                     "c_ids": [
                            //                         commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            //                     ],
                            //                     "d_ids": domainIds,
                            //                     "filter_type": "Group",
                            //                     "from_date": null,
                            //                     "to_date": null,
                            //                     "criticality": "",
                            //                     "filter_ids": [
                            //                         1
                            //                     ],
                            //                     "le_ids": entityData,
                            //                     "country_domains": countryDomain,
                            //                     "chart_year": year
                            //                 }
                            //             ]
                            //     }
                            // ]
                            // getCompliancestatuschart({
                            //     payload: payload,
                            //     paramid: paramid
                            // })
                            // setOpen(false)
                            // setFilterOpen(false)
                            // setGo(false)
                            setCriticalityStatus(false)
                            setChartType("count")
                            setFieldType("")
                            setFilterData({
                                ...filterData,
                                // country: '',
                                // domain: '',
                                legalentity: '',
                                division: '',
                                unit: '',
                                business: '',
                                category: '',
                                unit: '',
                                from_date: null,
                                to_date: null,
                                from_date_only: null,
                                from_year: null,
                                from_month: null
                            })
                            // setCountry([])
                            // setDomain([])
                            setBusiness([])
                            setEntity([])
                            setdivisionDatas([])
                            setcategoryData([])
                            setunitData([])
                            setDateState(false)
                            setTempState({
                                ...tempState,
                                bg: [],
                                le: [],
                                div: [],
                                cat: [],
                                unit: []
                            })

                        }}
                        size='default' >
                        Reset
                    </Button>
                </div>

            </Drawer>
            <Modal open={historymodal}
                title="Remarks History"
                className={" add-service-prv " + localStorage.getItem('currentTheme')}
                footer={null}
                onCancel={historycancel}
                maskClosable={false}
            >
                {/* <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                  columns={history_columns}
                  dataSource={historydata && historydata.remarks_history}
                  pagination={false}
                /> */}
                <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                    <div className='ant-table ant-table-bordered'>
                        <div className='ant-table-container'>
                            <table>
                                <colgroup>
                                    <col style={{ width: '10px' }}></col>
                                </colgroup>
                                <thead className="ant-table-thead " >
                                    <tr>
                                        <th className={'ant-table-cell'} style={{ width: '5px' }}>#</th>
                                        <th className={'ant-table-cell'} style={{ width: '10px' }}>Activity Date</th>
                                        <th className={'ant-table-cell'} style={{ width: '30px' }}>User Type</th>
                                        <th className={'ant-table-cell'} style={{ width: '200px' }}>Reviewer Report</th>
                                        <th className={'ant-table-cell'} style={{ width: '30px' }}>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody className='ant-table-tbody'>
                                    {remarksHistoryData && remarksHistoryData.remarks_history && remarksHistoryData.remarks_history.length > 0 && remarksHistoryData.remarks_history.map((item, i) => {
                                        console.log(item, 'itemitemitem');
                                        let splittedDocs;
                                        if (item.documents != null) {
                                            let doc = item.documents;
                                            splittedDocs = doc.split('||')
                                        }
                                        console.log(splittedDocs, 'splittedDocs');
                                        return <tr>
                                            <td className='ant-table-cell'>
                                                <center>
                                                    {i + 1}
                                                </center>
                                            </td>
                                            <td><center>{item.activity_on}</center></td>
                                            <td><center>{((item.user_category_id == 5 || item.user_category_id == 6) ? 'Assignee' : ((item.user_category_id == 3 || item.user_category_id == 4) && (item.action == 'Rectified' || item.action == 'Concurrence Rejected' || item.action == 'Concurred')) ? 'Concurrer' : (item.user_category_id == 1 && (item.action == 'RectifyApproval' || item.action == 'Approval Rejected' || item.action == 'Approved') ||
                                                (item.user_category_id == 3 || item.user_category_id == 4) && (item.action == 'Approval Rejected' || item.action == 'RectifyApproval' || item.action == 'Approved') ? 'Approver' : ''))}</center></td>
                                            <td>{splittedDocs && splittedDocs.length > 0 ?
                                                splittedDocs.map((items, l) => {
                                                    return <tr><a href="javascript:;" style={{ wordBreak: 'break-all' }} onClick={() => {
                                                        const remarkapi = [
                                                            authtoken,
                                                            {
                                                                "session_token": authtoken,
                                                                "request": [
                                                                    "DownloadFile",

                                                                    {

                                                                        "le_id": remarksModalData.le_id,
                                                                        "c_id": Number(remarksModalData.c_id),
                                                                        "d_id": Number(remarksModalData.d_id),
                                                                        "u_id": Number(remarksModalData.u_id),
                                                                        "start_date": remarksModalData.start_date,
                                                                        "file_name": items
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                        console.log(remarkapi, "trueeee123");
                                                        // if (entityid != "null" && entityid !== null) {
                                                        console.log("trueeee123");
                                                        getdownloadpath({
                                                            payload: remarkapi,
                                                            paramid: paramid,
                                                        })
                                                        // }
                                                    }}>{items}</a></tr>
                                                }) : ''}</td>
                                            <td><p style={{ wordBreak: 'break-all' }}>{item.remarks}</p></td>


                                        </tr>
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>

    )
}


export default connect(mapStateToProps, {
    getCompliancestatuschart,
    getCriticalityFilter,
    getCommonData,
    getCompliancestatusTable,
    getTimeZoneData,
    getComplianceTrendShowMoreTableData,
    getRemarksHistoryData,
    getdownloadpath
})(ComplianceStatusChart);