import { Fragment, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Card, Select, Input, Button, message, Upload, Dragger, Table, Space, Tooltip, Modal } from 'antd';
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt'
import {
    getClientTransaction, getUnit, getlist, getDomainaDetails,
    getdownloadpath, downloadfiles, uploadcontent, upload_docu_file, savebulkrecord,
    getCompletedTaskCsvUploadedList, getqueuepath, queuedocument, uploadbulkdocument,
    savebulkrecordstatus, uploadbulkfiles, zipfiledownload, Contentfileupload
} from './../../Store/Action/Transcations/Completedtaskcurrentbulkupload'
import _ from 'lodash';
import { Toaster } from '../../Libs/Toaster';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import {
    UploadOutlined, DownloadOutlined, PlusOutlined, SearchOutlined,
    ArrowLeftOutlined, CloseCircleOutlined,
    PlayCircleOutlined, FilterFilled, InboxOutlined, ConsoleSqlOutlined, PlusCircleOutlined, CloudUploadOutlined
} from '@ant-design/icons';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';
import url from '../../Libs/URL';
import { validationType, validateSelect, onPasteToaster } from './../../Libs/inputValidation';
import HTTP from '../../Libs/http'
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    unit: state.bulkupload.bulkuploads.unitlist,
    lists: state.bulkupload.bulkuploads.downlist,
    downloadpath: state.bulkupload.bulkuploads.downpath,
    uploadpath: state.bulkupload.bulkuploads.uploadcontent,
    uploaderror: state.bulkupload.bulkuploads.errorlog,
    uploadfiledata: state.bulkupload.bulkuploads.uploadfile,
    datalist: state.bulkupload.bulkuploads.datalist,
    bulist: state.bulkupload.bulkuploads.datalist.csv_list,
    queuepath: state.bulkupload.bulkuploads.queuedpath,
    queuestatuss: state.bulkupload.bulkuploads.queuestatus
});

const Completedtaskcurrentyearbulkuploadlist = ({
    getClientTransaction,
    getUnit,
    getDomainaDetails,
    getlist,
    unit,
    lists,
    getdownloadpath,
    savebulkrecord,
    uploadbulkfiles,
    savebulkrecordstatus,
    downloadpath,
    downloadfiles,
    uploadbulkdocument,
    uploadcontent,
    uploadpath,
    upload_docu_file,
    uploaderror,
    uploadfiledata,
    datalist,
    getCompletedTaskCsvUploadedList,
    bulist,
    getqueuepath,
    queuepath,
    queuedocument,
    queuestatuss,
    zipfiledownload,
    Contentfileupload
}) => {
    console.log(unit.in_units, "unitunitunit");
    const [modelshow, setModelShow] = useState(true)
    const { Option } = Select;
    const { Dragger } = Upload;

    const location = useLocation();
    const historynew = useHistory();
    const paramid = location.pathname;
    const formkey = localStorage.getItem('formkey');
    const authtoken = localStorage.getItem('authToken');

    const entityname = localStorage.getItem('SelectedEntity');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const isAuth = localStorage.getItem('isAuthenticated');
    const [showlist, setshowlist] = useState('block');
    const [showdown, setshowdown] = useState('none');
    const [errorshow, seterrorshow] = useState('none');
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [uploadState, setUploadState] = useState(false)
    const [downloadState, setDownloadState] = useState(false)
    console.log(AddFormSubmit, 'addformsub')
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [modalVisible, SetmodalVisible] = useState(false);
    const [boolean, setBoolean] = useState(false)
    const [useeffecttrigger, setUseeffecttrigger] = useState(true)
    const [domain, setDomian] = useState('');
    const [filereset, setfilereset] = useState('');
    const [errorlog, Seterrorlog] = useState();
    const [uploadfile1, setUploadFile1] = useState([])
    const [uploadfile2, Setuploadfile2] = useState([])
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const [entityid, setCurrentEntity] = useState('');
    const [filterText, setFilterText] = useState("");
    const [filename, setFilename] = useState([]);
    const [filecontent, setfilecontent] = useState();
    const [status, setstatus] = useState();
    const [legalentityid, setlegalentityid] = useState();
    const [uploadmodaldata, setuploadmodaldata] = useState();
    const [filesdrop, setFilesdrop] = useState([]);
    const [filePath, setfilePath] = useState('');
    const [buttonstatus, setbuttonstatus] = useState({
        uploadbutton: 'block',
        submitbutton: 'none'
    })
    const [modalstatus, setmodalstatus] = useState({
        uploadbutton: 'block',
        submitbutton: 'none'
    })
    const [UnitData, setUnitData] = useState([])



    // const [dataTableProperties, setDataTableProperties] = useState({
    //     fixedHeader: true
    // });
    const [downloadFile, setDownloadFile] = useState({
        legal_entity: '',
        domain: '',
        domainid: '',
        unit: '',
        unitid: '',
        unitcode: '',
        // legalentity_name: ''
        legalentity_name: localStorage.getItem('SelectedEntity') != "All Legal Entity" ?
            localStorage.getItem('SelectedEntity') : ''
    })

    const [uploadfile, Setuploadfile] = useState({
        filename: '',
        legal_entity: '',
    })
    const [upload2docu, setupload2docu] = useState({
        fieldetails: ''
    })
    const [documentupload, SetDocumentUpload] = useState()
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [fileList, setFileList] = useState([]);
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '20', '30']
    })
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const [baseData, setBaseData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');
    const [selectSearchValue, setSelectSearchValue] = useState({
        legalEntity: '',
        domain: '',
        unit: '',
        legalEntityUpload: ''
    })

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };
    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            setDownloadFile({
                ...downloadFile,
                legal_entity: localStorage.getItem('SelectedEntity')
            })
        }
    }, [localStorage.getItem('SelectedEntity')])

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset' onClick={() => handleReset(clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys)} />

            </div>
        ),
        filterIcon: (filtered) => (
            < SearchOutlined
                style={{
                    //   fontWeight:'bold'
                }}
            />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) => record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    const columns = [
        {
            title: '#',
            dataIndex: 'legal_entity_name',
            key: 'legal_entity_name',
            ellipsis: false,
            width: '3%',
            align: 'center',
            render: (data, record, index) => {
                return index + 1
            }

        },
        {
            title: 'Legal Entity Name',
            dataIndex: 'legal_entity_name',
            key: 'legal_entity_name',
            //  ...getColumnSearchProps('legal_entity_name'),
            ellipsis: false,
            width: '200px',
            ...getColumnSearchProps('legal_entity_name', 'Legal Entity Name'),

        },

        {
            title:
                'Uploaded file name',
            dataIndex: 'csv_name',
            key: 'csv_name',
            //  ...getColumnSearchProps('csv_name'),
            // ellipsis: true,
            width: '180px',
            ...getColumnSearchProps('csv_name', 'Upload File Name'),

        },

        {
            title: 'Uploaded On',
            dataIndex: 'uploaded_on',
            key: 'uploaded_on',
            // ...getColumnSearchProps('csv_name'),
            ellipsis: false,
            width: '100px'
        },

        {
            title: 'No.of Records',
            dataIndex: 'total_records',
            key: 'total_records',
            // ...getColumnSearchProps('csv_name'),
            ellipsis: false,
            width: '95px',
            left: true,
            align: 'center',
        },

        {
            title: 'Required Documents',
            dataIndex: 'total_documents',
            key: 'total_documents',
            // ...getColumnSearchProps('csv_name'),
            ellipsis: false,
            align: 'center',
            width: '75px',
        },

        {
            title: 'Uploaded Documents',
            dataIndex: 'bu_uploaded_documents',
            key: 'bu_uploaded_documents',
            // ...getColumnSearchProps('csv_name'),
            ellipsis: false,
            width: '70px',
            align: 'center',
        },

        {
            title: 'Remaining Documents',
            dataIndex: 'remaining_documents',
            key: 'remaining_documents',
            // ...getColumnSearchProps('csv_name'),
            ellipsis: false,
            width: '75px',
            align: 'center',
        },

        {
            title: 'Upload Documents',
            key: 'doc_names',
            ellipsis: false,
            width: '70px',
            align: 'center',
            render: (record, text) => {
                return <div style={{ marginLeft: "35px" }} >
                    {text.remaining_documents > 0 ?
                        <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" onClick={(e) => uploadmodalopen(text)}>
                            <Tooltip title='Click here to upload docs'><UploadOutlined /></Tooltip>

                        </span> : ''}
                </div>
            },
        },

        {
            title: 'Queued Documents',
            ellipsis: false,
            align: 'center',
            render: (record, text) => {
                return (
                    <div style={{ marginLeft: "33px" }} >
                        {text.remaining_documents == 0 ?
                            <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"
                                onClick={(e) => {
                                    queueddoc(record)
                                }} >
                                <Tooltip title='Click here to upload queued docs' placement='left'> <i className="ri-refresh-line" ></i></Tooltip>



                            </span> : ''}
                    </div>
                )
            },
            width: '70px'
        },

    ];
    const [pdfFile, setPdfFile] = useState([]);
    const [pdfFileError, setPdfFileError] = useState('');
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        multiple: true,
        onDrop: files => {
            setfilecontent(files)
            setFilesdrop(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            acceptedFiles.forEach((file) => {
                const reader = new FileReader()

                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = () => {
                    const binaryStr = reader.result
                    setPdfFile(binaryStr);
                    setfilePath(file.path);
                }
                reader.readAsDataURL(file)
            })

            var results = [];
            const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
            let files1 = files;
            let file_max_size = 52428800
            for (var i = 0; i < files1.length; i++) {
                var file = files1[i];
                var file_name = file.name;
                setFilename(file_name)


                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));

                if (fileRegex.test(f_name) === false) {
                    Toaster.error('Invalid Format');
                    return;
                }

                let file_size = file.size;
                if (file_size > file_max_size) {
                    Toaster.error('Maximum File Sixe is 50MB');
                    return;

                }

                if (file) {

                    convert_to_base641(file, function (file_content) {

                        var result = uploadFileFormat1(file_size, file_name, file_content);
                        // let tenp1 = []
                        // tenp1.push(result)
                        results.push(result);
                        SetDocumentUpload(results)
                        // SetDocumentUpload([...documentupload,...tenp1])

                    });

                }

            }
        }


    });

    const convert_to_base641 = (file, callback) => {

        var reader = new FileReader();
        reader.onload = function (readerEvt) {
            var binaryString = readerEvt.target.result;
            let file_content = base64_encode(binaryString);
            callback(file_content);
        };
        reader.readAsBinaryString(file);
    }
    const handlePasting = (e, regex) => {
        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
    }
    const uploadFileFormat1 = (size, name, content) => {

        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content
        };
        return result;
    }
    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const uploadmodalopen = (data) => {
        setuploaddoc_content({
            ...uploaddoc_content,
            filename: ''
        })
        setuploadmodaldata(data)
        SetmodalVisible(true);

    }

    const modalcancel = () => {
        SetmodalVisible(false);
        setuploaddoc_content({
            ...uploaddoc_content,
            filename: ''
        })
    }

    const searchRecords = (e) => {
        if (e == 'clear') {
            setClearText('')
            setBaseData(bulist)
            setIconEnable(false)
        } else {
            setClearText(e.target.value)
            if (e.target.value.length > 0) {
                setIconEnable(true)
            } else {
                setIconEnable(false)
            }
            const filterTable = bulist.filter(o =>
                Object.keys(o).some(k =>
                    String(o[k])
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );

            setBaseData(filterTable)
        }

    };

    const queueddoc = (row) => {
        setstatus(row)
        const info = JSON.parse(localStorage.getItem('sessionValue'))
        const session = info.entity_info;
        const legal_id = _.filter(session, { le_name: row.legal_entity_name })
        setlegalentityid(legal_id[0].le_id);
        const pay = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "ProcessQueuedTasks",
                    {
                        "file_submit_status": row.file_submit_status,
                        "data_submit_status": row.data_submit_status,
                        "new_csv_id": row.csv_past_id,
                        "country_id": 1,
                        "legal_entity_id": legal_id[0].le_id,
                        "domain_id": row.domain_id,
                        "unit_id": row.unit_id,
                        "skip_duplicate": false
                    }
                ]
            }
        ]

        getqueuepath({
            payload: pay,
            paramid: paramid
        })
    }



    const download = () => {
        setDownloadState(true)
        setAddFormSubmit(true)
        if (formValidator.current.allValid() || downloadFile.domainid != '') {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetDownloadData",
                        {
                            "legal_entity_id": entityid,
                            "unit_id": downloadFile.unit.unit_id,
                            "domain_id": parseInt(downloadFile.domainid),
                            "compliance_task_frequency": "Periodical",
                            "start_count": 0,
                            "le_name": downloadFile.legalentity_name,
                            "d_name": downloadFile.domain.d_name ? downloadFile.domain.d_name : downloadFile.domain,
                            "u_name": downloadFile.unit.unit_name,
                            "u_code": downloadFile.unit.unit_code
                        }
                    ]
                }
            ]
            getdownloadpath({
                payload: pay,
                paramid: paramid
            })
        }

    }
    const uploaddocu = () => {
        setAddFormSubmit1(true)
        setUploadState(true)
        if (formValidator1.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UploadCompletedTaskCurrentYearCSV",
                        {
                            "csv_name": uploadfile.filename[0].file_name,
                            "csv_data": uploadfile.filename[0].file_content,
                            "csv_size": uploadfile.filename[0].file_size,
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            uploadcontent({
                payload: pay,
                paramid: paramid,
                // setUploadState: setUploadState
            })
            setfilereset('');
            seterrorshow('none');
        }

    }
    const uploaddocument = () => {
        uploadbulkdocument(authtoken)
        setbuttonstatus({
            uploadbutton: 'none',
            submitbutton: 'block'
        })
        dropdzonefile();
    }

    const uploaddocu1 = (e) => {
        var results = [];
        const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
        let files = e.target.files;
        let file_max_size = 52428800
        for (var i = 0; i < files.length; i++) {
            let file = files[i];
            let file_name = file.name;


            if (file_name.includes('.csv')) {
                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
                let file_size = file.size;
                if (fileRegex.test(f_name) === false) {
                    Toaster.error('Invalid Format');
                    Setuploadfile({
                        ...uploadfile,
                        filename: ''
                    })
                } else if (file_size > file_max_size) {
                    Toaster.error('Maximum File Sixe is 50MB');
                    Setuploadfile({
                        ...uploadfile,
                        filename: ''
                    })

                } else if (file_name.length > 50) {
                    Toaster.error('File Name length exceeded 50 characters');
                    Setuploadfile({
                        ...uploadfile,
                        filename: ''
                    })
                } else {
                    convert_to_base64(file, function (file_content) {
                        var result = uploadFileFormat(file_size, file_name, file_content);
                        results.push(result);
                        Setuploadfile({
                            ...uploadfile,
                            filename: results
                        })

                    });

                }
            } else {
                Toaster.error('Invalid File Format')
            }
        }

    }

    const convert_to_base64 = (file, callback) => {
        let reader = new FileReader();
        reader.onload = function (readerEvt) {
            let binaryString = readerEvt.target.result;
            let file_content = base64_encode(binaryString);
            callback(file_content);
        };
        reader.readAsBinaryString(file);
    }

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content
        };
        return result;
    }

    const OnSubmit = () => {
        // for (let i in uploadfile1) {
        const pay1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "SaveBulkRecords",
                    {
                        "new_csv_id": uploadfile1.new_csv_id,
                        "country_id": 1,
                        "legal_entity_id": entityid,
                        "domain_id": uploadfile1.domain_id,
                        "unit_id": uploadfile1.unit_id,
                        "skip_duplicate": false
                    }
                ]
            }
        ]
        savebulkrecord({
            payload: pay1,
            paramid: paramid
        })


    }
    useEffect(() => {
        if (bulist && bulist.length > 0) {
            setBaseData(bulist);
        }
    }, [bulist])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])


    useEffect(() => {
        if (queuepath) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatus",
                        {
                            "legal_entity_id": legalentityid,
                            "csv_name": queuepath.csv_name
                        }
                    ]
                }
            ]
            queuedocument({
                payload: pay,
                paramid: paramid
            })
        }
    }, [queuepath])

    useEffect(() => {
        if (queuestatuss) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "ProcessQueuedTasks",
                        {
                            "file_submit_status": status.file_submit_status,
                            "data_submit_status": status.data_submit_status,
                            "new_csv_id": status.csv_past_id,
                            "country_id": 1,
                            "legal_entity_id": legalentityid,
                            "domain_id": status.domain_id,
                            "unit_id": status.unit_id,
                            "skip_duplicate": true
                        }
                    ]
                }
            ]

            Swal.fire({
                title: 'Are You Sure?',
                icon: 'info',
                html:
                    queuestatuss.duplicate_count + '&nbsp;' +
                    'Compliance declined,Do you want to continue?',
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Ok',
                cancelButtonText:
                    'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    getqueuepath({
                        payload: pay,
                        paramid: paramid
                    })
                }
            })
        }
    }, [queuestatuss])

    useEffect(() => {
        if (isAuth) {
            let pay;
            let temparr = [];
            if (entityid !== '') {
                if (entityid == 'null') {
                    let legal_ent = JSON.parse(localStorage.getItem('sessionValue'));
                    for (let i = 0; i < legal_ent.entity_info.length > 0; i++) {
                        let data = legal_ent.entity_info[i].le_id
                        temparr.push(data)
                    }

                    pay = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetCompletedTaskCsvUploadedList",
                                {
                                    "legal_entity_id": '',
                                    "legal_entity_list": temparr
                                }
                            ]
                        }
                    ]
                } else {
                    pay = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetCompletedTaskCsvUploadedList",
                                {
                                    "legal_entity_id": entityid,
                                    "legal_entity_list": [
                                        entityid
                                    ]
                                }
                            ]
                        }
                    ]
                }
                getDomainaDetails({
                    payload: pay,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid])

    useEffect(() => {
        if (entityid !== '') {
            if (boolean == true && entityid != "null") {
                // if (boolean == true && entityid != "null" && useeffecttrigger) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetPastRecordsFormData",
                            {
                                "le_id": entityid
                            }
                        ]
                    }
                ]
                getClientTransaction({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [boolean, entityid])
    useEffect(() => {
        if (entityid !== 'null') {
            if (datalist && entityid != 'null') {
                // if (datalist && entityid != 'null' && useeffecttrigger) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetUnits",
                            {
                                "legal_entity_id": entityid,
                                "domain_id": downloadFile && downloadFile.domain && downloadFile.domain.d_id
                            }
                        ]
                    }
                ]
                getUnit({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [datalist, entityid, downloadFile])
    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
    }, [])


    useEffect(() => {
        const url = localStorage.setItem('temppathname', paramid)
    }, [])

    useEffect(() => {
        if (uploadpath && uploadState == true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatus",
                        {
                            "legal_entity_id": entityid,
                            "csv_name": uploadpath.csv_name
                        }
                    ]
                }
            ]
            upload_docu_file({
                payload: payload,
                paramid: paramid,
                setUploadState: setUploadState
            })
        }
    }, [uploadpath])

    useEffect(() => {
        if (uploaderror) {
            Seterrorlog(uploaderror);
            seterrorshow('block');
            Setuploadfile({
                ...uploadfile,
                filename: ''
            })
            setAddFormSubmit1(false);
        }
    }, [uploaderror])

    useEffect(() => {
        if (uploadfiledata) {
            setUploadFile1(uploadfiledata);
        }
    }, [uploadfiledata])

    useEffect(() => {
        if (downloadpath && downloadState == true) {
            const path = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatus",
                        {
                            "legal_entity_id": entityid,
                            "csv_name": downloadpath.csv_name
                        }
                    ]
                }
            ]
            downloadfiles({
                payload: path,
                paramid: paramid
            })
        }
    }, [downloadpath])



    const downloaddata = (format, data) => {
        let datacolumn = data.split('.');
        let filename = datacolumn[0]

        let token = localStorage.getItem('authToken');
        let sessiontoken = base64_encode(token);
        let path = makekey() + sessiontoken + '/download/invalid/';
        let downloadfile;
        if (format == 'csv') {
            downloadfile = path + 'csv/' + filename + '.csv'
        } else if (format == 'excel') {
            downloadfile = path + 'xlsx/' + filename + '.xlsx'
        } else if (format == 'ods') {
            downloadfile = path + 'ods/' + filename + '.ods'
        } else {
            //downloadfile = path + 'txt/' + filename + '.txt'
            const element = document.createElement("a");
            const file = new Blob([document.getElementById(filename).value], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = filename + '.' + 'txt';
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }


        window.open(`${url.CommonURL}/` + downloadfile);
    }

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        //historynew.push(tempval);
        window.location.href = tempval
        document.getElementById('homehover').style.color = "blue"
    }

    const downloaduploadfile = (row) => {
        let token = localStorage.getItem('authToken');
        let sessiontoken = base64_encode(token);
        let path = makekey() + sessiontoken + '/uploaded_file/csv/';
        window.open(`${url.CommonURL}/` + path + row);
    }

    const dropdzonefile = (data) => {
        if (uploaddoc_content.filename.length > 0) {
            const pay = uploaddoc_content.filename;
            Contentfileupload({
                payload: pay,
                csv_id: data,
                token: authtoken,
                paramid: formkey
            })
            SetmodalVisible(false);
        } else {
            Toaster.error('Document(s) Required');
        }

    }

    const [uploaddoc_content, setuploaddoc_content] = useState({
        filename: ''
    })
    const uploadselected_docu = (e) => {
        var results = [];
        let tempArray = [];
        const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
        let files = e.target.files;
        let file_max_size = 52428800
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            let file_name = file.name;
            console.log(file_name, 'file_name')
            const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
            let file_size = file.size;
            if (uploadmodaldata.doc_names.includes(file_name) == true) {
                if (fileRegex.test(f_name) === false) {
                    Toaster.error('Invalid Format');
                    setuploaddoc_content({
                        ...uploaddoc_content,
                        filename: ''
                    })
                    return;
                } else if (file_size > file_max_size) {
                    Toaster.error('Maximum File Sixe is 50MB');
                    setuploaddoc_content({
                        ...uploaddoc_content,
                        filename: ''
                    })
                    return;

                } else {
                    console.log(file, 'filename');
                    convert_to_base64(file, function (file_content) {
                        console.log(file_content, 'contentname')
                        var result = uploadFileFormat(file_size, file_name, file_content);
                        results.push(result);
                        setuploaddoc_content({
                            ...uploaddoc_content,
                            filename: results
                        })
                    });

                }
            }
        }
    }

    const uploaded_file = (e) => {
        var results = [];
        const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
        let file_max_size = 52428800
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            let file_name = file.name;
            const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
            let file_size = file.size;
            if (uploadfile1.doc_names.includes(file_name) == true) {
                if (fileRegex.test(f_name) === false) {
                    Toaster.error('Invalid Format');
                    setuploaddoc_content({
                        ...uploaddoc_content,
                        filename: ''
                    })
                    return;
                } else if (file_size > file_max_size) {
                    Toaster.error('Maximum File Sixe is 50MB');
                    setuploaddoc_content({
                        ...uploaddoc_content,
                        filename: ''
                    })
                    return;

                } else {
                    convert_to_base64(file, function (file_content) {
                        var result = uploadFileFormat(file_size, file_name, file_content);
                        results.push(result);
                        setuploaddoc_content({
                            ...uploaddoc_content,
                            filename: results
                        })
                    });
                }
            }
        }
    }
    const [domainValue, setDomainValue] = useState(false)
    const [domainFilter, setDomainFilter] = useState([])

    useEffect(() => {
        if (downloadFile.legal_entity != '' && unit.domains) {
            setDomainValue(true)
            setDomainValue(false)
            setDomainFilter(unit.domains)

        }
    }, [unit.domains, downloadFile.legal_entity])




    return (
        <Fragment>
            {modelshow === true ?
                <div >
                    <div className="page-wrapper">
                        <div className="page-titles pb-0 pt-2 page-title-sticky">
                            <div className="row">
                                <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                                            <li className="breadcrumb-item">
                                                <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span style={{ fontSize: '16px' }}>Transaction</span>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                                <span>Completed Task - Current Year - Bulk Upload</span>
                                            </li>
                                        </ol>
                                        {/* <h2 className="mb-0 fw-bold">Completed Task - Current Year - Bulk Upload</h2> */}
                                    </nav>
                                </div >

                                <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end" >
                                    <div className="search-box me-2 " style={{
                                        boxShadow: '0 5px 5px rgb(0 0 0 / 10%)'
                                    }}>
                                        <input className="search-txt" type="text" value={clearText} onChange={searchRecords} placeholder="Type to Search" />
                                        <a className="search-btn">
                                            {iconEnable == true ?
                                                <i className="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                                : <i className="fas fa-search"></i>}
                                        </a>
                                    </div>
                                    <div className="wrapper">
                                        <Tooltip title='Add' placement="top"><Button type="primary" shape="round"
                                            icon={<PlusCircleOutlined />} size='default' onClick={() => {
                                                setBoolean(true)
                                                setshowdown('block')
                                                setAddFormSubmit(false)
                                                setshowlist('none')
                                                setModelShow(false)
                                            }} >
                                            Add
                                        </Button></Tooltip>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid pt-2">
                            <div className="row">
                                <div className="col-12">
                                    <div className='service-provider-table'>
                                        <Table
                                            size={'small'}
                                            pagination={false}
                                            columns={columns}
                                            className={localStorage.getItem("currentTheme")}
                                            dataSource={baseData}
                                            bordered
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div>
                :
                <div style={{ display: showdown }}>
                    <div className="page-wrapper">
                        <div className="page-titles pb-0 pt-1 page-title-sticky">
                            <div className="row">
                                <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                                            <li className="breadcrumb-item">
                                                <span to="/dashboard"><i className="ri-home-3-line fs-5"></i></span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span style={{ fontSize: '16px' }}>Transaction</span>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                                <span>Completed Task - Current Year - Bulk Upload</span>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                                <div
                                    className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end" >
                                    <Tooltip title='' placement="top">
                                        <Button
                                            type="primary"
                                            shape="round"
                                            className='addbutton'
                                            style={{
                                                background: "#0096FF",
                                                borderColor: "#0096FF",
                                                marginRight: "10px"
                                                // float: 'left'
                                            }}
                                            icon={<ArrowLeftOutlined />
                                            }
                                            size='default'
                                            onClick={() => {
                                                window.location.reload()
                                                // setModelShow(true)
                                                // setshowdown('block')
                                                // setAddFormSubmit(false)
                                                // setAddFormSubmit1(false)
                                                // seterrorshow('none');
                                                // setDownloadFile({
                                                //     ...downloadFile,
                                                //     legal_entity: '',
                                                //     domain: '',
                                                //     domainid: '',
                                                //     unit: '',
                                                //     unitid: '',
                                                //     unitcode: '',
                                                // })
                                                // Setuploadfile({
                                                //     ...uploadfile,
                                                //     filename: '',
                                                //     legal_entity: '',
                                                // })
                                                // setUploadFile1([])

                                            }}
                                        >
                                            Back
                                        </Button></Tooltip>
                                </div>
                            </div>
                        </div>
                        {/* {modelshow === false ? */}
                        <div className="container-fluid pt-1">
                            <div className="row mx-3" >
                                <Card className='col-md-6' style={{ marginTop: "5px", boxShadow: "0px 0px 0px 1px grey" }}>
                                    <h4 style={{ textAlign: "center", marginBottom: "10px" }}>Download file</h4>
                                    {/* <ToastContainer /> */}
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-3' style={{ padding: 0 }}>
                                                <label style={{ marginLeft: "15px" }}><b>Legal Entity: </b><span style={{ color: "red" }}>*</span></label>
                                            </div>
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                <div className="col-md-9">
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Select Legal Entity"
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 ( )]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.legalEntity}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                legalEntity: result
                                                            });
                                                        }}
                                                        onChange={(data, value) => {
                                                            if (data !== undefined) {
                                                                setCurrentEntity(data)
                                                                setDownloadFile({
                                                                    ...downloadFile,
                                                                    legal_entity: data,
                                                                    legalentity_name: value.children,
                                                                    domain: '',
                                                                    domainid: '',
                                                                    unit: '',
                                                                    unitid: ''
                                                                })
                                                                setAddFormSubmit(false)
                                                                // setUseeffecttrigger(true)
                                                            }
                                                            else {
                                                                setCurrentEntity('')
                                                                setDownloadFile({
                                                                    ...downloadFile,
                                                                    legal_entity: '',
                                                                    legalentity_name: '',
                                                                    domainid: '',
                                                                    unitid: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={downloadFile.legal_entity || undefined}
                                                        style={{ width: '100%' }}
                                                    >
                                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {formValidator.current.message(
                                                        'legal_entity',
                                                        downloadFile.legal_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}
                                                </div> :
                                                <div className="col-md-8" style={{ padding: 0 }}>
                                                    <p title={localStorage.getItem('SelectedEntity')} className='text-wrap-report' style={{ marginLeft: "19px", marginBottom: '0px' }}>{localStorage.getItem('SelectedEntity')}</p>

                                                </div>
                                            }
                                        </div>
                                    </div><br />
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label style={{ float: "left" }}><b>Domain: </b><span style={{ color: "red" }}>*</span></label>
                                            </div>
                                            <div className='col-md-9'>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Select Domain Name"
                                                    disabled={downloadFile.legal_entity == ''}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.domain}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            domain: result
                                                        });
                                                    }}
                                                    onChange={(data, value) => {
                                                        if (data !== undefined) {
                                                            let getData = _.find(unit.domains, { d_id: Number(data) });
                                                            setDownloadFile({
                                                                ...downloadFile,
                                                                domain: getData,
                                                                domainid: data,
                                                                unit: '',
                                                                unitid: ''
                                                            })
                                                            let FilterData = unit.in_units.filter((item) => {
                                                                return item.d_ids[0] === Number(data)
                                                            })

                                                            console.log(FilterData, "v3333FilterData");
                                                            setUnitData(FilterData)

                                                            setAddFormSubmit(false)
                                                        }
                                                        else {
                                                            setDownloadFile({
                                                                ...downloadFile,
                                                                domain: '',
                                                                unit: '',
                                                                unitid: '',
                                                                domainid: ''
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={downloadFile.domainid || undefined}
                                                    style={{ width: '100%' }}

                                                >
                                                    {domainFilter && domainFilter.length > 0 && domainFilter.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {formValidator.current.message(
                                                    'domain',
                                                    downloadFile.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div><br />
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label style={{ float: "left" }}><b>Vendor: </b><span style={{ color: "red" }}>*</span></label>
                                            </div>
                                            <div className='col-md-9'>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Select Vendor Name"

                                                    disabled={downloadFile.domain == ''}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 , . -  _]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.unit}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            unit: result
                                                        });
                                                    }}
                                                    onChange={(data, value) => {
                                                        if (data !== undefined) {
                                                            let getData = _.find(unit.in_units, { unit_code: data });
                                                            setDownloadFile({
                                                                ...downloadFile,
                                                                unit: getData,
                                                                unitid: data
                                                            })
                                                            setAddFormSubmit(false)
                                                        }
                                                        else {
                                                            setDownloadFile({
                                                                ...downloadFile,
                                                                unit: '',
                                                                unitid: ''
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%' }}
                                                    value={downloadFile.unitid || undefined}
                                                >
                                                    {UnitData && UnitData.length > 0 && UnitData.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_code}>
                                                                {item.unit_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {formValidator.current.message(
                                                    'unit',
                                                    downloadFile.unit,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Vendor Required',
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center mt-3' >
                                        <Button type="primary" shape="round" className='addbutton'
                                            icon={<DownloadOutlined />} size='default' onClick={download}>
                                            Download File
                                        </Button>
                                    </div>
                                </Card>
                                <Card className='col-md-6' style={{ marginTop: "5px", boxShadow: "0px 0px 0px 1px grey" }}>
                                    <h4 style={{ textAlign: "center", marginBottom: "10px" }}>Upload file</h4>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity: </b><span style={{ color: "red" }}>*</span></label>
                                            </div>
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                <div className="col-md-8">
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Select Legal Entity Name"
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 ( )]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.legalEntityUpload}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                legalEntityUpload: result
                                                            });
                                                        }}

                                                        onChange={(data, value) => {
                                                            if (data !== undefined) {
                                                                setCurrentEntity(data)
                                                                Setuploadfile({
                                                                    ...uploadfile,
                                                                    legal_entity: data
                                                                })
                                                                setAddFormSubmit1(false)
                                                                // setUseeffecttrigger(false)
                                                            }
                                                            else {
                                                                setCurrentEntity('')
                                                                Setuploadfile({
                                                                    ...uploadfile,
                                                                    legal_entity: '',

                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={uploadfile.legal_entity || undefined}
                                                        style={{ width: '100%' }}
                                                    >
                                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {formValidator1.current.message(
                                                        'legal_entity',
                                                        uploadfile.legal_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}
                                                </div> :
                                                <div className="col-md-8" style={{ padding: 0, float: "left" }}>
                                                    <p title={localStorage.getItem('SelectedEntity')} className='text-wrap-report' style={{ marginLeft: "17px", marginBottom: '0px' }}>{localStorage.getItem('SelectedEntity')}</p>


                                                </div>
                                            }
                                        </div>
                                    </div><br></br>
                                    {uploadfile1 && uploadfile1.doc_names && uploadfile1.doc_names.length > 0 ?
                                        <>
                                            {console.log(uploadfile1, 'uploadfile1')}
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4' >
                                                        <label><b>Uploaded File: </b><span style={{ color: "red" }}>*</span></label>
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <label className='text-wrap-report'>{uploadfile1 && uploadfile1.csv_name}</label>
                                                        <span className='pointer text-wrap-report' onClick={(e) => downloaduploadfile(uploadfile1.csv_name)}><Tooltip title='Download Document'><DownloadOutlined /></Tooltip></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Select Documents: </b><span style={{ color: "red" }}>*</span></label>

                                                    </div>
                                                    <div className='col-md-8'>
                                                        <Input className='form-control' name="notification" value={''} multiple type='file'
                                                            style={{ width: '70%' }} onChange={(e) => { uploaded_file(e) }} />
                                                        <span className='text-left d-block my-2'>Upload your files here</span>
                                                        <span className='text-left d-block'>Required Documents: {uploadfile1 && uploadfile1.doc_count}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            {uploaddoc_content && uploaddoc_content.filename.length > 0 ?
                                                <div className='col-md-12 my-2'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <h5>Files</h5>
                                                        </div>
                                                        <div className='col-md-8 text-wrap-report'>
                                                            <ul>{uploaddoc_content && uploaddoc_content.filename.map((item) => {
                                                                return <span>{item.file_name}<br /></span>
                                                            })}</ul>
                                                        </div>
                                                    </div>
                                                </div> : ''}
                                            <div className='text-center mt-2'>
                                                <Button type="primary" shape="round" className='addbutton'
                                                    icon={<UploadOutlined />} size='default' onClick={() => { dropdzonefile(uploadfile1.csv_past_id) }}
                                                >
                                                    Upload
                                                </Button>
                                            </div>
                                        </>
                                        : <>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4' >
                                                        <label><b>Select File:</b>&nbsp;<span style={{ color: "red" }}>*</span></label>
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <div className='d-flex'>
                                                            <label htmlFor="file-upload" className='btn btn-primary upload-btn p-1 d-flex justify-content-center align-items-center'>
                                                                <CloudUploadOutlined />
                                                            </label>
                                                            <label htmlFor='file-upload' className='ms-2 p-1'>Upload File Here</label></div>
                                                        <div>
                                                            <input type="file" id="file-upload" accept=".csv" className='mx-2 d-none' value={filereset} onChange={(e) => {
                                                                uploaddocu1(e)
                                                            }} />

                                                            {formValidator1.current.message(
                                                                'uploadfile',
                                                                uploadfile.filename,
                                                                'required',
                                                                {
                                                                    className: `mt-0 mx-2 invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'File Required',
                                                                    }
                                                                })}
                                                        </div>
                                                        <span className='text-wrap-report d-block' style={{ color: 'blue' }}>{uploadfile.filename && uploadfile.filename[0].file_name}</span>
                                                        <span className='d-block' style={{ color: '#97958F' }}>[Accepted file format<span style={{ color: 'red' }}>.csv</span>]</span>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='text-center mt-5'>
                                                <Button type="primary" shape="round" className='addbutton'
                                                    icon={<UploadOutlined />} size='default' onClick={uploaddocu}>
                                                    Upload
                                                </Button>
                                            </div> </>}
                                </Card>
                                {uploadState == true ?
                                    <div style={{ display: errorshow }}>
                                        {errorlog ?
                                            <div className="row">
                                                <div style={{ textAlign: 'center', padding: '16px' }}>
                                                    <Button type="primary" shape="round" className='addbutton'
                                                        icon={<DownloadOutlined />} size='default'>
                                                        Valid And Invalid Data
                                                    </Button><br />
                                                    <span onClick={(e) => { downloaddata('excel', errorlog.invalid_file) }}><Link>Download Excel | </Link></span>
                                                    <span onClick={(e) => { downloaddata('csv', errorlog.invalid_file) }}><Link>Download CSV |</Link></span>
                                                    <span onClick={(e) => { downloaddata('ods', errorlog.invalid_file) }}> <Link>Download ODS |</Link></span>
                                                    <span onClick={(e) => { downloaddata('text', errorlog.invalid_file) }}><Link> Download Text</Link></span>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="alert alert-success bg-success  m-3" style={{ opacity: 0.8 }} role="alert">
                                                        <p className='h5 text-white'>Uploaded Data - Summary</p>
                                                        <span className='d-block'>Total No. of Records: {errorlog.total}</span>
                                                        <span className='d-block'>Valid Records: {errorlog.max_length_error}</span>
                                                        <span className='d-block'>Invalid Records: {errorlog.invalid}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="alert alert-danger bg-danger m-3" style={{ opacity: 0.8 }} role="alert">
                                                        <p className='h5 text-white'>Invalid data - Summary</p>
                                                        <span className='d-block'>Invalid Date: {errorlog.invalid_date}</span>
                                                        <span className='d-block'>Invalid File Format: {errorlog.invalid_file_format}</span>
                                                        <span className='d-block'>Master Data Inactive: {errorlog.inactive_error}</span>
                                                        <span className='d-block'>Mandatory Field Blank: {errorlog.mandatory_error}</span>
                                                        <span className='d-block'>Invalid Data: {errorlog.invalid}</span>
                                                        <span className='d-block'>Duplicate Document Name: {errorlog.duplicate_doc_name}</span>
                                                        <span className='d-block'>Invalid Character: {errorlog.invalid_char_error}</span>
                                                    </div>
                                                </div>
                                            </div> :
                                            null
                                        }
                                    </div> : ''}
                            </div>

                        </div>
                    </div>
                </div >
            }
            <Modal title="Upload File" open={modalVisible} footer={null} maskClosable={false} className={"   add-service-prv " + localStorage.getItem('currentTheme')}
                onCancel={modalcancel}>
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <label><b>Legal Entity : </b>&nbsp;<span style={{ color: "red" }}>*</span></label>
                            <span className='text-wrap-report' title={uploadmodaldata && uploadmodaldata.legal_entity_name} style={{ padding: '58px' }}>{uploadmodaldata && uploadmodaldata.legal_entity_name}</span>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col-md-12' style={{ paddingTop: '0.5rem' }}>
                            <label><b>Uploaded File : </b>&nbsp;<span style={{ color: "red" }}>*</span></label>
                            <span style={{ padding: '45px' }}>{uploadmodaldata && uploadmodaldata.csv_name.split('_')[0]}</span>
                            <span className='pointer' onClick={(e) => downloaduploadfile(uploadmodaldata.csv_name)}><Tooltip title='Download Document'><DownloadOutlined /></Tooltip></span>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 '>
                    <div className='row'>
                        <div className='col-md-8' style={{ paddingTop: '0.5rem' }}>
                            <div className="d-flex">
                                <label ><b>Select Documents : </b><span style={{ color: "red" }}>*</span></label>&nbsp;&nbsp;
                                <Input className='form-control ms-2' name="notification" value={''} multiple type='file'
                                    style={{ width: '65%' }} onChange={(e) => { uploadselected_docu(e) }} />
                            </div>
                            {/* <Dragger className='form-control' name="notification" value={''} multiple type='file'
                                style={{ maxHeight: '50px' }} onChange={(e) => { uploadselected_docu(e) }} >
                                <p className='text-center mt-1'>Upload or Drag your files here</p>
                            </Dragger> */}
                            <p className='text-center mt-2'>Upload your files here</p>
                            <p className='text-center mt-1 text-wrap-report' style={{ color: 'blue' }}> {uploaddoc_content && uploaddoc_content.filename &&
                                uploaddoc_content.filename.length > 0 && uploaddoc_content.filename.map((data) => {
                                    return <span>{data.file_name}<br /></span>
                                })}</p>
                        </div>



                    </div>
                </div>
                <div className='col-md-4' style={{ paddingTop: '2rem', marginLeft: '23%' }}>
                    <span >Required Documents : {uploadmodaldata && uploadmodaldata.total_documents}</span><br />
                    <span>Uploaded Documents: {uploadmodaldata && uploadmodaldata.bu_uploaded_documents}</span><br />
                    <span style={{ color: 'red' }}>Remaining Documents:{uploadmodaldata && uploadmodaldata.remaining_documents} </span>
                    <div style={{ display: modalstatus.uploadbutton }}><Button type="primary" shape="round" className='addbutton mt-3'
                        style={{ marginLeft: '58%' }} icon={<UploadOutlined />} size='default' onClick={() => { dropdzonefile(uploadmodaldata.csv_past_id) }}>
                        Upload
                    </Button></div>

                    {/* <div style={{ display: modalstatus.submitbutton }}>
                                <Button type="primary" shape="round" className='addbutton'
                                    icon={<DownloadOutlined />} size='default' onClick={zipdownload}>
                                    Uploaded Data(.zip)
                                </Button>
                                <div className="button2 mt-3" id={localStorage.getItem('currentTheme')} onClick={() => { queueddoc(uploadmodaldata) }}>
                                    Submit
                                </div>

                            </div> */}

                </div>

            </Modal>
        </Fragment >
    )
}
function makekey() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
}
export default connect(mapStateToProps, {
    getClientTransaction,
    getUnit,
    getlist,
    getDomainaDetails,
    getdownloadpath,
    downloadfiles,
    uploadbulkdocument,
    uploadcontent,
    upload_docu_file,
    getCompletedTaskCsvUploadedList,
    getqueuepath,
    queuedocument,
    savebulkrecordstatus,
    savebulkrecord,
    uploadbulkfiles,
    zipfiledownload,
    Contentfileupload
})(Completedtaskcurrentyearbulkuploadlist);